import { animate, style, transition, trigger } from '@angular/animations';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'loading-overlay',
    template: require('./loading-overlay.html'),
    styles: [require('./loading-overlay.scss')],
    animations: [
        trigger('loadingState', [
            transition(':leave', [   // :leave is alias to '* => void'
                animate(200, style({opacity: 0})),
            ]),
        ]),
    ],
})

export class LoadingOverlayComponent implements OnInit {
    constructor(
    ) {
    }

    @Input() set loading(value: boolean) {
        this._loading = value;
    }
    @Input() set fullWidth(value: boolean) {
        this._fullWidth = value;
    }
    @Input() set message(value: string) {
        this._message = value;
    }

    private _loading: boolean = false;
    private _fullWidth: boolean = false;
    private _message: string;

    ngOnInit() {
        this._fullWidth = false;
        this._message = null;
    }

    isLoading(): boolean {
        return this._loading;
    }

    isFullWidth(): boolean {
        return this._fullWidth;
    }

    printMessage(): string {
        return this._message;
    }
}