export class Modal {
    headerText?: string;
    background: string;
    logoSrc: string;
    logoAlt: string;
    title: string;
    cssClass: string|string[];
    component: any;
    data: object;
    segment?: string;
    position?: 'relative' | 'static';
    inputs?: any[];
}
