import { Component, OnDestroy, OnInit } from '@angular/core';
import { EFXAdminService } from '../../efx-admin.service';
import { isValidPhoneNumber } from '../../../../../../Utilities/validatePhoneNumber';


@Component({
  selector: 'efx-app-download',
  template: require('./efx-app-download.html'),
  styles: [require('./efx-app-download.scss'), require('../../efx-admin.scss')],
})

export class EFXAppDownloadComponent implements OnDestroy {

  constructor(
    private efxAdminService: EFXAdminService
  ) {
  }
  readonly openInAppStore = require('../../../../../../../mars/components/icons/open-in-app-store');
  readonly openInGooglePlay = require('../../../../../../../mars/components/icons/open-in-google-play');

  private phoneNumber: string = '';

  private isPhoneValid: boolean = false;

  validateNumber() {

    if (this.phoneNumber.length && isValidPhoneNumber(this.phoneNumber)) {
      this.isPhoneValid = true;
    } else {
      this.isPhoneValid = false;
    }
  }

  sendLinkToPhone() {
    this.efxAdminService.sendDownloadLink(this.phoneNumber);
    this.phoneNumber = '';
  }

  ngOnDestroy() {
  }
}
