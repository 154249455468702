import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Actions} from '@ngrx/effects';
import {Store} from "@ngrx/store";
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {NzMessageService} from 'ng-zorro-antd/message';
import {shareReplay} from 'rxjs/operators';
import {RouteGeneratorService} from "../../../../_services/routes/route-generator.service";
import {SegmentService} from '../../../../Analytics/Segment/segment.service';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {TicketBlock} from '../../../../ApiClient/Models/Stack/ticket-block';
import {EventFarmService} from '../../../eventFarm.service';
import {AlertService} from '../../../eventFarmAlert.service';
import * as fromRoot from '../../../store';
import {Modal} from '../../Modal/modal.class';
import {ModalService} from '../../Modal/modal.service';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListTransfersComponent} from '../GuestListTransfers/guest-list-transfers.component';
import {GuestListTransfersService} from "../GuestListTransfers/guest-list-transfers.service";
import {ResendInvitationsComponent} from '../resend-invitations.component';

@Component({
    selector: 'guest-list-actions',
    template: require('./guest-list-actions.html'),
    styles: [require('./guest-list-actions.scss')]
})
export class GuestListActionsComponent implements OnInit {
    @Input() type: 'Event' | 'TicketBlock' = 'Event';
    @Input() ticketBlock?: TicketBlock;


    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private suspend: boolean = false;
    private hasTransfers: boolean = false;


    constructor(
        private store: Store<fromRoot.AppState>, private drawerRef: NzDrawerRef,
        private actions$: Actions,
        private guestListTransfersService: GuestListTransfersService,
        private eventFarmService: EventFarmService,
        private segmentService: SegmentService,
        private guestListService: GuestListViewService,
        private apiClient: EventFarmAPIClient,
        private messageService: NzMessageService,
        private modalService: ModalService,
        private message: NzMessageService,
        private drawerService: NzDrawerService,
        private alertService: AlertService,
        private routeGenerator: RouteGeneratorService,
        private router: Router
    ) {
    }


    ngOnInit() {
        this.checkForVirbelaSetting();
    }

    private get canSuspend() {
        return this.suspend;
    }

    confirmResend() {
        const inviteContext = {
            context: 'resendInvitations',
        };
        const modal: Modal = new Modal();
        modal.title = 'Re-send Unconfirmed Invitations';
        modal.component = ResendInvitationsComponent;
        modal.cssClass = 'resend-invitations';
        modal.data = inviteContext;

        this.modalService.changeModal(modal);
    }

    async resendInvitationForTicketBlock() {
        this.message.info('Resending invitations...');
        await this.guestListService.resendAllInvitationsForTicketBlock();
    }

    rescindAll() {
        this.alertService.guestList().confirmRescindAllInvitations()
            .then((res) => {
                if (res.value) {
                    this.message.info('Rescinding invitations...');
                    this.guestListService.recindAll();
                }
            });
    }

    async suspendAll() {
        this.alertService.virbelaIntegration().suspendAll()
            .then(async (res) => {
                if (res.value) {
                    this.message.info('Suspending invitations...');
                    await this.guestListService.suspendAll();
                }
            });
    }

    goToInviteAdd() {
        if (this.type === 'TicketBlock') {
            const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.invite', {eventId: this.eventFarmService.currentEvent.id});
            this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + this.ticketBlock.id);
        } else {
            this.router.navigateByUrl(this.routeGenerator.url('events.guest-list.invite', {
                poolId: this.eventFarmService.currentTeam.id,
                eventId: this.eventFarmService.currentEvent.id,
            }));
        }
    }

    goToTransfers(): void {

        const drawerRef = this.drawerService.create<GuestListTransfersComponent, {}, string>({
            nzTitle: 'Guest List Transfers',
            nzContent: GuestListTransfersComponent,
            nzWidth: '50%'
        });

    }

    async downloadGuestList() {
        await this.apiClient.getUseCaseFactory().Report().CreateGuestSummaryReport(
            this.eventFarmService.currentEvent.id,
            this.eventFarmService.currentUser.id,
            `Guest List - ${new Date().toLocaleString()}`
        ).toPromise();

        this.messageService.success('Guest List report generated');
    }

    async downloadTicketBlockList() {

        await this.apiClient.getUseCaseFactory().Report().CreateTicketBlockSummaryReport(
            this.eventFarmService.currentEvent.id,
            this.eventFarmService.currentUser.id,
            this.guestListService.ticketBlock.id,
            `Ticket Block - ${new Date().toLocaleString()}`
        ).toPromise();

        this.messageService.success('Guest List report generated');
    }


    private async checkForVirbelaSetting() {
        if (!this.guestListService.isTicketBlock && this.eventFarmService.virbelaEnabled) {
            const vbPoolSetting = await this.eventFarmService.currentPoolWorldForEvent();
            if (vbPoolSetting) {
                this.suspend = true;
            } else {
                this.suspend = false;
            }
        } else {
            this.suspend = false;
        }
    }

}
