import { BehaviorSubject } from 'rxjs';
import { PoolFeature } from '../../../../../ApiClient/Models/PoolFeature/poolFeature';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { NzMessageService } from 'ng-zorro-antd/message';
import {Allotment} from '../../../../../ApiClient/Models/Pool/allotment';
import {shareReplay} from 'rxjs/operators';
import * as fromRoot from '../../../../store';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../../store';
@Injectable()
export class TeamAllotmentService {
  private _features: PoolFeature[];

  constructor(
    private apiClient: EventFarmAPIClient,
    private messageService: NzMessageService,
    private store: Store<fromStore.AppState>

  ) {}

  public  fetchAllotmentsByPoolId(poolId: string) {
   return this.apiClient
      .getUseCaseFactory()
      .Pool()
      .ListPoolAllotmentsForPool(poolId);

  }

  /*** ALLOTMENTS ***/
  public  addAllotmentToPool(
    poolId: string,
    poolAllotmentType: 'eventfarm' | 'cio' | 'listly',
    amount: number
  ) {
      return this.apiClient
        .getUseCaseFactory()
        .PoolAllotment()
        .CreatePoolAllotment(poolId, poolAllotmentType, amount);
  }

  public  updateAllotmentForPool(
    poolId: string,
    poolAllotmentId: string,
    amount: number
  ) {
    return this.apiClient
        .getUseCaseFactory()
        .PoolAllotment()
        .SetPoolAllotmentAmount(poolAllotmentId, amount);

  }
}
