import {
    Component,
    OnDestroy,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { EventFarmService } from '../../../eventFarm.service';
import { AccountInfoService } from '../account-info.service';

import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import {dateTimeFormat, momentShortDateTime, timePickerDefaults} from '../../../../Shared/datetime-options';

@Component({
    selector: 'request-efx',
    template: require('./request-efx.html'),
    styles: [require('./request-efx.scss')],
})

export class RequestEFxComponent implements AfterViewInit, OnInit, OnDestroy {

    private currentCsm: BehaviorSubject<{}> = new BehaviorSubject({name: 'Select One...', required: true});
    private error: BehaviorSubject<boolean> = new BehaviorSubject(false);
    readonly successIcon = require('./../assets/green-success-check');
    private eventTypes;
    private dateTimeFormat = dateTimeFormat;

    //TODO This should be moved to a config file or environment variable
    slackOptions = new BehaviorSubject<any>([
        { name: 'Gizzella Diaz', slackId: 'ULDC3RX7W' },
    ]);

    private efxTimeOptions = {
        altInput: true,
        altFormat: 'j F, Y',
        altInputClass: 'w-2/3 p-2',
        enableTime: false,
        minDate: new Date(),
        defaultDate: new Date(),
    };

    constructor(
        private accountUsageService: AccountInfoService,
        private eventFarmService: EventFarmService,
        public modalService: ModalService,
        private formBuilder: FormBuilder,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
    ) {

        this.eventTypes = this.apiClient.getTypeFactory().Event().CIOMarketingEventType();

    }

    private efxRequestForm = new FormGroup({
        eventSize: new FormControl(null, Validators.required),
        primaryUsage: new FormControl(null, Validators.required),
        date: new FormControl(null, Validators.required),
    });

    ngOnInit() {
        this.accountUsageService.sumbittingStatus$.next({text: 'Send Request', status: 'not-sent'});
        const date = new Date();
        this.efxRequestForm.patchValue({
            date: date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate(),
        });
    }

    ngAfterViewInit() {
    }

    sendUpgradeRequest() {

        let submittingStatus = this.accountUsageService.sumbittingStatus$.value;
        let options = 'EFx request: \n  ';

        if (submittingStatus.status === 'sent') {
            return this.modalService.closeModal();
        }

        Object.keys(this.efxRequestForm.value).forEach(i => {
            const name = i.replace(/([A-Z])/g, function (g) { return ' ' + g[0].toLowerCase() });
            if (this.efxRequestForm.value[i] && this.efxRequestForm.value[i].name) {
                options += ('\n' + name + ': ' + this.efxRequestForm.value[i].name);
            } else {
                options += ('\n' + name + ': ' + this.efxRequestForm.value[i]);
            }
        });

        this.accountUsageService.requestUpgrade(this.slackOptions.value[0].slackId, [], options);
    }

    ngOnDestroy() {
        this.currentCsm.unsubscribe();
        this.error.unsubscribe();
    }
}
