import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IconModule} from '@ant-design/icons-angular';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgxIntlTelInputModule} from "@khazii/ngx-intl-tel-input";
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {NzInputModule} from 'ng-zorro-antd/input';
import {SegmentService} from '../../../Analytics/Segment/segment.service';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';

import {UtilitiesModule} from '../../../Utilities/utilities.module';
import {EfModalService} from "../../eventFarmModal.service";
import {EventAccessService} from "../../EventsModule/EventAccess/event-access.service";
import {BreadcrumbsModule} from '../Breadcrumbs/breadcrumbs.module';
import {DebounceSearchModule} from "../DebounceSearch/debounce-search.module";
import {ClickStopPropagationDirective} from "../Directives/click-stop-propogation";
import {ErrorModule} from '../Error/error.module';
import {DynamicFormModule} from '../Forms/dynamic-form.module';
import {IconService} from '../Icons/icons.service';
import {IntlPhoneInputModule} from "../IntlPhoneInput/intl-phone-input.module";
import {InvitationOptionsModule} from '../InvitationOptions/invitationOptions.module';
import {LoadingOverlayModule} from '../LoadingOverlay/loading-overlay.module';
import {ModalModule} from '../Modal/modal.module';
import {UserFormModule} from '../UserForm/userForm.module';
import {EditInvitationComponent} from './EditInvitation/edit-invitation.component';
import {EditInvitationService} from './EditInvitation/edit-invitation.service';
import {GuestListUserService} from './guest-list-user.service';
import {GuestListViewComponent} from './guest-list-view.component';
import {GuestListViewService} from './guest-list-view.service';
import {GuestActivityLogModule} from './GuestActivity/guest-activity-log.module';
import {GuestListActionsComponent} from './GuestListActionsMenu/guest-list-actions.component';
import {GuestListAppliedFiltersComponent} from './GuestListAppliedFilters/guest-list-applied-filters.component';
import {GuestListBulkUpdateFormComponent} from './GuestListBulkUpdateForm/guest-list-bulk-update-form.component';
import {GuestListBulkUpdateFormService} from './GuestListBulkUpdateForm/guest-list-bulk-update-form.service';
import {GuestListFiltersComponent} from './GuestListFilters/guest-list-filters.component';
import {GuestListFiltersService} from './GuestListFilters/guest-list-filters.service';
import {
    GuestListFiltersAddFilterComponent
} from './GuestListFilters/GuestListFiltersAddFilter/guest-list-filters-add-filter.component';
import {
    GuestListInvitationListItemComponent
} from './GuestListInvitationListItem/guest-list-invitation-list-item.component';
import {GuestListResultCountComponent} from './GuestListResultCount/guest-list-result-count.component';
import {GuestListSearchComponent} from './GuestListSearch/guest-list-search.component';
import {GuestListSortComponent} from './GuestListSort/guest-list-sort.component';
import {GuestListStatsComponent} from "./GuestListStats/guest-list-stats.component";

import {GuestListTableComponent} from './GuestListTable/guest-list-table.component';
import {GuestListTransfersComponent} from './GuestListTransfers/guest-list-transfers.component';
import {GuestListTransfersService} from './GuestListTransfers/guest-list-transfers.service';
import {GuestResponsesComponent} from './GuestResponses/guest-responses.component';
import {GuestResponsesService} from './GuestResponses/guest-responses.service';
import {InviteEditGuestService} from './invite-edit-guest.service';
import {GuestRelatedInvitationsLogModule} from './RelatedGuestInvitations/guest-related-invitations-log.module';

import {ResendInvitationsComponent} from './resend-invitations.component';
import { PictureCropperComponent } from '../../EventsModule/_Shared/PictureCropper/picture-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';

const forExport = [
    GuestListInvitationListItemComponent,
    ResendInvitationsComponent,
    GuestResponsesComponent,
    GuestListViewComponent,
    GuestListTransfersComponent,
    EditInvitationComponent,
    GuestListTableComponent,
    GuestListFiltersComponent,
    GuestListBulkUpdateFormComponent,
    GuestListFiltersAddFilterComponent,
    GuestListActionsComponent,
    GuestListAppliedFiltersComponent,
    GuestListSearchComponent,
    GuestListResultCountComponent,
    GuestListSortComponent,
    PictureCropperComponent,
    GuestListStatsComponent
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    entryComponents: [
        ResendInvitationsComponent,
        GuestResponsesComponent,
        GuestListTransfersComponent,
        EditInvitationComponent,
        GuestListTableComponent,
        GuestListBulkUpdateFormComponent,
        GuestListActionsComponent,
        GuestListAppliedFiltersComponent,
        GuestListSearchComponent,
        GuestListResultCountComponent,
        GuestListSortComponent,

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ModalModule,
        DynamicFormModule,
        UtilitiesModule,
        InvitationOptionsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        UserFormModule,
        NzUtilitiesModule,
        NzInputModule,
        IconModule,
        GuestActivityLogModule,
        GuestRelatedInvitationsLogModule,
        NgxUtilitiesModule,
        IntlPhoneInputModule,
        NgxIntlTelInputModule,
        FontAwesomeModule,
        ClickStopPropagationDirective,
        DebounceSearchModule,
        ImageCropperModule
    ],
    providers: [
        GuestListViewService,
        GuestResponsesService,
        InviteEditGuestService,
        GuestListUserService,
        GuestListTransfersService,
        IconService,
        NzDrawerService,
        EditInvitationService,
        SegmentService,
        GuestListFiltersService,
        GuestListBulkUpdateFormService,
        EventAccessService,
        EfModalService
    ]
})

export class GuestListViewModule {
}
