import { Component, Input, OnInit } from '@angular/core';
import { SalesforceEventIntegrationsService } from '../eventIntegrations.service';
import { AlertService } from '../../../../eventFarmAlert.service';

@Component({
    selector: 'invitation-settings',
    template: require('./invitationSettings.html'),
    styles: [require('./invitationSettings.scss')],
})

export class InvitationSettingsComponent implements OnInit {

    private invitationCreationTypes: Object;

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private alertService: AlertService,
    ) {
    }

    @Input() eventSettings;
    creationType = this._sfeis.eventSettings.creationMethod;
    invitationCount = this._sfeis.eventSettings.salesforceConfig.attributes.invitationCount;

    ngOnInit() {
        this.invitationCreationTypes = this._sfeis.getInvitationCreationTypes();
    }

    changeInvitationCreationType(type) {
        this._sfeis.eventSettings.isSyncing = true;
        this._sfeis.setInvitationCreationTypeForSalesforceEventSetting(type)
            .then((res) => {
                this._sfeis.eventSettings.creationMethod = type;
                this._sfeis.syncingFeedback();
            });
    }

    changeStackMode(useExistingStack) {
        this._sfeis.setStackMode(
            this._sfeis.eventSettings.salesforceConfig.id,
            useExistingStack,
        );
    }

    changeStackId(stackId) {
        this._sfeis.eventSettings.isSyncing = true;

        this._sfeis.setStack(
            this._sfeis.eventSettings.salesforceConfig.id,
            stackId,
        )
            .then((res) => {
                this._sfeis.syncingFeedback();
            });
    }

    setInvitationCount() {
        if (this.invitationCount <= 0 || this.invitationCount > 99) {
            this.invitationCount = 1;
        }

        const count = this.invitationCount;

        this._sfeis.eventSettings.isSyncing = true;
        this._sfeis.setInvitationCountForSalesforceEventSetting(count)
            .then((res) => {
                this._sfeis.eventSettings.salesforceConfig.attributes.invitationCount = count;
                this._sfeis.syncingFeedback();
            })
            .catch((error) => {
                this.invitationCount = this._sfeis.eventSettings.salesforceConfig.attributes.invitationCount;

                let message = 'Invalid InvitationCount';

                if (error.response && error.response.status === 400 && error.response.data.errors[0]) {
                    message = error.response.data.errors[0].detail;
                }

                this._sfeis.syncingFeedback();

                this.alertService.salesforceIntegration().invalidInvitationCount(message);
            });
    }

    incrementInvitationCount() {
        this.invitationCount += 1;
        this.setInvitationCount();
    }

    decrementInvitationCount() {
        this.invitationCount -= 1;
        this.setInvitationCount();
    }
}
