import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmDeactivateGuard } from './email-design-center-guard.service';

// module root component
import { EmailDesignCenterComponent } from './email-design-center.component';

// child components
import { CreateEditEmailComponent } from './CreateEditEmail/create-edit-email.component';
import { GuestMessagingComponent } from './GuestMessaging/guest-messaging.component';
import { ScheduledCreateComponent } from './Scheduled/Create/create.component';
import { ScheduledComponent } from './Scheduled/scheduled.component';
import { SelectDesignTemplateComponent } from './SelectDesignTemplate/select-design-template.component';

const routes: Routes = [
    {
        path: '',
        component: EmailDesignCenterComponent,
        children: [
            {
                path: '',
                component: SelectDesignTemplateComponent,
            },
            {
                path: 'create',
                component: CreateEditEmailComponent,
                canDeactivate: [ConfirmDeactivateGuard],
            },
            {
                path: 'create/:id',
                component: CreateEditEmailComponent,
                canDeactivate: [ConfirmDeactivateGuard],
            },
            {
                path: 'edit/:id',
                component: CreateEditEmailComponent,
                canDeactivate: [ConfirmDeactivateGuard],
            },
            {
                path: 'guest-messaging',
                component: GuestMessagingComponent,
            },
            {
                path: 'scheduled',
                component: ScheduledComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EmailDesignCenterRoutingModule { }
