import { AbstractModel } from '../abstract-model';

export class UserIdentifier extends AbstractModel {
    private _isPrimary: boolean;
    private _isEmail: boolean;
    private _identifier: string;

    public static fromApiResponse(data) {
        const userIdentifier = new this(data.id);

        if (data.meta.isInitialized) {
            userIdentifier._identifier = data.attributes.identifier;
            userIdentifier._isPrimary = data.attributes.isPrimary;
            userIdentifier._isEmail = data.attributes.type.isEmail;
        }

        return userIdentifier;
    }

    get identifier(): string {
        return this._identifier;
    }

    get isPrimary(): boolean {
        return this._isPrimary;
    }

    get isEmail(): boolean {
        return this._isEmail;
    }

    set identifier(value: string) {
        this._identifier = value;
    }
}
