import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { shareReplay } from 'rxjs/operators';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Track } from '../../../../../../ApiClient/Models/Track/track';
// import { EditTracksComponent } from '../EditTracks/edit-tracks.component';
import { ModalService } from '../../../../../CoreUI/Modal/modal.service';
import { AlertService } from '../../../../../eventFarmAlert.service';
import { EventAgendaService } from '../../../event-agenda.service';
import { EditTracksComponent } from '../EditTracks/edit-tracks.component';

@Component({
    selector: '[tracks-item]',
    template: require('./tracks-item.html'),
    styles: [require('./tracks-item.scss')],
})

export class TracksItemComponent {
    @Input() trackItem: Track;
    public componentClicked: boolean = false;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private store: Store<fromRoot.AppState>,
        private drawerService: NzDrawerService,
        private modalService: ModalService,
        private alertService: AlertService,
        private eventAgendaService: EventAgendaService
    ) { }

    openComponent(): void {
        if (!this.componentClicked) {
            this.componentClicked = true;
            const drawerRef = this.drawerService.create<EditTracksComponent, { track: Track }, string>({
                nzTitle: 'Edit Track',
                nzContent: EditTracksComponent,
                nzWidth: '50%',
                nzContentParams: {
                    track: this.trackItem,
                }
            });
        
            drawerRef.afterOpen.subscribe(() => {
            });
        
            drawerRef.afterClose.subscribe(data => {
                this.componentClicked = false;
            });
        }
    }

    public async delete() {
        this.alertService.general().confirmTableDelete(this.trackItem.name)
            .then((res) => {
                if (res.value) {
                    this.eventAgendaService.removeTrack(this.trackItem.id).then((response) => {
                        this.eventAgendaService.getAllTracks();
                    });

                }
            });
    }
}
