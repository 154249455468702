import * as eventActions from '../actions/event.action';
import * as waitListActions from '../actions/event/wait-list.action';
import * as guestListActions from '../actions/event/guest-list.action';
import * as accessTypesActions from '../actions/event/event-access.action';
import * as designsActions from '../actions/event/designs.action';
import * as sitePagesActions from '../actions/event/site-pages.action';

import * as designsHandler from './event/designs.handler';
import * as accessTypesHandler from './event/access-types.handler';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { SalesforceIntegration } from '../../../ApiClient/Models/Integration/salesforce-integration';

import { TicketType } from '../../../Shared/ticket-type';
import { Stack } from './../../../ApiClient/Models/Stack/stack';
import { IEfDataState, DefaultEfDataState } from './interfaces';
import { SitePage } from '../../../ApiClient/Models/SitePage/site-page';

export interface EventState {
    data: EfEvent | null;
    access: object;
    salesforceIntegration: SalesforceIntegration | null;
    invitations: object;
    guestlist: object;
    ticketTypes: IEfDataState<TicketType>;
    stacks: IEfDataState<Stack>;
    designs: IEfDataState<Stack>;
    sitePages: IEfDataState<SitePage>;
    loaded: boolean;
    loading: boolean;
}

export const initialEventState = {
    data: null,
    access: {},
    salesforceIntegration: new SalesforceIntegration(),
    invitations: null,
    guestlist: null,
    ticketTypes: DefaultEfDataState,
    stacks: DefaultEfDataState,
    designs: DefaultEfDataState,
    sitePages: DefaultEfDataState,
    loaded: false,
    loading: false,
};

export function reducer(
    state = initialEventState,
    action: eventActions.EventAction |
    waitListActions.EventAction |
    guestListActions.EventAction |
    accessTypesActions.EventAccessAction |
    designsActions.EventAction |
    sitePagesActions.EventAction
): EventState {

    switch (action.type) {

        case eventActions.SET_CURRENT_EVENT:
            return {
                ...state,
                loading: true
            };

        case eventActions.SET_CURRENT_EVENT_SUCCESS: {
            const data = action.payload.data;
            return {
                ...state,
                data,
                loading: false,
                loaded: true
            };
        }

        case eventActions.FETCH_CURRENT_EVENT:
            return {
                ...state,
                loading: true
            };

        case eventActions.FETCH_CURRENT_EVENT_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                data,
                loading: false,
                loaded: true
            };
        }

        case eventActions.RESET_CURRENT_EVENT: {
            return Object.assign({}, initialEventState);
        }

        case eventActions.FETCH_SALESFORCE_INTEGRATION_FOR_EVENT_SUCCESS: {
            const salesforceIntegration = new SalesforceIntegration();
            salesforceIntegration.salesforceStatus = action.payload.data;
            return {
                ...state,
                salesforceIntegration,
                loading: false,
                loaded: true
            };
        }

        case eventActions.FETCH_USER_ROLES_FOR_CURRENT_EVENT_SUCCESS: {
            const access = action.payload.data;
            return {
                ...state,
                access,
                loading: false,
                loaded: true
            };
        }

        case guestListActions.FETCH_GUEST_LIST: {
            return {
                ...state,
                guestlist: {}
            };
        }

        case guestListActions.FETCH_GUEST_LIST_SUCCESS: {
            const meta = action.payload.meta;
            const data = action.payload.data.reduce((guestList, invitation) => {
                return {
                    ...guestList,
                    [invitation.id]: invitation
                }
            }, {});
            return {
                ...state,
                guestlist: {
                    data: {...state.guestlist.data, ...data},
                    meta
                }
            };
        }

        case waitListActions.FETCH_WAIT_LIST: {
            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    loading: true,
                    loaded: false
                },
            };
        }

        case waitListActions.SET_WAIT_LIST_SUCCESS:
            const data = action.payload.data.reduce((entities, invitation) => {
                return {
                    ...entities,
                    [invitation.id]: invitation
                };
            }, {});
            return {
                ...state,
                invitations: {
                    waitlist: {
                        data,
                        meta: action.payload.meta,
                    },
                    loading: false,
                    loaded: true
                },
            };

        case waitListActions.REMOVE_INVITATION_FROM_WAITLIST:
            return {
                ...state,
            };

        case waitListActions.REMOVE_INVITATION_FROM_WAITLIST_SUCCESS: {
            const invitations = state.invitations.waitlist.data;
            const { [action.payload]: value, ...updatedInvitations } = invitations;
            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    waitlist: {
                        ...state.invitations.waitlist,
                        data: updatedInvitations
                    },
                }
            };
        }

        case accessTypesActions.FETCH_ACCESS_TYPES_SUCCESS: {
            const typesObj = action.payload.ticketTypes.reduce((acc, type) => {
                return {
                    ...acc,
                    [type.id]: type,
                };
            }, {});
            return {
                ...state,
                data: Object.assign(state.data || {}, action.payload),
                ticketTypes: {
                    loaded: true,
                    loading: false,
                    data: typesObj
                }
            };
        }

        case sitePagesActions.FETCH_SITE_PAGES_SUCCESS: {
            const meta = action.payload.meta;
            const sitePagesObj = action.payload.data.reduce((acc, sitePage) => {
                return {
                    ...acc,
                    [sitePage.sitePageId]: sitePage,
                };
            }, {});

            return {
                    ...state,
                    sitePages: {
                        meta,
                        data: sitePagesObj,
                        loaded: true,
                        loading: false
                    }

                };
            }

        case accessTypesActions.FETCH_STACKS_SUCCESS: {
            const stacksObj = action.payload.stacks.reduce((acc, stack) => {
                return {
                    ...acc,
                    [stack.id]: stack,
                };
            }, {});
            return {
                ...state,
                stacks: {
                    loaded: true,
                    loading: false,
                    data: stacksObj
                }
            };
        }

        case designsActions.FETCH_DESIGNS: {
            return designsHandler.handleFetchDesigns(state, action);
        }
        case designsActions.FETCH_DESIGNS_SUCCESS: {
            return designsHandler.handleFetchDesignsSuccess(state, action);
        }

        case designsActions.SET_DESIGNS: {
            return designsHandler.handleSetDesign(state, action);
        }
        case designsActions.SET_DESIGNS_SUCCESS: {
            return designsHandler.handleSetDesignSuccess(state, action);
        }

        case accessTypesActions.CREATE_STACK_SUCCESS: {
            return accessTypesHandler.handleCreateStackSuccess(state, action);
        }

        case accessTypesActions.UPDATE_STACK_SUCCESS: {
            return accessTypesHandler.handleUpdateStackSuccess(state, action);
        }

        case accessTypesActions.DELETE_STACK_SUCCESS: {
            return accessTypesHandler.handleDeleteStackSuccess(state, action);
        }

        case accessTypesActions.DELETE_TICKET_TYPE_SUCCESS: {
            return accessTypesHandler.handleDeleteTicketTypeSuccess(state, action);
        }
    }
    return state;
}
