import { AbstractBaseAlert } from '../base-alert';

export class GeneralAlert extends AbstractBaseAlert {
  authExpired(): Promise<any> {
    return this.alert({
      title: 'Session Has Expired',
      text: 'Your Event Farm session is no longer active. Please re-authenticate to continue.',
      showCancelButton: true,
      confirmButtonText: 'Re-authenticate',
      icon: 'warning',
    });
  }

  requestAccessToFeature(): Promise<any> {
    return this.alert({
      title: 'Request Access',
      text: 'Would you like to request early access to this feature?',
      showCancelButton: true,
      confirmButtonText: 'Request',
      icon: 'question',
    });
  }

  confirmArchiveEvent(): Promise<any> {
    return this.alert({
      title: 'Archive Event?',
      text: 'Are you sure you want to archive this event? It will be hidden in your default event listings, but can be unarchived later.',
      showCancelButton: true,
      confirmButtonText: 'Archive Event',
      icon: 'question',
    });
  }

  donationMessageRequired(): Promise<any> {
    return this.alert({
      title: 'Message required',
      text: 'A message is required',
      icon: 'error'
    });
  }

  donationAmountRequried(): Promise<any> {
    return this.alert({
      title: 'Amount required',
      text: 'At least one amount is required',
      icon: 'error'
    });
  }

  efxModuleRequest(moduleName: string): Promise<any> {
    return this.alert({
      title: 'Sweet!',
      html: `Your Account Manager will be in touch soon to get you started with <b>${moduleName}</b>`,
      icon: 'success',
    });
  }

  efxRequest(): Promise<any> {
    return this.alert({
      title: 'Sweet!',
      html: `Your Account Manager will be in touch soon to get you started with EFx.`,
      icon: 'success',
    });
  }

  confirmTableDelete(name): Promise<any> {
    return this.alert({
      title: `You are attempting to delete ${name}`,
      text: 'Confirm below if you would like to continue',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      icon: 'error',
    });
  }
}
