import { tap, take, withLatestFrom, switchMap, filter, map, mergeMap, concatMap, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {SitePage} from '../../../../ApiClient/Models/SitePage/site-page';
import * as eventActions from '../../actions/event';
import * as fromStore from '../../app.state';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

@Injectable()
export class SitePagesEffects {
    constructor(
        private actions$: Actions,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
    ) { }


    fetchSitePages$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchDesigns>(eventActions.FETCH_SITE_PAGES),
        withLatestFrom(this.store.select('router')),
        map(([res, router]) => router),
        filter((router) => router.state.params.event),
        map((router) => router.state.params.event),
        switchMap((event) => this.apiClient.getUseCaseFactory().SitePage().ListSitePagesForEvent(event, 1, 100)),
        map((sitePages: any) => {
            const data = sitePages.data.map(sp => {
                return SitePage.fromApiResponse({...sp});
            });
            return {
                meta: sitePages.meta,
                data
            };
        }),
        switchMap((sitePages) => [
            new eventActions.FetchSitePagesSuccess(sitePages),
        ]),
    ));

}
