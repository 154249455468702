import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { Stack } from './stack';

export class TicketBlockAllotment extends AbstractModel {

    private _quantity?: number;
    private _isDeleted?: boolean;
    private _extraAttributes?: any;
    private _allotments?: any[];
    private _event?: EfEvent;
    private _stack?: Stack;

    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const tbAllotment = new this(data.id);

        tbAllotment._isDeleted = data.attributes.isDeleted;

        if (data.relationships && data.relationships.event) {
            tbAllotment._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        if (data.relationships && data.relationships.stack) {
            tbAllotment._stack = Stack.fromApiResponse(data.relationships.stack);
        }

        return tbAllotment;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    get event(): EfEvent {
        return this._event;
    }

    get stack(): Stack {
        return this._stack;
    }
}
