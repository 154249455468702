import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { map, tap, switchMap, take, concatMap, shareReplay } from 'rxjs/operators';
import * as RouterActions from '../actions';

@Injectable()
export class RouterEffects {

    // @ts-ignore
    navigate$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActions.GO),
        map((action: RouterActions.Go) => {
            return action.payload;
        }),
        tap(({ path, query: queryParams, extras }) =>
            this.router.navigate(path, { queryParams, ...extras })
        )
    ), {dispatch: false});


    // @ts-ignore
    navigateBack$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActions.BACK),
        tap(() => this.location.back())
    ), {dispatch: false});

    // @ts-ignore
    navigateForward$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActions.FORWARD),
        tap(() => this.location.forward())
    ), {dispatch: false});


    // @ts-ignore
    getCurrentUrl$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActions.GET_CURRENT_URL),
        concatMap(() => [
            this.location.path(),
        ]),
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) { }
}
