import { Injectable } from '@angular/core';
import { Operator } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class UploaderTabsService {

    constructor(
        private router: Router,
    ) {
    }

    private _tabs: UploaderTab[] = [
        {
            id: 0,
            name: 'Choose File',
            active: true,
            error: false,
        },
        {
            id: 1,
            name: 'Preview Upload',
            active: false,
            error: false,
        },
        {
            id: 2,
            name: 'Finish',
            active: false,
            error: false,
        },
    ];

    changeTabName(forTabsWithId: number, newNameForMatchingTabs: string) {
        const matchingTabs = this._tabs.filter((tab) => {
            return forTabsWithId === tab.id;
        });
        matchingTabs.forEach((matchingTab) => {
            matchingTab.name = newNameForMatchingTabs;
            this._tabs.forEach((tab) => {
                if (matchingTab.id === tab.id) {
                    return tab.name = matchingTab.name;
                }
            });
        });
    }

    setNextAsActive() {
        let shifted: boolean = false;
        this._tabs.forEach((tab) => {
            if (!shifted && tab.active === true && this._tabs[tab.id + 1]) {
                this._tabs[tab.id + 1].active = true;
                tab.active = false;
                shifted = true;
            }
        });
    }

    setPreviousAsActive() {
        let shifted: boolean = false;
        this._tabs.forEach((tab) => {
            if (!shifted && tab.active === true && this._tabs[tab.id - 1]) {
                this._tabs[tab.id - 1].active = true;
                tab.active = false;
                shifted = true;
            }
        });
    }

    setCurrentAsError() {
        this._tabs.forEach((tab) => {
            if (tab.active === true && tab.error === false) {
                tab.error = true;
            }
        });
    }

    unsetCurrentAsError() {
        this._tabs.forEach((tab) => {
            if (tab.active === true && tab.error === true) {
                tab.error = false;
            }
        });
    }

    reset() {
        this.changeTabName(1, 'Preview Upload');
        this.changeTabName(2, 'Finish');
        this._tabs.forEach((tab) => {
            tab.active = false;
            tab.error = false;
            if (tab.id === 0) {
                tab.active = true;
                tab.error = false;
            }
        });
    }

    public get tabs() {
        return this._tabs;
    }
}

export interface UploaderTab {
    id: number;
    name: string;
    active: boolean;
    error: boolean;
}
