import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailCommunicationComponent } from './email-communication.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { EmailCommunicationRoutingModule } from './email-communication.routing.module';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';

@NgModule({
    bootstrap: [EmailCommunicationComponent],
    declarations: [
        EmailCommunicationComponent,
    ],
    entryComponents: [
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BreadcrumbsModule,
        EmailCommunicationRoutingModule,
        UtilitiesModule,
        NzUtilitiesModule
    ],
})

export class EmailCommunicationModule {
}
