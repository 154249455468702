import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentEventsListTracking extends AbstractBaseSegment {

    useListView() {
        return this.track({
            event: actions.SWITCH_TO_LIST_VIEW
        });
    }

    useTileView() {
        return this.track({
            event: actions.SWITCH_TO_TILE_VIEW
        });
    }
}
