import {Component, Input} from '@angular/core';
import {ModalService} from '../../../modal.service';
import {EventFarmService} from '../../../../../eventFarm.service';
import {CioGetStartedStepperService} from '../CioGetStartedStepper/cio-get-started-stepper.service';

@Component({
    selector: 'cio-get-started-welcome',
    styles: [require('./cio-get-started-welcome.scss')],
    template: require('./cio-get-started-welcome.html')
})

export class CioGetStartedWelcomeComponent {
    @Input() subHeader?: string = '';
    @Input() mainText?: string = '';
    @Input() list?: string = '';
    @Input() flowType?: 'cio' | 'cioPlusClear' ;

    constructor(
        public modalService: ModalService,
        private eventFarmService: EventFarmService,
        private cioGetStartedStepperService: CioGetStartedStepperService
    ) {
    }
    cta() {
        if (this.flowType === 'cioPlusClear') {
            this.cioGetStartedStepperService.launchCioPlusClearModal();
        } else {
            this.cioGetStartedStepperService.launchCioOnlyModal();
        }
    }
}
