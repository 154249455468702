import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'checkbox',
    template: require('./checkbox.html'),
    styles: [require('./checkbox.scss')],
})

export class CheckboxComponent {
    @Input() checked;
    @Input() disabled;
    @Output() checkboxClicked: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    clickCheckbox() {
        this.checkboxClicked.emit();
    }
}