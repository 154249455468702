import { AbstractModel } from '../abstract-model';

interface FormField {
    auth_mode_type: string;
    credentials: [{[key: string]: any}];
    name: string;
}

export class PaymentGateway extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _companyName: string;
    private _companyUrl: string;
    private _stringId: string;
    private _formFields: FormField[];
    private _gatewayType: string;
    private _name: string;
    private _sortOrder: number;
    private _companyLogo: string;

    public static fromApiResponse(data) {
        const paymentGateway = new this(data.id);
        paymentGateway._companyName = data.companyName || null;
        paymentGateway._companyUrl = data.companyUrl || null;
        paymentGateway._stringId = data.stringId || null;
        paymentGateway._formFields = data.formFields || null;
        paymentGateway._gatewayType = data.gatewayType || null;
        paymentGateway._name = data.name || null;
        paymentGateway._sortOrder = data.sortOrder;
        paymentGateway._companyLogo = data.logoUrl ?? data.companyLogo;

        return paymentGateway;
    }


    get companyName(): string {
        return this._companyName;
    }

    get companyUrl(): string {
        return this._companyUrl;
    }

    get stringId(): string {
        return this._stringId;
    }

    get formFields(): FormField[] {
        return this._formFields;
    }

    get gatewayType(): string {
        return this._gatewayType;
    }

    get name(): string {
        return this._name;
    }

    get sortOrder(): number {
        return this._sortOrder;
    }

    get companyLogo(): string {
        return this._companyLogo;
    }

}
