import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from "lodash";
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { dateTimeFormat, momentShortDateTime, timePickerDefaults } from '../../../../Shared/datetime-options';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { EventFarmService } from '../../../eventFarm.service';
import * as fromRoot from '../../../store';

interface FormattedDate {
    month?: string;
    date?: string;
    day?: string;
    time?: string;
    period?: string;
}

@Component({
    template: require('./create-event-details.html'),
    styles: [require('./create-event-details.scss')],
    encapsulation: ViewEncapsulation.None
})

export class CreateFullEventComponent implements OnInit {

    public readonly endDateOffset: {amount: number, unit: moment.unitOfTime.Base} = {
        amount: 3,
        unit: 'hours'
    };

    public startDate: FormattedDate;
    public endDate: FormattedDate;

    public isCreatingEvent = false;
    public creatingEventStatus;
    private eventType;
    private eventCount;

    public dateTimeFormat = dateTimeFormat;
    public eventTimezone: string;

    public timePickerDefaults = timePickerDefaults;

    private get currentPoolId() {
        let id;
        this.store.select(fromRoot.getPoolParam).subscribe(res => id = res);
        return id;
    }

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private router: Router,
        private route: ActivatedRoute,
        private routeGenerator: RouteGeneratorService,
        private message: NzMessageService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService
    ) {

        setTimeout(() => {
            const eventName = document.getElementsByName('event-name')[0];
            if (document.activeElement !== eventName) {
                eventName.focus();
            }
       }, 1500);
        this.route.url.subscribe(res => this.eventType = res[0].path);

        this.apiClient.getUseCaseFactory().Event().ListEventsForUser(this.eventFarmService.currentUser.id).subscribe(res => {
            this.eventCount = res.data.length;
        });
    }

    // There is an issue where only changing the time in the date picker doesn't get reflected in the model, so we need to explicitly handle
    handleStartOk($event) {
        this.updateStartDate($event)
    }

    // There is an issue where only changing the time in the date picker doesn't get reflected in the model, so we need to explicitly handle
    handleEndOk($event: Date){
        this.updateEndDate($event)
    }
    public ngOnInit() {

        const date = moment().add(1, 'week').set({'hour': 18, 'minute': 0});
        const sDate = date.toDate();
        const eDate = date.add(this.endDateOffset.amount, this.endDateOffset.unit).toDate();

        this.startDate = this.formatDateDisplay(sDate);
        this.endDate = this.formatDateDisplay(eDate);

        this.createEventForm.get('eventStartDateTime').setValue(sDate);
        this.createEventForm.get('eventStartDateTime').valueChanges.subscribe(value => {
            this.updateStartDate(value);
        });

        this.createEventForm.get('eventEndDateTime').setValue(eDate);
        this.createEventForm.get('eventEndDateTime').valueChanges.subscribe(value => {
            this.updateEndDate(value);
        });

        this.updateValidEndDate();
    }

    public createEvent() {
        if (!this.createEventForm.get('eventName').value) {
            return this.message.info(`Don't forget to name your event`);
        }

        if (!this.createEventForm.get('eventTimeZone').value) {
            return this.message.info(`Don't forget to set a time zone`);
        }

        if (this.eventType === 'full-event') {
            return this.createFullEvent();
        }

        if (this.eventType === 'cio-event') {
            return this.createCIOEvent();
        }
    }

    private createFullEvent() {
        this.isCreatingEvent = true;
        this.creatingEventStatus = 'Setting up your event...';

        const createEventValues = {
            eventName: this.createEventForm.get('eventName').value,
            eventStartTime: this.getFormattedTimeFromForm('eventStartDateTime'),
            eventEndTime: this.getFormattedTimeFromForm('eventEndDateTime'),
            eventTimeZone: this.createEventForm.get('eventTimeZone').value
        };

        this.apiClient.getUseCaseFactory().Event().CreateEvent(
            this.eventFarmService.currentTeam.id,
            this.eventFarmService.currentUser.id,
            this.createEventForm.get('eventName').value,
            null,
            this.eventFarmService.currentUser.primaryEmail,
            this.getFormattedTimeFromForm('eventStartDateTime'),
            this.getFormattedTimeFromForm('eventEndDateTime'),
            this.createEventForm.get('eventTimeZone').value,
            null
        ).subscribe(
            (res) => {
                const segmentValues = {...createEventValues,
                    eventId: res.data.command.eventId
                };

                this.segmentService.segmentEventTracking().createFullEvent(segmentValues);

                this.store.dispatch(new fromRoot.FetchEventsForTeam());
                return this.eventCreateSuccess(res.data.command.eventId);
            },
            (err) => this.eventCreateFail(err)
        );
    }

    private getFormattedTimeFromForm(fieldname) {
        return moment(this.createEventForm.get(fieldname).value).format(momentShortDateTime);
    }

    private createCIOEvent() {
        this.isCreatingEvent = true;
        this.creatingEventStatus = 'Setting up your event...';

        const createEventValues = {
            eventName: this.createEventForm.get('eventName').value,
            eventStartTime: this.getFormattedTimeFromForm('eventStartDateTime'),
            eventEndTime:  this.getFormattedTimeFromForm('eventEndDateTime'),
            eventTimeZone: this.createEventForm.get('eventTimeZone').value
        };

        this.apiClient.getUseCaseFactory().Event().CreateCIOEvent(
            this.eventFarmService.currentTeam.id,
            this.eventFarmService.currentUser.id,
            this.createEventForm.get('eventName').value,
            this.getFormattedTimeFromForm('eventStartDateTime'),
            this.getFormattedTimeFromForm('eventEndDateTime'),
            this.createEventForm.get('eventTimeZone').value,
            null
        ).subscribe(
            (res) => {
                this.store.dispatch(new fromRoot.FetchEventsForTeam());

                const segmentValues = {...createEventValues,
                    eventId: res.data.command.eventId
                };

                this.segmentService.segmentEventTracking().createCIOEvent(segmentValues);

                return this.eventCreateSuccess(res.data.command.eventId, 'cio');
            },
            (err) => this.eventCreateFail(err)
        );
    }

    private eventCreateFail(err) {
        this.creatingEventStatus = _.get(err, 'error.errors[0].detail');
        setTimeout(() => {
            this.isCreatingEvent = false;
        }, 3000);
    }

    public createEventForm = new FormGroup({
        eventName: new FormControl(),
        eventStartDateTime: new FormControl(),
        eventEndDateTime: new FormControl(),
        eventTimeZone: new FormControl(this.defaultTimeZone),
    });

    private get defaultTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    private updateEventDate(type: string, dateFromPicker: Date) {
        if (type === 'start') {
            this.startDate = this.formatDateDisplay(dateFromPicker);
        } else {
            this.endDate = this.formatDateDisplay(dateFromPicker);
        }
    }

    private updateValidEndDate() {
        const start = this.createEventForm.get('eventStartDateTime').value;
        const end = this.createEventForm.get('eventEndDateTime').value;

        if (start > end) {
            this.createEventForm.get('eventEndDateTime').setValue(new Date(Date.parse(start) + 10800000));
        }
    }

    private updateStartDate(value: Date) {
         this.updateEventDate('start', value);
         this.updateValidEndDate();
    }

    private updateEndDate(value: Date) {
        this.updateEventDate('end', value);
    }

    public getDisabledEndDate() {
        return (current: Date) => {
            const start = this.createEventForm?.get('eventStartDateTime')?.value as Date;
            if (current == null || start == null) {
                return false;
            }

            const c = moment(current);
            const s = moment(start);
            return c.isBefore(s, 'day');
        };
    }

    private formatDateDisplay(date) {
        const formattedDate: FormattedDate = {};
        formattedDate.month = moment(date).format('MMM');
        formattedDate.date = moment(date).format('DD');
        formattedDate.day = moment(date).format('ddd');
        formattedDate.time = moment(date).format('h:mm');
        formattedDate.period = moment(date).format('a');
        return formattedDate;
    }

    eventCreateSuccess(eventId, eventType = 'full') {
        this.creatingEventStatus = 'Success! Taking you to your event!';
        this.router.navigate([this.routeGenerator.url('events.info', {poolId: this.currentPoolId, eventId})], {queryParams: {'new': this.eventCount > 1 && eventType === 'full' ? true : null }, queryParamsHandling: 'merge'})
            .then()
            .catch();
    }

    public goBack() {
        this.eventFarmService.goBack();
    }
}
