import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { EventFarmService } from '../../eventFarm.service';
import { TicketBlockDashboardService } from './ticketBlockDashboard.service';
import { IconService } from '../../CoreUI/Icons/icons.service';
@Component({
    selector: 'ticket-block-invitation-list',
    providers: [
        ModalService,
    ],
    template: require('./ticket-block-invitation-list.html'),
    styles: [require('./ticket-block-invitation-list.scss')],
})

export class TicketBlockInvitationListComponent {

    constructor(
        private eventFarmService: EventFarmService,
        private ticketBlockDashboardService: TicketBlockDashboardService,
        private iconService: IconService
    ) {
    }
}
