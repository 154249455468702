import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { AddressBookService } from './addressBook.service';
import { Router } from '@angular/router';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../store';
import { SegmentService } from '../../../Analytics/Segment/segment.service';

@Component({
    selector: 'address-book',
    template: require('./address-book.html'),
    styles: [require('./address-book.scss')],
})

export class AddressBookComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private addressBookService: AddressBookService,
        private modalService: ModalService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService
    ) {
        this.modalClose$ = this.modalService.closeModal$.subscribe((res) => {
            this.router.navigateByUrl(this.routeGenerator.url('pools.address-book.list', { poolId: this.eventFarmService.currentTeam.id }));
        });
    }

    modalClose$: Subscription;

    ngOnInit() {
        this.store.dispatch(new fromRoot.ResetCurrentEvent());
        this.addressBookService.userHasRestrictedAccess = false;
        this.addressBookService.currentTeam = this.eventFarmService.currentTeam;
        this.addressBookService.selectedUser = null;
        this.addressBookService.selectedGroup = null;
        this.segmentService.segmentSideNavTracking().addressBookEntered();
    }

    ngOnDestroy() {
        this.modalClose$.unsubscribe();
    }
}
