import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TicketBlockUsersComponent } from './ticket-block-users.component';
import { FormsModule } from '@angular/forms';
import { Userv2TypeaheadModule } from '../Typeahead/userv2-typeahead.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { TicketBlockUsersService } from './ticket-block-users.service';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [TicketBlockUsersComponent],
    exports: [TicketBlockUsersComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        Userv2TypeaheadModule,
        NzUtilitiesModule,
        NgxUtilitiesModule
    ],
    providers: [
        TicketBlockUsersService,
    ]
})

export class TicketBlockUsersModule {
}
