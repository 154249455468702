import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from './../../manage.routing-module';
import { AdminService } from '../../../admin.service';
import { EventFarmService } from '../../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { LoaderGrayModule } from '../../../../CoreUI/LoaderGray/loader-gray.module';
import { TeamEmailMasksComponent } from './team-email-masks.component';
import { CommonModule } from '@angular/common';
import { AdminAlertModule } from '../../../Alert/admin-alert.module';

const forExport = [TeamEmailMasksComponent];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    LoaderGrayModule,
    CommonModule,
    AdminAlertModule,
  ],
  providers: [AdminService, EventFarmService, NzDrawerService],
})
export class TeamEmailMasksModule {}
