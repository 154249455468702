import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GuestActivityLogService } from './guest-activity-log.service';
import * as fromRoot from '../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IconService } from '../../Icons/icons.service';

@Component({
    selector: 'guest-activity-log',
    providers: [
        GuestActivityLogService
    ],
    template: require('./guest-activity-log.html'),
    styles: [require('./guest-activity-log.scss')],
})

export class GuestActivityLogComponent implements OnInit, OnDestroy {


    constructor(
        private activityLogService: GuestActivityLogService,
        private store: Store<fromRoot.AppState>,
        private iconService: IconService
    ) {
    }
    
    @Input() invitationId: string;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {
        this.activityLogService.getActivityLogForInivtation(this.invitationId);
    }

    public changePage(page) {
        this.activityLogService.activityListOptions.pagination.currentListPage = page;
        this.activityLogService.getActivityLogForInivtation(this.invitationId);
    }

    ngOnDestroy() {
        this.activityLogService.clearList();
    }
}
