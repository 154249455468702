import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiError, ErrorInterface } from '../../../../../ApiClient/Models/error';
import { EventFarmService } from '../../../../eventFarm.service';
import { TicketBlockDashboardService } from '../../ticketBlockDashboard.service';
import { EFHelper } from '../../../../../Shared/helper';
import { InvitationOptionsService } from '../../../../CoreUI/InvitationOptions/invitationOptions.service';
import { AddInviteCountService } from '../../../../CoreUI/InvitationOptions/addInviteCount.service';
import { StackSelectionService } from '../../../../CoreUI/InvitationOptions/stackSelection.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { InvitationCreationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { cloneDeep, merge } from 'lodash';
import { RouteGeneratorService } from '../../../../../_services/routes/route-generator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { take, shareReplay } from 'rxjs/operators';
import { User } from '../../../../../ApiClient/Models/User/user';
import { Stack } from '../../../../../ApiClient/Models/Stack/stack';
import { isValidEmail } from '../../../../../Utilities/validateEmail';
import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from '@khazii/ngx-intl-tel-input';
@Component({
    selector: 'add-guest',
    styles: [require('../sharedInvite.scss'), require('./addGuestToTicketBlock.scss')],
    template: require('./addGuestToTicketBlock.html'),
})

export class AddGuestComponent implements OnInit {
    readonly inviteIndividualSVG = require('./../assets/InviteIndividual-Icon');
    private showGuestInfoWarning: boolean = true;
    private showAdditionalFields: boolean = false;
    private isGuestDetailsActive: boolean = true;
    private guestSalutation: string;
    private errors: ErrorInterface[];
    public ticketTypes: any = {};
    private invitation: Invitation;
    private creationType: string = 'send-email';
    private isSubmitting: boolean = false;

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private ticketBlockDashboardService: TicketBlockDashboardService,
        private invitationOptionsService: InvitationOptionsService,
        private addInviteCountService: AddInviteCountService,
        private stackSelectionService: StackSelectionService,
        private alertService: AlertService
    ) {
        this.guestSalutation = '';
    }

    public mTBStackToInviteCount = {};


    ngOnInit() {
        this.resetInvite();
        if (this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id) {
            this.invitation.eventId = this.eventFarmService.currentEvent.id;
            this.invitationOptionsService.stacks = this.ticketBlockDashboardService.stacks;
        }
        this.ticketBlockDashboardService.fetchSalutations();
        this.errors = [];

        this.ticketBlockDashboardService.stacks.forEach(s => {
            this.mTBStackToInviteCount[s.id] = 0;
        })
    }

    showFirstOrLastNameWarning(): boolean {
        return !this.invitation.firstName && !this.invitation.lastName;
    }

    shouldEmailBeDisabled(type: InvitationCreationTypeInterface): boolean {
        return type.shouldSendEmail && !this.invitation['email'];
    }

    get hasRequiredFields(): boolean {

        let userDetails = {
            email: this.invitation['email'],
            firstName: this.invitation['firstName'],
            lastName: this.invitation['lastName'],
        } as ICreateUser;

        if (this.isSessionTB) {
            if (!this.hasFirstAndLastName(userDetails)) {
                return false;
            }

            if (!Object.values(this.mTBStackToInviteCount).some(x => x > 0)) {
                return false;
            }

        } else {
            if (!this.invitationOptionsService.selectedStackId || !this.hasFirstAndLastName(userDetails)) {
                return false;
            }
        }

        // if someone puts an email, it better be valid
        if (userDetails.email.length && !isValidEmail(userDetails.email)) {
            return false;
        }

        return true;
    }

    private hasFirstAndLastName(userDetails: ICreateUser): boolean {
        return !EFHelper.isEmpty(userDetails.firstName) && !EFHelper.isEmpty(userDetails.lastName);
    }

    save() {
        if (this.isSubmitting) {
            return;
        }
        
        this.isSubmitting = true;

        if (EFHelper.isEmpty(this.invitation.email) && this.stackSelectionService.invitationCreationTypeSlug === 'send-email') {
            this.alertService.guestList().noEmailForInvitation();
            return false;
        }

        let formattedInvitation = cloneDeep(this.invitation);
        //Let's use e164Number value for telephone
        formattedInvitation.telephone = this.invitation.telephone ? this.invitation.telephone['e164Number'] : null;

        for (let key in formattedInvitation) {
            if (formattedInvitation.hasOwnProperty(key) && formattedInvitation[key] === '') {
                formattedInvitation[key] === null;
            }
        }

        formattedInvitation.isCheckedIn = this.invitationOptionsService.invitationOptions.isCheckedIn;
        formattedInvitation.checkInNotes = this.invitationOptionsService.invitationOptions.checkInNotes;
        formattedInvitation.invitationNotes = this.invitationOptionsService.invitationOptions.invitationNotes;

        if (this.isSessionTB) {
            this.saveMTB(formattedInvitation);
            return;
        }


        this.addInviteCountService.validateInviteCount(false);
        formattedInvitation.status = this.stackSelectionService.invitationStatus;
        formattedInvitation.shouldSendInvitation = this.stackSelectionService.invitationCreationMethod.shouldSendEmail;

        const arrivalAlerts = this.invitationOptionsService.getArrivalAlertForCreation();

        this.ticketBlockDashboardService.createInvitation(
            formattedInvitation,
            this.invitationOptionsService.selectedStackId,
            this.addInviteCountService.inviteCount,
        )
        .pipe(take(1)).subscribe(
            (res) => {
                if (this.invitationOptionsService.arrivalAlertOptions.shouldSendArrivalAlert) {
                    const invitationId = res.data.command.invitationId;
                    this.ticketBlockDashboardService.setArrivalAlertAndEmails(
                        invitationId,
                        arrivalAlerts.arrivalAlertEmails,
                        arrivalAlerts.shouldSendArrivalAlert,
                        arrivalAlerts.arrivalAlertMobileNumbers
                );
                }
                this.isSubmitting = false;
                let eventId = this.eventFarmService.currentEvent.id;
                let ticketBlockId = this.ticketBlockDashboardService.ticketBlock.id;
                this.ticketBlockDashboardService.gatherRelatedData();
                this.message.success('Invitation added successfully to your ticket block');
                const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.list', { eventId });
                this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + ticketBlockId);
            },
            (err) => {
                this.isSubmitting = false;
                this.errors = ApiError.fromApiErrorArray(err.error.errors);
                this.errors = this.errors.map((error) => {
                    if (error.detail === 'stackId (string) is required') {
                        error.detail = 'Please select an invitation type';
                    }
                    return error;
                });
            },
        );
    }

    private async saveMTB(formattedInvitation: Invitation) {

        if (this.creationType === 'send-email') {
            formattedInvitation.status = 'not-yet-registered';
            formattedInvitation.shouldSendInvitation = true;
        } else {
            formattedInvitation.status = this.creationType;
            formattedInvitation.shouldSendInvitation = false;
        }
        const entries = Object.entries(this.mTBStackToInviteCount);
        let shouldCreateParent = true;
        let shouldReturn = true;

        for(let i = 0; i < entries.length; i++) {
            const en = entries[i];
            const stackId = en[0];
            const count: number = en[1] as number;
            const stack = this.ticketBlockDashboardService.stacks.find(s => s.id === stackId);


            if (count > 0) {
                try {
                    await this.ticketBlockDashboardService.createInvitation(
                        formattedInvitation,
                        stackId,
                        count,
                        shouldCreateParent
                    ).toPromise();


                    if (shouldCreateParent) {
                        shouldCreateParent = false;
                    }

                    if (stack !== undefined) {
                        this.message.success(`Successfully added guest for ${stack.event.name}`);
                    } else {
                        this.message.success(`Successfully added guest`);
                    }

                } catch (e) {
                    shouldReturn = false;
                    this.isSubmitting = false;
                    if (e.status === 409) {
                        this.message.error(`Guest already has an invite for ${stack.event.name}`);
                    } else {
                        this.message.error(`Error adding guest for ${stack.event.name}`);
                    }
                }
            }
        }

        if (shouldReturn) {
            this.isSubmitting = false;
            let eventId = this.eventFarmService.currentEvent.id;
            let ticketBlockId = this.ticketBlockDashboardService.ticketBlock.id;
            this.ticketBlockDashboardService.gatherRelatedData();
            const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.list', { eventId });
            this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + ticketBlockId);
        }
    }

    resetInvite() {
        this.invitation = {
            eventId: '',
            email: '',
            status: '',
            firstName: '',
            lastName: '',
            company: '',
            position: '',
            isCheckedIn: false,
            shouldSendInvitation: false,
            inviteSource: 'direct-invite',
            checkInNotes: '',
            invitationNotes: '',
            telephone: '',
            title: ''
        };
    }

    private selectUser(user: User): void {
        let formattedUser = this.transformUser(user);
        this.invitation = merge(this.invitation, formattedUser);
    }

    get isSessionTB(): boolean {
        return this.ticketBlockDashboardService.isSessionTicketBlock;
    }

    private getMinAvailabilityForStack(stack: Stack): number {
        return 0;
    }

    private getMaxAvailabilityForStack(stack: Stack): number {
        return Math.max(0, stack.availabilityCounts.totalUnused);
    }

    private getIsDisabledForStack(stack: Stack): boolean {
        return this.getMaxAvailabilityForStack(stack) === 0;
    }
 
    private transformUser(user: User): IFormattedUser {
        let fullName = user.name ? user.name.fullName : null;
        let fullNameWithEmail = User.getFullNameWithEmail(user.name, user.identifier);
        let userId = user.id;
        let firstName = user.name ? user.name.firstName : null;
        let lastName = user.name ? user.name.lastName : null;
        let email = user.identifier ? user.identifier.identifier : null;
        let position = null;
        let company = null;

        user.attributes.forEach((attribute) => {
            if (attribute.isPosition) {
                position = attribute.value;
            }
            if (attribute.isCompany) {
                company = attribute.value;
            }
        });

        return {
            userId,
            fullName,
            fullNameWithEmail,
            firstName,
            lastName,
            email,
            position,
            company,
        };
    }

    goToAddInviteTb() {
        const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.invite', {eventId: this.eventFarmService.currentEvent.id});
        this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + this.ticketBlockDashboardService.ticketBlock.id);
    }

}

interface ICreateUser {
    email?: string;
    firstName?: string;
    lastName?: string;
}

interface IFormattedUser {
    userId: string;
    fullName?: string;
    fullNameWithEmail?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
    position?: string;
}

interface Invitation {
    eventId: string;
    isCheckedIn: boolean;
    inviteSource: string;
    status: string;
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    checkInNotes: string;
    invitationNotes: string;
    shouldSendInvitation: boolean;
    telephone: string;
    title: string;
};

