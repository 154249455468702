import { AbstractBaseSegment } from './segment-base';

export class SegmentPoolTracking extends AbstractBaseSegment {

    changeTeam(selectedTeam, currentUser) {
        // return this.analytics.track({
        //     userId: currentUser.id,
        //     event: 'Change Team',
        //     properties: {
        //         teamId: selectedTeam.id,
        //         teamName: selectedTeam.name
        //     }
        // });
    }
}
