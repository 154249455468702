import { tap, take, withLatestFrom, switchMap, filter, map, mergeMap, concatMap, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as eventActions from '../../actions/event';
import * as fromStore from '../../app.state';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Design } from './../../../EventsModule/EmailDesignCenter/Models/design';
import { of, from, merge } from 'rxjs';
import { DesignKeysForStack } from '../../../EventsModule/EmailCommunication/InvitationsConfirmations/ICRowItem/ic-row-item.component';
import { SetDesigns } from '../../actions/event';

@Injectable()
export class DesignsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
    ) { }


    fetchDesigns$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchDesigns>(eventActions.FETCH_DESIGNS),
        withLatestFrom(this.store.select('router')),
        map(([res, router]) => router),
        filter((router) => router.state.params.event),
        map((router) => router.state.params.event),
        switchMap((event) => this.apiClient.getUseCaseFactory().EmailDesign().ListEmailDesignsByEvent(event, 1, 500)),
        map((designs: any) => {
            return {
                meta: designs.meta,
                data: designs.data.map(design => {
                    return Design.fromApi(design);
                })
            };
        }),
        switchMap((designs) => [
            new eventActions.FetchDesignsSuccess(designs),
        ]),
    ));

    setDesignForStack$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.SetDesigns>(eventActions.SET_DESIGNS),
        switchMap(
          async (set: SetDesigns) => {
              await this.apiClient
                .getUseCaseFactory()
                .Stack()
                .SetEmailDesignsForStack(
                  set.payload.stackId,
                  set.payload.inviteEmailId,
                  set.payload.confirmEmailId,
                  set.payload.declineEmailId,
                ).toPromise();
              return set.payload;
          }
        ),
        switchMap((res: DesignKeysForStack) => [
            new eventActions.SetDesignsSuccess(res)
        ])
    ));

}
