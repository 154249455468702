import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentGuestListTracking extends AbstractBaseSegment {

    downloadList() {
        return this.track({
            event: actions.DOWNLOAD_GUESTLIST
        }, true);
    }

    invite() {
        return this.track({
            event: actions.VISIT_INVITE_ADD_GUEST
        }, true);
    }

    importFromSalesforce() {
        return this.track({
            event: actions.IMPORT_GUESTLIST_FROM_SALESFORCE
        }, true);
    }

    addGroup() {
        return this.track({
            event: actions.UPLOAD_GUESTLIST_ADD_GROUP
        }, true);
    }

    openInvitationSlideOut(guestDetails: object) {
        return this.track({
            event: actions.OPEN_INVITATION_SLIDEOUT,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    checkInGuest(guestDetails: object) {
        return this.track({
            event: actions.CHECK_IN_GUEST,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    undoCheckIn(guestDetails: object) {
        return this.track({
            event: actions.UNDO_CHECK_IN_GUEST,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    sortGuestList(sortDetails: object) {
        return this.track({
            event: actions.SORT_GUEST_LIST,
            properties: {
                ...sortDetails
            }
        }, true);
    }

    saveInvitation(guestDetails: object) {
        return this.track({
            event: actions.SAVE_INVITATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    deleteInvitation(guestDetails: object) {
        return this.track({
            event: actions.DELETE_INVITATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    transferInvitation(guestDetails: object) {
        return this.track({
            event: actions.TRANFER_INVITATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    visitShareLink(guestDetails: object) {
        return this.track({
            event: actions.VISIT_SHARE_LINK,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    copyShareLink(guestDetails: object) {
        return this.track({
            event: actions.COPY_SHARE_LINK,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    resendConfirmation(guestDetails: object) {
        return this.track({
            event: actions.RESEND_CONFIRMATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    resendInvitation(guestDetails: object) {
        return this.track({
            event: actions.RESEND_INVITATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    syncSaleforceInvitation(guestDetails: object) {
        return this.track({
            event: actions.SYNC_SALESFORCE_INVITATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    savedRegQuestions(guestDetails: object) {
        return this.track({
            event: actions.SAVE_REG_QUESTIONS,
            properties: {
                ...guestDetails
            }
        }, true);
    }

    cancelEdit(guestDetails: object) {
        return this.track({
            event: actions.CANCEL_EDIT_INVITATION,
            properties: {
                ...guestDetails
            }
        }, true);
    }

}
