import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[clickOutside]' })

export class ClickOutsideDirective {

    constructor(private elementRef: ElementRef) { }

    @Output()
    public clickOutside = new EventEmitter();

    @HostListener('document:click', ['$event.target']) clickedOutside(targetElement) {
        const clickedOutside = !this.elementRef.nativeElement.contains(targetElement);
        if (clickedOutside) {
            this.clickOutside.emit(null);
        }
    }

}
