import { TicketBlockTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/TicketBlock';
import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { TicketBlockAllotment } from './ticket-block-allotment';

export class TicketBlock extends AbstractModel {

    private _name?: string;
    private _emailText?: string;
    private _isBlackListEnabled?: boolean;
    private _isDeleted?: boolean;
    private _extraAttributes?: any;
    private _allotments?: TicketBlockAllotment[];
    private _event?: EfEvent;
    private _type: TicketBlockTypeInterface;

    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data) {
            const ticketBlock = new this(data.id);
            if (data.meta && data.meta.isInitialized) {
                ticketBlock._name = data.attributes.name;
                ticketBlock._emailText = data.attributes.emailText;
                ticketBlock._isBlackListEnabled = data.attributes.isBlackListEnabled;
                ticketBlock._isDeleted = data.attributes.isDeleted;
                ticketBlock._extraAttributes = data.extraAttributes;
                ticketBlock._type = data.attributes.type;
            }

            if (data.relationships && data.relationships.event) {
                ticketBlock._event = EfEvent.fromApiResponse(data.relationships.event);
            }

            if (data.relationships && data.relationships.allotments) {
                ticketBlock._allotments = [];
                data.relationships.allotments.forEach(allotment => {
                    ticketBlock._allotments.push(TicketBlockAllotment.fromApiResponse(allotment));
                });
            }

            return ticketBlock;
        }

    get name(): string {
        return this._name;
    }

    get emailText(): string {
        return this._emailText;
    }

    get isBlackListEnabled(): boolean {
        return this._isBlackListEnabled;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    get extraAttributes(): any {
        return this._extraAttributes;
    }

    get event(): EfEvent {
        return this._event;
    }

    get allotments(): TicketBlockAllotment[] {
        return this._allotments;
    }

    get type(): TicketBlockTypeInterface{
        return this._type;
    }

    get isSessionTB(): boolean {
        return this._type.isSession;
    }
}
