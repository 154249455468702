const moment = require('moment');

import { EmailDesign } from '../EmailDesign/email-design';
import { FormattedDateTime } from '../../ApiClient/Models/formatted-datetime';

export class ScheduledMessage {
    set id(value: string) {
        this._id = value;
    }

    set design(value: EmailDesign) {
        this._design = value;
    }

    set designId(value: string) {
        this._designId = value;
    }

    set recipients(value: string[]) {
        this._recipients = value;
    }

    setFromNowFromSeconds(unixSeconds: number) {
        const inMicroSeconds = unixSeconds * 1000;
        if (inMicroSeconds > 0) {
            this._fromNow = moment(inMicroSeconds).fromNow();
        } else {
            this._fromNow = '';
        }
    }

    turnTargetsToString(targets) {

        const keys = Object.keys(targets);

        const res = keys.map( key => {

            if (key === 'hasAssigned' && targets[key]) {
                return 'Assigned';
            }

            if (key === 'hasAttended' && targets[key]) {
                return 'Attended';
            }

            if (key === 'hasConfirmed' && targets[key]) {
                return 'Confirmed';
            }
            if (key === 'hasPurchased' && targets[key]) {
                return 'Purchased';
            }

            if (key === 'hasUnconfirmed' && targets[key]) {
                return 'Unconfirmed';
            }

            return '';

        });

        this._recipients = res.filter(Boolean);

    }

    set ownerUserId(value: string) {
        this._ownerUserId = value;
    }
    get id(): string {
        return this._id;
    }

    get design(): EmailDesign {
        return this._design;
    }

    get designId(): string {
        return this._designId;
    }

    get recipients(): string[] {
        return this._recipients;
    }

    get fromNow(): string {
        return this._fromNow;
    }

    get fromNowFormatted(): FormattedDateTime {
        return this._fromNowFormatted;
    }

    get status(): string {
        return this._status;
    }

    get ownerUserId(): string {
        return this._ownerUserId;
    }

    private _id: string;
    private _design: EmailDesign = new EmailDesign();
    private _designId: string;
    private _recipients: string[];
    private _fromNow: string;
    private _fromNowFormatted: FormattedDateTime;

    private _status: string;
    private _ownerUserId: string;

    public static fromApi(data) {
        const scheduledMessage = new this();

        scheduledMessage._id = data.id;
        try {
            scheduledMessage._designId = data.relationships.emailDesign.id;
            scheduledMessage.turnTargetsToString(data.attributes.targets);
            scheduledMessage._fromNowFormatted = data.attributes.scheduledStartFormatted;
            scheduledMessage._status = data.attributes.isCompleted ? 'Done' : 'New';
            scheduledMessage._ownerUserId = data.relationships.ownerUser.id;
        } catch (err) {
        }

        return scheduledMessage;
    }
}
