import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import * as AdminArchiveTeamAction from '../../actions/admin/admin-archive-team.action';
import * as fromRoot from '../..';

@Injectable()
export class AdminArchiveTeamEffects {

    constructor(
        private actions$: Actions,
        private apiClient: EventFarmAPIClient,
        private messageService: NzMessageService,
        private store: Store<fromRoot.AppState>,
    ) { }


    // @ts-ignore
    public archiveTeamForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType<AdminArchiveTeamAction.ArchiveCurrentTeamForAdmin>(AdminArchiveTeamAction.ARCHIVE_CURRENT_TEAM_FOR_ADMIN),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeam)),
        switchMap(([action, currentTeam]) => {
            return  this.apiClient.getUseCaseFactory().Pool().ArchivePool(currentTeam.data.id)
                .pipe(
                    map(res => res['data']),
                    switchMap((res: any) => [
                        new AdminArchiveTeamAction.ArchiveCurrentTeamForAdminSuccess(),
                        this.messageService.success('Team deleted!'),
                    ]),
                )
        }),
        catchError(err => {
            this.messageService.error('Error deleting team!');
            return of(new AdminArchiveTeamAction.ArchiveCurrentTeamForAdminFail(err));
        })));
}
