import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormControl, Validators, NG_VALIDATORS,
} from '@angular/forms';

import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from '@khazii/ngx-intl-tel-input';

@Component({
    selector: 'intl-phone-input',
    template: require('./intl-phone-input.html'),
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IntlPhoneInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: IntlPhoneInputComponent,
            multi: true
        }
    ],
})
export class IntlPhoneInputComponent implements ControlValueAccessor {

    value: any;

    constructor() {}

    input = new FormControl(undefined, [Validators.required]);

    separateDialCode = false;

    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];

    validate({ value }: FormControl) {
        const isNotValid = !this.input.valid;
        return isNotValid && {
            invalid: true
        }
    }

    handleChange(val) {
        this.input.setValue(val);
    }

    // Step 4: use the setValue method on the form control to update it when a new value comes in from the outside
    writeValue(input: string) {
        this.input.setValue(input);
    }

    // Step 5: Listen to value changes on the form control. If something happens, the function provided by Angular will be called and the outside world will be informed.
    subscriptions = [];
    registerOnChange(fn: any): void {
        this.subscriptions.push(this.input.valueChanges.subscribe(fn));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    // Step 6: onTouch stays the same
    onTouch: any = () => {};
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }


}
