import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
    selector: 'form-textarea',
    template: `
    <div
      class="dynamic-field form-group form-textarea"
      [formGroup]="group">
      <label>{{ config.label }}</label>
      <textarea
        class="form-control"
        rows="3"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name"></textarea>
    </div>
  `
})
export class FormTextareaComponent implements Field {
    config;
    group: FormGroup;
}
