import { Params, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../../ApiClient/Models/User/user';
import * as fromRouter from '@ngrx/router-store';


export interface RouterStateUrl {
    url: string;
    queryParams: Params;
    params: Params;
}

export interface AppState {
    user: any;
    router: fromRouter.RouterReducerState<RouterStateUrl>;
    team: any;
    event: any;
    admin: any;
}

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        const { queryParams } = routerState.root;

        let state: ActivatedRouteSnapshot = routerState.root;
        let params = {};
        while (state.firstChild) {
            state = state.firstChild;
            params = {...params, ...state.params};
        }

        return { url, queryParams, params };
    }
}
