import { RouteGeneratorService } from './../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { User } from '../../../ApiClient/Models/User/user';
import { EventFarmService } from '../../eventFarm.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { Observable ,  Subscription } from 'rxjs';
import { map, filter, tap, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../eventFarmAlert.service';
import { LocalStorageService } from '../../../_services/storage/local-storage.service';
import * as Sentry from "@sentry/angular-ivy";

@Component({
    selector: 'user-details',
    template: require('./user-details.html'),
    styles: [require('./user-details.scss')],

})

export class UserDetailsComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private segmentService: SegmentService,
        private store: Store<fromRoot.AppState>,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private http: HttpClient,
        private alertService: AlertService,
        private localStorageService: LocalStorageService
    ) {
        this.userUpdate$ = this.userStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.user = val.data;
            const first = this.user.name && this.user.name.firstName ? this.user.name.firstName.slice(0, 1) : '';
            const last = this.user.name && this.user.name.lastName ? this.user.name.lastName.slice(0, 1) : '';
            this.userInitials = first + last;
        });
    }
    @Input() isTopNav: boolean;
    private user: User;
    private ghosting: boolean = false;
    private userInitials: string;
    private eventStore$ = this.store.select(fromRoot.getCurrentEvent).pipe(shareReplay());
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userUpdate$: Subscription;

    ngOnInit() {
        this.ghosting = document.cookie.match('(^|;)\\s*' + 'EF_GHOST_ACCESS_TOKEN' + '\\s*=\\s*([^;]+)') ? true : false;
    }

    changeTeam(id) {
        this.eventFarmService.eventListOptions.pagination.currentEventsPage = 1;
        this.eventFarmService.eventListOptions.tags = null;
        this.eventFarmService.eventListOptions.sortBy = 'event-start';
        this.eventFarmService.eventListOptions.sortDirection = 'ascending';
        this.eventFarmService.eventListOptions.query = null;
        this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', { poolId: id }));
        this.store.select(fromRoot.selectTeam(id)).subscribe(team => {
            this.store.dispatch(new fromRoot.SetCurrentTeam(team));
            this.segmentService.segmentPoolTracking().changeTeam(
                team,
                this.eventFarmService.currentUser,
            );
        });
    }

    // TODO clean up all the local storage stuff
    switchToSelf() {
            // Remove GhostTech key/val
             this.localStorageService.set('X-EF-GhostTech', undefined);

            // Revert effective user back to original
             const userId = this.localStorageService.get('efUserId');
             this.localStorageService.set('effectiveEfUserId', userId);

            // Redirect to admin
             const fullBaseUrl = window.location.protocol + '//' + window.location.host;
             const redirectUrl = fullBaseUrl + '/users/dashboard/admin/user';
             window.location.href = redirectUrl;
    }

    isGhosting() {
        return this.localStorageService.get('X-EF-GhostTech') ? true : false;
    }

    public logout(evt?) {
        this.localStorageService.resetStorageAndLogout();
    }

    public resetNavBars() {
        this.localStorageService.resetNavBar.next(true);
    }

    ngOnDestroy() {
        this.userUpdate$.unsubscribe();
    }

    async handleResetPassword() {
       await this.alertService.userPassword().confirmResetPassword();
    }
}
