import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IconService } from '../../Icons/icons.service';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';

@Injectable()
export class GuestRelatedInvitationsLogService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private nzMessageService: NzMessageService,
        private iconService: IconService
    ) {
    }

    public readonly currentRelatedInvitations$ = new BehaviorSubject<Invitation[]>([]);
    public logMetaSource = new BehaviorSubject<any>({});
    public logMeta$ = this.logMetaSource.asObservable();
    public isLoadingList = false;

    public relatedInvitationListOptions: RelatedInvitationsListOptions = {
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentListPage: 1,
            itemsPerPage: 5,
        }
    };

    public clearList() {
        this.currentRelatedInvitations$.next([]);
        this.logMetaSource.next(null);
        this.relatedInvitationListOptions.pagination.currentListPage = 1;
    }

    public get logCount(): number {
        return this.currentRelatedInvitations$.value.length;
    }

    public get totalResults(): number {
        return this.logMetaSource.value.totalResults;
    }

    public async getRelatedInvitations(invitationId: string) {

        this.isLoadingList = true;

        const invitations = await this.apiClient.getUseCaseFactory()
        .Invitation()
        .ListRelatedInvitations(
            invitationId,
            ['UserIdentifiers', 'StackAndTicketType'],
            ['info', 'custom'],
            null,
            ['assigned', 'purchased', 'confirmed-by-rsvp', 'declined-by-rsvp', 'left-behind', 'registered', 'unconfirmed', 'not-yet-purchased', 'not-yet-registered'],
            null,
            null,
            this.relatedInvitationListOptions.sortBy,
            this.relatedInvitationListOptions.sortDirection,
            this.relatedInvitationListOptions.pagination.currentListPage,
            this.relatedInvitationListOptions.pagination.itemsPerPage,
        ).toPromise();

        this.logMetaSource.next(invitations.meta);

        this.currentRelatedInvitations$.next(invitations.data.map(invitation => {
            return Invitation.fromApiResponse(invitation)
        }));

        this.isLoadingList = false;
    }
}

export interface Pagination {
    currentListPage: number;
    itemsPerPage: number;
}

export interface RelatedInvitationsListOptions {
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}
