import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {PopoverModule} from 'ngx-bootstrap/popover';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { MyAccountOverviewComponent } from './my-account-overview.component';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { DropdownSelectModule } from '../../CoreUI/DropdownSelect/dropdown-select.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailCommunicationsComponent } from './EmailCommunications/email-communications.component';
import { TotalContactsComponent } from './TotalContacts/total-contacts.component';
import { PlanDetailsComponent } from './PlanDetails/plan-details.component';
import { RequestUpgradeComponent } from './RequestUpgrade/request-upgrade.component';
import { AccountInfoService } from './account-info.service';
import { IntegrationsLinkComponent } from './Integrations/integrations-link.component';
import { MyAccountResolver } from './my-account.resolver';
import { SalesforceTeamSettingsComponent } from './Integrations/salesforce-team-settings.component';
import { MyAccountComponent } from './my-account.component';
import { MobileAppLinksComponent } from './MobileApps/mobile-app-links.component';
import { GuestPrivacyComponent } from './GuestPrivacy/guest-privacy.component';
import { EfxListingComponent } from './EFXListing/efx-listing.component';
import { RequestEFxComponent } from './EFXListing/requestEFx.component';
import { SessionStorageService } from '../../../_services/storage/session-storage.service';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';
import {PaymentSettingsComponent} from './PaymentSettings/payment-settings.component';
import {PaymentSettingsModule} from './PaymentSettings/payment-settings.module';


@NgModule({
    bootstrap: [MyAccountOverviewComponent],
    declarations: [
        MyAccountComponent,
        MyAccountOverviewComponent,
        PlanDetailsComponent,
        IntegrationsLinkComponent,
        SalesforceTeamSettingsComponent,
        TotalContactsComponent,
        EmailCommunicationsComponent,
        RequestUpgradeComponent,
        RequestEFxComponent,
        MobileAppLinksComponent,
        GuestPrivacyComponent,
        EfxListingComponent

    ],
    entryComponents: [
        MyAccountOverviewComponent,
        RequestUpgradeComponent,
        RequestEFxComponent,
    ],
    imports: [
        CommonModule,
        ErrorModule,
        UtilitiesModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        MyAccountRoutingModule,
        DropdownSelectModule,
        NgxChartsModule,
        PopoverModule,
        NzUtilitiesModule,
        PaymentSettingsModule
    ],
    providers: [
        AccountInfoService,
        SessionStorageService,
        MyAccountResolver,
    ],
})

export class MyAccountModule {
}
