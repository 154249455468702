import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateEventComponent } from './create-event.component';
import { CreateEventRoutingModule } from './create-event.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputAutosizeDirective } from '../../CoreUI/Forms/components/form-input-autosize/form-input-autosize.directive';
import { TimezoneTypeaheadModule } from '../../CoreUI/Typeahead/timezone-typeahead.module';

import { SelectEventTypeComponent } from './SelectEventType/select-event-type.component';
import { CreateFullEventComponent } from './CreateEventDetails/create-event-details.component';
import { GetFreeEventComponent } from './GetFreeEvent/get-free-event.component';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { CreateEventResolve } from './create-event.resolve';
import { CreateEventService } from './create-event.service';

import { EventTimeZoneTypeahead } from './CreateEventDetails/event-time-zone-typeahead';
import { CanCreateEventGuard } from './guards/create-event-guard';

import * as fromGuards from './guards';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';


@NgModule({
    bootstrap: [CreateEventComponent],
    declarations: [
        CreateEventComponent,
        SelectEventTypeComponent,
        FormInputAutosizeDirective,
        CreateFullEventComponent,
        GetFreeEventComponent,
        EventTimeZoneTypeahead,
    ],
    entryComponents: [
        CreateEventComponent
    ],
    imports: [
        CreateEventRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UtilitiesModule,
        TimezoneTypeaheadModule,
        NzUtilitiesModule
    ],
    providers: [
        CreateEventResolve,
        fromGuards.guards,
        CreateEventService
    ]
})

export class CreateEventModule {}
