import { NgModule } from '@angular/core';
import { CookieTokenService } from '../ApiClient/cookie-token-service';
import { EventFarmAPIClient } from '../ApiClient/event-farm-api-client';
import { RouteGeneratorService } from './routes/route-generator.service';
import { LocalStorageService } from './storage/local-storage.service';
import { SessionStorageService } from './storage/session-storage.service';
import { AngularRestClient } from './rest/AngularRestClient';

@NgModule({
    providers: [
        AngularRestClient,
        CookieTokenService,
        EventFarmAPIClient,
        LocalStorageService,
        RouteGeneratorService,
        SessionStorageService,
    ],
})

export class AppServicesModule {
}
