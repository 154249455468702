import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GuestListComponent } from './guest-list.component';
import { InviteAddGuestComponent } from './Invite/inviteAddGuest.component';
import { TheListComponent } from './List/the-list.component';
import { AddGuestComponent } from './Invite/AddIndividual/addGuest.component';
import { AddGroupComponent } from './Invite/AddGroup/addGroup.component';

const routes: Routes = [
    {
        path: '',
        component: GuestListComponent,
        children: [
            {
                path: 'list',
                component: TheListComponent,
            },
            {
                path: 'invite',
                component: InviteAddGuestComponent,
            },
            {
                path: 'invite/individual',
                component: AddGuestComponent,
            },
            {
                path: 'invite/group',
                component: AddGroupComponent,
            },
            {
                path: '**',
                redirectTo: 'list',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class GuestListRoutingModule {
}
