import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const moment = require('moment');

import { EmailDesign } from '../../../../Shared/EmailDesign/email-design';
import { ScheduledMessage } from '../../../../Shared/EmailMessage/scheduled-message';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { EmailDesignCenterService } from '../email-design-center.service';
import { AlertService } from '../../../eventFarmAlert.service';

@Injectable()

export class ScheduledService {
    public messages: ScheduledMessage[] = [];
    private designs: EmailDesign[] = [];

    public loaded: boolean = false;

    constructor(
        private eventFarmService: EventFarmService,
        private emailDesignCenterService: EmailDesignCenterService,
        private apiClient: EventFarmAPIClient,
        private alertService: AlertService,
    ) { }

    public assembleList() {
        this.loaded = false;

        forkJoin(
            this.listMessages(),
            this.listDesigns(),
        ).subscribe((data) => this.assignDesigns(data));
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    private listMessages(): Observable<ScheduledMessage[]> {
        return this.apiClient.getUseCaseFactory().EmailMessage()
        .ListScheduledBatchEmailMessagesByEvent(
            this.emailDesignCenterService.event.id,
        )
        .pipe(map((data) => data['data'].map((message) => ScheduledMessage.fromApi(message)).map((message) => this.markMine(message))));
    }

    private listDesigns(): Observable<EmailDesign[]> {
        return this.apiClient.getUseCaseFactory().EmailDesign()
        .ListEmailDesignsByEvent(this.emailDesignCenterService.event.id)
        .pipe(map((data) => data['data'].map((design) => EmailDesign.fromApi(design))));
    }

    private markMine(message) {
        message.mine = message.ownerUserId === this.eventFarmService.currentUser.id;
        return message;
    }

    private assignDesigns(data) {
        this.loaded = true;

        const messages = data.shift();
        this.designs = data.shift();

        const processedMessages: ScheduledMessage[] = [];

        for (const message of messages) {
            const matchedDesigns = this.designs
            .filter((design) => design.id === message.designId);

            if (matchedDesigns.length) {
                const matchedDesign = matchedDesigns.shift();
                message.design = matchedDesign;
            }

            processedMessages.push(message);
        }

        this.messages = processedMessages;
    }

    public async removeMessage(message) {
        try {
            await this.apiClient.getUseCaseFactory().EmailMessage() .RemoveScheduledBatchEmailMessage(message.id).toPromise();
            this.assembleList();
        } catch (e) {
            this.alertService.emailDesignCenter().removeScheduledEmailError();
        }
    }
}
