import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from "rxjs";
import {ValidateMaxSlugContent} from '../../../../../Shared/Validators/textAreaSlugContentValidator';
import {EventFarmService} from '../../../../eventFarm.service';
import {SMSCreateService} from '../sms-create.service';
import { SMSMessageSlugTypeInterface, SMSMessage } from '@eventfarm/javascript-sdk/dist/Api/Type/SMSMessage';




@Component({
    selector: 'sms-create-compose',
    template: require('./sms-create-compose.html'),
    styles: [require('./sms-create-compose.scss')]
})

export class SMSCreateComposeComponent implements OnInit, OnDestroy {
    @Output() onValidityChange = new EventEmitter();

    smsComposeForm: FormGroup;
    smsComposeFormStatusSub$: Subscription;
    smsComposeFormContentSub$: Subscription;
    private slugs: SMSMessageSlugTypeInterface[];
    constructor(
        private eventFarmService: EventFarmService,
        private formBuilder: FormBuilder,
        private smsCreateService: SMSCreateService
    ) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.slugs = new SMSMessage().SMSMessageSlugType();

        // Emit this so parent can adjust "next" button appropriately
        this.onValidityChange.emit(this.smsComposeForm.valid);
        this.smsComposeFormStatusSub$ = this.smsComposeForm.statusChanges.subscribe(
            result => {
                this.onValidityChange.emit(this.smsComposeForm.valid);
            }
        );

        this.updateValuesAndValidity();

    }



    buildForm(): void {

        this.smsComposeForm = this.formBuilder.group({
            content: new FormControl(this.smsCreateService.content || '', [
                Validators.required,
                Validators.minLength(2),
                ValidateMaxSlugContent
            ])
        });

        // Store value changes in our SMS Create service
        this.smsComposeFormContentSub$ = this.smsComposeForm.controls.content.valueChanges.subscribe(data => {
            this.smsCreateService.content = data
        });
    }

    private  modifyValidators() {
        // Start by resetting all conditional validation
        this.clearConditionalValidation();

        this.smsComposeForm.controls['content'].setValidators([
                    Validators.required,
                    Validators.minLength(2),
                    ValidateMaxSlugContent
                ]);
        this.updateValuesAndValidity();
    }
    private clearConditionalValidation() {
        this.smsComposeForm.controls['content'].clearValidators();
    }

    private updateValuesAndValidity() {
        this.smsComposeForm.controls['content'].updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.smsComposeFormStatusSub$.unsubscribe();
        this.smsComposeFormContentSub$.unsubscribe();
    }
}
