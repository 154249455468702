import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Exhibitor } from '../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { EditExhibitorComponent } from './EditExhibitor/edit-exhibitor.component';

@Component({
  selector: 'create-exhibitor-button',
  template: `
    <button
      nz-button
      nzType="primary"
      class="main-action ef-btn-sm"
      (click)="openComponent()"
    >Create New</button>
  `
})

export class CreateExhibitorButtonComponent implements OnInit {
  @Input() exhibitor: Exhibitor;
  @Input() buttonText;
  drawerTitle;
  width = '50%';

  constructor(
    private drawerService: NzDrawerService,
  ) {}

  ngOnInit() {
      this.drawerTitle = 'Create Exhibitor';
  }

  openComponent(): void {
    const drawerRef = this.drawerService.create<EditExhibitorComponent, { }, string>({
      nzTitle: this.drawerTitle,
      nzContent: EditExhibitorComponent,
      nzWidth: this.width,
      nzContentParams: {
      }
    });

    drawerRef.afterOpen.subscribe(() => {
    });

    drawerRef.afterClose.subscribe(data => {

    });
  }

}
