import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QuestionCenterComponent } from './question-center.component';

const routes: Routes = [
    {
        path: '',
        component: QuestionCenterComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class QuestionCenterRoutingModule {
}

