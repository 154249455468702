import { Component, OnInit } from '@angular/core';
import { AccountInfoService } from '../account-info.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EventFarmService } from '../../../eventFarm.service';
import { Team } from '../../../../ApiClient/Models/Pool/team';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
    selector: 'guest-privacy',
    template: require('./guest-privacy.html'),
    styles: [require('./guest-privacy.scss')],
})

export class GuestPrivacyComponent implements OnInit {

    constructor(
        private eventFarmService: EventFarmService,
        private accountUsageService: AccountInfoService,
        private message: NzMessageService,
        private store: Store<fromRoot.AppState>
    ) {}

    private orgName: string;
    private orgPPURL: string;
    private userContactType$ = this.store.select(fromRoot.getPoolContactType).pipe(shareReplay());

    ngOnInit() {
        this.orgName = this.eventFarmService.currentTeam.customerDisplayName;
        this.orgPPURL = this.eventFarmService.currentTeam.privacyPolicyLink;
    }

    save() {
        Promise.all([
            this.accountUsageService.setOrUnsetPrivacyPolicyOrgName(this.orgName).toPromise(),
            this.accountUsageService.setOrUnsetPrivacyPolicyURL(this.orgPPURL).toPromise(),
        ]).then((res) => {
            this.message.success(`Privacy policy information updated`);
        }).catch((err) => {
            this.message.error(`Information could not be updated. Please verify it is valid.`);
        })
    }
}