import _ = require("lodash");
import {TicketType} from "./Stack/ticket-type";

type SendByLabel =
    'Preview' |
    'Invitations' |
    'Invitation Status'|
    'Group'|
    'Ticket Block' |
    'Waitlist'|
    'Checked-in'|
    'No Shows';

export type SendByValue = 'preview' | 'invitations' | 'invitation-status'|'group'| 'ticket-block' | 'wait-list'|'checked-in'|'no-shows';

type InvitationStatusLabel = 'Confirmed or Registered'|'Purchased'|'Assigned'|'Unconfirmed';
export type InvitationStatusValue = 'confirmed-by-rsvp'|'purchased'|'assigned'|'unconfirmed';

// The values we send to the api for invitation status differ from those bound to options in the ui
export type InvitationStatusPayloadValue =
    'assigned' |
    'confirmed-by-rsvp'|
    'registered'|
    'purchased'|
    'not-yet-purchased'|
    'unconfirmed' |
    'not-yet-registered';

interface SendByOptionInterface {
    label: SendByLabel;
    value: SendByValue;
    helper?: string;
}

export interface InvitationStatusOptionInterface {
    label: InvitationStatusLabel;
    value: InvitationStatusValue;
    payloadValue: InvitationStatusPayloadValue[];
}

export interface InvitationAccessTypeOptionInterface {
    label: string;
    value: string;
}


export class Communication {

    static get sendByOptions(): SendByOptionInterface[] {
        return [
            {
                label: 'Preview',
                value: 'preview',
                helper: 'Preview message by sending to an individual number.  NOTE: Dynamic elements will not be replaced.'
            },
            {
                label: 'Invitations',
                value: 'invitations',
                helper: 'Send to one or more invitations from the guest list.  Guests without a phone number will appear as disabled items.'
            },
            {
                label: 'Invitation Status',
                value: 'invitation-status',
                helper: 'Send to guests with a particular invitation status, for specific access type(s)'

            },
            {
                label: 'Ticket Block',
                value: 'ticket-block',
                helper: 'Send to a list of users on a ticket block'
            },
            {
                label: 'Group',
                value: 'group',
                helper: 'Send to a group from your address book. Note: The the group member must have an invitation for the event in order to receive a text.'
            },
            {
                label: 'Waitlist',
                value: 'wait-list',
                helper: 'Send to guests currently on the waitlist'
            },
            {
                label: 'Checked-in',
                value: 'checked-in',
                helper: 'Send to guests that are currently checked in'
            },
            {
                label: 'No Shows',
                value: 'no-shows',
                helper: 'All guests who are known to be confirmed, but who have not checked in to your event.'
            }
        ];
    }

    static get invitationStatusTypeOptions(): InvitationStatusOptionInterface[] {
        return [
            {
                label: 'Confirmed or Registered',
                value: 'confirmed-by-rsvp',
                payloadValue: ['assigned', 'confirmed-by-rsvp', 'registered']
            },
            {
                label: 'Purchased',
                value: 'purchased',
                payloadValue: ['purchased']
            },
            {
                label: 'Assigned',
                value: 'assigned',
                payloadValue: ['assigned']
            },
            {
                label: 'Unconfirmed',
                value: 'unconfirmed',
                payloadValue: ['not-yet-purchased', 'unconfirmed', 'not-yet-registered']
            }
        ];
    }

    // Populate array of payloadValues based on statsTypeValues
    static constructStatusesForPayload(statusTypeValues): InvitationStatusValue[] {
        const mapped =  _.mapValues(_.keyBy(Communication.invitationStatusTypeOptions, 'value'), 'payloadValue')
        let payload = [];
        statusTypeValues.forEach(statusTypeOption => {
            payload = [...payload, ...mapped[statusTypeOption]];
        });
        return _.union(payload);
    }

    static constructTicketTypesForPayload(ticketTypes: TicketType[]): string[] {
        return ticketTypes.map(ticketType => ticketType.id);
    }
    static getStatusTypeLabelFromValue(value: string): string {
        const filtered = this.invitationStatusTypeOptions.filter(opt => opt.value === value);
        if (filtered.length > 0){
            return filtered[0].label;
        } else {
            return '';
        }
    }

    static getSendByLabelFromValue(value: string): string {
        const filtered = this.sendByOptions.filter(opt => opt.value === value);
        if (filtered.length > 0){
            return filtered[0].label;
        } else {
            return '';
        }
    }

    static getSendByHelperTextFromValue(value: string): string {
        const filtered = this.sendByOptions.filter(opt => opt.value === value);
        if (filtered.length > 0) {
            return filtered[0].helper;
        } else {
            return '';
        }
    }
}