import {
  DesignKeysForStack
} from '../../../EventsModule/EmailCommunication/InvitationsConfirmations/ICRowItem/ic-row-item.component';

export const handleFetchDesigns = (state, action) => {
  return {
    ...state,
    designs: {
      ...state.designs,
      loading: true,
      loaded: false
    }
  };
};

export const handleFetchDesignsSuccess = (state, action) => {
  const data = action.payload.data.reduce((acc, design) => {
    return {
      ...acc,
      [design.emailDesignId]: design,
    };
  }, {});
  return {
    ...state,
    designs: {
      data,
      meta: action.payload.meta,
      loaded: true,
      loading: false
    }
  };
};

export const handleSetDesign = (state, action) => {
  return {
    ...state,
    stacks: {
      ...state.stacks,
      loading: true,
    }
  };
};

export const handleSetDesignSuccess = (state, action) => {
  const payload: DesignKeysForStack = action.payload;
  const updateWithNullFields = ['inviteEmailId', 'confirmEmailId', 'declineEmailId']
    .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: payload[curr] || null,
        };
      },
      {});
  const stackId = action.payload.stackId;
  delete payload.stackId;
  return {
    ...state,
    stacks: {
      ...state.stacks,
      loading: false,
      data: {
        ...state.stacks.data,
        [stackId]: Object.assign(state.stacks.data[stackId], updateWithNullFields)
      }
    }
  };
};
