import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-checkbox',
  template: `
    <div class="dynamic-field form-group form-checkbox"
      [formGroup]="group">
      <div [formGroupName]="config.name">
        <label>{{ config.label }}</label>
        <div class="checkbox" *ngFor="let option of config.options">
          <label>
            <input type="checkbox"
              [value]="option.id"
              [formControlName]="option.id" />
              {{ option.text }}
          </label>
        </div>
      </div>
    </div>
  `
})

export class FormCheckboxComponent implements Field {
  config;
  group: FormGroup;
}
