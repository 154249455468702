import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentSideNavTracking extends AbstractBaseSegment {

    /**
     * Pool/Team
     */
    paymentSettingsEntered() {
        this.track({
            event: actions.PAYMENT_SETTINGS_ENTERED,
        });
    }

    myAccountEntered() {
        this.track({
            event: actions.MY_ACCOUNT_ENTERED,
        });
    }

    allEventsEntered() {
        this.track({
            event: actions.ALL_EVENTS_ENTERED,
        });
    }

    addressBookEntered() {
        this.track({
            event: actions.ADDRESS_BOOK_ENTERED,
        });
    }

    /**
     * Event
     */

    eventInfoEntered() {
        this.track({
            event: actions.EVENT_INFO_ENTERED,
        }, true);
    }

    eventAccessEntered() {
        this.track({
            event: actions.EVENT_ACCESS_ENTERED,
        }, true);
    }

    efxAdminPageEntered() {
        this.track({
            event: actions.EFX_ADMIN_CONFIG_ENTERED,
        }, true);
    }

    webPresenceEntered() {
        this.track({
            event: actions.WEB_PRESENCE_ENTERED,
        }, true);
    }

    promoCodesEntered() {
        this.track({
            event: actions.PROMO_CODES_ENTERED,
        }, true);
    }

    registrationQuestionsEntered() {
        this.track({
            event: actions.REGISTRATION_QUESTIONS_ENTERED,
        }, true);
    }

    emailDesignsEntered() {
        this.track({
            event: actions.EMAIL_DESIGNS_ENTERED,
        }, true);
    }

    invitationsConfirmationsEntered() {
        this.track({
            event: actions.INVITATIONS_CONFIRMATIONS_ENTERED,
        }, true);
    }

    guestMessagingEntered() {
        this.track({
            event: actions.GUEST_MESSAGING_ENTERED,
        }, true);
    }

    scheduledMessagesEntered() {
        this.track({
            event: actions.SCHEDULED_MESSAGES_ENTERED,
        }, true);
    }

    guestListEntered() {
        this.track({
            event: actions.GUEST_LIST_ENTERED,
        }, true);
    }

    addGuestsEntered() {
        this.track({
            event: actions.ADD_GUESTS_ENTERED,
        }, true);
    }

    reportsEntered() {
        this.track({
            event: actions.REPORTS_ENTERED,
        }, true);
    }

    emailStatisticsEntered() {
        this.track({
            event: actions.EMAIL_STATISTICS_ENTERED,
        }, true);
    }

    sentMessagesEntered() {
        this.track({
            event: actions.SENT_MESSAGES_ENTERED,
        }, true);
    }

}

