import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../eventFarmAlert.service';
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminService } from '../admin.service';

@Component({
  selector: 'user',
  template: require('./user.html'),
  styles: [require('./user.scss')],

})
export class UserComponent implements OnInit, OnDestroy {
  ghostUserForm: FormGroup;

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>,
    private adminService: AdminService
  ) {
    // this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
    //   this.toggleState = this.eventFarmService.currentEvent.eventAttributes.hasDistribute;
    // });
  }

  private eventRoleType$ = this.store
    .select(fromStore.eventRoleType)
    .pipe(shareReplay());
  private toggleState: boolean = false;
  // private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  // private eventUpdate$: Subscription;
  ngOnInit() {

  }

  private handleContinueAsSelf() {
    const fullBaseUrl = window.location.protocol + '//' + window.location.host;
    const redirectUrl = fullBaseUrl + '/users/dashboard';

    this.adminService.removeGhostingAsHeader();
    window.location.href = redirectUrl;
  }
  toggle() {
    this.toggleState = !this.toggleState;
    if (this.eventFarmService.currentEvent.eventAttributes.hasDistribute) {
      this.disableDistribution();
    } else {
      this.enableDistribution();
    }
  }

  ngOnDestroy() {
    // this.eventUpdate$.unsubscribe();
  }

  enableDistribution() {
    this.apiClient
      .getUseCaseFactory()
      .Event()
      .EnableDistribution(this.eventFarmService.currentEvent.id)
      .toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasDistribute = true;
        this.store.dispatch(
          new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent)
        );
        this.message.success('+1 Information Required');
      });
  }

  disableDistribution() {
    this.apiClient
      .getUseCaseFactory()
      .Event()
      .DisableDistribution(this.eventFarmService.currentEvent.id)
      .toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasDistribute =
          false;
        this.store.dispatch(
          new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent)
        );
        this.message.success('+1 Information not required');
      });
  }
}
