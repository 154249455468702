import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';

import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';
import { EFProfile } from '../../../../../../ApiClient/Models/Profile/EFProfile';
import { EventAgendaService } from '../../../event-agenda.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventFarmService } from '../../../../../eventFarm.service';
import _ = require('lodash');
import { ValidateEmail } from '../../../../../../Utilities/validateEmail';
import { BehaviorSubject } from 'rxjs';
import { EventProfileTypeInterface, Profile } from '@eventfarm/javascript-sdk/dist/Api/Type/Profile';
import { ValidateUrl } from '../../../../../../Utilities/validateUrl';

@Component({
    selector: 'edit-sponsor',
    template: require('./edit-sponsor.html'),
    styles: [require('./edit-sponsor.scss')]
})
export class EditSponsorComponent implements OnInit {
    @Input() sponsor: EFProfile;
    public loading: boolean = false;
    public avatarUrl?: string;

    private isSaving: boolean = false;
    private confirmDelete: boolean = false;
    private isDeleting: boolean = false;
    public base64Image: any;
    private editSponsorItemForm: FormGroup;
    private canSaveImage: boolean|null;



    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store<fromRoot.AppState>,
        private eventAgendaService: EventAgendaService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
        private messageService: NzMessageService

    ) {}

    ngOnInit() {
        this.initializeFormControls();
    }

    private async addSponsor() {
        if (this.isSaving === false) {
            this.isSaving = true;
            try {
                const sponsor = new EFProfile();
                sponsor.company = this.editSponsorItemForm.get('company').value;
                sponsor.imageUrl = this.editSponsorItemForm.get('imageUrl').value;
                sponsor.description = this.editSponsorItemForm.get('description').value;
                sponsor.emailAddress = this.editSponsorItemForm.get('emailAddress').value;
                sponsor.profileType = new Profile().ProfileType().find(pt => pt.isCompany);
                const eventProfileType = new Profile().EventProfileType().find(pt => pt.isSponsor);
                
                if (this.base64Image) {
                    try {
                        const imageRes =  await this.eventAgendaService.uploadProfileImage(this.base64Image);
                        sponsor['imageUrl'] = imageRes;
                    } catch (e) {
                        this.isSaving = false;
                        return;
                    }
                } else {
                    sponsor['imageUrl'] = null;
                }

                const profile = await this.eventAgendaService.createProfile(sponsor, this.eventFarmService.currentEvent.id, eventProfileType.slug);

                await this.eventAgendaService.updateProfileLinks(this.editSponsorItemForm, profile);
                this.drawerRef.close();
                this.eventAgendaService.sponsorsListOptions.pagination.currentPage = 1;
                await this.eventAgendaService.getProfiles(false);
            } catch (err) {
                this.messageService.error('Error adding sponsor');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private async saveSponsor() {
        if (this.isSaving === false) {
            this.isSaving = true;
            const fieldsToUpdate = {};

            // Populate fieldsToUpdate object/map with dirty name/value pairs
            this.determineDirtyFields(
                this.editSponsorItemForm.controls
            ).forEach(d => {
                fieldsToUpdate[d.name] = d.value;
            });
            try {
            
                if (this.base64Image) {
                    try {
                        const imageRes =  await this.eventAgendaService.uploadProfileImage(this.base64Image);
                        fieldsToUpdate['imageUrl'] = imageRes;
                    } catch (e) {
                        this.isSaving = false;
                        return;
                    }
                } else {
                    if (this.base64Image === null && this.sponsor.imageUrl !== null) {
                        fieldsToUpdate['imageUrl'] = null;
                    } else {
                        fieldsToUpdate['imageUrl'] = this.sponsor.imageUrl;
                    }
                }

                await this.eventAgendaService.updateSpeaker(this.sponsor.id, fieldsToUpdate);
                await this.eventAgendaService.updateProfileLinks(this.editSponsorItemForm, this.sponsor);
                this.messageService.success('Sponsor updated!');
                this.drawerRef.close();
                this.eventAgendaService.getProfiles(false);
            } catch (error) {
                this.messageService.error('Error updating sponsor');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private determineDirtyFields(controls) {
        const dirty = Object.keys(controls)
            .map((name) => {
                return { ...controls[name], name };
            })
            .filter((obj) => !obj.pristine);
        return dirty;
    }

    private async handleDeleteSponsor() {
        if (this.isDeleting) {
            this.isDeleting = false;
        }
        if (this.confirmDelete) {
            this.isDeleting = true;
            const deleteSuccess = await this.eventAgendaService.removeProfile(this.sponsor.id);

            this.isDeleting = false;
            this.confirmDelete = false;

            if (deleteSuccess === true) {
                this.drawerRef.close();
                await this.eventAgendaService.getProfiles(false);
            }
        }
        this.confirmDelete = true;
    }

    private allowSave() {

        if (this.canSaveImage === false) {
            return false;
        }

        return this.editSponsorItemForm.controls['company'].valid
        && this.editSponsorItemForm.controls['emailAddress'].valid;
    }

    private initializeFormControls() {
        let website = null;
        let linkedIn = null;
        let twitter = null;
        let facebook = null;
        let instagram = null;

        if (this.sponsor) {
            website = this.sponsor.profileLinks.find(pl => pl.link.linkType.isWeb);
            linkedIn = this.sponsor.profileLinks.find(pl => pl.link.linkType.isLinkedIn);
            twitter = this.sponsor.profileLinks.find(pl => pl.link.linkType.isTwitter);
            facebook = this.sponsor.profileLinks.find(pl => pl.link.linkType.isFacebook);
            instagram = this.sponsor.profileLinks.find(pl => pl.link.linkType.isInstagram);
        }

        this.editSponsorItemForm = new FormGroup({
            description: new FormControl(_.get(this, 'sponsor.description', null)),
            company: new FormControl(_.get(this, 'sponsor.company', null), Validators.required),
            imageUrl: new FormControl(_.get(this, 'sponsor.imageUrl', null)),
            emailAddress: new FormControl(_.get(this, 'sponsor.emailAddress', null), [ValidateEmail] ),
            website: new FormControl(_.get(website, 'link.url', ''), [ValidateUrl]),
            linkedIn: new FormControl(_.get(linkedIn, 'link.url', ''), [ValidateUrl]),
            twitter: new FormControl(_.get(twitter, 'link.url', ''), [ValidateUrl]),
            facebook: new FormControl(_.get(facebook, 'link.url', ''), [ValidateUrl]),
            instagram: new FormControl(_.get(instagram, 'link.url', ''), [ValidateUrl]),
        });

        this.canSaveImage = null;
    }

    public saveImageAsBase64(image) {
        this.base64Image = image;
        this.canSaveImage = true;
    }

    public imageSet($event) {
        this.canSaveImage = false;
    }

    close(): void {
        this.drawerRef.close(this.sponsor);
    }
}
