import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AlertService } from '../../../../../eventFarmAlert.service';
import * as _ from 'lodash';
import { Question } from '../../../../../../ApiClient/Models/Question/question';
import { QuestionCenterService } from '../../../question-center.service';
import { Answer } from '../../../../../../ApiClient/Models/Answer/answer';
import { AnswerBindingTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Question';
import { AnswerBinding } from '../../../../../../ApiClient/Models/Question/answer-binding';
@Injectable()
export class QuestionLogicService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private alertService: AlertService,
        private questionCenterService: QuestionCenterService,
    ) {
    }

    private currentAnswerBindings: AnswerBinding[] = [];

    public initServiceWithCurrentBindinds(bindings: AnswerBinding[]) {
        this.currentAnswerBindings = _.cloneDeep(bindings);
    }

    public async loadQuestionsForCenter() {
        await this.questionCenterService.getQuestions();
    }

    get allVisibleQuestions(): Question[] {
        return this.questionCenterService.visibleQuestions$.value
    }

    get allIndividualVisibleQuestions(): Question[] {
        return this.questionCenterService.visibleIndividualQuestions$.value
    }

    get allTransactionVisibleQuestions(): Question[] {
        return this.questionCenterService.visibleTransactionQuestions$.value
    }

    public async saveLogic(bindings: AnswerBinding[]) {
        try {
            await this.deleteRules(bindings);
            await this.createOrUpdateRules(bindings);
            this.message.success('Logic Saved');
            return true;
        } catch (err) {
            this.message.error('Error Saving Logic');
            return false;
        }
    }


    //logic rules

    private async deleteRules(bindings: AnswerBinding[]) {
        const promises = [];

        for (const existingBinding of this.currentAnswerBindings) {
            const inUI = bindings
                .find((a) => existingBinding.id === a.id);
            if (inUI) {
                continue;
            }

            promises.push(this.deleteRule(existingBinding.id));
        }

        return Promise.all(promises);
    }

    private async createOrUpdateRules(bindings: AnswerBinding[]) {
        const promises = [];

        for (const binding of bindings) {
            const inExisting = this.currentAnswerBindings
                .find((a) => binding.id === a.id);

            if (inExisting) {
                promises.push(this.updateRule(binding.id, binding.answer.id, binding.answerBindingType.slug));
            } else {
                promises.push(this.createRule(
                    binding.question.id,
                    binding.answer.id,
                    binding.answerBindingType.slug
                ));

            }
        }
        
        return Promise.all(promises);
    }


    private createRule(questionId: string, answerId: string, type: string) {
        return this.apiClient.getUseCaseFactory()
            .Question()
            .CreateAnswerBindingForQuestion(questionId, answerId, type)
            .toPromise();
    }

    private deleteRule(answerBindingId: string) {
        return this.apiClient.getUseCaseFactory()
            .Question()
            .RemoveAnswerBindingForQuestion(answerBindingId)
            .toPromise();
    }

    private updateRule(answerBindingId: string, answerId: string, type: string) {
        return this.apiClient.getUseCaseFactory()
            .Question()
            .UpdateAnswerBindingForQuestion(
                answerId,
                type,
                answerBindingId
            )
            .toPromise();
    }
}
