import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import * as fromRoot from '../../../store';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListBulkUpdateFormService} from '../GuestListBulkUpdateForm/guest-list-bulk-update-form.service';
import {GuestListFiltersService} from '../GuestListFilters/guest-list-filters.service';

@Component({
    selector: 'guest-list-stats',
    template: require('./guest-list-stats.html'),
    styles: [require('./guest-list-stats.scss')]
})
export class GuestListStatsComponent implements OnInit {

    constructor(
        private store: Store<fromRoot.AppState>, private drawerRef: NzDrawerRef,
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService
    ) {
    }



    ngOnInit() {
    }


}
