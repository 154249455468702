import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Operator, Observable ,  Subject } from 'rxjs';

import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { Invitation } from '../../../ApiClient/Models/Invitation/invitation';
import { EventFarmService } from '../../eventFarm.service';
import { AlertService } from '../../eventFarmAlert.service';
import {
    InvitationCreationTypeInterface,
    InvitationStatusTypeInterface
} from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';

@Injectable()
export class GuestListService {
    public counts: any = {};
    public access: any = {};
    public parameters: any = {
        currentDataRelationships: [],
        currentUserAttributes: [],
        lastModifiedTimestamp: 0,
        isCheckedIn: null,
        sortBy: '',
        sortDirection: '',
        activeQuery: '',
        pagination: {
            currentPage: 1,
            resultsPerPage: 20,
            nextPage: '',
            prevPage: '',
        },
        statuses: [],
    };
    public guests: any = [];
    public guestsMeta: any = {};
    public ticketTypes: any = {};
    public invitationsSource = new Subject<any>();
    public availableStatusSource = new Subject<any>();
    public invitations$ = this.invitationsSource.asObservable();
    public availableStatusSource$ = this.availableStatusSource.asObservable();

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private alertService: AlertService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
    ) {
    }

    get stacks(): Stack[] {
        return this.eventFarmService.currentEvent.stacks;
    }

    get invitationStatusTypes(): InvitationStatusTypeInterface[] {
        return this.apiClient.getTypeFactory().Invitation().InvitationStatusType();
    }

    get invitationCreationTypes(): InvitationCreationTypeInterface[] {
        return this.apiClient.getTypeFactory().Invitation().InvitationCreationType();
    }

    setCheckInNotes(invitation, note) {
        if (note == '') {
            note = null;
        };
        return this.apiClient.getUseCaseFactory().Invitation().SetCheckInNotes(invitation.id, note).toPromise();
    }

    setInvitationNotes(invitation, note) {
        if (note == '') {
            note = null;
        }
        return this.apiClient.getUseCaseFactory().Invitation().SetInvitationNotes(invitation.id, note).toPromise();
    }

    setInvitationCount(invitationId: string, count: number) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .ChangeInviteCount(invitationId, count)
            .toPromise();
    }

    setArrivalAlertAndEmailsPhoneNumbers(invitationId: string, emails: string[]|null, shouldSendArrivalAlert: boolean|null, phoneNumbers: string[]|null) {
        return this.apiClient.getUseCaseFactory().Invitation().SetArrivalAlertEmailsAndPhoneNumbers(invitationId, emails, shouldSendArrivalAlert, phoneNumbers).toPromise();
    }

    setProxyEmail(invitationId: string, email: string|null) {
        return this.apiClient.getUseCaseFactory().Invitation().SetProxyEmail(invitationId, email).toPromise();
    }

    createInvitation(invitation: CreateInvitationCommand, stackId: string, inviteCount: number): Observable<any> {
        const invitationId = null;
        return this.apiClient.getUseCaseFactory().Invitation().CreateInvitation(
            invitation.eventId,
            stackId,
            invitation.status,
            invitation.inviteSource,
            invitation.isCheckedIn,
            inviteCount,
            invitation.email,
            invitation.firstName,
            invitation.lastName,
            invitation.company,
            invitation.position,
            invitation.checkInNotes,
            invitationId,
            invitation.shouldSendInvitation,
            invitation.invitationNotes,
            invitation.title,
            invitation.telephone,
            invitation.other,
            null,
            null,
            null,
            invitation.externalId,
            null,
            invitation.proxyEmail
        );
    }
}

export interface CreateInvitationCommand {
    eventId: string;
    status: string;
    inviteSource: string;
    isCheckedIn: boolean;
    email?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    position?: string;
    checkInNotes?: string;
    shouldSendInvitation: boolean;
    invitationNotes?: string;
    title?: string;
    telephone?: string;
    other?: string;
    externalId: string;
    proxyEmail: string|null;
}

export interface UpdateInvitationCommand {
    invitationId: string;
    invitationStatus: string;
    company?: string;
    position?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    telephone?: string;
    title?: string;
    other?: string;
}
