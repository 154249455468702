import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Group } from '../../../ApiClient/Models/Group/group';
import { User } from '../../../ApiClient/Models/User/user';

@Injectable()
export class GuestListUserService {
    public selectedUser: User;
    public selectedUserSource = new Subject<any>();
    public selectedUser$ = this.selectedUserSource.asObservable();
    public poolContactsForUser: any = [];
    public poolContactsForUserSource = new Subject<any>();
    public poolContactsForUser$ = this.poolContactsForUserSource.asObservable();
    public selectedGroup: Group;
    public selectedGroupSource = new Subject<any>();
    public selectedGroup$ = this.selectedGroupSource.asObservable();
    public groupsForUser: any = [];
    public groupsForUserSource = new Subject<any>();
    public groupsForUser$ = this.groupsForUserSource.asObservable();

    constructor(
        private apiClient: EventFarmAPIClient,
    ) {
    }

    getUserInPool(poolId: string, userId: string) {
        return this.apiClient
            .getUseCaseFactory()
            .User()
            .GetUserInPool(
                poolId,
                userId,
                ['UserName', 'UserAddress', 'UserIdentifier'],
                ['info'],
            );
    }

    setUserAttribute(poolId: string, userId: string, attributeType: string, attributeValue: string) {
        return this.apiClient
            .getUseCaseFactory()
            .UserAttribute()
            .SetInfoUserAttribute(
                poolId,
                userId,
                attributeType,
                attributeValue,
            )
            .toPromise();
    }
}
