import { AbstractModel } from '../abstract-model';
import {PaymentGateway} from '../PaymentGateway/paymentGateway';

interface FormField {
    auth_mode_type: string;
    credentials: [{[key: string]: any}];
    name: string;
}

export class PoolPaymentGateway extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _isDefault: boolean;
    private _isSandbox: boolean;
    private _isDeleted: boolean;
    private _name: string;
    private _sortOrder: number;
    private _paymentGateway: PaymentGateway;

    public static fromApiResponse(data) {
        const poolPaymentGateway = new this(data.id);
        const attributes = data.attributes;
        poolPaymentGateway._isDefault = attributes.isDefault;
        poolPaymentGateway._isDeleted = attributes.isDeleted;
        poolPaymentGateway._isSandbox = attributes.isSandbox;
        poolPaymentGateway._name = attributes.name;
        poolPaymentGateway._paymentGateway = PaymentGateway.fromApiResponse(attributes.paymentGateway);
        return poolPaymentGateway;
    }

    public static fromEventResponse(data) {
        const poolPaymentGateway = new this(data.id);
        poolPaymentGateway._isDefault = data.isDefault;
        poolPaymentGateway._isDeleted = data.isDeleted;
        poolPaymentGateway._isSandbox = data.isSandbox;
        poolPaymentGateway._name = data.name;
        poolPaymentGateway._paymentGateway = PaymentGateway.fromApiResponse(data.paymentGateway);
        return poolPaymentGateway;
    }

    get isDefault(): boolean {
        return this._isDefault;
    }

    get isSandbox(): boolean {
        return this._isSandbox;
    }

    get isLive(): boolean {
        return !this._isSandbox;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    get name(): string {
        return this._name;
    }

    get sortOrder(): number {
        return this._sortOrder;
    }

    get paymentGateway(): PaymentGateway {
        return this._paymentGateway;
    }
}
