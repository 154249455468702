import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DebounceSearchComponent} from './debounce-search.component';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';

@NgModule({
    declarations: [DebounceSearchComponent],
    exports: [DebounceSearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzUtilitiesModule
    ],
})

export class DebounceSearchModule {
}
