import { Component, OnInit } from '@angular/core';
import { AccountInfoService } from '../account-info.service';
import { IconService } from '../../../CoreUI/Icons/icons.service';

@Component({
    selector: 'mobile-app-links',
    template: require('./mobile-app-links.html'),
    styles: [require('./mobile-app-links.scss')],
})

export class MobileAppLinksComponent implements OnInit {

    constructor(
        private accountUsageService: AccountInfoService,
        private iconService: IconService
    ) {}

    ngOnInit() {

    }
}