import { Component, OnInit, Input } from '@angular/core';
import { WebPresenceService } from '../../../web-presence.service';
import { RGBA } from 'ngx-color';
import { ThemeColorTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
const Color = require('color');

@Component({
    selector: 'color-picker',
    template: require('./color-picker.html'),
    styles: [require('./color-picker.scss'), require('../../../web-presence.scss')],
})

export class ColorPickerComponent implements OnInit {

    constructor(
        private webPresenceService: WebPresenceService
    ) {
    }

    @Input() color: ThemeColorTypeInterface;
    @Input() showEditState: boolean;
    private shouldDisplayColorPicker: boolean;
    private colorRGB: RGBA;

    ngOnInit() {
        this.shouldDisplayColorPicker = false;
        this.setRGB();
    }

    setRGB() {
        let c = Color(this.color.description).object();
        if (c.alpha) {
            c = {
                r: c.r,
                g: c.g,
                b: c.b,
                a: c.alpha
            };
        }
        this.colorRGB = c;
    }

    changeColor(event) {
        this.colorRGB = event.color.rgb;
    }


    /**
     * Used for showing the color in rgba format for css
     */
    getRGBA() {
        return `rgba(${this.colorRGB.r},${this.colorRGB.g},${this.colorRGB.b},${this.colorRGB.a ? this.colorRGB.a : '1.0'})`;
    }

    handleClick() {
        this.shouldDisplayColorPicker = true;
    }

    handleClose() {
        const removeAlpha = {
            r: this.colorRGB.r,
            g: this.colorRGB.g,
            b: this.colorRGB.b
        };

        if (this.colorRGB.a) {
            this.webPresenceService.setColorForSlug(this.color.slug, Color(removeAlpha).alpha(this.colorRGB.a).string());
        } else {
            this.webPresenceService.setColorForSlug(this.color.slug, Color(removeAlpha).string());
        }

        // Have to remove the alpha then re apply in order for the Color library to work
        this.shouldDisplayColorPicker = false;
    }
}
