import { tap, take, withLatestFrom, switchMap, filter, map, catchError, mergeMap, concatMap, shareReplay } from 'rxjs/operators';
import { of} from 'rxjs';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as eventActions from '../../actions/event';
import * as fromStore from '../../app.state';
import { WaitListService } from '../../../EventsModule/WaitList/waitList.service';
import { Invitation } from './../../../../ApiClient/Models/Invitation/invitation';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

@Injectable()
export class WaitListEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
    ) { }


    fetchWaitList$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchWaitList>(eventActions.FETCH_WAIT_LIST),
        withLatestFrom(this.store.select('router')),
        filter(([res, router]) => router.state.params.event),
        mergeMap(([res, router]) => this.waitListService.fetchWaitList(router.state.params.event, res.payload)),
        map((res: Invitation[]) => {
            return {
                meta: res['meta'],
                data: res['data'].map(invitation => Invitation.fromApiResponse(invitation))
            };
        }),
        switchMap(waitlist => [
            new eventActions.SetWaitListSuccess(waitlist),
        ]),
    ));


    // @ts-ignore
    removeInvitationFromWaitlist$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.RemoveInvitationFromWaitlist>(eventActions.REMOVE_INVITATION_FROM_WAITLIST),
        map(res => res.payload),
        mergeMap(data => {
            if (data.type !== 'recycled') {
                if (data.type === 'Confirmed') {
                    return this.apiClient.getUseCaseFactory().Invitation().PromoteInvitationsFromWaitlist(data.invitationId, true, data.sendNotification);
                } else {
                    return this.apiClient.getUseCaseFactory().Invitation().PromoteInvitationsFromWaitlist(data.invitationId, false, data.sendNotification);
                }
            }
            return this.apiClient.getUseCaseFactory().Invitation().UpdateInvitation(data.invitationId[0], data.stackId, 'recycled');
        }),
        map((res: any) => res.data.command.invitationIds ? res.data.command.invitationIds : [res.data.command.invitationId]),
        mergeMap((invitationIds: any) =>
            invitationIds.map(ids => new eventActions.RemoveInvitationFromWaitlistSuccess(ids))
        )
    ));
}
