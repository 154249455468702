import { Injectable } from '@angular/core';
import { InvitationOptionsService } from './invitationOptions.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';

@Injectable()

export class StatusSelectionService {

    constructor(
        private invitationOptionsService: InvitationOptionsService,
        private apiClient: EventFarmAPIClient,
    ) {
    }
}
