import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from '../../../_services/storage/local-storage.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';

@Component({
    selector: 'alert-banner',
    template: require('./alert-banner.html'),
    styles: [require('./alert-banner.scss')],
})

export class AlertBannerComponent implements OnInit, OnDestroy {

    constructor(
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private localStorageService: LocalStorageService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.event = val.data;
            this.getAlertBannerUpdates();
        });
    }

    private showAlertBanner: boolean;
    private type: 'success' | 'info' | 'warning' | 'error';
    private message: string;

    private eventUpdate$: Subscription;
    private event: EfEvent;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());


    ngOnInit() {
        this.resetBanner();

        this.localStorageService.resetNavBar.subscribe((resetNav) => {
           if (resetNav === true) {
                this.resetBanner();
           }
        });
    }

    private getAlertBannerUpdates() {
        const update = this.eventFarmService.getAlertBannerUpdates();
        this.showAlertBanner = update.showAlertBanner;
        this.type = update.type;
        this.message = update.message;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
        this.resetBanner();
    }

    private resetBanner() {
        this.showAlertBanner = false;
        this.type = 'warning';
        this.message = '';
    }
}
