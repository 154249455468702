import {Injectable, OnDestroy} from '@angular/core';
import {Store} from "@ngrx/store";
import * as _ from "lodash";
import {BehaviorSubject, Subscription} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {Invitation, UniqueStacksFromInvitations} from '../../../../ApiClient/Models/Invitation/invitation';
import {Stack} from '../../../../ApiClient/Models/Stack/stack';
import {EventFarmService} from '../../../eventFarm.service';
import * as fromRoot from '../../../store';


@Injectable()
export class GuestListBulkUpdateFormService implements OnDestroy {
    public isSelectModeEnabled: boolean = false;
    public setOfCheckedInvitations = new Map<string, Invitation>();
    private currentUserId = this.eventFarmService.currentUser?.id;
    private currentEventId = this.eventFarmService.currentEvent?.id;
    public availabilityCounts;
    public uniqueStacksFromSelections$ = new BehaviorSubject<any>(null);

    // Bulk update form values
    public availabilityCounts$ = this.store.select(fromRoot.getStackAvailabilityCounts).pipe(shareReplay())
    public postProcessInviteCountUpdateCountsByStack$ = new BehaviorSubject<any>(null)
    public postProcessAccessTypeUpdateCountsByStack$ = new BehaviorSubject<any>(null)

    formValues$ = new BehaviorSubject<any>(null)
    private availabilityCountsSub$: Subscription;
    public invitationIdsForPayload: string[] = this.arrayOfCheckInvitationIds;
    public updating: boolean = false;


    checked = false;
    indeterminate = false;
    listOfCurrentPageData: readonly Invitation[] = [];


    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private store: Store<fromRoot.AppState>
    ) {

        this.formValues$.subscribe(val => {
            if (val && val.selection?.value === 'inviteCounts') {
                this.handleFormChangeInviteCounts(val?.inviteCounts)
            } else if (val?.selection?.value === 'accessType') {
                if (val.accessType) this.handleFormChangeAccessType(val.accessType)
            }
        })

        this.availabilityCountsSub$ = this.availabilityCounts$.subscribe(val => {
            this.availabilityCounts = val;
        });
    }


    private handleFormChangeAccessType(accessType) {
        const uniqueStacksFromInvitation = UniqueStacksFromInvitations(this.arrayOfCheckInvitations)
        const uniqueStacks = _.uniqBy([...uniqueStacksFromInvitation, accessType], 'id');
        this.uniqueStacksFromSelections$.next(uniqueStacks)
        // Filter out invitations that are already part of the access type
        const filteredIds = this.arrayOfCheckInvitations
            .filter(invitation => invitation.stack.id !== accessType.id)
            .map(invitation => invitation.id);

        this.invitationIdsForPayload = filteredIds;

        this.postProcessAccessTypeUpdateCountsByStack$.next(this.processAccessTypeCountsByStack(accessType.id))
    }

    private handleFormChangeInviteCounts(inviteCounts) {
        if (inviteCounts === '') return;
        this.postProcessInviteCountUpdateCountsByStack$.next(this.processInviteCountsByStack(inviteCounts))
    }

    // Returns map where key is stackId and value is the new availability count if bulk update happens
    public processAccessTypeCountsByStack(stackId): Map<string, number> {
        // Availability counts
        const tmpCounts = this.availabilityCounts;

        const diffMap = this.generateAccessTypeCountsDiffMap(stackId)

        /* availabilityMap contains map where key is stackId and value is sum of current availability plus
         diff value determined above */
        const availabilityMap = new Map(diffMap);

        Array.from(availabilityMap.keys()).forEach(key => {
            const tmpVal = tmpCounts[key]?.totalUnused
            availabilityMap.set(key, tmpVal + diffMap.get(key))
        })

        // return availabilityMap;
        return availabilityMap;
    }

    private generateAccessTypeCountsDiffMap(stackId) {
        // diffMap contains map where key is stackId and value is sum of total tickets required or "put back" if bulk proceeds
        const diffMap = new Map();
        const targetStackId = stackId;

        this.arrayOfCheckInvitations.forEach(invitation => {
            const inviteCount = invitation.inviteCount;
            const sourceStackId = invitation.stack.id
            // We want to filter out scenario where source stack and target stack are the same
            if (sourceStackId === targetStackId) return;

            // handle source value
            if (diffMap.has(sourceStackId)) {
                const nextValue = diffMap.get(sourceStackId) + inviteCount
                diffMap.set(sourceStackId, nextValue);
            } else {
                diffMap.set(sourceStackId, inviteCount);
            }

            // handle target value
            if (diffMap.has(targetStackId)) {
                const nextValue = diffMap.get(targetStackId) - inviteCount
                diffMap.set(targetStackId, nextValue);
            } else {
                diffMap.set(targetStackId, -inviteCount);
            }
        })
        return diffMap;
    }

    // Returns map where key is stackId and value is the new availability count if bulk update happens
    public processInviteCountsByStack(inviteCounts): Map<string, number> {
        // Availability counts
        const tmpCounts = this.availabilityCounts;

        const diffMap = this.generateInviteCountsDiffMap(inviteCounts)

        /* availabilityMap contains map where key is stackId and value is sum of current availability plus
         diff value determined above */
        const availabilityMap = new Map(diffMap);

        Array.from(availabilityMap.keys()).forEach(key => {
            const tmpVal = tmpCounts[key]?.totalUnused
            availabilityMap.set(key, tmpVal + diffMap.get(key))
        })

        // return availabilityMap;
        return availabilityMap
    }

    private generateInviteCountsDiffMap(count) {
        // diffMap contains map where key is stackId and value is sum of total tickets required or "put back" if bulk proceeds
        const diffMap = new Map();
        this.arrayOfCheckInvitations.forEach(invitation => {
            const diff = invitation.inviteCount - count;
            const targetStackId = invitation.stack.id
            if (diffMap.has(targetStackId)) {
                const nextValue = diffMap.get(targetStackId) + diff
                diffMap.set(targetStackId, nextValue);
            } else {
                diffMap.set(targetStackId, diff);
            }
        })
        return diffMap;
    }

    updateCheckedSet(invitation: Invitation, checked: boolean): void {
        // Re-process counts
        if (checked) {
            this.setOfCheckedInvitations.set(invitation.id, invitation);
        } else {
            this.setOfCheckedInvitations.delete(invitation.id);
        }
    }

    onItemChecked(invitation: Invitation, checked: boolean): void {
        this.updateCheckedSet(invitation, checked);
        this.refreshCheckedStatus();
    }

    onItemToggleCheck(invitation: Invitation): void {
        if (this.setOfCheckedInvitations.has(invitation.id)) {
            this.setOfCheckedInvitations.delete(invitation.id)
        } else{
            this.setOfCheckedInvitations.set(invitation.id, invitation);
        }
        this.refreshCheckedStatus();
    }

    resetCheckedInvitations() {
        this.setOfCheckedInvitations.clear()
    }

    clearBulkUpdate(){
        this.resetCheckedInvitations();
        this.handleSetSelectMode(false)
    }

    onAllChecked(value: boolean): void {
        this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item, value));
        this.refreshCheckedStatus();
        this.handleSetSelectMode(value)
    }

    refreshCheckedStatus(): void {
        this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedInvitations.has(item.id));
        this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedInvitations.has(item.id)) && !this.checked;
        this.invitationIdsForPayload = this.arrayOfCheckInvitationIds;
        this.uniqueStacksFromSelections$.next(UniqueStacksFromInvitations(this.arrayOfCheckInvitations));
    }


    onCurrentPageDataChange($event: readonly Invitation[]): void {
        this.listOfCurrentPageData = $event;
        this.refreshCheckedStatus();
    }

    handleToggleSelectMode() {
        this.isSelectModeEnabled = !this.isSelectModeEnabled;
        if (!this.isSelectModeEnabled) {
            this.resetCheckedInvitations()
        }
    }

    get arrayOfCheckInvitations() {
        return Array.from(this.setOfCheckedInvitations.values())
    }

    get arrayOfCheckInvitationIds() {
        return this.arrayOfCheckInvitations.map(i => i.id);
    }

    get invitationsAreSelected(): boolean{
        return this.arrayOfCheckInvitationIds.length > 0;
    }
    private uniqueStacksFromCheckedInvitations: Stack[] = UniqueStacksFromInvitations(this.arrayOfCheckInvitations)


    handleSetSelectMode(val: boolean) {
        this.isSelectModeEnabled = val;
    }


    bulkUpdateInviteCounts(count: number) {
        return this.apiClient.getUseCaseFactory().Invitation().SetInviteCountForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            count
        );
    }

    bulkUpdateAccessTypes(stackId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().SetStackForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            stackId
        );
    }

    bulkUpdateGuestPassCounts(count: number) {
        return this.apiClient.getUseCaseFactory().Invitation().SetGuestPassCountForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            count
        );

    }

    bulkUpdateSetProxyEmail(email: string) {
        return this.apiClient.getUseCaseFactory().Invitation().SetProxyEmailForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            email
        );

    }

    bulkUpdateInvitationNotes(notes: string) {
        return this.apiClient.getUseCaseFactory().Invitation().SetInvitationNotesForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            notes
        );

    }

    bulkUpdateCheckinNotes(notes: string) {
        return this.apiClient.getUseCaseFactory().Invitation().SetCheckInNotesForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            notes
        );
    }

    bulkUpdateArrivalAlertDetails(shouldSendArrivalAlert, emails, phoneNumbers) {
        return this.apiClient.getUseCaseFactory().Invitation().SetArrivalAlertEmailsAndPhoneNumbersForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            emails,
            shouldSendArrivalAlert,
            phoneNumbers
        );
    }

    bulkUpdateCheckInInvitations() {
        return this.apiClient.getUseCaseFactory().Invitation().CheckInInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId
        );
    }

    bulkUpdateStatuses(status: 'affirmative' | 'unconfirmed' | 'recycled') {
        return this.apiClient.getUseCaseFactory().Invitation().SetStatusesForInvitations(
            this.invitationIdsForPayload,
            this.currentEventId,
            this.currentUserId,
            status
        );
    }

    ngOnDestroy() {
        this.availabilityCountsSub$.unsubscribe();
    }

}
