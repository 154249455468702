import { AbstractModel } from '../abstract-model';
import { DateTimeUtility } from '../../../Utilities/Time/dateTime.utility';

export class Contract extends AbstractModel {
    private timeUtility: DateTimeUtility;

    private _planType?: string = null;
    private _startDate?: string = null;
    private _endDate?: string = null;
    private _responseTime?: string = 'Within 48 Business hrs';

    constructor(id = null) {
        super(id);
        this.timeUtility = new DateTimeUtility();
    }

    public static fromApiResponse(data) {
        const contract = new this(data.id);
        if (data.meta && data.meta.isInitialized && data.attributes) {
            contract._planType = data.attributes.poolContractType.name;
            contract._startDate = data.attributes.startDate.date;
            contract._endDate = data.attributes.endDate.date;
        }

        if (data.extraAttributes.poolContractPlan) {
            contract._responseTime = data.extraAttributes.poolContractPlan.csmResponseTime;
        }

        return contract;
    }

    get planType(): string | null {
        return this._planType;
    }

    get startDate(): string | null {
        return this._startDate;
    }

    get endDate(): string | null {
        return this._endDate;
    }

    get responseTime(): any {
        return this._responseTime;
    }

    getformattedStartDate(format: string = 'MMM D, YYYY'): string {
        return this.timeUtility.format(this._startDate, format);
    }

    getformattedEndDate(format: string = 'MMM D, YYYY'): string {
        return this.timeUtility.format(this._endDate, format);
    }
}
