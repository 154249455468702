export const RouteDefinitions = {

    'admin.user': '/users/dashboard/admin/user',
    'admin.manage': '/users/dashboard/admin/manage',
    'admin.data': '/users/dashboard/admin/data',

    'events.add': '/users/dashboard/team/:poolId/event/add-event',
    'events.info': '/users/dashboard/team/:poolId/event/:eventId/event-info',
    'events.settings': '/users/dashboard/team/:poolId/event/:eventId/event-settings',

    'events.guest-list': '/users/dashboard/team/:poolId/event/:eventId/guest-list',
    'events.guest-list.list': '/users/dashboard/team/:poolId/event/:eventId/guest-list/list',
    'events.guest-list.invite': '/users/dashboard/team/:poolId/event/:eventId/guest-list/invite',
    'events.guest-list.invite.group': '/users/dashboard/team/:poolId/event/:eventId/guest-list/invite/group',
    'events.guest-list.invite.individual': '/users/dashboard/team/:poolId/event/:eventId/guest-list/invite/individual',

    'events.wait-list': '/users/dashboard/team/:poolId/event/:eventId/wait-list',

    'ticketblocks': '/users/dashboard/ticketblocks/:eventId',
    'ticketblocks.list': '/users/dashboard/ticketblocks/:eventId/list',
    'ticketblocks.invite': '/users/dashboard/ticketblocks/:eventId/invite',
    'ticketblocks.invite.group': '/users/dashboard/ticketblocks/:eventId/invite/group',
    'ticketblocks.invite.individual': '/users/dashboard/ticketblocks/:eventId/invite/individual',

    'events.event-access': '/users/dashboard/team/:poolId/event/:eventId/event-access',
    'events.child-events': '/users/dashboard/team/:poolId/event/:eventId/child-events',
    'events.invitee-segmentation': '/users/dashboard/team/:poolId/event/:eventId/invitee-segmentation',
    'events.integrations': '/users/dashboard/team/:poolId/event/:eventId/event-integrations',
    'events.web-presence': '/users/dashboard/team/:poolId/event/:eventId/web-presence',
    'events.web-form': '/users/dashboard/team/:poolId/event/:eventId/web-form',

    'events.questions': '/users/dashboard/team/:poolId/event/:eventId/questions',
    'events.promotions': '/users/dashboard/team/:poolId/event/:eventId/event-promotions',
    'events.payments-promos': '/users/dashboard/team/:poolId/event/:eventId/payments-promos',
    'events.utilities': '/users/dashboard/team/:poolId/event/:eventId/event-utilities',

    'events.reports.legacy': '/users/dashboard/team/:poolId/event/:eventId/reports/generate',
    'events.reports.v2': '/users/dashboard/team/:poolId/event/:eventId/reports/create',
    'events.reports.email-stats': '/users/dashboard/team/:poolId/event/:eventId/reports/email-stats',
    'events.reports.message-stats': '/users/dashboard/team/:poolId/event/:eventId/reports/message-stats',

    'events.email-communication.invitations-confirmations': '/users/dashboard/team/:poolId/event/:eventId/email-center/invitations-confirmations',
    'events.edc': '/users/dashboard/team/:poolId/event/:eventId/email-center',
    'events.edc.guest-messaging': '/users/dashboard/team/:poolId/event/:eventId//email-center/guest-messaging',
    'events.edc.scheduled': '/users/dashboard/team/:poolId/event/:eventId//email-center/scheduled',

    'pools.dashboard': '/users/dashboard',
    'pools.events-list': '/users/dashboard/team/:poolId/events-list',
    'pools.create-event': '/users/dashboard/team/:poolId/create-event',
    'pools.create-event.full': '/users/dashboard/team/:poolId/create-event/full-event',
    'pools.create-event.cio': '/users/dashboard/team/:poolId/create-event/cio-event',
    'pools.create-event.free-cio': '/users/dashboard/team/:poolId/create-event/get-free-event',

    'pools.my-account': '/users/dashboard/team/:poolId/my-account',
    'pools.my-account.overview': '/users/dashboard/team/:poolId/my-account/overview',
    'pools.my-account.payment-settings': '/users/dashboard/team/:poolId/my-account/payment-settings',

    'pools.my-account.salesforce': '/users/dashboard/team/:poolId/my-account/salesforce',
    'pools.address-book': '/users/dashboard/team/:poolId/address-book',
    'pools.address-book.list': '/users/dashboard/team/:poolId/address-book/list',
    'pools.address-book.add-group': '/users/dashboard/team/:poolId/address-book/add-group',
    'pools.address-book.add-user': '/users/dashboard/team/:poolId/address-book/add-user',
    'pools.address-book.edit-group': '/users/dashboard/team/:poolId/address-book/edit-group/:groupId',
    'pools.address-book.edit-user': '/users/dashboard/team/:poolId/address-book/edit-user/:userId',

    'pools.uploader': '/users/dashboard/team/:poolId/upload/choose',
    'pools.uploader.preview': '/users/dashboard/team/:poolId/upload/preview',
    'pools.uploader.processing': '/users/dashboard/team/:poolId/upload/processing',
    'pools.uploader.finish': '/users/dashboard/team/:poolId/upload/finish',

    'tests.no-params': '/users/dashboard/tests',
    'tests.one-params': '/users/dashboard/tests/:eventId',
    'tests.two-params': '/users/dashboard/tests/:eventId/:poolId',

    'events.efx.config': '/users/dashboard/team/:poolId/event/:eventId/efx/config',
    'events.efx.station': '/users/dashboard/team/:poolId/event/:eventId/efx/station',
    'events.efx.module.config': '/users/dashboard/team/:poolId/event/:eventId/efx/config/module-config/:moduleId',

    'events.sms.create': '/users/dashboard/team/:poolId/event/:eventId/sms/create',
    'events.sms.reports': '/users/dashboard/team/:poolId/event/:eventId/sms/reports',

    'events.exhibitors': '/users/dashboard/team/:poolId/event/:eventId/exhibitors',
    'events.event-agenda': '/users/dashboard/team/:poolId/event/:eventId/event-agenda',
    'events.agenda.speakers': '/users/dashboard/team/:poolId/event/:eventId/agenda/speakers',
    'events.agenda.sessions': '/users/dashboard/team/:poolId/event/:eventId/agenda/sessions',
    'events.agenda.tracks': '/users/dashboard/team/:poolId/event/:eventId/agenda/tracks',
    'events.agenda.sponsors': '/users/dashboard/team/:poolId/event/:eventId/agenda/sponsors'
};
