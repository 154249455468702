import {Action} from '@ngrx/store';
import {Allotment} from '../../../../ApiClient/Models/Pool/allotment';

export const ADD_ALLOTMENT_TO_ADMIN_TEAM = '[Admin/Team] Add Allotment For Team';
export const ADD_ALLOTMENT_TO_ADMIN_TEAM_SUCCESS = '[Admin/Team] Add Allotment For Team Success';
export const ADD_ALLOTMENT_TO_ADMIN_TEAM_FAIL = '[Admin/Team] Add Allotment For Team Fail';

export const UPDATE_ALLOTMENT_FOR_ADMIN_TEAM = '[Admin/Team] Update Allotment For Team';
export const UPDATE_ALLOTMENT_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Update Allotment For Team Success';
export const UPDATE_ALLOTMENT_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Update Allotment For Team Fail';

export const FETCH_ALLOTMENTS_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Allotments For Team';
export const FETCH_ALLOTMENTS_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Allotments For Team Success';
export const FETCH_ALLOTMENTS_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Allotments For Team Fail';

export class AddAllotmentToAdminTeam implements Action {
    readonly type = ADD_ALLOTMENT_TO_ADMIN_TEAM;
    constructor(public payload: {teamId: string, type: 'eventfarm' | 'cio' | 'listly', amount: number}) { }
}

export class AddAllotmentToAdminTeamSuccess implements Action {
    readonly type = ADD_ALLOTMENT_TO_ADMIN_TEAM_SUCCESS;
    constructor(public payload: Allotment) { }
}

export class AddAllotmentToAdminTeamFail implements Action {
    readonly type = ADD_ALLOTMENT_TO_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class UpdateAllotmentForAdminTeam implements Action {
    readonly type = UPDATE_ALLOTMENT_FOR_ADMIN_TEAM;
    constructor(public payload: {teamId: string, allotmentId: string, amount: number}) { }
}

export class UpdateAllotmentForAdminTeamSuccess implements Action {
    readonly type = UPDATE_ALLOTMENT_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: Allotment) { }
}

export class UpdateAllotmentForAdminTeamFail implements Action {
    readonly type = UPDATE_ALLOTMENT_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class FetchAllotmentsForAdminTeam implements Action {
    readonly type = FETCH_ALLOTMENTS_FOR_ADMIN_TEAM;
    constructor(public payload: string) { }
}

export class FetchAllotmentsForAdminTeamSuccess implements Action {
    readonly type = FETCH_ALLOTMENTS_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: Allotment[]) { }
}

export class FetchAllotmentsForAdminTeamFail implements Action {
    readonly type = FETCH_ALLOTMENTS_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export type AdminAllotmentAction =
    AddAllotmentToAdminTeam|
    AddAllotmentToAdminTeamSuccess |
    AddAllotmentToAdminTeamFail |
    UpdateAllotmentForAdminTeam|
    UpdateAllotmentForAdminTeamSuccess |
    UpdateAllotmentForAdminTeamFail |
FetchAllotmentsForAdminTeam |
FetchAllotmentsForAdminTeamSuccess |
FetchAllotmentsForAdminTeamFail;
