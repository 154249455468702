import { Component, OnInit } from '@angular/core';
import { AccountInfoService } from '../account-info.service';

@Component({
    selector: 'total-contacts',
    template: require('./total-contacts.html'),
    styles: [require('./total-contacts.scss')],
})

export class TotalContactsComponent implements OnInit {

    readonly addressOff = require('./../assets/address-off');

    constructor(
        private accountUsageService: AccountInfoService,
    ) {}

    ngOnInit() {

    }
}