import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../../eventFarm.service';
import { ModalService } from './../../modal.service';

@Component({
    template: require('./request-canvas.html'),
})

export class RequestCanvasComponent {

    isFormValid = true;

    status = {
        requesting: false,
        loaded: false
    };

    constructor(
        private formBuilder: FormBuilder,
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private modalService: ModalService
    ) {}

    when = [
        {id: 1, value: 'This week'},
        {id: 2, value: 'This month'},
        {id: 3, value: 'Next month'},
        {id: 4, value: 'Other'},
        {id: 5, value: 'I don\'t know'}
    ];

    type = [
        {id: 1, value: 'Brand Awareness Event'},
        {id: 2, value: 'Trade Show'},
        {id: 3, value: 'Conference'},
        {id: 4, value: 'Roadshow'},
        {id: 5, value: 'Internal Meeting'},
        {id: 6, value: 'Recruiting Event'},
        {id: 7, value: 'Fundraising Event'},
    ];

    experience = [
        {id: 1, value: 'Total Novice'},
        {id: 2, value: 'Some Experience'},
        {id: 3, value: 'Very Experienced'},
        {id: 4, value: 'I\'m a Pro!'}
    ];

    private requestFeatureForm: FormGroup = this.formBuilder.group({
        when: [null],
        type: [null],
        experience: [null],
    });

    sendUpgradeRequestToCsm() {
        this.isFormValid = !(Object.values(this.requestFeatureForm.value).filter(val => val === null).length);

        if (this.isFormValid) {
            this.status.requesting = true;
            this.status.loaded = false;
            const formToString = Object.entries(this.requestFeatureForm.value).map(i =>  `${i[0]}: ${i[1]}`).join('\n');
            this.apiClient.getUseCaseFactory().Pool().SendUpgradeRequestToCsm(
                this.eventFarmService.currentTeam.id,
                this.eventFarmService.currentUser.id,
                null,
                ['canvas'],
                formToString
            ).subscribe(
                () => {
                    this.status.requesting = false;
                    this.status.loaded = true;
                    document.getElementById('request-canvas').remove();
                },
                (err) => console.log(err)
            );
        }
    }
}
