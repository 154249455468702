import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxIntlTelInputModule} from '@khazii/ngx-intl-tel-input';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { AddressBookRoutingModule } from './addressBook-routing.module';
import { AddressBookComponent } from './addressBook.component';
import { AddressBookUserListComponent } from './User/addressBookUserList.component';
import { AddressBookGroupListComponent } from './Group/addressBookGroupList.component';
import { AddressBookAddGroupModalWrapperComponent } from './Group/Add/addressBookAddGroupModalWrapper.component';
import { AddressBookAddGroupComponent } from './Group/Add/addressBookAddGroup.component';
import { AddressBookListComponent } from './addressBookList.component';
import { AddressBookService } from './addressBook.service';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { UserFormModule } from '../../CoreUI/UserForm/userForm.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { AddressBookGroupService } from './Group/addressBookGroup.service';
import { AddressBookUserService } from './User/addressBookUser.service';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { AddressBookGlobalSearchComponent } from './addressBookGlobalSearch.component';
import { AddressBookAddUserComponent } from './User/Add/addressBookAddUser.component';
import { AddressBookEditUserComponent } from './User/Edit/addressBookEditUser.component';
import { AddressBookAddUserModalWrapperComponent } from './User/Add/addressBookAddUserModalWrapper.component';
import { AddressBookEditGroupModalWrapperComponent } from './Group/Edit/addressBookEditGroupModalWrapper.component';
import { AddressBookEditUserModalWrapperComponent } from './User/Edit/addressBookEditUserModalWrapper.component';
import { AddressBookUserBasicDetailDisplayComponent } from './User/Edit/addressBookUserBasicDetailDisplay.component';
import { AddressBookAddGroupsForUserTaggerComponent } from './User/Add/addressBookAddGroupsForUserTagger.component';
import { AddressBookEditGroupsForUserTaggerComponent } from './User/Edit/addressBookEditGroupsForUserTagger.component';
import { AddressBookUserActivityComponent } from './User/Edit/addressBookUserActivity.component';
import { AddressBookUserInvitationsComponent } from './User/Edit/addressBookUserInvitations.component';
import { ConfirmAddUserDeactivateGuard } from './addressBookAddUserGuard.service';
import { DropdownSelectModule } from '../../CoreUI/DropdownSelect/dropdown-select.module';
import { CheckboxModule } from '../../CoreUI/Checkbox/checkbox.module';
import { ConfirmEditUserDeactivateGuard } from './addressBookEditUserGuard.service';
import { AddressBookBulkAddUsersToGroup } from './User/Bulk/address-book-bulk-add-users-to-group.component';
import { InvitationOptionsModule } from '../../CoreUI/InvitationOptions/invitationOptions.module';
import { GuestListViewModule } from '../../CoreUI/GuestListTableView/guest-list-view.module';
import { ChartModule } from '../../CoreUI/Charts/ef-chart.module';
import { AddressBookEditGroupComponent } from './Group/Edit/addressBookEditGroup.component';
import { GroupTypeaheadModule } from '../../CoreUI/Typeahead/group-typeahead.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [AddressBookComponent],
    declarations: [
        AddressBookComponent,
        AddressBookListComponent,
        AddressBookGlobalSearchComponent,
        AddressBookGroupListComponent,
        AddressBookUserListComponent,
        AddressBookAddGroupModalWrapperComponent,
        AddressBookAddUserModalWrapperComponent,
        AddressBookEditUserModalWrapperComponent,
        AddressBookAddUserComponent,
        AddressBookEditUserComponent,
        AddressBookUserBasicDetailDisplayComponent,
        AddressBookAddGroupComponent,
        AddressBookEditGroupComponent,
        AddressBookEditGroupModalWrapperComponent,
        AddressBookAddGroupsForUserTaggerComponent,
        AddressBookEditGroupsForUserTaggerComponent,
        AddressBookUserActivityComponent,
        AddressBookUserInvitationsComponent,
        AddressBookBulkAddUsersToGroup,
    ],
    entryComponents: [
        AddressBookAddGroupComponent,
        AddressBookEditGroupComponent,
        AddressBookAddUserComponent,
        AddressBookEditUserComponent,
        AddressBookBulkAddUsersToGroup,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilitiesModule,
        ModalModule,
        ErrorModule,
        UserFormModule,
        LoadingOverlayModule,
        GroupTypeaheadModule,
        DropdownSelectModule,
        CheckboxModule,
        InvitationOptionsModule,
        GuestListViewModule,
        ChartModule,
        CheckboxModule,
        AddressBookRoutingModule,
        NgxUtilitiesModule,
        NgxIntlTelInputModule

    ],
    providers: [
        ConfirmAddUserDeactivateGuard,
        ConfirmEditUserDeactivateGuard,
        AddressBookService,
        AddressBookGroupService,
        AddressBookUserService,
        ModalService,
    ],
})

export class AddressBookModule {
}
