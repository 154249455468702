import { AbstractModel } from '../abstract-model';
import { UserAddress } from './user-address';
import { UserAttribute } from './user-attribute';
import { UserIdentifier } from './user-identifier';
import { UserName } from './user-name';
import { EventRoles, IEventRoles } from '../EventRole/event-role';
import moment = require('moment');
import {UserHealthPass} from './user-healthpass';

export class User extends AbstractModel {

    private _name?: UserName = null;
    private _identifier?: UserIdentifier = null;
    private _addresses?: UserAddress[] = [];
    private _attributes?: UserAttribute[] = [];
    private _healthPass?: UserHealthPass = null;
    private _primaryEmail?: string | null = null;
    private _confirmedAt?: number | null = null;
    private _admin: boolean = false;
    private _isTeamManager: boolean = false;
    private _eventRoles?: IEventRoles;

    constructor(id) {
        super(id);
    }

    get name(): UserName {
        return this._name;
    }

    get id(): string {
        return this._id;
    }

    get isTeamManager(): boolean {
        return this._isTeamManager;
    }

    get eventRoles(): IEventRoles|null {
        return this._eventRoles;
    }

    set eventRoles(value: IEventRoles|null) {
        this._eventRoles = value;
    }

    set name(value: UserName) {
        this._name = value;
    }

    get identifier(): UserIdentifier {
        return this._identifier;
    }

    set identifier(value: UserIdentifier) {
        this._identifier = value;
    }


    get healthPass(): UserHealthPass {
        return this._healthPass;
    }

    set healthPass(value: UserHealthPass) {
        this._healthPass = value;
    }


    get addresses(): UserAddress[] {
        return this._addresses;
    }

    get attributes(): UserAttribute[] {
        return this._attributes;
    }

    get phoneNumber(): string|null {
        return this.getAttributeType('telephone');
    }

    public hasAttributeType(val: string): boolean {
        if (this.getAttributeType(val) != null) {
            return true;
        }

        return false;
    }

    public getAttributeType(val: string): string|null {
        const array: UserAttribute[] = this._attributes.filter((attribute: UserAttribute) => {
            return attribute.key === val;
        });

        if (array.length > 0) {
            return array[0].value;
        }

        return null;
    }

    public getAttributeByKey(val: string): UserAttribute|null {
        const array: UserAttribute[] = this._attributes.filter((attribute: UserAttribute) => {
            return attribute.key === val;
        });

        if (array.length > 0) {
            return array[0];
        }

        return null;
    }

    get primaryEmail(): string | null {
        return this._primaryEmail;
    }

    get confirmedAt(): number | null {
        return this._confirmedAt;
    }

    get admin(): boolean {
        return this._admin;
    }

    private static bindRelationships(user: User, {invitations = [], poolContacts = [], userNames = [], userAddresses = [], userAttributes = [], userIdentifiers = [], userHealthPasses = []}): User {
        if (userNames.length) {
            user._name = UserName.fromApiResponse(userNames[0]) || null;
        } else if (
            invitations.length
            && invitations[0].relationships
            && invitations[0].userName
        ) {
            user._name = UserName.fromApiResponse(invitations[0].userName) || null;
        }

        if (userAddresses.length) {
            userAddresses.forEach((address) => {
                user._addresses.push(UserAddress.fromApiResponse(address));
            });
        }

        if (userAttributes.length) {
            userAttributes.forEach((attribute) => {
                user._attributes.push(UserAttribute.fromApiResponse(attribute));
            });
            const userAttrs = userAttributes.reduce((attrs, item) => {
                return {
                    ...attrs,
                    [item.attributes.value.key]: item.attributes.value.value
                };
            }, {});
            Object.assign(user, userAttrs);
        }

        if (userIdentifiers.length) {
            const identifiers = userIdentifiers.filter((identifier) => identifier.attributes.isPrimary);
            if (identifiers.length) {
                user._identifier = UserIdentifier.fromApiResponse(identifiers[0]);
            }
        }

        if (userHealthPasses.length) {
            if (userHealthPasses.length) {
                user._healthPass = UserHealthPass.fromApiResponse(userHealthPasses[0]);
            }
        }

        if (poolContacts.length) {
            const { poolContactType } = poolContacts[0].attributes;
            user._isTeamManager = poolContactType ? poolContactType.isFull : false;
        }

        return user;
    }

    public static fromApiResponse({id = null, attributes = null, extraAttributes = null, relationships = null, primaryEmail = null}) {
        const user = new this(id);

        if (attributes) {
            user._primaryEmail = attributes.primaryEmail || null;
            user._confirmedAt = attributes.confirmedAt || null;
        }

        if (extraAttributes) {
            user._admin = extraAttributes.isEFAdmin || false;
            user._eventRoles = new EventRoles(extraAttributes.eventRoles || {});
        }

        if (primaryEmail && !user._primaryEmail) {
            user._primaryEmail = primaryEmail;
        }

        if (relationships) {
            return User.bindRelationships(user, relationships);
        } else {
            return user;
        }
    }

    set primaryEmail(value: string) {
        this._primaryEmail = value;
    }

    static getFullNameWithEmail(userName: UserName = null, userIdentifier: UserIdentifier = null): string {
        if (userName !== null) {
            return userName.getFullNameWithIdentifier(userIdentifier);
        } else if (userIdentifier !== null) {
            return userIdentifier.identifier;
        } else {
            return 'No User Identifier Available';
        }
    }

    get healthPassScoringTime(): string {
        return  moment(this.healthPass.scoreTime).format('LT L');
    }

}
