import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import * as fromRoot from '../../../store';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListBulkUpdateFormService} from "../GuestListBulkUpdateForm/guest-list-bulk-update-form.service";
import {GuestListFiltersService} from '../GuestListFilters/guest-list-filters.service';

@Component({
    selector: 'guest-list-result-count',
    template: require('./guest-list-result-count.html'),
    styles: [require('./guest-list-result-count.scss')]
})
export class GuestListResultCountComponent implements OnInit {

    constructor(
        private store: Store<fromRoot.AppState>, private drawerRef: NzDrawerRef,
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService
    ) {
    }

    get hasFiltersApplied(): boolean {
        return this.guestListFiltersService.filtersApplied ||
            this.guestListViewService.isQueryApplied;
    }

    get hasSelections(): boolean {
        return this.guestListBulkUpdateFormService.invitationsAreSelected
    }

    get withoutFilters(): boolean {
        return !(this.hasFiltersApplied) &&
            !(this.hasSelections);
    }

    get selectedWithoutFilters(): boolean {
        return !(this.hasFiltersApplied) &&
            (this.hasSelections)
    }

    get withFilters(): boolean {
        return (this.hasFiltersApplied) &&
            !(this.hasSelections)
    }

    get selectedWithFilters(): boolean {
        return (this.hasFiltersApplied) &&
            (this.hasSelections)
    }

    get pageSizeWithoutFilters(): number {
        if (this.guestListViewService.isOnLastPage) {
            return this.guestListViewService.totalRecords - ((this.guestListViewService.currentPage - 1) * this.guestListViewService.itemsPerPage);
        }

        return Math.min(this.guestListViewService.itemsPerPage, this.guestListViewService.totalRecords);
    }

    get pageSizeWithFilters(): number {
        if (this.guestListViewService.isOnLastPage) {
            return this.guestListViewService.guestsMeta?.totalResults - ((this.guestListViewService.currentPage - 1) * this.guestListViewService.itemsPerPage);
        }
        return Math.min(this.guestListViewService.parameters.pagination.itemsPerPage, this.guestListViewService.totalRecords);
    }

    ngOnInit() {
    }


}
