import { animate, style, transition, trigger } from '@angular/animations';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'loader-gray',
    template: require('./loader-gray.html'),
    styles: [require('./loader-gray.scss')],
})

export class LoaderGrayComponent implements OnInit {
    constructor(
    ) {
    }

    @Input() set loading(value: boolean) {
        this._loading = value;
    }

    private _loading: boolean = false;

    ngOnInit() {
    }

    get isLoading(): boolean {
        return this._loading;
    }

}
