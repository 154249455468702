import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';

import { EFXComponent } from './efx.component';

import { EFXAdminComponent } from './Admin/efx-admin.component';
import { EFXSettingsComponent } from './Admin/Settings/efx-settings.component';
import { EFXModulesComponent } from './Admin/Modules/efx-modules.component';
import { EFXModuleComponent } from './Admin/Modules/Module/efx-module.component';
import { EFXContactComponent } from './Admin/Contact/efx-contact.component';
import { EFXRoutingModule } from './efx-routing.module';
import { EFXAdminService } from './Admin/efx-admin.service';
import { EFXInfoComponent } from './Admin/Info/efx-info.component';
import { EFXNFCComponent } from './Admin/Contact/NFC/efx-nfc.component';
import { EFXSMSComponent } from './Admin/Contact/SMS/efx-sms.component';
import { EFXSMSArrivalComponent } from './Admin/Contact/SMSArrival/efx-sms-arrival.component';
import { EFXSMSArrivalConfigureComponent } from './Admin/Contact/SMSArrivalConfigure/efx-sms-arrival-configure.component';
import { EFXAppDownloadComponent } from './Admin/Settings/AppDownload/efx-app-download.component';
import { EFXPasscodeComponent } from './Admin/Settings/Passcode/efx-passcode.component';
import { EFXModuleListComponent } from './Admin/Modules/ModuleList/efx-module-list.component';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { StationComponent } from './Station/efx-station.component';
import { EfxStationService } from './Station/efx-station.service';
import { SmsConfigureComponent } from './Station/_shared/SmsConfigure/sms-configure.component';
import { EditStationComponent } from './Station/StationList/EditStation/edit-station.component';
import { StationListComponent } from './Station/StationList/station-list.component';
import { DebounceSearchModule } from '../../CoreUI/DebounceSearch/debounce-search.module';
import { StationItemComponent } from './Station/StationList/StationItem/station-item.component';
import { TruncatePipe } from '../../../Utilities/truncate.pipe';
import { EventAgendaModule } from '../EventAgenda/event-agenda.module';
import { EmailConfigureComponent } from './Station/_shared/EmailConfigure/email-configure.component';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { ScreenConfigureComponent } from './Station/_shared/ScreenConfigure/screen-configure.component';

@NgModule({
    bootstrap: [EFXComponent],
    declarations: [
        EFXAdminComponent,
        EFXComponent,
        EFXSettingsComponent,
        EFXModulesComponent,
        EFXModuleComponent,
        EFXContactComponent,
        EFXNFCComponent,
        EFXSMSComponent,
        EFXSMSArrivalComponent,
        EFXSMSArrivalConfigureComponent,
        EFXInfoComponent,
        EFXAppDownloadComponent,
        EFXPasscodeComponent,
        EFXModuleListComponent,
        StationComponent,
        SmsConfigureComponent,
        EmailConfigureComponent,
        EditStationComponent,
        StationListComponent,
        StationItemComponent,
        TruncatePipe,
        ScreenConfigureComponent
    ],
    entryComponents: [
        EFXComponent,
        StationComponent,
        SmsConfigureComponent,
        EmailConfigureComponent,
        EditStationComponent,
        ScreenConfigureComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        EFXRoutingModule,
        NzUtilitiesModule,
        ReactiveFormsModule,
        DebounceSearchModule,
        EventAgendaModule,
    ],
    providers: [
        EFXAdminService,
        EfxStationService,
    ],
})

export class EFXModule {
}
