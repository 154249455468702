
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { WebPresenceService } from '../../web-presence.service';
import { TrackingScriptTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';

@Component({
  selector: 'web-presence-tracking',
  template: require('./web-presence-tracking.html'),
  styles: [require('./web-presence-tracking.scss'), require('../../web-presence.scss')],

})

export class WebPresenceTrackingComponent implements OnInit, OnDestroy {

  constructor(
    private webPresenceService: WebPresenceService
  ) {
  }

  @Input() type: TrackingScriptTypeInterface;

  private trackingPixel: string = '';
  private editingTracking: boolean;

  ngOnDestroy(): void {
    this.editingTracking = false;
  }
  ngOnInit(): void {
    this.editingTracking = false;
  }

  editTracking() {
    if (this.editingTracking) {
      this.webPresenceService.saveTracking(this.type.slug, this.trackingPixel).then(res => {
        this.editingTracking = false;
      }).catch(e => {
        this.editingTracking = true;
      });
    } else {
      this.trackingPixel = this.type.value ? this.type.value : '';
      this.editingTracking = true;
    }
  }

  cancelEdit() {
    this.editingTracking = false;
  }

}
