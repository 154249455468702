import { getCurrentUser } from './user.selectors';
import { createSelector } from '@ngrx/store';
import {getPoolWorlds} from './poolWorld.selectors';
import * as userSelector from './../selectors/user.selectors';
import {EfEvent} from '../../../ApiClient/Models/Event/event';
import {PoolWorld} from '../../../ApiClient/Models/PoolWorld/pool-world';
import * as _ from 'lodash';
export const getCurrentTeam = (state) => state.team;

export const getAllEventsForCurrentTeam = createSelector(
    getCurrentTeam,
    getPoolWorlds,
    (team, poolWorlds) => {
        return Object.keys(team.events.data).map(id => {
            const event = team.events.data[id];
            // Frontend workaround to display WorldName for the venue when in the context of Echo-location events
            if (_.get(event, 'venue.venueType.slug') === 'virbela') {
                // Get world name from poolWorlds
                const poolWorld = _.get(poolWorlds, 'data', []).filter(pw => pw.virbelaWorldId == event.venue.name)
                const name = _.get(poolWorld[0], 'worldName') || event.venue.name;
                team.events.data[id].venue.name = name;
                return team.events.data[id];
            }
            return team.events.data[id];
        });
    }
);

export const selectTeam = (id: string) => createSelector(
    userSelector.getTeamsEntitiesForUser,
    teams => teams[id]
  );

export const getEventsLoaded = createSelector(
    getCurrentTeam,
    (team) => {
        return team.events.loaded;
    }
);

export const getEventsLoading = createSelector(
    getCurrentTeam,
    (team) => {
        return team.events.loading;
    }
);

export const getPoolContactType = createSelector(
    getCurrentTeam,
    getCurrentUser,
    (team, user) => {
        if (team.data && team.data.id !== 'id') {
            const id = team.data.id;
            return user.poolContactType[id];
        }
    }
);

export const getPoolFeatures = createSelector(
    getCurrentTeam,
    (team) => {
        if (team.features.data && team.features.data.length) {
            return team.features;
        }
        return [];
    }
);
