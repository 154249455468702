import { createSelector } from '@ngrx/store';

export const getCurrentTeam = (state) => state.team;


export const getPoolWorlds = createSelector(
    getCurrentTeam,
    (team) => {
        if (team.poolWorlds.data && team.poolWorlds.data.length) {
            return team.poolWorlds;
        }
        return [];
    }
);
