import { SweetAlert } from '../sweet-alert';
import { AbstractBaseAlert } from '../base-alert';

export class QuestionsAlert extends AbstractBaseAlert {

    confirmRemoveQuestion(): Promise<any> {
        return this.alert({
            title: 'Delete Question?',
            text: 'Are you sure you want to delete this question?',
            icon: 'warning',
            confirmButtonColor: '#ea2b38',
            focusCancel: true,
            confirmButtonText: 'Delete Question',
            showCancelButton: true
        });
    }
}
