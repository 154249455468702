import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {NgxIntlTelInputModule} from '@khazii/ngx-intl-tel-input';
import { UtilitiesModule } from '../../../Utilities/utilities.module';

import { LoadingOverlayModule } from '../LoadingOverlay/loading-overlay.module';
import { ErrorModule } from '../Error/error.module';
import { ModalModule } from '../Modal/modal.module';
import { UserFormComponent } from './userForm.component';
import { UserFormService } from './userForm.service';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

const forExport = [
    UserFormComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        ModalModule,
        NgxUtilitiesModule,
        NgxIntlTelInputModule
    ],
    providers: [
        UserFormService,
    ]
})

export class UserFormModule {
}
