import {Component, Input, OnInit} from '@angular/core';
import { UploaderService } from '../uploader.service';
import { UploaderTabsService } from '../uploaderTabs.service';
import { ProcessingService } from './processing.service';
import { QueueCommand } from '../../../../ApiClient/Models/Queue/queue-command';
import { EventFarmService } from '../../../eventFarm.service';

@Component({
    selector: 'processing-container',
    providers: [
    ],
    template: require('./processing-container.html'),
    styles: [require('./processing-container.scss')],
})

export class ProcessingContainerComponent implements OnInit {
    @Input() userImportId?: string;
    @Input() textAlignment?: 'left' | 'center' | 'right';
    @Input() showSkipped?: boolean;
    @Input() simplified?: boolean;

    constructor(
        private uploaderService: UploaderService,
        private processingService: ProcessingService,
        private uploaderTabsService: UploaderTabsService,
        private eventFarmService: EventFarmService
    ) {
    }

    currentProgress: QueueCommand;
    error: boolean = false;

    ngOnInit() {
        this.uploaderTabsService.reset();
        this.uploaderTabsService.setNextAsActive();
        if (this.processingService.currentState === 'pre' && !this.processingService.hasError) {
            this.uploaderTabsService.changeTabName(1, 'Processing...');
        } else if (this.processingService.currentState === 'post' && !this.processingService.hasError) {
            this.uploaderTabsService.setNextAsActive();
            this.uploaderTabsService.changeTabName(2, 'Processing...');
        }
    }
}
