import { Action } from '@ngrx/store';

export const ARCHIVE_CURRENT_TEAM_FOR_ADMIN = '[Admin/Team] Archive Current Team';
export const ARCHIVE_CURRENT_TEAM_FOR_ADMIN_SUCCESS = '[Admin/Team] Archive Current Team Success';
export const ARCHIVE_CURRENT_TEAM_FOR_ADMIN_FAIL = '[Admin/Team] Archive Current Team Fail';

export class ArchiveCurrentTeamForAdmin implements Action {
    public readonly type = ARCHIVE_CURRENT_TEAM_FOR_ADMIN;
    constructor() { }
}

export class ArchiveCurrentTeamForAdminSuccess implements Action {
    public readonly type = ARCHIVE_CURRENT_TEAM_FOR_ADMIN_SUCCESS;
    constructor() { }
}

export class ArchiveCurrentTeamForAdminFail implements Action {
    public readonly type = ARCHIVE_CURRENT_TEAM_FOR_ADMIN_FAIL;
    constructor(public errorMessage: string) { }
}

export type AdminArchiveCurrentTeamActions =
    ArchiveCurrentTeamForAdmin
    | ArchiveCurrentTeamForAdminSuccess
    | ArchiveCurrentTeamForAdminFail;
