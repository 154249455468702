import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EventFarmService } from './eventFarm.service';
import { RouteGeneratorService } from '../_services/routes/route-generator.service';
import { Team } from '../ApiClient/Models/Pool/team';
import { Store } from '@ngrx/store';
import * as fromRoot from './store';
import { shareReplay } from 'rxjs/operators';
@Injectable()

export class UserManagerOrEventCreateResolver implements Resolve<boolean> {
    constructor(
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
    ) {
        this.userContactType$.subscribe((val) => {
            this.userContactType = val;
        });
    }

    private pointOfContactEventCreate: boolean = false;
    private userContactType$ = this.store.select(fromRoot.getPoolContactType).pipe(shareReplay());
    private userContactType: any;

    public async resolve() {

        if (this.userContactType && (this.userContactType.isCreate || this.userContactType.isFull)) {
            return true;
        }

        this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', { poolId: this.eventFarmService.currentTeam.id }));
        return false;
    }
}