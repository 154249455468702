import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupTypeaheadComponent } from './group-typeahead.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [GroupTypeaheadComponent],
    exports: [GroupTypeaheadComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUtilitiesModule
    ],
})

export class GroupTypeaheadModule {
}
