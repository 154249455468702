import { AbstractBaseSegment } from './segment-base';

export class SegmentUploaderTracking extends AbstractBaseSegment {

    openUploader(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Opened Uploader',
        );
    }

    uploadToExistingGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Uploading to an Existing Group',
            group
        );
    }

    private logToSegment(currentTeam, currentUser, segmentEventName, group = null) {
        const props = {
            user: {
                userName: currentUser.name ? currentUser.name.firstName + ' ' + currentUser.name.lastName : '',
                userIdentifier: currentUser.identifier ? currentUser.identifier.identifier : '',
            },
            team: {
                teamName: currentTeam.name,
                teamId: currentTeam.id
            }
        };
        if (group) {
            props['group'] = {};
            props['group'].groupName = group.name;
            props['group'].groupId = group.id;
        }
        // return this.analytics.track({
        //     userId: currentUser.id,
        //     event: segmentEventName,
        //     properties: props
        // });
    }
}
