import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AlertService } from '../../../../../eventFarmAlert.service';
import { cloneDeep } from 'lodash';
import decodeHTML from '../../../../../../Utilities/decode.html';
import * as _ from 'lodash';
import { Question } from '../../../../../../ApiClient/Models/Question/question';
import { QuestionCenterService } from '../../../question-center.service';
import { Answer } from '../../../../../../ApiClient/Models/Answer/answer';
@Injectable()
export class EditQuestionService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private alertService: AlertService,
        private questionCenterService: QuestionCenterService,
    ) {
    }

    public existingAnswers: Answer[] = [];
    public currentQuestionAnswers: Answer[] = [];
    public answerOptionsValid: boolean = false;

    public async loadQuestionsForCenter() {
        await this.questionCenterService.getQuestions();
    }

    public isQuestionLogicEnabled(): boolean {
        return this.eventFarmService.questionLogicEnabled;
    }

    // question methods

    public isIndividualToggleDisabled(question: Question): boolean {
        if (!this.eventFarmService.questionLogicEnabled) {
            return false;
        }

        if (question.hasLogic) {
            return true;
        }

        const answerIds = question.answers.map(a => a.id);

        let visibleQuestionsForType = this.questionCenterService.visibleIndividualQuestions$.value;

        if (!question.isIndividual) {
            visibleQuestionsForType =  this.questionCenterService.visibleTransactionQuestions$.value;
        }

        let questionAnswerHasRuleTiedToIt = false;

        visibleQuestionsForType.forEach(q => {
            q.answerBindings.forEach(ab => {
                if (answerIds.findIndex(aid => aid === ab.answer.id) !== -1) {
                    questionAnswerHasRuleTiedToIt = true;
                }
            });
        });

        return questionAnswerHasRuleTiedToIt;
    }

    public async createQuestion(question: Question) {
        try {
            const q = await this.apiClient.getUseCaseFactory()
            .Question()
            .CreateQuestion(
                this.eventFarmService.currentEvent.id,
                question.text,
                question.questionType.slug,
                question.isHidden ? -1 : null,
                question.isRequired,
                question.isIndividual,
                question.ticketType ? question.ticketType.id : null,
                null,
                ['registration']
            )
            .toPromise();

            const questionId = q.data.command.questionId;

            if (!question.isHidden) {
                if (question.isIndividual) {
                    await this.questionCenterService.setQuestionSortOrder(questionId, this.questionCenterService.visibleIndividualQuestionCount + 1, false);
                } else {
                    await this.questionCenterService.setQuestionSortOrder(questionId, this.questionCenterService.visibleQuestionCount + 1, false);
                }
            }

            const commands = await this.createAnswers(questionId);
            await this.setAnswersSortOrder(commands);

            this.message.success('Question And Answers Created');
            return true;

        } catch (err) {
            this.message.error('Error Creating Question and Answers');
            return false;
        }
    }

    public async updateQuestion(question: Question) {
        try {
            const q = await this.apiClient.getUseCaseFactory().Question().UpdateQuestion(
                question.id,
                question.text,
                question.questionType.slug,
                ['registration'],
                question.isRequired,
                question.isIndividual,
                question.ticketType ? question.ticketType.id : null
            ).toPromise();

            if (question.isHidden) {
                await this.questionCenterService.setQuestionSortOrder(question.id, -1, false);
            } else {
                if (question.isIndividual) {
                    const index = this.questionCenterService.visibleIndividualQuestions$.value.findIndex(q => q.id === question.id);
                    if (index === -1) {
                        await this.questionCenterService.setQuestionSortOrder(question.id, this.questionCenterService.visibleIndividualQuestionCount + 1, false);
                    }
                } else {
                    const index = this.questionCenterService.visibleTransactionQuestions$.value.findIndex(q => q.id === question.id);
                    if (index === -1) {
                        await this.questionCenterService.setQuestionSortOrder(question.id, this.questionCenterService.visibleQuestionCount + 1, false);
                    }
                }
            }

            if (question.shouldNotHaveAnswers) {
                await this.deleteAllAnswers();
            } else {
                await this.deleteAnswers();
                const commands = await this.updateAnswers(question.id);
                await this.setAnswersSortOrder(commands);
            }

            this.message.success('Question And Answers Updated');
            return true;

        } catch (err) {
            this.message.error('Error Updating Question and Answers');
            return false;
        }
    }

    public async deleteQuestion(questionId: string) {
        try {
            await this.apiClient.getUseCaseFactory()
            .Question()
            .DeleteQuestion(questionId)
            .toPromise();
            await this.deleteAllAnswers();
            await this.questionCenterService.setQuestionSortOrder(questionId, -1, false);
            this.message.success('Question Deleted');
            return true;
        } catch (err) {
            this.message.success('Error Deleting Question');
            return false;
        }
    }

    public async toggleEnableOrDisable(question: Question) {
        if (!question.isDisabled) {
            try {
                await this.apiClient.getUseCaseFactory()
                .Question()
                .DisableQuestion(question.id)
                .toPromise();
                this.message.success('Question Disabled');
            } catch (err) {
                this.message.error('Please try again');
            }
        } else {
            try {
                await this.apiClient.getUseCaseFactory()
                .Question()
                .EnableQuestion(question.id)
                .toPromise();
                this.message.success('Question Enabled');
            } catch (err) {
                this.message.error('Please try again');
            }
        }
    }

    // Answer Methods

    private async createAnswers(questionId: string) {
        const promises = [];

        let sortOrder = 1;
        const textValues = [];

        for (const answer of this.currentQuestionAnswers) {
            if (!answer.text.trim().length) {
                continue;
            }

            if (textValues.indexOf(answer.text) !== -1) {
                continue;
            }

            promises.push(
                this.createAnswer(questionId, answer, sortOrder),
            );

            textValues.push(answer.text);
            sortOrder++;
        }

        return Promise.all(promises);
    }

    private async deleteAnswers() {
        const promises = [];

        for (const existingAnswer of this.existingAnswers) {
            const inUI = this.currentQuestionAnswers
                .filter((a) => existingAnswer.id === a.id)[0];
            if (inUI) {
                continue;
            }

            promises.push(this.deleteAnswer(existingAnswer));
        }

        return Promise.all(promises);
    }

    private async deleteAllAnswers() {
        const promises = [];

        for (const existingAnswer of this.existingAnswers) {
            promises.push(this.deleteAnswer(existingAnswer));
        }

        return Promise.all(promises);
    }

    private async updateAnswers(questionId: string) {
        const promises = [];

        let sortOrder = 1;

        for (const answer of this.currentQuestionAnswers) {
            if (!answer.text.trim().length && !answer.id) {
                continue;
            }

            if (answer.id) {
                promises.push(this.updateAnswer(answer));
            } else {
                promises.push(this.createAnswer(
                    questionId,
                    answer,
                    sortOrder,
                ));
            }

            sortOrder++;
        }

        return Promise.all(promises);
    }

    private async setAnswersSortOrder(commands) {
        if (!commands.length) {
            return commands;
        }

        const answers = commands.map((command) => command.data.command);

        return answers.reduce(
            (lastPromise, currentAnswer, currentIndex) => {
                if (currentIndex === 0) {// don't process initialValue twice
                    return lastPromise;
                }

                return lastPromise.then((res) => this
                    .setAnswerSortOrder(currentAnswer.answerId, currentIndex + 1),
                );
            },
            // initialValue
            this.setAnswerSortOrder(answers[0].answerId, 1),
        );
    }

    private createAnswer(questionId: string, answer: Answer, sortOrder: number) {
        return this.apiClient.getUseCaseFactory()
            .Answer()
            .CreateAnswer(questionId, answer.text, null, answer.isDefault)
            .toPromise();
    }

    private setAnswerSortOrder(answerId: string, sortOrder: number) {
        return this.apiClient.getUseCaseFactory()
            .Answer()
            .SetAnswerSortOrder(answerId, sortOrder)
            .toPromise();
    }

    private deleteAnswer(answer: Answer) {
        return this.apiClient.getUseCaseFactory()
            .Answer()
            .DeleteAnswer(answer.id)
            .toPromise();
    }

    private updateAnswer(answer: Answer) {
        return this.apiClient.getUseCaseFactory()
            .Answer()
            .UpdateAnswer(
                answer.id,
                answer.text,
                answer.isDefault
            )
            .toPromise();
    }
}
