import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SMSCreateComponent} from './Create/sms-create.component';
import {SMSReportsComponent} from './Reports/sms-reports.component';
import {SMSComponent} from './sms.component';

const routes: Routes = [
    {
        path: '',
        component: SMSComponent,
        children: [
            {
                path: 'create',
                component: SMSCreateComponent,
            },
            {
                path: 'reports',
                component: SMSReportsComponent,
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class SMSRoutingModule {
}
