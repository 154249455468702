import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { FormattedDateTime } from '../formatted-datetime';
import { TicketType } from '../Stack/ticket-type';
import {SMSNotification} from "./sms-notification";
import * as _ from 'lodash'
import {SMSStatusSlug} from './sms-status';
export interface UpdatableTrackOptions {
    name: string|null;
    description: string|null;
}


export class SentSMSMessage extends AbstractModel {

    private _messageContent?: string;
    private _sentTo?: string;
    private _isWhatsApp: boolean;
    private _sentAtFormatted: FormattedDateTime
    private _smsNotifications: SMSNotification[]
    private _userEmail: string;
    private _userName: string;
    constructor(id?: string) {
        super(id);
    }



    public static fromApiResponse(data) {
        const sentSMSMessage = new this(data.id);
        sentSMSMessage._messageContent = data.attributes.messageContent;
        sentSMSMessage._isWhatsApp = data.attributes.isWhatsApp;
        sentSMSMessage._sentTo = data.attributes.sentTo;
        sentSMSMessage._sentAtFormatted = data.attributes.sentAtFormatted;
        sentSMSMessage._userName = data.attributes.userName || 'n/a';
        sentSMSMessage._userEmail = data.attributes.userEmail || 'n/a';

        if (data.relationships && data.relationships.messageNotifications) {
            const smsNotifications = [];
            data.relationships && data.relationships.messageNotifications.map(smsNotification => {
                smsNotifications.push(SMSNotification.fromApiResponse(smsNotification));
            });
            sentSMSMessage._smsNotifications = smsNotifications;
        }

        return sentSMSMessage;
    }

    get userEmail(): string {
        return this._userEmail;
    }

    set userEmail(value: string) {
        this._userEmail = value;
    }

    get userName(): string {
        return this._userName;
    }

    set userName(value: string) {
        this._userName = value;
    }

    get failed(): boolean {
        return this.getNotificationBySMSStatusSlug('failed') !== null;
    }

    get delivered(): boolean {
        return this.getNotificationBySMSStatusSlug('delivered') !== null;
    }

    get sentNotification(): SMSNotification {
        return this.getNotificationBySMSStatusSlug('sent')
    }

    get sendingNotification(): SMSNotification {
        return this.getNotificationBySMSStatusSlug('sending')
    }

    get deliveredNotification(): SMSNotification {
        return this.getNotificationBySMSStatusSlug('delivered')
    }

    get failedNotification(): SMSNotification {
        return this.getNotificationBySMSStatusSlug('failed')
    }

    getNotificationBySMSStatusSlug(slug: SMSStatusSlug){
        const filtered = this.smsNotifications.filter(notification => notification.status.slug === slug)
        if (filtered.length > 0) {
            return  filtered[0];
        }
        return null;
    }

    get messageContent(): string {
        return this._messageContent;
    }

    get sentTo(): string {
        return this._sentTo;
    }

    get isWhatsApp(): boolean {
        return this._isWhatsApp;
    }

    get sentAtFormatted(): FormattedDateTime {
        return this._sentAtFormatted;
    }

    get smsNotifications(): SMSNotification[] {
        return this._smsNotifications;
    }
}
