import { AbstractModel } from '../abstract-model';
export class UserAttribute extends AbstractModel {
    public static fromApiResponse(data) {
        const userAttribute = new this(data.id);
        try {
            userAttribute._key = data.attributes.value.key;
            userAttribute._value = data.attributes.value.value;
        } catch (err) {
        }
        return userAttribute;
    }

    private _key?: string;
    private _value?: string;

    get key(): string {
        return this._key;
    }

    set key(value: string) {
        this._key = value;
    }

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
    }

    get isPosition(): boolean {
        return (this._key === 'position');
    }

    get isCompany(): boolean {
        return (this._key === 'company');
    }

    get isTelephone(): boolean {
        return (this._key === 'telephone');
    }

    get isOther(): boolean {
        return (this._key === 'other');
    }

    get isTitle(): boolean {
        return (this._key === 'title');
    }

    get isExternalId(): boolean {
        return (this._key === 'externalId');
    }
}
