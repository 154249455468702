export class UploadResults {
    private _header: string[];
    private _content: any[];

    constructor(header: string[], content: any[]) {
        this._header = header;
        this._content = content;
    }

    static createFromResultsArray(resultsArray: any[]) {
        if (resultsArray.length) {
            const headerKey = 0;
            const header = resultsArray[headerKey];
            const content = resultsArray;
            content.splice(headerKey, 1);
            return new this(header, content);
        }
    }

    get header(): string[] {
        return this._header;
    }

    get content(): any[] {
        return this._content;
    }
}
