

type SMSStatusName = 'Sent' | 'Delivered' | 'Sending' | 'Failed';
export type SMSStatusSlug = 'sent' | 'delivered' | 'sending' | 'failed';

export class SMSStatus {

    private _description?: string;
    private _isCanceled: boolean;
    private _isDelivered: boolean;
    private _isFailed: boolean;
    private _isRead: boolean;
    private _isReceived: boolean;
    private _isScheduled: boolean;
    private _isSending: boolean;
    private _isSent: boolean;
    private _name: SMSStatusName;
    private _slug: SMSStatusSlug;

    constructor() {
    }

    static fromApiResponse(data){
        const smsStatus = new this()
        smsStatus._description = data.description;
        smsStatus._isCanceled = data.isCanceled;
        smsStatus._isDelivered = data.isDelivered;
        smsStatus._isFailed = data.isFailed;
        smsStatus._isRead = data.isRead;
        smsStatus._isReceived = data.isReceived;
        smsStatus._isScheduled = data.isScheduled;
        smsStatus._isSending = data.isSending;
        smsStatus._isSent = data.isSent;
        smsStatus._name = data.name
        smsStatus._slug = data.slug;
        return smsStatus;
    }

    get description(): string {
        return this._description;
    }

    get isCanceled(): boolean {
        return this._isCanceled;
    }

    get isDelivered(): boolean {
        return this._isDelivered;
    }

    get isFailed(): boolean {
        return this._isFailed;
    }

    get isRead(): boolean {
        return this._isRead;
    }

    get isReceived(): boolean {
        return this._isReceived;
    }

    get isScheduled(): boolean {
        return this._isScheduled;
    }

    get isSending(): boolean {
        return this._isSending;
    }

    get isSent(): boolean {
        return this._isSent;
    }

    get name(): SMSStatusName {
        return this._name;
    }

    get slug(): SMSStatusSlug {
        return this._slug;
    }
}