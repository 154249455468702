import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Exhibitor } from '../../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { EditExhibitorTeamMembersComponent } from './edit-exhibitor-team-members.component';

@Component({
  selector: 'edit-exhibitor-team-members-button',
  template: `
      <span nzTooltipTitle="Edit members" nz-tooltip (click)="openComponent()">
        <i class="fa fa-solid fa-group" style="cursor: pointer;"></i>
      </span>`
})

export class EditExhibitorTeamMembersButtonComponent implements OnInit {
  @Input() exhibitor: Exhibitor;
  width = '50%';

  constructor(
    private drawerService: NzDrawerService,
  ) {}

  ngOnInit() {
  }

  openComponent(): void {
    const drawerRef = this.drawerService.create<EditExhibitorTeamMembersComponent, { exhibitor: Exhibitor }, string>({
      nzTitle: '',
      nzContent: EditExhibitorTeamMembersComponent,
      nzWidth: this.width,
      nzClosable: false,
      nzContentParams: {
        exhibitor: this.exhibitor,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
    });

    drawerRef.afterClose.subscribe(data => {

    });
  }

}