import { Injectable } from '@angular/core';
import {FusionAuthService} from "@fusionauth/angular-sdk";
import { BehaviorSubject } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

const storagePrefix = 'eventFarm.';

@Injectable()
export class LocalStorageService {
    public linkClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public hideNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public resetNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private auth: FusionAuthService
    ) {
    }

    public get<T>(key: string): T {
        const item = localStorage.getItem(storagePrefix + key);

        if (!item || item === 'null') {
            return null;
        }

        try {
            return JSON.parse(item);
        } catch (e) {
            return null;
        }
    }

    public set(key: string, value: any): boolean {
        if (value === undefined) {
            value = null;
        } else {
            value = JSON.stringify(value);
        }

        try {
            localStorage.setItem(storagePrefix + key, value);
        } catch (e) {
            return false;
        }
    }

    public resetStorageAndLogout() {
        localStorage.removeItem('eventFarm.effectiveEfUserId');
        localStorage.removeItem('eventFarm.X-EF-GhostTech');
        localStorage.removeItem('eventFarm.efUserId');
        localStorage.removeItem('eventFarm.effectiveEfUserEmail');
        localStorage.removeItem('eventFarm.cioGetStartedDisplayCount');

        Sentry.setUser(null);
        this.auth.logout();
    }
}
