import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentTransfersTracking extends AbstractBaseSegment {

    /**
     * View Transfers Entered
     */

    transfersEntered() {
        this.track({
            event: actions.TRANSFERS_OPEN_SLIDEOUT,
        }, true);
    }

    /**
     * View Transfers closed
     */

    transfersClosed() {
        this.track({
            event: actions.TRANSFERS_CLOSE_SLIDEOUT,
        }, true);
    }

    /**
     * Force Confirm
     */

    transfersForceConfirmed(transferDetails: object) {
        this.track({
            event: actions.TRANSFERS_FORCE_CONFIRMED,
            properties: {
                ...transferDetails
            }
        }, true);
    }

    /**
     * Resend Email
     */

    transfersResendEmail(transferDetails: object) {
        this.track({
            event: actions.TRANSFERS_RESEND_EMAIL,
            properties: {
                ...transferDetails
            }
        }, true);
    }

}