import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import {IconService} from '@ant-design/icons-angular';

import { Store } from '@ngrx/store';
import {EventFarmService} from '../../../../eventFarm.service';

import {SMSReportsService} from '../sms-reports.service';



@Component({
    selector: 'sms-reports-search',
    template: require('./sms-reports-search.html'),
    styles: [require('./sms-reports-search.scss')]
})

export class SMSReportsSearchComponent implements OnInit, OnDestroy {
    expandSet = new Set<number>();
    searchForm: any;
    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
    }
    constructor(
        private eventFarmService: EventFarmService,
        private smsReportsService: SMSReportsService,
        private formBuilder: FormBuilder,

    ) {}

    ngOnInit(): void {
            this.searchForm = this.formBuilder.group({
                search: new FormControl()
            });

            this.searchForm.valueChanges.subscribe(val =>{
                this.smsReportsService.query = val.search
            })
    }


    ngOnDestroy() {
    }

    showSort(i: number) {
        // Hides sort on expandable column
        return i !== 0;
    }


    onSubmit() {
        this.smsReportsService.pageIndex = 1;
        this.smsReportsService.fetchStatsForEvent();
    }
}
