import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';

@Component({
    selector: 'guest-list',
    template: require('./guest-list.html'),
    styles: [require('./guest-list.scss')],
})

export class GuestListComponent {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>
    ) {

    }
}
