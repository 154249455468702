import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EventFarmService } from '../../../eventFarm.service';
import { AddressBookService } from '../addressBook.service';
import { AddressBookUserService } from '../User/addressBookUser.service';
import { AddressBookGroupService } from './addressBookGroup.service';
import { Group } from '../../../../ApiClient/Models/Group/group';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { Team } from '../../../../ApiClient/Models/Pool/team';
import { User } from '../../../../ApiClient/Models/User/user';
import { filter, shareReplay } from 'rxjs/operators';
@Component({
    selector: 'address-book-group-list',
    template: require('./address-book-group-list.html'),
    styles: [require('./address-book-group-list.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class AddressBookGroupListComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private router: Router,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private addressBookGroupService: AddressBookGroupService,
        private segmentService: SegmentService,
        private store: Store<fromRoot.AppState>,
        private routeGenerator: RouteGeneratorService,
    ) {
        this.groupsMeta$ = this.addressBookGroupService.groupsMeta$.subscribe((res) => {
            this.groupsMeta = res;
        });
        this.activeGroup$ = this.addressBookService.selectedGroup$.subscribe((res) => {
            this.activeGroup = res;
            this.scrollToGroup(res);
        });
        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.data && val.data.id && !!this.teamStoreData && !!this.teamStoreData.id)).subscribe((val) => {
            this.teamStoreData = val.data;
            this.segmentService.segmentAddressBookTracking().openNewGroup(
                this.teamStoreData,
                this.userStoreData
            );
        });
        this.userUpdate$ = this.userStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            if (val.data && val.data.id) {
                this.userStoreData = val.data;
                this.addressBookGroupService.broadcastGroupsForUser(this.userStoreData.id);
            }
        });
    }

    readonly ellipsisSVG = require('../assets/ellipsis');
    readonly groupPeopleWhiteSVG = require('../assets/circle-group-people-white');
    readonly groupPeopleBlueSVG = require('../assets/circle-group-people-blue');
    readonly showGroupsArrowSVG = require('../assets/show-groups-arrow');

    private groupsMeta$: Subscription;
    private activeGroup$: Subscription;
    private teamUpdate$: Subscription;
    private userUpdate$: Subscription;
    private teamStore$ = this.store.select(fromRoot.getCurrentTeam).pipe(shareReplay());
    private teamStoreData: Team;
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userStoreData: User;
    private activeGroup: Group;
    private groupsMeta: any;
    private groupsExpanded = true;
    @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;

    ngOnInit() {
    }

    toggleGroup(group: Group = null) {
        if (group && (!this.addressBookGroupService.groupListOptions.selectedGroup || (this.addressBookGroupService.groupListOptions.selectedGroup.id !== group.id))) {
            this.segmentService.segmentAddressBookTracking().selectedGroup(
                this.eventFarmService.currentEvent,
                this.eventFarmService.currentUser,
                group
            );
            this.activeGroup = group;
            this.addressBookGroupService.groupListOptions.selectedGroup = group;
            this.addressBookService.broadcastGroup(group);
        } else {
            this.activeGroup = null;
            this.addressBookGroupService.groupListOptions.selectedGroup = null;
            this.addressBookService.broadcastClearGroup();
            this.segmentService.segmentAddressBookTracking().clearedGroup(
                this.eventFarmService.currentEvent,
                this.eventFarmService.currentUser
            );
        }
        this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
        this.addressBookService.usersSelectedInList = [];
    }

    showAllContacts() {
        this.activeGroup = null;
        this.addressBookGroupService.groupListOptions.selectedGroup = null;
        this.addressBookService.globalSearchTerm = '';
        this.addressBookUserService.userListOptions.query = '';
        this.addressBookGroupService.groupListOptions.query = '';
        this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
        this.addressBookService.broadcastClearGroup();
        this.addressBookService.usersSelectedInList = [];
    }

    nextGroupsPage() {
        if (this.addressBookGroupService.groupListOptions.pagination.currentGroupsPage < this.groupsMeta.totalPages ) {
            this.addressBookGroupService.groupListOptions.pagination.currentGroupsPage += 1;
            this.addressBookGroupService.broadcastGroupsForUser(this.eventFarmService.currentUser.id);
        }
    }

    previousGroupsPage() {
        if (this.addressBookGroupService.groupListOptions.pagination.currentGroupsPage > 1 ) {
            this.addressBookGroupService.groupListOptions.pagination.currentGroupsPage -= 1;
            this.addressBookGroupService.broadcastGroupsForUser(this.eventFarmService.currentUser.id);
        }
    }

    ngOnDestroy() {
        this.groupsMeta$.unsubscribe();
        this.activeGroup$.unsubscribe();
        this.teamUpdate$.unsubscribe();
        this.userUpdate$.unsubscribe();
    }

    closePopovers() {
        this.segmentService.segmentAddressBookTracking().toggleGroupOptionsPopover(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.popovers.forEach((popover: PopoverDirective) => {
            popover.hide();
        });
    }

    deleteGroup(group: Group) {
        this.closePopovers();
        this.addressBookGroupService.deleteGroup(group);
    }

    stopProp($event) {
        $event.stopPropagation();
    }

    viewGroup(group: Group) {
        this.closePopovers();
        this.addressBookService.displayedForEditingGroup = group;
        this.addressBookService.openEditGroupModal(group.id);
    }

    scrollToGroup(group: Group) {
        setTimeout(() => {
            try {
                let groupIdDOMId = '#groupId-' + group.id;
                if ( window['$']('.groups-container').width() > 500 && window['$']('.groups-container').width() < window['$'](groupIdDOMId).offset().left) {
                    window['$']('.groups-container').animate({ scrollLeft: window['$'](groupIdDOMId).offset().left - window['$']('side-nav').width() }, 500);
                }
            }
            catch(err) {
            }
        }, 1000);
    }

    toggleCollapseGroups() {
        this.groupsExpanded = !this.groupsExpanded;
        if (this.groupsExpanded) {
            this.segmentService.segmentAddressBookTracking().showGroupsDisplay(
                this.eventFarmService.currentEvent,
                this.eventFarmService.currentUser
            );
        } else {
            this.segmentService.segmentAddressBookTracking().hideGroupsDisplay(
                this.eventFarmService.currentEvent,
                this.eventFarmService.currentUser
            );
        }
    }
}
