import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../../CoreUI/Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';
import { KioskModeGuestPassesComponent } from './kiosk-mode-guest-passes.component';

const forExport = [
    KioskModeGuestPassesComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,

    ],
})

export class KioskModeGuestPassesModule {
}