import {AbstractBaseAlert} from '../base-alert';
export class PaymentSettingsAlert extends AbstractBaseAlert {

    confirmRemovePoolPaymentGateway(message: string): Promise<any> {
        return this.alert({
            title: 'Remove Payment Gateway',
            text: message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Remove Gateway'
        });
    }
}
