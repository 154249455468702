import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';

import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';
import { EFProfile } from '../../../../../../ApiClient/Models/Profile/EFProfile';
import { EventAgendaService } from '../../../event-agenda.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventFarmService } from '../../../../../eventFarm.service';
import _ = require('lodash');
import { ValidateEmail } from '../../../../../../Utilities/validateEmail';
import { BehaviorSubject } from 'rxjs';
import { EventProfileTypeInterface, Profile } from '@eventfarm/javascript-sdk/dist/Api/Type/Profile';
import { ValidateUrl } from '../../../../../../Utilities/validateUrl';

@Component({
    selector: 'edit-speakers',
    template: require('./edit-speakers.html'),
    styles: [require('./edit-speakers.scss')]
})
export class EditSpeakersComponent implements OnInit {
    @Input() speaker: EFProfile;
    editSpeakersItemForm: FormGroup;
    public loading: boolean = false;
    public avatarUrl?: string;

    private isSaving: boolean = false;
    private confirmDelete: boolean = false;
    private isDeleting: boolean = false;
    public base64Image: any;
    private canSaveImage: boolean|null;
    public roles: EventProfileTypeInterface[] = new Profile().EventProfileType().filter(pt => !pt.isSponsor);

    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store<fromRoot.AppState>,
        private eventAgendaService: EventAgendaService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
        private messageService: NzMessageService

    ) {}

    ngOnInit() {
        this.initializeFormControls();
    }

    private async addSpeaker() {
        if (this.isSaving === false) {
            this.isSaving = true;
            try {
                const speaker = new EFProfile();
                
                speaker.firstName = this.editSpeakersItemForm.get('firstName').value;
                speaker.lastName = this.editSpeakersItemForm.get('lastName').value;
                speaker.title = this.editSpeakersItemForm.get('title').value;
                speaker.company = this.editSpeakersItemForm.get('company').value;
                speaker.imageUrl = this.editSpeakersItemForm.get('imageUrl').value;
                speaker.description = this.editSpeakersItemForm.get('description').value;
                speaker.emailAddress = this.editSpeakersItemForm.get('emailAddress').value;
                speaker.profileType = new Profile().ProfileType().find(pt => pt.isPerson);

                const eventProfileType =  this.editSpeakersItemForm.get('eventProfileType').value;
                
                if (this.base64Image) {
                    try {
                        const imageRes =  await this.eventAgendaService.uploadProfileImage(this.base64Image);
                        speaker['imageUrl'] = imageRes;
                    } catch (e) {
                        this.isSaving = false;
                        return;
                    }
                } else {
                    speaker['imageUrl'] = null;
                }
                
                const profile = await this.eventAgendaService.createProfile(speaker, this.eventFarmService.currentEvent.id, eventProfileType);
                await this.eventAgendaService.updateProfileLinks(this.editSpeakersItemForm, profile);
                this.drawerRef.close();
                this.eventAgendaService.speakersListOptions.pagination.currentPage = 1;
                await this.eventAgendaService.getProfiles(true);

            } catch (err) {
                this.messageService.error('Error adding speaker');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private async saveSpeaker() {        
        if (this.isSaving === false) {
            this.isSaving = true;
            const fieldsToUpdate = {};

            // Populate fieldsToUpdate object/map with dirty name/value pairs
            this.determineDirtyFields(
                this.editSpeakersItemForm.controls
            ).forEach(d => {
                fieldsToUpdate[d.name] = d.value;
            });

            try {
                if (this.base64Image) {
                    try {
                        const imageRes =  await this.eventAgendaService.uploadProfileImage(this.base64Image);
                        fieldsToUpdate['imageUrl'] = imageRes;
                    } catch (e) {
                        this.isSaving = false;
                        return;
                    }
                } else {
                    if (this.base64Image === null && this.speaker.imageUrl !== null) {
                        fieldsToUpdate['imageUrl'] = null;
                    } else {
                        fieldsToUpdate['imageUrl'] = this.speaker.imageUrl;
                    }
                }
                
                await this.eventAgendaService.updateSpeaker(this.speaker.id, fieldsToUpdate);
                const eventProfileType =  this.editSpeakersItemForm.get('eventProfileType').value;

                if (eventProfileType !== this.speaker.firstEventProfileTypeSlug) {
                    await this.eventAgendaService.setEventProfileType(this.speaker.firstEventProfile.id, eventProfileType);
                }

                await this.eventAgendaService.updateProfileLinks(this.editSpeakersItemForm, this.speaker);
                
                this.messageService.success('Speaker updated!');
                this.drawerRef.close();
                await this.eventAgendaService.getProfiles(true);

            } catch (error) {
                this.messageService.error('Error updating speaker');
            }

            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private determineDirtyFields(controls) {
        const dirty = Object.keys(controls)
            .map((name) => {
                return { ...controls[name], name };
            })
            .filter((obj) => !obj.pristine);
        return dirty;
    }

    private async handleDeleteSpeaker() {
        if (this.isDeleting) {
            this.isDeleting = false;
        }
        if (this.confirmDelete) {
            this.isDeleting = true;
            const deleteSuccess = await this.eventAgendaService.removeProfile(this.speaker.id);

            this.isDeleting = false;
            this.confirmDelete = false;

            if (deleteSuccess === true) {
                this.drawerRef.close();
                await this.eventAgendaService.getProfiles(true);
            }
        }
        this.confirmDelete = true;
    }

    private allowSave() {
        if (this.canSaveImage === false) {
            return false;
        }

        return this.editSpeakersItemForm.controls['firstName'].valid &&
            this.editSpeakersItemForm.controls['lastName'].valid &&
            this.editSpeakersItemForm.controls['emailAddress'].valid;
    }

    private initializeFormControls() {

        let website = null;
        let linkedIn = null;
        let twitter = null;
        let facebook = null;
        let instagram = null;

        if (this.speaker) {
            website = this.speaker.profileLinks.find(pl => pl.link.linkType.isWeb);
            linkedIn = this.speaker.profileLinks.find(pl => pl.link.linkType.isLinkedIn);
            twitter = this.speaker.profileLinks.find(pl => pl.link.linkType.isTwitter);
            facebook = this.speaker.profileLinks.find(pl => pl.link.linkType.isFacebook);
            instagram = this.speaker.profileLinks.find(pl => pl.link.linkType.isInstagram);
        }

        this.editSpeakersItemForm = new FormGroup({
            firstName: new FormControl(_.get(this, 'speaker.firstName', null), Validators.required),
            lastName: new FormControl(_.get(this, 'speaker.lastName', null), Validators.required),
            description: new FormControl(_.get(this, 'speaker.description', null)),
            title: new FormControl(_.get(this, 'speaker.title', null)),
            company: new FormControl(_.get(this, 'speaker.company', null)),
            imageUrl: new FormControl(_.get(this, 'speaker.imageUrl', null)),
            eventProfileType: new FormControl(_.get(this, 'speaker.firstEventProfile.eventProfileType.slug', 'speaker')),
            emailAddress: new FormControl(_.get(this, 'speaker.emailAddress', null), [ValidateEmail] ),
            website: new FormControl(_.get(website, 'link.url', ''), [ValidateUrl]),
            linkedIn: new FormControl(_.get(linkedIn, 'link.url', ''), [ValidateUrl]),
            twitter: new FormControl(_.get(twitter, 'link.url', ''), [ValidateUrl]),
            facebook: new FormControl(_.get(facebook, 'link.url', ''), [ValidateUrl]),
            instagram: new FormControl(_.get(instagram, 'link.url', ''), [ValidateUrl]),
        });

        this.canSaveImage = null;
    }

    public saveImageAsBase64(image) {
        this.base64Image = image;
        this.canSaveImage = true;
    }

    public imageSet($event) {
        this.canSaveImage = false;
    }

    close(): void {
        this.drawerRef.close(this.speaker);
    }
}
