import { AbstractModel } from '../abstract-model';

import { v4 as uuidv4 } from 'uuid';
import { FormControl } from '@angular/forms';
import { EFLink } from './ef-link';


export class EventLink extends AbstractModel {

    private _link: EFLink

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): EventLink {
        const eventLink = new this(data.id);

        try {
            if (data.relationships && data.relationships.link) {
                eventLink._link = EFLink.fromApiResponse(data.relationships.link)
            } 

        } catch (err) { }
        return eventLink;
    }


    get link(): EFLink {
        return this._link;
    }

    set link(value: EFLink) {
        this._link = value;
    }
}