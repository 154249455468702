import {Component, OnDestroy, OnInit} from '@angular/core';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { shareReplay } from 'rxjs/operators';
import { ExhibitorsService } from '../exhibitors.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { sortFormat } from '../../../../Utilities/nzTableSortFormat';

@Component({
    selector: 'exhibitor-list',
    template: require('./exhibitor-list.html'),
    styles: [require('./exhibitor-list.scss')]
})

export class ExhibitorListComponent implements OnInit, OnDestroy {

    constructor(
        private iconService: IconService,
        private exhibitorsService: ExhibitorsService,
        private store: Store<fromRoot.AppState>
    ) {}

    ngOnInit(): void {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    async handleSearch(query: string) {
        this.exhibitorsService.exhibitorsListOptions.pagination.currentExhibitorsPage = 1;
        this.exhibitorsService.exhibitorsListOptions.query = query;
        await this.exhibitorsService.getExhibitors();
    }

    async loadDataFromServer(pageIndex: number, sortField: string, sortOrder: string) {
        this.exhibitorsService.exhibitorsListOptions.pagination.currentExhibitorsPage = pageIndex;
        this.exhibitorsService.exhibitorsListOptions.sortBy = sortField;
        this.exhibitorsService.exhibitorsListOptions.sortDirection = sortOrder;
        await this.exhibitorsService.getExhibitors();
    }

    async onQueryParamsChange(params: NzTableQueryParams): Promise<void> {
        const { pageSize, pageIndex, sort, filter } = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;
        sortOrder = sortFormat(sortOrder);
        await this.loadDataFromServer(pageIndex, sortField, sortOrder);
    }

    ngOnDestroy() {
    }
}
