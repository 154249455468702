import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { Subscription ,  Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
@Component({
    selector: 'web-form',
    template: require('./old-web-presence.html'),
    styles: [require('./old-web-presence.scss')],
})

export class OldWebPresenceComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private legacyUIService: LegacyUIService,
        private segmentService: SegmentService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id && !this.legacyTimeoutRunning)).subscribe((val) => {
            this.fetchV1(val.data);
        });
    }

    private isV1: boolean;
    private legacyTimeoutRunning: boolean;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().webPresenceEntered();
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }

    fetchV1(event: EfEvent) {
        this.isV1 = true;
        this.startLegacyRefreshActionLimiterTimeout();
        this.legacyUIService.fetchAndCompileTemplateContents('/events/configWebPresence/' + this.eventFarmService.currentEvent.id, 'web-presence-tab-content', event);
    }

    startLegacyRefreshActionLimiterTimeout(timeout: number = 5000) {
        this.legacyTimeoutRunning = true;
        setTimeout(() => {
            this.legacyTimeoutRunning = false;
        }, timeout);
    }
}