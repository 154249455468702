import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormButtonComponent } from '../form-button/form-button.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormTextareaComponent } from '../form-textarea/form-textarea.component';
import { FormRadioComponent } from '../form-radio/form-radio.component';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';
import { FormDateComponent } from '../form-date/form-date.component';
import { FormWaiverComponent } from '../form-waiver/form-waiver.component';

const components = {
  button: FormButtonComponent,
  text: FormInputComponent,
  select: FormSelectComponent,
  multi: FormTextareaComponent,
  radio: FormRadioComponent,
  checkbox: FormCheckboxComponent,
  date: FormDateComponent,
  waiver: FormWaiverComponent
};

@Directive({
  selector: '[dynamicField]'
})

export class DynamicFieldDirective implements OnInit {
  @Input()
  config;

  @Input()
  group: FormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  component;

  ngOnInit() {
    const component = components[this.config.type];
    const factory = this.resolver.resolveComponentFactory<any>(component);
    this.component = this.container.createComponent(factory);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
