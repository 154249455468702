import { Action } from '@ngrx/store';

export const FETCH_ACCESS_TYPES = '[Event] Fetch Access Types';
export const FETCH_ACCESS_TYPES_SUCCESS = '[Event] Fetch Access Types Success';
export const FETCH_ACCESS_TYPES_FAIL = '[Event] Fetch Access Types Fail';

export const UPDATE_ACCESS_TYPE = '[Event] Update Access Type';
export const UPDATE_ACCESS_TYPE_SUCCESS = '[Event] Update Access Type Success';
export const UPDATE_ACCESS_TYPE_FAIL = '[Event] Update Access Type Fail';

export const FETCH_STACKS = '[Event] Fetch Stacks';
export const FETCH_STACKS_SUCCESS = '[Event] Fetch Stacks Success';
export const FETCH_STACKS_FAIL = '[Event] Fetch Stacks Fail';

export const UPDATE_STACK = '[Event] Update Stack';
export const UPDATE_STACK_SUCCESS = '[Event] Update Stack Success';
export const UPDATE_STACK_FAIL = '[Event] Update Stack Fail';

export const CREATE_STACK = '[Event] Create Stack';
export const CREATE_STACK_SUCCESS = '[Event] Create Stack Success';
export const CREATE_STACK_FAIL = '[Event] Create Stack Fail';

export const DELETE_STACK = '[Event] Delete Stack';
export const DELETE_STACK_SUCCESS = '[Event] Delete Stack Success';
export const DELETE_STACK_FAIL = '[Event] Delete Stack Fail';

export const DELETE_TICKET_TYPE = '[Event] Delete Ticket Type';
export const DELETE_TICKET_TYPE_SUCCESS = '[Event] Delete Ticket Type Success';
export const DELETE_TICKET_TYPE_FAIL = '[Event] Delete Ticket Type Fail';

export const CREATE_STACKS_FOR_TICKET_TYPE = '[Event] Create Stacks For Ticket Type';
export const CREATE_STACKS_FOR_TICKET_TYPE_SUCCESS = '[Event] Create Stacks For Ticket Type Success';
export const CREATE_STACKS_FOR_TICKET_TYPE_FAIL = '[Event] Create Stacks For Ticket Type Fail';

export const UPDATE_STACKS_FOR_TICKET_TYPE = '[Event] Update Stacks For Ticket Type';
export const UPDATE_STACKS_FOR_TICKET_TYPE_SUCCESS = '[Event] Update Stacks For Ticket Type Success';
export const UPDATE_STACKS_FOR_TICKET_TYPE_FAIL = '[Event] Update Stacks For Ticket Type Fail';


export const FETCH_STACKS_FOR_TICKET_TYPE = '[Event] Fetch Stacks For Ticket Type';
export const FETCH_STACKS_FOR_TICKET_TYPE_SUCCESS = '[Event] Fetch Stacks For Ticket Type Success';
export const FETCH_STACKS_FOR_TICKET_TYPE_FAIL = '[Event] Fetch Stacks For Ticket Type Fail';

export class FetchAccessTypes implements Action {
    readonly type = FETCH_ACCESS_TYPES;
    constructor(public payload?: any) { }
}

export class FetchAccessTypesSuccess implements Action {
    readonly type = FETCH_ACCESS_TYPES_SUCCESS;
    constructor(public payload?: any) { }
}

export class FetchAccessTypesFail implements Action {
    readonly type = FETCH_ACCESS_TYPES_FAIL;
    constructor(public payload?: any) { }
}

export class UpdateAccessType implements Action {
    readonly type = UPDATE_ACCESS_TYPE;
    constructor(public payload?: any) { }
}

export class UpdateAccessTypeSuccess implements Action {
    readonly type = UPDATE_ACCESS_TYPE_SUCCESS;
    constructor(public payload?: any) { }
}

export class UpdateAccessTypeFail implements Action {
    readonly type = UPDATE_ACCESS_TYPE_FAIL;
    constructor(public payload?: any) { }
}

export class FetchStacks implements Action {
    readonly type = FETCH_STACKS;
    constructor(public payload?: any) { }
}

export class FetchStacksSuccess implements Action {
    readonly type = FETCH_STACKS_SUCCESS;
    constructor(public payload?: any) { }
}

export class FetchStacksFail implements Action {
    readonly type = FETCH_STACKS_FAIL;
    constructor(public payload?: any) { }
}

export class UpdateStack implements Action {
    readonly type = UPDATE_STACK;
    constructor(public payload?: any) { }
}

export class UpdateStackSuccess implements Action {
    readonly type = UPDATE_STACK_SUCCESS;
    constructor(public payload?: any) { }
}

export class UpdateStackFail implements Action {
    readonly type = UPDATE_STACK_FAIL;
    constructor(public payload?: any) { }
}

export class CreateStack implements Action {
    readonly type = CREATE_STACK;
    constructor(public payload?: any) { }
}

export class CreateStackSuccess implements Action {
    readonly type = CREATE_STACK_SUCCESS;
    constructor(public payload?: any) { }
}

export class CreateStackFail implements Action {
    readonly type = CREATE_STACK_FAIL;
    constructor(public payload?: any) { }
}

export class DeleteStack implements Action {
    readonly type = DELETE_STACK;
    constructor(public payload?: any) { }
}

export class DeleteStackSuccess implements Action {
    readonly type = DELETE_STACK_SUCCESS;
    constructor(public payload?: any) { }
}

export class DeleteStackFail implements Action {
    readonly type = DELETE_STACK_FAIL;
    constructor(public payload?: any) { }
}

export class DeleteTicketType implements Action {
    readonly type = DELETE_TICKET_TYPE;
    constructor(public payload?: any) { }
}

export class DeleteTicketTypeSuccess implements Action {
    readonly type = DELETE_TICKET_TYPE_SUCCESS;
    constructor(public payload?: any) { }
}

export class DeleteTicketTypeFail implements Action {
    readonly type = DELETE_TICKET_TYPE_FAIL;
    constructor(public payload?: any) { }
}

export class CreateStacksForTicketType implements Action {
    readonly type = CREATE_STACKS_FOR_TICKET_TYPE;
    constructor(public payload?: any) { }
}

export class CreateStacksForTicketTypeSuccess implements Action {
    readonly type = CREATE_STACKS_FOR_TICKET_TYPE_SUCCESS;
    constructor(public payload?: any) { }
}

export class CreateStacksForTicketTypeFail implements Action {
    readonly type = CREATE_STACKS_FOR_TICKET_TYPE_FAIL;
    constructor(public payload?: any) { }
}

export class UpdateStacksForTicketType implements Action {
    readonly type = UPDATE_STACKS_FOR_TICKET_TYPE;
    constructor(public payload?: any) { }
}

export class UpdateStacksForTicketTypeSuccess implements Action {
    readonly type = UPDATE_STACKS_FOR_TICKET_TYPE_SUCCESS;
    constructor(public payload?: any) { }
}

export class UpdateStacksForTicketTypeFail implements Action {
    readonly type = UPDATE_STACKS_FOR_TICKET_TYPE_SUCCESS;
    constructor(public payload?: any) { }
}


export class FetchStacksForTicketType implements Action {
    readonly type = FETCH_STACKS;
    constructor(public payload?: any) { }
}

export class FetchStacksForTicketTypeSuccess implements Action {
    readonly type = FETCH_STACKS_SUCCESS;
    constructor(public payload?: any) { }
}

export class FetchStacksForTicketTypeFail implements Action {
    readonly type = FETCH_STACKS_FAIL;
    constructor(public payload?: any) { }
}

export type EventAccessAction =
    FetchAccessTypes |
    FetchAccessTypesSuccess |
    FetchAccessTypesFail |
    UpdateAccessType |
    UpdateAccessTypeSuccess |
    UpdateAccessTypeFail |
    FetchStacks |
    FetchStacksSuccess |
    FetchStacksFail |
    UpdateStack |
    UpdateStackSuccess |
    UpdateStackFail |
    CreateStack |
    CreateStackSuccess |
    CreateStackFail |
    DeleteStack |
    DeleteStackSuccess |
    DeleteStackFail |
    FetchStacksForTicketType |
    FetchStacksForTicketTypeSuccess |
    FetchStacksForTicketTypeFail |
    DeleteTicketType |
    DeleteTicketTypeSuccess | 
    DeleteTicketTypeFail |
    CreateStacksForTicketType |
    CreateStacksForTicketTypeSuccess | 
    CreateStacksForTicketTypeFail |
    UpdateStacksForTicketType |
    UpdateStacksForTicketTypeSuccess | 
    UpdateStacksForTicketTypeFail;
