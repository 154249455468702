export function formatMoney(e, min = 0) {
    const input = e.target.value;
    const cursorIndex = e.target.selectionStart;

    if (cursorIndex === 0) {
        return e.charCode >= 49 && e.charCode <= 57;
    }

    const decimalIndex = input.indexOf('.');
    if (input.indexOf('.') < 0) {
        return e.charCode >= 48 && e.charCode <= 57 || e.charCode === 46;
    }
    if (input.split('.')[1].length > 1) {
        if (cursorIndex <= decimalIndex) {
            return e.charCode >= 48 && e.charCode <= 57;
        }
        return false;
    }
    if (cursorIndex <= decimalIndex) {
        return e.charCode >= 48 && e.charCode <= 57;
    }
    return e.charCode >= 48 && e.charCode <= 57;
}

export function formatInt(e, limit: number | null = null) {
    const length = e.target.value.length;
    const numOnly = (char) => char.charCode >= 48 && char.charCode <= 57;

    if (limit) {
        if (limit <= length) {
            return false;
        }
        return numOnly(e);
    }
    return numOnly(e);
}
