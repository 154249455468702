import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { User } from '../../../../ApiClient/Models/User/user';
import { AlertService } from '../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';

export interface CurrentEventRoles {
    none: boolean;
    user: boolean;
    admin: boolean;
    'event-crud': boolean;
    'event-view': boolean;
    'ticket-block-crud': boolean;
    'ticket-block-view': boolean;
    'address-book-crud': boolean;
    'address-book-view': boolean;
    'user-crud': boolean;
    'user-view': boolean;
    'list-view': boolean;
    'list-crud': boolean;
    upload: boolean;
    email: boolean;
    reports: boolean;
    'mass-remove': boolean;
    'custom-layout': boolean;
    payments: boolean;
    'will-call': boolean;
    partner: boolean;
    'inventory-records': boolean;
    'contest-scores': boolean;
    'pool-contact': boolean;
}

@Injectable()
export class EventUsersService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private message: NzMessageService

    ) {
        this.eventFarmService.currentEventRoles$.subscribe(res => {
            this.currentEventRoles = res;
        });
    }

    public currentEventRoles: CurrentEventRoles|null;
    public usersSource = new Subject<User[]>();
    public usersMetaSource = new BehaviorSubject<any>({});
    public users$ = this.usersSource.asObservable();
    public usersMeta$ = this.usersMetaSource.asObservable();
    public eventRoleTypes = this.apiClient.getTypeFactory().User().EventRoleType();
    public isLoadingUsers = true;

    public userListOptions: UserListOptions = {
        query: null,
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentUsersPage: 1,
            itemsPerPage: 10,
        }
    };

    public getUsersForEvent() {
        this.apiClient.getUseCaseFactory()
        .User()
        .ListUsersWithRolesForEvent(
            this.eventFarmService.currentEvent.id,
            [
                'eventRoles',
                'PoolContacts',
                'UserIdentifiers',
                'UserNames',
            ], this.userListOptions.query,
            this.userListOptions.sortBy,
            this.userListOptions.sortDirection,
            this.userListOptions.pagination.currentUsersPage,
            this.userListOptions.pagination.itemsPerPage
        )
        .subscribe((res) => {
            this.usersMetaSource.next(res.meta);
            const users: User[] = res.data.map((userResponse) => {
                return Object.assign(User.fromApiResponse(userResponse), {'editable': false});
            });
            this.usersSource.next(users);
            this.isLoadingUsers = false;
        });
    }

    public async triggerResendEmailPrompt(user ): Promise<void> {
        const confirm = await this.alertService.welcomeEmail().resendWelcomeEmail();
        if (confirm.value) {
            this.message.info('Re-sending welcome email...');
            try {
                await this.apiClient.getUseCaseFactory().Event().ResendEventUserRoleEmail(
                    this.eventFarmService.currentEvent.id,
                    user.id,
                    user.eventRoles.maxRoleString.toLowerCase().replace(' ', '-')
                ).toPromise();
                this.message.success('Email resent');
            } catch (error) {
                this.message.error('Error sending email');
            }
        }
    }

    public createUserRoleForEvent(user: User) {
        const firstName = user.name.firstName ? user.name.firstName : '';
        const lastName = user.name.lastName ? user.name.lastName : '';

        return this.apiClient.getUseCaseFactory().Event().AddUserRoleToEvent(
            this.eventFarmService.currentEvent.id,
            user.primaryEmail,
            firstName,
            lastName,
            user.eventRoles['slug'],
            this.eventFarmService.currentUser.id,
        );
    }

    public updateUserRoleForEvent(user: User) {
        return this.apiClient.getUseCaseFactory().User().SetEventRoleForUser(
            user.id,
            this.eventFarmService.currentEvent.id,
            user.eventRoles['slug'],
            this.eventFarmService.currentUser.id,
        );
    }

    public saveUserName(user: User, firstName: string, lastName: string) {
        return this.apiClient.getUseCaseFactory().UserName().SetUserName(
            user.name.id,
            firstName,
            lastName
        );
    }

    public deleteUserRoleForEvent(user: User) {
        return this.apiClient.getUseCaseFactory().User().RemoveEventRoleForUser(
            user.id,
            this.eventFarmService.currentEvent.id,
        );
    }

}

export interface Pagination {
    currentUsersPage: number;
    itemsPerPage: number;
}

export interface UserListOptions {
    selectedUser?: User;
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}
