import {Component, OnDestroy, OnInit} from '@angular/core';
import {FusionAuthService} from "@fusionauth/angular-sdk";
import {EventFarmAPIClient} from '../../ApiClient/event-farm-api-client';
import {LocalStorageService} from '../../_services/storage/local-storage.service';
import * as userActions from '../store/actions/user.action';
import {Store} from '@ngrx/store';
import * as fromStore from '../store';
import {Subscription} from "rxjs";
import { RouteGeneratorService } from '../../_services/routes/route-generator.service';
import { Router } from '@angular/router';

@Component({
    selector: 'empty-component',
    template: '',
})

export class AuthCallbackComponent implements OnInit, OnDestroy {

    profileJson: string = null;

    constructor(
        public auth: FusionAuthService,
        private efApi: EventFarmAPIClient,
        private localStorageService: LocalStorageService,
        private store: Store<fromStore.AppState>,
        private routeGenerator: RouteGeneratorService,
        private route: Router,

    ) {
    }

    sub: Subscription;

    // TODO look at cleaning this up
    async ngOnInit(): Promise<void> {
        try {

            // Reset cio get started display count (used for cio modal flow)
            const profile = await this.auth.getUserInfo();
            this.localStorageService.set('cioGetStartedDisplayCount', null);
            this.localStorageService.set('effectiveEfUserEmail', profile.email);
            this.store.dispatch(new userActions.GetUserMe(profile.email));
        } catch (err) {
            this.localStorageService.resetStorageAndLogout();
        }

    }

    ngOnDestroy() {
        // TODO Unsubscribing here causes the above code not to run, this needs to be refactored to be more elegant
        // this.sub.unsubscribe();
    }
}
