import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromStore from '../../app.state';
import {WaitListService} from '../../../EventsModule/WaitList/waitList.service';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {of} from 'rxjs';
import {TeamFeatureService} from '../../../AdminModule/Manage/ManageTeam/TeamFeatures/team-feature.service';
import * as adminCreateTeamActions from '../../actions/admin/admin-create-team.action';

import { NzMessageService } from 'ng-zorro-antd/message';
import * as adminActions from '../../actions/admin';

///TODO go back and fix

@Injectable()
export class AdminCreateTeamEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamFeatureService: TeamFeatureService,
        private messageService: NzMessageService
    ) { }

    // @ts-ignore
    createTeamForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType<adminCreateTeamActions.CreateTeamForAdmin>(adminCreateTeamActions.CREATE_TEAM_FOR_ADMIN),
        switchMap((action) => this.apiClient.getUseCaseFactory().Pool().CreatePool(action.payload.teamName, '')
            .pipe(
                map(res => res['data']),
                switchMap((res: any) => [
                    new adminActions.CreateTeamForAdminSuccess(res.command),
                    this.messageService.success('Team added!'),
                    new adminActions.AddContractToAdminTeam({teamId: res.command.poolId, contract: action.payload.contract})
                ]),
            )),
        catchError(err => {
            this.messageService.error('Error adding team!');
            return of(new adminActions.CreateTeamForAdminFail(err));
        })));

}
