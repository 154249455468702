import { Component} from '@angular/core';
import { Modal } from '../../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { AddressBookAddUserComponent } from './addressBookAddUser.component';

@Component({
    selector: 'address-book-add-user-modal-wrapper',
    template: '',
})

export class AddressBookAddUserModalWrapperComponent {
    constructor(
        private modalService: ModalService,
    ) {}

    ngOnInit() {
        this.openUserModal();
    }

    openUserModal() {
        const modal: Modal = new Modal();
        modal.title = 'Create New User'.toUpperCase();
        modal.component = AddressBookAddUserComponent;
        modal.cssClass = 'create-new-user';
        modal.data = {};
        this.modalService.changeModal(modal);
    }
}
