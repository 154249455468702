import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EfEvent } from '../../ApiClient/Models/Event/event';
import { Team } from '../../ApiClient/Models/Pool/team';
import { EventFarmAPIClient } from '../../ApiClient/event-farm-api-client';
import { RouteGeneratorService } from '../../_services/routes/route-generator.service';
import { EventFarmService } from '../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
@Injectable()

export class PoolResolver implements Resolve<any> {
    constructor(
        private client: EventFarmAPIClient,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private router: Router,
        private store: Store<fromRoot.AppState>
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return;
    }
}
