import { Component, ComponentFactoryResolver, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { UserFormService, BasicUserDetails } from '../../../../CoreUI/UserForm/userForm.service';
import { Subscription } from 'rxjs';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { ApiError } from '../../../../../ApiClient/Models/error';
import { EFHelper } from '../../../../../Shared/helper';
import { User } from '../../../../../ApiClient/Models/User/user';
import { RouteGeneratorService } from '../../../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'address-book-add-user',
    styles: [require('./address-book-add-user.scss')],
    template: require('./address-book-add-user.html'),
})

export class AddressBookAddUserComponent implements OnInit, OnDestroy {
    constructor(
        private modalService: ModalService,
        private userFormService: UserFormService,
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private message: NzMessageService,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private segmentService: SegmentService,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
        this.userFormSaved$ = this.userFormService.userChange$.subscribe(
            (res) => {
                this.basicUserDetails = res;
            },
            (err) => {}
        );
        this.userFormSubmitted$ = this.userFormService.userSubmit$.subscribe(
            (res) => {
                if (res.isValid) {
                    this.save();
                }
            }
        );
        this.userGroups$ = this.addressBookUserService.groupsSelectedForUser$.subscribe(
            (res) => {
                this.userFormService.basicUserDetails.hasChanges = true;
            }
        );
    };

    readonly userPersonBlueSVG = require('../../assets/circle-user-person-blue');
    readonly saveIconSVG = require('../../assets/save-icon');

    userFormSaved$: Subscription;
    userFormSubmitted$: Subscription;
    userGroups$: Subscription;
    basicUserDetails: BasicUserDetails;
    requesting: boolean = false;
    errors: ApiError[];

    ngOnInit() {
        this.basicUserDetails = this.userFormService.basicUserDetails;
        this.addressBookService.selectedUser = null;
        this.requesting = false;
        this.segmentService.segmentAddressBookTracking().openNewContact(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
    }

    hasChanges(): boolean {
        if (this.userFormService.basicUserDetails.hasChanges) {
            return true;
        }
        return false;
    }

    save() {
        this.segmentService.segmentAddressBookTracking().newContact(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.errors = [];
        this.requesting = true;
        this.userFormService.basicUserDetails.hasChanges = false;
        this.basicUserDetails = this.userFormService.basicUserDetails;
        let otherUserDataPromises: Promise<any>[] = [];

        this.addressBookUserService.createUser(
            this.basicUserDetails.email,
            this.basicUserDetails.firstName,
            this.basicUserDetails.lastName,
            this.basicUserDetails.company,
            this.basicUserDetails.position,
            this.basicUserDetails.telephone ? this.basicUserDetails.telephone['e164Number'] : null,
            this.eventFarmService.currentTeam.id,
            this.basicUserDetails.title,
            this.basicUserDetails.other)
        .toPromise()
        .then((res) => {

            if (this.basicUserDetails.address1 && this.basicUserDetails.city && this.basicUserDetails.state && this.basicUserDetails.postalCode) {
                const userAddress = this.addressBookUserService.addAddressToUser(
                    this.eventFarmService.currentTeam.id,
                    res.data.command.userId,
                    this.basicUserDetails.address1,
                    this.basicUserDetails.address2,
                    this.basicUserDetails.city,
                    this.basicUserDetails.state,
                    this.basicUserDetails.postalCode,
                    this.basicUserDetails.country
                ).toPromise();

                otherUserDataPromises.push(userAddress);
            }

            if (this.addressBookUserService.groupsSelectedForUser) {
                this.addressBookUserService.groupsSelectedForUser.forEach((userGroup) => {
                    otherUserDataPromises.push(this.addressBookUserService.addUsersToGroup(userGroup.id, [res.data.command.userId]).toPromise());
                });
            }
            if (otherUserDataPromises.length) {
                return Promise.all(otherUserDataPromises);
            } else {
                return {};
            }
        }).then((res) => {
            this.requesting = false;
            if (this.addressBookService.selectedGroup) {
                this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
            } else {
                this.addressBookUserService.broadcastAllUsers();
            }
            this.userFormService.basicUserDetails.hasChanges = false;
            this.basicUserDetails = this.userFormService.basicUserDetails;
            this.modalService.closeModal();
            this.message.success('Contact created');
        }).catch((err) => {

            if (err.status === 409) {
                let idForExistingUser = null;
                err.error.errors.forEach((error) => {
                    idForExistingUser = (error.meta && error.meta.userId) ? error.meta.userId : null;
                });

                let poolId = this.addressBookService.currentTeam.id;
                let existingUser = new User(idForExistingUser);

                this.addressBookUserService.getUserInPool(poolId, existingUser.id)
                    .toPromise()
                    .then((res) => {
                        if (res.data.relationships.userAttributes.length || res.data.relationships.userNames.length) {
                            this.requesting = false;
                            this.addressBookUserService.redirectToExistingUserInPool(existingUser, poolId);
                        } else {
                            this.updateUserAttributesForExistingUserAssociatedWithDifferentPool(existingUser.id);
                        }
                    }).catch((err) => {
                        this.updateUserAttributesForExistingUserAssociatedWithDifferentPool(existingUser.id);
                    });
                return;
            }
            this.errors = this.addressBookUserService.extractErrorsIntoMessages(err.error);
        });
    }

    updateUserAttributesForExistingUserAssociatedWithDifferentPool(existingUserId: string) {

        let existingUserDataPromises: Array<Promise<any>> = this.addressBookUserService.generateUserUpdateApiPromises(existingUserId, this.basicUserDetails);

        if (this.addressBookUserService.groupsSelectedForUser) {
            this.addressBookUserService.groupsSelectedForUser.forEach((userGroup) => {
                existingUserDataPromises.push(this.addressBookUserService.addUsersToGroup(userGroup.id, [existingUserId]).toPromise());
            });
        }

        this.requesting = false;
        if (existingUserDataPromises.length) {
            Promise.all(existingUserDataPromises)
                .then((res) => {
                    this.userFormService.basicUserDetails.hasChanges = false;
                    this.basicUserDetails = this.userFormService.basicUserDetails;
                    if (this.addressBookService.selectedGroup) {
                        this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                    } else {
                        this.addressBookUserService.broadcastAllUsers();
                    }
                    this.modalService.closeModal();
                    this.alertService.addressBook().userCreatedSuccess();
                }).catch((err) => {
                    let formattedErrors: any[] = [];
                    formattedErrors.push({
                        detail: 'Unable to save all contact details due to permission limitations.',
                    });
                    this.errors = ApiError.fromApiErrorArray(formattedErrors);
                });
        };
    }

    close() {
        this.modalService.closeModal();
    }

    ngOnDestroy() {
        this.userGroups$.unsubscribe();
        this.userFormSubmitted$.unsubscribe();
        this.userFormSaved$.unsubscribe();
    }
}
