import { Component, EventEmitter, Output, HostBinding, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { User } from '../../../ApiClient/Models/User/user';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { debounceTime, map, tap, distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'user-typeahead',
    template: require('./user-typeahead.html'),
    animations: [
        trigger('leavingState', [
            transition(':enter', [
                animate(0, style({ opacity: 0, top: '-1rem' })),
                animate('100ms ease-out', style({ opacity: 1, top: '0' })),
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0 })),
            ])
        ])
    ],
    host: {
        '[@leavingState]': '',
        '(document:click)': 'offClick($event)',
    }
})

export class UserTypeaheadComponent {
    private searchTerm: FormControl = new FormControl();
    private searchResults$ = new Subject<User[]>();
    private isSearching: boolean = false;
    @Output() selectionReceived: EventEmitter<any> = new EventEmitter();

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private elementRef: ElementRef
    ) {
        this.searchForUser();
    }

    @HostBinding('class.active') activeInput: boolean;

    private searchForUser() {
        this.searchTerm.valueChanges
            .pipe(debounceTime(200),
                tap(() => { this.isSearching = true; this.activeInput = true; }),
                distinctUntilChanged()
            ).subscribe((term) => {
                this.apiClient.getUseCaseFactory().User().ListUsersForPools(
                    [this.eventFarmService.currentTeam.id],
                    ['UserIdentifiers', 'UserAttributes', 'UserNames'],
                    term
                )
                    .pipe(map(res => res['data'].map(user => User.fromApiResponse((user)))))
                    .subscribe((results: User[]) => {
                        this.isSearching = false;
                        if (term) {
                            this.searchResults$.next(results);
                        } else {
                            this.searchResults$.next([]);
                        }
                    });
            });
    }

    private selectOption(option) {
        this.selectionReceived.emit(option);
        this.activeInput = false;
    }

    private offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.activeInput = false;
        }
    }

    private goToCreateNewUser() {
        const poolId = this.eventFarmService.currentTeam.id;
        this.router.navigate([this.routeGenerator.url('pools.address-book.list', { poolId }), {outlets: { 'add-user-outlet' : ['add-user']}}]);
    }
}
