import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaitListEntryComponent } from './waitListEntry.component';
import { WaitListComponent } from './waitList.component';

const routes: Routes = [
    {
        path: '',
        component: WaitListEntryComponent,
        children: [
            {
                path: '',
                component: WaitListComponent
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class WaitListRoutingModule {
}
