import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { EventAgendaService } from '../event-agenda.service';

@Component({
    selector: 'event-speakers',
    template: require('./speakers.html'),
    styles: [require('./speakers.scss')],
})

export class SpeakersComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventAgendaService: EventAgendaService
    ) {
    }

    async ngOnInit() {
        await this.eventAgendaService.getProfiles(true);
    }

    ngOnDestroy() {
        this.eventAgendaService.clearSpeakers();
    }
}
