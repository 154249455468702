import * as _ from 'lodash';
import {NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {RouteGeneratorService} from '../../../_services/routes/route-generator.service';
import {EventFarmAPIClient} from '../../../ApiClient/event-farm-api-client';
import {Team} from './../../../ApiClient/Models/Pool/team';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as userActions from '../actions/user.action';
import * as teamActions from '../actions/team.action';
import * as fromRoot from '..';
import {catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {LocalStorageService} from '../../../_services/storage/local-storage.service';
import * as Http from 'http';
import {HttpClient} from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private route: Router,
        private apiClient: EventFarmAPIClient,
        private store: Store<fromRoot.AppState>,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private routeGenerator: RouteGeneratorService,
    ) {
    }
    fullBaseUrl = window.location.protocol + '//' + window.location.host;

    // @ts-ignore
    getCurrentUser$ = createEffect(() =>  {
        return this.actions$.pipe(
            ofType<userActions.GetCurrentUser>(userActions.GET_CURRENT_USER),
            switchMap((action) => this.apiClient.getUseCaseFactory().User().GetUser(this.localStorageService.get('effectiveEfUserId'), ['UserName', 'UserAddress', 'UserIdentifier', 'isEFAdmin']).pipe(
                map(res => res['data']),
                switchMap((user) =>  [
                    new userActions.GetCurrentUserSuccess(user)
                ]),
                catchError(err => of(new userActions.GetCurrentUserFail(err)))
            )
            ),
        ); });

    getCurrentUserSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.GetCurrentUserSuccess>(userActions.GET_CURRENT_USER_SUCCESS),
        tap((action) => {
            Sentry.setUser({ id: _.get(action, 'payload.id') });
        }),
        mergeMap(action => [
            new userActions.FetchTeamsForUser(action.payload.id)
        ]),
    ));

    // @ts-ignore
    getUserByEmail$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.GetUserByEmail>(userActions.GET_USER_BY_EMAIL),
        take(1),
        switchMap((action) => {
            return this.apiClient.getUseCaseFactory().User().GetUserByEmail(action.payload).pipe(
            map(res => res['data']),
            switchMap((user) => of(new userActions.GetUserByEmailSuccess(user))),
            catchError(err => of(new userActions.GetUserByEmailFail(err)))
        ); }),
    ));

    // @ts-ignore
    getUserByEmailSuccess = createEffect(() => this.actions$.pipe(
        ofType<userActions.GetUserByEmailSuccess>(userActions.GET_USER_BY_EMAIL_SUCCESS),

        map((action) => {
            const {id} = action.payload;
            // Sets ids after login
            this.localStorageService.set('effectiveEfUserId', id);
            return action;
        }),

        switchMap((action) => this.apiClient.getUseCaseFactory().User().GetUser(action.payload.id, ['UserName', 'UserAddress', 'UserIdentifier', 'isEFAdmin'])
            .pipe(
                map(res => res['data']),
                switchMap((user) => [
                    new userActions.GetCurrentUserSuccess(user),
                ]),
                catchError(err => of(new userActions.GetCurrentUserFail(err)))
            ))
    ));

    getUserMe$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.GetUserMe>(userActions.GET_USER_ME),
        take(1),
        switchMap((action) => this.apiClient.getMe().pipe(
            map(res => res['data']),
            switchMap((user) => of(new userActions.GetUserMeSuccess(user))),
            catchError(err => {
                return  of(new userActions.GetUserMeFail(err));
            }
        )),
        )
    ));

    // @ts-ignore
    getUserMeSuccess = createEffect(() => this.actions$.pipe(
        ofType<userActions.GetUserMeSuccess>(userActions.GET_USER_ME_SUCCESS),
        map((action) => {
            const {id} = action.payload;
            // Sets ids after login
            this.localStorageService.set('effectiveEfUserId', id);
            this.localStorageService.set('efUserId', id);
            Sentry.setUser({ id: id });
            return action;
        }),
        tap(() => {
            this.route.navigateByUrl(this.routeGenerator.url('pools.dashboard'));
        }),
        switchMap((action) => this.apiClient.getUseCaseFactory().User().GetUser(action.payload.id, ['UserName', 'UserAddress', 'UserIdentifier', 'isEFAdmin'])
            .pipe(
                map(res => res['data']),
                switchMap((user) => [
                    new userActions.GetCurrentUserSuccess(user),
                ]),
                catchError(err => of(new userActions.GetCurrentUserFail(err)))
            ))
    ));

    // @ts-ignore
    getUserMeFailure = createEffect(() => this.actions$.pipe(
        ofType<userActions.GetUserMeSuccess>(userActions.GET_USER_ME_FAIL),
        map((action) => {
            return this.localStorageService.resetStorageAndLogout();

        })
    ));
    // @ts-ignore
    fetchTeamsForUser$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.FetchTeamsForUser>(userActions.FETCH_TEAMS_FOR_USER),
        switchMap((action) => this.apiClient.getUseCaseFactory().Pool().ListAccessiblePoolsForUser(action.payload).pipe(
            map(teams => {
                return teams['data']
                    .sort((a, b) => (a.attributes.name < b.attributes.name) ? -1 : (a.attributes.name > b.attributes.name) ? 1 : 0)
                    .map(team => Team.fromApiResponse(team));
            }),
            switchMap(teams => [
                new userActions.FetchTeamsForUserSuccess(teams),
            ]),
            catchError(err => of(new userActions.FetchTeamsForUserFail(err)))
        )),
    ));

    fetchTeamsForUserSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<userActions.FetchTeamsForUserSuccess>(userActions.FETCH_TEAMS_FOR_USER_SUCCESS),
        withLatestFrom(this.store),
        filter(([, store]) => !!Object.keys(store.router.state).length),
        take(1),
        switchMap(([teams, store]) => [
            store.router.state.params.pool ? (
                new teamActions.SetCurrentTeam(teams.payload.filter(team => team.id === store.router.state.params.pool)[0])
            ) : (
                new teamActions.SetCurrentTeam(teams.payload[0])
            ),
        ]),
    ));

    // @ts-ignore
    getCurrentUserPoolPermissions$ = createEffect(() => this.actions$
        .pipe(
        ofType<userActions.GetUserPoolPermissions>(userActions.GET_USER_POOL_PERMISSIONS),
            map((action) => action.payload),
            withLatestFrom(this.store),
            switchMap(([teamId, store]) => {
            return this.apiClient.getUseCaseFactory().Pool().ListPoolContactsByPoolId(teamId, 1, 500).pipe(map(pool => {
                const poolContact = pool['data'].filter(contact => contact.relationships.user.id === store.user.data.id)[0];
                const poolContactType = poolContact ? poolContact.attributes.poolContactType : false;
                const isCreate = poolContactType.isCreate || false;
                const isFull = poolContactType.isFull || false;
                return { teamId, type: { isFull, isCreate } };
            }),
                switchMap(res => [
                    new userActions.GetUserPoolPermissionsSuccess(res),
                ])
            );
        }),
    ));
}
