import {Component, OnDestroy, OnInit} from '@angular/core';
import { IconService } from '../../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { shareReplay } from 'rxjs/operators';
import { EfxStationService } from '../efx-station.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { sortFormat } from '../../../../../Utilities/nzTableSortFormat';
import { EditStationComponent } from './EditStation/edit-station.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
    selector: 'station-list',
    template: require('./station-list.html'),
    styles: [require('./station-list.scss')],
})

export class StationListComponent implements OnInit, OnDestroy {
    public drawerTitle: string = `Create ${this.efxStationService.currentModule} Station`;
    public width = '50%';

    constructor(
        private iconService: IconService,
        private efxStationService: EfxStationService,
        private store: Store<fromRoot.AppState>,
        private drawService: NzDrawerService
    ) {}

    ngOnInit(): void {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    async handleSearch(query: string) {
        this.efxStationService.efxStationsListOptions.pagination.currentEfxStationPage = 1;
        this.efxStationService.efxStationsListOptions.query = query;
        await this.efxStationService.getEfxStations();
    }

    async loadDataFromServer(pageIndex: number, sortField: string, sortOrder: string) {
        this.efxStationService.efxStationsListOptions.pagination.currentEfxStationPage = pageIndex;
        this.efxStationService.efxStationsListOptions.sortBy = sortField;
        this.efxStationService.efxStationsListOptions.sortDirection = sortOrder;
        await this.efxStationService.getEfxStations();
    }

    async onQueryParamsChange(params: NzTableQueryParams): Promise<void> {
        const { pageSize, pageIndex, sort, filter } = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;
        sortOrder = sortFormat(sortOrder);
        await this.loadDataFromServer(pageIndex, sortField, sortOrder);
    }

    openComponent(): void {
        const drawerRef = this.drawService.create<EditStationComponent, { }, string>({
            nzTitle: this.drawerTitle,
            nzContent: EditStationComponent,
            nzWidth: this.width,
            nzContentParams: {
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {

        });
    }

    ngOnDestroy() {
    }
}
