import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CioGetStartedStepperComponent } from './cio-get-started-stepper.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { UploaderSharedModule } from '../../../../../PoolsModule/Uploader/uploader-shared.module';
import { CioGetStartedStepperCompletedComponent } from './CompletedStep/cio-get-started-stepper-completed.component';
import { CioGetStartedStepperService } from './cio-get-started-stepper.service';

@NgModule({
    declarations: [CioGetStartedStepperComponent, CioGetStartedStepperCompletedComponent],
    exports: [CioGetStartedStepperComponent],
    entryComponents: [CioGetStartedStepperComponent, CioGetStartedStepperCompletedComponent],
    imports: [CommonModule, NzStepsModule, UploaderSharedModule],
    providers: [CioGetStartedStepperService]
})

export class CioGetStartedStepperModule {
}
