import { Component} from '@angular/core';
import { Modal } from '../../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { AddressBookEditUserComponent } from './addressBookEditUser.component';
import { AddressBookService } from '../../addressBook.service';

@Component({
    selector: 'address-book-edit-user-modal-wrapper',
    template: '',
})

export class AddressBookEditUserModalWrapperComponent {
    constructor(
        private modalService: ModalService,
        private addressBookService: AddressBookService,
    ) {}

    ngOnInit() {
        this.openUserModal();
    }

    openUserModal() {
        const modal: Modal = new Modal();
        modal.title = 'View/Edit Contact'.toUpperCase();
        modal.component = AddressBookEditUserComponent;
        modal.cssClass = 'edit-existing-user';
        modal.data = this.addressBookService.selectedUser;
        this.modalService.changeModal(modal);
    }
}
