import { Component, OnInit, OnDestroy, ViewContainerRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import {FusionAuthService} from "@fusionauth/angular-sdk";
import { Subscription ,  Observable } from 'rxjs';
import { EventFarmService } from './eventFarm.service';
import { SessionStorageService } from '../_services/storage/session-storage.service';
import { RouteGeneratorService } from '../_services/routes/route-generator.service';

import { Store } from '@ngrx/store';
import * as fromStore from './store';
import * as RouterActions from './store/actions/router.action';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from '../_services/storage/local-storage.service';

@Component({
    selector: 'app',
    template: require('./app.html'),
    styles: [require('./app.scss')],
    encapsulation: ViewEncapsulation.None
})

export class    AppComponent implements OnInit, OnDestroy, AfterViewInit {

    private user$: Observable<any>;
    private currentTeam$: Observable<any>;
    private userTeams$: Observable<any>;
    private addMobilePadding: boolean = true;

    constructor(
        private eventFarmService: EventFarmService,
        private viewContainerRef: ViewContainerRef,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private store: Store<fromStore.AppState>,
        private localStorageService: LocalStorageService,
        private fusionAuth: FusionAuthService
    ) {
        this.viewContainerRef = viewContainerRef;
        this.features = JSON.parse(viewContainerRef.element.nativeElement.getAttribute('features-data'));
        this.store.select('router').pipe(
            filter(res => Object.keys(res).length !== 0 && Object.keys(res.state.params).length !== 0),
        ).subscribe(route => {
            if (route.state.queryParams.ticketBlockId) {
                this.addMobilePadding = false;
            }
        });
    }
    private features: any;
    private loading: boolean;
    private fullWidth: boolean;
    private loadingMessage: string;

    ngOnInit() {
        this.features = [];
        this.loading = false;
        this.fullWidth = false;
        this.loadingMessage = 'Loading...';

        this.currentTeam$ = this.store.select(fromStore.getCurrentTeam);
        this.user$ = this.store.select(fromStore.getCurrentUser);
        this.userTeams$ = this.store.select(fromStore.getAllTeamsForCurrentUser);

        this.mobileCheck();
        // Handles automatically refreshing token for us
        this.fusionAuth.initAutoRefresh();

    }

    private mobileCheck() {
        this.localStorageService.resetNavBar.subscribe((resetNav) => {
           if (resetNav) {
               this.addMobilePadding = true;
           }
        });
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {

        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.loading = true;
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    setTimeout(() => {
                        this.loading = false;
                    }, 0);
                }
            });
    }
}
