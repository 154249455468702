import { BehaviorSubject } from "rxjs";
import { PoolFeature } from "../../../../../ApiClient/Models/PoolFeature/poolFeature";
import { Injectable } from "@angular/core";
import { EventFarmAPIClient } from "../../../../../ApiClient/event-farm-api-client";
import { Team } from "../../../../../ApiClient/Models/Pool/team";

@Injectable()
export class TeamEventsService {
  private _features: PoolFeature[];
  public poolLoading$ = new BehaviorSubject<any>(false);
  public tabLoading$ = new BehaviorSubject<any>(false);

  teams$ = new BehaviorSubject<Team[]>([]);

  constructor(private apiClient: EventFarmAPIClient) {
    // this.fetchAllTeams();
    // this.fetchAllFeatures();
  }

  /*** Event Configuration
   *
   *
   *
   *
   * ***/

  public getEventConfigModifyEventVariantPromise(
    eventId: string,
    eventVariant: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetEventVariant(eventId, eventVariant)
      .toPromise();
  }

  /*** CATEGORY
   *
   * ***/

  /*** RFID CONNECT
   *
   * ***/

  public getEventConfigModifyEventShieldPromise(
    eventId: string,
    enable: boolean
  ) {
    if (enable) {
      return this.apiClient
        .getUseCaseFactory()
        .Event()
        .EnableShield(eventId)
        .toPromise();
    } else {
      return this.apiClient
        .getUseCaseFactory()
        .Event()
        .DisableShield(eventId)
        .toPromise();
    }
  }

  /*** BACKGROUND URL
   *
   * ***/

  public getEventConfigModifyEventAltKeywordPromise(
    eventId: string,
    keyword: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetAltKeywordForEvent(eventId, keyword)
      .toPromise();
  }

  /*** Alt Layout
   *
   * ***/

  public getEventConfigModifyEventAltEmailLayoutPromise(
    eventId: string,
    altEmailLayout: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetAltEmailLayoutForEvent(eventId, altEmailLayout)
      .toPromise();
  }

  public getEventConfigModifyEventAltLayoutPromise(
    eventId: string,
    altLayout: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetAltLayoutForEvent(eventId, altLayout)
      .toPromise();
  }

  public getEventConfigModifyEventAltPaymentProcessorPromise(
    eventId: string,
    altPaymentProcessType: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetAltPaymentProcessorForEvent(eventId, altPaymentProcessType)
      .toPromise();
  }

  public getEventConfigModifyEventAltPaymentFilePromise(
    eventId: string,
    altPaymentFile: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetAltPaymentFileForEvent(eventId, altPaymentFile)
      .toPromise();
  }

  public getEventConfigModifyEventAltPayeePromise(
    eventId: string,
    altPayee: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetAltPayeeForEvent(eventId, altPayee)
      .toPromise();
  }

  public getEventConfigModifyEventCurrencyPromise(
    eventId: string,
    processingCurrency: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetProcessingCurrency(eventId, processingCurrency)
      .toPromise();
  }

  public getEventConfigModifyEventProcessingPercPromise(
    eventId: string,
    processingPercentage: number
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetProcessingPercentage(eventId, processingPercentage)
      .toPromise();
  }

  public getEventConfigModifyEventProcessingFeePromise(
    eventId: string,
    processingFee: number
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetProcessingFee(eventId, processingFee)
      .toPromise();
  }

  public getEventConfigModifyEventProcessingRefundPromise(
    eventId: string,
    processingRefund: number
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .SetProcessingRefund(eventId, processingRefund)
      .toPromise();
  }

  /*** License fee
   *
   * ***/

  /*** Mobile purchase
   *
   * ***/

  public getEventConfigModifyEventSetParentEventPromise(
    eventId: string,
    parentEventId: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .AddParentToEvent(eventId, parentEventId)
      .toPromise();
  }

  public getEventConfigRemoveParentEventPromise(eventId: string) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .RemoveParentFromEvent(eventId)
      .toPromise();
  }

  public getEventConfigModifyEventHealthPassShortCodePromise(
      eventId: string,
      healthPassShortCode: string
  ) {
    return this.apiClient
        .getUseCaseFactory()
        .Event()
        .SetHealthPassShortCodeForEvent(eventId, healthPassShortCode)
        .toPromise();
  }
}
