import { EventFarmService } from '../../../eventFarm.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../../store';
import * as adminActions from '../../actions/admin/';
import * as fromRoot from '../../../store';

import { mergeMap, switchMap, map, filter, catchError, tap, take, withLatestFrom, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import {TeamAllotmentService} from '../../../AdminModule/Manage/ManageTeam/TeamAllotments/team-allotment.service';
import {AdminService} from '../../../AdminModule/admin.service';
import {Allotment} from '../../../../ApiClient/Models/Pool/allotment';
import {Team} from '../../../../ApiClient/Models/Pool/team';
import {TeamPointOfContactService} from '../../../AdminModule/Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {TeamFeatureService} from '../../../AdminModule/Manage/ManageTeam/TeamFeatures/team-feature.service';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';

@Injectable()
export class AdminEffects {
    constructor(
        private actions$: Actions,
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>,
        private teamAllotmentService: TeamAllotmentService,
        private teamPointOfContactService: TeamPointOfContactService,
        private teamFeaturesService: TeamFeatureService,
        private adminService: AdminService

    ) { }


    setCurrentAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.SetCurrentAdminTeam>(adminActions.SET_CURRENT_ADMIN_TEAM),
        switchMap(action => [
            new adminActions.FetchAdminTeam(action.payload.id)
        ]),
    ));


    // @ts-ignore
    fetchAdminTeam = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchAdminTeam>(adminActions.FETCH_ADMIN_TEAM),
        map(action => action.payload),
        switchMap((teamId: string) => this.adminService.fetchPoolById(teamId)
            .pipe(
                map(res => res['data']),
                switchMap((team) => [
                    new adminActions.FetchAdminTeamSuccess(Team.fromApiResponse(team)),
                ]),
                catchError(err => of(new adminActions.FetchAdminTeamFail(err)))
            ))
    ));

    fetchAdminTeamSuccess = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchAdminTeamSuccess>(adminActions.FETCH_ADMIN_TEAM_SUCCESS),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => [
            new adminActions.FetchContractForAdminTeam(team.id),
            new adminActions.FetchEventsForAdminTeam(team.id),
            new adminActions.FetchAllotmentsForAdminTeam(team.id),
        ]),
    ));


    // @ts-ignore
    fetchEventsForAdminTeam = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchEventsForAdminTeam>(adminActions.FETCH_EVENTS_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.adminService.fetchEventsByPool(team.id, action.payload.currentPage, action.payload.itemsPerPage, action.payload.name)
            .pipe(
                map(res => {
                    let data =  res['data']
                        .map(e =>  EfEvent.fromApiResponse(e));
                    return {meta: res['meta'], data}

                }),
                switchMap((res: any) => [
                    new adminActions.FetchEventsForAdminTeamSuccess({meta:res.meta, data:res.data})
                ]),
            )),
        catchError(err => of(new adminActions.FetchEventsForAdminTeamFail(err)))
    ));

    // @Effect()
    // fetchContractForTeam$ = this.actions$.pipe(
    //     ofType<teamActions.FetchContractForTeam>(teamActions.FETCH_CONTRACT_FOR_TEAM),
    //     map(action => action.payload),
    //     filter(team => !!team),
    //     switchMap((teamId: string) => this.apiClient.getUseCaseFactory().Pool().GetPoolContract(teamId).pipe(
    //         switchMap(contract => [
    //             new teamActions.FetchContractForTeamSuccess(contract['data'])
    //         ]),
    //         catchError(err => of(new teamActions.FetchContractForTeamFail(err)))
    //     )),
    // );


    //
    // @Effect()
    // fetchPoolWorldsForTeamAdmin$ = this.actions$.pipe(
    //     ofType<adminActions.FetchPoolWorldsForTeam>(adminActions.FETCH_POOL_WORLDS_FOR_TEAM),
    //     map(action => action.payload),
    //     switchMap((teamId: string) => this.apiClient.getUseCaseFactory().PoolWorld().ListPoolWorldsForPool(teamId)
    //         .pipe(
    //             map(poolWorlds => {
    //                 return poolWorlds['data']
    //                     .map(pw => PoolWorld.fromApiResponse(pw));
    //             }),
    //             switchMap(poolWorlds => [
    //                 new adminActions.FetchPoolWorldsForTeamSuccess(poolWorlds)
    //             ]),
    //         )),
    //     catchError(err => of(new adminActions.FetchPoolWorldsForTeamFail(err)))
    // );
    //
    //
    //
    //
    // @Effect()
    // fetchEventsForTeamAdmin$ = this.actions$.pipe(
    //     ofType<teamActions.FetchEventsForTeam>(teamActions.FETCH_EVENTS_FOR_TEAM),
    //     map(action => action.payload),
    //     withLatestFrom(this.store),
    //     map(([team, store]) => team ? team : store.router.state.params.pool),
    //     withLatestFrom(this.store),
    //     mergeMap(([team, store]) => this.eventFarmService.listAllEventsForUserByPool(store.user.data.id, team).pipe(
    //         switchMap((events: EfEvent[]) => [
    //             new teamActions.FetchEventsForTeamSuccess(events)
    //         ])
    //     )),
    // );
}
