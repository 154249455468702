import { Component } from '@angular/core';
import { AddressBookService } from './addressBook.service';
import { EventFarmService } from '../../eventFarm.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';

@Component({
    selector: 'address-book-list',
    template: require('./address-book-list.html'),
    styles: [require('./address-book-list.scss')],
})

export class AddressBookListComponent {

    constructor(
        private addressBookService: AddressBookService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
    ) {}

    readonly addGroupPeopleSVG = require('./assets/add-group-people');
    readonly addUserPersonSVG = require('./assets/add-user-person');
    readonly uploadSVG = require('./assets/upload');


    ngOnInit() {
        this.segmentService.segmentAddressBookTracking().openAddressBook(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        )
    }

    goToUploader() {
        this.segmentService.segmentAddressBookTracking().uploadGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.addressBookService.goToUploader();
    }

    openAddUserModal() {
        this.addressBookService.openAddUserModal();
    }

    openAddGroupModal() {
        this.addressBookService.openAddGroupModal();
    }
}
