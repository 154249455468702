import { Component } from '@angular/core';

import { ViewMessagingService } from './view-messaging.service';
import { EventFarmService } from '../../../eventFarm.service';

@Component({
    template: require('./view-messaging.html'),
    providers: [ViewMessagingService],
    styles: [require('./view-messaging.scss')],

})

export class ViewMessagingComponent {
    constructor(private viewMessagingService: ViewMessagingService, private eventFarmService: EventFarmService) {
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }
}
