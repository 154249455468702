import { Component} from '@angular/core';
import { Modal } from '../../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { AddressBookAddGroupComponent } from './addressBookAddGroup.component';

@Component({
    selector: 'address-book-add-group-modal-wrapper',
    template: '',
})

export class AddressBookAddGroupModalWrapperComponent {
    constructor(
        private modalService: ModalService,
    ) {}

    ngOnInit() {
        this.openGroupModal();
    }

    openGroupModal() {
        const modal: Modal = new Modal();
        modal.title = 'Create New Group'.toUpperCase();
        modal.component = AddressBookAddGroupComponent;
        modal.cssClass = 'create-new-group';
        modal.data = {};
        this.modalService.changeModal(modal);
    }
}
