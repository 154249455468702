import { Injectable } from '@angular/core';
import {ModalService} from '../../../modal.service';
import { Modal } from '../../../../Modal/modal.class';
import {EventFarmService} from '../../../../../eventFarm.service';
import {CioGetStartedWelcomeComponent} from './cio-get-started-welcome.component';
@Injectable()
export class CioGetStartedWelcomeService {
    constructor(
        private modalService: ModalService,
        private eventFarmService: EventFarmService
    ) {
    }

    public launchCioModal() {
        const modal: Modal = new Modal();
        modal.logoSrc = 'https://cdn.eventfarm.com/platform/img/modals/cio/EventFarm_logo_gray-stacked.png';
        modal.logoAlt = 'Event Farm and Clear logos';
        modal.background = '#FCFCFC';
        modal.position = 'relative';
        modal.title = 'Welcome! Glad you are here!';
        modal.component = CioGetStartedWelcomeComponent;
        modal.inputs = [
            {input: 'flowType', val: 'cio'},
            {input: 'subHeader', val: 'Add your guests'},
            {input: 'mainText', val: 'Your event is set up.  Let’s walk through the steps to add contacts to the guest list.  Completing this early will help make your event a success.'},
            {input: 'list', val: `
            <ul class="dynamicModalList">
             <li>Verify your <strong>Guests health status</strong> ahead of time</li>
             <li>Allow time for <strong>additional communication</strong> prior to the event and ensure guest attendance.</li>
             <li>Provide a <strong>great check-in experience</strong> to your guests</li>
            </ul>`}
        ];
        modal.cssClass = 'cio';
        this.modalService.changeModal(modal);
        this.modalService.showModal();
    }

    public launchCioPlusClearModal() {
        const modal: Modal = new Modal();
        modal.logoSrc = 'https://cdn.eventfarm.com/platform/img/modals/cio/ef_and_clear_logo.png';
        modal.logoAlt = 'Event Farm and Clear logos';
        modal.background = '#FCFCFC';
        modal.position = 'relative';
        modal.title = `Welcome! Glad you're here!`;
        modal.component = CioGetStartedWelcomeComponent;
        modal.inputs = [
            {input: 'flowType', val: 'cioPlusClear'},
            {input: 'subHeader', val: 'Why upload my Guest List?'},
            {input: 'mainText', val: `
            Uploading Guests to your Guest List allows for a more seamless experience for your event and for your guests.`},
            {input: 'list', val: `
            <ul class="dynamicModalList">
             <li>Upload from an existing file or download a template</li>
             <li>Identify contact information that should be corrected</li>
             <li>Familiarize yourself with the Check-In app in advance to ensure a great check-in experience</li>
            </ul>`}
        ];

        modal.cssClass = 'cio';

        this.modalService.changeModal(modal);
        this.modalService.showModal();
    }


}
