import { WaitListService } from './../waitList.service';
import { Component, Input } from '@angular/core';
import { ClickOutsideDirective } from './../../../CoreUI/Directives/click-outside.directive';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';

@Component({
    selector: 'waitlist-item',
    template: require('./waitListItem.html'),
    styles: [require('./waitListItem.scss')],
})

export class WaitListItemComponent {

    @Input() invitation: Invitation;

    private checked: boolean = false;

    get isChecked(): boolean {
        this.checked = this.waitListService.invitationsToUpdate.includes(this.invitation.id);
        return this.checked;
    }

    private deleteInitialState = {
        text: 'Delete',
        confirm: false,
        deleting: false,
    };

    private delete = this.deleteInitialState;

    constructor(private waitListService: WaitListService) {
    }

    private toggleUpdateList() {
        if (!this.checked) {
            this.waitListService.invitationsToUpdate.push(this.invitation.id);
            if (this.waitListService.currentWaitlist.length === this.waitListService.invitationsToUpdate.length) {
                this.waitListService.selectAll = true;
            }
        } else {
            this.waitListService.invitationsToUpdate = this.waitListService.invitationsToUpdate.filter(id => id !== this.invitation.id);
            this.waitListService.selectAll = false;
        }
    }

    private deleteInvitation() {
        if (this.delete.confirm) {
            this.delete.deleting = true;
            return this.waitListService.updateInvitation(this.invitation, 'Delete');
        }
        this.delete.text = 'Click to Confirm';
        this.delete.confirm = !this.delete.confirm;
    }

    private clickOutside() {
        this.delete = {
            text: 'Delete',
            confirm: false,
            deleting: false,
        };
    }
}
