import { Component, Input, OnInit } from '@angular/core';
import { IconService } from '../../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as eventActions from './../../../../store/actions/event';
import * as fromRoot from './../../../../store';
import { Stack } from './../../../../../ApiClient/Models/Stack/stack';
import { Design } from '../../../EmailDesignCenter/Models/design';

import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';


@Component({
    selector: '[ic-row-item]',
    template: require('./ic-row-item.html'),
    styles: [require('./ic-row-item.scss')]
})

export class IcRowItemComponent implements OnInit {
    @Input() stack: Stack;
    @Input() designs;
    isSettingDesign;

    private editMode: boolean = false;

    private stackDesigns: object;

    constructor(
        private iconService: IconService,
        private store: Store<fromRoot.AppState>,
        private actions$: Actions
    ) { }


    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    ngOnInit() {
        this.stackDesigns = this.designKeysForStack.reduce((acc, key) => {
            return {
                ...acc,
                [key]: { ...this.stack[key] }
            };
        }, {});

    }

    private updateDesign(design) {
        const prevDesignId = this.stackDesigns[design.type].emailId;
        const newDesignId = design ? design.design.emailId : prevDesignId;
        this.stackDesigns[design.type] = {
            stackId: this.stack.id,
            emailId: design.design.emailDesignId,
            update: prevDesignId !== newDesignId,
        };
    }

    private handleEditSave() {
        if (this.editMode) {
            if (!this.checkForUpdates()) {
                return this.editMode = false;
            }
            this.isSettingDesign = true;
            const designsByKey: DesignKeysForStack = this.designKeysForStack.reduce((attrs, key) => {
                if (key != null && this.stackDesigns[key] && this.stackDesigns[key].emailId !== '') {
                    return {
                        ...attrs,
                        [key]: this.stackDesigns[key].emailId,
                    };
                }
                return { ...attrs };
            }, {
                    stackId: this.stack.id
                });
            this.store.dispatch(new eventActions.SetDesigns(designsByKey));
        }
        this.actions$.pipe(ofType(eventActions.SET_DESIGNS_SUCCESS)).subscribe((res: any) => {
            this.isSettingDesign = false;
            this.editMode = false;
        });
        this.editMode = true;
    }

    checkForUpdates() {
        return Object.keys(this.stackDesigns).filter((key: any) => this.stackDesigns[key].update).length > 0;
    }

    private get designKeysForStack() {
        return this.stack.designKeys;
    }
}

export interface DesignKeysForStack {
    stackId: string;
    inviteEmailId?: string | null;
    confirmEmailId?: string | null;
    declineEmailId?: string | null;
}
