import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Question } from '../../../../../../ApiClient/Models/Question/question';
import { EventFarmService } from '../../../../../eventFarm.service';
import { QuestionLogicComponent } from './question-logic.component';

@Component({
  selector: 'question-logic-button',
  styles: [require('./question-logic.scss')],
  template: `
    <span *ngIf="(eventFarmService.currentEventRoles$ | async)['event-crud']" nzTooltipTitle="Question logic" nz-tooltip (click)="openComponent()">
        <i class="fa fa-puzzle-piece edit-btn" aria-hidden="true"></i>
    </span>
  `
})

export class EditQuestionLogicButtonComponent implements OnInit {
  @Input() question;

  constructor(
    private drawerService: NzDrawerService,
    private eventFarmService: EventFarmService
  ) {}

  ngOnInit() {
  }

  openComponent(): void {
    const drawerRef = this.drawerService.create<QuestionLogicComponent, { question: Question }, string>({
      nzTitle: 'Question Logic',
      nzContent: QuestionLogicComponent,
      nzWidth: '50%',
      nzContentParams: {
        question: this.question,
      }
    });

    drawerRef.afterOpen.subscribe(() => {

    });

    drawerRef.afterClose.subscribe(data => {

    });
  }

}
