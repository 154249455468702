import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {PopoverModule} from 'ngx-bootstrap/popover';

import { GuestSimpleStatsComponent } from './guestSimpleStats.component';

@NgModule({
    declarations: [GuestSimpleStatsComponent],
    exports: [GuestSimpleStatsComponent],
    imports: [
        CommonModule,
        PopoverModule,
    ],
})

export class GuestSimpleStatsModule {
}
