
import { Component, OnInit } from '@angular/core';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { shareReplay } from 'rxjs/operators';
import { AlertService } from '../../../eventFarmAlert.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { EventFarmService } from '../../../eventFarm.service';
import { QuestionCenterService } from '../question-center.service';
import { DragulaService } from 'ng2-dragula';


@Component({
    selector: 'question-center-list',
    template: require('./question-center-list.html'),
    styles: [require('./question-center-list.scss'), require('./dragula.scss'),]
})
export class QuestionCenterListComponent implements OnInit {
    constructor(
        private iconService: IconService,
        private questionCenterService: QuestionCenterService,
        private store: Store<fromRoot.AppState>,
        private alertService: AlertService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private eventFarmService: EventFarmService,
        private dragulaService: DragulaService,
    ) {
        this.dragulaService.dropModel.subscribe((drag) => this.sortQuestion(drag));
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private individualDragulaBag: string = 'individual-questions-bag';
    private transactionDragulaBag: string = 'transaction-questions-bag';

    private dragulaOptions = {
        moves: (el, container, handle) => handle.dataset.drag,
    };

    ngOnInit(): void {
        this.questionCenterService.getQuestions();
    }


    private async sortQuestion(drag) {
        if ((drag[0] !== this.individualDragulaBag) && (drag[0] !== this.transactionDragulaBag)) {
            return;
        }

        const droppedElement = drag[1];

        const parent = droppedElement.parentElement;

        let index = 0;
        let foundIndex;

        for (const child of parent.children) {
            if (child === droppedElement) {
                foundIndex = index;
                break;
            }

            index++;
        }

        if (foundIndex === null) {
            return;
        }

        if (drag[0] === this.individualDragulaBag) {
            await this.questionCenterService.setQuestionSortOrder(droppedElement.dataset.id, foundIndex + 1)
        } else {
            await this.questionCenterService.setQuestionSortOrder(droppedElement.dataset.id, this.questionCenterService.visibleIndividualQuestionCount + foundIndex + 1)
        }
        await this.questionCenterService.getQuestions()
    }
}
