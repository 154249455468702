import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebPresenceService } from '../web-presence.service';

@Component({
    selector: 'web-presence-settings',
    template: require('./web-presence-settings.html'),
    styles: [require('./web-presence-settings.scss'), require('../web-presence.scss')],
})

export class WebPresenceSettingsComponent implements OnInit, OnDestroy {

    constructor(
        private webPresenceService: WebPresenceService
    ) { }

    ngOnInit() {
        this.webPresenceService.init();
    }

    ngOnDestroy() {
        this.webPresenceService.reset();
    }

}
