
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { WebPresenceService } from '../../web-presence.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  selector: 'web-presence-nav-options',
  template: require('./web-presence-nav-options.html'),
  styles: [require('./web-presence-nav-options.scss'), require('../../web-presence.scss')],

})

export class WebPresenceNavOptionsComponent implements OnDestroy {

  constructor(
    private webPresenceService: WebPresenceService,
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      this.toggleStateShowCalendar = this.eventFarmService.currentEvent.eventAttributes.hasShowCalendar;
      this.toggleStateSocialMedia = this.eventFarmService.currentEvent.eventAttributes.hasSocialMediaBar;

    });
  }

  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  private toggleStateShowCalendar: boolean = false;
  private toggleStateSocialMedia: boolean = false;

  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;

  ngOnDestroy(): void {
    this.eventUpdate$.unsubscribe();
  }

  //show media bar

  toggleShowSocialMedia() {
    this.toggleStateSocialMedia = !this.toggleStateSocialMedia;
    if (this.eventFarmService.currentEvent.eventAttributes.hasSocialMediaBar) {
      this.disableSocialMedia();
    } else {
      this.enableSocialMedia();
    }
  }

  enableSocialMedia() {
    this.apiClient.getUseCaseFactory().Event().EnableSocialMediaBar(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasSocialMediaBar = true;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Show Social Media On Confirmation Enabled');
      });
  }

  disableSocialMedia() {
    this.apiClient.getUseCaseFactory().Event().DisableSocialMediaBar(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasSocialMediaBar = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Show Social Media On Confirmation Disabled');
      });
  }

  //show calendar

  toggleShowCalendar() {
    this.toggleStateShowCalendar = !this.toggleStateShowCalendar;
    if (this.eventFarmService.currentEvent.eventAttributes.hasShowCalendar) {
      this.disableShowCalendar();
    } else {
      this.enableShowCalendar();
    }
  }

  enableShowCalendar() {
    this.apiClient.getUseCaseFactory().Event().EnableShowCalendar(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasShowCalendar = true;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Show Calendar Links Enabled');
      });
  }

  disableShowCalendar() {
    this.apiClient.getUseCaseFactory().Event().DisableShowCalendar(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasShowCalendar = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Show Calendar Links Disabled');
      });
  }

}
