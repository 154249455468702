import { tap, switchMap, map, concatMap, expand } from 'rxjs/operators';
import { of, Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as eventActions from '../../actions/event';
import * as fromStore from '../../app.state';
import { WaitListService } from '../../../EventsModule/WaitList/waitList.service';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';

@Injectable()
export class GuestListEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService
    ) { }

    private fetchGuestList = (eventId, currentPage = 1): Observable<any> => {
        return this.apiClient.getUseCaseFactory().Invitation().ListInvitationsForEvent(
            eventId,
            ['UserIdentifiers'],
            ['info'],
            null,
            null,
            null,
            null,
            null,
            null,
            currentPage,
            250
        );
    }


    fetchGuestList$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchGuestList>(eventActions.FETCH_GUEST_LIST),
        map(res => res.payload.id),
        switchMap(eventId => {
            return this.fetchGuestList(eventId).pipe(
                expand(res => res.meta.totalPages === 0 || res.meta.totalPages === res.meta.currentPage ? (
                    empty()
                 ) : (
                     this.fetchGuestList(eventId, res.meta.currentPage + 1))),
                concatMap(res => of(res)),
            );
        }),
        tap((res: any) => {
            const data = res.data ? res.data.map(invitation => Invitation.fromApiResponse(invitation)) : [];
            return {res, ...data};
        }),
        switchMap(invitations => [
            new eventActions.FetchGuestListSuccess(invitations)
        ])
    ));
}
