import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiError, ErrorInterface } from '../../../ApiClient/Models/error';
import { EventFarmService } from '../../eventFarm.service';
import { EFHelper } from '../../../Shared/helper';
import { BasicUserDetails, UserFormService } from './userForm.service';
import { User } from '../../../ApiClient/Models/User/user';
import { isEqual, cloneDeep } from 'lodash';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from '@khazii/ngx-intl-tel-input';
import { Observable } from 'rxjs';
@Component({
    selector: 'user-form',
    styles: [require('./user-form.scss')],
    template: require('./user-form.html'),
})

export class UserFormComponent implements OnInit {
    private selectUndefinedOptionValue: any;

    constructor(
        private userFormService: UserFormService,
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
    ) {
    }

    @Input() user?: User;
    @Input() userAddEditContext: 'edit-invitation' | 'add-user' | 'edit-user';

    @ViewChild('userForm', {static: false}) userForm;

    private icons: any = {
        address: require('../assets/Form/contact-field-address.svg'),
        email: require('../assets/Form/contact-field-email.svg'),
        name: require('../assets/Form/contact-field-name.svg'),
        phone: require('../assets/Form/contact-field-phone.svg'),
        position: require('../assets/Form/contact-field-position.svg'),
        company: require('../assets/Form/contact-field-company.svg'),
        title: require('../assets/Form/contact-field-title.svg'),
        other: require('../assets/Form/contact-field-other.svg')
    };
    private errors: ErrorInterface[];
    private showAddlFields: boolean;
    private initialBasicUserDetails: BasicUserDetails;
    private isDisabled: boolean;
    private canChangeEmailForExistingUser: boolean;
    private isEditingEmail: boolean;
    private canDuplicateNewEmailOntoNewUser: boolean;
    private animateState: string;
    private usaStates: any;
    private countries: any;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventRoleType: any;

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];

    ngOnInit() {
        this.userFormService.userAddEditContext = this.userAddEditContext || 'add-user';
        this.checkIncomingUserData();
        this.checkPermission();
        this.canDuplicateNewEmailOntoNewUser = false;
        this.userFormService.requesting = false;
        this.canChangeEmailForExistingUser = true;
        this.isEditingEmail = false;
        this.isDisabled = false;
        this.showAddlFields = false;
        this.usaStates = this.userFormService.fetchUsaStateList();
        this.countries = this.userFormService.fetchCountryList();
        this.canChangeExistingEmail();
    }

    ngOnChanges(changes: any): void {
        this.checkIncomingUserData();
        this.canChangeExistingEmail();
    }

    ngAfterViewInit() {
        this.userForm.control.valueChanges
            .pipe(debounceTime(50),
                distinctUntilChanged())
            .subscribe(values => {
                this.userFormService.basicUserDetails.hasChanges = true;
                this.userFormService.basicUserDetails.isValid = this.hasRequiredFields();
                this.initialBasicUserDetails.isValid = this.hasRequiredFields();
                this.initialBasicUserDetails.hasChanges = this.userFormService.basicUserDetails.hasChanges;

                for (let prop in this.userFormService.basicUserDetails) {
                    if (this.userFormService.basicUserDetails[prop] && this.userFormService.basicUserDetails[prop] === '') {
                        this.userFormService.basicUserDetails[prop] = null;
                    }
                }

                if (isEqual((this.initialBasicUserDetails), (this.userFormService.basicUserDetails))) {
                   this.userFormService.basicUserDetails.hasChanges = false;
                }
            });
    }

    hasRequiredFields(): boolean {

        let userDetails = {
            email: this.userFormService.basicUserDetails.email,
            firstName: this.userFormService.basicUserDetails.firstName,
            lastName: this.userFormService.basicUserDetails.lastName,
        } as CreateUser;

        if (!this.hasFirstAndLastName(userDetails) && !this.hasValidEmail(userDetails)) {
            return false;
        }

        if (this.initialBasicUserDetails && this.initialBasicUserDetails.email && !this.hasValidEmail(userDetails)) {
            return false;
        }

        if (!this.hasFirstOrLastName(userDetails) && this.userFormService.userAddEditContext === 'add-user') {
            return false;
        }

        return true;
    }

    checkIncomingUserData() {
        this.userFormService.basicUserDetails = {};

        this.userFormService.basicUserDetails.firstName = (this.user && this.user.name && this.user.name.firstName) ? this.user.name.firstName : null;

        this.userFormService.basicUserDetails.lastName = (this.user && this.user.name && this.user.name.lastName) ? this.user.name.lastName : null;

        this.userFormService.basicUserDetails.email = (this.user && this.user.identifier && this.user.primaryEmail) ? this.user.primaryEmail : null;

        this.userFormService.basicUserDetails.address1 = (this.user && this.user.addresses && this.user.addresses[0] && this.user.addresses[0].address1) ? this.user.addresses[0].address1 : null;

        this.userFormService.basicUserDetails.address2 = (this.user && this.user.addresses && this.user.addresses[0] && this.user.addresses[0].address2) ? this.user.addresses[0].address2 : null;

        this.userFormService.basicUserDetails.city = (this.user && this.user.addresses && this.user.addresses[0] && this.user.addresses[0].city) ? this.user.addresses[0].city : null;

        this.userFormService.basicUserDetails.state = (this.user && this.user.addresses && this.user.addresses[0] && this.user.addresses[0].state) ? this.user.addresses[0].state : null;

        this.userFormService.basicUserDetails.country = (this.user && this.user.addresses && this.user.addresses[0] && this.user.addresses[0].country) ? this.user.addresses[0].country : 'US';

        this.userFormService.basicUserDetails.postalCode = (this.user && this.user.addresses && this.user.addresses[0] && this.user.addresses[0].postalCode) ? this.user.addresses[0].postalCode : null;

        this.userFormService.basicUserDetails.company = (this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('company')) ? this.user.getAttributeType('company') : null;

        this.userFormService.basicUserDetails.position = (this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('position')) ? this.user.getAttributeType('position') : null;

        this.userFormService.basicUserDetails.telephone = (this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('telephone')) ? this.user.getAttributeType('telephone') : null;

        this.userFormService.basicUserDetails.title = (this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('title')) ? this.user.getAttributeType('title') : null;

        this.userFormService.basicUserDetails.other = (this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('other')) ? this.user.getAttributeType('other') : null;

        this.hasRequiredFields() ? this.userFormService.basicUserDetails.isValid = true : this.userFormService.basicUserDetails.isValid = false;

        this.userFormService.basicUserDetails.hasChanges = false;

        this.initialBasicUserDetails = cloneDeep(this.userFormService.basicUserDetails);
        this.userFormService.initialUserDetails = cloneDeep(this.initialBasicUserDetails);
    }

    checkPermission() {
        if (this.eventFarmService.currentEvent && this.eventRoleType && this.eventRoleType['list-crud']) {
            return true;
        }
        if (this.eventFarmService.currentEvent && !this.eventRoleType) {
            return true;
        }
        return false;
    }

    canChangeExistingEmail() {
        this.canChangeEmailForExistingUser = true;
        this.canDuplicateNewEmailOntoNewUser = false;
        if (this.userAddEditContext === 'edit-user' && this.initialBasicUserDetails) {
            this.canChangeEmailForExistingUser = false;
            this.canDuplicateNewEmailOntoNewUser = true;
        }
    }

    toggleEditingEmail() {
        this.isEditingEmail = !this.isEditingEmail;

    }

    startUserDuplicationOntoNewUser(newEmail: string) {
        if (newEmail === this.userFormService.initialUserDetails.email) {
            return;
        }
        this.toggleEditingEmail();
        this.userFormService.requesting = true;
        this.userFormService.basicUserDetails.email = newEmail;
        this.userFormService.change();
    }

    hasFirstAndLastName(userDetails: CreateUser): boolean {
        return !EFHelper.isEmpty(userDetails.firstName) && !EFHelper.isEmpty(userDetails.lastName);
    }

    hasFirstOrLastName(userDetails: CreateUser): boolean {
        return !EFHelper.isEmpty(userDetails.firstName) || !EFHelper.isEmpty(userDetails.lastName);
    }

    hasValidEmail(userDetails: CreateUser): boolean {
        const emailIsStringAtStringDotString = /\S+@\S+\.\S+/;
        return !EFHelper.isEmpty(userDetails.email) && emailIsStringAtStringDotString.test(userDetails.email);
    }

    toggleFields() {
        this.showAddlFields = !this.showAddlFields;
        if (this.showAddlFields) {
            this.animateState = 'show';
        } else {
            this.animateState = 'hide';
        }
    }
}

interface CreateUser {
    email?: string;
    firstName?: string;
    lastName?: string;
}
