import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../admin.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { PoolContact } from '../../../../../ApiClient/Models/PoolContact/poolContact';
import { TeamEchoWorldsService } from './team-echo-worlds.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import { PoolWorld } from '../../../../../ApiClient/Models/PoolWorld/pool-world';
import { VirbelaWorld } from '../../../../../ApiClient/Models/VirbelaWorld/virbela-world';
import { AdminAlertService } from '../../../Alert/adminEventFarmAlert.service';
import * as adminPoolWorldAction from '../../../../store/actions/admin/admin-pool-world.action';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../../store';
import * as fromRoot from '../../../../store';

import {shareReplay} from 'rxjs/operators';
@Component({
  selector: 'team-echo-worlds',
  template: require('./team-echo-worlds.html'),
  styles: [require('./team-echo-worlds.scss')],
})
export class TeamEchoWorldsComponent implements OnInit, OnDestroy {
  @Input() team: Team;
  teamPointOfContactForm: FormGroup;
  existingContactsForm: FormGroup;
  poolContactTypes: any;
  contactsToUpdate: {};
  existingContacts: {};
  modifiedContacts: {};
  dynamicForm: FormGroup;
  dynamicContactForm: FormGroup;
  submitted = false;
  manageVirbelaWorlds: FormGroup;
  public placeholder: string = 'Enter the Team Name';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private teamEchoWorldsService: TeamEchoWorldsService,
    private adminAlertService: AdminAlertService,
    private store: Store<fromStore.AppState>

  ) {}

  virbelaWorlds$ = new BehaviorSubject<VirbelaWorld[]>([]);

  ngOnInit() {
    this.teamEchoWorldsService.virbelaWorlds$.subscribe(val => {
      this.virbelaWorlds$.next(val.data);
    });
    this.store.dispatch(new adminPoolWorldAction.FetchVirbelaWorldsForAdmin());
    this.store.dispatch(new adminPoolWorldAction.FetchPoolWorldsForAdminTeam());

    this.manageVirbelaWorlds = new FormGroup({
      selectedVirbelaWorld: new FormControl(''),
    });
  }

  async handleVirbelaWorldChange(virbelaWorld) {
    this.store.dispatch(new adminPoolWorldAction.AddPoolWorldToAdminTeam({teamId: this.team.id, virbelaWorldId: virbelaWorld.id}));
  }

  handleModifiedContact(event, contactId) {
    this.modifiedContacts[contactId] = event.slug;
  }

  updateContacts() {}

   removeWorld(world: PoolWorld) {
    this.store.dispatch(new adminPoolWorldAction.RemovePoolWorldFromAdminTeam({poolWorldId: world.id}));
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dynamicForm.invalid) {
      return;
    }

    // display form values on success
    alert(
      'SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4)
    );
  }

  onReset() {
    // reset whole form back to initial state
    this.submitted = false;
    this.dynamicForm.reset();
  }

  onClear() {
    this.submitted = false;
  }

  ngOnDestroy() {
    // this.listVirbelaWorlds$.unsubscribe();
  }

  addPoolWorldToTeam(selectedVirbelaWorld: FormControl) {}
}
