import { SweetAlert } from '../sweet-alert';
import { AbstractBaseAlert } from '../base-alert';

export class EmailDesignCenterAlert extends AbstractBaseAlert {

    missingRequiredField(errors: string[]): Promise<any> {
        return this.alert({
            title: `Missing Info`,
            html: `<p>Looks like you didn't fill out all the required fields.</p> <p><b>${errors.join(', ')}</b></p>`,
            icon: 'warning',
            confirmButtonText: 'OK',
        });
    }

    incompleteDomainMask(): Promise<any> {
        return this.alert({
            title: `Missing Domain Mask Info`,
            html: `<p>Looks like you didn't complete the Domain Mask form.</p> <p>Take another look and provide the missing info.</p>`,
            icon: 'warning',
            confirmButtonText: 'OK',
        });
    }

    missingDynamicElement(elem): Promise<any> {
        return this.alert({
            title: `Missing Info`,
            html: `<p>Don't forget to include [${elem.name}]</p>`,
            icon: 'warning',
            confirmButtonText: 'OK',
        });
    }

    missingInviteLink(): Promise<any> {
        return this.alert({
            title: 'Required Link Missing',
            icon: 'warning',
            input: 'checkbox',
            text: 'This invitation email is missing the personalized invitation link [INVITE_LINK].  In order for your guests to register for this event, this link must be included.  Are you sure you wish to save this invitation without the link?',
            inputValue: 0,
            inputPlaceholder:
              'I have read, understand, and accepted these risks.',
            cancelButtonText:
              'Cancel',
            showCancelButton: true,
            confirmButtonText:
              'Save',
            inputValidator: (result) => {
              return !result && 'You need to accept to save without an [INVITE_LINK]';
            }
          });
    }

    linkDynamicElement(): Promise<any> {
        return this.alert({
            title: 'Include a Link',
            text: 'This will be a clickable link. What would you like it to say?',
            showCancelButton: true,
            input: 'text',
        });
    }

    unsavedChanges(): Promise<any> {
        return this.alert({
            title: 'You have unsaved changes',
            text: 'Are you sure you want to leave the design editor?',
            confirmButtonText: 'Exit without Saving',
            showCancelButton: true,
            cancelButtonText: 'Review Changes',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#3085d6',
            allowOutsideClick: true,
        });
    }

    guestMessageSendSuccess(): Promise<any> {
        return this.alert({
            title: `Message Sent`,
            html: `Guest Message Sent Successfully!`,
            icon: 'success',
            confirmButtonText: 'OK',
        });
    }

    guestMessageSendError(): Promise<any> {
        return this.alert({
            title: `Message Send Error`,
            html: `Something went wrong sending your message, please try again!`,
            icon: 'warning',
            confirmButtonText: 'OK',
        });
    }

    removeScheduledEmail(): Promise<any> {
        return this.alert({
            title: 'Remove Scheduled Message?',
            text: 'Are you sure you want to remove this scheduled message? Please note removal will fail if you did not request this scheduled message.',
            confirmButtonText: 'Remove Message',
            icon: 'question',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            showCancelButton: true,
        }).catch(() => {});
    }

    removeScheduledEmailError(): Promise<any> {
        return this.alert({
            title: 'Unable To Remove Scheduled Email',
            text: 'Please contact your CSM!',
            icon: 'error',
            confirmButtonText: 'OK',
        }).catch(() => {});
    }

    confirmRemoveEmailDesign(designName: string): Promise<any> {
        return this.alert({
            title: `Delete Design`,
            text: `Are you sure you want to delete ` + designName + `?`,
            icon: 'question',
            focusCancel: true,
            showCancelButton: true,
            confirmButtonColor: '#ea2b38',
            confirmButtonText: 'Delete Design',
        });
    }

    confirmCreateDuplicateEmailDesign(designName: string): Promise<any> {
        return this.alert({
            title: `Duplicate Design`,
            text: `This will create a new design named "[COPY] ` + designName + `" and load all designs for current event.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Duplicate Design',
        });
    }

    messageScheduledSuccess(): Promise<any> {
        return this.alert({
            title: 'Message Scheduled',
            text: 'Your message has been scheduled and will be sent at the time selected',
            icon: 'success'
        });
    }

    testEmailSucccess(emailAddress: string): Promise<any> {
        return this.alert({
            title: 'Test Email Sent!',
            text: 'The test has been sent to: ' + emailAddress,
            icon: 'success',
        });
    }

    testEmailFail(): Promise<any> {
        return this.alert({
            title: 'Test Email Failed.',
            text: 'We could not send out the test email. Please try again later, or contact Customer Success.',
            icon: 'error',
        });
    }

}
