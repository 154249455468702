import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploaderTabsService } from './uploaderTabs.service';
import { UploaderService } from './uploader.service';

@Component({
    selector: 'uploader-tabs',
    template: require('./uploader-tabs.html'),
    styles: [require('./uploader-tabs.scss')],
})

export class UploaderTabsComponent implements OnInit {
    readonly alertIconSVG = require('./assets/alert-icon');

    constructor(
        public uploaderTabsService: UploaderTabsService,
        public uploaderService: UploaderService,
        public router: Router,
    ) {

    }

    ngOnInit() {

    };
}
