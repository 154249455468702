import { tap, take, withLatestFrom, switchMap, filter, map, catchError, mergeMap, concatMap, shareReplay, concatMapTo, flatMap, merge, concat, zip, combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromStore from '../../app.state';
import { WaitListService } from '../../../EventsModule/WaitList/waitList.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

import { forkJoin, of } from 'rxjs';

import * as adminAllotmentActions from '../../actions/admin/admin-allotment.action';
import { NzMessageService } from 'ng-zorro-antd/message';
import {TeamAllotmentService} from '../../../AdminModule/Manage/ManageTeam/TeamAllotments/team-allotment.service';
import {Allotment} from '../../../../ApiClient/Models/Pool/allotment';
import * as fromRoot from '../../../store';

@Injectable()
export class AdminAllotmentEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamAllotmentService: TeamAllotmentService,
        private messageService: NzMessageService
    ) { }


    // @ts-ignore
    fetchAllotmentsForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminAllotmentActions.FetchAllotmentsForAdminTeam>(adminAllotmentActions.FETCH_ALLOTMENTS_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamAllotmentService.fetchAllotmentsByPoolId(team.id)
            .pipe(
                map(allotments => {
                    return allotments['data']
                        .map(e =>  Allotment.fromApiResponse(e));
                }),
                switchMap((allotments: Allotment[]) => [
                    new adminAllotmentActions.FetchAllotmentsForAdminTeamSuccess(allotments)
                ]),
            )),
        catchError(err => of(new adminAllotmentActions.FetchAllotmentsForAdminTeamFail(err)))));


    // @ts-ignore
    addAllotmentForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminAllotmentActions.AddAllotmentToAdminTeam>(adminAllotmentActions.ADD_ALLOTMENT_TO_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team ]) => this.teamAllotmentService.addAllotmentToPool(team.id, action.payload.type, action.payload.amount).pipe(
            switchMap((allotment: Allotment) => [
                new adminAllotmentActions.AddAllotmentToAdminTeamSuccess(allotment),
                this.messageService.success('Allotment added to pool'),
                new adminAllotmentActions.FetchAllotmentsForAdminTeam(action.payload.teamId),
            ]),
            catchError(err => {
                    this.messageService.error('Error adding allotment to pool');
                    return of(new adminAllotmentActions.FetchAllotmentsForAdminTeamFail(err));
            })))));


    // @ts-ignore
    updateAllotmentForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminAllotmentActions.UpdateAllotmentForAdminTeam>(adminAllotmentActions.UPDATE_ALLOTMENT_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team ]) => this.teamAllotmentService.updateAllotmentForPool(team.id, action.payload.allotmentId, action.payload.amount).pipe(
            switchMap((allotment: Allotment) => [
                new adminAllotmentActions.UpdateAllotmentForAdminTeamSuccess(allotment),
                this.messageService.success('Allotment updated for pool'),
                new adminAllotmentActions.FetchAllotmentsForAdminTeam(action.payload.teamId),
            ]),
            catchError(err => {
                this.messageService.error('Error updating allotment for pool');
                return of(new adminAllotmentActions.UpdateAllotmentForAdminTeamFail(err));
            })))));

}
