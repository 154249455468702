import { Injectable } from '@angular/core';
import { AlertService } from '../../eventFarmAlert.service';
import { Observable, Subject } from 'rxjs';

import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { Invitation } from '../../../ApiClient/Models/Invitation/invitation';
import {
    InvitationCreationTypeInterface,
    InvitationStatusTypeInterface
} from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { ArrivalAlertUtility, ArrivalAlerts } from '../../../Utilities/arrivalAlert.utility';

@Injectable()
export class InvitationOptionsService {
    public stacks: Stack[];
    public invitationCreationTypes: InvitationCreationTypeInterface[];
    public invitationOptions: InvitationOptions;
    public invitationCreationContext: 'invitation' | 'invitation-ticketblock' | 'add-group' | 'add-group-ticketblock' | 'upload-group';
    private _selectedStackId: string;
    private _selectedStack?: Stack;
    private _selectedStatus?: InvitationStatusTypeInterface;
    public validStatusesSource = new Subject<any>();
    public validStatuses$ = this.validStatusesSource.asObservable();

    private _selectedTicketTypeId?: string;
    private _selectedTicketType?: TicketTypeDTO;
    public showTimeSlots: boolean = false;
    public ticketTypeAndStacksList: TicketTypeDTO[] = [];

    public invitationCount: number;
    public initialInvitation: Invitation;
    public arrivalAlertOptions: ArrivalAlertOptions;
    public currentEvent: EfEvent;

    constructor(
        private alertService: AlertService,
        private apiClient: EventFarmAPIClient,
    ) {
        this.invitationCreationTypes = this.getInvitationCreationTypes();
        this.invitationOptions = {
            checkInNotes: '',
            invitationNotes: '',
            shouldSendInvitation: false,
            isCheckedIn: false,
            selectedStackId: '',
            existingArrivalAlertSettings: {
                shouldSendArrivalAlert: false,
                arrivalEmails: '',
                arrivalPhoneNumbers: '',
            }
        };
    }

    getInvitationCreationTypes(): InvitationCreationTypeInterface[] {
        return this.apiClient.getTypeFactory().Invitation().InvitationCreationType().reverse();
    }

    getInvitationStatusTypes() {
        return this.apiClient.getTypeFactory().Invitation().InvitationStatusType();
    }

    getAllInvitationTypesForStackMethodType(stackMethodType: string) {
        this.apiClient.getUseCaseFactory().Invitation().GetAllInvitationStatusTypesForStackMethodType(stackMethodType)
            .subscribe((res) => {
                this.validStatusesSource.next(res.data.invitationStatusTypesForStackMethodType);
            });
    }

    getStackFromId(stackId: string) {
        let stacks: Stack[];

        stacks = this.stacks.filter((stack) => {
            return stack.id === stackId;
        });

        return stacks[0];
    }

    private getTicketTypeFromId(ticketTypeId: string) {
        return this.ticketTypeAndStacksList.find(tt => tt.id === ticketTypeId);
    }

    get selectedStack(): Stack | null {
        return this._selectedStack;
    }

    get selectedStatus(): InvitationStatusTypeInterface | null {
        return this._selectedStatus;
    }

    get selectedStackId(): string {
        return this._selectedStackId;
    }

    set selectedStackId(stackId: string) {
        this._selectedStackId = stackId;
        this._selectedStack = this.getStackFromId(stackId);
    }

    get selectedTicketTypeId(): string {
        return this._selectedTicketTypeId;
    }

    set selectedTicketTypeId(ticketTypeId: string) {
        this._selectedTicketTypeId = ticketTypeId;
        this._selectedTicketType = this.getTicketTypeFromId(ticketTypeId);
        if (this._selectedTicketType.stacks.length === 1) {
            this.showTimeSlots = false;
        } else {
            this.showTimeSlots = true;
        }
    }

    get selectedTicketType(): TicketTypeDTO | null {
        return this._selectedTicketType ? this._selectedTicketType : null;
    }

    getArrivalAlertForCreation(): ArrivalAlerts {
        const arrivalUtility = new ArrivalAlertUtility();

        const arrivalAlerts = arrivalUtility.format(
            this.arrivalAlertOptions.shouldSendArrivalAlert,
            this.arrivalAlertOptions.arrivalEmails,
            this.arrivalAlertOptions.arrivalPhoneNumbers
        )

        return arrivalAlerts;
    }

    resetSelectedStack() {
        this._selectedStack = null;
        this._selectedStackId = null;
        this.initialInvitation = null;
        this._selectedTicketType = null;
        this._selectedTicketTypeId = null;
        this.showTimeSlots = false;
        this.ticketTypeAndStacksList = [];
    }

    set selectedStatus(status: InvitationStatusTypeInterface) {
        this._selectedStatus = status;
    }

    initTicketTypes() {
        this.ticketTypeAndStacksList = this.getTicketTypeAndStacksList();
    }

    getTicketTypeAndStacksList(): TicketTypeDTO[] {

        const ticketTypeDTOArray: TicketTypeDTO[] = [];

        const sortedStacks = this.stacks.sort((a, b) => {
            const nameA = a.ticketType.name.toUpperCase();
            const nameB = b.ticketType.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            if (a.expirationStartTime && b.expirationStartTime) {
                return a.expirationStartTime - b.expirationStartTime

            }

            return 0;
        });

        sortedStacks.forEach(ss => {
            if (ticketTypeDTOArray.length === 0) {
                ticketTypeDTOArray.push({
                    id: ss.ticketType.id,
                    name: ss.ticketType.name,
                    stacks: [ss]
                })
            } else {
                if (ticketTypeDTOArray[ticketTypeDTOArray.length-1].id === ss.ticketType.id) {
                    ticketTypeDTOArray[ticketTypeDTOArray.length-1].stacks.push(ss);
                } else {
                    ticketTypeDTOArray.push({
                        id: ss.ticketType.id,
                        name: ss.ticketType.name,
                        stacks: [ss]
                    })
                }

            }
        })

        return ticketTypeDTOArray;
    }


    private configureTicketTypeNameForDisplay(ticketType: TicketTypeDTO) {
        let name = ticketType.name;
        let totalRemaining = 0;

        ticketType.stacks.forEach(s => {
            totalRemaining += s.availabilityCounts.totalUnused
        })

        return `${name} (${totalRemaining} remain)`;

    }

}

export interface InvitationOptions {
    checkInNotes?: string;
    shouldSendInvitation: boolean;
    isCheckedIn: boolean;
    invitationNotes?: string;
    selectedStackId?: string;
    existingArrivalAlertSettings?: ArrivalAlertOptions;
}

export interface ArrivalAlertOptions {
    shouldSendArrivalAlert: boolean;
    arrivalEmails: string;
    arrivalPhoneNumbers: string
}

export interface TicketTypeDTO {
    id: string
    name: string
    stacks: Stack[]
}
