import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { GuestSimpleStatsModule } from '../../CoreUI/GuestSimpleStats/guestSimpleStats.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { EditSaveButtonModule } from '../../CoreUI/EditSaveButton/edit-save-button.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { EventAgendaRoutingModule } from './event-agenda-routing.module';
import { EventAgendaService } from './event-agenda.service';
import { EventAgendaComponent } from './event-agenda.component';
import { SpeakersListComponent } from './Speakers/SpeakersList/speakers-list.component';
import { SpeakersItemComponent } from './Speakers/SpeakersList/SpeakersItem/speakers-item.component';
import { DebounceSearchModule } from '../../CoreUI/DebounceSearch/debounce-search.module';
import { EditSpeakersComponent } from './Speakers/SpeakersList/EditSpeakers/edit-speakers.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PictureCropperComponent } from '../_Shared/PictureCropper/picture-cropper.component';
import { SessionsListComponent } from './Sessions/SessionsList/sessions-list.component';
import { SpeakersComponent } from './Speakers/speakers.component';
import { EditSessionsComponent } from './Sessions/SessionsList/EditSessions/edit-sessions.component';
import { SessionsItemComponent } from './Sessions/SessionsList/SessionsItem/sessions-item.component';
import { SessionsComponent } from './Sessions/sessions.component';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { SpeakerToSessionComponent } from './Speakers/SpeakerToSession/speaker-to-session.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TracksComponent } from './Tracks/tracks.component';
import { TracksListComponent } from './Tracks/TracksList/tracks-list.component';
import { TracksItemComponent } from './Tracks/TracksList/TracksItem/tracks-item.component';
import { EditTracksComponent } from './Tracks/TracksList/EditTracks/edit-tracks.component';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { SponsorsComponent } from './Sponsors/sponsors.component';
import { SponsorListComponent } from './Sponsors/SponsorsList/sponsor-list.component';
import { SponsorItemComponent } from './Sponsors/SponsorsList/SponsorItem/sponsor-item.component';
import { EditSponsorComponent } from './Sponsors/SponsorsList/EditSponsor/edit-sponsor.component';
import { SponsorToSessionComponent } from './Sponsors/SponsorsToSession/sponsor-to-session.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [EventAgendaComponent],
    declarations: [
        EventAgendaComponent,
        SpeakersListComponent,
        SpeakersItemComponent,
        EditSpeakersComponent,
        PictureCropperComponent,
        SessionsListComponent,
        SpeakersComponent,
        SessionsComponent,
        EditSessionsComponent,
        SessionsItemComponent,
        SpeakerToSessionComponent,
        SponsorToSessionComponent,
        TracksComponent,
        TracksListComponent,
        TracksItemComponent,
        EditTracksComponent,
        SponsorsComponent,
        SponsorListComponent,
        EditSponsorComponent,
        SponsorItemComponent,
    ],
    entryComponents: [
        EventAgendaComponent,
        EditSpeakersComponent,
        PictureCropperComponent,
        EditSessionsComponent,
        SpeakerToSessionComponent,
        SponsorToSessionComponent,
        EditTracksComponent,
        EditSponsorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GuestSimpleStatsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        EventAgendaRoutingModule,
        NzUtilitiesModule,
        EditSaveButtonModule,
        DebounceSearchModule,
        NzUploadModule,
        ImageCropperModule,
        ModalModule,
        MatChipsModule,
        AutocompleteLibModule,
        NgxUtilitiesModule
    ],
    providers: [
        EventAgendaService,
        ModalService,
        RouteGeneratorService
    ],
    exports: [
        PictureCropperComponent
    ]
})

export class EventAgendaModule {
}
