import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { Action } from '@ngrx/store';
import { Team } from '../../../ApiClient/Models/Pool/team';

export const FETCH_CURRENT_TEAM = '[Team] Fetch Current Team';
export const FETCH_CURRENT_TEAM_FAIL = '[Team] Fetch Current Team Fail';
export const FETCH_CURRENT_TEAM_SUCCESS = '[Team] Fetch Current Team Success';

export const SET_CURRENT_TEAM = '[Team] Set Current Team';
export const SET_CURRENT_TEAM_SUCCESS = '[Team] Set Current Team Success';
export const SET_CURRENT_TEAM_FAIL = '[Team] Set Current Team Fail';

export const FETCH_CONTRACT_FOR_TEAM = '[Team] Fetch Contract For Team';
export const FETCH_CONTRACT_FOR_TEAM_SUCCESS = '[Team] Fetch Contract For Team Success';
export const FETCH_CONTRACT_FOR_TEAM_FAIL = '[Team] Fetch Contract For Team Fail';

export const FETCH_FEATURES_FOR_TEAM = '[Team] Fetch Features For Team';
export const FETCH_FEATURES_FOR_TEAM_SUCCESS = '[Team] Fetch Features For Team Success';
export const FETCH_FEATURES_FOR_TEAM_FAIL = '[Team] Fetch Features For Team Fail';

export const FETCH_POOL_WORLDS_FOR_TEAM = '[Team] Fetch Pool Worlds For Team';
export const FETCH_POOL_WORLDS_FOR_TEAM_SUCCESS = '[Team] Fetch Pool Worlds For Team Success';
export const FETCH_POOL_WORLDS_FOR_TEAM_FAIL = '[Team] Fetch Pool Worlds For Team Fail';

export const FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM = '[Team] Fetch Web Conference Connections For Team';
export const FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM_SUCCESS = '[Team] Fetch Web Conference Connections For Team Success';
export const FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM_FAIL = '[Team] Fetch Web Conference Connections For Team Fail';

export const SET_EVENTS_FOR_TEAM = '[Team] Set Events For Current Team';
export const FETCH_EVENTS_FOR_TEAM = '[Team] Fetch Events For Current Team';
export const FETCH_EVENTS_FOR_TEAM_FAIL = '[Team] Fetch Events For Current Team Fail';
export const FETCH_EVENTS_FOR_TEAM_SUCCESS = '[Team] Fetch Events For Current Team Success';

export class FetchCurrentTeam implements Action {
    readonly type = FETCH_CURRENT_TEAM;
    constructor(public payload: string) { }
}

export class FetchCurrentTeamFail implements Action {
    readonly type = FETCH_CURRENT_TEAM_FAIL;
    constructor(public payload?: any) { }
}

export class FetchCurrentTeamSuccess implements Action {
    readonly type = FETCH_CURRENT_TEAM_SUCCESS;
    constructor(public payload: Team) { }
}

export class SetCurrentTeam implements Action {
    readonly type = SET_CURRENT_TEAM;
    constructor(public payload: any) { }
}

export class SetCurrentTeamSuccess implements Action {
    readonly type = SET_CURRENT_TEAM_SUCCESS;
    constructor(public payload: any) { }
}

export class SetCurrentTeamFail implements Action {
    readonly type = SET_CURRENT_TEAM_FAIL;
    constructor(public payload: any) { }
}

export class FetchContractForTeam implements Action {
    readonly type = FETCH_CONTRACT_FOR_TEAM;
    constructor(public payload: {}) { }
}

export class FetchContractForTeamSuccess implements Action {
    readonly type = FETCH_CONTRACT_FOR_TEAM_SUCCESS;
    constructor(public payload: {}) { }
}

export class FetchContractForTeamFail implements Action {
    readonly type = FETCH_CONTRACT_FOR_TEAM_FAIL;
    constructor(public payload: {}) { }
}

export class FetchPoolWorldsForTeam implements Action {
    readonly type = FETCH_POOL_WORLDS_FOR_TEAM;
    constructor(public payload: {}) { }
}

export class FetchPoolWorldsForTeamSuccess implements Action {
    readonly type = FETCH_POOL_WORLDS_FOR_TEAM_SUCCESS;
    constructor(public payload: {}) { }
}

export class FetchPoolWorldsForTeamFail implements Action {
    readonly type = FETCH_POOL_WORLDS_FOR_TEAM_FAIL
    constructor(public payload: string) { }
}

export class FetchWebConferenceConnectionsForTeam implements Action {
    readonly type = FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM;
    constructor(public payload: {}) { }
}

export class FetchWebConferenceConnectionsForTeamSuccess implements Action {
    readonly type = FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM_SUCCESS;
    constructor(public payload: {}) { }
}

export class FetchWebConferenceConnectionsForTeamFail implements Action {
    readonly type = FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM_FAIL
    constructor(public payload: string) { }
}

export class FetchFeaturesForTeam implements Action {
    readonly type = FETCH_FEATURES_FOR_TEAM;
    constructor(public payload: {}) { }
}

export class FetchFeaturesForTeamSuccess implements Action {
    readonly type = FETCH_FEATURES_FOR_TEAM_SUCCESS;
    constructor(public payload: {}) { }
}

export class FetchFeaturesForTeamFail implements Action {
    readonly type = FETCH_FEATURES_FOR_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class SetEventsForTeam implements Action {
    readonly type = SET_EVENTS_FOR_TEAM;
    constructor(public payload) {}
}

export class FetchEventsForTeam implements Action {
    readonly type = FETCH_EVENTS_FOR_TEAM;
    constructor(public payload?: string) {}
}

export class FetchEventsForTeamFail implements Action {
    readonly type = FETCH_EVENTS_FOR_TEAM_FAIL;
    constructor(public payload: string) {}
}

export class FetchEventsForTeamSuccess implements Action {
    readonly type = FETCH_EVENTS_FOR_TEAM_SUCCESS;
    constructor(public payload: EfEvent[]) {}
}

export type TeamAction =
    FetchCurrentTeam |
    FetchCurrentTeamFail |
    FetchCurrentTeamSuccess |
    SetCurrentTeam |
    SetCurrentTeamSuccess |
    SetCurrentTeamFail |
    FetchContractForTeam |
    FetchContractForTeamSuccess |
    FetchContractForTeamFail |
    FetchFeaturesForTeam |
    FetchFeaturesForTeamSuccess |
    FetchFeaturesForTeamFail |
    FetchPoolWorldsForTeam |
    FetchPoolWorldsForTeamSuccess |
    FetchPoolWorldsForTeamFail |
    FetchWebConferenceConnectionsForTeam |
    FetchWebConferenceConnectionsForTeamSuccess |
    FetchWebConferenceConnectionsForTeamFail |
    SetEventsForTeam |
    FetchEventsForTeam |
    FetchEventsForTeamFail |
    FetchEventsForTeamSuccess;
