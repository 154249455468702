import { ModalService } from './modal.service';

export abstract class ModalServiceComponent {
    protected serviceData: Object;

    constructor(public modalService: ModalService) {
        this.serviceData = this.modalService.data;
    }

    close() {
        this.modalService.closeModal();
    }
}
