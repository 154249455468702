import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {ContractDetailsFormComponent} from './contract-details-form.component';
import {NzUtilitiesModule} from '../../../../NZUtilities/nz.utilities.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ManageRoutingModule} from '../../Manage/manage.routing-module';
import {BreadcrumbsModule} from '../../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {LoaderGrayModule} from '../../../CoreUI/LoaderGray/loader-gray.module';
import {AdminAlertModule} from '../../Alert/admin-alert.module';
import {AdminService} from '../../admin.service';
import {EventFarmService} from '../../../eventFarm.service';
import {AlertService} from '../../../eventFarmAlert.service';

@NgModule({
    declarations: [ContractDetailsFormComponent],
    exports: [ContractDetailsFormComponent],
    imports: [
        ManageRoutingModule,
        CommonModule,
        FormsModule,
        BreadcrumbsModule,
        ReactiveFormsModule,
        NzUtilitiesModule,
        LoaderGrayModule,
        AdminAlertModule,
    ],
    entryComponents: [ContractDetailsFormComponent],
    providers: [
        AdminService,
        EventFarmService,
        NzDrawerService,
        AlertService,
    ],
})

export class ContractDetailsFormModule {
}
