import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import {PaginationModule} from 'ngx-bootstrap/pagination'

import { IconService } from '../../Icons/icons.service';
import { GuestRelatedInvitationsLogComponent } from './guest-related-invitations-log.component';
import { GuestRelatedInvitationsLogService } from './guest-related-invitations-log.service';

const forExport = [
    GuestRelatedInvitationsLogComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        PaginationModule,
    ],
    providers: [
        GuestRelatedInvitationsLogService,
        IconService
    ]
})

export class GuestRelatedInvitationsLogModule {
}