import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { EventFarmAPIClient } from '../../../../../../../../ApiClient/event-farm-api-client';
import { Team } from '../../../../../../../../ApiClient/Models/Pool/team';

@Injectable()
export class EditEventEfxService {
  public currentTeamDomainMasks$ = new BehaviorSubject<any>(null);

  teams$ = new BehaviorSubject<Team[]>([]);

  constructor(
    private apiClient: EventFarmAPIClient,
    private messageService: NzMessageService
  ) {}

  public getModuleAdminSwitchData() {
    return this.apiClient.getTypeFactory().EFx().EFxModuleType();
  }

  public fetchModulesForEvent(eventId) {
    return this.apiClient
      .getUseCaseFactory()
      .EFx()
      .GetAllModulesForEvent(eventId);
  }

  public enableEfxForEvent(eventId: string) {
    return this.apiClient.getUseCaseFactory().Event().EnableEfx(eventId);
  }

  public disableEfxForEvent(eventId: string) {
    return this.apiClient.getUseCaseFactory().Event().EnableEfx(eventId);
  }

  public enableEfxModuleForEvent(eventId: string, moduleType: string) {
    return this.apiClient
      .getUseCaseFactory()
      .EFx()
      .EnableModuleForEvent(eventId, moduleType);
  }

  public disableEfxModuleForEvent(eventId: string, moduleType: string) {
    return this.apiClient
      .getUseCaseFactory()
      .EFx()
      .DisableModuleForEvent(eventId, moduleType);
  }
}
