
import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { EFXAdminService } from '../../efx-admin.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'efx-sms-arrival-configure',
  template: require('./efx-sms-arrival-configure.html'),
  styles: [require('./efx-sms-arrival-configure.scss'), require('../../efx-admin.scss')],

})

export class EFXSMSArrivalConfigureComponent implements OnDestroy {

  constructor(
    private efxAdminService: EFXAdminService,
    private message: NzMessageService
  ) {
  }

  @ViewChild('smsGreetingTxarea', {static: false}) greetingTextAreaRef: ElementRef;

  private readonly FIRST_NAME = 'first_name';
  private readonly LAST_NAME = 'last_name';
  private readonly EMAIL = 'email';
  private readonly PHONE = 'mobile_number';
  private readonly QR_CODE = 'qr_code';


  get characterCount(): number {
    return this.efxAdminService.smsGreeting$.value.length;
  }

  async addSlug(slug: string) {

    let slugToAdd = '';

    switch (slug) {
      case(this.FIRST_NAME):
        slugToAdd = `[${this.FIRST_NAME}]`;
        break;
      case(this.LAST_NAME):
        slugToAdd = `[${this.LAST_NAME}]`;
        break;
      case(this.EMAIL):
        slugToAdd = `[${this.EMAIL}]`;
        break;
      case(this.PHONE):
        slugToAdd = `[${this.PHONE}]`;
        break;
      case(this.QR_CODE):
        slugToAdd = `[${this.QR_CODE}]`;
        break;
      default:
    }

    if ((this.characterCount + slugToAdd.length) < 160) {
      const start = this.greetingTextAreaRef.nativeElement.selectionStart;
      const end = this.greetingTextAreaRef.nativeElement.selectionEnd;
      const newGreeting = this.efxAdminService.smsGreeting$.value.slice(0, start) + slugToAdd + this.efxAdminService.smsGreeting$.value.slice(end);
      this.efxAdminService.smsGreeting$.next(newGreeting);
      await this.greetingTextAreaRef.nativeElement.focus();
      setTimeout(function() {
        const element = document.getElementById('efxtxtarea') as HTMLInputElement;
        const location = end + slugToAdd.length;
        element.setSelectionRange(location, location);
       }, 10);
    } else {
      this.message.warning('Message length too long');
    }

  }

  saveGreeting() {
    this.efxAdminService.setGreetingText();
  }

  ngOnDestroy() {
  }
}
