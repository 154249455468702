import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { TicketStacksListComponent } from './ticketStacksList.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [TicketStacksListComponent],
    exports: [TicketStacksListComponent],
    imports: [
        CommonModule,
        UtilitiesModule,
        NgxUtilitiesModule
    ],
})

export class TicketStacksListModule {
}
