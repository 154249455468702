import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { EventAgendaService } from '../../event-agenda.service';
import * as _ from 'lodash';
import { EFProfile } from '../../../../../ApiClient/Models/Profile/EFProfile';
import { EventProfileTypeInterface, Profile } from '@eventfarm/javascript-sdk/dist/Api/Type/Profile';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'sponsor-to-session',
    template: require('./sponsor-to-session.html'),
    styles: [require('./sponsor-to-session.scss')],
})

export class SponsorToSessionComponent implements OnInit, OnDestroy {
    @Input() activeSponsors: EFProfile[] = [];
    @Input() sessionId: string;
    @Output() addedSponsors: EventEmitter<EFProfile[]> = new EventEmitter();
    @ViewChild('sponsorInput') sponsorInput: any;

    public selectedSponsors: EFProfile[] = [];
    public filteredSponsors: EFProfile[] = [];
    public allSponsors: EFProfile[] = [];
    public selectable: boolean = true;
    public removable: boolean = true;
    public sponsorCtrl = new FormControl();
    public addOnBlur: boolean = false;
    public loadFilteredSponsors: boolean = true;
    public showLoadMore: boolean = false;
    public loading: boolean = false;
    

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventAgendaService: EventAgendaService,
        private message: NzMessageService,
    ) {
    }

    async ngOnInit() {
        this.initiateAllSponsors();
        this.checkTotalSponsors();
    }


    public initiateAllSponsors() {
        this.allSponsors = _.cloneDeep(this.eventAgendaService.currentSponsors$.value);

        this.allSponsors.forEach((sponsor) => {
            sponsor.selected = false;
            sponsor.edit = false;
            sponsor.disabled = false;
            sponsor.eventProfileSlug = _.get(sponsor, 'eventProfiles[0].eventProfileType.slug', 'sponsor');
            sponsor.eventProfileId = '';
        });

        this.selectedSponsors = [];

        if (this.activeSponsors && this.activeSponsors.length) {
            this.activeSponsors.forEach((active) => {
                const index = this.allSponsors.findIndex((sponsor => sponsor.id === active.id));
                if (index > -1) {
                    this.allSponsors[index].selected = true;
                    const backupSlug = _.get(this.allSponsors[index], 'eventProfiles[0].eventProfileType.slug', 'sponsor');
                    this.allSponsors[index].eventProfileSlug = _.get(active, 'eventProfiles[0].eventProfileType.slug', backupSlug);
                    this.allSponsors[index].eventProfileId = _.get(active, 'eventProfiles[0].id', '');
                    this.selectedSponsors.push(this.allSponsors[index]);
                }
            });
        }
    }

    public checkTotalSponsors() {
        this.showLoadMore = this.allSponsors.length < this.eventAgendaService.sponsorsMetaTotalResults.value;
    }

    public async getMoreSponsors() {
        this.loading = true;
        this.eventAgendaService.sponsorsListOptions.pagination.currentPage = this.eventAgendaService.sponsorsListOptions.pagination.currentPage + 1;
        await this.eventAgendaService.getProfiles(false);

        const newSponsors = _.cloneDeep(this.eventAgendaService.currentSponsors$.value);
        newSponsors.forEach((sponsor) => {
            sponsor.edit = false;
            sponsor.disabled = false;
            if (this.activeSponsors && this.activeSponsors.length) {
                const index = this.activeSponsors.findIndex(active => active.id === sponsor.id);
                sponsor.selected = index > -1;
                if (sponsor.selected) {
                    const backupSlug = _.get(sponsor, 'eventProfiles[0].eventProfileType.slug', 'sponsor');
                    sponsor.eventProfileSlug = _.get(this.activeSponsors[index], 'eventProfiles[0].eventProfileType.slug', backupSlug);
                    sponsor.eventProfileId = _.get(this.activeSponsors[index], 'eventProfiles[0].id', '');
                }

            } else {
                sponsor.selected = false;
            }
            this.allSponsors.push(sponsor);
        });

        this.checkTotalSponsors();
        this.loading = false;
    }

    public removeSelectedSponsor() {
        const selectedSponsors = _.cloneDeep(this.selectedSponsors);
        selectedSponsors.forEach((sponsor) => {
            const index = this.selectedSponsors.findIndex(selectedSponsor => selectedSponsor.id === sponsor.id);
            this.updateSelectedSponsors(index, sponsor);
        });
    }

    

    public toggleSelection(sponsorSelected: EFProfile) {
        const sponsorIndex = this.selectedSponsors.findIndex(list => list.id === sponsorSelected.id);
        (sponsorIndex > -1) ? this.remove(sponsorSelected) : this.selected(sponsorSelected);
    }

    selected(selectedSponsor: EFProfile): void {
        this.addSelectedSponsors(selectedSponsor);
    }


    remove(sponsor: EFProfile): void {
        const index = this.selectedSponsors.findIndex(selectedSponsor => selectedSponsor.id === sponsor.id);
        this.updateSelectedSponsors(index, sponsor);
        if (this.activeSponsors && this.activeSponsors.length) {
            this.eventAgendaService.removeProfileFromEvent(sponsor.id, this.sessionId);
        }
    }

    public addSelectedSponsors(selectedSponsor: EFProfile) {
        this.selectedSponsors.push(selectedSponsor);
        if (this.sponsorInput) {
            this.sponsorInput.nativeElement.value = '';
        }
        this.filteredSponsors.splice(this.filteredSponsors.indexOf(selectedSponsor), 1);
        this.allSponsors.forEach(sponsor => {
            if (sponsor.id && sponsor.id === selectedSponsor.id) {
                sponsor.selected = true;
            }
        });
        this.selectedSponsors = _.uniqBy(this.selectedSponsors, 'id');
        this.updateSponsorsForSession(this.selectedSponsors);
        this.sponsorCtrl.setValue(null);
    }

    private updateSelectedSponsors(index: number, newSponsor: EFProfile) {
        if (index >= 0) {
            this.selectedSponsors.splice(index, 1);
            const isSponsorExist = this.filteredSponsors.find((x) => {
                return x.id === newSponsor.id;
            });
            if (!isSponsorExist) {
                this.filteredSponsors.push(newSponsor);
            }
            this.allSponsors.forEach(sponsor => {
                if (sponsor.id === newSponsor.id) {
                    sponsor.selected = false;
                }
            });
        }
        this.updateSponsorsForSession(this.selectedSponsors);
        this.sponsorCtrl.setValue(null);
    }

    public updateSponsorsForSession(sponsors: EFProfile[]) {
        this.addedSponsors.emit(sponsors);
    }


    public async handleSearch(query: string) {
        this.eventAgendaService.sponsorsListOptions.query = query;
        await this.eventAgendaService.getProfiles(false);
    }

    
    ngOnDestroy() {
        this.eventAgendaService.resetEfProfileListOptions();
    }
}
