import { Component, OnInit } from '@angular/core';
import { EFXAdminService } from '../efx-admin.service';

@Component({
    selector: 'efx-contact',
    template: require('./efx-contact.html'),
    styles: [require('./efx-contact.scss'), require('../efx-admin.scss')],
})

export class EFXContactComponent implements OnInit {

    constructor(
        private efxAdminService: EFXAdminService
    ) { }

    ngOnInit() {
    }

}
