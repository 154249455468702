import { createSelector } from '@ngrx/store';

export const getCurrentUser = (state) => state.user;
export const getTeamsEntities = (state) => state.user.entities;

export const getUserLoading = createSelector(
    getCurrentUser,
    (user) => {
        return user.loading;
    }
);

export const getUserLoaded = createSelector(
    getCurrentUser,
    (user) => {
        return user.loaded;
    }
);

export const getTeamsEntitiesForUser = createSelector(
    getCurrentUser,
    (user) => {
        return user.teams;
    }
);

export const getAllTeamsForCurrentUser = createSelector(

    getCurrentUser,
    (user) => {
        if (user && user.teams) {
            return Object.keys(user.teams).map(id => user.teams[id]);
        }
    }
);
