
/**
 * Create Event
 */
export const CREATE_EVENT_STARTED = 'CREATE_EVENT_STARTED';
export const FULL_EVENT_SELECTED = 'FULL_EVENT_SELECTED';
export const CIO_EVENT_SELECTED = 'CIO_EVENT_SELECTED';
export const CREATE_FULL_EVENT_SUBMITTED = 'CREATE_FULL_EVENT_SUBMITTED';
export const CREATE_CIO_EVENT_SUBMITTED = 'CREATE_CIO_EVENT_SUBMITTED';
export const START_FROM_SCRATCH_EVENT = 'START_FROM_SCRATCH_EVENT';
export const COPIED_EVENT_SUBMITTED = 'COPIED_EVENT_SUBMITTED';
export const COPIED_EVENT_DISMISSED = 'COPIED_EVENT_DISMISSED';
export const QUICK_CREATE_ACCESS_TYPES_STARTED = 'QUICK_CREATE_ACCESS_TYPES_STARTED';
export const QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED = 'QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED';
export const QUICK_CREATE_ACCESS_TYPES_SAVE_CREATE_ANOTHER = 'QUICK_CREATE_ACCESS_TYPES_SAVE_CREATE_ANOTHER';
export const QUICK_CREATE_ACCESS_TYPES_SAVE_DONE = 'QUICK_CREATE_ACCESS_TYPES_SAVE_DONE';
export const QUICK_CREATE_ENTERED_CANVAS = 'QUICK_CREATE_ENTERED_CANVAS';
export const QUICK_CREATE_ENTERED_CANVAS_MODAL_DISMISSED = 'QUICK_CREATE_ENTERED_CANVAS_MODAL_DISMISSED';
export const PQL_GET_CIO_EVENT_SELECTED = 'PQL_GET_CIO_EVENT_SELECTED';
export const PQL_GET_CIO_EVENT_DISMISSED = 'PQL_GET_CIO_EVENT_DISMISSED';
export const PQL_GET_CIO_EVENT_SUBMITTED = 'PQL_GET_CIO_EVENT_SUBMITTED';

export const PQL_GET_FULL_EVENT_SELECTED = 'PQL_GET_FULL_EVENT_SELECTED';
export const PQL_GET_FULL_EVENT_DISMISSED = 'PQL_GET_FULL_EVENT_DISMISSED';
export const PQL_GET_FULL_EVENT_SUBMITTED = 'PQL_GET_FULL_EVENT_SUBMITTED';

// temporary
export const SWITCH_TO_LIST_VIEW = 'SWITCH_TO_LIST_VIEW';
export const SWITCH_TO_TILE_VIEW = 'SWITCH_TO_TILE_VIEW';
export const LOAD_EVENTS_DASHBOARD = 'LOAD_EVENTS_DASHBOARD';
export const NAVIGATE_TO_EVENT = 'NAVIGATE_TO_EVENT';
export const EDITING_GUEST_QUESTIONS = 'EDITING_GUEST_QUESTIONS';
export const PREVIEWING_GUEST_QUESTIONS = 'PREVIEWING_GUEST_QUESTIONS';
export const ADDING_NEW_GUEST_QUESTION = 'ADDING_NEW_GUEST_QUESTION';


/**
 * EFx
 */

export const EFX_LEARN_MORE = 'EFX_LEARN_MORE';
export const EFX_REQUEST = 'EFX_REQUEST';
export const EFX_TEXT_LINK = 'EFX_TEXT_LINK';
export const EFX_UPDATE_ADMIN_PASSCODE = 'EFX_UPDATE_ADMIN_PASSCODE';
export const EFX_SMS_ARRIVAL_ENABLED = 'EFX_SMS_ARRIVAL_ENABLED';
export const EFX_SMS_ARRIVAL_DISABLED = 'EFX_SMS_ARRIVAL_DISABLED';
export const EFX_SMS_ARRIVAL_MESSAGE_SET = 'EFX_SMS_ARRIVAL_MESSAGE_SET';
export const EFX_NFC_ENABLED = 'EFX_NFC_ENABLED';
export const EFX_NFC_DISABLED = 'EFX_NFC_DISABLED';
export const EFX_MODULE_REQUESTED = 'EFX_MODULE_REQUESTED';

/**
 * Side Nav
 */

export const MY_ACCOUNT_ENTERED = 'MY_ACCOUNT_ENTERED';
export const PAYMENT_SETTINGS_ENTERED = 'PAYMENT_SETTINGS_ENTERED';

export const ALL_EVENTS_ENTERED = 'ALL_EVENTS_ENTERED';
export const ADDRESS_BOOK_ENTERED = 'ADDRESS_BOOK_ENTERED';

export const EVENT_INFO_ENTERED = 'EVENT_INFO_ENTERED';
export const EVENT_ACCESS_ENTERED = 'EVENT_ACCESS_ENTERED';
export const EFX_ADMIN_CONFIG_ENTERED = 'EFX_ADMIN_CONFIG_ENTERED';
export const WEB_PRESENCE_ENTERED = 'WEB_PRESENCE_ENTERED';
export const PROMO_CODES_ENTERED = 'PROMO_CODES_ENTERED';
export const REGISTRATION_QUESTIONS_ENTERED = 'REGISTRATION_QUESTIONS_ENTERED';
export const EMAIL_DESIGNS_ENTERED = 'EMAIL_DESIGNS_ENTERED';
export const INVITATIONS_CONFIRMATIONS_ENTERED = 'INVITATIONS_CONFIRMATIONS_ENTERED';
export const GUEST_MESSAGING_ENTERED = 'GUEST_MESSAGING_ENTERED';
export const SCHEDULED_MESSAGES_ENTERED = 'SCHEDULED_MESSAGES_ENTERED';
export const GUEST_LIST_ENTERED = 'GUEST_LIST_ENTERED';
export const ADD_GUESTS_ENTERED = 'ADD_GUESTS_ENTERED';
export const REPORTS_ENTERED = 'REPORTS_ENTERED';
export const EMAIL_STATISTICS_ENTERED = 'EMAIL_STATISTICS_ENTERED';
export const SENT_MESSAGES_ENTERED = 'SENT_MESSAGES_ENTERED';


/**
 * Reports
 */

export const REPORT_GENERATE = 'REPORT_GENERATE';

/**
 * Web Presence
 */

export const WEB_PRESENCE_SET_THEME = 'WEB_PRESENCE_SET_THEME';
export const WEB_PRESENCE_SET_TRACKING = 'WEB_PRESENCE_SET_TRACKING';
export const WEB_PRESENCE_SET_DISPLAY_MESSAGE = 'WEB_PRESENCE_SET_DISPLAY_MESSAGE';


/**
 *  Promotions
 */

export const PROMO_CREATE = 'PROMO_CREATE';
export const PROMO_UPDATE = 'PROMO_UPDATE';
export const PROMO_DELETE = 'PROMO_DELETE';
export const PROMO_OPEN_SLIDEOUT = 'PROMO_OPEN_SLIDEOUT';
export const PROMO_CLOSE_SLIDEOUT = 'PROMO_CLOSE_SLIDEOUT';


/**
 *  Transfers
 */

export const TRANSFERS_OPEN_SLIDEOUT = 'TRANSFERS_OPEN_SLIDEOUT';
export const TRANSFERS_CLOSE_SLIDEOUT = 'TRANSFERS_CLOSE_SLIDEOUT';
export const TRANSFERS_FORCE_CONFIRMED = 'TRANSFERS_FORCE_CONFIRM';
export const TRANSFERS_RESEND_EMAIL = 'TRANSFERS_RESEND_EMAIL';



/**
 * Guest List
 */

export const DOWNLOAD_GUESTLIST = 'DOWNLOAD_GUESTLIST';
export const VISIT_INVITE_ADD_GUEST = 'VISIT_INVITE_ADD_GUEST';
export const IMPORT_GUESTLIST_FROM_SALESFORCE = 'IMPORT_GUESTLIST_FROM_SALESFORCE';
export const UPLOAD_GUESTLIST_ADD_GROUP = 'UPLOAD_GUESTLIST_ADD_GROUP';

export const OPEN_INVITATION_SLIDEOUT = 'OPEN_INVITATION_SLIDEOUT';
export const CHECK_IN_GUEST = 'CHECK_IN_GUEST';
export const UNDO_CHECK_IN_GUEST = 'UNDO_CHECK_IN_GUEST';
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const SORT_GUEST_LIST = 'UNDO_CHECK_IN_GUEST';
export const SAVE_INVITATION = 'SAVE_INVITATION';
export const TRANFER_INVITATION = 'TRANFER_INVITATION';
export const VISIT_SHARE_LINK = 'VISIT_SHARE_LINK';
export const COPY_SHARE_LINK = 'COPY_SHARE_LINK';
export const RESEND_CONFIRMATION = 'RESEND_CONFIRMATION';
export const RESEND_INVITATION = 'RESEND_INVITATION';
export const SYNC_SALESFORCE_INVITATION = 'SYNC_SALESFORCE_INVITATION';
export const SAVE_REG_QUESTIONS = 'SAVE_REG_QUESTIONS';
export const CANCEL_EDIT_INVITATION = 'CANCEL_EDIT_INVITATION';


