import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ReportService } from '../report.service';
import { ReportType, ReportTypes, ReportTypeEnum} from '../Models/report-models';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';

@Component({
    selector: 'report-list',
    template: require('./report-list.html'),
    styles: [require('./report-list.scss')],
})

/**
 * Displays all the reports for the event
 */
export class ReportListComponent implements OnDestroy, OnInit {

    constructor(
        private reportService: ReportService
    ) {
    }

    downloadFile(url: string|null, isReady: boolean): void {
        if (isReady) {
            window.open(url, '_blank');
        }
    }

    ngOnDestroy() {
        this.reportService.clearList();
    }

    ngOnInit() {
        this.reportService.getReportsForEvent();
    }

    refresh() {
        this.reportService.getReportsForEvent();
    }
}
