import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as adminActions from '../../actions/admin';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {WaitListService} from '../../../EventsModule/WaitList/waitList.service';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../store';
import * as fromRoot from '../../../store';

import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {TeamAllotmentService} from '../../../AdminModule/Manage/ManageTeam/TeamAllotments/team-allotment.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {TeamPointOfContactService} from '../../../AdminModule/Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import * as userActions from "../../actions/user.action";
import {LocalStorageService} from "../../../../_services/storage/local-storage.service";

@Injectable()
export class AdminContactEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamAllotmentService: TeamAllotmentService,
        private messageService: NzMessageService,
        private teamPointOfContactService: TeamPointOfContactService,
        private localStorageService: LocalStorageService
    ) {
    }


    // @ts-ignore
    fetchContactsForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchContactsForAdminTeam>(adminActions.FETCH_CONTACTS_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamPointOfContactService.fetchContactsByPoolId(team.id)
            .pipe(
                map(contacts => {
                    return contacts['data']
                        .map(c => PoolContact.fromApiResponse(c));
                }),
                switchMap((contacts: PoolContact[]) => [
                    new adminActions.FetchContactsForAdminTeamSuccess(contacts)
                ]),
            )),
        catchError(err => of(new adminActions.FetchContactsForAdminTeamFail(err)))));


    // @ts-ignore
    addContactToAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.AddContactToAdminTeam>(adminActions.ADD_CONTACT_TO_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamPointOfContactService.addContact(team.id, action.payload.email, action.payload.type)),
            switchMap((contact: PoolContact) => [
                new adminActions.AddContactToAdminTeamSuccess(contact),
                new adminActions.FetchContactsForAdminTeam(),
                this.messageService.success('Contact added to pool'),

            ]),
            catchError(err => {
                    this.messageService.error('Error adding contact to pool');
                    return of(new adminActions.AddContactToAdminTeamFail(err));
                }
            )

    ));


    // @ts-ignore
    removeContactFromAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.RemoveContactFromAdminTeam>(adminActions.REMOVE_CONTACT_FROM_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamPointOfContactService.removeContact(team.id, action.payload.contactId, action.payload.userId).pipe(
            switchMap((contact: PoolContact) => [
                new adminActions.RemoveContactFromAdminTeamSuccess(contact),
                new adminActions.FetchContactsForAdminTeam(),

                this.messageService.success('Contact removed from pool'),
            ]),
            catchError(err => {
                    this.messageService.error('Error removing contact from pool');
                    return of(new adminActions.RemoveContactFromAdminTeamFail(err));
                }
            )
        )),
    ));

    // @ts-ignore
    removeContactFromAdminTeamSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.RemoveContactFromAdminTeamSuccess>(adminActions.REMOVE_CONTACT_FROM_ADMIN_TEAM_SUCCESS),

        map((action) => {
            const {id} = action.payload;
            // Sets ids after login

            this.localStorageService.set('effectiveEfUserId', id);

            return action;
        }),

        switchMap((action) => this.apiClient.getUseCaseFactory().User().GetUser(action.payload.id, ['UserName', 'UserAddress', 'UserIdentifier', 'isEFAdmin'])
            .pipe(
                map(res => res['data']),
                switchMap((user) => [
                    new userActions.GetCurrentUserSuccess(user),
                ]),
                catchError(err => of(new userActions.GetCurrentUserFail(err)))
            ))
    ));
}
