import { Component, OnInit } from '@angular/core';
import { EFXAdminService } from '../efx-admin.service';
import { AlertService } from '../../../../eventFarmAlert.service';

@Component({
    selector: 'efx-info',
    template: require('./efx-info.html'),
    styles: [require('./efx-info.scss'), require('../efx-admin.scss')],
})

export class EFXInfoComponent implements OnInit {

    constructor(
         private efxAdminService: EFXAdminService
    ) { }

    ngOnInit() {
    }

    toUrlFromButton(url: string) {
        this.efxAdminService.toUrlFromButton(url);
    }
}
