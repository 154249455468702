import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { ModalServiceComponent } from '../../../../CoreUI/Modal/modalService.component';
import { TicketBlockDashboardService } from '../../ticketBlockDashboard.service';
import { AddGroupService } from './addGroupToTicketBlock.service';

import { Group } from '../../../../../ApiClient/Models/Group/group';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import {EventFarmService} from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { StackSelectionService } from '../../../../CoreUI/InvitationOptions/stackSelection.service';
import { InvitationOptionsService } from '../../../../CoreUI/InvitationOptions/invitationOptions.service';
import { RouteGeneratorService } from '../../../../../_services/routes/route-generator.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'add-group',
    styles: [require('../sharedInvite.scss'),require('./addGroupToTicketBlock.scss')],
    template: require('./addGroupToTicketBlock.html'),
})

export class AddGroupComponent extends ModalServiceComponent implements OnInit {
    readonly inviteGroupSVG = require('./../assets/InviteGroup-Icon');

    private selectedGroup = new Group('');
    private loading;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private routeGenerator: RouteGeneratorService,
        public modalService: ModalService,
        private alertService: AlertService,
        private ticketBlockDashboardService: TicketBlockDashboardService,
        private stackSelectionService: StackSelectionService,
        private message: NzMessageService,
        private addGroupService: AddGroupService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
        private invitationOptionsService: InvitationOptionsService
    ) {
        super(modalService);
    }

    private context: string = 'group';
    private isGuestDetailsActive = true;

    ngOnInit() {
        this.segmentService.segmentGuestListTracking().addGroup();
        this.invitationOptionsService.stacks = this.ticketBlockDashboardService.stacks;
    }

    createInviteFromGroup() {
        const eventId = this.eventFarmService.currentEvent.id;

        if (this.stackSelectionService.invitationCreationTypeSlug === 'send-email') {
            this.alertService.guestList().confirmGroupAddedToEventWithEmails(this.selectedGroup.name)
                .then((res) => {
                    if (res.value) {
                        this.addGroupService.createInvitationFromGroup(this.selectedGroup.id)
                            .subscribe(() => {
                                this.alertService.guestList().groupAddedToEvent(this.selectedGroup.name, true);
                                let eventId = this.eventFarmService.currentEvent.id;
                                let ticketBlockId = this.ticketBlockDashboardService.ticketBlock.id;
                                this.message.success('Group added successfully to your ticket block, and invitation emails are queued');
                                this.ticketBlockDashboardService.gatherRelatedData();
                                const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.list', { eventId });
                                this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + ticketBlockId);
                            });
                    }
                });

        } else {
            this.addGroupService.createInvitationFromGroup(this.selectedGroup.id)
                .subscribe(() => {
                    this.alertService.guestList().groupAddedToEvent(this.selectedGroup.name);
                    let eventId = this.eventFarmService.currentEvent.id;
                    this.eventFarmService.updateEventDetails(eventId);
                    this.message.success('Group added successfully to your ticket block');
                    let ticketBlockId = this.ticketBlockDashboardService.ticketBlock.id;
                    this.ticketBlockDashboardService.gatherRelatedData();
                    const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.list', { eventId });
                    this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + ticketBlockId);
                });
        }
    }

    private selectGroup(group: Group): void {
        this.selectedGroup = group;
        this.addGroupService.getGroupDetails(group.id).toPromise()
            .then((res) => {
                this.selectedGroup = Group.fromApiResponse(res.data);
            });
    }

    goToAddInviteTb() {
        const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.invite', {eventId: this.eventFarmService.currentEvent.id});
        this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + this.ticketBlockDashboardService.ticketBlock.id);
    }
}
