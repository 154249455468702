import { Component, Input, OnInit } from '@angular/core';
import { Answer } from '../../../../../../../ApiClient/Models/Answer/answer';
import { EditQuestionService } from '../edit-question.service';

@Component({
    selector: 'answers',
    styles: [
        require('./answers.scss'),
        require('../../../dragula.scss'),
    ],
    template: require('./answers.html'),
})

export class AnswersComponent implements OnInit {
    @Input() isSaving: boolean;
    constructor(
        private editQuestionService: EditQuestionService
    ) {}


    ngOnInit(): void {
        this.checkAnsweredOptions();
    }

    private dragulaOptions = {
        moves: (el, container, handle) => {
            if (!el.classList.contains('disable-drag')) {
                return handle.dataset.drag;
            }
        }
    };

    private add() {
        this.editQuestionService.currentQuestionAnswers.push(new Answer());
        this.editQuestionService.answerOptionsValid = false;
    }

    private delete(answer: Answer) {
        if (!this.isSaving) {
            this.editQuestionService.currentQuestionAnswers = this.editQuestionService.currentQuestionAnswers
                .filter((i) => i !== answer);
            this.checkAnsweredOptions();
        }
    }

    private setDefault(answer: Answer) {
        this.editQuestionService.currentQuestionAnswers.forEach(a => {
            a.isDefault = false;
        });
        answer.isDefault = true;
    }

    private setValue(answer: Answer, value) {
        answer.text = value;
        this.checkAnsweredOptions();
    }

    public checkAnsweredOptions() {
        let allAnswersFilled = true;
        this.editQuestionService.currentQuestionAnswers.forEach((test) => {
            if (!test.text) {
                allAnswersFilled = false;
            }
        });
        this.editQuestionService.answerOptionsValid = allAnswersFilled;
    }
}
