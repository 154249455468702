import { Component } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import * as _ from 'lodash';


@Component({
  selector: 'payments-configured',
  template: require('./payments-configured.html'),
  styles: [require('./payments-configured.scss')],
})

export class PaymentsConfiguredComponent {

  constructor(
    private eventFarmService: EventFarmService, 
   ) {
  }

  get gatewayName() {
    return _.get(this, 'eventFarmService.currentEvent.poolPaymentGateway.name');
  }

  get gateway() {
    return _.get(this, 'eventFarmService.currentEvent.poolPaymentGateway.paymentGateway');
  }
}
