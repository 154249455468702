import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { AccountInfoService } from './account-info.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';

@Component({
    selector: 'my-account-overview',
    template: require('./my-account-overview.html'),
    styles: [require('./my-account-overview.scss')],
})

export class MyAccountOverviewComponent implements OnInit {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService
    ) {}

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().myAccountEntered();
    }

    private userContactType$ = this.store.select(fromRoot.getPoolContactType).pipe(shareReplay());
}
