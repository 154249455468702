import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import {SMSService} from './sms.service';

@Component({
    selector: 'sms',
    template: require('./sms.html'),
    styles: [require('./sms.scss')],
})

export class SMSComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private smsService: SMSService
    ) {
    }

    async ngOnInit() {}

    ngOnDestroy() {
    }
}
