import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { User } from '../User/user';
import { EFx, EFxModuleTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { EFxStationTypeInterface, EFxScreenTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { EmailDesign } from '../../../Shared/EmailDesign/email-design';
import { EFxScreen } from './efxScreen';

export class EFxStationScreen extends AbstractModel {
    private _efxScreen?: EFxScreen;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const stationScreen = new this(data.id);

        if (data.relationships && data.relationships.efxScreen) {
            stationScreen._efxScreen = EFxScreen.fromApiResponse(data.relationships.efxScreen);
        } 

        return stationScreen;
    }

    public static default(): EFxStationScreen[] {

        const screenTypes = new EFx().EFxScreenType();

        const scanScreen = new this();
        scanScreen._efxScreen = EFxScreen.defaultFromType(screenTypes.find(st => st.isScanView));
        const successScreen = new this();
        successScreen._efxScreen = EFxScreen.defaultFromType(screenTypes.find(st => st.isSuccessView));
        const failureScreen = new this();
        failureScreen._efxScreen = EFxScreen.defaultFromType(screenTypes.find(st => st.isFailureView));
        const checkoutScreen = new this();
        checkoutScreen._efxScreen = EFxScreen.defaultFromType(screenTypes.find(st => st.isCheckOutView));


        return [
            scanScreen,
            successScreen,
            failureScreen,
            checkoutScreen
        ]
    }

    get efxScreen(): EFxScreen {
        return this._efxScreen;
    }
}
