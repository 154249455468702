import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AlertService } from '../../eventFarmAlert.service';
import { UserFormService } from '../../CoreUI/UserForm/userForm.service';
import { ModalService } from '../../CoreUI/Modal/modal.service';

@Injectable()

export class ConfirmEditUserDeactivateGuard implements CanDeactivate<UserFormService> {

    constructor(
        private alertService: AlertService,
        private userFormService: UserFormService,
        private modalService: ModalService,
    ) {}

    canDeactivate() {
        if (this.userFormService.basicUserDetails.hasChanges) {
            this.alertService.addressBook().saveUserChanges()
                .then((res) => {
                    if (res.value === 'confirm') {
                        this.modalService.showModal();
                        this.userFormService.save();
                    } else if (res.dismiss === 'cancel') {
                        this.modalService.closeModal();
                    } else {
                        this.modalService.showModal();
                    }
                })
                .catch((err) => {
                    this.modalService.closeModal();
                });
        }
        return true;
    }
}
