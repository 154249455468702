import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    OnDestroy,
    ViewChild,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef, NgZone, ComponentRef
} from '@angular/core';

import {Subscription, BehaviorSubject} from 'rxjs';
import { EfModalDirective } from './modal.directive';

import { ModalService } from './modal.service';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { filter, take, shareReplay } from 'rxjs/operators';
import { QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED } from '../../../Analytics/Segment/actions';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'ef-modal',
    styles: [require('./modal.scss')],
    template: require('./modal.html'),
    // changeDetection: ChangeDetectionStrategy.OnPush,

})

export class ModalComponent implements OnInit, OnDestroy {

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private modalService: ModalService,
        private efs: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private appRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private sanitizer: DomSanitizer
) {
        this.store.select('router').pipe(
            filter(res => Object.keys(res).length !== 0 && Object.keys(res.state.params).length !== 0),
        ).subscribe(route => {
            this.allocateSideNavSpace = true;
            if (route.state.queryParams.ticketBlockId) {
                this.allocateSideNavSpace = false;
            }
            if (window['location'].pathname === '/admin') {
                this.allocateSideNavSpace = false;
            }
        });
    }

    @ViewChild(EfModalDirective, {static: true}) viewChild: EfModalDirective;
    public readonly hidden$ = new BehaviorSubject<boolean>(true);

    private segment: string;
    private hiding: boolean;
    private allocateSideNavSpace: boolean;
    private title: string;
    private logoSrc: string;
    private logoAlt: string;
    private background: string;
    private cssClass: string|string[];
    private headerText?: string;
    private position?: 'relative' | 'static';
    private inputs?: any[];

    private changeSubscription$: Subscription;
    private changeTitleSubscription$: Subscription;
    private closeSubscription$: Subscription;
    private showSubscription$: Subscription;
    private componentRef: ComponentRef<any>;

    ngOnInit() {
        this.logoSrc = null;
        this.logoAlt = null;
        this.headerText = null;

        this.background = 'white';

        this.hidden$.next(true);
        this.hiding = false;
        this.title = 'Event Farm';
        this.cssClass = '';
        this.segment = '';
        this.position = 'static';
        this.inputs = [];

        this.initSubscriptions();
    }

    private initSubscriptions() {

        this.showSubscription$ = this.modalService.showModal$.subscribe((res) => {
            this.hidden$.next(false);
        });

        this.changeSubscription$ = this.modalService.changeModal$.subscribe(
            (modal) => {
                this.title = modal.title;
                this.logoSrc = modal.logoSrc;
                this.logoAlt = modal.logoAlt;
                if (modal.background) { this.background = modal.background; }
                if (modal.headerText) { this.headerText = modal.headerText; }
                if (modal.position) {this.position = modal.position; }
                this.cssClass = modal.cssClass;
                if (modal.segment) {
                    this.segment = modal.segment;
                }
                if (modal.inputs) {
                    this.inputs = modal.inputs;
                }
                this.loadComponent(modal.component, this.inputs);
            },
        );

        this.closeSubscription$ = this.modalService.closeModal$.subscribe((meep) => {
            this.hidden$.next(true);
        });

        this.changeTitleSubscription$ = this.modalService.changeModalTitle$.subscribe(
            (title) => {
                this.title = title;
            },
        );
    }

    private close() {
        this.modalService.closeModal();
    }

    public hide(event = null) {
        if (event.target.className === 'ef-modal-backdrop' ||
            event.target.className === 'close-modal-button' ||
            event.target.className === 'ef-modal-under' ||
            (event.target.className && event.target instanceof Element && event.target.className && event.target.className.includes('ef-modal-container')) ||
            event === null
        ) {

            if (this.segment === QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED) {
                this.segmentService.segmentEventTracking().quickCreateAccessTypesClose();
            } else if (this.segment === QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED) {
                this.segmentService.segmentEventTracking().quickCreateCanvasClose();
            }

            this.close();
        }
    }

    public show() {
        this.modalService.showModal();
    }

    private loadComponent(component, inputs) {
        const viewContainerRef = this.viewChild.viewContainerRef;
        viewContainerRef.clear();
        const componentFactory = this._componentFactoryResolver
            .resolveComponentFactory(component);
        this.ngZone.run(() => {
            // Bring event back inside Angular's zone
             this.componentRef =  viewContainerRef.createComponent(componentFactory);
            inputs.map(i => {
                this.componentRef.instance[i.input] = i.val;
            });
          // @ts-ignore

        });
        this.modalService.showModal();
        this.appRef.detectChanges();
    }

    ngOnDestroy() {
        this.changeSubscription$.unsubscribe();
        this.closeSubscription$.unsubscribe();
        this.showSubscription$.unsubscribe();
        this.changeTitleSubscription$.unsubscribe();
        this.inputs.map(i => {
            this.componentRef.instance[i.input] = null;
        });
    }
}
