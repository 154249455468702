import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Operator } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';

@Injectable()

export class GuestMessagingService {
    private _activeDynamicElements: string[] = [];
    private _sendAMessageDynamicElements: string[] = [];
    private _sendGroupMessageDynamicElements: string[] = [];

    constructor(private apiClient: EventFarmAPIClient, private eventFarmService: EventFarmService) {
        this.apiClient.getUseCaseFactory().EmailDesignType().GetAllEmailDesignTypes()
            .subscribe(
                (res) => {
                    this._sendAMessageDynamicElements = this.extractFields(res.data, 'SendAMessage');
                    this._sendGroupMessageDynamicElements = this.extractFields(res.data, 'SendGroupMessage');
                    this._activeDynamicElements = this._sendAMessageDynamicElements;
                }
            );
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get activeDynamicElements(): string[] {
        return this._activeDynamicElements;
    }

    getDynamicElements(query: string = 'SendAMessage') {
        if (query === 'SendAMessage') {
            this._activeDynamicElements = this._sendAMessageDynamicElements;
        } else {
            this._activeDynamicElements = this._sendGroupMessageDynamicElements;
        }
    }

    private extractFields(res: any, query: 'SendAMessage'|'SendGroupMessage') {
        return res.filter((type) => type.id === query)[0].attributes.fields;
    }
}
