
import {HTTPAppVersionInterceptor} from '../app.version.interceptor';
import authConfig from './Auth/config';
import {EfModalService} from "./eventFarmModal.service";
import { WaitListService } from './EventsModule/WaitList/waitList.service';
import { UserState } from './store/reducers/user.reducer';
import { ErrorHandler, NgModule, APP_INITIALIZER, Injector, ApplicationRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule }  from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {FusionAuthModule} from '@fusionauth/angular-sdk';


import { CookieTokenService } from '../ApiClient/cookie-token-service';
import { EventFarmAPIClient } from '../ApiClient/event-farm-api-client';
import { UtilitiesModule } from '../Utilities/utilities.module';
import { AppRoutingModule } from './app-routing.module';
import { BreadcrumbsModule } from './CoreUI/Breadcrumbs/breadcrumbs.module';
import { GuestOverviewModule } from './CoreUI/GuestOverview/guestOverview.module';
import { LoadingOverlayModule } from './CoreUI/LoadingOverlay/loading-overlay.module';
import { DynamicFormModule } from './CoreUI/Forms/dynamic-form.module';
import { SideNavComponent } from './CoreUI/SideNav/sideNav.component';
import { SideNavHamburgerComponent } from './CoreUI/SideNav/sideNavHamburger/sideNavHamburger.component';
import { TicketBlockListModule } from './CoreUI/TicketBlockList/ticket-block-list.module';
import { TicketBlockUsersModule } from './CoreUI/TicketBlockUsers/ticket-block-users.module';
import { TopNavComponent } from './CoreUI/TopNav/topNav.component';
import { EmptyComponent } from './empty.component';
import { EventFarmCoreRoutesComponent } from './eventFarmCoreRoutes.component';
import { EventFarmService } from './eventFarm.service';
import { AlertService } from './eventFarmAlert.service';
import { SegmentService } from '../Analytics/Segment/segment.service';
import { AuthenticationGuardService } from './Guards/authenticationGuard.service';
import { FeatureToggleService } from './featureToggle.service';
import { UserDetailsComponent } from './CoreUI/UserDetails/userDetails.component';
import { loadUserContext } from './app.initializers';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UserTypeaheadModule } from './CoreUI/Typeahead/user-typeahead.module';
import { IconService } from './CoreUI/Icons/icons.service';
import { AnalyticsComponent } from '../Analytics/analytics.component';
import { ModalModule } from './CoreUI/Modal/modal.module';
import { LegacyUIModule } from './LegacyUI/legacy-UI.module';
import { AppComponent } from './app.component';
import { AppServicesModule } from '../_services/app-services.module';
import { UserManagerResolver } from './user-manager-resolver.service';
import { UserManagerOrEventCreateResolver } from './user-manager-or-event-create-resolver.service';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

declare const __IS_PRODUCTION__: boolean;
declare const __IS_STAGE__: boolean;
declare const __USE_SUBDOMAINS__: boolean;

import {
    StoreRouterConnectingModule,
    RouterStateSerializer,
    MinimalRouterStateSerializer
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects, CustomSerializer, AppState } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { initialState as initialUserState } from './store/reducers/user.reducer';
import { initialTeamState } from './store/reducers/team.reducer';
import { initialEventState } from './store/reducers/event.reducer';
import { TimezoneTypeaheadModule } from './CoreUI/Typeahead/timezone-typeahead.module';
import { TagTypeaheadModule } from './CoreUI/Typeahead/tag-typeahead.module';
import { Userv2TypeaheadModule } from './CoreUI/Typeahead/userv2-typeahead.module';
import { InvitationRevealModule } from './EventsModule/EventInfo/InvitationReveal/invitation-reveal.module';
import { NzUtilitiesModule } from '../NZUtilities/nz.utilities.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthCallbackComponent } from './Auth/auth.callback.component';
import { AdminModule } from './AdminModule/admin.module';
import { AdminNavComponent } from './CoreUI/AdminNav/adminNav.component';
import { GlobalErrorHandler } from '../globalErrorHandler';
import { initialAdminState } from './store/reducers/admin.reducer';
import { UploaderSharedModule } from './PoolsModule/Uploader/uploader-shared.module';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { AlertBannerComponent } from './CoreUI/AlertBanner/alert-banner.component';
import { RefreshNotificationComponent } from './CoreUI/RefreshNotification/refresh-notification.component';

const localStorageServiceConfig = {
    prefix: 'eventFarm',
    storageType: 'sessionStorage',
};

const envProviders: any[] = [
    EventFarmAPIClient,
    CookieTokenService,
    EventFarmService,
    IconService,
    AlertService,
    EfModalService,
    UserManagerResolver,
    UserManagerOrEventCreateResolver,
    AuthenticationGuardService,
    FeatureToggleService,
    SegmentService,
    NzDrawerRef
   // {provide: RouterStateSerializer, useClass: CustomSerializer}
];

envProviders.push({
    provide: APP_INITIALIZER,
    useFactory: loadUserContext,
    deps: [Injector],
    multi: true,
});

// envProviders.push({
//     provide: APP_INITIALIZER,
//     useFactory: startQueuePoll,
//     deps: [Injector],
//     multi: true
// });

const initialRouterState = {
    'state': {
      'url': '/',
      'params': {},
      'queryParams': {}
    },
    'navigationId': 0
  };

const initialState: AppState = {
    user: initialUserState,
    team: initialTeamState,
    event: initialEventState,
    admin: initialAdminState,
    router: initialRouterState
  };

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingOverlayModule,
        TicketBlockListModule,
        TicketBlockUsersModule,
        UserTypeaheadModule,
        Userv2TypeaheadModule,
        TimezoneTypeaheadModule,
        TagTypeaheadModule,
        GuestOverviewModule,
        DynamicFormModule,
        NgxChartsModule,
        ModalModule,
        LegacyUIModule,
        ImageCropperModule,
        PopoverModule.forRoot(),
        CollapseModule.forRoot(),
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        TypeaheadModule.forRoot(),
        TooltipModule.forRoot(),
        ProgressbarModule.forRoot(),
        UtilitiesModule,
        NzUtilitiesModule,
        BreadcrumbsModule,
        AppServicesModule,
        NzUploadModule,
        StoreModule.forRoot(reducers, {
            initialState,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        FusionAuthModule.forRoot(authConfig),
        EffectsModule.forRoot(effects),
        StoreRouterConnectingModule.forRoot({serializer: CustomSerializer, stateKey: 'router'}),
        AppRoutingModule,
        AdminModule,
        OverlayModule,
        ! __IS_PRODUCTION__ ? StoreDevtoolsModule.instrument() : [],
        __IS_STAGE__  ? StoreDevtoolsModule.instrument() : [],
        UploaderSharedModule.forRoot(),
        FontAwesomeModule
    ],
    declarations: [
        AppComponent,
        EventFarmCoreRoutesComponent,
        AnalyticsComponent,
        TopNavComponent,
        SideNavComponent,
        SideNavHamburgerComponent,
        UserDetailsComponent,
        AdminNavComponent,
        EmptyComponent,
        AuthCallbackComponent,
        AlertBannerComponent,
        RefreshNotificationComponent
    ],
    entryComponents: [
        SideNavHamburgerComponent
    ],
    providers: [
        ...envProviders,
        WaitListService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        { provide: NZ_I18N, useValue: en_US },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
        {
            provide: HTTP_INTERCEPTORS, useClass: HTTPAppVersionInterceptor, multi: true
        }
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
    }
}
