import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChooseFileContainerComponent} from './ChooseFile/chooseFileContainer.component';
import {UploaderService} from './uploader.service';
import {EventFarmService} from '../../eventFarm.service';
import {UploaderTabsService} from './uploaderTabs.service';
import {ChooseFileService} from './ChooseFile/chooseFile.service';
import {GroupTypeaheadModule} from '../../CoreUI/Typeahead/group-typeahead.module';
import {UtilitiesModule} from '../../../Utilities/utilities.module';
import {UploaderInformationComponent} from './uploaderInformation.component';
import {ProcessingService} from './Processing/processing.service';
import {ProcessingContainerComponent} from './Processing/processingContainer.component';
import {ProgressDisplayComponent} from './Processing/progressDisplay.component';

import {ProgressbarModule} from 'ngx-bootstrap/progressbar'

import {ErrorModule} from '../../CoreUI/Error/error.module';
import {PreviewUploadContainerComponent} from './PreviewUpload/previewUploadContainer.component';
import {PreviewUploadService} from './PreviewUpload/previewUpload.service';
import {InvitationOptionsService} from '../../CoreUI/InvitationOptions/invitationOptions.service';
import {InvitationOptionsModule} from '../../CoreUI/InvitationOptions/invitationOptions.module';
import {FinishContainerComponent} from './Finish/finishContainer.component';
import {RouteGeneratorService} from '../../../_services/routes/route-generator.service';
import {FinishService} from './Finish/finish.service';
import {FinishDisplayComponent} from './Finish/finishDisplay.component';
import {ImportFailureDisplayComponent} from './Finish/importFailureDisplay.component';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';

const components: any[] = [
    ChooseFileContainerComponent,
    UploaderInformationComponent,
    ProcessingContainerComponent,
    PreviewUploadContainerComponent,
    ProgressDisplayComponent,
    FinishContainerComponent,
    FinishDisplayComponent,
    ImportFailureDisplayComponent
];

@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        GroupTypeaheadModule,
        UtilitiesModule,
        ProgressbarModule,
        ErrorModule,
        InvitationOptionsModule,
        NzUtilitiesModule
    ],
    providers: [
        PreviewUploadService,
        InvitationOptionsService,
        UploaderService,
        EventFarmService,
        UploaderTabsService,
        ChooseFileService,
        ProcessingService,
        RouteGeneratorService,
        FinishService
    ],
    exports: components
})

export class UploaderSharedModule {
    static forRoot(): ModuleWithProviders<UploaderSharedModule> {
        return {
            ngModule: UploaderSharedModule,
            providers: [
                PreviewUploadService,
                InvitationOptionsService,
                UploaderService,
                EventFarmService,
                UploaderTabsService,
                ChooseFileService,
                ProcessingService,
                RouteGeneratorService,
                FinishService

            ],
        };
    }
}
