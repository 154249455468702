import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../../CoreUI/Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';
import { KioskModeBreakoutSessionsComponent } from './kiosk-mode-breakout-sessions.component';

const forExport = [
    KioskModeBreakoutSessionsComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,

    ],
})

export class KioskModeBreakoutSessionsModule {
}