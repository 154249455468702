import { Injectable } from '@angular/core';
import { ActivityLogActionTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/ActivityLog';

@Injectable()
export class IconService {
    public blueprint = require('../../../../mars/components/icons/flat/blueprint');
    public fingerprint = require('../../../../mars/components/icons/flat/file-2');
    public question = require('../../../../mars/components/icons/zondicons/question');
    public internet = require('../../../../mars/components/icons/flat/internet');
    public list = require('../../../../mars/components/icons/flat/list');
    public search = require('../../../../mars/components/icons/flat/search-1');
    public success = require('../../../../mars/components/icons/flat/success');
    public openInAppStore = require('../../../../mars/components/icons/open-in-app-store');
    public openInGooglePlay = require('../../../../mars/components/icons/open-in-google-play');
    public openInExternal = require('../../../../mars/components/icons/open-in-external');
    public email = require('../../../../mars/components/icons/contact/email');
    public phone = require('../../../../mars/components/icons/contact/phone');
    public rightArrow = require('../../../../mars/components/icons/right-arrow');
    public tickets = require('../../../../mars/components/icons/tickets');
    public promo = require('../../../../mars/components/icons/flat/price-tag');
    public menu = require('../../../../mars/components/icons/flat/menu');
    public actLogAdded = require('../../../../mars/components/icons/activityLog/added');
    public actLogCheckIn = require('../../../../mars/components/icons/activityLog/check-in');
    public actLogCreated = require('../../../../mars/components/icons/activityLog/created');
    public actLogInteracted = require('../../../../mars/components/icons/activityLog/interacted');
    public actLogOther = require('../../../../mars/components/icons/activityLog/other');
    public actLogRemoved = require('../../../../mars/components/icons/activityLog/removed');
    public actLogResponded = require('../../../../mars/components/icons/activityLog/responded');
    public actLogPerson = require('../../../../mars/components/icons/activityLog/person');
    public webConVideoCam = require('../../../../mars/components/icons/zoom/icon_videocam');
    public webConVideoCamInActive = require('../../../../mars/components/icons/zoom/icon_videocam_inactive');
    public webConZoom = require('../../../../mars/components/icons/zoom/icon_zoom');
    public webConZoomInActive = require('../../../../mars/components/icons/zoom/icon_zoom_inactive');
    public exhibitor = require('../../../../mars/components/icons/flat/price-tag');
    public archive = require('../../../../mars/components/icons/flat/download');


    public getActivityLogLogoForType(type: ActivityLogActionTypeInterface) {

        if (type.isAdded) {
            return this.actLogAdded;
        } else if (type.isCreated) {
            return this.actLogCreated;
        } else if (type.isCheckIn) {
            return this.actLogCheckIn;
        } else if (type.isInteracted) {
            return this.actLogInteracted;
        } else if (type.isRemoved) {
            return this.actLogRemoved;
        } else if (type.isResponded) {
            return this.actLogResponded;
        } else if (type.isEmail) {
            return this.actLogInteracted;
        } else if (type.isAddedToWorld) {
            return this.actLogPerson;
        } else if (type.isUpdatedForWorld) {
            return this.actLogPerson;
        } else if (type.isChangedRoleForWorld) {
            return this.actLogPerson;
        } else if (type.isChangedTeamIdForWorld) {
            return this.actLogPerson;
        } else if (type.isChangedTitleForWorld) {
            return this.actLogPerson;
        }  else {
            return this.actLogOther;
        }
    }

}
