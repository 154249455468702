import { RestClientInterface } from '../Interfaces';
import { AngularRestClientInterface } from './AngularRestClientInterface';
import {LocalStorageService} from "../../../_services/storage/local-storage.service";

export class OAuthRestClient implements RestClientInterface {
  constructor(
    private restClient: AngularRestClientInterface,
    private localStorageService: LocalStorageService
  ) {
  }

  get(requestPath: string, queryParameters: any, headers?: any, options?: any) {
    return this.restClient.get(
      requestPath,
      queryParameters,
      this.getAuthorizationHeaders(requestPath),
      options
    );
  }

  post(requestPath: string, formParameters: any, headers?: any, options?: any) {
    return this.restClient.post(
      requestPath,
      formParameters,
      this.getAuthorizationHeaders(),
      options
    );
  }

  postMultipart(requestPath: string, formParameters: any, headers?: any, options?: any) {
    return this.restClient.postMultipart(
      requestPath,
      formParameters,
      this.getAuthorizationHeaders(),
      options
    );
  }

  private getAuthorizationHeaders(requestPath?: string): Headers {
    const headers = new Headers();

    /** The following is being commented out as part of switching
     *  from auth0 to FusionAuth.
     *  The token is now being sent as a cookie and our API middleware is handling the rest

    const token = this.localStorageService.get('accessToken');

    // force logout if null token
    if (token === null) {
      this.localStorageService.resetStorageAndLogout();
    }

    headers.append('Authorization', `Bearer ${token}`);

     */
    if (this.localStorageService.get('X-EF-GhostTech')) {
      // We don't want to add ghost header when searching for ghost user
      if (requestPath !== 'User/UseCase/GetUserByEmail') {
        headers.append('X-EF-GhostTech', this.localStorageService.get('X-EF-GhostTech'));
      }
    }

    return headers;
  }
}
