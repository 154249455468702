import { Component, OnDestroy, OnInit } from '@angular/core';
import { EFXAdminService, EFXSMSARRIVALSETTING } from '../../efx-admin.service';

@Component({
  selector: 'efx-sms-arrival',
  template: require('./efx-sms-arrival.html'),
})

export class EFXSMSArrivalComponent implements OnDestroy {

  constructor(
    private efxAdminService: EFXAdminService
  ) {
  }

  toggle() {

    if (this.efxAdminService.isSMSArrivalEnabled) {
      this.efxAdminService.toggleEFXContactSetting(EFXSMSARRIVALSETTING, false);
    } else {
      this.efxAdminService.toggleEFXContactSetting(EFXSMSARRIVALSETTING, true);
    }
  }

  ngOnDestroy() {
  }
}
