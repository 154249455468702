import { Stack } from './../../../../ApiClient/Models/Stack/stack';
import { createSelector } from '@ngrx/store';
import { TicketType } from '../../../../ApiClient/Models/Stack/ticket-type';

const getCurrentEvent = (state) => state.event;

export const getTypesWithStacks: any = createSelector(
    getCurrentEvent,
    (event) => {
        if (event.ticketTypes.loaded && event.stacks.loaded) {
            const types: TicketType[] = Object.keys(event.ticketTypes.data).map(ticketTypeId => {
                return event.ticketTypes.data[ticketTypeId];
            });

            const stacks: Stack[] = Object.keys(event.stacks.data).map(stackId => {
                return event.stacks.data[stackId];
            });

            const typesWithStacks = types.map((type: TicketType) => {
                const stacksForType = stacks.filter((stack: Stack) => {
                    if (stack.ticketType) {
                        return stack.ticketType.id === type.id;
                    }
                });
                return Object.assign(type, { stacks: stacksForType });
            });
            return typesWithStacks;
        }
    }
);

export const getTypesWithCounts: any = createSelector(
    getTypesWithStacks,
    (types: any) => {
        if (types) {

            const getTotalUnused = (id) => {
                const quantity = types.filter(type => type.id === id)[0].quantity;
                const stacks = types.filter(type => type.id === id)[0].stacks;
                if (stacks.length > 0) {
                    const stacksUsed = stacks.map(stack => stack.availabilityCounts.totalUsed).reduce((acc, val) => acc + val);
                    return quantity - stacksUsed;
                }
                return quantity;
            };

            return types.map(type => {
                return {
                    [type.id]: {
                        total: type.quantity,
                        totalUnused: getTotalUnused(type.id),
                        stacks: type.stacks.reduce((obj, item) => {
                            return {
                                ...obj,
                                [item.id]: item.availabilityCounts
                            };
                        }, {})
                    }
                };
            }).reduce((obj, item) => {
                const id = Object.keys(item)[0];
                return {
                    ...obj,
                    [id]: item[id]
                };
            }, {});
        }
    }
);
