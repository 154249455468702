import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../admin.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup } from '@angular/forms';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { TeamAllotmentService } from './team-allotment.service';
import {shareReplay} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../../store';
import * as adminAllotmentActions from '../../../../store/actions/admin/admin-allotment.action';
@Component({
  selector: 'team-allotments',
  template: require('./team-allotments.html'),
})
export class TeamAllotmentsComponent implements OnInit, OnDestroy {
  @Input() team: Team;
  teamAllotmentsForm: FormGroup;
  teamAllotmentsFormEF: FormGroup;
  teamAllotmentsFormCIO: FormGroup;
  showCreateForm: boolean = true;
  poolContractTypes: any;
  poolAllotmentTypes: any;
  teamAllotmentsUpdate$: Subscription;
  cioMaxEventsUpdate$: Subscription;
  efMaxEventsUpdate$: Subscription;

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private adminService: AdminService,
    private teamAllotmentService: TeamAllotmentService,
    private store: Store<fromRoot.AppState>,

  ) {}
  private allotmentsLoading$ = this.store.select(fromRoot.getAdminCurrentTeamAllotmentsLoading).pipe(shareReplay());

  private allotments$ = this.store.select(fromRoot.getAdminCurrentTeamAllotmentsData).pipe(shareReplay());
  private efAllotments$ = this.store.select(fromRoot.getAdminCurrentTeamAllotmentsEF).pipe(shareReplay());
  private efMaxEvents$ = this.store.select(fromRoot.getAdminCurrentTeamAllotmentsEFMax).pipe(shareReplay());
  private cioMaxEvents$ = this.store.select(fromRoot.getAdminCurrentTeamAllotmentsCIOMax).pipe(shareReplay());

  async ngOnInit() {
    this.teamAllotmentsForm = new FormGroup({
      allotmentType: new FormControl('eventfarm'),
      allotmentAmount: new FormControl(1),

    });
    this.teamAllotmentsFormEF = new FormGroup({
      allotmentAmountEF: new FormControl(),
    });
    this.teamAllotmentsFormCIO = new FormGroup({
      allotmentAmountCIO: new FormControl(),
    });
    this.poolAllotmentTypes = this.apiClient
        .getTypeFactory()
        .Pool()
        .PoolAllotmentType()
        .filter((p) => p.slug !== 'listly');

    // Fetch allotments

    // Updates values of allotment count for CIO
    this.cioMaxEventsUpdate$ =  this.cioMaxEvents$.subscribe(val => {
      this.teamAllotmentsFormCIO.controls.allotmentAmountCIO.setValue(
          val
      );
      if (val) {
        this.poolAllotmentTypes = this.poolAllotmentTypes.filter(
            (p) => p.slug !== 'cio'
        );
      }

    });

    // Updates values of allotment count for EF
    this.efMaxEventsUpdate$ =  this.efMaxEvents$.subscribe(val => {
      this.teamAllotmentsFormEF.controls.allotmentAmountEF.setValue(
          val
      );
      if (val) {
        this.poolAllotmentTypes = this.poolAllotmentTypes.filter(
            (p) => p.slug !== 'eventfarm'
        );
      }
    });

  }
  addAllotment() {
    this.store.dispatch(new adminAllotmentActions.AddAllotmentToAdminTeam(
        {
          teamId: this.team.id,
          type: this.teamAllotmentsForm.get('allotmentType').value.slug,
          amount: this.teamAllotmentsForm.get('allotmentAmount').value
        }));
  }

  update(allotmentId: string, amount: number) {
    this.store.dispatch(new adminAllotmentActions.UpdateAllotmentForAdminTeam(
        {
          teamId: this.team.id,
          allotmentId,
          amount
        }));
  }

  ngOnDestroy() {
    this.cioMaxEventsUpdate$.unsubscribe();
    this.efMaxEventsUpdate$.unsubscribe();
  }
}
