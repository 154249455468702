import { Component } from '@angular/core';
import { EventFarmService } from './../../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../store';
import { map, tap, filter } from 'rxjs/operators';

@Component({
    selector: 'launch-canvas',
    styles: [require('./launch-canvas.scss')],
    template: require('./launch-canvas.html')
})

export class LaunchCanvasComponent {
    readonly canvasSVG = require('./../../../../../../mars/components/icons/canvas.svg');
    private eventId: string;
    constructor(
        private efs: EventFarmService,
        private store: Store<fromRoot.AppState>
    ) {
        this.store.select('event').pipe(
            filter(event => !!event.data),
            map(res => res.data.id),
        ).subscribe(eventId => {
            return this.eventId = eventId;
        });
    }
}
