import {Component, OnDestroy, OnInit} from '@angular/core';
import {SMSStatusSlug} from '../../../../ApiClient/Models/SMS/sms-status';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import {EventFarmService} from '../../../eventFarm.service';
import * as fromRoot from '../../../store';
import {SMSService} from '../sms.service';
import {SMSReportsService} from './sms-reports.service';

@Component({
    selector: 'sms-reports',
    template: require('./sms-reports.html'),
    styles: [require('./sms-reports.scss')]
})

export class SMSReportsComponent implements OnInit, OnDestroy {
    expandSet = new Set<number>();
    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
    }
    constructor(
        private iconService: IconService,
        private smsService: SMSService,
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private smsReportsService: SMSReportsService
    ) {}

    ngOnInit(): void {
        this.smsReportsService.fetchStatsForEvent();
    }


    ngOnDestroy() {
    }

    showSort(i: number) {
        // Only shows sort for sentAt
        return i === 3;
    }

    getColor(val: SMSStatusSlug) {
        switch (val) {
            case 'sent':
                return '#5085F0';
            case 'sending':
                return '#FF9F1C';
            case 'delivered':
                return '#3ddc97';
            case 'failed':
                return '#C2185B';
        }
    }
}
