import { AbstractModel } from '../abstract-model';

import { QuestionTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Question';
import { Answer } from '../Answer/answer';
import { TicketType } from '../Stack/ticket-type';
import { AnswerBinding } from './answer-binding';

export class Question extends AbstractModel {

    private _isDeleted: boolean;
    private _isDisabled: boolean;
    private _isHidden: boolean;
    private _isIndividual: boolean;
    private _isRequired: boolean;
    private _questionType: QuestionTypeInterface;
    private _sortOrder: number;
    private _text: string;
    private _answers: Answer[];
    private _ticketType: TicketType|null;
    private _answerBindings: AnswerBinding[];

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): Question {
        const question = new this(data.id);

        try {
            question._isDeleted = data.attributes.isDeleted;
            question._isDisabled = data.attributes.isDisabled;
            question._isHidden = data.attributes.isHidden;
            question._isIndividual = data.attributes.isIndividual;
            question._isRequired = data.attributes.isRequired;
            question._questionType = data.attributes.questionType;
            question._sortOrder = data.attributes.sortOrder;
            question._text = data.attributes.text;
            question._answers = [];
            question._answerBindings = [];

            if (data.relationships && data.relationships.answers) {
                data.relationships.answers.forEach(answer => {
                    const a = Answer.fromApiResponse(answer);
                    if (!a.isDeleted) {
                        question._answers.push(a);
                    }
                });
            }

            if (data.relationships && data.relationships.ticketType) {
                question._ticketType = TicketType.fromApiResponse(data.relationships.ticketType);
            }else {
                question._ticketType = null;
            }

            if (data.relationships && data.relationships.answerBindings) {
                data.relationships.answerBindings.forEach(answerBinding => {
                    const a = AnswerBinding.fromApiResponse(answerBinding);
                    a.question = question;
                    question._answerBindings.push(a);
                });
            }
            
        } catch (err) { }
        return question;
    }

    public static forNewCreation(): Question {
        const question = new this();

        question._text = '';
        question._isDisabled = false;
        question._isHidden = false;
        question._isIndividual = true;
        question._isRequired = false;
        question._answers = [];
        question._answerBindings = [];
        question._ticketType = null;

        return question;
    }

    get isAnswerBindingCompatible(): boolean {
        if (this._questionType.isRadio || this._questionType.isSelect || this._questionType.isCheckbox) {
            return true;
        }
        return false;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get isDisabled(): boolean {
        return this._isDisabled;
    }

    set isDisabled(value: boolean) {
        this._isDisabled = value;
    }

    get isHidden(): boolean {
        return this._isHidden;
    }

    set isHidden(value: boolean) {
        this._isHidden = value;
    }

    get isIndividual(): boolean {
        return this._isIndividual;
    }

    set isIndividual(value: boolean) {
        this._isIndividual = value;
    }

    get isRequired(): boolean {
        return this._isRequired;
    }

    set isRequired(value: boolean) {
        this._isRequired = value;
    }

    get questionType(): QuestionTypeInterface {
        return this._questionType;
    }

    set questionType(value: QuestionTypeInterface) {
        this._questionType = value;
    }

    get sortOrder(): number {
        return this._sortOrder;
    }

    set sortOrder(value: number) {
        this._sortOrder = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get answers(): Answer[] {
        return this._answers;
    }

    set answers(value: Answer[]) {
        this._answers = value;
    }

    get ticketType(): TicketType | null {
        return this._ticketType;
    }

    set ticketType(value: TicketType | null) {
        this._ticketType = value;
    }

    get answerBindings(): AnswerBinding[] {
        return this._answerBindings;
    }

    set answerBindings(value: AnswerBinding[]) {
        this._answerBindings = value;
    }

    public toggleEnabledOrDisabled() {
        this._isDisabled = !this.isDisabled;
    }

    get hasLogic() {
        return this._answerBindings.length > 0
    }

    get shouldNotHaveAnswers() {
        return this._questionType.isAddress || this._questionType.isDate || this._questionType.isText || this._questionType.isMulti;
    }
}


export class QuestionBuilderTypeInterface {
    slug: string;
    hasOptions: boolean = false;
    hasText: boolean = false;
    isRequired: boolean = false;
}

export const QuestionBuilderTypes = [
    {
        slug: 'checkbox',
        hasOptions: true,
    } as QuestionBuilderTypeInterface,
    {
        slug: 'radio',
        hasOptions: true,
        isRequired: true,
    } as QuestionBuilderTypeInterface,
    {
        slug: 'multi',
    } as QuestionBuilderTypeInterface,
    {
        slug: 'text',
    } as QuestionBuilderTypeInterface,
    {
        slug: 'select',
        hasOptions: true,
    } as QuestionBuilderTypeInterface,
    {
        slug: 'date',
    } as QuestionBuilderTypeInterface,
    {
        slug: 'waiver',
        hasText: true,
        isRequired: true,
    } as QuestionBuilderTypeInterface,
    {
        slug: 'address',
    } as QuestionBuilderTypeInterface,
];
