import { Injectable } from '@angular/core';

import { Group } from '../../../../ApiClient/Models/Group/group';
import { EmailDesignCenterService } from '../email-design-center.service';
import { InvitationStatusTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { Operator } from 'rxjs';
import { EventGroupStatusFilterTypeInterface, Group as EFGroup } from '@eventfarm/javascript-sdk/dist/Api/Type/Group';

@Injectable()

export class GuestMessagingSendOptionsService {
    private _isTypeSendAMessage?: boolean;
    private _isTypeSendGroupMessage?: boolean;

    private _isSendByInvitationStatus: boolean = true;
    private _isSendByGroup: boolean = false;
    private _isSendByWaitList: boolean = false;
    private _isSendByNoShows: boolean = false;
    private _isSendByAllCheckedInGuests: boolean = false;

    private _ticketTypeIds: string[] = [];
    private _messageTargets: string[] = [];
    private _selectedGroup: Group;
    private _groupStatusFilter: null|EventGroupStatusFilterTypeInterface = null;

    constructor(
    ) {
    }

    get isTypeSendAMessage(): boolean {
        return this._isTypeSendAMessage !== false;
    }

    get isTypeSendGroupMessage(): boolean {
        return this._isTypeSendGroupMessage !== false;
    }

    set isTypeSendAMessage(value: boolean) {
        this._isTypeSendAMessage = value;
    }

    set isTypeSendGroupMessage(value: boolean) {
        this._isTypeSendGroupMessage = value;
    }

    get messageTargets(): string[] {
        return this._messageTargets;
    }

    set messageTargets(value: string[]) {
        this._messageTargets = value;
    }

    get isSendByInvitationStatus(): boolean {
        return this._isSendByInvitationStatus;
    }

    get isSendByGroup(): boolean {
        return this._isSendByGroup;
    }

    get isSendByWaitList(): boolean {
        return this._isSendByWaitList;
    }

    get isSendByNoShows(): boolean {
        return this._isSendByNoShows;
    }
    

    get isSendByAllCheckedInGuests(): boolean {
        return this._isSendByAllCheckedInGuests;
    }

    get ticketTypeIds(): string[] {
        return this._ticketTypeIds;
    }

    set ticketTypeIds(value: string[]) {
        this._ticketTypeIds = value;
    }

    get selectedGroup(): Group {
        return this._selectedGroup;
    }

    set selectedGroup(value: Group) {
        this._selectedGroup = value;
    }

    get groupStatusFilterSlug(): null|string {
        return this._groupStatusFilter ? this._groupStatusFilter.slug : null;
    }

    setForSendByInvitationStatus() {
        this.resetPrivates();
        this._isSendByInvitationStatus = true;
    }

    setForSendByGroup() {
        this.resetPrivates();
        this._isSendByGroup = true;
        this._groupStatusFilter = null;
    }

    setForSendByWaitList() {
        this.resetPrivates();
        this._isSendByWaitList = true;
    }

    setForSendByAllCheckedIn() {
        this.resetPrivates();
        this._isSendByAllCheckedInGuests = true;
    }

    setForSendByNoShows() {
        this.resetPrivates();
        this._isSendByNoShows = true;
    }

    // set group status filters

    setGroupStatusForAllGroupMembers() {
        this._groupStatusFilter = null;
    }

    setGroupStatusForGroupMembersOnGuestList() {
        this._groupStatusFilter = new EFGroup().EventGroupStatusFilterType().find(f => f.isHasInvitation) ?? null;
    }

    setGroupStatusForMembersNotOnGuestList () {
        this._groupStatusFilter = new EFGroup().EventGroupStatusFilterType().find(f => f.isDoesNotHaveInvitation) ?? null;
    }

    setMessageTargets(statusType: InvitationStatusTypeInterface) {

        if (this.isMessageTargetSelected(statusType.slug)) {
            this._messageTargets = this._messageTargets.filter((message) => {
                return message !== statusType.slug;
            });
        } else {
            this._messageTargets.push(statusType.slug);
        }
    }

    private resetPrivates(): void {
        this._isSendByInvitationStatus = false;
        this._isSendByGroup = false;
        this._isSendByWaitList = false;
        this._isSendByNoShows = false;
        this._isSendByAllCheckedInGuests = false;
        this._messageTargets = [];
        this._ticketTypeIds = [];
        this._groupStatusFilter = null;
    }

    private shouldDisableButton(): boolean {
        if (this._isSendByInvitationStatus) {
            return !this._messageTargets.length || !this._ticketTypeIds.length;
        } else if (this._isSendByGroup) {
            return !this._selectedGroup;
        }

        return false;
    }

    private isMessageTargetSelected(messageTarget: string): boolean {
        return this._messageTargets.some((iterableId) => {
            return messageTarget === iterableId;
        });
    }

    private isTicketTypeSelected(selectedId: string): boolean {
        return this._ticketTypeIds.some((iterableId) => {
            return selectedId === iterableId;
        });
    }
}
