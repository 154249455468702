import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PoolsComponent } from './pools.component';
import { PoolResolver } from './pool-resolver.service';
import { UserManagerResolver } from '../user-manager-resolver.service';
import { UserManagerOrEventCreateResolver } from '../user-manager-or-event-create-resolver.service';
import { PoolsGuard } from './pools.guard';

const poolsRoutes: Routes = [
    {
        path: '',
        component: PoolsComponent,
        canActivate: [PoolsGuard],
        children: [
            {
                path: ':pool',
                resolve: [PoolResolver],
                loadChildren: () => import('./EventsList/eventsList.module').then(
                    module => module.EventsListModule
                ),
            },
            {
                path: ':pool/events-list',
                resolve: [PoolResolver],
                loadChildren: () => import('./EventsList/eventsList.module').then(
                    module => module.EventsListModule
                ),
            },
            {
                path: ':pool/event',
                resolve: [PoolResolver],
                loadChildren: () => import('./../EventsModule/events.module').then(
                    module => module.EventsModule
                ),
            },
            {
                path: ':pool/create-event',
                resolve: [PoolResolver, UserManagerOrEventCreateResolver],
                loadChildren: () => import('./CreateEvent/create-event.module').then(
                    module => module.CreateEventModule
                ),
            },
            {
                path: ':pool/my-account',
                resolve: [PoolResolver],
                loadChildren: () => import('./MyAccount/my-account.module').then(
                    module => module.MyAccountModule
                ),
            },
            {
                path: ':pool/upload',
                resolve: [PoolResolver],
                loadChildren: () => import('./Uploader/uploader.module').then(
                    module => module.UploaderModule
                ),
            },
            {
                path: ':pool/address-book',
                resolve: [PoolResolver],
                loadChildren: () => import('./AddressBook/addressBook.module').then(
                    module => module.AddressBookModule
                ),
            },
            {
                path: '**',
                resolve: [PoolResolver],
                loadChildren: () => import('./EventsList/eventsList.module').then(
                    module => module.EventsListModule
                ),
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(poolsRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        PoolsGuard,
        PoolResolver,
    ]
})
export class PoolsRoutingModule {
}