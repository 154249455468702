import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { User } from '../../../../ApiClient/Models/User/user';
import { AlertService } from '../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { GuestListViewService } from '../guest-list-view.service';
import { UserName } from '../../../../ApiClient/Models/User/user-name';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';
import { Stack } from '../../../../ApiClient/Models/Stack/stack';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { getEventUrl } from '../../../environment';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';

@Injectable()
export class EditInvitationService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private messageService: NzMessageService,
        private segmentService: SegmentService,
        private guestListService: GuestListViewService,
        private store: Store<fromRoot.AppState>,

    ) {}

    public resending: boolean = false;

    get currentStacks(): Stack[] {
        return this.eventFarmService.currentEvent.stacks;
    }

    get currentEvent(): EfEvent {
        return this.eventFarmService.currentEvent;
    }

    private eventUrl() {
        return getEventUrl(this.eventFarmService.currentEvent);
    }

    public async saveInvitation(updateInvitation: UpdateInvitationValues) {
        try {

            if (updateInvitation.shouldTransfer) {
                const message = "Editing a guest's email address will transfer this ticket to the updated email address. Would you like to proceed?";
                const confirmTransfer = await this.alertService.guestList().confirmTransferInvitation(message);
                if (!confirmTransfer.value) {
                    return null;
                }
            }

            const promises = [];

            promises.push(this.apiClient
                .getUseCaseFactory()
                .Invitation()
                .UpdateInvitation(
                    updateInvitation.invitationId,
                    updateInvitation.stackId,
                    updateInvitation.invitationStatus,
                    updateInvitation.company,
                    updateInvitation.position,
                    updateInvitation.shouldTransfer ? updateInvitation.oldEmail : updateInvitation.email,
                    updateInvitation.firstName,
                    updateInvitation.lastName,
                    updateInvitation.other,
                    updateInvitation.telephone,
                    null,
                    null,
                    updateInvitation.count,
                    updateInvitation.title
                ).toPromise());

            if (updateInvitation.externalId.userAttributeId) {
                promises.push(this.apiClient.getUseCaseFactory().UserAttribute().RemoveUserAttribute(updateInvitation.externalId.userAttributeId).toPromise());
            } else if (updateInvitation.externalId.userId && updateInvitation.externalId.value) {
                promises.push(this.apiClient.getUseCaseFactory().UserAttribute().SetCustomUserAttribute(this.eventFarmService.currentTeam.id, updateInvitation.externalId.userId, 'external_id', updateInvitation.externalId.value).toPromise());
            }

            promises.push(this.apiClient.getUseCaseFactory().Invitation().SetCheckInNotes(updateInvitation.invitationId, updateInvitation.checkInNote).toPromise());
            promises.push(this.apiClient.getUseCaseFactory().Invitation().SetInvitationNotes(updateInvitation.invitationId, updateInvitation.inviteNote).toPromise());
            promises.push(this.apiClient.getUseCaseFactory().Invitation().SetProxyEmail(updateInvitation.invitationId, updateInvitation.proxyEmail).toPromise());

            // updates old stacks count and

            if (updateInvitation.shouldCheckIn) {
                promises.push(this.apiClient.getUseCaseFactory().Invitation().CheckIn(updateInvitation.invitationId, null, true).toPromise());
            } else {
                promises.push(this.apiClient.getUseCaseFactory().Invitation().UndoCheckIn(updateInvitation.invitationId).toPromise());
            }

            if (updateInvitation.shouldSendArrival) {
                promises.push(this.apiClient.getUseCaseFactory().Invitation().SetArrivalAlertEmailsAndPhoneNumbers(updateInvitation.invitationId, updateInvitation.arrivalEmails, true, updateInvitation.arrivalPhoneNumbers).toPromise());
            } else {
                promises.push(this.apiClient.getUseCaseFactory().Invitation().DisableArrivalAlert(updateInvitation.invitationId).toPromise());
            }

            if (this.eventFarmService.guestPassEnabled) {
                promises.push(this.apiClient.getUseCaseFactory().Invitation().SetGuestPassCount(updateInvitation.invitationId, updateInvitation.guestPassCount).toPromise());
            }


            await Promise.all(promises);

            if (updateInvitation.shouldTransfer) {

                const transfer = await this.apiClient.getUseCaseFactory().Transfer().CreateTransfer(this.eventFarmService.currentEvent.id, updateInvitation.invitationId, updateInvitation.firstName, updateInvitation.lastName, updateInvitation.email, updateInvitation.count, false, true).toPromise();
                const newInvite = await this.apiClient.getUseCaseFactory().Transfer().GetTransfer(transfer.data.command.transferId).toPromise();

                this.messageService.success('Invitation transferred');

                this.segmentService.segmentGuestListTracking().transferInvitation({updateInvitation});

                return await this.fetchCurrentInvitation(newInvite.data.relationships.recipient.id);

            } else {
                this.messageService.success('Invitation updated');
            }

            this.segmentService.segmentGuestListTracking().saveInvitation({updateInvitation});

            return await this.fetchCurrentInvitation(updateInvitation.invitationId);

        } catch (err) {
            const errors = this.guestListService.extractErrorsIntoMessages(err.error);
            if (errors.length) {
                let errorsString = '';
                errors.forEach((error) => {
                    errorsString += error.detail + ' ';
                });
                await this.alertService.guestList().emailChangeError(errorsString);
            } else {
                await this.alertService.invitation().unableToUpdateInvitation();
            }

            return null;

        }
    }

    /**
     * Fetches the current invitation
     *
     */

    public async fetchCurrentInvitation(invitationId: string) {
        this.segmentService.segmentGuestListTracking().openInvitationSlideOut({invitationId});

        if (!this.guestListService.isTicketBlock) {
            this.store.dispatch(new fromRoot.FetchCurrentEvent());
        }

        try {
            const invitation = await this.apiClient
                .getUseCaseFactory()
                .Invitation()
                .GetInvitation(
                    invitationId,
                    ['User', 'UserName', 'UserIdentifier', 'Stack', 'TicketType', 'Event', 'QuestionResponse', 'Answer', 'UserHealthPass', 'GuestPassAvailabilityCounts', 'RelatedInvitation'],
                    ['info', 'custom']
                ).toPromise();
            return Invitation.fromApiResponse(invitation.data);
        } catch (error) {
            this.messageService.error('Please close the slide out and try again');
            return null;
        }
    }

    /**
     * Fetches the current user details for invitation
     *
     */

    public async fetchCurrentUserForInvitation(userId: string) {
        const userAttributes = ['info', 'custom'];

        if (this.eventFarmService.virbelaEnabled) {
            userAttributes.push('virbela');
        }

        try {
            const user = await this.apiClient
                .getUseCaseFactory()
                .User()
                .GetUserInPool(
                    this.eventFarmService.currentTeam.id,
                    userId,
                    ['UserName', 'UserAddress', 'UserIdentifier', 'UserHealthPass'],
                    userAttributes,
                ).toPromise();
            return User.fromApiResponse(user.data);
        } catch (error) {
            this.messageService.error('Please close the slide out and try again');
            return null;
        }
    }

    /**
     * Deletes (changes invitation status to recycled) invitation
     *
     */
    public async deleteInvitation(invitationId: string, guestName: UserName) {
        try {
            let message = 'Are you sure you want to remove this invitation?';
            if (guestName && guestName.firstName && guestName.lastName) {
                message = 'Are you sure you want to remove the invitation for ' + guestName.firstName + ' ' + guestName.lastName + '?';
            }

            const confirmDelete = await this.alertService.guestList().confirmRemoveInvitation(message);

            if (confirmDelete) {
                const statusChange = await this.apiClient.getUseCaseFactory().Invitation().ChangeInvitationStatus(invitationId, 'recycled').toPromise();
                this.messageService.success('Invitation removed successfully');
                this.guestListService.inviteChangedSource.next();
                this.segmentService.segmentGuestListTracking().deleteInvitation({invitationId, guestName});

                return true;
            } else {
                return false;
            }
        } catch (error) {
            let errorMessage = 'Unable to remove invitation for the guest. Please contact Event Farm support.';
            if (error.response && error.response.status === 403) {
                errorMessage = 'Permission to remove invitations is not available. Please contact your event organizer.';
            }
            this.messageService.error(errorMessage);
            return false;
        }
    }

    /**
     * Resends the invite or confirmation email to user
     */
    public async resendInviteOrConfirmation(type: string, invitationId: string, identifier: string) {
        this.resending = true;
        try {
            if (type === 'resend-invite') {
                const invite = await this.alertService.invitation().invitationResendConfirmation(identifier);
                if (invite) {
                    await this.apiClient.getUseCaseFactory().Invitation().ResendInvitationEmail(invitationId).toPromise();
                    this.segmentService.segmentGuestListTracking().resendInvitation({invitationId, identifier});

                    this.messageService.success('Invite resent successfully');
                }
            } else {
                const confirm = await this.alertService.invitation().confirmationResendConfirmation(identifier);
                // @ts-ignore
                if (confirm) {
                    await this.apiClient.getUseCaseFactory().Invitation().ResendConfirmationEmail(invitationId).toPromise();
                    this.segmentService.segmentGuestListTracking().resendConfirmation({invitationId, identifier});
                    this.messageService.success('Confirmation resent successfully');
                }
            }
        } catch (error) {
            this.messageService.error(error.error.errors[0].detail);
        }
        this.resending = false;
    }


    /**
     * Suspends guest user account
     */
    public async suspendUserFromVirbela(invitationId: string, identifier: string) {
        try {
            const confirm = await this.alertService.virbelaIntegration().suspendGuest(identifier);
            if (confirm.value) {
                await this.apiClient.getUseCaseFactory().Virbela().SuspendUserForVirbela(invitationId).toPromise();
                this.messageService.success('Guest suspended successfully');
                return true;
            }
        } catch (error) {
            this.messageService.error(`Unable to suspend ${identifier} at this time. Please contact support.`);
            return false;
        }
    }


    /**
     * Syncs to salesforce if its connected
     */
    public async syncToSaleforce(invitationId: string) {

        try {
            await this.apiClient.getUseCaseFactory().Salesforce().ExportInvitationToSalesforce(invitationId).toPromise();
            this.segmentService.segmentGuestListTracking().syncSaleforceInvitation({invitationId});

            this.alertService.salesforceIntegration().salesforceExportSingleSuccess();
        } catch (error) {
            this.alertService.salesforceIntegration().salesforceExportError();
        }

    }

    /**
     * Checks in user for fast check in on table view
     */
    public async changeCheckInStatus(invitationId: string, shouldCheckIn: boolean) {
        try {
            if (shouldCheckIn) {
                await this.apiClient.getUseCaseFactory().Invitation().CheckIn(invitationId, null, true).toPromise();
                this.segmentService.segmentGuestListTracking().checkInGuest({invitationId});

                this.messageService.success('Check-in success');
            } else {
                await this.apiClient.getUseCaseFactory().Invitation().UndoCheckIn(invitationId).toPromise();
                this.segmentService.segmentGuestListTracking().undoCheckIn({invitationId});
                this.messageService.success('Undo check-in success');
            }
        } catch (error) {
            this.messageService.error('Please try again');
        }
    }

    /**
     * Copies share Link to clipboard for user
     */
    public async shareLink(invitation: Invitation) {
        try {

            const shareLink = `${invitation.sitePagePath}?invitationId=${invitation.id}`;
            const el = document.createElement('textarea');
            el.value = shareLink;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.segmentService.segmentGuestListTracking().copyShareLink({shareLink});
            this.messageService.success('Invite link copied to clipboard');

        } catch (error) {}
    }

    /**
     * Visits share Link to clipboard for user
     */
    public async visitLink(invitation: Invitation) {
        try {

            const link = `${invitation.sitePagePath}?invitationId=${invitation.id}`;

            this.segmentService.segmentGuestListTracking().visitShareLink({link});

            window.open(link, '_blank');

        } catch (error) {}
    }

    public async handleProfileImage(userId: string, currentyHasProfileImage: boolean, base64Image: string|null) {
        const poolId = this.eventFarmService.currentTeamId;

        //delete profile image
        if (currentyHasProfileImage && !base64Image) {
            await this.apiClient.getUseCaseFactory().User().DeleteProfileImageForUser(poolId, userId).toPromise();
        }

        // set profile image
        if ((!currentyHasProfileImage && base64Image) || (currentyHasProfileImage && base64Image)) {
            await this.apiClient.getUseCaseFactory().User().SetProfileImageForUser(poolId, userId, base64Image).toPromise();
        }
    }

}

export interface UpdateInvitationValues {
    invitationId: string;
    invitationStatus: string;
    stackId: string;
    company: string;
    position: string;
    email: string;
    firstName: string;
    lastName: string;
    other: string;
    externalId: {
        userAttributeId: string|null,
        value: string|null,
        userId: string|null,
    }
    telephone: string;
    checkInNote: string;
    inviteNote: string;
    count: number;
    shouldCheckIn: boolean;
    userId: string;
    title: string;
    shouldSendArrival: boolean;
    arrivalEmails: string[]|null;
    arrivalPhoneNumbers: string[]|null;
    oldEmail: string;
    shouldTransfer: boolean;
    guestPassCount: number| null;
    proxyEmail: string|null;
}
