import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { AddressBookGroupService } from '../addressBookGroup.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { ApiError } from '../../../../../ApiClient/Models/error';
import { AddressBookService } from '../../addressBook.service';
import { Group } from '../../../../../ApiClient/Models/Group/group';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'address-book-add-group',
    styles: [require('./address-book-add-group.scss')],
    template: require('./address-book-add-group.html'),
})

export class AddressBookAddGroupComponent implements OnInit {
    constructor(
        private modalService: ModalService,
        private addressBookGroupService: AddressBookGroupService,
        private segmentService: SegmentService,
        private addressBookService: AddressBookService,
        private message: NzMessageService,
        private eventFarmService: EventFarmService,
    ) {}

    readonly groupPeopleBlueSVG = require('../../assets/circle-group-people-blue');
    readonly saveIconSVG = require('../../assets/save-icon');

    requesting: boolean = false;
    groupName: string = '';
    errors: ApiError[];

    ngOnInit() {
        this.errors = [];
        this.requesting = false;
        this.segmentService.segmentAddressBookTracking().openNewGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
    }

    close() {
        this.modalService.closeModal();
    }

    save() {
        this.segmentService.segmentAddressBookTracking().newGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            this.groupName
        );
        this.requesting = true;
        this.addressBookGroupService.createGroupForUser(this.eventFarmService.currentUser.id, this.groupName)
            .toPromise()
            .then((res) => {
                this.requesting = false;
                this.addressBookGroupService.broadcastGroupsForUser(this.eventFarmService.currentUser.id);
                this.message.success('Group created');
                let newGroup = new Group(res.data.command.groupId);
                newGroup.name = res.data.command.groupName;
                this.addressBookService.broadcastGroup(newGroup);
                this.modalService.closeModal();
            })
            .catch((err) => {
                this.requesting = false;
                let formattedErrors: any[] = [];
                if (err.error.errors.length) {
                    err.error.errors.forEach((error) => {
                        formattedErrors.push({
                            detail: error.detail
                        });
                    });
                } else {
                    formattedErrors.push({
                        detail: 'Unable to Create Group',
                    });
                }
                this.errors = ApiError.fromApiErrorArray(formattedErrors);
            });
    }
}
