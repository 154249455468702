import { Component, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { EventFarmService } from '../EventFarm/eventFarm.service';
import { User } from '../ApiClient/Models/User/user';
import { Store } from '@ngrx/store';
import * as fromRoot from '../EventFarm/store';
import { filter, take, shareReplay } from 'rxjs/operators';
@Component({
    selector: 'analytics',
    template: ''
})

export class AnalyticsComponent implements OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private zone: NgZone,
        private store: Store<fromRoot.AppState>,
    ) {
        this.userUpdate$ = this.userStore$.pipe(filter(val => val.data && val.data.id), take(1)).subscribe((val) => {
            this.verifyUserDetails(val.data);
        });
    }

    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userUpdate$: Subscription;

    verifyUserDetails(user: User) {
        let userId = user.id;
        let userEmail = user && user.identifier && user.identifier.identifier ? user.identifier.identifier : null;
        let userName = user && user.name && user.name.fullName ? user.name.fullName : null;
        if ((!userId && !userName) || (!userId && !userEmail)) {
            return;
        }
        // this.chameleonInit(userId, userName, userEmail);
        // this.intercomInit(userId, userEmail);
    }

    // chameleonInit(userId, userName, userEmail) {
    //     this.zone.runOutsideAngular(() => {
    //         if (!window['chmln']) {
    //             return;
    //         }
    //         window['chmln'].identify(userId, {
    //             email: userEmail,
    //             name: userName,
    //         });
    //     });
    // }

    // intercomInit(userId, userEmail) {
    //     this.zone.runOutsideAngular(() => {
    //         if (!window['Intercom']) {
    //             return;
    //         }
    //         window['Intercom']('boot', {
    //             app_id: 'wri890r0',
    //             email: userEmail,
    //             user_id: userId,
    //         });
    //     });
    // }

    ngOnDestroy() {
        this.userUpdate$.unsubscribe();
    }
}