import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AddressBookService } from '../addressBook.service';
import { AddressBookUserService } from '../User/addressBookUser.service';
import { Subject } from 'rxjs';
import { Group } from '../../../../ApiClient/Models/Group/group';
import { EventFarmService } from '../../../eventFarm.service';
import { AlertService } from '../../../eventFarmAlert.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class AddressBookGroupService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private alertService: AlertService,
        private message: NzMessageService,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
    ) {}

    public groupsSource = new Subject<Group[]>();
    public groupsMetaSource = new Subject<any>();
    public groups$ = this.groupsSource.asObservable();
    public groupsMeta$ = this.groupsMetaSource.asObservable();
    public isLoadingGroups: boolean;

    public groupListOptions: GroupListOptions = {
        query: '',
        pagination: {
            currentGroupsPage: 1,
            itemsPerPage: 50,
        }
    };

    public broadcastGroupsForUser(userId) {
        this.isLoadingGroups = true;
        this.getGroupsForUser(userId)
            .subscribe((res) => {
                let groupsFromApi = res;
                let groups: Group[] = [];
                this.groupsMetaSource.next(groupsFromApi.meta);
                groupsFromApi.data.forEach((group) => {
                    if (!group.isDeleted) {
                        groups.push(Group.fromApiResponse(group));
                    }
                });
                let sortedGroups: Group[] = [];
                groups.forEach((group) => {
                    if (group.name && group.name === 'Added By Me') {
                        this.addressBookService.addedByMeGroup = group;
                    } else {
                        sortedGroups.push(group);
                    }
                });
                if (this.addressBookService.addedByMeGroup) {
                    sortedGroups.unshift(this.addressBookService.addedByMeGroup);
                } else {
                    sortedGroups = groups;
                }
                this.groupsSource.next(sortedGroups);
                this.checkGroupsForSelectedGroup(sortedGroups);
                this.isLoadingGroups = false;
            });
    }

    public checkGroupsForSelectedGroup(groups: Group[]) {
        if (this.addressBookService.selectedGroup) {
            let foundGroup = groups.filter(group => group.id === this.addressBookService.selectedGroup.id);
            if (foundGroup.length < 1) {
                this.addressBookService.broadcastClearGroup();
            };
        };
    }

    public getGroupsForUser(userId) {
        return this.apiClient.getUseCaseFactory().Group().ListGroupsOwnedByUser(
            userId,
            this.groupListOptions.query,
            this.groupListOptions.pagination.currentGroupsPage,
            this.groupListOptions.pagination.itemsPerPage,
            'name'
        );
    }

    public createGroupForUser(userId: string, groupName: string) {
        return this.apiClient.getUseCaseFactory().Group().CreateGroupForUser(userId, groupName);
    }

    public getGroup(groupId: string) {
        return this.apiClient.getUseCaseFactory().Group().GetGroup(groupId, ['totalUsersInGroup', 'creatorUser']);
    }

    public changeGroupName(groupId: string, userId: string, groupName: string) {
        return this.apiClient.getUseCaseFactory().Group().SetGroupName(
            groupId,
            userId,
            groupName
        );
    }

    public deleteGroup(group: Group) {
        this.segmentService.segmentAddressBookTracking().startDeleteGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            group
        );
        this.alertService.addressBook()
            .deleteGroupAlert(group.name)
            .then((result) => {
                if (result.value) {
                    this.segmentService.segmentAddressBookTracking().startDeleteGroup(
                        this.eventFarmService.currentEvent,
                        this.eventFarmService.currentUser,
                        group
                    );
                    this.apiClient.getUseCaseFactory()
                        .Group()
                        .DeleteGroup(group.id)
                        .toPromise()
                        .then(() => {
                            if (this.addressBookService.selectedGroup && this.addressBookService.selectedGroup.id === group.id) {
                                this.addressBookService.selectedGroup = null;
                                this.message.success('Group deleted');
                                this.addressBookUserService.broadcastAllUsers();
                            }
                            this.broadcastGroupsForUser(
                                this.eventFarmService.currentUser.id
                            );
                        });
                }
            });
    }

}

export interface Pagination {
    currentGroupsPage: number;
    itemsPerPage: number;
}

export interface GroupListOptions {
    selectedGroup?: Group;
    query: string;
    pagination: Pagination;
}
