import { Injectable, OnDestroy } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { shareReplay } from 'rxjs/operators';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { Module } from './Modules/Models/module-models';
import { AlertService } from '../../../eventFarmAlert.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';

export const EFXSMSSETTING = 'EFXSMSSETTING';
export const EFXSMSARRIVALSETTING = 'EFXSMSARRIVALSETTING';
export const EFXNFCSETTING = 'EFXNFCSETTING';

@Injectable()
export class EFXAdminService implements OnDestroy {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private store: Store<fromStore.AppState>,
        private segmentService: SegmentService,
        private alertService: AlertService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
    ) {
    }

    private smsArrivalEnabled: boolean = false;
    private nfcEnabled: boolean = false;
    public readonly smsGreeting$ = new BehaviorSubject<string>('');
    public readonly adminPin$ = new BehaviorSubject<string>('----');

    public readonly enabledModules$ = new BehaviorSubject<Module[]>([]);
    public readonly availableModules$ = new BehaviorSubject<Module[]>([]);
    public isLoadingModules: boolean = false;

    public eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());

    ngOnDestroy() {
    }
    
    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    private get eventId(): string {
        return this.eventFarmService.currentEvent.id;
    }

    get betaEnabledForPool(): boolean
    {
        return this.eventFarmService.betaEnabledForPool;
    }

    get isEFXEnabled(): boolean {
        return this.eventFarmService.currentEvent.isEFxEnabled;
    }

    public clearList() {
        this.smsGreeting$.next('');
        this.adminPin$.next('----');
        this.enabledModules$.next([]);
        this.availableModules$.next([]);
    }

    get isSMSArrivalEnabled(): boolean {
        return this.smsArrivalEnabled;
    }

    get isNFCEnabled(): boolean {
        return this.nfcEnabled;
    }

    get listCount(): number {
        return this.enabledModules$.value.length;
    }

    private getAdminConfigForEvent() {
        if (this.isEFXEnabled) {
            this.apiClient.getUseCaseFactory().EFx().GetAdminConfigForEvent(this.eventId).toPromise().then( (res) => {
                const { adminUnlockPin, isNfcEnabled, isSmsEnabledForEvent, smsMessage } = res.data.attributes;
                this.nfcEnabled = isNfcEnabled;
                this.smsArrivalEnabled = isSmsEnabledForEvent;
                this.smsGreeting$.next(smsMessage);
                this.adminPin$.next(adminUnlockPin !== null ? adminUnlockPin : '1111');
            }).catch(err => {
                this.message.error('Please refresh page');
            });
        }
    }

    private getModulesForEvent() {
        this.isLoadingModules = true;
        this.apiClient.getUseCaseFactory().EFx().GetAllModulesForEvent(this.eventId, true).toPromise().then( (res) => {
            const allModules: Module[] = Object.values(res.data.attributes).map(m => new Module(m));
            const removeMalformed: Module[] = allModules.filter(m => m.name !== undefined);
            this.availableModules$.next(removeMalformed.filter(m => !m.isActive).filter(a => !a.isAdminOnly));
            this.enabledModules$.next(removeMalformed.filter(m => m.isActive).filter(gi => gi.slug !== 'guest-info'));
            this.isLoadingModules = false;
        }).catch(err => {
            this.message.error('Please refresh page');
            this.isLoadingModules = false;
        });
    }

    public toggleEFXContactSetting(setting: string, value: boolean ) {
        switch (setting) {
            case (EFXSMSARRIVALSETTING):
                if (value) {
                    this.apiClient.getUseCaseFactory().EFx().EnableSMSForEvent(this.eventId).toPromise().then( (res) => {
                        this.message.success('SMS enabled for event');
                        this.segmentService.segmentEfxTracking().efxSmsArrivalEnabled();
                        this.smsArrivalEnabled = value;
                        const { smsMessage } = res.data.command;
                        this.smsGreeting$.next(smsMessage);
                    }).catch(err => {
                        this.message.error('Please try again');
                      });
                } else {
                    this.apiClient.getUseCaseFactory().EFx().DisableSMSForEvent(this.eventId).toPromise().then( (res) => {
                        this.message.success('SMS disabled for event');
                        this.segmentService.segmentEfxTracking().efxSmsArrivalDisabled();
                        this.smsArrivalEnabled = value;
                        this.smsGreeting$.next('');
                    }).catch(err => {
                        this.message.error('Please try again');
                      });
                }
                break;
            case (EFXNFCSETTING):
                if (value) {
                    this.apiClient.getUseCaseFactory().EFx().EnableNFCForEvent(this.eventId).toPromise().then( (res) => {
                        this.message.success('NFC enabled for event');
                        this.segmentService.segmentEfxTracking().efxNFCEnabled();
                        this.nfcEnabled = value;
                    }).catch(err => {
                        this.message.error('Please try again');
                      });
                } else {
                    this.apiClient.getUseCaseFactory().EFx().DisableNFCForEvent(this.eventId).toPromise().then( (res) => {
                        this.message.success('NFC disabled for event');
                        this.segmentService.segmentEfxTracking().efxNFCDisabled();
                        this.nfcEnabled = value;
                    }).catch(err => {
                        this.message.error('Please try again');
                      });
                }
                break;
            default:
        }
    }

    public sendDownloadLink(phoneNumber: string ) {
        this.apiClient.getUseCaseFactory().EFx().SendSMSWithAppLink(phoneNumber).toPromise().then( (res) => {
            this.message.success('Message Sent');
            this.segmentService.segmentEfxTracking().efxAppLinkText(phoneNumber);
        }).catch(err => {
            this.message.error('Please try again');
          });
    }

    public setAdminCode() {
        const adminCode = this.adminPin$.value;
        this.apiClient.getUseCaseFactory().EFx().SetAdminPinForEvent(this.eventId, adminCode).toPromise().then( (res) => {
            this.message.success('Admin code set');
            this.segmentService.segmentEfxTracking().efxUpdatedAdminPasscode(adminCode);
           // this.adminPin = adminCode;
        }).catch(err => {
            this.message.error('Please try again');
          });
    }

    public setGreetingText() {
        const greetingText: string = this.smsGreeting$.value;
        if (!greetingText.length) {
            this.toggleEFXContactSetting(EFXSMSARRIVALSETTING, false);
        } else {
            this.apiClient.getUseCaseFactory().EFx().SetSMSForEvent(this.eventId, greetingText).toPromise().then( (res) => {
                this.message.success('Arrival message set');
                this.segmentService.segmentEfxTracking().efxSmsArrivalMessageSet(greetingText);
            }).catch(err => {
                this.message.error('Please try again');
              });
        }

    }

    public toUrlFromButton(url: string) {
        if (url === 'https://eventfarm.com/platform/efx') {
            this.segmentService.segmentEfxTracking().efxLearnMoreClicked();
            window.open(url, '_blank');
        } else if (url === 'request') {
            const userId: string = this.eventFarmService.currentUser.id;
            this.apiClient.getUseCaseFactory().EFx().RequestForEvent(this.eventId, userId).toPromise().then( (res) => {
                this.alertService.general().efxRequest();
                this.segmentService.segmentEfxTracking().efxRequested();
            }).catch(err => {
                this.message.error('Please try again');
              });
        }
    }

    public requestModule(slug: string, name: string) {
        const userId: string = this.eventFarmService.currentUser.id;
        this.apiClient.getUseCaseFactory().EFx().RequestForEvent(this.eventId, userId, [slug]).toPromise().then( (res) => {
            this.alertService.general().efxModuleRequest(name);
            this.segmentService.segmentEfxTracking().efxModuleRequested(slug);
        }).catch(err => {
            this.message.error('Please try again');
        });
    }

    public configModule(slug: string, name: string) {
        this.router.navigateByUrl(this.routeGenerator.url('events.efx.module.config', { moduleId: slug, poolId: this.eventFarmService.currentTeam.id,
            eventId: this.eventFarmService.currentEvent.id, }));

    }

    public enteredAdminPage() {
        this.segmentService.segmentSideNavTracking().efxAdminPageEntered();
        this.getAdminConfigForEvent();
        this.getModulesForEvent();
    }
}
