import { RouteGeneratorService } from './../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../ApiClient/Models/User/user';
import { EventFarmService } from '../../eventFarm.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { Observable ,  Subscription } from 'rxjs';
import { map, filter, tap, shareReplay } from 'rxjs/operators';
import * as Sentry from "@sentry/angular-ivy";

@Component({
    selector: 'admin-nav',
    template: require('./admin-nav.html'),
    styles: [require('./admin-nav.scss')],

})

export class AdminNavComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private segmentService: SegmentService,
        private store: Store<fromRoot.AppState>,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
    ) {
        this.userUpdate$ = this.userStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.user = val.data;
            const first = this.user.name && this.user.name.firstName ? this.user.name.firstName.slice(0, 1) : '';
            const last = this.user.name && this.user.name.lastName ? this.user.name.lastName.slice(0, 1) : '';
            this.userInitials = first + last;
        });
    }

    private user: User;
    private ghosting: boolean = false;
    private userInitials: string;
    private eventStore$ = this.store.select(fromRoot.getCurrentEvent).pipe(shareReplay());
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userUpdate$: Subscription;

    ngOnInit() {
        this.ghosting = document.cookie.match('(^|;)\\s*' + 'EF_GHOST_ACCESS_TOKEN' + '\\s*=\\s*([^;]+)') ? true : false;
    }

    changeTeam(id) {
        this.eventFarmService.eventListOptions.pagination.currentEventsPage = 1;
        this.eventFarmService.eventListOptions.tags = null;
        this.eventFarmService.eventListOptions.sortBy = 'event-start';
        this.eventFarmService.eventListOptions.sortDirection = 'ascending';
        this.eventFarmService.eventListOptions.query = null;
        this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', { poolId: id }));
        this.store.select(fromRoot.selectTeam(id)).subscribe(team => {
            this.store.dispatch(new fromRoot.SetCurrentTeam(team));
            this.segmentService.segmentPoolTracking().changeTeam(
                team,
                this.eventFarmService.currentUser,
            );
        });
    }

    ngOnDestroy() {
        this.userUpdate$.unsubscribe();
    }
}
