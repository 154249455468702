import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ColorPickerModule } from 'ngx-color-picker';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead'
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { CreateEditEmailComponent } from './CreateEditEmail/create-edit-email.component';
import { SendTestComponent } from './CreateEditEmail/send-test.component';
import { ConfirmDeactivateGuard } from './email-design-center-guard.service';
import { EmailDesignCenterComponent } from './email-design-center.component';
import { EmailDesignCenterRoutingModule } from './email-design-center.routing.module';
import { GuestMessagingComponent } from './GuestMessaging/guest-messaging.component';
import { SlugPipe } from './pipes/RequiredSlug.pipe';
import { FroalaService } from './froala.service';
import { GuestMessagingGroupSearchComponent } from './GuestMessaging/guest-messaging-group-search.component';
import { GuestMessagingSendOptionsComponent } from './GuestMessaging/guest-messaging-send-options.component';
import { GuestMessagingSendOptionsService } from './GuestMessaging/guest-messaging-send-options.service';
import { GuestMessagingService } from './GuestMessaging/guest-messaging.service';
import { ScheduledCreateComponent } from './Scheduled/Create/create.component';
import { ScheduledComponent } from './Scheduled/scheduled.component';
import { OptionItemComponent } from './SelectDesignTemplate/OptionItem/option-item.component';
import { SelectDesignTemplateComponent } from './SelectDesignTemplate/select-design-template.component';
import { TableEditorComponent } from './TableEditor/table-editor.component';
import { UploadImageComponent } from './UploadImage/upload-image.component';
import { ButtonEditorComponent } from './ButtonEditor/button-editor.component';
import { GroupTypeaheadModule } from '../../CoreUI/Typeahead/group-typeahead.module';
import { EventTypeaheadModule } from '../../CoreUI/Typeahead/event-typeahead.module';
import { VirbelaSlugPipe } from './pipes/VirbelaSlug.pipe';
import { WebConferenceSlugPipe } from './pipes/WebConferenceSlug.pipe';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { EventAgendaSlugPipe } from './pipes/EventAgendaSlug.pipe';
import { SessionSummarySlugPipe } from './pipes/SessionSummary.pipe';

@NgModule({
    bootstrap: [EmailDesignCenterComponent],
    declarations: [
        EmailDesignCenterComponent,
        CreateEditEmailComponent,
        UploadImageComponent,
        TableEditorComponent,
        SendTestComponent,
        ButtonEditorComponent,
        GuestMessagingComponent,
        GuestMessagingSendOptionsComponent,
        GuestMessagingGroupSearchComponent,
        OptionItemComponent,
        ScheduledComponent,
        ScheduledCreateComponent,
        SelectDesignTemplateComponent,
        SlugPipe,
        VirbelaSlugPipe,
        WebConferenceSlugPipe,
        EventAgendaSlugPipe,
        SessionSummarySlugPipe
    ],
    entryComponents: [
        EmailDesignCenterComponent,
        ScheduledCreateComponent,
        CreateEditEmailComponent,
        SendTestComponent,
        UploadImageComponent,
        TableEditorComponent,
        ButtonEditorComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BreadcrumbsModule,
        UtilitiesModule,
        NzUtilitiesModule,
        ModalModule,
        TypeaheadModule,
        GroupTypeaheadModule,
        NgxChartsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        ColorPickerModule,
        PopoverModule,
        LoadingOverlayModule,
        EmailDesignCenterRoutingModule,
        EventTypeaheadModule
    ],
    providers: [
        ConfirmDeactivateGuard,
        FroalaService,
        GuestMessagingService,
        GuestMessagingSendOptionsService,
    ],
})

export class EmailDesignCenterModule {
}
