import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { EmailDesign } from '../../../../../Shared/EmailDesign/email-design';
import { EventFarmService } from '../../../../eventFarm.service';
import { EmailDesignCenterService } from '../../email-design-center.service';

@Injectable()

export class DesignsService {
    public loaded: boolean = false;
    public validDesigns = [];

    constructor(
        private emailDesignCenterService: EmailDesignCenterService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
    ) { }

    public listDesigns() {
        return this.apiClient.getUseCaseFactory().EmailDesign()
        .ListEmailDesignsByEvent(this.emailDesignCenterService.event.id, 1, 500)
        .subscribe(
            (data) => this.processDesigns(data.data),
            (error) => this.validDesigns = [],
            (completed) => this.loaded = true,
        );
    }

    private processDesigns(data) {
        this.validDesigns = data.filter(
            (design) => design.relationships.emailDesignType.id === 'SendAMessage',
        ).map((design) => EmailDesign.fromApi(design));
    }

    get eventTimeZone(): string {
        return this.eventFarmService.currentEvent.timezone;
    }

    public create(data) {
        return this.apiClient.getUseCaseFactory().EmailMessage()
        .CreateScheduledBatchEmailMessage(
            this.emailDesignCenterService.event.id,
            this.eventFarmService.currentUser.id,
            data.designId,
            'status',
            data.targets,
            data.time,
            data.timezone,
        );
    }
}
