import { Component, Input, OnInit, ViewEncapsulation  } from '@angular/core';
import { FormattedDateTime } from '../../../../ApiClient/Models/formatted-datetime';

const moment = require('moment');

@Component({
    selector: 'list-view-date',
    template: require('./listViewDate.html'),
    styles: [require('./listViewDate.scss'), require('../events-list.scss')],
    encapsulation: ViewEncapsulation.None
})

export class ListViewDateComponent {
    @Input() eventStartTime: FormattedDateTime;
    @Input() eventEndTime: FormattedDateTime;

    public startDay;
    public endDay;
    public startMonth;
    public endMonth;
    public startYear;
    public endYear;
    public startTime;
    public endTime;

    public sameDay;
    public currentStartYear;
    public currentEndYear;

    constructor() {
    }

    ngOnInit() {
        const start = moment.unix(this.eventStartTime.timestamp);

        this.sameDay = start.isSame(moment.unix(this.eventEndTime.timestamp), 'day');
        this.currentStartYear = moment(new Date()).isSame(moment.unix(this.eventStartTime.timestamp), 'year');
        this.currentEndYear = moment(new Date()).isSame(moment.unix(this.eventEndTime.timestamp), 'year');

        this.startDay = moment.unix(this.eventStartTime.timestamp).format('ddd');
        this.endDay = moment.unix(this.eventEndTime.timestamp).format('ddd');

        this.startMonth = moment.unix(this.eventStartTime.timestamp).format('MMM D');
        this.endMonth = moment.unix(this.eventEndTime.timestamp).format('MMM D');

        this.startYear = moment.unix(this.eventStartTime.timestamp).format('YYYY');
        this.endYear = moment.unix(this.eventEndTime.timestamp).format('YYYY');

        this.startTime = this.eventStartTime.time.short;
        this.endTime = this.eventEndTime.time.short;
    }
}
