import { Component, OnInit } from '@angular/core';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { PaymentsPromotionsService } from '../payments-promotions.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';
import { AlertService } from '../../../eventFarmAlert.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { EventFarmService } from '../../../eventFarm.service';
import { Promo } from '../../../../ApiClient/Models/Promotion/promo';


@Component({
    selector: 'promo-list',
    template: require('./promo-list.html'),
    styles: [require('./promo-list.scss')]
})
export class PromoListComponent implements OnInit {
    constructor(
        private iconService: IconService,
        private paymentsPromosService: PaymentsPromotionsService,
        private store: Store<fromRoot.AppState>,
        private alertService: AlertService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private eventFarmService: EventFarmService
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit(): void {

        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentTeam.id;


        this.paymentsPromosService.fetchAccessTypesForPromotions();

        if (this.paymentsPromosService.promoAccessTypes.length === 0) {
            this.alertService.guestList().noTicketTypesSet('adding promo codes')
            .then((res) => {
                if (res.value) {
                    this.router.navigateByUrl(this.routeGenerator.url('events.event-access', { poolId, eventId }));
                }
            });
        } else {
            this.paymentsPromosService.getPromotions();
        }
    }


    public changePage(page) {
        this.paymentsPromosService.promosListOptions.pagination.currentPromosPage = page;
        this.paymentsPromosService.getPromotions();
      }

}
