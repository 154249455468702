import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject, timer} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VersionMonitorService {

    public $versionIsUpToDateSubject = new BehaviorSubject<boolean>(true);
    public $loadedVersion = new BehaviorSubject<string>(null);

    public loadedVersion: string;

    constructor() {
        // Subscribes to loaded version being changed
        this.$loadedVersion.subscribe(val => {
            if (this.loadedVersion && val !== this.loadedVersion) {
                // Flag that current version is out of date
                this.$versionIsUpToDateSubject.next(false);
            }
            // Update the loaded version
            this.loadedVersion = val;

        });
    }


}
