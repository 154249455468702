import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Communication, InvitationStatusValue, SendByValue} from '../../../../../ApiClient/Models/Communication';
import {EventFarmService} from '../../../../eventFarm.service';
import {SMSCreateService} from '../sms-create.service';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'sms-create-review',
    template: require('./sms-create-review.html'),
    styles: [require('./sms-create-review.scss')]
})

export class SMSCreateReviewComponent implements OnInit, OnDestroy {
    @Output() onValidityChange = new EventEmitter();

    smsRecipientsForm: any;
    smsRecipientsFormSub$: Subscription;

    constructor(
        private eventFarmService: EventFarmService,
        private formBuilder: FormBuilder,
        private smsCreateService: SMSCreateService

    ) {}

     sendByOptions = Communication.sendByOptions;
     invitationStatusOptions = Communication.invitationStatusTypeOptions;
     ticketTypes = this.eventFarmService.currentEvent.ticketTypes;

    ngOnInit(): void {
    }

    get invitationsLabel(): string {
        const invitationNames = this.smsCreateService.recipientConfig.invitations.map(invitation => {
           return invitation.label;
        });
        return invitationNames.join(', ');
    }

    get invitationStatusLabel(): string {
         const statusNames = this.smsCreateService.recipientConfig.invitationStatus.map(status => {
           return Communication.getStatusTypeLabelFromValue(status);
        });
         return statusNames.join(', ');
    }

    get accessTypesLabel(): string {
        const accessTypes = this.smsCreateService.recipientConfig.ticketTypes.map(ticketType => {
            return ticketType.name
        });
        return accessTypes.join(', ');
    }


    get ticketBlockLabel(): string {
        return this.smsCreateService.recipientConfig.ticketBlock?.label;
    }

    get groupLabel(): string {
        return this.smsCreateService.recipientConfig.group?.label;
    }

    get whatsAppLabel(): string {
         return this.smsCreateService.recipientConfig.whatsAppEnabled ? 'Yes' : 'No';
    }

    get sendByLabel(): string {
        return Communication.getSendByLabelFromValue(this.smsCreateService.recipientConfig.sendBy)
    }

    // Empty data set so we can still use ng-form

    ngOnDestroy(): void {
         // this.smsRecipientsFormSub$.unsubscribe();
    }
}
