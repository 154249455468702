import { Component, OnDestroy, OnInit } from '@angular/core';
import { EFXAdminService } from '../../efx-admin.service';

const adminRegex = /^\d{4}$/;

@Component({
  selector: 'efx-passcode',
  template: require('./efx-passcode.html'),
  styles: [require('./efx-passcode.scss'), require('../../efx-admin.scss')],

})

export class EFXPasscodeComponent implements OnDestroy {

  constructor(
    private efxAdminService: EFXAdminService
  ) {
  }

  private isCodeValid: boolean = true;
  private isEditingCode: boolean = false;

  validateAdmin(value: string) {
    if (isNaN(Number(value))) {
      this.isCodeValid = false;
      return false;
    }

    this.efxAdminService.adminPin$.next(value);
    const adminCode = this.efxAdminService.adminPin$.value;

    if (adminRegex.test(adminCode)) {
      this.isCodeValid = true;
    } else {
      this.isCodeValid = false;
    }
  }

  saveAdminCode() {
    this.efxAdminService.setAdminCode();
    this.isEditingCode = false;
  }

  ngOnDestroy() {
  }
}
