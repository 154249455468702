import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { QuestionCenterService } from './question-center.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { shareReplay } from 'rxjs/operators';


@Component({
    selector: 'question-center',
    template: require('./question-center.html'),
    styles: [require('./question-center.scss')],
})

export class QuestionCenterComponent implements OnInit, OnDestroy {

    constructor(
        private segmentService: SegmentService,
        private questionCenterService: QuestionCenterService,
        private store: Store<fromRoot.AppState>,
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().registrationQuestionsEntered();
    }

    ngOnDestroy() {
    }
}
