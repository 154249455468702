import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../eventFarm.service';
import { AlertService } from '../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Stack } from '../../ApiClient/Models/Stack/stack';
import { EfEvent } from '../../ApiClient/Models/Event/event';
import { getEventUrl } from '../environment';
import { Team } from '../../ApiClient/Models/Pool/team';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import { PoolFeature } from '../../ApiClient/Models/PoolFeature/poolFeature';
import { LocalStorageService } from '../../_services/storage/local-storage.service';
import { TeamAllotmentService } from './Manage/ManageTeam/TeamAllotments/team-allotment.service';
import {shareReplay} from 'rxjs/operators';
import * as fromRoot from '../store';
import {Store} from '@ngrx/store';
import * as fromStore from '../store';
import * as adminFeatureActions from '../store/actions/admin/admin-feature.action';
@Injectable()
export class AdminService {
  private _features: PoolFeature[];
  public poolLoading$ = new BehaviorSubject<any>(false);
  public tabLoading$ = new BehaviorSubject<any>(false);
  public features$ =  new ReplaySubject(3)

  teams$ = new BehaviorSubject<Team[]>([]);

  constructor(
      private store: Store<fromStore.AppState>,
      private apiClient: EventFarmAPIClient,
      private eventFarmService: EventFarmService,
      private alertService: AlertService,
      private messageService: NzMessageService,
      private teamAllotmentService: TeamAllotmentService,
      private localStorageService: LocalStorageService
  ) {}

  public currentTeam$ = this.store.select(fromRoot.getAdminCurrentTeam).pipe(shareReplay());

  public currentTeamEvents$ = this.store.select(fromRoot.getAdminCurrentTeamEvents).pipe(shareReplay());
  public currentTeamEventsData$ = this.store.select(fromRoot.getAdminCurrentTeamEventsData).pipe(shareReplay());

  public currentTeamEvent$ = new BehaviorSubject<EfEvent>(null);
  public resending: boolean = false;

  get currentStacks(): Stack[] {
    return this.eventFarmService.currentEvent.stacks;
  }

  get features(): PoolFeature[] {
    return this._features;
  }

  get currentEvent(): EfEvent {
    return this.eventFarmService.currentEvent;
  }

  private eventUrl() {
    return getEventUrl(this.eventFarmService.currentEvent);
  }

  public fetchAllFeatures(): void {
    this.apiClient
      .getUseCaseFactory()
      .Feature()
      .ListFeatures()
      .subscribe((res) => {
        const features = [];
        for (const feature of res.data) {
          features.push(PoolFeature.fromApiResponse(feature));
        }
        this.store.dispatch(new adminFeatureActions.FetchFeaturesForAdminSuccess(features));
      });
  }

  public removeGhostingAsHeader() {
    // Remove ghost head from local storage
    // Reset effective userId with actual
    this.localStorageService.set('X-EF-GhostTech', undefined);
    this.localStorageService.set('effectiveEfUserId', this.localStorageService.get('efUserId'));
  }

  public setGhostingAsEmail(email: string) {
    this.localStorageService.set('X-EF-GhostTech', email);
  }

  public async fetchEvent(eventId: string): Promise<any> {
    const res = await this.apiClient
      .getUseCaseFactory()
      .Event()
      .GetEvent(eventId)
      .toPromise();
    const event = EfEvent.fromApiResponse(res.data);
    // TODO work on clearing this when switching teams
    this.currentTeamEvent$.next(event);
  }
  public async fetchUserById(userId: string) {
    return await this.apiClient
        .getUseCaseFactory()
        .User()
        .GetUser(userId, [ 'UserIdentifier', 'isEFAdmin'])
        .toPromise();
  }

  public fetchPoolById(poolId: string) {
    return  this.apiClient
      .getUseCaseFactory()
      .Pool()
      .GetPool(poolId);
  }

  public async setNameForPool(poolId, name) {
    try {
      await this.apiClient
        .getUseCaseFactory()
        .Pool()
        .SetNameForPool(poolId, name)
        .toPromise();
      this.messageService.success('Name set for pool');
    } catch (error) {
      this.messageService.error('Error setting name for pool');
    }
  }

  public async fetchEventsByPoolId(poolId: string) {
    const pool = await this.apiClient
        .getUseCaseFactory()
        .Event()
        .ListEventsForPool(poolId, null, null, null, null, null, 1, 5);
    return Team.fromApiResponse(pool.data);
  }

  public fetchEventsByPool(poolId: string, currentPage: number, itemsPerPage: number, name?: string) {
    return this.apiClient
        .getUseCaseFactory()
        .Event()
        .ListEventsForPool(poolId, name, null, null, null, null, currentPage, itemsPerPage, 'event-start', 'descending');
  }

  public async generateSiteTemplates() {
    return this.apiClient
      .getUseCaseFactory()
      .SitePage()
      .GenerateSitePageTemplates()
      .toPromise();
  }

  public async populateEchoWorlds() {
    return this.apiClient
      .getUseCaseFactory()
      .VirbelaWorld()
      .PopulateVirbelaWorldsFromVirbela()
      .toPromise();
  }

  public async removeExhibitorsAndLeadsForEvent(eventId: string) {
    return this.apiClient
        .getUseCaseFactory()
        .Exhibitor()
        .DeleteAllExhibitorsAndLeadsForEvent(eventId)
        .toPromise();
  }
}
