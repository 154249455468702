import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EventFarmService } from '../../eventFarm.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { TicketBlock } from '../../../ApiClient/Models/Stack/ticket-block';
import { AlertService } from '../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { IconService } from '../Icons/icons.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { TicketBlockListService } from './ticket-block-list.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
@Component({
    selector: 'ticket-block-list',
    template: require('./ticket-block-list.html'),
    styles: [require('./ticket-block-list.scss')],
})

export class TicketBlockListComponent implements OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private routeGenerator: RouteGeneratorService,
        private alertService: AlertService,
        private iconService: IconService,
        private message: NzMessageService,
        private router: Router,
        private apiClient: EventFarmAPIClient,
        private ticketBlockListService: TicketBlockListService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.resetListOptions();
            this.openedTicketBlocks = [];
            this.loadTicketBlocks();
            this.loadSessions();
        });
    }

    private openedTicketBlocks: TicketBlock[];
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;
    private isATicketBlockBeingEdited: boolean;


    loadTicketBlocks() {
        this.openedTicketBlocks = [];
        this.ticketBlockListService.getTicketBlocks();
    }

    async loadSessions() {
        await this.ticketBlockListService.listSessionsForParentEvent(this.eventFarmService.currentEvent.id);
    }

    resetListOptions() {
        this.isATicketBlockBeingEdited = false;
        this.ticketBlockListService.reset();
    }

    changePage(page) {
        this.ticketBlockListService.ticketBlockListOptions.pagination.currentPage = page;
        this.ticketBlockListService.getTicketBlocks();
    }

    ticketBlockEditing($event) {
        this.isATicketBlockBeingEdited = $event;
    }

    openTicketBlockListItem(ticketBlock: TicketBlock) {
        this.openedTicketBlocks.push(ticketBlock);
    }

    async edit(ticketBlock: TicketBlock) {
        await this.alertService.ticketBlock().updateTicketBlockName(ticketBlock);
        await this.ticketBlockListService.getTicketBlocks();
    }

    checkIfTicketBlockIsOpen(ticketBlock: TicketBlock) {
        return this.openedTicketBlocks.filter(eachTicketBlock => eachTicketBlock.id === ticketBlock.id).length;
    }

    generateTbUrl(ticketBlockId: string) {
        const url = this.routeGenerator.url('ticketblocks.list', { eventId: this.eventFarmService.currentEvent.id });

        this.router.navigate([url], { queryParams: { 'ticketBlockId': ticketBlockId } });
    }

    private async createNewTicketBlock() {
        await this.ticketBlockListService.createNewTicketBlock();
    }

    private async removeTicketBlock(ticketBlock: TicketBlock) {
        await this.ticketBlockListService.removeTicketBlock(ticketBlock);
    }

    ticketBlockChanged($e) {
        if ($e.action === 'Allotment change') {
            this.loadTicketBlocks();
        }
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
        this.resetListOptions();
    }
}
