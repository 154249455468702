import { Injectable } from '@angular/core';
import { UploaderService } from '../uploader.service';
import { ApiError, ErrorInterface } from '../../../../ApiClient/Models/error';
import { QueueCommand } from '../../../../ApiClient/Models/Queue/queue-command';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Router } from '@angular/router';
import { UploaderTabsService } from '../uploaderTabs.service';
import { Subject, Observable, Operator ,  interval ,  Subscription } from 'rxjs';
import { UserImport } from '../../../../ApiClient/Models/Import/user-import';
import { UserImportValidator } from '../user-import-validator';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { EventFarmService } from '../../../eventFarm.service';

@Injectable()
export class ProcessingService {

    public progressDataSource = new Subject<any>();
    public progressData$ = this.progressDataSource.asObservable();
    public hasError: boolean = false;
    public errors: ApiError[];
    public userImportValidator: UserImportValidator;
    public currentQueueCommand: QueueCommand;
    public currentState: 'pre'|'post' = 'pre';
    public pollingTask: Subscription;

    constructor(
        private apiClient: EventFarmAPIClient,
        private uploaderService: UploaderService,
        private eventFarmService: EventFarmService,
        private uploaderTabsService: UploaderTabsService,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    startPolling(
        queueCommandId: string,
        userImportId: string,
        onPreProcessSuccess?: (userImportId: string) => void,
        onPostProcessSuccess?: (userImportId: string) => void
    ) {
     this.unsetErrorState();
     this.pollingTask = interval(2000).pipe(mergeMap(() => this.fetchQueueCommandProgress(queueCommandId)))
            .subscribe(
            (val) => {
                this.currentQueueCommand = QueueCommand.fromApiResponse(val['data']);
                this.progressDataSource.next(this.currentQueueCommand);
                if (this.currentQueueCommand.isFinished && this.currentQueueCommand.isSuccess) {
                    this.pollingTask.unsubscribe();
                    if (this.currentQueueCommand.name.includes('Pre-Process')) {
                        this.goToPreview(userImportId, onPreProcessSuccess);
                    }
                    if (this.currentQueueCommand.name.includes('Post-Process')) {
                        this.goToFinish(userImportId, onPostProcessSuccess);
                    }
                }
                if (this.currentQueueCommand.isSuccess === false) {
                    this.pollingTask.unsubscribe();
                    this.setErrorState();
                    let errors = val['data']['relationships'].errors.map((error) => {
                        return {
                            meta: error.attributes.meta,
                            title: error.attributes.title,
                            detail: error.attributes.message,
                        };
                    });
                    if (!errors.length) {
                        errors = [{
                            meta: '',
                            title: 'Error Occurred',
                            detail: 'Invalid Response Received',
                        }];
                    }
                    this.errors = ApiError.fromApiErrorArray(errors);
                }
            },
            (error) => {
                this.setErrorState();
            }
        );
    }

    goToPreview(userImportId: string, onPreProcessSuccess?) {
        const poolId = this.eventFarmService.currentTeam.id;
        const eventId = this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id ? this.eventFarmService.currentEvent.id : null;
        this.unsetErrorState();

        this.uploaderService.fetchUserImport(userImportId)
            .subscribe(
                (val) => {
                    this.uploaderService.userImport = UserImport.fromApiResponse(val.data);

                    if (this.uploaderService.isEventContext && !this.uploaderService.userImport.totalRecordsWithTicketData) {
                        this.uploaderService.hasInvitations = false;
                    }

                    if (this.uploaderService.isEventContext && this.uploaderService.userImport.totalRecordsWithTicketData) {
                        this.uploaderService.hasInvitations = true;
                    }

                    const userImportId = this.uploaderService.userImport.id;

                    this.userImportValidator = new UserImportValidator(this.uploaderService.userImport);
                    this.userImportValidator.validate();

                    if (this.userImportValidator.isAccepted) {
                        setTimeout(() => {
                            if (onPreProcessSuccess) {
                                onPreProcessSuccess(userImportId);
                            } else {
                                if (eventId) {
                                    this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.preview', {
                                        poolId,
                                        eventId,
                                        userImportId
                                    }));
                                } else {
                                    this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.preview', {
                                        poolId,
                                        userImportId
                                    }));
                                }
                                this.uploaderTabsService.changeTabName(1, 'Preview');
                            }
                        }, 1000);
                    } else {
                        this.setErrorState();
                    }

                },
                (error) => {
                    this.setErrorState();
                }
            );
    }

    goToFinish(userImportId: string, onPostProcessSuccess?: (userImportId: string) => void) {
        const poolId = this.eventFarmService.currentTeam.id;
        const eventId = this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id ? this.eventFarmService.currentEvent.id : null;

        this.unsetErrorState();

        this.uploaderService.fetchUserImport(userImportId)
            .subscribe(
                (val) => {
                    this.uploaderService.userImport = UserImport.fromApiResponse(val.data);

                    if (this.uploaderService.userImport.totalGoodRecords === 0) {
                        this.setErrorState();
                    } else {
                        setTimeout(() => {
                            if (onPostProcessSuccess) {
                                onPostProcessSuccess(userImportId);
                            } else {
                                if (eventId) {
                                    this.eventFarmService.updateEventDetails(this.eventFarmService.currentEvent.id);
                                    this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.finish', { poolId, eventId, userImportId }));
                                } else {
                                    this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.finish', { poolId, userImportId }));
                                }
                                this.uploaderTabsService.changeTabName(2, 'Finish');
                            }
                        }, 1000);
                    }
                },
                (error) => {
                    this.setErrorState();
                }
            );
    }

    fetchQueueCommandProgress(queueCommandId: string) {
        return this.apiClient.getUseCaseFactory()
            .Queue()
            .GetQueueCommand(
                queueCommandId,
                ['QueueCommandMessages', 'QueueCommandErrors']
            );
    }

    setErrorState() {
        this.hasError = true;
        this.uploaderTabsService.reset();
        this.uploaderTabsService.setNextAsActive();
        this.uploaderTabsService.setCurrentAsError();
        this.uploaderTabsService.changeTabName(1, 'File Error');
    }

    unsetErrorState() {
        this.hasError = false;
        this.errors = null;
        this.userImportValidator = null;
        this.uploaderTabsService.unsetCurrentAsError();
    }

    retryUpload() {
        const poolId = this.eventFarmService.currentEvent.team.id;
        this.uploaderTabsService.reset();
        this.router.navigateByUrl(this.routeGenerator.url('pools.uploader', { poolId }));
    }
}
