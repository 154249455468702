import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {NzMessageService} from 'ng-zorro-antd/message';
import {BehaviorSubject, debounceTime, Observable, switchMap} from 'rxjs';
import {EventFarmAPIClient} from '../../../../../ApiClient/event-farm-api-client';
import {EventFarmService} from '../../../../eventFarm.service';
import {AlertService} from '../../../../eventFarmAlert.service';
import * as fromStore from '../../../../store';
import {AdminService} from '../../../admin.service';
import {AdminAlertService} from '../../../Alert/adminEventFarmAlert.service';
import {ManageAdminService} from '../manage-admin.service';

@Component({
    selector: 'upgrade-to-admin',
    template: require('./upgrade-to-admin.html'),
    styles: [require('./upgrade-to-admin.scss')],

})
export class UpgradeToAdminComponent implements OnInit {
    selectedValue = null;
    nzFilterOption = (): boolean => true;
    searchChange$ = new BehaviorSubject('');
    public listOfOptions: Array<{ value: string; text: string }> = [];

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private alertService: AlertService,
        private message: NzMessageService,
        private store: Store<fromStore.AppState>,
        private adminService: AdminService,
        private adminAlertService: AdminAlertService,
        private manageAdminService: ManageAdminService
    ) {

    }

    public async handleUserUpgrade(userId: any) {
        const res = await this.adminAlertService.general().upgradeUserConfirm(userId);
        if (res.value) {
            try {
                await this.manageAdminService.upgradeUser(userId).toPromise();
                await this.manageAdminService.fetchExistingAdmins();
                await this.adminAlertService.general().upgradeUserSuccess();
                this.selectedValue = null;

            } catch (error) {
            }
        }
    }

    handleSearchForUserByEmail(val) {
        this.searchChange$.next(val);
    }

    ngOnInit() {
        const optionList$: Observable<any> = this.searchChange$
            .asObservable()
            .pipe(debounceTime(500))
            .pipe(switchMap(this.manageAdminService.searchForUser));

        optionList$.subscribe(data => {
            if (data) {
                this.listOfOptions = [{value: data.id, text: data.primaryEmail}];
            }
        });
    }


}
