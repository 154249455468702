import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import { TicketBlock } from '../../ApiClient/Models/Stack/ticket-block';
import { TicketBlockTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/TicketBlock';

export class TicketBlockAlert extends AbstractBaseAlert {

    confirmTicketBlockUserDeletion(userName: string): Promise<any> {
        return this.alert({
            title: 'Remove User?',
            text: 'Are you sure you would like to remove user \"' + userName + '\" from being able to access this ticketBlock?',
            confirmButtonText: 'Remove User',
            showCancelButton: true,
            icon: 'question',
        });
    }

    confirmDeleteTicketBlock(ticketBlockName: string): Promise<any> {
        return this.alert({
            title: 'Ticket Blocks',
            text: 'Are you sure you want to delete Ticket Block `' + ticketBlockName + '`? All guests in this Ticket Block will be removed from your Guest List.',
            icon: 'question',
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: 'Delete Ticket Block',
            confirmButtonColor: '#ea2b38'
        });
    }

    addNewTicketBlock(eventId: string, type: TicketBlockTypeInterface): Promise<any> {
        return this.alert({
            title: 'Create Ticket Block',
            confirmButtonText: 'Yes, add ticket block',
            html:
                '<input id="name" class="form-control sa-input-spacing" placeholder="Ticket Block Name" required>',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            preConfirm: async () => {

                const name = (document.getElementById('name') as HTMLInputElement).value;

                if (!name.length) {
                    return swal.showValidationMessage(
                        `Please enter a proper name`);
                }
                try {
                    await this.apiClient.getUseCaseFactory().TicketBlock().CreateTicketBlock(eventId, name, false, null, type.slug).toPromise();
                } catch (e) {

                    if (e.status === 409) {
                        swal.showValidationMessage(
                            `Can't have two ticket blocks with the same name`
                        )
                    }
                }
            }
        });
    }

    updateTicketBlockName(ticketBlock: TicketBlock): Promise<any> {
        return this.alert({
            title: 'Update Name',
            confirmButtonText: 'Yes, update name',
            html:
                `<input id="name" class="form-control sa-input-spacing" placeholder="Ticket Block Name" value="${ticketBlock.name}" required>`,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            preConfirm: async () => {

                const name = (document.getElementById('name') as HTMLInputElement).value;

                if (!name.length) {
                    return swal.showValidationMessage(
                        `Please enter a proper name`);
                }

                await this.apiClient.getUseCaseFactory().TicketBlock().SetTicketBlockName(ticketBlock.id, name).toPromise();
            }
        });
    }
}
