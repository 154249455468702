import { Component, Input, OnInit } from '@angular/core';
import { Exhibitor } from '../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { shareReplay } from 'rxjs/operators';

@Component({
    selector: '[exhibitor-item]',
    template: require('./exhibitor-item.html'),
    styles: [require('./exhibitor-item.scss')],
})

export class ExhibitorItemComponent implements OnInit {
    @Input() exhibitor: Exhibitor;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private store: Store<fromRoot.AppState>
    ) { }

    ngOnInit() {
    }

    public checkIfUrlIsImage(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    }
}
