import { Component } from '@angular/core';

import { ngxChartsOptions } from '../../../../Shared/ngx-charts-options';

import { StatisticsService } from './statistics.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
  template: require('./statistics.html'),
  styles: [ require('./statistics.scss') ],
  providers: [ StatisticsService ],
})

export class StatisticsComponent {
    private ngxChartsOptions = ngxChartsOptions;

    constructor(
        private statisticsService: StatisticsService,
        private segmentService: SegmentService) { }

    ngOnInit() {
        this.statisticsService.getStats();
        this.statisticsService.getLastActionCounts();
        this.statisticsService.getOpenActionsForLastMonth();

        this.segmentService.segmentSideNavTracking().emailStatisticsEntered();

    }

    public formatLineChartDateTicks(val) {
        let label = '';
        let day = new Date(val).getDate();
        if ((day % 5) === 0) {
            label = val;
        }
        return label;
    }
}
