import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EFXComponent } from './efx.component';
import { EFXAdminComponent } from './Admin/efx-admin.component';
import { StationComponent } from './Station/efx-station.component';

const routes: Routes = [
    {
        path: '',
        component: EFXComponent,
        children: [
            {
                path: 'config',
                component: EFXAdminComponent,
            },
            {
                path: 'config/module-config/:moduleId',
                component: StationComponent,
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EFXRoutingModule {
}
