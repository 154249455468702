
export class UserField {
    slug: string;
    name: string;
    exists: string;
}

export class UserFields {
    public static fromApi(data) {
        return UserFields.all.filter((f) => data[f.exists]);
    }

    public static all = [
        {
            slug: 'title',
            name: 'Title',
            exists: 'hasTitle',
        } as UserField,
        {
            slug: 'company',
            name: 'Company',
            exists: 'hasCompany',
        } as UserField,
        {
            slug: 'position',
            name: 'Job Title/Position',
            exists: 'hasPosition',
        } as UserField,
        {
            slug: 'address',
            name: 'Address/City/State',
            exists: 'hasAddress',
        } as UserField,
        {
            slug: 'phone',
            name: 'Mobile Phone',
            exists: 'hasPhone',
        } as UserField,
        {
            slug: 'country',
            name: 'Country',
            exists: 'hasCountry',
        } as UserField,
    ];
}