import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../../../../ApiClient/Models/User/user';
import { filter, map, take, withLatestFrom, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { EventUsersService } from './event-users.service';
import { Observable ,  Subscription } from 'rxjs';
import { AlertService } from '../../../eventFarmAlert.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

@Component({
    selector: 'event-users',
    providers: [
        ModalService,
        EventUsersService
    ],
    template: require('./event-users.html'),
    styles: [require('./event-users.scss')],
})

export class EventUsersComponent implements OnInit, OnDestroy {
    private currentUsersWithRolesForEvent: User[];
    private addUserEnabled: boolean = false;
    private isEditingUser: boolean = false;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;
    private requesting: boolean = false;
    private roleDescription: string = null;
    private newUserAdded: boolean = false;
    readonly loading = `<div class="ef-css-loader-inline"><div class="loader-inline"></div></div>`;

    constructor(
        private alertService: AlertService,
        private eventUsersService: EventUsersService,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
        private element: Renderer2,
        private store: Store<fromRoot.AppState>,
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(
          map(event => event),
          withLatestFrom(this.store.select('user')),
          filter(([event, user]) => event['data'] && event['data'].id && user['data'] && user['data'].id),
          take(1)
        ).subscribe((val) => {
        });
    }

    ngOnInit() {
        this.currentUsersWithRolesForEvent = [];
        this.eventUsersService.isLoadingUsers = true;
        this.eventUsersService.getUsersForEvent();
    }

    get canUserCRUD(): boolean {
        return this.eventUsersService.currentEventRoles['user-crud'];
    }

    ngAfterViewInit() {
        this.eventUsersService.users$.subscribe(res => {
            this.currentUsersWithRolesForEvent = res;
        });
    }

    public changePage(page) {
        this.eventUsersService.isLoadingUsers = true;
        this.eventUsersService.userListOptions.pagination.currentUsersPage = page;
        this.eventUsersService.getUsersForEvent();
    }

    private removeUser(user: User, i: number): void {
        this.alertService.userRoles().confirmUserDeletion(user.identifier.identifier)
        .then((res) => {
            if (res.value) {
                this.eventUsersService.isLoadingUsers = true;

                this.eventUsersService.deleteUserRoleForEvent(user).subscribe(
                    () => {
                        this.message.success(`Removed ${user.name.fullName} From Event`);
                        this.currentUsersWithRolesForEvent.splice(i, 1);

                        if ( this.currentUsersWithRolesForEvent.length === 0) {
                            this.changePage(this.eventUsersService.userListOptions.pagination.currentUsersPage - 1);
                        }
                        this.isEditingUser = false;
                        this.roleDescription = null;
                        this.eventUsersService.isLoadingUsers = false;
                    },
                    (err) => {
                        this.message.error(err.error.errors[0].detail.split(':')[0]);
                        this.eventUsersService.isLoadingUsers = false;
                    },
                );
            }
        });
    }

    private updateNameAndUserRoleForEvent(user) {
        this.newUserAdded = !!user.create;
        const userName = user.name && user.name.firstName ? user.name.firstName : 'this user';
        const first = user.name && user.name.firstName ? user.name.firstName : '';
        const last = user.name && user.name.lastName ? user.name.lastName : '';

        if (!user.eventRoles) {
            return this.message.info(`Please select a role for ${userName}.`);
        }

        if (!first.length || !last.length) {
            return this.message.error('First name or last name cannot be empty');
        }

        if (!user.eventRoles.maxRoleString) {
            user.eventRoles.maxRoleString = user.eventRoles.name;
        }

        if (user.create) {
            return this.addUserToEvent(user);
        }

        if (user.editable) {
            this.eventUsersService.isLoadingUsers = true;

            this.eventUsersService.saveUserName(user, first, last).subscribe((res) => {
                user.name._fullName = user.name.firstName + ' ' + user.name.lastName;
                if (!user.eventRoles.slug) {
                    this.message.success('Saved!');
                    this.roleDescription = null;
                    this.eventUsersService.isLoadingUsers = false;
               } else {
                    this.eventUsersService.updateUserRoleForEvent(user).subscribe( () => {
                            this.message.success(`Updated ${user.name.fullName }!`);
                            if (user['create']) {
                                delete user['create'];
                            }
                            this.eventUsersService.isLoadingUsers = false;
                            this.roleDescription = null;
                        },
                        (err) => {
                            this.message.error(err.error.errors[0].detail.split(':')[0]);
                            this.eventUsersService.isLoadingUsers = false;
                        });
               }
            });
        }
        this.isEditingUser = !this.isEditingUser;
        user.editable = !user.editable;
    }

    private addUserToEvent(user: User): void {
        this.addUserEnabled = false;
        this.eventUsersService.isLoadingUsers = true;

        this.eventUsersService.createUserRoleForEvent(user)
            .subscribe(
                () => {
                    Object.assign(user, {eventRoles: {maxRoleString: user.eventRoles['name']}});
                    user.name._fullName = user.name.firstName + ' ' + user.name.lastName;
                    this.message.success(`${user.name.fullName} Added!`);
                    user['editable'] = !user['editable'];
                    if (user['create']) {
                        delete user['create'];
                    }
                    this.roleDescription = null;
                    this.isEditingUser = false;
                    this.eventUsersService.isLoadingUsers = false;
                },
                (err) => {
                    if (err.error.errors[0].status === '409') {
                        this.currentUsersWithRolesForEvent.pop();
                    }
                    this.message.error(err.error.errors[0].detail.split(':')[0]);
                    this.eventUsersService.isLoadingUsers = false;
                },
            );
    }

    private selectUser(newUser: User): void {
        if (this.currentUsersWithRolesForEvent.filter(user => user.id === newUser.id).length) {
            this.message.error('User is already a collaborator for this event');
        } else {
            Object.assign(newUser, {create: true, editable: true});
            this.currentUsersWithRolesForEvent.push(newUser);
            this.newUserAdded = true;
        }
        this.addUserEnabled = false;
    }

    private updateUserRoleModel(role, user) {
        this.isEditingUser = true;
        this.setRoleDescription(role.slug);
        Object.assign(user.eventRoles, role);
    }

    private setRoleDescription(slug: string) {
        switch (slug) {
            case('organizer'):
                this.roleDescription = 'Organizer: Can view or change any setting or option within an event.';
                break;
            case('assistant'):
                this.roleDescription = 'Assistant: Can view most settings and options, modify the guest list, and change settings related to registration and payments.';
                break;
            case('support'):
                this.roleDescription = 'Support: Can view event information, perform limited event functions, and make changes to guest-related information, including adding guests to events and to the address book.';
                break;
            case('check-in-staff'):
                this.roleDescription = 'Check In Staff: Can view event details and the guest list in order to check guests in, but cannot add additional guests to the list.';
                break;
            case('read-only'):
                this.roleDescription = 'Read Only: Can view details about an event but cannot make any changes.';
                break;
            default:
                this.roleDescription = '';
                break;
        }
    }

    private cancel(user) {
        if (this.newUserAdded) {
            this.currentUsersWithRolesForEvent.pop();
            this.newUserAdded = false;
        } else {
            user.editable = false;
        }

        this.isEditingUser = false;
    }

    // need this for mismatched data sets
    private compareRoleTypes(r1, r2) {
        return r1 && r2 ? r1.name === r2.maxRoleString : r1 === r2;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
}
