import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Subject, BehaviorSubject, Observable, from ,  Subscription } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { AlertService } from '../../eventFarmAlert.service';
import * as _ from 'lodash';
import { Question } from '../../../ApiClient/Models/Question/question';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { UserField, UserFields } from '../../../ApiClient/Models/Event/user-field';
@Injectable()
export class QuestionCenterService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private store: Store<fromStore.AppState>,
        private alertService: AlertService,
    ) {
    }

    public eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
    public readonly allQuestions$ = new BehaviorSubject<Question[]>([]);
    public readonly hiddenQuestions$ = new BehaviorSubject<Question[]>([]);
    public readonly visibleQuestions$ = new BehaviorSubject<Question[]>([]);
    public readonly visibleTransactionQuestions$ = new BehaviorSubject<Question[]>([]);
    public readonly visibleIndividualQuestions$ = new BehaviorSubject<Question[]>([]);
    public readonly enabledQuestions$ = new BehaviorSubject<Question[]>([]);
    public isLoadingQuestions: boolean = false;
    private listView: boolean = true;

    get isListView(): boolean {
        return this.listView;
    }

    get isShield(): boolean {
        return this.eventFarmService.currentEvent.isShield;
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get allQuestionCount(): number {
        return this.allQuestions$.value.length
    }

    get enabledQuestionCount(): number {
        return this.enabledQuestions$.value.length
    }

    get visibleQuestionCount(): number {
        return this.visibleQuestions$.value.length
    }

    get visibleIndividualQuestionCount(): number {
        return this.visibleIndividualQuestions$.value.length
    }

    get visibleTransactionQuestionCount(): number {
        return this.visibleTransactionQuestions$.value.length
    }

    get hiddenQuestionCount(): number {
        return this.hiddenQuestions$.value.length
    }

    get questionPreviewCount(): number {
        return this.allQuestions$.value.filter(q => !q.isDisabled && !q.isHidden).length
    }

    public toggleView() {
        this.listView = !this.listView;
    }

    public clearList() {
        this.allQuestions$.next([]);
        this.visibleQuestions$.next([]);
        this.visibleTransactionQuestions$.next([]);
        this.visibleIndividualQuestions$.next([]);
        this.hiddenQuestions$.next([]);
        this.enabledQuestions$.next([]);
    }

    public async getQuestions() {
        this.isLoadingQuestions = true;
        try {
            const eventResponse = await this.apiClient.getUseCaseFactory()
            .Event()
            .GetEvent(
                this.eventFarmService.currentEvent.id,
                ['QuestionsAndAnswers', 'QuestionContext', 'AllQuestions', 'AnswerBindings'],
            ).toPromise();

            const efEvent = EfEvent.fromApiResponse(eventResponse.data);
            this.setupQuestionsForEvent(efEvent.questions);
            this.isLoadingQuestions = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingQuestions = false;
            return false;
        }
    }

    private setupQuestionsForEvent(questions: Question[]) {
        this.allQuestions$.next(questions);
        this.visibleQuestions$.next(questions.filter(q => !q.isHidden));
        this.hiddenQuestions$.next(questions.filter(q => q.isHidden));
        this.enabledQuestions$.next(questions.filter(q => !q.isDisabled));
        this.visibleTransactionQuestions$.next(questions.filter(q => !q.isHidden && !q.isIndividual));
        this.visibleIndividualQuestions$.next(questions.filter(q => !q.isHidden && q.isIndividual));
    }

    public async setQuestionSortOrder(questionId: string, sortOrder: number, showMessage: boolean = true) {
        await this.apiClient.getUseCaseFactory()
                .Question()
                .SetQuestionSortOrder(questionId, sortOrder)
                .toPromise();

        if (showMessage) {
            this.message.success('Order Set');
        }
    }

    public async updateUserField(userField: UserField, shouldEnable: boolean) {
        if (shouldEnable) {
            try {
                await this.apiClient.getUseCaseFactory()
                .Event()
                .EnableUserField(this.eventFarmService.currentEvent.id, userField.slug)
                .toPromise();
                this.message.success(userField.name + ' Enabled');
                this.eventFarmService.currentEvent.userFields.push(userField);
                this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            } catch (err) {
                this.message.error('Please try again');
            }
        } else {
            try {
                await this.apiClient.getUseCaseFactory()
                .Event()
                .DisableUserField(this.eventFarmService.currentEvent.id, userField.slug)
                .toPromise();
                this.message.success(userField.name + ' Disabled');

                const index = this.eventFarmService.currentEvent.userFields.indexOf(userField);
                if (index > -1) {
                    this.eventFarmService.currentEvent.userFields.splice(index, 1);
                }
                this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            } catch (err) {
                this.message.error('Please try again');
            }
        }
    }

}
