import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';
import * as fromStore from '../../../store';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  selector: 'event-app',
  template: require('./event-app.html'),
})

export class EventAppComponent implements OnDestroy {

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>,
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      this.toggleState = this.eventFarmService.currentEvent.eventAttributes.hasEventAppEnabled;
    });
  }

  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  private toggleState: boolean = false;
  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;

  toggle() {
    this.toggleState = !this.toggleState;
    if (this.eventFarmService.currentEvent.eventAttributes.hasEventAppEnabled) {
      this.disableEventApp();
    } else {
      this.enableEventApp();
    }
  }

  ngOnDestroy() {
    this.eventUpdate$.unsubscribe();
  }

  enableEventApp() {
    this.apiClient.getUseCaseFactory().Event().EnableEventApp(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasEventAppEnabled = true;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Event App Enabled');
      });
  }

  disableEventApp() {
    this.apiClient.getUseCaseFactory().Event().DisableEventApp(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasEventAppEnabled = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Event App Disabled');
      });
  }
}
