import { EfEvent } from '../ApiClient/Models/Event/event';
declare const __USE_SUBDOMAINS__: boolean;

function getDomain(): string {
  const dots: number = ( window.location.host.match( RegExp('([.])+', 'g') ) || [] ).length;
  const domainWithoutSubdomain: string = dots < 2 ? window['location'].hostname : window.location.hostname.split('.').slice(1).join('.');
  return domainWithoutSubdomain;
}

export function getBaseUrl(): string {
  const port = window['location'].port ? ':' + window['location'].port : '';
  return window['location'].protocol + '//' + getDomain() + port;
}

export function getEventUrl(currentEvent: EfEvent): string {
  if (__USE_SUBDOMAINS__ || window.location.host.includes('eventfarm')) {
    return window['location'].protocol + '//' + currentEvent.altKeyword + '.' + getDomain();
  }
  const port = window['location'].port ? ':' + window['location'].port : '';
  return window['location'].protocol + '//' + getDomain() + port + '/' + currentEvent.altKeyword;
}

export const EF_DOMAIN = getDomain();
