import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventFarmService } from '../../../../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../../../../admin.service';
import { TeamEventsService } from '../../../team-events.service';
import { EfEvent } from '../../../../../../../../ApiClient/Models/Event/event';
import _ = require('lodash');
import { AdminAlertService } from '../../../../../../Alert/adminEventFarmAlert.service';

@Component({
  selector: 'edit-event-exhibitors',
  template: require('./edit-event-exhibitors.html'),
  styles: [require('./edit-event-exhibitors.scss')],
})
  export class EditEventExhibitorsComponent implements OnInit {
  @Input() eventId: string;

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private adminService: AdminService,
    private messageService: NzMessageService,
    private teamEventsService: TeamEventsService,
    private adminAlertService: AdminAlertService
  ) {}

  ngOnInit() {

  }


  /**
   *
   * Updates the status of the invite
   */
  private onChangeStatus(status) {}

  private determineDirtyFields(controls) {
    const dirty = Object.keys(controls)
      .map((name) => {
        return { ...controls[name], name };
      })
      .filter((obj) => !obj.pristine);
    return dirty;
  }



  /**
   * Setup initial values and subscriptions
   */

  close() {
    this.drawerRef.close();
  }

  // Todo revisit using unsubscribe instead
  ngOnDestroy() {

    this.adminService.currentTeamEvent$.next(null);
  }
}
