import {Component, Input, OnInit} from '@angular/core';
import { Operator } from 'rxjs';
import { UploaderService } from '../uploader.service';
import { FinishService } from './finish.service';
import { EventFarmService } from '../../../eventFarm.service';
import { IconService } from '../../../CoreUI/Icons/icons.service';

@Component({
    selector: 'finish-display',
    template: require('./finish-display.html'),
    styles: [require('./finish-display.scss')],
})

export class FinishDisplayComponent implements OnInit {

    @Input() simplified?: boolean = false;
    @Input() twoColumn?: boolean = false;
    @Input() contactTitle?: string = null;
    @Input() duplicatesIconSrc?: string = null;
    @Input() errorsIconSrc?: string = null;
    @Input() contactsIconSrc?: string = null;
    constructor(
        private uploaderService: UploaderService,
        private finishService: FinishService,
        private eventFarmService: EventFarmService,
        private iconService: IconService
    ) {
    }

    readonly thumbUpIcon = require('../assets/thumb-up-icon');
    readonly downloadIcon = require('../assets/download-icon');

    ngOnInit() {

    }
}
