import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-select',
  template: `
    <div
      class="dynamic-field form-group form-select"
      [formGroup]="group">
      <label>{{ config.label }}</label>
      <select [formControlName]="config.name" class="form-control">
        <option value="">{{ config.placeholder }}</option>
        <option *ngFor="let option of config.options"
          [value]="option.id">
          {{ option.text }}
        </option>
      </select>
    </div>
  `
})
export class FormSelectComponent implements Field {
  config;
  group: FormGroup;
}
