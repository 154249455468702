export class EmailDesign {
    id: string = '';
    name: string = '';

    public static fromApi(data) {
        return {
            id: data.id,
            name: data.attributes.name,
        } as EmailDesign;
    }

    public static fromContentDelivery(data) {
        return {
            id: data.id,
            name: data.name,
        } as EmailDesign;
    }
}
