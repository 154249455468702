import { Action } from '@ngrx/store';

export const GET_CURRENT_USER = '[User] Get Current User';
export const GET_CURRENT_USER_SUCCESS = '[User] Get Current User Success';
export const GET_CURRENT_USER_FAIL = '[User] Get Current User Fail';

export const GET_USER_ME = '[User] Get User Me';
export const GET_USER_ME_SUCCESS = '[User] Get User Me Success';
export const GET_USER_ME_FAIL = '[User] Get User Me Fail';

export const GET_USER_BY_EMAIL = '[User] Get User By Email';
export const GET_USER_BY_EMAIL_SUCCESS = '[User] Get User By Email Success';
export const GET_USER_BY_EMAIL_FAIL = '[User] Get User By Email Fail';


export const GET_USER_POOL_PERMISSIONS = '[User] Get User Pool Permissions';
export const GET_USER_POOL_PERMISSIONS_SUCCESS = '[User] Get User Pool Permissions Success';

export const SET_USER_POOL_PERMISSIONS_ON_TEAM = '[User] Set User Pool Permissions On Team';

export const FETCH_TEAMS_FOR_USER = '[Team] Fetch Teams For User';
export const FETCH_TEAMS_FOR_USER_FAIL = '[Team] Fetch Teams For User Fail';
export const FETCH_TEAMS_FOR_USER_SUCCESS = '[Team] Fetch Teams For User Success';

export class GetCurrentUser implements Action {
    readonly type = GET_CURRENT_USER;
    constructor(public payload?) {}
}

export class GetCurrentUserSuccess implements Action {
    readonly type = GET_CURRENT_USER_SUCCESS;
    constructor(public payload) {}
}

export class GetCurrentUserFail implements Action {
    readonly type = GET_CURRENT_USER_FAIL;
    constructor(public payload) {}
}

export class GetUserMe implements Action {
    readonly type = GET_USER_ME;
    constructor(public payload?) {}
}

export class GetUserMeSuccess implements Action {
    readonly type = GET_USER_ME_SUCCESS;
    constructor(public payload) {}
}

export class GetUserMeFail implements Action {
    readonly type = GET_USER_ME_FAIL;
    constructor(public payload) {}
}

export class GetUserByEmail implements Action {
    readonly type = GET_USER_BY_EMAIL;
    constructor(public payload?) {}
}

export class GetUserByEmailSuccess implements Action {
    readonly type = GET_USER_BY_EMAIL_SUCCESS;
    constructor(public payload) {}
}

export class GetUserByEmailFail implements Action {
    readonly type = GET_USER_BY_EMAIL_FAIL;
    constructor(public payload) {}
}

export class GetUserPoolPermissions implements Action {
    readonly type = GET_USER_POOL_PERMISSIONS;
    constructor(public payload?) {}
}

export class GetUserPoolPermissionsSuccess implements Action {
    readonly type = GET_USER_POOL_PERMISSIONS_SUCCESS;
    constructor(public payload) {}
}

export class SetUserPoolPermissionsOnTeam implements Action {
    readonly type = SET_USER_POOL_PERMISSIONS_ON_TEAM;
    constructor(public payload) {}
}

export class FetchTeamsForUser implements Action {
    readonly type = FETCH_TEAMS_FOR_USER;
    constructor(public payload?) {}
}

export class FetchTeamsForUserSuccess implements Action {
    readonly type = FETCH_TEAMS_FOR_USER_SUCCESS;
    constructor(public payload?) {}
}

export class FetchTeamsForUserFail implements Action {
    readonly type = FETCH_TEAMS_FOR_USER_FAIL;
    constructor(public payload?) {}
}

export type UserAction =
    GetCurrentUserSuccess |
    GetCurrentUser |
    GetUserPoolPermissionsSuccess |
    GetUserPoolPermissions |
    SetUserPoolPermissionsOnTeam |
    FetchTeamsForUser |
    FetchTeamsForUserSuccess |
    FetchTeamsForUserFail;
