import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {NgxIntlTelInputModule} from "@khazii/ngx-intl-tel-input";
import { GuestListViewModule } from '../../CoreUI/GuestListTableView/guest-list-view.module';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { TicketBlockDashboardComponent } from './ticketBlockDashboard.component';
import { TicketBlockAllotmentControlsComponent } from './ticketBlockAllotmentControls.component';
import { TicketBlockDashboardService } from './ticketBlockDashboard.service';
import { TicketBlockDashboardRoutingModule } from './ticketBlockDashboard-routing.module';
import { InviteAddGuestComponent } from './Invite/inviteAddGuestToTicketBlock.component';
import { TicketBlockInvitationListComponent } from './ticketBlockInvitationList.component';
import { AddGroupComponent } from './Invite/AddGroup/addGroupToTicketBlock.component';
import { AddGuestComponent } from './Invite/AddIndividual/addGuestToTicketBlock.component';
import { AddGroupService } from './Invite/AddGroup/addGroupToTicketBlock.service';
import { InvitationOptionsModule } from '../../CoreUI/InvitationOptions/invitationOptions.module';

import { TicketBlockUsersModule } from '../../CoreUI/TicketBlockUsers/ticket-block-users.module';
import { TicketBlockResolver } from '../ticket-block-resolver.service';
import { UserTypeaheadModule } from '../../CoreUI/Typeahead/user-typeahead.module';
import { GroupTypeaheadModule } from '../../CoreUI/Typeahead/group-typeahead.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [TicketBlockDashboardComponent],
    declarations: [
        TicketBlockDashboardComponent,
        TicketBlockInvitationListComponent,
        AddGroupComponent,
        AddGuestComponent,
        InviteAddGuestComponent,
        TicketBlockAllotmentControlsComponent,
    ],
    entryComponents: [
        TicketBlockDashboardComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        TicketBlockDashboardRoutingModule,
        UserTypeaheadModule,
        GroupTypeaheadModule,
        InvitationOptionsModule,
        TicketBlockUsersModule,
        GuestListViewModule,
        NzUtilitiesModule,
        NgxUtilitiesModule,
        NgxIntlTelInputModule
    ],
    providers: [
        TicketBlockDashboardService,
        AddGroupService,
        TicketBlockResolver
    ],
})

export class TicketBlockDashboardModule {
}
