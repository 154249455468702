export const ngxChartsOptions = {
    // demo: https://swimlane.github.io/ngx-charts/
    // https://github.com/swimlane/ngx-charts/blob/master/src/utils/color-sets.ts
    colorScheme: {
        domain: [
            '#2D5D86',
            '#2FC2A6',
            '#4A90E2',
            '#4AE777',
            '#393063',
            '#9486AF',
            '#3DD4F3',
            '#50E3C2',
            '#0B4378',
            '#3BC2DE',
        ],
    },
    showLegend: true,
};

export const ngxPieChartsOptions = {
    showLabels: true,
    explodeSlices: false,
    doughnut: true,
};
