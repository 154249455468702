import { Component, OnInit } from '@angular/core';
import { UploaderService } from './uploader.service';
import { EventFarmService } from '../../eventFarm.service';
import * as fromRoot from '../../store';
import { Store } from '@ngrx/store';
@Component({
    selector: 'uploader',
    template: require('./uploader.html'),
    styles: [require('./uploader.scss')],
})

export class UploaderComponent implements OnInit {

    constructor(
        private uploaderService: UploaderService,
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>
    ) {
    }


    ngOnInit() {
        this.uploaderService.userImport = null;
        this.uploaderService.hasInvitations = false;
        this.uploaderService.hasSentInvitations = false;
        this.uploaderService.selectedStack = null;
        this.uploaderService.uploaderIsForEvent(false);
        this.uploaderService.setExistingEventFromUrl();
    }
}
