import { EventFarmService } from './../../../eventFarm.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import * as eventActions from './../../../store/actions/event';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { InvitationsConfirmationsService } from './invitations-confirmations.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';


@Component({
    selector: 'invitations-confirmations',
    template: require('./invitations-confirmations.html'),
    styles: [require('./invitations-confirmations.scss')],
    providers: [InvitationsConfirmationsService]
})

export class InvitationsConfirmationsComponent implements OnInit {

    constructor(
        private ics: InvitationsConfirmationsService,
        private store: Store<fromRoot.AppState>,
        private iconService: IconService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private segmentService: SegmentService
    ) {
        this.store.dispatch(new eventActions.FetchAccessTypes());
    }



    ngOnInit() {
        this.segmentService.segmentSideNavTracking().invitationsConfirmationsEntered();

    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    private emailDesignCenterUrl() {
        return this.routeGenerator.url('events.edc', { poolId: this.eventFarmService.currentTeam.id, eventId: this.eventFarmService.currentEvent.id });
    }
}
