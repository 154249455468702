import swal, { SweetAlertOptions } from 'sweetalert2';

export class SweetAlert {
    private sweetAlert: any;

    constructor() {
        this.sweetAlert = swal;
    }

    alert(sweetAlertOptions: SweetAlertOptions): Promise<any> {
        return this.sweetAlert.fire(sweetAlertOptions);
    }
}
