import {FusionAuthService} from "@fusionauth/angular-sdk";
import { EventFarmService } from './eventFarm.service';
import { Injector } from '@angular/core';
import {LocalStorageService} from '../_services/storage/local-storage.service';

export function loadUserContext(injector: Injector): () => Promise <any> {
    return async (): Promise<any> => {
        const localStorageService = injector.get(LocalStorageService);
        const eventFarmService = injector.get(EventFarmService);
        const authService = injector.get(FusionAuthService);
        if (localStorageService.get('effectiveEfUserId')) {
                if (authService.isLoggedIn()) {
                    return eventFarmService.loadRequiredAppUserContext(
                        localStorageService.get('effectiveEfUserId'),
                        localStorageService.get('effectiveEfUserEmail')
                    );
                } else {
                    localStorageService.resetStorageAndLogout();
                }
        } else {
            return new Promise((resolve, reject) => resolve(true))
        }
    };
}

export function startQueuePoll(injector: Injector): () => Promise <any> {
    return (): Promise<any> => {
        return new Promise((resolve) => {
            const eventFarmService = injector.get(EventFarmService);
            // eventFarmService.checkUserQueueOnInterval(localStorageService.get('effectiveEfUserId'), 12000);
            resolve(true);
        });
    };
}
