import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const TRIGGER_NOOP = '[Misc] Noop';

export class Noop implements Action {
    readonly type = TRIGGER_NOOP;
    constructor(public payload) { }
}
export type MiscActions = Noop;
