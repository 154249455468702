import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import { User } from '../../ApiClient/Models/User/user';
import {EventRevenueService} from "../../EventFarm/EventsModule/EventInfo/Revenue/event-revenue.service";

const currencyRegex = /^\$?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/;

export class RevenueAlert extends AbstractBaseAlert {

    withdrawFunds(eventId: string, revAvailable: number, revenueService: EventRevenueService): Promise<any> {
        return this.alert({
            title: 'Withdraw Funds',
            confirmButtonText: 'Yes, withdraw',
            text: `Revenue Available: $${revAvailable.toFixed(2)} (USD) (80% available until 30 days after)`,
            icon: 'question',
            input: 'text',
            inputValue: revAvailable.toFixed(2),
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            inputValidator: (num) => {
                const removeWhitespaceSpecial = num.replace(' ', '').replace('$', '').replace(',', '');
                if (!removeWhitespaceSpecial || !currencyRegex.test(removeWhitespaceSpecial)) {
                    return 'Invalid Number!';
                }
                if (Number(removeWhitespaceSpecial) > revAvailable) {
                    return "Can't withdraw more than available ";
                }
            },
            preConfirm: async (amountToWithdraw) => {
                const removeWhitespaceSpecial = amountToWithdraw.replace(' ', '').replace('$', '').replace(',', '');
                try {
                    await this.apiClient.getUseCaseFactory().Withdrawal().CreateWithdrawal(eventId, removeWhitespaceSpecial).toPromise();
                    await revenueService.getWithdrawalsForEvent();

                    await this.withdrawSuccess();
                } catch (error) {
                    swal.showValidationMessage(
                        `Unable to withdraw at this time`);
                }
            }
         });
    }

    withdrawSuccess(): Promise<any> {
        return this.alert({
            title: 'Withdrawl Sent',
            text: 'Your withdrawal request has been recorded! Please allow 5-7 business days for processing',
            icon: 'success',
        });
    }

    refundSuccess(): Promise<any> {
        return this.alert({
            title: 'Refund Sent',
            text: 'A refund request has been sent',
            icon: 'success',
        });
    }

    resendReceipt(paymentId: string, name: string): Promise<any> {
        return this.alert({
            title: 'Resend Receipt',
            confirmButtonText: 'Yes, resend',
            text: `Are you sure want to resend a receipt to ${name}`,
            icon: 'question',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            preConfirm: async () => {
                try {
                    await this.apiClient.getUseCaseFactory().EmailMessage().ResendReceipt(paymentId).toPromise();
                } catch (error) {
                    swal.showValidationMessage(
                        `Unable to resend the receipt at this time`);
                }
            }
         });
    }

    refundFunds(paymentId: string, transactionId: string, revAvailable: number): Promise<any> {
        return this.alert({
            title: 'Refund Amount',
            confirmButtonText: 'Yes, refund',
            text: `Amount Available To Refund: $${revAvailable.toFixed(2)} (USD)`,
            icon: 'question',
            input: 'text',
            inputValue: revAvailable.toFixed(2),
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            inputValidator: (num) => {
                const removeWhitespaceSpecial = num.replace(' ', '').replace('$', '').replace(',', '');
                if (!removeWhitespaceSpecial || !currencyRegex.test(removeWhitespaceSpecial)) {
                    return 'Invalid Number!';
                }
                if (Number(removeWhitespaceSpecial) > revAvailable) {
                    return "Can't refund more than available ";
                }
            },
            preConfirm: async (amountToWithdraw) => {
                const removeWhitespaceSpecial = amountToWithdraw.replace(' ', '').replace('$', '').replace(',', '');
                try {
                    await this.apiClient.getUseCaseFactory().Refund().CreateRefund(paymentId, transactionId, removeWhitespaceSpecial).toPromise();
                    await this.refundSuccess();
                } catch (error) {
                    swal.showValidationMessage(
                        `Unable to refund at this time`);
                }
            }
         });
    }
}
