import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';
import { Group } from '../../../../../ApiClient/Models/Group/group';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'address-book-add-groups-for-user-tagger',
    styles: [require('./address-book-add-groups-for-user-tagger.scss')],
    template: require('./address-book-add-groups-for-user-tagger.html'),
})

export class AddressBookAddGroupsForUserTaggerComponent implements OnInit {
    constructor(
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private message: NzMessageService,
    ) {
    }

    readonly deleteGroupSVG = require('../../assets/delete-group');

    ngOnInit() {
        this.addressBookUserService.groupsSelectedForUser = [];
        if (this.addressBookService.addedByMeGroup && this.addressBookService.selectedGroup && this.addressBookService.addedByMeGroup.id !== this.addressBookService.selectedGroup.id) {
            this.addressBookUserService.groupsSelectedForUser.push(this.addressBookService.selectedGroup);
        }
    }

    private selectGroup(group: Group): void {
        if (group.name === 'Added By Me') {
            this.addressBookUserService.groupsSelectedForUser.unshift(group);
        } else {
            this.addressBookUserService.groupsSelectedForUser.push(group);
            this.addressBookUserService.broadcastGroupSelectionsForUser();
        }
    }

    removeGroup(groupToRemove: Group) {
        let newGroupsList = this.addressBookUserService.groupsSelectedForUser.map((group) => {
            if (group.id !== groupToRemove.id) {
                return group;
            }
        });
        this.message.success('Contact removed from group');
        this.addressBookUserService.groupsSelectedForUser = newGroupsList;
        this.addressBookUserService.broadcastGroupSelectionsForUser();
    };

}
