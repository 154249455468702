import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventsListService } from '../eventsList.service';

@Component({
    selector: 'applied-tags',
    template: require('./appliedTags.html'),
    styles: [require('./appliedTags.scss'), require('../events-list.scss')],
    encapsulation: ViewEncapsulation.None
})

export class AppliedTagsComponent implements OnInit {

    private eventListOptions = this.eventsDashboardService.eventListOptions;
    private currentTagFilters;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventsDashboardService: EventsListService
    ) {}

    ngOnInit() {
        this.eventsDashboardService.currentTagFilters$.subscribe(res => this.currentTagFilters = res);
    }

    removeTag(tag, index) {
        tag.isActive = false;
        this.currentTagFilters.splice(index, 1);
        const arrayOfSlugs = this.currentTagFilters.map(i => i.slug);
        Object.assign(this.eventListOptions, {
            'tags': arrayOfSlugs
        });
        this.eventsDashboardService.currentTagFiltersSource.next(this.currentTagFilters);
        this.eventsDashboardService.refreshEvents();
    }
}
