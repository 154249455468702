import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BreadcrumbsComponent } from './breadcrumbs.component';

const forExport = [
    BreadcrumbsComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        RouterModule,
    ],
})

export class BreadcrumbsModule {
}
