import { NgModule } from '@angular/core';

import { ModalComponent } from './modal.component';
import { EfModalDirective } from './modal.directive';
import { ModalService } from './modal.service';
import { CommonModule } from '@angular/common';
import {UtilitiesModule} from '../../../Utilities/utilities.module';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';

const forExport = [
    EfModalDirective,
    ModalComponent
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        UtilitiesModule,
        NzUtilitiesModule
    ],
    providers: [
        ModalService,
    ],
})

export class ModalModule {
}
