import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import { Stack } from '../../ApiClient/Models/Stack/stack';
import { isValidPhoneNumber } from '../../Utilities/validatePhoneNumber';
import { isValidEmail } from '../../Utilities/validateEmail';

export class GuestListAlert extends AbstractBaseAlert {
    noTicketTypesSet(preventedAction: string): Promise<any> {
        return this.alert({
            title: 'Configure Access Types First',
            text: `It looks like this event doesn\'t have any available Access Types. Please configure Access Types before ${preventedAction}.`,
            showCancelButton: true,
            confirmButtonText: 'Configure',
            icon: 'warning',
        });
    }

    noEmailForInvitation(): Promise<any> {
        return this.alert({
            title: 'Add Email Address for Invitation',
            text: 'You will need to need an email address to send this invitation',
            confirmButtonText: 'Add Email',
            icon: 'warning',
        });
    }

    confirmRescindAllInvitations(): Promise<any> {
        return this.alert({
            title: 'Rescind all invitations?',
            text: 'Are you sure you want to rescind ALL remaining invitations?\n',
            showCancelButton: true,
            confirmButtonText: 'Rescind All Invitations',
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            icon: 'warning',
        });
    }

    rescindSuccessful(): Promise<any> {
        return this.alert({
            title: 'All Invitations Removed',
            text: 'Your invitations have been removed. The list will now refresh',
            icon: 'success',
        });
    }

    groupAddedToEvent(groupName: string, withEmails: boolean = false): Promise<any> {
        return this.alert({
            title: 'Group Added',
            html: 'Group <i>' + groupName + '</i> is being processed and added to the guest list.  You will receive an email when this completes and may perform other actions during this time.',
            icon: 'success',
        });
    }

    confirmGroupAddedToEventWithEmails(groupName: string): Promise<any> {
        return this.alert({
            title: 'Add Group \"' + groupName + '\" to Event And Send Emails?',
            text: 'Are you sure you would like to add Group \"' + groupName + '\" and send invitations out?',
            confirmButtonText: 'Invite and Send',
            showCancelButton: true,
            icon: 'question',
        });
    }

    confirmInvitationEmailChange(): Promise<any> {
        return this.alert({
            title: 'Are you sure you would like to change this email?',
            text: 'This change will affect this guest\'s invitations for all events',
            confirmButtonText: 'Change Email',
            showCancelButton: true,
            icon: 'question',
        });
    }

    emailChangeError(reason: string = null): Promise<any> {
        return this.alert({
            title: 'Unable to Change Email',
            text: 'Unable to change email due to following: ' + reason,
            icon: 'error',
        });
    }

    confirmRemoveInvitation(message: string): any {
        return this.confirm({
            title: 'Remove Invitation',
            text: message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Remove Invitation'
        });
    }

    confirmTransferInvitation(message: string): Promise<any> {
        return this.alert({
            title: 'Transfer Invitation',
            text: message,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Transfer'
        });
    }

    unableToRemoveInvitation(newMessage: string): Promise<any> {
        return this.alert({
            title: 'Unable to Remove Invitation',
            text: newMessage,
            icon: 'error'
        });
    }

    addWaitlistUser(eventId: string, currentStacks: Stack[]): Promise<any> {

        const stackOptions = currentStacks.map((s, index) => {
            const stackId = s.id;
            const stackName = s.ticketType.name;
            const selected = index === 0 ? 'selected' : '';

            return `<option value="${stackId}" ${selected}>${stackName}</option>`;
        });

        return this.alert({
            title: 'Add To Waitlist',
            confirmButtonText: 'Yes, add user',
            html:
                '<input type="email" id="email" class="form-control sa-input-spacing" placeholder="Email" required>' +
                '<input id="first-name" class="form-control sa-input-spacing" placeholder="First Name" required>' +
                '<input id="last-name" class="form-control sa-input-spacing" placeholder="Last Name" required>' +
                '<input type="tel" id="phone" class="form-control sa-input-spacing" placeholder="Mobile Number">' +
                `<select id="stack-select" class="form-control sa-input-spacing">${stackOptions.join('')}</select>`,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            preConfirm: async () => {

                const email = (document.getElementById('email') as HTMLInputElement).value;
                const firstName = (document.getElementById('first-name') as HTMLInputElement).value;
                const lastName = (document.getElementById('last-name') as HTMLInputElement).value;
                const phone = (document.getElementById('phone') as HTMLInputElement).value;
                const stack = (document.getElementById('stack-select') as HTMLInputElement).value;

                if (!email.length || !isValidEmail(email)) {
                    return swal.showValidationMessage(
                        `Please enter a proper email`);
                }

                if (phone.length && !isValidPhoneNumber(phone)) {
                    return swal.showValidationMessage(
                        `Please enter a proper US phone number`);
                }

                if (!firstName.length && !lastName.length) {
                    return swal.showValidationMessage(
                        `First and last names can't be empty`);
                }

                if (!firstName.length) {
                    return swal.showValidationMessage(
                        `First name cant be empty`);
                }

                if (!lastName.length) {
                    return swal.showValidationMessage(
                        `Last name cant be empty`);
                }

                if (!stack.length) {
                    return swal.showValidationMessage(
                        `Please select a access type`);
                }

                try {

                    await this.apiClient.getUseCaseFactory().Invitation().CreateInvitation(eventId, stack,
                                        'waitlisted', 'api-addition',
                                        false, 1,
                                        email, firstName,
                                        lastName, null,
                                        null, null,
                                        null, false,
                                        null, null,
                                        phone).toPromise();

                } catch (e) {
                    swal.showValidationMessage(
                        `Please try again`);
                }

            }
        });
    }
}
