import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../admin.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup } from '@angular/forms';
import { Team } from '../../../../../ApiClient/Models/Pool/team';

@Component({
  selector: 'team-names',
  template: require('./team-names.html'),
  styles: [require('./team-names.scss')],
})
export class TeamNamesComponent implements OnInit {
  @Input() team: Team;
  profileForm: FormGroup;

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private adminService: AdminService
  ) {}

  async handleSave() {
    await this.adminService.setNameForPool(
      this.team.id,
      this.profileForm.controls.poolName.value
    );
  }

  ngOnInit() {
    this.profileForm = new FormGroup({
      poolName: new FormControl(this.team.name || ''),
      poolShortName: new FormControl(this.team.shortName || ''),
    });
  }
}
