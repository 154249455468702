import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { Exhibitor } from '../../../ApiClient/Models/Exhibitor/exhibitor';

@Injectable()
export class ExhibitorsService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private segmentService: SegmentService,
    ) {}

    public exhibitorsListOptions: ExhibitorsListOptions = {
        query: '',
        sortBy: 'createdAt',
        sortDirection: 'descending',
        pagination: {
            currentExhibitorsPage: 1,
            itemsPerPage: 10,
        }
    };

    public exhibitorMetaSource = new BehaviorSubject<any>({});
    public exhibitorMetaTotalResults = new BehaviorSubject<number>(0);

    public exhibitorsMeta$ = this.exhibitorMetaSource.asObservable();
    public readonly currentExhibitors$ = new BehaviorSubject<Exhibitor[]>([]);
    public exhibitorsMetaTotalResults$ = new BehaviorSubject<number>(0);

    public isLoadingExhibitors: boolean = false;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    public clearList() {
        this.currentExhibitors$.next([]);
        this.exhibitorMetaSource.next(null);
        this.exhibitorsListOptions.pagination.currentExhibitorsPage = 1;
    }

    get exhibitorCount(): number {
        return this.currentExhibitors$.value.length;
    }

    public async getExhibitors() {

        this.isLoadingExhibitors = true;
        const eventId = this.eventFarmService.currentEvent.id;

        try {
            const exhibitors = await this.apiClient.getUseCaseFactory()
                .Exhibitor()
                .ListExhibitorsForEvent(
                    eventId,
                    ['totalUserRolesForExhibitor'],
                    true,
                    this.exhibitorsListOptions.query,
                    this.exhibitorsListOptions.pagination.currentExhibitorsPage,
                    this.exhibitorsListOptions.pagination.itemsPerPage,
                    this.exhibitorsListOptions.sortBy,
                    this.exhibitorsListOptions.sortDirection
                ).toPromise();

            this.exhibitorMetaSource.next(exhibitors.meta);
            this.exhibitorMetaTotalResults.next(exhibitors.meta.totalResults);
            this.exhibitorsMetaTotalResults$.next(exhibitors.meta.totalResults);
            const e  = exhibitors.data.map(exhibitor => Exhibitor.fromApiResponse(exhibitor));

            this.currentExhibitors$.next(e);
            this.isLoadingExhibitors = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingExhibitors = false;
            return false;
        }
    }

    public async createExhibitor(exhibitor: Exhibitor) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Exhibitor()
                .CreateExhibitorForEvent(
                    this.eventFarmService.currentEvent.id,
                    exhibitor.name,
                    exhibitor.description,
                    exhibitor.logoUrl,
                    exhibitor.id
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateExhibitorName(currentExhibitorId: string, name: string) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Exhibitor()
                .SetExhibitorName(
                    currentExhibitorId,
                    name
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateExhibitorDescription(currentExhibitorId: string, description: string) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Exhibitor()
                .SetExhibitorDescription(
                    currentExhibitorId,
                    description
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateExhibitorLogoUrl(currentExhibitorId: string, exhibitorLogoURL: string) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Exhibitor()
                .SetExhibitorLogoUrl(
                    currentExhibitorId,
                    exhibitorLogoURL
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async removeExhibitor(id: string) {
        try {
            const deletedExhibitor = await this.apiClient.getUseCaseFactory().Exhibitor().RemoveExhibitor(id).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

}

export interface Pagination {
    currentExhibitorsPage: number;
    itemsPerPage: number;
}

export interface ExhibitorsListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}
