import { Component, OnInit } from '@angular/core';
import { InvitationOptionsService } from './invitationOptions.service';
import { EventFarmService } from '../../eventFarm.service';

import { Subscription ,  BehaviorSubject } from 'rxjs';
import { InvitationStatusTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';

@Component({
    selector: 'status-selection',
    styles: [require('./statusSelection.scss')],
    template: require('./statusSelection.html'),
})

export class StatusSelectionComponent implements OnInit {

    private invitationStatusTypes: InvitationStatusTypeInterface[];
    private validStatuses$: Subscription;
    private _selectedStatus?: InvitationStatusTypeInterface;

    constructor(
        private invitationOptionsService: InvitationOptionsService,
        private eventFarmService: EventFarmService,
    ) {
        this.validStatuses$ = invitationOptionsService.validStatuses$.subscribe((val) => {
            this.dropdownOptionList.next(val);
            if (!this.invitationOptionsService.selectedStatus) {
                return;
            }
            if (val.filter(status => status.slug === this.invitationOptionsService.selectedStatus.slug).length < 1) {
                this.invitationOptionsService.selectedStatus = val[0];
            }
            this.placeholder.next({
                name: this.invitationOptionsService.selectedStatus.name
            });
        });
    }

    placeholder = new BehaviorSubject({
        name: ''
    });

    dropdownOptionList = new BehaviorSubject([]);

    private dropdownConfig = {
        options: this.dropdownOptionList,
        placeholder: this.placeholder,
        error: false
    };

    ngOnInit() {
        this._selectedStatus = this.invitationOptionsService.selectedStatus;
        this.placeholder.next({
            name: this._selectedStatus.name
        });
    }

    changeSelectedStatus(event) {
        this._selectedStatus = event;
        this.invitationOptionsService.selectedStatus = event;
        this.placeholder.next({
            name: event.name
        });
    }

    get selectedStatus(): InvitationStatusTypeInterface {
        return this._selectedStatus;
    }

    set selectedStatus(status: InvitationStatusTypeInterface) {
        this._selectedStatus = status;
    }
}
