import { Action } from '@ngrx/store';
import { EfEvent } from '../../../ApiClient/Models/Event/event';

export const SET_CURRENT_EVENT = '[Event] Set Current Event';
export const SET_CURRENT_EVENT_SUCCESS = '[Event] Set Current Event Success';
export const SET_CURRENT_EVENT_FAIL = '[Event] Set Current Event Fail';

export const FETCH_CURRENT_EVENT = '[Event] Fetch Current Event';
export const FETCH_CURRENT_EVENT_SUCCESS = '[Event] Fetch Current Event Success';

export const RESET_CURRENT_EVENT = '[Event] Reset Current Event';

export const FETCH_DETAILED_CURRENT_EVENT = '[Event] Fetch Detailed Current Event';
export const FETCH_DETAILED_CURRENT_EVENT_SUCCESS = '[Event] Fetch Detailed Current Event Success';

export const FETCH_SALESFORCE_INTEGRATION_FOR_EVENT = '[Event] Fetch SalesForce Integration for Current Event';
export const FETCH_SALESFORCE_INTEGRATION_FOR_EVENT_SUCCESS = '[Event] Fetch SalesForce Integration for Current Event Success';

export const FETCH_USER_ROLES_FOR_CURRENT_EVENT = '[Event] Fetch User Roles For Current Event';
export const FETCH_USER_ROLES_FOR_CURRENT_EVENT_SUCCESS = '[Event] Fetch User Roles For Current Event Success';

export class SetCurrentEvent implements Action {
    readonly type = SET_CURRENT_EVENT;
    constructor(public payload?: any) { }
}

export class SetCurrentEventSuccess implements Action {
    readonly type = SET_CURRENT_EVENT_SUCCESS;
    constructor(public payload?: any) { }
}

export class SetCurrentEventFail implements Action {
    readonly type = SET_CURRENT_EVENT_FAIL;
    constructor(public payload?: any) { }
}

export class FetchCurrentEvent implements Action {
    readonly type = FETCH_CURRENT_EVENT;
}

export class FetchDetailedCurrentEvent implements Action {
    readonly type = FETCH_DETAILED_CURRENT_EVENT;
}

export class FetchCurrentEventSuccess implements Action {
    readonly type = FETCH_CURRENT_EVENT_SUCCESS;
    constructor(public payload: any) {}
}

export class FetchDetailedCurrentEventSuccess implements Action {
    readonly type = FETCH_DETAILED_CURRENT_EVENT_SUCCESS;
    constructor(public payload: any) {}
}

export class ResetCurrentEvent implements Action {
    readonly type = RESET_CURRENT_EVENT;
}

export class FetchUserRolesForCurrentEvent implements Action {
    readonly type = FETCH_USER_ROLES_FOR_CURRENT_EVENT;
}

export class FetchSalesForceIntegrationForEvent implements Action {
    readonly type = FETCH_SALESFORCE_INTEGRATION_FOR_EVENT;
}

export class FetchSalesForceIntegrationForEventSuccess implements Action {
    readonly type = FETCH_SALESFORCE_INTEGRATION_FOR_EVENT_SUCCESS;
    constructor(public payload: any) {}
}

export class FetchUserRolesForCurrentEventSuccess implements Action {
    readonly type = FETCH_USER_ROLES_FOR_CURRENT_EVENT_SUCCESS;
    constructor(public payload: any) {}
}

export type EventAction =
    ResetCurrentEvent |
    FetchCurrentEvent |
    FetchCurrentEventSuccess |
    SetCurrentEvent |
    SetCurrentEventSuccess |
    SetCurrentEventFail |
    FetchDetailedCurrentEvent |
    FetchDetailedCurrentEventSuccess |
    FetchSalesForceIntegrationForEvent |
    FetchSalesForceIntegrationForEventSuccess |
    FetchUserRolesForCurrentEvent |
    FetchUserRolesForCurrentEventSuccess;
