import { UserImport } from '../../../ApiClient/Models/Import/user-import';

export class UserImportValidator {
    private _isAccepted: boolean;
    private _statusMessages: string[];

    constructor(private _userImport: UserImport) {
        this._isAccepted = true;
        this._statusMessages = [];
    }

    validate(): void {
        if (this._userImport.totalErrorRecords > 1000) {
            this.addErrorMessage('Over 1000 invalid records detected');
        }
        if (this._userImport.totalGoodRecords === 0) {
            this.addErrorMessage('No valid records could be read from this file');
        }
        // if (this._userImport.canonicalHeaderNames) {
        //     this._userImport.canonicalHeaderNames.forEach((canonicalHeaderName) => {
        //        if (typeof canonicalHeaderName === 'number') {
        //            this.addErrorMessage('Unrecognizable header name in column ' + canonicalHeaderName);
        //        }
        //     });
        // }
    }

    private addErrorMessage(message: string): void {
            this._isAccepted = false;
            this._statusMessages.push(message);
    }

    get isAccepted(): boolean {
        return this._isAccepted;
    }

    get statusMessages(): string[] {
        return this._statusMessages;
    }
}
