import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthCallbackComponent} from './Auth/auth.callback.component';
import { EventFarmCoreRoutesComponent } from './eventFarmCoreRoutes.component';
import { EmptyComponent } from './empty.component';
import { AuthGuard } from './Auth/AuthGuard';

const eventFarmCoreRoutes: Routes = [

    {
        path: 'users/dashboard',
        component: EventFarmCoreRoutesComponent,
        // canActivate: [AuthGuard],

        children: [
            {
                path: 'auth/callback',
                component: AuthCallbackComponent
            },

            {
                path: 'admin',
                loadChildren: () => import('./AdminModule/admin.module').then(
                    module => module.AdminModule
                ),
                canActivate: [AuthGuard],

            },
            {
                path: 'team',
                loadChildren: () => import('./PoolsModule/pools.module').then(
                    module => module.PoolsModule
                ),
                canActivate: [AuthGuard],

            },
            {
                path: 'ticketblocks',
                loadChildren: () => import('./TicketBlockModule/ticket-block.module').then(
                    module => module.TicketBlockModule
                ),
                canActivate: [AuthGuard],

            },
            {
                path: '**',
                redirectTo: 'team',
                pathMatch: 'full'
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'users/dashboard',
        // component: EmptyComponent,
        // canActivate: [AuthGuard]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(eventFarmCoreRoutes)],
    exports: [RouterModule],
    providers: [AuthGuard],
})

export class AppRoutingModule {
}
