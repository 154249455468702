export interface EventsState {
    data: object;
    loaded: boolean;
    loading: boolean;
}

export const eventsInitialState = {
    data: {},
    loaded: false,
    loading: false,
};
