import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Router } from '@angular/router';
import { ProcessingService } from '../Processing/processing.service';
import { InvitationOptionsService } from '../../../CoreUI/InvitationOptions/invitationOptions.service';
import { StackSelectionService } from '../../../CoreUI/InvitationOptions/stackSelection.service';
import { UploaderService } from '../uploader.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { EventFarmService } from '../../../eventFarm.service';

@Injectable()
export class PreviewUploadService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private uploaderService: UploaderService,
        private eventFarmService: EventFarmService,
        private processingService: ProcessingService,
        private invitationOptionsService: InvitationOptionsService,
        private stackSelectionService: StackSelectionService,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    public completeAndFinish(onPreviewSuccess?: (userImportId: string) => void, onPostProcessSuccess?: (userImportId: string) => void) {
        let poolId = this.eventFarmService.currentTeam.id;
        let eventId = this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id ? this.eventFarmService.currentEvent.id : null;
        let userImportId = this.uploaderService.userImport.id;

        let completeUploadAPIOption = this.completeUploadForUsers();

        if (this.uploaderService.isEventContext && this.eventFarmService.currentEvent.isAllowFullGuestList) {
            completeUploadAPIOption = this.completeUploadForInvitations();
        }

        if (this.uploaderService.isEventContext && this.eventFarmService.currentEvent.isTypeCio) {
            completeUploadAPIOption = this.completeUploadForCIOInvitations();
        }

        completeUploadAPIOption
            .subscribe(
                (val) => {
                    if (onPreviewSuccess) {
                        onPreviewSuccess(userImportId);
                    } else {
                        if (eventId) {
                            this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.processing', { poolId, eventId, userImportId }));
                        } else {
                            this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.processing', { poolId, userImportId }));
                        }
                    }
                    this.processingService.currentState = 'post';
                    this.processingService.startPolling(val.data.id, userImportId, null, onPostProcessSuccess);

                }
            );
    }

    public completeUploadForInvitations() {
        if (this.uploaderService.newGroupName && this.uploaderService.group && this.uploaderService.group.name && (this.uploaderService.newGroupName !== this.uploaderService.group.name)) {
            this.uploaderService.group = null;
        }
        let eventId = this.eventFarmService.currentEvent.id;
        let poolId = this.eventFarmService.currentTeam.id;
        let userImportId = this.uploaderService.userImport.id;
        let stackId = this.invitationOptionsService.selectedStackId;
        let guestsPerInvitation = this.invitationOptionsService.invitationCount;
        let invitationCreationType = this.stackSelectionService.invitationCreationTypeSlug;
        let groupName = this.uploaderService.group && this.uploaderService.group.name ? this.uploaderService.group.name : this.uploaderService.newGroupName;
        let groupId = this.uploaderService.group && this.uploaderService.group.id ? this.uploaderService.group.id : null;

        if (this.invitationOptionsService.selectedStack.stackMethodType.isAnyInviteToRegister) {
            invitationCreationType = 'confirmed-no-email';
        }

        this.uploaderService.selectedStack = this.invitationOptionsService.selectedStack;
        this.uploaderService.hasSentInvitations = invitationCreationType === 'send-email' ? true : false;

        return this.apiClient.getUseCaseFactory()
            .Import()
            .PostProcessAndImportInvitations(
                userImportId,
                eventId,
                stackId,
                guestsPerInvitation,
                invitationCreationType,
                groupName,
                groupId,
                this.routeGenerator.url('pools.uploader.finish', {poolId, eventId, userImportId})
            );
    }

    // TODO: ticketblock uploads method

    public completeUploadForCIOInvitations() {
        let eventId = this.eventFarmService.currentEvent.id;
        let poolId = this.eventFarmService.currentTeam.id;
        let userImportId = this.uploaderService.userImport.id;
        let groupName = this.uploaderService.group && this.uploaderService.group.name ? this.uploaderService.group.name : this.uploaderService.newGroupName;
        let groupId = this.uploaderService.group && this.uploaderService.group.id ? this.uploaderService.group.id : null;
        const invitationCount = 1;

        return this.apiClient.getUseCaseFactory()
            .Import()
            .PostProcessAndImportInvitations(
                userImportId,
                eventId,
                null,
                invitationCount,
                null,
                groupName,
                groupId,
                this.routeGenerator.url('pools.uploader.finish', {poolId, eventId, userImportId})
            );
    }

    public completeUploadForUsers() {
        let groupName = this.uploaderService.group && this.uploaderService.group.name ? this.uploaderService.group.name : this.uploaderService.newGroupName;
        let groupId = this.uploaderService.group && this.uploaderService.group.id ? this.uploaderService.group.id : null;
        let poolId = this.eventFarmService.currentTeam.id;
        let userImportId = this.uploaderService.userImport.id;

        return this.apiClient.getUseCaseFactory()
            .Import()
            .PostProcessAndImportUsers(
                userImportId,
                groupName,
                groupId,
                this.routeGenerator.url('pools.uploader.finish', {poolId, userImportId})
            );
    }
}
