import { Component, OnDestroy, OnInit } from '@angular/core';
import { WebPresenceService } from './web-presence.service';
@Component({
    selector: 'web-presence',
    template: require('./web-presence.html'),
    styles: [require('./web-presence.scss')],
})

export class WebPresenceComponent implements OnInit, OnDestroy {

    constructor(
        private webPresenceService: WebPresenceService,
    ) {
    }

    ngOnInit() {
        // this.webPresenceService.init();
    }

    ngOnDestroy() {
        // this.webPresenceService.reset();
    }
}
