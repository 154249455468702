import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminRoutingModule} from './admin.routing-module';
import {AdminComponent} from './admin.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BreadcrumbsModule} from '../CoreUI/Breadcrumbs/breadcrumbs.module';
import {LocalStorageService} from '../../_services/storage/local-storage.service';
import {ManageModule} from './Manage/manage.module';
import {UserModule} from './User/user.module';
import {DataModule} from './Data/data.module';
import {TeamPointOfContactModule} from './Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.module';
import {TeamPointOfContactService} from './Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import {TeamEmailMasksService} from './Manage/ManageTeam/TeamEmailMasks/team-email-masks.service';
import {AdminAlertModule} from './Alert/admin-alert.module';
import {AdminGuardService} from './admin.guard';
import {NgxUtilitiesModule} from '../../NGXUtilities/ngx.utilities.module';

const mainModules: any[] = [CommonModule];

const ngxOtherModules: any[] = [NgxChartsModule,  NgxUtilitiesModule];

const adminModules: any[] = [
    AdminRoutingModule,
    BreadcrumbsModule,
    ManageModule,
    UserModule,
    DataModule,
    TeamPointOfContactModule,
    AdminAlertModule,
];

@NgModule({
    imports: mainModules.concat(
        ngxOtherModules,
        adminModules
    ),
    declarations: [AdminComponent],
    providers: [
        LocalStorageService,
        TeamPointOfContactService,
        TeamEmailMasksService,
        AdminGuardService
    ],
})
export class AdminModule {
}
