import { AbstractBaseAlert } from '../base-alert';

export class TicketTypeAlert extends AbstractBaseAlert {

    confirmTicketTypeDeletion(): Promise<any> {
        return this.alert({
            title: 'Delete access type',
            text: 'Are you sure you want to delete this access type?',
            confirmButtonColor: '#ea2b38',
            focusCancel: true,
            showCancelButton: true,
            icon: 'question',
            confirmButtonText: 'Delete Type'
        });
    }

    ticketTypeRemovedConfirmation(): Promise<any> {
        return this.alert({
            title: 'Access type removed',
            text: 'Successfully deleted access type',
            icon: 'success'
        });
    }

    unableToRemove(): Promise<any> {
        return this.alert({
            title: 'Access Type',
            text: 'Unable to remove access type. Please check your permissions.',
            icon: 'error'
        });
    }


    confirmStackDeletion(): Promise<any> {
        return this.alert({
            title: 'Delete time period',
            text: 'Are you sure you want to delete this time period? This will remove all attendees associated with this period!',
            confirmButtonColor: '#ea2b38',
            focusCancel: true,
            showCancelButton: true,
            icon: 'question',
            confirmButtonText: 'Delete Time Period'
        });
    }
}
