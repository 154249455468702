import { Injectable } from '@angular/core';
import {NzTableQueryParams} from "ng-zorro-antd/table";
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {
    Communication,
    SendByValue,
    InvitationStatusValue,
    InvitationStatusOptionInterface
} from '../../../../ApiClient/Models/Communication';
import {Group} from '../../../../ApiClient/Models/Group/group';
import {Invitation} from '../../../../ApiClient/Models/Invitation/invitation';
import {SentSMSMessage} from "../../../../ApiClient/Models/SMS/sent-sms-message";
import {TicketBlock} from '../../../../ApiClient/Models/Stack/ticket-block';
import {TicketType} from '../../../../ApiClient/Models/Stack/ticket-type';
import {EventFarmService} from '../../../eventFarm.service';


@Injectable()


export class SMSReportsService {

    isLoading: boolean;
    isSending: boolean;
    errorSending: boolean;
    sent$ = new BehaviorSubject<boolean>(false);
    stats$ = new BehaviorSubject<any[]>([]);

    private _content: string = '';


    public filteredGroups$ =  new BehaviorSubject<any[]>([]);
    public filteredTicketBlocks$ =  new BehaviorSubject<any[]>([]);
    public totalResults = 0;
    public pageIndex = 1;
    public pageSize = 10;
    public sortBy = 'sent-at';
    public sortDir = 'descending';
    public query: '';
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
    ) {
    }

   public fetchStatsForEvent(){
        this.isLoading = true;
        this.apiClient.getUseCaseFactory().SMSMessage().ListSentSMSMessagesForEvent(
            this.eventFarmService.currentEvent.id,
            ['SMSNotifications'],
            this.pageIndex,
            this.pageSize,
            this.sortBy,
            this.sortDir,
            this.query
        ).subscribe(val =>{
            this.totalResults = val.meta.totalResults;
            const smsData = val.data.map(d => SentSMSMessage.fromApiResponse(d));
            this.stats$.next(smsData);
            this.isLoading = false
         })
    }




    // Used to create autocomplete options for group search

    //  Responsible for hitting the correct endpoint
    handlePageIndexChange(val) {
        this.pageIndex = val
        this.fetchStatsForEvent()
    }


    //  This is simplified b/c we only allow sort by Sent At
    queryParamsChange($event: NzTableQueryParams) {
        if ($event.sort && $event.sort.length > 0){
            const targetDir = $event.sort[0].value;
            if (targetDir === 'descend' || targetDir === null){
                this.sortDir = 'descending';
            } else {
                this.sortDir = 'ascending'
            }

        }
        this.fetchStatsForEvent()
    }
}
