import { tap, take, takeWhile, filter, map, switchMap, shareReplay } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from './../../../ApiClient/event-farm-api-client';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { Observable, of } from 'rxjs';
import * as eventActions from './../../store/actions/event';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AlertService } from '../../eventFarmAlert.service';
import { Stack } from '../../../ApiClient/Models/Stack/stack';

@Injectable()
export class WaitListService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private store: Store<fromRoot.AppState>,
        private message: NzMessageService,
        private actions$: Actions,
        private alertService: AlertService,
    ) {
        this.updateWaitList();
        this.attachLocalModelSubscribers();
    }

    public currentWaitlist: string[] = [];
    public invitationsToUpdate = [];
    public selectAll: boolean = false;
    public shouldNotifyGuest: boolean = true;
    public eventStore$: Observable<any> = this.store.select('event');

    toastrOptions = {
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        newestOnTop: true,
        showMethod: 'slideDown'
    };

    initWaitlist() {
        this.waitListOptions.page = 1;
        this.store.dispatch(new eventActions.FetchWaitList(this.waitListOptions));
    }

    public fetchWaitList(eventId, data): Observable<any> {

        this.receiveOptions(data);

        return this.apiClient.getUseCaseFactory().Invitation().ListWaitlistForEvent(
            eventId,
            this.waitListOptions.withData,
            this.waitListOptions.withUserAttributes,
            this.waitListOptions.query,
            this.waitListOptions.lastModifiedTimestamp,
            this.waitListOptions.isCheckedIn,
            this.waitListOptions.sortBy ? this.waitListOptions.sortBy : 'created-at',
            this.waitListOptions.sortDirection,
            this.waitListOptions.query === '' ? this.waitListOptions.page : 1,
            this.waitListOptions.itemsPerPage
        );
    }

    public waitListOptions: any = {
        eventId: '',
        withData: ['StackAndTicketType'],
        withUserAttributes: '',
        query: '',
        lastModifiedTimestamp: null,
        isCheckedIn: null,
        sortBy: 'created-at',
        sortDirection: 'ascending',
        page: 1,
        itemsPerPage: 20
    };

    private updateWaitList = () => this.store.select('event').pipe(
        filter(event => event.invitations && !event.invitations.loading && event.invitations.waitlist),
        map(event => event.invitations.waitlist),
        filter(waitlist => waitlist.meta.totalResults > 0 && Object.keys(waitlist.data).length === 0),
        tap((waitlist) => {
            if (waitlist.meta.totalPages === this.waitListOptions.page) {
                this.waitListOptions.page--;
            }
            return this.store.dispatch(new eventActions.FetchWaitList());
        }),
    ).subscribe()

    public receiveOptions(data) {
        return this.waitListOptions = {...this.waitListOptions, ...data};
    }

    public changePage() {
        this.invitationsToUpdate = [];
        this.store.dispatch(new eventActions.FetchWaitList(this.waitListOptions));
    }

    public updateInvitation(invitation, type, bulk = false) {
        type = this.formatUpdateType(type);
        let data;
        if (this.invitationsToUpdate.length) {
            data = {
                invitationId: bulk ? this.invitationsToUpdate : [invitation.id],
                stackId: invitation.stack ? invitation.stack.id : null,
                type: type,
                sendNotification: this.shouldNotifyGuest
            };
        } else {
            data = {
                invitationId: [invitation.id],
                stackId: invitation.stack ? invitation.stack.id : null,
                type: type,
                sendNotification: this.shouldNotifyGuest
            };
        }
        this.store.dispatch(new eventActions.RemoveInvitationFromWaitlist(data));
        this.handleNotifier(invitation, type, bulk);
    }

    public formatUpdateType(type) {
        switch (type.toLowerCase()) {
            case 'delete':
                return 'recycled';
            default:
                return type;
        }
    }

    public async addToWaitlist(id: string, stacks: Stack[]) {
        await this.alertService.guestList().addWaitlistUser(id, stacks);
        return this.store.dispatch(new eventActions.FetchWaitList());
    }

    private handleNotifier(invitation, type, bulk) {
        let user = this.invitationsToUpdate.length;
        if (!bulk) {
            user = invitation.userName && invitation.userName.fullName !== ' ' ? invitation.userName.fullName : invitation.user.primaryEmail;
        }
        const notify = this.message.loading(this.formatNotifierMessage(user, type, bulk), { nzDuration: 0 }).messageId;
        this.actions$.pipe(
            ofType(eventActions.REMOVE_INVITATION_FROM_WAITLIST_SUCCESS),
            take(1)
        ).subscribe(() => {
            this.message.remove(notify);
            this.message.success(this.formatNotifierMessage(user, type, bulk, true));
            this.invitationsToUpdate = [];
        });
    }

    public formatNotifierMessage(userData, type, bulk, complete = false) {
        if (type === 'confirmed-by-rsvp' && !bulk) {
            if (!complete) { return `Promoting ${userData} to Confirmed...`; }
        }

        if (type === 'confirmed-by-rsvp' && bulk) {
            if (!complete) { return `Promoting ${userData} invitations to Confirmed...`; }
        }

        if (type === 'unconfirmed' && !bulk) {
            if (!complete) { return `Promoting ${userData} to Unconfirmed...`; }
        }

        if (type === 'unconfirmed' && bulk) {
            if (!complete) { return `Promoting ${userData} invitations to Unconfirmed...`; }
        }

        if (type !== 'recycled' && complete) {
            return `Promoted ${userData}`;
        }

        if (type === 'recycled') {
            if (!complete) { return `Deleting invitation for ${userData}...`; }
            return `Deleted ${userData}`;
        }
    }

    public addAll() {
        this.invitationsToUpdate = this.currentWaitlist;
    }

    public removeAll() {
        this.invitationsToUpdate = [];
    }

    private attachLocalModelSubscribers(): void {

        this.eventStore$.subscribe((val) => {
            this.currentWaitlist = null;
            if (val.invitations && val.invitations.waitlist) {
                this.currentWaitlist = Object.keys(val.invitations.waitlist.data);
            }
        });
    }

    ngOnDestroy() {
        this.updateWaitList().unsubscribe();
    }
}
