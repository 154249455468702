import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { Observable ,  Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
@Component({
    selector: 'web-presence',
    template: require('./event-promotions.html'),
    styles: [require('./event-promotions.scss')],
})

export class EventPromotionsComponent implements OnDestroy, OnInit {
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;
    constructor(
        private eventFarmService: EventFarmService,
        private legacyUIService: LegacyUIService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.legacyUIService.fetchAndCompileTemplateContents('/events/configPromotions/' + val.data.id, 'event-promos-tab-content', val.data);
        });
    }

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().promoCodesEntered();
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
}
