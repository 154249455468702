import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'picture-cropper',
    template: require('./picture-cropper.html'),
    styles: [require('./picture-cropper.scss')],
})

export class PictureCropperComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() cropperShape: string;
    @Input() image: string;
    @Output() imageUploaded: EventEmitter<string> = new EventEmitter();
    @Output() imageSet: EventEmitter<boolean> = new EventEmitter();

    public cropperRatio: number = 3 / 3;
    public editing: boolean = false;
    public imageSaved: boolean = false;
    public imageChangedEvent: any = '';
    public croppedImage: any = '';
    public isRound: boolean = false;
    public showCropper: boolean = true;
    public croppedWidth: number = 256;
    public croppedHeight: number = 256;
    public isLargePreview = false;

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        this.editing = true;
        this.imageSaved = false;
    }

    imageCropped(event: ImageCroppedEvent) {
        if (this.showCropper) {
            this.croppedImage = event.base64;
        }
    }

    imageLoaded(event) {
        if (!this.showCropper) {
            this.croppedImage = event.original.base64;
            this.cropAndSaveImage();
        } else {
            this.imageSet.emit(true);
        }
    }

    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    constructor(
        private drawerService: NzDrawerService
    ) { }

    ngOnInit() {
        this.setCropperShape();
        this.checkIfImageExists();
    }

    public setCropperShape() {
        switch (this.cropperShape) {
            case 'square':
                this.cropperRatio = 3 / 3;
                break;
            case 'circle':
                this.isRound = true;
                break;
            case 'rectangle':
                this.cropperRatio = 4 / 3;
                break;
            case 'scan-screen':
                this.cropperRatio = 0.56;
                this.croppedWidth = 750;
                this.croppedHeight = 1334;
                this.isLargePreview = true;
                break;
            case 'none':
                this.showCropper = false;
                break;
        }
    }

    public checkIfImageExists() {
        if (this.image) {
            this.croppedImage = this.image;
            this.editing = false;
            this.imageSaved = true;
        }
    }

    public cropAndSaveImage() {
        this.editing = false;
        this.imageSaved = true;
        this.imageUploaded.emit(this.croppedImage);
    }

    public removeImage() {
        this.croppedImage = '';
        this.editing = false;
        this.imageSaved = false;
        this.imageChangedEvent = '';
        this.imageUploaded.emit(null);
    }
}
