import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { PoolContact } from '../../../../../ApiClient/Models/PoolContact/poolContact';
import { User } from '../../../../../ApiClient/Models/User/user';
import { EventFarmService } from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import * as fromStore from '../../../../store';
import * as adminActions from '../../../../store/actions/admin/';
import { AdminService } from '../../../admin.service';
import { TeamPointOfContactService } from './team-point-of-contact.service';

import _ = require('lodash');

@Component({
  selector: 'team-point-of-contact',
  template: require('./team-point-of-contact.html'),
  styles: [require('./team-point-of-contact.scss')],
})
export class TeamPointOfContactComponent implements OnInit, OnDestroy {
  @Input() public team: Team;
  public teamPointOfContactForm: FormGroup;
  public existingContactsForm: FormGroup;
  public poolContactTypes: any;
  public contactsToUpdate: {};
  public existingContacts: {};
  public modifiedContacts: {};
  public dynamicForm: FormGroup;
  public dynamicContactForm: FormGroup;
  public submitted = false;
  public contactsSub$: Subscription;
  public currentUserEmail: string;

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    public teamPointOfContactService: TeamPointOfContactService,
    private store: Store<fromStore.AppState>

  ) {}
  private pcs = [];
  public handleModifiedContact(event, contactId) {
    this.modifiedContacts[contactId] = event.slug;
  }
  
  // TODO use redux for this
  public  async updateContacts() {
    const touched = this.t2.controls.filter((control) => !control.pristine);
    const promises = touched.map((form) =>
      this.teamPointOfContactService.updatePoolContact(
        form.value.id,
        form.value.contactType
      )
    );
    try {
      await Promise.all(promises);
      await this.teamPointOfContactService
        .fetchContactsByPoolId(this.team.id)
        .toPromise();
      this.message.success('Team Contact role(s) updated!');
    } catch (error) {
      this.message.error('Error updating team contact roles');
    }
  }

  async removeContact(contact: PoolContact) {
      const confirm = await this.alertService.pointOfContact().confirmDeletion(contact);
      if (confirm.isConfirmed) {
        this.message.info('Removing user roles...');
        try {
            await this.teamPointOfContactService.removeUserRoles(contact.user.id, this.team.id).toPromise();
            this.message.success('User Roles Removed For Pool Events');
            this.store.dispatch(new adminActions.RemoveContactFromAdminTeam({
              teamId: this.team.id,
              contactId: contact.id,
              userId: contact.user.id
            }));
        } catch (error) {
          this.message.error('Error updating team contact roles');
        }
    } else if (confirm.isDenied) {
        this.store.dispatch(new adminActions.RemoveContactFromAdminTeam({
          teamId: this.team.id,
          contactId: contact.id,
          userId: contact.user.id
        }));
    }
  }

  async updateUserName(contact: PoolContact) {
    const confirm = await this.alertService.pointOfContact().updateUserName(this.team.id, contact);
      if (confirm.value) {
        this.message.info('Updating name...');
        this.store.dispatch(new adminActions.FetchContactsForAdminTeam());

    } 
  }

    async triggerResendWelcomeEmailPrompt(contact: PoolContact) {
        const confirm = await this.alertService.welcomeEmail().resendWelcomeEmail();
        if (confirm.value) {
            this.message.info('Re-sending welcome email...');
            try {
                await this.apiClient.getUseCaseFactory().PoolContact().ResendPoolContactEmail(this.team.id, contact.user.id).toPromise();
                this.message.success('Email resent');
            } catch (error) {
                this.message.error('Error sending email');
            }
        }
    }


  async addContact() {
    const email = this.teamPointOfContactForm.get('newContactEmail').value;
    try {
      const userData = await this.apiClient.getUseCaseFactory().User().GetUserByEmail(email, ['UserName'], [], this.team.id).toPromise();
      const user: User = User.fromApiResponse(userData.data);
      if (!user.name) {
        await this.alertService.pointOfContact().addNewUser(this.team.id, email);
      }
    } catch (err) {
      await this.alertService.pointOfContact().addNewUser(this.team.id, email);
    }

    this.store.dispatch(new adminActions.AddContactToAdminTeam({
      teamId: this.team.id,
      email: this.teamPointOfContactForm.get('newContactEmail').value,
      type: this.teamPointOfContactForm.get('newContactType').value.slug
    }));
    this.teamPointOfContactForm.controls['newContactEmail'].setValue('');
  }

  public  ngAfterContentInit() {
   this.contactsSub$ =  this.teamPointOfContactService.contactsData$.subscribe((pcs: PoolContact[]) => {
      this.pcs = _.cloneDeep(pcs);
      const groups = pcs.map((pc) => {
        return this.formBuilder.group({
          id: [pc.id, Validators.required],
          name: [pc.user.name.fullName, Validators.required],
          email: [pc.email, [Validators.required, Validators.email]],
          userId: [pc.user.id, [Validators.required]],
          user: [pc.user, [Validators.required]],
          contactType: [
            pc.contactType.slug || 'Team Manager',
            [Validators.required, Validators.email],
          ],
        });
      });

      if (pcs.length > 0 ) { this.dynamicContactForm.setControl('contacts', new FormArray(groups)); }

    });
  }

   public ngOnInit() {
        this.store.dispatch(new adminActions.FetchContactsForAdminTeam());
        this.poolContactTypes = this.apiClient
      .getTypeFactory()
      .Pool()
      .PoolContactType();

        this.teamPointOfContactForm = new FormGroup({
      newContactEmail: new FormControl(''),
      newContactType: new FormControl(this.poolContactTypes[0], Validators.required),
    });
        // this.teamPointOfContactForm.controls['newContactType'].setValue('full');
        this.dynamicForm = this.formBuilder.group({
      numberOfTickets: ['', Validators.required],
      tickets: new FormArray([]),
    });
        this.dynamicContactForm = this.formBuilder.group({
      contacts: new FormArray([]),
    });
    this.currentUserEmail = this.eventFarmService.currentUser.primaryEmail;
  }

  // convenience getters for easy access to form fields
  contacts: any;

  get f2() {
    return this.dynamicContactForm.controls;
  }

  get t2() {
    return this.f2.contacts as FormArray;
  }

  onChangeContacts(e) {
    const numberOfTickets = e.target.value || 0;
    if (this.t2.length < numberOfTickets) {
      for (let i = this.t2.length; i < numberOfTickets; i++) {
        this.t2.push(
          this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
          })
        );
      }
    } else {
      for (let i = this.t2.length; i >= numberOfTickets; i--) {
        this.t2.removeAt(i);
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dynamicForm.invalid) {
      return;
    }

    // display form values on success
    alert(
      'SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4)
    );
  }

  onReset() {
    // reset whole form back to initial state
    this.submitted = false;
    this.dynamicForm.reset();
    this.t2.clear();
  }

  onClear() {
    // clear errors and reset ticket fields
    this.submitted = false;
    this.t2.reset();
  }

  ngOnDestroy() {
    this.contactsSub$.unsubscribe();
  }

  public ghostUser(email: string) {
      this.adminService.setGhostingAsEmail(email);
      const redirectUrl = `${window.location.protocol}//${window.location.host}/users/dashboard`;
      window.location.href = redirectUrl;
  }
}
