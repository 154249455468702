import { Component, OnInit } from '@angular/core';
import { EFXAdminService } from '../efx-admin.service';

@Component({
    selector: 'efx-modules',
    template: require('./efx-modules.html'),
    styles: [require('./efx-modules.scss'), require('../efx-admin.scss')],
})

export class EFXModulesComponent implements OnInit {

    constructor(
         private efxAdminService: EFXAdminService
    ) { }

    ngOnInit() {
    }

}
