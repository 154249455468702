import { AbstractModel } from '../abstract-model';

interface Date {
    date: string;
    timezone: string;
    timezone_type: number;
}
export class PoolContract extends AbstractModel {
    private _startDate?: Date;
    private _endDate?: Date;
    private _contractType?: string;

    constructor( id?: string) {
        super(id);
    }

    public static fromApiResponse(data): PoolContract {
        const poolContract = new this(data.id);
        poolContract._startDate = data.attributes.startDate;
        poolContract._endDate = data.attributes.endDate;
        poolContract._contractType = data.attributes.poolContractType.slug;
        return poolContract;
    }
    get startDate(): Date {
        return this._startDate;
    }
    get endDate(): Date {
        return this._endDate;
    }
    get contractType(): string {
        return this._contractType;
    }
    get id(): string {
        return this._id;
    }
}
