import { NgModule } from '@angular/core';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {AccordionModule} from 'ngx-bootstrap/accordion'
import {AlertModule} from 'ngx-bootstrap/alert'
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'
import {CollapseModule} from 'ngx-bootstrap/collapse'
import {PaginationModule} from 'ngx-bootstrap/pagination'
import {ProgressbarModule} from 'ngx-bootstrap/progressbar'
import {TabsModule} from 'ngx-bootstrap/tabs'
import {TooltipModule} from 'ngx-bootstrap/tooltip'
import {TypeaheadModule} from 'ngx-bootstrap/typeahead'

const forExport = [
    PopoverModule,
    AccordionModule,
    AlertModule,
    BsDropdownModule,
    CollapseModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    TypeaheadModule
];

@NgModule({
    exports: forExport,
})

export class NgxUtilitiesModule {
}
