import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { GuestListViewModule } from '../../CoreUI/GuestListTableView/guest-list-view.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { DynamicFormModule } from '../../CoreUI/Forms/dynamic-form.module';
import { GuestListRoutingModule } from './guest-list-routing.module';
import { GuestListComponent } from './guest-list.component';
import { GuestListService } from './guest-list.service';
import { GuestListUserService } from './guest-list-user.service';
import { InviteAddGuestComponent } from './Invite/inviteAddGuest.component';
import { TheListComponent } from './List/the-list.component';
import { AddGroupComponent } from './Invite/AddGroup/addGroup.component';
import { AddGuestComponent } from './Invite/AddIndividual/addGuest.component';
import { AddGroupService } from './Invite/AddGroup/addGroup.service';
import { InvitationOptionsModule } from '../../CoreUI/InvitationOptions/invitationOptions.module';
import { GroupTypeaheadModule } from '../../CoreUI/Typeahead/group-typeahead.module';
import { UserTypeaheadModule } from '../../CoreUI/Typeahead/user-typeahead.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';
import {NgxIntlTelInputModule} from '@khazii/ngx-intl-tel-input';

@NgModule({
    bootstrap: [GuestListComponent],
    declarations: [
        AddGroupComponent,
        AddGuestComponent,
        GuestListComponent,
        InviteAddGuestComponent,
        TheListComponent,
    ],
    entryComponents: [
        InviteAddGuestComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        DynamicFormModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        UserTypeaheadModule,
        GroupTypeaheadModule,
        BreadcrumbsModule,
        InvitationOptionsModule,
        GuestListViewModule,
        GuestListRoutingModule,
        NgxUtilitiesModule,
        NgxIntlTelInputModule
    ],
    providers: [
        GuestListService,
        GuestListUserService,
        AddGroupService,
    ],
})

export class GuestListModule {
}
