import { Component, Input, OnInit } from '@angular/core';
import { EditPromoButtonComponent } from './EditPromo/edit-promo-button.component';
import { IconService } from '../../../../CoreUI/Icons/icons.service';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';


import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import * as eventActions from '../../../../store/actions/event';
import { Promo } from '../../../../../ApiClient/Models/Promotion/promo';

@Component({
    selector: '[promo-item]',
    template: require('./promo-item.html'),
    styles: [require('./promo-item.scss')],
})

export class PromoItemComponent implements OnInit {
    @Input() promo: Promo;
    private promotionStatusColor: { borderLeft: string };
    private currentTime = + new Date() / 1000;

    constructor(
        private iconService: IconService,
        private store: Store<fromRoot.AppState>) { }

    ngOnInit() {
        this.getPromotionColor();
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    getPromotionColor() {


        let duringPromotion;

        if (this.promo.startTime && this.promo.endTime) {
            duringPromotion = this.promo.startTime < this.currentTime && this.promo.endTime > this.currentTime;

            if (!this.promo.isEnabled) {
                return this.promotionStatusColor = { borderLeft: '4px grey solid' };
            }
            if (this.promo.startTime > this.currentTime) {
                return this.promotionStatusColor = { borderLeft: '4px orange solid' };
            }
            if (this.promo.endTime < this.currentTime) {
                return this.promotionStatusColor = { borderLeft: '4px red solid' };
            }
        } else {
            duringPromotion = true;
            if (!this.promo.isEnabled) {
                return this.promotionStatusColor = { borderLeft: '4px grey solid' };
            }
        }

        if ( this.promo.maximum === null) {
            return this.promotionStatusColor = { borderLeft: '4px green solid' };
        } else {
            if (duringPromotion && ( this.promo.maximum - this.promo.used >= 1 )) {
                return this.promotionStatusColor = { borderLeft: '4px green solid' };
            }
            if (duringPromotion && ( this.promo.maximum - this.promo.used <= 0 )) {
                return this.promotionStatusColor = { borderLeft: '4px red solid' };
            }
        }
    }

}
