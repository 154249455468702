import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AdminService } from '../admin.service';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { FormControl, FormGroup } from '@angular/forms';
import {CreateTeamComponent} from './CreateTeam/create-team.component';
import { Subject } from 'rxjs';
import * as fromRoot from '../../store';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'manage',
  template: require('./manage.html'),
  styles: [require('./manage.scss')],
})
export class ManageComponent implements OnInit, OnDestroy {
  public manageTeamsForm: FormGroup;

  public placeholder: string = 'Enter the Team Name';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  public selectedTeamId: any;
  public selectedTeam: Team;
  public searchTerm: any;
  public selected: any;

  private cancelSubscriptions$ = new Subject<void>();

  constructor(
    private store: Store<fromStore.AppState>,
    public adminService: AdminService,
    private drawerService: NzDrawerService
  ) {}

  public ngOnInit() {
    this.manageTeamsForm = new FormGroup({
      selectedTeam: new FormControl(''),
    });

    this.store.select(fromRoot.getAdminIsArchivingCurrentTeam)
        .pipe(
            takeUntil(this.cancelSubscriptions$),
            filter(isArchiving => isArchiving))
        .subscribe(() => this.manageTeamsForm.get('selectedTeam').setValue(''));
  }

  public ngOnDestroy() {
      this.cancelSubscriptions$.next();
  }

  public handleTeamChange(team) {
    this.store.dispatch(new fromStore.SetCurrentAdminTeam(team));
  }

  public openNewTeamSlideout() {
    const drawerRef = this.drawerService.create<CreateTeamComponent, {}, Team>({
      nzTitle: '',
      nzContent: CreateTeamComponent,
      nzWidth: '50%',
      nzClosable: true,
    });
    drawerRef.afterClose.subscribe(async (data) => {
      if (data) {
        // this.getStatusColor();
      }
    });
  }
}

