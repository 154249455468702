import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddGuestComponent } from './Invite/AddIndividual/addGuestToTicketBlock.component';
import { AddGroupComponent } from './Invite/AddGroup/addGroupToTicketBlock.component';
import { InviteAddGuestComponent } from './Invite/inviteAddGuestToTicketBlock.component';
import { TicketBlockInvitationListComponent } from './ticketBlockInvitationList.component';

import { TicketBlockDashboardComponent } from './ticketBlockDashboard.component';
import { TicketBlockResolver } from '../ticket-block-resolver.service';

const routes: Routes = [
    {
        path: '',
        component: TicketBlockDashboardComponent,
        data: { state: 'open-ticket-block' },
        resolve: {
            ticketBlock: TicketBlockResolver
        },
        children: [
            {
                path: 'list',
                component: TicketBlockInvitationListComponent,
            },
            {
                path: 'invite',
                component: InviteAddGuestComponent,
            },
            {
                path: 'invite/individual',
                component: AddGuestComponent,
            },
            {
                path: 'invite/group',
                component: AddGroupComponent,
            },
            {
                path: '**',
                redirectTo: 'list',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class TicketBlockDashboardRoutingModule {
}