import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventFarmService } from '../../../../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../../../../admin.service';
import { TeamEventsService } from '../../../team-events.service';
import { EditEventEfxService } from './edit-event-efx.service';
import { PoolContact } from '../../../../../../../../ApiClient/Models/PoolContact/poolContact';

@Component({
  selector: 'edit-event-efx',
  template: require('./edit-event-efx.html'),
  styles: [require('./edit-event-efx.scss')],
})
export class EditEventEfxComponent implements OnInit, OnDestroy {
  @Input() eventId: string;
  dynamicContactForm: FormGroup;
  defaultModules: any[];
  enabledModulesForEvent$ = new BehaviorSubject<PoolContact[]>([]);
  enabledModulesForEvent: any[];
  private isSaving: boolean = false;
  private eventTypes;
  dynamicForm: FormGroup;
  editEventsListItemForm: FormGroup;
  private modules$: Subscription;

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private eventFarmService: EventFarmService,
    private formBuilder: FormBuilder,
    private apiClient: EventFarmAPIClient,
    private adminService: AdminService,
    private messageService: NzMessageService,
    private teamEventsService: TeamEventsService,
    private editEventEfxService: EditEventEfxService
  ) {}

  get f2() {
    return this.dynamicForm.controls;
  }

  get t2() {
    return this.f2.modules as FormArray;
  }

  ngOnInit() {
    this.eventTypes = this.apiClient.getTypeFactory().Event().EventType();
    this.isSaving = false;
    this.dynamicForm = this.formBuilder.group({
      modules: new FormArray([]),
    });

    this.defaultModules = this.editEventEfxService.getModuleAdminSwitchData();

    this.modules$ = this.editEventEfxService
      .fetchModulesForEvent(this.eventId)
      .subscribe((res) => {
        this.enabledModulesForEvent$.next(res.data);
        this.enabledModulesForEvent = res.data;
        this.populateEfxModuleControls(res.data.attributes);
      });
  }
  private isModuleEnabledForEvent(slug) {
    const tmp = this.enabledModulesForEvent.filter((o) => o.slug === slug);
    if (tmp.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private populateEfxModuleControls(attributes) {
    this.defaultModules.map((mod) => {
      this.t2.push(
        this.formBuilder.group({
          id: [mod.name, Validators.required],
          description: [mod.description, Validators.required],
          slug: [mod.slug, Validators.required],
          enabled: [
            attributes[mod.id] ? true : false,
            Validators.required,
          ],
        })
      );
    });
  }

  close() {
    this.drawerRef.close();
  }

  async enableEfxForEvent() {
    try {
      await this.editEventEfxService
        .enableEfxForEvent(this.eventId)
        .toPromise();
      await this.adminService.fetchEvent(this.eventId);
      this.messageService.success('Enabled!');
    } catch (error) {
      this.messageService.error('Nope!');
    }
  }

  async disableEfxForEvent() {
    try {
      await this.editEventEfxService
        .disableEfxForEvent(this.eventId)
        .toPromise();
      await this.adminService.fetchEvent(this.eventId);
      this.messageService.success('Enabled!');
    } catch (error) {
      this.messageService.error('Nope!');
    }
  }

  async enableEfxModuleForEvent($event) {
    const touched = this.t2.controls.filter((control) => !control.pristine);
    const promises = touched.map((form) => {
      if (form.value.enabled) {
        return this.editEventEfxService
          .enableEfxModuleForEvent(this.eventId, form.value.slug)
          .toPromise();
      } else {
        return this.editEventEfxService
          .disableEfxModuleForEvent(this.eventId, form.value.slug)
          .toPromise();
      }
    });
    try {
      await Promise.all(promises);
      await this.adminService.fetchEvent(this.eventId);
      this.messageService.success('modified!');
    } catch (error) {
      this.messageService.error('Nope!');
    }
  }
  // Todo revisit using unsubscribe instead
  ngOnDestroy() {
    this.adminService.currentTeamEvent$.next(null);
    this.modules$.unsubscribe();
  }

}
