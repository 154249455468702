import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { shareReplay } from 'rxjs/operators';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EFProfile } from '../../../../../../ApiClient/Models/Profile/EFProfile';
import { EditSponsorComponent } from '../EditSponsor/edit-sponsor.component';

@Component({
    selector: '[sponsor-item]',
    template: require('./sponsor-item.html'),
    styles: [require('./sponsor-item.scss')],
})

export class SponsorItemComponent implements OnInit {
    @Input() sponsorItem: EFProfile;
    public componentClicked: boolean = false;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private store: Store<fromRoot.AppState>,
        private drawerService: NzDrawerService
    ) { }

    ngOnInit() {
    }

    openComponent(): void {
        if (!this.componentClicked) {
            this.componentClicked = true;
            const drawerRef = this.drawerService.create<EditSponsorComponent, { sponsor: EFProfile }, string>({
                nzTitle: 'Edit Sponsor',
                nzContent: EditSponsorComponent,
                nzWidth: '50%',
                nzContentParams: {
                    sponsor: this.sponsorItem,
                }
            });

            drawerRef.afterOpen.subscribe(() => {
            });

            drawerRef.afterClose.subscribe(data => {
                this.componentClicked = false;
            });
        }
    }

    public checkIfUrlIsImage(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    }
}
