import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Operator, Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { EFHelper } from '../Shared/helper';
import { Environment } from './ClientLibrary/config';
import { merge } from 'lodash';
import 'rxjs-compat/add/operator/catch';
import { AngularRestClientInterface } from './ClientLibrary/RestClient/AngularRestClientInterface';

@Injectable()

export class AngularApiRestClient implements AngularRestClientInterface {

    constructor(private http: HttpClient) {
    }

    get(requestPath: string, params: any, headers?: any, options?: any): Observable<any> {

        let getOptions = {};
        if (options != null) {
            getOptions = options;
        }

        const getHeaders = this.setHeaders(headers);
        const getParams = this.setParams(params);

        if (options != null) {
            getOptions = merge({
                headers: getHeaders,
                params: getParams,
            }, options);
        } else {
            getOptions = {
                headers: getHeaders,
                params: getParams
            };
        }

        return this.http.get(
            this.getFullUrl(requestPath),
            getOptions
        ).pipe(catchError(this.handleError));
    }

    post(requestPath: string, formParams: any, headers: any, options?: any) {
        const postHeaders = this.setHeaders(headers);
        return this.http.post(
            this.getFullUrl(requestPath),
            this.convertFormParams(formParams),
            {
                headers: postHeaders
            },
        );
    }

    postMultipart(requestPath: string, formParams: any, headers: any, options?: any) {
        const isPostMultipart = true;
        const postHeaders = this.setHeaders(headers, isPostMultipart);

        return this.http.post(
            this.getFullUrl(requestPath),
            this.getFormData(this.convertFormParams(formParams)),
            {
                headers: postHeaders,
                params: this.convertFormParams(formParams, false)
            }
        );
    }

    private setHeaders(headers: any, isMultiPartForm: boolean = false): HttpHeaders {
        let newHeaders = new HttpHeaders();
        headers.forEach((value, key) => {
            newHeaders = newHeaders.append(EFHelper.capitalizeFirstLetter(key), value);
        });

        if (isMultiPartForm) {
            newHeaders.append('Enctype', 'multipart/form-data');
        }

        return newHeaders;
    }

    private getFormData(data: any) {
        let formData: FormData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        return formData;
    }

    private setParams(params: any): HttpParams {
        let newParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                if (Array.isArray(params[key])) {
                    newParams = this.addArrayToParamsObject(key, params[key], newParams);
                } else if (params[key]) {
                    newParams = newParams.append(key, params[key]);
                }
            }
        }
        return newParams;
    }

    private convertFormParams(formParams: any, allowFiles = true): any {
        for (const key in formParams) {
            if (formParams.hasOwnProperty(key)) {
                if (formParams[key] === true) {
                    formParams[key] = 'true';
                } else if (formParams[key] === false) {
                    formParams[key] = 'false';
                } else if (formParams[key] === null || ((!allowFiles && key === 'image') || (!allowFiles && key === 'file'))) {
                    delete formParams[key];
                }
            }
        }

        return formParams;
    }

    private addArrayToParamsObject(headerKey: string, arrayParams: any, existingParams: HttpParams): any {
        arrayParams.forEach(paramKey => {
            existingParams = existingParams.append(headerKey + '[]', paramKey);
        });
        return existingParams;
    }

    private handleError(err: HttpErrorResponse): Observable<HttpErrorResponse> {
        throw(err);
    }

    private getFullUrl(requestPath: string): string {
        return Environment.host + '/' + Environment.path + '/' + Environment.version + '/' + requestPath;
    }
}
