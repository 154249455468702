import { tap, take, withLatestFrom, switchMap, filter, map, catchError, mergeMap, concatMap, shareReplay, concatMapTo, flatMap, merge, concat, zip, combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromStore from '../../app.state';
import { WaitListService } from '../../../EventsModule/WaitList/waitList.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import * as fromRoot from '../../../store';
import { forkJoin, of } from 'rxjs';
import {TeamFeatureService} from '../../../AdminModule/Manage/ManageTeam/TeamFeatures/team-feature.service';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';
import * as adminFeatureActions from '../../actions/admin/admin-feature.action';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class AdminFeatureEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamFeatureService: TeamFeatureService,
        private messageService: NzMessageService
    ) { }

    // @ts-ignore
    fetchFeaturesForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminFeatureActions.FetchFeaturesForAdminTeam>(adminFeatureActions.FETCH_FEATURES_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamFeatureService.fetchFeaturesByPoolId(team.id)
            .pipe(
                map(features => {
                    return features['data']
                        .map(f =>  PoolFeature.fromApiResponse(f));
                }),
                switchMap((features: PoolFeature[]) => [
                    new adminFeatureActions.FetchFeaturesForAdminTeamSuccess(features)
                ]),
            )),
        catchError(err => of(new adminFeatureActions.FetchFeaturesForAdminTeamFail(err)))
    ));


    // @ts-ignore
    addFeatureToAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminFeatureActions.AddFeatureForAdminTeam>(adminFeatureActions.ADD_FEATURE_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),

        switchMap(([action, team]) => this.teamFeatureService.addFeatureToPool(team.id, action.payload.featureId).pipe(
            switchMap((feature: PoolFeature) => [
                new adminFeatureActions.AddFeatureForAdminTeamSuccess(feature),
                this.messageService.success('Feature added to pool'),
                new adminFeatureActions.FetchFeaturesForAdminTeam(team.id),
            ]),
            catchError(err => {
                this.messageService.error('Error adding feature to pool');
                return of(new adminFeatureActions.AddFeatureForAdminTeamFail(err))
                }
            )
        )),
    ));


    // @ts-ignore
    removeFeatureFromAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminFeatureActions.RemoveFeatureForAdminTeam>(adminFeatureActions.REMOVE_FEATURE_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamFeatureService.removeFeatureFromPool(team.id, action.payload.featureId).pipe(
            switchMap((feature: PoolFeature) => [
                new adminFeatureActions.RemoveFeatureForAdminTeamSuccess(feature),
                this.messageService.success('Feature removed from pool'),
                new adminFeatureActions.FetchFeaturesForAdminTeam(team.id),
            ]),
            catchError(err => {
                this.messageService.error('Error removing feature from pool');
                return of(new adminFeatureActions.RemoveFeatureForAdminTeamSuccess(err));
            })
            )),
        ));


    // @ts-ignore
    enableFeatureForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminFeatureActions.EnableFeatureForAdminTeam>(adminFeatureActions.ENABLE_FEATURE_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap((([action, team]) => this.teamFeatureService.enableFeatureForPool(team.id, action.payload.featureId).pipe(
            switchMap((feature: PoolFeature) => [
                new adminFeatureActions.EnableFeatureForAdminTeamSuccess(feature),
                this.messageService.success('Feature enabled for pool'),
                new adminFeatureActions.FetchFeaturesForAdminTeam(team.id),
            ]),
            catchError(err => {
                this.messageService.error('Error enabling feature for pool');
                return of(new adminFeatureActions.EnableFeatureForAdminTeamFail(err));
            })
            )),
        )));


    // @ts-ignore
    disableFeatureForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminFeatureActions.DisableFeatureForAdminTeam>(adminFeatureActions.DISABLE_FEATURE_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap((([action, team]) => this.teamFeatureService.disableFeatureForPool(team.id, action.payload.featureId).pipe(
            switchMap((feature: PoolFeature) => [
                new adminFeatureActions.DisableFeatureForAdminTeamSuccess(feature),
                this.messageService.success('Feature disabled for pool'),
                new adminFeatureActions.FetchFeaturesForAdminTeam(team.id),
            ]),
            catchError(err => {
                this.messageService.error('Error disabling feature for pool');
                return of(new adminFeatureActions.DisableFeatureForAdminTeamFail(err));
            })
            )),
        )));

}
