import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EventFarmService } from '../../eventFarm.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Observable, of, forkJoin } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Contract } from '../../../ApiClient/Models/Contract/contract';
import {catchError} from "rxjs/operators";

@Injectable()

export class MyAccountResolver implements Resolve<any> {
    constructor(
        private client: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let poolId = route.params.pool;

        if (poolId) {
            return this.gatherAccountContract(poolId);
        }

        this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', { poolId: this.eventFarmService.currentTeam.id }));
        return;
    }

    gatherAccountContract(poolId: string): Observable<any> {

        return forkJoin([
            this.client.getUseCaseFactory().Event().GetEventCountsForPool(poolId).pipe(catchError(() => of({error: true}))),
            this.client.getUseCaseFactory().PoolContract().CountPoolContractUsers(poolId).pipe(catchError(() => of({error: true}))),
            this.client.getUseCaseFactory().PoolContract().GetEmailCountsForPoolContract(poolId).pipe(catchError(() => of({error: true}))),
            this.client.getUseCaseFactory().Pool().ListPoolContactsByPoolId(poolId, 1, 500).pipe(catchError(() => of({error: true}))),
        ])
    }
}