import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Operator } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { EmailDesignCenterService } from '../email-design-center.service';
import { GuestMessagingSendOptionsService } from './guest-messaging-send-options.service';
import { Group } from '../../../../ApiClient/Models/Group/group';

@Component({
    selector: 'guest-messaging-group-search',
    template: require('./guest-messaging-group-search.html'),
    styles: [require('./guest-messaging-group-search.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestMessagingGroupSearchComponent implements OnInit {
    @Input() context;
    @ViewChild('input', {static: false})
    inputRef: ElementRef;

    readonly placeholder = 'Search Existing Groups.';

    public query: string;
    public dataSource: Observable<any>;

    public typeaheadLoading: boolean;
    public typeaheadNoResults: boolean;

    public selectedGroup: boolean;
    public selectedGroupName: string;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private guestMessagingSendOptionsService: GuestMessagingSendOptionsService,
    ) {
    }

    ngOnInit() {
    }

    setQuery(e) {
        this.query = e.target.value;
    }

    private selectGroup(group: Group): void {
        this.guestMessagingSendOptionsService.selectedGroup = group;
        this.selectedGroupName = group.name;
        this.selectedGroup = true;
    }

    private clearSelection() {
        this.guestMessagingSendOptionsService.selectedGroup = null;
        this.selectedGroupName = '';
        this.selectedGroup = false;
    }

    changeTypeaheadLoading(isLoading: boolean): void {
        this.typeaheadLoading = isLoading;
    }

    changeTypeaheadNoResults(hasNoResults: boolean): void {
        this.typeaheadNoResults = hasNoResults;
    }
}
