import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'edit-save-button',
    template: `
        <button
            nz-button
            nzType="primary"
            class="main-action ef-btn-sm"
            (click)="handleEditSave()"
            [nzLoading]="isSaving"
        >
            {{ this.editMode ? 'Save' : this.isSaving ? 'Saving...' : 'Edit' }}
        </button>
    `
})

export class EditSaveButtonComponent {

    private editMode = false;
    private buttonText = 'Edit';

    @Input() isSaving;
    @Output() clicked: EventEmitter<any> = new EventEmitter();

    private handleEditSave() {

        if (this.editMode) {
            this.clicked.emit({save: true, edit: false});
        }

        if (!this.editMode) {
            this.clicked.emit({save: false, edit: true});
        }

        this.editMode = !this.editMode;
    }
}
