import { Component, OnInit, ViewEncapsulation, ElementRef, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { AccountInfoService } from '../account-info.service';

import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { ModalServiceComponent } from '../../../CoreUI/Modal/modalService.component';

@Component({
    selector: 'request-upgrade',
    template: require('./request-upgrade.html'),
    styles: [require('./request-upgrade.scss')],
})

export class RequestUpgradeComponent implements OnDestroy, OnInit {

    private featureRequestForm: FormGroup;
    private error: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly gearsIcon = require('./../assets/gears');
    readonly successIcon = require('./../assets/green-success-check');



    constructor(
        private accountUsageService: AccountInfoService,
        public modalService: ModalService,
        private formBuilder: FormBuilder,
    ) {

        this.featureRequestForm = this.formBuilder.group({
            'Increase Usage Allotments': false,
            'Other Check': false,
            'Other Text': null,
        });

        this.addToFormGroup();
    }

    ngOnInit() {
        this.accountUsageService.sumbittingStatus$.next({text: 'Send Request', status: 'not-sent'});
    }

    addToFormGroup() {
        this.modalService.data
            .forEach(control => {
                return this.featureRequestForm.addControl(control.slug, this.formBuilder.control(control.isSelected));
            });
    }

    sendUpgradeRequest() {

        const submittingStatus = this.accountUsageService.sumbittingStatus$.value;

        if (submittingStatus.status === 'sent') {
            return this.modalService.closeModal();
        }

        const features = Object.keys(this.featureRequestForm.value).filter(i => {
            if (i === 'Other Text' || i === 'Other Check' || i === 'Increase Usage Allotments') {
                return false;
            }
            return this.featureRequestForm.value[i];
        });

        let otherText = this.featureRequestForm.controls['Other Text'].value;

        if (this.featureRequestForm.controls['Increase Usage Allotments'].value === true) {
            if (otherText === null) {
                otherText = 'Increase Usage Allotments';
            } else {
                otherText = '- ' + otherText + ('\n' + '- Increase Usage Allotments');
            }
        }

        this.accountUsageService.requestUpgrade(null, features, otherText);
    }

    ngOnDestroy() {
        this.error.unsubscribe();
    }
}
