import {Component, ContentChild, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Operator } from 'rxjs';
import { UploaderService } from '../uploader.service';
import { UploaderTabsService } from '../uploaderTabs.service';
import { FinishService } from './finish.service';
import { EventFarmService } from '../../../eventFarm.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

@Component({
    selector: 'finish-container',
    template: require('./finish-container.html'),
    styles: [require('./finish-container.scss')],
})

export class FinishContainerComponent implements OnInit {
    @Input() hideCloseBtn?: boolean = false;
    @Input() showCheckmark?: boolean = false;
    @Input() showGuestListButton?: boolean = false;
    @Input() simplified?: boolean = false;
    @Input() twoColumn?: boolean = false;
    @Input() contactTitle?: string = null;
    @Input() sectionHeader?: string = 'Upload Complete';
    @Input() sectionSubHeader?: string = null;
    @Input() duplicatesIconSrc?: string = null;
    @Input() errorsIconSrc?: string = null;
    @Input() contactsIconSrc?: string = null;
    @Input() textAlignment?: 'left' | 'center' = 'center';

   // @ts-ignore
    @ContentChild('rightColumn', {static: true})
    constructor(
        private uploaderService: UploaderService,
        private uploaderTabsService: UploaderTabsService,
        private eventFarmService: EventFarmService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private finishService: FinishService,
    ) {
    }

    ngOnInit() {

        this.uploaderService.setExistingUserImportFromUrl();
        this.uploaderTabsService.changeTabName(2, 'Upload Complete!');
        if (this.uploaderService.hasInvitations) {
            this.uploaderTabsService.changeTabName(2, 'Upload Complete & Invites Created!');
        }
        if (this.uploaderService.hasInvitations && this.uploaderService.hasSentInvitations) {
            this.uploaderTabsService.changeTabName(2, 'Upload Complete & Invites Sent!');
        }
    }

    openAddressBook() {
        this.router.navigateByUrl(this.routeGenerator.url('pools.address-book.list', { poolId: this.eventFarmService.currentTeam.id }));
    }

    openGuestList() {
        this.eventFarmService.updateEventDetails(this.eventFarmService.currentEvent.id);
        this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId: this.eventFarmService.currentTeam.id, eventId: this.eventFarmService.currentEvent.id }));
    }
}
