import { AbstractModel } from '../abstract-model';
import { SalutationLocaleTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Salutation';

export class Salutation extends AbstractModel {
    private _name: string;
    private _locale: SalutationLocaleTypeInterface;

    public static fromApiResponse(data) {
        const salutation = new this(data.id);

        try {
            salutation._locale = data.attributes.locale;
            salutation._name = data.attributes.name;
        } catch (err) {
        }

        return salutation;
    }

    get name(): string {
        return this._name;
    }

    get locale(): SalutationLocaleTypeInterface {
        return this._locale;
    }
}
