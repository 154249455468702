import { WaitListSettingsComponent } from './WaitlistSettings/waitListSettings.component';
import { NgModule } from '@angular/core';
import { UtilitiesModule } from './../../../Utilities/utilities.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { WaitListRoutingModule } from './waitList-routing.module';
import { WaitListEntryComponent } from './waitListEntry.component';
import { WaitListComponent } from './waitList.component';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { GuestSearchModule } from './../../CoreUI/GuestSearch/guestSearch.module';
import { WaitListService } from './waitList.service';
import { WaitListItemComponent } from './WaitlistItem/waitListItem.component';
import {PaginationModule} from 'ngx-bootstrap/pagination'

import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';

@NgModule({
    bootstrap: [WaitListEntryComponent],
    declarations: [
        WaitListEntryComponent,
        WaitListComponent,
        WaitListItemComponent,
        WaitListSettingsComponent,
    ],
    entryComponents: [],
    imports: [
        CommonModule,
        FormsModule,
        WaitListRoutingModule,
        BreadcrumbsModule,
        GuestSearchModule,
        UtilitiesModule,
        PaginationModule,
        NzUtilitiesModule,
    ],
    providers: [
        WaitListService,
    ],
})

export class WaitListModule {
}
