import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../admin.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { TeamEventsService } from './team-events.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as adminActions from '../../../../store/actions/admin/';
import { EfMeta } from '../../../../store/reducers/admin.reducer';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
@Component({
  selector: 'team-events',
  template: require('./team-events.html'),
  styles: [require('./team-events.scss')],
})
export class TeamEventsComponent implements OnInit, OnDestroy {
  @Input() team: Team;
  form: FormGroup;
  public allEvents$ = new BehaviorSubject<any>(false);
  public eventsToDisplay$ = new BehaviorSubject<any>(false);
  public totalItemsCount$ = new BehaviorSubject<any>(false);

  private currentTeamEvents$: Subscription;
  private allEventsSub$: Subscription;
  private currentPage: number;
  private itemsPerPage: number;
  private totalItemsCount: number;

  poolContractTypes: any;
  filtering: boolean = false;
  public placeholder: string = 'Filter by name or date';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  constructor(
      private formBuilder: FormBuilder,
      private store: Store,
      private eventFarmService: EventFarmService,
      private apiClient: EventFarmAPIClient,
      private alertService: AlertService,
      private message: NzMessageService,
      private adminService: AdminService,
      private teamEventsService: TeamEventsService
  ) {
    this.currentPage = 1;
    this.itemsPerPage = 20;
    this.form = this.formBuilder.group({
      eventName: [''],
    });

  }

  switchAscDesc(val, dir: boolean = true) {
    this.filtering = true;
  }

  ngOnInit() {
    this.currentTeamEvents$ =  this.adminService.currentTeamEvents$.subscribe((val) => {
      if (val.data) { this.allEvents$.next(val.data); }
      if (val.data && val.data.length) { this.eventsToDisplay$.next(val.data); }
      if (val.meta) { this.totalItemsCount$.next(val.meta.totalResults); }
    });

    this.form.valueChanges
        .pipe(debounceTime(500))
        .subscribe(data => this.onChangeSearch(data.eventName));
  }

  async handlePageSizeChange(idx) {
    this.store.dispatch(new adminActions.FetchEventsForAdminTeam({currentPage: this.currentPage, itemsPerPage: idx}));

  }
  async handlePageIndexChange(idx) {
    this.store.dispatch(new adminActions.FetchEventsForAdminTeam({currentPage: idx, itemsPerPage: this.itemsPerPage}));
  }

  ngOnDestroy() {
    this.currentTeamEvents$.unsubscribe();
  }

  onChangeSearch($event) {
    this.store.dispatch(new adminActions.FetchEventsForAdminTeam({currentPage: 1, itemsPerPage: 30, name: $event}));
  }
}
