import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditQuestionComponent } from './edit-question.component';
import { Question } from '../../../../../../ApiClient/Models/Question/question';
import { EventFarmService } from '../../../../../eventFarm.service';

@Component({
  selector: 'edit-question-button',
  styles: [require('./edit-question.scss')],
  template: `
    <span *ngIf="(eventFarmService.currentEventRoles$ | async)['event-crud']" nzTooltipTitle="Edit question" nz-tooltip (click)="openComponent()">
        <i class="fa fa-pencil" style="cursor: pointer; color: #5085F0FF; margin-left: 6px; font-size: 15px;" aria-hidden="true"></i
    ></span>
  `
})

export class EditQuestionButtonComponent implements OnInit {
  @Input() question;

  constructor(
    private drawerService: NzDrawerService,
    private eventFarmService: EventFarmService
  ) {}

  ngOnInit() {
  }

  openComponent(): void {
    const drawerRef = this.drawerService.create<EditQuestionComponent, { question: Question }, string>({
      nzTitle: 'Edit Question',
      nzContent: EditQuestionComponent,
      nzWidth: '50%',
      nzContentParams: {
        question: this.question,
      }
    });

    drawerRef.afterOpen.subscribe(() => {

    });

    drawerRef.afterClose.subscribe(data => {

    });
  }

}
