import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Design } from '../Models/design';
import { DomainMask, IDomainInfo } from '../Models/domain-mask';

import { AlertService } from '../../../eventFarmAlert.service';
import { EmailDesignCenterService } from '../email-design-center.service';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { froalaOptions } from '../../../../Shared/froala-options';
import { GuestMessagingSendOptionsService } from './guest-messaging-send-options.service';
import { GuestMessagingService } from './guest-messaging.service';
import { FroalaService } from '../froala.service';
import { EFHelper } from '../../../../Shared/helper';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

declare var $: any;

@Component({
    template: require('./guest-messaging.html'),
    styles: [require('./guest-messaging.scss'), require('./../froala-editor.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestMessagingComponent implements OnInit, OnDestroy {

    constructor(
        private emailDesignCenterService: EmailDesignCenterService,
        private guestMessagingSendOptionsService: GuestMessagingSendOptionsService,
        private guestMessagingService: GuestMessagingService,
        private froalaService: FroalaService,
        private alertService: AlertService,
        private apiClient: EventFarmAPIClient,
        private segmentService: SegmentService
    ) { }

    private design: Design = new Design();
    private messages: Design[] = [];
    private domainMask: DomainMask;
    private domainMasks: DomainMask[] = [];
    private emptyDomainMask?: string;
    private codeViewIsActive = false;

    private loading = true;

    public options: Object = froalaOptions;

    private sendTestCopy: string = 'Send Yourself a Test Message';

    private color: string;

    ngOnInit() {
        // CUSTOMIZE FROALA
        if (!this.color) {
            this.color = '#ffffff';
        }
        this.options['events'] = Object.assign(froalaOptions['events'], this.froalaService.createEditEmailOptions,
            {
                'froalaEditor.html.afterGet': (e, editor) => {
                    editor.$el[0].style.backgroundColor = this.color;
                }
            }
        );

        this.fetchMessages(this.emailDesignCenterService.event.id);
        this.getAllDomainMasksByPool();
        this.guestMessagingService.getDynamicElements();
        this.guestMessagingSendOptionsService.setForSendByInvitationStatus();

        this.froalaService.codeViewChange.subscribe(
            (codeViewIsActive) => {
                this.codeViewIsActive = codeViewIsActive;
            }
        );

        this.segmentService.segmentSideNavTracking().guestMessagingEntered();
    }

    fetchMessages(eventId) {
        this.emailDesignCenterService
            .fetchDesigns(eventId)
            .subscribe((savedMessages) => {
                this.messages = savedMessages.data.map(
                    (res) => Design.fromApi(res)
                ).filter(
                    (msg: Design) => (msg.isTypeSendAMessage || msg.isTypeSendGroupMessage)
                );
                this.loading = false;
            }
        );
    }

    useDesign(design) {

        if (design.isTypeSendAMessage) {
            this.setFormElements('SendAMessage', true, false);
            this.guestMessagingSendOptionsService.setForSendByInvitationStatus();
        } else if (design.isTypeSendGroupMessage) {
            this.setFormElements('SendGroupMessage', false, true);
            this.guestMessagingSendOptionsService.setForSendByGroup();
        } else if (design.isTypeSendGroupMessage) {
            this.setFormElements('SendGroupMessage', false, true);
            this.guestMessagingSendOptionsService.setForSendByWaitList();
        } else {
            this.design = new Design();
            this.setFormElements('SendAMessage', null, null);
            this.guestMessagingSendOptionsService.setForSendByInvitationStatus();
        }

        this.color = `#${this.design.backgroundColor}`;
        this.color = this.emailDesignCenterService.fixHexShorthand(this.color);
        $('.custom-theme.fr-box.fr-basic .fr-element.fr-view').css({backgroundColor: `${this.color}`});
        this.design.backgroundColor = this.color.replace('#', '');

        // Safety check against design in memory
        if (design.emailDesignId != null) {
            this.emailDesignCenterService.fetchEmailDesign(design.emailDesignId).subscribe((res) => {
                this.color = `#${this.design.backgroundColor}`;
            });
        }
    }

    private setFormElements(
        dynamicElementsRequested: string,
        isTypeSendAMessage?: boolean,
        isTypeSendGroupMessage?: boolean,
    ) {
        this.guestMessagingSendOptionsService.isTypeSendAMessage = isTypeSendAMessage;
        this.guestMessagingSendOptionsService.isTypeSendGroupMessage = isTypeSendGroupMessage;
        this.guestMessagingService.getDynamicElements(dynamicElementsRequested);
    }

    getAllDomainMasksByPool() {
        this.emailDesignCenterService
            .getAllDomainMasksByPool()
            .subscribe((res) => {
                this.domainMasks = res.data.map((item) => DomainMask.fromApi(item));
            });
    }

    colorChange(color) {
        $('.custom-theme.fr-box.fr-basic .fr-element.fr-view').css({backgroundColor: `${this.color}`});
        color = color.replace('#', '');
        color = this.emailDesignCenterService.fixHexShorthand(color);
        this.design.backgroundColor = color;
    }

    sendTest() {
        if (this.checkDomainMaskFields(this.design)) {
            this.emailDesignCenterService.sendGuestMessagePreview(this.design);
        }
    }

    checkDomainMaskFields(design) {
        let isDomainUserNamePopulated = !EFHelper.isEmpty(design.domainMask.senderAddress);
        let isDomainSelected = !EFHelper.isEmpty(design.domainMask.mask.domain);
        let isFullDomainMaskPopulated = !EFHelper.isEmpty(design.domainMask.id) && !EFHelper.isEmpty(design.domainMask.fullDomainMaskEmail);
        let isDomainMaskValid = this.emailDesignCenterService.isDomainMaskValid(isDomainUserNamePopulated, isDomainSelected, isFullDomainMaskPopulated);
        if (!isDomainMaskValid) {
            this.alertService.emailDesignCenter().incompleteDomainMask();
            return false;
        } else {
            return true;
        }
    }

    disableSpace(e) {
        if (e === 32) return false;
    }

    onDomainMaskChange() {
        let selectedDesign = this.domainMasks.filter((domainMask: DomainMask) => {
            return this.design.domainMask.mask.domain === domainMask.mask.domain;
        });

        if (selectedDesign.length > 0) {
            this.design.domainMask.id = selectedDesign[0].id;
        } else {
            this.design.domainMask.id = null;
        }
    }

    save() {
        if (this.guestMessagingSendOptionsService.isSendByInvitationStatus) {
            this.emailDesignCenterService.sendGuestMessageToTicketTypes(this.design);
        } else if (this.guestMessagingSendOptionsService.isSendByGroup) {
            this.emailDesignCenterService.sendGuestMessageToGroup(this.design);
        } else if (this.guestMessagingSendOptionsService.isSendByWaitList) {
            this.emailDesignCenterService.sendGuestMessageToEventWaitList(this.design);
        } else if (this.guestMessagingSendOptionsService.isSendByAllCheckedInGuests) {
            this.emailDesignCenterService.sendGuestMessageToAllCheckedInGuests(this.design);
        } else if (this.guestMessagingSendOptionsService.isSendByNoShows) {
            this.emailDesignCenterService.sendGuestMessageToNoShows(this.design);
        }
    }

    ngOnDestroy() {
        if ($('.fr-box').length) {
          $('.fr-box').froalaEditor('destroy');
        }
    }
}
