import { Injectable } from '@angular/core';

import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { Group } from '../../../ApiClient/Models/Group/group';
import { User } from '../../../ApiClient/Models/User/user';
import { AlertService } from '../../eventFarmAlert.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { GuestMessagingSendOptionsService } from './GuestMessaging/guest-messaging-send-options.service';
import { Design } from './Models/design';
import { Template } from './Models/template';
import { InvitationStatusTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { EmailMessageTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EmailMessage';
import { map, tap, switchMap, filter, take, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from './../../store';
import { LocalStorageService } from '../../../_services/storage/local-storage.service';

@Injectable()

export class EmailDesignCenterService {
    public event: EfEvent = this.eventFarmService.currentEvent;
    public currentEvent: EfEvent = this.eventFarmService.currentEvent;
    public currentUser: User = this.eventFarmService.currentUser;
    private _thumbnailRetryIntervalInSeconds = 15;
    public emailDesignsTotalPages: number;

    public isLoadingNewOption: boolean = false;
    private _invitationStatusTypes: InvitationStatusTypeInterface[];

    constructor(
        private alertService: AlertService,
        private eventFarmService: EventFarmService,
        private guestMessagingSendOptionsService: GuestMessagingSendOptionsService,
        private client: EventFarmAPIClient,
        private store: Store<fromStore.AppState>,
        private localStorageService: LocalStorageService
    ) {
        this._invitationStatusTypes = this.client.getTypeFactory().Invitation().InvitationStatusType();
    }

    get currentEventName(): string {
        return this.event ? this.event.name : 'No Event Selected';
    }

    get isVirbelaEnabled(): boolean {
        return this.eventFarmService.virbelaEnabled && this.eventFarmService.currentEvent.virbelaPublicPaths !== null;
    }

    get isWebConferenceEnabled(): boolean {
        return this.eventFarmService.webConferenceEnabled && this.eventFarmService.currentEvent.isWebConferenceSetup;
    }

    get isEventAgendaEnabled(): boolean {
        return this.isSessionsEnabled && this.eventFarmService.betaEnabledForPool;
    }

    get isSessionsEnabled(): boolean {
        return this.eventFarmService.sessionsEnabled;
    }


    public designsListOptions: DesignListOptions = {
        query: '',
        pagination: {
            currentDesignsPage: 1,
            itemsPerPage: 500,
        }
    };

    get invitationStatusTypes(): InvitationStatusTypeInterface[] {
        return this._invitationStatusTypes;
    }

// GetAllTemplateTypes
    templates = this.client.getTypeFactory().EmailTemplate().EmailTemplateType()
        .map((data) => Template.fromApi(data));

    // GetTemplateByType
    getTemplate(template) {
        return this.client.getUseCaseFactory().EmailTemplate().GetEmailTemplateByType(template);
    }

    // ListEventsForUser
    fetchAllEvents() {
        return this.store.select(fromStore.getCurrentUser).pipe(
            filter(user => !!user.data),
            take(1),
            switchMap((user) => this.client.getUseCaseFactory().Event().ListEventsForUser(user.data.id))
        );
    }

    // ListEmailDesignsByEvent
    fetchDesigns(eventId) {
        return this.client.getUseCaseFactory().EmailDesign().ListEmailDesignsByEvent(eventId, this.designsListOptions.pagination.currentDesignsPage, this.designsListOptions.pagination.itemsPerPage);
    }

    // GetEmailThumbnailUrl
    fetchDesignThumbnail(designId) {
        return this.client.getUseCaseFactory().EmailSample().GetEmailThumbnailUrl(designId);
    }

    async getThumbnailFailureState(designId) {
        const commands = await this.client.getUseCaseFactory().Queue().ListQueueCommandsForUser(this.eventFarmService.currentUser.id, 1, 20, false).toPromise();
        const commandsJson = commands;
        if (commandsJson.data) {
            const dataLength = commandsJson.data.length;
            for (let i = 0; i < dataLength; i++) {
                const currentCommand = commandsJson.data[i];
                if (currentCommand.attributes.jsonCommand.emailDesignId === designId) {
                    return 'processing';
                }
            }
        }
        return 'failed';
    }

    public isDomainMaskValid(isDomainUserNamePopulated, isDomainSelected, isFullDomainMaskPopulated) {
        if (isDomainUserNamePopulated && (!isDomainSelected || !isFullDomainMaskPopulated)) {
            return false;
        }
        if (isDomainSelected && (!isDomainSelected || !isFullDomainMaskPopulated)) {
            return false;
        }
        if (isFullDomainMaskPopulated && (!isDomainUserNamePopulated || !isDomainSelected)) {
            return false;
        }
        return true;
    }

    // GetEmailDesign
    fetchEmailDesign(designId) {
        return this.client.getUseCaseFactory()
            .EmailDesign()
            .GetEmailDesign(designId);
    }

    // CreateEmailDesign
    createEmailDesign(design: Design) {
        return this.client.getUseCaseFactory().EmailDesign().CreateEmailDesign(
            design.name,
            design.layout,
            design.fromName,
            design.subject,
            design.content,
            design.backgroundColor,
            design.emailDesignTypeId,
            this.eventFarmService.currentEvent.id,
            design.fromEmail,
            design.replyEmail,
            design.ccEmails,
            design.bccEmails,
            design.domainMask.id,
            design.domainMask.fullDomainMaskEmail,
        );
    }

    // UpdateEmailDesign
    updateEmailDesign(design: Design) {
        return this.client.getUseCaseFactory().EmailDesign().UpdateEmailDesign(
            design.emailDesignId,
            design.name,
            design.layout,
            design.fromName,
            design.subject,
            design.content,
            design.backgroundColor,
            design.emailDesignTypeId,
            this.event.id,
            design.fromEmail,
            design.replyEmail,
            design.ccEmails,
            design.bccEmails,
            design.domainMask.id,
            design.domainMask.fullDomainMaskEmail,
        );
    }

    // CreateEmailSample
    createEmailSample(designId, designContent, overrideMinimumInterval = false) {
        return this.client.getUseCaseFactory()
            .EmailSample()
            .CreateEmailSample(
                designContent,
                designId,
                overrideMinimumInterval,
            );
    }

    // RegenerateEmailSample
    regenerateEmailSample(designId, designContent, design, overrideMinimumInterval = false) {
        design.thumbnailUrl = 'processing';
        const emailSample = this.createEmailSample(designId, designContent, overrideMinimumInterval);
        this.retryFetchDesignThumbnail(design);
        return emailSample;
    }

    retryFetchDesignThumbnail(design) {
        setTimeout(() => {
            this.fetchDesignThumbnail(design.emailDesignId).subscribe((res) => {
                design.thumbnailUrl = res.data.attributes;
            }, (err) => {
                this.retryFetchDesignThumbnail(design);
            });
        }, (this._thumbnailRetryIntervalInSeconds * 1000));
    }

    // RemoveEmailDesign
    removeEmailDesign(design) {
        return this.client.getUseCaseFactory()
            .EmailDesign()
            .RemoveEmailDesign(design.emailDesignId);
    }

    // DuplicateEmailDesign
    duplicateEmailDesign(design) {
        return this.client.getUseCaseFactory()
            .EmailDesign()
            .DuplicateEmailDesign(design.emailDesignId, this.eventFarmService.currentEvent.id);
    }

    sendGuestMessagePreview(design: Design) {
        let ownerUserId = this.eventFarmService.currentUser.id;
        const currentUserId = this.localStorageService.get('state');
        if (this.eventFarmService.isGhosting && currentUserId !== null) {
            ownerUserId = currentUserId['accessToken']['userId'];
        }

        this.client
            .getUseCaseFactory()
            .EmailMessage()
            .SendAMessagePreview(
                this.eventFarmService.currentEvent.id,
                ownerUserId,
                design.content,
                design.subject,
                design.fromName,
                design.replyEmail,
                design.shouldUseEventSpecificLayout,
                design.backgroundColor,
                design.domainMask.fullDomainMaskEmail
            )
            .subscribe(
                (res) => {
                    this.alertService.emailDesignCenter().guestMessageSendSuccess();
                },
                (error) => {
                    this.alertService.emailDesignCenter().guestMessageSendError();
                }
            );
    }

    sendGuestMessageToAllCheckedInGuests(design: Design) {
        const ownerUserId = this.eventFarmService.currentUser.id;

        this.client
            .getUseCaseFactory()
            .EmailMessage()
            .SendAMessageToAllCheckedInGuests(
                this.eventFarmService.currentEvent.id,
                ownerUserId,
                design.content,
                design.subject,
                design.fromName,
                design.replyEmail,
                design.ccEmails,
                design.bccEmails,
                design.shouldUseEventSpecificLayout,
                design.backgroundColor,
                design.domainMask.fullDomainMaskEmail
            )
            .subscribe(
                (res) => {
                    this.alertService.emailDesignCenter().guestMessageSendSuccess();
                },
                (error) => {
                    this.alertService.emailDesignCenter().guestMessageSendError();
                }
            );
    }

    sendGuestMessageToNoShows(design: Design) {
        const ownerUserId = this.eventFarmService.currentUser.id;

        this.client
            .getUseCaseFactory()
            .EmailMessage()
            .SendAMessageToNoShows(
                this.eventFarmService.currentEvent.id,
                ownerUserId,
                design.content,
                design.subject,
                design.fromName,
                design.replyEmail,
                design.ccEmails,
                design.bccEmails,
                design.shouldUseEventSpecificLayout,
                design.backgroundColor,
                design.domainMask.fullDomainMaskEmail
            )
            .subscribe(
                (res) => {
                    this.alertService.emailDesignCenter().guestMessageSendSuccess();
                },
                (error) => {
                    this.alertService.emailDesignCenter().guestMessageSendError();
                }
            );
    }

    sendGuestMessageToEventWaitList(design: Design) {
        const ownerUserId = this.eventFarmService.currentUser.id;

        this.client
            .getUseCaseFactory()
            .EmailMessage()
            .SendAMessageToEventWaitList(
                this.eventFarmService.currentEvent.id,
                ownerUserId,
                design.content,
                design.subject,
                design.fromName,
                design.replyEmail,
                design.ccEmails,
                design.bccEmails,
                design.shouldUseEventSpecificLayout,
                design.backgroundColor,
                design.domainMask.fullDomainMaskEmail
            )
            .subscribe(
                (res) => {
                    this.alertService.emailDesignCenter().guestMessageSendSuccess();
                },
                (error) => {
                    this.alertService.emailDesignCenter().guestMessageSendError();
                }
            );
    }

    sendGuestMessageToGroup(design: Design) {
        const ownerUserId = this.eventFarmService.currentUser.id;

        this.client
            .getUseCaseFactory()
            .EmailMessage()
            .SendAMessageToGroup(
                this.guestMessagingSendOptionsService.selectedGroup.id,
                this.eventFarmService.currentEvent.id,
                ownerUserId,
                design.content,
                design.subject,
                design.fromName,
                design.replyEmail,
                design.ccEmails,
                design.bccEmails,
                design.shouldUseEventSpecificLayout,
                design.backgroundColor,
                design.domainMask.fullDomainMaskEmail,
                this.guestMessagingSendOptionsService.groupStatusFilterSlug
            )
            .subscribe(
                (res) => {
                    this.alertService.emailDesignCenter().guestMessageSendSuccess();
                },
                (error) => {
                    this.alertService.emailDesignCenter().guestMessageSendError();
                }
            );
    }

    sendGuestMessageToTicketTypes(design: Design) {
        const ownerUserId = this.eventFarmService.currentUser.id;

        this.client
            .getUseCaseFactory()
            .EmailMessage()
            .SendAMessageToTicketTypes(
                this.eventFarmService.currentEvent.id,
                this.guestMessagingSendOptionsService.ticketTypeIds,
                this.guestMessagingSendOptionsService.messageTargets,
                ownerUserId,
                design.content,
                design.subject,
                design.fromName,
                design.replyEmail,
                design.ccEmails,
                design.bccEmails,
                design.shouldUseEventSpecificLayout,
                design.backgroundColor,
                design.domainMask.fullDomainMaskEmail
            )
            .subscribe(
                (res) => {
                    this.alertService.emailDesignCenter().guestMessageSendSuccess();
                },
                (error) => {
                    this.alertService.emailDesignCenter().guestMessageSendError();
                }
            );
    }

    getEmailMessageTypes(): EmailMessageTypeInterface[] {
        return this.client.getTypeFactory().EmailMessage().EmailMessageType();
    }

    // EmailDesignTypes
    getAllEmailDesignTypes() {
        return this.client.getUseCaseFactory().EmailDesignType().GetAllEmailDesignTypes();
    }

    // getAllDomainMasksByPool
    getAllDomainMasksByPool() {
        return this.client.getUseCaseFactory().DomainMask().GetAllDomainMasksByPool(this.eventFarmService.currentTeam.id);
    }

    fixHexShorthand(hex: string): string {
        let newHex;
        if ( hex.length === 3 ) {
            newHex = hex.split('').map(l => l + l).join('');
        } else {
            newHex = hex;
        }
        return newHex;
    }
}

export interface Pagination {
    currentDesignsPage: number;
    itemsPerPage: number;
}

export interface DesignListOptions {
    selectedGroup?: Group;
    query: string;
    pagination: Pagination;
}
