import { AbstractModel } from '../abstract-model';
import { Transaction } from '../Transaction/transaction';
import { Team } from '../Pool/team';
import { EfEvent } from '../Event/event';
import { UserName } from '../User/user-name';

export class Purchase extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _pricePaid: number;
    private _inviteCount: number;
    private _promotion: any;
    private _created: any;
    private _userName: UserName;
    private _email: string;
    private _description: string;

    public static fromApiResponse(data) {
        const purchase = new this(data.id);
        const attributes = data.attributes;
        purchase._inviteCount = attributes.inviteCount;
        purchase._created = attributes.created;
        purchase._promotion = attributes.promotion;
        purchase._pricePaid = attributes.pricePaid;
        purchase._description = attributes.description;
        purchase._email = attributes.invitation.email;
        purchase._userName = UserName.fromApiResponse(attributes.invitation.userName);
        return purchase;
    }

    /**
     * Getter pricePaid
     * @return {number}
     */
    public get pricePaid(): number {
        return this._pricePaid;
    }

    /**
     * Getter inviteCount
     * @return {number}
     */
    public get inviteCount(): number {
        return this._inviteCount;
    }

    /**
     * Getter promotion
     * @return {any}
     */
    public get promotion(): any {
        return this._promotion;
    }

    /**
     * Getter created
     * @return {any}
     */
    public get created(): any {
        return this._created;
    }

    /**
     * Getter userName
     * @return {UserName}
     */
    public get userName(): UserName {
        return this._userName;
    }

    /**
     * Getter email
     * @return {string}
     */
    public get email(): string {
        return this._email;
    }

    /**
     * Getter description
     * @return {string}
     */
    public get description(): string {
        return this._description;
    }

}
