import {Injectable} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {SelectOption} from '../../../../ApiClient/ClientLibrary/Interfaces';
import {InvitationAccessTypeOptionInterface, InvitationStatusOptionInterface} from '../../../../ApiClient/Models/Communication';
import {Invitation} from '../../../../ApiClient/Models/Invitation/invitation';
import {GuestListViewService} from '../guest-list-view.service';
import { TicketTypeDTO } from '../../InvitationOptions/invitationOptions.service';
import { Stack } from '../../../../ApiClient/Models/Stack/stack';
import * as moment from 'moment-timezone';
import {GuestListBulkUpdateFormService} from '../GuestListBulkUpdateForm/guest-list-bulk-update-form.service';

export type QuickFilterSelectionValueType =
    'who-is-coming'
    | 'who-is-not-coming'
    | 'who-has-not-decided'
    | 'all-invited'
    | 'checked-in';

export type QuickFilterSelectionLabelType =
    "Who's Coming"
    | "Who's Not Coming"
    | "Who Hasn't Decided"
    | 'All Invited'
    | 'Checked In';


export enum GuestListFilterType {
    QUICK,
    STATUS,
    STACK
}

export interface QuickFilterSelectOption extends SelectOption {
    label: QuickFilterSelectionLabelType;
    value: QuickFilterSelectionValueType;
}


@Injectable()
export class GuestListFiltersService {

    public quickFilterSelection;
    // Populated from "Add Filter" slideout
    public filterSelection: InvitationStatusOptionInterface[] = [];
    public stackSelection: InvitationAccessTypeOptionInterface[] = [];

    public quickFilterSelectionDropdownReset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    searchForm;
    quickFilterForm;
    private $quickFilterFormSub;

    constructor(
        private guestListViewService: GuestListViewService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService,
        private fb: FormBuilder
    ) {
        this.quickFilterForm = this.fb.group({
            selection: this.fb.control<QuickFilterSelectionValueType>('who-is-coming', Validators.required),
        });
        this.searchForm = this.fb.group({
            query: ['', Validators.required],
        });
        this.handleQuickFilterDropdownSelection();
    }


    public async handleSelectedFiltersChange(filters: InvitationStatusOptionInterface[], stacks: InvitationAccessTypeOptionInterface[]) {
        this.filterSelection = filters;
        this.stackSelection = stacks;
        const statusFilters = filters.map(filter => filter.value);
        // If no invitation status filters are set we need to set all, as to exclude recycled
        if (statusFilters.length > 0) {
            this.guestListViewService.parameters.statuses = statusFilters;
        } else {
           this.guestListViewService.setStatusesToAll();
        }

        this.guestListViewService.parameters.stackIds = stacks.length ? stacks.map(s => s.value) : null;
        this.guestListBulkUpdateFormService.clearBulkUpdate();
        await this.guestListViewService.fetchGuestListForEventOrTicketBlock();
    }

    // The following is used for clicks on icons on the guest list invitation item
    public async handleQuickFilterChange(status = null, stack = null, ticketBlock = null) {
        this.quickFilterSelectionDropdownReset$.next(true)
        // Resets additional filter selections
        this.resetFilterSelection();
        this.resetQuickFilter()
        this.guestListBulkUpdateFormService.clearBulkUpdate();

        if (status) {
            this.quickFilterSelection = status.name
            this.guestListViewService.parameters.statuses = [status.slug];
            this.guestListViewService.parameters.stackIds = null;

        } else if (stack) {
            this.quickFilterSelection = stack.ticketType.name;
            this.guestListViewService.setStatusesToAll();
            this.guestListViewService.parameters.stackIds = [stack.id];
        }
        await this.guestListViewService.fetchGuestListForEventOrTicketBlock();

    }

    // The following is used on the Quick Filter dropdown selection
    public handleQuickFilterDropdownSelection() {
        // When quick filter changes, set Guest List Service status based on selection
        this.$quickFilterFormSub = this.quickFilterForm.valueChanges.subscribe(async val => {
            if (val.selection?.value) {
                // Resets additional filter selections
                this.resetFilterSelection();
                this.guestListBulkUpdateFormService.clearBulkUpdate();
                this.guestListViewService.parameters.statuses = Invitation.invitationStatusPayloadByQuickFilter(val.selection.value);
                this.quickFilterSelection = val.selection.label;

                // If "Checked In" quick filter is chosen
                if (val.selection.value === 'checked-in') {
                    this.guestListViewService.parameters.isCheckedIn = true;
                    this.guestListViewService.setStatusesToAll();
                } else {
                    this.guestListViewService.parameters.isCheckedIn = null;
                }
                await this.guestListViewService.fetchGuestListForEventOrTicketBlock();
            }
        });
    }

    get filtersApplied(): boolean {
        return this.filterSelection.length > 0 || this.quickFilterSelection || this.stackSelection.length > 0;
    }

    public resetQuickFilter() {
        this.quickFilterSelection = null;
        this.quickFilterForm.reset();
        this.guestListViewService.parameters.isCheckedIn = null;
    }

    public resetFilterSelection() {
        this.filterSelection = [];
        this.stackSelection = [];
    }

    async removeTag(filterType: GuestListFilterType, value: string) {
        if (filterType === GuestListFilterType.QUICK) {
            this.quickFilterSelection = null;
            this.guestListViewService.parameters.statuses = null;
            this.guestListViewService.parameters.stackIds = null;

            // Reset quick filter menu
            this.resetQuickFilter();
            this.guestListViewService.setStatusesToAll();
            await this.guestListViewService.fetchGuestListForEventOrTicketBlock();
        } else {
            if (filterType === GuestListFilterType.STACK) {
                // Remove tag from selection set
                const filtered = this.stackSelection.filter(f => f.value !== value);
                this.stackSelection = filtered;

                // Update params
                this.guestListViewService.parameters.stackIds = filtered.map(f => f.value);
            } else {
                // Remove tag from selection set
                const filtered = this.filterSelection.filter(f => f.value !== value);
                this.filterSelection = filtered;

                // Update params
                this.guestListViewService.parameters.statuses = filtered.map(f => f.value);

                //If statuses are empty, let's set to all
                if (this.guestListViewService.parameters.statuses.length === 0 ) {
                    this.guestListViewService.setStatusesToAll();
                }
            }

            // Make new call
            await this.guestListViewService.fetchGuestListForEventOrTicketBlock();
        }
    }

    public formatTicketTypesAndStackIdsForFilter() {

        const ticketTypeDTOArray: TicketTypeDTO[] = [];

        const sortedStacks = this.guestListViewService.currentEvent.stacks.sort((a, b) => {
            const nameA = a.ticketType.name.toUpperCase();
            const nameB = b.ticketType.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            if (a.expirationStartTime && b.expirationStartTime) {
                return a.expirationStartTime - b.expirationStartTime

            }

            return 0;
        });

        sortedStacks.forEach(ss => {
            if (ticketTypeDTOArray.length === 0) {
                ticketTypeDTOArray.push({
                    id: ss.ticketType.id,
                    name: ss.ticketType.name,
                    stacks: [ss]
                })
            } else {
                if (ticketTypeDTOArray[ticketTypeDTOArray.length-1].id === ss.ticketType.id) {
                    ticketTypeDTOArray[ticketTypeDTOArray.length-1].stacks.push(ss);
                } else {
                    ticketTypeDTOArray.push({
                        id: ss.ticketType.id,
                        name: ss.ticketType.name,
                        stacks: [ss]
                    })
                }

            }
        })

        let options = ticketTypeDTOArray.map(opt => {
            return opt.stacks.map(stack => {
                return {
                    label: this.configureStackNameForDisplay(stack),
                    value: stack.id
                };
            })
        });

        options = [].concat(...options);

        return options;
    }

    private configureStackNameForDisplay(stack: Stack = null): string {
        if (!stack) {
            return ''
        }

        let displayName = stack.ticketType.name;

        const expirationString = this.getExpirationString(stack);

        if (expirationString.length !== 0) {
            displayName = displayName + ' - ' + expirationString;
        } 

        return displayName;
    }

    private getExpirationString(stack: Stack): string {
        // since if the expiration start and end time are defaulted to an empty object, we can use object.keys to see if those values have any fields

        if (Object.keys(stack.expirationStartTimeFormatted).length && !Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid From ${stack.expirationStartTimeFormatted.short}`;
        } else if (!Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid Til ${stack.expirationEndTimeFormatted.short}`;
        } else if (Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            if (stack.expirationStartTimeFormatted.date.short === stack.expirationEndTimeFormatted.date.short) {
                return `${stack.expirationStartTimeFormatted.date.short} ${stack.expirationStartTimeFormatted.time.short} to ${stack.expirationEndTimeFormatted.time.short} ${moment().tz(stack.expirationEndTimeFormatted.timezone).zoneAbbr()}`;
            } else {
                return `${stack.expirationStartTimeFormatted.short} to ${stack.expirationEndTimeFormatted.short}`;
            }
        }
        
        return '';
    }
}
