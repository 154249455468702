import { tap, take, withLatestFrom, switchMap, filter, map, catchError, mergeMap, concatMap, shareReplay, concatMapTo, flatMap, merge, concat, zip, combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromStore from '../../app.state';
import { WaitListService } from '../../../EventsModule/WaitList/waitList.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import * as fromRoot from '../../../store';
import { forkJoin, of } from 'rxjs';
import {TeamFeatureService} from '../../../AdminModule/Manage/ManageTeam/TeamFeatures/team-feature.service';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';
import * as adminActions from '../../actions/admin/';
import { NzMessageService } from 'ng-zorro-antd/message';
import {TeamEchoWorldsService} from '../../../AdminModule/Manage/ManageTeam/TeamEchoWorlds/team-echo-worlds.service';
import {PoolWorld} from '../../../../ApiClient/Models/PoolWorld/pool-world';
import {VirbelaWorld} from '../../../../ApiClient/Models/VirbelaWorld/virbela-world';
import * as adminPoolWorldActions from "../../actions/admin/admin-pool-world.action";
import {Allotment} from "../../../../ApiClient/Models/Pool/allotment";
import {DomainMask} from "../../../../ApiClient/Models/DomainMask/domainMask";

@Injectable()
export class AdminPoolWorldEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamFeatureService: TeamFeatureService,
        private teamEchoWorldService: TeamEchoWorldsService,
        private messageService: NzMessageService
    ) { }

    // @ts-ignore
    fetchVirbelaWorldsForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchVirbelaWorldsForAdmin>(adminActions.FETCH_VIRBELA_WORLDS_FOR_ADMIN),
        switchMap(() => this.teamEchoWorldService.listAllVirbelaWorlds()
            .pipe(
                map(res => {
                    return res['data']
                        .map(f =>  VirbelaWorld.fromApiResponse2(f));
                }),
                switchMap((worlds: PoolWorld[]) => [
                    new adminActions.FetchVirbelaWorldsForAdminSuccess(worlds)
                ]),
            )),
        catchError(err => of(new adminActions.FetchVirbelaWorldsForAdminFail(err)))
    ));


    // @ts-ignore
    fetchPoolWorldsForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminPoolWorldActions.FetchPoolWorldsForAdminTeam>(adminPoolWorldActions.FETCH_POOL_WORLDS_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamEchoWorldService.fetchPoolWorldsForPool(team.id)
            .pipe(
                map(worlds => {
                    return worlds['data']
                        .map(e =>  PoolWorld.fromApiResponse(e));
                }),
                switchMap((worlds: PoolWorld[]) => [
                    new adminPoolWorldActions.FetchPoolWorldsForAdminTeamSuccess(worlds)
                ]),
            )),
        catchError(err => of(new adminPoolWorldActions.FetchPoolWorldsForAdminTeamFail(err)))));


    // @ts-ignore
    addPoolWorldToAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.AddPoolWorldToAdminTeam>(adminActions.ADD_POOL_WORLD_TO_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamEchoWorldService.addPoolWorldToPool(team.id, action.payload.virbelaWorldId)
            .pipe(
                map(res => res['data']),
                switchMap((poolWorld: PoolWorld) => [
                    new adminActions.AddPoolWorldToAdminTeamSuccess(poolWorld),
                    this.messageService.success('Pool world added to pool'),
                    new adminActions.FetchPoolWorldsForAdminTeam()

                ]),
            )),
        catchError(err => {
            this.messageService.error('Error adding pool world to pool');
            return of(new adminActions.AddPoolWorldToAdminTeamFail(err));
        })));

    // @ts-ignore
    removePoolWorldFromAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.RemovePoolWorldFromAdminTeam>(adminActions.REMOVE_POOL_WORLD_FROM_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamEchoWorldService.removePoolWorldFromPool(action.payload.poolWorldId)
            .pipe(
                map(res => res['data']),
                switchMap((poolWorld: PoolWorld) => [
                    new adminActions.RemovePoolWorldFromAdminTeamSuccess(poolWorld),
                    this.messageService.success('Pool world  removed from pool'),
                    new adminActions.FetchPoolWorldsForAdminTeam()

                ]),
            )),
        catchError(err => {
            this.messageService.error('Error removing pool world from pool');
            return of(new adminActions.RemovePoolWorldFromAdminTeamFail(err));
        })));


}
