import { Component } from '@angular/core';

import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { AlertService } from '../../../eventFarmAlert.service';

import { SendTestService } from './send-test.service';

@Component({
    providers: [ SendTestService ],
    styles: [
    ],
    template: require('./send-test.html'),
})

export class SendTestComponent {
    private emails = [];

    constructor(
        private alertService: AlertService,
        private modalService: ModalService,
        private sendTestService: SendTestService,
    ) {
        this.sendTestService.design = this.modalService.data;
        this.addMine();
    }

    private submit($event) {
        $event.preventDefault();

        if (! this.validAddresses.length) {
            return false;
        }

        this.sendTestService.sendTest(this.validAddresses).subscribe(
            (success) => {
                this.alertService.emailDesignCenter()
                    .testEmailSucccess(
                        this.validAddresses.join(', ')
                    )
                    .then(
                        (res) => this.modalService.closeModal()
                    );
            },
            (error) => {
                this.alertService.emailDesignCenter().testEmailFail();
            },
        );
    }

    private get validAddresses() {
        let addresses = [];

        const emailInputs = document.querySelectorAll('ef-modal input.test-email-recipient') as any;
        for (const input of emailInputs) {
            if (input.validity.valid) {
                addresses.push(input.value);
            }
        }

        return addresses;
    }

    private emailObject(address) {
        return {address: address};
    }

    private addMine() {
        this.emails.push(this.emailObject(this.sendTestService.myEmailAddress));
    }

    private add() {
        this.emails.push(this.emailObject(''));
    }

    private delete(email) {
        this.emails = this.emails.filter((i) => i !== email);

        if (! this.emails.length) {
            this.addMine();
        }
    }

    private handleKeyDown($event) {
        switch ($event.key) {
            case 'Enter':
                this.handleEnterKey($event);
                break;
            case 'Backspace':
                this.handleBackspace($event);
                break;
            default:
        }
    }

    private handleEnterKey($event) {
        $event.preventDefault();

        const nextEmailFormGroup = $event.target.parentElement.parentElement
            .nextElementSibling;
        if (nextEmailFormGroup.classList.contains('form-group')) {
            nextEmailFormGroup.querySelector('input').focus();
        } else {
            this.add();
        }
    }

    private handleBackspace($event) {
        if ($event.target.value) {
            return false;
        }

        $event.target.parentElement.nextElementSibling
        .querySelector('button').click();

        const previousFormGroup = $event.target.parentElement.parentElement
        .previousElementSibling;
        if (previousFormGroup) {
            $event.preventDefault();
            previousFormGroup.querySelector('input').focus();
        }
    }
}
