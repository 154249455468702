export interface ErrorInterface {
    title: string;
    detail: string;
    meta: ErrorMetaInterface;
}

export interface ErrorMetaInterface {
    detail: string;
    isRequired: string;
    name: string;
    type: string;
    validValues?: string[];
}

export class ApiError implements ErrorInterface {

    public static fromApiErrorArray(data: ErrorInterface[]) {
        const errors: ApiError[] = [];
        for (const err of data) {
            errors.push(new this(err.title, err.detail, err.meta));
        }
        return errors;
    }

    private _detail: string;
    private _meta: ErrorMetaInterface;
    private _title: string;

    constructor(title: string, detail: string, meta: ErrorMetaInterface) {
        this._title = title;
        this._detail = detail;
        this._meta = meta;
    }

    get detail(): string {
        return this._detail;
    }

    set detail(value: string) {
        this._detail = value;
    }

    get meta(): ErrorMetaInterface {
        return this._meta;
    }

    set meta(value: ErrorMetaInterface) {
        this._meta = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }
}
