
import { Component } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';

import { QuestionCenterService } from '../question-center.service';

@Component({
    selector: 'question-list-preview',
    styles: [require('./question-list-preview.scss')],
    template: require('./question-list-preview.html'),
})

export class QuestionPreviewComponent {
    constructor(
        private questionsCenterService: QuestionCenterService,
        private eventFarmService: EventFarmService,
    ) { }
    ngOnInit() {
        this.questionsCenterService.getQuestions();
    }
}