import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'payment-info',
    template: require('./payment-info.html'),
})

export class PaymentInfoComponent {
    @Input() parentForm: FormGroup;

    private preventNonInt(e) {
        const input = e.target.value;
        const cursorIndex = e.target.selectionStart;
        const decimalIndex = input.indexOf('.');

        if (cursorIndex === 0) {
            return e.charCode >= 49 && e.charCode <= 57;
        }

        if (input.indexOf('.') < 0) {
            return e.charCode >= 48 && e.charCode <= 57 || e.charCode === 46;
        }
        if (input.split('.')[1].length > 1) {
            if (cursorIndex <= decimalIndex) {
                return e.charCode >= 48 && e.charCode <= 57;
            }
            return false;
        }
        if (cursorIndex <= decimalIndex) {
            return e.charCode >= 48 && e.charCode <= 57;
        }
        return e.charCode >= 48 && e.charCode <= 57;
    }

    get shouldShowServiceFee(): boolean {
        const val: number = Number(this.parentForm.get('accessTypeCost').value);
        return val > 0;
    }

}
