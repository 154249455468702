import { Component, OnDestroy } from '@angular/core';

import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { AddressBookGroupService } from '../addressBookGroup.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { ApiError } from '../../../../../ApiClient/Models/error';
import { Group } from '../../../../../ApiClient/Models/Group/group';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressBookService } from '../../addressBook.service';
import { AddressBookUserService } from '../../User/addressBookUser.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'address-book-edit-group',
    styles: [require('./address-book-edit-group.scss')],
    template: require('./address-book-edit-group.html'),
})

export class AddressBookEditGroupComponent {
    constructor(
        private modalService: ModalService,
        private addressBookGroupService: AddressBookGroupService,
        private addressBookUserService: AddressBookUserService,
        private addressBookService: AddressBookService,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    readonly groupPeopleBlueSVG = require('../../assets/circle-group-people-blue');

    requesting: boolean;
    isEditing: boolean;
    newGroupName: string;
    groupId: string;
    errors: ApiError[];

    ngOnInit() {
        this.errors = [];
        this.setExistingGroupIdFromUrl();
        this.fetchDetailedGroupInfo();
        this.isEditing = false;
        this.newGroupName = '';
    }

    fetchDetailedGroupInfo() {
        let groupIdToLoad = this.addressBookService.displayedForEditingGroup ? this.addressBookService.displayedForEditingGroup.id : this.groupId;
        this.requesting = true;
        this.addressBookGroupService.getGroup(groupIdToLoad)
            .toPromise()
            .then((res) => {
                this.requesting = false;
                this.addressBookService.displayedForEditingGroup = Group.fromApiResponse(res.data);
                this.newGroupName = this.addressBookService.displayedForEditingGroup.name;
                this.modalService.changeModalTitle(this.newGroupName + ' <small>(' + this.addressBookService.displayedForEditingGroup.memberCount + ' Contacts)</small>');
            });
    }

    setExistingGroupIdFromUrl() {
        try {
            this.groupId = this.route.children[0].snapshot.children[0].url[1].path;
        } catch (err) {
        }
    }

    selectGroup() {
        this.addressBookGroupService.groupListOptions.selectedGroup = this.addressBookService.displayedForEditingGroup;
        this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
        this.addressBookService.usersSelectedInList = [];
        this.addressBookService.broadcastGroup(this.addressBookService.displayedForEditingGroup);
        this.close();
    }

    close() {
        this.modalService.closeModal();
    }

    save() {
        if (this.requesting || this.newGroupName === this.addressBookService.displayedForEditingGroup.name) {
            return;
        }
        this.requesting = true;
        this.addressBookGroupService.changeGroupName(this.addressBookService.displayedForEditingGroup.id, this.eventFarmService.currentUser.id, this.newGroupName)
            .subscribe(
                (res) => {
                    this.message.success('Group updated');
                    this.addressBookGroupService.broadcastGroupsForUser(this.eventFarmService.currentUser.id);
                    this.modalService.changeModalTitle(this.newGroupName + '<small>(' + this.addressBookService.displayedForEditingGroup.memberCount + ' Contacts)</small>');
                }, (err) => {
                    this.requesting = false;
                    let formattedErrors: any[] = [];
                    if (err.error.errors.length) {
                        err.error.errors.forEach((error) => {
                            formattedErrors.push({
                                detail: error.detail
                            });
                        });
                    } else {
                        formattedErrors.push({
                            detail: 'Unable to save changes to group',
                        });
                    }
                    this.errors = ApiError.fromApiErrorArray(formattedErrors);
                },
                () => {
                    this.requesting = false;
                    this.isEditing = false;
                }
            );
    }
}
