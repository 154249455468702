import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchFilter'
})

export class FilterPipe implements PipeTransform {

    transform(items: any[], term: any) {
        if (!term) { return items; }
        return items.filter(item => item._slug.indexOf(term) !== -1);
    }
}
