import { Injectable } from '@angular/core';

import { EventFarmService } from '../../EventFarm/eventFarm.service';

import { SegmentAddressBookTracking } from './address';
import { SegmentEventTracking } from './event';
import { SegmentEventsListTracking } from './events-list';
import { SegmentGuestListTracking } from './guest-list';
import { SegmentGuestQuestionsTracking } from './guest-questions';
import { SegmentTicketTracking } from './tickets';
import { SegmentUploaderTracking } from './uploader';
import { SegmentPoolTracking } from './pool';
import { SegmentEFxTracking } from './efx';
import { SegmentSideNavTracking } from './side-nav';
import { SegmentReportTracking } from './report';
import { SegmentWebPresenceTracking } from './web-presence';
import { SegmentPromotionTracking } from './promotions';
import { SegmentTransfersTracking } from './transfers';

@Injectable()
export class SegmentService {
    constructor(
        private eventFarmService: EventFarmService
        ) { }

    public segmentAddressBookTracking() {
        return new SegmentAddressBookTracking(this.eventFarmService);
    }

    public segmentGuestListTracking() {
        return new SegmentGuestListTracking(this.eventFarmService);
    }

    public segmentGuestQuestionsTracking() {
        return new SegmentGuestQuestionsTracking(this.eventFarmService);
    }

    public segmentEventTracking() {
        return new SegmentEventTracking(this.eventFarmService);
    }

    public segmentPoolTracking() {
        return new SegmentPoolTracking(this.eventFarmService);
    }

    public segmentEventsListTracking() {
        return new SegmentEventsListTracking(this.eventFarmService);
    }

    public segmentTicketTracking() {
        return new SegmentTicketTracking(this.eventFarmService);
    }

    public segmentUploaderTracking() {
        return new SegmentUploaderTracking(this.eventFarmService);
    }

    public segmentEfxTracking() {
        return new SegmentEFxTracking(this.eventFarmService);
    }

    public segmentSideNavTracking() {
        return new SegmentSideNavTracking(this.eventFarmService);
    }

    public segmentReportTracking() {
        return new SegmentReportTracking(this.eventFarmService);
    }

    public segmentWebPresenceTracking() {
        return new SegmentWebPresenceTracking(this.eventFarmService);
    }

    public segmentPromotionsTracking() {
        return new SegmentPromotionTracking(this.eventFarmService);
    }

    public segmentTransfersTracking() {
        return new SegmentTransfersTracking(this.eventFarmService);
    }

}
