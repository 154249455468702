import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from './../../manage.routing-module';
import { AdminService } from '../../../admin.service';
import { EventFarmService } from '../../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AlertService } from '../../../../eventFarmAlert.service';
import { LoaderGrayModule } from '../../../../CoreUI/LoaderGray/loader-gray.module';
import { TeamFeatureService } from '../TeamFeatures/team-feature.service';
import { TeamContractDetailsComponent } from './team-contract-details.component';
import { TeamContractDetailsService } from './team-contract-details.service';
import { ContractDetailsFormModule } from '../../../Common/ContractDetailsForm/contract-details-form.module';
import {NgxUtilitiesModule} from '../../../../../NGXUtilities/ngx.utilities.module';

const forExport = [TeamContractDetailsComponent];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    LoaderGrayModule,
    ContractDetailsFormModule,
    NgxUtilitiesModule
  ],
  providers: [
    AdminService,
    EventFarmService,
    NzDrawerService,
    AlertService,
    TeamFeatureService,
    TeamContractDetailsService,
  ],
})
export class TeamContractDetailsModule {}
