import {Component, Input, OnInit} from '@angular/core';
import {EventFarmService} from '../../../../eventFarm.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import * as _ from 'lodash';
import {SegmentService} from '../../../../../Analytics/Segment/segment.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PaymentSettingsService} from '../payment-settings.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {AlertService} from '../../../../eventFarmAlert.service';
import {PoolPaymentGateway} from '../../../../../ApiClient/Models/PoolPaymentGateway/poolPaymentGateway';
import {PaymentGateway} from '../../../../../ApiClient/Models/PaymentGateway/paymentGateway';

@Component({
    selector: 'payment-gateway',
    template: require('./payment-gateway.html'),
    styles: [require('./payment-gateway.scss')],
})

export class PaymentGatewayComponent implements OnInit {
    @Input() gateway: PaymentGateway; // or whatever type `entry` is
    @Input() poolPaymentGateway: PoolPaymentGateway; // or whatever type `entry` is
    gatewayForm: FormGroup;
    private isEditing: boolean;
    private credentials;
    private mode;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private paymentSettingsService: PaymentSettingsService,
        private messageService: NzMessageService,
        private alertService: AlertService
    ) {

    }
    get name() { return this.gatewayForm.get('name'); }
    get isLive() { return this.gatewayForm.get('isLive'); }


    async ngOnInit() {
        this.credentials = this.gateway.formFields.length ? this.gateway.formFields[0].credentials : null;
        this.mode = this.gateway.formFields.length ? this.gateway.formFields[0].auth_mode_type : null;
        // If we haven't initialized the form yet
        if (!this.gatewayForm && this.credentials.length) {
            this.initializeForm();
        }
    }

    initializeForm() {
        const dynamicGroup = {};
        dynamicGroup['name'] = new FormControl(_.get(this, 'poolPaymentGateway.name'), [Validators.required]);
        dynamicGroup['isLive'] = new FormControl(_.get(this, 'poolPaymentGateway.isLive', true), [Validators.required]);
        this.credentials.forEach(c => {
            dynamicGroup[c.name] = new FormControl('');
        });
        this.gatewayForm = new FormGroup(dynamicGroup);
    }

    async makeDefault() {
        try {
            await this.paymentSettingsService.makeDefault(this.poolPaymentGateway.id)
            this.messageService.success('Default payment gateway updated successfully');
            await this.paymentSettingsService.initialize(this.eventFarmService.currentTeam.id)
        } catch (err) {
            this.messageService.success('Error updating default payment gateway');
        }
    }

    save() {
        if (this.poolPaymentGateway) {
            this.update();
        } else {
            this.create();
        }
    }

    // Revert field values to value of poolPaymentGateway
    resetFields() {
        this.gatewayForm.get('name').setValue(_.get(this, 'poolPaymentGateway.name'));
        this.gatewayForm.get('isLive').setValue(_.get(this, 'poolPaymentGateway.isLive', true));

        this.credentials.forEach(c => {
            this.gatewayForm.get([c.name]).setValue('');
        });
    }

    async cancel() {
        this.resetFields();
        this.isEditing = false;
    }

    getFormValuesWithoutName() {
        const values = this.gatewayForm.value;
        delete values.name;
        return values;
    }

    preparePayload() {
        const obj = {};
        // Obtain form values without name
        const values = this.getFormValuesWithoutName();
        Object.keys(values).map(key => {
            // dont trim sandbox
            if (key !== 'isLive') {
                const trimmedValue = values[key].trim();
                // only add key that have value with a length.
                // this will allow us to just update friendly or is sandboxmode
                if (trimmedValue.length) {
                    obj[key] = trimmedValue;
                }
            } 
        });

        const payload = {
            ...obj,
            gateway_type: this.gateway.gatewayType,
            description: this.gatewayForm.get('name').value.trim(),
            sandbox: !this.gatewayForm.get('isLive').value
        };

        if (Object.keys(obj).length) {
            payload['mode'] = this.mode;
        } 

        return payload;
    }

    async create() {
        try {
            await this.paymentSettingsService.createPoolPaymentGateway(
                this.eventFarmService.currentTeam.id,
                this.gatewayForm.get('name').value.trim(),
                this.gateway.gatewayType,
                this.preparePayload()
            );
            this.isEditing = false;
            this.messageService.success('Payment gateway created successfully');
            this.paymentSettingsService.initialize(this.eventFarmService.currentTeam.id);

        } catch (err) {
            this.messageService.error('Error creating payment gateway');
        }
    }

    async update() {
        try {
            await this.paymentSettingsService.updatePoolPaymentGateway(
                this.poolPaymentGateway.id,
                this.gatewayForm.get('name').value.trim(),
                this.gateway.gatewayType,
                this.preparePayload()
            );

            this.isEditing = false;
            this.messageService.success('Payment gateway updated successfully');
            this.paymentSettingsService.initialize(this.eventFarmService.currentTeam.id);

        } catch (err) {
            this.messageService.error('Error updating payment gateway');

        }
    }

    async delete() {
        try {
            const message = 'Are you sure you want to remove this gateway? Connected events will no longer be able to receive payments!';
            const confirmDelete = await this.alertService.paymentSettings().confirmRemovePoolPaymentGateway(message);

            if (confirmDelete.value) {
                await this.paymentSettingsService.deletePoolPaymentGateway(
                    this.poolPaymentGateway.id
                );
                this.isEditing = false;
                this.messageService.success('Payment gateway deleted successfully');
                await this.paymentSettingsService.initialize(this.eventFarmService.currentTeam.id);
                this.poolPaymentGateway = null;
                return true;
            } else {
                return false;
            }

        } catch (err) {
            this.messageService.error('Error deleting payment gateway');
        }
    }


}
