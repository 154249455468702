import { Pipe } from '@angular/core';

@Pipe({
    name: 'ArchivedEventPipe',
})
export class ArchivedEventPipe {
    transform(events, args?) {
        const matchedEvents = [];
        events.forEach((eachEvent) => {
            if (eachEvent.eventAttributes && eachEvent.eventAttributes.hasArchived === args) {
                matchedEvents.push(eachEvent);
            }
            if (!eachEvent.eventAttributes) {
                matchedEvents.push(eachEvent);
            }
        });
        return matchedEvents;
    }
}
