import { Component } from '@angular/core';
import { WaitListService } from './../waitList.service';

@Component({
    selector: 'waitlist-settings',
    template: require('./waitListSettings.html')
})

export class WaitListSettingsComponent {
    constructor(private waitListService: WaitListService) {

    }

    toggleSelectAll() {
        if (this.waitListService.selectAll) {
            this.waitListService.addAll();
        } else {
            this.waitListService.removeAll();
        }

    }
}
