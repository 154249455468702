import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventPromotionsComponent } from './eventPromotions.component';

const routes: Routes = [
    {
        path: '',
        component: EventPromotionsComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EventPromotionsRoutingModule {
}
