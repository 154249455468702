import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {NzSpinModule} from "ng-zorro-antd/spin";
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import {NzTagModule} from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageServiceModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import {NzTimelineModule} from "ng-zorro-antd/timeline";
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { ColorPickerModule } from 'ngx-color-picker';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzCardModule } from 'ng-zorro-antd/card';


const forExport = [
    NzButtonModule,
    NzIconModule,
    NzSwitchModule,
    NzDropDownModule,
    NzMenuModule,
    NzInputModule,
    NzDividerModule,
    NzSelectModule,
    NzRadioModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzPopoverModule,
    NzDrawerModule,
    NzMessageModule,
    NzMessageServiceModule,
    NzModalModule,
    NzSliderModule,
    NzCheckboxModule,
    NzTabsModule,
    NzAutocompleteModule,
    NzBadgeModule,
    NzPaginationModule,
    NzStepsModule,
    NzToolTipModule,
    NzTableModule,
    NzFormModule,
    NzInputNumberModule,
    NzAlertModule,
    NzNotificationModule,
    ColorPickerModule,
    NzSpinModule,
    NzTimelineModule,
    NzTagModule,
    NzImageModule,
    NzStatisticModule,
    NzCardModule

];

@NgModule({
    exports: forExport,
})

export class NzUtilitiesModule {
}
