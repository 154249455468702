import { AbstractBaseAlert } from '../base-alert';

export class AddressBookAlert extends AbstractBaseAlert {

    deleteGroupAlert(groupName: string): Promise<any> {
        return this.alert({
            title: 'Delete Group',
            html: `Are you sure you want to delete <strong>${groupName}</strong>?`,
            confirmButtonText: 'Yes, delete group',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    saveUserChanges(): Promise<any> {
        return this.alert({
            title: 'Do you want to save the changes you made to the contact?',
            confirmButtonText: 'Save changes',
            html: 'Or <a href="#" isclicked="false" class="ef-swal-custom-back-link" onclick="document.querySelector(\'.ef-swal-custom-back-link\').setAttribute(\'isclicked\', true); swal.clickConfirm();">Go Back</a> to continue to edit your contact.',
            preConfirm: function() {
                return new Promise((resolve) => {
                    const value = document.querySelector('.ef-swal-custom-back-link').getAttribute('isclicked') === 'true' ? 'go-back' : 'confirm';
                    resolve(value);
                });
            },
            showCancelButton: true,
            cancelButtonText: 'Discard Changes',
            icon: 'question',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    userDuplicatedSuccess(): Promise<any> {
        return this.alert({
            title: 'Email successfully changed',
            text: 'New email address set for contact successfully. Any existing invitations for this guest will still be tied to the previous email address, which can still be found in your address book.',
            icon: 'success',
        });
    }

    userCreatedSuccess(): Promise<any> {
        return this.alert({
            title: 'You have successfully created a new contact!',
            icon: 'success',
        });
    }

    usersAddedToGroupSuccess(): Promise<any> {
        return this.alert({
            title: 'You have successfully added contacts to a group!',
            icon: 'success',
        });
    }

    userExistsInPool(): Promise<any> {
        return this.alert({
            title: 'Contact already exists',
            text: 'This contact already exists in your address book. View contact details?',
            confirmButtonText: 'Yes, view details',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'question',
            confirmButtonColor: '#2983d8',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    removeSingleUserFromGroupWarning(userName: string, groupName: string): Promise<any> {
        return this.alert({
            title: 'Remove contact from group',
            text: 'Are you sure you want to remove ' + userName + ' from ' + groupName + '?',
            confirmButtonText: 'Yes, remove from group',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    removeSingleUserFromPoolWarning(userName: string): Promise<any> {
        return this.alert({
            title: 'Are you sure you want to delete ' + userName + ' from your team\'s address book?',
            confirmButtonText: 'Yes, delete contact',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    contactSyncedToSalesForce(userName: string): Promise<any> {
        return this.alert({
            title: 'Contact cannot be deleted',
            text: userName + ' is a contact imported from your Salesforce campaign and cannot be deleted from your address book.',
            icon: 'error',
        });
    }

    confirmUserRemove(userName: string): Promise<any> {
        return this.alert({
            title: 'Confirm contact deletion',
            text: 'Are you sure you want to delete ' + userName + ' from your address book?',
            confirmButtonText: 'Yes, delete contact',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    confirmMultipleUserRemove(count: number): Promise<any> {
        const hasPlural = count > 1 ? 's' : '';
        return this.alert({
            title: 'Confirm contacts deletion',
            text: 'Are you sure you want to delete ' + count + ' contact' + hasPlural + ' from your address book?',
            confirmButtonText: 'Yes, delete contact',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    cannotRemoveUser(reason: string = null): Promise<any> {
        return this.alert({
            title: 'Unable to remove contact from address book',
            text: 'This contact cannot be deleted due to the following: ' + reason,
            icon: 'error',
        });
    }

    unableToRemoveUser(userName: string, reason: string = null): Promise<any> {
        return this.alert({
            title: 'Unable to remove contact from address book',
            text: 'Unable to remove ' + userName + ' from address book. ' + reason,
            icon: 'error',
        });
    }

    bulkRemoveUsersFromGroupWarning(groupName: string): Promise<any> {
        return this.alert({
            title: 'Are you sure you want to remove these users from ' + groupName + '?',
            confirmButtonText: 'Yes, remove from group',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    checkIfUserWantsToAbandonEmailChanges(): Promise<any> {
        return this.alert({
            title: 'Unsaved email changes detected',
            text: 'You have entered changes to the contact’s email address that have not been saved. Email changes must be saved separately by clicking “Save” text to the email field. Would you like to save other contact changes, except for this updated email?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Save changes except email',
            confirmButtonColor: '#5085F0',
            cancelButtonColor: '#a5a8b2',
        });
    }

    confirmMaxAllowedContactRemoval(count: number): Promise<any> {
        return this.alert({
            title: 'Exceeded maximum allowed contact deletion',
            text: 'Up to ' + count + ' contacts can be deleted at once. Would you like to remove the first ' + count + ' you have selected?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            focusCancel: true,
            confirmButtonText: 'Remove first ' + count,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2',
        });
    }

    unableToDeleteContacts(reason: string): Promise<any> {
        return this.alert({
            title: 'Unable to delete contacts',
            text: 'Your contacts could not be deleted because at least one of the selected contacts could not be removed: ' + reason,
            icon: 'error',
        });
    }

    performActionFailure(): Promise<any> {
        return this.alert({
            title: 'Action failed',
            text: 'Please try again later, or contact Customer Success.',
            icon: 'error',
        });
    }

    selectedContactsLimitError(): Promise<any> {
        return this.alert({
            text: 'Only 1000 contacts can be selected at once. Perform your bulk action on the current selection to continue.',
            icon: 'error',
        });
    }
}
