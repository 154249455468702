import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Answer } from '../../../../../../../ApiClient/Models/Answer/answer';
import { EditQuestionService } from '../edit-question.service';

declare var $: any;

@Component({
    selector: 'answer-text',
    styles: [require('./answer-text.scss')],
    template: require('./answer-text.html'),
})

export class AnswerTextComponent implements OnInit, OnDestroy {

    constructor(
        private sanitizer: DomSanitizer,
        private editQuestionService: EditQuestionService
    ) {
    }

    public options = {
        key: 'dKA5cC3C2D2C1E2A4A4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4B2E4C2C2E3C1C2==',
        toolbarButtons: [
            'fullscreen', 'ef_codeView', '|',
            'fontFamily', 'fontSize', '|',
            'bold', 'italic', 'underline', 'color', '|',
            'formatOL', 'formatUL', 'outdent', 'indent', '|',
            'paragraphFormat', 'align', '|',
            'ef_insertImage', 'insertLink', 'insertTable', 'insertHTML', '|',
            'undo', 'redo', 'clearFormatting', '|',
            'ef_editButton'
        ],
        dragInline: false,
        attribution: false,
        pasteDeniedAttrs: ['class', 'id'],
        useClasses: false,
        keepFormatOnDelete: true,

        linkEditButtons: ['linkEdit', 'linkRemove', '|', 'ef_editButton'],
        fontFamilySelection: true,
        fontFamily: {
            'Arial, Sans-Serif': 'Arial',
            'Arial Black, Gadget, Sans-Serif': 'Arial Black',
            'Helvetica, Sans-Serif': 'Helvetica',
            'Courier New, Courier, Monospace': 'Courier New',
            'Georgia, Times New Roman, Times, Serif': 'Georgia',
            'Impact, Charcoal, Sans-Serif': 'Impact',
            'Lucida Console, Monaco, Monospace': 'Lucida Console',
            'Lucida Sans Unicode, Lucida Grande, Sans-Serif': 'Lucida Sans',
            'Palatino Linotype, Book Antiqua, Palatino, Serif': 'Palatino',
            'Tahoma, Geneva, Sans-Serif': 'Tahoma',
            'Times New Roman, Times, Serif': 'Times New Roman',
            'Trebuchet MS, Helvetica, Sans-Serif': 'Trebuchet',
            'Verdana, Geneva, Sans-Serif': 'Verdana'
        },
        fontSizeSelection: true,
        charCounterCount: false,
        codeMirror: false,
        htmlAllowedEmptyTags: ['table'],
        linkConvertEmailAddress: false,
        imageAllowedTypes: [],
        imageEditButtons: [],
        inlineStyles: {
            'Full Width': `border-radius: 100px; display: inline-block; padding: 16px 28px;`,
            'Small Blue': 'font-size: 14px; color: blue;',
        },
        pluginsEnabled: [],
    };
    public FroalaEditor: any;

    ngOnInit() {
        if (!this.editQuestionService.currentQuestionAnswers.length) {
            const newAnswer = new Answer();
            newAnswer.text = '<p></p>';
            this.editQuestionService.currentQuestionAnswers.push(newAnswer);
        }
    }

    ngOnDestroy() {
      if ($('.fr-box').length) {
          $('.fr-box').froalaEditor('destroy');
        }
    }
}
