import { Component, OnDestroy, OnInit } from '@angular/core';
import { EFXAdminService, EFXNFCSETTING } from '../../efx-admin.service';

@Component({
  selector: 'efx-nfc',
  template: require('./efx-nfc.html'),
})

export class EFXNFCComponent implements OnDestroy {

  constructor(
    private efxAdminService: EFXAdminService
  ) {
  }

  toggle() {

    if (this.efxAdminService.isNFCEnabled) {
      this.efxAdminService.toggleEFXContactSetting(EFXNFCSETTING, false);
    } else {
      this.efxAdminService.toggleEFXContactSetting(EFXNFCSETTING, true);
    }
  }

  ngOnDestroy() {
  }
}
