import { Analytics } from '@segment/analytics-node'
import { EventFarmService } from '../../EventFarm/eventFarm.service';
import * as _ from 'lodash';
declare const __IS_STAGE__: boolean;
declare const __IS_PRODUCTION__: boolean;

export abstract class AbstractBaseSegment {

    private analytics: Analytics = new Analytics({writeKey: this.segmentKey});
    private platform = require('platform');

    constructor(private eventFarmService: EventFarmService) {
    }

    private get segmentKey() {
        if (__IS_PRODUCTION__ && !__IS_STAGE__) {
            return 'ehsMwWL5XLwjQWs7X3OWiQAr6SrIVsVv';
        } else {
            return 'OD2fg1bTVlspOLJtGLwx1nsz7hoPf51T';
        }
    }

    private get context(): object {
        const traits = this.eventFarmService.currentUser ? this.userTraits : null;
        return {
            device: {
                model: this.platform.description,
                manufacturer: this.platform.manufacturer,
                type: this.platform.product
            },
            os: {
                name: this.platform.name,
                version: this.platform.version
            },
            traits: traits
        };
    }

    private get userTraits(): object {
        return {
            id:  _.get(this, 'eventFarmService.currentUser.id'),
            name: _.get(this, 'eventFarmService.currentUser.name.fullName'),
            email: _.get(this, 'eventFarmService.currentUser.primaryEmail'),
            company: {
                name: _.get(this, 'eventFarmService.currentTeam.name'),
                id: _.get(this, 'eventFarmService.currentTeam.id')
            }
        };
    }

    private get companyTraits(): object {
        return {
            name: _.get(this, 'eventFarmService.currentTeam.name'),
            id: _.get(this, 'eventFarmService.currentTeam.id')
        };
    }

    track(tr: Event, attachCurrentEvent: boolean = false) {
        const tracking: Tracking = {...tr, userId: this.eventFarmService.currentUser ?  _.get(this, 'eventFarmService.currentUser.id') : 'anonymous', context: this.context };

        if (attachCurrentEvent) {
           const updatedProperties = { ...tracking.properties, eventId: _.get(this, 'eventFarmService.currentEvent.id'), eventName: _.get(this, 'eventFarmService.currentEvent.name') };
           tracking.properties = updatedProperties;
        }

        // @ts-ignore
        this.analytics.track(tracking);
    }

    identify() {
        const traits = this.eventFarmService.currentUser ? this.userTraits : null;
        const identify: Identify = {userId: this.eventFarmService.currentUser ?  _.get(this, 'eventFarmService.currentUser.id') : 'anonymous', context: this.context, traits: traits };
        this.analytics.identify(identify);
    }

    group() {
        const group: Group = {userId:  _.get(this, 'eventFarmService.currentUser.id', ''), groupId:  _.get(this, 'eventFarmService.currentTeam.id', ''), traits: this.companyTraits, context: this.context };
        this.analytics.group(group);
    }

}

interface Event {
    event: string;
    properties?: object;
}

interface Tracking {
    userId?: string;
    event: string;
    properties?: object;
    timestamp?: Date;
    context?: object;
    anonymousId?: string
}

interface Identify {
    anonymousId?: string;
    userId: string;
    traits?: object;
    timestamp?: Date;
    context?: object;
}

interface Group {
    userId: string;
    groupId: string;
    traits?: object;
    context?: object;
    timestamp?: Date;
    anonymousId?: string;
}
