import { NgModule } from '@angular/core';
import {CreateTeamComponent} from './create-team.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContractDetailsFormModule} from '../../Common/ContractDetailsForm/contract-details-form.module';
import {CommonModule} from '@angular/common';
import {AdminAlertModule} from '../../Alert/admin-alert.module';
import {AdminService} from '../../admin.service';
import {LegacyUIService} from '../../../LegacyUI/legacy-UI.service';
import {ManageRoutingModule} from '../manage.routing-module';
import {BreadcrumbsModule} from '../../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import {NzUtilitiesModule} from '../../../../NZUtilities/nz.utilities.module';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {TeamEventsModule} from '../ManageTeam/TeamEvents/team-events.module';
import {LoaderGrayModule} from '../../../CoreUI/LoaderGray/loader-gray.module';
import {EventFarmService} from '../../../eventFarm.service';
import {GuestListService} from '../../../EventsModule/GuestList/guest-list.service';
import {AlertService} from '../../../eventFarmAlert.service';
import {TeamPointOfContactService} from '../ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import {TeamEmailMasksService} from '../ManageTeam/TeamEmailMasks/team-email-masks.service';
import {NgxUtilitiesModule} from '../../../../NGXUtilities/ngx.utilities.module';


@NgModule({
    declarations: [CreateTeamComponent],
    exports: [CreateTeamComponent],
    imports: [
        ManageRoutingModule,
        CommonModule,
        FormsModule,
        BreadcrumbsModule,
        NzTabsModule,
        ReactiveFormsModule,
        NzUtilitiesModule,
        AutocompleteLibModule,
        TeamEventsModule,
        LoaderGrayModule,
        AdminAlertModule,
        ContractDetailsFormModule,
        NgxUtilitiesModule
    ],
    entryComponents: [CreateTeamComponent],
    providers: [
        AdminService,
        EventFarmService,
        NzDrawerService,
        GuestListService,
        LegacyUIService,
        AlertService,
        TeamPointOfContactService,
        TeamEmailMasksService,
    ],
})
export class CreateTeamModule {}
