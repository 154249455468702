import { Component, OnInit } from '@angular/core';
import { AccountInfoService } from '../account-info.service';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { RequestEFxComponent } from './requestEFx.component';
import { EventFarmService } from '../../../eventFarm.service';

@Component({
    selector: 'efx-listing',
    template: require('./efx-listing.html'),
    styles: [require('./efx-listing.scss')],
})

export class EfxListingComponent implements OnInit {

    readonly efxLogo = require('./../assets/efx-logo');

    constructor(
        private accountInfoService: AccountInfoService,
        private iconService: IconService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private modalService: ModalService,
        public route: ActivatedRoute,
        private efs: EventFarmService
    ) {
    }

    private efxEventsFuture: EfEvent[];
    private efxEventsPast: EfEvent[];

    ngOnInit() {
        this.efxEventsFuture = [];
        this.efxEventsPast = [];
        this.accountInfoService.fetchEfxEvents('current-future').toPromise().then((res) => {
            res.data.forEach((event) => {
                this.efxEventsFuture.push(EfEvent.fromApiResponse(event));
            });
        });
        this.accountInfoService.fetchEfxEvents('past-3-months').toPromise().then((res) => {
            res.data.forEach((event) => {
                this.efxEventsPast.push(EfEvent.fromApiResponse(event));
            });
        });
    }

    private requestUpgrade() {
        const modal: Modal = new Modal();
        modal.title = 'Request EFx';
        modal.component = RequestEFxComponent;
        modal.cssClass = 'full-width';
        this.modalService.changeModal(modal);
    }
}
