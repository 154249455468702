import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { AlertService } from '../../../eventFarmAlert.service';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import {GUEST_ACTIVITY_REPORTS, GUEST_LIST_REPORTS, COMMUNICATIONS_REPORTS, ReportTypes, ReportTypeEnum, Report} from './Models/report-models';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Injectable()
export class ReportService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private segmentService: SegmentService
    ) {
    }

    public readonly currentReports$ = new BehaviorSubject<Report[]>([]);
    public isLoadingReports: boolean = false;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get isCIO(): boolean {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.isTypeCio : false;
    }


    get smsEnabled(): boolean {
        return this.eventFarmService.smsEnabled;
    }


    public clearList() {
        this.currentReports$.next([]);
    }

    get listCount(): number {
        return this.currentReports$.value.length;
    }

    /**
     * All the possible report types from the report service
     */
    public guestListReports: ReportTypes = GUEST_LIST_REPORTS;
    public commsReports: ReportTypes = COMMUNICATIONS_REPORTS;
    public guestActivityReports: ReportTypes = GUEST_ACTIVITY_REPORTS;

    /**
     * Gets the reports associated with the eventId
     */
    public getReportsForEvent() {

        this.isLoadingReports = true;
        const eventId = this.eventFarmService.currentEvent.id;

        this.apiClient.getUseCaseFactory().Report().ListReportsForEvent(eventId, null, null, 'createdAt', 'descending').toPromise().then( (reports) => {
            this.currentReports$.next(reports.data.map(report => new Report(report)));
            this.isLoadingReports = false;
        }).catch(err => {
            this.message.error('Please try again');
            this.isLoadingReports = false;
          });
    }

    /**
     * Generates the report based on the current report type selected.
     */
    public generateReport(slug: ReportTypeEnum, reportName: string = ''): void {

        const userId = this.eventFarmService.currentUser.id;
        const eventId = this.eventFarmService.currentEvent.id;

        const reportDetails = {
            reportName,
            reportType: ''
        };

        switch (slug) {
            case ReportTypeEnum.GL_SUMMARY:
                this.apiClient.getUseCaseFactory().Report().CreateGuestSummaryReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_SUMMARY';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_CONFIRMED:
                this.apiClient.getUseCaseFactory().Report().CreateConfirmedGuestReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_CONFIRMED';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_UNCONFIRMED:
                this.apiClient.getUseCaseFactory().Report().CreateUnconfirmedGuestReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_UNCONFIRMED';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_TRANSFER:
                this.apiClient.getUseCaseFactory().Report().CreateTransferReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_TRANSFER';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_WAITLIST:
                this.apiClient.getUseCaseFactory().Report().CreateWaitListReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_WAITLIST';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_PURCHASE:
                this.apiClient.getUseCaseFactory().Report().CreatePurchasedReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_PURCHASE';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_CHECKIN:
                this.apiClient.getUseCaseFactory().Report().CreateCheckinReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_CHECKIN';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.GL_TICKET_BLOCK:
                this.apiClient.getUseCaseFactory().Report().CreateTicketBlockUserReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'GL_TICKET_BLOCK';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.COMMS_EMAIL_DELIVERABILITY:
                this.apiClient.getUseCaseFactory().Report().CreateEmailDeliverabilityReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'COMMS_EMAIL_DELIVERABILITY';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.COMMS_SENT_EMAILS:
                this.apiClient.getUseCaseFactory().Report().CreateSentEmailReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'COMMS_SENT_EMAILS';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.ACTIVITY_LOG:
                this.apiClient.getUseCaseFactory().Report().CreateActivityLogReport(eventId, userId, reportName).toPromise().then( (report) => {
                    this.message.success('Report being queued');
                    reportDetails.reportType = 'ACTIVITY_LOG';
                    this.segmentService.segmentReportTracking().generateReport(reportDetails);
                    this.getReportsForEvent();
                }).catch(err => {
                    this.message.error('Please try again');
                  });
                break;
            case ReportTypeEnum.COMMS_SENT_SMS:
                    this.apiClient.getUseCaseFactory().Report().CreateSentSMSReport(eventId, userId, reportName).toPromise().then( (report) => {
                        this.message.success('Report being queued');
                        reportDetails.reportType = 'COMMS_SENT_SMS';
                        this.segmentService.segmentReportTracking().generateReport(reportDetails);
                        this.getReportsForEvent();
                    }).catch(err => {
                        this.message.error('Please try again');
                      });
                    break;
            default:
                this.message.error('Should Never Get Here');
                break;
          }
    }
}
