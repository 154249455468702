import { AbstractModel } from '../abstract-model';

export class DomainMask extends AbstractModel {
    private _domain?: string;
    private _ipCreated?: string;




    constructor(id: string) {
        super(id);

    }

    public static fromApiResponse(data): DomainMask {
        const domainMask = new this(data.id);
        domainMask._domain = data.attributes.domain;
        domainMask._ipCreated = data.attributes.ipCreated;
        return domainMask;
    }

    get domain(): string {
        return this._domain;
    }
    get ipCreated(): string {
        return this._ipCreated;
    }
    get id(): string {
        return this._id;
    }
}
