import { VenueTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Venue';
import { AbstractModel } from '../abstract-model';


export class Venue extends AbstractModel {
    private _name: string;
    private _capacity: number;
    private _description: string;
    private _url: string;
    private _venueType: VenueTypeInterface;

    private _address: string;
    private _city: string;
    private _country: string;
    private _county: string;
    private _postalCode: string;
    private _province: string;
    private _street1: string;
    private _street2: string;
    

    constructor(id?: string) {
        super(id);
      }

    public static fromEventResponse(data): Venue {
        const venue = new this(data.id);
        try {
            venue._name = data.name ?? '';
            venue._venueType = data.venueType;
            venue._capacity = data.capacity ?? 0;
            venue._description = data.description ?? '';
            venue._url = data.url ?? '';

            venue._address = data.address ?? '';
            venue._city = data.city ?? '';
            venue._country = data.country ?? '';
            venue._county = data.county ?? '';
            venue._postalCode = data.postalCode ?? '';
            venue._province = data.province ?? '';
            venue._street1 = data.street1 ?? '';
            venue._street2 = data.street2 ?? '';
        } catch (err) {
        }
        return venue;
    }

    public static fromApiResponse(data): Venue {
        const venue = new this(data.id);
        try {
            venue._name = data.attributes.name ?? '';
            venue._venueType = data.attributes.venueType;
            venue._capacity = data.attributes.capacity ?? 0;
            venue._description = data.attributes.description ?? '';
            venue._url = data.attributes.url ?? '';

            venue._address = data.attributes.address ?? '';
            venue._city = data.attributes.city ?? '';
            venue._country = data.attributes.country ?? '';
            venue._county = data.attributes.county ?? '';
            venue._postalCode = data.attributes.postalCode ?? '';
            venue._province = data.attributes.province ?? '';
            venue._street1 = data.attributes.street1 ?? '';
            venue._street2 = data.attributes.street2 ?? '';
        } catch (err) {
        }
        return venue;
    }

    public static fromChildCreation(data): Venue {
        const venue = new this();
        try {
            venue._name = data.name;
            venue._address = data.address;
        } catch (err) {
        }
        return venue;
    }

    
    
    get venueTypeDisplayName(): any {
        if (this._venueType.isPhysical) {
            return "In-Person";
        } else if (this._venueType.isVirtual) {
            return "Virtual";
        } else if (this._venueType.isVirbela) {
            return "The Echo";
        } else {
            return "Other"
        }
    }


    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get capacity(): number {
        return this._capacity;
    }

    set capacity(value: number) {
        this._capacity = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    get venueType(): VenueTypeInterface {
        return this._venueType;
    }

    set venueType(value: VenueTypeInterface) {
        this._venueType = value;
    }

    get address(): string {
        return this._address;
    }

    set address(value: string) {
        this._address = value;
    }

    get city(): string {
        return this._city;
    }

    set city(value: string) {
        this._city = value;
    }

    get country(): string {
        return this._country;
    }

    set country(value: string) {
        this._country = value;
    }

    get county(): string {
        return this._county;
    }

    set county(value: string) {
        this._county = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get province(): string {
        return this._province;
    }

    set province(value: string) {
        this._province = value;
    }

    get street1(): string {
        return this._street1;
    }

    set street1(value: string) {
        this._street1 = value;
    }

    get street2(): string {
        return this._street2;
    }

    set street2(value: string) {
        this._street2 = value;
    }
}
