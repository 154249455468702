import { AbstractModel } from '../abstract-model';

export class Group extends AbstractModel {

    public static fromApiResponse(data) {
        const group = new this(data.id);

        if (data.meta.isInitialized) {
            group._name = data.attributes.name;
            group._formattedDate = data.attributes.createdAt;
            group._isDeleted = data.attributes.isDeleted;
            group._created = data.attributes.created.createdAt;
            group._lastModified = data.attributes.modified.modifiedAt;
        }

        if (data.extraAttributes && data.extraAttributes.totalUsersInGroup != null) {
            group._memberCount = data.extraAttributes.totalUsersInGroup;
        }

        if (data.relationships && data.relationships.creatorUser && data.relationships.creatorUser.attributes) {
            group._createdBy = data.relationships.creatorUser.attributes.primaryEmail;
        }

        return group;
    }

    private _name?: string;
    private _createdBy?: string;
    private _created?: number;
    private _lastModified?: number;
    private _memberCount?: string;
    private _formattedDate?: string;
    private _isDeleted?: boolean;

    constructor(id) {
        super(id);
    }

    get name(): string {
        return this._name;
    }

    get memberCount(): string {
        return this._memberCount;
    }

    get formattedDate(): string {
        return this._formattedDate;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    get createdBy(): string {
        return this._createdBy;
    }

    get created(): number {
        return this._created;
    }

    get lastModified(): number {
        return this._lastModified;
    }

    set name(value: string) {
        this._name = value;
    }
}
