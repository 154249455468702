import { Component, OnDestroy, OnInit } from '@angular/core';
import { EFXAdminService, EFXSMSSETTING } from '../../efx-admin.service';

@Component({
  selector: 'efx-sms',
  template: require('./efx-sms.html'),
  styles: [require('../efx-contact.scss')],
})

export class EFXSMSComponent implements OnDestroy {

  constructor(
    private efxAdminService: EFXAdminService
  ) {
  }

  toggle() {

    // if (this.efxAdminService.isSMSEnabled) {
    //   this.efxAdminService.toggleEFXContactSetting(EFXSMSSETTING, false);
    // } else {
    //   this.efxAdminService.toggleEFXContactSetting(EFXSMSSETTING, true);
    // }
  }

  ngOnDestroy() {
  }
}
