import {AbstractControl} from '@angular/forms';

export const validateUrlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export function isValidUrl(url: string): boolean {
    return validateUrlRegex.test(url);
}

// Used for Reactive Forms
export function ValidateUrl(control: AbstractControl) {
        if (control && control.value) {
            const isValid = isValidUrl(control.value.trim());
            return !isValid ? {warning: true} : null;
        } else {
            return null;
        }
}
