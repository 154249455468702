import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../../../eventFarmAlert.service';
import { SalesforceEventIntegrationsService } from '../eventIntegrations.service';

@Component({
    selector: 'sync-settings',
    template: require('./syncSettings.html'),
    styles: [require('./syncSettings.scss')],
})

export class SyncSettingsComponent implements OnInit {

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private alertService: AlertService,
    ) {
    }

    @Input() eventSettings;

    ngOnInit() {
    }

    enableImport(salesforceEventSettingId) {
        this.eventSettings.isSyncing = true;
        this._sfeis.enableImport(salesforceEventSettingId)
            .then((res) => {
                this.alertService.salesforceIntegration().enableImport();
                this.eventSettings.salesforceConfig.attributes.isAutoImportEnabled = true;
                this.eventSettings.isSyncing = false;
            });
    }

    disableImport(salesforceEventSettingId) {
        this.eventSettings.isSyncing = true;
        this._sfeis.disableImport(salesforceEventSettingId)
            .then((res) => {
                this.alertService.salesforceIntegration().disableImport();
                this.eventSettings.salesforceConfig.attributes.isAutoImportEnabled = false;
                this.eventSettings.isSyncing = false;
            });
    }

    enableExport(salesforceEventSettingId) {
        this.eventSettings.isSyncing = true;
        this._sfeis.enableExport(salesforceEventSettingId)
            .then((res) => {
                this.alertService.salesforceIntegration().enableExport();
                this.eventSettings.salesforceConfig.attributes.isAutoExportEnabled = true;
                this.eventSettings.isSyncing = false;
            });
    }

    disableExport(salesforceEventSettingId) {
        this.eventSettings.isSyncing = true;
        this._sfeis.disableExport(salesforceEventSettingId)
            .then((res) => {
                this.alertService.salesforceIntegration().disableExport();
                this.eventSettings.salesforceConfig.attributes.isAutoExportEnabled = false;
                this.eventSettings.isSyncing = false;
            });
    }
}
