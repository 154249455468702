import { Injectable } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Subject } from 'rxjs';

@Injectable()

export class CreateEventService {

    public isCountingEvents = true;
    public fullEventsRemaining: number|string;
    public cioEventsRemaining: number|string;

    public eventTypeCounts = new Subject();

    constructor(
        public eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient
    ) {}

    getEventTypeCounts(data) {
        if (!data.length) {
            this.fullEventsRemaining = 0;
            this.cioEventsRemaining = 0;
            this.isCountingEvents = false;
            return;
        }
        let eventCounts;
        let fullEventsRemainingCount;
        let cioEventsRemainingCount;

        eventCounts = data;

        const efAllotments = eventCounts.filter(allotment => allotment.attributes.poolAllotmentType.slug === 'eventfarm');
        fullEventsRemainingCount = efAllotments.length > 0 ? efAllotments[0].attributes.maxEvents : 0;
        
        const cioAllotments = eventCounts.filter(allotment => allotment.attributes.poolAllotmentType.slug === 'cio');
        cioEventsRemainingCount = cioAllotments.length > 0 ? cioAllotments[0].attributes.maxEvents : 0;

        this.eventTypeCounts.next(this.fullEventsRemaining);

        this.fullEventsRemaining = fullEventsRemainingCount;
        this.cioEventsRemaining = cioEventsRemainingCount;
        this.isCountingEvents = false;
    }

    listPoolAllotmentsForPool(poolId) {
        return this.apiClient.getUseCaseFactory().Pool().ListPoolAllotmentsForPool(poolId);
    }
}
