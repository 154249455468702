import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { EventAgendaService } from '../event-agenda.service';

@Component({
    selector: 'event-sessions',
    template: require('./sessions.html'),
    styles: [require('./sessions.scss')],
})

export class SessionsComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventAgendaService: EventAgendaService
    ) {
    }

    async ngOnInit() {
        await this.eventAgendaService.getAllSessions();
        await this.eventAgendaService.getProfiles(false, null, true);
    }

    ngOnDestroy() {
        this.eventAgendaService.clearSesssions();
    }
}
