import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import _ = require('lodash');
import {BehaviorSubject, Subject} from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

@Component({
    selector: 'event-time-zone',
    template: `
                <input
                        placeholder="Search By City"
                        nz-input
                        [formControl]="input"
                        [nzAutocomplete]="auto"
                        (blur)="onTouch()"
                        (input)="searchForTimeZones($event)"
                />
                <nz-autocomplete [nzDataSource]="filteredTimeZones$ | async" #auto></nz-autocomplete>
    `,
    providers:
        [ {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EventTimeZoneTypeahead),
        }],
})

export class EventTimeZoneTypeahead implements OnDestroy {
    public isSearching = false;
    private ignoreNextValueChange = false;

    filteredTimeZones$ = new BehaviorSubject<any>([]);

    constructor(private apiClient: EventFarmAPIClient) { }

    private searchForTimeZones($event) {
        const term = $event.target.value
        this.isSearching = true;
        this.apiClient.getUseCaseFactory().Region().ListTimezonesForRegion(term)
        .subscribe(val => {
            this.filteredTimeZones$.next(_.get(val, 'data.attributes', []))
            this.isSearching = false;
        })

    }
    value: any;

    input = new FormControl('');

    handleChange(val) {
        this.input.setValue(val);
    }

    // Step 4: use the setValue method on the form control to update it when a new value comes in from the outside
    writeValue(input: string) {
        this.input.setValue(input);
    }

    // Step 5: Listen to value changes on the form control. If something happens, the function provided by Angular will be called and the outside world will be informed.
    subscriptions = [];
    registerOnChange(fn: any): void {
        this.subscriptions.push(this.input.valueChanges.subscribe(fn));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    // Step 6: onTouch stays the same
    onTouch: any = () => {};
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
}
