import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { EventFarmService } from '../../../eventFarm.service';
import { AccountInfoService } from '../account-info.service';

import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { RequestUpgradeComponent } from '../RequestUpgrade/request-upgrade.component';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
@Component({
    selector: 'plan-details',
    template: require('./plan-details.html'),
    styles: [require('./plan-details.scss')],
})

export class PlanDetailsComponent implements OnInit {

    readonly efLogo = require('./../assets/ef-logo');
    readonly eventStage = require('./../assets/event-stage');
    readonly renewIcon = require('./../assets/renew-icon');
    readonly openIcon = require('./../assets/open-icon');
    readonly turnIcon = require('./../assets/turn-icon');
    readonly phoneIcon = require('./../assets/phone-icon');
    readonly personIcon = require('./../assets/person-icon');
    readonly emailIcon = require('./../assets/email-icon');

    private features;
    private userContactType$ = this.store.select(fromRoot.getPoolContactType).pipe(shareReplay());

    constructor(
        private accountInfoService: AccountInfoService,
        private eventFarmService: EventFarmService,
        private modalService: ModalService,
        private store: Store<fromRoot.AppState>
    ) {}

    ngOnInit() {
        this.accountInfoService.disabledFeatures$.subscribe(features => {
            this.features = features;
        });
    }

    private requestUpgrade(selectedFeature) {
        this.features.map(feature => {
            if (feature.name === selectedFeature.name) {
                feature.isSelected = true;
            }
        });
        let modal: Modal = new Modal();
        modal.title = 'Request Upgrade';
        modal.component = RequestUpgradeComponent;
        modal.data = this.features;
        modal.cssClass = 'full-width';
        this.modalService.changeModal(modal);
    }
}
