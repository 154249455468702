import { Design } from './../../EmailDesignCenter/Models/design';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import * as eventActions from './../../../store/actions/event';
import { filter, map, shareReplay } from 'rxjs/operators';

@Injectable()

export class InvitationsConfirmationsService {
    public ticketTypes$ = this.store.select(fromRoot.getTypesWithStacks);
    public designs$ = this.store.select('event').pipe(
        filter(event => !!event.designs.data),
        map(event => event.designs.data),
        shareReplay()
    );
    public designsArr$;

    constructor(
        public store: Store<fromRoot.AppState>,
    ) {
        this.store.dispatch(new eventActions.FetchAccessTypes());
        this.designsArr$ = this.designs$.pipe(
            map((designs: Design): Design[] => {
                return Object.keys(designs).map((i) => designs[i]);
            })
        );
    }
}
