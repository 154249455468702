import { Injectable } from "@angular/core";
import { SweetAlert } from "../../../Alert/sweet-alert";
import { RouteGeneratorService } from "../../../_services/routes/route-generator.service";
import {ManageAdminService} from "../User/ManageAdmin/manage-admin.service";
import { AdminAlert } from "./admin-alert";
import { EventFarmAPIClient } from "../../../ApiClient/event-farm-api-client";
import { AdminService } from "../admin.service";
import { TeamEmailMasksService } from "../Manage/ManageTeam/TeamEmailMasks/team-email-masks.service";

@Injectable()
export class AdminAlertService {
  private alert: SweetAlert;
  private routeGenerator: RouteGeneratorService;

  constructor(
    private apiClient: EventFarmAPIClient,
    private manageAdminService: ManageAdminService,
    private adminService: AdminService,
    private teamEmailMasksService: TeamEmailMasksService
  ) {
    this.alert = new SweetAlert();
    this.routeGenerator = new RouteGeneratorService();
  }

  general() {
    return new AdminAlert(
      this.alert,
      this.routeGenerator,
      this.manageAdminService,
      this.adminService,
      this.teamEmailMasksService
    );
  }
}
