import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';
import { InvitationCountsDTO } from '../../../../../ApiClient/Models/dto';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';

import { ngxChartsOptions } from '../../../../../Shared/ngx-charts-options';
import { InvitationStatusTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';

@Component({
    selector: 'address-book-user-activity',
    styles: [require('./address-book-user-activity.scss')],
    template: require('./address-book-user-activity.html'),
})

export class AddressBookUserActivityComponent implements OnInit {
    private showLegend = false;
    private newChartData = [];
    private chartColors = ngxChartsOptions.colorScheme.domain;
    private invitationCountsDTO: InvitationCountsDTO;

    constructor(
        private apiClient: EventFarmAPIClient,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
    ) {
    }

    ngOnInit() {
        this.addressBookService
            .getInvitationCountsForSelectedUser()
            .then(
                (invitationCountsDTO: InvitationCountsDTO) => {
                    const statusTypeCounts = invitationCountsDTO['invitation-status-type-counts'];
                    const chartData = [];

                    this.invitationCountsDTO = invitationCountsDTO;

                    if (statusTypeCounts.total > 0) {
                        const skipTheseSlugTypes = ['total', 'recycled'];

                        for (const statusTypeSlug in statusTypeCounts) {
                            if (skipTheseSlugTypes.includes(statusTypeSlug) || statusTypeCounts[statusTypeSlug] <= 0) {
                                continue;
                            }

                            const invitationStatusType = this.apiClient.getTypeFactory()
                                .Invitation()
                                .InvitationStatusType()
                                .filter(
                                    (res: InvitationStatusTypeInterface) => {
                                        return statusTypeSlug === res.slug;
                                    })[0];

                            if (invitationStatusType && invitationStatusType.name) {
                              chartData.push({
                                'name': invitationStatusType.name,
                                'value': statusTypeCounts[statusTypeSlug],
                              });
                            }
                        }
                    }
                    this.newChartData = [... chartData];
                }
            );
    }
}

