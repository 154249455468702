import { Component, Input, OnInit } from '@angular/core';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { Invitation } from '../../../ApiClient/Models/Invitation/invitation';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { AddInviteCountService } from './addInviteCount.service';
import { ApiError, ErrorInterface } from '../../../ApiClient/Models/error';
import { EventFarmService } from '../../eventFarm.service';
import { Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { InvitationOptionsService, ArrivalAlertOptions } from './invitationOptions.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
@Component({
    selector: 'invitation-options',
    styles: [require('./invitation-options.scss')],
    template: require('./invitation-options.html'),
})

export class InvitationOptionsComponent implements OnInit {
    @Input() initialInvitation: Invitation;
    @Input() invitationCreationContext: 'invitation' | 'invitation-ticketblock' | 'add-group' | 'add-group-ticketblock' | 'upload-group';
    @Input() currentEventContext: EfEvent;
    @Input() errors: ApiError[];

    private showGuestInfoWarning: boolean = true;
    private showAdditionalFields: boolean = false;
    private isGuestDetailsActive: boolean = true;
    private guestSalutation: string;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private inviteCountService: AddInviteCountService,
        private invitationOptionsService: InvitationOptionsService,
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
    ) {
    }

    ngOnInit() {
        this.invitationOptionsService.resetSelectedStack();
        this.invitationOptionsService.selectedStatus = null;
        this.invitationOptionsService.arrivalAlertOptions = this.initialInvitation && this.initialInvitation.shouldSendArrivalAlert ? this.setArrivalOptions() : this.resetArrivalOptions();
        if (this.invitationOptionsService.initialInvitation) {
            this.invitationOptionsService.initialInvitation.inviteCount = this.initialInvitation.inviteCount;
        }
        this.invitationOptionsService.invitationCount = this.initialInvitation && this.initialInvitation.inviteCount ? this.initialInvitation.inviteCount : 1;

        if (this.invitationCreationContext) {
            this.invitationOptionsService.invitationCreationContext = this.invitationCreationContext;
        } else {
            this.invitationOptionsService.invitationCreationContext = 'invitation';
        }

        this.invitationOptionsService.invitationOptions.isCheckedIn = this.initialInvitation && this.initialInvitation.isCheckedIn ? true : false;
        this.invitationOptionsService.invitationOptions.checkInNotes = this.initialInvitation ? this.initialInvitation.checkInNotes : '';
        this.invitationOptionsService.invitationOptions.invitationNotes = this.initialInvitation ? this.initialInvitation.invitationNotes : '';
        this.invitationOptionsService.invitationOptions.existingArrivalAlertSettings = this.invitationOptionsService.arrivalAlertOptions;

        if (this.currentEventContext) {
            this.invitationOptionsService.currentEvent = this.currentEventContext;
        } else {
            this.invitationOptionsService.currentEvent = this.eventFarmService.currentEvent;
        }
        this.invitationOptionsService.stacks = this.invitationOptionsService.currentEvent.stacks;

        if (this.invitationOptionsService.currentEvent && this.invitationOptionsService.currentEvent.stacks && this.invitationOptionsService.invitationCreationContext !== 'add-group-ticketblock') {
            this.invitationOptionsService.stacks = this.invitationOptionsService.currentEvent.stacks;
        }

        if (this.initialInvitation && this.initialInvitation.stack) {
            this.invitationOptionsService.selectedStackId = this.initialInvitation.stack.id;
        }

        if (this.initialInvitation && this.initialInvitation.invitationStatusType) {
            this.invitationOptionsService.selectedStatus = this.initialInvitation.invitationStatusType;
        }
    }

    setArrivalOptions(): ArrivalAlertOptions {
        return {
            shouldSendArrivalAlert: this.initialInvitation.shouldSendArrivalAlert,
            arrivalEmails: this.initialInvitation.arrivalAlertEmails.join(', '),
            arrivalPhoneNumbers: this.initialInvitation.arrivalAlertPhoneNumbers.join(', ')
        };
    }

    resetArrivalOptions() {
        return {
            shouldSendArrivalAlert: false,
            arrivalEmails: '',
            arrivalPhoneNumbers:''
        };
    }
}
