import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { EmailDesignCenterService } from '../email-design-center.service';
import { GuestMessagingSendOptionsService } from './guest-messaging-send-options.service';
import { GuestMessagingService } from './guest-messaging.service';

@Component({
    selector: 'guest-messaging-send-options',
    template: require('./guest-messaging-send-options.html'),
    styles: [require('./guest-messaging-send-options.scss')],
})

export class GuestMessagingSendOptionsComponent implements OnInit {
    private messageTargets: IMessageTargets;

    ngOnInit(): void {
        this.guestMessagingSendOptionsService.isTypeSendAMessage = null;
        this.guestMessagingSendOptionsService.isTypeSendGroupMessage = null;
        this.messageTargets = {
            isConfirmed: false,
            isPurchased: false,
            isAssigned: false,
            isUnconfirmed: false,
        };
    }

    constructor(
        private eventFarmService: EventFarmService,
        private guestMessagingService: GuestMessagingService,
        private guestMessagingSendOptionsService: GuestMessagingSendOptionsService,
        private emailDesignCenterService: EmailDesignCenterService,
    ) {
    }

    private changeSendByInvitationStatus(
        type: 'invitation-status'|'group'|'wait-list'|'checked-in'|'no-shows'
    ): void {
        if (type === 'invitation-status') {
            this.guestMessagingService.getDynamicElements('SendAMessage');
            this.guestMessagingSendOptionsService.setForSendByInvitationStatus();
        } else if (type === 'group') {
            this.guestMessagingService.getDynamicElements('SendGroupMessage');
            this.guestMessagingSendOptionsService.setForSendByGroup();
        } else if (type === 'wait-list') {
            this.guestMessagingService.getDynamicElements('SendGroupMessage');
            this.guestMessagingSendOptionsService.setForSendByWaitList();
        } else if (type === 'checked-in') {
            this.guestMessagingService.getDynamicElements('SendAMessage');
            this.guestMessagingSendOptionsService.setForSendByAllCheckedIn();
        } else if (type === 'no-shows') {
            this.guestMessagingService.getDynamicElements('SendAMessage');
            this.guestMessagingSendOptionsService.setForSendByNoShows();
        }
        this.messageTargets = {
            isConfirmed: false,
            isPurchased: false,
            isAssigned: false,
            isUnconfirmed: false,
        };
    }

    private changeGroupStatus(type: null|'has_invitation'|'does_not_have_invitation')
    : void {
        if (type === null) {
            this.guestMessagingSendOptionsService.setGroupStatusForAllGroupMembers();
        } else if (type === 'has_invitation') {
            this.guestMessagingSendOptionsService.setGroupStatusForGroupMembersOnGuestList();
        } else if (type === 'does_not_have_invitation') {
            this.guestMessagingSendOptionsService.setGroupStatusForMembersNotOnGuestList();
        } 
    }

    private setMessageTargets(): void {
        this.guestMessagingSendOptionsService.messageTargets = [];
        for (const statusType of this.emailDesignCenterService.invitationStatusTypes) {
            if (this.messageTargets.isConfirmed && statusType.isSendToConfirmedOrRegisteredTypeIds) {
                this.guestMessagingSendOptionsService.setMessageTargets(statusType);
            } else if (this.messageTargets.isPurchased && statusType.isSendToPurchasedTypeIds) {
                this.guestMessagingSendOptionsService.setMessageTargets(statusType);
            } else if (this.messageTargets.isAssigned && statusType.isSendToAssignedTypeIds) {
                this.guestMessagingSendOptionsService.setMessageTargets(statusType);
            } else if (this.messageTargets.isUnconfirmed && statusType.isSendToUnconfirmedTypeIds) {
                this.guestMessagingSendOptionsService.setMessageTargets(statusType);
            }
        }
    }
}

interface IMessageTargets {
    isConfirmed: boolean;
    isPurchased: boolean;
    isAssigned: boolean;
    isUnconfirmed: boolean;
}
