import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { EventsListService } from '../eventsList.service';
import { Observable, of ,  Subscription } from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { Tag } from '../../../../ApiClient/Models/Event/tag';
import { FormControl } from '@angular/forms';
import { debounceTime, map, distinctUntilChanged ,  take, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { Team } from '../../../../ApiClient/Models/Pool/team';
import { isEqual } from 'lodash';

@Component({
    selector: 'list-view-tags',
    template: require('./listViewTags.html'),
    styles: [require('./listViewTags.scss'), require('../events-list.scss')],
    host: { '(document:click)': 'toggleTagsDropdown($event)' }
})

export class ListViewTagsComponent implements OnInit, OnDestroy {

    readonly tagIcon = require('./../assets/tag');

    private tagFilterOptions: Tag[] = [];
    private teamStoreData: Team;
    private currentTagFilters;
    private eventListOptions = this.eventFarmService.eventListOptions;
    private searchTags: FormControl = new FormControl();
    private tagFilterSearchTerm: string;
    private isTagDropdownVisible = false;
    private teamStore$: Observable<any> = this.store.select('team');
    private teamUpdate$: Subscription;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventsDashboardService: EventsListService,
        private eventFarmService: EventFarmService,
        private el: ElementRef,
        private store: Store<fromStore.AppState>
    ) {
        this.teamUpdate$ = this.teamStore$.subscribe((val) => {
            if (val.data && val.data.id && val.loaded && !isEqual(this.teamStoreData, val.data)) {
                this.teamStoreData = val.data;
                this.listTagsForPool(val.data.id);
            }
        });
    }

    ngOnInit() {

        this.eventsDashboardService.currentTagFilters$.subscribe(res => this.currentTagFilters = res);

        this.searchTags.valueChanges
            .pipe(debounceTime(100),
                distinctUntilChanged())
            .subscribe(term => {
                this.tagFilterSearchTerm = term;
            });
    }

    filterEventsByTag(tag, index) {
        if (tag.isActive === true) {
            tag.isActive = false;
            this.currentTagFilters = this.currentTagFilters.filter(i => i.slug !== tag.slug);
        } else {
            tag.isActive = true;
            this.currentTagFilters.push(this.tagFilterOptions.filter(i => i.id === tag.id)[0]);
        }

        Object.assign(this.eventListOptions, {
            'tags': this.currentTagFilters.map(i => i.slug)
        });

        this.store.dispatch(new fromStore.FetchEventsForTeam());
        this.eventsDashboardService.currentTagFiltersSource.next(this.currentTagFilters);
    }

    listTagsForPool(poolId) {
        this.eventsDashboardService.getTagsForPool(poolId)
            .pipe(take(1))
            .subscribe((res) => {
                this.tagFilterOptions = [];
                this.eventsDashboardService.tagsForPool = of(res.data.attributes);
                this.eventsDashboardService.tagsForPool.subscribe(res => {
                    for (const [key, value] of Object.entries(res)) {
                        const t = Tag.fromUniqueResponse(key, value);
                        if (!this.tagFilterOptions.filter(tg => tg.slug === t.slug).length){
                            this.tagFilterOptions.push(t);
                        }
                      }
                });
            });
    }

    toggleTagsDropdown(event) {
        if (this.el.nativeElement.contains(event.target)) {
            this.isTagDropdownVisible = true;
        } else {
            this.isTagDropdownVisible = false;
        }
    }

    ngOnDestroy() {
        this.teamUpdate$.unsubscribe();
    }
}
