import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PieChartComponent } from './Pie/ef-pie-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    declarations: [
        PieChartComponent
    ],
    exports: [
        PieChartComponent
    ],
    imports: [
        CommonModule,
        NgxChartsModule
    ],
})

export class ChartModule {
}
