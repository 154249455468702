import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from './manage.routing-module';
import { ManageComponent } from './manage.component';
import { AdminService } from '../admin.service';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { EventFarmService } from '../../eventFarm.service';
import { ManageTeamComponent } from './ManageTeam/manage-team.component';
import { GuestListService } from '../../EventsModule/GuestList/guest-list.service';
import { TeamInfoComponent } from './ManageTeam/TeamInfo/team-info.component';
import { TeamNamesComponent } from './ManageTeam/TeamNames/team-names.component';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { AlertService } from '../../eventFarmAlert.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TeamEventsModule } from './ManageTeam/TeamEvents/team-events.module';
import { LoaderGrayModule } from '../../CoreUI/LoaderGray/loader-gray.module';
import { TeamFeaturesModule } from './ManageTeam/TeamFeatures/team-features.module';
import { TeamAllotmentModule } from './ManageTeam/TeamAllotments/team-allotment.module';
import { TeamEmailMasksModule } from './ManageTeam/TeamEmailMasks/team-email-masks.module';
import { TeamPointOfContactModule } from './ManageTeam/TeamPointOfContact/team-point-of-contact.module';
import { TeamEchoWorldsModule } from './ManageTeam/TeamEchoWorlds/team-echo-worlds.module';

import { TeamPointOfContactService } from './ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import { TeamContractDetailsModule } from './ManageTeam/TeamContractDetails/team-contract-details.module';
import { TeamEmailMasksService } from './ManageTeam/TeamEmailMasks/team-email-masks.service';
import { AdminAlertModule } from '../Alert/admin-alert.module';
import {CreateTeamModule} from './CreateTeam/create-team.module';
import {PoolTypeaheadModule} from '../../CoreUI/Typeahead/pool/pool-typeahead.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

const forExport = [
  ManageComponent,
  ManageTeamComponent,
  TeamNamesComponent,
  TeamInfoComponent,
  ManageComponent
];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    CommonModule,
    FormsModule,
    BreadcrumbsModule,
    NzTabsModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    AutocompleteLibModule,
    TeamEventsModule,
    LoaderGrayModule,
    TeamFeaturesModule,
    TeamAllotmentModule,
    TeamEmailMasksModule,
    TeamPointOfContactModule,
    TeamContractDetailsModule,
    TeamEchoWorldsModule,
    AdminAlertModule,
    CreateTeamModule,
    PoolTypeaheadModule,
    NgxUtilitiesModule
  ],
  entryComponents: [],
  providers: [
    AdminService,
    EventFarmService,
    NzDrawerService,
    GuestListService,
    LegacyUIService,
    AlertService,
    TeamPointOfContactService,
    TeamEmailMasksService,
  ],
})
export class ManageModule {}
