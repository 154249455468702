import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AddressBookService } from '../addressBook.service';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { Group } from '../../../../ApiClient/Models/Group/group';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';
import { EventFarmService } from '../../../eventFarm.service';
import { User } from '../../../../ApiClient/Models/User/user';
import { UserFormService, BasicUserDetails } from '../../../CoreUI/UserForm/userForm.service';
import { UpdateInvitationCommand } from '../../../CoreUI/GuestListTableView/guest-list-view.service';
import { ApiError } from '../../../../ApiClient/Models/error';
import { AlertService } from '../../../eventFarmAlert.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

@Injectable()
export class AddressBookUserService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private addressBookService: AddressBookService,
        private eventFarmService: EventFarmService,
        private userFormService: UserFormService,
        private alertService: AlertService,
        private router: Router,
        private modalService: ModalService,
        private routeGenerator: RouteGeneratorService
    ) {}

    public usersSource = new Subject<User[]>();
    public usersMetaSource = new BehaviorSubject<any>({});
    public users$ = this.usersSource.asObservable();
    public usersMeta$ = this.usersMetaSource.asObservable();

    public groupsSelectedForUser: Group[];
    public groupsSelectedForUserSource = new Subject<Group[]>();
    public groupsSelectedForUser$ = this.groupsSelectedForUserSource.asObservable();

    public groupMembershipForUserSource = new Subject<Group[]>();
    public groupMembershipForUserMetaSource = new BehaviorSubject<any>({});
    public groupMembershipForUser$ = this.groupMembershipForUserSource.asObservable();
    public groupMembershipForUserMeta$ = this.groupMembershipForUserMetaSource.asObservable();

    public invitationsForUserSource = new Subject<Invitation[]>();
    public invitationsForUserMetaSource = new BehaviorSubject<any>({});
    public invitationsForUser$ = this.invitationsForUserSource.asObservable();
    public invitationsForUserMeta$ = this.invitationsForUserMetaSource.asObservable();

    public userInvitationsDateFilterType = 'current-future';
    public loadingUserInvitations = true;
    public isInvitationDetailsVisible;

    public userListOptions: UserListOptions = {
        query: null,
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentUsersPage: 1,
            itemsPerPage: 25,
        }
    };

    public userGroupsListOptions: UserGroupsListOptions = {
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentUsersPage: 1,
            itemsPerPage: 20,
        }
    };

    public userInvitationsListOptions: UserInvitationsListOptions = {
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentUsersPage: 1,
            itemsPerPage: 10,
        },
        eventDateFilterType: this.userInvitationsDateFilterType,
        statusFilter: [
            'assigned',
            'purchased',
            'confirmed-by-rsvp',
            'declined-by-rsvp',
            'left-behind',
            'not-yet-purchased',
            'registered',
            'unconfirmed',
            'not-yet-registered'
        ]
    };

    public getEventDateFilterType() {
        return this.apiClient.getTypeFactory().Event().EventDateFilterType();
    }

    public broadcastUsersInGroup(groupId) {
        this.getUsersInGroup(groupId)
            .subscribe((res) => {
                this.usersMetaSource.next(res.meta);
                const users: User[] = res.data.map((user) => User.fromApiResponse(user));
                this.usersSource.next(users);
                this.addressBookService.isLoadingContacts = false;
            });
    }

    public broadcastAllUsers() {
        this.getAllUsers()
            .subscribe(
                (res) => {
                    this.usersMetaSource.next(res.meta);
                    const users: User[] = res.data.map((user) => User.fromApiResponse(user));
                    this.usersSource.next(users);
                    this.addressBookService.isLoadingContacts = false;
                    this.addressBookService.userHasRestrictedAccess = false;
                },
                (err) => {
                    this.addressBookService.userHasRestrictedAccess = false;
                    this.usersSource.next([]);
                    if (err.length) {
                        err.forEach(error => {
                            if (error.detail === 'Access Denied') {
                                this.addressBookService.userHasRestrictedAccess = true;
                            }
                        });
                    }
                    this.addressBookService.isLoadingContacts = false;
                }
            );
    }

    public getUsersInGroup(groupId) {
        return this.apiClient
            .getUseCaseFactory()
            .User()
            .ListUsersInGroup(
                groupId,
                this.addressBookService.currentTeam.id,
                ['UserIdentifiers', 'UserNames', 'UserAttributes'],
                this.userListOptions.query,
                this.userListOptions.sortBy,
                this.userListOptions.sortDirection,
                this.userListOptions.pagination.currentUsersPage,
                this.userListOptions.pagination.itemsPerPage
            );
    }

    public getAllUsers() {
        return this.apiClient
            .getUseCaseFactory()
            .User()
            .ListUsersForPools([this.addressBookService.currentTeam.id],
                ['UserIdentifiers', 'UserAttributes', 'UserNames'],
                this.userListOptions.query,
                'name',
                'ascending',
                this.userListOptions.pagination.currentUsersPage,
                this.userListOptions.pagination.itemsPerPage);
    }

    public broadcastGroupSelectionsForUser() {
        this.groupsSelectedForUserSource.next(this.groupsSelectedForUser);
    }

    public getGroupMembershipForUser(poolId, userId, groupOwnerId, page, itemsPerPage) {
        return this.apiClient.getUseCaseFactory().Group().ListGroupMembershipForUser(
            poolId,
            userId,
            groupOwnerId,
            page,
            itemsPerPage
        );
    }

    public broadcastGroupMembershipForUser() {
        if (!this.addressBookService.selectedUser) {
            return;
        }
        this.getGroupMembershipForUser(
            this.eventFarmService.currentTeam.id,
            this.addressBookService.selectedUser.id,
            this.eventFarmService.currentUser.id,
            this.userGroupsListOptions.pagination.currentUsersPage,
            this.userGroupsListOptions.pagination.itemsPerPage)
        .subscribe((res) => {
            this.groupMembershipForUserMetaSource.next(res.meta);
            this.groupMembershipForUserSource.next(res.data);
        });
    }

    public getInvitationsForUser(userId, poolId, page = 1, itemsPerPage = 20, eventDateFilterType = 'current-future', sortDirection = 'descending', withData = null, statusFilter = null) {
        return this.apiClient.getUseCaseFactory().Invitation().ListInvitationsForUser(
            userId,
            poolId,
            null,
            page,
            itemsPerPage,
            eventDateFilterType,
            sortDirection,
            withData,
            statusFilter
        );
    }

    public checkIfUserCanBeDeleted(removeUserId, requestUserId, poolId) {
        return this.apiClient.getUseCaseFactory().User().CheckIfUserCanBeRemovedFromPool(
            removeUserId,
            requestUserId,
            poolId);
    }

    public broadcastInvitationsForUser(slug, sortDirection) {
        this.loadingUserInvitations = true;
        this.getInvitationsForUser(
            this.addressBookService.selectedUser.id,
            this.addressBookService.currentTeam.id,
            this.userInvitationsListOptions.pagination.currentUsersPage,
            this.userInvitationsListOptions.pagination.itemsPerPage,
            slug,
            sortDirection,
            ['Event', 'Stack'],
            this.userInvitationsListOptions.statusFilter
            )
        .subscribe((res) => {
            this.invitationsForUserMetaSource.next(res.meta);
            this.invitationsForUserSource.next(res.data);
            this.loadingUserInvitations = false;
        }, (error) => {
            this.loadingUserInvitations = false;
        });
    }

    public extractErrorsIntoMessages(err): ApiError[] {
        const formattedErrors: any[] = [];
        if (err.errors && err.errors.length) {
            err.errors.forEach((error) => {
                formattedErrors.push({
                    detail: error.detail,
                    meta: error.meta
                });
            });
        } else if (err.length) {
            err.forEach((error) => {
                formattedErrors.push({
                    detail: error.detail,
                    meta: error.meta
                });
            });
        } else {
            formattedErrors.push({
                detail: 'Unable to successfully make all network requests.',
            });
        }
        return ApiError.fromApiErrorArray(formattedErrors);
    }

    public redirectToExistingUserInPool(existingUser: User, poolId: string) {
        this.modalService.closeModal();
        this.alertService.addressBook().userExistsInPool()
            .then((confirm) => {
                this.modalService.showModal();
                if (confirm.value) {
                    this.addressBookService.selectedUser = existingUser;
                    this.addressBookService.broadcastUser(existingUser);
                    this.router.navigate([this.routeGenerator.url('pools.address-book.list', {poolId}), {outlets: {'edit-user-outlet': ['edit-user', existingUser.id]}}]);
                } else {
                    this.router.navigate([this.routeGenerator.url('pools.address-book.list', {poolId}), {outlets: {'add-user-outlet': ['add-user']}}]);
                }
            });
    }

    generateUserUpdateApiPromises(targetUserId: string, userEnteredValuesToUpdateWith: BasicUserDetails): Array<Promise<any>> {
        const existingUserDataPromises: Array<Promise<any>> = [];

        if ((this.addressBookService.selectedUser && this.addressBookService.selectedUser.name && this.addressBookService.selectedUser.name.firstName) ||
            (this.addressBookService.selectedUser && this.addressBookService.selectedUser.name && this.addressBookService.selectedUser.name.lastName)) {
            const userName = this.setUserNameToUser(
                this.addressBookService.selectedUser.name.id,
                userEnteredValuesToUpdateWith.firstName,
                userEnteredValuesToUpdateWith.lastName
            ).toPromise();
            existingUserDataPromises.push(userName);
        } else if (userEnteredValuesToUpdateWith.firstName || userEnteredValuesToUpdateWith.lastName) {
            const userName = this.addUserNameToUser(
                this.eventFarmService.currentTeam.id,
                targetUserId,
                userEnteredValuesToUpdateWith.firstName,
                userEnteredValuesToUpdateWith.lastName,
                null
            ).toPromise();
            existingUserDataPromises.push(userName);
        }

        if (this.addressBookService.selectedUser && this.addressBookService.selectedUser.addresses && this.addressBookService.selectedUser.addresses[0]
        && (userEnteredValuesToUpdateWith.address1 || userEnteredValuesToUpdateWith.address2 || userEnteredValuesToUpdateWith.city || userEnteredValuesToUpdateWith.state || userEnteredValuesToUpdateWith.postalCode)) {
            const userAddress = this.setAddressToUser(
                this.addressBookService.selectedUser.addresses[0].id,
                userEnteredValuesToUpdateWith.address1,
                userEnteredValuesToUpdateWith.address2,
                userEnteredValuesToUpdateWith.city,
                userEnteredValuesToUpdateWith.state,
                userEnteredValuesToUpdateWith.postalCode,
                userEnteredValuesToUpdateWith.country
            ).toPromise();
            existingUserDataPromises.push(userAddress);
        } else if (userEnteredValuesToUpdateWith.address1 || userEnteredValuesToUpdateWith.address2 || userEnteredValuesToUpdateWith.city || userEnteredValuesToUpdateWith.state || userEnteredValuesToUpdateWith.postalCode) {
            const userAddress = this.addAddressToUser(
                this.eventFarmService.currentTeam.id,
                targetUserId,
                userEnteredValuesToUpdateWith.address1,
                userEnteredValuesToUpdateWith.address2,
                userEnteredValuesToUpdateWith.city,
                userEnteredValuesToUpdateWith.state,
                userEnteredValuesToUpdateWith.postalCode,
                userEnteredValuesToUpdateWith.country
            ).toPromise();
            existingUserDataPromises.push(userAddress);
        }

        if (userEnteredValuesToUpdateWith.company) {
            const company = this.setAttributeToUser(
                this.eventFarmService.currentTeam.id,
                targetUserId,
                'company',
                userEnteredValuesToUpdateWith.company
            ).toPromise();
            existingUserDataPromises.push(company);
        }

        if (userEnteredValuesToUpdateWith.position) {
            const position = this.setAttributeToUser(
                this.eventFarmService.currentTeam.id,
                targetUserId,
                'position',
                userEnteredValuesToUpdateWith.position
            ).toPromise();
            existingUserDataPromises.push(position);
        }

        if (userEnteredValuesToUpdateWith.telephone) {
            const telephone = this.setAttributeToUser(
                this.eventFarmService.currentTeam.id,
                targetUserId,
                'telephone',
                userEnteredValuesToUpdateWith.telephone ? userEnteredValuesToUpdateWith.telephone['e164Number'] : null
            ).toPromise();
            existingUserDataPromises.push(telephone);
        }

        if (userEnteredValuesToUpdateWith.title) {
            const title = this.setAttributeToUser(
                this.eventFarmService.currentTeam.id,
                targetUserId,
                'title',
                userEnteredValuesToUpdateWith.title
            ).toPromise();
            existingUserDataPromises.push(title);
        }

        return existingUserDataPromises;
    }

    removeUsersFromGroup(groupId: string, userIds: string[]) {
        return this.apiClient.getUseCaseFactory().Group().RemoveUsersFromGroup(
            groupId,
            userIds
        );
    }

    removeUsersFromPool(userIds: string[], teamId: string) {
        return this.apiClient.getUseCaseFactory().User().RemoveUsersFromPool(userIds, this.eventFarmService.currentUser.id, teamId);
    }

    getUserInPool(poolId: string, userId: string) {
        return this.apiClient
            .getUseCaseFactory()
            .User()
            .GetUserInPool(
                poolId,
                userId,
                ['UserName', 'UserAddress', 'UserIdentifier'],
                ['info'],
            );
    }

    createUser(email: string, firstName: string, lastName: string, company: string, position: string, phone: string, poolId: string, title: string, other: string): Observable<any> {
        return this.apiClient.getUseCaseFactory()
            .User()
            .CreateUser(email, firstName, lastName, company, position, phone, poolId, title, other);
    }

    addUsersToGroup(groupId: string, userIds: string[]): Observable<any> {
        return this.apiClient.getUseCaseFactory()
            .Group()
            .AddUsersToGroup(groupId, userIds);
    }

    setAttributeToUser(poolId: string, userId: string, attributeKey: 'company' | 'position' | 'title' | 'telephone', attributeValue: string) {
        return this.apiClient.getUseCaseFactory().UserAttribute().SetInfoUserAttribute(poolId, userId, attributeKey, attributeValue);
    }

    addUserNameToUser(poolId: string, userId: string, firstName: string, lastName: string, userNameId: string) {
        return this.apiClient.getUseCaseFactory().UserName().AddUserName(poolId, userId, firstName, lastName, userNameId);
    }

    setUserNameToUser(userNameId: string, firstName: string, lastName: string) {
        return this.apiClient.getUseCaseFactory().UserName().SetUserName(userNameId, firstName, lastName);
    }

    removeAttributeFromUser(attributeId: string) {
        return this.apiClient.getUseCaseFactory().UserAttribute().RemoveUserAttribute(attributeId);
    }

    addAddressToUser(poolId: string, userId: string, address1: string, address2: string, city: string, state: string, postalCode: string, country: string) {
        return this.apiClient.getUseCaseFactory().UserAddress().AddUserAddress(poolId, userId, address1, city, state, postalCode, country, address2);
    }

    setAddressToUser(userAddressId: string, address1: string, address2: string, city: string, state: string, postalCode: string, country: string) {
        return this.apiClient.getUseCaseFactory().UserAddress().SetUserAddress(userAddressId, address1, city, state, postalCode, country, address2);
    }

    removeAddressFromUser(addressId: string) {
        return this.apiClient.getUseCaseFactory().UserAddress().RemoveUserAddress(addressId);
    }

    updateInvitation(updateInvitation: UpdateInvitationCommand, userDetails: BasicUserDetails, stackId: string) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .UpdateInvitation(
                updateInvitation.invitationId,
                stackId,
                updateInvitation.invitationStatus,
                userDetails.company,
                userDetails.position,
                userDetails.email,
                userDetails.firstName,
                userDetails.lastName,
                userDetails.other
            )
            .toPromise();
    }

    setCheckInNotes(invitation, note) {
        if (note == '') {
            note = null;
        }
        return this.apiClient.getUseCaseFactory().Invitation().SetCheckInNotes(invitation.id, note).toPromise();
    }

    setCheckInForGuest(invitationId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().CheckIn(invitationId, null, true).toPromise();
    }

    undoCheckInForGuest(invitationId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().UndoCheckIn(invitationId).toPromise();
    }

    setInvitationNotes(invitation, note) {
        if (note == '') {
            note = null;
        }
        return this.apiClient.getUseCaseFactory().Invitation().SetInvitationNotes(invitation.id, note).toPromise();
    }

    setInvitationCount(invitationId: string, count: number) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .ChangeInviteCount(invitationId, count)
            .toPromise();
    }

    setArrivalAlertAndEmails(invitationId: string, emails: string[]|null, shouldSendArrivalAlert: boolean|null, phoneNumbers: string[]|null) {
        return this.apiClient.getUseCaseFactory().Invitation().SetArrivalAlertEmailsAndPhoneNumbers(invitationId, emails, shouldSendArrivalAlert, phoneNumbers).toPromise();
    }

}

export interface Pagination {
    currentUsersPage: number;
    itemsPerPage: number;
}

export interface UserListOptions {
    selectedUser?: User;
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}

export interface UserGroupsListOptions {
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}

export interface UserInvitationsListOptions {
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
    eventDateFilterType: string;
    statusFilter: string[];
}
