import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { InvitationOptionsService } from '../../../../CoreUI/InvitationOptions/invitationOptions.service';
import { StackSelectionService } from '../../../../CoreUI/InvitationOptions/stackSelection.service';
import { AddInviteCountService } from '../../../../CoreUI/InvitationOptions/addInviteCount.service';
import { TicketBlockDashboardService } from '../../ticketBlockDashboard.service';

import { Group } from '../../../../../ApiClient/Models/Group/group';

@Injectable()

export class AddGroupService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private invitationOptionsService: InvitationOptionsService,
        private stackSelectionService: StackSelectionService,
        private addInviteCountService: AddInviteCountService,
        private ticketBlockDashboardService: TicketBlockDashboardService,
    ) {
    }

    getGroupDetails(groupId: string) {
        return this.apiClient.getUseCaseFactory().Group().GetGroup(groupId, ['totalUsersInGroup', 'creatorUser']);
    }

    createInvitationFromGroup(groupId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().CreateInvitationsFromGroupForTicketBlock(
            groupId,
            this.ticketBlockDashboardService.ticketBlock.id,
            this.invitationOptionsService.selectedStackId,
            this.addInviteCountService.inviteCount,
            this.stackSelectionService.invitationCreationTypeSlug
        );
    }
}
