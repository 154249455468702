import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { GuestSimpleStatsModule } from '../../CoreUI/GuestSimpleStats/guestSimpleStats.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { TicketTypeListModule } from '../../CoreUI/TicketTypeList/ticketTypeList.module';
import { ChildEventsRoutingModule } from './child-events-routing.module';
import { ChildEventsComponent } from './child-events.component';
import { ChildrenComponent } from './Children/children.component';
import { ChildItemComponent } from './Children/ChildItem/child-item.component';
import { ChildEventsService } from './child-events.service';
import { EditChildComponent } from './Children/ChildItem/EditChild/edit-child.component';
import { EditChildButtonComponent } from './Children/ChildItem/EditChild/edit-child-button.component';
import { EditSaveButtonModule } from '../../CoreUI/EditSaveButton/edit-save-button.module';
import { EventAccessService } from '../EventAccess/event-access.service';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [ChildEventsComponent],
    declarations: [
        ChildEventsComponent,
        ChildrenComponent,
        ChildItemComponent,
        EditChildComponent,
        EditChildButtonComponent,
    ],
    entryComponents: [
        ChildEventsComponent,
        EditChildComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TicketTypeListModule,
        GuestSimpleStatsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        ChildEventsRoutingModule,
        NzUtilitiesModule,
        EditSaveButtonModule,
        NgxUtilitiesModule
    ],
    providers: [
        ChildEventsService,
        EventAccessService
    ],
})

export class ChildEventsModule {
}
