import { AbstractModel } from '../abstract-model';
import { Team } from '../Pool/team';

export class Transaction extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _pool: Team;

    public static fromApiResponse(data) {
        const transaction = new this(data.id);
        transaction._pool = Team.fromApiResponse(data.pool);
        return transaction;
    }

    public get pool(): Team {
        return this._pool;
    }

    public get id(): string {
        return this._id;
    }
}
