import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Observable } from 'rxjs';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EmailMessage } from '../../../../ApiClient/Models/Email/email-message';
import { EventFarmService } from '../../../eventFarm.service';
import { Mailbox } from './../../../../Shared/EmailMessage/mailbox';

interface GetMessagesPayload {
    data: EmailMessage[];
    links: {
        first: string;
        last: string;
        self: string;
    };
    meta: {
        totalPages: number;
        totalResults: number;
        totalResultsReturned: number;
        itemsPerPage: number;
        currentPage: number;
    };
}

export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export interface MessageListOptions {
    pagination: Pagination;
}

@Component({
    selector: 'message-list',
     template: require('./message-list.html'),
     styles: [require('./message-list.scss')],
})
export class MessageListComponent {

    public messageOptions: MessageListOptions = {
        pagination: {
            currentPage: 1,
            itemsPerPage: 20,
            totalItems: 0,
            totalPages: 0,
        }
    };
    public messages: EmailMessage[];

    @Input() public mailbox: Mailbox;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private segmentService: SegmentService,
        private message: NzMessageService,
    ) { }

    public isLoadingMessages: boolean = false;

    public ngOnInit() {
        this.getMessages();
        this.segmentService.segmentSideNavTracking().sentMessagesEntered();
    }

    private getMessages() {

        this.isLoadingMessages = true;
        const eventId = this.eventFarmService.currentEvent.id;

        const result = this.apiClient.getUseCaseFactory().EmailMessage().ListSentEmailMessagesByEvent(
            eventId,
            this.messageOptions.pagination.currentPage,
            this.messageOptions.pagination.itemsPerPage
        ) as Observable<GetMessagesPayload>;

        result.subscribe(
            result => {
                this.messages = result.data.map(d => EmailMessage.fromApiResponse(d));
                this.messageOptions.pagination.totalItems = result.meta.totalResults;
                this.messageOptions.pagination.totalPages = result.meta.totalPages;
                this.isLoadingMessages = false;
            },
            () => {
                this.message.error('Please try again');
                this.isLoadingMessages = false;
            }
        );
    }

    public onQueryParamsChange(params: NzTableQueryParams) {
        this.messageOptions.pagination.currentPage = params.pageIndex;
        this.getMessages();
    }

}
