export class Module {
    name: string;
    slug: string;
    description: string;
    iconURL: string;
    isActive: boolean;
    isNFC: boolean;
    isSMS: boolean;
    isAdminOnly: boolean;
    isConfigEnabled: boolean;

    constructor(attributes) {
        this.name = attributes.name;
        this.slug = attributes.slug;
        this.description = attributes.description;
        this.isActive = attributes.isActive;
        this.iconURL = attributes.iconURL;
        this.isNFC = attributes.compatibilityNFC;
        this.isSMS = attributes.compatibilitySMS;
        this.isAdminOnly = attributes.isAdminOnly;
        this.isConfigEnabled = attributes.isConfigEnabled
    }
}
