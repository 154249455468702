import { Component, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventFarmService } from '../../../eventFarm.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, finalize, shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { AlertService } from '../../../eventFarmAlert.service';
import { AngularRestClient } from '../../../../_services/rest/AngularRestClient';

@Component({
  selector: 'donations-options',
  template: require('./donations-options.html'),
})

export class DonationsOptionsComponent implements OnDestroy {

  private donateDetailsForm: FormGroup;
  private eventUpdate$: Subscription;
  private toggleState: boolean;
  private customPriceOption: number;
  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());

  private donateDetails = [
    {slug: 'message', name: 'Message', type: 'text', value: ''},
    {slug: 'optional', name: 'Donation is optional', type: 'checkbox', value: false},
    {slug: 'other', name: 'Guest can enter custom amount', type: 'checkbox', value: false},
  ];

  constructor(
    private eventFarmService: EventFarmService,
    private alertService: AlertService,
    private router: Router,
    private http: AngularRestClient,
    private route: ActivatedRoute,
    private apiClient: EventFarmAPIClient,
    private fb: FormBuilder,
    private store: Store<fromStore.AppState>,
    private message: NzMessageService,
  ) {
    this.donateDetailsForm = this.fb.group({});
    this.addToFormGroup();
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      this.toggleState = this.eventFarmService.currentEvent.eventAttributes.hasDonate;
    });
  }

  private addToFormGroup() {
    this.donateDetails.forEach(control => {
      return this.donateDetailsForm.addControl(control.slug, this.fb.control(control.value));
    });
  }

  private toggleForSlug(slug) {
    if (slug === 'optional') {
      this.donateDetailsForm.patchValue({
        optional: !this.donateDetailsForm.get('optional').value,
      }, {emitEvent: false});
    } else if (slug === 'other') {
      this.donateDetailsForm.patchValue({
        other: !this.donateDetailsForm.get('other').value,
      }, {emitEvent: false});
    }
  }

  private createPricingInputInGroup() {
    this.donateDetails.push({
      slug: 'amount_' + this.customPriceOption,
      value: String(this.customPriceOption),
      type: 'number',
      name: 'Amount',
    });
    this.addToFormGroup();
  }

  private resetPricingOptions() {
    this.donateDetails.forEach((donateDetail, i) => {
      if (donateDetail.slug.includes('amount')) {
        this.donateDetails.splice(i, 1);
      }
    });
    this.addToFormGroup();
  }

  toggle() {
    // this.toggleState = !this.toggleState;
    if (this.eventFarmService.currentEvent.eventAttributes.hasDonate) {
      this.disableDonation();
    }
  }

  ngOnDestroy() {
    this.eventUpdate$.unsubscribe();
  }

  enableDonations() {
    const urlSearchParams = new URLSearchParams();
    if (!this.donateDetailsForm.get('message').value) {
      this.alertService.general().donationMessageRequired();
      return;
    }
    const amounts = [];
    Object.keys(this.donateDetailsForm.value).forEach((key) => {
      if (key.includes('amount') && this.donateDetails.filter((donateDetail) => donateDetail.slug === key)) {
        amounts.push(this.donateDetailsForm.value[key]);
      }
    });
    if (!amounts) {
      this.alertService.general().donationAmountRequried();
      return;
    }
    urlSearchParams.append('data[Donation][enable]', 'on');
    urlSearchParams.append('data[Event][donate]', this.donateDetailsForm.get('message').value);
    if (this.donateDetailsForm.get('optional').value) {
      urlSearchParams.append('data[Donation][amount][]', '0');
    }
    if (this.donateDetailsForm.get('other').value) {
      urlSearchParams.append('data[Donation][amount][]', 'other');
    }
    amounts.forEach((amount) => {
      urlSearchParams.append('data[Donation][amount][]', amount);
    });
    urlSearchParams.append('data[Donation][submitForm]:', '1');
    const body = urlSearchParams.toString();
    this.http.post('/events/handleDonation/' + encodeURIComponent(this.eventFarmService.currentEvent.id), body,
      {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest'
      })
    }).toPromise().then((res) => {
      this.message.success('Donations enabled');
    });
  }

  disableDonation() {
    this.apiClient.getUseCaseFactory().Event().DisableDonation(this.eventFarmService.currentEvent.id)
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasDonate = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Donations disabled');
      });
  }

}
