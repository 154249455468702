import { Injectable } from '@angular/core';

import { Mailbox } from '../../../../Shared/EmailMessage/mailbox';

@Injectable()

export class ViewMessagingService {
    private mailboxes: Mailbox[] = [];

    constructor() {
        this.addMailboxes();
    }

    private addMailboxes() {
        this.addMailbox(
            'Sent',
        );
    }

    private addMailbox(
        name: string = 'Mailbox',
    ) {
        let mailbox = new Mailbox();
        mailbox.name = name;
        this.mailboxes.push(mailbox);
    }
}
