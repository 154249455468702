import {Component, forwardRef, Input} from '@angular/core';
@Component({
    selector: 'mobile-preview',
    template: require('./mobile-preview.html'),
    styles: [require('./mobile-preview.scss')]
})

export class MobilePreviewComponent  {

    @Input() content: string;

    constructor() {
}

}
