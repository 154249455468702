import { AnswerBindingTypeInterface, Question as EFQuestion } from '@eventfarm/javascript-sdk/dist/Api/Type/Question';
import { AbstractModel } from '../abstract-model';

import { Answer } from '../Answer/answer';
import { Question } from './question';
import { v4 as uuidv4 } from 'uuid';
import { FormControl } from '@angular/forms';


export class AnswerBinding extends AbstractModel {

    private _answerBindingType: AnswerBindingTypeInterface;
    private _question: Question
    private _answer: Answer;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): AnswerBinding {
        const answerBinding = new this(data.id);

        try {
            answerBinding._answerBindingType = data.attributes.answerBindingType;            
            if (data.relationships && data.relationships.answer) {
                answerBinding._answer = Answer.fromApiResponse(data.relationships.answer)
            } 

        } catch (err) { }
        return answerBinding;
    }

    public static forNewCreation(q: Question): AnswerBinding {
        const uuid = uuidv4();
        const answerBinding = new this(uuid);
        answerBinding._question = q;
        answerBinding._answerBindingType = new EFQuestion().AnswerBindingType()[0];
        return answerBinding;
    }

    get answerBindingType(): AnswerBindingTypeInterface {
        return this._answerBindingType;
    }

    set answerBindingType(value: AnswerBindingTypeInterface) {
        this._answerBindingType = value;
    }

    get question(): Question {
        return this._question;
    }

    set question(value: Question) {
        this._question = value;
    }

    get answer(): Answer {
        return this._answer;
    }

    set answer(value: Answer) {
        this._answer = value;
    }
}

export interface AnswerBindingFormControlInterface {
    bindingId: string;
    formControl: FormControl;
}