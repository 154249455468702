import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TypeFactory } from '@eventfarm/javascript-sdk/dist/Api/Type/TypeFactory';
import { UseCaseFactory } from '@eventfarm/javascript-sdk/dist/Api/UseCase/UseCaseFactory';
import { OAuthRestClient } from './ClientLibrary/RestClient/OAuthRestClient';

import { AngularApiRestClient } from './angular-api-rest-client.service';
import { CookieTokenService } from './cookie-token-service';
import { USAState } from '@eventfarm/javascript-sdk/dist/Api/DateTime/USAStates';
import { Countries } from '@eventfarm/javascript-sdk/dist/Api/DateTime/Countries';
import { DateTimeZone } from '@eventfarm/javascript-sdk/dist/Api/DateTime/DateTimeZone';
import {LocalStorageService} from "../_services/storage/local-storage.service";
import {Observable} from "rxjs/index";

@Injectable()

export class EventFarmAPIClient {
    private oauthRestClient: OAuthRestClient;

    constructor(private cookieTokenService: CookieTokenService, private http: HttpClient, private localStorageService: LocalStorageService) {
        this.localStorageService = localStorageService;
        this.oauthRestClient = new OAuthRestClient(
            new AngularApiRestClient(this.http),
            this.localStorageService
        );
    }

    public getDateTimeFactory() {
        return new DateTimeZone();
    }

    public getUsaStateList() {
        return new USAState();
    }

    public getCountryList() {
        return new Countries();
    }

    public getTypeFactory() {
        return new TypeFactory();
    }

    public getUseCaseFactory() {
        return new UseCaseFactory(this.oauthRestClient);
    }

    public getMe(): Observable<any> {
        const fullBaseUrl = window.location.protocol + '//' + window.location.host;
        return  this.oauthRestClient.get('me', '');
    }

}
