import {Component, Input, OnInit, OnDestroy, AfterViewChecked} from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { FormControl } from '@angular/forms';
import { shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';
import { InvitationStatusTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EditInvitationService } from './edit-invitation.service';
import { GuestListViewService } from '../guest-list-view.service';
import { EventFarmService } from '../../../eventFarm.service';
import { User } from '../../../../ApiClient/Models/User/user';
import { AddInviteCountService } from '../../InvitationOptions/addInviteCount.service';
import { InvitationOptionsService, TicketTypeDTO } from '../../InvitationOptions/invitationOptions.service';
import { StackSelectionService } from '../../InvitationOptions/stackSelection.service';
import { isValidEmail } from '../../../../Utilities/validateEmail';
import * as _ from 'lodash';
import { ArrivalAlertUtility } from '../../../../Utilities/arrivalAlert.utility';
import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from '@khazii/ngx-intl-tel-input';
@Component({
    selector: 'edit-invitation',
    template: require('./edit-invitation.html'),
    styles: [require('./edit-invitation.scss')]
})
export class EditInvitationComponent implements OnInit, OnDestroy, AfterViewChecked {

    @Input() invitationId: string;
    // If passed in, we use target to navigate to a section of the component after it loads
    @Input() targetId?: string;

    private invitation: Invitation;
    private user: User;
    private title: FormControl = new FormControl();
    private firstName: FormControl = new FormControl();
    private lastName: FormControl = new FormControl();
    private email: FormControl = new FormControl();
    private proxyEmail: FormControl = new FormControl();
    private phone: FormControl = new FormControl();
    private position: FormControl = new FormControl();
    private company: FormControl = new FormControl();
    private other: FormControl = new FormControl();
    private externalId: FormControl = new FormControl();
    private checkInNotes: FormControl = new FormControl();
    private invitationNotes: FormControl = new FormControl();
    private guestPassCount: FormControl = new FormControl();
    private arrivalList: FormControl = new FormControl();
    private phoneNumberList: FormControl = new FormControl();
    private validStatuses$: Subscription;
    private statusTypes: InvitationStatusTypeInterface[];
    private isLoadingCurrentValues: boolean = false;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private shouldSaveQuestions: boolean = false;
    private arrivalRadioValue: string = 'email';
    private invalidEmail: boolean = false;
    private invalidProxyEmail: boolean = false;
    private invalidPhone: boolean = false;
    private arrivalLimit: boolean = false;
    private checkinLimit: boolean = false;
    private invitationLimit: boolean = false;
    private isSaving: boolean = false;
    private suspend: boolean = false;
    private guestPassLimit: string = 'none';
    private updateTicketBlockCountChange$ = Subscription;
    private targetIdElLocated = false;
    private profileImageUrl?: string = null;
    private base64Image: any;
    private canSaveImage: boolean|null;
    private currentlyHasProfileImage: boolean = false;

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];

    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store < fromRoot.AppState > ,
        private segmentService: SegmentService,
        private editInviteService: EditInvitationService,
        private guestListService: GuestListViewService,
        private eventFarmService: EventFarmService,
        private addInviteCountService: AddInviteCountService,
        private invitationOptionsService: InvitationOptionsService,
        private stackSelectionService: StackSelectionService
    ) {
        this.eventRoleType$.subscribe(res => {
            if (!res['address-book-crud'] && !res['user-crud'] && !this.guestListService.ticketBlock) {
                this.title.disable();
                this.firstName.disable();
                this.lastName.disable();
                this.phone.disable();
                this.position.disable();
                this.company.disable();
                this.other.disable();
                this.externalId.disable();
                this.proxyEmail.disable();
            }
            if (!this.guestListService.ticketBlock && !res['list-crud']) {
                this.arrivalList.disable();
                this.phoneNumberList.disable();
                this.checkInNotes.disable();
                this.invitationNotes.disable();
            }

            if (!res['list-crud']) {
                this.email.disable();
            }
        });
    }

    ngOnInit() {
        this.isSaving = false;
        this.initStoreSubscriptions();
        this.fetchCurrentInvitationAndUser();
    }

    ngAfterViewChecked() {
        // If target el has been passed in, and we haven't located it
        if (this.targetId && !this.targetIdElLocated) {
            const $el = document.querySelector(`#${this.targetId}`);
            if ($el) {
                $el.scrollIntoView();
                this.targetIdElLocated = true;
            }
        }

    }

    /**
     * Sets up the current invitation and user for edit
     */
    private async fetchCurrentInvitationAndUser() {

        this.isLoadingCurrentValues = true;

        const currentInvite = await this.editInviteService.fetchCurrentInvitation(this.invitationId);

        if (currentInvite) {
            this.invitation = currentInvite;

            if (!this.guestListService.isTicketBlock) {
                const currentUser = await this.editInviteService.fetchCurrentUserForInvitation(this.invitation.user.id);
                if (currentUser) {
                    this.user = currentUser;
                    this.user.name = this.invitation.userName ? this.invitation.userName : this.user.name;
                    this.invitation.userIdentifier = this.user.identifier;
                    this.initEmailPhoneSubscription();
                    this.setInitialFields();
                    this.initStatusSubscription();
                    this.checkForVirbelaSetting();
                } else {
                    this.isLoadingCurrentValues = false;
                }
            } else {
                this.user = currentInvite.user;
                this.user.name = this.invitation.userName ? this.invitation.userName : this.user.name;
                this.invitation.userIdentifier = this.user.identifier;
                this.initEmailPhoneSubscription();
                this.setInitialFields();
                this.initStatusSubscription();
            }
        } else {
            this.isLoadingCurrentValues = false;
        }

        this.isLoadingCurrentValues = false;
    }

    /**
     *
     * Updates the status of the invite
     */
    private onChangeStatus(status) {
        const selectedStatus = this.statusTypes.filter(s => s.slug === status)[0];
        this.invitationOptionsService.selectedStatus = selectedStatus;
        this.invitationOptionsService.getAllInvitationTypesForStackMethodType(this.invitationOptionsService.selectedStack.stackMethodType.slug);
    }

    private toggleArrival(val) {
        if (val === 'email') {
            this.arrivalList.setValue(this.makeEmailList());
            this.phoneNumberList.setValue(this.makeSMSList());
        } else {
            this.arrivalList.setValue('');
            this.phoneNumberList.setValue('');
        }
    }

    private get canSuspend() {
        return this.suspend;
    }

    private onChangeStack(s) {
        this.invitationOptionsService.selectedStackId = s;
        this.invitationOptionsService.getAllInvitationTypesForStackMethodType(this.invitationOptionsService.selectedStack.stackMethodType.slug);
        this.addInviteCountService.stack = this.invitationOptionsService.selectedStack;
        this.invitationOptionsService.invitationCount = this.invitation.inviteCount;
    }

    private async onChangeAction(action) {
        switch (action) {
            case 'resend-invite':
                await this.editInviteService.resendInviteOrConfirmation(action, this.invitation.id, this.invitation.identifier);
                break;
            case 'sync':
                await this.editInviteService.syncToSaleforce(this.invitation.id);
                break;
            case 'resend-confirm':
                await this.editInviteService.resendInviteOrConfirmation(action, this.invitation.id, this.invitation.identifier);
                break;
            case 'share':
                await this.editInviteService.shareLink(this.invitation);
                break;
            case 'visit':
                await this.editInviteService.visitLink(this.invitation);
                break;
            case 'suspend':
                await this.editInviteService.suspendUserFromVirbela(this.invitation.id, this.invitation.identifier);
                break;
        }
    }

    private checkShouldSendArrivalAlert() {
        if (this.invitation.shouldSendArrivalAlert === true && this.arrivalList.value.length === 0 && this.phoneNumberList.value.length === 0) {
            this.invitation.shouldSendArrivalAlert = false;
        }
    }

    /**
     * Saves invitation
     */
    private async saveInvitation() {
        if (this.isSaving === false) {
            this.shouldSaveQuestions = true;
            this.isSaving = true;

            const oldEmail = (this.user && this.user.identifier && this.user.primaryEmail) ? this.user.primaryEmail : '';
            const shouldTransfer = this.email.value.length && (oldEmail !== this.email.value);

            this.checkShouldSendArrivalAlert();

            const arrivalUtility = new ArrivalAlertUtility();

            const arrivalAlerts = arrivalUtility.format(
                this.invitation.shouldSendArrivalAlert,
                this.arrivalList.value,
                this.phoneNumberList.value
            )

            await this.editInviteService.handleProfileImage(this.invitation.user.id, this.currentlyHasProfileImage, this.base64Image);

            const updated = await this.editInviteService.saveInvitation({
                invitationId: this.invitation.id,
                invitationStatus: this.invitationOptionsService.selectedStatus.slug,
                stackId: this.invitationOptionsService.selectedStackId,
                company: this.company.value,
                position: this.position.value,
                email: this.email.value,
                firstName: this.firstName.value,
                lastName: this.lastName.value,
                other: this.other.value,
                externalId: this.buildExternalIdObject(),
                telephone: this.phone.value ? this.phone.value['e164Number'] : '',
                checkInNote: this.checkInNotes.value,
                inviteNote: this.invitationNotes.value,
                count: this.invitationOptionsService.invitationCount,
                shouldCheckIn: this.invitation.isCheckedIn,
                userId: this.user.id,
                title: this.title.value,
                shouldSendArrival: arrivalAlerts.shouldSendArrivalAlert,
                arrivalEmails: arrivalAlerts.arrivalAlertEmails,
                arrivalPhoneNumbers: arrivalAlerts.arrivalAlertMobileNumbers,
                oldEmail: oldEmail,
                shouldTransfer: shouldTransfer,
                guestPassCount: this.getGuestPassCount(),
                proxyEmail: this.invitation.hasProxyEmail ? this.proxyEmail.value : null
            });

            this.isSaving = false;

            if (updated) {
                if (this.guestListService.ticketBlock) {
                   if(!this.eventFarmService.updateTicketBlockCounts$.closed) {
                       this.eventFarmService.updateTicketBlockCounts$.next(true);
                   }
                }
                this.drawerRef.close(updated);
            }

        }

    }

    private buildExternalIdObject() {
        let userAttributeId = null;
        let userId = null;

        // grab value from form control and trim
        let value: string = this.externalId.value;
        value = value.trim();

        // if you had an external id and are trying to remove
        if (!value.length && this.invitation.hasExternalId) {
            const attribute = this.invitation.user.getAttributeByKey('external_id');

            return {
                userAttributeId: attribute.id,
                value: null,
                userId: null,
            }
        // if no value and user didnt have one, do nothing
        } else if (!value.length && !this.invitation.hasExternalId) {
            return {
                userAttributeId: null,
                value: null,
                userId: null,
            }

        // the value exists
        } else if (value.length) {
            return {
                userAttributeId: null,
                value,
                userId: this.invitation.user.id,
            }
        }

        // catch all do nothing
        return {
            userAttributeId: null,
            value: null,
            userId: null,
        }
    }

    private makeEmailList(): string {
        const emails = this.invitation.arrivalAlertEmails;
        return emails.join(', ');
    }

    private makeSMSList(): string {
        const sms = this.invitation.arrivalAlertPhoneNumbers;
        return sms.join(', ');
    }

    private async deleteInvite() {
        const deleteInvite = await this.editInviteService.deleteInvitation(this.invitation.id, this.invitation.userName);
        if (deleteInvite === true) {
            this.close(null);
            if (this.guestListService.ticketBlock) {
                this.guestListService.assembleGuestListData();
                if(!this.eventFarmService.updateTicketBlockCounts$.closed) {
                    this.eventFarmService.updateTicketBlockCounts$.next(true);
                }
            } else {
                this.guestListService.assembleGuestListData();
            }
        }
    }

    private allowSave() {
        if ( this.phone) {
            if (!this.phone.valid) {
                return false;
            }
        }
        if (this.invitation.hasProxyEmail) {
            if (this.invalidProxyEmail) {
                return false;
            }
        }

        if (this.canSaveImage === false) {
            return false;
        }


        return this.firstName.value !== '' && !this.invalidEmail && this.lastName.value !== '';
    }

    get shouldHideInviteLink(): boolean {
        if (this.guestListService.isSessionTB) {
            return true;
        }

        const selectedStack = this.invitationOptionsService.selectedStack;
        if (selectedStack) {
            return (selectedStack.stackMethodType.isPublicPurchase || selectedStack.stackMethodType.isPublicRegistration);
        }

        return false;
    }

    /**
     * Setup initial values and subscriptions
     */
    private setInitialFields() {
        this.title.setValue((this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('title')) ? this.user.getAttributeType('title') : '');
        this.firstName.setValue((this.user && this.user.name && this.user.name.firstName) ? this.user.name.firstName : '');
        this.lastName.setValue((this.user && this.user.name && this.user.name.lastName) ? this.user.name.lastName : '');
        this.email.setValue((this.user && this.user.identifier && this.user.primaryEmail) ? this.user.primaryEmail : '');
        this.phone.setValue((this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('telephone')) ? this.user.getAttributeType('telephone') : '');
        this.position.setValue((this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('position')) ? this.user.getAttributeType('position') : '');
        this.company.setValue((this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('company')) ? this.user.getAttributeType('company') : '');
        this.other.setValue((this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('other')) ? this.user.getAttributeType('other') : '');
        this.externalId.setValue((this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('external_id')) ? this.user.getAttributeType('external_id') : '');
        this.checkInNotes.setValue(this.invitation.checkInNotes ? this.invitation.checkInNotes : '');
        this.invitationNotes.setValue(this.invitation.invitationNotes ? this.invitation.invitationNotes : '');
        this.proxyEmail.setValue(this.invitation.proxyEmail ? this.invitation.proxyEmail : '');
        this.arrivalList.setValue(this.makeEmailList());
        this.phoneNumberList.setValue(this.makeSMSList());
        this.invitationOptionsService.resetSelectedStack();
        this.invitationOptionsService.selectedStatus = null;
        this.invitationOptionsService.invitationCount = this.invitation.inviteCount ? this.invitation.inviteCount : 1;
        this.invitationOptionsService.currentEvent = this.editInviteService.currentEvent;
        this.invitationOptionsService.stacks = this.editInviteService.currentStacks;
        this.invitationOptionsService.initialInvitation = this.invitation;
        this.invitationOptionsService.selectedStackId = this.invitation.stack.id;
        this.invitationOptionsService.getAllInvitationTypesForStackMethodType(this.invitationOptionsService.selectedStack.stackMethodType.slug);
        this.invitationOptionsService.selectedStatus = this.invitation.invitationStatusType;
        this.addInviteCountService.stack = this.invitationOptionsService.selectedStack;
        this.stackSelectionService.invitationCreationTypes = this.invitationOptionsService.invitationCreationTypes;
        this.stackSelectionService.invitationCreationTypeSlug = this.stackSelectionService.defaultInvitationCreationTypeSlug;
        this.stackSelectionService.invitationStatusSlug = this.stackSelectionService.defaultStatus;
        this.profileImageUrl = (this.user && this.user.attributes && this.user.attributes[0] && this.user.hasAttributeType('profileImageUrl')) ? this.user.getAttributeType('profileImageUrl') : null;

        if (this.profileImageUrl) {
            this.currentlyHasProfileImage = true;
        }


        this.invitationOptionsService.initTicketTypes();
        this.onChangeTicketType(this.invitation.stack.ticketType.id);

        if (this.invitation.guestPassCount !== null) {
            if (this.invitation.guestPassCount === -1) {
                this.guestPassLimit = 'unlimited';
            } else if (this.invitation.guestPassCount === 0) {
                this.guestPassLimit = 'none';
            } else {
                this.guestPassLimit = 'limited';
            }
            this.guestPassCount.setValue(this.invitation.guestPassCount);
        } else {
            this.guestPassLimit = 'not-set';
        }
    }

    private initStoreSubscriptions() {
        this.segmentService.segmentPromotionsTracking().openEdit();
    }

    private initStatusSubscription() {
        this.validStatuses$ = this.invitationOptionsService.validStatuses$.subscribe((val) => {
            if (!this.invitationOptionsService.selectedStatus) {
                return;
            }

            this.statusTypes = val;

            if (val.filter(status => status.slug === this.invitationOptionsService.selectedStatus.slug).length < 1) {
                this.invitationOptionsService.selectedStatus = val[0];
            }
        });
    }

    private onChangeTicketType(t: string) {
        const selectedTicketTypeDTO = this.invitationOptionsService.selectedTicketType;
        const shouldSetNewStack = selectedTicketTypeDTO && selectedTicketTypeDTO.id !== t;

        this.invitationOptionsService.selectedTicketTypeId = t;

        if (this.invitationOptionsService.selectedTicketType.stacks.length === 1) {
            this.onChangeStack(this.invitationOptionsService.selectedTicketType.stacks[0].id);
        } else {
            if (shouldSetNewStack) {
                this.onChangeStack(this.invitationOptionsService.selectedTicketType.stacks[0].id);
            }
        }
    }

    private shouldDisableTicketTypeOption(ticketType: TicketTypeDTO) {
        if (!this.invitationOptionsService.showTimeSlots && ticketType.stacks.length === 1) {
            return this.stackSelectionService.shouldDisableStackOption(ticketType.stacks[0]);
        }

        let disabled = true;

        ticketType.stacks.every(s => {
            const shouldDisableStack = this.stackSelectionService.shouldDisableStackOption(s);

            if (!shouldDisableStack) {
                disabled = false;
                return false;
            }

            return true;
        })

        return disabled;
    }

    private async checkForVirbelaSetting() {

        if (!this.guestListService.isTicketBlock && this.eventFarmService.virbelaEnabled) {
            const vbPoolSetting = await this.eventFarmService.currentPoolWorldForEvent();
            if (vbPoolSetting) {
                this.suspend = true;
            } else {
                this.suspend = false
            }
        } else {
            this.suspend = false;
        }
    }

    private getGuestPassCount(): number|null {
        if (this.eventFarmService.guestPassEnabled) {
            if (this.guestPassLimit === 'unlimited') {
                return -1;
            } else if (this.guestPassLimit === 'limited') {
                return this.guestPassCount.value;
            } else if (this.guestPassLimit === 'none') {
                return 0;
            }
        }

        return null;

    }

    private onGuestPassLimitChanged($event) {
        if ($event === 'unlimited') {
            this.guestPassCount.setValue(-1);
        } else if ($event === 'limited') {
            if (this.invitation.guestPassCount && this.invitation.guestPassCount > 0) {
                this.guestPassCount.setValue(this.invitation.guestPassCount);
            }else {
                this.guestPassCount.setValue(1);
            }
        } else if ($event === 'none') {
            this.guestPassCount.setValue(0);

        } else {
            this.guestPassCount.setValue(null);
        }
    }

    private getMinForGuestPass() {
        return Math.max(_.get(this.invitation, 'extraAttributes.guestPassCounts.totalUsed', 0), 1);
    }

    private initEmailPhoneSubscription() {
        this.email.valueChanges.subscribe(emailInput => {
            let badEmail: boolean = false;

            if (!emailInput.length) {
                badEmail = false;
            } else {
                if (isValidEmail(emailInput)) {
                    badEmail = false;
                } else {
                    badEmail = true;
                }
            }
            this.invalidEmail = badEmail;
        });

        this.proxyEmail.valueChanges.subscribe(emailInput => {
            let badEmail: boolean = false;

            if (!emailInput.length) {
                badEmail = true;
            } else {
                if (isValidEmail(emailInput)) {
                    badEmail = false;
                } else {
                    badEmail = true;
                }
            }
            this.invalidProxyEmail = badEmail;
        });

        this.arrivalList.valueChanges.subscribe(input => {
            this.arrivalLimit = input.length >= 1930;
        });

        this.phoneNumberList.valueChanges.subscribe(input => {
            this.arrivalLimit = input.length >= 1930;
        });

        this.checkInNotes.valueChanges.subscribe(input => {
            this.checkinLimit = input.length >= 430;
        });

        this.invitationNotes.valueChanges.subscribe(input => {
            this.invitationLimit = input.length >= 430;
        });
    }

    close(updatedInvite: Invitation | null) {

        if (updatedInvite === null) {
            this.segmentService.segmentGuestListTracking().cancelEdit({invitationId: this.invitationId});
        }

        this.drawerRef.close(updatedInvite);
    }
    get isResendingDisabled(): boolean {
        if (this.editInviteService.resending){
            return true
        } else if (!(this.invitation.userIdentifier || this.invitation.hasProxyEmail )){
            return true;
        }
        return false;
    }
    ngOnDestroy() {
        this.targetIdElLocated = false;
    }

    private saveImageAsBase64(image) {
        this.base64Image = image;
        this.canSaveImage = true;
    }

    private imageSet($event) {
        this.canSaveImage = false;
    }
}
