import { AbstractBaseAlert } from '../base-alert';

export class UploaderAlert extends AbstractBaseAlert {
    exitAndCancelUpload(): Promise<any> {
        return this.alert({
            title: 'Exit the Uploader?',
            text: 'Are you sure? If you exit now, the upload will be cancelled and your progress will be lost.',
            confirmButtonText: 'Exit Uploader',
            showCancelButton: true,
            icon: 'question',
        });
    }

    mergeIntoExistingGroup(): Promise<any> {
        return this.alert({
            title: 'Use Existing Group?',
            text: 'A group with this name already exists. Would you like to add to the existing group, or choose a new group name?',
            confirmButtonText: 'Add to Existing Group',
            cancelButtonText: 'Choose New Name',
            showCancelButton: true,
            icon: 'question',
        });
    }
}
