import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentReportTracking extends AbstractBaseSegment {

    /**
     * Report Gen
     */

    generateReport(reportDetails: object) {
        this.track({
            event: actions.REPORT_GENERATE,
            properties: {
                ...reportDetails
            }
        }, true);
    }

}

