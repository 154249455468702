import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';

import { Operator } from 'rxjs';

@Injectable()

export class SMSService {

    constructor(private apiClient: EventFarmAPIClient, private eventFarmService: EventFarmService) {

    }


}
