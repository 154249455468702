import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';

import { Observable, BehaviorSubject, Subject, of, forkJoin } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

import { Contract } from '../../../ApiClient/Models/Contract/contract';
import { HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../../../_services/storage/session-storage.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { AngularRestClient } from '../../../_services/rest/AngularRestClient';
import { VirbelaWorld } from '../../../ApiClient/Models/VirbelaWorld/virbela-world';
import { NzMessageService } from 'ng-zorro-antd/message';
import { WebConferenceUserConnection } from '../../../ApiClient/Models/WebConferenceUserConnection/web-conference-user-connection';
import { User } from '../../../ApiClient/Models/User/user';
import { AlertService } from '../../eventFarmAlert.service';

export class EventCount {
    constructor(
        public total = '---',
        public eventfarm = '---',
        public cio = '---',
    ) { }
}

export class SubmittingStatus {
    constructor(
        public text = 'Send Request',
        public status = 'not-sent'
    ) { }
}

export class EventGuestsCount {
    constructor(
        public totalUsers = 'Gathering Data...'
    ) { }
}

@Injectable()
export class AccountInfoService {

    eventCount$: BehaviorSubject<EventCount> = new BehaviorSubject(new EventCount());
    eventGuestsCount$: BehaviorSubject<EventGuestsCount> = new BehaviorSubject(new EventGuestsCount());
    adminCount$: BehaviorSubject<string> = new BehaviorSubject('---');
    admins$: BehaviorSubject<any> = new BehaviorSubject([]);
    contract$ = new BehaviorSubject(new Contract());
    disabledFeatures$: Observable<Object[]> = new Subject<any>();
    availableFeatures$: Observable<Object[]> = new Subject<any>();
    teamStore$: Observable<any> = this.store.select('team');
    emailCountsForEvent$: BehaviorSubject<any> = new BehaviorSubject([]);
    sumbittingStatus$: BehaviorSubject<SubmittingStatus> = new BehaviorSubject(new SubmittingStatus());
    features$: Observable<Object[]> = new Subject<any>();
    integrationsTeamConfig: any = {
        Salesforce: {
            enabled: false,
            active: false,
            settings: {},
        },
        Virbela: {
            enabled: false,
            active: false,
            settings: {},
            poolWorlds:[]
        },
        WebConference: {
            enabled: false,
            active: false,
            settings: {},
            poolConnections:[],
            isCurrentUserConnectedZoom: false
        },
    };
    salesforceLoginUrl: string = '';

    constructor(
        private apiClient: EventFarmAPIClient,
        private http: AngularRestClient,
        private store: Store<fromRoot.AppState>,
        private sessionStorageService: SessionStorageService,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private alertService: AlertService
    ) {
    }

    broadcastDataSetsForTeam(contractBulkResponses: any[]) {

        contractBulkResponses.forEach((response) => {
            if (response.data && response.data.eventfarm !== undefined && response.data.cio !== undefined) {
                this.eventCount$.next(response.data);
            }
            if (response.data && response.data.poolId && response.data.totalUsers !== undefined) {
                this.eventGuestsCount$.next(Object.assign({loaded: true}, response.data));
            }
            if (response.data && response.data.guestMessageCount !== undefined && response.data.invitationCount !== undefined) {
                this.emailCountsForEvent$.next(response.data);
            }
            if (response.data && response.data.length && response.data[0].type === 'PoolContact') {
                this.admins$.next(response.data);
                this.adminCount$.next(response.data.length);
            }
        });

        this.availableFeatures$ = of(this.apiClient.getTypeFactory().Pool().FeatureType()).pipe(map(data => data.filter(feature => this.removeFeatures(feature))));

        this.features$ = this.availableFeatures$.pipe(mergeMap((featureTypes: any) => {
            return this.teamStore$.pipe(map((team: any) => {
                return featureTypes.map((featureType: any) => {
                    const isEnabled = team.features.data.filter((initializedFeature: any) => {
                        if (initializedFeature.attributes.isEnabled) {
                            return initializedFeature.relationships.feature.attributes.name === featureType.name;
                        }
                    })[0] != null;
                    featureType['isEnabled'] = isEnabled;
                    
                    if (featureType.isSalesforce) {
                        this.integrationsTeamConfig.Salesforce.enabled = isEnabled;
                    }

                    if (featureType.isVirbela) {
                        this.integrationsTeamConfig.Virbela.enabled = isEnabled;
                    }

                    if (featureType.isWebConference) {
                        this.integrationsTeamConfig.WebConference.enabled = isEnabled;
                    }
                    return featureType;
                });
            }));
        }));

        this.disabledFeatures$ = this.features$.pipe(map(features => features.filter(feature => !feature['isEnabled'])));
    }

    async fetchSalesforcePoolSettings(team) {
        return new Promise((resolve, reject) => {
            this.apiClient.getUseCaseFactory()
                .SalesforcePoolSetting()
                .GetSalesforcePoolSetting(team.id)
                .toPromise()
                .then((res) => {
                    this.integrationsTeamConfig.Salesforce.settings = res.data;
                    this.integrationsTeamConfig.Salesforce.active = true;

                    if (res.data.attributes.defaultLeadSource) {
                        this.integrationsTeamConfig.Salesforce.settings.leadSource = res.data.attributes.defaultLeadSource;
                    }

                    if (res.data.attributes.defaultLeadCompany) {
                        this.integrationsTeamConfig.Salesforce.settings.leadCompany = res.data.attributes.defaultLeadCompany || 'Company Placeholder';
                    }

                    if (!res.data.relationships.syncUser) {
                        this.integrationsTeamConfig.Salesforce.active = false;
                        reject(res.data);
                    }
                    return this.apiClient.getUseCaseFactory()
                        .Salesforce()
                        .GetSalesforceSyncUserInfoForPool(team.id)
                        .toPromise();
                }).then((res) => {
                if (res.data.username) {
                    this.integrationsTeamConfig.Salesforce.settings.syncUser = res.data;
                    this.integrationsTeamConfig.Salesforce.settings.userUnavailable = false;
                    resolve(this.integrationsTeamConfig);
                } else {
                    this.integrationsTeamConfig.Salesforce.settings.userUnavailable = true;
                    this.integrationsTeamConfig.Salesforce.active = false;
                    this.sessionStorageService.set('poolIntegrations', this.integrationsTeamConfig);
                    reject(this.integrationsTeamConfig);
                }
            }).catch((error) => {
                this.integrationsTeamConfig.Salesforce.active = false;
                reject(this.integrationsTeamConfig);
            });
        });
    }
    async listVirbelaPoolWorlds(team) {
        try {
            const response = await this.apiClient.getUseCaseFactory()
            .PoolWorld()
            .ListPoolWorldsForPool(team.id)
            .toPromise();
            this.integrationsTeamConfig.Virbela.poolWorlds = response.data;
        } catch (e) {
            this.integrationsTeamConfig.Virbela.poolWorlds = []
        }
    }

    async listWebConferenceUserConnectionsForTeam(team) {
        this.integrationsTeamConfig.WebConference.isCurrentUserConnectedZoom = false;
        try {
            const response = await this.apiClient.getUseCaseFactory()
            .WebConference()
            .ListWebConferenceConnectionsForPool(team.id)
            .toPromise();
            this.integrationsTeamConfig.WebConference.poolConnections = response.data.map(m => {
                const connection = WebConferenceUserConnection.fromApiResponse(m);
                if (connection.user.id === this.eventFarmService.currentUser.id && connection.sourceType.isZoom) {
                    this.integrationsTeamConfig.WebConference.isCurrentUserConnectedZoom = true;
                }
                return connection;
            });
            this.integrationsTeamConfig.WebConference.active = this.integrationsTeamConfig.WebConference.poolConnections.length ? true : false;
        } catch (e) {
            this.integrationsTeamConfig.WebConference.poolConnections = []
            this.integrationsTeamConfig.WebConference.active = false;
        }
    }

    async setPoolSettingFromSearch(settings) {
        this.integrationsTeamConfig.Virbela.settings = settings;
        this.integrationsTeamConfig.Virbela.active = true;
    }

    async deauthorizeConnection(connection: WebConferenceUserConnection) {
        try {
            const confirm = await this.alertService.webConferenceIntegration().removeConnection(connection.userEmailAndType);
            if (confirm.value) {
                await this.apiClient.getUseCaseFactory().WebConference().RemoveWebConferenceConnectionFromPool(connection.user.id, this.eventFarmService.currentTeam.id, connection.sourceType.slug).toPromise();
                await this.message.success('Connection Removed Successfully');
                await this.listWebConferenceUserConnectionsForTeam(this.eventFarmService.currentTeam);
                return true;
            } 
            return false;
        } catch (e) {
        }
    }

    removeFeatures(feature) {
        return !['Agents', 'Marketo', 'VisaCheckout', 'Shield'].includes(feature.name);
    }

    requestUpgrade(csm, form, other) {
        this.sumbittingStatus$.next({text: 'Sending...', status: 'pending'});
        this.apiClient.getUseCaseFactory().Pool()
            .SendUpgradeRequestToCsm(this.eventFarmService.currentTeam.id, this.eventFarmService.currentUser.id, csm, form, other)
            .subscribe(res => {
                this.sumbittingStatus$.next({text: 'OK', status: 'sent'});
            }, (err) => {
                this.sumbittingStatus$.next({text: 'Try Again', status: 'error'});
            });
    }

    public fetchSalesforceLoginUrl() {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest'
            })
        };

        return this.http.get('/integrations/getSalesforceLoginUrl/' + this.eventFarmService.currentUser.id, httpOptions);
    }

    saveSalesforceTeamIntegrationValues() {
        this.integrationsTeamConfig.Salesforce.settings.leadCompany = String(this.integrationsTeamConfig.Salesforce.settings.leadCompany).trim() ? String(this.integrationsTeamConfig.Salesforce.settings.leadCompany).trim() : 'Company Placeholder';

        const leadCompany = this.apiClient.getUseCaseFactory()
            .SalesforcePoolSetting()
            .SetLeadCompanyForPool(this.eventFarmService.currentTeam.id, this.integrationsTeamConfig.Salesforce.settings.leadCompany);

        if (this.integrationsTeamConfig.Salesforce.settings.leadSource != null) {
            const leadSource = this.apiClient.getUseCaseFactory()
                .SalesforcePoolSetting()
                .SetLeadSourceForPool(this.eventFarmService.currentTeam.id, this.integrationsTeamConfig.Salesforce.settings.leadSource);

            return forkJoin(
                leadSource,
                leadCompany
            );
        }

        return leadCompany;
    }


    connectToSalesforce() {
        return this.apiClient.getUseCaseFactory()
            .SalesforcePoolSetting()
            .CreateSalesforcePoolSetting(this.eventFarmService.currentTeam.id)
            .toPromise();
    }

    async connectToZoom() {
        await this.eventFarmService.connectToZoom();
    }

    disconnectFromSalesforce() {
        return new Promise((resolve, reject) => {
            return this.apiClient.getUseCaseFactory()
                .SalesforcePoolSetting()
                .RemoveSyncUserForPool(this.eventFarmService.currentTeam.id)
                .toPromise()
                .then((res) => {
                    return this.fetchSalesforcePoolSettings(this.eventFarmService.currentTeam.id);
                }).catch((error) => {
                    reject(error);
                }).then((res) => {
                    resolve(res);
                });
        });
    }

    fetchSalesforceSdkLimitsForTeam() {
        return new Promise((resolve, reject) => {
            this.apiClient.getUseCaseFactory()
                .Salesforce()
                .GetSalesforceLimitsForPool(this.eventFarmService.currentTeam.id)
                .toPromise()
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    setOrUnsetPrivacyPolicyURL(linkURL = null) {
        if (linkURL) {
            return this.apiClient.getUseCaseFactory()
                .Pool().SetPrivacyPolicyLinkForPool(this.eventFarmService.currentTeam.id, linkURL);
        }
        return this.apiClient.getUseCaseFactory()
            .Pool().RemovePrivacyPolicyLinkForPool(this.eventFarmService.currentTeam.id);
    }

    fetchEfxEvents(dateFilterType: 'current-future' | 'past-3-months' = 'current-future') {
        return this.apiClient.getUseCaseFactory().Event().ListEventsForUser(this.eventFarmService.currentUser.id, null, ['efx-enabled'], ['archived'], null, null, 1, 10,  'event-start', 'descending', dateFilterType, this.eventFarmService.currentTeam.id);
    }

    setOrUnsetPrivacyPolicyOrgName(orgName = null) {
        if (orgName) {
            return this.apiClient.getUseCaseFactory()
                .Pool().SetCustomerDisplayNameForPool(this.eventFarmService.currentTeam.id, orgName);
        }
        return this.apiClient.getUseCaseFactory()
            .Pool().RemoveCustomerDisplayNameForPool(this.eventFarmService.currentTeam.id);
    }

}
