import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { EventFarmService } from '../../../eventFarm.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { FormattedDateTime } from '../../../../ApiClient/Models/formatted-datetime';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import {dateTimeFormat, momentShortDateTime, timePickerDefaults} from '../../../../Shared/datetime-options';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'event-info-header',
    template: require('./event-info-header.html'),
    styles: [require('./event-info-header.scss')],
})

export class EventInfoHeaderComponent implements OnInit, OnDestroy {

    private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
    private isEditing: boolean;
    private eventName: string;
    private eventOpeningDate;
    private eventClosingDate;
    private dateTimeFormat = dateTimeFormat;
    private disabledEndDate;
    private eventTimezone: string;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;
    private timePickerDefaults = timePickerDefaults;

    constructor(
        private eventFarmService: EventFarmService,
        private route: ActivatedRoute,
        private store: Store<fromStore.AppState>,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
    ) {

        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
        });

    }

    ngOnInit() {
        this.isEditing = false;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }

    goToUploader() {
        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentEvent.team.id;
        this.router.navigateByUrl(this.routeGenerator.url('pools.uploader', { eventId, poolId }));
    }

    cancelEdit() {
        this.isEditing = !this.isEditing;
    }

    editName() {
        if (this.isEditing) {
            if (this.eventName.length) {
                const startDateLocal: string = this.eventOpeningDate.toLocaleString('en-US', { hour12: false });
                const endDateLocal: string = this.eventClosingDate.toLocaleString('en-US', { hour12: false });
                const namePromise = this.apiClient.getUseCaseFactory().Event().SetNameForEvent(this.eventFarmService.currentEvent.id, this.eventName).toPromise();
                const timePromise = this.apiClient.getUseCaseFactory().Event().SetTimeForEvent(this.eventFarmService.currentEvent.id, startDateLocal, endDateLocal, this.eventTimezone).toPromise();

                Promise.all([namePromise, timePromise]).then( (res) => {
                    const {startTimeFormatted, endTimeFormatted} = res[1].data.command;
                    this.eventFarmService.currentEvent.name = this.eventName;
                    this.eventFarmService.currentEvent.startTime = startTimeFormatted;
                    this.eventFarmService.currentEvent.endTime = endTimeFormatted;
                    this.eventFarmService.currentEvent.timezone = this.eventTimezone;
                    this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                    this.message.success('Event name and time updated');
                    this.isEditing = !this.isEditing;
                }).catch((err) => {
                    this.message.error('Please try again');
                });
            }
        } else {
            this.eventName = this.currentEventName;

            const start = `${this.eventFarmService.currentEvent.startTime.date.short} ${this.eventFarmService.currentEvent.startTime.time.short}`;
            this.eventOpeningDate = moment(start, momentShortDateTime).toDate();
            const end = `${this.eventFarmService.currentEvent.endTime.date.short} ${this.eventFarmService.currentEvent.endTime.time.short}`;
            this.eventClosingDate = moment(end, momentShortDateTime).toDate();
            this.eventTimezone = this.eventFarmService.currentEvent.startTime.timezone;
            this.isEditing = !this.isEditing;
        }

    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get currentEventDateTime(): string {
        const sTime: FormattedDateTime = this.eventFarmService.currentEvent.startTime;
        const eTime: FormattedDateTime = this.eventFarmService.currentEvent.endTime;
        if (_.get(sTime, 'date.short') === _.get(eTime, 'date.short')) {
            return `${_.get(sTime, 'date.medium')} ${_.get(sTime, 'time.short')} - ${_.get(eTime, 'time.medium')}`;
        }
        return `${_.get(sTime, 'date.medium')} ${_.get(sTime, 'time.short')} - ${eTime.medium}`;
    }

    private updateValidEndDate() {
        if (this.eventOpeningDate > this.eventClosingDate) {
            this.eventClosingDate = new Date(Date.parse(this.eventOpeningDate) + 10800000);
        }
        this.setValidEndDate();
    }

    private setValidEndDate() {
        this.disabledEndDate = (current: Date): boolean => {
            return current < this.eventOpeningDate;
        };
    }

    private selectTimezone(timeZone: string): void {
        this.eventTimezone = timeZone;
    }

}
