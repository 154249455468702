import {Action} from '@ngrx/store';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';

export const FETCH_CONTACTS_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Contacts For Team';
export const FETCH_CONTACTS_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Contacts For Team Success';
export const FETCH_CONTACTS_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Contacts For Team Fail';

export const ADD_CONTACT_TO_ADMIN_TEAM = '[Admin/Team] Add Contact To Team';
export const ADD_CONTACT_TO_ADMIN_TEAM_SUCCESS = '[Admin/Team] Add Contact To Team Success';
export const ADD_CONTACT_TO_ADMIN_TEAM_FAIL = '[Admin/Team] Add Contact To Team Fail';

export const UPDATE_CONTACT_FOR_ADMIN_TEAM = '[Admin/Team] Update Contact From Team';
export const UPDATE_CONTACT_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Update Contact From Team Success';
export const UPDATE_CONTACT_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Update Contact From Team Fail';

export const REMOVE_CONTACT_FROM_ADMIN_TEAM = '[Admin/Team] Remove Contact From Team';
export const REMOVE_CONTACT_FROM_ADMIN_TEAM_SUCCESS = '[Admin/Team] Remove Contact From Team Success';
export const REMOVE_CONTACT_FROM_ADMIN_TEAM_FAIL = '[Admin/Team] Remove Contact From Team Fail';

export class AddContactToAdminTeam implements Action {
    readonly type = ADD_CONTACT_TO_ADMIN_TEAM;
    constructor( public payload: {teamId: string, email: string, type: string}){}
}

export class AddContactToAdminTeamSuccess implements Action {
    readonly type = ADD_CONTACT_TO_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolContact) { }
}

export class AddContactToAdminTeamFail implements Action {
    readonly type = ADD_CONTACT_TO_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class UpdateContactForAdminTeam implements Action {
    readonly type = UPDATE_CONTACT_FOR_ADMIN_TEAM;
    constructor() { }
}

export class UpdateContactForAdminTeamSuccess implements Action {
    readonly type = UPDATE_CONTACT_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolContact[]) { }
}

export class UpdateContactForAdminTeamFail implements Action {
    readonly type = UPDATE_CONTACT_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class FetchContactsForAdminTeam implements Action {
    readonly type = FETCH_CONTACTS_FOR_ADMIN_TEAM;
    constructor() { }
}

export class FetchContactsForAdminTeamSuccess implements Action {
    readonly type = FETCH_CONTACTS_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolContact[]) { }
}

export class FetchContactsForAdminTeamFail implements Action {
    readonly type = FETCH_CONTACTS_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class RemoveContactFromAdminTeam implements Action {
    readonly type = REMOVE_CONTACT_FROM_ADMIN_TEAM;
    constructor(public payload: {teamId:string, contactId:string, userId:string}) { }
}

export class RemoveContactFromAdminTeamSuccess implements Action {
    readonly type = REMOVE_CONTACT_FROM_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolContact) { }
}

export class RemoveContactFromAdminTeamFail implements Action {
    readonly type = REMOVE_CONTACT_FROM_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}
export type AdminContactAction =
    AddContactToAdminTeam |
    AddContactToAdminTeamSuccess |
    AddContactToAdminTeamFail |
    UpdateContactForAdminTeam |
    UpdateContactForAdminTeamSuccess |
    UpdateContactForAdminTeamFail |
    RemoveContactFromAdminTeam |
    RemoveContactFromAdminTeamSuccess |
    RemoveContactFromAdminTeamFail |
FetchContactsForAdminTeam |
FetchContactsForAdminTeamSuccess |
FetchContactsForAdminTeamFail;
