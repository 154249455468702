import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Subscription ,  Observable } from 'rxjs';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { filter, shareReplay } from 'rxjs/operators';
@Component({
    selector: 'time-remaining-until-event-start',
    template: require('./time-remaining-until-event-start.html'),
    styles: [require('./time-remaining-until-event-start.scss')],
})

export class TimeRemainingUntilEventStartComponent implements OnDestroy {

    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventStoreData: EfEvent;
    private eventUpdate$: Subscription;

    constructor(
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.eventStoreData = val.data;
            this.calculate();
        });
    }

    private currentDate: Date;
    private eventDate: Date;
    private months: number;
    private days: number;
    private totalDaysRemining: number;

    calculate() {
        this.currentDate = new Date();
        this.eventDate = new Date(this.eventStoreData.startTime.timestamp * 1000);

        const eventDateMoment = moment(this.eventDate);
        const currentDateMoment = moment(this.currentDate);

        this.totalDaysRemining = Number(eventDateMoment.diff(currentDateMoment, 'days'));

        this.months = 0;
        this.days = this.totalDaysRemining;

        if (this.totalDaysRemining > 30) {
            this.months  = Math.floor(Number(this.totalDaysRemining) / 30);
            this.days = this.totalDaysRemining - (this.months * 30);
        }
    }

    isInFuture() {
        if (!this.eventStoreData) {
            return false;
        }
        return Number(this.eventStoreData.startTime.timestamp) > ( this.currentDate.getTime() / 1000 );
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
}
