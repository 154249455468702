import { Component, OnInit } from '@angular/core';
import { WaitListService } from './waitList.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../store';
import { getInvitations } from './../../store/selectors/event/invitation.selectors';
import { WaitListItemComponent } from './WaitlistItem/waitListItem.component';
import { map, filter, shareReplay } from 'rxjs/operators';

@Component({
    template: require('./waitList.html'),
    styles: [require('./waitList.scss')],
})

export class WaitListComponent implements OnInit {

    public waitlist$: Observable<any>;
    public loaded$: Observable<any>;
    public loading$: Observable<any>;
    private meta$: Observable<any>;
    private eventId;

    constructor(
        private waitListService: WaitListService,
        private store: Store<fromRoot.AppState>,
    ) {
        this.waitlist$ = this.store.select(getInvitations).pipe(shareReplay());

        this.meta$ = this.store.select('event').pipe(
            filter(event => event.invitations && event.invitations.waitlist),
            map(event => event.invitations.waitlist.meta)
        );

        this.loading$ = this.store.select('event').pipe(
            filter(event => event.invitations && event.invitations.waitlist),
            map(event => event.invitations.loading)
        );

        this.loaded$ = this.store.select('event').pipe(
            filter(event => event.invitations && event.invitations.waitlist),
            map(event => event.invitations.loaded)
        );

       
    }
    ngOnInit(): void {
        this.waitListService.initWaitlist();
    }
}
