import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { EFProfile, LinkAction, ProfileLinkAction, UpdatableProfileOptions} from '../../../ApiClient/Models/Profile/EFProfile';
import { CreateSessionOptions, EFSession, UpdatableSessionOptions} from '../../../ApiClient/Models/Session/EFSession';
import * as _ from 'lodash';
import { Track, UpdatableTrackOptions } from '../../../ApiClient/Models/Track/track';
import { FormGroup } from '@angular/forms';
import { Link, LinkTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Link';
import { v4 as uuidv4 } from 'uuid';


@Injectable()
export class EventAgendaService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
    ) {
    }

    public speakersListOptions: EventProfileOptions = {
        query: '',
        sortBy: 'name',
        sortDirection: 'ascending',
        withData: ['EventProfiles', 'ProfileLinks'],
        pagination: {
            currentPage: 1,
            itemsPerPage: 20,
        },
        shouldHideDeleted: true,
        eventProfileFilter: ['speaker', 'presenter', 'panelist', 'moderator', 'host', 'participant', 'other']
    };

    public sponsorsListOptions: EventProfileOptions = {
        query: '',
        sortBy: 'company',
        sortDirection: 'ascending',
        withData: ['EventProfiles', 'ProfileLinks'],
        pagination: {
            currentPage: 1,
            itemsPerPage: 20,
        },
        shouldHideDeleted: true,
        eventProfileFilter: ['sponsor']
    };


    public sessionsListOptions: SessionsListOptions = {
        query: '',
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentPage: 1,
            itemsPerPage: 20,
        },
        attributesExcludeFilter: ['archived']
    };

    public tracksListOptions: TracksListOptions = {
        query: '',
        sortBy: 'name',
        sortDirection: 'ascending',
        withData: ['SessionTracks', 'TicketTypeTracks'],
        pagination: {
            currentPage: 1,
            itemsPerPage: 20,
        }
    };

    public originalSpeakers: EFProfile[];
    public speakersMetaSource = new BehaviorSubject<any>({});
    public speakersMetaTotalResults = new BehaviorSubject<number>(0);
    public speakersMeta$ = this.speakersMetaSource.asObservable();
    public readonly currentSpeakers$ = new BehaviorSubject<EFProfile[]>([]);

    public originalSponsors: EFProfile[];
    public sponsorsMetaSource = new BehaviorSubject<any>({});
    public sponsorsMetaTotalResults = new BehaviorSubject<number>(0);
    public sponsorsMeta$ = this.sponsorsMetaSource.asObservable();
    public readonly currentSponsors$ = new BehaviorSubject<EFProfile[]>([]);

    public sessionMetaSource = new BehaviorSubject<any>({});
    public sessionMetaTotalResults = new BehaviorSubject<number>(0);
    public sessionsMeta$ = this.sessionMetaSource.asObservable();
    public readonly currentSessions$ = new BehaviorSubject<EFSession[]>([]);

    public trackMetaSource = new BehaviorSubject<any>({});
    public trackMetaTotalResults = new BehaviorSubject<number>(0);
    public tracksMeta$ = this.trackMetaSource.asObservable();
    public readonly currentTracks$ = new BehaviorSubject<Track[]>([]);

    public isLoadingSpeakers: boolean = false;
    public isLoadingSessions: boolean = false;
    public isLoadingTracks: boolean = false;
    public isLoadingSponsors: boolean = false;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get betaEnabledForPool(): boolean {
        return this.eventFarmService.betaEnabledForPool;
    }

    public clearList() {
        this.clearSpeakers();
        this.clearSponsors();
        this.clearSesssions();
        this.clearTracks();
    }

    public clearSpeakers() {
        this.originalSpeakers = [];
        this.currentSpeakers$.next([]);
        this.speakersMetaSource.next(null);
        this.speakersListOptions.pagination.currentPage = 1;
        this.speakersListOptions.sortBy = 'name';
        this.speakersListOptions.sortDirection = 'ascending';
        this.speakersListOptions.query = '';
    }

    public clearSponsors() {
        this.originalSponsors = [];
        this.currentSponsors$.next([]);
        this.sponsorsMetaSource.next(null);
        this.sponsorsListOptions.pagination.currentPage = 1;
        this.sponsorsListOptions.query = '';
        this.sponsorsListOptions.sortBy = 'company';
        this.sponsorsListOptions.sortDirection = 'ascending';
    }

    public clearSesssions() {
        this.currentSessions$.next([]);
        this.sessionMetaSource.next(null);
        this.sessionsListOptions.pagination.currentPage = 1;
        this.sessionsListOptions.query = '';
        this.sessionsListOptions.sortBy = 'name';
        this.sessionsListOptions.sortDirection = 'ascending';
    }

    public clearTracks() {
        this.currentTracks$.next([]);
        this.trackMetaSource.next(null);
        this.tracksListOptions.pagination.currentPage = 1;
        this.tracksListOptions.query = '';
        this.tracksListOptions.sortBy = 'name';
        this.tracksListOptions.sortDirection = 'ascending';
    }

    public async getProfiles(isSpeakers: boolean, sessionId?: string|null, original?: boolean|null) {
        const eventId = this.eventFarmService.currentEvent.id;
        let options: EventProfileOptions;

        if (isSpeakers) {
            this.isLoadingSpeakers = true;
            options = this.speakersListOptions;
        } else {
            this.isLoadingSponsors = true;
            options = this.sponsorsListOptions;
        }
        
        try {
            const profiles = await this.apiClient.getUseCaseFactory()
                .Profile()
                .ListProfilesForEvent(
                    (sessionId) ? sessionId : eventId,
                    options.withData,
                    (sessionId) ? 1 : options.pagination.currentPage,
                    (sessionId) ? 50 : options.pagination.itemsPerPage,
                    options.sortBy,
                    options.sortDirection,
                    options.query,
                    options.shouldHideDeleted,
                    options.eventProfileFilter
                ).toPromise();
            if (sessionId) {
                return profiles.data.map(profile => EFProfile.fromApiResponse(profile));
            } else {
                if (isSpeakers) {
                    this.speakersMetaSource.next(profiles.meta);
                    this.speakersMetaTotalResults.next(profiles.meta.totalResults);
                    const e = profiles.data.map(profile => EFProfile.fromApiResponse(profile));
                    this.currentSpeakers$.next(e);
                    (original) ? this.originalSpeakers = this.currentSpeakers$.value : null;
                    this.isLoadingSpeakers = false;
                } else {
                    this.sponsorsMetaSource.next(profiles.meta);
                    this.sponsorsMetaTotalResults.next(profiles.meta.totalResults);
                    const e = profiles.data.map(profile => EFProfile.fromApiResponse(profile));
                    this.currentSponsors$.next(e);
                    (original) ? this.originalSponsors = this.currentSponsors$.value : null;
                    this.isLoadingSponsors = false;
                }
            }
            return true;
        } catch (err) {
            this.message.error('Please try again');
            if (isSpeakers) {
                this.isLoadingSpeakers = false;
            } else {
                this.isLoadingSponsors = false;
            }
            return false;
        }
    }


    public async getAllProfiles(isSpeakers: boolean): Promise<EFProfile[]> {
        const eventId = this.eventFarmService.currentEvent.id;
        let options: EventProfileOptions;

        if (isSpeakers) {
            options = this.speakersListOptions;
        } else {
            options = this.sponsorsListOptions;
        }
        
        try {
            const profiles = await this.apiClient.getUseCaseFactory()
                .Profile()
                .ListProfilesForEvent(
                    eventId,
                    options.withData,
                    1,
                    500,
                    options.sortBy,
                    options.sortDirection,
                    options.query,
                    options.shouldHideDeleted,
                    options.eventProfileFilter
                ).toPromise();
            return profiles.data.map(profile => EFProfile.fromApiResponse(profile));
        } catch (err) {
            this.message.error('Please try again');
            return [];
        }
    }

    public async createProfile(profile: EFProfile, eventId: string, eventProfileType: string) {
        try {
            const res = await this.apiClient
                .getUseCaseFactory()
                .Profile()
                .CreateProfile(
                    this.eventFarmService.currentTeam.id,
                    (profile.firstName) ? profile.firstName : null,
                    (profile.lastName) ? profile.lastName : null,
                    (profile.company) ? profile.company : null,
                    (profile.emailAddress) ? profile.emailAddress : null,
                    (profile.title) ? profile.title : null,
                    (profile.description) ? profile.description : null,
                    (profile.imageUrl) ? profile.imageUrl : null,
                    _.get(profile, 'profileType.slug', 'person'),
                    (profile.id) ? profile.id : null,
                    eventId,
                    eventProfileType
                ).toPromise();

            const profileId = _.get(res, 'data.command.profileId');
            const createdProfileRes = await this.apiClient.getUseCaseFactory().Profile().GetProfile(profileId).toPromise();
            return EFProfile.fromApiResponse(createdProfileRes.data);
        } catch (err) {
            this.message.error('Please try again');
            return null;
        }
    }

    public async addProfileToEvent(profile: EFProfile, eventId?: string|null) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Profile()
                .AddProfileToEvent(
                    profile.id,
                    (eventId) ? eventId : this.eventFarmService.currentEvent.id,
                    profile.eventProfileSlug
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async addProfilesToEvent(eventId: string, profileEventRequests: EventProfileRequest[]) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Profile()
                .AddProfilesToEvent(
                    eventId,
                    profileEventRequests
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async setProfilesForSession(sessionId: string, profileEventRequests: EventProfileRequest[]) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Event()
                .SetProfilesForSession(
                    sessionId,
                    profileEventRequests
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateSpeaker(currentSpeakerId: string, fieldsToUpdate: UpdatableProfileOptions) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Profile()
                .UpdateProfile(
                    currentSpeakerId,
                    null,
                    fieldsToUpdate.firstName,
                    fieldsToUpdate.lastName,
                    fieldsToUpdate.company,
                    fieldsToUpdate.emailAddress,
                    fieldsToUpdate.title,
                    fieldsToUpdate.description,
                    fieldsToUpdate.imageUrl
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async removeProfile(id: string) {
        try {
            const deletedSpeaker = await this.apiClient.getUseCaseFactory().Profile().RemoveProfile(id).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public uploadProfileImage = async (base64Image) => {
        try {
            const res = await this.apiClient.getUseCaseFactory().PoolImage().CreatePoolImage(this.eventFarmService.currentTeam.id, base64Image, 'profile').toPromise();
            const poolImageId = _.get(res, 'data.command.poolImageId');
            const poolImageRes = await this.apiClient.getUseCaseFactory().PoolImage().GetPoolImage(poolImageId).toPromise();
            return _.get(poolImageRes, 'data.attributes.imageUri');
        } catch (error) {
            this.message.error('Error uploading image');
            return false;
        }
    }
    
    public async getAllSessions(fetchAll?: boolean|null) {
        this.isLoadingSessions = true;
        const parentId = this.eventFarmService.currentEvent.id;
        try {
            const sessions = await this.apiClient.getUseCaseFactory()
                .Event()
                .ListChildrenForEvent(
                    parentId,
                    this.sessionsListOptions.query,
                    ['SessionTracks', 'Stacks', 'TicketTypes', 'Venue'],
                    (fetchAll) ? 1 : this.sessionsListOptions.pagination.currentPage,
                    (fetchAll) ? 500 : this.sessionsListOptions.pagination.itemsPerPage,
                    this.sessionsListOptions.sortBy,
                    this.sessionsListOptions.sortDirection,
                    null,
                    null,
                    null,
                    null,
                    this.sessionsListOptions.attributesExcludeFilter
                ).toPromise();
            this.sessionMetaSource.next(sessions.meta);
            this.sessionMetaTotalResults.next(sessions.meta.totalResults);
            const e = sessions.data.map(session => EFSession.fromApiResponse(session));
            this.currentSessions$.next(e);
            this.isLoadingSessions = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingSessions = false;
            return false;
        }
    }

    public async createSession(session: CreateSessionOptions, capacity: number, trackIds: string[], profiles?: EFProfile[], venueId?: string|null) {
        try {
            const createdEvent = await this.apiClient
                .getUseCaseFactory()
                .Event()
                .CreateSessionEvent(
                    this.eventFarmService.currentTeam.id,
                    this.eventFarmService.currentUser.id,
                    session.variant,
                    session.name,
                    this.eventFarmService.currentEvent.id,
                    session.description,
                    capacity ? capacity : 0,
                    session.startTime,
                    session.endTime,
                    this.eventFarmService.currentEvent.startTime.timezone,
                    venueId
                ).toPromise();
            const id: string = createdEvent.data.command.eventId;

            if (profiles.length) {
                const eventProfileRequest: EventProfileRequest[] = profiles.map(profile => {
                    return {
                        'profileId': profile.id,
                        'eventProfileType': profile.eventProfileSlug
                    }
                })
                
                await this.setProfilesForSession(id, eventProfileRequest);
            }

            await this.setTracksForSession(id, trackIds);

            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateSession(id: string, session: UpdatableSessionOptions, trackIds: string[], profiles?: EFProfile[]) {
        try {

            await this.apiClient.getUseCaseFactory().Event().UpdateSessionEvent(
                id,
                session.variant,
                session.name,
                session.startTime,
                session.endTime,
                this.eventFarmService.currentEvent.startTime.timezone,
                session.capacity,
                session.description
            ).toPromise()

            if (profiles.length) {
                const eventProfileRequest: EventProfileRequest[] = profiles.map(profile => {
                    return {
                        'profileId': profile.id,
                        'eventProfileType': profile.eventProfileSlug
                    }
                })
                

                await this.setProfilesForSession(id, eventProfileRequest);
            }

            await this.setTracksForSession(id, trackIds);

            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async removeSession(id: string) {
        try {
            const deletedSpeaker = await this.apiClient.getUseCaseFactory().Event().ArchiveEvent(
                id
            ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async setEventProfileType(eventProfileId: string, speakerType: string) {
        await this.apiClient.getUseCaseFactory().Profile().UpdateEventProfileType(eventProfileId, speakerType).toPromise();
    }

    public async removeProfileFromEvent(profileId: string, eventId: string) {
        await this.apiClient.getUseCaseFactory().Profile().RemoveProfileFromEvent(profileId, eventId).toPromise();
        this.message.success('Speaker removed');
    }

    public async getAllTracks(fetchAll?: boolean|null) {
        this.isLoadingTracks = true;
        const eventId = this.eventFarmService.currentEvent.id;
        try {
            const tracks = await this.apiClient.getUseCaseFactory()
                .Track()
                .ListTracksForEvent(
                    eventId,
                    this.tracksListOptions.withData,
                    this.tracksListOptions.sortBy,
                    this.tracksListOptions.sortDirection,
                    (fetchAll) ? 1 : this.tracksListOptions.pagination.currentPage,
                    (fetchAll) ? 100 : this.tracksListOptions.pagination.itemsPerPage,
                    this.tracksListOptions.query
                ).toPromise();
            this.trackMetaSource.next(tracks.meta);
            this.trackMetaTotalResults.next(tracks.meta.totalResults);
            const e = tracks.data.map(track => Track.fromApiResponse(track));
            this.currentTracks$.next(e);
            this.isLoadingTracks = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingTracks = false;
            return false;
        }
    }

    public async createTrack(track: Track, sessionsIds: string[], ticketTypeIds: string[]) {
        try {
            const createdTrack = await this.apiClient
                .getUseCaseFactory()
                .Track()
                .CreateTrack(
                    this.eventFarmService.currentEvent.id,
                    track.name.trim(),
                    track.description.trim(),
                ).toPromise();

            const id: string = createdTrack.data.command.trackId;
            await this.setSessionsForTrack(id, sessionsIds);
            await this.setTicketTypesForTrack(id, ticketTypeIds);
            
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateTrack(id: string, track: UpdatableTrackOptions, sessionsIds: string[], ticketTypeIds: string[]) {
        try {
            if (track.name) {
                await this.apiClient.getUseCaseFactory().Track().SetTrackName(id, track.name).toPromise();
            }

            if (track.description) {
                await this.apiClient.getUseCaseFactory().Track().SetTrackDescription(id, track.description).toPromise();
            }

            await this.setSessionsForTrack(id, sessionsIds);
            await this.setTicketTypesForTrack(id, ticketTypeIds);

            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async setTicketTypesForTrack(trackId: string, ticketTypeIds: string[]) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Track()
                .SetTicketTypesForTrack(
                    trackId,
                    ticketTypeIds
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async setSessionsForTrack(trackId: string, sessionIds: string[]) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Track()
                .SetSessionsForTrack(
                    trackId,
                    sessionIds
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async setTracksForSession(sessionId: string, tracksIds: string[]) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .Event()
                .SetTracksForSession(
                    sessionId,
                    tracksIds
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async removeTrack(id: string) {
        try {
            await this.apiClient.getUseCaseFactory()
                .Track()
                .RemoveTrack(
                    id
                ).toPromise();            
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async createVenue(name: string, address1: string, address2: string, city: string, state: string, postal: string) {
        const res = await this.apiClient
        .getUseCaseFactory()
        .Venue()
        .CreateVenue(this.eventFarmService.currentTeam.id,
            name, 'physical', address1, address2, city, null, state, null, postal).toPromise();
        
        return res.data.command.venueId;
    }

    public async setVenueForSession(venueId: string, sessionId: string) {
        try {
            await this.apiClient
            .getUseCaseFactory()
            .Event()
            .AddVenueToEvent(sessionId,
                venueId).toPromise();
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }        
    }

    public async updateVenue(venueId: string, name: string, address1: string, address2: string, city: string, state: string, postal: string) {
        try {
            await this.apiClient
            .getUseCaseFactory()
            .Venue()
            .UpdateVenue(
                venueId,
                this.eventFarmService.currentTeam.id, 
                name, 
                'physical',
                address1,
                address2,
                city,
                null,
                state,
                null,
                postal
            ).toPromise();
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }


    public resetEfProfileListOptions() {
        this.speakersListOptions.query = '';
        this.speakersListOptions.pagination.currentPage = 1;
        this.sponsorsListOptions.query = '';
        this.sponsorsListOptions.pagination.currentPage = 1;
    }


    // profile links

    public async updateProfileLinks(formgroup: FormGroup, profile: EFProfile) {
        let website: string = formgroup.get('website').value;
        website = website.trim();
        let linkedIn: string = formgroup.get('linkedIn').value;
        linkedIn = linkedIn.trim();
        let twitter: string = formgroup.get('twitter').value;
        twitter = twitter.trim();
        let facebook: string = formgroup.get('facebook').value;
        facebook = facebook.trim();
        let instagram: string = formgroup.get('instagram').value;
        instagram = instagram.trim();

        const links: ProfileLinkAction[] = [
            this.createProfileLinkAction(profile, website, new Link().LinkType().find(l => l.isWeb)),
            this.createProfileLinkAction(profile, linkedIn, new Link().LinkType().find(l => l.isLinkedIn)),
            this.createProfileLinkAction(profile, twitter, new Link().LinkType().find(l => l.isTwitter)),
            this.createProfileLinkAction(profile, facebook, new Link().LinkType().find(l => l.isFacebook)),
            this.createProfileLinkAction(profile, instagram, new Link().LinkType().find(l => l.isInstagram))
        ];

        const idsToAdd: string[] = [];
        const idsToRemove: string[] = [];

        for (let i = 0; i < links.length; i++) {
            const pl = links[i];
            if (pl.action === LinkAction.ADD) {
                await this.apiClient.getUseCaseFactory().Link().CreateLink(this.eventFarmService.currentTeam.id,
                    pl.value, null, pl.type.slug, pl.linkId).toPromise();
                idsToAdd.push(pl.linkId);
            } else if (pl.action === LinkAction.UPDATE) {
                await this.apiClient.getUseCaseFactory().Link().UpdateLink(pl.linkId, this.eventFarmService.currentTeam.id,
                    pl.value).toPromise();
            } else if (pl.action === LinkAction.DELETE) {
                await this.apiClient.getUseCaseFactory().Link().RemoveLink(pl.linkId).toPromise();
                idsToRemove.push(pl.linkId);
            } 
        }

        if (idsToAdd.length) {
            await this.apiClient.getUseCaseFactory().Link().AddLinksToProfile(idsToAdd,
                profile.id).toPromise();
        }

        if (idsToRemove.length) {
            await this.apiClient.getUseCaseFactory().Link().RemoveLinksFromProfile(idsToRemove,
                profile.id).toPromise();
        }
    }

    private createProfileLinkAction(profile: EFProfile, value: string, type: LinkTypeInterface): ProfileLinkAction {

        const pl = profile.profileLinks.find(pl => pl.link.linkType.slug === type.slug)

        if (pl === undefined) {
            if (value.length) {
                return {
                    value,
                    action: LinkAction.ADD,
                    type,
                    linkId: uuidv4()
                }
            } else {
                return {
                    value,
                    action: LinkAction.NONE,
                    type,
                    linkId: uuidv4()
                }
            }
        } else {
            if (value.length && value !== pl.link.url) {
                return {
                    value,
                    action: LinkAction.UPDATE,
                    type,
                    linkId: pl.link.id
                }
            } else if (value.length && value === pl.link.url) {
                return {
                    value,
                    action: LinkAction.NONE,
                    type,
                    linkId: pl.link.id
                }
            } else {
                return {
                    value,
                    action: LinkAction.DELETE,
                    type,
                    linkId: pl.link.id
                }
            }
        }
    }
}

export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
}

export interface TracksListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
    withData: string[];
}

export interface EventProfileOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
    shouldHideDeleted: boolean;
    withData: string[];
    eventProfileFilter: string[];
}

export interface SessionsListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
    attributesExcludeFilter: string[];
}

export interface EventProfileRequest {
    profileId: string;
    eventProfileType: string;
}