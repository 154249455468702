import { Component, NgZone, Input, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { EventFarmService } from '../eventFarm.service';
import { LegacyUIService } from './legacy-UI.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';

@Component({
    selector: 'legacy-ui',
    template: require('./legacy-UI-element.html'),
    encapsulation: ViewEncapsulation.None
})

export class LegacyUIElementComponent implements OnInit, OnDestroy {
    constructor(
        private eventFarmService: EventFarmService,
        private legacyUIService: LegacyUIService,
        private store: Store<fromRoot.AppState>,
        private zone: NgZone
    ) {
    }
    @Input() outputDomId;
    @Input() urlToLoad;
    @Input() isIframe;
    private loading;

    ngOnInit() {
        this.loading = false;
        if (this.isIframe !== true && this.isIframe !== 'true') {
            this.isIframe = false;
        }
        if (!this.outputDomId) {
            this.outputDomId = 'core-containment-element';
        }
        if (!this.isIframe) {
            this.fetchLegacyUI();
        }
    }

    ngOnChanges(changes: any): void {
        if (!this.isIframe && this.eventFarmService.currentEvent) {
            this.fetchLegacyUI();
        }
    }

    ngOnDestroy() {
    }

    async fetchLegacyUI() {
        if (this.loading || !this.urlToLoad) {
            return;
        }
        this.loading = true;
        this.legacyUIService.fetchAndCompileTemplateContents(this.urlToLoad, this.outputDomId, this.eventFarmService.currentEvent)
            .then((res) => {})
            .catch((err) => err)
            .then((res) => {
                this.loading = false;
            });
    }
}
