import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Communication, InvitationStatusValue, SendByValue} from '../../../../../ApiClient/Models/Communication';
import {EventFarmService} from '../../../../eventFarm.service';
import {SMSCreateService} from '../sms-create.service';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'sms-create-recipients',
    template: require('./sms-create-recipients.html'),
    styles: [require('./sms-create-recipients.scss')]
})

export class SMSCreateRecipientsComponent implements OnInit, OnDestroy {
    @Output() onValidityChange = new EventEmitter();

    smsRecipientsForm: any;
    smsRecipientsFormStatusSub$: Subscription;
    smsRecipientsFormValuesSub$: Subscription;
    smsRecipientsFormSendBySub$: Subscription;

    constructor(
        private eventFarmService: EventFarmService,
        private formBuilder: FormBuilder,
        private smsCreateService: SMSCreateService

    ) {}

        // Filter out "preview" option
     sendByOptions = Communication.sendByOptions.filter(opt => opt.value !== 'preview');
     invitationStatusOptions = Communication.invitationStatusTypeOptions;
     ticketTypes = this.eventFarmService.currentEvent.ticketTypes




    ngOnInit(): void {
        this.initForm();
        this.initFieldSubs()

        // Emit this so parent can adjust "next" button appropriately
        this.onValidityChange.emit(this.smsRecipientsForm.valid);
        this.smsRecipientsFormStatusSub$ = this.smsRecipientsForm.statusChanges.subscribe(
            result => this.onValidityChange.emit(this.smsRecipientsForm.valid)
        );
    }


    // Initialize form
    private initForm() {
        this.smsRecipientsForm = this.formBuilder.group({
            sendBy: new FormControl(this.smsCreateService.recipientConfig.sendBy),
            invitations: new FormControl(this.smsCreateService.recipientConfig.invitations),
            invitationStatus: new FormControl(this.smsCreateService.recipientConfig.invitationStatus),
            group: new FormControl(this.smsCreateService.recipientConfig.group?.label),
            ticketBlock: new FormControl(this.smsCreateService.recipientConfig.ticketBlock?.label),
            ticketTypes: new FormControl(this.smsCreateService.recipientConfig.ticketTypes),
            whatsAppEnabled: new FormControl(this.smsCreateService.recipientConfig.whatsAppEnabled),

        });
        // Initialize validators
        this.modifyValidators(this.smsCreateService.recipientConfig.sendBy);
    }

    // Establish field subscriptions
    private initFieldSubs(){
        this.smsRecipientsFormValuesSub$ = this.smsRecipientsForm.valueChanges.subscribe(data => {
            this.smsCreateService.recipientConfig = data;
        });
        // Store value changes in our SMS Create service
        this.smsRecipientsFormSendBySub$ = this.smsRecipientsForm.controls.sendBy.valueChanges.subscribe(data => {
            // We need to adjust which fields are required based on sendBy selection
            this.modifyValidators(data);
        });
    }

    // Handle conditional validation based on sendBy selection
    private  modifyValidators(sendBy: SendByValue){
         // Start by resetting all conditional validation
        this.clearConditionalValidation();
        switch (sendBy) {
            case 'invitations':
                this.smsRecipientsForm.controls['invitations'].setValidators([
                    Validators.required
                ]);
                break;
            case 'ticket-block':
                this.smsRecipientsForm.controls['ticketBlock'].setValidators([
                    Validators.required
                ]);
                break;
             case 'invitation-status':
                 this.smsRecipientsForm.controls['invitationStatus'].setValidators([
                     Validators.required
                 ]);
                 this.smsRecipientsForm.controls['ticketTypes'].setValidators([
                     Validators.required
                 ]);
                 break;
             case 'group':
                 this.smsRecipientsForm.controls['group'].setValidators([
                     Validators.required
                 ]);
                 break;
         }

        this.updateValuesAndValidity();
    }

    private clearConditionalValidation(){
        this.smsRecipientsForm.controls['invitationStatus'].clearValidators();
        this.smsRecipientsForm.controls['invitations'].clearValidators();
        this.smsRecipientsForm.controls['ticketTypes'].clearValidators();
        this.smsRecipientsForm.controls['ticketBlock'].clearValidators();
        this.smsRecipientsForm.controls['group'].clearValidators();
    }

    private updateValuesAndValidity(){
        this.smsRecipientsForm.controls['invitationStatus'].updateValueAndValidity();
        this.smsRecipientsForm.controls['invitations'].updateValueAndValidity();
        this.smsRecipientsForm.controls['ticketTypes'].updateValueAndValidity();
        this.smsRecipientsForm.controls['ticketBlock'].updateValueAndValidity();
        this.smsRecipientsForm.controls['group'].updateValueAndValidity();

    }
    async handleGroupInputChange($event: Event) {
        const input  = $event.target as HTMLInputElement;
        await this.smsCreateService.searchForGroup(input.value);
    }

    async handleTicketBlockInputChange($event: Event) {
        const input  = $event.target as HTMLInputElement;
        await this.smsCreateService.searchForTicketBlock(input.value);
    }

    ngOnDestroy(): void {
         this.smsRecipientsFormValuesSub$.unsubscribe();
         this.smsRecipientsFormStatusSub$.unsubscribe();
         this.smsRecipientsFormSendBySub$.unsubscribe();
    }

    get helperText() {
         return Communication.getSendByHelperTextFromValue(this.smsCreateService.recipientConfig.sendBy)
    }

    hasError($event: boolean) {

    }

    getNumber($event: any) {

    }

    telInputObject($event: any) {

    }

    onCountryChange($event: any) {

    }
}
