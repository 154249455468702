import { AbstractModel } from '../abstract-model';
import { QueueCommandMessageLevelTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Queue';

export class QueueCommandMessage extends AbstractModel {
    public static fromApiResponse(data) {
        const queueCommandMessage = new this(data.id);

        if (data.meta.isInitialized) {
            queueCommandMessage._levelType = data.attributes.levelType;
            queueCommandMessage._message = data.attributes.message;
            queueCommandMessage._createdAt = data.attributes.createdAt;
        }

        return queueCommandMessage;
    }

    private _levelType: QueueCommandMessageLevelTypeInterface;
    private _message: string;
    private _createdAt: number;

    get message(): string {
        return this._message;
    }

    get createdAt(): number {
        return this._createdAt;
    }
}
