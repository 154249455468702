import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploaderComponent } from './uploader.component';
import { ChooseFileContainerComponent } from './ChooseFile/chooseFileContainer.component';
import { PreviewUploadContainerComponent } from './PreviewUpload/previewUploadContainer.component';
import { ProcessingContainerComponent } from './Processing/processingContainer.component';
import { FinishContainerComponent } from './Finish/finishContainer.component';

const routes: Routes = [
    {
        path: '',
        component: UploaderComponent,
        children: [
            {
                path: 'choose',
                component: ChooseFileContainerComponent,
            },
            {
                path: 'preview',
                component: PreviewUploadContainerComponent,
            },
            {
                path: 'processing',
                component: ProcessingContainerComponent,
            },
            {
                path: 'finish',
                component: FinishContainerComponent,
            },
            {
                path: '**',
                redirectTo: 'choose',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class UploaderRoutingModule {
}
