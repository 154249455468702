import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { InvitationOptionsModule } from '../../CoreUI/InvitationOptions/invitationOptions.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { UploaderRoutingModule } from './uploader-routing.module';
import { UploaderComponent } from './uploader.component';
import { UploaderTabsComponent } from './uploaderTabs.component';
import { InvitationsFoundInFileComponent } from './PreviewUpload/invitationsFoundInFile.component';
import { PreviewUploadService } from './PreviewUpload/previewUpload.service';
import { FinishService } from './Finish/finish.service';
import { ProcessingService } from './Processing/processing.service';
import { GroupTypeaheadModule } from '../../CoreUI/Typeahead/group-typeahead.module';
import {UploaderSharedModule} from './uploader-shared.module';
import {InvitationOptionsService} from '../../CoreUI/InvitationOptions/invitationOptions.service';
import {UploaderService} from './uploader.service';
import {EventFarmService} from '../../eventFarm.service';
import {UploaderTabsService} from './uploaderTabs.service';
import {ChooseFileService} from './ChooseFile/chooseFile.service';
import {RouteGeneratorService} from '../../../_services/routes/route-generator.service';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [UploaderComponent],
    declarations: [
        UploaderComponent,
        UploaderTabsComponent,
        InvitationsFoundInFileComponent,
    ],
    entryComponents: [
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        InvitationOptionsModule,
        GroupTypeaheadModule,
        UtilitiesModule,
        BreadcrumbsModule,
        UploaderRoutingModule,
        UploaderSharedModule,
        NzUtilitiesModule,
        NgxUtilitiesModule
    ],
    providers: [
        ProcessingService,
        PreviewUploadService,
        FinishService,
        PreviewUploadService,
        InvitationOptionsService,
        UploaderService,
        EventFarmService,
        UploaderTabsService,
        ChooseFileService,
        RouteGeneratorService,
    ],
    exports: [
    ]
})

export class UploaderModule {
}
