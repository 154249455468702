import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentGuestQuestionsTracking extends AbstractBaseSegment {

    edit() {
        return this.track({
                event: actions.EDITING_GUEST_QUESTIONS
            }
        );
    }

    preview() {
        return this.track({
                event: actions.PREVIEWING_GUEST_QUESTIONS
            }
        );
    }

    addNew() {
        return this.track({
                event: actions.ADDING_NEW_GUEST_QUESTION
            }
        );
    }
}
