import * as _ from "lodash";
import {EventFarmService} from '../../../eventFarm.service';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store, Action} from '@ngrx/store';
import * as fromStore from '../../app.state';
import * as eventActions from '../../actions/event';

import {mergeMap, switchMap, withLatestFrom, map, tap, filter, take, shareReplay} from 'rxjs/operators';
import {EfEvent} from '../../../../ApiClient/Models/Event/event';
import {of} from 'rxjs';
import {LocalStorageService} from '../../../../_services/storage/local-storage.service';

@Injectable()
export class EventEffects {

    constructor(
        private actions$: Actions,
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>,
        private localStorageService: LocalStorageService
    ) {
    }


    fetchCurrentEvent$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchCurrentEvent>(eventActions.FETCH_CURRENT_EVENT),
        withLatestFrom(this.store),
        filter(([user, store]) => store.router.navigationId > 0),
        switchMap(([action, store]) => this.apiClient.getUseCaseFactory().Event().GetEvent((store.router['state'].params.event || store.router['state'].queryParams.eventId),
            [
                'Tags',
                'EventTexts',
                'Pool',
                'Stacks',
                'StacksWithAvailabilityCounts',
                'TicketTypes',
                'EventTexts',
                'EventTheme',
                'VirbelaWorld',
                'EventLinks',
                'TicketBlocks'
            ])),
        switchMap((event) => of(EfEvent.fromApiResponse(event['data']))),
        withLatestFrom(this.store),
        switchMap(([event, store]) => {
            const actionsToDispatch = [];
            actionsToDispatch.push(new eventActions.FetchCurrentEventSuccess(event))
            actionsToDispatch.push(new eventActions.FetchWaitList())
            actionsToDispatch.push(new eventActions.FetchDesigns())
            actionsToDispatch.push(new eventActions.FetchAccessTypes())
            actionsToDispatch.push(new eventActions.FetchSitePages())
            if (store.event && _.get(store, 'event.access.event-crud') && this.eventFarmService.salesforceEnabled) {
                actionsToDispatch.push(new eventActions.FetchSalesForceIntegrationForEvent())
            }
            return actionsToDispatch;
        }),
    ));


    fetchSalesForceIntegrationForEvent$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchSalesForceIntegrationForEvent>(eventActions.FETCH_SALESFORCE_INTEGRATION_FOR_EVENT),
        withLatestFrom(this.store),
        switchMap(([actions, store]) => this.apiClient.getUseCaseFactory().Salesforce().GetSalesforceStatusForEvent(store.router['state'].params.event || store.router['state'].queryParams.eventId)),
        switchMap(integration => [
            new eventActions.FetchSalesForceIntegrationForEventSuccess(integration)
        ])
    ));


    fetchUserRolesForCurrentEvent$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.FetchCurrentEventSuccess>(eventActions.FETCH_CURRENT_EVENT_SUCCESS),
        switchMap((action) => {
            return this.eventFarmService.fetchEventAccess(action.payload.id, this.localStorageService.get('effectiveEfUserId'));
        }),
        switchMap(roles => [
            new eventActions.FetchUserRolesForCurrentEventSuccess(roles),
        ]),
    ));
}
