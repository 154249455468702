import { Action } from '@ngrx/store';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { EfMeta } from "../../reducers/admin.reducer";

export * from './admin-allotment.action';
export * from './admin-contact.action';
export * from './admin-contract.action';
export * from './admin-create-team.action';
export * from './admin-archive-team.action';
export * from './admin-domain-mask.action';
export * from './admin-feature.action';
export * from './admin-pool-world.action';

export const FETCH_ADMIN_TEAM = '[Admin/Team] Fetch Team';
export const FETCH_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Team Success';
export const FETCH_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Team Fail';

export const SET_CURRENT_ADMIN_TEAM = '[Admin/Team] Set Current Team';
export const SET_CURRENT_ADMIN_TEAM_SUCCESS = '[Admin/Team] Set Current Team Success';
export const SET_CURRENT_ADMIN_TEAM_FAIL = '[Admin/Team] Set Current Team Fail';

export const FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Web Conference Connections For Team';
export const FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Web Conference Connections For Team Success';
export const FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Web Conference Connections For Team Fail';

export const FETCH_EVENTS_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Events For Current Team';
export const FETCH_EVENTS_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Events For Current Team Fail';
export const FETCH_EVENTS_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Events For Current Team Success';

export class FetchAdminTeam implements Action {
    readonly type = FETCH_ADMIN_TEAM;
    constructor(public payload: any) { }
}

export class FetchAdminTeamSuccess implements Action {
    readonly type = FETCH_ADMIN_TEAM_SUCCESS;
    constructor(public payload: any) { }
}

export class FetchAdminTeamFail implements Action {
    readonly type = FETCH_ADMIN_TEAM_FAIL;
    constructor(public payload: any) { }
}

export class SetCurrentAdminTeam implements Action {
    readonly type = SET_CURRENT_ADMIN_TEAM;
    constructor(public payload: any) { }
}

export class SetCurrentAdminTeamSuccess implements Action {
    readonly type = SET_CURRENT_ADMIN_TEAM_SUCCESS;
    constructor(public payload: any) { }
}

export class SetCurrentAdminTeamFail implements Action {
    readonly type = SET_CURRENT_ADMIN_TEAM_FAIL;
    constructor(public payload: any) { }
}

export class FetchEventsForAdminTeam implements Action {
    readonly type = FETCH_EVENTS_FOR_ADMIN_TEAM;
    constructor(public payload: {name?: string, currentPage: number, itemsPerPage: number}) { }
}

export class FetchEventsForAdminTeamSuccess implements Action {
    readonly type = FETCH_EVENTS_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: { meta: EfMeta, data: EfEvent[] }) { }
}

export class FetchEventsForAdminTeamFail implements Action {
    readonly type = FETCH_EVENTS_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}


export class FetchWebConferenceConnectionsForAdminTeam implements Action {
    readonly type = FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_ADMIN_TEAM;
    constructor(public payload: {}) { }
}

export class FetchWebConferenceConnectionsForAdminTeamSuccess implements Action {
    readonly type = FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: {}) { }
}

export class FetchWebConferenceConnectionsForAdminTeamFail implements Action {
    readonly type = FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export type AdminAction =
    SetCurrentAdminTeam |
    SetCurrentAdminTeamSuccess |
    SetCurrentAdminTeamFail |
    FetchAdminTeam |
    FetchAdminTeamSuccess |
    FetchAdminTeamFail |
    FetchEventsForAdminTeam |
    FetchEventsForAdminTeamSuccess |
    FetchEventsForAdminTeamFail |
    FetchWebConferenceConnectionsForAdminTeam |
    FetchWebConferenceConnectionsForAdminTeamSuccess |
    FetchWebConferenceConnectionsForAdminTeamFail;

