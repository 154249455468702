import { filter, shareReplay } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { User } from '../../../ApiClient/Models/User/user';
import { ActivatedRoute, Router } from '@angular/router';
import { IconService } from '../Icons/icons.service';
import { EventFarmService } from '../../eventFarm.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import * as eventActions from './../../store/actions/event';
import { LocalStorageService } from '../../../_services/storage/local-storage.service';
import { NzDrawerPlacement, NzDrawerService } from 'ng-zorro-antd/drawer';
import { SideNavHamburgerComponent } from './sideNavHamburger/sideNavHamburger.component';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
    selector: 'side-nav',
    template: require('./sideNav.html'),
    styles: [require('./sideNav.scss')],
})

export class SideNavComponent implements OnInit, OnDestroy {
    public isEventRoute: boolean;
    private userLoaded: boolean;
    private isTeamRoute: boolean;

    public currentActiveSection: 'event-configuration' | 'registration-experience' | 'email-communication' | 'guest-management' | 'event-reporting' | 'events-list' | 'event-efx' | 'event-exhibitors' | 'event-agenda';
    private toggledSections: SideNavOptions;
    private displaySideNav: boolean;
    private user: User;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventStoreData: EfEvent;
    private eventRoleType: any;
    private eventRoleUpdate$: Subscription;
    private teamUpdate$: Subscription;
    private userUpdate$: Subscription;
    private isWaitlistShown: boolean = false;
    public navBarActive: boolean = false;
    public width = '80% !important';
    public drawerTitle: string = 'Menu Items';
    public placement: NzDrawerPlacement = 'left';

    constructor(
        private store: Store<fromRoot.AppState>,
        private efs: EventFarmService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private drawerService: NzDrawerService,
        public route: ActivatedRoute,
        public iconService: IconService,
        public localStorageService: LocalStorageService,
        private drawerRef: NzDrawerRef
    ) {

        this.eventRoleUpdate$ = this.eventRoleType$.subscribe(res => this.eventRoleType = res);

        this.store.select('router').pipe(
            filter(res => Object.keys(res).length !== 0 && Object.keys(res.state.params).length !== 0),
        ).subscribe(rt => {
            this.isEventRoute = false;
            this.isTeamRoute = false;
            this.displaySideNav = true;
            this.resetSections();
            this.checkRoute();
            if (rt.state.queryParams.ticketBlockId) {
                this.displaySideNav = false;
            }
            if (rt.state.params.pool) {
                this.isTeamRoute = true;
            }
            if (rt.state.params.event || rt.state.queryParams.eventId) {
                this.isEventRoute = true;
            }
        });

    }

    ngOnInit() {
        this.isEventRoute = false;
        this.userLoaded = false;
        this.isTeamRoute = false;
        this.displaySideNav = true;
        this.currentActiveSection = 'events-list';
        this.resetSections();

        if (this.efs.currentUser && this.efs.currentUser.id) {
            this.userLoaded = true;
            this.user = this.efs.currentUser;
        }

        this.checkRoute();
        this.localStorageService.linkClicked.subscribe((res) => {
           if (res === true) {
               //this.drawerRef is empty object on desktop
               if (this.drawerRef && Object.keys(this.drawerRef).length !== 0 ) { this.drawerRef.close(); }
           }
        });

        this.localStorageService.resetNavBar.subscribe((reseNav) => {
            if (reseNav === true) {
                this.displaySideNav = true;
            }
        });
    }

    public openComponent(): void {
        this.checkRoute();
        this.drawerRef = this.drawerService.create<SideNavHamburgerComponent, { currentActiveSection, isEventRoute, isMobile }, string>({
            nzContent: SideNavHamburgerComponent,
            nzWidth: this.width,
            nzContentParams: {
                currentActiveSection: this.currentActiveSection,
                isEventRoute: this.isEventRoute,
                isMobile: true
            },
            nzPlacement: this.placement,
            nzBodyStyle: {padding: 0, color: '#939599'}
        });

        this.drawerRef.afterOpen.subscribe(() => {
        });

        this.drawerRef.afterClose.subscribe(data => {

        });
    }

    resetSections() {
        this.toggledSections = {
            'event-configuration': false,
            'registration-experience': false,
            'email-communication': false,
            'guest-management': false,
            'event-reporting': false,
            'event-efx': false,
            'event-exhibitors': false
        };
    }

    checkRoute() {
        if (!this.efs.currentTeam) {
            return;
        }

        if (this.router.url.includes('wait-list')) {
            this.isWaitlistShown = true;
        } else {
            if (this.isWaitlistShown === true) {
                this.store.dispatch(new eventActions.FetchWaitList({query: '', sortBy: 'created-at'}));
                this.isWaitlistShown = false;
            }
        }

        if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.info', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.event-access', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.child-events', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.settings', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-configuration';
            this.toggledSections['event-configuration'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.web-presence', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.questions', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.promotions', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.payments-promos', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.web-form', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            ) {
            this.currentActiveSection = 'registration-experience';
            this.toggledSections['registration-experience'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.edc', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'email-communication';
            this.toggledSections['email-communication'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.guest-list', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.wait-list', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.integrations', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'guest-management';
            this.toggledSections['guest-management'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.legacy', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.email-stats', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.message-stats', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.v2', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-reporting';
            this.toggledSections['event-reporting'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.efx.config', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.efx.station', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-efx';
            this.toggledSections['event-efx'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.exhibitors', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
             this.currentActiveSection = 'event-exhibitors';
             this.toggledSections['event-exhibitors'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.speakers', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
        || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.sessions', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
        || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.tracks', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
        ) {
            this.currentActiveSection = 'event-agenda';
            this.toggledSections['event-agenda'] = true;
        } else {
            this.currentActiveSection = 'events-list';
        }
    }

    ngOnDestroy() {
        this.teamUpdate$.unsubscribe();
        this.userUpdate$.unsubscribe();
        this.eventRoleUpdate$.unsubscribe();
    }
}

export interface SideNavOptions {
    'event-configuration': boolean;
    'registration-experience': boolean;
    'email-communication': boolean;
    'guest-management': boolean;
    'event-reporting': boolean;
    'event-efx': boolean;
    'event-exhibitors': boolean;
}
