import { Component, OnInit, Input } from '@angular/core';
import { ReportService } from '../report.service';
import { ReportType, ReportTypes, ReportTypeEnum } from '../Models/report-models';
import * as fromRoot from '../../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';


@Component({
    selector: 'report-type-box',
    template: require('./report-type-box.html'),
    styles: [require('./report-type-box.scss')],
})

/**
 * Shows all the reports for the given type
 */
export class ReportTypeBoxComponent {

    @Input() reportTypes: ReportTypes;

    constructor(
        private reportService: ReportService,
        private store: Store<fromRoot.AppState>,
    ) { }

    private currentSelected: ReportTypeEnum;
    private reportName: string;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    get currentReportType(): ReportType {
        return this.currentSelected ? this.reportTypes.types.filter(type => type.slug === this.currentSelected)[0] : null;
    }

    get types(): ReportType[] {

        let types = this.reportTypes.types;

        if (this.reportService.isCIO) {
            types = types.filter(type => type.showCIO === true);
        } 

        if (!this.reportService.smsEnabled) {
            types = types.filter(type => type.slug !== ReportTypeEnum.COMMS_SENT_SMS);
        }


        return types;
    }

    allowGenerate() {
        return (typeof this.currentSelected !== 'undefined' && this.reportName.length);
    }

    generateReport() {
        this.reportService.generateReport(this.currentSelected, this.reportName);
    }

    update() {
        const currentDate = new Date();
        this.reportName = `${this.currentReportType.standardReportName}${currentDate.toDateString()}`;
    }

}
