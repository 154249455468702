import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';

import { EFSession } from '../../../../../../ApiClient/Models/Session/EFSession';
import { EventAgendaService } from '../../../event-agenda.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { dateTimeFormat, momentShortDateTime, timePickerDefaults } from '../../../../../../Shared/datetime-options';
import * as moment from 'moment';
import { filter, map } from 'rxjs/operators';
import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import { FormattedDateTime } from '../../../../../../ApiClient/Models/formatted-datetime';
import { Track } from '../../../../../../ApiClient/Models/Track/track';
import * as _ from 'lodash';
import { Event, VariantTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
import { EFProfile } from '../../../../../../ApiClient/Models/Profile/EFProfile';
import { differenceInCalendarDays, setHours } from 'date-fns';
import {DisabledTimeFn, DisabledTimePartial} from 'ng-zorro-antd/date-picker';

@Component({
    selector: 'edit-sessions',
    template: require('./edit-sessions.html'),
    styles: [require('./edit-sessions.scss')]
})
export class EditSessionsComponent implements OnInit {
    @Input() session: EFSession;
    timeDefaultValue = setHours(new Date(), 0);

    private name: FormControl = new FormControl();
    private sessionCapacity: number;
    private startDate: Date;
    private endDate: Date;
    private description: FormControl = new FormControl();
    public isEdit: boolean = false;
    public loading: boolean = true;
    public avatarUrl?: string;
    private isSaving: boolean = false;
    private confirmDelete: boolean = false;
    private isDeleting: boolean = false;
    public base64Image: any;
    public disabledEndDate;
    public disabledEndTime;

    public eventTimezone$ = new BehaviorSubject<string>('America/New_York');
    public timePickerDefaults = timePickerDefaults;
    public dateTimeFormat = dateTimeFormat;
    private parentEventStartTime: FormattedDateTime;
    private parentEventEndTime: FormattedDateTime;

    public speakersToAdd: EFProfile[] = [];
    public activeSpeakers: EFProfile[] = [];
    private isLoadingSessionSpeakers = true;


    public sponsorsToAdd: EFProfile[] = [];
    public activeSponsors: EFProfile[] = [];
    private isLoadingSessionSponsors = true;


    private allowDelete: boolean = true;
    private showAddress = false;
    private shouldDisableCapacity = false;

    private readonly FIFTEENMINUTESTOMILLISECONDS = 900000;

    private sessionCategory: FormControl = new FormControl('session');

    private venueName: FormControl = new FormControl('');
    private venueAddress: FormControl = new FormControl('');
    private venueAddress2: FormControl = new FormControl('');
    private venueCity: FormControl = new FormControl('');
    private venueState: FormControl = new FormControl('');
    private venuePostalCode: FormControl = new FormControl('');

    private selectedTrackIds = [];
    private activeTracks: Track[] = [];

    private categories: VariantTypeInterface[] = new Event().VariantType().filter(vt => vt.isAnySessionType);


    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store<fromRoot.AppState>,
        private eventAgendaService: EventAgendaService,
        private messageService: NzMessageService

    ) {}

    ngOnInit() {
        this.isEdit = !!(this.session);
        this.initStoreSubscriptions();
        this.setInitialFields();
        this.setValidEndDate();
        this.setValidEndTime()
        if (this.session && this.session.id) {
            this.checkIfSpeakersExists();
            this.checkIfSponsorsExists();
            this.checkIfTracksExists();
        } else {
            this.activeSpeakers = [];
            this.isLoadingSessionSpeakers = false;

            this.activeSponsors = [];
            this.isLoadingSessionSponsors = false;
        }
    }

    public async checkIfSpeakersExists() {
        const speakers = await this.eventAgendaService.getProfiles(true, this.session.id);
        this.activeSpeakers = speakers;
        this.isLoadingSessionSpeakers = false;
    }

    public async checkIfSponsorsExists() {
        const sponsors = await this.eventAgendaService.getProfiles(false, this.session.id);
        this.activeSponsors = sponsors;
        this.isLoadingSessionSponsors = false;
    }

    public async checkIfTracksExists() {
        this.selectedTrackIds = this.session.tracks.map(track => track.id);
    }

    private async addSession() {
        if (this.isSaving === false) {
            this.isSaving = true;
            try {
                const session = {
                    name: this.name.value,
                    description: this.description.value,
                    startTime: this.getFormattedTime(this.startDate),
                    endTime: this.getFormattedTime(this.endDate),
                    variant: this.sessionCategory.value
                };

                let venueId = null;

                if (this.shouldCreateVenue()) {
                    venueId = await this.handleVenueCreateOrUpdate();
                }

                await this.eventAgendaService.createSession(session, this.sessionCapacity, this.selectedTrackIds, [...this.speakersToAdd, ...this.sponsorsToAdd], venueId);
                this.eventAgendaService.sessionsListOptions.pagination.currentPage = 1;
                await this.eventAgendaService.getAllSessions();
                this.drawerRef.close();
            } catch (err) {
                this.messageService.error('Error adding session');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private async saveSession() {
        if (this.isSaving === false) {
            this.isSaving = true;

            const variantSlug = this.sessionCategory.value;
            const name: string = this.name.value;
            const description: string = this.description.value;

            // if the value is the same, pass in null so that we dont update
            const fieldsToUpdate = {
                name: name.trim(),
                description: description.trim(),
                startTime: this.getFormattedTime(this.startDate),
                endTime: this.getFormattedTime(this.endDate),
                variant: variantSlug,
                capacity: this.sessionCapacity ? this.sessionCapacity : 0
            };

            try {
                await this.eventAgendaService.updateSession(this.session.id, fieldsToUpdate, this.selectedTrackIds, [...this.speakersToAdd, ...this.sponsorsToAdd]);

                if(this.shouldCreateVenue()) {
                    await this.handleVenueCreateOrUpdate();
                }

                this.messageService.success('Session updated!');
                this.drawerRef.close();
                this.eventAgendaService.getAllSessions();
            } catch (error) {
                this.messageService.error('Error updating session');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private async handleVenueCreateOrUpdate() {
        const lName: string = this.venueName.value;
        const lAddress: string = this.venueAddress.value;
        const lAddress2: string = this.venueAddress2.value;
        const lCity: string = this.venueCity.value;
        const lState: string = this.venueState.value;
        const lPoastalCode: string = this.venuePostalCode.value;

        if (this.isEdit) {
            if (this.session.venue) {
                await this.eventAgendaService.updateVenue(this.session.venue.id, lName, lAddress, lAddress2, lCity, lState, lPoastalCode);
                return this.session.venue.id;
            } else {
                const venueId = await this.eventAgendaService.createVenue(lName, lAddress, lAddress2, lCity, lState, lPoastalCode);
                await this.eventAgendaService.setVenueForSession(venueId, this.session.id);
                return venueId;
            }
        } else {
            const venueId = await this.eventAgendaService.createVenue(lName, lAddress, lAddress2, lCity, lState, lPoastalCode);
            return venueId;
        }
    }

    private async handleDeleteSession() {
        if (this.isDeleting) {
            this.isDeleting = false;
        }
        if (this.confirmDelete) {
            this.isDeleting = true;
            const deleteSuccess = await this.eventAgendaService.removeSession(this.session.id);

            this.isDeleting = false;
            this.confirmDelete = false;

            if (deleteSuccess === true) {
                this.drawerRef.close();
                await this.eventAgendaService.getAllSessions();
            }
        }
        this.confirmDelete = true;
    }

    private allowSave() {
        return (this.name.value !== '' && typeof this.startDate !== 'undefined' && typeof this.endDate !== 'undefined');
    }

    private async setInitialFields() {
        this.isSaving = false;

        if (!this.session) {
            this.session = EFSession.forSessionCreation({
                name: '',
                description: '',
                startTime: {},
                endTime: {},
                capacity: 50
            });
            this.allowDelete = false;
        } else {
            this.allowDelete = true;
        }

        if (!this.session.startTime.date) {
            const start = `${this.parentEventStartTime.date.short} ${this.parentEventStartTime.time.short}`;
            this.startDate = moment(start, momentShortDateTime).toDate();
        } else {
            const start = `${this.session.startTime.date.short} ${this.session.startTime.time.short}`;
            this.startDate = moment(start, momentShortDateTime).toDate();
        }

        if (!this.session.endTime.date) {
            this.endDate = new Date(this.startDate.getTime() + this.FIFTEENMINUTESTOMILLISECONDS);

        } else {
            const close = `${this.session.endTime.date.short} ${this.session.endTime.time.short}`;
            this.endDate = moment(close, momentShortDateTime).toDate();
        }


        if (this.session.venue) {
            const venue = this.session.venue;
            this.venueName.setValue(venue.name ?? '');
            this.venueAddress.setValue(venue.street1 ?? '');
            this.venueAddress2.setValue(venue.street2 ?? '');
            this.venueCity.setValue(venue.city ?? '');
            this.venueState.setValue(venue.province ?? '');
            this.venuePostalCode.setValue(venue.postalCode ?? '');
            this.showAddress = true;
        } else {
            this.showAddress = false;
        }

        this.name.setValue(this.session.name ? this.session.name : '');
        this.description.setValue(this.session.description ? this.session.description : '');


        const currentVariant = _.get(this, 'session.variant.slug', null)

        if (currentVariant !== null) {
            this.sessionCategory.setValue(currentVariant);
            if (!this.session.variant.isBlock) {
                this.sessionCapacity = this.session.capacity;
            } else {
                this.shouldDisableCapacity = true;
            }
        } else {
            this.sessionCategory.setValue('session');
            this.sessionCapacity = this.session.capacity;
        }



        await this.eventAgendaService.getAllTracks(true);
        this.activeTracks = this.eventAgendaService.currentTracks$.value;

        this.sessionCategory.valueChanges.subscribe(cat => {
            if (cat === 'block') {
                this.shouldDisableCapacity = true;
                this.sessionCapacity = null;
            } else {
                this.shouldDisableCapacity = false;
                this.sessionCapacity = this.session.capacity;
            }
        });
    }

    private toggleAddress() {
        this.showAddress = !this.showAddress;
    }

    public saveImageAsBase64(image) {
        this.base64Image = image;
    }

    public updateValidEndDate() {
        if (this.startDate > this.endDate) {
            this.endDate = new Date(this.startDate.getTime() + this.FIFTEENMINUTESTOMILLISECONDS);
        }
        this.setValidEndDate();
        this.setValidEndTime();
    }

    private getFormattedTime(date: Date) {
        return moment(date).format(momentShortDateTime);
    }

    private setValidEndDate() {
        this.disabledEndDate = (current: Date): boolean => {
            const diff =  differenceInCalendarDays(current, this.startDate);
            return diff < 0;
        };
    }

    range(start: number, end: number): number[] {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    disabledDate = (current: Date): boolean => {
        // Can not select days before today and today
       const diff =  differenceInCalendarDays(current, this.startDate) > 0;
       return diff;
    }

    // Basically, we only need to disable times when end date is the same as start date

    // There is an issue where only changing the time in the date picker doesn't get reflected in the model, so we need to explicitly handle
    handleStartOk($event) {
        this.startDate = $event;
    }

    // There is an issue where only changing the time in the date picker doesn't get reflected in the model, so we need to explicitly handle
    handleEndOk($event: Date){
        this.endDate = $event;
    }

    private setValidEndTime() {
        this.disabledEndTime = (current: Date) => {
            const diff = differenceInCalendarDays(current, this.startDate);
            const m = moment(this.startDate);
            const currentMoment = moment(current);

            const hour = m.hour();
            const currentHour = currentMoment.hour();
            const minutes = m.minutes();
            const currentMinutes = currentMoment.minutes();

            return {
                nzDisabledHours: () => {

                    if (diff === 0) {

                        return this.range(0, hour);
                    } else {
                        return this.range(0, 0);
                    }
                },
                nzDisabledMinutes: () => {
                    // If same day as start time
                    if (diff === 0  ) {
                        if (hour === currentHour) { // End time has same day, same hour selected
                            return this.range(0, minutes); // Only minutes after startTime are available
                        } else {
                            return this.range(0, 0); // All minutes available
                        }

                    } else {
                        return this.range(0, 0);
                    }
                },
                nzDisabledSeconds: () => this.range(0, 0),
            };
        };
    }

    private shouldCreateVenue(): boolean {
        const lName: string = this.venueName.value;
        const lAddress: string = this.venueAddress.value;
        const lAddress2: string = this.venueAddress2.value;
        const lCity: string = this.venueCity.value;
        const lState: string = this.venueState.value;
        const lPoastalCode: string = this.venuePostalCode.value;

        return (lName.trim().length + lAddress.trim().length + lAddress2.trim().length + lCity.trim().length + lState.trim().length + lPoastalCode.trim().length) > 0
    }

    close(): void {
        this.drawerRef.close(this.session);
        if (this.eventAgendaService.speakersListOptions.pagination.currentPage !== 1) {
            this.eventAgendaService.speakersListOptions.pagination.currentPage = 1;
            this.eventAgendaService.currentSpeakers$.next(this.eventAgendaService.originalSpeakers);
        }

        if (this.eventAgendaService.sponsorsListOptions.pagination.currentPage !== 1) {
            this.eventAgendaService.sponsorsListOptions.pagination.currentPage = 1;
            this.eventAgendaService.currentSponsors$.next(this.eventAgendaService.originalSponsors);
        }
    }

    private initStoreSubscriptions() {
        this.store.select('event').pipe(
            filter(res => !!res),
            map(res => res.data)
        ).subscribe((res: EfEvent) => {
            if (res) {
                this.eventTimezone$.next(res.timezone);
                this.parentEventStartTime = res.startTime;
                this.parentEventEndTime = res.endTime;
            }
        });
    }

    public setSpeakersForSession(profiles: EFProfile[]) {
        this.speakersToAdd = profiles;
    }

    public setSponsorsForSession(profiles: EFProfile[]) {
        this.sponsorsToAdd = profiles;
    }
}
