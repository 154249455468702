import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

import { of, Observable } from 'rxjs';
import { filter ,  switchMap, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';

@Injectable()
export class CreateEventPermissionsGuard implements CanActivate {

    private teamId;

    constructor(
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<fromRoot.AppState>
    ) {
        this.store.select(fromRoot.getPoolParam).subscribe(res => this.teamId = res);
    }

    canAccessCreateEvent(): Observable<any> {
        return this.store.select(fromRoot.getPoolContactType);
    }

    canActivate(route): Observable<boolean> {
        return this.canAccessCreateEvent().pipe(
            filter(user => !!user),
            switchMap((userType) => {
                if (userType['isFull'] || userType['isCreate']) {
                    return of(true);
                } else {
                    this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', { poolId: this.teamId }));
                    return of(false);
                }
            })
        );
    }
}
