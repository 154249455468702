import { AbstractBaseAlert } from '../../../Alert/base-alert';
import { SweetAlert } from '../../../Alert/sweet-alert';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import {EfModalService} from '../../eventFarmModal.service';
import { AdminService } from '../admin.service';
import { TeamEmailMasksService } from '../Manage/ManageTeam/TeamEmailMasks/team-email-masks.service';
import {ManageAdminService} from "../User/ManageAdmin/manage-admin.service";

export class AdminAlert extends AbstractBaseAlert {
  constructor(
    protected sweetAlert: SweetAlert,
    protected routeGenerator: RouteGeneratorService,
    protected manageAdminService: ManageAdminService,
    protected adminService?: AdminService,
    protected teamEmailMasksService?: TeamEmailMasksService
  ) {
    super(sweetAlert, routeGenerator);
  }

  public generateCanvasTemplate(): Promise<any> {
    return this.alert({
      title: 'Are you sure?',
      text: "This is going to regenerate all the SitePage Templates! Are you sure you know what you're doing?",
      showCancelButton: true,
      confirmButtonText: "Yes, generate em'",
      icon: 'info',
    }).then(async (result) => {
      if (result.value) {
        try {
          await this.adminService.generateSiteTemplates();
          this.generateCanvasTemplateSuccess();
        } catch (error) {
          this.generateCanvasTemplateError();
        }
      }

    });
  }

  public generateCanvasTemplateSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'The templates have been regenerated.',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }

  public generateCanvasTemplateError(): Promise<any> {
    return this.alert({
      title: 'Aww!',
      text: 'HTTP Request Failed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'error',
    });
  }

  public populateEchoWorlds(): Promise<any> {
    return this.alert({
      title: 'Are you sure?',
      text: "This is going to populate the Echo worlds in the Event Farm database. Are you sure you know what you're doing?",
      showCancelButton: true,
      confirmButtonText: "Yes, generate em'",
      icon: 'info',
    }).then(async (result) => {
      if (result.value) {
        try {
          await this.adminService.populateEchoWorlds();
          this.populateEchoWorldsSuccess();
        } catch (error) {
          this.populateEchoWorldsError();
        }
      }
    });
  }

  public populateEchoWorldsSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'Worlds have been updated!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }

  public populateEchoWorldsError(): Promise<any> {
    return this.alert({
      title: 'Aww!',
      text: 'HTTP Request Failed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'error',
    });
  }

  public removeEmailDomainMasks(mask): Promise<any> {
    return this.alert({
      title: 'Are you sure?',
      text: 'This is going to remove this email domain mask',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      allowOutsideClick: false,
      icon: 'info',
    })
  }

  public removeEmailDomainMaskSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'Email domain mask has been removed',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }

  public removeEmailDomainMaskError(): Promise<any> {
    return this.alert({
      title: 'Aww!',
      text: 'HTTP Request Failed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'error',
    });
  }

  public addPoolWorldToPoolSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'World has been added!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }

  public addPoolWorldToPoolError(): Promise<any> {
    return this.alert({
      title: 'Aww!',
      text: 'HTTP Request Failed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'error',
    });
  }

  public removePoolWorldFromPoolSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'World has been removed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }

  public removePoolWorldFromPoolError(): Promise<any> {
    return this.alert({
      title: 'Aww!',
      text: 'HTTP Request Failed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'error',
    });
  }

  public removePool() {
    return this.alert({
      title: 'Are you sure?',
      text: 'This is going to remove this team and all events',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete team',
      icon: 'error'
    });
  }

  public removeExhibitors(eventId: string): Promise<any> {
    return this.alert({
      title: 'Are you sure?',
      text: 'This is going to remove all exhibitors and leads for this event?',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove them',
      icon: 'info',
    }).then(async (result) => {
      if (result && result.value) {
        try {
          await this.adminService.removeExhibitorsAndLeadsForEvent(eventId);
          this.removeExhibitorsSuccess();
        } catch (error) {
          this.removeExhibitorsError();
        }
      }
    });
  }

  public removeExhibitorsSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'Exhibitors and leads for this event have been permanently deleted!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }

  public removeExhibitorsError(): Promise<any> {
    return this.alert({
      title: 'Aww!',
      text: 'HTTP Request Failed!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'error',
    });
  }

  public downgradeAdminConfirm(userId): Promise<any> {
    return this.alert({
      title: 'Are you sure?',
      text: 'This is going to downgrade this admin to a regular user',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      allowOutsideClick: false,
      icon: 'info',
    })

  }

  public upgradeUserConfirm(userId): Promise<any> {
    return this.alert({
      title: 'Are you sure?',
      text: "This is going to elevate this user's privileges to an admin",
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      allowOutsideClick: false,
      icon: 'info',
    })
  }
  public upgradeUserSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'User permissions elevated to Admin!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }
  public downgradeAdminSuccess(): Promise<any> {
    return this.alert({
      title: 'Success!',
      text: 'Admin downgraded!',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      icon: 'success',
    });
  }
}
