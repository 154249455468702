import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActivityLogComponent } from './activity-log.component';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../../CoreUI/Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { ActivityLogService } from './activity-log.service';
import {PaginationModule} from 'ngx-bootstrap/pagination'
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';

const forExport = [
    ActivityLogComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,
        PaginationModule,
    ],
    providers: [
        ActivityLogService,
        IconService
    ]
})

export class ActivityLogModule {
}