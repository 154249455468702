import { Observable } from "rxjs";
import {
  withLatestFrom,
  switchMap,
  tap,
  filter,
  take,
  shareReplay,
} from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromStore from "../store";
import { Router } from "@angular/router";
import { RouteGeneratorService } from "../../_services/routes/route-generator.service";
import { EventFarmService } from "../eventFarm.service";
import {AdminService} from "./admin.service";

@Component({
  template: ` <router-outlet></router-outlet> `,
})
export class AdminComponent {
  constructor(
    private store: Store<fromStore.AppState>,
    private router: Router,
    private routeGenerator: RouteGeneratorService,
    private eventFarmService: EventFarmService,
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.adminService.fetchAllFeatures();
  }
}
