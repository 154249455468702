import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';

import { EditSaveButtonComponent } from './edit-save-button.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

const forExport = [
    EditSaveButtonComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        NzUtilitiesModule,
        NgxUtilitiesModule
    ],
})

export class EditSaveButtonModule {
}
