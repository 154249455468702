import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as adminActions from '../../actions/admin';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {WaitListService} from '../../../EventsModule/WaitList/waitList.service';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../store';
import * as fromRoot from '../../../store';

import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {TeamAllotmentService} from '../../../AdminModule/Manage/ManageTeam/TeamAllotments/team-allotment.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {TeamPointOfContactService} from '../../../AdminModule/Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import * as adminFeatureActions from '../../actions/admin/admin-feature.action';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';
import {TeamContractDetailsService} from '../../../AdminModule/Manage/ManageTeam/TeamContractDetails/team-contract-details.service';
import {Contract} from '../../../../ApiClient/Models/Contract/contract';
import {TeamEmailMasksService} from '../../../AdminModule/Manage/ManageTeam/TeamEmailMasks/team-email-masks.service';
import {DomainMask} from '../../../../ApiClient/Models/DomainMask/domainMask';
import {Allotment} from '../../../../ApiClient/Models/Pool/allotment';
import * as adminAllotmentActions from '../../actions/admin/admin-allotment.action';

@Injectable()
export class AdminDomainMaskEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamAllotmentService: TeamAllotmentService,
        private messageService: NzMessageService,
        private teamPointOfContactService: TeamPointOfContactService,
        private teamContractDetailsService: TeamContractDetailsService,
        private teamEmailMasksService: TeamEmailMasksService

    ) {
    }


    // @ts-ignore
    fetchDomainMasksForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchDomainMasksForAdminTeam>(adminActions.FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamEmailMasksService.fetchDomainMasksByPool(team.id)
            .pipe(
                map(res => {
                    return res['data']
                        .map(e =>  DomainMask.fromApiResponse(e));
                }),
                switchMap((masks: DomainMask[]) => [
                    new adminActions.FetchDomainMasksForAdminTeamSuccess(masks)
                ]),
            )),
        catchError(err => of(new adminActions.FetchDomainMasksForAdminTeamFail(err)))));


    // @ts-ignore
    addDomainMaskToAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.AddDomainMaskToAdminTeam>(adminActions.ADD_DOMAIN_MASK_TO_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamEmailMasksService.addDomainMask(team.id, action.payload.domain)
            .pipe(
                map(res => res['data']),
                switchMap((mask: DomainMask) => [
                    new adminActions.AddDomainMaskToAdminTeamSuccess(mask),
                    this.messageService.success('Domain mask added to pool'),
                    new adminActions.FetchDomainMasksForAdminTeam()

                ]),
            )),
        catchError(err => {
            this.messageService.error('Error adding domain mask to pool');
            return of(new adminActions.AddDomainMaskToAdminTeamFail(err));
        })));


    // @ts-ignore
    removeDomainMaskFromAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.RemoveDomainMaskFromAdminTeam>(adminActions.REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamEmailMasksService.removeDomainMask(action.payload.maskId)
            .pipe(
                map(res => res['data']),
                switchMap((mask: DomainMask) => [
                    new adminActions.RemoveDomainMaskFromAdminTeamSuccess(mask),
                    this.messageService.success('Domain mask removed from pool'),
                    new adminActions.FetchDomainMasksForAdminTeam()

                ]),
            )),
        catchError(err => {
            this.messageService.error('Error removing domain mask from pool');
            return of(new adminActions.RemoveDomainMaskFromAdminTeamFail(err));
        })));
}
