import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DistributionComponent } from './distribution.component';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../../CoreUI/Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import {PopoverModule} from 'ngx-bootstrap/popover';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';

const forExport = [
    DistributionComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,
        PopoverModule
    ],
})

export class DistributionModule {
}