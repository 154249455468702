import { AbstractModel } from '../abstract-model';
import {HealthPassScoreTypeInterface} from '@eventfarm/javascript-sdk/dist/Api/Type/HealthPass';

export class UserHealthPass extends AbstractModel {
    private _score: HealthPassScoreTypeInterface;
    private _scoreTime: Date;

    public static fromApiResponse(data) {
        const userHealthPass = new this(data.id);

        if (data.meta.isInitialized) {
            userHealthPass._score = data.attributes.score;
            userHealthPass._scoreTime = data.attributes.scoreTime.date;
        }

        return userHealthPass;
    }

    get score(): HealthPassScoreTypeInterface {
        return this._score;
    }

    get scoreTime(): Date {
        return this._scoreTime;
    }
}
