import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { EventFarmService } from '../../eventFarm.service';
import { TicketBlockDashboardService } from './ticketBlockDashboard.service';
import { Subscription ,  Observable } from 'rxjs';
import { GuestListViewService } from '../../CoreUI/GuestListTableView/guest-list-view.service';
import { ActivatedRoute } from '@angular/router';
import { TicketBlock } from '../../../ApiClient/Models/Stack/ticket-block';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
@Component({
    selector: 'ticket-block-dashboard',
    providers: [
        ModalService,
    ],
    template: require('./ticket-block-dashboard.html'),
    styles: [require('./ticket-block-dashboard.scss')],
})

export class TicketBlockDashboardComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private route: ActivatedRoute,
        private store: Store<fromRoot.AppState>,
        private ticketBlockDashboardService: TicketBlockDashboardService,
        private guestListTableViewService: GuestListViewService,
    ) {
        this.guestListChange$ = this.guestListTableViewService.inviteChanged$.subscribe((val) => {
            this.ticketBlockDashboardService.gatherRelatedData();
        });
    }
    private guestListChange$: Subscription;
    private updateTicketBlockCountChange$: Subscription;

    async ngOnInit() {
        this.ticketBlockDashboardService.ticketBlock = TicketBlock.fromApiResponse(this.route.snapshot.data['ticketBlock'].data);
        const currentEvent = this.ticketBlockDashboardService.ticketBlock.event;
        this.store.dispatch(new fromRoot.SetCurrentEvent(currentEvent));
        this.ticketBlockDashboardService.totalAllotmentCounts = {
            used: 0,
            remaining: 0,
            quantity: 0,
            percentageUsed: 0,
        };
        this.ticketBlockDashboardService.gatherRelatedData();
        this.updateTicketBlockCountChange$ = this.eventFarmService.updateTicketBlockCounts$.subscribe(val => {
            if (val) {
                this.ticketBlockDashboardService.gatherRelatedData()
            }
        })
    }

    ngOnDestroy() {
        this.ticketBlockDashboardService.ticketBlock = null;
        this.ticketBlockDashboardService.ticketBlockUsers = [];
        this.ticketBlockDashboardService.stacks = [];
        this.ticketBlockDashboardService.ticketTypes = [];
        this.guestListChange$.unsubscribe();
        this.updateTicketBlockCountChange$.unsubscribe()
    }
}
