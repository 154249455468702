declare global {
    interface Window {
        authConfig:
            {
                AUTH_CLIENT_ID: string,
                AUTH_SERVER_URL: string,
                AUTH_REDIRECT_URI: string
            }
    }
}


const authConfig: any = {
    clientId: window.authConfig.AUTH_CLIENT_ID,
    serverUrl: window.authConfig.AUTH_SERVER_URL,
    redirectUri: window.authConfig.AUTH_REDIRECT_URI
}


export default authConfig;
