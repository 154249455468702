import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import * as eventActions from './../../../store/actions/event';
import * as fromRoot from './../../../store';
import { Subscription } from 'rxjs';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { EventFarmService } from '../../../eventFarm.service';
import { EmailDesignCenterService } from '../email-design-center.service';
import { EmailPreviewService } from '../email-preview.service';
import { Design } from '../Models/design';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';


@Component({
    selector: 'select-design-template',
    template: require('./select-design-template.html'),
    styles: [require('./select-design-template.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class SelectDesignTemplateComponent implements OnInit {
    constructor(
        private emailDesignCenterService: EmailDesignCenterService,
        private emailPreviewService: EmailPreviewService,
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
    ) {
        this.emailDesignCenterService.event = eventFarmService.currentEvent;
        this.emailDesignCenterService.currentEvent = eventFarmService.currentEvent;
    }

    private user: any = {};
    private selectedEvent = this.emailDesignCenterService.event;
    private currentEvent: EfEvent = this.emailDesignCenterService.currentEvent;
    private events: any = [];
    private designs: Design[] | any;
    private templates: Object[] = this.emailDesignCenterService.templates;
    private thumbnailRetryIntervalInSeconds = 15;

    reloadDesigns = new EventEmitter<any>();

    private loading: boolean = false;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    ngOnInit() {

        this.fetchDesigns(this.currentEvent);
        this.emailDesignCenterService.designsListOptions.pagination.currentDesignsPage = 1;
    }

    // GetAllDesigns
    fetchDesigns(event) {

        const { id } = event;
        this.selectedEvent = event;

        this.loading = true;
        this.emailDesignCenterService.fetchDesigns(id).subscribe((savedDesigns) => {
            this.emailDesignCenterService.emailDesignsTotalPages = savedDesigns.meta.totalPages;
            let designs = savedDesigns.data
                .map((design) => Design.fromApi(design))
                .map((design: Design) => {
                    return design;
                });
            this.loading = false;
            this.designs = designs;
        });
    }

    retryFetchDesignThumbnail(designId) {
        setTimeout(() => {
            this.emailDesignCenterService.fetchDesignThumbnail(designId).subscribe((res) => {
                this.designs.forEach((design) => {
                    if (design.emailDesignId === designId) {
                        design.thumbnailUrl = res.data.attributes;
                    }
                });
            }, (err) => {
                this.retryFetchDesignThumbnail(designId);
            });
        }, (this.thumbnailRetryIntervalInSeconds * 1000));
    }

    // DeleteDesign
    deleteDesign(design) {
        this.designs.splice(this.designs.indexOf(design), 1);
    }

    // DuplicateDesign
    duplicateDesign(newDesign, oldDesign) {
        if (this.selectedEvent.id !== this.currentEvent.id) {
            this.reloadDesigns.emit(this.fetchDesigns(this.currentEvent.id));
            this.selectedEvent = this.currentEvent;
        }
        this.designs.push({ emailDesignId: newDesign.data.command.duplicateEmailDesignId, name: `[Copy] ${oldDesign.name}`, type: 'design' });
    }

    private filterOutCioEvents(events) {
        let filteredEvents = [];
        events.forEach((event) => {
            if (event.attributes.type !== 'cio' && event.attributes.type !== 'listly') {
                filteredEvents.push(event);
            }
        });
        return filteredEvents;
    }

    nextDesignsPage() {
        if (this.emailDesignCenterService.designsListOptions.pagination.currentDesignsPage < this.emailDesignCenterService.emailDesignsTotalPages) {
            this.emailDesignCenterService.designsListOptions.pagination.currentDesignsPage += 1;
            this.fetchDesigns(this.emailDesignCenterService.currentEvent.id);
        }
    }

    previousDesignsPage() {
        if (this.emailDesignCenterService.designsListOptions.pagination.currentDesignsPage > 1) {
            this.emailDesignCenterService.designsListOptions.pagination.currentDesignsPage -= 1;
            this.fetchDesigns(this.emailDesignCenterService.currentEvent.id);
        }
    }

}