import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditQuestionComponent } from './edit-question.component';
import { Question } from '../../../../../../ApiClient/Models/Question/question';

@Component({
  selector: 'create-question-button',
  template: `
    <button
      nz-button
      nzType="primary"
      class="main-action ef-btn-sm"
      (click)="openComponent()"
    >{{ buttonText }}</button>
  `
})

export class CreateQuestionButtonComponent implements OnInit {
  @Input() question;
  @Input() buttonText;
  drawerTitle;
  width = '50%';

  constructor(
    private drawerService: NzDrawerService,
  ) {}

  ngOnInit() {
      this.drawerTitle = 'Create Question';
  }

  openComponent(): void {
    const drawerRef = this.drawerService.create<EditQuestionComponent, { question: Question }, string>({
      nzTitle: this.drawerTitle,
      nzContent: EditQuestionComponent,
      nzWidth: this.width,
      nzContentParams: {
        question: this.question,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      
    });

    drawerRef.afterClose.subscribe(data => {

    });
  }

}