import { Component } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Router } from '@angular/router';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

@Component({
  selector: 'registration-settings',
  template: require('./registration-settings.html'),
  styles: [require('./registration-settings.scss')],
})

export class RegistationSettingsComponent {

  constructor(private eventFarmService: EventFarmService, private router: Router, private routeGenerator: RouteGeneratorService) {
  }
  goToRegSettings() {
    const route = this.routeGenerator.url('events.web-presence', { poolId: this.eventFarmService.currentTeam.id, eventId: this.eventFarmService.currentEvent.id });
    this.router.navigateByUrl(route);
  }
}
