import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { InvitationCreationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { StackMethodTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Stack';

export class InvitationStatusSlugFinder {
    readonly defaultStatus = 'unconfirmed';

    private _invitationCreationMethod: InvitationCreationTypeInterface;
    private _stackMethodType: StackMethodTypeInterface;

    constructor(stack: Stack, invitationCreationMethod: InvitationCreationTypeInterface) {
        this._stackMethodType = stack.stackMethodType;
        this._invitationCreationMethod = invitationCreationMethod;
    }

    getStatus(): string {
        let status: string = this.defaultStatus;
        if (!this._stackMethodType) {
            return this.defaultStatus;
        }

        if (this._stackMethodType.isPublicRegistration) {
            status = 'registered';
        } else if (this._stackMethodType.isPublicPurchase) {
            status = 'purchased';
        } else if (this._invitationCreationMethod.isSendEmail || this._invitationCreationMethod.isUnconfirmedNoEmail) {
            if (this._stackMethodType.isInviteToRegister || this._stackMethodType.isInviteToRegisterFCFS) {
                status = 'not-yet-registered';
            } else if (this._stackMethodType.isInviteToPurchase || this._stackMethodType.isInviteToPurchaseFCFS) {
                status = 'not-yet-purchased';
            } else if (this._stackMethodType.isInviteToRSVP || this._stackMethodType.isInviteToRSVPFCFS) {
                status = 'unconfirmed';
            }
        } else if (this._invitationCreationMethod.isConfirmedNoEmail) {
            if (this._stackMethodType.isAnyInviteToRegister) {
                status = 'registered';
            } else if (this._stackMethodType.isAnyInviteToPurchase) {
                status = 'purchased';
            } else if (this._stackMethodType.isAnyInviteToRSVP) {
                status = 'confirmed-by-rsvp';
            }
        }
        else {
           status = this.defaultStatus; //Unnecessary but including the "else" for readability
        }
        return status
    }

    getCreationType(): string {
        let creationType: string = 'unconfirmed-no-email';
        if (this._stackMethodType && this._stackMethodType.isInvitation) {
            creationType = 'send-email';
        }

        return creationType;
    }
}
