import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';
import * as fromStore from '../../../store';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  selector: 'qr-code',
  template: require('./qr-code.html'),
})

export class QRCodeComponent implements OnDestroy {

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>,
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      this.toggleState = this.eventFarmService.currentEvent.eventAttributes.hasShowQRConfirmation;
    });
  }

  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  private toggleState: boolean = false;
  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;

  toggle() {
    this.toggleState = !this.toggleState;
    if (this.eventFarmService.currentEvent.eventAttributes.hasShowQRConfirmation) {
      this.disableQRCode();
    } else {
      this.enableQRCode();
    }
  }

  ngOnDestroy() {
    this.eventUpdate$.unsubscribe();
  }

  enableQRCode() {
    this.apiClient.getUseCaseFactory().Event().EnableQRCodeConfirmation(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasShowQRConfirmation = true;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('QR code to be displayed');
      });
  }

  disableQRCode() {
    this.apiClient.getUseCaseFactory().Event().DisableQRCodeConfirmation(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasShowQRConfirmation = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('QR code to not be displayed');
      });
  }
}
