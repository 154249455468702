import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { PaymentSettingsService } from './payment-settings.service';
import { EventFarmService } from '../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { LoaderGrayModule } from '../../../CoreUI/LoaderGray/loader-gray.module';
import { PaymentSettingsComponent } from './payment-settings.component';
import { CommonModule } from '@angular/common';
import {PaymentGatewayComponent} from './PaymentGateway/payment-gateway.component';

const forExport = [PaymentSettingsComponent, PaymentGatewayComponent];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        ReactiveFormsModule,
        NzUtilitiesModule,
        LoaderGrayModule,
        CommonModule,
    ],
    providers: [PaymentSettingsService, EventFarmService, NzDrawerService],
})
export class PaymentSettingsModule {}
