import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { EventRevenueService } from '../event-revenue.service';
import { EventFarmService } from '../../../../eventFarm.service';

@Component({
  selector: 'payments',
  template: require('./payments.html'),
  styles: [require('./payments.scss')]
})
export class PaymentsComponent implements OnInit {

  constructor(
    private drawerRef: NzDrawerRef,
    private revenueService: EventRevenueService,
    private eventFarmService: EventFarmService,
  ) { }

  private query: string = '';

  ngOnInit() {
    this.revenueService.getPaymentsForEvent();
  }

  paymentSearch() {
    this.revenueService.listOptions.pagination.currentPage = 1;
    this.revenueService.listOptions.query = this.query;
    this.revenueService.getPaymentsForEvent();
  }

  clearSearch() {
    this.query = '';
    this.revenueService.listOptions.pagination.currentPage = 1;
    this.revenueService.listOptions.query = '';
    this.revenueService.getPaymentsForEvent();
  }

  public changePage(page) {
    this.revenueService.isLoadingPayments = true;
    this.revenueService.listOptions.query = this.query;
    this.revenueService.listOptions.pagination.currentPage = page;
    this.revenueService.getPaymentsForEvent();
  }

  close(): void {
    this.drawerRef.close();
  }
}
