import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {NzMessageService} from 'ng-zorro-antd/message';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {EventFarmService} from '../../../eventFarm.service';
import {AlertService} from '../../../eventFarmAlert.service';
import * as fromStore from '../../../store';
import {AdminService} from '../../admin.service';
import {AdminAlertService} from '../../Alert/adminEventFarmAlert.service';
import {ManageAdminService} from './manage-admin.service';

@Component({
    selector: 'manage-admin',
    template: require('./manage-admin.html'),
    styles: [require('./manage-admin.scss')],

})
export class ManageAdminComponent implements OnInit {

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private alertService: AlertService,
        private message: NzMessageService,
        private store: Store<fromStore.AppState>,
        private adminService: AdminService,
        private adminAlertService: AdminAlertService,
        private manageAdminService: ManageAdminService
    ) {

    }



    async ngOnInit() {
       await this.manageAdminService.fetchExistingAdmins();
    }

}
