import { Injectable } from '@angular/core';

@Injectable()

export class CookieTokenService {
    private _token?: string;
    private _ghostToken?: string;

    constructor() {
        this._token = this.fetchTokenFromCookie('EF_ACCESS_TOKEN');
        this._ghostToken = this.fetchTokenFromCookie('EF_GHOST_ACCESS_TOKEN');
    }

    get headerString() {
        if (this._ghostToken == null) {
            return this.createBearerString(this.token);
        } else {
            return this.createBearerString(this._ghostToken);
        }
    }

    get token() {
        return this._token;
    }

    get bearerString() {
        return this.createBearerString(this._token);
    }

    private createBearerString(token: string): string {
        return 'Bearer ' + token;
    }

    private fetchTokenFromCookie(tokenName: string) {
        const regexString = '(^|;)\\s*' + tokenName + '\\s*=\\s*([^;]+)';

        if (document.cookie.match(regexString)) {
            return document.cookie.match(regexString).pop();
        }

        return null;
    }
}
