import {NzSafeAny} from "ng-zorro-antd/core/types";
import {NzModalRef} from "ng-zorro-antd/modal";
import { AbstractBaseAlert } from '../base-alert';

export class InvitationAlert extends AbstractBaseAlert {
    public invitationResendConfirmation(guestIdentifier: string): any {
        return this.confirm({
            title: 'Resend Guest Invitation?',
            text: 'Are you sure you want to resend the invitation for ' + guestIdentifier + '?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Resend Invitation',
        });
    }

    public invitationSendFailure(guestIdentifier: string, additionalInfo: string): Promise<any> {
        return this.alert({
            title: 'Invitation Send Failure',
            text: 'Could not resend your invitation for ' + guestIdentifier + '. Details: ' + additionalInfo,
            icon: 'error',
        });
    }

    public confirmationResendConfirmation(guestIdentifier: string): any{
         return this.confirm({
            title: 'Resend Confirmation?',
            html: `<div>Are you sure you want to resend the confirmation for <b>${guestIdentifier}?</b></div>`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Resend Confirmation',
        });
    }

    public confirmationResendFailure(guestIdentifier: string, additionalInfo: string): Promise<any> {
        return this.alert({
            title: 'Confirmation Send Failure',
            html: 'Could not resend your confirmation for ' + guestIdentifier + '. Please try again at a later time. ' +
            '<br>Errors:<br><pre>' + additionalInfo + '</pre>',
            icon: 'error',
        });
    }

    public unableToUpdateInvitation(): Promise<any> {
        return this.alert({
            title: 'Unable to update invitation',
            text: 'The invitation could not be successfully updated',
            icon: 'error',
        });
    }

    public invitationUpdated(): Promise<any> {
        return this.alert({
            title: 'Invitation Updated',
            text: 'The invitation has been updated successfully',
            icon: 'success',
        });
    }

    public invalidMaximumInvitationCount(value: string): Promise<any> {
        return this.alert({
            title: 'Invalid Invitation Count',
            html: value,
            icon: 'error',
        });
    }

    public resendingInvitations(responseMessage: string): Promise<any> {
        return this.alert({
            title: 'Resending Invitations',
            text: responseMessage,
            icon: 'success',
        });
    }

    public unableToResendInvitations(responseMessage: string): Promise<any> {
        return this.alert({
            title: 'Unable To Resend Invitations',
            text: responseMessage,
            icon: 'error',
        });
    }
}
