import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import * as fromRoot from '../../../../store';
import { AdminService } from '../../../admin.service';
import { AdminAlertService } from '../../../Alert/adminEventFarmAlert.service';

@Component({
  selector: 'team-info',
  template: require('./team-info.html'),
  styles: [require('./team-info.scss')],
})
export class TeamInfoComponent implements OnInit, OnDestroy {
  
  public contractDetailsForm: FormGroup;
  public poolContractTypes: any;
  public allotments$ = this.store.select(fromRoot.getAdminCurrentTeamAllotmentsData).pipe(shareReplay());
  public get currentTeam$() { return this.adminService.currentTeam$; }
  public isArchiving = false;

  private cancelSubscriptions$ = new Subject<void>();

  constructor(
    private apiClient: EventFarmAPIClient,
    private adminAlertService: AdminAlertService,
    private adminService: AdminService,
    private store: Store<fromRoot.AppState>,
  ) {}
  

  public ngOnInit() {
    this.poolContractTypes = this.apiClient
      .getTypeFactory()
      .Pool()
      .PoolContactType();

      this.store.select(fromRoot.getAdminIsArchivingCurrentTeam)
        .pipe(takeUntil(this.cancelSubscriptions$))
        .subscribe(isArchiving => this.isArchiving = isArchiving);
  }

  public ngOnDestroy() {
      this.cancelSubscriptions$.next();
  }

  public archiveTeam() {
    this.adminAlertService.general().removePool()
        .then(result => {
            if (result.dismiss)
                return;
            this.store.dispatch(new fromRoot.ArchiveCurrentTeamForAdmin());
            this.adminService.teams$.next([]);
        });
        
  }
}
