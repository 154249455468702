import { AbstractModel } from '../abstract-model';
import {Allotment} from "./allotment";
import {PoolContact} from "../PoolContact/poolContact";

export class Team extends AbstractModel {

    private _name?: string;
    private _shortName?: string;
    private _privacyPolicyLink?: string;
    private _customerDisplayName?: string;
    private _features?: any;
    private _allotments?: Allotment[]
    private _contacts?: PoolContact[]
    private _created?: string;
    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data): Team {
        const team = new this(data.id);
        team._name = data.attributes ? data.attributes.name : '';
        team._shortName = data.attributes ? data.attributes.shortName : '';
        team._privacyPolicyLink = data.attributes ? data.attributes.privacyPolicyLink : '';
        team._customerDisplayName = data.attributes ? data.attributes.customerDisplayName : '';
        team._created = data.attributes ? data.attributes.created.date : '';

        return team;
    }

    get name(): string {
        return this._name;
    }

    get features(): any {
        return this._features;
    }

    get privacyPolicyLink(): string {
        return this._privacyPolicyLink;
    }

    get created(): string {
        return this._created;
    }

    get shortName(): string {
        return this._shortName;
    }

    get customerDisplayName(): string {
        return this._customerDisplayName;
    }

    get allotments(): Allotment[] {
        return this._allotments;
    }

    get contacts(): PoolContact[] {
        return this._contacts;
    }
    set contacts(value: PoolContact[]) {
        this._contacts = value;
    }
    set name(value: string) {
        this._name = value;
    }

    set features(value: any) {
        this._features = value;
    }

    set allotments(value: Allotment[]){
        this._allotments = value;
    }
}
