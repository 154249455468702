import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { User } from '../User/user';
import { EFx, EFxModuleTypeInterface, EFxScreenTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { EFxStationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { EmailDesign } from '../../../Shared/EmailDesign/email-design';

export class EFxScreen extends AbstractModel {
    private _backgroundUrl?: string;
    private _text?: string;
    private _textColor?: string;
    private _screenType?: EFxScreenTypeInterface;

    
    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const screen = new this(data.id);

        screen._backgroundUrl = data.attributes.backgroundUrl;
        screen._text = data.attributes.text;
        screen._textColor = data.attributes.textColor;
        screen._screenType = data.attributes.screenType;

        return screen;
    }

    public static defaultFromType(type: EFxScreenTypeInterface) {
        const screen = new this();

        screen._backgroundUrl = screen.getDefaultBackgroundUrlByScreenType(type);
        screen._text = screen.getDefaultTextByScreenType(type);
        screen._textColor = screen.getDefaultTextColorByScreenType(type);
        screen._screenType = type

        return screen;
    }

    get backgroundUrl(): string {
        return this._backgroundUrl;
    }

    get text(): string|null {
        return this._text;
    }

    get textColor(): string|null {
        return this._textColor;
    }

    get screenType(): EFxScreenTypeInterface {
        return this._screenType;
    }

    get sortOrder(): number
    {
        if (this._screenType.isScanView) {
            return 0
        } else if (this._screenType.isSuccessView) {
            return 1
        } else if (this._screenType.isFailureView) {
            return 2
        } else if (this._screenType.isCheckOutView) {
            return 3
        } else if (this._screenType.isRegisterScanView) {
            return 4
        } else if (this._screenType.isRegisterSuccessView) {
            return 5
        } else if (this._screenType.isRegisterFailureView) {
            return 6
        }
    }

    private getDefaultTextColorByScreenType(type: EFxScreenTypeInterface): string
    {
        return type.defaultTextColor;
    }

    private getDefaultBackgroundUrlByScreenType(type: EFxScreenTypeInterface): string
    {
        return type.defaultPhoneBackgroundUrl;
    }

    private getDefaultTextByScreenType(type: EFxScreenTypeInterface): string
    {
        return type.defaultText;
    }
}
