import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Subscription} from 'rxjs';
import {Communication} from '../../../../../ApiClient/Models/Communication';
import {EventFarmService} from '../../../../eventFarm.service';
import {AlertService} from '../../../../eventFarmAlert.service';
import {SMSCreateService} from '../sms-create.service';
import { v4 as uuidv4 } from 'uuid';


@Component({
    selector: 'sms-create-preview',
    template: require('./sms-create-preview.html'),
    styles: [require('./sms-create-preview.scss')]
})

export class SMSCreatePreviewComponent implements OnInit, OnDestroy {
    @Output() onValidityChange = new EventEmitter();

    smsPreviewForm: any;
    smsPreviewFormValuesSub$: Subscription;

    constructor(
        private eventFarmService: EventFarmService,
        private formBuilder: FormBuilder,
        private smsCreateService: SMSCreateService,
        private drawerRef: NzDrawerRef,
        private alertService: AlertService,
        private messageService: NzMessageService
    ) {
    }


    // Filter out "preview" option
    sendByOptions = Communication.sendByOptions.filter(opt => opt.value !== 'preview');
    invitationStatusOptions = Communication.invitationStatusTypeOptions;
    ticketTypes = this.eventFarmService.currentEvent.ticketTypes

    ngOnInit(): void {
        this.initForm();
        this.initFieldSubs();
    }


    // Initialize form
    private initForm() {
        this.smsPreviewForm = this.formBuilder.group({
            preview: new FormControl(undefined),
        });
    }

    // Establish field subscriptions
    private initFieldSubs() {
        this.smsPreviewFormValuesSub$ = this.smsPreviewForm.valueChanges.subscribe(data => {
            this.smsCreateService.previewRecipients = Object.values(data);
        });
    }

    ngOnDestroy(): void {
        this.smsPreviewFormValuesSub$.unsubscribe();
    }

    close(): void {
        this.drawerRef.close();
    }

    async sendPreview() {
        await this.smsCreateService.sendPreview();
        this.close();
        this.messageService.success('SMS Preview Sent Successfully');
    }

    get controlsArray() {
        return Object.keys(this.smsPreviewForm.controls)
    }

    get controlCount(): number {
        return this.controlsArray?.length;
    }

    addPreviewInput() {
        if (this.controlCount > 0) {
            this.smsPreviewForm.addControl(`preview_${uuidv4()}`, new FormControl(''));
        }
    }

    removeControl(control: string) {
        this.smsPreviewForm.removeControl(control);
    }
}
