import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { EventFarmService } from '../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../admin.service';
import { TeamEventsService } from '../ManageTeam/TeamEvents/team-events.service';
import * as adminActions from '../../../store/actions/admin';

import {ContractDetailsFormValues} from '../../Common/ContractDetailsForm/contract-details-form.component';
import {shareReplay} from 'rxjs/operators';
import {Subscription} from 'rxjs';

export interface CreateTeamFormValues {
    name: string;
    contract: ContractDetailsFormValues;
}
@Component({
    selector: 'create-team-config',
    template: require('./create-team.html'),
    styles: [require('./create-team.scss')],

})
export class CreateTeamComponent implements OnInit {
    private saveAttempt: boolean = false;

    createTeamForm: FormGroup;
    private currentTeamSubscription$: Subscription;

    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private adminService: AdminService,
        private messageService: NzMessageService,
        private teamEventsService: TeamEventsService,
        private formBuilder: FormBuilder
    ) {}
    private currentTeam$ = this.store.select(fromRoot.getAdminCurrentTeam).pipe(shareReplay());

    ngOnInit() {
        this.createTeamForm = this.formBuilder.group({
            teamName: ['', Validators.required],
            contract: [{startDate: new Date(), endDate: new Date(), contractType: 'cio'}]
        });
        this.currentTeamSubscription$ =  this.currentTeam$.subscribe(val => {
            if (!val.loading && this.saveAttempt) {
                this.close();
            }
        });
    }

    private saveNewTeam() {
        this.saveAttempt = true;
        this.store.dispatch(new adminActions.CreateTeamForAdmin(this.createTeamForm.value));
    }

    close() {
        this.drawerRef.close();
    }

    // Todo revisit using unsubscribe instead
    ngOnDestroy() {
        this.currentTeamSubscription$.unsubscribe();
    }
}
