import { AbstractModel } from '../abstract-model';
import { FormattedDateTime } from '../formatted-datetime';
import { Transaction } from '../Transaction/transaction';
import { Team } from '../Pool/team';
import { EfEvent } from '../Event/event';
import { Refund } from './refund';
import { Purchase } from './purchase';

export class Payment extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _transaction: Transaction|null;
    private _derivedPool: Team|null;
    private _event: EfEvent;
    private _type: string;
    private _transType: string;
    private _paymentInfo: PaymentInfo;
    private _billingInfo: BillingInfo;
    private _remainingAmount: number;
    private _formattedCreated: FormattedDateTime;
    private _refunds: Refund[];
    private _purchases: Purchase[];
    private _description: string;
    private _promoDescription: string|null;
    private _invitationNamesForPayment: string;

    public static fromApiResponse(data) {
        const payment = new this(data.id);
        const attributes = data.attributes;

        if (payment._transaction) {
            payment._transaction = Transaction.fromApiResponse(attributes.transaction);
        }
        payment._event = EfEvent.fromApiResponse(attributes.event);
        if (attributes.derivedPool) {
            payment._derivedPool = Team.fromApiResponse(attributes.derivedPool);
        }
        payment._type = attributes.type;
        payment._transType = attributes.transType;
        payment._formattedCreated = attributes.formattedCreated;
        payment._remainingAmount = attributes.remainingAmount;
        payment._description = attributes.description;
        payment._promoDescription = attributes.promoDescription;
        payment._invitationNamesForPayment = attributes.invitationNamesForPayment;


        payment._paymentInfo = payment.createPaymentInfo(attributes.paymentInfo);
        payment._billingInfo = payment.createBillingInfo(attributes.billingInfo);

        const relationships = data.relationships;

        payment._purchases = payment.createPurchases(relationships.purchases);
        payment._refunds = payment.createRefunds(relationships.refunds);

        return payment;
    }

    private createPaymentInfo(paymentInfo): PaymentInfo {
        return {
            processingCurrency: paymentInfo.processingCurrency,
            amount: paymentInfo.amount,
            spreedlyTransactionId: paymentInfo.transId,
            promotionAmount: paymentInfo.promotionAmount,
            spreedlyTransactionResult: paymentInfo.transResult,
            cardType: paymentInfo.cardType
        };
    }

    private createBillingInfo(billingInfo): BillingInfo {
        return {
            billingFirstName: billingInfo.billingFirstName,
            billingLastName: billingInfo.billingLastName,
            billingEmail: billingInfo.billingEmail,
        };
    }

    private createPurchases(purchases): Purchase[] {
        return purchases.map(purchase => {
            return Purchase.fromApiResponse(purchase);
        });
    }

    private createRefunds(refunds): Refund[] {
        return refunds.map(refund => {
            return Refund.fromApiResponse(refund);
        });
    }

    public get refundedAmount(): number|null {
        if (this._refunds.length === 0) {
            return null;
        }
        return this._paymentInfo.amount - this._remainingAmount;
    }

    /**
     * Getter transaction
     * @return {Transaction}
     */
    public get transaction(): Transaction|null {
        return this._transaction;
    }

    /**
     * Getter derivedPool
     * @return {Team}
     */
    public get derivedPool(): Team|null {
        return this._derivedPool;
    }

    /**
     * Getter event
     * @return {EfEvent}
     */
    public get event(): EfEvent {
        return this._event;
    }

    /**
     * Getter type
     * @return {string}
     */
    public get type(): string {
        return this._type;
    }

    /**
     * Getter transType
     * @return {string}
     */
    public get transType(): string {
        return this._transType;
    }

    /**
     * Getter paymentInfo
     * @return {PaymentInfo}
     */
    public get paymentInfo(): PaymentInfo {
        return this._paymentInfo;
    }

    /**
     * Getter remainingAmount
     * @return {number}
     */
    public get remainingAmount(): number {
        return this._remainingAmount;
    }

    /**
     * Getter refunds
     * @return {Refund[]}
     */
    public get refunds(): Refund[] {
        return this._refunds;
    }

    /**
     * Getter purchases
     * @return {Purchase[]}
     */
    public get purchases(): Purchase[] {
        return this._purchases;
    }

    /**
     * Getter billingInfo
     * @return {BillingInfo}
     */
    public get billingInfo(): BillingInfo {
        return this._billingInfo;
    }

    /**
     * Getter formattedCreated
     * @return {FormattedDateTime}
     */
    public get formattedCreated(): FormattedDateTime {
        return this._formattedCreated;
    }

    public get detailedName(): string {
        return `${this._billingInfo.billingFirstName} ${this._billingInfo.billingLastName}`;
    }

    public get description(): string {
        return this._description;
    }

    public get promoDescription(): string|null {
        return this._promoDescription;
    }

    public get invitationNamesForPayment(): string {
        return this._invitationNamesForPayment;
    }
}

export interface PaymentInfo {
    processingCurrency: string;
    amount: number;
    spreedlyTransactionId: string;
    spreedlyTransactionResult: string;
    promotionAmount: number|null;
    cardType: string|null;
}

export interface BillingInfo {
    billingFirstName: string;
    billingLastName: string;
    billingEmail: string|null;
}
