import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { FormattedDateTime } from '../formatted-datetime';
import { TicketType } from '../Stack/ticket-type';
import {SMSStatus} from "./sms-status";



export class SMSNotification extends AbstractModel {
    private _errorCode?: string;
    private _errorMessage?: string;
    private _status: SMSStatus;
    private _formattedCreated: FormattedDateTime

    constructor(id?: string) {
        super(id);
    }



    public static fromApiResponse(data) {
        const smsNotification = new this(data.id);
        smsNotification._errorCode = data.attributes.errorCode;
        smsNotification._errorMessage = data.attributes.errorMessage;
        smsNotification._status = SMSStatus.fromApiResponse(data.attributes.status);
        smsNotification._formattedCreated = data.attributes.formattedCreated;
        return smsNotification;
    }


    get errorCode(): string {
        return this._errorCode;
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    get status(): SMSStatus {
        return this._status;
    }

    get formattedCreated(): FormattedDateTime {
        return this._formattedCreated;
    }
}
