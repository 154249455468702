import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'debounce-search',
    template: require('./debounce-search.html'),
})

export class DebounceSearchComponent implements OnInit, OnDestroy {
    @Input() placeholder: string;
    @Input() defaultValue: string;
    @Output() callback: EventEmitter<any> = new EventEmitter();
    obs: Subscription;
    mform: FormGroup;

    constructor(
    ) {}

    ngOnInit(): void {
        this.mform = new FormGroup({
            field: new FormControl(this.defaultValue )
        });

        this.obs = this.mform.valueChanges
            .pipe(debounceTime(750))
            .subscribe(data => this.callback.emit(data.field));
    }



    ngOnDestroy() {
        this.obs.unsubscribe();
    }
}
