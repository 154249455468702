import { EditStackButtonComponent } from './TicketTypesStacks/StackRowItem/EditStack/edit-stack-button.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { GuestSimpleStatsModule } from '../../CoreUI/GuestSimpleStats/guestSimpleStats.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { TicketBlockListModule } from '../../CoreUI/TicketBlockList/ticket-block-list.module';
import { TicketStacksListModule } from '../../CoreUI/TicketStacksList/ticketStacksList.module';
import { TicketTypeListModule } from '../../CoreUI/TicketTypeList/ticketTypeList.module';
import { EventAccessRoutingModule } from './event-access-routing.module';
import { EventAccessComponent } from './event-access.component';
import { TicketTypesStacksComponent } from './TicketTypesStacks/ticket-types-stacks.component';
import { StackRowItemComponent } from './TicketTypesStacks/StackRowItem/stack-row-item.component';
import { EditSaveButtonComponent } from './../../CoreUI/EditSaveButton/edit-save-button.component';
import { EventAccessService } from './event-access.service';
import { EditStackComponent } from './TicketTypesStacks/StackRowItem/EditStack/edit-stack.component';
import { EditSaveButtonModule } from '../../CoreUI/EditSaveButton/edit-save-button.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [EventAccessComponent],
    declarations: [
        EventAccessComponent,
        TicketTypesStacksComponent,
        StackRowItemComponent,
        // EditSaveButtonComponent,
        EditStackComponent,
        EditStackButtonComponent,
    ],
    entryComponents: [
        TicketTypesStacksComponent,
        EditStackComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TicketBlockListModule,
        TicketTypeListModule,
        TicketStacksListModule,
        GuestSimpleStatsModule,
        ModalModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        EventAccessRoutingModule,
        NzUtilitiesModule,
        EditSaveButtonModule,
        NgxUtilitiesModule
    ],
    providers: [
        EventAccessService
    ],
    exports: [
        StackRowItemComponent
    ]
})

export class EventAccessModule {
}
