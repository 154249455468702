import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {CioGetStartedWelcomeComponent} from './cio-get-started-welcome.component';
import {ModalService} from '../../../modal.service';
import {CioGetStartedStepperModule} from "../CioGetStartedStepper/cioGetStartedStepper.module";
import {CioGetStartedStepperComponent} from "../CioGetStartedStepper/cio-get-started-stepper.component";
import {CioGetStartedWelcomeService} from "./cio-get-started-welcome.service";
import {UtilitiesModule} from "../../../../../../Utilities/utilities.module";

@NgModule({
    declarations: [CioGetStartedWelcomeComponent],
    exports: [CioGetStartedWelcomeComponent],
    entryComponents: [CioGetStartedWelcomeComponent],
    imports: [CommonModule, CioGetStartedStepperModule, UtilitiesModule],
    providers: [ModalService, CioGetStartedWelcomeService]
})

export class CioGetStartedWelcomeModule {
}
