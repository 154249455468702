import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import * as fromRoot from '../../../store';
import {GuestListViewService} from "../guest-list-view.service";
import {GuestListFilterType, GuestListFiltersService} from "../GuestListFilters/guest-list-filters.service";

@Component({
    selector: 'guest-list-applied-filters',
    template: require('./guest-list-applied-filters.html'),
    styles: [require('./guest-list-applied-filters.scss')]
})
export class GuestListAppliedFiltersComponent implements OnInit {

    constructor(
        private store: Store<fromRoot.AppState>, private drawerRef: NzDrawerRef,
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService,


    ) {
    }

    public readonly glFilterType : typeof GuestListFilterType = GuestListFilterType;

    ngOnInit() {
    }


}
