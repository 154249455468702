import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '../CoreUI/Breadcrumbs/breadcrumbs.module';
import { LegacyUIElementComponent } from './legacy-UI-element.component';
import { LegacyUIService } from './legacy-UI.service';
import { LegacyUIModalContainerComponent } from './legacy-UI-modal-container.component';
import { UtilitiesModule } from '../../Utilities/utilities.module';
import { LegacyUIRootControlsComponent } from './legacy-UI-root-controls.component';
import { ModalModule } from '../CoreUI/Modal/modal.module';

@NgModule({
    bootstrap: [LegacyUIElementComponent],
    declarations: [
        LegacyUIElementComponent,
        LegacyUIModalContainerComponent,
        LegacyUIRootControlsComponent
    ],
    entryComponents: [
        LegacyUIElementComponent,
        LegacyUIModalContainerComponent,
        LegacyUIRootControlsComponent
    ],
    imports: [
        CommonModule,
        BreadcrumbsModule,
        UtilitiesModule,
        ModalModule
    ],
    exports: [
        LegacyUIModalContainerComponent,
        LegacyUIElementComponent,
        LegacyUIRootControlsComponent
    ],
    providers: [
        LegacyUIService
    ],
})

export class LegacyUIModule {
}
