import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { AddInviteCountService } from './addInviteCount.service';
import { InvitationOptionsService } from './invitationOptions.service';

@Component({
    selector: 'add-invite-count',
    styles: [require('./addInviteCount.scss')],
    template: require('./addInviteCount.html'),
})

export class AddInviteCountComponent implements OnInit, OnChanges {
    @Input() stack?: Stack;

    constructor(
        private inviteCountService: AddInviteCountService,
        private invitationOptionsService: InvitationOptionsService
    ) {
    }

    ngOnInit() {
        this.inviteCountService.stack = this.stack;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.inviteCountService.stack = this.stack;
    }
}
