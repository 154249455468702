import { Action } from '@ngrx/store';
import { DesignKeysForStack } from '../../../EventsModule/EmailCommunication/InvitationsConfirmations/ICRowItem/ic-row-item.component';

export const FETCH_DESIGNS = '[Event] Fetch Designs';
export const FETCH_DESIGNS_SUCCESS = '[Event] Fetch Designs Success';
export const FETCH_DESIGNS_FAIL = '[Event] Fetch Designs Fail';
export const SET_DESIGNS = '[Event] Set Designs';
export const SET_DESIGNS_SUCCESS = '[Event] Set Designs Success';
export const SET_DESIGNS_FAIL = '[Event] Set Designs Fail';

export class FetchDesigns implements Action {
    readonly type = FETCH_DESIGNS;
    constructor(public payload?: any) { }
}

export class FetchDesignsSuccess implements Action {
    readonly type = FETCH_DESIGNS_SUCCESS;
    constructor(public payload?: any) { }
}

export class FetchDesignsFail implements Action {
    readonly type = FETCH_DESIGNS_FAIL;
    constructor(public payload?: any) { }
}

export class SetDesigns implements Action {
    readonly type = SET_DESIGNS;
    constructor(public payload?: DesignKeysForStack) { }
}

export class SetDesignsSuccess implements Action {
    readonly type = SET_DESIGNS_SUCCESS;
    constructor(public payload?: DesignKeysForStack) { }
}

export class SetDesignsFail implements Action {
    readonly type = SET_DESIGNS_FAIL;
    constructor(public payload?: any) { }
}

export type EventAction =
    FetchDesigns |
    FetchDesignsSuccess |
    FetchDesignsFail |
    SetDesigns |
    SetDesignsSuccess |
    SetDesignsFail;
