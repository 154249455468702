import { Component} from '@angular/core';
import { Modal } from '../../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { AddressBookEditGroupComponent } from './addressBookEditGroup.component';
import { AddressBookService } from '../../addressBook.service';

@Component({
    selector: 'address-book-edit-group-modal-wrapper',
    template: '',
})

export class AddressBookEditGroupModalWrapperComponent {
    constructor(
        private modalService: ModalService,
        private addressBookService: AddressBookService,
    ) {}

    ngOnInit() {
        this.openGroupModal();
    }

    openGroupModal() {
        const modal: Modal = new Modal();
        modal.component = AddressBookEditGroupComponent;
        modal.cssClass = 'edit-group';
        modal.data = {};
        this.modalService.changeModal(modal);
    }
}
