import { Component, OnInit, OnDestroy } from '@angular/core';
import { EFXAdminService } from './efx-admin.service';

@Component({
    selector: 'efx-admin',
    template: require('./efx-admin.html'),
    styles: [require('./efx-admin.scss')],
})

export class EFXAdminComponent implements OnInit, OnDestroy {

    constructor(
        private efxAdminService: EFXAdminService
    ) { }

    ngOnInit() {
        this.efxAdminService.enteredAdminPage();
    }

    ngOnDestroy() {
        this.efxAdminService.clearList();
    }

}
