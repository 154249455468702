import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as fromRoot from '../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IconService } from '../../Icons/icons.service';
import { GuestRelatedInvitationsLogService } from './guest-related-invitations-log.service';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';
import * as _ from 'lodash';

@Component({
    selector: 'guest-related-invitations-log',
    providers: [
        GuestRelatedInvitationsLogService
    ],
    template: require('./guest-related-invitations-log.html'),
    styles: [require('./guest-related-invitations-log.scss')],
})

export class GuestRelatedInvitationsLogComponent implements OnInit, OnDestroy {


    constructor(
        private guestRelatedInvitationsLogService: GuestRelatedInvitationsLogService,
        private store: Store<fromRoot.AppState>,
        private iconService: IconService
    ) {
    }
    
    @Input() invitationId: string;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {
        this.guestRelatedInvitationsLogService.getRelatedInvitations(this.invitationId);
    }

    public changePage(page) {
        this.guestRelatedInvitationsLogService.relatedInvitationListOptions.pagination.currentListPage = page;
        this.guestRelatedInvitationsLogService.getRelatedInvitations(this.invitationId);
    }

    private formattedInvitationString(invitation: Invitation) {
        return `${invitation.identifier}`
    }

    ngOnDestroy() {
        this.guestRelatedInvitationsLogService.clearList();
    }
}
