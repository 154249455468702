import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';
import {AngularRestClient} from '../../../_services/rest/AngularRestClient';

import {EventFarmAPIClient} from '../../../ApiClient/event-farm-api-client';
import {ApiError} from '../../../ApiClient/Models/error';
import {Invitation} from '../../../ApiClient/Models/Invitation/invitation';
import {Salutation} from '../../../ApiClient/Models/Salutation/salutation';
import {Stack} from '../../../ApiClient/Models/Stack/stack';
import {TicketBlock} from '../../../ApiClient/Models/Stack/ticket-block';
import {EventFarmService} from '../../eventFarm.service';
import {BasicUserDetails} from '../UserForm/userForm.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';

@Injectable()
export class GuestListViewService {
    public counts$ = new BehaviorSubject({});
    private allStatuses = ['assigned', 'purchased', 'confirmed-by-rsvp', 'declined-by-rsvp', 'left-behind', 'registered', 'unconfirmed', 'not-yet-purchased', 'not-yet-registered'];
    public currentEventOrTicketBlockId;
    public access: any = {};
    public parameters: any = {
        currentDataRelationships: ['UserIdentifiers', 'StackAndTicketType', 'RelatedInvitation'],
        currentUserAttributes: ['info', 'custom'],
        lastModifiedTimestamp: 0,
        isCheckedIn: null,
        sortBy: 'first-name',
        sortDirection: 'ascending',
        activeQuery: '',
        pagination: {
            page: 1,
            itemsPerPage: 20,
            nextPage: '',
            prevPage: '',
        },
        statuses: this.allStatuses,
        stackIds: null
    };
    public guests: any = [];
    public guestsMeta: any = {};
    // Reflects total guests on guest list, not subject to changing based on applied filters
    public totalRecords: number = 0;
    public ticketBlock: TicketBlock;
    public inviteChangedSource = new Subject<void>();
    public availableStatusSource = new Subject<any>();
    public apiActionType: 'ListInvitationsForTicketBlock' | 'ListInvitationsForEvent' = 'ListInvitationsForEvent';

    public readonly invitations$ = new BehaviorSubject<any>({});
    public inviteChanged$ = this.inviteChangedSource.asObservable();
    private _salutations: Salutation[];
    public salesForceStatus: boolean = false;

    get isQueryApplied(): boolean {
        return this.parameters.activeQuery !== '';
    }

    get isSessionTB(): boolean {
        return _.get(this, 'ticketBlock.isSessionTB', false);
    }

    public setStatusesToAll() {
        this.parameters.statuses = this.allStatuses;
    }


    constructor(
        private http: AngularRestClient,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient
    ) {
        this._salutations = [];
        this.fetchSalutations();
    }

    public resetGuestList() {
        this.totalRecords = 0;
        this.guests = [];
        this.resetInvitationsSub();
        this.counts$.next({});
    }

    get currentPage(): number {
        return this.parameters.pagination.page;
    }
    get itemsPerPage(): number {
        return this.parameters.pagination.itemsPerPage;
    }
    get isOnLastPage(): boolean {
        return this.parameters.pagination.page === this.totalPages || this.totalPages === 0 || this.totalPages === 1;
    }
    get totalPages(): number {
        return Math.ceil(this.guestsMeta?.totalResults / this.parameters.pagination.itemsPerPage);
    }
    get stacks(): Stack[] {
        return this.eventFarmService.currentEvent.stacks;
    }

    get salutations(): Salutation[] {
        return this._salutations;
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get currentEvent(): EfEvent {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent : null;
    }

    get isTicketBlock(): boolean {
        return this.ticketBlock ? true : false;
    }

    public resetInvitationsSub(){
        this.invitations$.next({
            access: this.access,
            error: false,
            invitations: this.guests,
            loaded: false,
        });
    }

    public resetQueryParams() {
        this.parameters.isCheckedIn = null;
        this.parameters.sortBy = 'first-name';
        this.parameters.sortDirection = 'ascending';
        this.parameters.activeQuery = '';
        this.parameters.pagination.page = 1;
        this.parameters.pagination.itemsPerPage = 20;
        this.parameters.pagination.nextPage = '';
        this.parameters.pagination.prevPage = '';
        this.parameters.statuses = this.allStatuses;
        this.parameters.stackIds = null;
    }

    public async getSalesForceStatus() {
        if (!this.isTicketBlock) {
            const status = await this.apiClient.getUseCaseFactory().Salesforce().GetSalesforceStatusForEvent(this.eventFarmService.currentEvent.id).toPromise();
            this.salesForceStatus = status.data.isConnected;

        } else {
            this.salesForceStatus = false;
        }
    }

    fetchSalutations(): void {
        this.apiClient.getUseCaseFactory()
            .Salutation()
            .GetAllSalutations()
            .subscribe((res) => {
                this._salutations = [];
                for (const salutation of res.data) {
                    this._salutations.push(Salutation.fromApiResponse(salutation));
                }
            });
    }

    async assembleCounts() {
        const isTicketBlock = this.isTicketBlock;
        try {
            const data = await this.eventFarmService.fetchGuestCountsForEventOrTicketBlock(this.currentEventOrTicketBlockId, isTicketBlock);
            this.totalRecords = data.uniqueInvitationCount
            this.counts$.next(data);
        } catch (error) {

        }

    }


    public async  assembleGuestListData() {
        await this.assembleCounts();
        await this.fetchGuestListForEventOrTicketBlock();
    }

    async fetchGuestListForEventOrTicketBlock(
    ) {
        if (!this.currentEventOrTicketBlockId) {
            return;
        }

        this.invitations$.next({
            access: this.access,
            error: false,
            invitations: this.guests,
            loaded: false,
        });

        try {
            const res = await this.apiClient.getUseCaseFactory().Invitation()[this.apiActionType](
                this.currentEventOrTicketBlockId,
                this.parameters.currentDataRelationships,
                this.parameters.currentUserAttributes,
                this.parameters.activeQuery,
                this.parameters.statuses,
                this.parameters.lastModifiedTimestamp,
                this.parameters.isCheckedIn,
                this.parameters.sortBy,
                this.parameters.sortDirection,
                this.parameters.pagination.page,
                this.parameters.pagination.itemsPerPage,
                null,
                null,
                this.parameters.stackIds
            ).toPromise();
            this.guests = res.data.map(d => Invitation.fromApiResponse(d));

            this.invitations$.next({
                loaded: true,
                error: false,
                invitations: this.guests,
                access: this.access,
            });
            this.guestsMeta = res.meta;

        } catch (err) {
            this.invitations$.next({
                loaded: true,
                error: true,
                invitations: this.guests,
                access: this.access,
            });
        }
    }

    changeInvitationStatus(invitationId: string, status) {
        return this.apiClient.getUseCaseFactory().Invitation().ChangeInvitationStatus(invitationId, status).toPromise();
    }

    exportInvitationToSalesforce(invitationId: string) {
        return this.apiClient.getUseCaseFactory().Salesforce().ExportInvitationToSalesforce(invitationId).toPromise();
    }

    fetchInvitationDetails(invitationId: string) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .GetInvitation(
                invitationId,
                ['User', 'UserName', 'UserIdentifier', 'Stack', 'TicketType', 'Event'],
            );
    }

    fetchInvitationResponses(invitationId: string) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .GetInvitation(
                invitationId,
                ['QuestionResponse'],
                []
            );
    }

    changeInvitationEmail(targetInvitationId: string, targetUserId: string, newEmail: string) {
        return this.apiClient.getUseCaseFactory().User().SetEmailForInvitation(targetInvitationId, this.eventFarmService.currentUser.id, targetUserId, newEmail);
    }


    async recindAll() {
        await this.apiClient.getUseCaseFactory().Invitation().RescindAllInvitations(this.eventFarmService.currentEvent.id).toPromise();
        if (this.isTicketBlock) {
            await this.assembleGuestListData();
        } else {
            await this.assembleGuestListData();
        }
    }

    async suspendAll() {
        await this.apiClient.getUseCaseFactory().Virbela().SuspendAllUsersForVirbela(this.eventFarmService.currentEvent.id).toPromise();
    }

    async resendAllInvitationsForTicketBlock() {
        await this.apiClient.getUseCaseFactory().Invitation().ResendAllTicketBlockInvitationEmails(this.ticketBlock.id).toPromise();
    }

    updateInvitation(updateInvitation: UpdateInvitationCommand, userDetails: BasicUserDetails, stackId: string) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .UpdateInvitation(
                updateInvitation.invitationId,
                stackId,
                updateInvitation.invitationStatus,
                userDetails.company,
                userDetails.position,
                userDetails.email,
                userDetails.firstName,
                userDetails.lastName,
                userDetails.other
            )
            .toPromise();
    }

    setCheckInNotes(invitation, note) {
        if (note === '') {
            note = null;
        }
        return this.apiClient.getUseCaseFactory().Invitation().SetCheckInNotes(invitation.id, note).toPromise();
    }

    setCheckInForGuest(invitationId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().CheckIn(invitationId, null, true).toPromise();
    }

    undoCheckInForGuest(invitationId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().UndoCheckIn(invitationId).toPromise();
    }

    setInvitationNotes(invitation, note) {
        if (note == '') {
            note = null;
        }
        return this.apiClient.getUseCaseFactory().Invitation().SetInvitationNotes(invitation.id, note).toPromise();
    }

    setInvitationCount(invitationId: string, count: number) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .ChangeInviteCount(invitationId, count)
            .toPromise();
    }

    setArrivalAlertAndEmails(invitationId: string, emails: string[]|null, shouldSendArrivalAlert: boolean|null, phoneNumbers: string[]|null) {
        return this.apiClient.getUseCaseFactory().Invitation().SetArrivalAlertEmailsAndPhoneNumbers(invitationId, emails, shouldSendArrivalAlert, phoneNumbers).toPromise();
    }

    public extractErrorsIntoMessages(err): ApiError[] {
        const formattedErrors: any[] = [];
        if (err.errors && err.errors.length) {
            err.errors.forEach((error) => {
                formattedErrors.push({
                    detail: error.detail
                });
            });
        } else if (err.length) {
            err.forEach((error) => {
                formattedErrors.push({
                    detail: error.detail
                });
            });
        }
        return ApiError.fromApiErrorArray(formattedErrors);
    }
}

export interface UpdateInvitationCommand {
    invitationId: string;
    invitationStatus: string;
}
