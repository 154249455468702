import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Operator } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { User } from '../../../ApiClient/Models/User/user';
import { EventFarmService } from '../../eventFarm.service';
import { UserImport } from '../../../ApiClient/Models/Import/user-import';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { AlertService } from '../../eventFarmAlert.service';
import { Group } from '../../../ApiClient/Models/Group/group';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';

@Injectable()
export class UploaderService {
    readonly importResponseTypes: string[] = ['GoodRecords', 'DuplicateRecords', 'ErrorRecords', 'ImportFailureRecords'];

    public group: Group;
    public selectedStack: Stack;
    public userImport: UserImport;

    public hasInvitations: boolean = false;
    public hasSentInvitations: boolean = false;
    public isEventContext: boolean = false;
    public newGroupName: string;

    constructor(
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private apiClient: EventFarmAPIClient,
        private route: ActivatedRoute,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    fetchUserImport(userImportId: string) {
        return this.apiClient.getUseCaseFactory()
            .Import()
            .GetUserImport(
                userImportId,
                this.importResponseTypes
            );
    }

    cancelUpload(skipWarning: boolean = false) {

        if (!skipWarning) {
            this.alertService.uploader().exitAndCancelUpload()
                .then(() => this.navigateOutOfUploader())
                .catch(() => {});
        } else {
            this.navigateOutOfUploader();
        }
    }

    navigateOutOfUploader() {
        const eventId = this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.id : null;
        const poolId = this.eventFarmService.currentTeam.id;

        if (eventId) {

            if (this.eventFarmService.currentEvent.isTypeFullPlatform || this.eventFarmService.currentEvent.isTypeCio ) {
                this.router.navigateByUrl(this.routeGenerator.url('events.info', { poolId, eventId }));
            } else {
                // if sessions navigate to guest list
                this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId: this.eventFarmService.currentTeam.id, eventId: this.eventFarmService.currentEvent.id }));
            }

        } else {
            this.router.navigateByUrl(this.routeGenerator.url('pools.address-book.list', { poolId: this.eventFarmService.currentTeam.id }));
        }
    }

    confirmExistingGroup(): Promise<any> {
        return this.alertService.uploader().mergeIntoExistingGroup();
    }

    setExistingEventFromUrl() {
        this.route.queryParams.subscribe((queryParams) => {
            const eventId = queryParams['eventId'] || null;
            const hasLocalEvent = (this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id);

            if (eventId != null) {
                this.uploaderIsForEvent(true);
            }

            if (!hasLocalEvent && eventId != null) {
                this.eventFarmService.updateEventDetails(eventId);
            }
        });
    }

    uploaderIsForEvent(isEventContext: boolean = false) {
        this.isEventContext = isEventContext;
    }

    setExistingUserImportFromUrl() {
        this.route.queryParams.subscribe((queryParams) => {
            let userImportId = queryParams['userImportId'] || null;

            if (userImportId != null) {
                this.fetchUserImport(userImportId)
                    .subscribe(
                        (val) => {
                            this.userImport = UserImport.fromApiResponse(val.data);
                        }
                    );
                }
            }
        );
    }

    fetchFileFromUpload(csvFileId: string, type: 'good'|'duplicate'|'error'|'import-failure') {
        let fileName = 'import-records_' + type.toUpperCase();

        this.apiClient.getUseCaseFactory().Import().GetUserImportFile(
            this.userImport.id,
            csvFileId,
        )
            .subscribe(
            (data) => {
                this.downloadFile(data, fileName);
            },
            (error) => {
                // console.log('Error downloading the file.', error);
            }
        );
    }

    private downloadFile(data: string, fileName: string) {
        const blob = new Blob([data], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        document.body.appendChild(a);

        a.href = url;
        a.download = fileName;
        a.click();

        window.URL.revokeObjectURL(url);
    }
}
