import { Component, Input } from '@angular/core';
import { MappingService } from './mapping.service';

@Component({
    selector: 'custom-field-mapping',
    template: require('./customFieldMapping.html'),
})

export class CustomFieldMappingComponent {

    constructor(private _ms: MappingService) {
    }

    @Input() row;
    @Input() flow;
    @Input() efLeadFields;
    @Input() intLeadFields;
    @Input() i;

}
