import { Component, Input, OnInit } from '@angular/core';
import * as _ from "lodash";
import { Stack } from '../../../../../ApiClient/Models/Stack/stack';
import { IconService } from './../../../../CoreUI/Icons/icons.service';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';


import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { EventFarmService } from '../../../../eventFarm.service';

@Component({
    selector: '[stack-row-item]',
    template: require('./stack-row-item.html'),
    styles: [require('./stack-row-item.scss')],
})

export class StackRowItemComponent implements OnInit {
    private stackOptions: Stack;
    @Input() stacks: Stack[];

    private stackStatusColor;
    private currentTime = + new Date() / 1000;

    constructor(
        private iconService: IconService,
        private store: Store<fromRoot.AppState>,
        private eventfarmService: EventFarmService
        ) { }

    ngOnInit() {
        this.stackOptions = this.stacks[0];
        this.getStackStatusColor();
    }


    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    setCurrencyFormat(value, showFree = false) {
        return value && value !== '0px' ? Number(value).toLocaleString('en-US', { style: 'currency', currency: this.eventfarmService.currentEvent.currency.slug.toUpperCase() }) : showFree ? 'Free' : 'n/a';
    }

    get stackQuantity(): number {
        if (this.stacks.length > 1) {
            return this.stackOptions.ticketType.quantity;
        }

        return this.stackOptions.quantity;
    }

    dateFormatted(isOpeningAt:boolean, isDate:boolean) {
        if (isOpeningAt) {
            if (isDate) {
                return _.get(this.stackOptions, 'openingAtFormatted.date.medium', 'No start time set');
            }
            return _.get(this.stackOptions, 'openingAtFormatted.time.medium', '');
        } else {
            if (isDate) {
                return _.get(this.stackOptions, 'closingAtFormatted.date.medium', 'No start time set');
            }
            return _.get(this.stackOptions, 'closingAtFormatted.time.medium', '');
        }
    }

    getStackStatusColor() {

        let duringEvent;

        // if stack options is undefined
        if (this.stackOptions === undefined) {
            return this.stackStatusColor = { borderLeft: '4px red solid' };
        }

        if (this.stackOptions.closingAt && this.stackOptions.openingAt) {
            duringEvent = this.stackOptions.openingAt < this.currentTime && this.stackOptions.closingAt > this.currentTime;
            if (_.get(this, 'stackOptions.openingAt') > this.currentTime) {
                return this.stackStatusColor = { borderLeft: '4px orange solid' };
            }
            if (_.get(this, 'stackOptions.closingAt') < this.currentTime) {
                return this.stackStatusColor = { borderLeft: '4px red solid' };
            }
        } else {
            duringEvent = true;
        }

        if (this.stacks.length === 1) {
            if (duringEvent && this.stackOptions.availabilityCounts.percentUsed !== 100) {
                return this.stackStatusColor = { borderLeft: '4px green solid' };
            }
            if (duringEvent && this.stackOptions.availabilityCounts.percentUsed === 100) {
                return this.stackStatusColor = { borderLeft: '4px red solid' };
            }
        } else {
            if (duringEvent) {
                let showRed = true;

                this.stacks.every(s => {
                    if (s.availabilityCounts.percentUsed !== 100) {
                        showRed = false;
                        return false;
                    }

                    return true;
                });

                if (!showRed) {
                    return this.stackStatusColor = { borderLeft: '4px green solid' };
                }

                return this.stackStatusColor = { borderLeft: '4px red solid' };
            }
        }
    }
}
