import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import * as eventActions from '../../store/actions/event';
import { map } from 'rxjs/operators';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { Promo, PromoAccessType } from '../../../ApiClient/Models/Promotion/promo';

import { EventAccessService } from '../EventAccess/event-access.service';
import { ProcessingCurrencyTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';

@Injectable()
export class PaymentsPromotionsService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private segmentService: SegmentService,
    ) {
    }


    public promosListOptions: PromosListOptions = {
        sortBy: 'createdAt',
        sortDirection: 'descending',
        pagination: {
            currentPromosPage: 1,
            itemsPerPage: 20,
        }
    };



    public promoMetaSource = new BehaviorSubject<any>({});
    public promosMeta$ = this.promoMetaSource.asObservable();
    public readonly currentPromoCodes$ = new BehaviorSubject<Promo[]>([]);

    public promoAccessTypes: PromoAccessType[] = [];
    public isLoadingPromoCodes: boolean = false;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    public clearList() {
        this.currentPromoCodes$.next([]);
        this.promoAccessTypes = [];
        this.promoMetaSource.next(null);
        this.promosListOptions.pagination.currentPromosPage = 1;

    }

    get currency(): ProcessingCurrencyTypeInterface {
        return this.eventFarmService.currentEvent.currency;
    }

    get promoCodeCount(): number {
        return this.currentPromoCodes$.value.length;
    }

    public fetchAccessTypesForPromotions() {
        this.promoAccessTypes = this.eventFarmService.currentEvent.stacks.map(stack => {
            return { id: stack.id, name: stack.ticketType.name, type: stack.stackMethodType, cost: stack.price + stack.serviceFee };
        });
    }


    public async getPromotions() {

        this.isLoadingPromoCodes = true;
        const eventId = this.eventFarmService.currentEvent.id;

        try {
            const promotions = await this.apiClient.getUseCaseFactory()
            .Promotion()
            .ListPromotionsForEvent(
                this.eventFarmService.currentEvent.id,
                ['StackAndTicketType'],
                this.promosListOptions.sortBy,
                this.promosListOptions.sortDirection,
                this.promosListOptions.pagination.currentPromosPage,
                this.promosListOptions.pagination.itemsPerPage
            ).toPromise();

            this.promoMetaSource.next(promotions.meta);
            this.currentPromoCodes$.next(promotions.data.map(promo => Promo.fromApiResponse(promo, this.currency.slug)));
            this.isLoadingPromoCodes = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingPromoCodes = false;
            return false;
        }
    }

    public async createOrUpdatePromotion(promo: Promo, stackIdsToBeSaved: string[], currentPromoId: string = null) {

        let createdPromo;

        try {
            if (!currentPromoId) {
                createdPromo = await this.apiClient
                .getUseCaseFactory()
                .Promotion()
                .CreatePromotion(
                    this.eventFarmService.currentEvent.id,
                    promo.type.slug,
                    promo.code.replace(/\s+/g, ''),
                    promo.startTimeFormatted.mutable || promo.startTimeFormatted.short,
                    promo.endTimeFormatted.mutable || promo.endTimeFormatted.short,
                    promo.amount,
                    0,
                    promo.message,
                    promo.maximum,
                    promo.isEnabled
                ).toPromise();
                this.segmentService.segmentPromotionsTracking().generatePromotion(promo);
            } else {
                createdPromo = await this.apiClient
                .getUseCaseFactory()
                .Promotion()
                .UpdatePromotion(
                    currentPromoId,
                    promo.type.slug,
                    promo.code.replace(/\s+/g, ''),
                    promo.startTimeFormatted.mutable || promo.startTimeFormatted.short,
                    promo.endTimeFormatted.mutable || promo.endTimeFormatted.short,
                    promo.amount,
                    promo.used,
                    promo.message,
                    promo.maximum,
                    promo.isEnabled
                ).toPromise();
                this.segmentService.segmentPromotionsTracking().updatePromotion(promo);
            }

            const savedStacksForPromo = await this.apiClient.getUseCaseFactory().Promotion().SetStacksForPromotion(createdPromo.data.command.promotionId, stackIdsToBeSaved).toPromise();
            return true;

        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async deletePromo(id: string) {
        try {
            const deletedPromo = await this.apiClient.getUseCaseFactory().Promotion().RemovePromotion(id).toPromise();
            this.segmentService.segmentPromotionsTracking().deletePromotion({id});

            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }
}

export interface Pagination {
    currentPromosPage: number;
    itemsPerPage: number;
}

export interface PromosListOptions {
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}
