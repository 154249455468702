import {createSelector} from "@ngrx/store";
import {getCurrentEvent} from "./index";

export const getStackAvailabilityCounts = createSelector(
    getCurrentEvent,
    (event) => {
        if (event.stacks && event.stacks.data) {
            const res = {}
            Object.keys(event.stacks.data).forEach(key => {
                res[key] = event.stacks.data[key].availabilityCounts
            })
            return res;
        }
    }
);