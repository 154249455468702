import { AbstractModel } from '../abstract-model';
import { Team } from '../Pool/team';
import { Stack } from '../Stack/stack';
import { TicketBlock } from '../Stack/ticket-block';
import { TicketType } from '../Stack/ticket-type';
import { Tag } from './tag';
import { Venue } from './venue';
import { VirbelaWorld } from '../VirbelaWorld/virbela-world';
import { EventText } from './eventText';
import { FormattedDateTime } from '../formatted-datetime';
import { EventTheme } from './eventTheme';
import {
  EventMessageTypeInterface,
  TrackingScriptTypeInterface,
  Event,
  ProcessingCurrencyTypeInterface,
  EventTypeInterface,
  VariantTypeInterface
} from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
import { WebConferenceFormatTypeInterface, WebConferenceSourceTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/WebConference';
import { User } from '../User/user';
import _ = require("lodash");
import { Question } from '../Question/question';
import { UserField, UserFields } from './user-field';
import { EventLink } from '../Link/event-link';
import { EFLink } from '../Link/ef-link';
import { PoolPaymentGateway } from '../PoolPaymentGateway/poolPaymentGateway';


export class EfEvent extends AbstractModel {
  private _name?: string;
  private _created?: any;
  private _modified?: any;

  private _altKeyword: string;
  private _altLayout: string;
  private _altEmailLayout: string;
  private _altProcessor: string;
  private _altPaymentFile: string;
  private _altPayee: string;

  private _venue?: Venue;
  private _team?: Team;
  private _tags: Tag[];
  private _stacks: Stack[];
  private _virbelaWorld: VirbelaWorld;
  private _eventAttributes?: any;
  private _cardOptions?: any;
  private _ticketTypes: TicketType[];
  private _ticketBlocks: TicketBlock[];
  private _questions: Question[];
  // @todo 'listly' is deprecated and should be removed when we fully leave CakePhp behind.
  private _type?: EventTypeInterface;
  private _variant: VariantTypeInterface;
  private _mapSourceType: string;
  private _contactEmail: string;
  private _eventEmail: string;
  private _description?: string;
  private _timezone?: string;
  private _startTime: FormattedDateTime;
  private _endTime: FormattedDateTime;
  private _eventTexts?: EventText[];
  private _thumbnailUrl?: string;
  private _isCanvas: boolean;
  private _maxInvitationCount: number;
  private _minInvitationCount: number;
  private _defaultSitePage?: string;
  private _virbelaSitePage?: string;
  private _language?: string;
  private _efxEnabled: boolean;
  private _eventTheme?: EventTheme;
  private _eventTrackingScripts?: TrackingScriptTypeInterface[];
  private _eventMessageTypes?: EventMessageTypeInterface[];
  private _userFields?: UserField[];
  private _isShield: boolean;
  private _publicPaths: Paths;
  private _virbelaPublicPaths?: VirbelaPaths;
  private _modalPaths: Paths;
  private _processingCurrency: ProcessingCurrencyTypeInterface;
  private _processingPerc: number;
  private _processingFee: number;
  private _processingRefund: number;

  private _hasParentEvent: boolean;
  private _parentEventId: string;
  private _parentEventData: any;
  private _waitListSMS: string;
  private _webConferenceId?: string;
  private _webConferenceSourceType?: WebConferenceSourceTypeInterface;
  private _webConferenceFormat?: WebConferenceFormatTypeInterface;
  private _webConferenceAuthUser?: User;
  private _healthPassShortCode?: string;


  private _eventLinks: EventLink[];
  private _instagramHandle: EFLink|null;
  private _twitterHandle: EFLink|null;
  private _facebookHandle: EFLink|null;
  private _poolPaymentGatewayId?: string|null;
  private _poolPaymentGateway?: PoolPaymentGateway|null;

  constructor(id?: string) {
    super(id);
  }

  public static forChildCreation(data): EfEvent {
    const event = new this();

    try {
      const { name, endTime, startTime, description, locationName, locationAddress } = data;
      event._name = name;
      event._startTime = startTime;
      event._endTime = endTime;
      event._description = description;
      event._venue = Venue.fromChildCreation({ name: locationName, address: locationAddress });
    } catch (err) {
    }
    return event;

  }

  public static fromApiResponse(data): EfEvent {
    const event = new this(data.id);

    try {
      event._created = data.attributes.created;
      event._modified = data.attributes.modified;
      event._userFields = UserFields.fromApi(data.attributes.userFields);

      event._name = data.attributes.name;
      event._altKeyword = data.attributes.altKeyword;
      event._altLayout = data.attributes.altLayout;
      event._altEmailLayout = data.attributes.altEmailLayout;
      event._altProcessor = data.attributes.altProcessor;
      event._altPaymentFile = data.attributes.altPaymentFile;
      event._altPayee = data.attributes.altPayee;
      event._eventAttributes = data.attributes.eventAttributes;
      event._cardOptions = data.attributes.cardOptions;
      event._venue = data.attributes.venue ? Venue.fromEventResponse(data.attributes.venue) : null;
      event._type = data.attributes.type;
      event._variant = data.attributes.variant;
      event._mapSourceType = data.attributes.mapSourceType.name;
      event._contactEmail = data.attributes.contactEmail;
      event._eventEmail = data.attributes.email;
      event._description = data.attributes.eventDescription;
      event._startTime = data.attributes.startTime;
      event._timezone = data.attributes.timezone;
      event._endTime = data.attributes.endTime;
      event._isCanvas = data.attributes.isCanvas || false;
      event._defaultSitePage = data.attributes.defaultSitePage;
      event._virbelaSitePage = data.attributes.virbelaSitePage;
      event._minInvitationCount = data.attributes.minInvitationCount || 0;
      event._maxInvitationCount = data.attributes.maxInvitationCount || 0;
      event._language = data.attributes.language;
      event._efxEnabled = data.attributes.isEFxEnabled ? data.attributes.isEFxEnabled : false;
      event._isShield = data.attributes.isShield ? data.attributes.isShield : false;
      event._modalPaths = data.attributes.modalUrls;
      event._publicPaths = data.attributes.publicUrls;
      event._virbelaPublicPaths = data.attributes.publicVirbelaUrls;
      event._hasParentEvent = data.attributes.hasParentEvent;
      event._parentEventId = _.get(data, 'relationships.parentEvent.id') || null;
      event._parentEventData = _.get(data, 'attributes.parentEvent') || null;
      event._questions = [];
      event._waitListSMS = data.attributes.waitListSMS;

      event._webConferenceId = data.attributes.webConferenceId;
      event._webConferenceSourceType = data.attributes.webConferenceSourceType;
      event._webConferenceFormat = data.attributes.webConferenceFormatType;
      event._healthPassShortCode = data.attributes.healthPassShortCode;

      if (data.attributes.webConferenceAuthUser) {
        event._webConferenceAuthUser = User.fromApiResponse(
          data.attributes.webConferenceAuthUser
        );
      }
      const currencyTypes = new Event().ProcessingCurrencyType();

      event._processingCurrency = currencyTypes.filter(type => type.slug === data.attributes.processingCurrency)[0];
      event._processingPerc = data.attributes.processingPerc;
      event._processingFee = data.attributes.processingFee;
      event._processingRefund = data.attributes.processingRefund;

      if (data.attributes.paymentGateway) {
        event._poolPaymentGatewayId = data.attributes.paymentGateway.id;
        event._poolPaymentGateway = PoolPaymentGateway.fromEventResponse(data.attributes.paymentGateway);
      } else {
        event._poolPaymentGatewayId = null;
      }

      if (data.extraAttributes) {
        event._thumbnailUrl = data.extraAttributes.thumbnailUrl;
      }

      if (data.extraAttributes && data.extraAttributes.EventTheme) {
        event._eventTheme = EventTheme.fromApiResponse(
          data.extraAttributes.EventTheme
        );
      }
    } catch (err) { }

    if (data.relationships && data.relationships.pool) {
      event._team = Team.fromApiResponse(data.relationships.pool);
    }

    event._tags = [];

    if (data.relationships && data.relationships.tags) {
      data.relationships.tags.forEach(tag => {
        event._tags.push(Tag.fromApiResponse(tag));
      });
    }

    event._eventLinks = [];

    if (data.relationships && data.relationships.eventLinks) {
      data.relationships.eventLinks.forEach(eventLink => {
        event._eventLinks.push(EventLink.fromApiResponse(eventLink));
      });

      const insta = event._eventLinks.find(el => el.link.linkType.isInstagram);
      const tw = event._eventLinks.find(el => el.link.linkType.isTwitter);
      const fb = event._eventLinks.find(el => el.link.linkType.isFacebook);

      event._instagramHandle = insta !== undefined ? insta.link : null;
      event._facebookHandle = fb !== undefined ? fb.link : null;
      event._twitterHandle = tw !== undefined ? tw.link : null;
    }

    event._stacks = [];

    if (data.relationships && data.relationships.stacks) {
      data.relationships.stacks.forEach(stack => {
        stack = Stack.fromApiResponse(stack);
        if (!stack.isDeleted) {
          event._stacks.push(stack);
        }
      });
    }

    if (data.relationships && data.relationships.virbelaWorld) {
      event._virbelaWorld = VirbelaWorld.fromApiResponse(data.relationships.virbelaWorld.attributes);
    }

    event._ticketTypes = [];

    if (data.relationships && data.relationships.ticketTypes) {
      data.relationships.ticketTypes.forEach(ticketType => {
        ticketType = TicketType.fromApiResponse(ticketType);
        if (!ticketType.isDeleted) {
          event._ticketTypes.push(ticketType);
        }
      });
    }

    event._ticketBlocks = [];

    if (data.relationships && data.relationships.ticketBlocks) {
      data.relationships.ticketBlocks.forEach(ticketBlock => {
        event._ticketBlocks.push(TicketBlock.fromApiResponse(ticketBlock));
      });
    }

    event._eventTexts = [];

    if (data.relationships && data.relationships.eventTexts) {
      event._eventTrackingScripts = new Event().TrackingScriptType();
      event._eventMessageTypes = new Event().EventMessageType();

      data.relationships.eventTexts.forEach(eventText => {

        const { key, value } = eventText.attributes.value;
        const trIdx = event._eventTrackingScripts.findIndex(tr => tr.slug === key);

        if (trIdx !== -1) {
          event._eventTrackingScripts[trIdx].value = value;
        } else {
          const msIdx = event._eventMessageTypes.findIndex(ms => ms.slug === key);
          if (msIdx !== -1) {
            event._eventMessageTypes[msIdx].value = value;
          } else {
            event._eventTexts.push(EventText.fromApiResponse(eventText));
          }
        }
      });
    }

    event._questions = [];

    if (data.relationships && data.relationships.questions) {
      data.relationships.questions.forEach(question => {
        const q = Question.fromApiResponse(question);
        if (!q.isDeleted) {
          event._questions.push(q);
        }
      });
    }

    return event;
  }

  get name() {
    return this._name;
  }

  get created() {
    return this._created;
  }

  get modified() {
    return this._modified;
  }

  get mapSourceType(): string {
    return this._mapSourceType;
  }

  get timezone(): string {
    return this._timezone;
  }

  get altKeyword(): string {
    return this._altKeyword;
  }

  get altLayout(): string {
    return this._altLayout;
  }

  get altEmailLayout(): string {
    return this._altEmailLayout;
  }

  get altProcessor(): string {
    return this._altProcessor;
  }

  get altPaymentFile(): string {
    return this._altPaymentFile;
  }

  get altPayee(): string {
    return this._altPayee;
  }

  get eventAttributes(): any {
    return this._eventAttributes;
  }

  get eventTexts(): EventText[] {
    return this._eventTexts;
  }

  get cardOptions(): any {
    return this._cardOptions;
  }

  get contactEmail(): string {
    return this._contactEmail;
  }

  get startTime(): FormattedDateTime {
    return this._startTime;
  }

  get endTime(): FormattedDateTime {
    return this._endTime;
  }

  get language(): string {
    return this._language;
  }

  get currency(): ProcessingCurrencyTypeInterface {
    return this._processingCurrency;
  }

  get processingFee(): number {
    return this._processingFee;
  }
  get processingPerc(): number {
    return this._processingPerc;
  }
  get processingRefund(): number {
    return this._processingRefund;
  }

  set startTime(value: FormattedDateTime) {
    this._startTime = value;
  }

  set endTime(value: FormattedDateTime) {
    this._endTime = value;
  }

  get tags(): Tag[] {
    return this._tags;
  }

  get team() {
    return this._team;
  }

  get stacks() {
    return this._stacks;
  }

  get ticketTypes() {
    return this._ticketTypes;
  }

  get ticketBlocks() {
    return this._ticketBlocks;
  }

  get hasTicketBlocks() {
    return this.ticketBlocks.filter(tb => !tb.isDeleted).length > 0;
  }

  get isTypeCio(): boolean {
    return this._variant.isCio;
  }

  get isTypeFullPlatform(): boolean {
    return this._variant?.isFull;
  }

  get isAllowFullGuestList(): boolean {
    return this._variant.isAnySessionType || this._variant.isFull;
  }

  get isTypeSession(): boolean {
    return this._variant.isAnySessionType;
  }

  get thumbnailUrl(): any {
    return this._thumbnailUrl;
  }

  get isCanvas(): boolean {
    return this._isCanvas;
  }

  get instagramHandle(): EFLink | null {
    return this._instagramHandle;
  }

  get twitterHandle(): EFLink | null {
    return this._twitterHandle;
  }

  get facebookHandle(): EFLink | null {
    return this._facebookHandle;
  }

  get description(): string | null {
    return this._description;
  }

  get defaultSitePage(): string {
    return this._defaultSitePage;
  }

  get virbelaSitePage(): string {
    return this._virbelaSitePage;
  }

  get maxInvitationCount(): number {
    return this._maxInvitationCount;
  }

  get minInvitationCount(): number {
    return this._minInvitationCount;
  }

  get isEFxEnabled(): boolean {
    return this._efxEnabled;
  }

  get isShield(): boolean {
    return this._isShield;
  }

  get eventTheme(): EventTheme {
    return this._eventTheme;
  }

  get eventTrackingScripts(): TrackingScriptTypeInterface[] {
    return this._eventTrackingScripts;
  }

  get eventMessageTypes(): EventMessageTypeInterface[] {
    return this._eventMessageTypes;
  }

  get questions(): Question[] {
    return this._questions;
  }

  get publicPaths(): Paths {
    return this._publicPaths;
  }

  get userFields(): UserField[] {
    return this._userFields;
  }

  set userFields(value: UserField[]) {
    this._userFields = value;
  }

  get virbelaPublicPaths(): VirbelaPaths | null {
    return this._virbelaPublicPaths;
  }

  get modalPaths(): Paths {
    return this._modalPaths;
  }

  get hasParentEvent(): boolean {
    return this._hasParentEvent;
  }

  get parentEventId(): string {
    return this._parentEventId;
  }
  get parentEvent(): any {
    return this._parentEventData;
  }

  get type(): EventTypeInterface {
    return this._type;
  }

  set type(value: EventTypeInterface) {
    this._type = value;
  }

  get variant(): VariantTypeInterface {
    return this._variant;
  }

  set variant(value: VariantTypeInterface) {
    this._variant = value;
  }

  get waitListSMS(): string {
    return this._waitListSMS;
  }

  get webConferenceId(): string|null {
    return this._webConferenceId;
  }

  get webConferenceSourceType(): WebConferenceSourceTypeInterface|null {
    return this._webConferenceSourceType;
  }

  get webConferenceFormat(): WebConferenceFormatTypeInterface|null {
    return this._webConferenceFormat;
  }

  get webConferenceAuthUser(): User|null {
    return this._webConferenceAuthUser;
  }

  set webConferenceId(value: string) {
    this._webConferenceId = value;
  }

  set webConferenceSourceType(value: WebConferenceSourceTypeInterface|null) {
    this._webConferenceSourceType = value;
  }

  set webConferenceFormat(value: WebConferenceFormatTypeInterface|null) {
    this._webConferenceFormat = value;
  }

  set webConferenceAuthUser(value: User|null) {
    this._webConferenceAuthUser = value;
  }

  get isWebConferenceSetup(): boolean {
    return this._webConferenceAuthUser !== null && this._webConferenceId !== null && this._webConferenceSourceType !== null && this._webConferenceFormat !== null;
  }

  set waitListSMS(value: string) {
    this._waitListSMS = value;
  }

  set name(value: string) {
    this._name = value;
  }

  set timezone(value: string) {
    this._timezone = value;
  }

  set altKeyword(value: string) {
    this._altKeyword = value;
  }

  set stacks(stack: Stack[]) {
    this._stacks = stack;
  }

  set tags(tag: Tag[]) {
    this._tags = tag;
  }

  set ticketTypes(ticketTypes: TicketType[]) {
    this._ticketTypes = ticketTypes;
  }

  set ticketBlocks(ticketBlocks: TicketBlock[]) {
    this._ticketBlocks = ticketBlocks;
  }

  set team(team: Team) {
    this._team = team;
  }

  set eventAttributes(value: any) {
    this._eventAttributes = value;
  }

  set cardOptions(value: any) {
    this._cardOptions = value;
  }

  set mapSourceType(value: string) {
    this._mapSourceType = value;
  }

  set contactEmail(value: string) {
    this._contactEmail = value;
  }

  set defaultSitePage(value: string) {
    this._defaultSitePage = value;
  }

  set virbelaSitePage(value: string) {
    this._virbelaSitePage = value;
  }

  set maxInvitationCount(value: number) {
    this._maxInvitationCount = value;
  }

  set minInvitationCount(value: number) {
    this._minInvitationCount = value;
  }

  set facebookHandle(value: EFLink) {
    this._facebookHandle = value;
  }

  set instagramHandle(value: EFLink) {
    this._instagramHandle = value;
  }

  set twitterHandle(value: EFLink) {
    this._twitterHandle = value;
  }

  set description(value: string) {
    this._description = value;
  }

  set language(value: string) {
    this._language = value;
  }

  set eventTheme(value: EventTheme) {
    this._eventTheme = value;
  }

  set eventTrackingScripts(value: TrackingScriptTypeInterface[]) {
    this._eventTrackingScripts = value;
  }

  set eventMessageTypes(value: EventMessageTypeInterface[]) {
    this._eventMessageTypes = value;
  }

  get healthPassShortCode() {
    return this._healthPassShortCode;
  }

  get eventLinks(): EventLink[] {
    return this._eventLinks;
  }

  get venue(): Venue {
    return this._venue;
  }

  set venue(value: Venue) {
    this._venue = value;
  }

  get poolPaymentGateway(): PoolPaymentGateway | null {
    return this._poolPaymentGateway;
  }

  get poolPaymentGatewayId(): string | null {
    return this._poolPaymentGatewayId;
  }

  set poolPaymentGatewayId(value: string | null) {
    this._poolPaymentGatewayId = value;
  }

  get showSessionTicketBlocks(): boolean {
    const tag = this.tags.find(t => t.slug === 'sessionticketblock')

    if (tag === undefined) {
      return false;
    }

    return true;
  }

  get arePaymentsAllowed(): boolean {
    return !_.get(this, 'eventAttributes.hasPaymentSettingsNotConfigured', false)
  }
}

export interface Paths {
  main: string;
  transfer: string;
  waitlist: string;
  reveal: string;
  contact: string;
  root?: string;
}

export interface VirbelaPaths {
  main?: string;
}
