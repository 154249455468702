import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Observable, of, pipe } from 'rxjs';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { EventsListService } from './eventsList.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, switchMap, shareReplay } from 'rxjs/operators';
import { SessionStorageService } from '../../../_services/storage/session-storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as teamActions from './../../store/actions/team.action';
import { isEqual } from 'lodash';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { User } from '../../../ApiClient/Models/User/user';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { EventFarmService } from '../../eventFarm.service';
import { Modal } from '../../CoreUI/Modal/modal.class';
import { ModalService } from './../../CoreUI/Modal/modal.service';
import { RequestCanvasComponent } from '../../CoreUI/Modal/Modals/RequestCanvas/request-canvas.component';
import { EfEvent } from '../../../ApiClient/Models/Event/event';

@Component({
    selector: 'events-list',
    template: require('./events-list.html'),
    styles: [require('./events-list.scss'),
             require('../../CoreUI/LoadingOverlay/loading-overlay.scss')],
})

export class EventsListComponent implements OnInit, OnDestroy, AfterViewInit {

    private eventListOptions = this.eventFarmService.eventListOptions;

    readonly efLogo = require('./assets/ef-logo');
    readonly caretUp = require('./assets/caret-up');
    readonly caretDown = require('./assets/caret-down');
    readonly noSort = require('./assets/no-sort');
    readonly tileIcon = require('./assets/tile-icon');
    readonly listIcon = require('./assets/list-icon');
    readonly ticketsIcon = require('./assets/tickets-icon');
    readonly checkInIcon = require('./assets/checkin-icon');

    private sortOptions = {
        sortByName: {
            sortIcon: this.noSort,
            sortAscending: true,
        },
        sortByDate: {
            sortIcon: this.caretDown,
            sortAscending: true,
        },
    };

    constructor(
        private eventFarmService: EventFarmService,
        private eventsListService: EventsListService,
        private segmentService: SegmentService,
        private sessionStorageService: SessionStorageService,
        private message: NzMessageService,
        private apiClient: EventFarmAPIClient,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private modalService: ModalService,
        private store: Store<fromRoot.AppState>,

    ) {
        this.userContactType$ = this.store.select(fromRoot.getPoolContactType);

        this.teamUpdate$ = this.teamStore$.subscribe((val) => {
            if (val.data && val.data.id && val.loaded && !isEqual(this.teamStoreData, val.data)) {
                this.teamStoreData = val.data;
                if (this.eventListOptions.teamId !== this.teamStoreData.id) {
                    Object.assign(this.eventListOptions, {teamId: this.teamStoreData.id});
                    this.listFeaturesForPool(this.teamStoreData.id);
                    this.eventsListService.listTicketBlocksForUser(this.eventFarmService.currentUser.id);
                }
                if (this.userStoreData && this.sessionStorageService.get('globalSalesforceIntegrationsAuth&user=' + this.userStoreData.id + '&team=' + this.teamStoreData.id)) {
                    this.message.success(`Completing SalesForce authentication...`);
                    this.router.navigateByUrl(this.routeGenerator.url('pools.my-account', {poolId: this.teamStoreData.id}));
                }
                this.resetTeamViewSettings();
            }
        });

        this.userUpdate$ = this.userStore$.subscribe((val) => {
            if (val.data && val.data.id && val.loaded && !isEqual(this.userStoreData, val.data)) {
                this.userStoreData = val.data;
                this.eventsListService.listTicketBlocksForUser(this.userStoreData.id);
            }
        });
    }

    private teamStore$: Observable<any> = this.store.select('team').pipe(shareReplay());
    private teamStoreData: Team;
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userStoreData: User;
    private featuresForPool: Observable<any[]>;
    private loading: boolean = true;
    private loadingEventId: string;
    private loadingTicketBlockId: string;
    private events$: Subscription;
    private teamUpdate$: Subscription;
    private userUpdate$: Subscription;
    private searchTerm: FormControl = new FormControl();

    private userContactType$: Observable<any>;
    private currentEvents$: Observable<any>;
    private getEventsLoaded$: Observable<any>;
    private getEventsLoading$: Observable<any>;

    ngOnInit() {
        this.store.dispatch(new fromRoot.ResetCurrentEvent());

        this.loadingEventId = '';
        this.loadingTicketBlockId = '';

        this.searchTerm.valueChanges
            .pipe(debounceTime(400),
                distinctUntilChanged())
            .subscribe(term => this.eventSearch(term));

        this.currentEvents$ = this.store.select(fromRoot.getAllEventsForCurrentTeam).pipe(shareReplay());
        this.getEventsLoaded$ = this.store.select(fromRoot.getEventsLoaded);
        this.getEventsLoading$ = this.store.select(fromRoot.getEventsLoading);
    }

    ngAfterViewInit() {
        this.segmentService.segmentEventTracking().loadEventsDashboard();
        this.segmentService.segmentSideNavTracking().allEventsEntered();
    }

    listFeaturesForPool(poolId) {
        this.eventsListService.getFeaturesForPool(poolId)
            .subscribe((res) => {
                this.featuresForPool = of(res);
            });
    }

    toggleView() {
        this.eventListOptions.isListView = !this.eventListOptions.isListView;

        if (this.eventListOptions.isListView) {
            this.segmentService.segmentEventsListTracking()
                .useListView();
        } else {
            this.segmentService.segmentEventsListTracking()
                .useListView();
        }
    }

    setDefaultSortIcons() {
        const keys = Object.keys(this.sortOptions);
        keys.map((key) => {
            if (this.sortOptions[key].sortIcon) {
                this.sortOptions[key].sortIcon = this.noSort;
            }
        });
    }

    sortEvents(sortBy) {
        let sortDirection;
        let sortKey;
        this.setDefaultSortIcons();

        switch (sortBy) {
            case 'name':
                sortKey = 'sortByName';
                break;
            case 'event-start':
                sortKey = 'sortByDate';
                break;
            default:
                sortKey = 'sortByDate';
        }

        this.sortOptions[sortKey].sortAscending = !this.sortOptions[sortKey].sortAscending;
        sortDirection = this.sortOptions[sortKey].sortAscending ? 'ascending' : 'descending';
        Object.assign(this.eventListOptions, {'sortDirection': sortDirection, 'sortBy': sortBy});
        if (this.sortOptions[sortKey].sortAscending) {
            this.sortOptions[sortKey].sortIcon = this.caretDown;
        } else {
            this.sortOptions[sortKey].sortIcon = this.caretUp;
        }

        this.refreshEvents();
    }

    refreshEvents() {
        this.store.dispatch(new fromRoot.FetchEventsForTeam());
    }

    refreshTicketBlocks() {
        this.eventsListService.listTicketBlocksForUser(this.userStoreData.id);
    }

    goToEvent(event): void {
        this.loadingEventId = event.id;
        this.eventsListService.goToEvent(event);
    }

    goToTicketBlock(ticketBlock): void {
        this.loadingTicketBlockId = ticketBlock.id;
        this.eventsListService.goToTicketBlock(ticketBlock);
        this.segmentService.segmentEventTracking().loadEvent();
    }

    resetTeamViewSettings() {
        Object.assign(this.eventsListService.eventListOptions, {
            attributesFilter: null,
            attributesExcludeFilter: ['archived'],
            sortBy: 'event-start',
            sortDirection: 'ascending',
            eventDateFilterType: this.eventsListService.DEFAULT_FILTER_TYPE,
            query: null,
            pagination: {
                currentEventsPage: 1,
                currentTicketBlocksPage: 1,
                resultsPerPage: 15,
            },
            tags: null
        });

        this.eventsListService.currentTagFiltersSource.next([]);
        this.searchTerm.reset();

        if (this.eventsListService.eventListOptions && this.eventsListService.eventListOptions.teamId !== this.teamStoreData.id) {
            this.eventsListService.eventListOptions.teamId = this.teamStoreData.id;
        }
    }

    eventSearch(term): void {
        let searchTerm;
        Object.assign(this.eventListOptions, {'query': term});

        if (term) {
            searchTerm = term ? `Searching Events for ${term}` : 'Loading Events...';
        }
        this.refreshEvents();

    }

    showCanvasMessage = this.store.pipe(
        filter(store => store.user.poolContactType.loaded),
        switchMap((store) => {
            return of(!(store.team.features.data.filter(feature => feature.relationships.feature.attributes.name === 'Canvas').length === 1)
                && store.team.features.loaded
                && store.user.poolContactType[store.router.state.params.pool].isFull
            );
        })
    );

    popoverText(e: EfEvent) {
        return e.venue ? `${e.venue.name} ${e.venue.address}` : '';
    }

    requestCanvas(e) {
        e.preventDefault();
        const modal: Modal = new Modal();
        modal.title = 'Request Early Access';
        modal.component = RequestCanvasComponent;
        modal.data = {};

        this.modalService.changeModal(modal);
    }

    ngOnDestroy() {
        this.teamUpdate$.unsubscribe();
        this.userUpdate$.unsubscribe();
    }
}
