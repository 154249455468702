import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GuestActivityLogComponent } from './guest-activity-log.component';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { GuestActivityLogService } from './guest-activity-log.service';

import {PaginationModule} from 'ngx-bootstrap/pagination'

import { IconService } from '../../Icons/icons.service';

const forExport = [
    GuestActivityLogComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        PaginationModule,
    ],
    providers: [
        GuestActivityLogService,
        IconService
    ]
})

export class GuestActivityLogModule {
}