import { Component, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EfxStationService } from '../../efx-station.service';
import { EFxScreen } from '../../../../../../ApiClient/Models/EfxStation/efxScreen';
import { FormControl, FormGroup } from '@angular/forms';
import _ = require('lodash');


@Component({
    selector: 'screen-configure',
    template: require('./screen-configure.html'),
    styles: [require('./screen-configure.scss'), require('../../efx-station.scss')]
})

export class ScreenConfigureComponent implements OnInit, OnDestroy {

    constructor(
        private efxStationService: EfxStationService,
        private message: NzMessageService
    ) {}

    @Input() screen: EFxScreen;
    
    

    public async saveSmsImage(image) {

        if (image) {
            const imageUrl = await this.efxStationService.uploadPoolImage(image);
            this.efxStationService.stationScreens[this.screen.screenType.slug]['image'] = imageUrl;
        } else {
            this.efxStationService.stationScreens[this.screen.screenType.slug]['image'] = null;
        }
    }

    public imageSet($event) {
        // TODO What to do with value
    }

    ngOnInit(): void {
        if (!this.efxStationService.stationScreens[this.screen.screenType.slug]) {
            this.efxStationService.stationScreens[this.screen.screenType.slug] = {
                screenMessage: _.get(this, 'screen.text', ''),
                color: _.get(this, 'screen.textColor', '#ffff'),
                image: _.get(this, 'screen.backgroundUrl' ),
                id: _.get(this, 'screen.id' )
            }
        }
    }

    

    ngOnDestroy() {
    }
}
