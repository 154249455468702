import {AbstractModel} from '../abstract-model';
import {get} from 'lodash';

export class PoolFeature extends AbstractModel {

    private _name?: string;
    private _featureName?: string;
    private _isEnabled?: boolean;
    private _featureId?: string;

    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data): PoolFeature {
        const feature = new this(data.id);
        feature._featureName = get(data, 'attributes.name', '')
        //TODO re-visit if this is used
        feature._name = get(data, 'relationships.feature.attributes.name', '')
        feature._isEnabled = get(data, 'attributes.isEnabled', false)
        feature._featureId = get(data, 'relationships.feature.id', false)

        return feature;
    }

    get name(): string {
        return this._name;
    }

    get featureName(): string {
        return this._featureName;
    }

    get featureId(): string {
        return this._featureId;
    }

    get isEnabled(): boolean {
        return this._isEnabled;
    }


}
