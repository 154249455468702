import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuestSimpleStatsModule } from '../GuestSimpleStats/guestSimpleStats.module';
import { TicketTypeListModule } from '../TicketTypeList/ticketTypeList.module';
import { GuestOverviewComponent } from './guestOverview.component';

@NgModule({
    declarations: [GuestOverviewComponent],
    exports: [GuestOverviewComponent],
    imports: [
        CommonModule,
        GuestSimpleStatsModule,
        TicketTypeListModule,
    ],
})

export class GuestOverviewModule {
}
