import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivityLogService } from './activity-log.service';
import * as fromRoot from '../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IconService } from '../../../CoreUI/Icons/icons.service';

@Component({
    selector: 'activity-log',
    providers: [
        ActivityLogService
    ],
    template: require('./activity-log.html'),
    styles: [require('./activity-log.scss')],
})

export class ActivityLogComponent implements OnInit, OnDestroy {

    constructor(
        private activityLogService: ActivityLogService,
        private store: Store<fromRoot.AppState>,
        private iconService: IconService
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {
        this.activityLogService.getActivityLogForEvent();
    }

    public generateActivityLogReport() {
        this.activityLogService.genReport();
    }

    public changePage(page) {
        this.activityLogService.activityListOptions.pagination.currentListPage = page;
        this.activityLogService.getActivityLogForEvent();
    }

    ngOnDestroy() {
        this.activityLogService.clearList();
    }
}
