import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { shareReplay } from 'rxjs/operators';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EFSession } from '../../../../../../ApiClient/Models/Session/EFSession';
import { EditSessionsComponent } from '../EditSessions/edit-sessions.component';
import { ModalService } from '../../../../../CoreUI/Modal/modal.service';
import { AlertService } from '../../../../../eventFarmAlert.service';
import { EventAgendaService } from '../../../event-agenda.service';
import { RouteGeneratorService } from '../../../../../../_services/routes/route-generator.service';

@Component({
    selector: '[sessions-item]',
    template: require('./sessions-item.html'),
    styles: [require('./sessions-item.scss')],
})

export class SessionsItemComponent {
    @Input() sessionItem: EFSession;
    public componentClicked: boolean = false;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private store: Store<fromRoot.AppState>,
        private drawerService: NzDrawerService,
        private modalService: ModalService,
        private alertService: AlertService,
        private eventAgendaService: EventAgendaService,
        private routeGenerator: RouteGeneratorService,
    ) { }

    openComponent(): void {
        if (!this.componentClicked) {
            this.componentClicked = true;
            const drawerRef = this.drawerService.create<EditSessionsComponent, { session: EFSession }, string>({
                nzTitle: 'Edit Session',
                nzContent: EditSessionsComponent,
                nzWidth: '50%',
                nzContentParams: {
                    session: this.sessionItem,
                }
            });

            drawerRef.afterOpen.subscribe(() => {
            });

            drawerRef.afterClose.subscribe(data => {
                this.componentClicked = false;
                if (this.eventAgendaService.speakersListOptions.pagination.currentPage !== 1) {
                    this.eventAgendaService.speakersListOptions.pagination.currentPage = 1;
                    this.eventAgendaService.currentSpeakers$.next(this.eventAgendaService.originalSpeakers);
                }

                if (this.eventAgendaService.sponsorsListOptions.pagination.currentPage !== 1) {
                    this.eventAgendaService.sponsorsListOptions.pagination.currentPage = 1;
                    this.eventAgendaService.currentSponsors$.next(this.eventAgendaService.originalSponsors);
                }
            });
        }
    }

    public async delete() {
        this.alertService.general().confirmTableDelete(this.sessionItem.name)
            .then((res) => {
                if (res.value) {
                    this.eventAgendaService.removeSession(this.sessionItem.id).then((response) => {
                        this.eventAgendaService.getAllSessions();
                    });

                }
            });
    }

    private getTrackListPills() {
        if (!this.sessionItem.tracks.length) {
            return '';
        }

        return this.sessionItem.tracks.map(t => {
            return `<span style="font-size: 10px;
            padding: 5px;
            border-radius: 10px;
            color: black;
            background: #BCBCBC;
            margin: 1px;">${t.name}</span>`
        }).join(' ');

    }

    private viewGuests() {

    }
}
