import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilitiesModule } from '../../../Utilities/utilities.module';

import { LoadingOverlayModule } from '../LoadingOverlay/loading-overlay.module';
import { ErrorModule } from '../Error/error.module';
import { ModalModule } from '../Modal/modal.module';
import { DropdownSelectModule } from '../DropdownSelect/dropdown-select.module';
import { InvitationOptionsComponent } from './invitationOptions.component';
import { InvitationOptionsService } from './invitationOptions.service';
import { AddInviteCountComponent } from './addInviteCount.component';
import { AddInviteCountService } from './addInviteCount.service';
import { StatusSelectionComponent } from './statusSelection.component';
import { StatusSelectionService } from './statusSelection.service';
import { StackSelectionComponent } from './stackSelection.component';
import { StackSelectionService } from './stackSelection.service';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

const forExport = [
    InvitationOptionsComponent,
    AddInviteCountComponent,
    StackSelectionComponent,
    StatusSelectionComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        ModalModule,
        DropdownSelectModule,
        NzUtilitiesModule,
        NgxUtilitiesModule
    ],
    providers: [
        InvitationOptionsService,
        AddInviteCountService,
        StatusSelectionService,
        StackSelectionService,
    ]
})

export class InvitationOptionsModule {
}
