import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventFarmAPIClient } from '../../ApiClient/event-farm-api-client';
import { AlertService } from '../eventFarmAlert.service';
import { ModalService } from '../CoreUI/Modal/modal.service';
import { Modal } from '../CoreUI/Modal/modal.class';
import { LegacyUIModalContainerComponent } from './legacy-UI-modal-container.component';
import { AngularRestClient } from '../../_services/rest/AngularRestClient';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import { Observable } from 'rxjs';
import { EfEvent } from '../../ApiClient/Models/Event/event';

@Injectable()
export class LegacyUIService {
  constructor(
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private modalService: ModalService,
    private http: HttpClient,
    private store: Store<fromRoot.AppState>,
    private zone: NgZone,
  ) {
  }

  public loaded: boolean = false;
  public error: boolean = false;

  async fetchAndCompileTemplateContents(templateUrlToLoad, targetIdInDOM = null, event: EfEvent = null): Promise<any> {

    if (event) {
      window['ef'].event['eventId'] = event.id;
      window['ef'].event.v2Values = event;
    }

    this.loaded = false;
    return this.http
      .get(templateUrlToLoad, {
        headers: new HttpHeaders({
          'Pragma': 'no-cache',
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
        }),
        responseType: 'text',
      })
      .toPromise()
      .then((res) => {
        this.zone.runOutsideAngular(() => {
          if (!targetIdInDOM) {
            return res;
          }
          const $content = window['$']('#' + targetIdInDOM);
          $content.empty().html(res);
          this.compileAngularJS($content, targetIdInDOM);
        });
        this.loaded = true;
        this.error = false;
        return res;
      }).catch((err) => {
          this.loaded = true;
          this.error = true;
          this.alertService.general().authExpired()
            .then((res) => {
              if (res.value) {
                this.reload();
              }
            });
          return err;
        },
      );
  }

  async openCoreUrlInModal(urlToLoad: string, title: string = null, isIframe: boolean = false, refreshModal = false) {
    let legacyData = {
      url: urlToLoad,
      isIframe: isIframe,
    };
    if (refreshModal) {
      legacyData['refresh'] = true;
    }
    const modal: Modal = new Modal();
    modal.title = title;
    modal.component = LegacyUIModalContainerComponent;
    modal.data = legacyData;
    this.modalService.changeModal(modal);
  }

  compileAngularJS($content, targetIdInDOM) {
    if (!window['angular']) {
      return;
    }
    try {
      window['angular'].bootstrap(document, ['app.EventManager']);
      return;
    } catch (err) {
    }
    try {
      if (window['angular'] && window['angular'].element($content) && window['angular'].element(document).injector() && window['angular'].element(document.querySelector('#' + targetIdInDOM)).injector()) {
        window['angular'].element(document.querySelector('#' + targetIdInDOM)).ready(() => {
          window['angular'].element(document.querySelector('#' + targetIdInDOM)).injector().invoke(
            [
              '$compile', ($compile) => {
              let scope = window['angular'].element($content).scope();
              if (scope) {
                $compile($content)(scope);
              }
            },
            ]);
        });
      }
    } catch (err) {
      console.log('Angular JS compile error:', err);
    }
  }

  reload() {
    window['location'].reload();
  }
}
