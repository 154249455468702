import './mars/ef.mars.entry';

import 'core-js';
import 'reflect-metadata';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/EventFarm/app.module';
import configureSentry from './sentry';
declare const __IS_PRODUCTION__: boolean;



configureSentry();

if (__IS_PRODUCTION__) {
    enableProdMode();
}

const platform = platformBrowserDynamic();

platform.bootstrapModule(AppModule);
