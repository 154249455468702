import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TicketBlockListComponent } from './ticket-block-list.component';
import { TicketBlockUsersModule } from '../TicketBlockUsers/ticket-block-users.module';
import { FormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { TicketBlockListService } from './ticket-block-list.service';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [TicketBlockListComponent],
    exports: [TicketBlockListComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        UtilitiesModule,
        TicketBlockUsersModule,
        NgxUtilitiesModule
    ],
    providers: [
        TicketBlockListService,
    ]
})

export class TicketBlockListModule {
}
