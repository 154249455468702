import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import { User } from '../../ApiClient/Models/User/user';
import { isValidEmail } from '../../Utilities/validateEmail';
import { isValidPhoneNumber } from '../../Utilities/validatePhoneNumber';
import { HostBinding } from '@angular/core';


export class ExhibitorsAlert extends AbstractBaseAlert {
    confirmUserDeletion(userName: string): Promise<any> {
        return this.alert({
            title: 'Remove Team Member?',
            text: 'Are you sure you would like to remove team member \"' + userName + '\"?',
            confirmButtonText: 'Yes, remove user',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'question',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2'
        });
    }
}
