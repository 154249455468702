import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { EventIntegrationsRoutingModule } from './eventIntegrations-routing.module';
import { EventIntegrationsComponent } from './eventIntegrations.component';
import { ConfigurationOptionsComponent } from './Salesforce/ConfigurationOptions/configurationOptions.component';
import { ContactFieldMappingsComponent } from './Salesforce/ContactFieldMappings/contactFieldMappings.component';
import { CustomFieldMappingComponent } from './Salesforce/customFieldMapping.component';
import { SalesforceEventIntegrationsService } from './Salesforce/eventIntegrations.service';
import { FieldMappingComponent } from './Salesforce/fieldMapping.component';
import { InvitationSettingsComponent } from './Salesforce/InvitationSettings/invitationSettings.component';
import { LeadFieldMappingsComponent } from './Salesforce/LeadFieldMappings/leadFieldMappings.component';
import { MappingService } from './Salesforce/mapping.service';
import { StatusMappingsComponent } from './Salesforce/StatusMappings/statusMappings.component';
import { SyncSettingsComponent } from './Salesforce/SyncSettings/syncSettings.component';
import {NzUtilitiesModule} from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [EventIntegrationsComponent],
    declarations: [
        EventIntegrationsComponent,
        ConfigurationOptionsComponent,
        InvitationSettingsComponent,
        SyncSettingsComponent,
        ContactFieldMappingsComponent,
        LeadFieldMappingsComponent,
        StatusMappingsComponent,
        FieldMappingComponent,
        CustomFieldMappingComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        FormsModule,
        UtilitiesModule,
        BreadcrumbsModule,
        EventIntegrationsRoutingModule,
        NzUtilitiesModule,
        NgxUtilitiesModule
    ],
    providers: [
        SalesforceEventIntegrationsService,
        MappingService,
    ],
})

export class EventIntegrationsModule {
}
