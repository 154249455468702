import { Action } from '@ngrx/store';
import { DesignKeysForStack } from '../../../EventsModule/EmailCommunication/InvitationsConfirmations/ICRowItem/ic-row-item.component';

export const FETCH_SITE_PAGES = '[Event] Fetch SitePages';
export const FETCH_SITE_PAGES_SUCCESS = '[Event] Fetch SitePages Success';
export const FETCH_SITE_PAGES_FAIL = '[Event] Fetch SitePages Fail';
// export const SET_DESIGNS = '[Event] Set Designs';
// export const SET_DESIGNS_SUCCESS = '[Event] Set Designs Success';
// export const SET_DESIGNS_FAIL = '[Event] Set Designs Fail';

export class FetchSitePages implements Action {
    readonly type = FETCH_SITE_PAGES;
    constructor(public payload?: any) { }
}

export class FetchSitePagesSuccess implements Action {
    readonly type = FETCH_SITE_PAGES_SUCCESS;
    constructor(public payload?: any) { }
}

export class FetchSitePagesFail implements Action {
    readonly type = FETCH_SITE_PAGES_FAIL;
    constructor(public payload?: any) { }
}


export type EventAction =
    FetchSitePages |
    FetchSitePagesSuccess |
    FetchSitePagesFail;