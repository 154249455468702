
import { filter, take, shareReplay } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Observable , Subscription } from 'rxjs';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { Router } from '@angular/router';

@Component({
    selector: 'breadcrumbs',
    template: require('./breadcrumbs.html'),
    styles: [require('./breadcrumbs.scss')],
})

export class BreadcrumbsComponent implements OnDestroy {
    constructor(
        private store: Store<fromRoot.AppState>,
        private routeGenerator: RouteGeneratorService,
        private router: Router
    ) {
        this.eventRoleType$.subscribe(res => this.eventRoleType = res);
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.eventStoreData = val.data;
        });
        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.teamStoreData = val.data;
        });
        this.displayEventLink = false;
        this.store.select('router').pipe(
            filter(res => Object.keys(res).length !== 0 && Object.keys(res.state.params).length !== 0),
        ).subscribe(route => {
            if (route.state.queryParams.ticketBlockId) {
                this.displayEventLink = true;
            }
        });
    }
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventStoreData: EfEvent;
    private teamStore$: Observable<any> = this.store.select('team');
    private teamStoreData: Team;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventRoleType: any;
    private eventUpdate$: Subscription;
    private teamUpdate$: Subscription;
    displayEventLink: boolean;

    navigateToEvent() {
        this.store.dispatch(new fromRoot.FetchCurrentEvent());
        this.router.navigateByUrl(this.routeGenerator.url('events.info', {poolId: this.teamStoreData.id, eventId: this.eventStoreData.id}));
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
        this.teamUpdate$.unsubscribe();
    }
}
