import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolsRoutingModule } from './pools.routing-module';
import { PoolsComponent } from './pools.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { PoolResolver } from './pool-resolver.service';
import {NgxUtilitiesModule} from '../../NGXUtilities/ngx.utilities.module';

const mainModules: any[] = [
    CommonModule,
];

const ngxOtherModules: any[] = [
    NgxChartsModule,
];

const poolsModules: any[] = [
    PoolsRoutingModule,
];

@NgModule({
    imports: mainModules.concat(NgxUtilitiesModule, ngxOtherModules, poolsModules),
    declarations: [
        PoolsComponent
    ],
})
export class PoolsModule {
}