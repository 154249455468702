
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { WebPresenceService } from '../../web-presence.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription } from 'rxjs';
import { EventText } from '../../../../../ApiClient/Models/Event/eventText';
import { Event, ThemeColorTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
const Color = require('color');


@Component({
  selector: 'web-presence-form-style',
  template: require('./web-presence-form-styles.html'),
  styles: [require('./web-presence-form-styles.scss'), require('../../web-presence.scss')],

})

export class WebPresenceFormStyleComponent implements OnDestroy, OnInit {

  constructor(
    private webPresenceService: WebPresenceService,
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      const styles = this.eventFarmService.currentEvent.eventTexts.find(et => et.key === 'alt_form_style_vars');

      if (styles !== undefined) {
        this.altStyles = styles;
        this.currentStyles = styles.value
      } else {
          this.currentStyles = {
            'stylesheet':"ef.form.style.material",
            'themeColor':"rgba(215, 215, 215, .00)",
            'themeOpacity': 0,
            'fontColor':"rgba(0, 0, 0, 1)",
            'accentColor':"rgb(93, 88, 247)"
        };
      }
      this.setupColors();
    });
  }


  

  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  private altStyles: EventText|null;
  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;
  private styleSheets: { name: string; value: string; }[];
  
  private currentStyles;
  get parseOpacity() {
    return parseInt(this.currentStyles['themeOpacity'])
  }

  ngOnInit(): void {

    this.styleSheets = [
        { name: 'Overlay', value: 'ef.form.style.overlay' },
        { name: 'Material', value: 'ef.form.style.material' },
        { name: 'Classic', value: 'ef.form.style.classic' },
        { name: 'Traditional', value: 'ef.form.style.overlay.old' }
    ]

  }

  setupColors() {
    const colors = new Event().ThemeColorType().slice(2);
    
    colors[0].slug = 'themeColor';
    colors[0].name = 'Background Color';
    colors[0].description = this.currentStyles['themeColor'].replace("RGBA", "rgba").replace("RGB", "rgb");

    colors[1].slug = 'fontColor';
    colors[1].name = 'Font Color';
    colors[1].description = this.currentStyles['fontColor'].replace("RGBA", "rgba").replace("RGB", "rgb");

    colors[2].slug = 'accentColor';
    colors[2].name = 'Accent Color';
    colors[2].description = this.currentStyles['accentColor'].replace("RGBA", "rgba").replace("RGB", "rgb");

    this.webPresenceService.oldPPPColors = colors;
  }

  ngOnDestroy(): void {
    this.eventUpdate$.unsubscribe();
  }

  //save styles


  changeFormStyle(value) {
    this.currentStyles['stylesheet'] = value;
  }

  changeOpacity(value) {
    this.currentStyles['themeOpacity'] = value.toString();
  }

  opacityFormatter(value:number) : string {
    if (value === 0) {
      return 'transparent'
    }
    if (value === 100) {
      return 'no transparency'
    }

    return `${value}% opaque`
  }

  saveStyles() {  

    const accentColor = this.webPresenceService.oldPPPColors.find(val => val.slug === 'accentColor');
    const fontColor = this.webPresenceService.oldPPPColors.find(val => val.slug === 'fontColor');
    const themeColor = this.webPresenceService.oldPPPColors.find(val => val.slug === 'themeColor');

    this.currentStyles['themeColor'] = themeColor.description;
    this.currentStyles['accentColor'] = accentColor.description;
    this.currentStyles['fontColor'] = fontColor.description;

    this.apiClient.getUseCaseFactory().Event().SetAltFormStyleVarsForEvent(this.eventFarmService.currentEvent.id,  JSON.stringify(this.currentStyles)).toPromise()
      .then(() => {
        this.message.success('Styles Saved');
        this.store.dispatch(new fromStore.FetchCurrentEvent())
      });
  }
}
