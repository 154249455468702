import { RouteGeneratorService } from './../../_services/routes/route-generator.service';
import { Team } from '../../ApiClient/Models/Pool/team';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import { EventFarmService } from '../eventFarm.service';
import { Router } from '@angular/router';
import { tap, map, filter, take, withLatestFrom, shareReplay } from 'rxjs/operators';
import { getAllTeamsForCurrentUser } from '../store';

@Component({
    template: `
        <router-outlet></router-outlet>
    `
})
export class EventsComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>
    ) {
        if (!this.eventFarmService.currentEvent) {
            this.store.dispatch(new fromStore.FetchCurrentEvent());
        }

        this.store.select('event').pipe(
            map(event => event.data),
            withLatestFrom(this.store.select('router'), this.store.select(getAllTeamsForCurrentUser)),
            filter(([event, route, teams]) => !!event && !!event.id && !!teams.length),
            tap(([event, route, teams]) => {
                if (event.team && event.team.id !== route.state.params.pool) {
                    this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', {poolId: teams[0].id}));
                }
            }),
            take(1)
        ).subscribe();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
