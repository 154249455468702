import { FETCH_DESIGNS } from './../../../store/actions/event/designs.action';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';

import { EmailPreviewService } from '../email-preview.service';
import { AlertService } from '../../../eventFarmAlert.service';
import { EmailDesignCenterService } from '../email-design-center.service';
import { Design } from '../Models/design';
import { DomainMask} from '../Models/domain-mask';

import { ColorPickerService } from 'ngx-color-picker';
import { froalaOptions } from '../../../../Shared/froala-options';
import { FroalaService } from '../froala.service';

import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';

import { SendTestComponent } from './send-test.component';
import { EFHelper } from '../../../../Shared/helper';
import { EmailMessageTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EmailMessage';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../store';
import * as eventActions from './../../../store/actions/event';
import * as _ from 'lodash';

declare var $: any;
const isEqual = require('lodash.isequal');

@Component({
    selector: 'create-edit-email',
    template: require('./create-edit-email.html'),
    styles: [require('./create-edit-email.scss'), require('./../froala-editor.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class CreateEditEmailComponent implements OnInit, OnDestroy {
    constructor(
        public emailDesignCenterService: EmailDesignCenterService,
        public router: Router,
        public route: ActivatedRoute,
        public location: Location,
        private cdr: ChangeDetectorRef,
        public emailPreviewService: EmailPreviewService,
        private alertService: AlertService,
        private modalService: ModalService,
        private froalaService: FroalaService,
        private store: Store<fromRoot.AppState>
    ) {
    }
    public FroalaEditor = require('froala-editor');

    private request: Subscription;

    private loading: boolean;
    private loadingMessage: string;
    private templateSlug: string;

    private design: Design = new Design();
    private designRef: Design = new Design();
    private designTypes: object[] = [];
    private emailMessageTypes: EmailMessageTypeInterface[];
    private emailDesignTypes;
    private domainMask: DomainMask;
    private domainMasks: DomainMask[] = [];
    private emptyDomainMask?: string;
    private codeViewIsActive = false;

    public options: object = froalaOptions;

    private slugs: any[] = [];

    private sendTestCopy: string = 'Send Yourself a Test Email';

    private color: string;
    private color$: BehaviorSubject<any> = new BehaviorSubject('#ffffff');

    ngOnInit() {
        this.loading = true;
        this.color = '#ffffff';

        this.emailMessageTypes = this.emailDesignCenterService.getEmailMessageTypes()
          .filter(type => !type.isDonation);

        this.route.params.subscribe((params) => {
            this.templateSlug = params.id;
        });

        const path = this.route.snapshot.url[0].path;

        switch (path) {
            case 'edit':
                this.loadingMessage = 'Loading your amazing work...';
                this.request = this.emailDesignCenterService.fetchEmailDesign(this.templateSlug)
                    .subscribe((res) => {
                        this.design = Design.fromApi(res['data']);
                        this.designRef = Design.fromApi(res['data']);
                        this.color = `#${this.design.backgroundColor}`;
                        this.color$.next(`#${this.design.backgroundColor}`);
                    },
                        (err) => {}
                    );
                break;
            case 'create':
                this.loadingMessage = 'Creating your new email...';
                this.request = this.emailDesignCenterService.getTemplate(this.templateSlug)
                    .subscribe((res) => {
                        this.design.content = res.data.content;
                        this.designRef.content = res.data.content;
                    },
                        (err) => {}
                    );
                break;
            default:
        }

        this.color$.subscribe((color) => {
            this.color = color;
            if (document.getElementsByClassName('fr-element fr-view').length) {
                const editor = document.getElementsByClassName('fr-element fr-view')[0] as HTMLElement;
                editor.style.backgroundColor = color;
            }
        });

        this.options['events'] = Object.assign(froalaOptions['events'], this.froalaService.createEditEmailOptions,
            {
                'froalaEditor.html.afterGet': (editor) => {
                    editor.$el[0].style.backgroundColor = this.color;
                }
            }
        );

        this.request.add(() => {
            this.cdr.detectChanges();
            this.loading = false;
        });

        this.getEmailDesignTypes();

        this.getAllDomainMasksByPool();

        this.froalaService.codeViewChange.subscribe(
            (codeViewIsActive) => {
                this.codeViewIsActive = codeViewIsActive;
            }
        );
    }

    async saveEmailDesign(design) {
        if (this.codeViewIsActive) {
            design.content = this.froalaService.getContentFromCodeView();
        }

        if (design.backgroundColor) {
            design.backgroundColor = this.emailDesignCenterService.fixHexShorthand(design.backgroundColor);
        }

        if (this.checkRequiredFields(design) && this.checkRequiredSlugs(design) && this.checkDomainMaskFields(design)) {
            // if invite check to see if invite link is there, if not show popup
            if (design.isInvitation) {
                const shouldProceed = await this.checkInviteEmail(design);
                if (shouldProceed === undefined || shouldProceed === 0) {
                    return;
                }
            }

            this.loading = true;
            this.loadingMessage = 'Saving your Design...';
            if (design.emailDesignId) { // if design has an id/exists, update it
                this.request = this.emailDesignCenterService.updateEmailDesign(design).subscribe();
                this.emailDesignCenterService.createEmailSample(design.emailDesignId, design.content);
            } else { // if design is new, create it
                this.request = this.emailDesignCenterService.createEmailDesign(design).subscribe((res) => {
                    this.emailDesignCenterService.createEmailSample(res.data.command.emailDesignId, res.data.command.content);
                });
            }

            this.request.add(() => {
                this.store.dispatch(new eventActions.FetchDesigns());
                this.designRef = design;
                this.loading = false;
                this.goUp();
            });
        }
    }

    checkRequiredSlugs(design) {
        // if there's no emailDesignTypeId - go no further
        if (!this.design.emailDesignTypeId) { return false; }

        const designType = this.designTypes.find((type) => type['id'].toLowerCase() === design.emailDesignTypeId);

        if (!designType) {
            return false;
        }
        
        const requiredSlugs = designType['attributes'].fields.filter((i) => i['required']);
        const slugLinks = designType['attributes'].fields.filter((i) => i['link']);

        for (const slug of requiredSlugs) {
            if (!(design.content.indexOf(`[${slug.name}]`) !== -1)) {
                this.alertService.emailDesignCenter().missingDynamicElement(slug);
                return false;
            }
        }
        return true;
    }

    async checkInviteEmail(design) {
        if (!(design.content.indexOf(`[INVITE_LINK]`) !== -1)) {
            const shouldProceed = await this.alertService.emailDesignCenter().missingInviteLink();
            return shouldProceed.value;
        }

        return true;
    }



    checkRequiredFields(design) {
        const requiredFieldErrors = ['Email Name Missing', 'Email From Name Missing', 'Email Subject Missing', 'Email Body Empty', 'Email Message Type Not Set']
        const requiredFields = [design.name, design.fromName, design.subject, design.content, design.emailDesignTypeId];

        const errors = [];

        requiredFields.forEach(function (i, idx) {
            if (!i) {
                return errors.push(requiredFieldErrors[idx]);
            }
        });

        if (errors.length) {
            this.alertService.emailDesignCenter().missingRequiredField(errors);
            return false;
        }
        return true;
    }

    checkDomainMaskFields(design) {
        const isDomainUserNamePopulated = !EFHelper.isEmpty(design.domainMask.senderAddress);
        const isDomainSelected = !EFHelper.isEmpty(design.domainMask.mask.domain);
        const isFullDomainMaskPopulated = !EFHelper.isEmpty(design.domainMask.id) && !EFHelper.isEmpty(design.domainMask.fullDomainMaskEmail);
        const isDomainMaskValid = this.emailDesignCenterService.isDomainMaskValid(isDomainUserNamePopulated, isDomainSelected, isFullDomainMaskPopulated);
        if (!isDomainMaskValid)  {
            this.alertService.emailDesignCenter().incompleteDomainMask();
            return false;
        } else {
            return true;
        }
    }

    disableSpace(e) {
        return e !== 32;
    }

    getEmailDesignTypes() {
        this.emailDesignCenterService.getAllEmailDesignTypes().subscribe(res => {
            this.designTypes = (res.data.map(i => Object.assign(i, {id: i.id.toLowerCase()})));
        });
    }

    colorChange(color) {
        $('.custom-theme.fr-box.fr-basic .fr-element.fr-view').css({ backgroundColor: this.color });
        color = color.replace('#', '');
        color = this.emailDesignCenterService.fixHexShorthand(color);
        this.design.backgroundColor = color;
    }

    getAllDomainMasksByPool() {
        this.emailDesignCenterService
            .getAllDomainMasksByPool()
            .subscribe((res) => {
                this.domainMasks = res.data.map((item) => DomainMask.fromApi(item));
            });
    }

    compareFn(c1, c2): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    private sendTest(message) {
        if (message.backgroundColor) {
            message.backgroundColor = this.emailDesignCenterService.fixHexShorthand(message.backgroundColor);
        }
        if (this.checkRequiredFields(message) && this.checkRequiredSlugs(message)) {
            const modal: Modal = new Modal();
            modal.title = this.sendTestCopy;
            modal.component = SendTestComponent;
            modal.data = message;

            this.modalService.changeModal(modal);
        }
    }

    hasChanges(): boolean {
        if (!(isEqual(this.design, this.designRef))) {
            return true;
        }
        return false;
    }

    onDomainMaskChange() {
        const selectedDesign = this.domainMasks.filter((domainMask: DomainMask) => {
            return this.design.domainMask.mask.domain === domainMask.mask.domain;
        });

        if (selectedDesign.length > 0) {
            this.design.domainMask.id = selectedDesign[0].id;
        } else {
            this.design.domainMask.id = null;
        }
    }

    goUp() {
        this.router.navigate(['./'], { relativeTo: this.route.parent });
    }

    @HostListener('window:beforeunload')
    onBeforeUnload() {
        return !this.hasChanges();
    }

    ngOnDestroy() {
        if ($('div#froala-editor').data('froala.editor')) {
            $('div#froala-editor').froalaEditor('destroy');
        }
    }
}
