import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { EmailDesignCenterService } from '../email-design-center.service';
import { Design } from '../Models/design';

@Injectable()

export class SendTestService {
    public loading: boolean = false;
    public myEmailAddress;
    public design: Design;

    constructor(
        private emailDesignCenterService: EmailDesignCenterService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
    ) {
        this.myEmailAddress = this.eventFarmService.currentUser.identifier.identifier;
    }

    public sendTest(emails) {
        return this.apiClient.getUseCaseFactory().EmailMessage().CreatePreviewEmailMessage(
            this.emailDesignCenterService.currentEvent.id,
            this.eventFarmService.currentUser.id,
            this.design.content,
            emails,
            this.design.fromName,
            this.design.subject,
            this.design.layout,
            this.design.emailDesignTypeId,
            this.design.backgroundColor,
            this.design.fromEmail,
            this.design.replyEmail,
            this.design.domainMask.fullDomainMaskEmail,
        );
    }
}
