import { Component, OnInit } from '@angular/core';
import { ReportService } from './report.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
    selector: 'report',
    template: require('./report.html'),
    styles: [require('./report.scss')],
})

export class ReportComponent implements OnInit {

    constructor(
        private reportService: ReportService,
        private segmentService: SegmentService
    ) { }

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().reportsEntered();
    }

}
