import { Component, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EfxStationService } from '../../efx-station.service';

@Component({
    selector: 'sms-configure',
    template: require('./sms-configure.html'),
    styles: [require('./sms-configure.scss'), require('../../efx-station.scss')]
})

export class SmsConfigureComponent implements OnDestroy {

    constructor(
        private efxStationService: EfxStationService,
        private message: NzMessageService
    ) {}

    @ViewChild('smsGreetingTxarea', {static: false}) greetingTextAreaRef: ElementRef;
    @Input() smsImage: string;
    @Output() smsImageUploaded: EventEmitter<string> = new EventEmitter();

    private readonly FIRST_NAME = 'first_name';
    private readonly LAST_NAME = 'last_name';
    private readonly EMAIL = 'email';
    private readonly TAG_ID = 'tag_id';

    get characterCount(): number {
        return this.efxStationService.smsGreeting$.value.length;
    }

    async addSlug(slug: string) {

        let slugToAdd = '';

        switch (slug) {
            case(this.FIRST_NAME):
                slugToAdd = `[${this.FIRST_NAME}]`;
                break;
            case(this.LAST_NAME):
                slugToAdd = `[${this.LAST_NAME}]`;
                break;
            case(this.EMAIL):
                slugToAdd = `[${this.EMAIL}]`;
                break;
            case(this.TAG_ID):
                slugToAdd = `[${this.TAG_ID}]`;
                break;
            default:
        }

        if ((this.characterCount + slugToAdd.length) < 160) {
            const start = this.greetingTextAreaRef.nativeElement.selectionStart;
            const end = this.greetingTextAreaRef.nativeElement.selectionEnd;
            const newGreeting = this.efxStationService.smsGreeting$.value.slice(0, start) + slugToAdd + this.efxStationService.smsGreeting$.value.slice(end);
            this.efxStationService.smsGreeting$.next(newGreeting);
            await this.greetingTextAreaRef.nativeElement.focus();
            setTimeout(function() {
                const element = document.getElementById('efxtxtarea') as HTMLInputElement;
                const location = end + slugToAdd.length;
                element.setSelectionRange(location, location);
            }, 10);
        } else {
            this.message.warning('Message length too long');
        }

    }

    public saveSmsImage(image) {
        this.smsImageUploaded.emit(image);
    }

    public imageSet($event) {
        // TODO What to do with value
    }

    ngOnDestroy() {
    }
}
