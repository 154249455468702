import { EventFarmService } from './eventFarm.service';
import { Injectable } from '@angular/core';
import { Contract } from '../ApiClient/Models/Contract/contract';
import { DateTimeUtility } from '../Utilities/Time/dateTime.utility';
import { Router } from '@angular/router';

@Injectable()
export class FeatureToggleService {
    private contract: Contract;
    private dateTimeUtility: DateTimeUtility;

    constructor(private app: EventFarmService, private router: Router) {
    }


}
