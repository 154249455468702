import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CreateEventService } from './create-event.service';

@Injectable() 

export class CreateEventResolve implements Resolve<any> {
    constructor(
        private createEventService: CreateEventService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.createEventService.listPoolAllotmentsForPool(route.paramMap.get('pool'));
    }
}
