import { Component, OnInit } from '@angular/core';
import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { GuestListService } from '../guest-list.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
    selector: 'ef-the-list',
    providers: [
        ModalService,
    ],
    template: require('./the-list.html'),
    styles: [require('./the-list.scss')],
})

export class TheListComponent implements OnInit {

    constructor(
        private guestListService: GuestListService,
        private segmentService: SegmentService

    ) {}

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().guestListEntered();
    }

}
