import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Team } from '../../../../ApiClient/Models/Pool/team';
import { GuestListService } from '../../../EventsModule/GuestList/guest-list.service';

@Component({
  selector: 'manage-team',
  template: require('./manage-team.html'),
  styles: [require('./manage-team.scss')],
})
export class ManageTeamComponent implements OnInit {
  @Input() team: Team;
  private guestListService: GuestListService;
  private query: string = '';

  constructor(

  ) {}

  private tabs: any = {
  };

  manageDomainMasks() {}

  ngOnInit() {}

}
