import { Component, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { EventFarmAPIClient } from '../../../../../../ApiClient/event-farm-api-client';
import { EmailDesign } from '../../../../../../Shared/EmailDesign/email-design';
import { EfxStationService } from '../../efx-station.service';

@Component({
    selector: 'email-configure',
    template: require('./email-configure.html'),
    styles: [require('./email-configure.scss'), require('../../efx-station.scss')]
})

export class EmailConfigureComponent implements OnDestroy {

    constructor(
        private efxStationService: EfxStationService,
        private message: NzMessageService,
        private apiClient: EventFarmAPIClient
    ) {}

    async selectEmailDesign(design: string|null) {
        this.efxStationService.cdEmailDesign$.next(design);
    }

    ngOnDestroy() {
    }
}
