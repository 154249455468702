import { createSelector } from '@ngrx/store';
import * as fromRoot from '..';

export const getCurrentEvent = (state) => state.event;

export const eventRoleType = createSelector(
    getCurrentEvent,
    (event) => {
        return event.access;
    }
);
