import { Component, OnInit, Input } from '@angular/core';
import { EFXAdminService } from '../../efx-admin.service';

@Component({
    selector: 'efx-module-list',
    template: require('./efx-module-list.html'),
    styles: [require('./efx-module-list.scss')],
})

export class EFXModuleListComponent implements OnInit {

    @Input() enabled?: boolean;
    @Input() showAll?: boolean;

    constructor(
        private efxAdminService: EFXAdminService
    ) { }

    get isEnabled(): boolean {

        if (this.showAll ) {
            return false;
        }

        if (this.enabled) {
            return true;
        }

        return false;
    }

    ngOnInit() {
    }

}
