import { DesignsEffects } from './event/designs.effect';
import { AccessTypesEffects } from './event/access-types.effect';
import { SitePagesEffects } from './event/site-pages.effect';
import { RouterEffects } from './router.effect';
import { UserEffects } from './user.effect';
import { TeamEffects } from './team.effect';
import { EventEffects } from './event';
import { WaitListEffects } from './event/wait-list.effect';
import { GuestListEffects } from './event/guest-list.effect';
import {AdminEffects} from './admin/';
import {AdminFeatureEffects} from './admin/admin-feature.effect';
import {AdminAllotmentEffects} from './admin/admin-allotment.effect';
import {AdminPoolWorldEffects} from './admin/admin-pool-world.effect';
import {AdminDomainMaskEffects} from './admin/admin-domain-mask.effect';
import {AdminContractEffects} from './admin/admin-contract.effect';

import {AdminContactEffects} from './admin/admin-contact.effect';
import {AdminCreateTeamEffects} from "./admin/admin-create-team.effect";
import { AdminArchiveTeamEffects } from './admin/admin-archive-team.effect';

const EventEffectsArr = [EventEffects, WaitListEffects, GuestListEffects, DesignsEffects, AccessTypesEffects, SitePagesEffects];
const AdminEffectsArr = [AdminEffects, AdminFeatureEffects, AdminAllotmentEffects, AdminContactEffects, AdminContractEffects, AdminPoolWorldEffects, AdminDomainMaskEffects, AdminCreateTeamEffects, AdminArchiveTeamEffects];

export const effects: any[] = [UserEffects, RouterEffects, ...EventEffectsArr, TeamEffects, ...AdminEffectsArr];

export * from './router.effect';
export * from './user.effect';
export * from './event';
export * from './event/wait-list.effect';
export * from './event/guest-list.effect';
export * from './event/access-types.effect';
export * from './team.effect';
export * from './admin/';
// export * from './admin/admin-feature.effect';
