import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, shareReplay } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminService } from '../admin.service';
import { AdminAlertService } from '../Alert/adminEventFarmAlert.service';

@Component({
  selector: 'data',
  template: require('./data.html'),
})
export class DataComponent implements OnInit {
  ghostUserForm: FormGroup;

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>,
    private adminService: AdminService,
    private adminAlertService: AdminAlertService
  ) {}

  generateTemplates() {
    this.adminAlertService.general().generateCanvasTemplate();
  }

  populateEchoWorlds() {
    this.adminAlertService.general().populateEchoWorlds();
  }

  ngOnInit() {
    this.ghostUserForm = new FormGroup({
      email: new FormControl(""),
    });
  }
}
