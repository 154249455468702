import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationsConfirmationsComponent } from './invitations-confirmations.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DesignSelector } from './ICRowItem/DesignSelector/design-selector.component';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { InvitationsConfirmationsRoutingModule } from './invitations-confirmations.routing.module';
import {IcRowItemComponent} from './ICRowItem/ic-row-item.component';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';

@NgModule({
    bootstrap: [InvitationsConfirmationsComponent],
    declarations: [
        InvitationsConfirmationsComponent,
        IcRowItemComponent,
        DesignSelector
    ],
    entryComponents: [
        IcRowItemComponent,
        DesignSelector
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilitiesModule,
        NzUtilitiesModule,
        InvitationsConfirmationsRoutingModule
    ],
})

export class InvitationsConfirmationsModule {
}
