import { Directive, ElementRef, AfterViewInit } from '@angular/core';

import * as autosizeInput from 'autosize-input';

@Directive({
    selector: '[efAutosize]'
})
export class FormInputAutosizeDirective implements AfterViewInit{

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngAfterViewInit() {
        autosizeInput(this.elementRef.nativeElement, { minWidth: true });
    }

}