import { Component } from '@angular/core';
import { WebPresenceService } from '../web-presence.service';

@Component({
  selector: 'web-presence-messages',
  template: require('./web-presence-messages.html'),
  styles: [require('./web-presence-messages.scss'), require('../web-presence.scss')],

})

export class WebPresenceMessagesComponent {

    constructor(private webPresenceService: WebPresenceService) {}

}
