import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UtilitiesModule } from "../../../Utilities/utilities.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DataRoutingModule } from "./data-routing.module";

import { ModalModule } from "../../CoreUI/Modal/modal.module";
import { DataComponent } from "./data.component";
import { AdminService } from "../admin.service";
import { LoaderGrayModule } from "../../CoreUI/LoaderGray/loader-gray.module";
import { TeamPointOfContactService } from "../Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.service";
import { AdminAlertModule } from "../Alert/admin-alert.module";
import {NgxUtilitiesModule} from "../../../NGXUtilities/ngx.utilities.module";

@NgModule({
  bootstrap: [DataComponent],
  declarations: [DataComponent],
  entryComponents: [DataComponent],
  imports: [
    DataRoutingModule,
    CommonModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    UtilitiesModule,
    LoaderGrayModule,
    AdminAlertModule,
    NgxUtilitiesModule
  ],
  providers: [TeamPointOfContactService],
})
export class DataModule {}
