import { EventProfileTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Profile';
import { AbstractModel } from '../abstract-model';


export class EventProfile extends AbstractModel {
    private _eventProfileType: EventProfileTypeInterface;
    
    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const eventProfile = new this(data.id);
        eventProfile._eventProfileType = data.attributes.eventProfileType;
        return eventProfile;
    }

    get eventProfileType(): EventProfileTypeInterface {
        return this._eventProfileType;
    }

    set eventProfileType(value: EventProfileTypeInterface) {
        this._eventProfileType = value;
    }
}
