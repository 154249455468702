import { Component, ComponentFactoryResolver, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription ,  BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../../eventFarm.service';
import { EfEvent } from '../../../../../ApiClient/Models/Event/event';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';
import { Invitation } from '../../../../../ApiClient/Models/Invitation/invitation';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { User } from '../../../../../ApiClient/Models/User/user';
import { ApiError } from '../../../../../ApiClient/Models/error';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AddInviteCountService } from '../../../../CoreUI/InvitationOptions/addInviteCount.service';
import { InvitationOptionsService } from '../../../../CoreUI/InvitationOptions/invitationOptions.service';
import { UpdateInvitationCommand } from '../../../../CoreUI/GuestListTableView/guest-list-view.service';
import { BasicUserDetails } from '../../../../CoreUI/UserForm/userForm.service';
import { GuestListViewService } from '../../../../CoreUI/GuestListTableView/guest-list-view.service';
import { GuestListUserService } from '../../../../CoreUI/GuestListTableView/guest-list-user.service';
import { UserFormService } from '../../../../CoreUI/UserForm/userForm.service';
import { InviteEditGuestService } from '../../../../CoreUI/GuestListTableView/invite-edit-guest.service';
import { Stack } from '../../../../../ApiClient/Models/Stack/stack';
import { AlertService } from '../../../../eventFarmAlert.service';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'address-book-user-invitations',
    styles: [require('./address-book-user-invitations.scss')],
    template: require('./address-book-user-invitations.html'),
})

export class AddressBookUserInvitationsComponent implements OnInit, OnDestroy {
    constructor(
        private eventFarmService: EventFarmService,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private invitationOptionsService: InvitationOptionsService,
        private guestListService: GuestListViewService,
        private guestListUserService: GuestListUserService,
        private message: NzMessageService,
        private userFormService: UserFormService,
        private segmentService: SegmentService,
        private editGuestService: InviteEditGuestService,
        private inviteCountService: AddInviteCountService,
        private alertService: AlertService,
        private client: EventFarmAPIClient,
    ) {
        this.invitationsForUser$ = this.addressBookUserService.invitationsForUser$.subscribe((res: Invitation[]) => {
            this.userInvitations = [];
            res.forEach(
                eachRes => this.userInvitations.push(Invitation.fromApiResponse(eachRes))
            );
        });
    };

    readonly saveIconSVG = require('../../assets/save-icon');
    readonly editPencil = require('../../assets/edit-pencil');

    invitationsForUser$: Subscription;
    userInvitations: Invitation[];
    currentEvent: EfEvent;
    updateInvitation: UpdateInvitationCommand;
    userDetails: BasicUserDetails = {};
    currentViewContacts: Invitation|null = null;
    private team: Team;
    private user: User;
    private stack: Stack;
    private currentFilter;
    private currentFilterShortName = ' upcoming ';
    private userInvitationsDateFilterOptions = [];

    errors: ApiError[];

    placeholder = new BehaviorSubject({
        name: ''
    });

    dropdownOptionList = new BehaviorSubject([]);

    private dropdownConfig = {
        options: this.dropdownOptionList,
        placeholder: this.placeholder,
        error: false
    };

    public isSavingInvitation = false;
    currentUserInvitationDetails = new BehaviorSubject<Invitation>(this.currentViewContacts);

    ngOnInit() {
        this.addressBookUserService.isInvitationDetailsVisible = false;
        this.userInvitations = [];
        this.getDropdownDateFilterTypes();
        this.placeholder.next({
            name: this.userInvitationsDateFilterOptions[0].name
        });
        this.addressBookUserService.userInvitationsListOptions.pagination.currentUsersPage = 1;
        this.addressBookUserService.broadcastInvitationsForUser(
            this.userInvitationsDateFilterOptions[0].slug,
            this.userInvitationsDateFilterOptions[0].sortDirection
        );
    }

    private getDropdownDateFilterTypes() {
        this.userInvitationsDateFilterOptions = this.addressBookUserService.getEventDateFilterType().filter(
            dateFilter => dateFilter.slug === 'current-future' || dateFilter.slug === 'past-all');
        this.userInvitationsDateFilterOptions[0].sortDirection = 'ascending';
        this.userInvitationsDateFilterOptions[1].sortDirection = 'descending';
        this.dropdownOptionList.next(this.userInvitationsDateFilterOptions);
        this.currentFilter = this.userInvitationsDateFilterOptions[0];
    }

    filterInvitationsByDate(option) {
        this.addressBookUserService.userInvitationsListOptions.pagination.currentUsersPage = 1;
        this.placeholder.next({
            name: option.name
        });
        this.addressBookUserService.broadcastInvitationsForUser(option.slug, option.sortDirection);
        if (option.slug == 'current-future') {
            this.currentFilterShortName = ' upcoming ';
        } else if (option.slug == 'past-all') {
            this.currentFilterShortName = ' past ';
        } else {
            this.currentFilterShortName = ' ';
        };
        this.currentFilter = option;
    }

    openUserInvitationsDetails(invitation) {
        this.setEventDetailsForInvitation(invitation.event.id);
        this.currentUserInvitationDetails.next(invitation);
        this.invitationOptionsService.initialInvitation = invitation;

        this.editGuestService.updateInvitationCommand.invitationId = invitation.id;
        this.editGuestService.updateInvitationCommand.invitationStatus = invitation.invitationStatusType.slug;

        this.user = this.currentUserInvitationDetails.value.user;
        this.user.name = this.currentUserInvitationDetails.value.userName ? this.currentUserInvitationDetails.value.userName : this.user.name;
        this.team = this.eventFarmService.currentTeam;

        this.fetchCurrentUser(invitation.user.id);
        this.setCurrentStack();
        this.addressBookUserService.isInvitationDetailsVisible = true;
    }

    closeUserInvitationsDetails() {
        this.addressBookUserService.isInvitationDetailsVisible = false;
        this.userInvitations = [];
        this.getDropdownDateFilterTypes();
        this.placeholder.next({
            name: this.userInvitationsDateFilterOptions[0].name
        });
        this.addressBookUserService.userInvitationsListOptions.pagination.currentUsersPage = 1;
        this.addressBookUserService.broadcastInvitationsForUser(
            this.userInvitationsDateFilterOptions[0].slug,
            this.userInvitationsDateFilterOptions[0].sortDirection
        );
    }

    setEventDetailsForInvitation(eventId) {
        this.currentEvent = new EfEvent(eventId);
        this.eventFarmService.fetchEventDetails(eventId, [
            'Stacks',
            'StacksWithAvailabilityCounts',
            'TicketTypes'
        ])
        .subscribe(res => {
            this.currentEvent = EfEvent.fromApiResponse(res.data);
        });
    }

    private setCurrentStack() {
        let stacks: Stack[];

        if (!this.currentEvent.stacks) {
            return;
        }

        stacks = this.currentEvent.stacks.filter((stack) => {
            return stack.id === this.invitationOptionsService.selectedStackId;
        });

        if (stacks.length > 0) {
            this.stack = stacks[0];
            return this.stack;
        }

        return null;
    }

    private fetchCurrentUser(userId: string) {
        if (this.team) {
            this.guestListUserService
                .getUserInPool(this.team.id, userId)
                .subscribe((res) => {
                    this.setCurrentUser(res);
                });
        }
    }

    private setCurrentUser(res) {
        this.user = User.fromApiResponse(res.data);
    }


    changePage(page) {
        this.addressBookUserService.loadingUserInvitations = true;
        this.addressBookUserService.userInvitationsListOptions.pagination.currentUsersPage = page;
        this.addressBookUserService.broadcastInvitationsForUser(this.currentFilter.slug, this.currentFilter.sortDirection);
    }

    ngOnDestroy() {
        this.dropdownOptionList.unsubscribe();
        this.currentUserInvitationDetails.unsubscribe();
    }
}
