import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import { User } from '../../ApiClient/Models/User/user';
import { isValidEmail } from '../../Utilities/validateEmail';
import { PoolContact } from '../../ApiClient/Models/PoolContact/poolContact';

export class PointOfContactAlert extends AbstractBaseAlert {


    confirmDeletion(contact: PoolContact): Promise<any> {
        return this.alert({
            title: 'Remove User from Team',
            text: `Team privileges will be removed. Do you wish to retain ${contact.user.name._fullName}'s access to manage historical events?`,
            confirmButtonText: 'Remove All',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            denyButtonText: `Retain`,
            showDenyButton: true,
            icon: 'warning',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
        });
    }

    addNewUser(poolId: string, email: string): Promise<any> {
        return this.alert({
            title: 'Create Point Of Contact',
            confirmButtonText: 'Yes, add user',
            html:
                `<input type="email" id="email" class="form-control sa-input-spacing" value="${email}" required disabled>` +
                '<input id="first-name" class="form-control sa-input-spacing" placeholder="First Name" required>' +
                '<input id="last-name" class="form-control sa-input-spacing" placeholder="Last Name" required>',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: false,
            preConfirm: async () => {

                const email = (document.getElementById('email') as HTMLInputElement).value;
                const firstName = (document.getElementById('first-name') as HTMLInputElement).value;
                const lastName = (document.getElementById('last-name') as HTMLInputElement).value;

                if (!email.length || !isValidEmail(email)) {
                    return swal.showValidationMessage(
                        `Please enter a proper email`);
                }

                if (!firstName.length && !lastName.length) {
                    return swal.showValidationMessage(
                        `First and last names can't be empty`);
                }

                if (!firstName.length) {
                    return swal.showValidationMessage(
                        `First name cant be empty`);
                }

                if (!lastName.length) {
                    return swal.showValidationMessage(
                        `Last name cant be empty`);
                }

                const r1 = await this.apiClient.getUseCaseFactory().User().CreateUser(email, firstName, lastName, null, null, null, poolId, null, null).toPromise().catch(
                    async (err) => {
                        if (err.status === 409) {
                            let oldUserId = null;
                            err.error.errors.forEach((error) => {
                                oldUserId = (error.meta && error.meta.userId) ? error.meta.userId : null;
                            });
                            await this.apiClient.getUseCaseFactory().UserName().AddUserName(poolId, oldUserId, firstName, lastName, null).toPromise();
                            const r3 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, oldUserId, ['UserIdentifier', 'UserName']).toPromise();
                            return User.fromApiResponse(r3.data);
                        } else {
                            swal.showValidationMessage(
                                `Please try again`);
                        }
                    }
                );

                if (r1 instanceof User) {
                    return r1;
                }

                const { userId } = r1.data.command;
                const r4 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, userId, ['UserIdentifier', 'UserName']).toPromise();
                return User.fromApiResponse(r4.data);

            }
        });
    }

    updateUserName(poolId: string, contact: PoolContact): Promise<any> {
        return this.alert({
            title: 'Update Point Of Contact Name',
            confirmButtonText: 'Yes, update name',
            html:
                `<input type="email" id="email" class="form-control sa-input-spacing" value="${contact.email}" required disabled>` +
                `<input id="first-name" class="form-control sa-input-spacing" placeholder="First Name" value="${contact.user.name.firstName}" required>` +
                `<input id="last-name" class="form-control sa-input-spacing" placeholder="Last Name" value="${contact.user.name.lastName}" required>`,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            preConfirm: async () => {

                const email = (document.getElementById('email') as HTMLInputElement).value;
                const firstName = (document.getElementById('first-name') as HTMLInputElement).value;
                const lastName = (document.getElementById('last-name') as HTMLInputElement).value;

                if (!email.length || !isValidEmail(email)) {
                    return swal.showValidationMessage(
                        `Please enter a proper email`);
                }

                if (!firstName.length && !lastName.length) {
                    return swal.showValidationMessage(
                        `First and last names can't be empty`);
                }

                if (!firstName.length) {
                    return swal.showValidationMessage(
                        `First name cant be empty`);
                }

                if (!lastName.length) {
                    return swal.showValidationMessage(
                        `Last name cant be empty`);
                }

                await this.apiClient.getUseCaseFactory().UserName().SetUserName(contact.user.name.id, firstName, lastName).toPromise();
                const r4 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, contact.user.id, ['UserIdentifier', 'UserName']).toPromise();
                return User.fromApiResponse(r4.data);

            }
        });
    }
}
