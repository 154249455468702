import {AbstractControl} from '@angular/forms';

export function getGetContentLengthWithoutSlugs(input: string): number{
    const dirtyString = input;
    const cleanString = dirtyString.replace(/\[(.+?)\]/g, '');
    return cleanString.length;
}

export function ValidateMaxSlugContent(control: AbstractControl) {
    if (getGetContentLengthWithoutSlugs(control.value) > 160) {
          return { invalidUrl: true };
    }

    return null;
}


