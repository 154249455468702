import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

const moment = require('moment');

@Injectable()

export class SalesforceEventIntegrationsService {

    constructor(private apiClient: EventFarmAPIClient) {
    }

    salesforceConfig: Object = {};
    pool: any = {};
    event: any = {};
    user: any = {};
    campaigns: any[] = [];
    creationMethod?: string = null;
    newContactRules: any[] = [];
    newContactRule: string = 'do-nothing';

    leadFieldMappingsImport: any[] = [];
    leadFieldMappingsExport: any[] = [];
    contactFieldMappingsImport: any[] = [];
    contactFieldMappingsExport: any[] = [];

    efStatusNames: any[] = [];
    intStatusNames: any[] = [];

    eventSettings = {
        integration: 'Salesforce',
        isLoading: true,
        isSyncing: false,
        savingStatus: 'Saving...',
        pool: this.pool,
        event: this.event,
        campaigns: this.campaigns,
        creationMethod: this.creationMethod,
        fieldMappings: {
            newContactRule: 'do-nothing',
            newContactRules: this.newContactRules,
            lead: {
                import: this.leadFieldMappingsImport,
                export: this.leadFieldMappingsExport,
            },
            contact: {
                import: this.contactFieldMappingsImport,
                export: this.contactFieldMappingsExport,
            },
            efStatusNames: this.efStatusNames,
            intStatusNames: this.intStatusNames,
        },
        dataFlow: {
            import: true,
            export: false,
        },
        salesforceConfig: {
            attributes: {
                sfCampaignId: null,
                sfCampaignName: null,
                isAutoImportEnabled: false,
                isAutoExportEnabled: false,
                invitationCount: null,
                modifiedAt: null,
            },
            id: null,
            user: {
                userName: null,
            },
        },
        modal: false,
        stacks: [],
        stackId: null,
        useExistingStack: false,
        booleans: {
            true: true,
            false: false,
        },
    };

    syncingFeedback() {
        this.eventSettings.savingStatus = 'Done!';
        setTimeout(() => {
            this.eventSettings.isSyncing = false;
            this.eventSettings.savingStatus = 'Saving...';
        }, 1200);
    }

    listEventSettings(eventId) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .ListSalesforceEventSettingsForEvent(eventId)
            .toPromise()
            .then((res) => {
                if (!res['data'].length) {
                    this.apiClient.getUseCaseFactory()
                        .Integration()
                        .InitializeSalesforceIntegrationsForEvent(eventId)
                        .toPromise()
                        .then((internalRes) => {
                            this.listEventSettings(eventId);
                        });
                } else {

                    if (typeof res.data[0].relationships.stack === 'undefined') {
                        this.eventSettings.useExistingStack = false;
                    } else {
                        this.eventSettings.useExistingStack = true;
                        this.eventSettings.stackId = res.data[0].relationships.stack.id;
                    }

                    return Object.assign(this.eventSettings.salesforceConfig, res['data'][0]);
                }
            });
    }

    getCampaignList(poolId) {
        return this.apiClient.getUseCaseFactory().Salesforce().ListSalesforceCampaignsForPool(poolId)
            .toPromise()
            .then((res) => {
                if (!res.data || !res.data.attributes) {
                    throw new Error('Salesforce connection failed for Campaign List');
                }
                this.eventSettings.campaigns = res['data']['attributes'];
                return this.eventSettings.campaigns = this.eventSettings.campaigns.map((campaign) => {
                    campaign.text = campaign.Name;
                    campaign.id = campaign.Id;
                    return campaign;
                });
            }).catch((err) => {
                return err;
            });
    }

    getStatusMappings(salesforceConfigId) {
        return this.apiClient.getUseCaseFactory()
            .IntegrationStatusMapping()
            .ListStatusMappingsForSalesforceEventSetting(salesforceConfigId)
            .toPromise();
    }

    getImportLeadFieldMappings(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .ListFieldMappingsForSalesforceEventSetting(salesforceEventSettingId, 'salesforce-import-lead')
    }

    getExportLeadFieldMappings(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .ListFieldMappingsForSalesforceEventSetting(salesforceEventSettingId, 'salesforce-export-lead')
    }

    getImportContactFieldMappings(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .ListFieldMappingsForSalesforceEventSetting(salesforceEventSettingId, 'salesforce-import-contact')
    }

    getExportContactFieldMappings(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .ListFieldMappingsForSalesforceEventSetting(salesforceEventSettingId, 'salesforce-export-contact')
    }

    listStacksForEvent(eventId) {
        return this.apiClient.getUseCaseFactory()
            .Stack()
            .ListStacksForEvent(eventId, ['TicketType'], ['public-purchase', 'public-registration'], true)
            .toPromise()
            .then((res) => res.data)
            .catch((err) => []);
    }

    setStackMode(eventSettingId, useExistingStack) {
        this.eventSettings.stackId = null;

        if (useExistingStack) {
            return;
        }

        this.eventSettings.isSyncing = true;

        this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .SetStackForSalesforceEventSetting(eventSettingId, null)
            .toPromise()
            .then(() => {
                this.syncingFeedback();
            });
    }

    setStack(eventSettingId, stackId) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .SetStackForSalesforceEventSetting(eventSettingId, stackId)
            .toPromise();
    }

    getSalesforceStatusNames() {
        return this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ListSalesforceStatusNamesForEvent(this.event.id, this.eventSettings.salesforceConfig.attributes.sfCampaignId)
    }

    updateSalesforceStatusNames(campaignId) {
        return this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ListSalesforceStatusNamesForEvent(this.event.id, campaignId)
    }

    getInvitationCreationTypes() {
        return this.apiClient.getTypeFactory().Integration().IntegrationInvitationCreationType();
    }

    setInvitationCreationTypeForSalesforceEventSetting(type) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .SetInvitationCreationTypeForSalesforceEventSetting(this.eventSettings.salesforceConfig.id, type)
            .toPromise();
    }

    setInvitationCountForSalesforceEventSetting(count) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .SetInvitationCountForSalesforceEventSetting(this.eventSettings.salesforceConfig.id, count)
            .toPromise();
    }

    getSalesforceUser(poolId) {
        return this.apiClient.getUseCaseFactory()
            .Salesforce()
            .GetSalesforceSyncUserInfoForPool(poolId)
            .toPromise()
            .then((res) => {
                this.eventSettings.salesforceConfig.user.userName = res.data.username;
                return res;
            })
            .catch((err) => {
                this.eventSettings.salesforceConfig.user.userName = null;
                return err;
            });
    }

    enableImport(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .EnableImportForSalesforceEventSetting(salesforceEventSettingId)
            .toPromise();
    }

    disableImport(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .DisableImportForSalesforceEventSetting(salesforceEventSettingId)
            .toPromise();
    }

    enableExport(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .EnableExportForSalesforceEventSetting(salesforceEventSettingId)
            .toPromise();
    }

    disableExport(salesforceEventSettingId) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .DisableExportForSalesforceEventSetting(salesforceEventSettingId)
            .toPromise();
    }

    // UPDATE CAMPAIGN INFO FROM DROPDOWN
    updateCampaign(campaign) {
        return this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .SetCampaignForSalesforceEventSetting(
                this.eventSettings.salesforceConfig.id,
                campaign.id,
                campaign.text
            )
    }

    saveData(x) {
    }

    makeActive() {
        for (const s in this.eventSettings.dataFlow) {
            this.eventSettings.dataFlow[s] = !this.eventSettings.dataFlow[s];
        }
    }

    importWithIntegration() {
        return this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ImportCampaignMembersForSalesforceEventSetting(this.eventSettings.salesforceConfig.id, this.user.id)
            .toPromise();
    }

    exportWithIntegration() {
        return this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ExportEventToSalesforce(
                this.event.id,
                this.user.id
            )
            .toPromise();
    }
}
