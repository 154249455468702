import { Component, Input } from '@angular/core';
import { SalesforceEventIntegrationsService } from '../eventIntegrations.service';
import { MappingService } from '../mapping.service';

@Component({
    selector: 'contact-field-mappings',
    template: require('./contactFieldMappings.html'),
    styles: [require('./contactFieldMappings.scss')],
})

export class ContactFieldMappingsComponent {

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private _ms: MappingService,
    ) {
    }

    @Input() eventSettings;

    private title: string = 'Contact Field Mappings';

    contactImportMappings: Object[] = this._ms.contactImportMappings;
    loadingContactImportMappings: boolean;
    contactExportMappings: Object[] = this._ms.contactExportMappings;
    efImportContactFields: any[] = this._ms.efImportContactFields;
    efExportContactFields: any[] = this._ms.efExportContactFields;
    intImportContactFields: any[] = this._ms.intImportContactFields;
    intExportContactFields: any[] = this._ms.intExportContactFields;
    updateRules: Object[] = this._ms.getUpdateRules();
    customContactFieldsToImport: Object[] = this._ms.customContactFieldsToImport;
    customContactFieldsToExport: Object[] = this._ms.customContactFieldsToExport;
    flow = this._sfeis.eventSettings.dataFlow;

    maxContactImportFields = [];
    maxContactExportFields = [];

    ngOnInit() {

        this.checkImportFieldsAvailable();
        this.checkExportFieldsAvailable();

        this.loadingContactImportMappings = true;
        this._sfeis.getImportContactFieldMappings(this.eventSettings.salesforceConfig.id)
            .subscribe((res) => {
                this._ms.contactImportMappings = res['data'];
                this.appendImportCustomField();
                this.checkImportFieldsAvailable();
                this.loadingContactImportMappings = false;
            });

        this._sfeis.getExportContactFieldMappings(this.eventSettings.salesforceConfig.id)
            .subscribe((res) => {
                this._ms.contactExportMappings = res['data'];
                this.appendExportCustomField();
                this.checkExportFieldsAvailable();
            });

        this.updateRules = this._ms.getUpdateRules();

    }

    ngDoCheck() {
        this.checkImportFieldsAvailable();
        this.checkExportFieldsAvailable();
        this.contactImportMappings = this._ms.contactImportMappings;
        this.contactExportMappings = this._ms.contactExportMappings;
        this.efImportContactFields = this._ms.efImportContactFields;
        this.efExportContactFields = this._ms.efExportContactFields;
        this.intImportContactFields = this._ms.intImportContactFields;
        this.intExportContactFields = this._ms.intExportContactFields;
        this.customContactFieldsToImport = this._ms.customContactFieldsToImport;
        this.maxContactImportFields = this.efImportContactFields.filter(this.isSelected);
        this.maxContactExportFields = this.efExportContactFields.filter(this.isSelected);
    }

    // UPDATING AVAILABLE EF FIELDS TO CHOOSE FROM
    availableEfImportContactFields: Object[] = [];
    availableEfExportContactFields: Object[] = [];

    isSelected(obj) {
        return !obj.selected;
    }

    appendImportCustomField() {
        this._ms.contactImportMappings.forEach((contactImportItem: any) => {
            if (contactImportItem.attributes.fieldId.includes('salesforce.')) {
                const id = this.efImportContactFields.findIndex((efImportContact: any) => {
                    return efImportContact.attributes.fieldId === contactImportItem.attributes.fieldId;
                });
                if (id === -1) {
                    this._ms.efImportContactFields.push({
                        attributes: {
                            slug: 'newCustomField',
                            fieldName: contactImportItem.attributes.fieldName,
                            fieldId: contactImportItem.attributes.fieldId
                        }, selected: false
                    });
                }
            }
        });  
    }

    appendExportCustomField() {
        this._ms.contactExportMappings.forEach( (contactExportItem: any) => {
            if (contactExportItem.attributes.fieldId.includes('salesforce.')) {
                const id = this.efExportContactFields.findIndex((efExportContact: any) => {
                    return efExportContact.attributes.fieldId === contactExportItem.attributes.fieldId;
                });
                if (id === -1) {
                    this._ms.efExportContactFields.push({
                        attributes: {
                            slug: 'newCustomField',
                            fieldName: contactExportItem.attributes.fieldName,
                            fieldId: contactExportItem.attributes.fieldId
                        }, selected: false
                    });
                }
            }
        });  
    }

    checkImportFieldsAvailable() {
        for (const obj of this.efImportContactFields) {
            obj.selected = false;
            for (const f of this.customContactFieldsToImport) {
                if (obj.attributes.slug === f['ef'].attributes.slug) {
                    obj.selected = true;
                }
            }
            for (const f of this.contactImportMappings) {
                if (obj.attributes.fieldName === f['attributes'].fieldName) {
                    obj.selected = true;
                }                
            }
        }

        for (const obj of this.intImportContactFields) {
            obj['selected'] = false;
            for (const f of this.customContactFieldsToImport) {
                if (obj['attributes'].fieldId === f['int']['attributes'].fieldId) {
                    obj['selected'] = true;
                }
            }
            for (const f of this.contactImportMappings) {
                if (obj.attributes.fieldId === f['attributes'].integrationFieldValue) {
                    obj['selected'] = true;
                }
            }
        }

        this.availableEfImportContactFields = this.efImportContactFields.filter(this.isSelected);
    }

    checkExportFieldsAvailable() {
        for (const obj of this.efExportContactFields) {
            obj.selected = false;
            for (const f of this.customContactFieldsToExport) {
                if (obj.attributes.slug === f['ef'].attributes.slug) {
                    obj.selected = true;
                }
            }
            for (const f of this.contactExportMappings) {
                if (obj.attributes.fieldName === f['attributes'].fieldName) {
                    obj.selected = true;
                }
            }
        }

        for (const obj of this.intExportContactFields) {
            obj['selected'] = false;
            for (const f of this.customContactFieldsToExport) {
                if (obj['attributes'].fieldId === f['int']['attributes'].fieldId) {
                    obj['selected'] = true;
                }
            }
            for (const f of this.contactExportMappings) {
                if (obj.attributes.fieldId === f['attributes'].integrationFieldValue) {
                    obj['selected'] = true;
                }
            }
        }

        this.availableEfExportContactFields = this.efExportContactFields.filter(this.isSelected);
    }

    hasRemainingImportContactFields() {
        for (const obj of this.efImportContactFields) {
            if (!obj['selected']) {
                return true;
            }
        }

        return false;
    }

    hasRemainingExportContactFields() {
        for (const obj of this.efExportContactFields) {
            if (!obj.selected) {
                return true;
            }
        }
        return false;
    }

    saveNewField(row, type) {
        this._ms.saveAndCheckForCustomField(row, type);
    }

    addMapping(integrationSettingType, fieldMappingType) {

        if (fieldMappingType.includes('import-contact')) {

            this.checkImportFieldsAvailable();
            this._ms.updateEventFarmFields(this.efImportContactFields, 'import-contact');

            if (this.customContactFieldsToImport.length < this.maxContactImportFields.length) {

                this.customContactFieldsToImport
                    .push(
                        {
                            integrationSettingType,
                            fieldMappingType: integrationSettingType + '-' + fieldMappingType,
                            ef: { attributes: { slug: {} } },
                            int: { attributes: { fieldId: {} } },
                        },
                    );
            }
        }

        if (fieldMappingType.includes('export-contact')) {

            this.checkExportFieldsAvailable();
            this._ms.updateEventFarmFields(this.efExportContactFields, 'export-contact');

            if (this.customContactFieldsToExport.length < this.maxContactExportFields.length) {

                this.customContactFieldsToExport
                    .push(
                        {
                            integrationSettingType,
                            fieldMappingType: integrationSettingType + '-' + fieldMappingType,
                            ef: { attributes: { slug: {} } },
                            int: { attributes: { fieldId: {} } },
                        },
                    );
            }
        }
    }

    updateRule(row) {
        this._ms.updateRule(row.id, row.attributes.updateRule.slug);
    }

    updateEfField(field) {
        this.checkImportFieldsAvailable();
        this.checkExportFieldsAvailable();
        field.selected = true;
        this._ms.updateEfField(field);
    }

    updateIntField(field) {
        this._ms.updateIntField(field);
        this.checkImportFieldsAvailable();
        this.checkExportFieldsAvailable();
    }

    deleteMapping(row, flow) {

        if (flow.includes('import-contact')) {
            const i = this._ms.contactImportMappings.indexOf(row);
            this._ms.contactImportMappings.splice(i, 1);
            this.checkImportFieldsAvailable();
            this.efImportContactFields = this._ms.removingCustomField(this.efImportContactFields, row);
        }

        if (flow.includes('export-contact')) {
            const i = this._ms.contactExportMappings.indexOf(row);
            this._ms.contactExportMappings.splice(i, 1);
            this.checkExportFieldsAvailable();
            this.efExportContactFields = this._ms.removingCustomField(this.efExportContactFields, row);
        }

        this._ms.deleteMapping(row);

    }

    removeRow(row, flow) {

        row.ef.selected = false;

        if (flow.includes('import-contact')) {
            const i = this.customContactFieldsToImport.indexOf(row);
            this.customContactFieldsToImport.splice(i, 1);
            this.checkImportFieldsAvailable();
        }

        if (flow.includes('export-contact')) {
            const i = this.customContactFieldsToExport.indexOf(row);
            this.customContactFieldsToExport.splice(i, 1);
            this.checkExportFieldsAvailable();

        }

    }

}
