import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EventFarmService } from '../../eventFarm.service';
import { AlertService } from '../../eventFarmAlert.service';
import { SalesforceEventIntegrationsService } from './Salesforce/eventIntegrations.service';
import { MappingService } from './Salesforce/mapping.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';

@Component({
    selector: 'event-integrations',
    template: require('./eventIntegrations.html'),
    styles: [require('./eventIntegrations.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class EventIntegrationsComponent implements OnInit {

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private _ms: MappingService,
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
    ) {
    }

    public eventSettings = this._sfeis.eventSettings;
    private _authenticated = true;
    public loaded = false;
    public campaignSelection = [];

    // ARRAYS FOR MAPPINGS
    private customLeadFieldsToImport: Object[] = this._ms.customLeadFieldsToImport;
    private customContactFieldsToImport: Object[] = this._ms.customContactFieldsToImport;
    private customLeadFieldsToExport: Object[] = this._ms.customLeadFieldsToExport;
    private customContactFieldsToExport: Object[] = this._ms.customContactFieldsToExport;
    private selectedCampaign;
    fetchEvent(): Promise<any> {
        return this._sfeis.listEventSettings(this._sfeis.event.id);
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    ngOnInit() {
        this._authenticated = true;
        this._sfeis.user = this.eventFarmService.currentUser;
        this._sfeis.event = this.eventFarmService.currentEvent;
        this._sfeis.pool = this.eventFarmService.currentEvent.team;
        if (this._sfeis.event.id.length && this._sfeis.pool.id.length) {
            this.fetchInitData();
        }
    }

    fetchInitData() {
        this.fetchEvent()
            .then((res) => {
                this.loaded = true;
                this.campaignSelection.push({
                    id: res.attributes.sfCampaignId,
                    name: res.attributes.sfCampaignName,
                });
            }).catch((err) => {
            this.loaded = true;
        });

        this._sfeis.getCampaignList(this._sfeis.pool.id).then((res) => {

            if (res.response && res.response.status === 500) {
                this._authenticated = false;
                throw new Error('invalid campaign response');
            }
        }).catch((err) => {
        });

        this._sfeis.getSalesforceUser(this._sfeis.pool.id)
            .then((res) => {
                if (this.eventSettings.salesforceConfig.user && !this.eventSettings.salesforceConfig.user.userName) {
                    this._authenticated = false;
                }
            });

        this._sfeis.listStacksForEvent(this._sfeis.event.id)
            .then((res) => this.eventSettings.stacks = res);

        
    }

    setCampaignId(campaign) {
        this.campaignSelection[0] = {
            id: campaign.id,
            text: campaign.text,
        };
    }

    updateCampaignId(campaign) {
        this._sfeis.eventSettings.isSyncing = true;
        this._sfeis.updateCampaign(campaign)
            .subscribe((res) => {
                this._sfeis.syncingFeedback();
                this._sfeis.eventSettings.isLoading = false;
                this._sfeis.eventSettings.salesforceConfig.attributes.sfCampaignId = campaign.id;
                this._sfeis.eventSettings.salesforceConfig.attributes.sfCampaignName = campaign.text;
                this.alertService.salesforceIntegration().salesforceConfigureSyncSettingsComplete(this.eventFarmService.currentTeam.id, this._sfeis.event.id);
            });
    }

    saveMappings() {

        for (const mapping of this.customLeadFieldsToImport) {
            if (!mapping['int'].attributes || !mapping['ef'].selected) {
                // NO MAPPING
            } else {
                // MAPPING SENT
                this._ms.saveMappings(mapping)
                    .then(() => {
                        this._sfeis.getImportLeadFieldMappings(this.eventSettings.salesforceConfig.id)
                            .subscribe((res) => {
                                const i = this.customLeadFieldsToImport.indexOf(mapping);
                                this.customLeadFieldsToImport.splice(i, 1);
                                this._ms.leadImportMappings = res['data'];
                            });
                    });
            }
        }

        for (const mapping of this.customLeadFieldsToExport) {
            if (!mapping['int'].attributes || !mapping['ef'].selected) {
                // NO MAPPING
            } else {
                // MAPPING SENT
                this._ms.saveMappings(mapping)
                    .then(() => {
                        this._sfeis.getExportLeadFieldMappings(this.eventSettings.salesforceConfig.id)
                            .subscribe((res) => {
                                const i = this.customLeadFieldsToExport.indexOf(mapping);
                                this.customLeadFieldsToExport.splice(i, 1);
                                this._ms.leadExportMappings = res['data'];
                            });
                    });
            }
        }

        for (const mapping of this.customContactFieldsToImport) {
            if (!mapping['int'].attributes || !mapping['ef'].selected) {
                // NO MAPPING
            } else {
                // MAPPING SENT
                this._ms.saveMappings(mapping)
                    .then(() => {
                        this._sfeis.getImportContactFieldMappings(this.eventSettings.salesforceConfig.id)
                            .subscribe((res) => {
                                const i = this.customContactFieldsToImport.indexOf(mapping);
                                this.customContactFieldsToImport.splice(i, 1);
                                this._ms.contactImportMappings = res['data'];
                            });
                    });
            }
        }

        for (const mapping of this.customContactFieldsToExport) {
            if (!mapping['int'].attributes || !mapping['ef'].selected) {
                // NO MAPPING
            } else {
                // MAPPING SENT
                this._ms.saveMappings(mapping)
                    .then(() => {
                        this._sfeis.getExportContactFieldMappings(this.eventSettings.salesforceConfig.id)
                            .subscribe((res) => {
                                const i = this.customContactFieldsToExport.indexOf(mapping);
                                this.customContactFieldsToExport.splice(i, 1);
                                this._ms.contactExportMappings = res['data'];
                            });
                    });
            }
        }

    } // END SAVE MAPPINGS

    importWithIntegration() {
        this.alertService.salesforceIntegration().
            beginSalesforceImport(this._sfeis.eventSettings.integration)
            .then((res) => {
                if (res.value) {
                    this._sfeis.importWithIntegration()
                        .then((res) => {
                            // EventBus.default.emit('CheckJobsQueue')
                            this.alertService.salesforceIntegration().integrationImportStarted(this._sfeis.eventSettings.integration);
                        })
                        .catch((error) => {
                            this.alertService.salesforceIntegration().integrationImportFailed(this._sfeis.eventSettings.integration);
                        });
                }

        });
    }

    exportWithIntegration() {
        this.alertService.salesforceIntegration().
            beginSalesforceExport()
            .then((res) => {
            if (res.value) {
                this._sfeis.exportWithIntegration()
                    .then((res) => {
                        // EventBus.default.emit('CheckJobsQueue');
                        this.alertService.salesforceIntegration().integrationExportStarted(this._sfeis.eventSettings.integration);
                    })
                    .catch((error) => {
                        this.alertService.salesforceIntegration().integrationExportFailed(this._sfeis.eventSettings.integration);
                    });
            }
        });
    }

}
