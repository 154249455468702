import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {RouteGeneratorService} from '../../../../../_services/routes/route-generator.service';
import {EventFarmService} from '../../../../eventFarm.service';
import {SMSCreateService} from '../sms-create.service';

@Component({
    selector: 'sms-create-confirmation',
    template: require('./sms-create-confirmation.html'),
    styles: [require('./sms-create-confirmation.scss')]
})

export class SmsCreateConfirmationComponent implements OnDestroy{
    @Output()
    goToBeginning = new EventEmitter<boolean>();

    constructor(
        private eventFarmService: EventFarmService,
        private formBuilder: FormBuilder,
        private smsCreateService: SMSCreateService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,

    ) {}

     goToReports() {
        this.router.navigateByUrl(this.routeGenerator.url('events.sms.reports', {  poolId: this.eventFarmService.currentTeam.id, eventId: this.eventFarmService.currentEvent.id }));
    }

    ngOnDestroy() {
        this.smsCreateService.sent$.next(false);
    }

    goToSMS() {
        this.goToBeginning.emit(true);
    }
}
