import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IconService } from '../../Icons/icons.service';

@Injectable()
export class GuestActivityLogService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private nzMessageService: NzMessageService,
        private iconService: IconService
    ) {
    }

    public readonly currentLogs$ = new BehaviorSubject<LogItem[]>([]);
    public logMetaSource = new BehaviorSubject<any>({});
    public logMeta$ = this.logMetaSource.asObservable();
    public isLoadingLog = false;
    public isGeneratingReport = false;


    public activityListOptions: ActivityListOptions = {
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentListPage: 1,
            itemsPerPage: 5,
        }
    };

    public clearList() {
        this.currentLogs$.next([]);
        this.logMetaSource.next(null);
        this.activityListOptions.pagination.currentListPage = 1;
    }

    public get logCount(): number {
        return this.currentLogs$.value.length;
    }

    public get totalResults(): number {
        return this.logMetaSource.value.totalResults;
    }

    public async getActivityLogForInivtation(invitationId: string) {

        this.isLoadingLog = true;

        const logs = await this.apiClient.getUseCaseFactory()
        .ActivityLog()
        .ListEntriesForInvitation(
            invitationId,
            this.activityListOptions.pagination.currentListPage,
            this.activityListOptions.pagination.itemsPerPage
        ).toPromise();

        this.logMetaSource.next(logs.meta);

        this.currentLogs$.next(logs.data.map(log => {
            return { value: log.attributes.formattedLogString, icon: this.iconService.getActivityLogLogoForType(log.attributes.action) };
        }));

        this.isLoadingLog = false;
    }
}

export interface Pagination {
    currentListPage: number;
    itemsPerPage: number;
}

export interface ActivityListOptions {
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}

export interface LogItem {
    value: string;
    icon: any;
}
