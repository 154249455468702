import { AbstractModel } from '../abstract-model';
import { UploadResults } from './upload-results';

export class UserImport extends AbstractModel {
    public static fromApiResponse(data) {
        const userImport = new this(data.id);

        if (data.meta.isInitialized) {
            userImport._totalRecords = data.attributes.totalRecords;
            userImport._totalGoodRecords = data.attributes.totalGoodRecords;
            userImport._totalDuplicateRecords = data.attributes.totalDuplicateRecords;
            userImport._totalErrorRecords = data.attributes.totalErrorRecords;
            userImport._canonicalHeaderNames = data.attributes.canonicalHeaderNames;
            userImport._totalImportFailureRecords = data.attributes.totalImportFailureRecords;
            userImport._totalImportedRecords = data.attributes.totalImportedRecords;
            userImport._totalRecordsWithTicketData = data.attributes.totalRecordsWithTicketData;
            userImport._createdAt = data.attributes.createdAt;

            userImport._goodRecordsCsvFileId = data.attributes.goodRecordsCsvFileId;
            userImport._duplicateRecordsCsvFileId = data.attributes.duplicateRecordsCsvFileId;
            userImport._errorRecordsCsvFileId = data.attributes.errorRecordsCsvFileId;
            userImport._importFailureRecordsCsvFileId = data.attributes.importFailureRecordsCsvFileId;

        }

        if (data.extraAttributes) {
            if (data.extraAttributes.GoodRecords) {
                userImport._goodRecords = UploadResults.createFromResultsArray(data.extraAttributes.GoodRecords);
            }
            if (data.extraAttributes.DuplicateRecords) {
                userImport._duplicateRecords = UploadResults.createFromResultsArray(data.extraAttributes.DuplicateRecords);
            }
            if (data.extraAttributes.ErrorRecords) {
                userImport._errorRecords = UploadResults.createFromResultsArray(data.extraAttributes.ErrorRecords);
            }
            if (data.extraAttributes.ImportFailureRecords) {
                userImport._importFailureRecords = UploadResults.createFromResultsArray(data.extraAttributes.ImportFailureRecords);
            }
        }

        return userImport;
    }

    private _totalRecords: number;
    private _totalGoodRecords: number;
    private _totalDuplicateRecords: number;
    private _totalErrorRecords: number;
    private _totalImportFailureRecords: number|null;
    private _totalImportedRecords: number|null;
    private _totalRecordsWithTicketData: number|null;
    private _createdAt: number;

    private _goodRecordsCsvFileId: string|null;
    private _errorRecordsCsvFileId: string|null;
    private _importFailureRecordsCsvFileId: string|null;
    private _duplicateRecordsCsvFileId: string|null;

    private _goodRecords: UploadResults;
    private _duplicateRecords: UploadResults;
    private _errorRecords: UploadResults;
    private _importFailureRecords: UploadResults;
    private _canonicalHeaderNames: string[];

    get hasGoodRecordsCsvFile(): boolean {
        return this._goodRecordsCsvFileId !== null;
    }

    get hasDuplicateRecordsCsvFile(): boolean {
        return this._duplicateRecordsCsvFileId !== null;
    }

    get hasErrorRecordsCsvFile(): boolean {
        return this._errorRecordsCsvFileId !== null;
    }

    get totalRecordsWithTicketData(): number | any {
        return this._totalRecordsWithTicketData;
    }

    get canonicalHeaderNames(): string[] {
        return this._canonicalHeaderNames;
    }

    get goodRecordsCsvFileId(): string|null {
        return this._goodRecordsCsvFileId;
    }

    get errorRecordsCsvFileId(): string|null {
        return this._errorRecordsCsvFileId;
    }

    get importFailureRecordsCsvFileId(): string|null {
        return this._importFailureRecordsCsvFileId;
    }

    get duplicateRecordsCsvFileId(): string|null {
        return this._duplicateRecordsCsvFileId;
    }

    get totalRecords(): number {
        return this._totalRecords;
    }

    get totalGoodRecords(): number {
        return this._totalGoodRecords;
    }

    get totalDuplicateRecords(): number {
        return this._totalDuplicateRecords;
    }

    get totalErrorRecords(): number {
        return this._totalErrorRecords;
    }

    get totalImportFailureRecords(): number|null {
        return this._totalImportFailureRecords;
    }

    get totalImportedRecords(): number|null {
        return this._totalImportedRecords;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    get goodRecords(): UploadResults {
        return this._goodRecords;
    }

    get duplicateRecords(): UploadResults {
        return this._duplicateRecords;
    }

    get errorRecords(): UploadResults {
        return this._errorRecords;
    }

    get importFailureRecords(): UploadResults {
        return this._importFailureRecords;
    }
}
