import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';

import {
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
    dateTimeFormat,
} from '../../../../Shared/datetime-options';

import {Subscription} from 'rxjs';

export interface ContractDetailsFormValues {
    startDate: Date;
    endDate: Date;
    contractType: string;
}

@Component({
    selector: 'contract-details-form',
    template: require('./contract-details-form.html'),
    styles: [require('./contract-details-form.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContractDetailsFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ContractDetailsFormComponent),
            multi: true
        }
    ]
})

export class ContractDetailsFormComponent implements ControlValueAccessor, OnDestroy {
    form: FormGroup;
    subscriptions: Subscription[] = [];
    private dateTimeFormat = dateTimeFormat;
    private disabledDate;
    poolContractTypes: any;

    constructor(private formBuilder: FormBuilder, private apiClient: EventFarmAPIClient) {
        this.form = this.formBuilder.group({
            startDate: [new Date()],
            endDate: [new Date()],
            contractType: ['cio', Validators.required]
        });

        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    ngOnInit() {
        this.poolContractTypes = this.apiClient
            .getTypeFactory()
            .Pool()
            .PoolContractType();
    }

    get value(): ContractDetailsFormValues {
        return this.form.value;
    }

    set value(value: ContractDetailsFormValues) {
        this.form.setValue(value);
        this.onChange(value);
        this.onTouched();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onChange: any = () => {

    };
    onTouched: any = () => {};

    registerOnChange(fn) {
        this.onChange = fn;
    }

    writeValue(value) {
        if (value) {
            this.value = value;
        }

        if (value === null) {
            this.form.reset();
        }
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { profile: { valid: false } };
    }
}
