import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AdminService} from './admin.service';
import {User} from '../../ApiClient/Models/User/user';
import {LocalStorageService} from '../../_services/storage/local-storage.service';

@Injectable()
export class AdminGuardService implements CanActivate {

    constructor(
        private _router: Router,
        private adminService: AdminService,
        private localStorageService: LocalStorageService) {
    }

    async canActivate(route: ActivatedRouteSnapshot,
                      state: RouterStateSnapshot): Promise<boolean> {

        const userId: string = this.localStorageService.get('efUserId');
        const res = await this.adminService.fetchUserById(userId);
        const user = User.fromApiResponse({
            id: res.data.id,
            attributes: res.data.attributes,
            extraAttributes: res.data.extraAttributes
        });
        return user.admin;
    }

}
