import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PoolContact } from '../../../../../ApiClient/Models/PoolContact/poolContact';
import {shareReplay} from 'rxjs/operators';
import * as fromRoot from '../../../../store';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../../store';

@Injectable()
export class TeamPointOfContactService {
  public contacts$ = this.store.select(fromRoot.getAdminCurrentTeamContacts).pipe(shareReplay());
  public contactsData$ = this.store.select(fromRoot.getAdminCurrentTeamContactsData).pipe(shareReplay());

  constructor(
    private apiClient: EventFarmAPIClient,
    private messageService: NzMessageService,
    private store: Store
  ) {}

  public async updatePoolContact(poolContactId, poolContactType) {
    return this.apiClient
      .getUseCaseFactory()
      .PoolContact()
      .UpdateTypeForPoolContact(poolContactId, poolContactType)
      .toPromise();
  }

  public fetchContactsByPoolId(teamId: string) {
    return this.apiClient
      .getUseCaseFactory()
      .Pool()
      .ListPoolContactsByPoolId(teamId, 1, 500);
  }

  public addContact(
    poolId: string,
    email: string,
    poolContactType: string
  ) {
   return this.apiClient
        .getUseCaseFactory()
        .PoolContact()
        .CreatePoolContact(poolId, email, poolContactType);
  }

  public  removeContact(
    poolId: string,
    poolContactId: string,
    userId: string
  ) {
  return this.apiClient
        .getUseCaseFactory()
        .PoolContact()
        .RemovePoolContact(poolId, poolContactId, userId)

  }

  public  removeUserRoles(
    userId: string,
    poolId: string
  ) {
  return this.apiClient
        .getUseCaseFactory()
        .Pool()
        .RemoveAllUserRolesFromPool(userId, poolId)
  }
}
