import {Component, Input, OnInit} from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { UploaderService } from '../uploader.service';
import { PreviewUploadService } from './previewUpload.service';
import { UploaderTabsService } from '../uploaderTabs.service';
import { Router } from '@angular/router';
import { InvitationOptionsService } from '../../../CoreUI/InvitationOptions/invitationOptions.service';

@Component({
    selector: 'preview-upload-container',
    providers: [],
    template: require('./preview-upload-container.html'),
    styles: [require('./preview-upload-container.scss')],
})

export class PreviewUploadContainerComponent implements OnInit {
    @Input() simplified: boolean;
    @Input() hideCancelButton:boolean;

    @Input() onPreviewSuccess: (userImportId: string) => void;
    @Input() onPostProcessSuccess: (userImportId: string) => void;
    @Input() textAlignment?: 'left' | 'center' | 'right';

    constructor(
        private eventFarmService: EventFarmService,
        private uploaderService: UploaderService,
        private uploaderTabsService: UploaderTabsService,
        private previewUploadService: PreviewUploadService,
        private invitationOptionsService: InvitationOptionsService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.uploaderService.setExistingUserImportFromUrl();
        this.uploaderTabsService.changeTabName(1, 'Preview Upload');
    }

    showInPluralTense(value: number): boolean {
        return value > 1 || value === 0;
    }

    uploadButtonIsDisabled() {
        if (this.invitationOptionsService.selectedStackId) {
            return false;
        }
        if (this.uploaderService.hasInvitations) {
            return false;
        }
        if (this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.isTypeCio) {
            return false;
        }
        return true;
    }

    complete() {
        this.previewUploadService.completeAndFinish(this.onPreviewSuccess, this.onPostProcessSuccess);
    }
}
