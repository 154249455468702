import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-input',
  template: `
    <div
      class="dynamic-field form-group form-input"
      [formGroup]="group">
      <label>{{ config.label }}</label>
      <input
        class="form-control"
        type="text"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name" />
    </div>`
})
export class FormInputComponent implements Field {
  config;
  group: FormGroup;
}
