import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { EfxStationService} from './efx-station.service';

@Component({
    selector: 'station',
    template: require('./efx-station.html'),
    styles: [require('./efx-station.scss')],
})

export class StationComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private efxStationService: EfxStationService
    ) {
    }

    async ngOnInit() {
        await this.efxStationService.getEfxStations();
    }

    ngOnDestroy() {
    }
}