import {NzModalService} from "ng-zorro-antd/modal";
import { RouteGeneratorService } from '../_services/routes/route-generator.service';
import {EfModalService} from "../EventFarm/eventFarmModal.service";
import { SweetAlert } from './sweet-alert';

import { EventFarmAPIClient } from '../ApiClient/event-farm-api-client';

export abstract class AbstractBaseAlert {
    constructor(protected sweetAlert: SweetAlert,  protected routeGenerator: RouteGeneratorService, protected apiClient?: EventFarmAPIClient, protected efModalService?: EfModalService) {
    }

    public alert(params)  {
        return this.sweetAlert.alert(params);
    }

    public modal(params)  {
        return this.efModalService.general(params)
    }
    public confirm(params)  {
        return this.efModalService.confirm(params)
    }
}
