import { Component, OnInit } from '@angular/core';
import { IconService } from './../../../CoreUI/Icons/icons.service';
import { EventAccessService } from '../event-access.service';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../store';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';

import { EventFarmService } from '../../../eventFarm.service';

@Component({
    template: require('./ticket-types-stacks.html'),
    styles: [require('./ticket-types-stacks.scss')]
})
export class TicketTypesStacksComponent implements OnInit {
    constructor(
        private iconService: IconService,
        private eventAccessService: EventAccessService,
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService
    ) {
    }


    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    ngOnInit(): void {
        this.eventAccessService.fetchAccessTypes();
    }

    private counts$ = this.store.select(fromRoot.getTypesWithCounts).pipe(
        filter(count => !!count)
    );

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

}
