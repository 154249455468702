import { AbstractModel } from '../abstract-model';

export class VirbelaWorld extends AbstractModel {

    public worldId: string;
    public worldName: string;
    public macDownloadURL: string;
    public windowsDownloadURL: string;
    constructor(id) {
        super(id);
    }
    //TODO  See if we can get rid of thos
    public static fromApiResponse({ worldId, worldName, macDownloadURL, windowsDownloadURL }) {
        const world = new this(worldId);
        world.worldId = worldId;
        world.worldName = worldName;
        world.macDownloadURL = macDownloadURL;
        world.windowsDownloadURL = windowsDownloadURL;
        return world;
    }

    public static fromApiResponse2(data) {
        const world = new this(data.id);
        world.macDownloadURL = data.attributes.macDownloadURL;
        world.windowsDownloadURL = data.attributes.windowsDownloadURL;
        world.worldId = data.attributes.worldId;
        world.worldName = data.attributes.worldName;
        return world;
    }

    get name(): string {
        return this.worldName;
    }

}

