import { Injectable } from '@angular/core';
import { GeneralAlert } from '../Alert/General/general-alert';
import { GuestListAlert } from '../Alert/GuestList/guest-list-alert';
import { AddressBookAlert } from '../Alert/AddressBook/address-book-alert';
import { TicketBlockAlert } from '../Alert/TicketBlock/ticket-block-alert';
import { InvitationAlert } from '../Alert/GuestList/invitation-alert';
import { SalesforceIntegrationAlert } from '../Alert/Integrations/salesforce-integration-alert';
import { VirbelaIntegrationAlert } from '../Alert/Integrations/virbela-integration-alert';
import { SitePagePreviewAlert } from '../Alert/SitePage/sitepage-preview-alert';
import { UploaderAlert } from '../Alert/Uploader/uploader-alert';
import { SweetAlert } from '../Alert/sweet-alert';
import { EmailDesignCenterAlert } from '../Alert/EmailDesignCenter/email-design-center-alert';
import { TicketStackAlert } from '../Alert/TicketStack/ticket-stack-alert';
import { UserRolesAlert } from '../Alert/UserRoles/user-roles-alert';
import { TicketTypeAlert } from '../Alert/TicketType/ticket-type-alert';
import { QuestionsAlert } from '../Alert/Questions/questions.alert';
import { RouteGeneratorService } from '../_services/routes/route-generator.service';
import { EventFarmAPIClient } from '../ApiClient/event-farm-api-client';
import { RevenueAlert } from '../Alert/Revenue/revenue-alert';
import { WebConferenceIntegrationAlert } from '../Alert/Integrations/web-conference-integration-alert';
import {UserPasswordAlert} from '../Alert/UserPassword/user-password-alert';
import { PointOfContactAlert } from '../Alert/PointOfContact/point-of-contact-alert';
import { ExhibitorsAlert } from '../Alert/Exhibitors/exhibitors-alert';
import {WelcomeEmailAlert} from '../Alert/WelcomeEmail/welcome-email-alert';
import {PaymentSettingsAlert} from "../Alert/MyAccount/payment-settings-alert";
import {EfModalService} from "./eventFarmModal.service";


@Injectable()

export class AlertService {
    private alert: SweetAlert;
    private routeGenerator: RouteGeneratorService;

    constructor(
        private apiClient: EventFarmAPIClient,
        private efModalService: EfModalService
    ) {
        this.alert = new SweetAlert();
        this.routeGenerator = new RouteGeneratorService();
    }

    general() {
        return new GeneralAlert(this.alert, this.routeGenerator);
    }

    guestList() {
        return new GuestListAlert(this.alert, this.routeGenerator, this.apiClient, this.efModalService);
    }

    addressBook() {
        return new AddressBookAlert(this.alert, this.routeGenerator);
    }

    exhibitors() {
        return new ExhibitorsAlert(this.alert, this.routeGenerator);
    }

    ticketBlock() {
        return new TicketBlockAlert(this.alert, this.routeGenerator, this.apiClient);
    }

    ticketStack() {
        return new TicketStackAlert(this.alert, this.routeGenerator);
    }

    ticketType() {
        return new TicketTypeAlert(this.alert, this.routeGenerator);
    }

    salesforceIntegration() {
        return new SalesforceIntegrationAlert(this.alert, this.routeGenerator);
    }

    webConferenceIntegration() {
        return new WebConferenceIntegrationAlert(this.alert, this.routeGenerator);
    }

    virbelaIntegration() {
        return new VirbelaIntegrationAlert(this.alert, this.routeGenerator,  this.apiClient);
    }

    emailDesignCenter() {
        return new EmailDesignCenterAlert(this.alert, this.routeGenerator);
    }

    invitation() {
        return new InvitationAlert(this.alert, this.routeGenerator, null, this.efModalService);
    }

    questions() {
        return new QuestionsAlert(this.alert, this.routeGenerator);
    }

    uploader() {
        return new UploaderAlert(this.alert, this.routeGenerator);
    }

    userRoles() {
        return new UserRolesAlert(this.alert, this.routeGenerator, this.apiClient);
    }

    pointOfContact() {
        return new PointOfContactAlert(this.alert, this.routeGenerator, this.apiClient);
    }

    welcomeEmail() {
        return new WelcomeEmailAlert(this.alert, this.routeGenerator, this.apiClient);
    }

    userPassword() {
        return new UserPasswordAlert(this.alert, this.routeGenerator, this.apiClient);
    }

    revenue() {
        return new RevenueAlert(this.alert, this.routeGenerator, this.apiClient);
    }

    sitePagePreview() {
        return new SitePagePreviewAlert(this.alert, this.routeGenerator,  this.apiClient);
    }

    paymentSettings() {
        return new PaymentSettingsAlert(this.alert, this.routeGenerator,  this.apiClient);
    }
}
