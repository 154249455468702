import { AbstractModel } from '../abstract-model';

export class Allotment extends AbstractModel {

    private _type?: string;
    private _name?: string;
    private _maxQuantity?: number;
    private _expiresAt?: string;
    private _deletedAt?: string;
    private _max_qty?: number;
    private _maxEvents?: number;

    private _created?: string;

    constructor( id: string) {
        super(id);
    }

    public static fromApiResponse(data): Allotment {
        const allotment = new this(data.id);
        allotment._type = data.attributes && data.attributes.poolAllotmentType ? data.attributes.poolAllotmentType.slug : '';
        allotment._maxEvents = data.attributes && data.attributes.maxEvents ? data.attributes.maxEvents : '';

        allotment._maxQuantity = data.attributes && data.attributes._maxQuantity ? data.attributes._maxQuantity : null;
        allotment._expiresAt = data.attributes && data.attributes._expiresAt ? data.attributes._expiresAt : null;
        allotment._deletedAt = data.attributes && data.attributes._deletedAt ? data.attributes._deletedAt : null;

        return allotment;
    }

    get type(): string {
        return this._type;
    }

    get deleted_date(): string {
        return this._deletedAt;
    }

    get expires(): string {
        return this._expiresAt;
    }

    get max_qty(): number {
        return this._max_qty;
    }
    get maxEvents(): number {
        return this._maxEvents;
    }
    get deleted(): string {
        return this._deletedAt;
    }

}
