import { Pipe } from '@angular/core';

@Pipe({
    name: 'slug',
})
export class SlugPipe {
    transform(slugs, option) {
        switch (option) {
            case 'required':
                return slugs.filter((i) => i['required'] && i['name'] !== 'GUEST_RESPONSES' && i['name'] !== 'INVITOR_NAME' && i['name'] !== 'ORDER_AMOUNT');
            case 'optional':
                return slugs.filter((i) => i['required'] !== true && i['name'] !== 'GUEST_RESPONSES' && i['name'] !== 'INVITOR_NAME' && i['name'] !== 'ORDER_AMOUNT');
            default:
        }
    }
}
