import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { GuestSimpleStatsModule } from '../../CoreUI/GuestSimpleStats/guestSimpleStats.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { TicketTypeListModule } from '../../CoreUI/TicketTypeList/ticketTypeList.module';
import { PaymentsPromotionsRoutingModule } from './payments-promotions-routing.module';
import { PaymentsPromotionsComponent } from './payments-promotions.component';
import { PromoListComponent } from './Promos/promo-list.component';
import { PromoItemComponent } from './Promos/PromoItem/promo-item.component';
import { EditSaveButtonComponent } from '../../CoreUI/EditSaveButton/edit-save-button.component';
import { PaymentsPromotionsService } from './payments-promotions.service';
import { EditPromoComponent } from './Promos/PromoItem/EditPromo/edit-promo.component';
import { EditPromoButtonComponent } from './Promos/PromoItem/EditPromo/edit-promo-button.component';
import { EditSaveButtonModule } from '../../CoreUI/EditSaveButton/edit-save-button.module';
import { EventAccessService } from '../EventAccess/event-access.service';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';


@NgModule({
    bootstrap: [PaymentsPromotionsComponent],
    declarations: [
        PaymentsPromotionsComponent,
        PromoListComponent,
        PromoItemComponent,
        // EditSaveButtonComponent,
        EditPromoComponent,
        EditPromoButtonComponent,
    ],
    entryComponents: [
        PaymentsPromotionsComponent,
        EditPromoComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TicketTypeListModule,
        GuestSimpleStatsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        PaymentsPromotionsRoutingModule,
        NzUtilitiesModule,
        EditSaveButtonModule,
        NgxUtilitiesModule
    ],
    providers: [
        PaymentsPromotionsService,
        EventAccessService
    ],
})

export class PaymentsPromoModule {
}
