import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from './../../manage.routing-module';
import { AdminService } from '../../../admin.service';
import { EventFarmService } from '../../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AlertService } from '../../../../eventFarmAlert.service';
import { TeamAllotmentsComponent } from './team-allotments.component';
import { TeamAllotmentService } from './team-allotment.service';
import { LoaderGrayModule } from '../../../../CoreUI/LoaderGray/loader-gray.module';

const forExport = [TeamAllotmentsComponent];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    LoaderGrayModule,
  ],
  providers: [
    AdminService,
    EventFarmService,
    NzDrawerService,
    AlertService,
    TeamAllotmentService,
  ],
})
export class TeamAllotmentModule {}
