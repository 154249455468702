import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-button',
  template: `
    <div
      class="dynamic-field form-group form-button"
      [formGroup]="group">
      <button type="submit" (click)="config.submit" class="btn btn-primary">
        {{ config.label }}
      </button>
    </div>
  `
})
export class FormButtonComponent implements Field {
  config;
  group: FormGroup;
}
