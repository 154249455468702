import { Injectable } from '@angular/core';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { InvitationOptionsService } from './invitationOptions.service';
import { AlertService } from '../../eventFarmAlert.service';

@Injectable()

export class AddInviteCountService {
    readonly defaultInviteCount = 1;

    private _stack?: Stack;

    constructor(
        private alertService: AlertService,
        private invitationOptionsService: InvitationOptionsService,
    ) {
        this.invitationOptionsService.invitationCount = this.defaultInviteCount;
    }

    get maxStackInviteQuantity(): number {
        if (this._stack && this._stack.maxQuantity) {

            if (this.invitationOptionsService.initialInvitation
                && this.invitationOptionsService.initialInvitation.ticketType.id === this.invitationOptionsService.selectedStack.ticketType.id
                && this.invitationOptionsService.initialInvitation.inviteCount > this._stack.maxQuantity) {
                    return this.invitationOptionsService.initialInvitation.inviteCount;

            } else {
                return this._stack.maxQuantity;
                
            };
        };

        return 10;
    }

    get maxStackAvailabilityCount(): number {
        let totalUnused = null;

        if (this._stack && this._stack.availabilityCounts) {
            totalUnused = this._stack.availabilityCounts.totalUnused;

            if (totalUnused < 0) {
                totalUnused = 0;
            } 
        };

        if (totalUnused !== null &&
            this.invitationOptionsService.initialInvitation &&
            this.invitationOptionsService.initialInvitation.ticketType.id === this.invitationOptionsService.selectedStack.ticketType.id) {
                return (this.invitationOptionsService.initialInvitation.inviteCount + totalUnused);

        } else if (totalUnused !== null) {
            return totalUnused;

        };

        return 10;
    }

    get maxInviteQuantityForStackMessage(): string {
        return 'The maximum invitations allowed for distribution on this access type is ' + this.maxStackInviteQuantity + '.';
    }

    notEnoughInvitationsMessage(attemptedInvitationCount): string {
        return attemptedInvitationCount + ' is greater than the available ' + this.maxStackAvailabilityCount + ' invitations. Please try again.';
    }

    get genericInviteQuantityMessage(): string {
        return 'The number of invitations selected is invalid.';
    }

    get inviteCount(): number {
        return this.invitationOptionsService.invitationCount;
    }

    set inviteCount(value: number) {
        this.invitationOptionsService.invitationCount = value;
    }

    set stack(stack: Stack) {
        this._stack = stack;
        this.validateInviteCount(true);
    }

    validateInviteCount(withAlert: boolean = true) {
        let showAlert = withAlert && (
                !this.inviteCountIsWithinMaxQuantity()
                || !this.inviteCountIsWithinMaxAvailability()
                || !this.inviteCountIsAtLeastOne()
            );
        let maxInviteQuantityForStackAlert = false;
        let notEnoughInvitationsAlert = false;
        let attemptedInvitationCount = this.invitationOptionsService.invitationCount;

        if (!this.inviteCountIsWithinMaxQuantity()) {
            maxInviteQuantityForStackAlert = true;
            this.invitationOptionsService.invitationCount = this.maxStackInviteQuantity;
        }

        if (!this.inviteCountIsWithinMaxAvailability()) {
            notEnoughInvitationsAlert = true;
            attemptedInvitationCount = this.invitationOptionsService.invitationCount;
            this.invitationOptionsService.invitationCount = this.maxStackAvailabilityCount;
        }

        if (!this.inviteCountIsAtLeastOne()) {
            this.invitationOptionsService.invitationCount = 1;
        }

        if (showAlert && this.stackMethodTypeIsNotFirstComeFirstServe()) {
            if (maxInviteQuantityForStackAlert) {
                this.alertService
                    .invitation()
                    .invalidMaximumInvitationCount(
                        this.maxInviteQuantityForStackMessage,
                    );
            } else if (notEnoughInvitationsAlert) {
                this.alertService
                    .invitation()
                    .invalidMaximumInvitationCount(
                        this.notEnoughInvitationsMessage(attemptedInvitationCount),
                    );
            } else {
                this.alertService
                    .invitation()
                    .invalidMaximumInvitationCount(
                        this.genericInviteQuantityMessage,
                    );
            }
        }
    }

    private stackMethodTypeIsNotFirstComeFirstServe() {
        return this._stack && !this._stack.stackMethodType.isFirstComeFirstServe;
    }

    decrementInviteCount() {
        if (this.inviteCountIsAtLeastTwo()) {
            this.invitationOptionsService.invitationCount--;
        } else {
            this.invitationOptionsService.invitationCount = this.defaultInviteCount;
        }
    }

    incrementInviteCount() {
        if (this.inviteCountIsAtLeastTwoBelowMax()) {
            this.invitationOptionsService.invitationCount++;
        } 
    }

    private canIncrement(): boolean {
        return this.inviteCountIsAtLeastTwoBelowMax();
    }

    private canDecrement(): boolean {
        return this.inviteCountIsAtLeastTwo();
    }

    private inviteCountIsAtLeastTwo(): boolean {
        return (this.invitationOptionsService.invitationCount >= 2);
    }

    private inviteCountIsAtLeastOne(): boolean {
        return (this.invitationOptionsService.invitationCount >= 1);
    }

    private inviteCountIsWithinMaxAvailability(): boolean {
        return (this.maxStackAvailabilityCount >= this.invitationOptionsService.invitationCount);
    }

    private inviteCountIsWithinMaxQuantity(): boolean {
        return (this.maxStackInviteQuantity >= this.invitationOptionsService.invitationCount);
    }

    private inviteCountIsAtLeastTwoBelowMax(): boolean {
        return (this.maxStackInviteQuantity > this.invitationOptionsService.invitationCount) && (this.maxStackAvailabilityCount > this.invitationOptionsService.invitationCount);
    }
}
