import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { TicketBlockListModule } from '../../CoreUI/TicketBlockList/ticket-block-list.module';
import { GuestOverviewModule } from '../../CoreUI/GuestOverview/guestOverview.module';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import { EventInfoRoutingModule } from './eventInfo-routing.module';
import { CopyEventDetailsComponent } from './CopyEventDetails/copy-event-details.component';
import { EventURLsComponent } from './EventURLs/eventURLs.component';
import { TimeRemainingUntilEventStartComponent } from './TimeRemaingUntilEventStart/timeRemainingUntilEventStart.component';
import { EventInfoComponent } from './eventInfo.component';
import { LegacyUIModule } from '../../LegacyUI/legacy-UI.module';
import { EventTypeaheadModule } from '../../CoreUI/Typeahead/event-typeahead.module';
import { UserTypeaheadModule } from '../../CoreUI/Typeahead/user-typeahead.module';
import { EventUsersComponent } from './EventUsers/event-users.component';
import { ArchiveEventComponent } from './ArchiveEvent/archive-event.component';
import { AcceptedCreditCardsComponent } from './AcceptedCreditCards/accepted-credit-cards.component';
import { DonationsOptionsComponent } from './DonationsOptions/donations-options.component';
import { GuestListManagementComponent } from './GuestListManagement/guest-list-management.component';
import { GuestSimpleStatsModule } from '../../CoreUI/GuestSimpleStats/guestSimpleStats.module';
import { EventDetailsComponent } from './EventDetails/event-details.component';
import { TimezoneTypeaheadModule } from '../../CoreUI/Typeahead/timezone-typeahead.module';
import { EventInfoHeaderComponent } from './EventInfoHeader/event-info-header.component';
import { EventLocationComponent } from './EventLocation/event-location.component';
import { EventAdvancedDetailsComponent } from './EventAdvancedDetails/event-advanced-details.component';
import { TagTypeaheadModule } from '../../CoreUI/Typeahead/tag-typeahead.module';
import { Userv2TypeaheadModule } from '../../CoreUI/Typeahead/userv2-typeahead.module';
import { RegistationSettingsComponent } from './RegistrationSettings/registration-settings.component';
import { InvitationRevealModule } from './InvitationReveal/invitation-reveal.module';
import { AllowDuplicatesModule } from './AllowDuplicates/allow-duplicates.module';
import { EditableNamesModule } from './EditableNames/editable-name.module';
import { GuestChangeResponseModule } from './GuestCanChangeResponse/guest-change-response.module';
import { MinMaxSelectionModule } from './MinMaxSelection/min-max-selection-module';
import { DistributionModule } from './Distribution/distribution.module';
import { ActivityLogModule } from './ActivityLog/activity-log.module';
import { EventAppModule } from './EventApp/event-app.module';
import { WaitlistToggleModule } from './WaitlistToggle/waitlist-toggle.module';
import { EventRevenueModule } from './Revenue/event-revenue.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import {LocalStorageService} from '../../../_services/storage/local-storage.service';
import {ModalService} from '../../CoreUI/Modal/modal.service';
import {
  CioGetStartedWelcomeModule
} from '../../CoreUI/Modal/Modals/CioGetStarted/CioGetStartedWelcome/cioGetStartedWelcome.module';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';
import { PaymentsNotConfiguredComponent } from './PaymentsNotConfigured/payments-not-configured.component';
import { PaymentsConfiguredComponent } from './PaymentsConfigured/payments-configured.component';
import { KioskModeBreakoutSessionsModule } from './KioskModeBreakOutSessions/kiosk-mode-breakout-sessions.module';
import { KioskModeGuestPassesModule } from './KioskModeGuestPasses/kiosk-mode-guest-passes.module';

@NgModule({
  bootstrap: [EventInfoComponent],
  declarations: [
    EventInfoComponent,
    EventLocationComponent,
    EventUsersComponent,
    CopyEventDetailsComponent,
    EventURLsComponent,
    ArchiveEventComponent,
    TimeRemainingUntilEventStartComponent,
    AcceptedCreditCardsComponent,
    DonationsOptionsComponent,
    GuestListManagementComponent,
    EventDetailsComponent,
    EventInfoHeaderComponent,
    EventAdvancedDetailsComponent,
    RegistationSettingsComponent,
    PaymentsNotConfiguredComponent,
    PaymentsConfiguredComponent
  ],
  entryComponents: [
    EventInfoComponent,
    CopyEventDetailsComponent
  ],
  imports: [
    CommonModule,
    GuestSimpleStatsModule,
    FormsModule,
    ReactiveFormsModule,
    TagTypeaheadModule,
    LegacyUIModule,
    ModalModule,
    ErrorModule,
    LoadingOverlayModule,
    TicketBlockListModule,
    GuestOverviewModule,
    UtilitiesModule,
    BreadcrumbsModule,
    EventInfoRoutingModule,
    EventTypeaheadModule,
    UserTypeaheadModule,
    Userv2TypeaheadModule,
    NzUtilitiesModule,
    TimezoneTypeaheadModule,
    InvitationRevealModule,
    AllowDuplicatesModule,
    EditableNamesModule,
    GuestChangeResponseModule,
    MinMaxSelectionModule,
    DistributionModule,
    ActivityLogModule,
    EventAppModule,
    WaitlistToggleModule,
    EventRevenueModule,
    CioGetStartedWelcomeModule,
    NgxUtilitiesModule,
    KioskModeBreakoutSessionsModule,
    KioskModeGuestPassesModule
  ],
  providers: [
    ModalService,
    LocalStorageService
  ],
  exports: [
  ]
})

export class EventInfoModule {
}
