import { LinkTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Link';
import { AbstractModel } from '../abstract-model';
import { EFLink } from '../Link/ef-link';


export class ProfileLink extends AbstractModel {
    private _link: EFLink;
    
    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const profileLink = new this(data.id);

        if (data.relationships && data.relationships.link) {
            profileLink._link = EFLink.fromApiResponse(data.relationships.link);
        } 

        return profileLink;
    }

    get link(): EFLink {
        return this._link;
    }

    set link(value: EFLink) {
        this._link = value;
    }
}
