import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { UserFormService, BasicUserDetails } from '../../../../CoreUI/UserForm/userForm.service';
import { Subscription } from 'rxjs';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';
import { Group } from '../../../../../ApiClient/Models/Group/group';
import { EventFarmService } from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { ApiError } from '../../../../../ApiClient/Models/error';
import { User } from '../../../../../ApiClient/Models/User/user';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';

@Component({
    selector: 'address-book-edit-user',
    styles: [require('./address-book-edit-user.scss')],
    template: require('./address-book-edit-user.html'),
})

export class AddressBookEditUserComponent implements OnInit, OnDestroy {
    constructor(
        private modalService: ModalService,
        private userFormService: UserFormService,
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private addressBookService: AddressBookService,
        private segmentService: SegmentService,
        private addressBookUserService: AddressBookUserService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.userFormEmailChanged$ = this.userFormService.userChange$.subscribe(
            (res) => {
                this.basicUserDetails = res;
                if (this.basicUserDetails.email !== this.userFormService.initialUserDetails.email) {
                    this.duplicateUserWithNewEmail();
                }
            },
            (err) => {}
        );
        this.userFormSubmitted$ = this.userFormService.userSubmit$.subscribe(
            (res) => {
                if (res.isValid) {
                    this.save();
                }
            }
        );
        this.userGroups$ = this.addressBookUserService.groupsSelectedForUser$.subscribe(
            (res) => {
                this.userFormService.basicUserDetails.hasChanges = true;
            }
        );
        this.groupMembershipForUser$ = this.addressBookUserService.groupMembershipForUser$.subscribe((res: Group[]) => {
            this.userGroups = res;
            this.requesting = false;
        });
    }

    readonly userPersonBlueSVG = require('../../assets/circle-user-person-blue');
    readonly saveIconSVG = require('../../assets/save-icon');

    userFormEmailChanged$: Subscription;
    userFormSubmitted$: Subscription;
    groupMembershipForUser$: Subscription;
    userGroups$: Subscription;
    basicUserDetails: BasicUserDetails;
    requesting: boolean;
    errors: ApiError[];
    userGroups: Group[];

    @ViewChild('editUserTabs', {static: false}) staticTabs: TabsetComponent;

    ngOnInit() {
        this.addressBookUserService.isInvitationDetailsVisible = false;
        this.requesting = false;
        this.setExistingUserIdFromUrl();
        this.setTabFromUrl();
        this.fetchDetailedContactInfo();
        this.addressBookUserService.broadcastGroupMembershipForUser();
        this.errors = [];
        this.requesting = false;
    }

    public hasChanges(): boolean {
        if (this.userFormService.basicUserDetails.hasChanges) {
            return true;
        }
        return false;
    }

    public fetchDetailedContactInfo() {
        if (this.addressBookService.selectedUser) {
            this.addressBookUserService.getUserInPool(this.eventFarmService.currentTeam.id, this.addressBookService.selectedUser.id)
                .toPromise()
                .then((res) => {
                    this.addressBookService.selectedUser = User.fromApiResponse(res.data);
                });
        }
    }

    private setExistingUserIdFromUrl() {
        try {
            this.addressBookService.selectedUser = new User(this.route.children[0].snapshot.children[0].url[1].path);
        } catch (err) {
        }
    }

    private setTabFromUrl() {
        this.route.queryParams.subscribe((queryParams) => {
            const tab = queryParams['tab'] || null;
            if (tab === 'group') {
                this.selectTab(1);
            }
        });
    }

    public selectTab(tabId: number) {
        this.segmentService.segmentAddressBookTracking().editUserModalChangeTab(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            tabId
        );
        if (this.staticTabs.tabs[tabId]) {
            this.staticTabs.tabs[tabId].active = true;
        }
        this.addressBookUserService.isInvitationDetailsVisible = false;
    }

    public save(ignoreWarnings = false) {

        this.basicUserDetails = this.userFormService.basicUserDetails;

        if (!ignoreWarnings && this.basicUserDetails.email !== this.userFormService.initialUserDetails.email) {
            this.modalService.closeModal();
            this.alertService.addressBook().checkIfUserWantsToAbandonEmailChanges()
                .then((res) => {
                    this.modalService.showModal();
                    if (res.value) {
                        this.save(true);
                    }
                });
            return;
        }

        if (!this.userFormService.basicUserDetails.hasChanges) {
            this.userFormService.basicUserDetails.isValid = false;
            return;
        }

        this.userFormService.basicUserDetails.hasChanges = false;
        this.requesting = true;
        this.errors = [];
        let otherUserDataPromises: Array<Promise<any>> = [];

        otherUserDataPromises = this.addressBookUserService.generateUserUpdateApiPromises(this.addressBookService.selectedUser.id, this.basicUserDetails);

        if (this.basicUserDetails.hasChanges && this.addressBookUserService.groupsSelectedForUser) {
            this.addressBookUserService.groupsSelectedForUser.forEach((userGroup) => {
                otherUserDataPromises.push(this.addressBookUserService.addUsersToGroup(userGroup.id, [this.addressBookService.selectedUser.id]).toPromise());
            });
        }

        Promise.all(otherUserDataPromises)
            .then((res) => {
                this.requesting = false;
                if (this.addressBookService.selectedGroup) {
                    this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                } else {
                    this.addressBookUserService.broadcastAllUsers();
                }
                this.userFormService.basicUserDetails.hasChanges = false;
                this.basicUserDetails = this.userFormService.basicUserDetails;
                this.close();
            }).catch((err) => {
                this.requesting = false;
                this.errors = this.addressBookUserService.extractErrorsIntoMessages(err);
        });
    }

    private duplicateUserWithNewEmail() {
        const otherUserDataPromises: Promise<any>[] = [];
        this.addressBookUserService.checkIfUserCanBeDeleted(this.addressBookService.selectedUser.id, this.eventFarmService.currentUser.id, this.addressBookService.currentTeam.id)
            .toPromise()
            .then((res) => {
                this.errors = [];
                this.requesting = true;
                this.userFormService.basicUserDetails.hasChanges = false;
                this.basicUserDetails = this.userFormService.basicUserDetails;
                return this.addressBookUserService.createUser(
                    this.basicUserDetails.email,
                    this.basicUserDetails.firstName,
                    this.basicUserDetails.lastName,
                    this.basicUserDetails.company,
                    this.basicUserDetails.position,
                    this.basicUserDetails.telephone ? this.basicUserDetails.telephone['e164Number'] : null,
                    this.eventFarmService.currentTeam.id,
                    this.basicUserDetails.title,
                    this.basicUserDetails.other)
                .toPromise();
            }).then((res) => {
                if (this.basicUserDetails.address1 && this.basicUserDetails.city && this.basicUserDetails.state && this.basicUserDetails.postalCode) {
                    const userAddress = this.addressBookUserService.addAddressToUser(
                        this.eventFarmService.currentTeam.id,
                        res.data.command.userId,
                        this.basicUserDetails.address1,
                        this.basicUserDetails.address2,
                        this.basicUserDetails.city,
                        this.basicUserDetails.state,
                        this.basicUserDetails.postalCode,
                        this.basicUserDetails.country
                    ).toPromise();
                    otherUserDataPromises.push(userAddress);
                }

                if (this.userGroups) {
                    this.userGroups.forEach((userGroup) => {
                        otherUserDataPromises.push(this.addressBookUserService.addUsersToGroup(userGroup.id, [res.data.command.userId]).toPromise());
                    });
                }

                if (otherUserDataPromises.length) {
                    return Promise.all(otherUserDataPromises);
                } else {
                    return {};
                }
            })
            .then((res) => {
                this.requesting = false;

                if (this.addressBookService.selectedGroup) {
                    this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                } else {
                    this.addressBookUserService.broadcastAllUsers();
                }

                this.userFormService.basicUserDetails.hasChanges = false;
                this.userFormService.requesting = false;
                this.basicUserDetails = this.userFormService.basicUserDetails;
                this.alertService.addressBook().userDuplicatedSuccess();
            }).catch((err) => {
                if (err.status === 409) {
                    let idForExistingUser = null;
                    err.error.errors.forEach((error) => {
                        idForExistingUser = (error.meta && error.meta.userId) ? error.meta.userId : null;
                    });
                    const poolId = this.addressBookService.currentTeam.id;
                    const existingUser = new User(idForExistingUser);
                    this.addressBookUserService.getUserInPool(poolId, existingUser.id)
                        .toPromise()
                        .then((res) => {
                            if (res.data.relationships.userAttributes.length || res.data.relationships.userNames.length) {
                                this.addressBookUserService.redirectToExistingUserInPool(existingUser, poolId);
                                this.requesting = false;
                            } else {
                                this.updateUserAttributesForExistingUserAssociatedWithDifferentPool(existingUser.id);
                            }
                        }).catch((error) => {
                            this.updateUserAttributesForExistingUserAssociatedWithDifferentPool(existingUser.id);
                        });
                    return;
                }
                this.userFormService.requesting = false;
                this.errors = this.addressBookUserService.extractErrorsIntoMessages(err);
        });
    }

    private updateUserAttributesForExistingUserAssociatedWithDifferentPool(existingUserId: string) {
        const existingUserDataPromises: Array<Promise<any>> = this.addressBookUserService.generateUserUpdateApiPromises(existingUserId, this.basicUserDetails);
        this.requesting = false;
        if (existingUserDataPromises.length) {
            Promise.all(existingUserDataPromises)
                .then((res) => {
                    this.userFormService.basicUserDetails.hasChanges = false;
                    this.basicUserDetails = this.userFormService.basicUserDetails;
                    if (this.addressBookService.selectedGroup) {
                        this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                    } else {
                        this.addressBookUserService.broadcastAllUsers();
                    }
                    this.modalService.closeModal();
                    this.alertService.addressBook().userDuplicatedSuccess();
                }).catch((err) => {
                    const formattedErrors: any[] = [];
                    formattedErrors.push({
                        detail: 'Unable to save all contact details due to permission limitations.',
                    });
                    this.errors = ApiError.fromApiErrorArray(formattedErrors);
                });
        }
    }

    public close() {
        this.segmentService.segmentAddressBookTracking().closeEditUserModal(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.modalService.closeModal();
    }

    ngOnDestroy() {
        this.userFormEmailChanged$.unsubscribe();
        this.userFormSubmitted$.unsubscribe();
        this.userGroups$.unsubscribe();
        this.groupMembershipForUser$.unsubscribe();
    }
}
