import { Observable } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { User } from '../../../ApiClient/Models/User/user';
import { Injectable } from '@angular/core';
import { EfEvent } from '../../../ApiClient/Models/Event/event';

@Injectable()
export class TicketBlockUsersService {

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
    ) {
    }

    public async listUsersForTicketBlock(ticketBlockId: string): Promise<User[]> {
        let users = [];

        try {
            const res = await this.apiClient.getUseCaseFactory().User()
                        .ListUsersForTicketBlock(
                        ticketBlockId,
                        [
                            'UserNames',
                            'UserIdentifiers'
                        ],
                        null,
                        null,
                        null,
                        1, 
                        100
                    ).toPromise();
   
            users = res.data.map((userResponse) => User.fromApiResponse(userResponse));
        } catch (e) {

        }
        return users;
    }

    public async listSessionsForParentEvent(parentEventId: string): Promise<EfEvent[]> {
        let sessions = [];

        try {
            const res = await this.apiClient.getUseCaseFactory().Event().ListChildrenForEvent(
                        parentEventId,
                        null,
                        [
                            'Stacks',
                            'StacksWithAvailabilityCounts',
                            'TicketTypes'
                        ],
                        1,
                        500,
                        'event-start',
                        'ascending',
                        null,
                        null,
                        null,
                        null,
                        ['archived']
                    ).toPromise();
   
            sessions = res.data.map((er) => EfEvent.fromApiResponse(er));
        } catch (e) {

        }
        return sessions;
    }

    public addUserRoleToTicketBlock(ticketBlockId: string, user: User): Observable<any> {
        return this.apiClient.getUseCaseFactory().TicketBlock().AddUserRoleToTicketBlock(
            ticketBlockId,
            user.primaryEmail,
            user.name.firstName,
            user.name.lastName,
            this.eventFarmService.currentUser.id
        );
    }

    public addAllotment(ticketBlockId: string, stackId: string, quantity: number, allotmentId: string = null): Observable<any> {
        return this.apiClient.getUseCaseFactory().Allotment().CreateAllotment(ticketBlockId, stackId, quantity, allotmentId);
    }

    public removeAllotment(allotmentId: string) {
        return this.apiClient.getUseCaseFactory().Allotment().DeleteAllotment(allotmentId);
    }

    public updateAllotment(allotmentId: string, quanity: number) {
        return this.apiClient.getUseCaseFactory().Allotment().SetAllotmentQuantity(allotmentId, quanity);
    }

    public removeTicketBlockUser(userId: string, ticketBlockId: string): Observable<any> {
        return this.apiClient.getUseCaseFactory().TicketBlock().RemoveAllUserRolesFromTicketBlock(userId, ticketBlockId);
    }
}
