import { Component, OnInit, Input } from '@angular/core';
import { EFXAdminService } from '../../efx-admin.service';

import { Module } from '../Models/module-models';

@Component({
    selector: 'efx-module',
    template: require('./efx-module.html'),
    styles: [require('./efx-module.scss')],
})

export class EFXModuleComponent implements OnInit {

    @Input() module: Module;

    constructor(
        private efxAdminService: EFXAdminService
    ) { }

    ngOnInit() {
    }

}
