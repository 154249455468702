import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {VersionMonitorService} from './VersionMonitor/version-monitor.service';

@Injectable()
export class HTTPAppVersionInterceptor implements HttpInterceptor {

    constructor(private versionMonitorService: VersionMonitorService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap({
                    next: (event) => {
                       if (event instanceof HttpResponse) {
                           if (event.headers && event.headers.get('Wakanda')) {
                               const latestVersion = event.headers.get('Wakanda');
                               const loadedVersion = this.versionMonitorService.loadedVersion;
                               if (latestVersion !== loadedVersion) {
                                   this.versionMonitorService.$loadedVersion.next(latestVersion);
                               }
                           }
                        }
                       return event;
                    }
                }
            )
        );
    }
}