import { Injectable } from '@angular/core';
import { Observable, Operator } from 'rxjs';
import { UploaderService } from '../uploader.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Router } from '@angular/router';
import { UploaderTabsService } from '../uploaderTabs.service';
import { ProcessingService } from '../Processing/processing.service';

@Injectable()
export class FinishService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private uploaderService: UploaderService,
        private processingService: ProcessingService,
        private router: Router,
    ) {
    }
}
