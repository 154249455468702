import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminResolver } from './admin-resolver.service';
import {AdminGuardService} from './admin.guard';

const adminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AdminGuardService],
    children: [
      {
        path: 'user',
        resolve: [AdminResolver],
        loadChildren: () => import('./User/user.module').then(
            module => module.UserModule
        ),
        canActivate: [AdminGuardService],

      },
      {
        path: 'manage',
        resolve: [AdminResolver],
        loadChildren: () => import('./Manage/manage.module').then(
            module => module.ManageModule
        ),
        canActivate: [AdminGuardService],

      },
      {
        path: 'data',
        resolve: [AdminResolver],
        loadChildren: () => import('./Data/data.module').then(
            module => module.DataModule
        ),
        canActivate: [AdminGuardService],

      },
      {
        path: '**',
        resolve: [AdminResolver],
        loadChildren: () => import('./User/user.module').then(
            module => module.UserModule
        )
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
  providers: [
    // AdminGuard,
    AdminResolver,
  ],
})
export class AdminRoutingModule {}
