import { LinkTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Link';
import { Profile, ProfileTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Profile';
import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { EventProfile } from './eventProfile';
import { ProfileLink } from './profileLink';


export interface UpdatableProfileOptions {
    firstName?: string;
    lastName?: string;
    company?: string;
    emailAddress?: string;
    title?: string;
    description?: string;
    imageUrl?: string;
}

export enum LinkAction {
    ADD,
    UPDATE,
    DELETE,
    NONE
}

export interface ProfileLinkAction {
    value: string;
    action: LinkAction,
    type: LinkTypeInterface,
    linkId: string;
}

export class EFProfile extends AbstractModel {
    private _firstName?: string;
    private _lastName?: string;
    private _company?: string;
    private _emailAddress?: string;
    private _title?: string;
    private _description?: string;
    private _imageUrl?: string;
    private _profileType?: ProfileTypeInterface;
    private _profileId?: string;
    private _isDeleted?: boolean;
    private _deleted?: number;
    private _created?: number;
    private _lastModified?: number;
    private _createdBy?: string;
    private _event: EfEvent;
    private _eventProfiles: EventProfile[];
    private _profileLinks: ProfileLink[];


    // variables for the speaker to session component
    private _selected: boolean;
    private _disabled: boolean;
    private _edit: boolean;
    private _eventProfileSlug: string;
    private _eventProfileId: string;

    constructor(id?: string) {
        super(id);
        this._eventProfiles = [];
        this.profileLinks = [];
    }

    public static fromApiResponse(data) {
        const profile = new this(data.id);

        profile._firstName = data.attributes.firstName;
        profile._lastName = data.attributes.lastName;
        profile._description = data.attributes.description;
        profile._company = data.attributes.company;
        profile._emailAddress = data.attributes.emailAddress;
        profile._title = data.attributes.title;
        profile._imageUrl = data.attributes.imageUrl;
        profile._profileType = data.attributes.profileType;
        profile._profileId = data.attributes.profileId;
        profile._isDeleted = data.attributes.isDeleted;
        profile._deleted = data.attributes.deletedAt;
        profile._created = data.attributes.created.createdAt;
        profile._lastModified = data.attributes.modified.modifiedAt;

        if (data.relationships && data.relationships.event) {
            profile._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        profile._eventProfiles = [];

        if (data.relationships && data.relationships.eventProfiles) {
            profile._eventProfiles = data.relationships.eventProfiles.map(eventProfile => {
                return EventProfile.fromApiResponse(eventProfile);
            });
        }

        profile._profileLinks = [];

        if (data.relationships && data.relationships.profileLinks) {
            profile._profileLinks = data.relationships.profileLinks.map(profileLink => {
                return ProfileLink.fromApiResponse(profileLink);
            });
        }

        return profile;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get company(): string {
        return this._company;
    }

    set company(value: string) {
        this._company = value;
    }

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get imageUrl(): string {
        return this._imageUrl;
    }

    set imageUrl(value: string) {
        this._imageUrl = value;
    }

    get profileType(): ProfileTypeInterface {
        return this._profileType;
    }

    set profileType(value: ProfileTypeInterface) {
        this._profileType = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get deleted(): number {
        return this._deleted;
    }

    set deleted(value: number) {
        this._deleted = value;
    }

    get created(): number {
        return this._created;
    }

    set created(value: number) {
        this._created = value;
    }

    get lastModified(): number {
        return this._lastModified;
    }

    set lastModified(value: number) {
        this._lastModified = value;
    }

    get createdBy(): string {
        return this._createdBy;
    }

    set createdBy(value: string) {
        this._createdBy = value;
    }

    get event(): EfEvent {
        return this._event;
    }

    set event(value: EfEvent) {
        this._event = value;
    }

    get eventProfiles(): EventProfile[] {
        return this._eventProfiles;
    }

    set eventProfiles(value: EventProfile[]) {
        this._eventProfiles = value;
    }

    get profileLinks(): ProfileLink[] {
        return this._profileLinks;
    }

    set profileLinks(value: ProfileLink[]) {
        this._profileLinks = value;
    }

    get firstEventProfile(): EventProfile|null {
        return this._eventProfiles.length ? this._eventProfiles[0] : null;
    }

    get firstEventProfileTypeSlug(): string {
        return this._eventProfiles.length ? this._eventProfiles[0].eventProfileType.slug : 'speaker';
    }

    get firstEventProfileTypeName(): string {
        return this._eventProfiles.length ? this._eventProfiles[0].eventProfileType.name : 'Speaker';
    }

    get firstEventProfileTypeId(): string|null {
        return this._eventProfiles.length ? this._eventProfiles[0].id : null;
    }
    
    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        this._selected = value;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
    }

    get eventProfileSlug(): string {
        return this._eventProfileSlug;
    }

    set eventProfileSlug(value: string) {
        this._eventProfileSlug = value;

        if (this._eventProfiles.length) {
            const newType = new Profile().EventProfileType().find(t => t.slug === value);

            if (newType !== undefined) {
                this._eventProfiles[0].eventProfileType = newType;
            }
        }
    }

    get eventProfileId(): string {
        return this._eventProfileId;
    }

    set eventProfileId(value: string) {
        this._eventProfileId = value;
    }
    
    get edit(): boolean {
        return this._edit;
    }

    set edit(value: boolean) {
        this._edit = value;
    }

    get fullName(): string {
        return this._firstName + ' ' + this._lastName;
    }
}
