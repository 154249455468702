import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[ef-modal]',
})

export class EfModalDirective {
    constructor(public viewContainerRef: ViewContainerRef) {
    }
}
