import {Action} from '@ngrx/store';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {ContractDetailsFormValues} from '../../../AdminModule/Common/ContractDetailsForm/contract-details-form.component';
import {Team} from "../../../../ApiClient/Models/Pool/team";

export const CREATE_TEAM_FOR_ADMIN = '[Admin/Team] Create Team';
export const CREATE_TEAM_FOR_ADMIN_SUCCESS = '[Admin/Team] Create Team Success';
export const CREATE_TEAM_FOR_ADMIN_FAIL = '[Admin/Team] Create Team Fail';

export class CreateTeamForAdmin implements Action {
    readonly type = CREATE_TEAM_FOR_ADMIN;
    constructor( public payload: {teamName: string, contract: ContractDetailsFormValues}) {}
}

export class CreateTeamForAdminSuccess implements Action {
    readonly type = CREATE_TEAM_FOR_ADMIN_SUCCESS;
    constructor(public payload: { res: any }) { }
}

export class CreateTeamForAdminFail implements Action {
    readonly type = CREATE_TEAM_FOR_ADMIN_FAIL;
    constructor(public payload: string) { }
}

export type AdminCreateTeamAction =
    CreateTeamForAdmin |
    CreateTeamForAdminSuccess |
    CreateTeamForAdminFail;
