import { AbstractModel } from '../abstract-model';

export class Refund extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _amount: number;
    private _refundTranactionId: string;
    private _created: any;

    public static fromApiResponse(data) {
        const refund = new this(data.id);
        const attributes = data.attributes;
        refund._refundTranactionId = attributes.refundTransId;
        refund._amount = attributes.amount;
        refund._created = attributes.created;
        return refund;
    }

    /**
     * Getter amount
     * @return {number}
     */
    public get amount(): number {
        return this._amount;
    }

    /**
     * Getter refundTranactionId
     * @return {string}
     */
    public get refundTranactionId(): string {
        return this._refundTranactionId;
    }

    /**
     * Getter created
     * @return {any}
     */
    public get created(): any {
        return this._created;
    }
}
