import { Component, Input, OnInit } from '@angular/core';
import { QuestionCenterService } from '../question-center.service';

@Component({
  selector: 'toggle-question-view-button',
  template: `
    <button
      nz-button
      nzType="secondary"
      class="ef-btn-sm"
      style="margin-left: 10px;"
      (click)="toggle()"
    >{{ buttonText }}</button>
  `
})

export class ToggleQuestionViewButtonComponent implements OnInit {
  
  constructor(
    private questionCenterService: QuestionCenterService
  ) {}

  ngOnInit() {
  }

  get buttonText(): string {
    return this.questionCenterService.isListView ? 'Preview Questions' : 'Edit Questions';
  }

  toggle(): void {
    this.questionCenterService.toggleView();
  }

}