import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { BehaviorSubject ,  Subscription ,  Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EventFarmService } from '../../eventFarm.service';
import { User } from '../../../ApiClient/Models/User/user';
import { TicketBlock } from '../../../ApiClient/Models/Stack/ticket-block';
import { AlertService } from '../../eventFarmAlert.service';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { filter, take, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { stopCoverage } from 'v8';
import { TicketBlockUsersService } from './ticket-block-users.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import * as _ from 'lodash';

@Component({
    selector: 'ticket-block-users',
    template: require('./ticket-block-users.html'),
    styles: [require('./ticket-block-users.scss')],
})

export class TicketBlockUsersComponent implements OnInit, OnDestroy {
    @Input() ticketBlock: TicketBlock;
    @Input() canNotEdit: boolean;
    @Input() allTicketBlocks?: TicketBlock[];
    @Input() sessions?: EfEvent[];
    @Input() context: 'ticketBlock' | 'fullPlatform';
    @Output() ticketBlockChange: EventEmitter<any> = new EventEmitter();
    @Output() ticketBlockEditing: EventEmitter<any> = new EventEmitter();

    private ticketBlockUsers: User[];
    private isAddingUser: boolean;
    private requesting: boolean;
    private initialAllotments: any[];
    private editedAllotments: any[];
    private initialStacks: Stack[];
    private currentSessionStacks: Stack[];
    private availableStacks: Stack[];
    private newAllotmentsFromStacks: Stack[];
    private isEditing: boolean;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay()).pipe(shareReplay());
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventUpdate$: Subscription;
    private newUserToAdd: BehaviorSubject<{}> = new BehaviorSubject({name: 'Select One...', required: true});
    private error: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private stackToSessionMap = {};

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private element: Renderer2,
        private alertService: AlertService,
        private ticketBlockUsersService: TicketBlockUsersService,
        private message: NzMessageService,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.generateStackCounts();
            this.generateAllotments();
        });
    }

    ngOnChanges(changes: any): void {
        if (!this.ticketBlock || !this.ticketBlock.id) {
            return;
        }

        this.generateStackCounts();
        this.generateAllotments();
        this.getTicketBlockUsers();
    }

    async getTicketBlockUsers() {
        this.ticketBlockUsers = await this.ticketBlockUsersService.listUsersForTicketBlock(this.ticketBlock.id);
    }

    ngOnInit() {
        this.ticketBlockUsers = [];
        this.currentSessionStacks = [];
        this.initialStacks = [];
        this.requesting = false;
        this.isEditing = false;
        this.isAddingUser = false;
        this.generateStackCounts();
        this.generateAllotments();

        if (!this.context) {
            this.context = 'fullPlatform';
        }
    }

    private getFullNameWithEmail(user: User): string {
        return User.getFullNameWithEmail(user.name, user.identifier);
    }

    private generateAllotments() {
        if (this.ticketBlock && this.ticketBlock.extraAttributes && this.ticketBlock.extraAttributes.allotmentCounts) {
            this.editedAllotments = cloneDeep(this.ticketBlock.extraAttributes.allotmentCounts);
            this.initialAllotments = cloneDeep(this.ticketBlock.extraAttributes.allotmentCounts);
        }
    }

    private async generateStackCounts() {
        // create session ticket block group if beta and sessions are enabled
        if (_.get(this, 'ticketBlock.isSessionTB', false)) {
            const sessionStacks = this.genSessionStacks();
            this.initialStacks = this.assembleFilteredStacks(cloneDeep(sessionStacks));
            this.currentSessionStacks = cloneDeep(sessionStacks);
        } else {
            if (_.get(this, 'eventFarmService.currentEvent.stacks.length')) {
                this.initialStacks = this.assembleFilteredStacks(cloneDeep(this.eventFarmService.currentEvent.stacks));
            } else {
                this.initialStacks = [];
            }
        }

        this.newAllotmentsFromStacks = this.generateNewAllotmentsFromStacks(cloneDeep(this.initialStacks));
        this.availableStacks = cloneDeep(this.initialStacks);
    }

    private genSessionStacks() {
        const sessionStacks = [];

        if (_.get(this, 'sessions.length', 0)) {
            for (let i = 0; i < this.sessions.length; i++) {
                const curSession = this.sessions[i];

                if (curSession.stacks && curSession.stacks.length === 1 && !curSession.stacks[0].isDeleted) {
                    const curStack = curSession.stacks[0];
                    this.stackToSessionMap[curStack.id] = curSession.name;
                    sessionStacks.push(curStack);
                }
            }
        }

        return sessionStacks;
    }

    getTicketTypeNameForStack(stack: Stack) {

        if (!stack) {
            return '';
        }

        if (this.context === 'ticketBlock') {
            if (stack.event && stack.event.hasParentEvent) {
                return stack.event.name;
            }
        } else {
            if (this.stackToSessionMap[stack.id]) {
                return this.stackToSessionMap[stack.id];
            }
        }

        return stack.ticketType.name;
    }

    private removeUser(user: User, i: number, element): void {
        let button = element.target;
        let confirm = button.dataset.confirm;
        if (confirm === false || !confirm) {
            this.setButtonToConfirmStatus(button);
        } else {
            this.setButtonToRemovingStatus(button);

            this.ticketBlockUsersService.removeTicketBlockUser(user.id, this.ticketBlock.id)
                .subscribe(
                    (res) => {
                        this.message.success('Removed User From Ticket Block');
                        this.ticketBlockUsers.splice(i, 1);
                    },
                    (err) => {
                        this.setButtonToDefaultStatus(button, 'Remove User');
                        this.message.error(err.error.errors[0].detail);
                    }
                );
        }
    }

    private selectUser(user: User): void {
        this.ticketBlockUsersService.addUserRoleToTicketBlock(this.ticketBlock.id, user)
        .subscribe(
            (res) => {
                this.isAddingUser = false;
                this.ticketBlockUsers.push(user);
            },
            (err) => console.log(err)
        );
    }

    private updateAllotment(allotmentId, quantity) {
        this.ticketBlockUsersService.updateAllotment(allotmentId, quantity)
            .toPromise()
            .then((res) => {
                this.ticketBlock.extraAttributes.allotmentCounts.forEach((allotment) => {
                    if (allotment.id === allotmentId) {
                        allotment.quantity = quantity;
                        allotment.isEditing = false;
                        this.message.success(`Updated ${allotment.ticketTypeName} to ${allotment.quantity}`);
                    }
                });
            });
    }

    private async saveChanges() {
        const allotmentPromises: Promise<any>[] = [];
        if (this.editedAllotments) {
            this.editedAllotments.forEach((editedAllotment, index) => {
                if (editedAllotment.invitationCounts.totalQuantity !== this.initialAllotments[index].invitationCounts.totalQuantity && editedAllotment.invitationCounts.totalQuantity !== 0) {
                    allotmentPromises.push(this.ticketBlockUsersService.updateAllotment(editedAllotment.allotmentId, editedAllotment.invitationCounts.totalQuantity).toPromise());
                }
                if (editedAllotment.invitationCounts.totalQuantity === 0) {
                    allotmentPromises.push(this.ticketBlockUsersService.removeAllotment(editedAllotment.allotmentId).toPromise());
                }
            });
        }
        if (this.newAllotmentsFromStacks) {
            this.newAllotmentsFromStacks.forEach((newAllotmentFromStacks, index) => {
                if (newAllotmentFromStacks.availabilityCounts.totalQuantity !== 0) {
                    allotmentPromises.push(this.ticketBlockUsersService.addAllotment(this.ticketBlock.id, newAllotmentFromStacks.id, newAllotmentFromStacks.availabilityCounts.totalQuantity).toPromise());
                }
            });
        }
        if (!allotmentPromises.length) {
            this.message.warning('No allotment changes made');
            return;
        }
        await Promise.all(allotmentPromises)
            .then((res) => {
                this.message.success('Allotments changed successfully');
            }).catch((err) => {
                this.message.error(err.error.errors[0].detail);
            })
            .then((res) => {
                this.ticketBlockChange.emit({'action': 'Allotment change'});
                this.requesting = false;
            });

        this.isEditing = false;
        this.ticketBlockEditing.emit(false);

    }

    private stackIsInExistingAllotment(stackId: string): boolean {
        if (!this.ticketBlock || !this.ticketBlock.extraAttributes || !this.ticketBlock.extraAttributes.allotmentCounts) {
            return false;
        }
        let match = false;
        this.ticketBlock.extraAttributes.allotmentCounts.forEach((allotment) => {
            if (allotment.stackId === stackId) {
                match = true;
            }
        });
        return match;
    }

    private allotmentIsInDeletedStack(allotmentStackId: string) {
        if (!this.eventFarmService.currentEvent) {
            return true;
        }

        if (!_.get(this, 'ticketBlock.isSessionTB')) {
            const stackLen = _.get(this, 'eventFarmService.currentEvent.stacks.length', 0)

            if (!stackLen) {
                return false;
            }
            let match = false;
            this.eventFarmService.currentEvent.stacks.forEach((stack) => {
                if (allotmentStackId === stack.id && stack.isDeleted) {
                    match = true;
                }
            });
            return match;
        }

        return false;
    }

    private getStackFromAllotment(allotment) {
        let matches = [];

        if (this.context === 'fullPlatform' && _.get(this, 'ticketBlock.isSessionTB', false)) {
            this.currentSessionStacks.forEach((stack) => {
                if (allotment.stackId === stack.id) {
                    matches.push(stack);
                }
            });
        } else {
            this.eventFarmService.currentEvent.stacks.forEach((stack) => {
                if (allotment.stackId === stack.id) {
                    matches.push(stack);
                }
            });
        }

        if (matches.length) {
            return matches[0];
        }
    }

    private subtractUsedAllotmentsInOthersTicketBlocksFromStacks(stack: Stack): Stack {
        const length = _.get(this, 'eventFarmService.currentEvent.ticketBlocks.length', 0);
        if (length === 0) {
            return stack;
        }
        this.eventFarmService.currentEvent.ticketBlocks.forEach((otherTicketBlock) => {
            if (otherTicketBlock.id !== _.get(this, 'ticketBlock.id') && (!otherTicketBlock.isDeleted && otherTicketBlock.extraAttributes && otherTicketBlock.extraAttributes.allotmentCounts && otherTicketBlock.extraAttributes.allotmentCounts.length)) {
                otherTicketBlock.extraAttributes.allotmentCounts.forEach((otherAllotment) => {
                    if (stack.id === otherAllotment.stackId && otherAllotment.invitationCounts.totalQuantity > 0) {
                        stack.availabilityCounts.totalAvailable -= otherAllotment.invitationCounts.totalQuantity;
                    }
                    if (stack.availabilityCounts.totalAvailable < 0) {
                        stack.availabilityCounts.totalAvailable = 0;
                    }
                });
            }
        });
        return stack;
    }

    private assembleFilteredStacks(stacks: Stack[] = []): Stack[] {
        const filteredStacks = [];
        if (!stacks.length) {
            return stacks;
        }
        stacks.forEach((stack) => {
            if (!stack.isDeleted && !this.stackIsInExistingAllotment(stack.id) && !stack.stackMethodType.isPublicRegistration && !stack.stackMethodType.isPublicPurchase) {
                stack = this.subtractUsedAllotmentsInOthersTicketBlocksFromStacks(stack);
                filteredStacks.push(stack);
            }
        });
        return filteredStacks;
    }


    private getMinAvailabilityForStack(stack: Stack): number {
        return 0;
    }

    private getMaxAvailabilityForStack(stack: Stack, isEditing: boolean = false): number {
        const stackTotalAvailable = stack.availabilityCounts?.totalAvailable;

        if (stack.stackMethodType.isFirstComeFirstServe) {
            return stackTotalAvailable;
        }

        const currentAllotmentCountsByStack = this.allTicketBlocks.reduce(
            (accumulator, currentValue) => {
                currentValue.extraAttributes.allotmentCounts.forEach((allotment) => {
                    if (allotment.stackId === stack.id) {
                        if (isEditing) {
                            if (currentValue.id !== this.ticketBlock.id) {
                                accumulator += allotment.invitationCounts.totalRemaining;
                            } else {
                                accumulator -= allotment.invitationCounts.totalUsed;
                            }
                        } else {
                            accumulator += allotment.invitationCounts.totalRemaining;
                        }
                    }
                });

                return accumulator;
            },
            0
        );

        const available = stack.availabilityCounts?.totalAvailable - currentAllotmentCountsByStack;
        return Math.max(0, available);
    }

    private getEditTextForAllotment (available: number, currentAllotment: number): string {
        const difference = available - currentAllotment;
        return `You are able to allocate ${Math.max(difference, 0)} more tickets for your ticketblock.`
    }


    private generateNewAllotmentsFromStacks(existingAllotments: any): any[] {
        let newAllotmentsFromStacks = existingAllotments;
        newAllotmentsFromStacks.forEach(allotmentFromStacks => {
            allotmentFromStacks.availabilityCounts.totalQuantity = 0;
        });
        return newAllotmentsFromStacks;
    }

    private goToAddStack() {
        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentTeam.id;
        this.router.navigateByUrl(this.routeGenerator.url('events.event-access', { poolId, eventId }));
        setTimeout(() => {
            try {
                window['$']('#create-ticket-types-btn').click();
            }
            catch(err) {
            }
        }, 1000);
    }

    private setButtonToConfirmStatus(button) {
        button.innerText = 'Click To Confirm';
        button.dataset.confirm = true;
    }

    private setButtonToRemovingStatus(button) {
        button.innerText = 'Removing...';
        button.disabled = true;
        button.classList.add('shimmer', 'dark-shimmer');
    }

    private setButtonToDefaultStatus(button, text) {
        button.innerText = text;
        button.disabled = false;
        button.classList.remove('shimmer');
        button.classList.remove('dark-shimmer');
    }

    private cancelEdit() {
        this.isEditing = false;
        this.ticketBlockEditing.emit(false);
    }

    private startEdit() {
        this.isEditing = true;
        this.ticketBlockEditing.emit(true);
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
}
