import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Question } from '../../../../../../ApiClient/Models/Question/question';
import { QuestionLogicService } from './question-logic.service';
import { AnswerBindingTypeInterface, Question as EFQuestion } from '@eventfarm/javascript-sdk/dist/Api/Type/Question';
import { Answer } from '../../../../../../ApiClient/Models/Answer/answer';
import { AnswerBinding, AnswerBindingFormControlInterface } from '../../../../../../ApiClient/Models/Question/answer-binding';



@Component({
  selector: 'question-logic',
  template: require('./question-logic.html'),
  styles: [require('./question-logic.scss')]
})
export class QuestionLogicComponent implements OnInit {

  @Input() question: Question;
  
  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private questionLogicService: QuestionLogicService
  ) { }

  ngOnInit() {
    this.setInitialFields();
  }

  private isSaving: boolean = false;
  private answerBindings: AnswerBinding[] = [];
  private answerBindingTypes: AnswerBindingTypeInterface[] = new EFQuestion().AnswerBindingType();
  private answerBindingQuestions: Question[] = [];
  private questionControls: AnswerBindingFormControlInterface[] = [];
  private typeControls: AnswerBindingFormControlInterface[] = [];
  private answerControls: AnswerBindingFormControlInterface[] = [];


  get showDivider(): boolean {
    return this.answerBindings.length > 0;
  }

  private async add() {

    const bindings = [AnswerBinding.forNewCreation(this.question)]
    this.buildFormControls(bindings);
    this.answerBindings.push(...bindings);
  }

  private delete(answerBinding: AnswerBinding) {
    this.answerBindings = this.answerBindings.filter((i) => i !== answerBinding);
    this.questionControls = this.questionControls.filter((i) => i.bindingId !== answerBinding.id);
    this.typeControls = this.typeControls.filter((i) => i.bindingId !== answerBinding.id);
    this.answerControls = this.answerControls.filter((i) => i.bindingId !== answerBinding.id);
  }

  private async saveQuestionLogic() {
    if (this.isSaving === false) {
      this.isSaving = true;
      this.finalizeAnswerBindingsForSave();
      const questionLogicSaveSuccess = await this.questionLogicService.saveLogic(this.answerBindings);
      
      if (questionLogicSaveSuccess) {
        this.isSaving = false;
        this.drawerRef.close();
        await this.questionLogicService.loadQuestionsForCenter();
      } else {
        this.isSaving = false;
      }
    }
  }

  private finalizeAnswerBindingsForSave() {
    this.answerBindings.forEach(ab => {
      const answerControl = this.getFormControlFromBinding(ab, 'answer');
      const typeControl = this.getFormControlFromBinding(ab, 'answerBindingType');
      ab.answer = answerControl.value;
      ab.answerBindingType = typeControl.value;
    });
  }
  
  private allowSave() {
    let canSave = true;

    this.answerBindings.forEach(ab => {
      const questionControl = this.getFormControlFromBinding(ab, 'question');
      const answerControl = this.getFormControlFromBinding(ab, 'answer');
      if (!questionControl.value || !answerControl.value) {
        canSave = false;
      }
    });
  
    return canSave;
  }

  /**
   * Setup initial values and subscriptions
   */
  private setInitialFields() {
    this.setUpQuestions();
  }

  private setUpQuestions() {
    this.answerBindingQuestions = this.questionLogicService.allVisibleQuestions;
    this.questionLogicService.initServiceWithCurrentBindinds(this.question.answerBindings);
    const bindings = _.cloneDeep(this.question.answerBindings);
    this.buildFormControls(bindings);
    this.answerBindings = bindings;

    let compatibleQuestions = this.questionLogicService.allIndividualVisibleQuestions;

    if (!this.question.isIndividual) {
      compatibleQuestions = this.questionLogicService.allTransactionVisibleQuestions;
    }
    
    this.answerBindingQuestions = compatibleQuestions.filter(q => {
        if ((q.id !== this.question.id) && (q.sortOrder < this.question.sortOrder) && (q.isAnswerBindingCompatible)) {
          return q;
        }
      });
  }

  private buildFormControls(bindings: AnswerBinding[]) {
    bindings.forEach((binding: AnswerBinding) => {
      const questionFormControl = new FormControl();
      questionFormControl.setValue(binding.answer ? binding.answer.question : null);
      questionFormControl.valueChanges.subscribe(q => {
        this.setQuestion(q);
      });
      const typeFormControl = new FormControl();
      typeFormControl.setValue(binding.answerBindingType);

      const answerFormControl = new FormControl();
      answerFormControl.setValue(binding.answer ? binding.answer : null);
      this.questionControls.push({bindingId: binding.id, formControl: questionFormControl});
      this.typeControls.push({bindingId: binding.id, formControl: typeFormControl});
      this.answerControls.push({bindingId: binding.id, formControl: answerFormControl});
    })
  }

  private getFormControlFromBinding(binding: AnswerBinding, type: string): FormControl {
    let controls: AnswerBindingFormControlInterface[] = [];

    if (type === 'question') {
      controls = this.questionControls;
    } else if (type === 'answerBindingType') {
      controls = this.typeControls;
    } else if (type === 'answer') {
      controls = this.answerControls; 
    }

    for (const ab of controls) {
      if (ab.bindingId === binding.id) {
        return ab.formControl
      }
    }  
  }

  private compareFnAnswer = (o1: Answer, o2: Answer) => (o1 && o2 ? o1.id === o2.id : o1 === o2);
  private compareFnQuestion = (o1: Question, o2: Question) => (o1 && o2 ? o1.id === o2.id : o1 === o2);
  private compareFnType = (o1: AnswerBindingTypeInterface, o2: AnswerBindingTypeInterface) => (o1 && o2 ? o1.slug === o2.slug : o1 === o2);

  private setQuestion(q: Question) {
    const { bindingId } = this.questionControls.find(f => f.formControl.value === q);
    this.answerControls.find(f => f.bindingId === bindingId).formControl.reset();
  }

  private getAnswersForSelectedQuestion(binding: AnswerBinding): Answer[] {
    let qId = '';
    const {value} = this.getFormControlFromBinding(binding, 'question');
    if (binding.answer && binding.answer.question && value.id === binding.answer.question.id) {
      qId = binding.answer.question.id;
    } else {
      if (value && value.id) {
        qId = value.id;
      }
    }

    const q1 = this.answerBindingQuestions.find(q => q.id === qId);
    if (q1) {
      return q1.answers;
    } else {
      return [];
    }
  }


  close(): void {
    this.drawerRef.close(this.question);
  }
}
