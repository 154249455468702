import { AbstractModel } from '../abstract-model';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import { EfEvent } from '../Event/event';

export class EFWebConference extends AbstractModel {
    
    public _id: string;
    public _joinUrl: string;
    public _title: string;
    public _startTime: string;
    public _timeZone: string;

    constructor(id) {
        super(id);
    }
    
    public static fromApiResponse(data): EFWebConference {
        const WebConference = new this(data.uuid);

        WebConference._id = String(data.id);
        WebConference._joinUrl = data.join_url;
        WebConference._title = data.topic;
        WebConference._startTime = data.start_time;
        WebConference._timeZone = data.timezone;

        return WebConference;
    }

    
    get id(): string {
        return this._id;
    }

    get joinUrl(): string {
        return this._joinUrl;
    }

    get title(): string {
        return this._title;
    }

    get startTime(): string {
        return moment(this._startTime).tz(this._timeZone).format('lll z');
    }

    get fullName(): string {
        return this._title + ' (' + this.startTime + ')';
    }

    public isFromCurrentEvent(event: EfEvent): boolean {
        const eventTimeZone = event.startTime.timezone;
        const eventStartTimestamp = event.startTime.timestamp;
        const eventName = event.name;
        const conferenceTimestamp = moment(this._startTime).unix();

        return this._title === eventName && this._timeZone === eventTimeZone && eventStartTimestamp === conferenceTimestamp;
    }
}

