import { AbstractModel } from '../abstract-model';
import * as _ from 'lodash';
import {SitePage} from "../SitePage/site-page";
export class TicketType extends AbstractModel {

    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const ticketType = new this(data.id);

        if (data.meta.isInitialized) {
            ticketType._name = data.attributes.name;
            ticketType._code = data.attributes.code;
            ticketType._isDeleted = data.attributes.isDeleted;
            ticketType._description = data.attributes.description;
            ticketType._checkInMessage = data.attributes.checkInMessage;
            ticketType._quantity = data.attributes.quantity;
            ticketType._displayOrder = data.attributes.sortOrder;
            ticketType._shouldSendArrivalAlert = data.attributes.shouldSendArrivalAlert;
            ticketType._arrivalAlertEmails = data.attributes.arrivalAlertEmails;
            ticketType._arrivalAlertPhoneNumbers = data.attributes.arrivalAlertMobileNumbers;
            ticketType._defaultGuestPassCount = data.attributes.defaultGuestPassCount;

            if (_.get(data, 'relationships.defaultSitePage')) {
                ticketType._defaultSitePage = SitePage.fromApiResponse(data.relationships.defaultSitePage);
            }
        }

        return ticketType;
    }

    private _name: string;
    private _code: string;
    private _description: string;
    private _checkInMessage: string|null;
    private _isDeleted: boolean;
    private _quantity: number;
    private _displayOrder: number;
    private _stacks: [];
    private _shouldSendArrivalAlert: boolean;
    private _arrivalAlertPhoneNumbers?: string[];
    private _arrivalAlertEmails?: string[];
    private _defaultSitePage?: SitePage;
    private _defaultGuestPassCount: number|null;

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get quantity(): number {
        return this._quantity;
    }

    get stacks(): [] {
        return this._stacks;
    }

    set stacks(value: []) {
        this._stacks = value;
    }

    get displayOrder(): number {
        return this._displayOrder;
    }

    set displayOrder(value: number) {
        this._displayOrder = value;
    }

    get checkInMessage(): string {
        return this._checkInMessage;
    }

    set checkInMessage(value: string) {
        this._checkInMessage = value;
    }

    get shouldSendArrivalAlert() {
        return this._shouldSendArrivalAlert;
    }

    set shouldSendArrivalAlert(value: boolean) {
        this._shouldSendArrivalAlert = value;
    }

    get arrivalAlertEmails(): string[] {
        if (!this._arrivalAlertEmails) {
            return [];
        }
        return this._arrivalAlertEmails;
    }

    set arrivalAlertEmails(value: string[]) {
        this._arrivalAlertEmails = value;
    }

    get arrivalAlertPhoneNumbers(): string[] {
        if (!this._arrivalAlertPhoneNumbers) {
            return [];
        }

        return this._arrivalAlertPhoneNumbers;
    }

    set arrivalAlertPhoneNumbers(value: string[]) {
        this._arrivalAlertPhoneNumbers = value;
    }

    get defaultSitePage(): SitePage {
        return this._defaultSitePage;
    }

    set defaultSitePage(value: SitePage) {
        this._defaultSitePage = value;
    }

    get defaultGuestPassCount(): number|null {
        return this._defaultGuestPassCount;
    }

    set defaultGuestPassCount(value: number|null) {
        this._defaultGuestPassCount = value;
    }
}
