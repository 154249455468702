import { Stack } from './../../../../../../ApiClient/Models/Stack/stack';
import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditStackComponent } from './edit-stack.component';

@Component({
  selector: 'edit-stack-button',
  template: `
    <button
      nz-button
      nzType="primary"
      class="main-action ef-btn-sm"
      (click)="openComponent()"
    >{{ buttonText }}</button>
  `
})

export class EditStackButtonComponent implements OnInit {
  @Input() stacks: Stack[];
  @Input() buttonText;
  drawerTitle;
  width = '50%';

  public componentClicked: boolean = false;
  
  constructor(
    private drawerService: NzDrawerService
  ) {}

  ngOnInit() {
    if (!this.stacks) {
      this.drawerTitle = 'Create New Access Type';
    } else {
      this.drawerTitle = 'Edit Access Type';
    }
  }

  openComponent(): void {
    if (!this.componentClicked) {
      this.componentClicked = true
      const drawerRef = this.drawerService.create<EditStackComponent, { stacks: Stack[] }, string>({
        nzTitle: this.drawerTitle,
        nzContent: EditStackComponent,
        nzWidth: this.width,
        nzContentParams: {
          stacks: this.stacks,
        }
      });

      drawerRef.afterOpen.subscribe(() => {

      });

      drawerRef.afterClose.subscribe(data => {
        this.componentClicked = false;
      });
    }
  }
}