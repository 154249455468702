
import { AbstractBaseAlert } from '../base-alert';

export class SalesforceIntegrationAlert extends AbstractBaseAlert {
    salesforceImportSuccess(): Promise<any> {
        return this.alert({
            title: 'Import from Salesforce started',
            text: 'Depending on the number of records transferred, this may take a few minutes. The Guest List will refresh automatically and you are free to navigate elsewhere in the meantime.',
            icon: 'success',
        });
    }

    beginSalesforceImport(integration: string): Promise<any> {
        return this.alert({
            title: 'Begin sync with ' + integration + '?',
            text: 'Are you sure you want import from ' + integration + '? If new records are found for import, they will be added to your Guest List using your current integration settings.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Import',
        });
    }

    salesforceImportError(): Promise<any> {
        return this.alert({
            title: 'Import from Salesforce failed',
            text: 'Please check your event or team integration settings to make sure hte correct Salesforce campaign has been selected.',
            icon: 'error',
        });
    }

    beginSalesforceExport(): Promise<any> {
        return this.alert({
            title: 'Begin export to Salesforce?',
            text: 'Are you sure you want export to Salesforce? If records from Event Farm are exported, they will be added using your current integration settings.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Export',
        });
    }

    confirmIntegrationSync(integration: string): Promise<any> {
        return this.alert({
            title: `Begin sync with ` + integration + `?`,
            text: `Are you sure you want import from ` + integration + `? If new records are found for import, they will be added to your Guest List using your current integration settings.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Import'
        });
    }

    confirmCampaignChange(): Promise<any> {
        return this.alert({
            title: 'Notice: Campaign Updates Will Change Data',
            text: `Changes to a Salesforce campaign will be reflected in import and export data downloads.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK'
        });
    }

    noCampaignSelected(): Promise<any> {
        return this.alert({
            title: 'No Campaign Selected',
            text: 'Please Select a campaign.',
            icon: 'error'
        });
    }

    integrationImportStarted(integration: string): Promise<any> {
        return this.alert({
            title: `Import from ` + integration + ` started`,
            text: `Depending on the number of records transferred, this may take a few minutes. The Guest List will refresh automatically and you are free to navigate elsewhere in the meantime.`,
            icon: 'success'
        });
    }

    integrationImportFailed(integration: string): Promise<any> {
        return this.alert({
            title: `Import from ` + integration + ` failed`,
            text: `We were unable to initiate the import from ` + integration + `. Please check your event or team's integration settings and verify that your Salesforce campaign is accessible.`,
            icon: 'error',
        });
    }

    integrationExportStarted(integration: string): Promise<any> {
        return this.alert({
            title: `Export from ` + integration + ` started`,
            text: `Depending on the number of records transferred, this may take a few minutes. The Guest List will refresh automatically and you are free to navigate elsewhere in the meantime.`,
            icon: 'success'
        });
    }

    integrationExportFailed(integration: string): Promise<any> {
        return this.alert({
            title: `Export to ` + integration + ` failed`,
            text: `We were unable to initiate the export from  ` + integration + `. Please check your event or team's integration settings and verify that your ` + integration + ` campaign is accessible.`,
            icon: 'error',
        });
    }

    salesforceExportSingleSuccess(): Promise<any> {
        return this.alert({
            title: 'Exporting Invitation to Salesforce',
            text: 'This may take a few minutes.',
            icon: 'success',
        });
    }

    salesforceExportSuccess(): Promise<any> {
        return this.alert({
            title: 'Export to Salesforce started',
            text: 'Depending on the number of records transferred, this may take a few minutes. The Guest List will refresh automatically and you are free to navigate elsewhere in the meantime.',
            icon: 'success',
        });
    }

    salesforceExportError(): Promise<any> {
        return this.alert({
            title: 'Export to Salesforce failed',
            text: 'We were unable to initiate the export from Salesforce. Please check your event or team\'s integration settings and verify that your Salesforce campaign is accessible.',
            icon: 'error',
        });
    }

    salesforceConfigureSyncSettingsComplete(poolId: string, eventId: string): Promise<any> {
        return this.alert({
            title: 'Configuring Sync Settings for an Event',
            html: 'Now that your Salesforce connection has been established and your Campaign has been selected, please configure integration settings at the event level. ' +
                '<br><br> If event-level settings have already been configured, please proceed to the <a target="_self" href="' + this.routeGenerator.url('events.guest-list', { poolId, eventId }) + '">Guest List</a>.' +
                '<br><br>Note: All settings configurations will auto-save.',
            icon: 'success',
            confirmButtonText: 'OK',
        });
    }

    salesforceConnectAccountSuccess(): Promise<any> {
        return this.alert({
            title: 'Connecting to Integrations Complete',
            html: 'Now that you\'ve successfully connected Event Farm to Salesforce, head into your event dashboard to configure event integration settings.'
            + '<br><a target="_self" href="/dashboard" class="display-block margin-top-md margin-bottom-md"><button class="btn btn-sm btn-wide btn-primary" >Go To Events <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></button></a> '
            + 'Or if you would like to set up your team integration settings,',
            buttonsStyling: false,
            icon: 'success',
            customClass: 'reduced-spacer',
            confirmButtonClass: 'btn btn-sm btn-wide btn-primary btn-hollow',
            confirmButtonText: 'Go To Team Integration Settings',
        });
    }

    salesforceConnectAccountFailed() {
        return this.alert({
            title: 'Unable to connect',
            html: 'Authorization with Salesforce was not successful. Please try again.',
            icon: 'error',
        });
    }

    enableImport(): Promise<any> {
        return this.alert({
            title: 'Salesforce Scheduled-Import Enabled',
            html: 'Salesforce scheduled-import has been <i>enabled</i> for this event. If you would like to enable ' +
            'scheduled-export for this event as well, make sure you do so on your Export Settings Tab.',
            icon: 'success',
        });
    }

    disableImport(): Promise<any> {
        return this.alert({
            title: 'Salesforce Scheduled-Import Disabled',
            html: 'Salesforce scheduled-import has been <i>disabled</i> for this event If you would like to disable ' +
            'scheduled-export for this event as well, make sure you do so on your Export Settings Tab.',
            icon: 'success',
        });
    }

    enableExport(): Promise<any> {
        return this.alert({
            title: 'Salesforce Auto-Export Enabled',
            html: 'Salesforce auto-export has been <i>enabled</i> for this event. If you would like to enable ' +
            'auto-import for this event as well, make sure you do so on your Import Settings Tab.',
            icon: 'success',
        });
    }

    disableExport(): Promise<any> {
        return this.alert({
            title: 'Salesforce Auto-Export Disabled',
            html: 'Salesforce auto-import has been <i>disabled</i> for this event If you would like to disable ' +
            'auto-import for this event as well, make sure you do so on your Import Settings Tab.',
            icon: 'success',
        });
    }

    invalidInvitationCount(message: string): Promise<any> {
        return this.alert({
            title: 'Invalid Invitation Count',
            html: '<strong>' + message + '</strong>' + '<br> You can configure these values in the ' +
            '<strong>Event Settings</strong> page under <strong>Required Order Size</strong>.',
            icon: 'error',
        });
    }

    teamSettingsUpdateSuccess(leadSource: string, leadCompany: string): Promise<any> {
        return this.alert({
            title: 'Settings Update Successful',
            html: 'Your lead source has been set to <em>"' + leadSource + '"</em> and your company has been set to <em>"' + leadCompany + '"</em>',
            icon: 'success',
        });
    }

    teamSettingsUpdateFailed(): Promise<any> {
        return this.alert({
            title: 'Settings Update Failed',
            text: 'Settings could not be saved. Please try reconnecting your account or contact Client Services',
            icon: 'error',
        });
    }
}
