import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { User } from '../User/user';
import { EFxModuleTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { EFxStationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { EmailDesign } from '../../../Shared/EmailDesign/email-design';
import { EFxStationScreen } from './efxStationScreen';

export class EfxStation extends AbstractModel {
    private _stationName?: string;
    private _moduleType?: string;
    private _stationType?: string;
    private _isDeleted?: boolean;
    private _deleted?: number;
    private _created?: number;
    private _lastModified?: number;
    private _createdBy?: string;
    private _event: EfEvent;
    private _smsCDText?: string;
    private _smsCDMediaUrl?: string;
    private _emailCDEmailDesign?: EmailDesign;
    private _ticketTypeIds?: string[];
    private _stationScreens?: EFxStationScreen[];

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const efxStation = new this(data.id);

        efxStation._stationName = data.attributes.stationName;
        efxStation._moduleType = data.attributes.moduleType;
        efxStation._stationType = data.attributes.stationType;
        efxStation._isDeleted = data.attributes.isDeleted;
        efxStation._deleted = data.attributes.deletedAt;
        efxStation._created = data.attributes.created.createdAt;
        efxStation._lastModified = data.attributes.modified.modifiedAt;
        efxStation._smsCDText = data.attributes.smsCDText;
        efxStation._smsCDMediaUrl = data.attributes.smsCDMedialUrl;

        if (data.attributes.emailCDEmailDesign) {
            efxStation._emailCDEmailDesign = EmailDesign.fromContentDelivery(data.attributes.emailCDEmailDesign);
        } else {
            efxStation._emailCDEmailDesign = null;
        }


        if (data.relationships && data.relationships.event) {
            efxStation._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        if (data.relationships && data.relationships.stationTicketTypes) {
            efxStation._ticketTypeIds = data.relationships.stationTicketTypes.map(stt => stt.attributes.ticketType.id);
        } else {
            efxStation._ticketTypeIds = [];
        }

        if (data.relationships && data.relationships.stationScreens) {
            const stationScreens: EFxStationScreen[] = data.relationships.stationScreens.map(screen => EFxStationScreen.fromApiResponse(screen));
            stationScreens.sort((a,b) => a.efxScreen.sortOrder - b.efxScreen.sortOrder);
            efxStation._stationScreens = stationScreens.sort((a,b) => a.efxScreen.sortOrder - b.efxScreen.sortOrder);
        } else {
            efxStation._stationScreens = [];
        }

        return efxStation;
    }

    public static forCreate() {
        const efxStation = new this();

        efxStation._stationName = '';
        efxStation._ticketTypeIds = [];
        efxStation._stationScreens = EFxStationScreen.default();

        return efxStation;
    }

    get stationName(): string {
        return this._stationName;
    }

    set stationName(value: string) {
        this._stationName = value;
    }

    get moduleType(): string {
        return this._moduleType;
    }

    set moduleType(value: string) {
        this._moduleType = value;
    }

    get stationType(): string {
        return this._stationType;
    }

    set stationType(value: string) {
        this._stationType = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get deleted(): number {
        return this._deleted;
    }

    set deleted(value: number) {
        this._deleted = value;
    }

    get created(): number {
        return this._created;
    }

    set created(value: number) {
        this._created = value;
    }

    get lastModified(): number {
        return this._lastModified;
    }

    set lastModified(value: number) {
        this._lastModified = value;
    }

    get createdBy(): string {
        return this._createdBy;
    }

    set createdBy(value: string) {
        this._createdBy = value;
    }

    get event(): EfEvent {
        return this._event;
    }

    set event(value: EfEvent) {
        this._event = value;
    }

    get emailCDEmailDesign(): EmailDesign|null {
        return this._emailCDEmailDesign;
    }

    set emailCDEmailDesign(value: EmailDesign|null) {
        this._emailCDEmailDesign = value;
    }
    
    get smsCDText(): string {
        return this._smsCDText;
    }

    set smsCDText(value: string) {
        this._smsCDText = value;
    }

    get smsCDMediaUrl(): string {
        return this._smsCDMediaUrl;
    }

    set smsCDMediaUrl(value: string) {
        this._smsCDMediaUrl = value;
    }

    get ticketTypeIds(): string[] {
        return this._ticketTypeIds;
    }

    set ticketTypeIds(value: string[]) {
        this._ticketTypeIds = value;
    }

    get stationScreens(): EFxStationScreen[] {
        return this._stationScreens;
    }
}
