import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';
import { GuestListService } from '../guest-list.service';
import { EventFarmService } from '../../../eventFarm.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { SalesforceIntegration } from '../../../../ApiClient/Models/Integration/salesforce-integration';
import { filter, shareReplay } from 'rxjs/operators';
@Component({
    selector: 'invite-add-guest',
    template: require('./inviteAddGuest.html'),
    styles: [require('./inviteAddGuest.scss')],
})

export class InviteAddGuestComponent implements OnInit, OnDestroy {
    readonly marketoPopoverMessage = 'Marketo integrations are coming soon!';
    readonly cioPopoverMessage = 'Please upload a list before adding an individual';
    readonly inviteGroupSVG = require('./assets/InviteGroup-Icon');
    readonly uploadGroupSVG = require('./assets/UploadGroup-Icon');
    readonly inviteIndividualSVG = require('./assets/InviteIndividual-Icon');

    private isSalesforceStarting = false;
    private salesforcePopoverMessage = 'If you do not have Salesforce enabled for your account, ' +
        'please contact your Customer Success Manager to activate this feature. Otherwise, ' +
        'make sure your Salesforce is synced in the Event Integrations.';

    private ticketTypeCount: Observable<number>;
    private eventStore$: Observable<any> = this.store.select('event');
    private eventIntegration: SalesforceIntegration;
    private eventUpdate$: Subscription;

    constructor(
        private alertService: AlertService,
        private guestListService: GuestListService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private store: Store<fromRoot.AppState>,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private segmentService: SegmentService,
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe(res => this.eventIntegration = res.salesforceIntegration);
    }

    ngOnInit() {
        this.setSalesforceMessage();
        this.segmentService.segmentGuestListTracking().invite();
        this.segmentService.segmentSideNavTracking().addGuestsEntered();


        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentTeam.id;
        this.eventFarmService.fetchEventDetails(eventId, ['TicketTypes'])
            .subscribe(res => {
                this.ticketTypeCount = of(res.data.relationships.ticketTypes.length);
                this.ticketTypeCount.subscribe(res => {
                    if (res < 1 && this.eventFarmService.currentEvent.isTypeFullPlatform) {
                        this.alertService.guestList().noTicketTypesSet('building your guest list')
                        .then((res) => {
                            if (res.value) {
                                this.router.navigateByUrl(this.routeGenerator.url('events.event-access', { poolId, eventId }));
                            } else {
                                this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId, eventId }));
                            }
                        });
                    }
                });
            });

    }

    importFromSalesforce() {
        if ( !this.eventIntegration || !this.eventIntegration.salesforceStatus.shouldShowSalesforce) {
            return false;
        }

        this.isSalesforceStarting = true;
        this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ImportCampaignMembersForEvent(this.eventFarmService.currentEvent.id)
            .subscribe(
                (res) => {
                    this.alertService.salesforceIntegration().salesforceImportSuccess();
                    this.isSalesforceStarting = false;
                },
                (err) => {
                    this.alertService.salesforceIntegration().salesforceImportError();
                    this.isSalesforceStarting = false;
                },
            );

    }

    private allowSalesforceImport(): boolean {
        return (this.eventFarmService.currentEvent.isTypeFullPlatform
                && this.eventIntegration
                && this.eventIntegration.salesforceStatus.shouldShowSalesforce);
    }

    private setSalesforceMessage(): void {
        if (this.eventFarmService.currentEvent.isTypeCio) {
            this.salesforcePopoverMessage = 'Salesforce imports are available to full platform Event Farm users. ' +
                '<a href="//eventfarm.com/request-a-demo">Request a personalized demo to get started.</a>';
        } else if (this.eventIntegration
            && this.eventIntegration.salesforceStatus.isCampaignSelected) {
            this.salesforcePopoverMessage = 'Please select a Salesforce Campaign via \'Integration Settings\' ' +
                'before you can start an import.';
        }
    }

    private cioEventHasStacks(): boolean {
        if (this.eventFarmService.currentEvent.stacks && this.eventFarmService.currentEvent.stacks.length) {
            return true;
        } else {
            return false;
        }
    }

    private goToUploader() {
        let eventId =  this.eventFarmService.currentEvent.id;
        let poolId = this.eventFarmService.currentEvent.team.id;
        this.router.navigateByUrl(this.routeGenerator.url('pools.uploader', { eventId, poolId }));
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
 }
