import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EditableNamesComponent } from './editable-names.component';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../../CoreUI/Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';

const forExport = [
    EditableNamesComponent,
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,

    ],
})

export class EditableNamesModule {
}