import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressBookService } from '../../addressBook.service';
import { AddressBookUserService } from '../addressBookUser.service';
import { Group } from '../../../../../ApiClient/Models/Group/group';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'address-book-edit-groups-for-user-tagger',
    styles: [require('./address-book-edit-groups-for-user-tagger.scss')],
    template: require('./address-book-edit-groups-for-user-tagger.html'),
})

export class AddressBookEditGroupsForUserTaggerComponent implements OnInit, OnDestroy {
    constructor(
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
    ) {
        this.groupMembershipForUser$ = this.addressBookUserService.groupMembershipForUser$.subscribe((res: Group[]) => {
            this.userGroups = res;
            this.requesting = false;
        });
    }

    readonly deleteGroupSVG = require('../../assets/delete-group');

    groupMembershipForUser$: Subscription;
    requesting: boolean = false;
    userGroups: Group[];
    private selectedGroup: Group;
    private removedGroup: Group;

    ngOnInit() {
        this.addressBookUserService.userGroupsListOptions.pagination.currentUsersPage = 1;
        this.addressBookUserService.broadcastGroupMembershipForUser();
        this.userGroups = [];
        this.removedGroup = null;
        this.selectedGroup = null;
        this.requesting = false;
    }

    private selectGroup(group: Group): void {
        if (group.name !== 'Added By Me') {
            this.selectedGroup = group;
        }
    }

    addUserToGroup() {
        this.requesting = true;
        let singleUserArray = [];
        singleUserArray.push(this.addressBookService.selectedUser.id);
        this.segmentService.segmentAddressBookTracking().editUserModalAddUserToGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            this.selectedGroup
        );
        this.addressBookUserService.addUsersToGroup(this.selectedGroup.id, singleUserArray).toPromise()
        .then((res) => {
            this.addressBookUserService.broadcastGroupMembershipForUser();
            this.removedGroup = null;
            this.selectedGroup = null;
            this.message.success('Contact added to group');
        });
    }

    removeGroup(groupToRemove: Group) {
        this.requesting = true;
        let singleUserArray = [];
        singleUserArray.push(this.addressBookService.selectedUser.id);
        this.segmentService.segmentAddressBookTracking().editUserModalRemoveUserFromGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            this.selectedGroup
        );
        this.addressBookUserService.removeUsersFromGroup(groupToRemove.id, singleUserArray).toPromise()
        .then((res) => {
            this.addressBookUserService.broadcastGroupMembershipForUser();
            this.removedGroup = groupToRemove;
            this.selectedGroup = null;
            this.message.success('Contact removed from group');
        });
    };

    clearGroupFromSearch() {
        this.selectedGroup = null;
    }

    changePage(page) {
        this.requesting = true;
        this.addressBookUserService.userGroupsListOptions.pagination.currentUsersPage = page;
        this.addressBookUserService.broadcastGroupMembershipForUser();
    }

    ngOnDestroy() {
        this.groupMembershipForUser$.unsubscribe();
    }
}
