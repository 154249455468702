import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EventFarmService } from '../eventFarm.service';
import { EventFarmAPIClient } from '../../ApiClient/event-farm-api-client';
import { RouteGeneratorService } from '../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromStore from './../store';
import { EfEvent } from '../../ApiClient/Models/Event/event';
import { filter, switchMap, take, shareReplay } from 'rxjs/operators';
@Injectable()

export class EventResolver implements Resolve<Observable<boolean>> {
    constructor(
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private client: EventFarmAPIClient,
        private store: Store<fromStore.AppState>,
        private eventFarmService: EventFarmService,
    ) {
    }

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Observable<boolean> {
        if (!this.eventFarmService.currentEvent) {
            this.store.dispatch(new fromStore.FetchCurrentEvent());
        }
        return this.eventFarmService.eventStore$.pipe(
            filter(res => !!res.data),
            switchMap(() => of(true)),
            take(1)
        );
    }
}