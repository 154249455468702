import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {EventFarmService} from '../../../eventFarm.service';
import * as fromRoot from '../../../store';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListFiltersService, QuickFilterSelectOption} from './guest-list-filters.service';
import {GuestListFiltersAddFilterComponent} from './GuestListFiltersAddFilter/guest-list-filters-add-filter.component';

@Component({
    selector: 'guest-list-filters',
    template: require('./guest-list-filters.html'),
    styles: [require('./guest-list-filters.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestListFiltersComponent implements OnInit {
    private drawerRef;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private guestListService: GuestListViewService,
        private fb: FormBuilder,
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService,
        private drawerService: NzDrawerService,

    ) {
    }

    ngOnInit() {
    }

    private openApplyFiltersSlideout() {
            this.drawerRef = this.drawerService.create<GuestListFiltersAddFilterComponent, {  }, string>({
                nzTitle: 'Add filters',
                nzContent: GuestListFiltersAddFilterComponent,
                nzWidth: '25%',
                nzClosable: true
            });
        }


    private quickFilterOptions: QuickFilterSelectOption[] = [
        {label: "Who's Coming", value: 'who-is-coming'},
        {label: "Who's Not Coming", value: 'who-is-not-coming'},
        {label: "Who Hasn't Decided", value: 'who-has-not-decided'},
        {label: 'All Invited', value: 'all-invited'},
        {label: 'Checked In', value: 'checked-in'}
    ];
}
