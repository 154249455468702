import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../../../CoreUI/Modal/modal.service';

@Component({
    styles: [
        require('./button-editor.scss')
    ],
    template: require('./button-editor.html'),
})

export class ButtonEditorComponent {
    private button: FormGroup;
    private selectedElement;
    private textColor;
    private previewBackgroundColor;

    constructor(
        private modalService: ModalService,
        @Inject(FormBuilder) fb: FormBuilder
    ) {

        this.selectedElement = this.modalService.data.element.closest('.button.custom-button') || this.modalService.data.element;
        this.previewBackgroundColor = this.findBackgroundColor(this.selectedElement);

        let href = this.attr('href', this.selectedElement);
        let borderRadius = this.selectedElement.style['borderRadius'].split('px')[0];
        let borderWidth = this.selectedElement.style['border'].split('px')[0];
        let borderStyle = this.selectedElement.style['borderStyle'];
        let borderColor = this.selectedElement.style['borderColor'];
        let backgroundColor = this.selectedElement.style['backgroundColor'];
        let paddingHeight = this.selectedElement.style['paddingTop'].split('px')[0];
        let paddingWidth = this.selectedElement.style['paddingLeft'].split('px')[0];
        this.textColor = this.selectedElement.style['color'];

        this.button = fb.group({
            buttonHTML: this.selectedElement.innerHTML === '' ? 'Click to Edit Text' : this.selectedElement.innerHTML,
            buttonLink: !href ? '[INVITE_LINK]' : href,
            borderRadius: borderRadius === '' ? '5' : borderRadius,
            borderWidth: borderWidth === '' ? '0' : borderWidth,
            borderColor: borderColor === '' ? 'white' : borderColor,
            borderStyle: borderStyle === '' ? 'solid' : borderStyle,
            paddingHeight: paddingHeight === '' ? '16' : paddingHeight,
            paddingWidth: paddingWidth === '' ? '24' : paddingWidth,
            textColor: this.textColor === '' ? '#fafafa' : this.textColor,
            backgroundColor: backgroundColor === '' ? '#6875ff' : backgroundColor,
        });
    }

    textColorChange(color) {
        this.button.patchValue({ textColor: color });
    }

    bgColorChange(color) {
        this.button.patchValue({ backgroundColor: color });
    }

    borderColorChange(color) {
        this.button.patchValue({ borderColor: color });
    }

    onInsert(buttonHTML) {
        this.selectedElement.outerHTML = `<a href="${this.button.controls.buttonLink.value}" style="background-color: ${this.button.controls.backgroundColor.value}; color: ${this.button.controls.textColor.value}; border-radius: ${this.button.controls.borderRadius.value}px; padding: ${this.button.controls.paddingHeight.value}px ${this.button.controls.paddingWidth.value}px; border: ${this.button.controls.borderWidth.value}px ${this.button.controls.borderStyle.value} ${this.button.controls.borderColor.value}; display: inline-block;" class="button custom-button">${buttonHTML}</a>`;
        this.modalService.data.froala.undo.saveStep();
        this.modalService.closeModal();
    }

    findBackgroundColor(self) {
        let el = self;
        while (el.offsetParent) {
            el = el.offsetParent;
            if (el.style.backgroundColor && el.style.backgroundColor !== 'transparent')
                return el.style.backgroundColor;
        }
        return null;
    }

    attr(name: string, element): string {
        for (const attr of element.attributes) {
            if (attr.name === name) {
                return attr.value;
            }
        }

        return '';
    }
}
