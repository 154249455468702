import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {SelectOption} from "../../../../ApiClient/ClientLibrary/Interfaces";
import * as fromRoot from '../../../store';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListFiltersService} from '../GuestListFilters/guest-list-filters.service';

@Component({
    selector: 'guest-list-sort',
    template: require('./guest-list-sort.html'),
    styles: [require('./guest-list-sort.scss')]
})
export class GuestListSortComponent implements OnInit {
    private sortForm;
    private sortOptions: SelectOption[];

    constructor(
        private store: Store<fromRoot.AppState>, private drawerRef: NzDrawerRef,
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService,
        private fb: FormBuilder,
    ) {
    }


    ngOnInit() {
        this.sortOptions = [
            {label: 'First Name', value: 'first-name'},
            {label: 'Last Name', value: 'last-name'},
            {label: 'Contacted', value: 'last-notified'},
            {label: 'Email Interaction Type', value: 'last-action-name'},
            {label: 'Email Interaction Time', value: 'last-action'}
        ]
        this.sortForm = this.fb.group({
            sortBy: [this.sortOptions[0], Validators.required],
            sortDirection: ['ascending']
        });
        this.sortForm.valueChanges.subscribe(val => {
            this.guestListViewService.parameters.sortBy = val.sortBy.value;
            this.guestListViewService.parameters.sortDirection = val.sortDirection;
            this.guestListViewService.fetchGuestListForEventOrTicketBlock();
        })



    }


}
