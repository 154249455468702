import { BehaviorSubject } from "rxjs";
import { PoolFeature } from "../../../../../ApiClient/Models/PoolFeature/poolFeature";
import { Injectable } from "@angular/core";
import { EventFarmAPIClient } from "../../../../../ApiClient/event-farm-api-client";
import { Team } from "../../../../../ApiClient/Models/Pool/team";
import { NzMessageService } from 'ng-zorro-antd/message';
import {shareReplay} from "rxjs/operators";
import * as fromRoot from '../../../../store';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../../store';
@Injectable()
export class TeamFeatureService {
  private _features: PoolFeature[];
  public poolLoading$ = new BehaviorSubject<any>(false);
  public tabLoading$ = new BehaviorSubject<any>(false);
  public currentTeamFeatures$ = this.store.select(fromRoot.getAdminCurrentTeamFeaturesData).pipe(shareReplay());

  teams$ = new BehaviorSubject<Team[]>([]);

  constructor(
    private apiClient: EventFarmAPIClient,
    private messageService: NzMessageService,
    private store: Store
  ) {}

  /*** FEATURES ***/
  public fetchFeaturesByPoolId(poolId: string) {
    return this.apiClient
      .getUseCaseFactory()
      .PoolFeature()
      .ListFeaturesForPool(poolId);

  }

  public addFeatureToPool(poolId: string, featureId: string) {
   return this.apiClient
        .getUseCaseFactory()
        .PoolFeature()
        .AddFeatureForPool(poolId, featureId, true)
  }

  public  disableFeatureForPool(poolId: string, featureId: string) {
    return this.apiClient
        .getUseCaseFactory()
        .PoolFeature()
        .DisableFeatureForPool(poolId, featureId)

  }

  public  enableFeatureForPool(poolId: string, featureId: string) {

      return this.apiClient
        .getUseCaseFactory()
        .PoolFeature()
        .EnableFeatureForPool(poolId, featureId);
  }

  public removeFeatureFromPool(poolId: string, featureId: string) {
      return this.apiClient
        .getUseCaseFactory()
        .PoolFeature()
        .RemoveFeatureForPool(poolId, featureId);
  }

  public getEventConfigModifyEventSetParentEventPromise(
    eventId: string,
    parentEventId: string
  ) {
    return this.apiClient
      .getUseCaseFactory()
      .Event()
      .AddParentToEvent(eventId, parentEventId)
      .toPromise();
  }
}
