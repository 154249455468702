import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { FormControl } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import {  Question, QuestionBuilderTypeInterface, QuestionBuilderTypes } from '../../../../../../ApiClient/Models/Question/question';
import { Event } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
import { QuestionTypeInterface, Question as SDKQuestion } from '@eventfarm/javascript-sdk/dist/Api/Type/Question';

import { TicketType } from '../../../../../../ApiClient/Models/Stack/ticket-type';
import { EditQuestionService } from './edit-question.service';
import {QuestionType} from "../../../../../../Shared/question";

@Component({
  selector: 'edit-question',
  template: require('./edit-question.html'),
  styles: [require('./edit-question.scss')]
})
export class EditQuestionComponent implements OnInit {

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private editQuestionService: EditQuestionService
  ) { }

  ngOnInit() {
    this.initStoreSubscriptions();
    this.setInitialFields();
    this.initFieldSubscriptions();
  }

  @Input() question: Question;

  private questionText: FormControl = new FormControl();
  private questionType: FormControl = new FormControl();
  private ticketType: FormControl = new FormControl();
  private isRequired: FormControl = new FormControl();
  private isHidden: FormControl = new FormControl();
  private isIndividualRequired: FormControl = new FormControl();

  private ticketTypes: TicketType[];
  private questionTypes: QuestionTypeInterface[] =  new SDKQuestion().QuestionType().filter(q => !q.isAddress);
  public isSaving: boolean = false;
  private confirmDelete: boolean = false;
  private isDeleting: boolean = false;
  private createQuestion: boolean = false;
  public answersRequired: boolean = false;

  get hasText(): boolean {
    const value = this.questionType.value;
    const type = this.getQuestionTypeFromSlug(value);
    return type && type.hasText ? type.hasText : false;
  }

  get hasOptions(): boolean {
    const value = this.questionType.value;
    const type = this.getQuestionTypeFromSlug(value);
    this.answersRequired = type && type.hasOptions ? type.hasOptions : false;
    return this.answersRequired;
  }

  private async saveQuestion() {

    if (this.isSaving === false) {
      this.isSaving = true;
      let questionSaveSuccess = false;

      this.question.text = this.questionText.value;
      this.question.isRequired = this.isRequired.value;
      this.question.isHidden = this.isHidden.value;
      this.question.isIndividual = this.isIndividualRequired.value;

      const ticketTypeValue = this.ticketType.value;
      if (ticketTypeValue === 'all') {
        this.question.ticketType = null;
      } else {
        this.question.ticketType = this.ticketTypes.find(t => t.id === ticketTypeValue) ?? null;
      }

      const questionTypeValue = this.questionType.value;
      this.question.questionType = new SDKQuestion().QuestionType().find(t => t.slug === questionTypeValue) ?? new SDKQuestion().QuestionType().find(t => t.slug === 'multi');

      if (this.createQuestion) {
        questionSaveSuccess = await this.editQuestionService.createQuestion(this.question);
      } else {
        questionSaveSuccess = await this.editQuestionService.updateQuestion(this.question);
      }

      if (questionSaveSuccess) {
        this.isSaving = false;
        this.drawerRef.close();
        await this.editQuestionService.loadQuestionsForCenter();
      } else {
        this.isSaving = false;
        await this.editQuestionService.loadQuestionsForCenter();
      }
    }
  }

  private async handleDeleteQuestion() {
    if (this.isDeleting) {
      this.isDeleting = false;
    }
    if (this.confirmDelete) {
      this.isDeleting = true;

      const deleteSuccess = await this.editQuestionService.deleteQuestion(this.question.id);
      this.isDeleting = false;
      this.confirmDelete = false;

      if (deleteSuccess === true) {
        this.drawerRef.close();
        await this.editQuestionService.loadQuestionsForCenter();
      }
    }
    this.confirmDelete = true;
  }

  private allowSave() {
    if (this.answersRequired) {
      const answersValid = this.editQuestionService.answerOptionsValid;
      return !!(answersValid && this.questionText.value !== '' && this.questionType.value);
    } else {
      return this.questionText.value !== '' && this.questionType.value;
    }
  }

  /**
   * Setup initial values and subscriptions
   */
  private setInitialFields() {
    if (!this.question) {
      this.createQuestion = true;
      this.question = Question.forNewCreation();
    }

    this.questionText.setValue(this.question.text ? this.question.text : '');
    this.questionType.setValue(this.question.questionType ? this.question.questionType.slug : null);

    if (this.questionType.value) {
      this.updateIsRequired(this.questionType.value);
    }

    if (this.editQuestionService.isIndividualToggleDisabled(this.question)) {
      this.isIndividualRequired.disable();
      this.isHidden.disable();
    }

    this.ticketType.setValue(this.question.ticketType ? this.question.ticketType.id : 'all');
    this.isRequired.setValue(this.question.isRequired ? this.question.isRequired : false);
    this.isIndividualRequired.setValue(this.question.isIndividual ? this.question.isIndividual : false);
    this.isHidden.setValue(this.question.isHidden ? this.question.isHidden : false);
    this.editQuestionService.existingAnswers = _.cloneDeep(this.question.answers);
    this.editQuestionService.currentQuestionAnswers = _.cloneDeep(this.question.answers);
  }

  private initStoreSubscriptions() {
    this.store.select('event').pipe(
      filter(res => !!res),
      map(res => res.data)
    ).subscribe((res: EfEvent) => {
      if (res) {
        this.ticketTypes = res.ticketTypes;
      }
    });
  }

  private initFieldSubscriptions() {
    this.questionType.valueChanges.subscribe(slug => {
      this.updateIsRequired(slug);
    });
  }

  private updateIsRequired(slug: string) {
    const type = this.getQuestionTypeFromSlug(slug);
    if (type.isRequired) {
      this.isRequired.setValue(true);
      this.isRequired.disable();
    } else {
      this.isRequired.setValue(false);
      this.isRequired.enable();
    }
  }

  private getQuestionTypeFromSlug(slug: string): QuestionBuilderTypeInterface|undefined {
    return QuestionBuilderTypes.find(t => t.slug === slug);
  }

  close(): void {
    this.editQuestionService.currentQuestionAnswers = [];
    this.editQuestionService.existingAnswers = [];
    this.drawerRef.close(this.question);
  }
}
