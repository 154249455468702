
import { filter, take, shareReplay } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { EfEvent } from '../../../ApiClient/Models/Event/event';

@Component({
  selector: 'guest-simple-stats',
  template: require('./guest-simple-stats.html'),
  styles: [require('./guest-simple-stats.scss')],
})

export class GuestSimpleStatsComponent implements OnDestroy {

  constructor(
    private store: Store<fromRoot.AppState>,
    private eventFarmService: EventFarmService,
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id && this.loaded)).subscribe((val) => {
      this.loaded = false;
      this.counts = {};
      this.totalConfirmations = 0;
      this.checkInCount = 0;
      this.totalUsed = 0;
      this.potentialEventSize = 0;
      this.calculateAll();
    });
  }

  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private counts: any = {};
  private totalConfirmations: number = 0;
  private checkInCount: number = 0;
  private totalUsed: number = 0;
  private loaded: boolean = true;
  private potentialEventSize: number = 0;
  private eventUpdate$: Subscription;

  calculateAll() {
    this.eventFarmService.fetchGuestCountsForEventOrTicketBlock(this.eventFarmService.currentEvent.id)
      .then((res) => {
        this.counts = res;
        this.totalConfirmations = this.counts.confirmedByRsvp + this.counts.registered + this.counts.purchased + this.counts.assigned + this.counts.leftBehind;
        this.checkInCount = this.counts.checkIn;
        this.calculateTotalUsed();
        this.calculatePotentialEventSize();
        this.loaded = true;
      }).catch((err) => {
    });
  }

  calculateTotalUsed() {
    this.totalUsed = this.counts.confirmedByRsvp + this.counts.registered + this.counts.purchased + this.counts.leftBehind + this.counts.assigned + this.counts.unconfirmed + this.counts.notYetRegistered + this.counts.notYetPurchased;
  }

  calculatePotentialEventSize() {
    this.potentialEventSize = 0;
    if (this.eventFarmService.currentEvent.stacks) {
      this.eventFarmService.currentEvent.stacks.forEach((stack) => {
        if (!stack.isDeleted && (stack.stackMethodType.isPublicRegistration || stack.stackMethodType.isPublicPurchase)) {
          this.potentialEventSize += stack.availabilityCounts.totalQuantity;
        } else if (stack.stackMethodType.isFirstComeFirstServe) {
          this.potentialEventSize += stack.quantity;
        } else if (stack.stackMethodType.isInviteToRegister || stack.stackMethodType.isInviteToPurchase || stack.stackMethodType.isInviteToRSVP) {
          this.potentialEventSize += stack.availabilityCounts.totalUsed;
        }
      });
    }
  }

  ngOnDestroy() {
    this.eventUpdate$.unsubscribe();
  }
}
