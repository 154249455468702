import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup } from '@angular/forms';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { TeamEmailMasksService } from './team-email-masks.service';
import { AdminAlertService } from '../../../Alert/adminEventFarmAlert.service';
import * as adminActions from '../../../../store/actions/admin';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {shareReplay} from 'rxjs/operators';
import {DomainMask} from '../../../../../ApiClient/Models/DomainMask/domainMask';
@Component({
  selector: 'team-email-masks',
  template: require('./team-email-masks.html'),
})
export class TeamEmailMasksComponent implements OnInit {
  @Input() team: Team;
  teamEmailMasksForm: FormGroup;
  poolContractTypes: any;

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private teamEmailMasksService: TeamEmailMasksService,
    private adminAlertService: AdminAlertService,
    private store: Store
  ) {}

  async addDomain() {
    this.store.dispatch(new adminActions.AddDomainMaskToAdminTeam({
      teamId: this.team.id,
      domain:      this.teamEmailMasksForm.controls.domain.value

    }));

  }

  removeDomainMask(mask: DomainMask) {
    this.adminAlertService.general().removeEmailDomainMasks(mask)
  .then(async (result) => {
      this.store.dispatch(new adminActions.RemoveDomainMaskFromAdminTeam({maskId: mask.id}));
    });
  }

  ngOnInit() {
    this.store.dispatch(new adminActions.FetchDomainMasksForAdminTeam());

    this.poolContractTypes = this.apiClient
      .getTypeFactory()
      .Pool()
      .PoolContactType();
    this.teamEmailMasksForm = new FormGroup({
      domain: new FormControl(''),
    });
  }
}
