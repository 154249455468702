import { Injectable } from '@angular/core';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { InvitationOptionsService } from './invitationOptions.service';
import { InvitationStatusSlugFinder } from './invitation-status-slug-finder';
import { InvitationCreationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Injectable()

export class StackSelectionService {
    readonly defaultStatus = 'unconfirmed';
    readonly defaultInvitationCreationTypeSlug = 'send-email';

    private _invitationCreationTypes: InvitationCreationTypeInterface[];
    private _invitationStatusSlug: string;
    private _invitationCreationTypeSlug: string;

    constructor(
        private invitationOptionsService: InvitationOptionsService,
    ) {
    }

    set invitationCreationTypes(invitationCreationTypeInterface: InvitationCreationTypeInterface[]) {
        this._invitationCreationTypes = invitationCreationTypeInterface;
    }

    set invitationStatusSlug(invitationStatusSlug: string) {
        this._invitationStatusSlug = invitationStatusSlug;
    }

    get invitationCreationTypeSlug(): string {
        return this._invitationCreationTypeSlug;
    }

    set invitationCreationTypeSlug(value: string) {
        this._invitationCreationTypeSlug = value;
        this._invitationStatusSlug = this.invitationStatus;
    }

    get invitationStatus(): string {
        if (!this.invitationOptionsService.selectedStack) {
            return this.defaultStatus;
        }

        const statusFinder = new InvitationStatusSlugFinder(
            this.invitationOptionsService.selectedStack,
            this.getInvitationCreationMethod(),
        );

        return statusFinder.getStatus();
    }

    get invitationCreationMethod(): InvitationCreationTypeInterface {
        return this.getInvitationCreationMethod();
    }

    shouldDisableStackOption(stack: Stack): boolean {
        return (
            (stack.availabilityCounts.totalUnused <= 0 && this.invitationOptionsService.initialInvitation && this.invitationOptionsService.initialInvitation.stack.id !== stack.id)
            ||
            (stack.availabilityCounts.totalUnused <= 0 && !this.invitationOptionsService.initialInvitation)
        );
    }

    configureStackNameForDisplay(stack: Stack = null): string {
        if (!stack) {
            return 'Please Choose A Type';
        }

        let displayName = stack.ticketType.name;

        const expirationString = this.getExpirationString(stack);

        if (expirationString.length !== 0) {
            displayName = expirationString;
        } 

        if (stack.availabilityCounts.totalUnused < 0) {
            return displayName + ' (0 remain)';
        } else {
            return displayName + ' (' + stack.availabilityCounts.totalUnused + ' remain)';
        };
    }

    private getExpirationString(stack: Stack): string {

        // since if the expiration start and end time are defaulted to an empty object, we can use object.keys to see if those values have any fields

        if (Object.keys(stack.expirationStartTimeFormatted).length && !Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid From ${stack.expirationStartTimeFormatted.short}`;
        } else if (!Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid Til ${stack.expirationEndTimeFormatted.short}`;
        } else if (Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            if (stack.expirationStartTimeFormatted.date.short === stack.expirationEndTimeFormatted.date.short) {
                return `${stack.expirationStartTimeFormatted.date.short} ${stack.expirationStartTimeFormatted.time.short} to ${stack.expirationEndTimeFormatted.time.short} ${moment().tz(stack.expirationEndTimeFormatted.timezone).zoneAbbr()}`;
            } else {
                return `${stack.expirationStartTimeFormatted.short} to ${stack.expirationEndTimeFormatted.short}`;
            }
        }
        
        return '';
    }

    private getInvitationCreationMethod(): InvitationCreationTypeInterface {
        let types: InvitationCreationTypeInterface[];

        types = this._invitationCreationTypes.filter((invitationCreationType) => {
            return invitationCreationType.slug === this._invitationCreationTypeSlug;
        });

        return types[0];
    }
}
