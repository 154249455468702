import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {NzMessageService} from 'ng-zorro-antd/message';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {EventFarmService} from '../../../eventFarm.service';
import {AlertService} from '../../../eventFarmAlert.service';
import * as fromStore from '../../../store';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'ghost',
  template: require('./ghost.html'),
  styles: [require('./ghost.scss')],

})
export class GhostComponent implements OnInit {
  ghostUserForm: FormGroup;

  constructor(
      private eventFarmService: EventFarmService,
      private apiClient: EventFarmAPIClient,
      private alertService: AlertService,
      private message: NzMessageService,
      private store: Store<fromStore.AppState>,
      private adminService: AdminService,
  ) {

  }


  ngOnInit() {
    this.ghostUserForm = new FormGroup({
      email: new FormControl(''),
    });
  }

  private handleSwitchUser() {
    const fullBaseUrl = window.location.protocol + '//' + window.location.host;
    const redirectUrl = fullBaseUrl + '/users/dashboard';

    this.adminService.setGhostingAsEmail(
        this.ghostUserForm.controls.email.value
    );
    window.location.href = redirectUrl;
  }
}
