import {Action} from '@ngrx/store';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {DomainMask} from '../../../../ApiClient/Models/DomainMask/domainMask';

export const FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Domain Masks For Team';
export const FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Domain Masks For Team Success';
export const FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Domain Masks For Team Fail';

export const ADD_DOMAIN_MASK_TO_ADMIN_TEAM = '[Admin/Team] Add Domain Mask To Team';
export const ADD_DOMAIN_MASK_TO_ADMIN_TEAM_SUCCESS = '[Admin/Team] Add Domain Mask To Team Success';
export const ADD_DOMAIN_MASK_TO_ADMIN_TEAM_FAIL = '[Admin/Team] Add Domain Mask To Team Fail';

export const UPDATE_DOMAIN_MASK_FOR_ADMIN_TEAM = '[Admin/Team] Update Domain Mask For Team';
export const UPDATE_DOMAIN_MASK_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Update Domain Mask For Team Success';
export const UPDATE_DOMAIN_MASK_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Update Domain Mask For Team Fail';

export const REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM = '[Admin/Team] Remove Domain Mask From Team';
export const REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM_SUCCESS = '[Admin/Team] Remove Domain Mask From Team Success';
export const REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM_FAIL = '[Admin/Team] Remove Domain Mask From Team Fail';

export class AddDomainMaskToAdminTeam implements Action {
    readonly type = ADD_DOMAIN_MASK_TO_ADMIN_TEAM;
    constructor( public payload: {teamId: string, domain: string}) {}
}

export class AddDomainMaskToAdminTeamSuccess implements Action {
    readonly type = ADD_DOMAIN_MASK_TO_ADMIN_TEAM_SUCCESS;
    constructor(public payload: DomainMask) { }
}

export class AddDomainMaskToAdminTeamFail implements Action {
    readonly type = ADD_DOMAIN_MASK_TO_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class UpdateDomainMaskForAdminTeam implements Action {
    readonly type = UPDATE_DOMAIN_MASK_FOR_ADMIN_TEAM;
    constructor() { }
}

export class UpdateDomainMaskForAdminTeamSuccess implements Action {
    readonly type = UPDATE_DOMAIN_MASK_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolContact[]) { }
}

export class UpdateDomainMaskForAdminTeamFail implements Action {
    readonly type = UPDATE_DOMAIN_MASK_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class FetchDomainMasksForAdminTeam implements Action {
    readonly type = FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM;
    constructor() { }
}

export class FetchDomainMasksForAdminTeamSuccess implements Action {
    readonly type = FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: DomainMask[]) { }
}

export class FetchDomainMasksForAdminTeamFail implements Action {
    readonly type = FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class RemoveDomainMaskFromAdminTeam implements Action {
    readonly type = REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM;
    constructor(public payload: {maskId: string}) { }
}

export class RemoveDomainMaskFromAdminTeamSuccess implements Action {
    readonly type = REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM_SUCCESS;
    constructor(public payload: DomainMask) { }
}

export class RemoveDomainMaskFromAdminTeamFail implements Action {
    readonly type = REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}
export type AdminDomainMaskAction =
    AddDomainMaskToAdminTeam |
    AddDomainMaskToAdminTeamSuccess |
    AddDomainMaskToAdminTeamFail |
    UpdateDomainMaskForAdminTeam |
    UpdateDomainMaskForAdminTeamSuccess |
    UpdateDomainMaskForAdminTeamFail |
    RemoveDomainMaskFromAdminTeam |
    RemoveDomainMaskFromAdminTeamSuccess |
    RemoveDomainMaskFromAdminTeamFail |
    FetchDomainMasksForAdminTeam |
    FetchDomainMasksForAdminTeamSuccess |
    FetchDomainMasksForAdminTeamFail;
