import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { EventFarmService } from '../../eventFarm.service';
import { EmailDesignCenterService } from './email-design-center.service';
import { EmailPreviewService } from './email-preview.service';
import { ScheduledService } from './Scheduled/scheduled.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';

@Component({
    selector: 'email-design-center',
    template: require('./email-design-center.html'),
    styles: [require('./email-design-center.scss')],
    providers: [
        EmailDesignCenterService,
        EmailPreviewService,
        ScheduledService,
    ],
    encapsulation: ViewEncapsulation.None,
})

export class EmailDesignCenterComponent implements OnInit {

    constructor(
        private emailDesignCenterService: EmailDesignCenterService,
        private emailPreviewService: EmailPreviewService,
        private segmentService: SegmentService) { }

    ngOnInit() {
        this.segmentService.segmentSideNavTracking().emailDesignsEntered();
    }

}
