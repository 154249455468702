import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// module root component
import { EmailCommunicationComponent } from './email-communication.component';

// child components
import { InvitationsConfirmationsComponent } from './InvitationsConfirmations/invitations-confirmations.component';

const routes: Routes = [
    {
        path: '',
        component: EmailCommunicationComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./InvitationsConfirmations/invitations-confirmations.module').then(
                    module => module.InvitationsConfirmationsModule
                ),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EmailCommunicationRoutingModule { }
