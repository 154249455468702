import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { InvitationOptionsService } from '../../../../CoreUI/InvitationOptions/invitationOptions.service';
import { StackSelectionService } from '../../../../CoreUI/InvitationOptions/stackSelection.service';
import { AddInviteCountService } from '../../../../CoreUI/InvitationOptions/addInviteCount.service';

import { Group } from '../../../../../ApiClient/Models/Group/group';

@Injectable()

export class AddGroupService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private invitationOptionsService: InvitationOptionsService,
        private stackSelectionService: StackSelectionService,
        private addInviteCountService: AddInviteCountService
    ) {
    }

    createInvitationFromGroup(groupId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().CreateInvitationsFromGroup(
            groupId,
            this.invitationOptionsService.selectedStackId,
            this.addInviteCountService.inviteCount,
            this.stackSelectionService.invitationCreationTypeSlug
        );
    }

    getGroupDetails(groupId: string) {
        return this.apiClient.getUseCaseFactory().Group().GetGroup(groupId, ['totalUsersInGroup', 'creatorUser']);
    }

    createInvitationsFromGroupForCIOEvent(eventId: string, groupId: string) {
        return this.apiClient.getUseCaseFactory().Invitation().CreateInvitationsFromGroupForCIOEvent(
            eventId,
            groupId,
            this.addInviteCountService.inviteCount
        );
    }

}
