import { Pipe } from '@angular/core';

@Pipe({
    name: 'virbela',
})
export class VirbelaSlugPipe {
    transform(slugs, option) {
        switch (option) {
            case false:
            case undefined:
                return slugs.filter((i) => i['name'] !== 'ECHO_LINK');
            default:
                return slugs.filter((i) => i);
        }
    }
}
