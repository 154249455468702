// @ts-nocheck
import _ = require("lodash");

export function mergeByValue(arr) {
    return _(arr)
        .groupBy(function(item) { // group the items using the lower case
            return item.value.toLowerCase();
        })
        .map(function(group) { // map each group
            return _.mergeWith.apply(_, [{}].concat(group, function(obj, src) { // merge all items, and if a property is an array concat the content
                if (Array.isArray(obj)) {
                    return obj.concat(src);
                }
            }))
        })
        .values() // get the values from the groupBy object
        .value();
}
