import { Component, EventEmitter, Output, HostBinding, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Subject, Observable, Subscription } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { User } from '../../../ApiClient/Models/User/user';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { debounceTime, map, tap, distinctUntilChanged, filter, shareReplay } from 'rxjs/operators';
import { Group } from '../../../ApiClient/Models/Group/group';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
@Component({
    selector: 'group-typeahead',
    template: require('./group-typeahead.html'),
    animations: [
        trigger('leavingState', [
            transition(':enter', [
                animate(0, style({ opacity: 0, top: '-1rem' })),
                animate('100ms ease-out', style({ opacity: 1, top: '0' })),
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0 })),
            ]),
        ]),
    ],
    host: {
        '[@leavingState]': '',
        '(document:click)': 'offClick($event)',
    },
})

export class GroupTypeaheadComponent implements OnInit, OnDestroy {
    private searchTerm: FormControl = new FormControl();
    private searchResults$ = new Subject<Group[]>();
    private isSearching: boolean = false;
    private isSettingValue: boolean = false;
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userUpdate$: Subscription;
    @Output() selectionReceived: EventEmitter<any> = new EventEmitter();
    @Output() selectionTyped: EventEmitter<any> = new EventEmitter();
    @Input() omittedResults: Group[];
    @Input() defaultValue: string;
    @Input() placeholder: string = 'Search By Group Name...';

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private elementRef: ElementRef,
        private store: Store<fromRoot.AppState>
    ) {
        this.userUpdate$ = this.userStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.searchForGroup();
            if (this.defaultValue) {
                this.broadcastDefaultValue();
            }
        });
    }

    @HostBinding('class.active') activeInput: boolean;

    ngOnInit() {
    }

    private searchForGroup() {
        this.searchTerm.valueChanges
            .pipe(debounceTime(200),
                tap(() => { this.isSearching = true; !this.isSettingValue ? this.activeInput = true : this.activeInput = false; }),
                distinctUntilChanged(),
            ).subscribe((term) => {
            this.apiClient.getUseCaseFactory().Group().ListGroupsOwnedByUser(
                this.eventFarmService.currentUser.id,
                term,
            )
            .pipe(map(res => res['data'].map(group => Group.fromApiResponse((group)))))
            .subscribe((results: Group[]) => {
                this.isSearching = false;
                this.isSettingValue = false;
                this.selectionTyped.emit(term);
                if (this.omittedResults && this.omittedResults.length) {
                    results = this.removeResultsThatMatchOmittedItems(results);
                }
                if (term) {
                    this.searchResults$.next(results);
                } else {
                    this.searchResults$.next([]);
                }
            }, (err) => {
                this.selectionTyped.emit(term);
            });
        });
    }

    private removeResultsThatMatchOmittedItems(results: Group[]): Group[] {
        let filteredGroupsOwnedByUserRes: Group[] = [];
        results.forEach((groupResult) => {
            filteredGroupsOwnedByUserRes.push(groupResult);
            this.omittedResults.forEach((omittedResult) => {
                if (omittedResult && omittedResult.id === groupResult.id) {
                    filteredGroupsOwnedByUserRes.pop();
                }
            });
        });
        return filteredGroupsOwnedByUserRes;
    }

    private broadcastDefaultValue() {
        this.apiClient.getUseCaseFactory().Group().ListGroupsOwnedByUser(
            this.eventFarmService.currentUser.id,
            this.defaultValue,
        )
            .toPromise()
            .then((res) => {
                const match = res.data.filter((eachRes) => eachRes.attributes && eachRes.attributes.name === this.defaultValue)[0];
                if (match) {
                    const matchedGroup = Group.fromApiResponse(match);
                    this.selectOption(matchedGroup);
                    this.searchTerm.setValue(matchedGroup.name);
                }
            });
    }

    private selectOption(option) {
        this.selectionReceived.emit(option);
        this.searchTerm.setValue(option.name);
        this.activeInput = false;
        this.isSettingValue = true;
    }

    private offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.activeInput = false;
        }
    }

    ngOnDestroy() {
        this.userUpdate$.unsubscribe();
    }
}