import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventFarmService } from '../../../../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../../../../admin.service';
import { TeamEventsService } from '../../../team-events.service';
import { EfEvent } from '../../../../../../../../ApiClient/Models/Event/event';
import _ = require('lodash');
import { VariantTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';

@Component({
  selector: 'edit-event-settings',
  template: require('./edit-event-settings.html'),
  styles: [require('./edit-event-settings.scss')],
})
  export class EditEventSettingsComponent implements OnInit {
  @Input() eventId: string;
  public parentEventList$ = new BehaviorSubject<EfEvent[]>(null);

  private validStatuses$: Subscription;
  private isLoadingCurrentValues: boolean = true;
  private shouldSaveQuestions: boolean = false;
  private isSaving: boolean = false;
  private eventVariants: VariantTypeInterface[];
  private paymentProcessorTypes;
  private currencyTypes;
  private shieldEnabled: boolean;
  private altKeyword: string = '';
  private altLayout: string = '';
  private altEmailLayout: string = '';
  private altProcessor: string = '';
  private altPaymentFile: string = '';
  private altPayee: string = '';
  private processingCurrency: string = '';
  private processingPerc: string = '';
  private processingFee: string = '';
  private processingRefund: string = '';
  private parentEvent: string = '';
  private healthPassShortCode: string = '';

  editEventsListItemForm: FormGroup;
  private parentEventList: EfEvent[];
  private currentTeamEvent$: Subscription;
  private currentTeamEvents$: Subscription;
  private valueChanges$: Subscription;
  private statusChanges$: Subscription;

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private adminService: AdminService,
    private messageService: NzMessageService,
    private teamEventsService: TeamEventsService
  ) {}

  ngOnInit() {
    this.eventVariants = this.apiClient.getTypeFactory().Event().VariantType();
    this.paymentProcessorTypes = this.apiClient
      .getTypeFactory()
      .Event()
      .PaymentProcessorType();
    this.currencyTypes = this.apiClient
      .getTypeFactory()
      .Event()
      .ProcessingCurrencyType();
    this.adminService.fetchEvent(this.eventId);
    this.isSaving = false;
    this.editEventsListItemForm = new FormGroup({
      eventVariant: new FormControl(),
      shieldEnabled: new FormControl(),
      altKeyword: new FormControl(),
      altLayout: new FormControl(),
      altEmailLayout: new FormControl(),
      altProcessor: new FormControl(),
      altPaymentFile: new FormControl(),
      altPayee: new FormControl(),
      processingCurrency: new FormControl(),
      processingPerc: new FormControl(),
      processingFee: new FormControl(),
      processingRefund: new FormControl(),
      parentEvent: new FormControl(),
      healthPassShortCode: new FormControl(),

    });
    this.currentTeamEvent$ = this.adminService.currentTeamEvent$.subscribe((event) => {
      if (event) {
        this.isLoadingCurrentValues = false;
        this.initializeFormControls(event);
      }
    });
    this.statusChanges$ = this.editEventsListItemForm.statusChanges.subscribe((vals) => {});
    this.valueChanges$ = this.editEventsListItemForm.valueChanges.subscribe((vals) => {});
    this.currentTeamEvents$ = this.adminService.currentTeamEvents$.subscribe((vals) => {
      const tmp = vals.data.filter((val: EfEvent) => val.id !== this.eventId);
      this.parentEventList$.next(tmp);
    });
  }

  private initializeFormControls(event: EfEvent) {
    this.editEventsListItemForm.setValue({
      eventVariant: event.variant.slug,
      shieldEnabled: event.isShield,
      altKeyword: event.altKeyword || '',
      altLayout: event.altLayout || '',
      altEmailLayout: event.altEmailLayout || '',
      altProcessor: event.altProcessor || '',
      altPaymentFile: event.altPaymentFile || '',
      altPayee: event.altPayee || '',
      processingCurrency: _.get(event, 'currency.slug') || 'usd',
      processingPerc: event.processingPerc || '',
      processingFee: event.processingFee || '',
      processingRefund: event.processingRefund || '',
      parentEvent: event.parentEventId || '',
      healthPassShortCode: event.healthPassShortCode || '',

    });
  }

  /**
   *
   * Updates the status of the invite
   */
  private onChangeStatus(status) {}

  private determineDirtyFields(controls) {
    const dirty = Object.keys(controls)
      .map((name) => {
        return { ...controls[name], name };
      })
      .filter((obj) => !obj.pristine);
    return dirty;
  }

  private async saveEventConfiguration() {
    const dirty = this.determineDirtyFields(
      this.editEventsListItemForm.controls
    );
    const promises = [];
    // Populate promises based on dirty fields
    dirty.map((d) => {
      switch (d.name) {
        case 'eventVariant':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventVariantPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'shieldEnabled':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventShieldPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'altKeyword':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventAltKeywordPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'altLayout':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventAltLayoutPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'altEmailLayout':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventAltEmailLayoutPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'altProcessor':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventAltPaymentProcessorPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'altPaymentFile':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventAltPaymentFilePromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'altPayee':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventAltPayeePromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'processingCurrency':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventCurrencyPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'processingPerc':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventProcessingPercPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'processingFee':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventProcessingFeePromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'processingRefund':
          promises.push(
            this.teamEventsService.getEventConfigModifyEventProcessingRefundPromise(
              this.eventId,
              d.value
            )
          );
          break;
        case 'parentEvent':
          if (d.value === 'none') {
            promises.push(
              this.teamEventsService.getEventConfigRemoveParentEventPromise(
                this.eventId
              )
            );
          } else {
            promises.push(
              this.teamEventsService.getEventConfigModifyEventSetParentEventPromise(
                this.eventId,
                d.value
              )
            );
          }
          break;
        case 'healthPassShortCode':
          promises.push(
              this.teamEventsService.getEventConfigModifyEventHealthPassShortCodePromise(
                  this.eventId,
                  d.value
              )
          );
          break;
      }
    });
    try {
      await Promise.all(promises);
      this.messageService.success('Event configuration updated!');
      this.drawerRef.close();
    } catch (error) {
      this.messageService.error('Error updating event config');
    }
  }

  /**
   * Setup initial values and subscriptions
   */

  close() {
    this.drawerRef.close();
  }

  // Todo revisit using unsubscribe instead
  ngOnDestroy() {
    this.currentTeamEvents$.unsubscribe();
    this.statusChanges$.unsubscribe();
    this.valueChanges$.unsubscribe();
    this.adminService.currentTeamEvent$.next(null);
  }
}
