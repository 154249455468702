import { AbstractModel } from '../abstract-model';

export class PoolWorld extends AbstractModel {
    
    public _poolWorldId: string;
    public _teamId: number;
    public _titleMapping: string;
    public _virbelaWorldId: string;
    public _worldId: string;
    public _worldName: string;
    public _macDownloadURL: string;
    public _windowsDownloadURL: string;

    constructor(id) {
        super(id);
    }
    
    public static fromApiResponse(data): PoolWorld {
        const poolWorld = new this(data.id);
        poolWorld._teamId = data.attributes ? data.attributes.teamId : 0;
        poolWorld._titleMapping = data.attributes ? data.attributes.titleMapping : '';
        poolWorld._virbelaWorldId = data.attributes ? data.attributes.virbelaWorld.id : '';
        poolWorld._worldId = data.attributes ? data.attributes.virbelaWorld.worldId : '';
        poolWorld._worldName = data.attributes ? data.attributes.virbelaWorld.worldName : '';
        poolWorld._macDownloadURL = data.attributes ? data.attributes.virbelaWorld.macDownloadURL : '';
        poolWorld._windowsDownloadURL = data.attributes ? data.attributes.virbelaWorld.windowsDownloadURL : '';
        return poolWorld;
    }

    get worldName(): any {
        return this._worldName;
    }

    get worldId(): any {
        return this._worldId;
    }

    get virbelaWorldId(): any {
        return this._virbelaWorldId;
    }

}

