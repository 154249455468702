import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { TicketBlock } from '../../../ApiClient/Models/Stack/ticket-block';
import { EventFarmService } from '../../eventFarm.service';
import { Salutation } from '../../../ApiClient/Models/Salutation/salutation';
import { Observable } from 'rxjs';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { AlertService } from '../../eventFarmAlert.service';
import { User } from '../../../ApiClient/Models/User/user';
import {
    InvitationCreationTypeInterface,
    InvitationStatusTypeInterface
} from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { all } from 'core-js/fn/promise';
import * as _ from 'lodash';
@Injectable()
export class TicketBlockDashboardService {
    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private route: ActivatedRoute,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    public ticketBlock: TicketBlock;
    public totalAllotmentCounts: AllAllotmentCounts;
    private _salutations: Salutation[];
    public stacks: Stack[];
    public ticketBlockUsers: User[];
    public ticketTypes: any = {};
    public isSessionTicketBlock = false;

    async gatherRelatedData() {
        this.isSessionTicketBlock = false;

        await this.fetchNewTicketBlock();

        if (!this.eventFarmService.currentEvent) {
            this.eventFarmService.currentEvent = this.ticketBlock.event;
        }

        const extractedStacks = this.extractStacksFromTicketBlock(this.ticketBlock);

        this.eventFarmService.currentEvent.stacks = extractedStacks;
        this.stacks = extractedStacks;
        this.calculateTotalsFromAllotments();
        this.listUsersForTicketBlock();
        if(!this.eventFarmService.updateTicketBlockCounts$.closed) {
            this.eventFarmService.updateTicketBlockCounts$.next(false);
        }
    }

    private async fetchNewTicketBlock() {
        const t = await this.apiClient.getUseCaseFactory().TicketBlock().GetTicketBlock(this.ticketBlock.id, ['Event', 'Allotments', 'AllotmentCounts', 'AllotmentsAndStack'], true).toPromise();
        this.ticketBlock =  TicketBlock.fromApiResponse(t.data);
        this.isSessionTicketBlock = _.get(this, 'ticketBlock.isSessionTB', false);
    }


    calculateTotalsFromAllotments() {
        if (this.ticketBlock && this.ticketBlock.extraAttributes && this.ticketBlock.extraAttributes.allotmentCounts) {
            this.totalAllotmentCounts.used = 0;
            this.totalAllotmentCounts.quantity = 0;
            this.totalAllotmentCounts.remaining = 0;
            this.ticketBlock.extraAttributes.allotmentCounts.forEach((allotment) => {
                this.totalAllotmentCounts.used += allotment.invitationCounts.totalUsed;
                this.totalAllotmentCounts.quantity += allotment.invitationCounts.totalQuantity;
                this.totalAllotmentCounts.remaining += allotment.invitationCounts.totalRemaining;
            });
            this.totalAllotmentCounts.percentageUsed = (this.totalAllotmentCounts.used / this.totalAllotmentCounts.quantity) * 100;
        }
    }

    get salutations(): Salutation[] {
        return this._salutations;
    }

    fetchSalutations(): void {
        this.apiClient.getUseCaseFactory()
            .Salutation()
            .GetAllSalutations()
            .subscribe((res) => {
                this._salutations = [];
                for (const salutation of res.data) {
                    this._salutations.push(Salutation.fromApiResponse(salutation));
                }
            });
    }

    get invitationStatusTypes(): InvitationStatusTypeInterface[] {
        return this.apiClient.getTypeFactory().Invitation().InvitationStatusType();
    }

    get invitationCreationTypes(): InvitationCreationTypeInterface[] {
        return this.apiClient.getTypeFactory().Invitation().InvitationCreationType();
    }

    get invitationCreationTypesForMasterTicketBlocks(): InvitationCreationTypeInterface[] {
        return this.invitationCreationTypes.filter(ct => !ct.isSendEmail);
    }

    extractStacksFromTicketBlock(ticketBlock: TicketBlock): Stack[] {
        let stacks: Stack[] = [];
        if (ticketBlock.allotments) {
            ticketBlock.allotments.forEach((allotment) => {
                if (!allotment.stack.isDeleted) {
                    stacks.push(allotment.stack);
                }
            });
        }
        if (this.ticketBlock.extraAttributes) {
            this.ticketBlock.extraAttributes.allotmentCounts.forEach((allotment) => {
                stacks.forEach((stack) => {
                if (stack.id === allotment.stackId) {

                    if (stack.event.hasParentEvent && stack.event) {
                        stack.ticketType.name = stack.event.name;
                    } else {
                        stack.ticketType.name = allotment.ticketTypeName;
                    }

                    stack.availabilityCounts = allotment.invitationCounts;
                    stack.availabilityCounts.totalUnused = allotment.invitationCounts.totalRemaining;
                }
                });
            });
        }

        return stacks;
    }

    listUsersForTicketBlock() {
        this.apiClient.getUseCaseFactory().User().ListUsersForTicketBlock(this.ticketBlock.id, ['UserIdentifiers', 'UserNames', 'UserAttributes'])
            .subscribe((res) => {
                this.ticketBlockUsers = [];
                res.data.forEach((eachRes) => {
                    this.ticketBlockUsers.push(User.fromApiResponse(eachRes));
                });
            });
    }

    removeUserFromTicketBlock(userId: string) {
        return this.apiClient.getUseCaseFactory().TicketBlock().RemoveAllUserRolesFromTicketBlock(userId, this.ticketBlock.id);
    }

    setCheckInNotes(invitation, note) {
        if (note === '') {
            note = null;
        };
        return this.apiClient.getUseCaseFactory().Invitation().SetCheckInNotes(invitation.id, note).toPromise();
    }

    setInvitationNotes(invitation, note) {
        if (note === '') {
            note = null;
        }
        return this.apiClient.getUseCaseFactory().Invitation().SetInvitationNotes(invitation.id, note).toPromise();
    }

    setInvitationCount(invitationId: string, count: number) {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .ChangeInviteCount(invitationId, count)
            .toPromise();
    }

    setArrivalAlertAndEmails(invitationId: string, emails: string[]|null, shouldSendArrivalAlert: boolean|null, phoneNumbers: string[]|null) {
        return this.apiClient.getUseCaseFactory().Invitation().SetArrivalAlertEmailsAndPhoneNumbers(invitationId, emails, shouldSendArrivalAlert, phoneNumbers).toPromise();
    }

    createInvitation(invitation: CreateInvitationCommand, stackId: string, inviteCount: number, shouldCreateParent: boolean = false): Observable<any> {
        const invitationId = null;
        const stack = this.stacks.find(s => s.id === stackId);

        let eventId = invitation.eventId;

        if (stack !== undefined && stack.event) {
            eventId = stack.event.id;
        }

        return this.apiClient.getUseCaseFactory().Invitation().CreateInvitationForTicketBlock(
            eventId,
            stackId,
            this.ticketBlock.id,
            invitation.status,
            invitation.inviteSource,
            invitation.isCheckedIn,
            inviteCount,
            invitation.email,
            invitation.firstName,
            invitation.lastName,
            invitation.company,
            invitation.position,
            invitation.checkInNotes,
            invitationId,
            invitation.shouldSendInvitation,
            invitation.invitationNotes,
            invitation.title,
            invitation.telephone,
            null,
            shouldCreateParent
        );
    }
}

export interface CreateInvitationCommand {
    eventId: string;
    status: string;
    inviteSource: string;
    isCheckedIn: boolean;
    email?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    position?: string;
    checkInNotes?: string;
    shouldSendInvitation: boolean;
    invitationNotes?: string;
    telephone?: string;
    title?: string;
}

interface AllAllotmentCounts {
    used: number;
    quantity: number;
    remaining: number;
    percentageUsed: number;
}
