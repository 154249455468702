import { BehaviorSubject } from 'rxjs';
import { PoolFeature } from '../../../../../ApiClient/Models/PoolFeature/poolFeature';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { NzMessageService } from 'ng-zorro-antd/message';
import {shareReplay} from "rxjs/operators";
import {Store} from "@ngrx/store";
import * as fromRoot from '../../../../store';
@Injectable()
export class TeamContractDetailsService {
  private _features: PoolFeature[];
  public poolLoading$ = new BehaviorSubject<any>(false);
  public tabLoading$ = new BehaviorSubject<any>(false);
  public currentTeamContract$ = this.store.select(fromRoot.getAdminCurrentTeamContract).pipe(shareReplay());

  teams$ = new BehaviorSubject<Team[]>([]);

  constructor(
    private apiClient: EventFarmAPIClient,
    private messageService: NzMessageService,
    private store: Store
  ) {}

  public fetchPoolContractById(poolId: string) {
    return this.apiClient
      .getUseCaseFactory()
      .Pool()
      .GetPoolContract(poolId);
  }

  public  createPoolContract({
      teamId,
      poolContractType,
      startDate,
      endDate,
    }) {
    return this.apiClient
        .getUseCaseFactory()
        .Pool()
        .CreatePoolContract(teamId, poolContractType, startDate, endDate)

  }
  public  updatePoolContract({
    poolContractId,
    poolContractType,
    startDate,
    endDate,
  }) {
    return this.apiClient
      .getUseCaseFactory()
      .Pool()
      .UpdatePoolContract(poolContractId, poolContractType, startDate, endDate)

  }
}
