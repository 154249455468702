import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-radio',
  template: `
    <div
      class="dynamic-field form-group form-radio"
      [formGroup]="group">
      <label>{{ config.label }}</label>
      <div class="radio" *ngFor="let option of config.options">
        <label>
          <input
            type="radio"
            [value]="option.id"
            [formControlName]="config.name" />
            {{ option.text }}
        </label>
      </div>
    </div>
  `
})
export class FormRadioComponent implements Field {
  config;
  group: FormGroup;
}
