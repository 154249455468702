import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { Observable ,  Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { EventFarmService } from '../../../eventFarm.service';
import { QuestionCenterService } from '../question-center.service';
import { UserField, UserFields } from '../../../../ApiClient/Models/Event/user-field';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';

@Component({
    selector: 'attributes',
    styles: [require('./attributes.scss')],
    template: require('./attributes.html'),
})

export class AttributesComponent implements OnDestroy {
    constructor(
        private eventFarmService: EventFarmService,
        private questionCenterService: QuestionCenterService,
        private store: Store<fromRoot.AppState>,
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.event = val.data;
            this.makeFormGroup();
        });
    }

    private event: EfEvent;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private allUserFields: UserField[] = UserFields.all;
    private userFieldsFormGroup;
    private eventUpdate$: Subscription;


    private makeFormGroup() {
        const formGroupOptions = {};

        for (const userField of this.allUserFields) {
            const currentValue = this.event.userFields
                .filter((f) => f.slug === userField.slug)
                .length ? true : false;

            const formControl = new FormControl();
            formControl.setValue(currentValue);

            formControl.valueChanges
                .subscribe((value) => this.updateUserField(value, userField));

            formGroupOptions[userField.slug] = formControl;
        }

        this.userFieldsFormGroup = formGroupOptions;
    }

    private async updateUserField(value: boolean, userField: UserField) {
        await this.questionCenterService.updateUserField(userField, value);
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
}
