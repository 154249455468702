import { Component, OnInit } from "@angular/core";
import { EventFarmService } from "./eventFarm.service";
import { EventFarmAPIClient } from "../ApiClient/event-farm-api-client";
import { LocalStorageService } from "../_services/storage/local-storage.service";

@Component({
  selector: "empty-component",
  template: "",
})
export class EmptyComponent {
  profileJson: string = null;

  constructor(
    private efApi: EventFarmAPIClient,
    private ls: LocalStorageService
  ) {}

  //TODO look at cleaning this up
  ngOnInit(): void {

  }
}
