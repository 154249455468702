
import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';

export class SitePagePreviewAlert extends AbstractBaseAlert {

    view( pathRoot: string, previewRoot: string, messageType: string): Promise<any> {
        let inviteDisabled = false;
        let publicDisabled = false;
        let displayValue;

        window.addEventListener('message', function(e) {
            if (e.origin === pathRoot && e.data && e.data.message && e.data.message === 'shield-height') {
            const scrollHeight = e.data && e.data.value ? e.data.value : 400;
            if (document.getElementById('PreviewIframe')) {
                document.getElementById('PreviewIframe').style.height = scrollHeight + 'px';
            }
        }
        } , false);

        if (messageType === 'reveal' || messageType === 'declination' || messageType === 'confirmation_invitation' || messageType === 'additional_invitation' || messageType === 'wait_list_confirmation_invitation') {
            publicDisabled = true;
        }
        if (messageType === 'additional'  || messageType === 'confirmation' || messageType === 'wait_list_confirmation' || messageType === 'soldout') {
            inviteDisabled = true;
        }
        return this.alert({
            title: '',
            confirmButtonText: 'x',
            onBeforeOpen: function(m) {
                function getTarget(messamessageTypegeType, flow: string) {
                    let target: string = '';
                    const lowerFlow = flow.toLowerCase();
                    switch (messageType) {
                        case 'additional':
                            target = `${previewRoot}/public-intro`;
                            break;

                        case 'additional_invitation':
                            target = `${previewRoot}/invite-intro`;
                            break;

                        case 'opening':
                            target = `${previewRoot}/${lowerFlow}-prior`;
                            break;

                        case 'closing':
                            target = `${previewRoot}/${lowerFlow}-after`;
                            break;

                        case 'confirmation':
                            target = `${previewRoot}/public-confirmation`;
                            break;

                        case 'confirmation_invitation':
                            target = `${previewRoot}/invite-confirmation`;
                            break;

                        case 'declination':
                            target = `${previewRoot}/invite-declination`;
                            break;

                        case 'wait_list_confirmation':
                            target = `${previewRoot}/public-waitlist-confirmation`;
                            break;

                        case 'wait_list_confirmation_invitation':
                            target = `${previewRoot}/invite-waitlist-confirmation`;
                            break;

                        case 'soldout':
                            target = `${previewRoot}/${lowerFlow}-capacity`;
                            break;

                        case 'reveal':
                            target = `${previewRoot}/${lowerFlow}-reveal`;
                            break;

                        case 'response_restriction':
                            target = `${previewRoot}/${lowerFlow}-response-restriction`;
                            break;
                    }
                    return target;
                }

                const content = swal.getContent();
                const  container = content.parentElement;
                const  containerWrapper = container.parentElement;
                containerWrapper.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                container.style.backgroundColor = 'transparent';
                container.style.boxShadow = 'none';

                container.style.width = 'fit-content';
                container.style.padding = '0';

                const header: HTMLDivElement = container.querySelector('.swal2-header');
                header.style.height = '0';

                const actions: HTMLDivElement = container.querySelector('.swal2-actions');
                actions.style.height = '0';
                actions.style.overflow = 'hidden';
                actions.style.margin = '0';

                const cancel: HTMLButtonElement = container.querySelector('.swal2-confirm');
                cancel.style.position = 'absolute';
                cancel.style.top = '0';
                cancel.style.right = '0';
                cancel.style.width = '25px';
                cancel.style.height = '25px';
                cancel.style.top = '5px';
                cancel.style.right = '25px';
                cancel.style.fontSize = '1.5em';
                cancel.style.color = 'black';
                cancel.style.cursor = 'pointer';
                cancel.style.boxShadow = 'none';
                cancel.style.padding = '0 10px';
                cancel.style.content = 'x';
                cancel.style.background = 'transparent';
                cancel.style.color = 'black';

                const flowSelector: HTMLDivElement = container.querySelector('#FlowSelector');
                flowSelector.style.position = 'absolute';
                flowSelector.style.top = '-50px';
                flowSelector.style.right = '0';
                flowSelector.style.display = inviteDisabled || publicDisabled ? 'none' : 'flex'

                const sel: HTMLSelectElement = content.querySelector('#FlowId');

                // Dynamically build select options based off of invite/public visibility

                sel.innerHTML = null;
                if (!inviteDisabled) {
                    sel.innerHTML = '<option>Invite</option>';
                }
                if (!publicDisabled) {
                    sel.innerHTML += '<option>Public</option>';
                }

                const iframe: HTMLIFrameElement = content.querySelector('#PreviewIframe');
                iframe.style.maxHeight = '600px';
                const src = getTarget(messageType, 'invite');
                iframe.src = src;
                sel.addEventListener('change', function(e: any) {
                    const flow = e.target.value;
                    const source = getTarget(messageType, flow);
                    iframe.src = source;
                });
            },
            html: `
            <div>
                <div id="FlowSelector" style="display:flex; justify-content:flex-end;">
                    <select style="margin:5px;width:100px;" id="FlowId" class="form-control">
                        <option>Invite</option>
                        <option>Public</option>
                    </select>
                 </div>
                <iframe style="border:none;transition:1s all; height:400px;" id="PreviewIframe" width="602" src='#' />
            </div>`
        });
    }
}
