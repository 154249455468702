import { Component, OnInit } from '@angular/core';
import { Operator } from 'rxjs';
import { UploaderService } from '../uploader.service';
import { FinishService } from './finish.service';
import { EventFarmService } from '../../../eventFarm.service';

@Component({
    selector: 'import-failure-display',
    template: require('./import-failure-display.html'),
    styles: [require('./import-failure-display.scss')],
})

export class ImportFailureDisplayComponent implements OnInit {

    constructor(
        private uploaderService: UploaderService,
        private finishService: FinishService,
        private eventFarmService: EventFarmService,
    ) {
    }

    readonly downloadIcon = require('../assets/download-icon');

    ngOnInit() {
    }
}
