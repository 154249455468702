import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ManageRoutingModule } from './../../manage.routing-module';
import { EventFarmService } from '../../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AlertService } from '../../../../eventFarmAlert.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TeamEventsService } from './team-events.service';
import { LoaderGrayModule } from '../../../../CoreUI/LoaderGray/loader-gray.module';
import { EditEventEfxService } from './EventListItem/EditEventConfig/EditEventEfX/edit-event-efx.service';
import { EditEventSettingsComponent } from './EventListItem/EditEventConfig/EditEventSettings/edit-event-settings.component';
import { EditEventConfigModule } from './EventListItem/EditEventConfig/edit-event-config.module';
import { TeamEventsComponent } from './team-events.component';
import {NgxUtilitiesModule} from '../../../../../NGXUtilities/ngx.utilities.module';

const forExport = [
    TeamEventsComponent
];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    AutocompleteLibModule,
    LoaderGrayModule,
    EditEventConfigModule,
    NgxUtilitiesModule
  ],
  entryComponents: [],
  providers: [
    EventFarmService,
    NzDrawerService,
    AlertService,
    TeamEventsService,
    EditEventEfxService,
  ],
})
export class TeamEventsModule {}
