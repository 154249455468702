import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Subscription, Observable } from 'rxjs';
import { filter, take, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Venue } from '../../../../ApiClient/Models/Event/venue';
import { PoolFeature } from '../../../../ApiClient/Models/PoolFeature/poolFeature';
import { PoolWorld } from '../../../../ApiClient/Models/PoolWorld/pool-world';
import { getPoolWorldByVirbelaWorldName } from '../../../../Shared/pool-world';
import * as _ from 'lodash';
import { WebConferenceUserConnection } from '../../../../ApiClient/Models/WebConferenceUserConnection/web-conference-user-connection';
import { EFWebConference } from '../../../../ApiClient/Models/WebConference/web-conference';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { WebConference, WebConferenceFormatTypeInterface, WebConferenceSourceTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/WebConference';
import { AlertService } from '../../../eventFarmAlert.service';
import { User } from '../../../../ApiClient/Models/User/user';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { VenueTypeInterface, Venue as EFVenue } from '@eventfarm/javascript-sdk/dist/Api/Type/Venue';
import { SessionStorageService } from '../../../../_services/storage/session-storage.service';

@Component({
    selector: 'event-location',
    template: require('./event-location.html'),
    styles: [require('./event-location.scss')],
})
export class EventLocationComponent implements OnInit {
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private teamStore$: Observable<any> = this.store.select('team').pipe(shareReplay());
    private eventUpdate$: Subscription;
    private teamUpdate$: Subscription;
    private isEditingLocation: boolean;
    private selectedLocationType: VenueTypeInterface;
    private poolWorldOptions: PoolWorld[];
    private selectedPoolWorld: PoolWorld;
    private currentWebConferences: EFWebConference[];
    private webConferenceEnabled: boolean = false;
    private detailsFieldOpen;
    private disabled: boolean;
    private activeAddress: string;
    private activeDetails: string;
    private activeName: string;
    private activeStreet: string;
    private activeStreet2: string;
    private activeCity: string;
    private activeState: string;
    private activeZipCode: string;
    private showZoomMessage: boolean = false;
    private showConferenceSettingsWarningMessage: boolean = false;
    private warningCaretUp: boolean = false;
    private showFixSettings: boolean = false;
    private conferenceSettingsMessageArray: string[] = [];
    private activeWebConferenceId: string|null = null;
    private activeWebConferenceSourceType: WebConferenceSourceTypeInterface|null = new WebConference().WebConferenceSourceType().find(p => p.isNone);
    private activeWebConferenceFormat: WebConferenceFormatTypeInterface|null = new WebConference().WebConferenceFormatType().find(p => p.isWebinar);
    private selectedWebConference: EFWebConference = null;
    private webConferenceConnections: WebConferenceUserConnection[] = [];
    private selectedConferenceConnection: WebConferenceUserConnection = null;
    public loading: boolean = false;
    private defaultLocationType = new EFVenue().VenueType().filter(vt => vt.isPhysical)[0];
    private venueTypeOptions: VenueTypeInterface[];
    private formats = new WebConference().WebConferenceFormatType().filter(w => !w.isNone && !w.isWebinar);
    private selectedLocationTypeSlug: string;
    private userContactType$ = this.store.select(fromStore.getPoolContactType).pipe(shareReplay());
    private userContactType: any;
    private showConferenceConnectionsWarningMessage: boolean;
    private webConferenceAUserEmail: string;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
        private alertService: AlertService,
        private iconService: IconService,
        private sessionStorageService: SessionStorageService
    ) {
        this.detailsFieldOpen = false;
        this.venueTypeOptions = [];
        this.selectedLocationType = this.defaultLocationType;
        this.selectedLocationTypeSlug = this.selectedLocationType.slug;
        this.isEditingLocation = false;
        this.poolWorldOptions = [];
        this.currentWebConferences = [];
        this.webConferenceConnections = [];
        this.disabled = false;
        this.showConferenceConnectionsWarningMessage = false;

        this.userContactType$.subscribe((val) => {
            this.userContactType = val;
        });
    }

    ngOnInit() {
        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.poolWorlds && val.poolWorlds.data)).subscribe((val) => {
            this.poolWorldOptions = val.poolWorlds.data;
        });

        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.webConferenceConnections && val.webConferenceConnections.data)).subscribe((val) => {
            this.webConferenceConnections = val.webConferenceConnections.data;
        });

        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.features && val.features.data)).subscribe((val) => {
            const formatted = val.features.data.map(d => PoolFeature.fromApiResponse(d));
            const virbela = formatted.filter(f => f.name === 'Virbela' && f.isEnabled === true);
            this.webConferenceEnabled = formatted.filter(f => f.name === 'Web Conference' && f.isEnabled === true).length > 0;
            if (virbela.length > 0) {
                this.venueTypeOptions = this.getVenueTypes(true);
            } else {
                this.venueTypeOptions = this.getVenueTypes(false);
            }
        });

        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            const event: EfEvent = val.data;
            
            if (event.venue && event.venue.venueType.isPhysical) {
                this.activeAddress = event.venue.address ?? '';
            } else if (event.venue && event.venue.venueType.isVirtual) {
                this.activeAddress = event.venue.url ?? '';
            }

            if (event.venue) {
                this.selectedLocationType = event.venue.venueType;
            } else {
                this.selectedLocationType = this.defaultLocationType;
            }

            this.selectedLocationTypeSlug = this.selectedLocationType.slug;


            this.activeName = event.venue ? event.venue.name : '';
            this.activeDetails = event.venue ? event.venue.description : '';

            if (this.webConferenceEnabled) {
                this.setWebConferenceFields(event);
            }
            
            // Open details field where applicable
            this.detailsFieldOpen = this.activeDetails && this.activeDetails.length > 0;

            const userId = _.get(this.eventFarmService, 'currentUser.id', null);
            const teamId = _.get(this.eventFarmService, 'currentTeam.id', null);
            const eventId = _.get(this.eventFarmService, 'currentEvent.id', null);

            if (userId && teamId && eventId && this.sessionStorageService.get('globalZoomIntegrationsAuth&user=' + userId + '&team=' + teamId + '&event=' + eventId)) {
                this.sessionStorageService.remove('globalZoomIntegrationsAuth&user=' + userId + '&team=' + teamId + '&event=' + eventId);
                this.message.success(`Completing Zoom authentication...`);
            }
        });
    }


    get webConferenceAuthUserEmail(): string {
        if (this.webConferenceAUserEmail) {
            return this.webConferenceAUserEmail;
        }

        return _.get(this.eventFarmService, 'currentEvent.webConferenceAuthUser.primaryEmail', '')
    }

    async setWebConferenceFields(event: EfEvent) {

        if (event.webConferenceAuthUser) {
            this.setCurrentConnection(event.webConferenceAuthUser);
        }
        if (event.webConferenceId) {
            if (this.activeWebConferenceId !== event.webConferenceId) {
                this.verifyWebConferenceSettingsAndLimit();
            }

            this.activeWebConferenceId = event.webConferenceId;
            const conference = this.currentWebConferences.find(web => web.id === this.activeWebConferenceId);
            conference ? this.changeSelectedWebConference(conference) : null;
        } 
        if (event.webConferenceSourceType) {
            this.activeWebConferenceSourceType = event.webConferenceSourceType;
        } else {
            this.activeWebConferenceSourceType = new WebConference().WebConferenceSourceType().find(p => p.isNone);
        }
        if (event.webConferenceFormat.isNone) {
            this.activeWebConferenceFormat = this.formats.find(f => f.isMeeting);
        } else {
            this.activeWebConferenceFormat = event.webConferenceFormat.isWebinar ? this.formats.find(f => f.isWebinar) : this.formats.find(f => f.isMeeting);
        }
    }

    private async verifyWebConferenceSettingsAndLimit() {

        try {
            const warnings = await this.apiClient.getUseCaseFactory().WebConference().VerifyWebConferenceSettingsAndCapacityForEvent(this.eventFarmService.currentEvent.id).toPromise();

            this.showConferenceSettingsWarningMessage = false;
            this.conferenceSettingsMessageArray = [];
            this.conferenceSettingsMessageArray = _.get(warnings, 'data.warnings', []);
            this.showFixSettings = _.get(warnings, 'data.fixSettings', false);
    
            if (this.conferenceSettingsMessageArray.length) {
                this.showConferenceSettingsWarningMessage = true;
            }
        } catch(e) {
            this.handleWebConferenceError(e)
        }
    }

    async setCurrentConnection(authUser?: User) {
        let currentUserConnection = null;
        if (authUser) {
            currentUserConnection = this.webConferenceConnections.find(uc => uc.user.id === authUser.id);
        } else {
            currentUserConnection = this.webConferenceConnections.find(w => w.user.id === this.eventFarmService.currentUser.id);
        }

        this.selectedConferenceConnection = currentUserConnection ? currentUserConnection : this.webConferenceConnections[0];

        if (this.selectedConferenceConnection) {
            try {
                const webinarsEnabled = await this.apiClient.getUseCaseFactory().WebConference().GetWebConferenceSettingsForUser(this.selectedConferenceConnection.user.id, this.eventFarmService.currentEvent.id, this.selectedConferenceConnection.sourceType.slug).toPromise();

                if (webinarsEnabled.data.webinarsEnabled) {
                    this.formats = new WebConference().WebConferenceFormatType().filter(w => w.isNone === false);
                }
            } catch (e) {
                this.handleWebConferenceError(e)
                this.webConferenceAUserEmail = this.selectedConferenceConnection.user.primaryEmail;
                this.selectedConferenceConnection = null;
                this.changeConnectionType('none');
            }
        }
    }

    async updateWebConferenceSettings() {
        const update = await this.alertService.webConferenceIntegration().manageVirtualSettings(this.selectedConferenceConnection, this.webConferenceConnections);
        if (update.value) {
            this.selectedConferenceConnection = update.value.connection;
            await this.getWebConferences();
            if (this.currentWebConferences.length) {
                this.changeSelectedWebConference(this.currentWebConferences[0]);
            } else {
                this.activeName = '';
                this.activeDetails = '';
                this.activeAddress = '';
            }
        }
    }

    async quickCreateWebConference() {
        // if no selected connection, set connection
        if (!this.selectedConferenceConnection) {
            this.setCurrentConnection(this.eventFarmService.currentEvent.webConferenceAuthUser);
        }

        try {
            await this.apiClient.getUseCaseFactory().WebConference().QuickCreateWebConferenceForEvent(this.eventFarmService.currentEvent.id, this.selectedConferenceConnection.user.id, this.activeWebConferenceSourceType.slug, this.activeWebConferenceFormat.slug).toPromise();
            this.message.success('Conference created');
            await this.getWebConferences();

            const eventConference = this.currentWebConferences.find(c => c.isFromCurrentEvent(this.eventFarmService.currentEvent));

            if (eventConference !== undefined) {
                this.changeSelectedWebConference(eventConference);
            } else {
                this.changeSelectedWebConference(this.currentWebConferences[0]);
            }
        } catch (error) {
            this.message.error('Error creating conference');
        }
    }

    async getWebConferences() {
        // if no format set, set to webinar
        if (!this.activeWebConferenceFormat || this.activeWebConferenceFormat.isNone) {
            this.activeWebConferenceFormat = this.formats.find(f => f.isMeeting);
        }

        // if no selected connection, set connection
        if (!this.selectedConferenceConnection) {
            this.setCurrentConnection(this.eventFarmService.currentEvent.webConferenceAuthUser);
        }

        const response = await this.apiClient.getUseCaseFactory().WebConference().ListWebConferencesForAuthUser(this.selectedConferenceConnection.user.id, this.eventFarmService.currentEvent.id, this.selectedConferenceConnection.sourceType.slug, this.activeWebConferenceFormat.slug).toPromise();
        const webConferences = response.data.webConferences.map(w => EFWebConference.fromApiResponse(w));
        this.currentWebConferences = webConferences;

        if (!this.currentWebConferences.length) {
            this.disabled = true;
        }

        if (this.currentWebConferences.length || this.activeWebConferenceSourceType.isNone) {
            this.disabled = false;
        }

    }

    resetActiveFields() {
        this.activeAddress = '';
        this.activeDetails = '';
        this.activeName = '';
        this.activeStreet = '';
        this.activeStreet2 = '';
        this.activeCity = '';
        this.activeState = '';
        this.activeZipCode = '';
        this.activeWebConferenceId = '';
        this.showZoomMessage = false;
        this.activeWebConferenceSourceType = new WebConference().WebConferenceSourceType().find(p => p.isNone);
        this.activeWebConferenceFormat = new WebConference().WebConferenceFormatType().find(p => p.isMeeting);
        this.selectedWebConference = null;
        this.detailsFieldOpen = false;
        this.showConferenceSettingsWarningMessage = false;
        this.conferenceSettingsMessageArray = [];
    }

    hideWarnings() {
        this.warningCaretUp = !this.warningCaretUp;
    }

    async fixSettingWarnings() {
        try {
            await this.apiClient.getUseCaseFactory().WebConference().FixWebConferenceSettingsForEvent(this.eventFarmService.currentEvent.id).toPromise();
            this.message.success('Settings updated');
            await this.verifyWebConferenceSettingsAndLimit();
        } catch (error) {
            this.message.error('Error updating settings');
        }
    }

    restoreActiveFieldsFromCurrentEvent() {
        const venue = this.eventFarmService.currentEvent.venue;

        if (venue && venue.venueType.isPhysical) {
            this.activeAddress = venue.address ?? '';
        } else if (venue && venue.venueType.isVirtual) {
            this.activeAddress = venue.url ?? '';
        }
        
        this.activeDetails = venue ? venue.description : '';
        this.activeName = venue ? venue.name : '';
        this.activeStreet = venue ? venue.street1 : '';
        this.activeStreet2 = venue ? venue.street2 : '';
        this.activeCity = venue ? venue.city : '';
        this.activeState = venue ? venue.province : '';
        this.activeZipCode = venue ? venue.postalCode : '';
        this.selectedLocationType = venue ? venue.venueType : this.defaultLocationType;
        this.selectedLocationTypeSlug = this.selectedLocationType.slug;

        if (venue && venue.description && venue.description.length > 0) {
            this.detailsFieldOpen = true;
        }

        if (this.webConferenceEnabled) {
            this.setWebConferenceFields(this.eventFarmService.currentEvent);
        }
    }

    async changeSelectedLocationType(selection: string) {
        // Set selected location type
        this.selectedLocationTypeSlug = selection;
        this.selectedLocationType = new EFVenue().VenueType().find(vt => vt.slug === selection);
        this.disabled = false;
        this.showZoomMessage = false;


        // If selected location type is the same as the current event, restore the data
        if (selection === _.get(this, 'eventFarmService.currentEvent.venue.venueType.slug')) {
            this.restoreActiveFieldsFromCurrentEvent();
        } else {
            this.resetActiveFields();
        }
        // If virbela is selected and there is no selected pool world, use the first option
        if (this.selectedLocationType.isVirbela && this.selectedPoolWorld === undefined) {
            let targetPoolWorld;
            if (this.poolWorldOptions.length > 0) {
                targetPoolWorld = this.poolWorldOptions[0];
                this.selectedPoolWorld = targetPoolWorld;
                this.activeName = targetPoolWorld.virbelaWorldId;
            } else {
                this.disabled = true;
            }
        } else if (this.selectedLocationType.isVirtual) {
            if (this.selectedConferenceConnection) {
                await this.getWebConferences();
                this.setWebConferenceFields(this.eventFarmService.currentEvent);
            }
        }
    }

    changeSelectedPoolWorld(selection: PoolWorld) {
        this.selectedPoolWorld = selection;
        this.activeName = selection.virbelaWorldId;
    }

    changeSelectedConference($event: EFWebConference) {

        this.changeSelectedWebConference($event);
    }

    changeSelectedWebConference(selection?: EFWebConference|null|undefined) {
        if (selection === undefined) {
            selection = null;
        }
        this.selectedWebConference = selection;
        this.activeName = selection ? selection.title : '';
        this.activeAddress = selection ? selection.joinUrl : '';
        this.activeDetails = '';
    }

    async changeSelectedFormat(selection: WebConferenceFormatTypeInterface) {
        this.activeWebConferenceFormat = selection;
        await this.getWebConferences();
        this.changeSelectedWebConference(this.currentWebConferences[0]);
    }

    getFormatedActiveName() {
        let formattedActiveName;
        if (this.selectedLocationType.isVirbela) {
            formattedActiveName = this.selectedPoolWorld.virbelaWorldId;
        } else {
            formattedActiveName = this.activeName;
        }
        return formattedActiveName;
    }

    getActiveNameForDsiplay() {

        if (this.selectedLocationType.isVirbela && this.poolWorldOptions) {
            if (!this.selectedPoolWorld) {
                const poolWorld = getPoolWorldByVirbelaWorldName(this.poolWorldOptions, this.activeName);
                if (poolWorld) {
                    this.selectedPoolWorld = poolWorld;
                    // So we send virbelaWorldId instead of the text/worldName
                    this.activeName = poolWorld.virbelaWorldId;
                } else {
                    this.selectedPoolWorld = this.poolWorldOptions[0];
                }
            }
            return _.get(this, 'selectedPoolWorld.worldName');
        } else if (this.selectedLocationType.isVirbela && !this.poolWorldOptions) {
            return 'Loading World';
        }

        return this.activeName;
    }

    async cancelEdit() {
        this.showZoomMessage = false;
        this.activeWebConferenceSourceType = new WebConference().WebConferenceSourceType().find(p => p.isNone);
        this.restoreActiveFieldsFromCurrentEvent();
        this.isEditingLocation = !this.isEditingLocation;
        this.disabled = false;
    }

    get shouldShowCustomFields() {
        return !this.disabled && this.selectedWebConference === null && !this.showZoomMessage && this.activeWebConferenceSourceType.isNone;
    }

    async changeConnectionType(value: string) {
        if (value === 'zoom') {
            const currentEventSourceTypeZoom = this.eventFarmService.currentEvent.webConferenceSourceType.isZoom;
            if (currentEventSourceTypeZoom && this.webConferenceConnections.length) {
                await this.getWebConferences();
                this.setWebConferenceFields(this.eventFarmService.currentEvent);
                this.showZoomMessage = false;
            } else if (!currentEventSourceTypeZoom && this.webConferenceConnections.length) {
                this.activeWebConferenceSourceType = new WebConference().WebConferenceSourceType().find(p => p.isZoom);
                await this.getWebConferences();
                this.changeSelectedWebConference(this.currentWebConferences[0]);
                this.showZoomMessage = false;
            } else if (!this.eventFarmService.currentEvent.webConferenceSourceType.isZoom && !this.webConferenceConnections.length) {
                this.activeWebConferenceSourceType = new WebConference().WebConferenceSourceType().find(p => p.isZoom);
                this.disabled = true;
                this.showZoomMessage = true;
            }
        } else if (value === 'none') {
            this.activeWebConferenceSourceType = new WebConference().WebConferenceSourceType().find(p => p.isNone);
            this.changeSelectedWebConference(null);
            this.showZoomMessage = false;
            this.disabled = false;
        }
    }

    async editLocation() {
        // On save
        if (this.isEditingLocation) {
            this.loading = true;
            if (this.webConferenceEnabled) {
                this.showConferenceSettingsWarningMessage = false;
                this.conferenceSettingsMessageArray = [];
                const currentWebConferenceId = this.eventFarmService.currentEvent.webConferenceId;
                if ((!this.selectedWebConference && currentWebConferenceId) || (this.selectedWebConference && this.selectedWebConference.id !== currentWebConferenceId)) {
                    const inviteCount = await this.eventFarmService.fetchGuestCountsForEventOrTicketBlock(this.eventFarmService.currentEvent.id, false);
                    if (inviteCount.allAttending > 0 ) {
                        const confirm = await this.alertService.webConferenceIntegration().changeConference();
                        if (!confirm.value) {
                            return false;
                        }
                    }
                }
            }
            try {
                const eventType = this.selectedLocationType;

                let venueId = '';

                if (this.eventFarmService.currentEvent.venue) {
                    await this.apiClient.getUseCaseFactory().Venue().UpdateVenue(
                        this.eventFarmService.currentEvent.venue.id,
                        this.eventFarmService.currentTeam.id,
                        this.getFormatedActiveName(),
                        eventType.slug,
                        eventType.isPhysical ? this.activeStreet : this.activeStreet,
                        eventType.isPhysical ? this.activeStreet2 : '',
                        eventType.isPhysical ? this.activeCity : '',
                        null,
                        eventType.isPhysical ? this.activeState : '',
                        null,
                        eventType.isPhysical ? this.activeZipCode : '',
                        this.activeDetails,
                        eventType.isVirtual ? this.activeAddress: ''
                    ).toPromise();
                    
                    venueId = this.eventFarmService.currentEvent.venue.id;
                } else {
                    const newVenue = await this.apiClient.getUseCaseFactory().Venue().CreateVenue(
                        this.eventFarmService.currentTeam.id,
                        this.getFormatedActiveName(),
                        eventType.slug,
                        eventType.isPhysical ? this.activeStreet : this.activeStreet,
                        eventType.isPhysical ? this.activeStreet2 : '',
                        eventType.isPhysical ? this.activeCity : '',
                        null,
                        eventType.isPhysical ? this.activeState : '',
                        null,
                        eventType.isPhysical ? this.activeZipCode : '',
                        this.activeDetails,
                        eventType.isVirtual ? this.activeAddress: ''
                    ).toPromise();
    
                    await this.apiClient.getUseCaseFactory().Event().AddVenueToEvent(
                        this.eventFarmService.currentEvent.id,
                        newVenue.data.command.venueId
                    ).toPromise();

                    venueId = newVenue.data.command.venueId;
                }

                const venueResponse = await this.apiClient.getUseCaseFactory().Venue().GetVenue(
                    venueId
                ).toPromise();

                const createdVenue = Venue.fromApiResponse(venueResponse.data);
                this.eventFarmService.currentEvent.venue = createdVenue;

                    // if web conference enabled and a webinar is selected
                if (this.webConferenceEnabled && this.selectedWebConference) {
                    await this.apiClient.getUseCaseFactory().Event().SetWebConferenceAttributesForEvent(this.eventFarmService.currentEvent.id, this.selectedConferenceConnection.sourceType.slug, this.selectedConferenceConnection.user.id, this.selectedWebConference.id, this.activeWebConferenceFormat.slug).toPromise();
                    this.eventFarmService.currentEvent.webConferenceAuthUser = this.selectedConferenceConnection.user;
                    this.eventFarmService.currentEvent.webConferenceId = this.selectedWebConference.id;
                    this.eventFarmService.currentEvent.webConferenceSourceType = this.selectedConferenceConnection.sourceType;
                    this.eventFarmService.currentEvent.webConferenceFormat = this.activeWebConferenceFormat;
                    this.activeWebConferenceId = this.selectedWebConference.id;
                    this.verifyWebConferenceSettingsAndLimit();
                } else if (this.webConferenceEnabled && !this.selectedWebConference) {
                    await this.apiClient.getUseCaseFactory().Event().RemoveWebConferenceAttributesFromEvent(this.eventFarmService.currentEvent.id).toPromise();
                    this.eventFarmService.currentEvent.webConferenceAuthUser = null;
                    this.eventFarmService.currentEvent.webConferenceId = null;
                    this.selectedWebConference = null;
                    this.activeWebConferenceId = null;
                    this.eventFarmService.currentEvent.webConferenceSourceType = new WebConference().WebConferenceSourceType().find(wc => wc.isNone);
                    this.eventFarmService.currentEvent.webConferenceFormat = new WebConference().WebConferenceFormatType().find(wc => wc.isNone);
                }



                // We need to "convert" virbelaWorldId to virbelaWorldName before using setVenueForEvent
                this.activeName = this.selectedLocationType.isVirbela ? this.selectedPoolWorld.worldName : this.activeName;

                // set active address so location map shows
                if (createdVenue && createdVenue.venueType.isPhysical) {
                    this.activeAddress = createdVenue.address ?? '';
                } else if (createdVenue && createdVenue.venueType.isVirtual) {
                    this.activeAddress = createdVenue.url ?? '';
                }

                this.showConferenceConnectionsWarningMessage = false;

                this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                this.isEditingLocation = !this.isEditingLocation;
                this.loading = false;
                this.message.success('Location updated');

            } catch (e) {
                this.message.error('Please try again');
            }
        } else {
            const venue = this.eventFarmService.currentEvent.venue;
            // Will display...
            this.selectedLocationType = venue ? venue.venueType : this.defaultLocationType;
            this.selectedLocationTypeSlug = this.selectedLocationType.slug;
            this.activeName = venue ? venue.name : '';

            if (venue && venue.venueType.isPhysical) {
                this.activeAddress = venue.address ?? '';
            } else if (venue && venue.venueType.isVirtual) {
                this.activeAddress = venue.url ?? '';
            }

            this.activeStreet = venue ? venue.street1 : '';
            this.activeStreet2 = venue ? venue.street2 : '';
            this.activeCity = venue ? venue.city : '';
            this.activeState = venue ? venue.province : '';
            this.activeZipCode = venue ? venue.postalCode : '';
            this.activeDetails = venue ? venue.description : '';

            if (this.selectedLocationType.isVirbela) {
                const poolWorld = getPoolWorldByVirbelaWorldName(this.poolWorldOptions, this.activeName);
                if (poolWorld) {
                    this.selectedPoolWorld = poolWorld;
                    // So we send virbelaWorldId instead of the text/worldName
                    this.activeName = poolWorld.virbelaWorldId;
                } else {
                    this.selectedPoolWorld = this.poolWorldOptions[0];
                }
            }

            if (this.webConferenceEnabled && this.webConferenceConnections.length) {
                await this.setCurrentConnection(this.eventFarmService.currentEvent.webConferenceAuthUser);
                // if you type is other than none/custom, fetch web conferences
                if (this.selectedConferenceConnection && !this.eventFarmService.currentEvent.webConferenceSourceType.isNone) {
                    await this.getWebConferences();
                    this.setWebConferenceFields(this.eventFarmService.currentEvent);
                }
            }
            this.isEditingLocation = !this.isEditingLocation;
        }
    }

    toggleDetailsFieldOpen() {
        this.detailsFieldOpen = !this.detailsFieldOpen;
    }

    generateVenueDirectionsUrl() {
        const venue = this.eventFarmService.currentEvent.venue;
        if (this.eventFarmService.currentEvent.mapSourceType && venue && venue.address) {
            if (this.eventFarmService.currentEvent.mapSourceType === 'Google') {
                return 'https://maps.google.com/maps?f=d&hl=en&geocode=&saddr=' + encodeURI(venue.address);
            } else {
                return 'https://www.bing.com/maps/default.aspx?where1=' + encodeURI(venue.address);
            }
        }
    }

    generateVenueMapImageWithLink() {
        const venue = this.eventFarmService.currentEvent.venue;
        if (!venue && !venue.address) {
            return '';
        } else {
            if (_.get(this.eventFarmService, 'currentEvent.mapSourceType') && _.get(this.eventFarmService, 'currentEvent.venue.address')) {
                if (this.eventFarmService.currentEvent.mapSourceType === 'Google') {
                    return '<a target="_blank" href="' + this.generateVenueDirectionsUrl() + '" target="_blank"><img class="img-responsive" src="https://maps.googleapis.com/maps/api/staticmap?size=250x150&key=AIzaSyCLMQ6znviX6lDJT86vz_2m1NdZlP52vrg&markers=size:medium|color:blue|' + encodeURI(this.eventFarmService.currentEvent.venue.address) + '"></a>';
                } else {
                    return '<a target="_blank" href="' + this.generateVenueDirectionsUrl() + '" target="_blank"><img class="img-responsive" src="https://dev.virtualearth.net/REST/V1/Imagery/Map/Road/' + encodeURI(this.eventFarmService.currentEvent.venue.address) + '?mapSize=250,150&format=png&key=AoV_btwL_n-Xcmw6WK7xdyvAxYzbVRftHGeMETv1UkzunEP42SZNsBOUN3mk3cQn"></a>';
                }
            }
        }
    }

    private getVenueTypes(includeVirbela: boolean) : VenueTypeInterface[] {
        let options = new EFVenue().VenueType().filter(vt => !vt.isOther && !vt.isVirbela);

        if (includeVirbela) {
            options = new EFVenue().VenueType().filter(vt => !vt.isOther);
        } 
        return options.map(op => {
            if (op.isPhysical) {
                op['name'] = 'In-Person'
            } else if (op.isVirbela) {
                op['name'] = 'The Echo'
            }
            return op;
        });
    }

    private async handleWebConferenceError(e) {
        this.showConferenceConnectionsWarningMessage = true;
    }

    private async fixConnection() {
        this.eventFarmService.connectToZoom(true);
        this.showConferenceConnectionsWarningMessage = false;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }
}
