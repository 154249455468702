import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { ReversePipe } from './reverse.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { SafeStylePipe } from './safeStyle.pipe';
import { SafeScriptPipe } from './safeScript.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { MomentPipe } from './moment.pipe';
import { FilterPipe } from './filter.pipe';
import { ClickOutsideDirective } from './../EventFarm/CoreUI/Directives/click-outside.directive';

const forExport = [
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    SafeScriptPipe,
    KeysPipe,
    ReversePipe,
    MomentPipe,
    FilterPipe,
    ClickOutsideDirective
];

@NgModule({
    declarations: forExport,
    exports: forExport,
})

export class UtilitiesModule {
}
