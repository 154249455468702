import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Modal } from './modal.class';

@Injectable()

export class ModalService {
    private changeModalSource = new Subject<Modal>();
    private changeModalTitleSource = new Subject<string>();
    private closeModalSource = new Subject<void>();
    private showModalSource = new Subject<void>();
    public changeModal$ = this.changeModalSource.asObservable();
    public changeModalTitle$ = this.changeModalTitleSource.asObservable();
    public closeModal$ = this.closeModalSource.asObservable();
    public showModal$ = this.showModalSource.asObservable();

    public data: any;

    public changeModal(modal: Modal) {
        this.data = modal.data;
        this.changeModalSource.next(modal);
    }

    public changeModalTitle(newTitle: string) {
        this.changeModalTitleSource.next(newTitle);
    }

    public showModal() {
        this.showModalSource.next();
    }

    public closeModal() {
        this.data = null;
        this.closeModalSource.next();
    }
}
