import { Stack } from './../../../../ApiClient/Models/Stack/stack';

export const handleCreateStackSuccess = (state, action) => {
    return {
        ...state
    };
};

export const handleUpdateStackSuccess = (state, action) => {
    const updatedStack: Stack = action.payload;
    return {
        ...state,
        stacks: {
            ...state.stacks,
            data: {
                ...state.stacks.data,
                [updatedStack['id']]: Object.assign(state.stacks.data[updatedStack['id']], { ...updatedStack }),
            },
            loading: false
        }
    };
};

export const handleDeleteStackSuccess = (state, action) => {
    const stackId = action.payload.data.command.stackId;

    delete state.stacks.data[stackId];

    return {
        ...state,
        stacks: {
            ...state.stacks,
            data: state.stacks.data
        }
    };
};

export const handleDeleteTicketTypeSuccess = (state, action) => {
    const ticketTypeId = action.payload.data.command.ticketTypeId;

    for (let i = 0; i < state.ticketTypes.data[ticketTypeId].stacks.length; i++) {
        const stackId = state.ticketTypes.data[ticketTypeId].stacks[i].id;
        delete state.stacks.data[stackId];
    }

    delete state.ticketTypes.data[ticketTypeId];

    return {
        ...state,
        ticketTypes: {
            ...state.ticketTypes,
            data: state.ticketTypes.data
        },
        stacks: {
            ...state.stacks,
            data: state.stacks.data
        }
    };
};
