import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'moment'})
export class MomentPipe implements PipeTransform {
    transform(value, args: string): any {
        args ? args : 'MM/DD/YYYY';
        return moment.unix(value).format(args);
    }
}
