export const sortFormat = (sortOrder: string) => {
    switch (sortOrder) {
        case 'ascend':
            return 'ascending';
        case 'descend':
            return 'descending';
        default:
            return null;
            break;
    }
};
