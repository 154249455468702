import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EventFarmService} from '../../../../eventFarm.service';
import {EventFarmAPIClient} from '../../../../../ApiClient/event-farm-api-client';
import {AdminService} from '../../../admin.service';
import {AlertService} from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Team} from '../../../../../ApiClient/Models/Pool/team';
import {TeamContractDetailsService} from './team-contract-details.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as adminActions from '../../../../store/actions/admin/';
import _ = require('lodash');
import {PoolContract} from '../../../../../ApiClient/Models/PoolContract/poolContract';
import * as fromRoot from '../../../../store';
import {shareReplay} from 'rxjs/operators';

@Component({
    selector: 'team-contract-details',
    template: require('./team-contract-details.html'),
    styles: [require('./team-contract.scss')],
})
export class TeamContractDetailsComponent implements OnInit, OnDestroy {
    @Input() team: Team;
    contractForm: FormGroup;
    poolContractTypes: any;
    private poolContractId: string;
    private currentTeamContract$: Subscription;

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private alertService: AlertService,
        private message: NzMessageService,
        private adminService: AdminService,
        private teamContractDetailsService: TeamContractDetailsService,
        private store: Store,
        private formBuilder: FormBuilder
    ) {
    }

    async ngOnInit() {
        this.contractForm = this.formBuilder.group({
            contract: [{startDate: new Date(), endDate: new Date(), contractType: 'cio'}]
        });
        this.poolContractTypes = this.apiClient
            .getTypeFactory()
            .Pool()
            .PoolContractType();

        this.currentTeamContract$ = this.store.select(fromRoot.getAdminCurrentTeamContract).pipe(shareReplay()).subscribe((val) => {
            const obj = _.get(val, 'data');
            if (!obj) {
                return;
            }
            const pc = PoolContract.fromApiResponse(obj);
            this.poolContractId = pc.id;
            this.contractForm.controls['contract'].patchValue({
                startDate: pc.startDate.date,
                endDate: pc.endDate.date,
                contractType: pc.contractType
            });
        });
    }

     savePoolContract() {
        if (this.poolContractId) {
            this.updatePoolContract();
        }
    }

    updatePoolContract() {
        this.store.dispatch(new adminActions.UpdateContractForAdminTeam({
            contractId: this.poolContractId,
            contract: this.contractForm.get('contract').value
        }));
    }

    ngOnDestroy() {
        this.currentTeamContract$.unsubscribe();
    }
}
