import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'distribution-info',
    template: require('./distribution-info.html'),
})

export class DistributionInfoComponent {
    @Input() parentForm: FormGroup;
}
