import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { FormControl } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { FormattedDateTime } from '../../../../../../ApiClient/Models/formatted-datetime';
import {dateTimeFormat, momentShortDateTime, timePickerDefaults} from '../../../../../../Shared/datetime-options';
import { ChildEventsService } from '../../../child-events.service';
import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';

interface AccessTypeSelect {
  label: string;
  value: string;
}

@Component({
  selector: 'edit-child',
  template: require('./edit-child.html'),
  styles: [require('./edit-child.scss')]
})
export class EditChildComponent implements OnInit {

  @Input() child: EfEvent;

  private name: FormControl = new FormControl();
  private startDate: Date;
  private endDate: Date;
  private description: FormControl = new FormControl();
  private locationName: FormControl = new FormControl();
  private locationAddress: FormControl = new FormControl();

  private disabledEndDate;
  private eventTimezone$ = new BehaviorSubject<string>('America/New_York');
  private createChild: boolean;
  private isSaving: boolean = false;
  private confirmDelete: boolean = false;
  private isDeleting: boolean = false;
  private dateTimeFormat = dateTimeFormat;
  private parentEventStartTime: FormattedDateTime;
  private parentEventEndTime: FormattedDateTime;
  private currentTime = new Date().getTime() / 1000;
  private timePickerDefaults = timePickerDefaults;

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private childEventsService: ChildEventsService,
    private segmentService: SegmentService,

  ) { }

  ngOnInit() {
    this.initStoreSubscriptions();
    this.setInitialFields();
    this.setValidEndDate();
  }

  private async saveChild() {

    if (this.isSaving === false) {
      this.isSaving = true;

      /**
       * Grab type, amount, and maximum from form
       */

      const childSaveSuccess = await this.childEventsService.createOrUpdateChildEvent({
        eventName: this.name.value,
        startTime: this.startDate.toLocaleString('en-US', { hour12: false }),
        endTime: this.endDate.toLocaleString('en-US', { hour12: false }),
        description: this.description.value,
        locationName: this.locationName.value ? this.locationName.value : '',
        locationAddress: this.locationAddress.value ? this.locationAddress.value : '',
        eventId: this.child.id
      });

      if (childSaveSuccess) {
        this.isSaving = false;
        this.drawerRef.close();
        this.childEventsService.childEventsOptions.pagination.currentPage = 1;
        await this.childEventsService.getChildEvents();
      } else {
        this.isSaving = false;
      }

    }
  }

  private async handleDeleteChild() {
    if (this.isDeleting) {
      this.isDeleting = false;
    }
    if (this.confirmDelete) {
      this.isDeleting = true;

      const deleteSuccess = await this.childEventsService.deleteEvent(this.child.id);

      this.isDeleting = false;
      this.confirmDelete = false;

      if (deleteSuccess === true) {
        this.drawerRef.close();

        if (this.childEventsService.childrenCount - 1 === 0) {
          const currentPage = this.childEventsService.childEventsOptions.pagination.currentPage;
          if (currentPage > 1) {
            this.childEventsService.childEventsOptions.pagination.currentPage = currentPage - 1;
          }
        }

        await this.childEventsService.getChildEvents();
      }
    }
    this.confirmDelete = true;
  }

  private allowSave() {
    return this.name.value !== '' && this.startDate && this.endDate;
  }

  private updateValidEndDate() {
    if (this.startDate > this.endDate) {
      this.endDate = new Date(this.startDate.getTime() + 10800000);
    }
    this.setValidEndDate();
  }

  private setValidEndDate() {
    this.disabledEndDate = (current: Date): boolean => {
      return current < this.startDate;
    };
  }

  /**
   * Setup initial values and subscriptions
   */
  private setInitialFields() {

    this.isSaving = false;

    if (!this.child) {
      this.createChild = true;
      this.child = EfEvent.forChildCreation({
          name: '',
          description: '',
          locationAddress: '',
          locationName: '',
          startTime: {},
          endTime: {}
        });
    }

    if (!this.child.startTime.date) {
      const start = `${this.parentEventStartTime.date.short} ${this.parentEventStartTime.time.short}`;
      this.startDate = moment(start, momentShortDateTime).toDate();
    } else {
      const start = `${this.child.startTime.date.short} ${this.child.startTime.time.short}`;
      this.startDate = moment(start, momentShortDateTime).toDate();
    }

    if (!this.child.endTime.date) {
      // const close = `${this.parentEventEndTime.date.short} ${this.parentEventEndTime.time.short}`;
      // this.endDate = moment(close, momentShortDateTime).toDate();

      this.endDate = new Date(this.startDate.getTime() + 10800000);

    } else {
      const close = `${this.child.endTime.date.short} ${this.child.endTime.time.short}`;
      this.endDate = moment(close, momentShortDateTime).toDate();
    }

    this.name.setValue(this.child.name ? this.child.name : '');
    this.description.setValue(this.child.description ? this.child.description : '');
    this.locationName.setValue(this.child.venue ? this.child.venue.name : '');
    this.locationAddress.setValue(this.child.venue ? this.child.venue.address : '');

  }

  private initStoreSubscriptions() {
    // this.segmentService.segmentPromotionsTracking().openEdit();
    this.store.select('event').pipe(
      filter(res => !!res),
      map(res => res.data)
    ).subscribe((res: EfEvent) => {
      if (res) {
        this.eventTimezone$.next(res.timezone);
        this.parentEventStartTime = res.startTime;
        this.parentEventEndTime = res.endTime;
      }
    });
  }

  close(): void {
    this.drawerRef.close();
  }
}
