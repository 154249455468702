import { Component, OnDestroy, OnInit, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { Observable , Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { EfEvent } from '../../../../../ApiClient/Models/Event/event';
import { UserField, UserFields } from '../../../../../ApiClient/Models/Event/user-field';
import { EventFarmService } from '../../../../eventFarm.service';
import { QuestionCenterService } from '../../question-center.service';
import { Question } from '../../../../../ApiClient/Models/Question/question';
import { Answer } from '../../../../../ApiClient/Models/Answer/answer';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'preview-window',
    styles: [
        require('./preview-window.scss'),
    ],
    template: require('./preview-window.html'),
})

export class PreviewWindowComponent implements OnInit, OnDestroy {
    constructor(
        private questionCenterService: QuestionCenterService,
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.event = val.data;
            this.userFields = this.event.userFields;
            this.makeFormGroup();
        });
    }

    private event: EfEvent;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private allUserFields: UserField[] = UserFields.all;
    private userFields: UserField[] = [];
    private eventUpdate$: Subscription;
    private customFormGroup = {};
    private activeAnswerIds = {};
    public salutations = [{id: 0, text: 'Mr.'}, {id: 1, text: 'Ms.'}, {id: 2, text: 'Mrs.'}];
    public countries = [{id: 'US', text: 'United States'}, {id: 'AF', text: 'Afghanistan'}, {
        id: 'AL',
        text: 'Albania',
    }];

    private makeFormGroup() {
        const formGroupOptions = {};

        for (const question of this.questionCenterService.enabledQuestions$.value) {
            if (question.isAnswerBindingCompatible) {
                const formControl = new FormControl();
                formControl.valueChanges.subscribe((answerId) => this.updateActiveAnswerIds(answerId, question.id));
                const defaultAnswer = question.answers.find(a => a.isDefault);
                if (defaultAnswer) {
                    formControl.setValue(defaultAnswer.id)
                }
                formGroupOptions[question.id] = formControl;
            }
        }

        this.customFormGroup = formGroupOptions;
    }


    private shouldShowQuestion(question: Question) {
        if (question.isHidden) {
            return false;
        }

        if (!this.eventFarmService.questionLogicEnabled) {
            return true;
        }

        if (question.hasLogic) {
            const answerBinding = question.answerBindings[0];
            const answerId = answerBinding.answer.id;
            const answerQuestionId = answerBinding.answer.question.id;
            const activeAnswerIds: string[] = this.activeAnswerIds[answerQuestionId] ?? [];
            const answerIncluded = activeAnswerIds.includes(answerId);
            if (answerBinding.answerBindingType.isEqualTo) {
                if (answerIncluded) {
                    return true;
                } 
            } else if (answerBinding.answerBindingType.isNotEqualTo) {
                if (!answerIncluded) {
                    return true;
                } 
            } 
            return false;
        } else {
            return true;
        }
    }

    private async updateActiveAnswerIds(answerId: string, questionId: string) {
        this.activeAnswerIds[questionId] = [answerId];
    }

    private checkboxAnswerChanged(question: Question, answerIds: string[]) {
        this.activeAnswerIds[question.id] = answerIds;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
        this.customFormGroup = {};
        this.activeAnswerIds = {};
    }
}
