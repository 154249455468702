import { TicketBlockResolver } from './ticket-block-resolver.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketBlockRoutingModule } from './ticket-block-routing-module';
import { TicketBlocksComponent } from './ticket-blocks.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { TicketBlockDashboardCoreRoutesComponent } from './ticket-block-dashboard-core-routes.component';
import { UtilitiesModule } from '../../Utilities/utilities.module';
import {NgxUtilitiesModule} from '../../NGXUtilities/ngx.utilities.module';

const mainModules: any[] = [
    CommonModule,
];

const ticketBlocksModules: any[] = [
    TicketBlockRoutingModule,
];

const ngxBootstrapModules: any[] = [
    UtilitiesModule,
    NgxUtilitiesModule
];

const ngxOtherModules: any[] = [
    NgxChartsModule,
];

@NgModule({
    imports: mainModules.concat(ngxBootstrapModules, ngxOtherModules, ticketBlocksModules),
    declarations: [
        TicketBlocksComponent,
        TicketBlockDashboardCoreRoutesComponent
    ],
    providers: [
        TicketBlockResolver
    ]
})
export class TicketBlockModule {
}