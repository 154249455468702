import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Operator } from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';
import { TicketBlockDashboardService } from '../ticketBlockDashboard.service';
import { EventFarmService } from '../../../eventFarm.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { LoadingOverlayComponent } from '../../../CoreUI/LoadingOverlay/loading-overlay.component';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

@Component({
    selector: 'invite-add-guest',
    template: require('./inviteAddGuestToTicketBlock.html'),
    styles: [require('./inviteAddGuestToTicketBlock.scss')],
})

export class InviteAddGuestComponent implements OnInit {
    readonly inviteGroupSVG = require('./assets/InviteGroup-Icon');
    readonly uploadGroupSVG = require('./assets/UploadGroup-Icon');
    readonly inviteIndividualSVG = require('./assets/InviteIndividual-Icon');

    private ticketTypeCount: Observable<number>;

    constructor(
        private alertService: AlertService,
        private ticketBlockDashboardService: TicketBlockDashboardService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private segmentService: SegmentService,
    ) {
    }

    ngOnInit() {
        this.segmentService.segmentGuestListTracking().invite();
    }

    goToIndividualTb() {
        const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.invite.individual', {eventId: this.eventFarmService.currentEvent.id});
        this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + this.ticketBlockDashboardService.ticketBlock.id);
    }

    goToGroupTb() {
        const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.invite.group', {eventId: this.eventFarmService.currentEvent.id});
        this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + this.ticketBlockDashboardService.ticketBlock.id);
    }

    goToInviteAddTbList() {
        const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.list', {eventId: this.eventFarmService.currentEvent.id});
        this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + this.ticketBlockDashboardService.ticketBlock.id);
    }

    private goToUploader() {
        let eventId =  this.eventFarmService.currentEvent.id;
        let poolId = this.eventFarmService.currentEvent.team.id;
        let ticketBlockId = this.ticketBlockDashboardService.ticketBlock.id;
        this.router.navigateByUrl(this.routeGenerator.url('pools.uploader', { eventId, poolId, ticketBlockId }));
    }
 }
