import { Injectable } from '@angular/core';
import { SalesforceEventIntegrationsService } from './eventIntegrations.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { SessionStorageService } from '../../../../_services/storage/session-storage.service';
import * as _ from 'lodash';

@Injectable()

export class MappingService {

    pool: Object = this.localStorageService.get('pool');
    event: Object = this.localStorageService.get('event');
    salesforceId = this._sfeis.eventSettings.salesforceConfig.id;

    efImportLeadFields: Object[] = [];
    efExportLeadFields: Object[] = [];
    intImportLeadFields: Object[] = [];
    intExportLeadFields: Object[] = [];

    efImportContactFields: Object[] = [];
    efExportContactFields: Object[] = [];
    intImportContactFields: Object[] = [];
    intExportContactFields: Object[] = [];

    customLeadFieldsToImport: Object[] = [];
    customLeadFieldsToExport: Object[] = [];
    customContactFieldsToImport: Object[] = [];
    customContactFieldsToExport: Object[] = [];

    leadImportMappings: Object[] = [
        { attributes: { fieldName: 'First Name', integrationFieldValue: 'First Name' } },
        { attributes: { fieldName: 'Last Name', integrationFieldValue: 'Last Name' } },
        { attributes: { fieldName: 'Email', integrationFieldValue: 'Email' } },
        { attributes: { fieldName: 'Company', integrationFieldValue: 'Company' } },
    ];
    leadExportMappings: Object[] = [];

    contactImportMappings: Object[] = [
        { attributes: { fieldName: 'First Name', integrationFieldValue: 'First Name' } },
        { attributes: { fieldName: 'Last Name', integrationFieldValue: 'Last Name' } },
        { attributes: { fieldName: 'Email', integrationFieldValue: 'Email' } },
    ];
    contactExportMappings: Object[] = [];

    campaignMemberStatusMappings: Object[] = [];

    newContactRules: Object[] = this._sfeis.eventSettings.fieldMappings.newContactRules;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private localStorageService: SessionStorageService,
        private _sfeis: SalesforceEventIntegrationsService,
    ) {
        if (this.eventFarmService.currentEvent) {
            this.initializeMappingService();
        }
    }


    public initializeMappingService() {
        this.getSalesforceFields();

        // SETUP ARRAY OF EF STATUS NAMES
        const invitationStatusTypes = this.apiClient.getTypeFactory()
            .Invitation()
            .InvitationStatusType();

        for (const invitationStatusType of invitationStatusTypes) {
            if (!invitationStatusType.isRecycled) {
                this.campaignMemberStatusMappings.push({ ef: { attributes: { name: invitationStatusType.name } }, int: '' });
            }
        }

        // SETUP ARRAY OF EF FIELDS
        const customFieldTypes = this.apiClient.getTypeFactory()
            .Integration()
            .UserAttributeInfoFieldType();

            
        for (const customFieldType of customFieldTypes) {
            let fieldIdPrefix = 'info.';

            if (customFieldType.slug === 'user-email-unsubscribe' || customFieldType.slug === 'newCustomField') {
                fieldIdPrefix = 'salesforce.'
            } 

            const t = {
                attributes: {
                    slug: customFieldType.slug,
                    fieldName: customFieldType.name,
                    fieldId: fieldIdPrefix + customFieldType.slug,
                }, selected: false,
            };
        
            if (customFieldType.slug === 'user-email-unsubscribe') {
                this.efImportContactFields.push(t);
            } else if (customFieldType.slug === 'newCustomField') {
                this.efImportLeadFields.push(t);
                this.efImportContactFields.push(t);
            } else {
                this.efImportLeadFields.push(t);
                this.efExportLeadFields.push(t);
                this.efImportContactFields.push(t);
                this.efExportContactFields.push(t);
            }
        }

        // New Contact Rule Types
        const newContactRules = this.apiClient.getTypeFactory()
            .Salesforce()
            .NewContactRuleType();

        for (const newContactRule of newContactRules) {
            this.newContactRules.push(newContactRule);
        }
    }

    private getPoolId(): string {
        const poolId = _.get(this, 'eventFarmService.currentTeam.id') || _.get(this, 'eventFarmService.currentEvent.team.id');
        return poolId;
    }

    getUpdateRules() {
        return this.apiClient.getTypeFactory()
            .IntegrationFieldMapping()
            .UpdateRuleType();
    }

    getSalesforceFields() {
        this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ListSalesforceContactFieldsForPool(this.getPoolId())
            .toPromise()
            .then((res) => {
                if (!res.data) {
                    throw new Error('Salesforce connection failed for Contact Fields');
                }
                this.intImportContactFields = res['data']['attributes'].map((field) => {
                    return {
                        attributes: {
                            fieldName: field.label,
                            fieldId: field.name,
                            updateable: true,
                        },
                    };
                });
            });

        this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ListSalesforceLeadFieldsForPool(this.getPoolId())
            .toPromise()
            .then((res) => {
                if (!res.data) {
                    throw new Error('Salesforce connection failed for Lead Fields');
                }
                this.intImportLeadFields = res['data']['attributes'].map((field) => {
                    return {
                        attributes: {
                            fieldName: field.label,
                            fieldId: field.name,
                            canUpdateIntegrationField: field.isUpdateable,
                        },
                    };
                });
            });

        this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ListSalesforceContactFieldsForPool(this.getPoolId())
            .toPromise()
            .then((res) => {
                if (!res.data) {
                    throw new Error('Salesforce connection failed for Contact Fields');
                }
                this.intExportContactFields = res['data']['attributes'].map((field) => {
                    return {
                        attributes: {
                            fieldName: field.label,
                            fieldId: field.name,
                            updateable: true,
                        },
                    };
                });
            });

        this.apiClient.getUseCaseFactory()
            .Salesforce()
            .ListSalesforceLeadFieldsForPool(this.getPoolId())
            .toPromise()
            .then((res) => {
                if (!res.data) {
                    throw new Error('Salesforce connection failed for Lead Fields');
                }
                this.intExportLeadFields = res['data']['attributes'].map((field) => {
                    return {
                        attributes: {
                            fieldName: field.label,
                            fieldId: field.name,
                            canUpdateIntegrationField: field.isUpdateable,
                        },
                    };
                });
            });
    }

    // SAVE AND CHECK THE EF CUSTOM FIELD

    saveAndCheckForCustomField(row, type) {
        if (row.ef['attributes'].fieldName && row.int['attributes'].fieldName && row.ef.attributes.fieldName.attributes.slug === 'newCustomField') {
            row.ef.attributes.fieldName.attributes.fieldId = 'salesforce.' + row.int.attributes.fieldName.attributes.fieldId;
            row.ef.attributes.fieldName.attributes.fieldName = row.int.attributes.fieldName.attributes.fieldName;
            this.saveNewField(row, type);
        } else {
            this.saveNewField(row, type);
        }
    }

    // SAVE ON THE FLY

    saveNewField(row, type) {

        if (row.ef['attributes'].fieldName && row.int['attributes'].fieldName) {

            switch (type) {
                case 'import-lead':
                    this.saveMappings(row)
                        .then(() => {
                            this._sfeis.getImportLeadFieldMappings(this._sfeis.eventSettings.salesforceConfig.id).subscribe((res) => {
                                const i = this.customLeadFieldsToImport.indexOf(row);
                                this.customLeadFieldsToImport.splice(i, 1);
                                this.leadImportMappings = res['data'];
                            });
                        });
                    break;
                case 'export-lead':
                    if (row.updateRule) {
                        this.saveMappings(row)
                            .then(() => {
                                this._sfeis.getExportLeadFieldMappings(this._sfeis.eventSettings.salesforceConfig.id).subscribe((res) => {
                                    const i = this.customLeadFieldsToExport.indexOf(row);
                                    this.customLeadFieldsToExport.splice(i, 1);
                                    this.leadExportMappings = res['data'];
                                });
                            });
                    }
                    break;
                case 'import-contact':
                    this.saveMappings(row)
                        .then(() => {
                            this._sfeis.getImportContactFieldMappings(this._sfeis.eventSettings.salesforceConfig.id).subscribe((res) => {
                                const i = this.customContactFieldsToImport.indexOf(row);
                                this.customContactFieldsToImport.splice(i, 1);
                                this.contactImportMappings = res['data'];
                            });
                        });
                    break;
                case 'export-contact':
                    if (row.updateRule) {
                        this.saveMappings(row)
                            .then(() => {
                                this._sfeis.getExportContactFieldMappings(this._sfeis.eventSettings.salesforceConfig.id).subscribe((res) => {
                                    const i = this.customContactFieldsToExport.indexOf(row);
                                    this.customContactFieldsToExport.splice(i, 1);
                                    this.contactExportMappings = res['data'];
                                });
                            });
                    }
                    break;
                default:
            }
        }
    }

    saveMappings(mapping) {
        this._sfeis.eventSettings.isSyncing = true;
        return this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .CreateIntegrationFieldMapping(
                // Field Mapping Type
                mapping.fieldMappingType,
                // Integration Setting Type
                mapping.integrationSettingType,
                // Integration Setting Id
                this._sfeis.eventSettings.salesforceConfig['id'],
                // Field Type
                'user-attribute',
                // Field Id
                mapping.ef.attributes.fieldName.attributes.fieldId,
                // Integration Field Value
                mapping.int.attributes.fieldName.attributes.fieldId,
                // Can Update Event Farm Field
                true,
                // Can Update Integtration Field
                true,
                // Can Delete Mapping
                true,
                // Update Rule
                mapping.updateRule || 'never',
                // Field Name
                mapping.ef.attributes.fieldName.attributes.fieldName,
            )
            .toPromise()
            .then((res) => {
                this._sfeis.syncingFeedback();
            })
            .catch((err) => {
                this._sfeis.syncingFeedback();
            });
    }

    setSalesforceNewContactRuleForEvent(newContactRuleSlug) {
        this._sfeis.eventSettings.isSyncing = true;
        this.apiClient.getUseCaseFactory()
            .SalesforceEventSetting()
            .SetNewContactRuleForSalesforceEventSetting(this._sfeis.eventSettings.salesforceConfig.id, newContactRuleSlug)
            .toPromise()
            .then((res) => {
                this._sfeis.syncingFeedback();
            });
    }

    setIntegrationStatusMappingValue(integrationStatusMappingId, integrationStatusValue) {
        this._sfeis.eventSettings.isSyncing = true;
        this.apiClient.getUseCaseFactory()
            .IntegrationStatusMapping()
            .SetIntegrationStatusMappingValue(integrationStatusMappingId, integrationStatusValue)
            .toPromise()
            .then((res) => {
                this._sfeis.syncingFeedback();
            });
    }

    updateRule(integrationFieldMappingId, updateRule) {
        this._sfeis.eventSettings.isSyncing = true;
        this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .SetIntegrationFieldMappingUpdateRule(integrationFieldMappingId, updateRule)
            .toPromise()
            .then(() => {
                this._sfeis.syncingFeedback();
            });
    }

    updateEfField(field) {
        this._sfeis.eventSettings.isSyncing = true;
        this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .SetIntegrationFieldMappingFields(field.id, field.attributes.fieldType.slug, field.attributes.fieldId, field.attributes.fieldName)
            .toPromise()
            .then(() => {
                this._sfeis.syncingFeedback();
            });
    }

    updateIntField(field) {
        this._sfeis.eventSettings.isSyncing = true;
        this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .SetIntegrationFieldMappingName(field.id, field.attributes.integrationFieldValue)
            .toPromise()
            .then(() => {
                this._sfeis.syncingFeedback();
            });
    }

    deleteMapping(mapping) {
        this._sfeis.eventSettings.isSyncing = true;
        this.apiClient.getUseCaseFactory()
            .IntegrationFieldMapping()
            .DeleteIntegrationFieldMapping(mapping.id)
            .toPromise()
            .then((res) => {
                this._sfeis.syncingFeedback();
            });

    }

    updateEventFarmFields(efContactFields, customFieldType): any {
        if (customFieldType === 'import-contact') {
            if (efContactFields.findIndex((field) => this.checkForCustomField(field)) === -1) {
                efContactFields.push({
                    attributes: {
                        slug: 'newCustomField',
                        fieldName: 'New Custom Field',
                        fieldId: 'salesforce.newCustomField',
                    }, selected: false,
                });
            }
        }

        if (customFieldType === 'import-lead') {
            if (efContactFields.findIndex((field) => this.checkForCustomField(field)) === -1) {
                efContactFields.push({
                    attributes: {
                        slug: 'newCustomField',
                        fieldName: 'New Custom Field',
                        fieldId: 'salesforce.newCustomField',
                    }, selected: false,
                });
            }
        }

        return efContactFields;
    }

    checkForCustomField(efField) {
        return (efField.attributes.fieldName === 'New Custom Field');
    }

    removingCustomField(efImportContactFields, row): any {
        const fieldIndex = efImportContactFields.findIndex((field) => this.checkForExitingCustomField(field, row));
        if (fieldIndex > -1) {
            efImportContactFields.splice(fieldIndex, 1);
        }
        return efImportContactFields;
    }

    findCustomField(value) {
        return this.efImportContactFields.findIndex((field: any) => field.attributes.fieldId === value);
    }

    checkForExitingCustomField(field, row) {
        return ( field.attributes.slug === 'newCustomField' && field.attributes.fieldId === row.attributes.fieldId);
    }
}
