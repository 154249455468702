import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

import { Observable ,  Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import {
    CioGetStartedStepperService
} from '../../../CoreUI/Modal/Modals/CioGetStarted/CioGetStartedStepper/cio-get-started-stepper.service';

@Component({
    selector: 'guest-list-management',
    template: require('./guest-list-management.html'),
    styles: [require('./guest-list-management.scss')],
})

export class GuestListManagementComponent implements OnDestroy {
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventUpdate$: Subscription;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private message: NzMessageService,
        private route: ActivatedRoute,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private cioGetStartedStepperService: CioGetStartedStepperService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            //   this.baseUrl();
            //   this.eventUrl();
        });
    }

    goToUploader() {
        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentEvent.team.id;
        this.router.navigateByUrl(this.routeGenerator.url('pools.uploader', { eventId, poolId }));
    }

    goToGuestList() {
        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentEvent.team.id;
        this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { eventId, poolId }));
    }

    ngOnDestroy() {
      this.eventUpdate$.unsubscribe();
    }

    launchCioModal() {
        if (this.eventFarmService.currentEvent.isTypeCio) {
            if (this.eventFarmService.healthPassEnabled) {
                this.cioGetStartedStepperService.launchCioPlusClearModal();
            } else {
                this.cioGetStartedStepperService.launchCioOnlyModal();
            }
        }
    }
}
