import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import * as eventActions from '../../store/actions/event';
import { map } from 'rxjs/operators';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';

import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { SessionStorageService } from '../../../_services/storage/session-storage.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';

@Injectable()
export class ChildEventsService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private segmentService: SegmentService,
        private sessionStorageService: SessionStorageService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
    ) {
    }

    public childEventsOptions: ChildEventsListOptions = {
        query: null,
        sortBy:  'event-start',
        sortDirection: 'ascending',
        attributesExcludeFilter: ['archived'],
        pagination: {
            currentPage: 1,
            itemsPerPage: 20,
        }
    };

    public childEventsMetaSource = new BehaviorSubject<any>({});
    public childEventsMeta$ = this.childEventsMetaSource.asObservable();
    public readonly currentChildEvents$ = new BehaviorSubject<EfEvent[]>([]);

    public isLoadingChildEvents: boolean = false;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    public clearList() {
        this.currentChildEvents$.next([]);
        this.childEventsMetaSource.next(null);
        this.childEventsOptions.pagination.currentPage = 1;
    }

    get childrenCount(): number {
        return this.currentChildEvents$.value.length;
    }

    public async getChildEvents() {

        this.isLoadingChildEvents = true;
        const eventId = this.eventFarmService.currentEvent.id;

        try {
            const chilldEvents = await this.apiClient.getUseCaseFactory()
            .Event()
            .ListChildrenForEvent(
                eventId,
                this.childEventsOptions.query,
                null,
                this.childEventsOptions.pagination.currentPage,
                this.childEventsOptions.pagination.itemsPerPage,
                this.childEventsOptions.sortBy,
                this.childEventsOptions.sortDirection,
                null,
                null,
                null,
                null,
                this.childEventsOptions.attributesExcludeFilter
            ).toPromise();

            this.childEventsMetaSource.next(chilldEvents.meta);
            this.currentChildEvents$.next(chilldEvents.data.map(event => EfEvent.fromApiResponse(event)));
            this.isLoadingChildEvents = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingChildEvents = false;
            return false;
        }
    }

    public async createOrUpdateChildEvent(event: NewEventFields) {

        let createdEvent;

        try {
            if (!event.eventId) {
                createdEvent = await this.apiClient
                .getUseCaseFactory()
                .Event()
                .CreateEvent(
                    this.eventFarmService.currentTeam.id,
                    this.eventFarmService.currentUser.id,
                    event.eventName,
                    null,
                    this.eventFarmService.currentEvent.contactEmail,
                    event.startTime,
                    event.endTime,
                    this.eventFarmService.currentEvent.startTime.timezone
                ).toPromise();
                const id: string = createdEvent.data.command.eventId;
                await this.apiClient.getUseCaseFactory().Event().SetDescriptionForEvent(id, event.description).toPromise();

                const newVenue = await this.apiClient.getUseCaseFactory().Venue().CreateVenue(
                    this.eventFarmService.currentTeam.id,
                    event.locationName,
                    'physical',
                    event.locationAddress
                ).toPromise();

                await this.apiClient.getUseCaseFactory().Event().AddVenueToEvent(
                    id,
                    newVenue.data.command.venueId
                ).toPromise();
                await this.apiClient.getUseCaseFactory().Event().AddChildEvent(this.eventFarmService.currentEvent.id, id).toPromise();
            } else {
                const id: string = event.eventId;
                await this.apiClient.getUseCaseFactory().Event().SetNameForEvent(id, event.eventName).toPromise();
                await this.apiClient.getUseCaseFactory().Event().SetTimeForEvent(id, event.startTime, event.endTime, this.eventFarmService.currentEvent.startTime.timezone).toPromise();
                await this.apiClient.getUseCaseFactory().Event().SetDescriptionForEvent(id, event.description).toPromise();

                const newVenue = await this.apiClient.getUseCaseFactory().Venue().CreateVenue(
                    this.eventFarmService.currentTeam.id,
                    event.locationName,
                    'physical',
                    event.locationAddress
                ).toPromise();

                await this.apiClient.getUseCaseFactory().Event().AddVenueToEvent(
                    id,
                    newVenue.data.command.venueId
                ).toPromise();            }
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async deleteEvent(id: string) {
        try {
            await this.apiClient.getUseCaseFactory().Event().ArchiveEvent(id).toPromise();
            this.message.success('Child event removed');
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

}

export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
}

export interface ChildEventsListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    attributesExcludeFilter?: any[];
    pagination: Pagination;
}

export interface NewEventFields {
    eventName: string;
    startTime: string;
    endTime: string;
    description: string;
    locationName: string;
    locationAddress: string;
    eventId?: string;
}
