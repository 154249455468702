import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { ExhibitorsService } from './exhibitors.service';

@Component({
    selector: 'exhibitors',
    template: require('./exhibitors.html'),
    styles: [require('./exhibitors.scss')],
})

export class ExhibitorsComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private exhibitorsService: ExhibitorsService
    ) {
    }

    async ngOnInit() {
        await this.exhibitorsService.getExhibitors();
    }

    ngOnDestroy() {
    }
}
