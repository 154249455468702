import { AbstractModel } from '../abstract-model';
import { ThemeColorTypeInterface, ThemeNameTypeInterface, ThemeFontFamilyTypeInterface, Event } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';

export class EventTheme extends AbstractModel {

    private _colors?: ThemeColorTypeInterface[];
    private _companyLogo?: string;
    private _themeName?: ThemeNameTypeInterface;
    private _themeFontFamily?: ThemeFontFamilyTypeInterface;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): EventTheme {
        const theme = new this();
        try {
            const { colors, themeName, fontFamily, companyLogo } = data;
            theme._companyLogo = companyLogo;
            theme._colors = this.buildThemeColors(colors);
            theme._themeName = themeName;
            theme._themeFontFamily = fontFamily;

        } catch (err) {
        }
        return theme;
    }

    public static fromCommand(command): EventTheme {
        const theme = new this();
        try {

            const {
                themeFontFamilyType,
                themeNameType, primary, primaryAlt, alert, background, font } = command;

            theme._colors = this.buildThemeColors(command);
            theme._themeName = this.buildThemeName(themeNameType);
            theme._themeFontFamily = this.buildThemeFontFamily(themeFontFamilyType);

        } catch (err) {
        }

        return theme;
    }

    get colors(): ThemeColorTypeInterface[] {
        return this._colors;
    }

    get companyLogo(): string {
        return this._companyLogo;
    }

    get themeName(): ThemeNameTypeInterface {
        return this._themeName;
    }

    get themeFontFamily(): ThemeFontFamilyTypeInterface {
        return this._themeFontFamily;
    }

    private static buildThemeName(data): ThemeNameTypeInterface {
        return new Event().ThemeNameType().find(name => {
            return name.slug === data;
        });
    }

    private static buildThemeFontFamily(data): ThemeFontFamilyTypeInterface {
        return new Event().ThemeFontFamilyType().find(fontFamily => {
            return fontFamily.slug === data;
        });
    }

    private static buildThemeColors(colors): ThemeColorTypeInterface[] {

        const eventColors = new Event().ThemeColorType();

        Object.keys(colors).forEach(color => {
            switch (color) {
                case 'alert':
                    eventColors[0].description = colors[color];
                    break;
                case 'background':
                    eventColors[1].description = colors[color];
                    break;
                case 'font':
                    eventColors[2].description = colors[color];
                    break;
                case 'primary':
                    eventColors[3].description = colors[color];
                    break;
                case 'primaryAlt':
                    eventColors[4].description = colors[color];
                    break;
                default:
                    break;
            }
        });

        return eventColors;
    }
}
