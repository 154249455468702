import { Component } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, finalize, shareReplay } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';

@Component({
  selector: 'accepted-credit-cards',
  template: require('./accepted-credit-cards.html'),
})

export class AcceptedCreditCardsComponent {

  private cardDetailsForm: FormGroup;
  private eventUpdate$: Subscription;
  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());

  private cardDetails = [
    {slug: 'visa', name: 'Visa', value: false},
    {slug: 'mastercard', name: 'Mastercard', value: false},
    {slug: 'amex', name: 'American Express', value: false},
    {slug: 'discover', name: 'Discover', value: false},
  ];

  constructor(
    private eventFarmService: EventFarmService,
    private router: Router,
    private route: ActivatedRoute,
    private apiClient: EventFarmAPIClient,
    private fb: FormBuilder,
    private store: Store<fromStore.AppState>,
    private message: NzMessageService,
  ) {
    this.cardDetailsForm = this.fb.group({});
    this.addToFormGroup();
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      if (val.data.cardOptions && val.data.cardOptions.hasVisa) {
        this.cardDetailsForm.patchValue({
          visa: true,
        }, {emitEvent: false});
      }
      if (val.data.cardOptions && val.data.cardOptions.hasAmex) {
        this.cardDetailsForm.patchValue({
          amex: true,
        }, {emitEvent: false});
      }
      if (val.data.cardOptions && val.data.cardOptions.hasMastercard) {
        this.cardDetailsForm.patchValue({
          mastercard: true,
        }, {emitEvent: false});
      }
      if (val.data.cardOptions && val.data.cardOptions.hasDiscover) {
        this.cardDetailsForm.patchValue({
          discover: true,
        }, {emitEvent: false});
      }
    });
  }

  private addToFormGroup() {
    this.cardDetails.forEach(control => {
      return this.cardDetailsForm.addControl(control.slug, this.fb.control(control.value));
    });
  }

  private addToAcceptedCards(slug) {
    if (slug === 'amex') {
      if (!this.cardDetailsForm.get(slug).value) {
        this.apiClient.getUseCaseFactory().Event().EnableAmexCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('American Express enabled'));
      } else {
        this.apiClient.getUseCaseFactory().Event().DisableAmexCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('American Express disabled'));
      }
    } else if (slug === 'mastercard') {
      if (!this.cardDetailsForm.get(slug).value) {
        this.apiClient.getUseCaseFactory().Event().EnableMastercardCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('Mastercard enabled'));
      } else {
        this.apiClient.getUseCaseFactory().Event().DisableMastercardCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('Mastercard disabled'));
      }
    } else if (slug === 'visa') {
      if (!this.cardDetailsForm.get(slug).value) {
        this.apiClient.getUseCaseFactory().Event().EnableVisaCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('Visa enabled'));
      } else {
        this.apiClient.getUseCaseFactory().Event().DisableVisaCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('Visa disabled'));
      }
    } else if (slug === 'discover') {
      if (!this.cardDetailsForm.get(slug).value) {
        this.apiClient.getUseCaseFactory().Event().EnableDiscoverCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('Discover enabled'));
      } else {
        this.apiClient.getUseCaseFactory().Event().DisableDiscoverCard(this.eventFarmService.currentEvent.id).toPromise().then(() => this.message.success('Discover disabled'));
      }
    }
  }
}
