import { Action } from '@ngrx/store';

export const FETCH_GUEST_LIST = '[Event] Fetch Guest List';
export const FETCH_GUEST_LIST_SUCCESS = '[Event] Fetch Guest List Success';
export const FETCH_GUEST_LIST_FAIL = '[Event] Fetch Guest List Fail';
export const SET_GUEST_LIST_SUCCESS = '[Event] Set Guest List Success';
export const SET_GUEST_LIST_FAIL = '[Event] Set Guest List Fail';

export class FetchGuestList implements Action {
    readonly type = FETCH_GUEST_LIST;
    constructor(public payload?: any) { }
}

export class FetchGuestListFail implements Action {
    readonly type = FETCH_GUEST_LIST_FAIL;
    constructor(public payload?: any) { }
}

export class FetchGuestListSuccess implements Action {
    readonly type = FETCH_GUEST_LIST_SUCCESS;
    constructor(public payload?: any) { }
}

export class SetGuestListSuccess implements Action {
    readonly type = SET_GUEST_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class SetGuestListFail implements Action {
    readonly type = SET_GUEST_LIST_FAIL;
    constructor(public payload: any) { }
}

export type EventAction =
    FetchGuestList |
    FetchGuestListFail |
    FetchGuestListSuccess |
    SetGuestListFail |
    SetGuestListSuccess;
