
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {PopoverModule} from 'ngx-bootstrap/popover';
import {PaginationModule} from 'ngx-bootstrap/pagination'

import { EventsListRoutingModule } from './eventsList-routing.module';

import { EventsListComponent } from './eventsList.component';
import { ListViewActionsComponent } from './listViewActions/listViewActions.component';
import { ListViewDateComponent } from './listViewDate/listViewDate.component';
import { EventDateFilterComponent } from './eventDateFilter/eventDateFilter.component';
import { ListViewTagsComponent } from './listViewTags/listViewTags.component';
import { AppliedTagsComponent } from './appliedTags/appliedTags.component';
import { TagsFilterPipe } from './pipes/TagsFilter.pipe';
import { ArchivedEventPipe } from './pipes/ArchivedEvent.pipe';
import { EventsListService } from './eventsList.service';
import { RequestCanvasComponent } from '../../CoreUI/Modal/Modals/RequestCanvas/request-canvas.component';
import { ModalModule } from '../../CoreUI/Modal/modal.module';
import {NzUtilitiesModule} from "../../../NZUtilities/nz.utilities.module";

@NgModule({
    bootstrap: [EventsListComponent],
    declarations: [
        EventsListComponent,
        RequestCanvasComponent,
        ListViewDateComponent,
        ListViewActionsComponent,
        ListViewTagsComponent,
        EventDateFilterComponent,
        AppliedTagsComponent,
        TagsFilterPipe,
        ArchivedEventPipe,
    ],
    entryComponents: [
        EventsListComponent,
        RequestCanvasComponent,
        ListViewDateComponent
    ],
    imports: [
        EventsListRoutingModule,
        CommonModule,
        FormsModule,
        ModalModule,
        ReactiveFormsModule,
        PopoverModule,
        PaginationModule,
        UtilitiesModule,
        NzUtilitiesModule
    ],
    providers: [
        EventsListService
    ],
})

export class EventsListModule {
}
