import { AbstractModel } from '../abstract-model';
import { User } from '../User/user';
import { QueueCommandError } from './queue-command-error';
import { QueueCommandMessage } from './queue-command-message';

export class QueueCommand extends AbstractModel {
    constructor(id: string) {
        super(id);
        this._queueErrors = [];
        this._queueMessages = [];
    }

    public static fromApiResponse(data) {
        const queueCommand = new this(data.id);

        if (data.meta.isInitialized) {
            queueCommand._commandId = data.attributes.commandId;
            queueCommand._jobId = data.attributes.jobId;
            queueCommand._name = data.attributes.displayName;
            queueCommand._totalProcessed = data.attributes.totalProcessed;
            queueCommand._totalToProcess = data.attributes.totalToProcess;
            queueCommand._percentDone = data.attributes.percentDone;
            queueCommand._isFinished = data.attributes.isFinished;
            queueCommand._isSuccess = data.attributes.isSuccess;
            queueCommand._executionDurationMilliseconds = data.attributes.executionDurationMilliseconds;
        }

        if (data.relationships && data.relationships.user) {
            queueCommand._user = User.fromApiResponse(data.relationships.user);
        }

        if (data.relationships && data.relationships.errors) {
            data.relationships.errors.forEach((commandError) => {
                queueCommand._queueErrors.push(QueueCommandError.fromApiResponse(commandError));
            });
        }

        if (data.relationships && data.relationships.messages) {
            data.relationships.messages.forEach((commandMessage) => {
                queueCommand._queueMessages.push(QueueCommandMessage.fromApiResponse(commandMessage));
            });
        }

        return queueCommand;
    }

    private _user: User;
    private _commandId: string;
    private _jobId: string;
    private _name: string;
    private _totalProcessed: number;
    private _totalToProcess: number;
    private _percentDone: number;
    private _isFinished: boolean;
    private _isSuccess: boolean;
    private _executionDurationMilliseconds: number;
    private _queueErrors: QueueCommandError[];
    private _queueMessages: QueueCommandMessage[];

    get commandId(): string {
        return this._commandId;
    }

    get jobId(): string {
        return this._jobId;
    }

    get name(): string {
        return this._name;
    }

    get totalProcessed(): number {
        return this._totalProcessed | 0;
    }

    get totalToProcess(): number {
        return this._totalToProcess || 0;
    }

    get percentDone(): number {
        return this._percentDone || 0;
    }

    get isFinished(): boolean {
        return this._isFinished;
    }

    get isSuccess(): boolean {
        return this._isSuccess;
    }

    get executionDurationMilliseconds(): number {
        return this._executionDurationMilliseconds;
    }

    get user(): User {
        return this._user;
    }

    get queueErrors(): QueueCommandError[] {
        return this._queueErrors;
    }

    get queueMessages(): QueueCommandMessage[] {
        return this._queueMessages;
    }
}
