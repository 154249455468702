import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from '@khazii/ngx-intl-tel-input';

import {MobilePreviewComponent} from './mobile-preview.component';

@NgModule({
    declarations: [MobilePreviewComponent],
    exports: [MobilePreviewComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule
    ]
})

export class MobilePreviewModule {
}
