import { AbstractModel } from '../abstract-model';
import { Question } from '../Question/question';
export class Answer extends AbstractModel {

    private _text: string;
    private _isDefault: boolean;
    private _isDeleted: boolean;
    private _question: Question;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): Answer {
        const answer = new this(data.id);
        try {
            answer._isDefault = data.attributes.isDefault;
            answer._text = data.attributes.text;
            answer._isDeleted = data.attributes.isDeleted;

            if (data.relationships && data.relationships.question) {
                answer._question = Question.fromApiResponse(data.relationships.question);
            }

        } catch (err) { }
        return answer;
    }


    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get isDefault(): boolean {
        return this._isDefault;
    }

    set isDefault(value: boolean) {
        this._isDefault = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }
    
    get question(): Question {
        return this._question;
    }
}
