import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from '../../../../manage.routing-module';
import { NzUtilitiesModule } from '../../../../../../../NZUtilities/nz.utilities.module';
import { LoaderGrayModule } from '../../../../../../CoreUI/LoaderGray/loader-gray.module';
import { EditEventEfxService } from './EditEventEfX/edit-event-efx.service';
import { TeamEventsService } from '../../team-events.service';
import { TeamEventsComponent } from '../../team-events.component';
import { EventListItemComponent } from '../event-list-item.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { EventFarmService } from '../../../../../../eventFarm.service';
import { AlertService } from '../../../../../../eventFarmAlert.service';
import { EditEventEfxComponent } from './EditEventEfX/edit-event-efx.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditEventConfigComponent } from './edit-event-config.component';
import { EditEventSettingsComponent } from './EditEventSettings/edit-event-settings.component';
import { EditEventExhibitorsComponent } from './EditEventExhibitors/edit-event-exhibitors.component';
import {NgxUtilitiesModule} from '../../../../../../../NGXUtilities/ngx.utilities.module';

const forExport = [
    EventListItemComponent,
    EditEventConfigComponent,
    EditEventEfxComponent,
    EditEventSettingsComponent,
    EditEventExhibitorsComponent
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        ManageRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzUtilitiesModule,
        AutocompleteLibModule,
        LoaderGrayModule,
        NgxUtilitiesModule
    ],
    entryComponents: [EditEventConfigComponent, EditEventEfxComponent],
    providers: [
        EventFarmService,
        NzDrawerService,
        AlertService,
        TeamEventsService,
        EditEventEfxService,
    ],
})
export class EditEventConfigModule {}
