import { AbstractModel } from '../abstract-model';
import { v4 as uuidv4 } from 'uuid';


export class Tag extends AbstractModel {
    private _name?: string;
    private _slug?: string;
    private _isActive?: boolean;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): Tag {
        const tag = new this(data.id);
        try {
            tag._name = data.attributes.name;
            tag._slug = data.attributes.slug;
        } catch (err) {
        }
        return tag;
    }

    public static fromUniqueResponse(key: string, value: string): Tag {
        const tag = new this(uuidv4());
        try {
            tag._slug = key;
            tag._name = value;
        } catch (err) {
        }
        return tag;
    }

    public static fromNewTagCreation(data): Tag {
        const tag = new this();
        try {
            tag._name = data.tagName;
            tag._slug = data.tagSlug;
        } catch (err) {
        }

        return tag;
    }

    get name(): string {
        return this._name;
    }

    get slug(): string {
        return this._slug;
    }

    get isActive(): boolean {
        return this._isActive;
    }

    set isActive(val) {
        this._isActive = val;
    }
}
