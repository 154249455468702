import {AbstractModel} from '../abstract-model';
import {EfEvent} from '../Event/event';

export class SitePage extends AbstractModel {
    private _name?: string;
    private _event: EfEvent;
    private _sitePageId?: string;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const sitePage = new this();
        sitePage._sitePageId = data.id;
        if (data.attributes) {
            sitePage._name = data.attributes.name;
        }
        if (data.relationships && data.relationships.event) {
            sitePage._event = data.relationships.event;
        }

        return sitePage;
    }


    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get event(): EfEvent {
        return this._event;
    }

    get sitePageId(): string {
        return this._sitePageId;
    }

    get isDefault(): boolean {
        return this.sitePageId === this.event.defaultSitePage;
    }
}
