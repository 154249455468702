import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from './../../manage.routing-module';
import { AdminService } from '../../../admin.service';
import { EventFarmService } from '../../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../../NZUtilities/nz.utilities.module';
import { AlertService } from '../../../../eventFarmAlert.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoaderGrayModule } from '../../../../CoreUI/LoaderGray/loader-gray.module';
import { TeamFeatureService } from './team-feature.service';
import { TeamFeaturesComponent } from './team-features.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {NgxUtilitiesModule} from '../../../../../NGXUtilities/ngx.utilities.module';

const forExport = [TeamFeaturesComponent];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    AutocompleteLibModule,
    LoaderGrayModule,
    NgxUtilitiesModule
  ],
  entryComponents: [],
  providers: [
    AdminService,
    EventFarmService,
    NzDrawerService,
    AlertService,
    TeamFeatureService,
  ],
})
export class TeamFeaturesModule {}
