import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { shareReplay } from 'rxjs/operators';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditSpeakersComponent } from '../EditSpeakers/edit-speakers.component';
import { EFProfile } from '../../../../../../ApiClient/Models/Profile/EFProfile';

@Component({
    selector: '[speakers-item]',
    template: require('./speakers-item.html'),
    styles: [require('./speakers-item.scss')],
})

export class SpeakersItemComponent implements OnInit {
    @Input() speakerItem: EFProfile;
    public componentClicked: boolean = false;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private store: Store<fromRoot.AppState>,
        private drawerService: NzDrawerService
    ) { }

    ngOnInit() {
    }

    openComponent(): void {
        if (!this.componentClicked) {
            this.componentClicked = true;
            const drawerRef = this.drawerService.create<EditSpeakersComponent, { speaker: EFProfile }, string>({
                nzTitle: 'Edit Speaker',
                nzContent: EditSpeakersComponent,
                nzWidth: '50%',
                nzContentParams: {
                    speaker: this.speakerItem,
                }
            });

            drawerRef.afterOpen.subscribe(() => {
            });

            drawerRef.afterClose.subscribe(data => {
                this.componentClicked = false;
            });
        }
    }

    public checkIfUrlIsImage(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    }
}
