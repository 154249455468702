import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { EventPromotionsComponent } from './eventPromotions.component';
import { EventPromotionsRoutingModule } from './eventPromotions-routing.module';
import { LegacyUIModule } from '../../LegacyUI/legacy-UI.module';

@NgModule({
    bootstrap: [EventPromotionsComponent],
    declarations: [
        EventPromotionsComponent,
    ],
    entryComponents: [
        EventPromotionsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        LegacyUIModule,
        EventPromotionsRoutingModule,
    ],
    providers: [
    ],
})

export class EventPromotionsModule {
}
