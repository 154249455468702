import * as moment from 'moment';
import { FormattedDateTime } from '../../../../../ApiClient/Models/formatted-datetime';

export interface ReportType {
    name: string;
    slug: ReportTypeEnum;
    standardReportName: string;
    description: string;
    showCIO?: boolean;
}

export class Report {

    name: string;
    private path?: string;
    private createdAtFormatted: FormattedDateTime;
    isReady: boolean;

    constructor({attributes}) {
        this.name = attributes.name;
        this.path = attributes.path ? attributes.path : null;
        this.isReady = attributes.status.isDone;
        this.createdAtFormatted = attributes.createdFormatted;
    }

    get url(): string {
        return this.path ? `https://s3.amazonaws.com/reports.eventfarm/${this.path}` : '';
    }

    get created(): string {
        return this.createdAtFormatted.medium;
    }
}

class ReportTypeMaker {
    static create(type: ReportType) {
        const {name, slug, description, showCIO, standardReportName} = type;
        return { name, slug, description, showCIO, standardReportName};
    }
}

export interface ReportTypes {
    name: string;
    description: string;
    types: ReportType[];
}

class ReportsTypeMaker {
    static create(rt: ReportTypes) {
        const {name, description, types} = rt;
        return { name, description, types };
    }
}

export const enum ReportTypeEnum {
    GL_SUMMARY = 1,
    GL_CONFIRMED,
    GL_UNCONFIRMED,
    GL_TRANSFER,
    GL_WAITLIST,
    GL_PURCHASE,
    GL_CHECKIN,
    GL_TICKET_BLOCK,
    COMMS_EMAIL_DELIVERABILITY,
    COMMS_SENT_EMAILS,
    ACTIVITY_LOG,
    COMMS_SENT_SMS
}

export const GUEST_LIST_REPORTS: ReportTypes = ReportsTypeMaker.create(
    {name: 'Guest List Reports', description: 'Choose a guest list report from the dropdown below to see details about the available report and generate it for your event.', types: [
        ReportTypeMaker.create({name: 'Guest List Summary Report', slug: ReportTypeEnum.GL_SUMMARY, description: 'A downloadable copy of the guest list, to view all guest data in a centralized location. Use this report to quickly analyze your guest list and guests’ registration question responses at any point before, during, or after your event.', showCIO: true, standardReportName: `Guest List - `}),
        ReportTypeMaker.create({name: 'Confirmed Guests Report', slug: ReportTypeEnum.GL_CONFIRMED, description: 'A limited version of the Guest List Summary report, excluded only to those who have confirmed their attendance to your event. Use this report to better understand your event’s actual attendees.', standardReportName: `Confirmed Guests - `}),
        ReportTypeMaker.create({name: 'Unconfirmed Guests Report', slug: ReportTypeEnum.GL_UNCONFIRMED, description: 'A version of the Guest List Summary report, limited to only those guests who have not yet confirmed their registration. This report is useful for outreach before the event to increase attendance.', standardReportName: `Unconfirmed Guests - `}),
        ReportTypeMaker.create({name: 'Transfer Report', slug: ReportTypeEnum.GL_TRANSFER, description: 'For events allowing registrants to transfer their access to others, the transfer list allows you to see which guest(s) have initiated a transfer of their registration, their recipient(s), and the status of the transfer.', standardReportName: `Transfer - `}),
        ReportTypeMaker.create({name: 'Waitlist Report', slug: ReportTypeEnum.GL_WAITLIST, description: 'The waiting list for sold out events keeps track of additional registrants who complete a waitlist registration, so that they can be re-invited to the event as space becomes available.', standardReportName: `Waitlist - `}),
        ReportTypeMaker.create({name: 'Purchase Report', slug: ReportTypeEnum.GL_PURCHASE, description: 'For events charging for registration, this report includes a PDF receipt for each guest and CSV list view of all transactions and guest billing information.', standardReportName: `Purchase - `}),
        ReportTypeMaker.create({name: 'Check-In Report', slug: ReportTypeEnum.GL_CHECKIN, description: 'A downloadable copy of the list of checked-in guests.', showCIO: true, standardReportName: `Check-In - `}),
        ReportTypeMaker.create({name: 'Ticket Block Users Report', slug: ReportTypeEnum.GL_TICKET_BLOCK, description: 'For events allowing invitation through ticket blocks, use this report to drill into the guest list and see the originating ticket block(s) for each guest on the list.', standardReportName: `Ticket Block Users - `}),
        ]
    }
);

export const COMMUNICATIONS_REPORTS: ReportTypes = ReportsTypeMaker.create(
    {name: 'Guest Communication Reports', description: 'Choose a communication report from the dropdown below to see details about the available report and generate it for your event.', types: [
        ReportTypeMaker.create({name: 'Email Deliverability Report', slug: ReportTypeEnum.COMMS_EMAIL_DELIVERABILITY, description: 'For each invitation, view the status of email communication with the guest, and the last action taken by the guest. This report is useful to determine which unconfirmed guests have not received or opened their invitations, and which guest email addresses are not valid.', standardReportName: `Email Deliverability - `}),
        ReportTypeMaker.create({name: 'Event Sent Emails Report', slug: ReportTypeEnum.COMMS_SENT_EMAILS, description: 'A digital record of all event-related emails that have been sent to guests on the guest list.', standardReportName: `Event Sent Emails - `}),
        ReportTypeMaker.create({name: 'Event Sent SMS Report', slug: ReportTypeEnum.COMMS_SENT_SMS, description: 'A digital record of all event-related text messages that have been sent to guests on the guest list.', standardReportName: `Event Sent SMS - `}),
        ]
    }
);

export const GUEST_ACTIVITY_REPORTS: ReportTypes = ReportsTypeMaker.create(
    {name: 'Guest Activity Reports', description: 'Download a log of all guest activity to your event, including registration, check-in, and experiential activity.', types: [
        ReportTypeMaker.create({name: 'Activity Log', slug: ReportTypeEnum.ACTIVITY_LOG, description: 'View a running log of guests’ engagement with your event, including registration, check-in, and experiential interaction (experiential data requires EFx-enabled event).', standardReportName: `Activity Log - `}),
        ]
    }
);
