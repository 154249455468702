import { AbstractModel } from '../abstract-model';

export class UserAddress extends AbstractModel {
    public static fromApiResponse(data) {
        const userAddress = new this(data.id);
        try {
            userAddress._address1 = data.attributes.address.address1;
            userAddress._address2 = data.attributes.address.address2;
            userAddress._city = data.attributes.address.city;
            userAddress._country = data.attributes.address.country;
            userAddress._postalCode = data.attributes.address.postalCode;
            userAddress._state = data.attributes.address.state;
        } catch (err) {
        }
        return userAddress;
    }

    private _address1?: string;
    private _address2?: string;
    private _city?: string;
    private _country?: string;
    private _postalCode?: string;

    private _state?: string;

    get address1(): string {
        return this._address1;
    }

    set address1(value: string) {
        this._address1 = value;
    }

    get address2(): string {
        return this._address2;
    }

    set address2(value: string) {
        this._address2 = value;
    }

    get city(): string {
        return this._city;
    }

    set city(value: string) {
        this._city = value;
    }

    get country(): string {
        return this._country;
    }

    set country(value: string) {
        this._country = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get state(): string {
        return this._state;
    }

    set state(value: string) {
        this._state = value;
    }
}
