import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Observable, Subscription} from 'rxjs';
import {VersionMonitorService} from '../../../VersionMonitor/version-monitor.service';
import {EventFarmService} from '../../eventFarm.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {LocalStorageService} from '../../../_services/storage/local-storage.service';

@Component({
    selector: 'refresh-notification',
    template: `
        <ng-template #template>
            <div class="ant-notification-notice-content">
                <div>
                    <div class="ant-notification-notice-message">Update Available!</div>
                    <div class="ant-notification-notice-description">
                        A new and improved version of the application is now available.
                        <button nz-button (click)="reloadPage()" >
                            <span>
                                <i class="fa fa-refresh"></i> Refresh
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    styles: [require('./refresh-notification.scss')],
})

export class RefreshNotificationComponent implements OnInit, OnDestroy {
    @ViewChild(TemplateRef, {static: false}) template?: TemplateRef<{}>;

    constructor(
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private localStorageService: LocalStorageService,
        private versionMonitorService: VersionMonitorService,
        private notification: NzNotificationService
    ) {
    }

    private alertIsDisplayed: boolean = false;
    private buildNumberSub$: Subscription;

    reloadPage() {
        window.location.reload();
    }
    showNotification(): void {
        this.alertIsDisplayed = true;
        this.notification.template(this.template, {
            nzPlacement: 'bottomRight',
            nzDuration: 0,
            nzStyle: {
                background: 'rgb(217, 244, 236)',
                border: '1px solid rgb(80, 138, 116)'
            }
        });
    }

    ngOnInit() {
        this.buildNumberSub$ = this.versionMonitorService.$versionIsUpToDateSubject.subscribe((versionIsUpToDate) => {
            if (versionIsUpToDate === true) {
                this.notification.remove();
            } else {
                // If we are not currently displaying the notification, go ahead and show it
                if (!this.alertIsDisplayed) {
                    this.showNotification();
                }
            }
        });
    }

    ngOnDestroy() {
        this.buildNumberSub$.unsubscribe();
    }

}
