import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { Injectable } from '@angular/core';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { AlertService } from '../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TicketBlock } from '../../../ApiClient/Models/Stack/ticket-block';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { TicketBlockTypeInterface, TicketBlock as SDKTB } from '@eventfarm/javascript-sdk/dist/Api/Type/TicketBlock';


@Injectable()
export class TicketBlockListService {

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private segmentService: SegmentService,
        private alertService: AlertService,
        private message: NzMessageService,

    ) {
    }

    public ticketBlockMetaSource = new BehaviorSubject<any>({});
    public readonly ticketBlocks$ = new BehaviorSubject<TicketBlock[]>([]);
    public ticketBlockMeta$ = this.ticketBlockMetaSource.asObservable();
    public isLoadingTicketBlock = true;


    private isSessionTicketBlockEnabled(): boolean {
        return this.eventFarmService.betaEnabledForPool && this.eventFarmService.sessionsEnabled && this.eventFarmService.currentEvent.showSessionTicketBlocks;
    }

    public ticketBlockListOptions: TicketBlockListOptions = {
        query: null,
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentPage: 1,
            itemsPerPage: 100,
        },
        withData: ['Allotments', 'AllotmentCounts', 'AllotmentsAndStack']
    };

    public readonly sessions$ = new BehaviorSubject<EfEvent[]>([]);


    async getTicketBlocks() {

        this.isLoadingTicketBlock = true;

        const res = await this.apiClient.getUseCaseFactory().TicketBlock().ListTicketBlocksForEvent(
            this.eventFarmService.currentEvent.id, 
            this.ticketBlockListOptions.query, 
            this.ticketBlockListOptions.withData,
            this.ticketBlockListOptions.pagination.currentPage, 
            this.ticketBlockListOptions.pagination.itemsPerPage, 
            this.ticketBlockListOptions.sortBy, 
            this.ticketBlockListOptions.sortDirection, 
            null, 
            true
        ).toPromise();
        
        this.ticketBlockMetaSource.next(res.meta);
        const ticketBlocks: TicketBlock[] = res.data.map((tb) => TicketBlock.fromApiResponse(tb));
        this.ticketBlocks$.next(ticketBlocks);
        this.isLoadingTicketBlock = false;
    }

    public get ticketBlockCount(): number {
        return this.ticketBlocks$.value.length;
    }

    async reset() {
        this.ticketBlockListOptions.pagination.currentPage = 1;
        this.ticketBlocks$.next([]);
        this.ticketBlockMetaSource.next({});
        this.sessions$.next([]);
    }


    async createNewTicketBlock() {
        this.segmentService.segmentTicketTracking().addNewTicketBlock(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );

        const ticketBlockTypes: TicketBlockTypeInterface[] = new SDKTB().TicketBlockType();

        let tbType = ticketBlockTypes.find(t => t.isStandard);

        if (this.isSessionTicketBlockEnabled()) {
            tbType = ticketBlockTypes.find(t => t.isSession);
        }


        const res = await this.alertService.ticketBlock().addNewTicketBlock(this.eventFarmService.currentEvent.id, tbType)
        
        if (res.value) {
            this.getTicketBlocks();
            this.message.success('Ticket Block added successfully');
        }
    }

    async removeTicketBlock(ticketBlock: TicketBlock) {
        this.segmentService.segmentTicketTracking().removeTicketBlockClicked(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        const res = await this.alertService.ticketBlock().confirmDeleteTicketBlock(ticketBlock.name);

        if (res.value) {
            try {
                await this.apiClient.getUseCaseFactory().TicketBlock().RemoveTicketBlock(ticketBlock.id).toPromise();
                this.message.success('Ticket Block Removed Successfully');
                this.getTicketBlocks();
            } catch(e) {
                this.message.error('Error removing ticket block');
            }
        }
    }


    public async listSessionsForParentEvent(parentEventId: string) {
        try {
            const res = await this.apiClient.getUseCaseFactory().Event().ListChildrenForEvent(
                        parentEventId,
                        null,
                        [
                            'Stacks',
                            'StacksWithAvailabilityCounts',
                            'TicketTypes'
                        ],
                        1,
                        500,
                        'event-start',
                        'ascending',
                        null,
                        null,
                        null,
                        null,
                        ['archived']
                    ).toPromise();
   
            const sessions = res.data.map((er) => EfEvent.fromApiResponse(er));
            this.sessions$.next(sessions);
        } catch (e) {
            this.sessions$.next([]);
        }
    }


}


export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
}

export interface TicketBlockListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
    withData: string[];
}