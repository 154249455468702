
import { createSelector } from '@ngrx/store';

export const getCurrentEvent = (state) => state.event;
export const currentEventId = (state) => state.event.id as string;

export const eventRoleType = createSelector(
    getCurrentEvent,
    (event) => {
        return event.access;
    }
);
