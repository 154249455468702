import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { QuestionCenterService } from './question-center.service';
import { QuestionCenterComponent } from './question-center.component';
import { EditQuestionButtonComponent } from './QuestionList/QuestionListItem/EditQuestion/edit-question-button.component';
import { EditQuestionComponent } from './QuestionList/QuestionListItem/EditQuestion/edit-question.component';
import { QuestionCenterRoutingModule } from './question-center-routing.module';
import { QuestionCenterListComponent } from './QuestionList/question-center-list.component';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { AttributesComponent } from './Attributes/attributes.component';
import { QuestionsListItemComponent } from './QuestionList/QuestionListItem/question-list-item.component';
import { CreateQuestionButtonComponent } from './QuestionList/QuestionListItem/EditQuestion/create-question-button.component';
import { AnswerTextComponent } from './QuestionList/QuestionListItem/EditQuestion/AnswerText/answer-text.component';
import { EditQuestionService } from './QuestionList/QuestionListItem/EditQuestion/edit-question.service';
import { AnswersComponent } from './QuestionList/QuestionListItem/EditQuestion/Answers/answers.component';
import { QuestionLogicComponent } from './QuestionList/QuestionListItem/QuestionLogic/question-logic.component';
import { EditQuestionLogicButtonComponent } from './QuestionList/QuestionListItem/QuestionLogic/question-logic-button.component';
import { QuestionLogicService } from './QuestionList/QuestionListItem/QuestionLogic/question-logic.service';
import { ToggleQuestionViewButtonComponent } from './ToggleQuestionViewButton/toggle-question-view-button.component';
import { QuestionPreviewComponent } from './QuestionListPreview/question-list-preview.component';
import { PreviewWindowComponent } from './QuestionListPreview/PreviewWindow/preview-window.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [QuestionCenterComponent],
    declarations: [QuestionCenterComponent, EditQuestionComponent, EditQuestionButtonComponent,
        QuestionCenterListComponent, AttributesComponent, QuestionsListItemComponent,
        CreateQuestionButtonComponent, AnswerTextComponent, AnswersComponent, QuestionLogicComponent,
        EditQuestionLogicButtonComponent, ToggleQuestionViewButtonComponent, QuestionPreviewComponent, PreviewWindowComponent],
    entryComponents: [QuestionCenterComponent, EditQuestionComponent, QuestionLogicComponent],
    imports: [
        CommonModule,
        DragulaModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingOverlayModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        BreadcrumbsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,
        QuestionCenterRoutingModule,
        NgxUtilitiesModule
    ],
    providers: [
        QuestionCenterService,
        EditQuestionService,
        QuestionLogicService
    ],
})

export class QuestionCenterModule {
}
