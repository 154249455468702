import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as appState from '../app.state';

import * as userReducer from './user.reducer';
import * as teamReducer from './team.reducer';
import * as event from './event.reducer';
import * as admin from './admin.reducer';

export const reducers: ActionReducerMap<appState.AppState> = {
    user: userReducer.reducer,
    team: teamReducer.reducer,
    event: event.reducer,
    admin: admin.reducer,
    router: fromRouter.routerReducer,
};

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<appState.RouterStateUrl>>(
    'router'
);

export const getPoolParam = createSelector(
    getRouterState,
    (router) => {
        return router.state.params['pool'];
    }
);
