import { Pipe } from '@angular/core';

@Pipe({
    name: 'TagsFilterPipe',
})
export class TagsFilterPipe {
    transform(events, args?) {
        let tagToMatch = false;
        const matchedEvents = [];
        if (args.find((tag) => tag.selected === true)) {
            tagToMatch = true;
        }
        args.forEach((arg) => {
            if (tagToMatch && arg.selected) {
                events.forEach((eachEvent) => {
                    if (eachEvent.tags && eachEvent.tags.find((tag) => tag === arg.slug)) {
                        matchedEvents.push(eachEvent);
                    }
                });
            }
        });
        if (!tagToMatch) {
            return events;
        } else {
            return matchedEvents;
        }
    }
}
