import { Component, OnInit } from '@angular/core';
import { Subscription, Operator } from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { AlertService } from '../../../eventFarmAlert.service';
import { SessionStorageService } from '../../../../_services/storage/session-storage.service';
import { AccountInfoService } from '../account-info.service';
@Component({
    selector: 'salesforce-team-settings',
    template: require('./salesforce-team-settings.html'),
    styles: [require('./salesforce-team-settings.scss')],

})

export class SalesforceTeamSettingsComponent implements OnInit {

    constructor(
        private accountInfoService: AccountInfoService,
        private eventFarmService: EventFarmService,
        private sessionStorageService: SessionStorageService,
        private alertService: AlertService,
        private client: EventFarmAPIClient,
    ) {
    }

    private tabs: any = {
        left: {
            active: true,
            label: 'Manage Connection',
        },
        right: {
            active: false,
            label: 'Global Settings',
        },
    };
    readonly salesforceLogo = require('../assets/salesforce');
    readonly salesforceLogoDisabled = require('../assets/salesforce-disabled');
    readonly salesforceLogoActive = require('../assets/salesforce-active');
    private poolIntegrations: any = this.sessionStorageService.get('poolIntegrations');
    private userUnavailable = false;
    private user: any = {};
    private loading: boolean = true;
    private error: boolean = false;
    private requesting: boolean = false;
    private salesforcePoolSetting;
    public window: Window;

    ngOnInit() {

        if (this.eventFarmService.currentUser.id) {
            this.user = this.eventFarmService.currentUser;
        }

        this.switchTab('left');

        if (this.accountInfoService.integrationsTeamConfig.Salesforce.settings.connecting) {
            this.loading = true;
            this.accountInfoService.integrationsTeamConfig.Salesforce.settings.connecting = false;

            this.accountInfoService.fetchSalesforcePoolSettings(this.eventFarmService.currentTeam)
                .catch((error) => {
                    this.accountInfoService.integrationsTeamConfig.Salesforce.active = false;
                    this.alertService.salesforceIntegration().salesforceConnectAccountFailed();

                }).then((res) => {
                this.loading = false;

                if (this.accountInfoService.integrationsTeamConfig.Salesforce.settings.syncUser && this.accountInfoService.integrationsTeamConfig.Salesforce.settings.syncUser.username) {
                    this.alertService.salesforceIntegration().salesforceConnectAccountSuccess();

                    // intentionally set to run in background
                    this.accountInfoService.fetchSalesforceSdkLimitsForTeam()
                        .then((limits) => {
                            this.accountInfoService.integrationsTeamConfig.Salesforce.settings.limits = limits;
                        }).catch((err) => {
                    });
                }
            });

        } else {
            this.accountInfoService.fetchSalesforcePoolSettings(this.eventFarmService.currentTeam)
                .then((res) => {
                    return this.accountInfoService.fetchSalesforceSdkLimitsForTeam();
                }).then((limits) => {
                this.accountInfoService.integrationsTeamConfig.Salesforce.settings.limits = limits;
                return;
            }).catch((error) => {
                return;
            }).then((res) => {
                this.loading = false;
            });
        }
    }

    switchTab(tab) {
        this.tabs = {
            left: {
                active: tab === 'left' ? true : false,
            },
            right: {
                active: tab === 'right' ? true : false,
            },
        };
    }

    savePoolIntegrationValues() {
        this.accountInfoService.saveSalesforceTeamIntegrationValues()
            .subscribe(
                (data) => {
                    const leadSource = this.accountInfoService.integrationsTeamConfig.Salesforce.settings.leadSource ? this.accountInfoService.integrationsTeamConfig.Salesforce.settings.leadSource : '(blank)';
                    const leadCompany = this.accountInfoService.integrationsTeamConfig.Salesforce.settings.leadCompany ? this.accountInfoService.integrationsTeamConfig.Salesforce.settings.leadCompany : '(blank)';
                    this.alertService.salesforceIntegration().teamSettingsUpdateSuccess(leadSource, leadCompany);
                    this.requesting = false;
                },
                (err) => {
                    this.alertService.salesforceIntegration().teamSettingsUpdateFailed();
                    this.requesting = false;
                },
            );
    }

    connectToSalesforce() {
        if (!this.accountInfoService.salesforceLoginUrl) {
            return;
        }
        const team = this.eventFarmService.currentTeam;

        return this.accountInfoService.connectToSalesforce()
        .catch((res) => {
            return res;
        }).then((res) => {
            this.sessionStorageService.set('globalSalesforceIntegrationsAuth&user=' + this.user.id + '&team=' + this.eventFarmService.currentTeam.id, true);
            let salesforcePoolSettingId = '';

            if (res && res.data && res.data.command) {
                this.redirectToUrl(res.data.command.salesforcePoolSettingId);
            } else if (this.salesforcePoolSetting) {
                this.redirectToUrl(this.salesforcePoolSetting.id);
            }

            this.client.getUseCaseFactory()
                .SalesforcePoolSetting()
                .GetSalesforcePoolSetting(team.id)
                .subscribe((clientRes) => {
                    salesforcePoolSettingId = clientRes.data.id;
                    this.redirectToUrl(salesforcePoolSettingId);
                });
        });
    }

    private redirectToUrl(salesforcePoolSettingId: string) {
        const statePattern = 'oauth_integration_login';
        const urlWithPoolId = this.accountInfoService.salesforceLoginUrl.replace(
            statePattern,
            statePattern + ':' + salesforcePoolSettingId,
        );
        window.location.href = urlWithPoolId;
    }

    disconnectFromSalesforce() {
        this.requesting = true;
        this.accountInfoService.disconnectFromSalesforce()
            .catch((error) => {
                return;
            }).then(() => {
            this.requesting = false;
            this.accountInfoService.integrationsTeamConfig.Salesforce.active = false;
        });
    }
}
