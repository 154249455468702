import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgxIntlTelInputModule} from "@khazii/ngx-intl-tel-input";

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { GuestSimpleStatsModule } from '../../CoreUI/GuestSimpleStats/guestSimpleStats.module';
import {IntlPhoneInputModule} from "../../CoreUI/IntlPhoneInput/intl-phone-input.module";
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { EditSaveButtonModule } from '../../CoreUI/EditSaveButton/edit-save-button.module';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { ExhibitorsService } from './exhibitors.service';
import { ExhibitorsRoutingModule } from './exhibitors-routing.module';
import { ExhibitorsComponent } from './exhibitors.component';
import { ExhibitorListComponent } from './ExhibitorList/exhibitor-list.component';
import { ExhibitorItemComponent } from './ExhibitorList/ExhibitorItem/exhibitor-item.component';
import { EditExhibitorComponent } from './ExhibitorList/ExhibitorItem/EditExhibitor/edit-exhibitor.component';
import { CreateExhibitorButtonComponent } from './ExhibitorList/ExhibitorItem/create-exhibitor-button.component';
import { TruncatePipe } from '../../../Utilities/truncate.pipe';
import {EditExhibitorButtonComponent} from './ExhibitorList/ExhibitorItem/edit-exhibitor-button.component';
import { EditExhibitorTeamMembersComponent } from './ExhibitorList/ExhibitorItem/ExhibitorTeamMembers/edit-exhibitor-team-members.component';
import { EditExhibitorTeamMembersButtonComponent } from './ExhibitorList/ExhibitorItem/ExhibitorTeamMembers/edit-exhibitor-team-members-button.component';
import { ExhibitorTeamMemberTypeaheadModule } from '../../CoreUI/Typeahead/exhibitor-team-member-typeahead.module';
import { ExhibitorTeamMemberComponent } from './ExhibitorList/ExhibitorItem/ExhibitorTeamMembers/ExhibitorTeamMember/exhibitor-team-member.component';
import { ExhibitorTeamMembersService } from './ExhibitorList/ExhibitorItem/ExhibitorTeamMembers/exhibitors-team-members.service';
import { DebounceSearchModule } from '../../CoreUI/DebounceSearch/debounce-search.module';
import { ExhibitorTeamMemberAddComponent } from './ExhibitorList/ExhibitorItem/ExhibitorTeamMembers/ExhibitorTeamMember/exhibitor-team-member-add.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    bootstrap: [ExhibitorsComponent],
    declarations: [
        ExhibitorsComponent,
        ExhibitorListComponent,
        ExhibitorItemComponent,
        EditExhibitorComponent,
        CreateExhibitorButtonComponent,
        TruncatePipe,
        EditExhibitorButtonComponent,
        EditExhibitorTeamMembersComponent,
        EditExhibitorTeamMembersButtonComponent,
        ExhibitorTeamMemberComponent,
        ExhibitorTeamMemberAddComponent
    ],
    entryComponents: [
        ExhibitorsComponent,
        EditExhibitorComponent,
        EditExhibitorButtonComponent,
        EditExhibitorTeamMembersComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GuestSimpleStatsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        ExhibitorsRoutingModule,
        NzUtilitiesModule,
        EditSaveButtonModule,
        ExhibitorTeamMemberTypeaheadModule,
        DebounceSearchModule,
        NgxUtilitiesModule,
        IntlPhoneInputModule,
        NgxIntlTelInputModule
    ],
    providers: [
        ExhibitorsService,
        ExhibitorTeamMembersService
    ],
})

export class ExhibitorsModule {
}
