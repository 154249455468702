import {Injectable} from '@angular/core';

import {NzModalService} from 'ng-zorro-antd/modal';
import {EventFarmAPIClient} from '../ApiClient/event-farm-api-client';

interface Params {
    title: string;
    text?: string;
    html?: string;
    icon?: 'question';
    showCancelButton?: boolean;
    confirmButtonText?: string;
    onOkay?: any;
}

@Injectable()

export class EfModalService {
    private modal: NzModalService;

    constructor(
        private nzModal: NzModalService,
        private apiClient: EventFarmAPIClient
    ) {
        this.modal = nzModal;
    }

    confirm(params: Params) {
        const content = params.html ? params.html : params.text;
        const modalRef = this.modal.confirm({
            nzWidth: '400px',
            nzTitle: params.title || 'Default Title',
            nzContent: content
        });

        return new Promise((resolve, reject) => {
               modalRef.getConfig().nzOnOk = () => resolve(true);
            });

    }

    general(params: Params) {
        const content = params.html ? params.html : params.text;

        const modalRef = this.modal.create({
            nzTitle: params.title || 'Default Title',
            nzOkText: params.confirmButtonText || 'OK',
            nzClosable: true,
            nzContent: content
        });

        return modalRef;
    }
}
