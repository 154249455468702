export interface ISalesforceFeatureStatus {
    doesExist: boolean;
    isEnabled: boolean;
    isConnected: boolean;
    isCampaignSelected: boolean;
    shouldShowSalesforce: boolean;
}

export class SalesforceIntegration {
    private _salesforceStatus: ISalesforceFeatureStatus;
    private _syncing: boolean;

    constructor() {
        this.setStatusToFalse();
    }

    get salesforceStatus(): ISalesforceFeatureStatus {
        return this._salesforceStatus;
    }

    set salesforceStatus(value: ISalesforceFeatureStatus) {
        this._salesforceStatus = value;
    }

    get syncing(): boolean {
        return this._syncing;
    }

    set syncing(value: boolean) {
        this._syncing = value;
    }

    setStatusToFalse() {
        this._salesforceStatus = {
            doesExist: false,
            isEnabled: false,
            isConnected: false,
            isCampaignSelected: false,
            shouldShowSalesforce: false,
        };
    }
}
