import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../admin.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup } from '@angular/forms';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { PoolFeature } from '../../../../../ApiClient/Models/PoolFeature/poolFeature';
import { TeamFeatureService } from './team-feature.service';
import * as adminActions from '../../../../store/actions/admin/';
import * as adminFeatureActions from '../../../../store/actions/admin/admin-feature.action';

import {Store} from '@ngrx/store';
import * as fromStore from '../../../../store';
import {shareReplay} from "rxjs/operators";
import * as fromRoot from '../../../../store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'team-features',
  template: require('./team-features.html'),
  styles: [require('./team-features.scss')],
})
export class TeamFeaturesComponent implements OnInit, OnDestroy {
  @Input() team: Team;
  teamFeaturesForm: FormGroup;


  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private adminService: AdminService,
    private teamFeatureService: TeamFeatureService,
    private store: Store<fromStore.AppState>

  ) {}

  private features$ = this.store.select(fromRoot.getAdminFeaturesData).pipe(shareReplay());
  private featuresUpdate$: Subscription;
  private shouldRefetchPool = false;

  addFeatureToTeam() {
    this.checkCurrentPoolForRefetch();
    this.store.dispatch(new adminFeatureActions.AddFeatureForAdminTeam({
      teamId: this.team.id,
      featureId: this.teamFeaturesForm.controls.selectedPoolFeature.value
    }));
  }

  removeFeatureFromTeam(teamId: string, featureId: string) {
    this.checkCurrentPoolForRefetch();
    this.store.dispatch(new adminFeatureActions.RemoveFeatureForAdminTeam({
      teamId: teamId,
      featureId: featureId
    }));
  }

  toggleFeatureForPool(poolFeature: PoolFeature) {
    this.checkCurrentPoolForRefetch();

    if (poolFeature.isEnabled) {
      this.store.dispatch(new adminFeatureActions.DisableFeatureForAdminTeam({teamId: this.team.id, featureId: poolFeature.featureId}));

    } else {
      this.store.dispatch(new adminFeatureActions.EnableFeatureForAdminTeam({teamId: this.team.id, featureId: poolFeature.featureId}));
    }
  }

  private checkCurrentPoolForRefetch() {
    if (this.team.id === this.eventFarmService.currentTeam.id) {
      this.shouldRefetchPool = true;
    }
  }

  async ngOnInit() {
    this.shouldRefetchPool = false;
    this.store.dispatch(new adminFeatureActions.FetchFeaturesForAdminTeam(this.team.id));

    this.teamFeaturesForm = new FormGroup({
      selectedPoolFeature: new FormControl(''),
    });

    // if pool features change and you are editing the current team that you are apart of, fetch the team to grab the updates
    this.featuresUpdate$ = this.features$.pipe().subscribe((val) => {
      if (this.shouldRefetchPool) {
        this.shouldRefetchPool = false;
        this.store.select(fromRoot.selectTeam(this.eventFarmService.currentTeam.id)).subscribe(team => {
          this.store.dispatch(new fromRoot.SetCurrentTeam(team));
        });
      }
    });
  }

  ngOnDestroy() {
    this.shouldRefetchPool = false;
    this.featuresUpdate$.unsubscribe();
  }
}
