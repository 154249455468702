const moment = require('moment');

import { AbstractModel } from '../abstract-model';
import { FormattedDateTime } from '../formatted-datetime';

export class EmailMessage extends AbstractModel {
    private _subject: string = '';
    private _to: string = '';
    private _delivered: string = '';
    private _date: FormattedDateTime;

    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const message = new this(data.id);
        try {
            message.subject = data.attributes.subject;
            message.to = data.attributes.to;
            message.delivered = data.attributes._delivered
                ? data.attributes._delivered
                : 'No';
            message.date = data.attributes.sentFormatted;
        } catch (err) {
        }

        return message;
    }

    set subject(value: string) {
        this._subject = value;
    }

    set to(value: string) {
        this._to = value;
    }

    set delivered(value: string) {
        this._delivered = value;
    }

    set date(value: FormattedDateTime) {
        this._date = value;
    }

    setDateFromDateTime(value: string) {
        this._date = moment(value + 'Z')
            .format('ddd, MMMM Do YYYY, h:mm a');
    }

    get subject(): string {
        return this._subject;
    }

    get to(): string {
        return this._to;
    }

    get delivered(): string {
        return this._delivered;
    }

    get date(): FormattedDateTime {
        return this._date;
    }
}
