import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentWebPresenceTracking extends AbstractBaseSegment {

    /**
     * Theme
     */

    webPresenceSetTheme(alertColor: string, backgroundColor: string, fontColor: string, primaryColor: string, primaryAltColor: string) {
        this.track({
            event: actions.WEB_PRESENCE_SET_THEME,
            properties: {
                alertColor,
                backgroundColor,
                fontColor,
                primaryColor,
                primaryAltColor
            }
        }, true);
    }

    /**
     * Tracking
     *
     */

    webPresenceSetTracking(trackingType: string, value: string) {
        this.track({
            event: actions.WEB_PRESENCE_SET_TRACKING,
            properties: {
                trackingType,
                value
            }
        }, true);
    }

    /**
     * Display Messages
     */

    webPresenceSetDisplayMessage(messageType: string, value: string) {
        this.track({
            event: actions.WEB_PRESENCE_SET_DISPLAY_MESSAGE,
            properties: {
                messageType,
                value
            }
        }, true);
    }

}
