import { tap, take, withLatestFrom, switchMap, filter, map, catchError, mergeMap, concatMap, shareReplay, concatMapTo, flatMap, merge, concat, zip, combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as eventActions from '../../actions/event';
import * as fromStore from '../../app.state';
import { WaitListService } from '../../../EventsModule/WaitList/waitList.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { TicketType } from './../../../../ApiClient/Models/Stack/ticket-type';
import { Stack } from '../../../../ApiClient/Models/Stack/stack';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import {forkJoin, of, throwError} from 'rxjs';
import _ = require("lodash");

@Injectable()
export class AccessTypesEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
    ) { }


    fetchAccessTypes$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.EventAccessAction>(eventActions.FETCH_ACCESS_TYPES),
        withLatestFrom(this.store.select('router')),
        map(([res, router]) => router),
        filter((router) => router.state.params.event),
        map((router) => router.state.params.event),
        switchMap((event) => this.apiClient.getUseCaseFactory().Event().GetEvent(event, ['TicketTypes', 'Stacks', 'StacksWithAvailabilityCounts', 'VirbelaWorld', 'Tags', 'TicketBlocks'])),
        map((event: any) => EfEvent.fromApiResponse(event.data)),
        switchMap((event) => [
            new eventActions.FetchAccessTypesSuccess(event),
            new eventActions.FetchStacksSuccess(event),
        ]),
    ));


    updateStack$ = createEffect(()=> this.actions$.pipe(
        ofType<eventActions.UpdateStack>(eventActions.UPDATE_STACK),
        map(res => res.payload),
        switchMap(stack => {
            return this.apiClient.getUseCaseFactory().Stack().UpdateStackFromSettings(
                stack.id,
                null,
                stack.ticketType.id,
                stack.stackMethodType.isInvitation,
                stack.stackMethodType.isFirstComeFirstServe,
                stack.quantity,
                stack.maxQuantity,
                stack.price,
                stack.serviceFee,
                stack.openingAtFormatted.mutable || stack.openingAtFormatted.short,
                stack.closingAtFormatted.mutable || stack.closingAtFormatted.short,
                stack.isTransferable,
                stack.inviteEmailId,
                stack.confirmEmailId,
                stack.declineEmailId,
                stack.virbelaTeamId,
                stack.virbelaRole,
                stack.expirationStartTimeFormatted.mutable ? stack.expirationStartTimeFormatted.mutable : null,
                stack.expirationEndTimeFormatted.mutable ? stack.expirationEndTimeFormatted.mutable: null,
            ).pipe(
                catchError(err => {
                    this.store.dispatch(new eventActions.UpdateStackFail(err));
                    return throwError(err);
                }),
                concat(this.apiClient.getUseCaseFactory().TicketType().UpdateTicketType(
                    stack.ticketType.id,
                    stack.ticketType.name,
                    stack.quantity,
                    stack.ticketType.displayOrder,
                    "",
                    stack.ticketType.description,
                    stack.ticketType.checkInMessage,
                    _.get(stack, 'ticketType.defaultSitePage.sitePageId'),
                    _.get(stack, 'ticketType.defaultGuestPassCount')
                ).pipe(
                    catchError(err => {
                        return [stack, err];
                    })
                )),
                concat(this.apiClient.getUseCaseFactory().TicketType().SetArrivalAlertEmailsAndPhoneNumbersForTicketType(stack.ticketType.id, stack.ticketType.arrivalAlertEmails, stack.ticketType.shouldSendArrivalAlert, stack.ticketType.arrivalAlertPhoneNumbers)
                .pipe(
                    catchError(err => {
                        return [stack, err];
                    })
                )),
            );
        }),
        switchMap((res) => {
            return [
                new eventActions.FetchAccessTypes()
            ];
        })
    ));


    createStack$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.CreateStack>(eventActions.CREATE_STACK),
        withLatestFrom(this.store.select('router')),
        map(([res, router]) => [res.payload, router.state.params.event]),
        mergeMap(([stack, eventId]) => this.apiClient.getUseCaseFactory().TicketType().CreateTicketType(
            eventId,
            stack.ticketType.name,
            null,
            stack.quantity,
            stack.ticketType.displayOrder,
            false,
            stack.ticketType.description,
            stack.ticketType.checkInMessage,
            null,
            _.get(stack, 'ticketType.defaultSitePage.sitePageId'),
            _.get(stack, 'ticketType.defaultGuestPassCount', null)
        ).pipe(
            map(res => [stack, res])
        )),
        map(([stack, response]) => [stack, response.data.command]),
        mergeMap(([stack, response]) => this.apiClient.getUseCaseFactory().Stack().CreateStackFromSettings(
            response.eventId,
            response.ticketTypeId,
            stack.stackMethodType.isInvitation,
            stack.stackMethodType.isFirstComeFirstServe,
            response.quantity,
            stack.maxQuantity || 10,
            stack.price || 0,
            stack.serviceFee || 0,
            stack.openingAtFormatted.mutable || stack.openingAtFormatted.short,
            stack.closingAtFormatted.mutable || stack.closingAtFormatted.short,
            stack.isTransferable || false,
            null,
            null,
            null,
            null,
            stack.virbelaTeamId,
            stack.virbelaRole,
            stack.expirationStartTimeFormatted.mutable ? stack.expirationStartTimeFormatted.mutable : null,
            stack.expirationEndTimeFormatted.mutable ? stack.expirationEndTimeFormatted.mutable: null,
        ).pipe(
            map(res => [stack, res])
        )),
        map(([stack, response]) => [stack, response.data.command]),
        mergeMap(([stack, response]) => this.apiClient.getUseCaseFactory().TicketType().SetArrivalAlertEmailsAndPhoneNumbersForTicketType(response.ticketTypeId, stack.ticketType.arrivalAlertEmails, stack.ticketType.shouldSendArrivalAlert, stack.ticketType.arrivalAlertPhoneNumbers)),
        switchMap(() => [
            new eventActions.FetchAccessTypes()

        ])
    ));


    createStacksForTicketType$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.CreateStacksForTicketType>(eventActions.CREATE_STACKS_FOR_TICKET_TYPE),
        withLatestFrom(this.store.select('router')),
        map(([res, router]) => [res.payload, router.state.params.event]),
        mergeMap(([payload, eventId]) => this.apiClient.getUseCaseFactory().TicketType().CreateTicketType(
            eventId,
            payload.stack.ticketType.name,
            null,
            payload.stack.quantity,
            payload.stack.ticketType.displayOrder,
            false,
            payload.stack.ticketType.description,
            payload.stack.ticketType.checkInMessage,
            null,
            _.get(payload, 'stack.ticketType.defaultSitePage.sitePageId'),
            _.get(payload, 'stack.ticketType.defaultGuestPassCount')
        ).pipe(
            map(res => [payload, res])
        )),
        map(([payload, response]) => [payload, response.data.command]),
        mergeMap(([payload, response]) => this.apiClient.getUseCaseFactory().Stack().CreateStacksForTicketTypeFromSettings(
            response.eventId,
            response.ticketTypeId,
            payload.timePeriods,
            payload.stack.stackMethodType.isInvitation,
            payload.stack.stackMethodType.isFirstComeFirstServe,
            payload.stack.maxQuantity || 10,
            payload.stack.price || 0,
            payload.stack.serviceFee || 0,
            payload.stack.openingAtFormatted.mutable || payload.stack.openingAtFormatted.short,
            payload.stack.closingAtFormatted.mutable || payload.stack.closingAtFormatted.short,
            payload.stack.isTransferable || false,
            null,
            null,
            null,
        ).pipe(
            map(res => [payload, res])
        )),
        map(([payload, response]) => [payload, response.data.command]),
        mergeMap(([payload, response]) => this.apiClient.getUseCaseFactory().TicketType().SetArrivalAlertEmailsAndPhoneNumbersForTicketType(response.ticketTypeId, payload.stack.ticketType.arrivalAlertEmails, payload.stack.ticketType.shouldSendArrivalAlert, payload.stack.ticketType.arrivalAlertPhoneNumbers)),
        switchMap(() => [
            new eventActions.FetchAccessTypes()
        ])
    ));


    updateStacksForTicketType$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.UpdateStacksForTicketType>(eventActions.UPDATE_STACKS_FOR_TICKET_TYPE),
        map(res => res.payload),
        switchMap(payload => {
            return this.apiClient.getUseCaseFactory().Stack().UpdateStacksForTicketTypeFromSettings(
                payload.eventId,
                payload.stack.ticketType.id,
                payload.timePeriods,
                payload.stack.stackMethodType.isInvitation,
                payload.stack.stackMethodType.isFirstComeFirstServe,
                payload.stack.maxQuantity,
                payload.stack.price,
                payload.stack.serviceFee,
                payload.stack.openingAtFormatted.mutable || payload.stack.openingAtFormatted.short,
                payload.stack.closingAtFormatted.mutable || payload.stack.closingAtFormatted.short,
                payload.stack.isTransferable,
                payload.stack.inviteEmailId,
                payload.stack.confirmEmailId,
                payload.stack.declineEmailId,
            ).pipe(
                catchError(err => {
                    this.store.dispatch(new eventActions.UpdateStacksForTicketTypeFail(err));
                    return throwError(err);
                }),
                concat(this.apiClient.getUseCaseFactory().TicketType().UpdateTicketType(
                    payload.stack.ticketType.id,
                    payload.stack.ticketType.name,
                    payload.stack.quantity,
                    payload.stack.ticketType.displayOrder,
                    "",
                    payload.stack.ticketType.description,
                    payload.stack.ticketType.checkInMessage,
                    _.get(payload, 'stack.ticketType.defaultSitePage.sitePageId'),
                    _.get(payload, 'stack.ticketType.defaultGuestPassCount')
                ).pipe(
                    catchError(err => {
                        return [payload.stack, err];
                    })
                )),
                concat(this.apiClient.getUseCaseFactory().TicketType().SetArrivalAlertEmailsAndPhoneNumbersForTicketType(payload.stack.ticketType.id, payload.stack.ticketType.arrivalAlertEmails, payload.stack.ticketType.shouldSendArrivalAlert, payload.stack.ticketType.arrivalAlertPhoneNumbers).pipe(
                    catchError(err => {
                        return [payload.stack, err];
                    })
                )),
            );
        }),
        switchMap((res) => {
            return [
                new eventActions.FetchAccessTypes()
            ];
        })
    ));



    deleteStack$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.DeleteStack>(eventActions.DELETE_STACK),
        map(res => res.payload),
        mergeMap((stack: Stack) => this.apiClient.getUseCaseFactory().Stack().DeleteStack(stack.id)),
        switchMap((stack: Stack) => [
            new eventActions.FetchAccessTypes()
        ])
    ));


    deleteTicketType$ = createEffect(() => this.actions$.pipe(
        ofType<eventActions.DeleteTicketType>(eventActions.DELETE_TICKET_TYPE),
        map(res => res.payload),
        mergeMap((stack: Stack) => this.apiClient.getUseCaseFactory().TicketType().DeleteTicketType(stack.ticketType.id)),
        switchMap((stack: Stack) => [
            new eventActions.DeleteTicketTypeSuccess(stack),
            new eventActions.FetchAccessTypes()
        ])
    ));
}
