import { EfEvent } from './../../../../ApiClient/Models/Event/event';
import { QuickEventAccess } from './../../EventAccess/QuickEventAccess/quick-event-access.component';
import { Component } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { finalize, shareReplay, filter, tap } from 'rxjs/operators';
import { Modal } from '../../../CoreUI/Modal/modal.class';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../store';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
  template: require('./copy-event-details.html'),
  styles: [require('./copy-event-details.scss')],
})

export class CopyEventDetailsComponent {

  private eventName;
  private eventDetailsForm: FormGroup;
  private eventToCopy;
  private event: EfEvent;
  private loading = false;

  private eventDetails = [
    {slug: 'shouldCopyUsers', name: 'Event Collaborators', value: true},
    {slug: 'shouldCopyEmailDesigns', name: 'Email Designs', value: true},
    {slug: 'shouldCopySettings', name: 'Event Settings', value: true},
    {slug: 'shouldCopyRegQuestions', name: 'Registration Questions', value: true},
    {slug: 'shouldCopySitePages', name: 'Site Pages', value: true},
    {slug: 'shouldCopyTicketTypes', name: 'Access Types', value: true},
    {slug: 'shouldCopyTicketBlocks', name: 'Ticket Blocks', value: true},
  ];

  constructor(
    private eventFarmService: EventFarmService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private apiClient: EventFarmAPIClient,
    private fb: FormBuilder,
    private message: NzMessageService,
    private store: Store<fromRoot.AppState>,
    private segmentService: SegmentService
  ) {
    this.eventName = this.eventFarmService.currentEvent.name;
    this.eventDetailsForm = this.fb.group({});
    this.addToFormGroup();
    this.selectDetailsCheck();

    this.store.select('event').pipe(
      filter(event => !!event.data)
    ).subscribe(event => this.event = event.data);
  }

  private addToFormGroup() {
    this.eventDetails.forEach(control => {
      return this.eventDetailsForm.addControl(control.slug, this.fb.control(control.value));
    });
  }

  private startFromScratch() {

    this.segmentService.segmentEventTracking().startFromScratchSelected();
    this.handleSelection();
  }

  private selectDetailsCheck() {
    this.eventDetailsForm.valueChanges.subscribe(form => {
      if (!form.shouldCopyTicketTypes) {
        this.eventDetailsForm.patchValue({
          shouldCopyTicketBlocks: false,
          shouldCopyStacks: false,
        }, {emitEvent: false});
      }
    });
  }

  private copyDetails() {

    if (!this.eventToCopy) {
      this.message.info('Please select an event');
    }

    this.loading = true;

    const eventDetailsToCopy = {
      eventId: this.eventFarmService.currentEvent.id,
      eventToCopyId: this.eventToCopy.id,
      shouldCopyUsers: this.eventDetailsForm.get('shouldCopyUsers').value,
      shouldCopyEmailDesigns: this.eventDetailsForm.get('shouldCopyEmailDesigns').value,
      shouldCopySettings: this.eventDetailsForm.get('shouldCopySettings').value,
      shouldCopyRegQuestions: this.eventDetailsForm.get('shouldCopyRegQuestions').value,
      shouldCopySitePages: this.eventToCopy.isCanvas ? this.eventDetailsForm.get('shouldCopySitePages').value : false,
      shouldCopyTicketTypes: this.eventDetailsForm.get('shouldCopyTicketTypes').value,
      shouldCopyTicketBlocks: this.eventDetailsForm.get('shouldCopyTicketBlocks').value,
      shouldCopyAccessTypes: this.eventDetailsForm.get('shouldCopyTicketTypes').value,
    };

    this.segmentService.segmentEventTracking().copyEvent(eventDetailsToCopy);

    this.apiClient.getUseCaseFactory().Event().CopyExistingEventConfiguration(
      this.eventToCopy.id,
      this.eventFarmService.currentEvent.id,
      this.eventDetailsForm.get('shouldCopyUsers').value,
      this.eventDetailsForm.get('shouldCopyEmailDesigns').value,
      this.eventDetailsForm.get('shouldCopySettings').value,
      this.eventDetailsForm.get('shouldCopyRegQuestions').value,
      this.eventToCopy.isCanvas ? this.eventDetailsForm.get('shouldCopySitePages').value : false,
      this.eventDetailsForm.get('shouldCopyTicketTypes').value,
      this.eventDetailsForm.get('shouldCopyTicketBlocks').value,
      this.eventDetailsForm.get('shouldCopyTicketTypes').value,
      false, // this.eventDetailsForm.get('shouldCopyIntegrationSettings').value
    )
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (res) => {
          this.message.success(`${this.eventToCopy.name} details copied to this event`);
          setTimeout(() => {
            this.eventFarmService.updateEventDetails();
            this.modalService.closeModal();
          }, 5000)

        },
        (err) => {
          this.message.error(`Something went wrong. Please try again.`);
        });
  }

  private selectEvent(event) {
    this.eventToCopy = event;
  }

  private handleSelection() {
    if (this.event.isCanvas) {
      const modal: Modal = new Modal();
      modal.component = QuickEventAccess;
      modal.cssClass = 'thin-header';
      modal.data = this.event;
      this.modalService.changeModal(modal);
    } else {
      this.modalService.closeModal();
      this.router.navigate([this.router.url.split('?')[0]]);
    }
  }

  private cancelCopy() {
    this.segmentService.segmentEventTracking().cancelCopyEvent();
    this.startFromScratch();
  }

}
