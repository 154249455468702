import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { filter, take, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from '../../../_services/storage/local-storage.service';

@Component({
    selector: 'top-nav',
    template: require('./topNav.html'),
    styles: [require('./topNav.scss')],

})

export class TopNavComponent implements OnInit, OnDestroy {

    constructor(
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private routeGenerator: RouteGeneratorService,
        private localStorageService: LocalStorageService
    ) {
        this.eventRoleType$.subscribe(res => this.eventRoleType = res);
        this.store.select('router').pipe(
            filter(res => Object.keys(res).length !== 0 && Object.keys(res.state.params).length !== 0),
        ).subscribe(route => {
            this.showTopNav = false;
            if (route.state.queryParams.ticketBlockId) {
                this.showTopNav = true;
            }
        });
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventRoleType: any;

    private user$: Subscription;
    private showTopNav: boolean;
    private queueItems = [];

    ngOnInit() {
        this.showTopNav = false;
        if (window['location'].pathname === '/admin') {
            this.showTopNav = true;
        }

        this.localStorageService.resetNavBar.subscribe((resetNav) => {
           if (resetNav === true) {
               this.showTopNav = false;
           }
        });
    }

    ngOnDestroy() {
        this.user$.unsubscribe();
    }

}
