import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {Store} from '@ngrx/store';
import * as moment from 'moment-timezone';
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {shareReplay} from "rxjs/operators";
import {SegmentService} from '../../../../Analytics/Segment/segment.service';
import {Invitation} from '../../../../ApiClient/Models/Invitation/invitation';
import {Stack} from '../../../../ApiClient/Models/Stack/stack';
import {TicketBlock} from "../../../../ApiClient/Models/Stack/ticket-block";
import {EventFarmService} from '../../../eventFarm.service';
import * as fromRoot from '../../../store';
import {EditInvitationComponent} from "../EditInvitation/edit-invitation.component";
import {EditInvitationService} from '../EditInvitation/edit-invitation.service';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListBulkUpdateFormService} from '../GuestListBulkUpdateForm/guest-list-bulk-update-form.service';
import {GuestListFiltersService} from "../GuestListFilters/guest-list-filters.service";


@Component({
    selector: 'guest-list-table',
    template: require('./guest-list-table.html'),
    styles: [require('./guest-list-table.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestListTableComponent implements OnInit {
    @Input() ticketBlock?: TicketBlock;
    private isClipboardHover: boolean = false;
    private drawerRef;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private isDisabled: boolean;
    private isMobile: boolean;

    constructor(
        public eventFarmService: EventFarmService,
        private guestListService: GuestListViewService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private inviteEditService: EditInvitationService,
        private guestListFiltersService: GuestListFiltersService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService,
        private drawerService: NzDrawerService,

    ) {
    }

    private getTicketTypeNameForGuest(guest): string {

        let ticketTypeName = '';
        let expirationTime = '';

        if (!this.eventFarmService.currentEvent) {
            return '';
        }

        // //handling master ticket blocks
        if (guest && guest.ticketBlock) {
            const s = this.eventFarmService.currentEvent.stacks.find(s => s.id === guest.stack.id);

            if (s !== undefined) {
                expirationTime = this.getExpirationString(s);
                ticketTypeName = s.ticketType.name;
            }
        } else {
            ticketTypeName = guest.ticketType.name;
            expirationTime = this.getExpirationString(guest.stack);
        }

        if (expirationTime.length) {
            return `${ticketTypeName} - ${expirationTime}`;
        }

        return ticketTypeName;
    }

    private getExpirationString(stack: Stack): string {

        // since if the expiration start and end time are defaulted to an empty object, we can use object.keys to see if those values have any fields

        if (Object.keys(stack.expirationStartTimeFormatted).length && !Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid From ${stack.expirationStartTimeFormatted.short}`;
        } else if (!Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid Til ${stack.expirationEndTimeFormatted.short}`;
        } else if (Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            if (stack.expirationStartTimeFormatted.date.short === stack.expirationEndTimeFormatted.date.short) {
                return `${stack.expirationStartTimeFormatted.date.short} ${stack.expirationStartTimeFormatted.time.short} to ${stack.expirationEndTimeFormatted.time.short} ${moment().tz(stack.expirationEndTimeFormatted.timezone).zoneAbbr()}`;
            } else {
                return `${stack.expirationStartTimeFormatted.short} to ${stack.expirationEndTimeFormatted.short}`;
            }
        }

        return '';
    }

    ngOnInit(): void {
        if (window.screen.width === 1025) { // 768px portrait
            this.isMobile = true;
        }

        this.eventRoleType$.subscribe(val =>{
            if (!this.guestListService.ticketBlock && !val['list-view']){
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
            }
        })
    }

    handleClipboardHover(val: boolean) {
        if (val) {
            if (!this.isClipboardHover) {
                this.isClipboardHover = true;
            }
        } else {
            if (this.isClipboardHover) {
                this.isClipboardHover = false;
            }
        }
    }

    trackGuest(index: number, guest: Invitation) {
        return guest.id;
    }

    openInvitationSlideout(invitation: Invitation) {
        if(this.isDisabled) { return; }

        this.drawerRef = this.drawerService.create<EditInvitationComponent, { invitationId: string }, Invitation>({
            nzTitle: '',
            nzContent: EditInvitationComponent,
            nzWidth: '50%',
            nzContentParams: {
                invitationId: invitation.id
            },
            nzClosable: false
        });

        this.drawerRef.afterClose.subscribe(async data => {
            if (data) {
                await this.guestListService.assembleGuestListData();
            }
        });

    }

    rowClicked(invitation: Invitation) {
        // We only support bulk edit in regular mode, not Ticket Block
        this.openInvitationSlideout(invitation)
    }
}
