import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { PaymentsPromotionsService } from './payments-promotions.service';

@Component({
    selector: 'payments-promotions',
    template: require('./payments-promotions.html'),
    styles: [require('./payments-promotions.scss')],
})

export class PaymentsPromotionsComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private paymentsPromoService: PaymentsPromotionsService
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {

        this.segmentService.segmentSideNavTracking().promoCodesEntered();

    }

    ngOnDestroy() {
    }
}
