import { Component, Input } from '@angular/core';
import { ngxChartsOptions, ngxPieChartsOptions } from '../../../../Shared/ngx-charts-options';

@Component({
    selector: 'ef-pie-chart',
    template: require('./ef-pie-chart.html'),
    styles: [require('./ef-pie-chart.scss')],
})

export class PieChartComponent {
    @Input() chartData;
    @Input() showLegend: boolean = true;
    @Input() showLabels: boolean = true;
    @Input() view: number[] = [400,300];
    
    readonly ngxChartsOptions = ngxChartsOptions;
    readonly ngxPieChartsOptions = ngxPieChartsOptions;
    
    constructor() {
    }
}
