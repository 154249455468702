import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable ,  Subscription ,  BehaviorSubject } from 'rxjs';
import { take, shareReplay } from 'rxjs/operators';

import { EventFarmService } from '../../../eventFarm.service';
import { AccountInfoService } from '../account-info.service';

import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../ApiClient/Models/User/user';
import { SessionStorageService } from '../../../../_services/storage/session-storage.service';
import { Team } from '../../../../ApiClient/Models/Pool/team';
import { RequestUpgradeComponent } from '../RequestUpgrade/request-upgrade.component';
import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { AlertService } from '../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import swal from 'sweetalert2';
import { WebConferenceUserConnection } from '../../../../ApiClient/Models/WebConferenceUserConnection/web-conference-user-connection';

@Component({
    selector: 'integrations-link',
    template: require('./integrations-link.html'),
    styles: [require('./integrations-link.scss')],
})

export class IntegrationsLinkComponent implements OnInit {
    constructor(
        private accountInfoService: AccountInfoService,
        private eventFarmService: EventFarmService,
        private sessionStorageService: SessionStorageService,
        private modalService: ModalService,
        private routeGenerator: RouteGeneratorService,
        private alertService: AlertService,
        private router: Router,
        private message: NzMessageService,
        public route: ActivatedRoute
    ) {
    }
    readonly salesforceLogo = require('../assets/salesforce');
    readonly salesforceLogoDisabled = require('../assets/salesforce-disabled');
    readonly salesforceLogoActive = require('../assets/salesforce-active');
    readonly otherIntegrations = require('../assets/other-integrations');
    private features: any;
    private loading: boolean = true;
    private error: boolean = false;
    private connectAuth: boolean = false;

    ngOnInit() {
        this.accountInfoService.features$.pipe(take(1)).subscribe(features => {
            this.features = features;
            this.bootstrapInitialIntegrationSettings(this.features);
        });

        // TODO revert and fix
         this.fetchSalesforceLoginUrl();
    }

    private fetchSalesforceLoginUrl() {
        this.accountInfoService.fetchSalesforceLoginUrl().toPromise()
            .then((res) => {
                this.accountInfoService.salesforceLoginUrl = res['data']['salesforceLoginUrl'];
            }).catch((err) => {
                this.loading = false;
                this.error = true;
        });
    }

    async updateVirbelaSettings() {
        await this.alertService.virbelaIntegration().updateVirbelaSettings(this.eventFarmService.currentTeam.id, this.accountInfoService.integrationsTeamConfig.Virbela.poolWorlds);
        await this.accountInfoService.listVirbelaPoolWorlds(this.eventFarmService.currentTeam);
    }

    async updateWebConferenceConnections() {
        await this.alertService.webConferenceIntegration().manageUserConnections(this.accountInfoService.integrationsTeamConfig.WebConference.poolConnections, this.deauthorizeConnection, this);
    }

    async deauthorizeConnection(connection: WebConferenceUserConnection, current: IntegrationsLinkComponent) {
        await current.accountInfoService.deauthorizeConnection(connection);
        current.updateWebConferenceConnections();
    }

    async authorizeConnection(type: string, current: IntegrationsLinkComponent) {
        if (type === 'zoom') {
            current.accountInfoService.connectToZoom();
        }
    }


    async bootstrapInitialIntegrationSettings(features) {
        features.forEach(async (feature) => {
            if (feature.error) {
                this.error = true;
            }
            if (feature.isSalesforce) {
                this.accountInfoService.integrationsTeamConfig.Salesforce = {
                    enabled: feature.isEnabled,
                    active: false,
                    settings: {},
                };
            }

            if (feature.isVirbela) {
                this.accountInfoService.integrationsTeamConfig.Virbela = {
                    enabled: feature.isEnabled,
                    active: false,
                    settings: {},
                    poolWorlds:[]
                };
            }

            if (feature.isWebConference) {
                this.accountInfoService.integrationsTeamConfig.WebConference = {
                    enabled: feature.isEnabled,
                    active: false,
                    settings: {},
                    poolConnections:[],
                    isCurrentUserConnectedZoom: false
                };
            }
        });


        await this.accountInfoService.listVirbelaPoolWorlds(this.eventFarmService.currentTeam);

        this.accountInfoService.listWebConferenceUserConnectionsForTeam(this.eventFarmService.currentTeam).then(
            _ => {
                this.completeOauthIntegrationSetup()
            }
        );

        this.accountInfoService.fetchSalesforcePoolSettings(this.eventFarmService.currentTeam)
            .then((res) => {
                return;
            }).catch((err) => {
                return;
            }).then((res) => {
                this.loading = false;
                this.completeOauthIntegrationSetup();
        });
    }



    async completeOauthIntegrationSetup() {
        if (this.sessionStorageService.get('globalSalesforceIntegrationsAuth&user=' + this.eventFarmService.currentUser.id + '&team=' + this.eventFarmService.currentTeam.id)) {
            this.sessionStorageService.remove('globalSalesforceIntegrationsAuth&user=' + this.eventFarmService.currentUser.id + '&team=' + this.eventFarmService.currentTeam.id);
            this.accountInfoService.integrationsTeamConfig.Salesforce.settings.connecting = true;
            this.router.navigateByUrl(this.routeGenerator.url('pools.my-account.salesforce', {poolId: this.eventFarmService.currentTeam.id}));
        }

        if (this.sessionStorageService.get('globalZoomIntegrationsAuth&user=' + this.eventFarmService.currentUser.id + '&team=' + this.eventFarmService.currentTeam.id)) {
            this.sessionStorageService.remove('globalZoomIntegrationsAuth&user=' + this.eventFarmService.currentUser.id + '&team=' + this.eventFarmService.currentTeam.id);
            this.message.success(`Completing Zoom authentication...`);
        }
    }

    goToLink(platform: string) {
        if (platform === 'Mac') {
            window.open(this.accountInfoService.integrationsTeamConfig.Virbela.settings.attributes.macDownloadURL, '_blank');
        } else {
            window.open(this.accountInfoService.integrationsTeamConfig.Virbela.settings.attributes.windowsDownloadURL, '_blank');
        }
    }

    private requestUpgrade(selectedFeature) {
        this.features.map(feature => {
            if (feature.name === selectedFeature) {
                feature.isSelected = true;
            }
        });
        const modal: Modal = new Modal();
        modal.title = 'Request Upgrade';
        modal.component = RequestUpgradeComponent;
        modal.data = this.features;
        modal.cssClass = 'full-width';
        this.modalService.changeModal(modal);
    }

    private requestZapier() {
        this.alertService.general().requestAccessToFeature().then((res) => {
            if (res.value) {
                this.accountInfoService.requestUpgrade(null, [], 'Zapier early access request');
                this.message.success('Someone will be in touch shortly');
            }
        });
    }

    private selectWorld(world) {
        this.accountInfoService.setPoolSettingFromSearch({
            attributes: world
        });
        this.updateVirbelaSettings();
    }

    reset() {
        this.loading = true;
        this.bootstrapInitialIntegrationSettings(this.features);
    }
}
