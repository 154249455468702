import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Group } from '../../../ApiClient/Models/Group/group';
import { EventFarmService } from '../../eventFarm.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../../ApiClient/Models/User/user';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class AddressBookService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
    ) {
    }

    public selectedGroupSource = new Subject<Group>();
    public selectedGroup$ = this.selectedGroupSource.asObservable();
    public selectedGroup?: Group;
    public displayedForEditingGroup?: Group;
    public addedByMeGroup?: Group;

    public selectedUserSource = new Subject<User>();
    public selectedUser$ = this.selectedUserSource.asObservable();
    public selectedUser?: User;
    public usersSelectedInList: string[];

    public globalSearchTerm: string;

    public currentTeam?: Team;
    public isLoadingContacts: boolean;
    public userHasRestrictedAccess: boolean;

    public broadcastGroup(group: Group) {
        this.isLoadingContacts = true;
        this.apiClient.getUseCaseFactory()
            .Group()
            .GetGroup(group.id, ['totalUsersInGroup'])
            .subscribe((res) => {
                this.selectedGroup = Group.fromApiResponse(res.data);
                this.selectedGroupSource.next(this.selectedGroup);
            });
    }

    public broadcastClearGroup() {
        this.selectedGroup = null;
        this.selectedGroupSource.next(this.selectedGroup);
        this.isLoadingContacts = false;
    }

    public broadcastUser(user: User) {
        this.selectedUser = user;
        this.selectedUserSource.next(this.selectedUser);
    }

    public getInvitationCountsForSelectedUser() {
        return this.apiClient.getUseCaseFactory()
            .Invitation()
            .GetInvitationCountsForUser(this.selectedUser.id, this.eventFarmService.currentTeam.id)
            .pipe(map((res) => res['data']))
            .toPromise();
    }

    public openAddUserModal() {
        const poolId = this.eventFarmService.currentTeam.id;
        this.router.navigate([this.routeGenerator.url('pools.address-book.list', { poolId }), {outlets: { 'add-user-outlet' : ['add-user']}}]);
    }

    public openEditUserModal(userId: string, tab = null) {
        const poolId = this.eventFarmService.currentTeam.id;
        if (tab) {
            this.router.navigate([this.routeGenerator.url('pools.address-book.list', { poolId }), {outlets: { 'edit-user-outlet' : ['edit-user', userId]}}], { queryParams: { tab }});
        } else {
            this.router.navigate([this.routeGenerator.url('pools.address-book.list', { poolId }), {outlets: { 'edit-user-outlet' : ['edit-user', userId]}}]);
        }
    }

    public openAddGroupModal() {
        const poolId = this.eventFarmService.currentTeam.id;
        this.router.navigate([this.routeGenerator.url('pools.address-book.list', { poolId }), {outlets: { 'add-group-outlet' : ['add-group']}}]);
    }

    public openEditGroupModal(groupId: string) {
        const poolId = this.eventFarmService.currentTeam.id;
        this.router.navigate([this.routeGenerator.url('pools.address-book.list', { poolId }), {outlets: { 'edit-group-outlet' : ['edit-group', groupId]}}]);
    }

    public goToUploader() {
        const poolId = this.eventFarmService.currentTeam.id;
        this.router.navigateByUrl(this.routeGenerator.url('pools.uploader', { poolId }));
    }
}
