import { Component, Input } from '@angular/core';
import { SalesforceEventIntegrationsService } from '../eventIntegrations.service';
import { MappingService } from '../mapping.service';

@Component({
    selector: 'lead-field-mappings',
    template: require('./leadFieldMappings.html'),
    styles: [require('./leadFieldMappings.scss')],

})

export class LeadFieldMappingsComponent {

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private _ms: MappingService,
    ) {
    }

    @Input() eventSettings;

    private title: string = 'Lead Field Mappings';

    leadImportMappings: Object[] = this._ms.leadImportMappings;
    loadingLeadImportMappings: boolean;
    leadExportMappings: Object[] = this._ms.leadExportMappings;
    efImportLeadFields: any[] = this._ms.efImportLeadFields;
    efExportLeadFields: any[] = this._ms.efExportLeadFields;
    intImportLeadFields: any[] = this._ms.intImportLeadFields;
    intExportLeadFields: any[] = this._ms.intExportLeadFields;
    updateRules: Object[] = this._ms.getUpdateRules();
    customLeadFieldsToImport: Object[] = this._ms.customLeadFieldsToImport;
    customLeadFieldsToExport: Object[] = this._ms.customLeadFieldsToExport;
    flow = this._sfeis.eventSettings.dataFlow;

    maxLeadImportFields = [];
    maxLeadExportFields = [];

    ngOnInit() {
        this.loadingLeadImportMappings = true;

        this._sfeis.getImportLeadFieldMappings(this.eventSettings.salesforceConfig.id)
            .subscribe((res) => {
                this._ms.leadImportMappings = res['data'];
                this.appendImportLeadField();
                this.checkImportFieldsAvailable();
                this.loadingLeadImportMappings = false;
            });

        this._sfeis.getExportLeadFieldMappings(this.eventSettings.salesforceConfig.id)
            .subscribe((res) => {
                this._ms.leadExportMappings = res['data'];
                this.appendExportLeadField();
                this.checkExportFieldsAvailable();
            });
    }

    ngDoCheck() {
        this.checkImportFieldsAvailable();
        this.checkExportFieldsAvailable();
        this.leadImportMappings = this._ms.leadImportMappings;
        this.leadExportMappings = this._ms.leadExportMappings;
        this.efImportLeadFields = this._ms.efImportLeadFields;
        this.efExportLeadFields = this._ms.efExportLeadFields;
        this.intImportLeadFields = this._ms.intImportLeadFields;
        this.intExportLeadFields = this._ms.intExportLeadFields;
        this.customLeadFieldsToImport = this._ms.customLeadFieldsToImport;
        this.maxLeadImportFields = this.efImportLeadFields.filter(this.isSelected);
        this.maxLeadExportFields = this.efExportLeadFields.filter(this.isSelected);
    }

    // UPDATING AVAILABLE EF FIELDS TO CHOOSE FROM
    availableEfImportLeadFields: Object[] = [];
    availableEfExportLeadFields: Object[] = [];

    isSelected(obj) {
        return !obj.selected;
    }

    appendImportLeadField() {
        this._ms.leadImportMappings.forEach((leadImportItem: any) => {
            if (leadImportItem.attributes.fieldId.includes('salesforce.')) {
                const id = this.efImportLeadFields.findIndex((efImportLead: any) => {
                    return efImportLead.attributes.fieldId === leadImportItem.attributes.fieldId;
                });
                if (id === -1) {
                    this._ms.efImportLeadFields.push({
                        attributes: {
                            slug: 'newCustomField',
                            fieldName: leadImportItem.attributes.fieldName,
                            fieldId: leadImportItem.attributes.fieldId
                        }, selected: false
                    });
                }  
            }
        });
    }

    appendExportLeadField() {
        this._ms.leadExportMappings.forEach((leadExportItem: any) => {
            if (leadExportItem.attributes.fieldId.includes('salesforce.')) {
                const id = this.efExportLeadFields.findIndex((efExportLead: any) => {
                    return efExportLead.attributes.fieldId === leadExportItem.attributes.fieldId;
                });
                if (id === -1) {
                    this._ms.efExportLeadFields.push({
                        attributes: {
                            slug: 'newCustomField',
                            fieldName: leadExportItem.attributes.fieldName,
                            fieldId: leadExportItem.attributes.fieldId
                        }, selected: false
                    });
                }  
            }
        });
    }

    checkImportFieldsAvailable() {
        for (const obj of this.efImportLeadFields) {
            obj.selected = false;
            for (const f of this.customLeadFieldsToImport) {
                if (obj['attributes'].slug === f['ef'].attributes.slug) {
                    obj.selected = true;
                }
            }
            for (const f of this.leadImportMappings) {
                if (obj.attributes.fieldName === f['attributes'].fieldName) {
                    obj.selected = true;
                }
            }
        }

        for (const obj of this.intImportLeadFields) {
            obj['selected'] = false;
            for (const f of this.customLeadFieldsToImport) {
                if (obj['attributes'].fieldId === f['int']['attributes'].fieldId) {
                    obj['selected'] = true;
                }
            }
            for (const f of this.leadImportMappings) {
                if (obj.attributes.fieldId === f['attributes'].integrationFieldValue) {
                    obj['selected'] = true;
                }
            }
        }

        this.availableEfImportLeadFields = this.efImportLeadFields.filter(this.isSelected);
    }

    checkExportFieldsAvailable() {
        for (const obj of this.efExportLeadFields) {
            obj.selected = false;
            for (const f of this.customLeadFieldsToExport) {
                if (obj.attributes.slug === f['ef'].attributes.slug) {
                    obj.selected = true;
                }
            }
            for (const f of this.leadExportMappings) {
                if (obj.attributes.fieldName === f['attributes'].fieldName) {
                    obj.selected = true;
                }
            }
        }

        for (const obj of this.intExportLeadFields) {
            obj['selected'] = false;
            for (const f of this.customLeadFieldsToExport) {
                if (obj['attributes'].fieldId === f['int']['attributes'].fieldId) {
                    obj['selected'] = true;
                }
            }
            for (const f of this.leadExportMappings) {
                if (obj.attributes.fieldId === f['attributes'].integrationFieldValue) {
                    obj['selected'] = true;
                }
            }
        }

        this.availableEfExportLeadFields = this.efExportLeadFields.filter(this.isSelected);
    }

    saveNewField(row, type) {
        this._ms.saveAndCheckForCustomField(row, type);
    }

    hasRemainingImportLeadFields() {
        for (const obj of this.efImportLeadFields) {
            if (!obj.selected) {
                return true;
            }
        }
        return false;
    }

    hasRemainingExportLeadFields() {
        for (const obj of this.efExportLeadFields) {
            if (!obj.selected) {
                return true;
            }
        }
        return false;
    }

    addMapping(integrationSettingType, fieldMappingType) {

        if (fieldMappingType.includes('import-lead')) {

            this.checkImportFieldsAvailable();
            this._ms.updateEventFarmFields(this.efImportLeadFields, 'import-lead');

            if (this.customLeadFieldsToImport.length < this.maxLeadImportFields.length) {
                this.customLeadFieldsToImport
                    .push(
                        {
                            integrationSettingType,
                            fieldMappingType: integrationSettingType + '-' + fieldMappingType,
                            ef: {attributes: {slug: {}}},
                            int: {attributes: {fieldId: {}}},
                        },
                    );
            }
        }

        if (fieldMappingType.includes('export-lead')) {

            this.checkExportFieldsAvailable();
            this._ms.updateEventFarmFields(this.efExportLeadFields, 'export-lead');

            if (this.customLeadFieldsToExport.length < this.maxLeadExportFields.length) {

                this.customLeadFieldsToExport
                    .push(
                        {
                            integrationSettingType,
                            fieldMappingType: integrationSettingType + '-' + fieldMappingType,
                            ef: {attributes: {slug: {}}},
                            int: {attributes: {fieldId: {}}},
                        },
                    );
            }
        }
    }

    updateRule(row) {
        this._ms.updateRule(row.id, row.attributes.updateRule.slug);
    }

    updateEfField(field) {
        this.checkImportFieldsAvailable();
        this.checkExportFieldsAvailable();
        field.selected = true;
        this._ms.updateEfField(field);
    }

    updateIntField(field) {
        this._ms.updateIntField(field);
    }

    deleteMapping(row, flow) {

        if (flow.includes('import-lead')) {
            const i = this._ms.leadImportMappings.indexOf(row);
            this._ms.leadImportMappings.splice(i, 1);
            this.checkImportFieldsAvailable();

            this.efImportLeadFields = this._ms.removingCustomField(this.efImportLeadFields, row);
        }

        if (flow.includes('export-lead')) {
            const i = this._ms.leadExportMappings.indexOf(row);
            this._ms.leadExportMappings.splice(i, 1);
            this.checkExportFieldsAvailable();

            this.efExportLeadFields = this._ms.removingCustomField(this.efExportLeadFields, row);
        }

        this._ms.deleteMapping(row);

    }

    removeRow(row, flow) {

        row.ef.selected = false;
        row.int.selected = false;

        if (flow.includes('import-lead')) {
            const i = this.customLeadFieldsToImport.indexOf(row);
            this.customLeadFieldsToImport.splice(i, 1);
            this.checkImportFieldsAvailable();
        }

        if (flow.includes('export-lead')) {
            const i = this.customLeadFieldsToExport.indexOf(row);
            this.customLeadFieldsToExport.splice(i, 1);
            this.checkExportFieldsAvailable();
        }

    }

}
