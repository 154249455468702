
import { filter, shareReplay } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, QueryList, ViewEncapsulation, ViewChildren } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { PopoverDirective } from 'ngx-bootstrap/popover';

import { TicketType } from '../../../ApiClient/Models/Stack/ticket-type';
import { AlertService } from '../../eventFarmAlert.service';
import { Subscription ,  Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { AngularRestClient } from '../../../_services/rest/AngularRestClient';
@Component({
    selector: 'ticket-type-list',
    template: require('./ticket-type-list.html'),
    styles: [require('./ticket-type-list.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class TicketTypeListComponent implements OnInit, OnDestroy {
    readonly ellipsis = require('./assets/ellipsis');
    private collapsedRows: boolean[];
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventUpdate$: Subscription;
    @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;

    constructor(
        private eventFarmService: EventFarmService,
        private legacyUIService: LegacyUIService,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
        private store: Store<fromRoot.AppState>,
        private alertService: AlertService,
        private http: AngularRestClient,
    ) {
        this.collapsedRows = [];
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.generateRowCollapseSettings();
        });
    }

    ngOnInit() {
        this.generateRowCollapseSettings();
    }

    generateRowCollapseSettings() {
        if (this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.ticketTypes) {
            this.eventFarmService.currentEvent.ticketTypes.forEach((ticketType) => {
                this.collapsedRows.push(false);
            });
        }
    }

    removeTicketType(ticketType: TicketType): void {
        const that = this;
        this.alertService.ticketType().confirmTicketTypeDeletion()
            .then((res) => {
                if (res.value) {

                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                            'X-Requested-With': 'XMLHttpRequest'
                        })
                    };

                    this.http
                        .post('/tickettypes/removeTickettype/' + ticketType.id, {'delete': true}, httpOptions)
                        .subscribe(
                            (data) => {
                                if (data['result']) {
                                    this.alertService.ticketType().ticketTypeRemovedConfirmation();
                                    this.message.success('Access type removed');
                                    that.eventFarmService.updateEventDetails(that.eventFarmService.currentEvent.id);
                                } else {
                                    this.alertService.ticketType().unableToRemove();
                                }
                            },
                        );
                }
        });
    }

    getStackUsed(ticketTypeId: string): number {
        let total = 0;
        this.eventFarmService.currentEvent.stacks.forEach((stack) => {
            if (stack.ticketType.id === ticketTypeId) {
                total += stack.availabilityCounts.totalUsed;
            }
        });
        return total;
    }

    closePopovers() {
        this.popovers.forEach((popover: PopoverDirective) => {
            popover.hide();
        });
    }

    getStackConfirmed(ticketTypeId: string): number {
        let total = 0;
        this.eventFarmService.currentEvent.stacks.forEach((stack) => {
            if (stack.ticketType.id === ticketTypeId) {
                total += stack.availabilityCounts.totalAffirmed;
            }
        });
        return total;
    }

    getStatusTypeCount(ticketTypeId: string, statusTypeSlug: string): number {
        let total = 0;
        this.eventFarmService.currentEvent.stacks.forEach((stack) => {
            if (stack.ticketType.id === ticketTypeId) {
                total += stack.availabilityCounts[statusTypeSlug];
            }
        });
        return total;
    }

    getInvitationStatusTypes(): string[] {
        let statusTypeSlugs = [];
        let statusTypes = this.apiClient.getTypeFactory().Invitation().InvitationStatusType();
        statusTypes.forEach((statusType) => {
            statusTypeSlugs.push(statusType.slug);
        });
        return statusTypeSlugs;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }

}
