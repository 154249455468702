
import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Subscription ,  Observable } from 'rxjs';
import { filter, take, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Tag } from '../../../../ApiClient/Models/Event/tag';
import { isValidEmail } from '../../../../Utilities/validateEmail';
import * as _ from 'lodash';

@Component({
    selector: 'event-details',
    template: require('./event-details.html'),
    styles: [require('./event-details.scss')],
})
export class EventDetailsComponent implements OnInit {
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;
    private isEditingContact: boolean;
    private newTag: string = '';
    private contactEmail: string;
    private tags: any[];

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
        });
    }

    ngOnInit() {
        this.isEditingContact = false;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }

    private selectTag(tag: string): void {
        this.newTag = tag;
    }

    cancelEdit() {
        this.isEditingContact = !this.isEditingContact;
    }

    deleteTag(slug: string) {
        this.apiClient.getUseCaseFactory().Event().RemoveTagFromEvent(this.eventFarmService.currentEvent.id, slug).toPromise().then( (res) => {
            const updatedTags = this.eventFarmService.currentEvent.tags.filter(tag => tag.slug !== slug);
            this.eventFarmService.currentEvent.tags = updatedTags;
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.message.success('Tag removed from event');
        }).catch((err) => {
            this.message.error('Please try again');
        });
    }

    addTag() {
        this.apiClient.getUseCaseFactory().Event().AddTagToEvent(this.eventFarmService.currentEvent.id, this.newTag).toPromise().then( (res) => {
            this.eventFarmService.currentEvent.tags.push(Tag.fromNewTagCreation(res.data.command));
            this.newTag = '';
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.message.success('Tag added to event');
        }).catch((err) => {
            this.message.error('Please try again');
        });
    }


    get currentTags(): Tag[] {
        return _.get(this,'eventFarmService.currentEvent.tags', []);
    }

    editContact() {

        if (this.isEditingContact) {
            if (this.contactEmail.length && isValidEmail(String(this.contactEmail).toLowerCase())) {
                this.apiClient.getUseCaseFactory().Event().SetContactEmailForEvent(this.eventFarmService.currentEvent.id, this.contactEmail).toPromise().then( () => {
                    this.eventFarmService.currentEvent.contactEmail = this.contactEmail;
                    this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                    this.isEditingContact = !this.isEditingContact;
                    this.message.success('Contact updated');
                }).catch((err) => {
                    this.message.error('Please try again');
                });
            }
        } else {
            this.contactEmail = this.eventFarmService.currentEvent.contactEmail;
            this.isEditingContact = !this.isEditingContact;
        }
    }

}
