import { Component, EventEmitter, Output, HostBinding, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { debounceTime, map, tap, distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'timezone-typeahead',
    template: require('./timezone-typeahead.html'),
    animations: [
        trigger('leavingState', [
            transition(':enter', [
                animate(0, style({ opacity: 0, top: '-1rem' })),
                animate('100ms ease-out', style({ opacity: 1, top: '0' })),
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0 })),
            ])
        ])
    ],
    host: {
        '[@leavingState]': '',
        '(document:click)': 'offClick($event)',
    }
})

export class TimezoneTypeaheadComponent implements OnInit {
    private searchTerm: FormControl = new FormControl();
    private searchResults$ = new Subject<void>();
    private isSearching: boolean = false;
    @Output() selectionReceived: EventEmitter<any> = new EventEmitter();
    @Input() timezone: string;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.searchTerm.setValue(this.timezone);
        this.searchForTimeZone();

    }

    @HostBinding('class.active') activeInput: boolean;

    private searchForTimeZone() {
        this.searchTerm.valueChanges
            .pipe(debounceTime(200),
                tap(() => { this.isSearching = true; this.activeInput = true; }),
                distinctUntilChanged()
            ).subscribe((term) => {
                this.apiClient.getUseCaseFactory().Region().ListTimezonesForRegion(term).subscribe(res => {
                    this.isSearching = false;
                    if (term) {
                        this.searchResults$.next(res.data.attributes);
                    } else {
                        this.searchResults$.next();
                    }
                }, (err) => this.isSearching = false);
            });
    }

    private selectOption(option) {
        this.selectionReceived.emit(option);
        this.timezone = option;
        this.activeInput = false;
    }

    private offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.activeInput = false;
        }
    }
}
