import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AccordionModule} from 'ngx-bootstrap/accordion';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagTypeaheadComponent } from './tag-typeahead.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [TagTypeaheadComponent],
    exports: [TagTypeaheadComponent],
    imports: [
        CommonModule,
        AccordionModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUtilitiesModule
    ],
})

export class TagTypeaheadModule {
}
