import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../../../../../../../ApiClient/Models/User/user';
import {isValidEmail, ValidateEmail, validateEmailRegex} from '../../../../../../../Utilities/validateEmail';
import { validatePhoneRegex } from '../../../../../../../Utilities/validatePhoneNumber';
import { ExhibitorTeamMembersService } from '../exhibitors-team-members.service';

interface FormValues {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

@Component({
    selector: 'exhibitor-team-member-add',
    template: require('./exhibitor-team-member-add.html'),
    styles: [require('./exhibitor-team-member-add.scss')],
})
export class ExhibitorTeamMemberAddComponent implements OnInit {

    @Output() addUser: EventEmitter<User> = new EventEmitter();
    @Output() cancelAddUser: EventEmitter<any> = new EventEmitter();

    public form: FormGroup;

    constructor(
        private teamMembersService: ExhibitorTeamMembersService,
        private message: NzMessageService,
        private formBuilder: FormBuilder
    ) { }

    public ngOnInit() {

        this.form = this.formBuilder.group({
            email: [null, [Validators.required, ValidateEmail]],
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            phone: [null]
        })

    }

    public async cancel() {
        this.cancelAddUser.emit();
    }

    public async save() {

        Object.values(this.form.controls).forEach(c => {
            c.markAsTouched();
            c.markAsDirty();
            c.updateValueAndValidity();
        });
        
        if (this.form.invalid)
            return;

        let { email, firstName, lastName, phone } = this.form.value as FormValues;
        if(phone){
            phone = phone['e164Number']
        }
        const user = await this.teamMembersService.createUser(email.trim(), firstName, lastName, phone)
        this.message.success('User Added To Team');
        this.addUser.emit(user);
    }

    public onBlur(key: string) {
        const control = this.form.get(key);
        control.markAsDirty();
        control.updateValueAndValidity();
    }
}
