
import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Subscription ,  Observable } from 'rxjs';
import { filter, take, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Link, LinkTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Link';
import { EFLink } from '../../../../ApiClient/Models/Link/ef-link';



@Component({
    selector: 'event-advanced-details',
    template: require('./event-advanced-details.html'),
    styles: [require('./event-advanced-details.scss')],
})
export class EventAdvancedDetailsComponent implements OnInit {
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;
    private isEditingLanguage: boolean;
    private isEditingFacebook: boolean;
    private isEditingInstagram: boolean;
    private isEditingTwitter: boolean;
    private isEditingDescription: boolean;

    private language: string;
    private facebook: string;
    private instagram: string;
    private twitter: string;
    private description: string;

    private languages = [{name: 'English', slug: 'eng'},
    {name: 'Deutsch (German)', slug: 'deu'},
    {name: 'Español (Spanish - European)', slug: 'spa'},
    {name: 'Español (Spanish - South American)', slug: 'es-mx'},
    {name: '中国的 (Chinese [Mandarin])', slug: 'zh-cn'},
    {name: '中文 (Chinese [Traditional])', slug: 'zho'},
    {name: 'Français (French)', slug: 'fra'},
    {name: 'עברית (Hebrew)', slug: 'heb'},
    {name: 'Italiano (Italian)', slug: 'ita'},
    {name: '日本語 (Japanese)', slug: 'jpn'},
    {name: '한국말 (Korean)', slug: 'kor'},
    {name: 'Polski (Polish)', slug: 'pol'},
    {name: 'Português (Portuguese)', slug: 'por'},
    {name: 'ภาษาเขียน (Thai)', slug: 'tha'}];

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,

    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
        });
    }

    ngOnInit() {

        this.isEditingLanguage = false;
        this.isEditingFacebook = false;
        this.isEditingInstagram = false;
        this.isEditingTwitter = false;
        this.isEditingDescription = false;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }

    get currentLanguage(): string {
        const l = this.eventFarmService.currentEvent.language ? this.languages.filter(lang => lang.slug === this.eventFarmService.currentEvent.language)[0].name : 'English';
        return l;
    }

    cancelEdit(slug: string) {
        switch (slug) {
            case('language'):
                this.isEditingLanguage = !this.isEditingLanguage;
                break;
            case('facebook'):
                this.isEditingFacebook = !this.isEditingFacebook;
                break;
            case('instagram'):
                this.isEditingInstagram = !this.isEditingInstagram;
                break;
            case('twitter'):
                this.isEditingTwitter = !this.isEditingTwitter;
                break;
            case('description'):
                this.isEditingDescription = !this.isEditingDescription;
                break;
            default:
                console.log('should never get here');
        }

    }

    async editField(slug: string) {

        switch (slug) {
            case('language'):
                if (this.isEditingLanguage) {
                    this.apiClient.getUseCaseFactory().Event().SetLanguageForEvent(this.eventFarmService.currentEvent.id, this.language).toPromise().then( () => {
                        this.eventFarmService.currentEvent.language = this.language;
                        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                        this.isEditingLanguage = !this.isEditingLanguage;
                        this.message.success('Language updated');
                    }).catch((err) => {
                        this.message.error('Please try again');
                    });
                } else {
                    this.language = this.eventFarmService.currentEvent.language ? this.eventFarmService.currentEvent.language : 'eng';
                    this.isEditingLanguage = !this.isEditingLanguage;
                }
                break;
            case('facebook'):
                if (this.isEditingFacebook) {
                    const shownText = this.facebook;
                    const url = 'https://www.facebook.com/' + this.facebook;

                    if (this.eventFarmService.currentEvent.facebookHandle) {
                        const linkId = this.eventFarmService.currentEvent.facebookHandle.id;

                        if (shownText.length) {
                            const link = await this.updateSocialLink(linkId,shownText, url);
                            this.eventFarmService.currentEvent.facebookHandle = link;
                        } else {
                            await this.removeSocialLink(linkId);
                            this.eventFarmService.currentEvent.facebookHandle = null;
                        }
                    } else {
                        if (shownText.length) {
                            const link = await this.createSocialLink(shownText, url, new Link().LinkType().find(lt => lt.isFacebook));
                            this.eventFarmService.currentEvent.facebookHandle = link;
                        } else {
                            this.eventFarmService.currentEvent.facebookHandle = null;
                        }
                    }

                    this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                    this.isEditingFacebook = !this.isEditingFacebook;
                    this.message.success('Facebook handle updated');
                } else {
                    this.facebook = this.eventFarmService.currentEvent.facebookHandle ? this.eventFarmService.currentEvent.facebookHandle.shownText : '';
                    this.isEditingFacebook = !this.isEditingFacebook;
                }
                break;
            case('instagram'):
                if (this.isEditingInstagram) {
                    const shownText = this.instagram;
                    const url = 'https://www.instagram.com/' + this.instagram;

                    if (this.eventFarmService.currentEvent.instagramHandle) {
                        const linkId = this.eventFarmService.currentEvent.instagramHandle.id;

                        if (shownText.length) {
                            const link = await this.updateSocialLink(linkId,shownText, url);
                            this.eventFarmService.currentEvent.instagramHandle = link;
                        } else {
                            await this.removeSocialLink(linkId);
                            this.eventFarmService.currentEvent.instagramHandle = null;
                        }
                    } else {
                        if (shownText.length) {
                            const link = await this.createSocialLink(shownText, url, new Link().LinkType().find(lt => lt.isInstagram));
                            this.eventFarmService.currentEvent.instagramHandle = link;
                        } else {
                            this.eventFarmService.currentEvent.instagramHandle = null;
                        }
                    }

                    this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                    this.isEditingInstagram = !this.isEditingInstagram;
                    this.message.success('Instagram handle updated');
                } else {
                    this.instagram = this.eventFarmService.currentEvent.instagramHandle ? this.eventFarmService.currentEvent.instagramHandle.shownText : '';
                    this.isEditingInstagram = !this.isEditingInstagram;
                }
                break;
            case('twitter'):
                if (this.isEditingTwitter) {
                    const shownText = this.twitter;
                    const url = 'https://www.twitter.com/' + this.twitter;

                    if (this.eventFarmService.currentEvent.twitterHandle) {
                        const linkId = this.eventFarmService.currentEvent.twitterHandle.id;

                        if (shownText.length) {
                            const link = await this.updateSocialLink(linkId,shownText, url);
                            this.eventFarmService.currentEvent.twitterHandle = link;
                        } else {
                            await this.removeSocialLink(linkId);
                            this.eventFarmService.currentEvent.twitterHandle = null;
                        }
                    } else {
                        if (shownText.length) {
                            const link = await this.createSocialLink(shownText, url, new Link().LinkType().find(lt => lt.isTwitter));
                            this.eventFarmService.currentEvent.twitterHandle = link;
                        } else {
                            this.eventFarmService.currentEvent.twitterHandle = null;
                        }
                    }

                    this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                    this.isEditingTwitter = !this.isEditingTwitter;
                    this.message.success('Twitter handle updated');
                } else {
                    this.twitter = this.eventFarmService.currentEvent.twitterHandle ? this.eventFarmService.currentEvent.twitterHandle.shownText : '';
                    this.isEditingTwitter = !this.isEditingTwitter;
                }
                break;
            case('description'):
                if (this.isEditingDescription) {
                    this.apiClient.getUseCaseFactory().Event().SetDescriptionForEvent(this.eventFarmService.currentEvent.id, this.description).toPromise().then( () => {
                        this.eventFarmService.currentEvent.description = this.description;
                        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
                        this.isEditingDescription = !this.isEditingDescription;
                        this.message.success('Description updated');
                    }).catch((err) => {
                        this.message.error('Please try again');
                    });
                } else {
                    this.description = this.eventFarmService.currentEvent.description ? this.eventFarmService.currentEvent.description : '';
                    this.isEditingDescription = !this.isEditingDescription;
                }
                break;
            default:
                console.log('should never get here');
        }

    }


    private async createSocialLink(shownText: string, url: string, type: LinkTypeInterface): Promise<EFLink> {
        const createResponse = await this.apiClient.getUseCaseFactory().Link().CreateLink(
            this.eventFarmService.currentTeam.id,
            url,
            shownText,
            type.slug
        ).toPromise();

        const getResponse = await this.apiClient.getUseCaseFactory().Link().GetLink(
            createResponse.data.command.linkId
        ).toPromise();

        const link = EFLink.fromApiResponse(getResponse.data);

        await this.apiClient.getUseCaseFactory().Link().AddLinkToEvent(
            link.id,
            this.eventFarmService.currentEvent.id
        ).toPromise();

        return link;
    }

    private async updateSocialLink(linkId: string, shownText: string, url: string): Promise<EFLink> {
        await this.apiClient.getUseCaseFactory().Link().SetShownText(
            linkId,
            shownText
        ).toPromise();

        await this.apiClient.getUseCaseFactory().Link().SetUrl(
            linkId,
            url,
        ).toPromise();

        const getResponse = await this.apiClient.getUseCaseFactory().Link().GetLink(
            linkId
        ).toPromise();

        return EFLink.fromApiResponse(getResponse.data);
    }

    private async removeSocialLink(linkId: string) {
        await this.apiClient.getUseCaseFactory().Link().RemoveLinkFromEvent(
            linkId,
            this.eventFarmService.currentEvent.id,
        ).toPromise();
    }
}
