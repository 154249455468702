import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExhibitorTeamMemberTypeaheadComponent } from './exhibitor-team-member-typeahead.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [ExhibitorTeamMemberTypeaheadComponent],
    exports: [ExhibitorTeamMemberTypeaheadComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUtilitiesModule
    ],
})

export class ExhibitorTeamMemberTypeaheadModule {
}
