import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { routerTransition } from './router-transition';
import { EventFarmService } from '../../eventFarm.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import {timePickerDefaults} from "../../../Shared/datetime-options";

@Component({
    selector: 'create-event',
    template: require('./create-event.html'),
    animations: [ routerTransition ]
})

export class CreateEventComponent implements OnInit, OnDestroy {
    private createEventForm: FormGroup = new FormGroup({});
    private timePickerDefaults = timePickerDefaults;

    constructor(
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private segmentService: SegmentService
    ) {
    }

    ngOnInit() {
        this.segmentService.segmentEventTracking().createEventButtonClicked();
    }

    private getState(outlet) {
        return outlet.activatedRouteData.state;
    }

    ngOnDestroy() {
    }
}
