import * as moment from 'moment';
import { isValidEmail } from './validateEmail';
import { validateAndFormatPhoneNumber } from './validatePhoneNumber';

export class ArrivalAlertUtility {
    public format(shouldSendArrivalAlert: boolean, emails: string, phoneNumbers: string): ArrivalAlerts {
        let arrivalEmails = null;
        let arrivalPhoneNumbers = null

        if (shouldSendArrivalAlert) {
            const emailList = this.getArrivalEmails(emails);
            const phoneList = this.getArrivalPhoneNumbers(phoneNumbers);
    
            if (emailList.length === 0 && phoneList.length === 0) {
              shouldSendArrivalAlert = false;
            }
    
            if (emailList.length > 0) {
              arrivalEmails = emailList;
            }
    
            if (phoneList.length > 0) {
              arrivalPhoneNumbers = phoneList;
            }
        }

        return {
            shouldSendArrivalAlert,
            arrivalAlertEmails: arrivalEmails,
            arrivalAlertMobileNumbers: arrivalPhoneNumbers
        }
    }


    /**
     * Formats the arrival email string into the main email and array of cc emails
     */
    private getArrivalEmails(emails: string) {
        if (!emails.length) {
            return [];
        }

        return emails.replace(/\s/g, '').split(',').filter(email => email.length > 0).filter(email => isValidEmail(email));
    }

    /**
     * Formats the arrival email string into the main email and array of cc emails
     */
    private getArrivalPhoneNumbers(numbers: string) {
        if (!numbers.length) {
            return [];
        }
        return numbers.replace(/\s/g, '').split(',').filter(n => n.length > 0)
        .map(n => validateAndFormatPhoneNumber(n)).filter(n => n !== null);
    }
}

export interface ArrivalAlerts {
    shouldSendArrivalAlert: boolean;
    arrivalAlertEmails: string[]|null;
    arrivalAlertMobileNumbers: string[]|null;
}
