import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';
import { Exhibitor } from '../../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { ExhibitorsService } from '../../../exhibitors.service';
import { User } from '../../../../../../ApiClient/Models/User/user';
import { ExhibitorTeamMembersService } from './exhibitors-team-members.service';

@Component({
  selector: 'edit-exhibitor-team-members',
  template: require('./edit-exhibitor-team-members.html'),
  styles: [require('./edit-exhibitor-team-members.scss')]
})
export class EditExhibitorTeamMembersComponent implements OnInit, OnDestroy {

  @Input() exhibitor: Exhibitor;
   private isSaving: boolean = false;
    private isDeleting: boolean = false;

  private addingTeamMember: boolean = false;
   private searchingForTeamMember: boolean = false;
   private exhibitorTeamMembers: User[] = [];

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private exhibitorService: ExhibitorsService,
    private teamMembersService: ExhibitorTeamMembersService,
    private segmentService: SegmentService,
    private message: NzMessageService
  ) {
  }

  ngOnInit() {
    this.setInitialFields();
  }

  ngOnDestroy(): void {
      this.teamMembersService.reset();
  }

  private async selectUser(newUser: User) {
    if (this.exhibitorTeamMembers.filter(user => user.id === newUser.id).length) {
        this.message.error('User is already a team member for this exhibitor');
    } else {
        Object.assign(newUser, {create: true, editable: true});
        await this.teamMembersService.addUserToExhibitor(newUser, this.exhibitor);
    }
    this.searchingForTeamMember = false;
    this.addingTeamMember = false;
  }

  private startSearchForTeamMember() {
    this.searchingForTeamMember = true;
  }

  private cancelSearch() {
    this.searchingForTeamMember = false;
  }

  private startCreateTeamMember() {
    this.searchingForTeamMember = false;
    this.addingTeamMember = true;
  }

  private cancelAddNewTeamMember() {
    this.addingTeamMember = false;
  }

  private async delete(teamMember: User) {
    await this.teamMembersService.removeUserFromExhibitor(teamMember, this.exhibitor);
  }

  private async edit(teamMember: User) {
    await this.teamMembersService.updateUserForExhibitor(teamMember);
  }

  async handlePageIndexChange(val) {
    this.teamMembersService.exhibitorTeamMembersListOptions.pagination.currentExhibitorTeamMembersPage = val;
    await this.teamMembersService.fetchTeamMembersForExhibitor(this.exhibitor);
  }

  async handleSearch(query: string) {
    this.teamMembersService.exhibitorTeamMembersListOptions.pagination.currentExhibitorTeamMembersPage = 1;
    this.teamMembersService.exhibitorTeamMembersListOptions.query = query;
    await this.teamMembersService.fetchTeamMembersForExhibitor(this.exhibitor);
  }

  public setIsSaving(val: boolean) {
    this.isSaving = val;
  }

  public setIsDeleting(val: boolean) {
    this.isDeleting = val;
  }

  /**
   * Setup initial values and subscriptions
   */
  private async setInitialFields() {
    await this.teamMembersService.fetchTeamMembersForExhibitor(this.exhibitor);
  }

  close(): void {
    this.teamMembersService.reset();
    this.drawerRef.close(this.exhibitor);
    this.exhibitorService.getExhibitors();
  }
}
