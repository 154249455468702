import { Injectable } from '@angular/core';

@Injectable()

export class EmailPreviewService {

    constructor() {
    }

    generatePreviewImage(id, html) {
        // post to litmus to generate preview
        // get guid back from litmus
        // update DB with guid for design

        return true;
    }

    getPreview(id) {
        // request preview image from litmus
        // might need to include browser code

        return true;
    }
}
