import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { filter, map } from 'rxjs/operators';
import { Track } from '../../../../../../ApiClient/Models/Track/track';
import { EventAgendaService } from '../../../event-agenda.service';
import { FormControl } from '@angular/forms';
import { EFSession } from '../../../../../../ApiClient/Models/Session/EFSession';
import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import { TicketType } from '../../../../../../ApiClient/Models/Stack/ticket-type';

@Component({
    selector: 'edit-tracks',
    template: require('./edit-tracks.html'),
    styles: [require('./edit-tracks.scss')]
})
export class EditTracksComponent implements OnInit {
    @Input() track: Track;

    private name: FormControl = new FormControl();
    private description: FormControl = new FormControl();
    public isEdit: boolean = false;
    public loading: boolean = true;
    private isSaving: boolean = false;
    private confirmDelete: boolean = false;
    private isDeleting: boolean = false;
    private allowDelete: boolean = true;
    private selectedSessionIds = [];
    private activeSessions: EFSession[] = [];
    private selectedTicketTypeIds = [];
    private ticketTypes: TicketType[] = [];

    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store<fromRoot.AppState>,
        private eventAgendaService: EventAgendaService,
        private messageService: NzMessageService

    ) {}

    ngOnInit() {
        this.initStoreSubscriptions();
        this.isEdit = !!(this.track);
        this.setInitialFields();
        if (this.track && this.track.id) {
            this.checkIfSessionsExists();
            this.checkIfTicketTypesExists();
        }
    }


    public async checkIfSessionsExists() {
        this.selectedSessionIds = this.track.sessions.map(session => session.id);
    }

    public async checkIfTicketTypesExists() {
        this.selectedTicketTypeIds = this.track.ticketTypes.map(ticketType => ticketType.id);
    }

    private async addTrack() {
        if (this.isSaving === false) {
            this.isSaving = true;
            try {
                const track = new Track();
                track.name = this.name.value;
                track.description = this.description.value;

                await this.eventAgendaService.createTrack(track, this.selectedSessionIds, this.selectedTicketTypeIds);
                this.eventAgendaService.sessionsListOptions.pagination.currentPage = 1;
                await this.eventAgendaService.getAllTracks();
                this.drawerRef.close();
            } catch (err) {
                this.messageService.error('Error adding track');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }



    private async saveTrack() {
        if (this.isSaving === false) {
            this.isSaving = true;

            const name: string = this.name.value;
            const description: string = this.description.value;

            const fieldsToUpdate = {
                name: name.trim() !== this.track.name ? name.trim() : null,
                description: description.trim() !== this.track.description ? description.trim() : null,
            };

            try {
                await this.eventAgendaService.updateTrack(this.track.id, fieldsToUpdate, this.selectedSessionIds, this.selectedTicketTypeIds);
                this.messageService.success('Session updated!');
                this.drawerRef.close();
                this.eventAgendaService.getAllTracks();
            } catch (error) {
                this.messageService.error('Error updating session');
            }
            this.isSaving = false;
            this.drawerRef.close();
        }
    }

    private async handleDeleteTrack() {
        if (this.isDeleting) {
            this.isDeleting = false;
        }
        if (this.confirmDelete) {
            this.isDeleting = true;
            const deleteSuccess = await this.eventAgendaService.removeTrack(this.track.id);

            this.isDeleting = false;
            this.confirmDelete = false;

            if (deleteSuccess === true) {
                this.drawerRef.close();
                await this.eventAgendaService.getAllTracks();
            }
        }
        this.confirmDelete = true;
    }

    private allowSave() {
        return (this.name.value !== '');
    }

    private async setInitialFields() {
        this.isSaving = false;

        if (!this.track) {
            this.track = Track.forTrackCreation({
                name: '',
                description: '',
            });
            this.allowDelete = false;
        } else {
            this.allowDelete = true;
        }

        this.name.setValue(this.track.name ? this.track.name : '');
        this.description.setValue(this.track.description ? this.track.description : '');

        await this.eventAgendaService.getAllSessions(true);
        this.activeSessions = this.eventAgendaService.currentSessions$.value;
    }

    private initStoreSubscriptions() {
        this.store.select('event').pipe(
          filter(res => !!res),
          map(res => res.data)
        ).subscribe((res: EfEvent) => {
          if (res) {
            this.ticketTypes = res.ticketTypes;
          }
        });
    }


    close(): void {
        this.drawerRef.close(this.track);
    }
}
