import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { EventFarmService } from '../eventFarm.service';

@Injectable()
export class AuthenticationGuardService implements CanActivate, CanActivateChild {
    constructor(private eventFarmService: EventFarmService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {
        if (this.eventFarmService.currentUser &&  this.eventFarmService.currentUser.id) {
            return true;
        }
        return false;
        }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {
        return this.canActivate(route, state);
    }

}
