import { AbstractBaseSegment } from './segment-base';

export class SegmentAddressBookTracking extends AbstractBaseSegment {

    openAddressBook(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Opened Address Book',
        );
    }

    openNewGroup(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Create New Group Modal Opened',
        );
    }

    uploadGroup(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked Upload Group',
        );
    }

    newGroup(currentTeam, currentUser, groupName) {
        const group = {};
        group['name'] = groupName;
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Create New Group',
            group
        );
    }

    startDeleteGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked Delete Group',
            group
        );
    }

    confirmDeleteGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Confirmed Delete Group',
            group
        );
    }

    startBulkRemoveUsersFromGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked Bulk Remove Users From Group',
            group
        );
    }

    confirmBulkRemoveUsersFromGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Confirmed Bulk Remove Users From Group',
            group
        );
    }

    openNewContact(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Create New Group Modal Opened',
        );
    }

    newContact(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Create New Contact',
        );
    }

    openEditUserModal(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Open Edit User Modal',
        );
    }

    editUserModalAddUserToGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Edit User Modal - Add User To Group',
            group
        );
    }

    editUserModalRemoveUserFromGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Edit User Modal - Remove User From Group',
            group
        );
    }

    editUserModalChangeTab(currentTeam, currentUser, tab) {
        let tabName = 'User Form';
        if (tab === 1) {
            tabName = 'User Groups';
        } else if (tab === 2) {
            tabName = 'User Activity';
        } else if (tab === 3) {
            tabName = 'User Invitations';
        }
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Edit User Modal - Change tab: ' + tabName,
        );
    }

    editUserModalChangeInvitation(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Edit User Modal - Change Invitation',
        );
    }

    closeEditUserModal(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Close Edit User Modal',
        );
    }

    startDeleteContact(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked Delete Contact',
        );
    }

    confirmDeleteContact(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Confirmed Delete Contact',
        );
    }

    startRemoveContactFromGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked Remove Contact From Group',
            group
        );
    }

    confirmRemoveContactFromGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Confirmed Remove Contact From Group',
            group
        );
    }

    selectedGroup(currentTeam, currentUser, group) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Selected Group',
            group
        );
    }

    clearedGroup(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Cleared Group to Show All Contacts'
        );
    }

    showGroupsDisplay(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked to Unhide Groups List'
        );
    }

    hideGroupsDisplay(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Clicked To Hide Groups List'
        );
    }

    toggleGroupOptionsPopover(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Toggled Group Menu Item Popover'
        );
    }

    toggleUserOptionsPopover(currentTeam, currentUser) {
        return this.logToSegment(
            currentTeam,
            currentUser,
            'Toggled User Menu Item Popover'
        );
    }

    private logToSegment(currentTeam, currentUser, segmentEventName, group = null) {
        if (!currentTeam || !currentUser) {
            return;
        }
        const props = {
            user: {
                userName: currentUser.name ? currentUser.name && currentUser.name.firstName + ' ' + currentUser.name.lastName : '',
                userIdentifier: currentUser.identifier ? currentUser.identifier.identifier : '',
            },
            team: {
                teamName: currentTeam.name,
                teamId: currentTeam.id
            }
        };
        if (group) {
            props['group'] = {
                groupName: group.name,
                groupId: group.id
            };
        }
        // return this.analytics.track({
        //     userId: currentUser.id,
        //     event: segmentEventName,
        //     properties: props
        // });
    }
}
