import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventAccessComponent } from './event-access.component';
import { TicketTypesStacksComponent } from './TicketTypesStacks/ticket-types-stacks.component';

const routes: Routes = [
    {
        path: '',
        component: EventAccessComponent,
        children: [
            {
                path: '',
                component: TicketTypesStacksComponent
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EventAccessRoutingModule {
}
