export class Template {

    type: string;
    name: string;
    content: string;
    slug: string;

    constructor() {
        this.type = 'template';
    }

    public static fromApi(data) {
        let newTemplate = new Template();
        newTemplate.name = data.name;
        newTemplate.slug = data.slug;
        return newTemplate;
    }
}
