export const froalaOptions: Object = {
    key: 'dKA5cC3C2D2C1E2A4A4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4B2E4C2C2E3C1C2==',
    theme: 'custom',
    placeholderText: 'Edit Your Content Here!',
    attribution: false,
    toolbarSticky: true,
    toolbarButtons: [
        'fullscreen', 'ef_codeView', '|',
        'fontFamily', 'fontSize', '|',
        'bold', 'italic', 'underline', 'textColor', 'backgroundColor','|',
        'formatOLSimple', 'formatUL', 'outdent', 'indent', '|',
        'paragraphFormat', 'align', '|',
        'ef_insertImage', 'insertLink', 'insertTable', 'insertHTML', '|',
        'undo', 'redo', 'clearFormatting', '|',
        'ef_editButton'
    ],
    dragInline: false,
    pasteDeniedAttrs: ['class', 'id'],
    useClasses: false,
    keepFormatOnDelete: true,
    tooltips: false,
    linkEditButtons: ['linkEdit', 'linkRemove', '|', 'ef_editButton'],
    fontFamilySelection: true,
    fontFamily: {
        'Arial, Sans-Serif': 'Arial',
        'Arial Black, Gadget, Sans-Serif': 'Arial Black',
        'Helvetica, Sans-Serif': 'Helvetica',
        'Courier New, Courier, Monospace': 'Courier New',
        'Georgia, Times New Roman, Times, Serif': 'Georgia',
        'Impact, Charcoal, Sans-Serif': 'Impact',
        'Lucida Console, Monaco, Monospace': 'Lucida Console',
        'Lucida Sans Unicode, Lucida Grande, Sans-Serif': 'Lucida Sans',
        'Palatino Linotype, Book Antiqua, Palatino, Serif': 'Palatino',
        'Tahoma, Geneva, Sans-Serif': 'Tahoma',
        'Times New Roman, Times, Serif': 'Times New Roman',
        'Trebuchet MS, Helvetica, Sans-Serif': 'Trebuchet',
        'Verdana, Geneva, Sans-Serif': 'Verdana'
    },
    fontSizeSelection: true,
    charCounterCount: false,
    codeMirror: false,
    codeViewKeepActiveButtons: ['fullscreen', 'ef_codeView'],
    htmlAllowedEmptyTags: ['table'],
    linkConvertEmailAddress: false,
    colorsStep: 9,
    tableMultipleStyles:false,
    tableStyles:{
        noBorder:"No border"
    },
    colorsText: [
        '#FFFFFF', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
        '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#7C706B',
        '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#61BD6D',
        '#FAC51C', '#F37934', '#D14841', '#B8312F', '#000000', 'REMOVE'
    ],

    colorsBackground: [
        '#FFFFFF', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
        '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#7C706B',
        '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#61BD6D',
        '#FAC51C', '#F37934', '#D14841', '#B8312F', '#000000', 'REMOVE'
    ],

    tableEditButtons: [
        'ef_editTable', 'tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle'
    ],

    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    imageResizeWithPercent: true,
    imageEditButtons: [
        'ef_insertImage', 'imageAlign', 'imageRemove', '|',
        'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-',
        'imageDisplay', 'imageAlt', 'imageSize'
    ],
    inlineStyles: {
        'Full Width': `border-radius: 100px; display: inline-block; padding: 16px 28px;`,
        'Small Blue': 'font-size: 14px; color: blue;',
    },
    pluginsEnabled: [
        'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'video', 'wordPaste'
    ],

    events: {}, // Added in component,
    // scrollableContainer: '#froala-container'
};
