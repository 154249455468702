import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalService } from '../Modal/modal.service';
import { ModalServiceComponent } from '../Modal/modalService.component';
import { AlertService } from '../../eventFarmAlert.service';
import { GuestListViewService } from './guest-list-view.service';
import { EventFarmService } from '../../eventFarm.service';
import { Operator } from 'rxjs';
import { AngularRestClient } from '../../../_services/rest/AngularRestClient';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';

@Component({
    selector: 'resend-invitations',
    template: require('./resend-invitation.html'),
    styles: [require('./resend-invitations.scss')],
})

export class ResendInvitationsComponent extends ModalServiceComponent implements OnInit {

    constructor(
        modalService: ModalService,
        private http: AngularRestClient,
        private alertService: AlertService,
        private guestListService: GuestListViewService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
    ) {
        super(modalService);
    }

    private timeFrame: number = 1;
    private timeFrames: any = [
        { dayCount : 0 , name : 'Never Contacted / Invited'},
        { dayCount : 1 , name : '24 hours ago'},
        { dayCount : 2 , name : '2 days (48 hours) ago '},
        { dayCount : 3 , name : '3 days ago'},
        { dayCount : 4 , name : '4 days ago'},
        { dayCount : 5 , name : '5 days ago'},
        { dayCount : 6 , name : '6 days ago'},
        { dayCount : 7 , name : '1 week ago'},
        { dayCount : 14, name : '2 weeks ago'},
        { dayCount : 21, name : '3 weeks ago'},
        { dayCount : 28, name : '4 weeks ago'},
        { dayCount : 30, name : '1 month ago'},
        { dayCount : 60, name : '2 months ago'},
        { dayCount : 90, name : '3 months ago'},
    ];

    ngOnInit() {
    }

    async sendEmails(timeFrame) {
        try {
            await this.apiClient.getUseCaseFactory().Invitation().ResendAllInvitationEmails(this.eventFarmService.currentEvent.id, timeFrame).toPromise();
            this.alertService.invitation().resendingInvitations('')
                        .then(() => {
                            this.close();
                        });
        } catch (error) {
            this.alertService.invitation().unableToResendInvitations(error.body.message);
        }
    }

}
