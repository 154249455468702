import { filter, take, tap, shareReplay } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { Subscription, of ,  Observable } from 'rxjs';

import { User } from '../../../../ApiClient/Models/User/user';
import { ActivatedRoute, Router } from '@angular/router';
import { IconService } from '../../Icons/icons.service';
import { EventFarmService } from '../../../eventFarm.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { SalesforceIntegration } from '../../../../ApiClient/Models/Integration/salesforce-integration';
import * as eventActions from './../../../store/actions/event';
import { LocalStorageService } from '../../../../_services/storage/local-storage.service';

@Component({
    selector: 'side-nav-hamburger',
    template: require('./sideNavHamburger.html'),
    styles: [require('./sideNavHamburger.scss')],
})

export class SideNavHamburgerComponent implements OnInit, OnDestroy {
    @Input() currentActiveSection: string;
    @Input() isEventRoute: boolean;
    @Input() isMobile: boolean;
    readonly addressBookOnSVG = require('./../assets/address-on');
    readonly addressBookOffSVG = require('./../assets/address-off');
    readonly teamDashboardOnSVG = require('./../assets/team-dashboard-on');
    readonly teamDashboardOffSVG = require('./../assets/team-dashboard-off');
    readonly eventsDashboardOnSVG = require('./../assets/events-on');
    readonly eventsDashboardOffSVG = require('./../assets/events-off');
    readonly designCenterOnSVG = require('./../assets/design-center-on');
    readonly designCenterOffSVG = require('./../assets/design-center-off');
    readonly registrationExperienceOnSVG = require('./../assets/tir-icon-on');
    readonly registrationExperienceOffSVG = require('./../assets/tir-icon-off');
    readonly guestManagementOnSVG = require('./../assets/guest-list-on');
    readonly guestManagementOffSVG = require('./../assets/guest-list-off');
    readonly emailCommunicationsOnSVG = require('./../assets/email-on');
    readonly emailCommunicationsOffSVG = require('./../assets/email-off');
    readonly eventConfigurationOnSVG = require('./../assets/settings-on');
    readonly eventConfigurationOffSVG = require('./../assets/settings-off');
    readonly eventReportingOnSVG = require('./../assets/reports-on');
    readonly eventReportingOffSVG = require('./../assets/reports-off');
    readonly efxOnSVG = require('./../assets/efx-on');
    readonly efxOffSVG = require('./../assets/efx-off');
    readonly exhibitorOnSVG = require('./../assets/exhibitor-on');
    readonly exhibitorOffSVG = require('./../assets/exhibitor-off');
    readonly eventAgendaOnSVG = require('./../assets/events-agenda-on');
    readonly eventAgendaOffSVG = require('./../assets/events-agenda-off');
    readonly smsOnSVG = require('./../assets/sms-on');
    readonly smsOffSVG = require('./../assets/sms-off');

    private userLoaded: boolean;
    private isTeamRoute: boolean;

    private toggledSections: SideNavOptions;
    private displaySideNav: boolean;
    private user: User;

    private eventStore$: Observable<any> = this.store.select('event');
    private teamStore$: Observable<any> = this.store.select('team');
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    private userContactType$ = this.store.select(fromRoot.getPoolContactType).pipe(shareReplay());
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private eventIntegration: SalesforceIntegration;
    private eventStoreData: EfEvent;
    private eventRoleType: any;
    private hasCanvasPoolFeature: boolean;
    private eventUpdate$: Subscription;
    private eventRoleUpdate$: Subscription;
    private teamUpdate$: Subscription;
    private userUpdate$: Subscription;
    private isWaitlistShown: boolean = false;

    constructor(
        private store: Store<fromRoot.AppState>,
        private efs: EventFarmService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        public route: ActivatedRoute,
        public iconService: IconService,
        public localStorageService: LocalStorageService,
        private eventFarmService: EventFarmService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => !!val.data)).subscribe((val) => {
            this.eventStoreData = val.data;
            this.eventIntegration = val.salesforceIntegration;
        });

        this.teamUpdate$ = this.teamStore$.pipe(
            filter((val: any) => val.features && val.features.data.length),
        ).subscribe((val) => {
            const featureFoundOnPool = val.features.data.filter((feature) => feature.relationships.feature.attributes.name === 'Canvas');
            this.hasCanvasPoolFeature = featureFoundOnPool.length ? true : false;
        });

        this.userUpdate$ = this.userStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.userLoaded = true;
            this.user = val.data;
        });

        this.eventRoleUpdate$ = this.eventRoleType$.subscribe(res => this.eventRoleType = res);

        this.store.select('router').pipe(
            filter(res => Object.keys(res).length !== 0 && Object.keys(res.state.params).length !== 0),
        ).subscribe(route => {
            this.isTeamRoute = false;
            this.displaySideNav = true;
            this.resetSections();
            this.checkRoute();
            if (route.state.queryParams.ticketBlockId) {
                this.displaySideNav = false;
            }
            if (route.state.params.pool) {
                this.isTeamRoute = true;
            }
        });

    }

    ngOnInit() {
        this.userLoaded = false;
        this.isTeamRoute
            = false;
        this.displaySideNav = true;
        this.resetSections();
        if (this.efs.currentUser && this.efs.currentUser.id) {
            this.userLoaded = true;
        }

        this.localStorageService.resetNavBar.subscribe((resetNav) => {
            if (resetNav === true) {
                this.displaySideNav = true;
            }
        });
    }

    resetSections() {
        this.toggledSections = {
            'my-account': false,
            'event-configuration': false,
            'registration-experience': false,
            'email-communication': false,
            'guest-management': false,
            'event-reporting': false,
            'event-efx': false,
            'event-exhibitors': false,
            'event-agenda': false
        };
    }

    checkRoute() {
        if (!this.efs.currentTeam) {
            return;
        }

        if (this.router.url.includes('wait-list')) {
            this.isWaitlistShown = true;
        } else {
            if (this.isWaitlistShown === true) {
                this.store.dispatch(new eventActions.FetchWaitList({query: '', sortBy: 'created-at'}));
                this.isWaitlistShown = false;
            }
        }

        if ( String(this.router.url).includes(this.routeGenerator.url('pools.my-account.overview', { poolId: this.efs.currentTeam.id}))
            || String(this.router.url).includes(this.routeGenerator.url('pools.my-account.payment-settings', { poolId: this.efs.currentTeam.id}))) {
            this.currentActiveSection = 'my-account';
            this.toggledSections['my-account'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.info', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.event-access', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.child-events', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.settings', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-configuration';
            this.toggledSections['event-configuration'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.web-presence', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.questions', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.promotions', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.payments-promos', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.web-form', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
        ) {
            this.currentActiveSection = 'registration-experience';
            this.toggledSections['registration-experience'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.edc', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'email-communication';
            this.toggledSections['email-communication'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.guest-list', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.wait-list', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.integrations', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'guest-management';
            this.toggledSections['guest-management'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.legacy', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.email-stats', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.message-stats', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.reports.v2', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-reporting';
            this.toggledSections['event-reporting'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.efx.config', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.efx.station', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-efx';
            this.toggledSections['event-efx'] = true;
        }  else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.sms.create', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.sms.reports', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-sms';
            this.toggledSections['event-sms'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.exhibitors', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))) {
            this.currentActiveSection = 'event-exhibitors';
            this.toggledSections['event-exhibitors'] = true;
        } else if (this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.speakers', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.sessions', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.tracks', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            || this.eventStoreData && String(this.router.url).includes(this.routeGenerator.url('events.agenda.sponsors', { poolId: this.efs.currentTeam.id, eventId: this.eventStoreData.id }))
            ) {
            this.currentActiveSection = 'event-agenda';
            this.toggledSections['event-agenda'] = true;
        } else {
            this.currentActiveSection = 'events-list';
        }
    }

    toggleSection(section: 'my-account' | 'event-configuration' | 'registration-experience' | 'email-communication' | 'guest-management' | 'event-reporting' | 'events-list' | 'event-efx' | 'event-exhibitors' | 'event-agenda' | 'event-sms') {
        const previousValue = this.toggledSections[section];
        this.resetSections();
        if (!this.toggledSections[section]) {
            this.toggledSections[section] = true;
        } else {
            this.toggledSections[section] = false;
        }
        if (previousValue) {
            this.toggledSections[section] = false;
        }
    }

    isMyAccountSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'my-account';
    }

    isEventConfigurationSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'event-configuration';
    }

    isRegistrationExperienceSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'registration-experience';
    }

    isEmailCommunicationSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'email-communication';
    }

    isGuestManagementSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'guest-management';
    }

    isEventReportingSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'event-reporting';
    }

    isEventEFXSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'event-efx';
    }

    isEventExhibitorsSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'event-exhibitors';
    }

    isEventAgendaSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'event-agenda';
    }

    isEventSMSSection(): boolean {
        this.checkRoute();
        return this.currentActiveSection === 'event-sms';
    }

    isInToggledSections(section: 'my-account' | 'event-configuration' | 'registration-experience' | 'email-communication' | 'guest-management' | 'event-reporting' | 'events-list' | 'event-efx' | 'event-exhibitors' | 'event-agenda' | 'event-sms'): boolean {
        for (const toggledSection in this.toggledSections) {
            if (this.toggledSections.hasOwnProperty(toggledSection) && section === toggledSection && this.toggledSections[toggledSection]) {
                return true;
            }
        }
        return false;
    }

    async toCanvasUrl(eventId: string): Promise<any> {
        const fullBaseUrl = window.location.protocol + '//' + window.location.host;
        const redirectUrl = `${fullBaseUrl}/canvas/tokenize?eventId=${eventId}`;
        window.location.href = redirectUrl;
    }

    async toCanvasDevUrl(eventId: string): Promise<any> {
        const fullBaseUrl = 'http://localhost:32954';
        const redirectUrl = `${fullBaseUrl}/tokenize?eventId=${eventId}`;
        window.location.href = redirectUrl;
    }

    public closeMobileHamburger(shouldRefreshEventList = false) {
        this.localStorageService.linkClicked.next(true);

        if (shouldRefreshEventList) {
            this.store.dispatch(new fromRoot.FetchEventsForTeam());
        }
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
        this.teamUpdate$.unsubscribe();
        this.userUpdate$.unsubscribe();
        this.eventRoleUpdate$.unsubscribe();
    }
}

export interface SideNavOptions {
    'my-account' : boolean;
    'event-configuration': boolean;
    'registration-experience': boolean;
    'email-communication': boolean;
    'guest-management': boolean;
    'event-reporting': boolean;
    'event-efx': boolean;
    'event-exhibitors': boolean;
    'event-agenda': boolean;
}
