import {AbstractModel} from '../abstract-model';
import {Team} from '../Pool/team';
import {EfEvent} from '../Event/event';

export class Withdrawal extends AbstractModel {
    constructor(id: string) {
        super(id);
    }

    private _amount: number | null;
    private _created: Team | null;
    private _event: EfEvent;
    private _isDeleted: boolean;


    public static fromApiResponse(data) {
        const withdrawal = new this(data.id);
        const attributes = data.attributes;

        withdrawal._amount = attributes.amount;
        withdrawal._created = attributes.created;
        withdrawal._event = EfEvent.fromApiResponse(attributes.event);
        withdrawal._isDeleted = attributes.isDeleted;

        return withdrawal;
    }


    get amount(): number | null {
        return this._amount;
    }

    set amount(value: number | null) {
        this._amount = value;
    }

    get created(): Team | null {
        return this._created;
    }

    set created(value: Team | null) {
        this._created = value;
    }

    get event(): EfEvent {
        return this._event;
    }

    set event(value: EfEvent) {
        this._event = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }
}

