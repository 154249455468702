
import * as moment from "moment";
import {
    QuickFilterSelectionValueType
} from "../../../EventFarm/CoreUI/GuestListTableView/GuestListFilters/guest-list-filters.service";
import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { Team } from '../Pool/team';
import { Stack } from '../Stack/stack';
import { TicketType } from '../Stack/ticket-type';
import { User } from '../User/user';
import { UserIdentifier } from '../User/user-identifier';
import { UserName } from '../User/user-name';
import { InvitationStatusTypeInterface, Invitation as InvitationType } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { TicketBlock } from '../Stack/ticket-block';
import * as _ from 'lodash';

export type sendBy = '';



export type InvitationStatusSlugs =
    'assigned'
    | 'purchased'
    | 'confirmed-by-rsvp'
    | 'declined-by-rsvp'
    | 'left-behind'
    | 'registered'
    | 'unconfirmed'
    | 'not-yet-purchased'
    | 'not-yet-registered';

export const invitationStatusSlugs = new InvitationType().InvitationStatusType()

export class Invitation extends AbstractModel {
    private _isCheckedIn: boolean;
    private _inviteCount: number;
    private _createdAt: number;
    private _inviteSourceType: any = {};
    private _invitationStatusType: InvitationStatusTypeInterface;
    private _stack: Stack;
    private _ticketBlock: TicketBlock;
    private _event: EfEvent;
    private _ticketType: TicketType;
    private _modified: any;
    private _lastNotifiedAt: number;
    private _lastActionAt: number;
    private _lastInvitationActionType: any;
    private _user: User;
    private _userName: UserName;
    private _userIdentifier: UserIdentifier;
    private _checkInNotes: string;
    private _invitationNotes: string;
    private _checkInAt: number;
    private _shouldSendArrivalAlert: boolean;
    private _arrivalAlertPhoneNumbers?: string[];
    private _arrivalAlertEmails?: string[];
    private _invitationPath?: string;
    private _guestPassCount: number|null;
    private _extraAttributes?: any;
    private _hasProxyEmail: boolean;
    private _proxyEmail: string|null;
    private _sitePagePath: string|null;
    private _ticketBlockName: string|null;
    constructor(id) {
        super(id);
    }

    public static fromApiResponse(data): Invitation {
        const invitation = new this(data.id);

        try {
            invitation._isCheckedIn = data.attributes.isCheckedIn;
            invitation._inviteCount = data.attributes.inviteCount;
            invitation._createdAt = data.attributes.created.createdAt;
            invitation._shouldSendArrivalAlert = data.attributes.shouldSendArrivalAlert;
            invitation._inviteSourceType = data.attributes.inviteSourceType;
            invitation._invitationStatusType = data.attributes.invitationStatusType;
            invitation._modified = data.attributes.modified;
            invitation._lastNotifiedAt = data.attributes.lastNotifiedAt;
            invitation._checkInAt = data.attributes.checkInAt;
            invitation._lastInvitationActionType = data.attributes.lastInvitationActionType;
            invitation._arrivalAlertEmails = data.attributes.arrivalAlertEmails;
            invitation._arrivalAlertPhoneNumbers = data.attributes.arrivalAlertPhoneNumbers;
            invitation._checkInNotes = data.attributes.checkInNotes;
            invitation._invitationNotes = data.attributes.invitationNotes;
            invitation._invitationPath = data.attributes.invitationPath;
            invitation._lastActionAt = data.attributes.lastResponseAt;
            invitation._guestPassCount = data.attributes.guestPassCount;
            invitation._proxyEmail = data.attributes.proxyEmail;
            invitation._hasProxyEmail = data.attributes.hasProxyEmail;
            invitation._extraAttributes = data.extraAttributes;
            invitation._sitePagePath = data.attributes.sitePagePath
            invitation._ticketBlockName = data.attributes.ticketBlockName
        } catch (err) {
        }

        if (data.relationships && data.relationships.stack) {
            try {
                invitation._stack = Stack.fromApiResponse(data.relationships.stack);
            } catch (err) {
            }
        }

        if (invitation.stack && invitation.stack.ticketType) {
            try {
                invitation._ticketType = invitation.stack.ticketType;
            } catch (err) {
            }
        }

        if (data.relationships && data.relationships.user) {
            try {
                invitation._user = User.fromApiResponse(data.relationships.user);
            } catch (err) {
            }
        }

        if (data.relationships && data.relationships.event) {
            invitation._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        if (data.relationships && data.relationships.ticketBlock) {
            invitation._ticketBlock = TicketBlock.fromApiResponse(data.relationships.ticketBlock);
        }

        if (data.relationships && data.relationships.userName) {
            try {
                invitation._userName = UserName.fromApiResponse(data.relationships.userName);
            } catch (err) {
            }
        }

        if (data.relationships && data.relationships.userIdentifier) {
            try {
                invitation._userIdentifier = UserIdentifier.fromApiResponse(data.relationships.userIdentifier);
            } catch (err) {
            }
        }

        if (data.relationships && data.relationships.user && data.relationships.user.relationships && data.relationships.user.relationships.userIdentifiers) {
            try {
                const identifiers = data.relationships.user.relationships.userIdentifiers.filter((identifier) => identifier.attributes.isPrimary);
                if (identifiers.length) {
                    invitation._userIdentifier = UserIdentifier.fromApiResponse(identifiers[0]);
                }
            } catch (err) {
            }
        }

        return invitation;
    }

    get guestOf(): string|null {
        const relatedGuestInfo = _.get(this, 'extraAttributes.relatedInvitationIdentifier', null);

        if (relatedGuestInfo) {
            return `Guest of ${relatedGuestInfo}`
        }

        return null;
    }

    get isCheckedIn() {
        return this._isCheckedIn;
    }

    set isCheckedIn(value: boolean) {
        this._isCheckedIn = value;
    }

    get inviteCount() {
        return this._inviteCount;
    }

    get createdAt() {
        return this._createdAt;
    }

    get shouldSendArrivalAlert() {
        return this._shouldSendArrivalAlert;
    }

    get inviteSourceType() {
        return this._inviteSourceType;
    }

    get invitationStatusType(): InvitationStatusTypeInterface {
        return this._invitationStatusType;
    }

    get isAffirmative(): boolean {
        return this.invitationStatusType?.isAssigned
            || this.invitationStatusType?.isPurchased
            || this.invitationStatusType?.isConfirmedByRSVP
            || this.invitationStatusType?.isRegistered
    }

    get isLikeConfirmed(): boolean {
        return this.invitationStatusType?.isLeftBehind
        || this.invitationStatusType?.isNotYetRegistered
        || this.invitationStatusType?.isUnconfirmed
        || this.invitationStatusType?.isNotYetPurchased
    }

    get stack(): Stack {
        return this._stack;
    }

    get ticketType() {
        return this._ticketType;
    }

    get event(): EfEvent {
        return this._event;
    }

    set event(value: EfEvent) {
        this._event = value;
    }

    get arrivalAlertEmails(): string[] {
        if (!this._arrivalAlertEmails) {
            return [];
        }
        return this._arrivalAlertEmails;
    }

    set arrivalAlertEmails(value: string[]) {
        this._arrivalAlertEmails = value;
    }

    get arrivalAlertPhoneNumbers(): string[] {
        if (!this._arrivalAlertPhoneNumbers) {
            return [];
        }

        return this._arrivalAlertPhoneNumbers;
    }

    set arrivalAlertPhoneNumbers(value: string[]) {
        this._arrivalAlertPhoneNumbers = value;
    }

    set user(value: User) {
        this._user = value;
    }

    get modified() {
        return this._modified;
    }

    get lastNotifiedAt() {
        return this._lastNotifiedAt;
    }

    get lastActionAt() {
        return this._lastActionAt;
    }

    get user(): User {
        return this._user;
    }

    get ticketBlock(): TicketBlock {
        return this._ticketBlock;
    }

    get path(): string {
        return this._invitationPath;
    }

    get userName() {
        return this._userName;
    }

    get userIdentifier() {
        return this._userIdentifier;
    }

    get checkInNotes() {
        return this._checkInNotes;
    }

    get invitationNotes() {
        return this._invitationNotes;
    }

    get checkInAt() {
        return this._checkInAt;
    }

    get lastInvitationActionType() {
        return this._lastInvitationActionType;
    }

    set userName(value: UserName) {
        this._userName = value;
    }

    set userIdentifier(value: UserIdentifier) {
        this._userIdentifier = value;
    }

    set inviteCount(value: number) {
        this._inviteCount = value;
    }

    set checkInNotes(checkInNotes: string) {
        this._checkInNotes = checkInNotes;
    }

    set invitationNotes(invitationNotes: string) {
        this._invitationNotes = invitationNotes;
    }

    set shouldSendArrivalAlert(value: boolean) {
        this._shouldSendArrivalAlert = value;
    }

    get identifier(): string {
        if (this._userName && this._userName.fullName !== '') {
            return this._userName.getFullNameWithIdentifier(this._userIdentifier);
        }

        if (this._userIdentifier && this._userIdentifier.identifier) {
            return this._userIdentifier.identifier;
        }

        return '';
    }

    get fullName(): string {
        if (this._userName && this._userName.fullName !== '') {
            return this._userName.fullName;
        }

        return '';
    }

    get email(): string {
        return this._userIdentifier && this._userIdentifier.identifier ? this._userIdentifier.identifier : this.user.primaryEmail;
    }

    get hasExternalId(): boolean {
        if (this._user && this._user.hasAttributeType('external_id')) {
            return this._user.hasAttributeType('external_id');
        }

        return false;
    }

    get externalId(): string|null {
        if (this._user && this._user.getAttributeType('external_id')) {
            return this._user.getAttributeType('external_id');
        }

        return null;
    }

    get sitePagePath(): string {
        return this._sitePagePath;
    }

    public get guestPassCount(): number|null {
        return this._guestPassCount;
    }

    public set guestPassCount(guestPassCount: number|null) {
        this._guestPassCount = guestPassCount;
    }

    get extraAttributes(): any {
        return this._extraAttributes;
    }

    public get proxyEmail(): string|null {
        return this._proxyEmail;
    }

    public set proxyEmail(value: string|null) {
        this._proxyEmail = value;
    }

    public get hasProxyEmail(): boolean {
        return this._hasProxyEmail;
    }

    public set hasProxyEmail(value: boolean) {
        this._hasProxyEmail = value;
    }
    get isTicketBlock(): boolean {
        return !!this.ticketBlock;
    }

    get hasInvitationNotes(): boolean {
        return this.invitationNotes !== null && this.invitationNotes !== '';
    }

    get hasCheckInNotes(): boolean {
        return this.checkInNotes !== null && this.checkInNotes !== '';
    }

    get ticketBlockName(): string {
        return this._ticketBlockName;
    }

    get relativeCheckinTime(): string  {
        if(!this.checkInAt) {
            return null
        } else {
            return moment.unix(this.checkInAt).fromNow();
        }
    }

    get resendType(): 'resend-invite' | 'resend-confirm' {
        if (
        this.invitationStatusType.isConfirmedByRSVP ||
        this.invitationStatusType.isAssigned ||
        this.invitationStatusType.isRegistered ||
        this.invitationStatusType.isPurchased ||
        this.invitationStatusType.isLeftBehind){
                return 'resend-confirm';
        } else {
            return 'resend-invite';
        }
    }

    public static invitationStatusPayloadByQuickFilter(quickFilterSelection: QuickFilterSelectionValueType): InvitationStatusSlugs[] {
        switch (quickFilterSelection) {
            case 'who-is-coming':
                return ['assigned', 'purchased', 'confirmed-by-rsvp', 'left-behind', 'registered'];
            case 'who-is-not-coming':
                return ['declined-by-rsvp'];
            case 'who-has-not-decided':
                return ['not-yet-purchased', 'not-yet-registered'];
            case 'all-invited':
                return ['assigned', 'purchased', 'confirmed-by-rsvp', 'declined-by-rsvp', 'left-behind', 'registered', 'unconfirmed', 'not-yet-purchased', 'not-yet-registered']
        }
    }

}

export function UniqueStacksFromInvitations(invitations: Invitation[]): Stack[] {
    const stacks = invitations.map(i => i.stack);
    const uniqueStacks = _.uniqBy(stacks, 'id');
    return uniqueStacks;
}

// Given an array of invitations, returns a map where key is stack, value is number of invitations
export function InvitationCountsByStack(invitations: Invitation[]): Map<string, number> {
    const map = new Map();

    invitations.forEach(i => {
        // If valid exists in map, increment
        if (map.get(i.stack.id)) {
            const currVal =  map.get(i.stack.id);
            map.set(i.stack.id, currVal + 1);
            // map.get(i.stack.id).val = 100
        } else {
            // Otherwise, add
            map.set(i.stack.id, 1);
        }
    });
    return map;
}
