import { Component, EventEmitter, Output, HostBinding, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { debounceTime, map, tap, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { Tag } from '../../../ApiClient/Models/Event/tag';

@Component({
    selector: 'tag-typeahead',
    template: require('./tag-typeahead.html'),
    animations: [
        trigger('leavingState', [
            transition(':enter', [
                animate(0, style({ opacity: 0, top: '-1rem' })),
                animate('100ms ease-out', style({ opacity: 1, top: '0' })),
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0 })),
            ])
        ])
    ],
    host: {
        '[@leavingState]': '',
        '(document:click)': 'offClick($event)',
    }
})

export class TagTypeaheadComponent {
    private searchTerm: FormControl = new FormControl();
    private searchResults$ = new Subject<string[]>();
    private isSearching: boolean = false;
    @Output() selectionReceived: EventEmitter<any> = new EventEmitter();
    @Input() currentTag: string;

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private elementRef: ElementRef
    ) {
        this.searchForTags();
    }

    @HostBinding('class.active') activeInput: boolean;

    private searchForTags() {
        this.searchTerm.valueChanges
            .pipe(debounceTime(200),
                tap(() => { this.isSearching = true; this.activeInput = true; }),
                distinctUntilChanged()
            ).subscribe((term) => {
                this.apiClient.getUseCaseFactory().Pool().ListUniqueTagNamesForPool(this.eventFarmService.currentTeam.id).subscribe(res => {
                    this.isSearching = false;
                    const tags = this.filterTags(Object.values(res.data.attributes), this.searchTerm.value);
                    if (term) {
                        this.searchResults$.next(tags);
                    } else {
                        this.searchResults$.next([]);
                    }
                }, (err) => this.isSearching = false);

            });
    }

    private filterTags(tags: string[], searchValue: string): string[] {
        const currentTags = this.eventFarmService.currentEvent.tags.map(tag => tag.name);
        const diff = tags.filter(x => !currentTags.includes(x));
        const filtered = diff.filter(tag => tag.toLowerCase().search(searchValue) !== -1);

        /**
         * If search value is not in filtered array, add to front
         */
        const lowercaseFiltered = filtered.map(tag => tag.toLowerCase());
        if (lowercaseFiltered.indexOf(searchValue) === -1 ) {
            filtered.unshift(searchValue);
        }

        return filtered;
    }

    private selectOption(option) {
        this.selectionReceived.emit(option);
        this.activeInput = false;
    }

    private offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.activeInput = false;
        }
    }
}
