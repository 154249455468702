import { Pipe } from '@angular/core';

@Pipe({
    name: 'sessionSummaryPipe',
})
export class SessionSummarySlugPipe {
    transform(slugs, option) {
        switch (option) {
            case false:
            case undefined:
                return slugs.filter((i) => i['name'] !== 'SESSION_SUMMARY');
            default:
                return slugs.filter((i) => i);
        }
    }
}
