import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { AccountInfoService } from '../account-info.service';

class EmailData {
    constructor(
        public emailData = [
            {name: 'Invites Count', value: '---'},
            {name: 'Guest Messaging', value: '---'},
            {name: 'Confirmation Emails', value: '---'},
        ]
    ) {}
}

@Component({
    selector: 'email-communications',
    template: require('./email-communications.html'),
    styles: [require('./email-communications.scss')],
})

export class EmailCommunicationsComponent implements OnInit {

    private emailData$ = new BehaviorSubject(new EmailData());
    private emailDataForChart;
    private totalEmails = new BehaviorSubject('---');

    private colorScheme = {
        domain: ['#50e3c2', '#0b4378', '#3bc2de']
    };

    constructor (
        private accountInfoService: AccountInfoService,
        private eventFarmService: EventFarmService
    ) {

    }

    ngOnInit() {
        this.accountInfoService.emailCountsForEvent$.subscribe((data) => {
            this.emailDataForChart = Object.keys(data)
                .map(i => Object.assign({}, {'name': this.formatLabel(i), 'value': data[i]}))
                .filter(i => this.filterData(i));

            this.totalEmails.next(data.total);
            this.emailData$.value.emailData = this.emailDataForChart;
        });
    }

    formatLabel(name) {
        const formatMap = {'invitationCount': 'Invites Sent', 'guestMessageCount': 'Guest Messaging', 'confirmationCount': 'Confirmation Count'};
        return formatMap[name] || name;
    }

    filterData(data) {
        return !['total', 'poolId'].includes(data.name);
    }
}
