import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GuestListViewService} from '../guest-list-view.service';
import {GuestListFiltersService} from '../GuestListFilters/guest-list-filters.service';

@Component({
    selector: 'guest-list-search',
    template: require('./guest-list-search.html'),
    styles: [require('./guest-list-search.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestListSearchComponent implements OnInit {

    constructor(
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService
    ) {
    }

    public searchForm;

    ngOnInit() {
    }

    async handleSearch($event: any) {
        this.guestListViewService.parameters.activeQuery = $event;
        await this.guestListViewService.fetchGuestListForEventOrTicketBlock();
    }
}
