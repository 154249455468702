import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Actions } from '@ngrx/effects';
import { FormattedDateTime } from '../../../../ApiClient/Models/formatted-datetime';
import { GuestListTransfersService } from './guest-list-transfers.service';
import { Transfer } from '../../../../ApiClient/Models/Transfer/transfer';
import { EventFarmService } from '../../../eventFarm.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
  selector: 'guest-list-transfers',
  template: require('./guest-list-transfers.html'),
  styles: [require('./guest-list-transfers.scss')]
})
export class GuestListTransfersComponent implements OnInit {


  private currentTransfers: Transfer[];


  constructor(
    private drawerRef: NzDrawerRef,
    private actions$: Actions,
    private guestListTransfersService: GuestListTransfersService,
    private eventFarmService: EventFarmService,
    private segmentService: SegmentService
  ) { }


  ngOnInit() {
    this.currentTransfers = [];
    this.guestListTransfersService.isLoadingTransfers = true;
    this.segmentService.segmentTransfersTracking().transfersEntered();
    this.guestListTransfersService.getTransfersForEvent();

    this.guestListTransfersService.transfers$.subscribe(res => {
        this.currentTransfers = res;
    });

  }

  public changePage(page) {
    this.guestListTransfersService.isLoadingTransfers = true;
    this.guestListTransfersService.transfersListOptions.pagination.currentTransfersPage = page;
    this.guestListTransfersService.getTransfersForEvent();
  }

  close(): void {
    this.guestListTransfersService.reset();
    this.segmentService.segmentTransfersTracking().transfersClosed();

    this.drawerRef.close();
  }
}
