import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MyAccountOverviewComponent } from './my-account-overview.component';
import { PoolResolver } from '../pool-resolver.service';
import { UserManagerResolver } from '../../user-manager-resolver.service';
import { MyAccountResolver } from './my-account.resolver';
import { SalesforceTeamSettingsComponent } from './Integrations/salesforce-team-settings.component';
import { MyAccountComponent } from './my-account.component';
import {PaymentSettingsComponent} from './PaymentSettings/payment-settings.component';
import {AdminGuardService} from '../../AdminModule/admin.guard';

const routes: Routes = [
    {
        path: '',
        component: MyAccountComponent,
        resolve: {
            contract: MyAccountResolver
        },
        children: [
            {
                path: 'salesforce',
                resolve: [PoolResolver, UserManagerResolver],
                component: SalesforceTeamSettingsComponent,
            },
            {
                path: 'overview',
                component: MyAccountOverviewComponent,
            },
            {
                path: 'payment-settings',
                component: PaymentSettingsComponent
            },
            {
                path: '**',
                redirectTo: 'overview',
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class MyAccountRoutingModule {
}
