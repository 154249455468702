import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
@Component({
  selector: 'dynamic-form',
  template: `
    <form
    class="dynamic-form"
    [formGroup]="group"
    (ngSubmit)="submitted.emit(group.value)">
    <ng-container
      *ngFor="let field of fields"
      dynamicField
      [config]="field"
      [group]="group">
    </ng-container>
  </form>
  `
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input()
  fields: any[] = [];

  @Input() shouldSave?: boolean;

  @Output()
  submitted: EventEmitter<any> = new EventEmitter<any>();

  group: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.createGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
      if (this.shouldSave) {
        this.submitted.emit(_.get(this, 'group.value'));
      }
  }


  private createGroup() {
    let group: any = {};
    this.fields.forEach(field => {
      if (field.supportsMultipleAnswers && field.supportsMultipleAnswers()) {
        let answers = {};
        field.options.forEach(option => {
          answers[option.id] = field.responses
            .filter(response => response.answerId === option.id)
            .length > 0;
        });

        group[field.name] = this.fb.group(answers);
      } else {
        group[field.name] = this.fb.control(field.storedValue);
      }
    });

    this.group = new FormGroup(group);
  }
}
