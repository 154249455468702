import {Injectable, EventEmitter, ApplicationRef} from '@angular/core';
import { PlatformLocation } from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';

import { Design } from './Models/design';
import { Modal } from '../../CoreUI/Modal/modal.class';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { AlertService } from '../../eventFarmAlert.service';

import { UploadImageComponent } from './UploadImage/upload-image.component';
import { ButtonEditorComponent } from './ButtonEditor/button-editor.component';
import { TableEditorComponent } from './TableEditor/table-editor.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as _ from 'lodash';
declare var $: any;

@Injectable()

export class FroalaService {
    design: Design;
    public previousColor = '';
    public currentColor = '';
    public codeViewIsActive = false;
    public codeViewChange: EventEmitter<any> = new EventEmitter();
    public FroalaEditor = require('froala-editor');
    private observer;
    constructor(
        private modalService: ModalService,
        private alertService: AlertService,
        private platformLocation: PlatformLocation,
        private message: NzMessageService,
        private appRef: ApplicationRef
    ) {
        this.registerCommands();
    }

    private launchButtonEditor = (scope) => {
        const component = this;
        const modal: Modal = new Modal();
        const data = {
            froala: scope,
            element: scope.selection.element()
        };

        modal.title = 'Button Editor';
        modal.component = ButtonEditorComponent;
        modal.data = Object.assign(data);
        component.modalService.changeModal(modal);
        component.modalService.showModal();
    }

    private launchImageEditor(scope) {
        const component = this;
        const modal: Modal = new Modal();
        modal.title = 'Upload an Image';
        modal.component = UploadImageComponent;
        modal.data = scope;
        component.modalService.changeModal(modal);
    }

    private launchTableEditor = (scope) => {
        const component = this;
        const modal: Modal = new Modal();

        modal.title = 'Table Editor';
        modal.component = TableEditorComponent;
        modal.data = document.querySelector('.fr-selected-cell');
        component.modalService.changeModal(modal);
    }

    private launchCodeView(editor) {
        editor.codeView.toggle();
        this.codeViewIsActive = editor.codeView.isActive();
        this.codeViewChange.emit(this.codeViewIsActive);
    }

    public getSelectedImage() {
        return this.FroalaEditor.image.get();
    }

    public getPreviousColor() {
        return (document.getElementById('fr-color-hex-layer-text-1') as HTMLInputElement).value;
    }

    public setCurrentColor() {
        (document.getElementById('fr-color-hex-layer-text-1') as HTMLInputElement).value = this.currentColor;
    }

    public getContentFromCodeView() {
        return this.FroalaEditor.codeView.get();
    }

    private exitFullScreenIfOpen() {
        if (this.FroalaEditor('fullscreen.isActive')) {
            this.FroalaEditor('fullscreen.toggle');
        }
    }

    private setFixed(fixed: boolean) {
        const el = document.querySelector('.fr-toolbar');
        if(!el){
            return
        }
        if (fixed) {
            if (!el.classList.contains('fr-sticky-on')) {
                el.classList.add('fr-sticky-on');
            }

        } else {
            if (el.classList.contains('fr-sticky-on')) {
                el.classList.remove('fr-sticky-on');
            }

        }
    }

    private scrollListener() {
         this.observer = new IntersectionObserver((entries) => {
             // The target has left viewport b/c of scrolling down so we need to set it to fixed
             if (entries[0].boundingClientRect.top < 0) {
                this.setFixed(true);
            } else {
                this.setFixed(false);
            }
        });
         //We have #froala-spacer in the EDC and Guest Messaging instances of froala
         if (document.querySelector('#froala-spacer')) {
            this.observer.observe(document.querySelector('#froala-spacer'));
        }
    }
    public registerCommands() {
        const parent = this;
        this.FroalaEditor.DefineIcon('ef_insertImage', { NAME: 'image', template: 'font_awesome'  });
        this.FroalaEditor.RegisterCommand('ef_insertImage', {
            title: 'Insert Image',
            focus: false,
            undo: true,
            callback: function() {
                parent.launchImageEditor(this);
                },
        });

        this.FroalaEditor.DefineIcon('ef_editImage', { NAME: 'exchange', template: 'font_awesome'  });
        this.FroalaEditor.RegisterCommand('ef_editImage', {
            title: 'Replace Image',
            focus: false,
            undo: true,
            callback: function() { parent.launchImageEditor(this); },
        });

        this.FroalaEditor.DefineIcon('ef_editButton', { NAME: 'minus-square', template: 'font_awesome'  });
        this.FroalaEditor.RegisterCommand('ef_editButton', {
            title: 'Button Editor',
            focus: false,
            undo: true,
            callback: function() {
                parent.launchButtonEditor(this);
                this.events.trigger('blur', [], true);
            },
        });

        this.FroalaEditor.DefineIcon('ef_editTable', { NAME: 'table', template: 'font_awesome'  });
        this.FroalaEditor.RegisterCommand('ef_editTable', {
            title: 'Table Editor',
            focus: true,
            undo: true,
            callback: function() { parent.launchTableEditor(this); },
        });

        this.platformLocation.onPopState(() => {
            this.exitFullScreenIfOpen();
        });

        this.FroalaEditor.DefineIcon('ef_codeView', { NAME: 'code', template: 'font_awesome' });
        this.FroalaEditor.RegisterCommand('ef_codeView', {
            title: 'Code View',
            focus: true,
            undo: true,
            callback:  function() { parent.launchCodeView(this);

        }
        });

        this.platformLocation.onPopState(() => {
            this.exitFullScreenIfOpen();
        });
    }

    createEditEmailOptions: object = {
        'destroy': function() {
            // Do something here.
            // this is the editor instance.
           if (this.observer) {
            this.observer.unobserve(document.querySelector('#froala-spacer'));
           }

        },
        'initialized': (obj) => {
            this.scrollListener();
            const editor = obj._editor;
            editor.events.bindClick($('body'), '#insert-button', () => {
                editor.events.focus();
            });

            editor.events.bindClick($('body'), '#update-table', () => {
                editor.events.focus();
            });

            let designObject;
            editor.events.bindClick($('body'), '.slug', (slug) => {
                designObject = `[${slug.target.innerText}]`;
                if (slug.target.dataset.link) {
                    const snap = editor.snapshot.get();
                    return this.alertService.emailDesignCenter().linkDynamicElement().then((input) => {
                        editor.selection.save();
                        if (input.value === '') {
                            editor.selection.restore();
                            editor.snapshot.restore(snap);
                            return editor.html.insert(`<a href="${designObject}" target="_blank">${designObject}</a>`);
                        }
                        editor.selection.restore();
                        editor.snapshot.restore(snap);
                        editor.html.insert(`<a href="${designObject}" target="_blank">${input.value}</a>`);
                        editor.undo.saveStep();
                    });
                }
                if (slug.target.dataset.qrcode) {
                    editor.html.insert(`<img style="height:200px; width:200px;" src="${designObject}" alt="[INVITE_QR_CODE]" />`);
                    editor.undo.saveStep();
                    return;
                }
                if (slug.target.dataset.text) {
                    designObject = slug.target.dataset.text;
                    editor.html.insert(`${designObject}`);
                    editor.undo.saveStep();
                    return;
                }
                editor.html.insert(`${designObject}`);
                editor.undo.saveStep();
            });
        },

        'focus': function() {
            this.html.cleanEmptyTags();
            // editor.position.refresh();

            // disable button links while editing
            [].forEach.call(
                document.body.querySelectorAll('a.custom-button'), (i) => {
                    i.addEventListener('click', (event) => {
                        // event.preventDefault();
                    });
                }
            );
        },
        'blur': function() {
            this.html.cleanEmptyTags();
            // editor.position.refresh();
        },
        'contentChanged': function() {
            this.html.cleanEmptyTags();
            // editor.position.refresh();
        },
        'enter': (obj) => {
            // const editor = obj._editor;
            // editor.ENTER_BR;
        },
        'click': (e, editor) => {
            // editor.position.refresh();
        },

        'table.inserted': function(table) {
            // Used to style tables that are inserted in editor, but not those that are part of our templates
            table.setAttribute('data-origin', 'froala');
        },
        'paste.beforeCleanup': function(clipboardHtml) {
            const html = _.get(this, 'html.get') ? this.html.get(true) : '';
            Sentry.withScope(scope => {
                scope.setExtras({pastedContent: clipboardHtml, html});
                Sentry.captureMessage('Froala: Before Paste Cleanup', { level: 'info'});
            });
            if (clipboardHtml.search('img') !== -1) {
                this.message.warning('Please upload images through the image upload toolbar option');
                return clipboardHtml.replace(/<img[^>]*>/g, '');
            } else if (clipboardHtml.search('imagedata') !== -1) {
                this.message.warning('Please upload images through the image upload toolbar option');
                return clipboardHtml.replace(/<v:imagedata[^>]*>/g, '');
            }
        },
        'paste.afterCleanup': function(clipboardHtml) {
            const html = _.get(this, 'html.get') ? this.html.get(true) : '';
            Sentry.withScope(scope => {
                scope.setExtras({pastedContent: clipboardHtml, html});
                Sentry.captureMessage('Froala: After Paste Cleanup', { level: 'info'});
            });
        },
        'image.beforePasteUpload': (img) => {
            this.message.warning('Please upload images through the image upload toolbar option');
            img.remove();
            return false;
        },
        'image.resizeEnd': ($img) => {
                const imgWidth = $img[0].width;
                $img.attr('width', imgWidth);
        },
        'commands.after': (cmd) => {
            if (cmd === 'imageSetSize') {
                const selectedImg = this.FroalaEditor.getSelectedImage();
                selectedImg.attr('width', selectedImg[0].width);
                selectedImg.attr('height', selectedImg[0].height);
            }
            if (cmd === 'color') {
                this.previousColor = '';
            }
            if (cmd === 'colorChangeSet') {
                this.setCurrentColor();
            }
            this.currentColor = this.previousColor;
        },
        'commands.before': (cmd) => {
            if (cmd === 'colorChangeSet') {
                this.previousColor = this.getPreviousColor();
            }
        },

        'drop': (e, editor) => {
            e.preventDefault();
        },
        'position.refresh': function() {
            // Do something here.
            // this is the editor instance.
        }
    };

}
