import { AbstractModel } from '../abstract-model';
import {EfEvent} from '../Event/event';
import { User } from '../User/user';

export class Exhibitor extends AbstractModel {
    private _name?: string;
    private _description?: string;
    private _logoUrl?: string;
    private _isDeleted?: boolean;
    private _deleted?: number;
    private _created?: number;
    private _lastModified?: number;
    private _createdBy?: string;
    private _event: EfEvent;
    private _exhibitorTeamMembers: User[];
    private _totalUserRolesForExhibitor: number;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const exhibitor = new this(data.id);

        exhibitor._name = data.attributes.name;
        exhibitor._description = data.attributes.description;
        exhibitor._logoUrl = data.attributes.logoUrl;
        exhibitor._isDeleted = data.attributes.isDeleted;
        exhibitor._deleted = data.attributes.deletedAt;
        exhibitor._created = data.attributes.created.createdAt;
        exhibitor._lastModified = data.attributes.modified.modifiedAt;
        exhibitor._exhibitorTeamMembers = [];
        if (data.extraAttributes && data.extraAttributes.totalUserRolesForExhibitor) {
            exhibitor._totalUserRolesForExhibitor = data.extraAttributes.totalUserRolesForExhibitor;
        } else {
            exhibitor._totalUserRolesForExhibitor = 0;
        }

        if (data.relationships && data.relationships.event) {
            exhibitor._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        return exhibitor;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get logoUrl(): string {
        return this._logoUrl;
    }

    set logoUrl(value: string) {
        this._logoUrl = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get deleted(): number {
        return this._deleted;
    }

    set deleted(value: number) {
        this._deleted = value;
    }

    get created(): number {
        return this._created;
    }

    set created(value: number) {
        this._created = value;
    }

    get lastModified(): number {
        return this._lastModified;
    }

    set lastModified(value: number) {
        this._lastModified = value;
    }

    get createdBy(): string {
        return this._createdBy;
    }

    set createdBy(value: string) {
        this._createdBy = value;
    }

    get event(): EfEvent {
        return this._event;
    }

    set event(value: EfEvent) {
        this._event = value;
    }

    get exhibitorTeamMembers(): User[] {
        return this._exhibitorTeamMembers;
    }

    set exhibitorTeamMembers(value: User[]) {
        this._exhibitorTeamMembers = value;
    }

    get totalUserRolesForExhibitor(): number {
        return this._totalUserRolesForExhibitor;
    }

    set totalUserRolesForExhibitor(value: number) {
        this._totalUserRolesForExhibitor = value;
    }
}
