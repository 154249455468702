import { Component, Input, OnInit } from '@angular/core';
import { SalesforceEventIntegrationsService } from '../eventIntegrations.service';
import { AlertService } from '../../../../eventFarmAlert.service';

@Component({
    selector: 'configuration-options',
    template: require('./configurationOptions.html'),
    styles: [require('./configurationOptions.scss')],
})

export class ConfigurationOptionsComponent implements OnInit {

    public campaignSelection = [{}];

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private alertService: AlertService,
    ) {
    }

    @Input() eventSettings;

    ngOnInit() {

        this.campaignSelection[0] = {
            id: this._sfeis.eventSettings.salesforceConfig.attributes.sfCampaignId,
            text: this._sfeis.eventSettings.salesforceConfig.attributes.sfCampaignName,
        };
    }

    makeActive() {
        if (this.eventSettings.salesforceConfig['attributes']['sfCampaignId'] != null) {
            this._sfeis.makeActive();
        } else {
            this.alertService.salesforceIntegration().noCampaignSelected();
        }
    }

    updateCampaignId(campaignId) {
        this.alertService.salesforceIntegration().confirmCampaignChange()
            .then((result) => {
                if (result.value) {
                    const campaign = this.eventSettings.campaigns.find(c => c.id === campaignId);
                    this._sfeis.eventSettings.isSyncing = true;
                    this._sfeis.updateCampaign(campaign)
                        .subscribe((res) => {
                            this.campaignSelection[0] = {
                                id: campaign.id,
                                text: campaign.text,
                            };
                            this._sfeis.syncingFeedback();
                            this.updateMappings(campaign.id);

                        });
                } else {
                    this.campaignSelection[0] = {
                        id: this._sfeis.eventSettings.salesforceConfig.attributes.sfCampaignId,
                        text: this._sfeis.eventSettings.salesforceConfig.attributes.sfCampaignName,
                    };
                }
            });
    }

    updateMappings(campaignId) {
        this._sfeis.updateSalesforceStatusNames(campaignId)
            .subscribe((res) => {
                this.eventSettings.fieldMappings.intStatusNames = res['data']['attributes']['records'];
            });
    }

}
