import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Invitation} from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import {Store} from '@ngrx/store';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ValidateEmail} from '../../../../Shared/Validators/emailValidator';
import {ValidateEmailOrPhoneRequired} from '../../../../Shared/Validators/validateEmailOrPhoneRequired';
import {ArrivalAlertUtility} from "../../../../Utilities/arrivalAlert.utility";
import {EventFarmService} from '../../../eventFarm.service';
import {EfModalService} from '../../../eventFarmModal.service';
import {EventAccessService} from '../../../EventsModule/EventAccess/event-access.service';
import * as fromRoot from '../../../store';
import {GuestListViewService} from '../guest-list-view.service';

import {GuestListBulkUpdateFormService} from './guest-list-bulk-update-form.service';

type GuestListBulkUpdateSelectionType =
    'default' |
    'inviteCounts' |
    'accessType' |
    'guestPassCounts' |
    'proxyEmail' |
    'invitationNotes' |
    'checkInNotes' |
    'arrivalAlert' |
    'checkInInvitations' |
    'status';

interface BulkUpdateOptionInterface {
    id: number;
    name: string;
    value: GuestListBulkUpdateSelectionType;
}

@Component({
    selector: 'guest-list-bulk-update-form',
    template: require('./guest-list-bulk-update-form.html'),
    styles: [require('./guest-list-bulk-update-form.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestListBulkUpdateFormComponent implements OnInit, OnDestroy {
    @ViewChild('confirmation') confirmation: TemplateRef<any>;
    @ViewChild('inviteCounts') inviteCounts: TemplateRef<any>;
    @ViewChild('accessType') accessType: TemplateRef<any>;
    @ViewChild('guestPassCounts') guestPassCounts: TemplateRef<any>;
    @ViewChild('proxyEmail') proxyEmail: TemplateRef<any>;
    @ViewChild('invitationNotes') invitationNotes: TemplateRef<any>;
    @ViewChild('checkInNotes') checkInNotes: TemplateRef<any>;
    @ViewChild('arrivalAlert') arrivalAlert: TemplateRef<any>;
    @ViewChild('checkInInvitations') checkInInvitations: TemplateRef<any>;
    @ViewChild('status') status: TemplateRef<any>;

    private bulkUpdateOptions: BulkUpdateOptionInterface[];
    private statusOptions;
    private guestListBulkUpdateFormSub$: any;
    private postProcessInviteCountUpdateCountsByStackSub$;
    private postProcessAccessTypeUpdateCountsByStackSub$;
    private accessTypeUpdateMap: Map<string, number>;
    private inviteCountUpdateMap: Map<string, number>;
    private bulkUpdateForm;
    private modalRef;
    private updating: boolean = false;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private guestListService: GuestListViewService,
        private fb: FormBuilder,
        private guestListSearchAndFilterService: GuestListBulkUpdateFormService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService,
        private messageService: NzMessageService,
        private eventAccessService: EventAccessService,
        private modal: EfModalService

    ) {
        //We want to use "Confirmed" instead of "Affirmative"
        this.statusOptions = new Invitation().InvitationBulkUpdateStatusType().map(opt => {
            if (opt.name === 'Affirmative') { opt.name = 'Confirmed'; }
            return opt;
        });

        this.bulkUpdateOptions = [
            {id: 1, name: 'Modify Invite Count', value: 'inviteCounts'},
            {id: 2, name: 'Modify Access Type', value: 'accessType'},
            {id: 3, name: 'Modify Guest Pass Count', value: 'guestPassCounts'},
            {id: 4, name: 'Modify Delegate Email', value: 'proxyEmail'},
            {id: 5, name: 'Modify Invitation Note', value: 'invitationNotes'},
            {id: 6, name: 'Modify Check In Note', value: 'checkInNotes'},
            {id: 7, name: 'Modify Arrival Alert', value: 'arrivalAlert'},
            {id: 8, name: 'Check In Guests', value: 'checkInInvitations'},
            {id: 9, name: 'Modify Status', value: 'status'}
        ];
    }

    ngOnInit() {
        this.bulkUpdateForm = this.fb.group({
            selection: [],
            inviteCounts: [1],
            accessType: [null],
            guestPassCounts: [1],
            proxyEmail: [''],
            useProxyEmail: [true],
            invitationNotes: [''],
            checkInNotes: [''],
            arrivalAlertEnabled: [true],
            arrivalAlertEmails: [''],
            arrivalAlertPhoneNumbers: [''],
            checkInInvitations: [true],
            status: []

        }, {validator: ValidateEmailOrPhoneRequired});
        this.modifyValidators(this.bulkUpdateForm.controls.selection);
        this.bulkUpdateForm.valueChanges.subscribe(val => {
            // Enable okay button in modal when form is valid
            this.setModalOkDisabled(!this.bulkUpdateForm.valid);
            // Make service aware of changing form values
            this.guestListBulkUpdateFormService.formValues$.next(val);

        });

        this.initSubscriptions();

    }

    private initSubscriptions() {
        // Subscribe to selection changes so we can adjust validators accordingly
        this.guestListBulkUpdateFormSub$ = this.bulkUpdateForm.controls.selection.valueChanges.subscribe(data => {
            // Initialize modal if selection is made
            if (data) {
                this.initModal(data);
                this.modifyValidators(data.value);
            }
        });

        // Subscribe to change with proxy email toggle so we can adjust validators accordingly
        this.guestListBulkUpdateFormSub$ = this.bulkUpdateForm.controls.useProxyEmail.valueChanges.subscribe(data => {
            // Delegate email selection is made
            if (data) {
                this.bulkUpdateForm.controls['proxyEmail'].setValidators([
                    Validators.required,
                    ValidateEmail
                ]);
            } else {
                this.bulkUpdateForm.controls['proxyEmail'].setValidators([
                ]);
            }
        });

        // Sets modal ok button to be disabled if any stack is going to be over-sold by performing bulk-update
        this.postProcessInviteCountUpdateCountsByStackSub$ = this.guestListBulkUpdateFormService.postProcessInviteCountUpdateCountsByStack$.subscribe(val => {
            this.inviteCountUpdateMap = val;
            this.handleOversoldCheck(val);
        });
        this.postProcessAccessTypeUpdateCountsByStackSub$ = this.guestListBulkUpdateFormService.postProcessAccessTypeUpdateCountsByStack$.subscribe(val => {
            this.accessTypeUpdateMap = val;
            // Disable if not able to update access types
            if (this.accessTypeUpdateMap?.size === 0) {
                this.setModalOkDisabled(true);
            } else {
                this.handleOversoldCheck(this.accessTypeUpdateMap);
            }
        });
    }

    private handleOversoldCheck(val) {
        if (!val) { return false; }
        let shouldDisable = false;
        Array.from(val.values()).forEach(v => {
            if (v < 0) {
                shouldDisable = true;
            }
        });
        if (shouldDisable) {
            this.setModalOkDisabled(true);
        } else {
            this.setModalOkDisabled(false);
        }
    }

    /* Have to do some fancy stuff here to disable the okay button the modal */
    private setModalOkDisabled(val: boolean) {
        if (this.modalRef) {
            this.modalRef.getConfig().nzOkDisabled = val;
        }
    }

    get modalOkDisabled(): boolean {
        return this.modalRef.getConfig().nzOkDisabled;
    }

    get proxyEmailSwitchLabel(): string  {
        return this.bulkUpdateForm.get('useProxyEmail').value ? 'Use Delegate Email' : 'Do Not Use Delegate Email';
    }


    private initModal(data) {
        // Store in temp variable since this selection value gets reset when modal is closed
        this.modalRef = this.modal.general({
            title: `Bulk update: ${data.name}`,
            html: this[data.value],
            icon: 'question'
        });

        // Reset bulk update selection when modal is closed
        this.modalRef.getConfig().nzOnCancel = () => {
            this.resetBulkUpdateSelection();
            this.resetBulkUpdateValues()
        };

        // Submit form when "ok" is pressed in modal
        this.modalRef.getConfig().nzOnOk = () => {

            // Change the content of the modal
            this.updateModalToConfirmation();
            // Prevents modal from closing
            return false;
        };
    }

    private updateModalToConfirmation() {
        const selection = this.bulkUpdateForm.get('selection').value.value;
        // Update text in modal
        this.modalRef.containerInstance.modalElementRef.nativeElement.querySelector('.ant-modal-body').innerHTML = `<p>Are you sure you wish to proceed?`;
        this.modalRef.getConfig().nzOnOk = () => {
            this.setModalOkDisabled(false);
            this.onSubmit(selection);
        };
    }

    private resetBulkUpdateSelection() {
        this.bulkUpdateForm.patchValue({selection: undefined});
        this.bulkUpdateForm.get('selection').markAsUntouched();
    }

    private resetBulkUpdateValues(){
        this.bulkUpdateForm.patchValue({
            inviteCounts: 1,
            accessType: null,
            guestPassCounts: 1,
            proxyEmail: '',
            useProxyEmail: false,
            invitationNotes: '',
            checkInNotes: '',
            arrivalAlertEnabled: true,
            arrivalAlertEmails: '',
            arrivalAlertPhoneNumbers: '',
            checkInInvitations: true,
            status: null

        })
    }

    private modifyValidators(selection: GuestListBulkUpdateSelectionType) {
        // Start by resetting all conditional validation
        this.clearConditionalValidation();
        switch (selection) {
            case 'inviteCounts':
                this.bulkUpdateForm.controls['inviteCounts'].setValidators([
                    Validators.required,
                    Validators.min(1),
                    Validators.pattern('^[0-9]*$'),

                ]);
                break;
            case 'accessType':
                this.bulkUpdateForm.controls['accessType'].setValidators([
                    Validators.required
                ]);

                break;
            case 'guestPassCounts':
                this.bulkUpdateForm.controls['guestPassCounts'].setValidators([
                    Validators.required,
                    Validators.min(0)
                ]);
                break;
            case 'proxyEmail':
                this.bulkUpdateForm.controls['proxyEmail'].setValidators([
                    Validators.required,
                    ValidateEmail
                ]);
                break;

            case 'invitationNotes':
                this.bulkUpdateForm.controls['invitationNotes'].setValidators([
                    Validators.maxLength(450)
                ]);
                break;
            case 'checkInNotes':
                this.bulkUpdateForm.controls['checkInNotes'].setValidators([
                    Validators.maxLength(450)
                ]);
                break;

            case 'status':
                this.bulkUpdateForm.controls['status'].setValidators([
                    Validators.required
                ]);
                break;
        }

        this.updateValuesAndValidity();
    }

    private clearConditionalValidation() {
        Object.keys(this.bulkUpdateForm.controls).forEach(key => {
            // We don't need to to anything for selection field since it persists
            if (key !== 'selection') {
                this.bulkUpdateForm.controls[key].clearValidators();
            }
        });
    }

    private updateValuesAndValidity() {
        Object.keys(this.bulkUpdateForm.controls).forEach(key => {
            // We don't need to to anything for selection field since it persists
            if (key !== 'selection') {
                this.bulkUpdateForm.controls[key].updateValueAndValidity();
            }
        });
    }

    get arrivalEmail(): string {
        return this.bulkUpdateForm.get('arrivalAlertEmails').value 

    }

    get arrivalPhoneNumbers(): string {
        return this.bulkUpdateForm.get('arrivalAlertPhoneNumbers').value
    }

    onSubmit(selection: GuestListBulkUpdateSelectionType) {
        let query;
        this.guestListBulkUpdateFormService.updating = true;

        switch (selection) {
            case 'inviteCounts':
                query = this.guestListBulkUpdateFormService.bulkUpdateInviteCounts(this.bulkUpdateForm.controls.inviteCounts.value);
                break;
            case 'accessType':
                query = this.guestListBulkUpdateFormService.bulkUpdateAccessTypes(this.bulkUpdateForm.controls.accessType.value.id);
                break;
            case 'guestPassCounts':
                query = this.guestListBulkUpdateFormService.bulkUpdateGuestPassCounts(this.bulkUpdateForm.controls.guestPassCounts.value);
                break;
            case 'proxyEmail':
                const payload = this.bulkUpdateForm.controls.useProxyEmail.value ? this.bulkUpdateForm.controls.proxyEmail.value : null;
                query = this.guestListBulkUpdateFormService.bulkUpdateSetProxyEmail(payload);
                break;
            case 'invitationNotes':
                query = this.guestListBulkUpdateFormService.bulkUpdateInvitationNotes(this.bulkUpdateForm.controls.invitationNotes.value);
                break;
            case 'checkInNotes':
                query = this.guestListBulkUpdateFormService.bulkUpdateCheckinNotes(this.bulkUpdateForm.controls.checkInNotes.value);
                break;
            case 'arrivalAlert':

                const arrivalUtility = new ArrivalAlertUtility();

                const arrivalAlerts = arrivalUtility.format(
                    this.bulkUpdateForm.controls.arrivalAlertEnabled.value,
                    this.arrivalEmail,
                    this.arrivalPhoneNumbers
                )
                query = this.guestListBulkUpdateFormService.bulkUpdateArrivalAlertDetails(arrivalAlerts.shouldSendArrivalAlert, arrivalAlerts.arrivalAlertEmails, arrivalAlerts.arrivalAlertMobileNumbers);
                break;
            case 'checkInInvitations':
                query = this.guestListBulkUpdateFormService.bulkUpdateCheckInInvitations();
                break;
            case 'status':
                query = this.guestListBulkUpdateFormService.bulkUpdateStatuses(this.bulkUpdateForm.get('status').value.slug);
                break;
        }
        query.subscribe(async res => {
                this.messageService.success('Bulk update complete!');
                await this.guestListService.assembleGuestListData();
                this.resetBulkUpdateSelection();
                this.resetBulkUpdateValues()
                // Uncheck selections after update
                this.guestListBulkUpdateFormService.clearBulkUpdate();
                this.guestListBulkUpdateFormService.updating = false;
            },
            err => {
                this.messageService.error('Error with bulk update');
                this.guestListBulkUpdateFormService.updating = false;
            });
    }

    ngOnDestroy() {
        this.postProcessInviteCountUpdateCountsByStackSub$.unsubscribe();
        this.postProcessAccessTypeUpdateCountsByStackSub$.unsubscribe();
    }
}
