
import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { WebConferenceUserConnection } from '../../ApiClient/Models/WebConferenceUserConnection/web-conference-user-connection';
import { WebConference, WebConferenceFormatTypeInterface, WebConferenceSourceTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/WebConference';
export class WebConferenceIntegrationAlert extends AbstractBaseAlert {
    public removeConnection(guestIdentifier: string): Promise<any> {
        return this.alert({
            title: 'Remove Connection?',
            text: 'Are you sure you want to remove ' + guestIdentifier + ' connection?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Remove',
        });
    }

    public changeConference(): Promise<any> {
        return this.alert({
            title: 'Warning',
            text: 'Some guests have already registered for this event.  Those guests may not be able to join if the location settings are changed.  Are you sure you want to save these changes?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue',
        });
    }

    public manageUserConnections(data: WebConferenceUserConnection[], deauthCallback, currentClass): Promise<any> {
        return this.alert({

            onBeforeOpen: (m) => {
                const content = swal.getContent()

                const removeConnection = async (connection: WebConferenceUserConnection) => {
                    deauthCallback(connection, currentClass)
                }

                const getImageFromType = (type: string) => {
                    if (type === 'zoom') {
                        return 'https://cdn.eventfarm.com/platform/img/zoom-button.png';
                    }
                }

                const populate = () => {

                    //Populate Connections
                    const list = content.querySelector("#webConferenceUserConnections");
                    list.innerHTML = "";

                    if (!data.length) {
                        var li = document.createElement("li");
                            li.className = 'w-full flex flex-row items-center justify-between'
                            li.innerHTML =  '<span class="block item-name"><b>No Connections. </b></span>';
                        list.appendChild(li);
                    } else {
                        data.forEach((connection, idx) => {
                            var li = document.createElement("li");
                            li.className = 'w-full flex flex-row items-center justify-between'
                            li.innerHTML =  '<span class="block item-name" style="color: black;"><b><img src="' + getImageFromType(connection.sourceType.slug) + '" alt="Zoom Connect" style="width:20px; margin:10px" />'+ connection.userEmail + '</b></span>';
                            let background = 'background:white;';
                            if (!(idx & 1)){
                                background = 'background:lightgrey;';
                            }

                            li.style.cssText = background;

                            var btn = document.createElement('button');
                            btn.className = 'btn ef-btn-sm';
                            btn.style.cssText = "background: none;";
                            btn.innerHTML = '<i class="fa fa-trash" style="color: #c2185b; font-size: 1.2rem;"></i>';
                            btn.addEventListener('click', function(){
                                removeConnection(connection);
                            });

                            li.appendChild(btn);

                            list.appendChild(li);
                        })
                    }
                }

                populate();
            },
            title: `Manage Connections`,
            confirmButtonText: 'Done',
            html:
                `<ul id="webConferenceUserConnections" style="list-style: none;padding: 0px 10px 150px 10px;" class="sa-input-spacing"></ul>`
            ,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: false,
            preConfirm: async () => {
            }
        });
    }

    public manageVirtualSettings(currentUserConnection: WebConferenceUserConnection, data: WebConferenceUserConnection[]): Promise<any> {

        return this.alert({

            onBeforeOpen: (m) => {
                const content = swal.getContent()

                const getImageFromType = (type: string) => {
                    if (type === 'zoom') {
                        return 'https://cdn.eventfarm.com/platform/img/zoom-button.png';
                    }
                }

                const populate = () => {
                    //Populate Auth User
                    const select1 = content.querySelector("#connections");
                    select1.innerHTML = "";
                    data.forEach(obj => {
                        var option = document.createElement("option");
                        option.text = obj.userEmailAndType;
                        option.value = obj.id;
                        option.selected = obj.id === currentUserConnection.id;
                        select1.appendChild(option);
                    })
                }

                populate();
            },
            title: `Manage Settings`,
            confirmButtonText: 'Update Settings',
            showCancelButton: true,
            html:
            '<label style="display:flex;">Connections</label>' +
            `<select id="connections" class="form-control sa-input-spacing"></select>`
            ,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            preConfirm: async () => {
                const connectionId = (document.getElementById('connections') as HTMLInputElement).value;
                const connection = data.find(c => c.id === connectionId);

                return {
                    connection
                }
            }
        });
    }

    public conferenceConnectionIssue(guestIdentifier: string, type: WebConferenceSourceTypeInterface, isTeamManager: boolean): Promise<any> {

        if (isTeamManager) {
            const message = `The ${type.name} connection used for this event needs a refresh. If you have access to ${guestIdentifier}'s account, click here to reconnect! Warning this will take to to ${type.name}!`

            return this.alert({
                title: 'Connection Issue!',
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Reconnect',
            });
        } else {
            const message = `The ${type.name} connection used for this event needs a refresh. You team manager needs to reconnect ${guestIdentifier}'s account!`

            return this.alert({
                title: 'Connection Issue!',
                text: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
            });
        }
    }
}
