import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportsComponent } from './reports.component';
import { ReportComponent } from './Report/report.component';

import { StatisticsComponent } from './Statistics/statistics.component';
import { ViewMessagingComponent } from './SentMessages/view-messaging.component';

const routes: Routes = [
    {
        path: '',
        component: ReportsComponent,
        children: [
            {
                path: 'email-stats',
                component: StatisticsComponent,
            },
            {
                path: 'message-stats',
                component: ViewMessagingComponent,
            },
            {
                path: 'create',
                component: ReportComponent,
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class ReportsRoutingModule {
}
