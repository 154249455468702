import {Component, OnDestroy, OnInit} from '@angular/core';
import { IconService } from '../../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { shareReplay } from 'rxjs/operators';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { sortFormat } from '../../../../../Utilities/nzTableSortFormat';
import { EventAgendaService } from '../../event-agenda.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditTracksComponent } from './EditTracks/edit-tracks.component';

@Component({
    selector: 'tracks-list',
    template: require('./tracks-list.html'),
    styles: [require('./tracks-list.scss')]
})

export class TracksListComponent implements OnInit, OnDestroy {

    constructor(
        private iconService: IconService,
        private eventAgendaService: EventAgendaService,
        private store: Store<fromRoot.AppState>,
        private drawerService: NzDrawerService
    ) {}

    ngOnInit(): void {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    public test: string;

    async handleSearch(query: string) {
        this.eventAgendaService.tracksListOptions.pagination.currentPage = 1;
        this.eventAgendaService.tracksListOptions.query = query;
        await this.eventAgendaService.getAllTracks();
    }

    async loadDataFromServer(pageIndex: number, sortField: string, sortOrder: string) {
        this.eventAgendaService.tracksListOptions.pagination.currentPage = pageIndex;
        this.eventAgendaService.tracksListOptions.sortBy = sortField;
        this.eventAgendaService.tracksListOptions.sortDirection = sortOrder;
        await this.eventAgendaService.getAllTracks();
    }

    async onQueryParamsChange(params: NzTableQueryParams): Promise<void> {
        const { pageSize, pageIndex, sort, filter } = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || 'name';
        let sortOrder = (currentSort && currentSort.value) || null;
        sortOrder = sortFormat(sortOrder);
        await this.loadDataFromServer(pageIndex, sortField, sortOrder);
    }

    public openComponent(): void {
         const drawerRef = this.drawerService.create<EditTracksComponent, { }, string>({
            nzTitle: 'Add a New Track',
            nzContent: EditTracksComponent,
            nzWidth: '50%',
            nzContentParams: {
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });
        
        drawerRef.afterClose.subscribe(data => {
        });
    }

    ngOnDestroy() {
    }
}
