import { format, parsePhoneNumber } from 'libphonenumber-js';

export const validatePhoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export function isValidPhoneNumber(phoneString: string): boolean {
    return validatePhoneRegex.test(phoneString);
    // const phone = parsePhoneNumberFromString(phoneString);
    // return phone.isValid();
}

export function isValidPhoneNumberLibJS(phoneString: string): boolean {
    const phone = parsePhoneNumber(phoneString, 'US');

    return phone.isValid();
}

export function validateAndFormatPhoneNumber(phoneString: string): string|null {
    const phone = parsePhoneNumber(phoneString, 'US');

    if (phone.isValid) {
        return phone.format('E.164');
    }

    return null;
}
