import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditPromoComponent } from './edit-promo.component';
import { Promo } from '../../../../../../ApiClient/Models/Promotion/promo';

@Component({
  selector: 'edit-promo-button',
  template: `
    <button
      nz-button
      nzType="primary"
      class="main-action ef-btn-sm"
      (click)="openComponent()"
    >{{ buttonText }}</button>
  `
})

export class EditPromoButtonComponent implements OnInit {
  @Input() promo;
  @Input() buttonText;
  drawerTitle;
  width = '50%';

  constructor(
    private drawerService: NzDrawerService,
  ) {}

  ngOnInit() {
    if (!this.promo) {
      this.drawerTitle = 'Create Promo Code';
    } else {
      this.drawerTitle = 'Edit Promo Code';
    }
  }

  openComponent(): void {
    const drawerRef = this.drawerService.create<EditPromoComponent, { promo: Promo }, string>({
      nzTitle: this.drawerTitle,
      nzContent: EditPromoComponent,
      nzWidth: this.width,
      nzContentParams: {
        promo: this.promo,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
    });

    drawerRef.afterClose.subscribe(data => {

    });
  }

}