import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { InvitationsConfirmationsService } from './../../invitations-confirmations.service';
import { Design } from '../../../../EmailDesignCenter/Models/design';
import { map, filter, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'design-selector',
    template: require('./design-selector.html')
})

export class DesignSelector implements OnInit {
    @Input() editMode;
    @Input() isSettingDesign;
    @Input() stack;
    @Input() designs;
    @Input() key;
    @Output() updateDesign = new EventEmitter();

    private selectedDesign;
    private designsToList$: Subject<Design[]> = new Subject();
    private newDesign = new Design();

    constructor(
        private ics: InvitationsConfirmationsService,
    ) {}

    ngOnInit() {
        this.selectedDesign = {
            type: this.key,
            design: this.stack[this.key] && this.designs[this.stack[this.key]] ? this.designs[this.stack[this.key]] : this.newDesign,
        };

        this.updateDesign.emit(this.selectedDesign);

        this.designsToList$ = this.ics.designsArr$.pipe(
            filter(res => !!res),
            map((designs: Design[]) => {
                return designs.filter((design) => {
                    if (this.key === 'inviteEmailId') {
                        return design.emailDesignTypeId === 'invitation';
                    }
                    if (this.key === 'confirmEmailId' || this.key === 'declineEmailId') {
                        return design.emailDesignTypeId === 'confirmation';
                    }
                    return design;
                });
            })
        );
    }

    handleDesignSelect(design) {
        this.selectedDesign = {
            design: design ? design : this.newDesign,
            type: this.key
        };
        this.updateDesign.emit(this.selectedDesign);
    }

    mapDesignId(design1, design2): boolean {
        return design1 && design2 ? design1 === design2 : design1 === design2;
    }
}
