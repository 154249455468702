import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';

@Component({
    selector: 'efx',
    template: require('./efx.html'),
    styles: [require('./efx.scss')],
})

export class EFXComponent {

  constructor(
    private eventFarmService: EventFarmService
  ) {
  }
}
