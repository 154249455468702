import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';
import * as fromStore from '../../../store';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription, BehaviorSubject } from 'rxjs';
import { Event } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';

@Component({
  selector: 'waitlist-toggle',
  template: require('./waitlist-toggle.html'),
  styles: [require('./waitlist-toggle.scss')],
})

export class WaitlistToggleComponent implements OnDestroy {

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>,
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      this.toggleState = this.eventFarmService.currentEvent.eventAttributes.hasShowWaitlistEnabled;
      this.toggleEmailState = this.eventFarmService.currentEvent.eventAttributes.hasWaitlistEmailEnabled;
      this.toggleSMSState = this.eventFarmService.currentEvent.eventAttributes.hasWaitlistSMSEnabled;
      this.waitListSMS$.next(this.eventFarmService.currentEvent.waitListSMS);
    });
  }

  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  public readonly waitListSMS$ = new BehaviorSubject<string>('');
  private toggleState: boolean = false;
  private toggleEmailState: boolean = false;
  private toggleSMSState: boolean = false;
  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;
  private readonly FIRST_NAME = 'first_name';
  private readonly LAST_NAME = 'last_name';
  private readonly EVENT_NAME = 'event_name';
  private readonly QR_CODE = 'qr_code';

  @ViewChild('waitlistSMSTxarea', {static: false}) waitlistSMSTxareaRef: ElementRef;

  get characterCount(): number {
    return this.waitListSMS$.value.length;
  }

  toggle(value: string) {

    switch (value) {
      case 'waitlist':
        this.toggleState = !this.toggleState;
        if (this.eventFarmService.currentEvent.eventAttributes.hasShowWaitlistEnabled) {
          this.disable();
        } else {
          this.enable();
        }
        break;
      case 'email':
        this.toggleEmailState = !this.toggleEmailState;
        if (this.eventFarmService.currentEvent.eventAttributes.hasWaitlistEmailEnabled) {
          this.disableEmail();
        } else {
          this.enableEmail();
        }
        break;
      case 'sms':
        this.toggleSMSState = !this.toggleSMSState;
        if (this.eventFarmService.currentEvent.eventAttributes.hasWaitlistSMSEnabled) {
          this.disableSMS();
        } else {
          this.enableSMS();
        }
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.waitListSMS$.next('');
    this.eventUpdate$.unsubscribe();
  }

  enable() {
    this.apiClient.getUseCaseFactory().Event().EnableWaitlistEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasShowWaitlistEnabled = true;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Waitlist is enabled');
      });
  }

  disable() {
    this.apiClient.getUseCaseFactory().Event().DisableWaitlistEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasShowWaitlistEnabled = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Waitlist is disabled');
      });
  }

  async enableSMS() {

    try {
      await this.apiClient.getUseCaseFactory().Event().EnableWaitlistSMSEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise();
      await this.apiClient.getUseCaseFactory().Event().EnableUserField(this.eventFarmService.currentEvent.id, 'phone').toPromise();
      this.eventFarmService.currentEvent.eventAttributes.hasWaitlistSMSEnabled = true;
      this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
      this.message.success('Waitlist SMS is enabled');
    } catch (e) {
    }
  }

  disableSMS() {
    this.apiClient.getUseCaseFactory().Event().DisableWaitlistSMSEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasWaitlistSMSEnabled = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Waitlist SMS is disabled');
      });
  }

  enableEmail() {
    this.apiClient.getUseCaseFactory().Event().EnableWaitlistEmailEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasWaitlistEmailEnabled = true;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Waitlist email is enabled');
      });
  }

  disableEmail() {
    this.apiClient.getUseCaseFactory().Event().DisableWaitlistEmailEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise()
      .then(() => {
        this.eventFarmService.currentEvent.eventAttributes.hasWaitlistEmailEnabled = false;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Waitlist email is disabled');
      });
  }

  async addSlug(slug: string) {

    let slugToAdd = '';

    switch (slug) {
      case(this.FIRST_NAME):
        slugToAdd = `[${this.FIRST_NAME}]`;
        break;
      case(this.LAST_NAME):
        slugToAdd = `[${this.LAST_NAME}]`;
        break;
      case(this.EVENT_NAME):
        slugToAdd = `[${this.EVENT_NAME}]`;
        break;
      case(this.QR_CODE):
        slugToAdd = `[${this.QR_CODE}]`;
        break;
      default:
    }

    if ((this.characterCount + slugToAdd.length) < 160) {
      const start = this.waitlistSMSTxareaRef.nativeElement.selectionStart;
      const end = this.waitlistSMSTxareaRef.nativeElement.selectionEnd;
      const newSMS = this.waitListSMS$.value.slice(0, start) + slugToAdd + this.waitListSMS$.value.slice(end);
      this.waitListSMS$.next(newSMS);
      await this.waitlistSMSTxareaRef.nativeElement.focus();
      setTimeout(function() {
        const element = document.getElementById('wltxtarea') as HTMLInputElement;
        const location = end + slugToAdd.length;
        element.setSelectionRange(location, location);
       }, 10);
    } else {
      this.message.warning('Message length too long');
    }
  }

  async saveGreeting() {
    const waitlistSMS: string = this.waitListSMS$.value;
    if (!waitlistSMS.length) {
        try {
            await this.apiClient.getUseCaseFactory().Event().DisableWaitlistSMSEnabledForEvent(this.eventFarmService.currentEvent.id).toPromise();
            await this.apiClient.getUseCaseFactory().Event().RemoveMessageForEvent(this.eventFarmService.currentEvent.id, 'wait_list_sms').toPromise();
            this.eventFarmService.currentEvent.eventAttributes.hasWaitlistSMSEnabled = false;
            this.eventFarmService.currentEvent.waitListSMS = new Event().EventMessageType().filter(f => f.isWaitListSMS)[0].defaultValue;
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.message.success('Waitlist SMS is disabled');
        } catch (err) {}
    } else {
        await this.apiClient.getUseCaseFactory().Event().SetMessageForEvent(this.eventFarmService.currentEvent.id, 'wait_list_sms', waitlistSMS).toPromise();
        this.eventFarmService.currentEvent.waitListSMS = waitlistSMS;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Waitlist SMS set for event');
    }
  }
}
