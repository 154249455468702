import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { distinctUntilChanged, debounceTime, take, tap, shareReplay } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../store';
import * as eventActions from './../../store/actions/event';

@Component({
    selector: 'guest-search',
    template: require('./guestSearch.html'),
    styles: [require('./guestSearch.scss')],
})

export class GuestSearchComponent {

    constructor(
        private store: Store<fromRoot.AppState>,
        private formBuilder: FormBuilder
    ) {}

    private guestSearchForm: FormGroup = this.formBuilder.group({
        query: [''],
        sortBy: ['created-at'],
    });
    @Input() type;
    @Output() sendData: EventEmitter<any> = new EventEmitter();
 
    ngOnInit() {
        this.guestSearchForm.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(250),
        ).subscribe((data) => {
            this.sendData.emit(data);
            switch (this.type) {
                case 'waitlist':
                    return this.store.dispatch(new eventActions.FetchWaitList(data));
                case 'guestlist':
                    return this.store.dispatch(new eventActions.FetchWaitList(data));
                default:
                    return this.store.dispatch(new eventActions.FetchWaitList(data));
            }
        });
    }
}
