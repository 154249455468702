import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {LoaderGrayComponent} from "./loader-gray.component";

@NgModule({
    declarations: [LoaderGrayComponent],
    exports: [LoaderGrayComponent],
    imports: [CommonModule],
})

export class LoaderGrayModule {
}
