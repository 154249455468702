import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import { User } from '../../ApiClient/Models/User/user';
import { isValidEmail } from '../../Utilities/validateEmail';

export class WelcomeEmailAlert extends AbstractBaseAlert {

    resendWelcomeEmail(): Promise<any> {
        return this.alert({
            title: 'Resend welcome email',
            confirmButtonText: 'Yes, send',
            text: 'Are you sure you would like to resend the welcome email?',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
        });
    }

}
