import { PoolContact } from '@eventfarm/javascript-sdk/dist/Api/UseCase/PoolContact';
import { Action } from '@ngrx/store';
import * as userActions from '../actions/user.action';
import { User } from '../../../ApiClient/Models/User/user';
import { Team } from '../../../ApiClient/Models/Pool/team';

export interface UserState {
    loaded: boolean;
    loading: boolean;
    data: User | null;
    teams: object;
    poolContactType: PoolContactType;
}

export interface PoolContactType {
    loaded?: boolean;
    loading?: boolean;
}

export const initialPoolContactType: PoolContactType = {
    loaded: false,
    loading: false,
};

export const initialState = {
    data: null,
    loaded: false,
    loading: false,
    teams: {},
    poolContactType: initialPoolContactType
};

export function reducer(
    state = initialState,
    action: userActions.UserAction
): UserState {
    switch (action.type) {
        case userActions.GET_CURRENT_USER:
            return { ...state };

        case userActions.GET_CURRENT_USER_SUCCESS: {
            const data = action.payload;
            const userData = User.fromApiResponse(data);
            return {
                ...state,
                data: userData,
                loading: false
            };
        }

        case userActions.FETCH_TEAMS_FOR_USER:
            return { ...state, loading: true };

        case userActions.FETCH_TEAMS_FOR_USER_SUCCESS: {

            const teams = action.payload.reduce((entities, pool) => {
                return {
                    ...entities,
                    [pool.id]: pool
                };
            }, {});

            return {
                ...state,
                teams,
                loading: false
            };
        }

        case userActions.GET_USER_POOL_PERMISSIONS:
            return {
                ...state,
                loading: true,
                poolContactType: {
                    ...state.poolContactType,
                    loading: true,
                    loaded: false
                }
            };

        case userActions.GET_USER_POOL_PERMISSIONS_SUCCESS:
            const team = action.payload.teamId;
            return {
                ...state,
                poolContactType: {
                    ...state.poolContactType,
                    [team]: {
                        ...action.payload.type,
                    },
                    loading: false,
                    loaded: true
                },
                loading: false,
                loaded: true
            };
        default:
            return state;
    }
}
