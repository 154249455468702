import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import * as fromRoot from '../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AlertService } from '../../../eventFarmAlert.service';
import { Payment } from '../../../../ApiClient/Models/Payment/payment';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { Withdrawal } from '../../../../ApiClient/Models/Withdrawal/withdrawal';

@Injectable()
export class EventRevenueService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private nzMessageService: NzMessageService,
        private iconService: IconService,
        private store: Store<fromRoot.AppState>,
        private alertService: AlertService
    ) {

        this.currentWithdrawals$.subscribe((withdrawals) => {
            const sum =  withdrawals.reduce((a, b) => a + (b['amount'] || 0), 0);
            this.currentWithdrawalsTotal$.next(sum);
        });
    }

    public readonly currentPayments$ = new BehaviorSubject<LogItem[]>([]);
    public readonly currentTotals$ = new BehaviorSubject<PaymentTotals>(null);
    public readonly currentWithdrawals$ = new BehaviorSubject<Withdrawal[]>([]);
    public readonly currentWithdrawalsTotal$ = new BehaviorSubject<number>(0);

    public paymentsMetaSource = new BehaviorSubject<any>({});
    public paymentsMeta$ = this.paymentsMetaSource.asObservable();
    public withdrawalsMetaSource = new BehaviorSubject<any>({});
    public withdrawalsMeta$ = this.withdrawalsMetaSource.asObservable();
    public isLoadingPayments = false;
    public isLoadingWithdrawals = false;
    public isLoadingTotals = false;
    public eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    public listOptions: ListOptions = {
        query: '',
        sortBy: 'name',
        sortDirection: 'ascending',
        pagination: {
            currentPage: 1,
            itemsPerPage: 5,
        },
        withData: ['Purchase', 'Refund'],
        typeFilter: []
    };

    public clearList() {
        this.currentPayments$.next([]);
        this.paymentsMetaSource.next(null);
        this.currentWithdrawals$.next([]);
        this.currentWithdrawalsTotal$.next(0);
        this.withdrawalsMetaSource.next(null);
        this.listOptions.pagination.currentPage = 1;
        this.listOptions.query = '';
        this.currentTotals$.next(null);
    }

    public get transactionCount(): number {
        return this.currentPayments$.value.length;
    }

    public get totalResults(): number {
        return this.paymentsMetaSource.value.totalResults;
    }

    public get availableRev(): number {
        return this.currentTotals$.value.net.availableTotal;
    }

    public get sumOfWithdrawals(): number {
        return this.currentWithdrawalsTotal$.value;
    }

    public get currentEventId(): string {
        return this.eventFarmService.currentEvent.id;
    }

    public get currentEvent(): EfEvent {
        return this.eventFarmService.currentEvent;
    }

    public get showMobile(): boolean {
        if (this.currentTotals$.value.revenue.mobileCredit.count > 0) {
            return true;
        }
        return false;
    }

    public async getPaymentsForEvent() {

        this.isLoadingPayments = true;

        const isShield = this.eventFarmService.currentEvent.isShield;

        if (isShield) {
            this.listOptions.typeFilter = ['transaction'];
        } else {
            this.listOptions.typeFilter = ['ticket'];
        }

        const payments = await this.apiClient.getUseCaseFactory()
        .Payment()
        .ListPaymentsForEvent(
            this.eventFarmService.currentEvent.id,
            this.listOptions.withData,
            this.listOptions.pagination.currentPage,
            this.listOptions.pagination.itemsPerPage,
            this.listOptions.query,
            this.listOptions.sortBy,
            this.listOptions.sortDirection,
            this.listOptions.typeFilter,
        ).toPromise();

        this.paymentsMetaSource.next(payments.meta);

        this.currentPayments$.next(payments.data.map(payment => {
            return Payment.fromApiResponse(payment);
        }));

        this.isLoadingPayments = false;
    }

    public async getWithdrawalsForEvent() {

        this.isLoadingWithdrawals = true;

        const withdrawals = await this.apiClient.getUseCaseFactory()
            .Withdrawal()
            .ListWithdrawalsForEvent(
                this.eventFarmService.currentEvent.id
            ).toPromise();
        this.withdrawalsMetaSource.next(withdrawals.meta);
        this.currentWithdrawals$.next(withdrawals.data.map(withdrawal => {
            return Withdrawal.fromApiResponse(withdrawal);
        }));

        this.isLoadingWithdrawals = false;
    }

    public async getReceipt(payment: Payment) {

    }

    public async resendReceipt(payment: Payment) {
        await this.alertService.revenue().resendReceipt(payment.id, payment.detailedName);
    }

    public async withdrawFunds() {
        await this.alertService.revenue().withdrawFunds(this.eventFarmService.currentEvent.id, this.currentTotals$.value.net.availableTotal, this);
    }

    public async refundPayment(payment: Payment) {
        const els = document.querySelectorAll<HTMLElement>('.cdk-global-overlay-wrapper .cdk-overlay-pane');
        const pane = els.length > 0 ? els[0] : undefined
        if (pane) {
            pane.style.display = 'none';
        }
        await this.alertService.revenue().refundFunds(payment.id, payment.paymentInfo.spreedlyTransactionId, payment.remainingAmount);
        if (pane) {
            pane.style.display = 'block';
        }
        await this.getPaymentsForEvent();
    }

    private getIconForType(type: string): string {
        if (type === 'added') {
            return this.iconService.actLogAdded;
        } else if (type === 'created') {
            return this.iconService.actLogCreated;
        } else if (type === 'check-in') {
            return this.iconService.actLogCheckIn;
        } else if (type === 'interacted') {
            return this.iconService.actLogInteracted;
        } else if (type === 'removed') {
            return this.iconService.actLogRemoved;
        } else if (type === 'responded') {
            return this.iconService.actLogResponded;
        } else {
            return this.iconService.actLogOther;
        }
    }

    private totalString(type: string): string {
    switch (type) {
            case 'net':
                return this.formatString(this.currentTotals$.value.net.total, false, this.currentTotals$.value.processingCurrency);
            case 'rev-total':
                return this.formatString(this.currentTotals$.value.revenue.total.revenue, false, this.currentTotals$.value.processingCurrency);
            case 'credit':
                return this.formatString(this.currentTotals$.value.revenue.credit.sum, false, this.currentTotals$.value.processingCurrency);
            case 'mobile-credit':
                return this.formatString(this.currentTotals$.value.revenue.mobileCredit.sum, false, this.currentTotals$.value.processingCurrency);
            case 'cash':
                return this.formatString(this.currentTotals$.value.revenue.cash.sum, false, this.currentTotals$.value.processingCurrency);
            case 'check':
                return this.formatString(this.currentTotals$.value.revenue.check.sum, false, this.currentTotals$.value.processingCurrency);
            case 'processing-fee-total':
                return this.formatString(this.totalProcessingFees(), true, this.currentTotals$.value.processingCurrency);
            case 'li-fee':
                return this.formatString(this.currentTotals$.value.expenses.fees.licenseFee , true, this.currentTotals$.value.processingCurrency);
            case 'pr-fee':
                return this.formatString(this.currentTotals$.value.expenses.fees.processingFees, true, this.currentTotals$.value.processingCurrency);
            case 'pr-per':
                return this.formatString(this.currentTotals$.value.expenses.fees.processingPerc, true, this.currentTotals$.value.processingCurrency);
            case 'ref-fee':
                return this.formatString(this.currentTotals$.value.expenses.fees.refundFees, true, this.currentTotals$.value.processingCurrency);
            case 'refunds':
                return this.formatString(this.currentTotals$.value.expenses.refunds.sum, true, this.currentTotals$.value.processingCurrency);
            case 'promos':
                return this.formatString(Math.abs(this.currentTotals$.value.expenses.promotions.sum), true, this.currentTotals$.value.processingCurrency);
            case 'withdrawn':
            return this.formatString(Math.abs(this.currentWithdrawalsTotal$.value), true, this.currentTotals$.value.processingCurrency);
        }

    return '';

    }

    private highlightText(type: string): boolean {
        switch (type) {
            case 'net':
                return this.textPositive(this.currentTotals$.value.net.total);
            case 'rev-total':
                return this.textPositive(this.currentTotals$.value.revenue.total.revenue);
            case 'credit':
                return this.textPositive(this.currentTotals$.value.revenue.credit.sum);
            case 'cash':
                return this.textPositive(this.currentTotals$.value.revenue.cash.sum);
            case 'check':
                return this.textPositive(this.currentTotals$.value.revenue.check.sum);
            case 'fee-total':
                return this.textDanger(this.currentTotals$.value.expenses.total.expenses);
            case 'li-fee':
                return this.textDanger(this.currentTotals$.value.expenses.fees.licenseFee);
            case 'pr-fee':
                return this.textDanger(this.currentTotals$.value.expenses.fees.processingFees);
            case 'pr-per':
                return this.textDanger(this.currentTotals$.value.expenses.fees.processingPerc);
            case 'ref-fee':
                return this.textDanger(this.currentTotals$.value.expenses.fees.refundFees);
            case 'refunds':
                return this.textDanger(this.currentTotals$.value.expenses.refunds.sum);
            case 'promos': // We want to always have Promos appear with "danger" class, and they come back as "-" to us
                return this.textDanger(Math.abs(this.currentTotals$.value.expenses.promotions.sum));
            case 'withdrawn': // We want to always have Promos appear with "danger" class, and they come back as "-" to us
                return this.textDanger(Math.abs(this.currentWithdrawalsTotal$.value));
        }
        return false;
    }

    private countText(type: string): string {
        switch (type) {
            case 'rev-total':
                return this.countString(this.currentTotals$.value.revenue.total.count);
            case 'credit':
                return this.countString(this.currentTotals$.value.revenue.credit.count);
            case 'mobile-credit':
                return this.countString(this.currentTotals$.value.revenue.mobileCredit.count);
            case 'cash':
                return this.countString(this.currentTotals$.value.revenue.cash.count);
            case 'check':
                return this.countString(this.currentTotals$.value.revenue.check.count);
            case 'fee-total':
                return this.countString(this.currentTotals$.value.expenses.total.count);
            case 'refunds':
                return this.countString(this.currentTotals$.value.expenses.refunds.count);
            case 'promos':
                return this.countString(this.currentTotals$.value.expenses.promotions.count);
        }
        return '';
    }

    private processingFeeText(type: 'perc' | 'fixed'): string {
        switch (type) {
            case 'perc':
                return `(${(this.currentEvent.processingPerc * 100).toFixed(2)}%)`;
            case 'fixed':
                return `($${(this.currentEvent.processingFee).toFixed(2)})`;
            default:
                return '';
        }
    }

    private formatString(amount: number, isNegative: boolean = false, currency?: string): string {
        let res = '';
        const showUsdPrefix = (currency !== 'usd' && currency !== 'USD' && currency !== null && currency !== undefined) ? false : true;
        const usdPrefix = showUsdPrefix ? "$" : "";
        if (isNegative && amount > 0) {
            res = `(${usdPrefix}${amount.toFixed(2)})`;
        } else {
            res = `${usdPrefix}${amount.toFixed(2)}`;
        }
        return `${currency ? currency.toUpperCase() : ''} ` + res;
    }

    get displayProcessingPerc(): boolean {
        return Math.abs(this.currentEvent.processingPerc) !== 0;
    }

    get displayProcessingFee(): boolean {
        return Math.abs(this.currentEvent.processingFee) !== 0;
    }

    private formatPercentage(amount: number): string {
        return `${amount.toFixed(2)} (%)`;
    }

    private countString(count: number): string {
        return `(${count})`;
    }

    private textPositive(amount: number): boolean {
        return amount > 0 ? true : false;
    }

    private textDanger(amount: number): boolean {
        return amount > 0 ? true : false;
    }

    private textDangerFees(amount: number): boolean {
        return amount > 0 ? true : false;
    }

    get shouldDisableButton(): boolean {
        if (!this.currentTotals$.value) {
            return true;
        }
        return this.currentTotals$.value.revenue.total.count === 0;
    }
    private totalProcessingFees() {
       return this.currentTotals$.value.expenses.fees.processingPerc +
       this.currentTotals$.value.expenses.fees.processingFees +
       this.currentTotals$.value.expenses.fees.refundFees;
    }

    public async getPaymentTotals() {
        this.isLoadingTotals = true;
        const totals = await this.apiClient.getUseCaseFactory().Payment().GetPaymentTotalsForEvent(
            this.eventFarmService.currentEvent.id,
        ).toPromise();

        this.currentTotals$.next(totals.data);
        this.isLoadingTotals = false;
    }

}

export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
}

export interface ListOptions {
    query: string;
    sortBy: string|null;
    sortDirection: string;
    pagination: Pagination;
    withData: string[];
    typeFilter: string[];
}

export interface LogItem {
    value: string;
    icon: any;
}

export interface PaymentTotals {
    net: {
        total: number;
        availableTotal: number;
    };
    revenue: {
        total: {
            revenue: number;
            count: number;
        };
        credit: PaymentRevenueType;
        cash: PaymentRevenueType;
        check: PaymentRevenueType;
        mobileCredit: PaymentRevenueType;
        mobileCash: PaymentRevenueType;
    };
    expenses: {
        total: {
            expenses: number;
            count: number;
        };
        fees: {
            licenseFee: number;
            processingFees: number;
            processingPerc: number
            refundFees: number;
        };
        refunds: PaymentRevenueType;
        promotions: PaymentRevenueType;
    };
    processingCurrency: string;
}

export interface PaymentRevenueType {
    sum: number;
    count: number;
}
