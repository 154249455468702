import {Action} from '@ngrx/store';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';

export const FETCH_FEATURES_FOR_ADMIN = '[Admin/Team] Fetch Features';
export const FETCH_FEATURES_FOR_ADMIN_SUCCESS = '[Admin/Team] Fetch Features Success';
export const FETCH_FEATURES_FOR_ADMIN_FAIL = '[Admin/Team] Fetch Features Fail';

export const FETCH_FEATURES_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Features For Team';
export const FETCH_FEATURES_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Features For Team Success';
export const FETCH_FEATURES_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Features For Team Fail';

export const ENABLE_FEATURE_FOR_ADMIN_TEAM = '[Admin/Team] Enable Feature For Team';
export const ENABLE_FEATURE_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Enable Feature For Team Success';
export const ENABLE_FEATURE_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Enable Feature For Team Fail';

export const DISABLE_FEATURE_FOR_ADMIN_TEAM = '[Admin/Team] Disable Feature For Team';
export const DISABLE_FEATURE_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Disable Feature For Team Success';
export const DISABLE_FEATURE_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Disable Feature For Team Fail';


export const ADD_FEATURE_FOR_ADMIN_TEAM = '[Admin/Team] Add Feature For Team';
export const ADD_FEATURE_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Add Feature For Team Success';
export const ADD_FEATURE_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Add Feature For Team Fail';

export const REMOVE_FEATURE_FOR_ADMIN_TEAM = '[Admin/Team] Remove Feature For Team';
export const REMOVE_FEATURE_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Remove Feature For Team Success';
export const REMOVE_FEATURE_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Remove Feature For Team Fail';

export class FetchFeaturesForAdmin implements Action {
    readonly type = FETCH_FEATURES_FOR_ADMIN;
    constructor(public payload: {}) { }
}

export class FetchFeaturesForAdminSuccess implements Action {
    readonly type = FETCH_FEATURES_FOR_ADMIN_SUCCESS;
    constructor(public payload: PoolFeature[]) { }
}

export class FetchFeaturesForAdminFail implements Action {
    readonly type = FETCH_FEATURES_FOR_ADMIN_FAIL;
    constructor(public payload: string) { }
}

export class FetchFeaturesForAdminTeam implements Action {
    readonly type = FETCH_FEATURES_FOR_ADMIN_TEAM;
    constructor(public payload: {}) { }
}

export class FetchFeaturesForAdminTeamSuccess implements Action {
    readonly type = FETCH_FEATURES_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolFeature[]) { }
}

export class FetchFeaturesForAdminTeamFail implements Action {
    readonly type = FETCH_FEATURES_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class AddFeatureForAdminTeam implements Action {
    readonly type = ADD_FEATURE_FOR_ADMIN_TEAM;
    constructor(public payload: {teamId: string, featureId: string}) { }
}

export class AddFeatureForAdminTeamSuccess implements Action {
    readonly type = ADD_FEATURE_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolFeature) { }
}

export class AddFeatureForAdminTeamFail implements Action {
    readonly type = ADD_FEATURE_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class RemoveFeatureForAdminTeam implements Action {
    readonly type = REMOVE_FEATURE_FOR_ADMIN_TEAM;
    constructor(public payload: {teamId: string, featureId: string}) { }
}

export class RemoveFeatureForAdminTeamSuccess implements Action {
    readonly type = REMOVE_FEATURE_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolFeature) { }
}

export class RemoveFeatureForAdminTeamFail implements Action {
    readonly type = REMOVE_FEATURE_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class EnableFeatureForAdminTeam implements Action {
    readonly type = ENABLE_FEATURE_FOR_ADMIN_TEAM;
    constructor(public payload: {teamId: string, featureId: string}) { }
}

export class EnableFeatureForAdminTeamSuccess implements Action {
    readonly type = ENABLE_FEATURE_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolFeature) { }
}

export class EnableFeatureForAdminTeamFail implements Action {
    readonly type = ENABLE_FEATURE_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class DisableFeatureForAdminTeam implements Action {
    readonly type = DISABLE_FEATURE_FOR_ADMIN_TEAM;
    constructor(public payload: {teamId: string, featureId: string}) { }
}

export class DisableFeatureForAdminTeamSuccess implements Action {
    readonly type = DISABLE_FEATURE_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolFeature) { }
}

export class DisableFeatureForAdminTeamFail implements Action {
    readonly type = DISABLE_FEATURE_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export type AdminFeatureAction =
    FetchFeaturesForAdmin |
    FetchFeaturesForAdminSuccess |
    FetchFeaturesForAdminFail |
FetchFeaturesForAdminTeam |
FetchFeaturesForAdminTeamSuccess |
FetchFeaturesForAdminTeamFail |
    AddFeatureForAdminTeam|
    AddFeatureForAdminTeamSuccess |
    AddFeatureForAdminTeamFail |
    RemoveFeatureForAdminTeam |
    RemoveFeatureForAdminTeamSuccess |
    RemoveFeatureForAdminTeamFail |
    EnableFeatureForAdminTeam |
    EnableFeatureForAdminTeamSuccess |
    EnableFeatureForAdminTeamFail |
    DisableFeatureForAdminTeam |
    DisableFeatureForAdminTeamSuccess |
    DisableFeatureForAdminTeamFail;
