import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventFarmService } from '../../../../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../../../../ApiClient/event-farm-api-client';
import { AdminService } from '../../../../../admin.service';
import { TeamEventsService } from '../../team-events.service';
import { EfEvent } from '../../../../../../../ApiClient/Models/Event/event';
import _ = require('lodash');

@Component({
  selector: 'edit-event-config',
  template: require('./edit-event-config.html'),
  styles: [require('./edit-event-config.scss')],
})
export class EditEventConfigComponent implements OnInit {
  @Input() eventId: string;
  public parentEventList$ = new BehaviorSubject<EfEvent[]>(null);

  private validStatuses$: Subscription;
  private isLoadingCurrentValues: boolean = true;
  private shouldSaveQuestions: boolean = false;
  private isSaving: boolean = false;
  private eventTypes;
  private paymentProcessorTypes;
  private currencyTypes;
  private shieldEnabled: boolean;
  private altKeyword: string = '';
  private altLayout: string = '';
  private altEmailLayout: string = '';
  private altProcessor: string = '';
  private altPaymentFile: string = '';
  private altPayee: string = '';
  private processingCurrency: string = '';
  private processingPerc: string = '';
  private processingFee: string = '';
  private processingRefund: string = '';
  private parentEvent: string = '';
  private healthPassShortCode: string = '';

  editEventsListItemForm: FormGroup;
  private parentEventList: EfEvent[];


  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private adminService: AdminService,
    private messageService: NzMessageService,
    private teamEventsService: TeamEventsService
  ) {}

  ngOnInit() {
  }

  private initializeFormControls(event) {

  }

  /**
   *
   * Updates the status of the invite
   */
  private onChangeStatus(status) {}

  /**
   * Saves invitation
   */
  private async saveInvitation() {
    if (this.isSaving === false) {
      this.shouldSaveQuestions = true;
      this.isSaving = true;
      this.isSaving = false;
    }
  }

  /**
   * Setup initial values and subscriptions
   */

  close() {
    this.drawerRef.close();
  }

  // Todo revisit using unsubscribe instead
  ngOnDestroy() {
    this.adminService.currentTeamEvent$.next(null);
  }
}
