import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { TicketTypeListComponent } from './ticketTypeList.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [TicketTypeListComponent],
    exports: [TicketTypeListComponent],
    imports: [
        CommonModule,
        UtilitiesModule,
        NgxUtilitiesModule
    ],
})

export class TicketTypeListModule {
}
