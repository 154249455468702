import { Contract } from '../../../ApiClient/Models/Contract/contract';
import { DomainMask } from '../../../ApiClient/Models/DomainMask/domainMask';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { Allotment } from '../../../ApiClient/Models/Pool/allotment';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { PoolContact } from '../../../ApiClient/Models/PoolContact/poolContact';
import { PoolFeature } from '../../../ApiClient/Models/PoolFeature/poolFeature';
import { PoolWorld } from '../../../ApiClient/Models/PoolWorld/pool-world';
import * as adminActions from '../actions/admin/';
import * as adminAllotmentActions from '../actions/admin/admin-allotment.action';
import * as adminArchiveTeamActions from '../actions/admin/admin-archive-team.action';
import * as adminContactActions from '../actions/admin/admin-contact.action';
import * as adminContractActions from '../actions/admin/admin-contract.action';
import * as adminCreateTeamActions from '../actions/admin/admin-create-team.action';
import * as adminDomainMaskActions from '../actions/admin/admin-domain-mask.action';
import * as adminFeatureActions from '../actions/admin/admin-feature.action';
import * as adminPoolWorldActions from '../actions/admin/admin-pool-world.action';

export interface EfMeta {
    totalPages:number,
    totalResults:number,
    totalResultsReturned:number,
    currentPage:number,
    itemsPerPage:number,
    executedAt:number
}
export interface AdminState {
    isArchivingCurrentTeam: boolean;
    currentTeam: {
        loading: boolean,
        data: Team
    };
    currentTeamEvents: {
        loading: boolean,
        data: EfEvent[]
        meta?: EfMeta
    };
    features: {
        loading: boolean,
        data: PoolFeature[]
    };
    currentTeamFeatures: {
        loading: boolean,
        data: PoolFeature[]
    };
    currentTeamAllotments: {
        loading: boolean,
        data: Allotment[]
    };
    currentTeamContacts: {
        loading: boolean,
        data: PoolContact[]
    };
    currentTeamContract: {
        loading: boolean,
        data: Contract
    };
    currentTeamPoolWorlds: {
        loading: boolean,
        data: PoolWorld[]
    };
    currentTeamDomainMasks: {
        loading: boolean,
        data: DomainMask[]
    };
    virbelaWorlds: {
        loading: boolean,
        data: PoolWorld[]
    };
}

export const initialAdminState = {
    isArchivingCurrentTeam: false,
    currentTeam: {
        loading: false,
        data: null
    },
    currentTeamEvents: {
        loading: false,
        data: []
    },
    features: {
        loading: false,
        data: []
    },
    currentTeamFeatures: {
        loading: false,
        data: []
    },
    currentTeamAllotments: {
        loading: false,
        data: []
    },
    currentTeamContacts: {
        loading: false,
        data: []
    },
    currentTeamContract: {
        loading: false,
        data: null
    },
    currentTeamPoolWorlds: {
        loading: false,
        data: []
    },
    currentTeamDomainMasks: {
        loading: false,
        data: []
    },
    virbelaWorlds: {
        loading: false,
        data: []
    }
};

export function reducer(
    state = initialAdminState,
    action: adminActions.AdminAction |
        adminFeatureActions.AdminFeatureAction |
        adminAllotmentActions.AdminAllotmentAction |
        adminContactActions.AdminContactAction |
        adminPoolWorldActions.AdminPoolWorldAction |
        adminContractActions.AdminContractAction |
        adminDomainMaskActions.AdminDomainMaskAction |
        adminCreateTeamActions.AdminCreateTeamAction |
        adminArchiveTeamActions.AdminArchiveCurrentTeamActions
): AdminState {

    switch (action.type) {
        case adminActions.FETCH_ADMIN_TEAM:
        case adminActions.CREATE_TEAM_FOR_ADMIN:
            return {
                ...state,
                isArchivingCurrentTeam: false,
                currentTeam: {
                    loading: true,
                    data: null
                }
            };

        case adminActions.FETCH_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeam: {
                    loading: false,
                    data: action.payload
                }
            };
        case adminActions.SET_CURRENT_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                isArchivingCurrentTeam: action.payload
            };

        case adminActions.ARCHIVE_CURRENT_TEAM_FOR_ADMIN:
            return {
                ...state,
                isArchivingCurrentTeam: true,
            };

        case adminActions.ARCHIVE_CURRENT_TEAM_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                isArchivingCurrentTeam: false,
                currentTeam: {
                    loading: false,
                    data: null
                },
            };

        case adminActions.ARCHIVE_CURRENT_TEAM_FOR_ADMIN_FAIL:
            return {
                ...state,
               isArchivingCurrentTeam: false,
        };

        // ALLOTMENTS
        case adminActions.FETCH_ALLOTMENTS_FOR_ADMIN_TEAM:
        case adminActions.UPDATE_ALLOTMENT_FOR_ADMIN_TEAM:
        case adminActions.ADD_ALLOTMENT_TO_ADMIN_TEAM:

            return {
                ...state,
                currentTeamAllotments: {
                    loading: true,
                    data: [...state.currentTeamAllotments.data]
                }
            };

        case adminActions.FETCH_ALLOTMENTS_FOR_ADMIN_TEAM_FAIL:
        case adminActions.UPDATE_ALLOTMENT_FOR_ADMIN_TEAM_FAIL:
        case adminActions.ADD_ALLOTMENT_TO_ADMIN_TEAM_FAIL:

            return {
                ...state,
                currentTeamAllotments: {
                    loading: false,
                    data: [...state.currentTeamAllotments.data]
                }
            };

        case adminActions.FETCH_ALLOTMENTS_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamAllotments: {
                    loading: false,
                    data: action.payload
                }
            };

        // CONTACTS

        case adminActions.FETCH_CONTACTS_FOR_ADMIN_TEAM:
        case adminActions.ADD_CONTACT_TO_ADMIN_TEAM:
        case adminActions.UPDATE_CONTACT_FOR_ADMIN_TEAM:

            return {
                ...state,
                currentTeamContacts: {
                    loading: true,
                    data: []
                }
            };

        case adminActions.FETCH_CONTACTS_FOR_ADMIN_TEAM_FAIL:
        case adminActions.ADD_CONTACT_TO_ADMIN_TEAM_FAIL:
        case adminActions.UPDATE_CONTACT_FOR_ADMIN_TEAM_FAIL:

            return {
                ...state,
                currentTeamContacts: {
                    loading: false,
                    data: [...state.currentTeamContacts.data]
                }
            };
        case adminActions.FETCH_CONTACTS_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamContacts: {
                    loading: false,
                    data: action.payload
                }
            };

        // EVENTS
        case adminActions.FETCH_EVENTS_FOR_ADMIN_TEAM:
            return {
                ...state,
                currentTeamEvents: {
                    loading: true,
                    data: []
                }
            };
        case adminActions.FETCH_EVENTS_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamEvents: {
                    loading: false,
                    data: action.payload.data,
                    meta: action.payload.meta
                }
            };

        case adminActions.FETCH_FEATURES_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                features: {
                    loading: true,
                    data: action.payload
                }
            };

        // FEATURES
        case adminActions.FETCH_FEATURES_FOR_ADMIN_TEAM:
        case adminActions.ADD_FEATURE_FOR_ADMIN_TEAM:
        case adminActions.REMOVE_FEATURE_FOR_ADMIN_TEAM:
            return {
                ...state,
                currentTeamFeatures: {
                    loading: true,
                    data: [...state.currentTeamFeatures.data]
                }
            };

        case adminActions.FETCH_FEATURES_FOR_ADMIN_TEAM_FAIL:
        case adminActions.ADD_FEATURE_FOR_ADMIN_TEAM_FAIL:
        case adminActions.REMOVE_FEATURE_FOR_ADMIN_TEAM_FAIL:
            return {
                ...state,
                currentTeamFeatures: {
                    loading: false,
                    data: [...state.currentTeamFeatures.data]
                }
            };

        case adminActions.FETCH_FEATURES_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamFeatures: {
                    loading: false,
                    data: action.payload
                }
            };

        // POOL WORLDS
        case adminActions.FETCH_VIRBELA_WORLDS_FOR_ADMIN:
            return {
                ...state,
                virbelaWorlds: {
                    loading: true,
                    data: []
                }
            };
        case adminActions.FETCH_VIRBELA_WORLDS_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                virbelaWorlds: {
                    loading: false,
                    data: action.payload
                }
            };

        // To trigger loading spinner
        case adminActions.FETCH_POOL_WORLDS_FOR_ADMIN_TEAM:
        case adminActions.ADD_POOL_WORLD_TO_ADMIN_TEAM:
        case adminActions.REMOVE_POOL_WORLD_FROM_ADMIN_TEAM:
            return {
                ...state,
                currentTeamPoolWorlds: {
                    loading: true,
                    data: [...state.currentTeamPoolWorlds.data]
                }
            };
        // To trigger loading spinner
        case adminActions.FETCH_POOL_WORLDS_FOR_ADMIN_TEAM_FAIL:
        case adminActions.ADD_POOL_WORLD_TO_ADMIN_TEAM_FAIL:
        case adminActions.REMOVE_POOL_WORLD_FROM_ADMIN_TEAM_FAIL:
            return {
                ...state,
                currentTeamPoolWorlds: {
                    loading: false,
                    data: [...state.currentTeamPoolWorlds.data]
                }
            };

        case adminActions.FETCH_POOL_WORLDS_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamPoolWorlds: {
                    loading: false,
                    data: action.payload
                }
            };

        // CONTRACT
        case adminActions.FETCH_CONTRACT_FOR_ADMIN_TEAM:
        case adminActions.ADD_CONTRACT_TO_ADMIN_TEAM:
        case adminActions.UPDATE_CONTRACT_FOR_ADMIN_TEAM:
            return {
                ...state,
                currentTeamContract: {
                    loading: true,
                    data: state.currentTeamContract.data
                }
            };

        case adminActions.FETCH_CONTRACT_FOR_ADMIN_TEAM_FAIL:
        case adminActions.ADD_CONTRACT_TO_ADMIN_TEAM_FAIL:
        case adminActions.UPDATE_CONTRACT_FOR_ADMIN_TEAM_FAIL:
            return {
                ...state,
                currentTeamContract: {
                    loading: false,
                    data: null
                }
            };

        case adminActions.FETCH_CONTRACT_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamContract: {
                    loading: false,
                    data: action.payload
                }
            }
        case adminActions.ADD_CONTRACT_TO_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeam: {
                    loading: false,
                    data: state.currentTeam.data
                },
                currentTeamContract: {
                    loading: false,
                    data: action.payload
                }
            };
        // DOMAIN MASKS
        case adminActions.FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM:
        case adminActions.REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM:
        case adminActions.ADD_DOMAIN_MASK_TO_ADMIN_TEAM:
            return {
                ...state,
                currentTeamDomainMasks: {
                    loading: true,
                    data: [...state.currentTeamDomainMasks.data]
                }
            };

        case adminActions.FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM_FAIL:
        case adminActions.REMOVE_DOMAIN_MASK_FROM_ADMIN_TEAM_FAIL:
        case adminActions.ADD_DOMAIN_MASK_TO_ADMIN_TEAM_FAIL:
            return {
                ...state,
                currentTeamDomainMasks: {
                    loading: false,
                    data: [...state.currentTeamDomainMasks.data]
                }
            };
        case adminActions.FETCH_DOMAIN_MASKS_FOR_ADMIN_TEAM_SUCCESS:
            return {
                ...state,
                currentTeamDomainMasks: {
                    loading: false,
                    data: action.payload
                }
            };
    }
    return state;
}
