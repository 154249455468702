import { Pipe } from '@angular/core';

@Pipe({
    name: 'webConferencePipe',
})
export class WebConferenceSlugPipe {
    transform(slugs, option) {
        switch (option) {
            case false:
            case undefined:
                return slugs.filter((i) => i['name'] !== 'WEB_CONFERENCE_REGISTRANT_JOIN_URL');
            default:
                return slugs.filter((i) => i);
        }
    }
}
