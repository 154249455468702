import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { EventFarmService } from '../../../eventFarm.service';

import { GuestListViewService } from '../guest-list-view.service';
import { GuestResponsesService } from './guest-responses.service';

import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';
import { ModalService } from '../../Modal/modal.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { Observable ,  Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Router } from '@angular/router';
class Answer {
    constructor(
        public id: string,
        public text: string,
    ) {}
}

class Response {
    public static fromApi(response) {
        if (response.relationships.answer) {
            return { answerId: response.relationships.answer.id };
        }

        return { text: response.attributes.text };
    }
}

class Question {
    invitationId: string;

    name: string;
    type: string;
    label: string;
    options: any[] = [];
    responses: any[] = [];
    responseIds: string[] = [];
    formGroupValue: any = {};
    placeholder: string = '';
    isDisabled: boolean = false;

    public static fromApi(question, invitationId) {
        let result = new Question;
        try {
            result.invitationId = invitationId;
            result.name = question.id;
            result.type = question.attributes.questionType.slug;
            result.label = question.attributes.text;
            result.isDisabled = question.attributes.isDisabled ? question.attributes.isDisabled : false;
            result.options = question.relationships.answers
                .map(answer => new Answer(answer.id, answer.attributes.text));
        } catch (err) { }

        if (result.type === 'date') {
            result.placeholder = 'MM/DD/YYYY';
        }

        return result;
    }

    public supportsAnswers() {
        if (
            this.type === 'checkbox'
            || this.type === 'radio'
            || this.type === 'select'
        ) {
            return true;
        }

        return false;
    }

    public get shouldSave() {
        if (this.type === 'waiver') {
            return false;
        }

        return true;
    }

    public supportsMultipleAnswers() {
        if (this.type === 'checkbox') {
            return true;
        }

        return false;
    }

    public get storedValue() {
        if (this.supportsMultipleAnswers()) {
            return this.responses.map(response => response.answerId);
        }

        if (this.responses.length !== 1) {
            return null;
        }

        if (this.supportsAnswers()) {
            return this.responses[0].answerId;
        }

        return this.responses[0].text;
    }

    public attachFormGroupValue(formGroupValues) {
        this.formGroupValue = formGroupValues ? formGroupValues[this.name] : null;
    }

    public get answers() {
        if ( ! this.supportsAnswers()) {
            return null;
        }

        if (typeof this.formGroupValue === 'string') {
            return [ this.formGroupValue ];
        }

        if (this.supportsMultipleAnswers()) {
            let answerIds = [];

            for (const id in this.formGroupValue) {
                if (this.formGroupValue[id]) {
                    answerIds.push(id);
                }
            }

            return answerIds.length ? answerIds : null;
        }

        return this.formGroupValue;
    }

    public get text() {
        if (this.supportsAnswers()) {
            return null;
        }

        return this.formGroupValue;
    }
}

@Component({
    selector: 'guest-responses',
    template: require('./guest-responses.html'),
    styles: [require('./guest-responses.scss')],
})

export class GuestResponsesComponent implements OnDestroy {

    @Input() invitationId: string;
    @Input() incomingDrawerRef: NzDrawerRef;
    @Input() shouldSave: boolean;


    private questions = [];
    private loading: boolean = true;
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventUpdate$: Subscription;


    constructor(
        private modalService: ModalService,
        private guestListService: GuestListViewService,
        private guestResponsesService: GuestResponsesService,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private router: Router
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.fetch();
        });

    }

    private goToQuestions() {
        const eventId = this.eventFarmService.currentEvent.id;
        this.incomingDrawerRef.close();
        this.router.navigateByUrl(this.routeGenerator.url('events.questions', { eventId: eventId }));
    }



    fetch() {
        this.loading = true;
        this.questions = [];

        this.guestResponsesService.getAllQuestions()
        .then((questions) => this.fetchedQuestions(questions));
    }

    fetchedQuestions(questions) {
        this.questions = questions
            .map(question => Question.fromApi(question, this.invitationId));
        // this.questions.push({name: 'submit', type: 'button', label: 'Submit' });

        this.guestListService.fetchInvitationResponses(this.invitationId)
        .subscribe((invitation) => this.fetchedResponses(invitation));
    }

    fetchedResponses(invitation) {
        const responses = invitation.data.relationships.questionResponses;
        if ( ! responses || ! responses.length) {
            this.loading = false;
            return false;
        }

        responses.map(response => this.integrateResponse(response));

        this.questions = this.questions.filter(q => {
            if (q.responses.length === 0 && q.isDisabled) {
                return false;
            }

            return true;
        });

        this.loading = false;
    }

    integrateResponse(response) {
        const relatedQuestions = this.questions.filter(
            question => question.name === response.relationships.question.id
        );

        if ( ! relatedQuestions || relatedQuestions.length !== 1) {
            return false;
        }

        const relatedQuestion = relatedQuestions[0];

        if (!relatedQuestion.responseIds.includes(response.id)) {
            relatedQuestion.responseIds.push(response.id);
            relatedQuestion.responses.push(Response.fromApi(response));
        }
    }

    ngOnDestroy() {
        this.questions = [];
        this.eventUpdate$.unsubscribe();
    }

    formSubmitted(form) {
        this.saveAnswers(form);
    }

    saveAnswers(formGroupValues) {
        this.questions.forEach(question => {
            if ( ! question.shouldSave) {
                return false;
            }

            question.attachFormGroupValue(formGroupValues);

            this.guestResponsesService.updateQuestionResponse(
                this.invitationId,
                question
            ).subscribe((res) => this.modalService.closeModal());
        });
    }
}
