import { Component, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';

@Component({
    selector: 'guest-overview',
    template: require('./guest-overview.html'),
    styles: [require('./guest-overview.scss')],
})

export class GuestOverviewComponent {

    constructor(
        private eventFarmService: EventFarmService,
    ) {
    }
}
