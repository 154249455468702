import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { EventAgendaService } from '../../event-agenda.service';
import * as _ from 'lodash';
import { EFProfile } from '../../../../../ApiClient/Models/Profile/EFProfile';
import { EventProfileTypeInterface, Profile } from '@eventfarm/javascript-sdk/dist/Api/Type/Profile';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';


@Component({
    selector: 'speaker-to-session',
    template: require('./speaker-to-session.html'),
    styles: [require('./speaker-to-session.scss')],
})

export class SpeakerToSessionComponent implements OnInit, OnDestroy {
    @Input() activeSpeakers: EFProfile[] = [];
    @Input() sessionId: string;
    @Output() addedSpeakers: EventEmitter<EFProfile[]> = new EventEmitter();

    private readonly currentSpeakers$ = new BehaviorSubject<EFProfile[]>([]);
    private readonly selectedSpeakers$ = new BehaviorSubject<EFProfile[]>([]);
    private readonly allSpeakers$ = new BehaviorSubject<EFProfile[]>([]);

    private selectable: boolean = true;
    private removable: boolean = true;
    private speakerCtrl = new FormControl();
    private addOnBlur: boolean = false;
    private editSpeaker: EFProfile|null;
    private showAddNewSpeakerFields: boolean = false;
    private roles: EventProfileTypeInterface[] = new Profile().EventProfileType().filter(pt => !pt.isSponsor);
    private selectRole: string = 'Select Role';
    private loading: boolean = false;
    
    
    private newFirstName: string;
    private newLastName: string;
    private newEmail: string;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventAgendaService: EventAgendaService,
        private message: NzMessageService,
    ) {
    }

    async ngOnInit() {
        await this.initiateAllSpeakers();
    }

    private async initiateAllSpeakers() {
        this.loading = true;

        const speakers = await this.eventAgendaService.getAllProfiles(true);
        speakers.forEach((speaker) => {
            speaker.selected = false;
            speaker.edit = false;
            speaker.disabled = false;
            speaker.eventProfileSlug = _.get(speaker, 'eventProfiles[0].eventProfileType.slug', 'speaker');
            speaker.eventProfileId = '';
        });

        if (this.activeSpeakers && this.activeSpeakers.length) {
            const selectedSpeakers = [];
            this.activeSpeakers.forEach((active) => {
                const index = speakers.findIndex((speaker => speaker.id === active.id));
                
                if (index > -1) {
                    speakers[index] = active;
                    speakers[index].eventProfileSlug = _.get(active, 'eventProfiles[0].eventProfileType.slug');
                    speakers[index].eventProfileId = _.get(active, 'eventProfiles[0].id', '');
                    speakers[index].selected = true;
                    selectedSpeakers.push(speakers[index]);
                }
            });
            this.selectedSpeakers$.next(selectedSpeakers);
        }

        this.allSpeakers$.next(speakers);
        this.currentSpeakers$.next(speakers);
        this.loading = false;
    }

    get currentSpeakersLength():number {
        return this.currentSpeakers$.value.length;
    }

    get selectedSpeakersLength():number {
        return this.selectedSpeakers$.value.length;
    }


    private clearSpeakers() {
        this.selectedSpeakers$.next([]);
        this.updateSpeakersForSession([]);
        this.speakerCtrl.setValue(null);
    }

    private updateSpeakersForSession(speakers: EFProfile[]) {
        this.addedSpeakers.emit(speakers);
    }

    private async setProfileType(type: string) {
        const selectedSpeakers = _.cloneDeep(this.selectedSpeakers$.value);
        const allSpeakers = _.cloneDeep(this.allSpeakers$.value);
        const currentSpeakers = _.cloneDeep(this.currentSpeakers$.value);

        const ssIndex = selectedSpeakers.findIndex(ss => ss.id === this.editSpeaker.id);
        const asIndex = allSpeakers.findIndex(ss => ss.id === this.editSpeaker.id);
        const csIndex = currentSpeakers.findIndex(ss => ss.id === this.editSpeaker.id);

        if (this.editSpeaker.eventProfileId.length) {
            await this.eventAgendaService.setEventProfileType(this.editSpeaker.eventProfileId, type);
            this.message.success('Speaker category updated');
        } 

        selectedSpeakers[ssIndex].eventProfileSlug = type;
        allSpeakers[asIndex].eventProfileSlug = type;
        currentSpeakers[csIndex].eventProfileSlug = type;
        this.editSpeaker.eventProfileSlug = type;

        this.selectedSpeakers$.next(selectedSpeakers);
        this.allSpeakers$.next(allSpeakers);
        this.currentSpeakers$.next(currentSpeakers);

        this.updateSpeakersForSession(selectedSpeakers);
    }
    

    private toggleSelection(speakerSelected: EFProfile) {
        const speakerIndex = this.selectedSpeakers$.value.findIndex(list => list.id === speakerSelected.id);
        (speakerIndex > -1) ? this.remove(speakerSelected) : this.selected(speakerSelected);
    }

    private selected(selectedSpeaker: EFProfile): void {
        this.addSelectedSpeaker(selectedSpeaker);
    }

    private async remove(speaker: EFProfile) {
        this.removeSelectedSpeaker(speaker);
    }


    private removeSelectedSpeaker(speakerToRemove: EFProfile) {
        let selectedSpeakers = _.cloneDeep(this.selectedSpeakers$.value);
        const currentSpeakers = _.cloneDeep(this.currentSpeakers$.value);
        const allSpeakers = _.cloneDeep(this.allSpeakers$.value);

        this.selectedSpeakers$.next(selectedSpeakers.filter(speaker => speakerToRemove.id !== speaker.id));

        currentSpeakers.forEach(speaker => {
            if (speaker.id && speaker.id === speakerToRemove.id) {
                speaker.selected = false;
            }
        });

        allSpeakers.forEach(speaker => {
            if (speaker.id && speaker.id === speakerToRemove.id) {
                speaker.selected = false;
            }
        });

        this.currentSpeakers$.next(currentSpeakers);
        this.allSpeakers$.next(allSpeakers);

        this.updateSpeakersForSession(this.selectedSpeakers$.value);
    }

    private addSelectedSpeaker(speakerToAdd: EFProfile) {
        const selectedSpeakers = _.cloneDeep(this.selectedSpeakers$.value);
        const currentSpeakers = _.cloneDeep(this.currentSpeakers$.value);
        const allSpeakers = _.cloneDeep(this.allSpeakers$.value);

        if (selectedSpeakers.findIndex(s => s.id === speakerToAdd.id) < 0) {
            selectedSpeakers.push(speakerToAdd);
        }

        currentSpeakers.forEach(speaker => {
            if (speaker.id && speaker.id === speakerToAdd.id) {
                speaker.selected = true;
            }
        });

        allSpeakers.forEach(speaker => {
            if (speaker.id && speaker.id === speakerToAdd.id) {
                speaker.selected = true;
            }
        });

        this.selectedSpeakers$.next(selectedSpeakers);
        this.currentSpeakers$.next(currentSpeakers);
        this.allSpeakers$.next(allSpeakers);

        this.updateSpeakersForSession(this.selectedSpeakers$.value);
    }

    private editFields(speaker: EFProfile) {
        speaker.edit = !speaker.edit;
        if (this.editSpeaker) {
            this.selectedSpeakers$.value.forEach((s) => {
                s.disabled = false;
            });
            this.editSpeaker = null;
        } else {
            const index = this.selectedSpeakers$.value.findIndex(sp => sp.id === speaker.id);
            this.selectedSpeakers$.value.forEach((s) => {
                s.disabled = true;
            });

            const speakers = _.cloneDeep(this.selectedSpeakers$.value);
            speakers[index].disabled = false;
            this.selectedSpeakers$.next(speakers);
            this.editSpeaker = this.selectedSpeakers$.value[index];
        }
    }


    private async handleSearch(query: string) {
        if (query === '') {
            this.currentSpeakers$.next(this.allSpeakers$.value);
        }

        const filtered = this.allSpeakers$.value.filter(s => {
            return s.fullName.toLowerCase().includes(query.toLowerCase());
        })

        this.currentSpeakers$.next(filtered);
    }

    private showAddSpeakerFields() {
        this.showAddNewSpeakerFields = true;
    }

    private async createNewSpeaker() {
        this.loading = true;
        this.showAddNewSpeakerFields = false;
        const speaker = new EFProfile(uuidv4());
        speaker.firstName = this.newFirstName;
        speaker.lastName = this.newLastName;
        speaker.emailAddress = this.newEmail;
        speaker.profileType = new Profile().ProfileType().find(pt => pt.isPerson);

        const eventProfileType = new Profile().EventProfileType().find(pt => pt.isSpeaker);

        const createdProfile = await this.eventAgendaService.createProfile(speaker, this.eventFarmService.currentEvent.id, eventProfileType.slug);
        createdProfile.eventProfileSlug = eventProfileType.slug;
        await this.eventAgendaService.addProfileToEvent(createdProfile, this.sessionId);
        const speakers = await this.eventAgendaService.getProfiles(true, this.sessionId);
        this.activeSpeakers = speakers;

        await this.initiateAllSpeakers();
        this.defaultValues();
        this.loading = false;
    }

    public defaultValues() {
        this.newFirstName = null;
        this.newLastName = null;
        this.newEmail = null;
    }

    public newSpeakerFieldsAdded() {
        return !(this.newEmail && this.newFirstName && this.newLastName);
    }

    ngOnDestroy() {
    }
}
