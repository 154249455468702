import { Component, Input } from '@angular/core';
import { SalesforceEventIntegrationsService } from './eventIntegrations.service';
import { MappingService } from './mapping.service';

@Component({
    selector: 'field-mapping',
    template: require('./fieldMapping.html'),
    styles: [],
})

export class FieldMappingComponent {
    @Input() row;
    @Input() flow;
    @Input() efLeadFields;
    @Input() intLeadFields;

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private _ms: MappingService,
    ) {
    }

    // attributeSelect(val) {
    // 	this._ms.attributeSelect(val)
    // }
}
