import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { EventsRoutingModule } from './events.routing-module';
import { EventsComponent } from './events.component';

import { UtilitiesModule } from '../../Utilities/utilities.module';
import { EventResolver } from './event-resolver.service';
import { QuickEventAccess } from './EventAccess/QuickEventAccess/quick-event-access.component';
import { PaymentInfoComponent } from './EventAccess/QuickEventAccess/PaymentInfo/payment-info.component';
import { DistributionInfoComponent } from './EventAccess/QuickEventAccess/DistributionInfo/distribution-info.component';
import { LaunchCanvasComponent } from './../CoreUI/Modal/Modals/LaunchCanvas/LaunchCanvas.component';
import {NgxUtilitiesModule} from '../../NGXUtilities/ngx.utilities.module';

const mainModules: any[] = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilitiesModule
];

const ngxBootstrapModules: any[] = [
    NgxUtilitiesModule
];

const ngxOtherModules: any[] = [
    NgxChartsModule,
];

const eventsModules: any[] = [
    EventsRoutingModule,
];

@NgModule({
    imports: [
        mainModules.concat(ngxBootstrapModules, ngxOtherModules, eventsModules)

    ],
    declarations: [
        EventsComponent,
        QuickEventAccess,
        PaymentInfoComponent,
        DistributionInfoComponent,
        LaunchCanvasComponent
    ],
    entryComponents: [
        QuickEventAccess,
        LaunchCanvasComponent
    ],
    providers: [
        EventResolver
    ]
})
export class EventsModule {
}
