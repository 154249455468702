import { Component, ComponentFactoryResolver, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';

@Component({
    selector: 'address-book-user-basic-detail-display',
    styles: [require('./address-book-user-basic-detail-display.scss')],
    template: require('./address-book-user-basic-detail-display.html'),
})

export class AddressBookUserBasicDetailDisplayComponent {
    constructor(
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
    ) {
    };

    readonly userPersonBlueSVG = require('../../assets/circle-user-person-blue');
}
