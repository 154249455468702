import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AddressBookComponent } from './addressBook.component';
import { AddressBookListComponent } from './addressBookList.component';
import { AddressBookAddGroupModalWrapperComponent } from './Group/Add/addressBookAddGroupModalWrapper.component';
import { AddressBookAddUserModalWrapperComponent } from './User/Add/addressBookAddUserModalWrapper.component';
import { ConfirmAddUserDeactivateGuard } from './addressBookAddUserGuard.service';
import { ConfirmEditUserDeactivateGuard } from './addressBookEditUserGuard.service';
import { AddressBookEditUserModalWrapperComponent } from './User/Edit/addressBookEditUserModalWrapper.component';
import { AddressBookEditGroupModalWrapperComponent } from './Group/Edit/addressBookEditGroupModalWrapper.component';
const routes: Routes = [

    {
        path: '',
        component: AddressBookComponent,
        children: [
            {
                path: 'list',
                component: AddressBookListComponent,
                children: [
                    {
                        path: 'add-group',
                        component: AddressBookAddGroupModalWrapperComponent,
                        outlet: 'add-group-outlet',
                    },
                    {
                        path: 'edit-group/:id',
                        component: AddressBookEditGroupModalWrapperComponent,
                        outlet: 'edit-group-outlet',
                    },
                    {
                        path: 'add-user',
                        component: AddressBookAddUserModalWrapperComponent,
                        canDeactivate: [ConfirmAddUserDeactivateGuard],
                        outlet: 'add-user-outlet',
                    },
                    {
                        path: 'edit-user/:id',
                        component: AddressBookEditUserModalWrapperComponent,
                        canDeactivate: [ConfirmEditUserDeactivateGuard],
                        outlet: 'edit-user-outlet',
                    },
                ]
            },
            {
                path: '**',
                redirectTo: 'list',
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class AddressBookRoutingModule {
}