import { AbstractModel } from '../../../../ApiClient/Models/abstract-model';
import { EFHelper } from '../../../../Shared/helper';

export interface IDomainInfo {
    domain?: string;
}

export class DomainMask extends AbstractModel {
    private _mask: IDomainInfo;
    private _senderAddress?: string;

    constructor(id: string) {
        super(id);
        this._mask = {};
    }

    public static fromApi(data) {
        let domainMask = new this(data.id);
        if(data.attributes) {
            domainMask._mask.domain = data.attributes.domain;
        }
        return domainMask;
    }

    get id() {
        if (EFHelper.isEmpty(this._id)) {
            return null;
        }

        return this._id;
    }

    /**
     * @param {string|null} value
     */
    set id(value) {
        this._id = value;
    }

    get fullDomainMaskEmail() {
        if (EFHelper.isEmpty(this._senderAddress) || EFHelper.isEmpty(this._mask.domain)) {
            return null;
        }
        
        const email = this._senderAddress + '@' + this._mask.domain;

        return email.replace(/\s/g,'');
    }

    get mask(): IDomainInfo {
        return this._mask;
    }

    set mask(value: IDomainInfo) {
        this._mask = value;
    }

    get senderAddress(): string {
        return this._senderAddress;
    }

    set senderAddress(value: string) {
        this._senderAddress = value;
    }
}
