import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import { UploaderService } from '../uploader.service';
import { ChooseFileService } from './chooseFile.service';
import { UploaderTabsService } from '../uploaderTabs.service';
import { Group } from '../../../../ApiClient/Models/Group/group';
import { EventFarmService } from '../../../eventFarm.service';

@Component({
    selector: 'choose-file-container',
    template: require('./choose-file-container.html'),
    styles: [require('./choose-file-container.scss')],
    encapsulation: ViewEncapsulation.None
})

export class ChooseFileContainerComponent implements OnInit, OnDestroy {
    @Input() hideBorder?: boolean = false;
    @Input() eventContext?: boolean = false;

    @Input() hideCancelUploadBtn?: boolean = false;
    @Input() borderRadius?: boolean = false;
    @Input() buttonBorderRadius?: boolean = false;

    @Input() textAlignment?: 'left' | 'center' = 'center';
    @Input() backgroundColor?: '#fafafa';
    @Input() removePadding?: boolean;

    @Input() layout?: 'row' | 'column' = 'column';
    @Input() groupNameFieldLabel?: string = 'Group Name';
    @Input() groupNameFieldPlaceholder?: string = 'Search By Group Name...';
    @Input() fileFieldLabel?: string = 'File to Upload:';
    @Input() fileFieldPlaceholder?: string = 'Choose a file';
    @Input() disableUploadIcon?: boolean = false;
    @Input() sectionHeader?: string = 'Upload Your List as a Group:';
    @Input() sectionPrompt?: string = 'Choose a file from your computer containing the guests you want to upload.';
    @Input() hideUploadInfoSectionHeader?: boolean = false;
    @Input() hideUploadInfoTemplateLinks?: boolean = false;
    @Input() hideFileExtensions?: boolean = false;
    @Input() uploadInfoSectionContent?: string = 'We will give you a preview of how the system interprets your data before you complete your upload.';


    @Input() onChooseFileSuccess: () => void;
    @Input() onPreviewSuccess: (userImportId: string) => void;
    @Input() onPreProcessSuccess: (userImportId: string) => void;

    readonly uploadIcon = require('../assets/upload-icon');
    readonly fakePathString = 'C:\\fakepath\\';

    private selectedFile: File;
    private groupPlaceholder: string;
    private fileToUpload: string = 'fileToUpload';
    private uploadFile: string = 'uploadFile';

    constructor(
        private uploaderService: UploaderService,
        private eventFarmService: EventFarmService,
        private uploaderTabsService: UploaderTabsService,
        private chooseFileService: ChooseFileService,
    ) {

    }


    ngOnInit() {
        this.uploaderTabsService.reset();
        this.uploaderService.group = null;
        this.uploaderService.newGroupName = null;
        if(this.eventContext) { this.uploaderService.isEventContext = true; }
    }

    selectFile($event) {
        let fileList: FileList = $event.target['files'];
        if (fileList && fileList.length > 0) {
            this.selectedFile = fileList[0];
        }
    }

    triggerFileInput(file: Element) {
        const currentFile: any = file;
        currentFile.click();
    }

    upload() {
        this.chooseFileService.upload(
            this.selectedFile,
            this.onChooseFileSuccess,
            this.onPreProcessSuccess
        );
    }

    shouldDisableUpload(): boolean {
        if (!this.selectedFile) {
            return true;
        }

        return false;
    }

    private selectGroup(group: Group): void {
        this.uploaderService.group = group;
    }

    private typedNewGroupName(value): void {
        this.uploaderService.newGroupName = value;
    }

    ngOnDestroy() {
    }
}
