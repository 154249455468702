import { Injectable } from '@angular/core';
import {CioGetStartedStepperComponent} from './cio-get-started-stepper.component';
import {ModalService} from '../../../modal.service';
import { Modal } from '../../../../Modal/modal.class';
import {EventFarmService} from '../../../../../eventFarm.service';
@Injectable()
export class CioGetStartedStepperService {
    constructor(
        private modalService: ModalService,
        private eventFarmService: EventFarmService
    ) {
    }

    public launchCioOnlyModal() {
        const modal: Modal = new Modal();
        modal.logoSrc = 'https://cdn.eventfarm.com/platform/img/modals/cio/EventFarm_logo_gray-stacked.png';
        modal.logoAlt = 'Event Farm and Clear logos';
        modal.background = '#FCFCFC';
        modal.position = 'relative';
        modal.title = `Step 1: Upload Guests to your Event: <span>${this.eventFarmService.currentEvent.name}</span>`;
        modal.component = CioGetStartedStepperComponent;
        modal.inputs = [
            {input: 'whatsNextContent', val: `
            <p>Download the Event Farm Check-In app (
            <a target="_blank" href="https://apps.apple.com/us/app/event-check-in/id586714344">iOS</a> or
            <a href="https://play.google.com/store/apps/details?id=event.farm.app" target="_blank">Android</a>).
            Update your guests with event reminders and include any details about entry requirements.</p>
            <a target="_blank" href="https://support.eventfarm.com/hc/en-us/articles/360036376971-Check-In-Only-FAQs">Check-In Only FAQs</a>
`}

        ];
        modal.cssClass = 'cio';
        this.modalService.changeModal(modal);
        this.modalService.showModal();
    }

    public launchCioPlusClearModal() {
        const modal: Modal = new Modal();
        modal.logoSrc = 'https://cdn.eventfarm.com/platform/img/modals/cio/ef_and_clear_logo.png';
        modal.logoAlt = 'Event Farm and Clear logos';
        modal.background = '#FCFCFC';
        modal.position = 'relative';
        modal.title = `Step 1: Upload Guests to your Event: <span>${this.eventFarmService.currentEvent.name}</span>`;
        modal.component = CioGetStartedStepperComponent;
        modal.inputs = [
            {
                input: 'whatsNextContent', val: `
            <p>Inform your guests about the Clear App and the Check-In process at the event. Download our guide here.</p>
            <a target='_blank' href="https://support.eventfarm.com/hc/en-us/articles/4411811598733-PDF-Event-Farm-Check-in-CLEAR-Health-Pass-User-Deck">Download Guide Here</a>`
            }
        ];
        modal.cssClass = 'cio';
        this.modalService.changeModal(modal);
        this.modalService.showModal();
    }
}
