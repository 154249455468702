import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {User} from '../../../../ApiClient/Models/User/user';

@Injectable()
export class ManageAdminService {
    public loading;
    public listOfExistingAdmins$: BehaviorSubject<{ value: string; text: string }[]> = new BehaviorSubject([{
        value: '',
        text: ''
    }])
    public count = 0;

    constructor(
        private apiClient: EventFarmAPIClient

    ) { }

     public async fetchExistingAdmins() {
       const val = await  this.apiClient.getUseCaseFactory().User().ListAdmins().toPromise()
       if (val?.data) {
                const users = val.data.map(d => {
                    const u = User.fromApiResponse(d)
                    return {
                        value: u.id,
                        text: u.primaryEmail
                    };
                }).sort((a, b) => {
                    const textA = a.text.toUpperCase(); // ignore upper and lowercase
                    const textB = b.text.toUpperCase(); // ignore upper and lowercase
                    if (textA < textB) {
                        return -1;
                    }
                    if (textA > textB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                this.listOfExistingAdmins$.next(users);
            }
    }

    public searchForUser = (name: string): Observable<any> =>
        this.apiClient.getUseCaseFactory().User().GetUserByEmail(name)
            .pipe(
                catchError(() => of({results: []})),
                map((res: any) => res.data)
            )
            .pipe(map((data: any) => data ? User.fromApiResponse(data) : null));


    public downgradeAdmin(userId: string) {

        return this.apiClient.getUseCaseFactory().User().RemoveAdminUserAccess(userId)
    }

    public upgradeUser(userId: string) {
        return this.apiClient.getUseCaseFactory().User().AddAdminUserAccess(userId)
    }

}
