import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NzDrawerService} from "ng-zorro-antd/drawer";
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { Store } from '@ngrx/store';
import {EventFarmService} from '../../../eventFarm.service';
import * as fromRoot from '../../../store';
import {EditStationComponent} from "../../EFX/Station/StationList/EditStation/edit-station.component";
import {SMSService} from '../sms.service';
import {SMSCreateComposeComponent} from './Compose/sms-create-compose.component';
import {SMSCreatePreviewComponent} from "./Preview/sms-create-preview.component";
import {SMSCreateRecipientsComponent} from './Recipients/sms-create-recipients.component';
import {SMSCreateService} from './sms-create.service';

@Component({
    selector: 'sms-create',
    template: require('./sms-create.html'),
    styles: [require('./sms-create.scss')]
})

export class SMSCreateComponent implements OnInit, OnDestroy {
    @ViewChild(SMSCreateComposeComponent) smsCreateComposeComponent: SMSCreateComposeComponent;
    @ViewChild(SMSCreateRecipientsComponent) smsCreateRecipientsComponent: SMSCreateRecipientsComponent;
    private $recipientFormSub: any;
    public drawerTitle: string = `SMS Preview`;
    public width = '25%';
    constructor(
        private iconService: IconService,
        private smsService: SMSService,
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private smsCreateService: SMSCreateService,
        private drawService: NzDrawerService

    ) {}

    ngOnDestroy(): void {
    }

    ngOnInit(): void {

    }
    ngAfterViewInit() {

    }

    get isOnCompose(): boolean{
        return this.current === 0;
    }
    get isOnRecipients(): boolean {
        return this.current === 1;
    }

    get isOnReview(): boolean {
        return this.current === 2;
    }

    get isOnConfirmation(): boolean {
        return this.current === 3;
    }

    isNextDisabled = true;

    //We need there to be content in the message in order to send preview
    get isPreviewDisabled(): boolean {
        return this.isOnCompose && this.isNextDisabled;
    }

    current = 0;

    reset() {
        this.current = 0;
    }
    pre(): void {
        this.current -= 1;
    }

    next(): void {
        this.current += 1;
    }

   async done(): Promise<void> {
       await this.smsCreateService.sendSMS();
       this.smsCreateService.sent$.subscribe(val =>{
           if(val) {
               // Move to confirmation
               this.current = 3;
           }
       })

    }

    handleComposeFormValidityChange(isValid: boolean) {
        if (this.current === 0) {
            this.isNextDisabled = !isValid;
        }

    }
    handleRecipientFormValidityChange(isValid: boolean) {
        if (this.current === 1) {
            this.isNextDisabled = !isValid;
        }
    }

    openComponent(): void {
        const drawerRef = this.drawService.create<SMSCreatePreviewComponent, { }, string>({
            nzTitle: this.drawerTitle,
            nzContent: SMSCreatePreviewComponent,
            nzWidth: this.width,
            nzContentParams: {
            }
        });
    }

    getViewName() {
        switch (this.current) {
            case 0:
                return 'compose';
            case 1:
                return 'recipients';
            case 2:
                return 'review';
            case 3:
                return 'confirmation';
        }
        return undefined;
    }
}
