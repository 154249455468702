import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { EventAgendaService } from './event-agenda.service';

@Component({
    selector: 'event-agenda',
    template: require('./event-agenda.html'),
    styles: [require('./event-agenda.scss')],
})

export class EventAgendaComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventAgendaService: EventAgendaService
    ) {
    }

    async ngOnInit() {}

    ngOnDestroy() {
        this.eventAgendaService.clearList();
    }
}
