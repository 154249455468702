import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageRoutingModule } from './../../manage.routing-module';
import { AdminService } from '../../../admin.service';
import { EventFarmService } from '../../../../eventFarm.service';

import { NzUtilitiesModule } from '../../../../../NZUtilities/nz.utilities.module';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService} from 'ng-zorro-antd/message';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { AlertService } from '../../../../eventFarmAlert.service';
import { LoaderGrayModule } from '../../../../CoreUI/LoaderGray/loader-gray.module';
import { TeamEchoWorldsService } from './team-echo-worlds.service';
import { TeamEchoWorldsComponent } from './team-echo-worlds.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import {NgxUtilitiesModule} from '../../../../../NGXUtilities/ngx.utilities.module';

const forExport = [TeamEchoWorldsComponent];

@NgModule({
  declarations: forExport,
  exports: forExport,
  imports: [
    ManageRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzUtilitiesModule,
    LoaderGrayModule,
    AutocompleteLibModule,
    NzAutocompleteModule,
    NgxUtilitiesModule
  ],

  providers: [
    EventFarmService,
    NzDrawerService,
    AlertService,
    AdminService,
    NzMessageService,
    TeamEchoWorldsService,
  ],
})
export class TeamEchoWorldsModule {}
