import { Observable } from 'rxjs';
import { withLatestFrom, switchMap, tap, filter, take, shareReplay } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import { Router } from '@angular/router';
import { RouteGeneratorService } from '../../_services/routes/route-generator.service';
import { EventFarmService } from '../eventFarm.service';
import * as _ from 'lodash';

@Component({
    template: `
        <router-outlet></router-outlet>
    `
})
export class PoolsComponent {
    constructor(
        private store: Store<fromStore.AppState>,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService
    ) {}

    navigateToTeam(team, route) {
        if (!route.state.params.event) {
            this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', {poolId: team.data.id}));
            return;
        }
        return;
    }

    ngOnInit() {
        this.store.select('team').pipe(
            withLatestFrom(this.store.select('router')),
            filter(([team, router]) => !!_.get(team,'data.id') && !!_.get(router, 'state.params')),
            take(1),
            tap(([team, router]) => {
                if (!router.state.params.pool || router.state.params.pool !== team.data.id) {
                    this.navigateToTeam(team, router);
                }
            })
        ).subscribe();
    }
}
