import { Component, Input, Output, OnInit, ElementRef, EventEmitter, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'dropdown-select',
    template: require('./dropdown-select.html'),
    styles: [require('./dropdown-select.scss')],
    host: { '(document:click)': 'toggleDropdown($event)' }
})

export class DropdownSelectComponent {
    @Input() dropdownConfig;
    @Input() cssClass;
    @Output() selectionReceived: EventEmitter<any> = new EventEmitter();
    private isDropdownVisible = false;

    constructor(
        private el: ElementRef,
    ) {}

    toggleDropdown(event) {
        if (this.isDropdownVisible !== true) {
            return;
        }
        this.isDropdownVisible = this.el.nativeElement.contains(event.target);
    }

    makeDropdownVisible(event) {
        this.isDropdownVisible = true;
        event.stopPropagation();
    }

    selectOption(option) {
        this.isDropdownVisible = false;
        this.selectionReceived.emit(option);
    }

}
