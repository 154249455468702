import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Invitation} from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import * as _ from "lodash";
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {Subscription} from 'rxjs';
import {ValidateMustBeCheckedGroup} from '../../../../../Shared/Validators/validateMustBeCheckedGroup';
import {mergeByValue} from '../../../../../Utilities/mergeByValue';
import {GuestListViewService} from '../../guest-list-view.service';
import {GuestListFiltersService} from '../guest-list-filters.service';

@Component({
    selector: 'guest-list-filters-add-filter',
    template: require('./guest-list-filters-add-filter.html'),
    styles: [require('./guest-list-filters-add-filter.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestListFiltersAddFilterComponent implements OnInit {
    private filtersFormSub$: Subscription;

    public searchForm;
    filtersForm: any;
    tagPlaceHolder: any;
    invitationStatusOptions: any = [];
    invitationAccessTypeOptions: any = [];
    stackIds: any = [];

    constructor(
        private drawerRef: NzDrawerRef,
        private fb: FormBuilder,
        private guestListViewService: GuestListViewService,
        private guestListSearchAndFilterService: GuestListFiltersService
    ) {
    }


    close(): void {
        this.drawerRef.close();
    }

    ngOnInit() {
        this.invitationStatusOptions = this.generateInvitationStatusOptions()
        this.invitationAccessTypeOptions = this.generateInvitationAccessTypeOptions()
        this.filtersForm = this.fb.group({
            invitationStatusOptions: [this.invitationStatusOptions],
            invitationAccessTypeOptions: [this.invitationAccessTypeOptions],
        });
    }

    private generateInvitationStatusOptions() {
        let options = new Invitation().InvitationStatusType().map(opt => {
            return {
                label: opt.name,
                value: opt.slug
            };
        });

        // Merge initial set with actively selected filters
        options = mergeByValue([...options, ...this.guestListSearchAndFilterService.filterSelection])

        // Sort
        options = _.sortBy(options, ['label']);

        options = options.filter(opt => opt.value !== 'recycled')
        return options;

    }

    private generateInvitationAccessTypeOptions() {
        let options =  this.guestListSearchAndFilterService.formatTicketTypesAndStackIdsForFilter();

        // Merge initial set with actively selected filters
        options = mergeByValue([...options, ...this.guestListSearchAndFilterService.stackSelection])
        return options;
    }

    async applyFilters() {
        this.close();
        // Reset quick filter
        this.guestListSearchAndFilterService.resetQuickFilter();

        // Get checked invitation status options
        const checked = this.filtersForm.get('invitationStatusOptions').value.filter(v => v.checked);

        // stack ids
        const checkedStacks = this.filtersForm.get('invitationAccessTypeOptions').value.filter(v => v.checked);

        await this.guestListSearchAndFilterService.handleSelectedFiltersChange(checked, checkedStacks);
    }

}
