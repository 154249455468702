import { Component, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { EventFarmService } from '../eventFarm.service';
import { LegacyUIService } from './legacy-UI.service';

@Component({
    selector: 'legacy-ui-root-controls',
    template: require('./legacy-ui-root-controls.html'),
    encapsulation: ViewEncapsulation.None
})

export class LegacyUIRootControlsComponent {
    constructor(
        private eventFarmService: EventFarmService,
        private legacyUIService: LegacyUIService,
        private el: ElementRef,
    ) {
    }
    private legacyTimeoutRunning: boolean;
    @ViewChild('legacyModalControl', {static: false}) legacyModalControl;

    ngOnInit() {
        this.legacyTimeoutRunning = false;
    }

    refreshEventLinkForLegacyUI() {
        if (this.eventFarmService.currentEvent.id && !this.legacyTimeoutRunning) {
            this.eventFarmService.updateEventDetails(this.eventFarmService.currentEvent.id);
        }
        this.startLegacyClickActionLimiterTimeout();
    }

    openLegacyUIUrlInModal() {
        let legacyTimeout = this.legacyTimeoutRunning;
        setTimeout(() => {
            const hiddenButtonElem = this.el.nativeElement.querySelector('#open-url-in-modal');
            const modalElem = this.el.nativeElement.querySelector('.ef-modal');
            const url = hiddenButtonElem.getAttribute('data-url');
            const isIframe = hiddenButtonElem.getAttribute('data-iframe');
            let refreshModal = false;
            if (url && url !== 'x' && !legacyTimeout) {
                this.legacyUIService.openCoreUrlInModal(url, null, isIframe, refreshModal);
            }
        });
        this.startLegacyClickActionLimiterTimeout();
    }

    startLegacyClickActionLimiterTimeout(timeout: number = 1500) {
        this.legacyTimeoutRunning = true;
        setTimeout(() => {
            this.legacyTimeoutRunning = false;
        }, timeout);
    }
}
