import {Action} from '@ngrx/store';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';
import {
    FETCH_FEATURES_FOR_ADMIN_TEAM,
    FETCH_FEATURES_FOR_ADMIN_TEAM_FAIL,
    FETCH_FEATURES_FOR_ADMIN_TEAM_SUCCESS
} from './admin-feature.action';
import {PoolWorld} from '../../../../ApiClient/Models/PoolWorld/pool-world';

export const FETCH_VIRBELA_WORLDS_FOR_ADMIN = '[Admin/Team] Fetch Virbela Worlds For Admin';
export const FETCH_VIRBELA_WORLDS_FOR_ADMIN_SUCCESS = '[Admin/Team] Fetch Virbela Worlds For Admin Success ';
export const FETCH_VIRBELA_WORLDS_FOR_ADMIN_FAIL = '[Admin/Team] Fetch Virbela Worlds For Admin Fail';

export const FETCH_POOL_WORLDS_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Pool Worlds For Admin Team';
export const FETCH_POOL_WORLDS_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Pool Worlds For Admin Team Success ';
export const FETCH_POOL_WORLDS_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Pool Worlds For Admin Team Fail';

export const ADD_POOL_WORLD_TO_ADMIN_TEAM = '[Admin/Team] Add Pool Worlds to Admin Team';
export const ADD_POOL_WORLD_TO_ADMIN_TEAM_SUCCESS = '[Admin/Team] Add Pool Worlds to Admin Team Success ';
export const ADD_POOL_WORLD_TO_ADMIN_TEAM_FAIL = '[Admin/Team] Add Pool Worlds to Admin Team Fail';

export const REMOVE_POOL_WORLD_FROM_ADMIN_TEAM = '[Admin/Team] Remove Pool World From Admin Team';
export const REMOVE_POOL_WORLD_FROM_ADMIN_TEAM_SUCCESS = '[Admin/Team] Remove Pool World From Admin Team Success ';
export const REMOVE_POOL_WORLD_FROM_ADMIN_TEAM_FAIL = '[Admin/Team] Remove Pool World From Admin Team Fail';

export class FetchVirbelaWorldsForAdmin implements Action {
    readonly type = FETCH_VIRBELA_WORLDS_FOR_ADMIN;
    constructor() { }
}

export class FetchVirbelaWorldsForAdminSuccess implements Action {
    readonly type = FETCH_VIRBELA_WORLDS_FOR_ADMIN_SUCCESS;
    constructor(public payload: PoolWorld[]) { }
}

export class FetchVirbelaWorldsForAdminFail implements Action {
    readonly type = FETCH_VIRBELA_WORLDS_FOR_ADMIN_FAIL;
    constructor(public payload: string) { }
}

export class FetchPoolWorldsForAdminTeam implements Action {
    readonly type = FETCH_POOL_WORLDS_FOR_ADMIN_TEAM;
    constructor() { }
}

export class FetchPoolWorldsForAdminTeamSuccess implements Action {
    readonly type = FETCH_POOL_WORLDS_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolWorld[]) { }
}

export class FetchPoolWorldsForAdminTeamFail implements Action {
    readonly type = FETCH_POOL_WORLDS_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class AddPoolWorldToAdminTeam implements Action {
    readonly type = ADD_POOL_WORLD_TO_ADMIN_TEAM;
    constructor(public payload: {teamId: string, virbelaWorldId: string}) { }
}

export class AddPoolWorldToAdminTeamSuccess implements Action {
    readonly type = ADD_POOL_WORLD_TO_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolWorld) { }
}

export class AddPoolWorldToAdminTeamFail implements Action {
    readonly type = ADD_POOL_WORLD_TO_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class RemovePoolWorldFromAdminTeam implements Action {
    readonly type = REMOVE_POOL_WORLD_FROM_ADMIN_TEAM;
    constructor(public payload: {poolWorldId:string}) { }
}

export class RemovePoolWorldFromAdminTeamSuccess implements Action {
    readonly type = REMOVE_POOL_WORLD_FROM_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolWorld) { }
}

export class RemovePoolWorldFromAdminTeamFail implements Action {
    readonly type = REMOVE_POOL_WORLD_FROM_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export type AdminPoolWorldAction =
    FetchVirbelaWorldsForAdmin |
    FetchVirbelaWorldsForAdminSuccess |
    FetchVirbelaWorldsForAdminFail |
    FetchPoolWorldsForAdminTeam |
    FetchPoolWorldsForAdminTeamSuccess |
    FetchPoolWorldsForAdminTeamFail |
    AddPoolWorldToAdminTeam |
    AddPoolWorldToAdminTeamSuccess |
    AddPoolWorldToAdminTeamFail |
    RemovePoolWorldFromAdminTeam |
    RemovePoolWorldFromAdminTeamSuccess |
    RemovePoolWorldFromAdminTeamFail;
