import { BehaviorSubject ,  Subject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { User } from '../../../ApiClient/Models/User/user';
import { Tag } from '../../../ApiClient/Models/Event/tag';
import { EventFarmService } from '../../eventFarm.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { TicketBlock } from '../../../ApiClient/Models/Stack/ticket-block';
import * as moment from 'moment';
import { SessionStorageService } from '../../../_services/storage/session-storage.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';

@Injectable()
export class EventsListService {
    public readonly DEFAULT_FILTER_TYPE = 'current-future';

    public eventsSource = new BehaviorSubject<EfEvent[]>([]);
    public ticketBlocksSource = new BehaviorSubject<TicketBlock[]>([]);
    public eventsMetaSource = new BehaviorSubject<any>([]);
    public ticketBlocksMetaSource = new BehaviorSubject<any>([]);
    public currentTagFiltersSource = new BehaviorSubject<any>([]);
    public tagsForPool: Observable<any[]>;

    public events$ = this.eventsSource.asObservable();
    public ticketBlocks$ = this.ticketBlocksSource.asObservable();
    public eventsMeta$ = this.eventsMetaSource.asObservable();
    public ticketBlocksMeta$ = this.ticketBlocksMetaSource.asObservable();
    public currentTagFilters$ = this.currentTagFiltersSource.asObservable();

    public eventListOptions = this.eventFarmService.eventListOptions;

    get currentPoolId() {
        let id;
        this.store.select(fromRoot.getPoolParam).subscribe(res => id = res);
        return id;
    }

    constructor(
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private routeGenerator: RouteGeneratorService,
        private store: Store<fromRoot.AppState>
    ) {
    }

    listEventsForUserByPool(loadingMessage = 'Loading Events...') {
        if (!this.eventListOptions || !this.eventListOptions.teamId) {
            return;
        }

        this.eventFarmService.listAllEventsForUserByPool(this.eventFarmService.currentUser.id, this.eventListOptions.teamId)
            .subscribe((events) => {
                this.eventsSource.next(events);
            });
    }

    refreshEvents() {
        this.store.dispatch(new fromRoot.FetchEventsForTeam());
    }

    listTicketBlocksForUser(userId: string) {
        this.listAllTicketBlocksForUser(userId)
            .subscribe((res) => {
                const ticketBlocksFromApi = res;
                const ticketBlocks = [];
                this.ticketBlocksMetaSource.next(ticketBlocksFromApi.meta);
                ticketBlocksFromApi.data.forEach((ticketBlock) => {
                    ticketBlocks.push(TicketBlock.fromApiResponse(ticketBlock));
                });
                this.ticketBlocksSource.next(ticketBlocks);
            });
    }

    public listAllTicketBlocksForUser(userId: string) {
        return this.apiClient.getUseCaseFactory().TicketBlock().ListTicketBlocksForUser(userId,
            '',
            ['Event'],
            this.eventListOptions.pagination.currentTicketBlocksPage,
            this.eventListOptions.pagination.resultsPerPage,
            'event-start',
            'ascending',
            'current-future',
            this.eventFarmService.currentTeam.id
        );
    }

    public getFeaturesForPool(currentTeamId:string) {
        return this.apiClient.getUseCaseFactory().PoolFeature().ListFeaturesForPool(currentTeamId);
    }

    public listFeaturesForPool() {
        return this.store.select(fromRoot.getPoolFeatures);
    }

    public getTagsForPool(poolId: string) {
        return this.apiClient.getUseCaseFactory().Pool().ListUniqueTagNamesForPool(poolId);
    }

    public goToEvent(event): void {
        const eventId = event.id;
        // todo: remove various component reliance on localstorage
        this.sessionStorageService.set('event', {
            id: event.id,
            name: event.name || '',
        });
        this.sessionStorageService.set('pool', {
            id: event.poolId,
            attributes: {
                name: event.poolName || '',
            },
        });

        this.currentTagFiltersSource.next([]);

        this.router.navigateByUrl(this.routeGenerator.url('events.info', { poolId: this.currentPoolId, eventId: eventId }));
    }

    public goToEventGuestlist(event): void {
        const eventId = event.id;
        // todo: remove various component reliance on localstorage
        this.sessionStorageService.set('event', {
            id: event.id,
            name: event.name || '',
        });
        this.sessionStorageService.set('pool', {
            id: event.team && event.team.id ? event.team.id : '',
            attributes: {
                name: event.team && event.team.name ? event.team.name : '',
            },
        });
        this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId: this.currentPoolId, eventId: eventId }));
    }

    public goToTicketBlock(ticketBlock): void {
        const ticketBlockId = ticketBlock.id;
        const eventId = ticketBlock.event.id;
        const tbDashboardBaseUrl = this.routeGenerator.url('ticketblocks.list', { eventId });
        this.router.navigateByUrl(tbDashboardBaseUrl + '?ticketBlockId=' + ticketBlockId);
    }
}

export interface Pagination {
    currentEventsPage: number;
    currentTicketBlocksPage: number;
    resultsPerPage: number;
}

export interface EventListOptions {
    query?: string;
    attributesFilter?: any[];
    attributesExcludeFilter?: any[];
    withData?: any;
    lastModifiedTimestamp?;
    page?;
    itemsPerPage?;
    sortBy?: string;
    sortDirection?: string;
    eventDateFilterType?;
    teamId?: string;
    pagination: Pagination;
    tags?;
    isListView: boolean;
}
