import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import { EventFarmService } from '../../../eventFarm.service';
import {EfModalService} from "../../../eventFarmModal.service";
import {InvitationOptionsService} from "../../InvitationOptions/invitationOptions.service";
import { GuestListViewService } from '../guest-list-view.service';

import { Modal } from '../../Modal/modal.class';
import { ModalService } from '../../Modal/modal.service';

import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';

import { AlertService } from '../../../eventFarmAlert.service';
import { LegacyUIService } from '../../../LegacyUI/legacy-UI.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { Observable ,  Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { SalesforceIntegration } from '../../../../ApiClient/Models/Integration/salesforce-integration';
import { IconService } from '../../Icons/icons.service';
import { EditInvitationComponent } from '../EditInvitation/edit-invitation.component';
import { EditInvitationService } from '../EditInvitation/edit-invitation.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { Stack } from '../../../../ApiClient/Models/Stack/stack';
import * as moment from 'moment-timezone';
import {GuestListBulkUpdateFormService} from "../GuestListBulkUpdateForm/guest-list-bulk-update-form.service";
import {GuestListFiltersService} from "../GuestListFilters/guest-list-filters.service";

@Component({
    selector: '[guest-list-invitation-list-item]',
    template: require('./guest-list-invitation-list-item.html'),
    styles: [require('./guest-list-invitation-list-item.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class GuestListInvitationListItemComponent implements OnInit, OnDestroy {
    @Input() guestApi;
    @Input() guestListPermissions;
    @Input() ticketTypes;

    private invitationStatusColor: object;
    private drawerRef;
    private reminderModalRef: NzModalRef<unknown>;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private isDisabled: boolean;
    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private guestListService: GuestListViewService,
        private legacyUIService: LegacyUIService,
        private message: NzMessageService,
        private modalService: ModalService,
        private alertService: AlertService,
        private iconService: IconService,
        private drawerService: NzDrawerService,
        private inviteEditService: EditInvitationService,
        private guestListFiltersService: GuestListFiltersService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService,
        private editInviteService: EditInvitationService,
        private invitationOptionsService: InvitationOptionsService,
        private modal: EfModalService

    ) {
        this.eventUpdate$ = this.eventStore$.subscribe(res => this.eventIntegration = res.salesforceIntegration);
    }

    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventIntegration: SalesforceIntegration;
    private guest: Invitation;
    private eventUpdate$: Subscription;
    private guestStatus: string = 'Unconfirmed';
    private toggleTooltipText = '';
    private isToggleTooltipVisible: boolean = false;
    private checkedInTooltipText: string;
    private unavailableInTicketBlockTooltipText = 'This feature is unavailable for ticket blocks.';
    ngOnInit() {
        this.guest = this.guestApi
        this.getStatusColor();
        this.initToggleTooltip();
        this.checkedInTooltipText = this.guest.isCheckedIn ? 'Check Guest Out' : 'Check Guest In';

        this.eventRoleType$.subscribe(val => {
            if (!this.guestListService.ticketBlock && !val['list-view']){
                this.isDisabled = true;
            }
        })
    }

    private  initToggleTooltip() {
        if (this.guestListService.ticketBlock) {
            this.isToggleTooltipVisible = true;
            this.toggleTooltipText = 'This feature is unavailable for ticket blocks.';
        }
    }

    displayHumanReadableAction(action) {
        let humanReadableAction = '';
        action = String(action).toLowerCase() || '';



        switch (action) {
            case 'clicked':
                humanReadableAction = 'Clicked';
                break;
            case 'received':
                humanReadableAction = 'Received';
                break;
            case 'processed':
                humanReadableAction = 'Sent';
                break;
            case 'spamreport':
                humanReadableAction = 'Reported As Spam';
                break;
            case 'bounce':
                humanReadableAction = 'Bounced';
                break;
            case 'deferred':
                humanReadableAction = 'Deferred';
                break;
            case 'dropped':
                humanReadableAction = 'Dropped';
                break;
            case 'open':
                humanReadableAction = 'Opened';
                break;
            case 'delivered':
                humanReadableAction = 'Delivered';
                break;
            case 'none':
                humanReadableAction = 'None';
                break;
            case 'unsubscribed':
                humanReadableAction = 'Unsubscribed';
                break;
            default:
                humanReadableAction = 'Unknown';
                break;
        }
        return humanReadableAction;
    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }



    private async toggleCheckIn() {
        await this.inviteEditService.changeCheckInStatus(this.guest.id, !this.guest.isCheckedIn);
        this.guest = await this.inviteEditService.fetchCurrentInvitation(this.guest.id);
        this.getStatusColor();
        await this.guestListService.assembleGuestListData();
    }



    private getStatusColor() {
        if (!this.guest) {
            return;
        }

        if (this.guest.isAffirmative) {
            this.guestStatus = 'Confirmed';
            return this.invitationStatusColor = { color: 'green' };
        } else if (this.guest.invitationStatusType.isDeclinedByRSVP) {
            this.guestStatus = 'Declined';
            return this.invitationStatusColor = { color: 'red' };
        } else if (this.guest.isLikeConfirmed) {
            this.guestStatus = 'Unconfirmed';
            return this.invitationStatusColor = { color: 'black' };
        }
    }






    private getTicketTypeNameForGuest(): string {

        let ticketTypeName = '';
        let expirationTime = ''

        if (!this.eventFarmService.currentEvent) {
            return '';
        }

        // //handling master ticket blocks
        if (this.guest && this.guest.ticketBlock ) {
            const s = this.eventFarmService.currentEvent.stacks.find(s => s.id === this.guest.stack.id);

            if (s !== undefined) {
                expirationTime = this.getExpirationString(s);
                ticketTypeName =  s.ticketType.name;
            }
        } else {
            ticketTypeName = this.guest.ticketType.name;
            expirationTime = this.getExpirationString(this.guest.stack);
        }

        if (expirationTime.length) {
            return `${ticketTypeName} - ${expirationTime}`
        }


        return ticketTypeName;
    }

    private getExpirationString(stack: Stack): string {

        // since if the expiration start and end time are defaulted to an empty object, we can use object.keys to see if those values have any fields

        if (Object.keys(stack.expirationStartTimeFormatted).length && !Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid From ${stack.expirationStartTimeFormatted.short}`;
        } else if (!Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            return `Valid Til ${stack.expirationEndTimeFormatted.short}`;
        } else if (Object.keys(stack.expirationStartTimeFormatted).length && Object.keys(stack.expirationEndTimeFormatted).length) {
            if (stack.expirationStartTimeFormatted.date.short === stack.expirationEndTimeFormatted.date.short) {
                return `${stack.expirationStartTimeFormatted.date.short} ${stack.expirationStartTimeFormatted.time.short} to ${stack.expirationEndTimeFormatted.time.short} ${moment().tz(stack.expirationEndTimeFormatted.timezone).zoneAbbr()}`;
            } else {
                return `${stack.expirationStartTimeFormatted.short} to ${stack.expirationEndTimeFormatted.short}`;
            }
        }

        return '';
    }

    get shouldHideInviteLink(): boolean {
        if (this.guestListService.isSessionTB) {
            return true;
        }

        const selectedStack = this.invitationOptionsService.selectedStack;
        if (selectedStack) {
            return (selectedStack.stackMethodType.isPublicPurchase || selectedStack.stackMethodType.isPublicRegistration);
        }

        return false;
    }
    public getTooltipText(scenario: 'check-in' | 'select'): string {
        let text;
        switch (scenario) {
            case 'check-in':
                text = this.guestListService.ticketBlock ? this.unavailableInTicketBlockTooltipText : this.checkedInTooltipText;
                break;
            case 'select':
                text = this.guestListService.ticketBlock ? this.unavailableInTicketBlockTooltipText : '';
                break;
        }
        return text;
    }

}
