import { Component } from '@angular/core';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { EventFarmService } from '../../eventFarm.service';
import { TicketBlockDashboardService } from './ticketBlockDashboard.service';

@Component({
    selector: 'ticket-block-allotment-controls',
    providers: [
        ModalService,
    ],
    template: require('./ticket-block-allotment-controls.html'),
    styles: [require('./ticket-block-allotment-controls.scss')],
})

export class TicketBlockAllotmentControlsComponent {

    constructor(
        private eventFarmService: EventFarmService,
        private ticketBlockDashboardService: TicketBlockDashboardService,
    ) {}
}
