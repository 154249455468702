import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventIntegrationsComponent } from './eventIntegrations.component';

const routes: Routes = [
    {
        path: '',
        component: EventIntegrationsComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EventIntegrationsRoutingModule {
}
