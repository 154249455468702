import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { Subscription } from 'rxjs';
import { AddressBookUserService } from '../addressBookUser.service';
import { AddressBookService } from '../../addressBook.service';
import { Group } from '../../../../../ApiClient/Models/Group/group';
import { AlertService } from '../../../../eventFarmAlert.service';
import { ApiError } from '../../../../../ApiClient/Models/error';

@Component({
    selector: 'address-book-bulk-add-users-to-group',
    styles: [require('./address-book-bulk-add-users-to-group.scss')],
    template: require('./address-book-bulk-add-users-to-group.html'),
})

export class AddressBookBulkAddUsersToGroup implements OnInit {
    constructor(
        private modalService: ModalService,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private alertService: AlertService,
    ) {
    };

    requesting: boolean = false;
    errors: ApiError[];
    private selectedGroup: Group;

    readonly groupPeopleBlueSVG = require('../../assets/circle-group-people-blue');

    ngOnInit() {
        this.selectedGroup = null;
        this.requesting = false;
    }

    private selectGroup(group: Group): void {
        if (group.name !== 'Added By Me') {
            this.selectedGroup = group;
        }
    }

    removeGroup(groupToRemove: Group) {
        this.selectedGroup = null;
    }

    submitAddUsersToGroup() {
        this.requesting = true;
        this.addressBookUserService.addUsersToGroup(this.selectedGroup.id, this.addressBookService.usersSelectedInList).toPromise().then((res) => {
            this.requesting = false;
            this.modalService.closeModal();
            this.alertService.addressBook().usersAddedToGroupSuccess()
                .then((res) => {
                    this.addressBookService.usersSelectedInList = [];
                }).catch((err) => {
                    this.requesting = false;
                    let formattedErrors: any[] = [];
                    if (err.error.errors.length) {
                        err.error.errors.forEach((error) => {
                            formattedErrors.push({
                                detail: error.detail
                            });
                        });
                    } else {
                        formattedErrors.push({
                            detail: 'Unable to add users to group',
                        });
                    }
                    this.errors = ApiError.fromApiErrorArray(formattedErrors);
            });
        });
    }

}
