import { AbstractModel } from '../abstract-model';
import { TicketType } from './ticket-type';
import { StackMethodTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Stack';
import { FormattedDateTime } from '../formatted-datetime';
import { VirbelaRoleTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Virbela';
import { EfEvent } from '../Event/event';

export class Stack extends AbstractModel {
    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data) {
        const stack = new this(data.id);
        if (data.meta.isInitialized) {
            stack._isTransferable = data.attributes.isTransferable;
            stack._isDeleted = data.attributes.isDeleted;
            stack._quantity = data.attributes.quantity;
            stack._price = data.attributes.price;
            stack._serviceFee = data.attributes.serviceFee;
            stack._openingAt = Number(data.attributes.openingAt);
            stack._closingAt = Number(data.attributes.closingAt);
            stack.openingAtFormatted = data.attributes.openingAtFormatted ? data.attributes.openingAtFormatted : {};
            stack.closingAtFormatted = data.attributes.closingAtFormatted ? data.attributes.closingAtFormatted : {};
            stack._maxQuantity = data.attributes.maxQuantity;
            stack._stackMethodType = data.attributes.stackMethodType;
            stack._inviteEmailId = data.attributes.inviteEmailId;
            stack._confirmEmailId = data.attributes.confirmEmailId;
            stack._declineEmailId = data.attributes.declineEmailId;
            stack._virbelaTeamId = data.attributes.virbelaTeamId;
            stack._virbelaRole = data.attributes.virbelaRole;

            stack._expirationStartTime = data.attributes.expirationStartTime ? Number(data.attributes.expirationStartTime) : null;
            stack._expirationEndTime = data.attributes.expirationEndTime ? Number(data.attributes.expirationEndTime) : null;

            stack._expirationStartTimeFormatted = data.attributes.expirationStartTimeFormatted ? data.attributes.expirationStartTimeFormatted : {};
            stack._expirationEndTimeFormatted = data.attributes.expirationEndTimeFormatted ? data.attributes.expirationEndTimeFormatted : {};

        }

        if (data.extraAttributes) {
            stack._availabilityCounts = data.extraAttributes.availabilityCounts;
        }

        if (data.relationships && data.relationships.ticketType) {
            stack._ticketType = TicketType.fromApiResponse(data.relationships.ticketType);
        }

        if (data.relationships && data.relationships.event) {
            stack._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        return stack;
    }

    private _ticketType?: TicketType;
    private _stackMethodType?: StackMethodTypeInterface;
    private _quantity?: number;
    private _price?: number;
    private _serviceFee?: number;
    private _openingAt?: number;
    private _closingAt?: number;
    openingAtFormatted?: FormattedDateTime;
    closingAtFormatted?: FormattedDateTime;
    private _maxQuantity?: number;
    private _isTransferable?: boolean;
    private _isDeleted?: boolean;
    private _availabilityCounts: any;
    private _inviteEmailId: string;
    private _confirmEmailId: string;
    private _declineEmailId: string;
    private _virbelaTeamId: number|null;
    private _virbelaRole: VirbelaRoleTypeInterface|null;
    private _event: EfEvent|null;

    private _expirationStartTime?: number|null;
    private _expirationEndTime?: number|null;
    private _expirationStartTimeFormatted?: FormattedDateTime;
    private _expirationEndTimeFormatted?: FormattedDateTime;

    get stackMethodType(): StackMethodTypeInterface {
        return this._stackMethodType;
    }

    get quantity(): number {
        return this._quantity;
    }

    get ticketType(): TicketType {
        return this._ticketType;
    }

    get event(): EfEvent|null {
        return this._event;
    }

    get availabilityCounts(): any {
        return this._availabilityCounts;
    }

    get price(): number {
        return this._price ? this._price : 0;
    }

    get serviceFee(): number {
        return this._serviceFee ? this._serviceFee : 0;
    }

    get openingAt(): number {
        return this._openingAt;
    }

    get closingAt(): number {
        return this._closingAt;
    }

    get virbelaTeamId(): number|null {
        return this._virbelaTeamId;
    }

    get virbelaRole(): VirbelaRoleTypeInterface|null {
        return this._virbelaRole;
    }

    get maxQuantity(): number {
        return this._maxQuantity;
    }
    get isTransferable(): boolean {
        return this._isTransferable;
    }
    get isDeleted(): boolean {
        return this._isDeleted;
    }
    get inviteEmailId(): string {
        return this._inviteEmailId;
    }
    get confirmEmailId(): string {
        return this._confirmEmailId;
    }
    get declineEmailId(): string {
        return this._declineEmailId;
    }
    get designKeys(): string[] {
        const type = this.stackMethodType;
        if (type.isPublicRegistration || type.isPublicPurchase) {
            return [
                null,
                'confirmEmailId',
                null,
            ];
        }
        if (type.isInviteToPurchase || type.isInviteToPurchaseFCFS || type.isInviteToRegister) {
            return [
                'inviteEmailId',
                'confirmEmailId',
                null
            ];
        }
        return [
            'inviteEmailId',
            'confirmEmailId',
            'declineEmailId'
        ];
    }

    set ticketType(value: TicketType) {
        this._ticketType = value;
    }

    set stackMethodType(value: StackMethodTypeInterface) {
        this._stackMethodType = value;
    }

    set quantity(value: number) {
        this._quantity = value;
    }

    set price(value: number) {
        this._price = value;
    }
    
    set serviceFee(value: number) {
        this._serviceFee = value;
    }

    set openingAt(value: number) {
        this._openingAt = value;
    }

    set closingAt(value: number) {
        this._closingAt = value;
    }

    set maxQuantity(value: number) {
        this._maxQuantity = value;
    }

    set isTransferable(value: boolean) {
        this._isTransferable = value;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    set availabilityCounts(value: any) {
        this._availabilityCounts = value;
    }

    set inviteEmailId(value: string) {
        this._inviteEmailId = value;
    }
    set confirmEmailId(value: string) {
        this._confirmEmailId = value;
    }
    set declineEmailId(value: string) {
        this._declineEmailId = value;
    }
    set virbelaTeamId(value: number|null) {
        this._virbelaTeamId = value;
    }
    set virbelaRole(value: VirbelaRoleTypeInterface|null) {
        this._virbelaRole = value;
    }

    public get expirationStartTime(): number|null {
        return this._expirationStartTime;
    }
    public set expirationStartTime(value: number|null) {
        this._expirationStartTime = value;
    }

    public get expirationEndTime(): number|null {
        return this._expirationEndTime;
    }
    public set expirationEndTime(value: number|null) {
        this._expirationEndTime = value;
    }

    public get expirationStartTimeFormatted(): FormattedDateTime {
        return this._expirationStartTimeFormatted;
    }
    public set expirationStartTimeFormatted(value: FormattedDateTime) {
        this._expirationStartTimeFormatted = value;
    }

    public get expirationEndTimeFormatted(): FormattedDateTime {
        return this._expirationEndTimeFormatted;
    }
    public set expirationEndTimeFormatted(value: FormattedDateTime) {
        this._expirationEndTimeFormatted = value;
    }

}
