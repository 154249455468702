import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as adminActions from '../../actions/admin';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {WaitListService} from '../../../EventsModule/WaitList/waitList.service';
import {Store} from '@ngrx/store';
import * as fromStore from '../../../store';
import * as fromRoot from '../../../store';

import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {TeamAllotmentService} from '../../../AdminModule/Manage/ManageTeam/TeamAllotments/team-allotment.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {TeamPointOfContactService} from '../../../AdminModule/Manage/ManageTeam/TeamPointOfContact/team-point-of-contact.service';
import * as adminFeatureActions from '../../actions/admin/admin-feature.action';
import {PoolFeature} from '../../../../ApiClient/Models/PoolFeature/poolFeature';
import {TeamContractDetailsService} from '../../../AdminModule/Manage/ManageTeam/TeamContractDetails/team-contract-details.service';
import {Contract} from '../../../../ApiClient/Models/Contract/contract';
import * as userActions from '../../actions/user.action';
import moment = require('moment');

@Injectable()
export class AdminContractEffects {
    constructor(
        private actions$: Actions,
        private waitListService: WaitListService,
        private store: Store<fromStore.AppState>,
        private apiClient: EventFarmAPIClient,
        private teamAllotmentService: TeamAllotmentService,
        private messageService: NzMessageService,
        private teamPointOfContactService: TeamPointOfContactService,
        private teamContractDetailsService: TeamContractDetailsService

    ) {
    }


    // @ts-ignore
    fetchContractForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.FetchContractForAdminTeam>(adminActions.FETCH_CONTRACT_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamContractDetailsService.fetchPoolContractById(team.id)
            .pipe(
                map(res => res['data']),
                switchMap((contract: Contract) => [
                    new adminActions.FetchContractForAdminTeamSuccess(contract),
                ]),
                catchError(err => of(new adminActions.FetchContractForAdminTeamFail(err)))
            ))
    ));
    // @ts-ignore
    updateContractForAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.UpdateContractForAdminTeam>(adminActions.UPDATE_CONTRACT_FOR_ADMIN_TEAM),
        withLatestFrom(this.store.select(fromRoot.getAdminCurrentTeamData)),
        switchMap(([action, team]) => this.teamContractDetailsService.updatePoolContract({
            poolContractId: action.payload.contractId,
            poolContractType: action.payload.contract.contractType,
            startDate: moment(action.payload.contract.startDate).unix(),
            endDate: moment(action.payload.contract.endDate).unix()
        }).pipe(
            switchMap((contract: Contract) => [
                new adminActions.UpdateContractForAdminTeamSuccess(contract),
                this.messageService.success('Contract updated for pool'),
                new adminActions.FetchContractForAdminTeam(team.id),
            ]),
            catchError(err => {
                    this.messageService.error('Error updating contract for pool');
                    return of(new adminActions.UpdateContractForAdminTeamFail(err));
                }
            )
        )),
    ));


    // @ts-ignore
    addContractToAdminTeam$ = createEffect(() => this.actions$.pipe(
        ofType<adminActions.AddContractToAdminTeam>(adminActions.ADD_CONTRACT_TO_ADMIN_TEAM),
        switchMap((action) => this.teamContractDetailsService.createPoolContract({
            teamId: action.payload.teamId,
            poolContractType: action.payload.contract.contractType,
            startDate: moment(action.payload.contract.startDate).unix(),
            endDate: moment(action.payload.contract.endDate).unix()
        }).pipe(
            switchMap((res: any) => [
                new adminActions.AddContractToAdminTeamSuccess(res.data.command),
                this.messageService.success('Contract added for pool'),
                new adminActions.FetchContractForAdminTeam(action.payload.teamId),
            ]),
            catchError(err => {
                    this.messageService.error('Error adding contract for pool');
                    return of(new adminActions.AddContractToAdminTeamFail(err));
                }
            )
        )),
    ));
}
