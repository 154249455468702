import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../modal.service';
import {EventFarmService} from '../../../../../eventFarm.service';

@Component({
    selector: 'cio-get-started',
    styles: [require('./cio-get-started-stepper.scss')],
    template: require('./cio-get-started-stepper.html')
})

export class CioGetStartedStepperComponent implements OnInit {
    @Input() whatsNextContent?: string = '';
    private userImportId: string = null;
    ngOnInit() {
        this.next = this.next.bind(this);
    }
    current = 0;

    next(userImportId?: string): void {
        this.userImportId = userImportId;
        this.current ++;
        const nextTitle = this.changeTitle(this.current);
        this.modalService.changeModalTitle(nextTitle);
    }

    previous(){
        this.current --;
    }

    changeTitle(current: number) {
        switch (current) {
            case 1:
                return `Step 1: Upload Guests to your Event: <span>${this.eventFarmService.currentEvent.name}</span>`;

            case 2:
                return `Step 2: Processing Upload: <span>${this.eventFarmService.currentEvent.name}</span>`;

            case 3:
                return `Step 3: Preview Upload: <span>${this.eventFarmService.currentEvent.name}</span>`;

            case 4:
                return `Step 4: Processing Upload: <span>${this.eventFarmService.currentEvent.name}</span>`;

            case 5:
                return `Step 5: Confirm Upload of Guests: <span>${this.eventFarmService.currentEvent.name}</span>`;
        }
    }
    done(): void {
    }

    constructor(private modalService: ModalService, private eventFarmService: EventFarmService) {

    }
}
