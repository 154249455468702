import { Component, OnDestroy, OnInit, QueryList, ViewEncapsulation, ViewChildren } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { AlertService } from '../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AngularRestClient } from '../../../_services/rest/AngularRestClient';
import { shareReplay } from 'rxjs/operators';

@Component({
    selector: 'ticket-stacks-list',
    template: require('./ticket-stacks-list.html'),
    styles: [require('./ticket-stacks-list.scss')],
})

export class TicketStacksListComponent implements OnInit {

    readonly ellipsisSVG = require('./assets/ellipsis');
    readonly ellipsisOnSVG = require('./assets/ellipsis-on');
    @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;

    constructor(
        private eventFarmService: EventFarmService,
        private legacyUIService: LegacyUIService,
        private store: Store<fromRoot.AppState>,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
        private alertService: AlertService,
        private http: AngularRestClient
    ) {
    }

    private currentDate: Date;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    ngOnInit() {
        this.currentDate = new Date();
    }

    removeStack(stack: Stack) {
        const that = this;
        this.alertService.ticketStack().confirmTicketStackDeletion(stack.ticketType.name)
        .then((res) => {
            if (res.value) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Requested-With': 'XMLHttpRequest'
                    })
                };

                this.http
                    .post('/stacks/removeStack/' + stack.id, {'delete': true}, httpOptions)
                    .subscribe(
                        (data) => {
                            if (data['result']) {
                                this.alertService.ticketStack().stackSuccessfullyRemoved();
                                this.message.success('Stack removed');
                                that.eventFarmService.updateEventDetails(that.eventFarmService.currentEvent.id);
                            } else {
                                this.alertService.ticketStack().unableToRemove();
                            }
                        },
                    );
            }
        });
    }

    stackColor(stack: Stack) {
        if (this.opensInFuture(stack)) {
            return 'warning';
        } else if (this.closesInPast(stack)) {
            return 'danger';
        } else {
            return 'success';
        }
    }

    closePopovers() {
        this.popovers.forEach((popover: PopoverDirective) => {
            popover.hide();
        });
    }

    stackIcon(stack: Stack) {
        if (this.opensInFuture(stack)) {
            return 'fa-pause-circle-o';
        } else if (this.closesInPast(stack)) {
            return 'fa-stop-circle-o';
        } else {
            return 'fa-play-circle-o';
        }
    }

    stackMessage(stack: Stack) {
        if (this.opensInFuture(stack)) {
            return 'Paused';
        } else if (this.closesInPast(stack)) {
            return 'Closed';
        } else {
            return 'Open';
        }
    }

    stackProgressBarStyle(stack: Stack) {
        if (Number(stack.availabilityCounts.percentUsed) >= 70) {
            return 'success';
        } else {
            return 'primary';
        }
    }

    opensInFuture(stack: Stack) {
        return Number(stack.openingAt) > ( this.currentDate.getTime() / 1000 );
    }

    closesInPast(stack: Stack) {
        return Number(stack.closingAt) < ( this.currentDate.getTime() / 1000 );
    }
}
