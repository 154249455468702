import {AbstractBaseSegment} from './segment-base';

export class SegmentTicketTracking extends AbstractBaseSegment {
    loadTab(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Opened'
        );
    }

    public closeTab(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Closed'
        );
    }

    public createTicketType(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Create Access Type Modal Opened'
        );
    }

    public openManageTicketTypes(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Manage Event Access & Distribution Tab Opened'
        );
    }

    public addNewTicketBlock(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Add New Ticket Block Clicked'
        );
    }

    public closeManageTicketBlock(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Manage Ticket Block Tab Closed'
        );
    }

    public closeManageTicketType(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Manage Tickets / Invitations / Registrations Tab Closed'
        );
    }

    public removeTicketBlockClicked(currentEvent, currentUser) {
        return this.logToSegment(
            currentEvent,
            currentUser,
            'Event Access & Distribution Tab - Manage Ticket Block: Remove Ticket Block Clicked'
        );
    }

    private logToSegment(currentEvent, currentUser, segmentEventName) {
        if (!currentEvent || !currentUser) {
            return;
        }
        // return this.analytics.track({
        //     userId: currentUser.id,
        //     event: segmentEventName,
        //     properties: {
        //         user: {
        //             userName: currentUser.name ? currentUser.name.firstName + ' ' + currentUser.name.lastName : '',
        //             userIdentifier: currentUser.identifier ? currentUser.identifier.identifier : '',
        //         },
        //         event: {
        //             eventName: currentEvent.name,
        //             eventId: currentEvent.id
        //         }
        //     }
        // });
    }
}
