import { Component } from '@angular/core';

import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { AlertService } from '../../../eventFarmAlert.service';

import { ScheduledCreateComponent } from './Create/create.component';

import { DesignsService } from './Create/designs.service';
import { ScheduledService } from './scheduled.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
    providers: [ DesignsService ],
    styles: [ require('./scheduled.scss') ],
    template: require('./scheduled.html'),
})

export class ScheduledComponent {
    constructor(
        private alertService: AlertService,
        private designsService: DesignsService,
        private modalService: ModalService,
        private scheduledService: ScheduledService,
        private segmentService: SegmentService
    ) { }

    ngOnInit() {
        this.scheduledService.assembleList();
        this.designsService.listDesigns();

        this.segmentService.segmentSideNavTracking().scheduledMessagesEntered();

    }

    private create() {
        // disabling create for now
        // let modal: Modal = new Modal();
        // modal.title = 'Add Scheduled Message';
        // modal.component = ScheduledCreateComponent;

        // this.modalService.changeModal(modal);
    }

    private removeMessage(message) {
        this.alertService.emailDesignCenter().removeScheduledEmail()
        .then((res) => {
            if (res.value) {
                this.scheduledService.removeMessage(message);
            }
        });
    }
}
