import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {NzUtilitiesModule} from "../../../NZUtilities/nz.utilities.module";
import { UtilitiesModule } from "../../../Utilities/utilities.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {GhostComponent} from "./Ghost/ghost.component";
import {DowngradeFromAdmin} from "./ManageAdmin/DowngradeFromAdmin/downgrade-from-admin";
import {ManageAdminComponent} from "./ManageAdmin/manage-admin.component";
import {ManageAdminService} from "./ManageAdmin/manage-admin.service";
import {UpgradeToAdminComponent} from "./ManageAdmin/UpgradeToAdmin/upgrade-to-admin.component";
import { UserRoutingModule } from "./user-routing.module";

import { RequestCanvasComponent } from "../../CoreUI/Modal/Modals/RequestCanvas/request-canvas.component";
import { ModalModule } from "../../CoreUI/Modal/modal.module";
import { UserComponent } from "./user.component";
import { AdminService } from "../admin.service";
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
  bootstrap: [UserComponent],
  declarations: [UserComponent, ManageAdminComponent, GhostComponent, UpgradeToAdminComponent, DowngradeFromAdmin],
  entryComponents: [UserComponent, ManageAdminComponent, GhostComponent, UpgradeToAdminComponent, DowngradeFromAdmin],
  imports: [
    UserRoutingModule,
    CommonModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    UtilitiesModule,
      NgxUtilitiesModule,
    NzUtilitiesModule,
  ],
  providers: [AdminService, ManageAdminService],
})
export class UserModule {}
