import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventAgendaComponent } from './event-agenda.component';
import { SpeakersComponent } from './Speakers/speakers.component';
import { SessionsComponent } from './Sessions/sessions.component';
import { TracksComponent } from './Tracks/tracks.component';
import { SponsorsComponent } from './Sponsors/sponsors.component';

const routes: Routes = [
    {
        path: '',
        component: EventAgendaComponent,
        children: [
            {
                path: 'speakers',
                component: SpeakersComponent,
            },
            {
                path: 'sponsors',
                component: SponsorsComponent,
            },
            {
                path: 'sessions',
                component: SessionsComponent,
            },
            {
                path: 'tracks',
                component: TracksComponent,
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class EventAgendaRoutingModule {
}
