import {Action} from '@ngrx/store';
import {PoolContact} from '../../../../ApiClient/Models/PoolContact/poolContact';
import {Contract} from '../../../../ApiClient/Models/Contract/contract';
import {ContractDetailsFormValues} from '../../../AdminModule/Common/ContractDetailsForm/contract-details-form.component';

export const FETCH_CONTRACT_FOR_ADMIN_TEAM = '[Admin/Team] Fetch Contract For Team';
export const FETCH_CONTRACT_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Fetch Contract For Team Success';
export const FETCH_CONTRACT_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Fetch Contract For Team Fail';

export const ADD_CONTRACT_TO_ADMIN_TEAM = '[Admin/Team] Add Contract To Team';
export const ADD_CONTRACT_TO_ADMIN_TEAM_SUCCESS = '[Admin/Team] Add Contract To Team Success';
export const ADD_CONTRACT_TO_ADMIN_TEAM_FAIL = '[Admin/Team] Add Contract To Team Fail';

export const UPDATE_CONTRACT_FOR_ADMIN_TEAM = '[Admin/Team] Update Contract From Team';
export const UPDATE_CONTRACT_FOR_ADMIN_TEAM_SUCCESS = '[Admin/Team] Update Contract From Team Success';
export const UPDATE_CONTRACT_FOR_ADMIN_TEAM_FAIL = '[Admin/Team] Update Contract From Team Fail';

export const REMOVE_CONTRACT_FROM_ADMIN_TEAM = '[Admin/Team] Remove Contract From Team';
export const REMOVE_CONTRACT_FROM_ADMIN_TEAM_SUCCESS = '[Admin/Team] Remove Contract From Team Success';
export const REMOVE_CONTRACT_FROM_ADMIN_TEAM_FAIL = '[Admin/Team] Remove Contract From Team Fail';

export class AddContractToAdminTeam implements Action {
    readonly type = ADD_CONTRACT_TO_ADMIN_TEAM;
    constructor( public payload: {teamId: string, contract: ContractDetailsFormValues}) {}
}

export class AddContractToAdminTeamSuccess implements Action {
    readonly type = ADD_CONTRACT_TO_ADMIN_TEAM_SUCCESS;
    constructor(public payload: Contract) { }
}

export class AddContractToAdminTeamFail implements Action {
    readonly type = ADD_CONTRACT_TO_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class UpdateContractForAdminTeam implements Action {
    readonly type = UPDATE_CONTRACT_FOR_ADMIN_TEAM;
    constructor(public payload: {contractId: string, contract: ContractDetailsFormValues}) { }
}

export class UpdateContractForAdminTeamSuccess implements Action {
    readonly type = UPDATE_CONTRACT_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: Contract) { }
}

export class UpdateContractForAdminTeamFail implements Action {
    readonly type = UPDATE_CONTRACT_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class FetchContractForAdminTeam implements Action {
    readonly type = FETCH_CONTRACT_FOR_ADMIN_TEAM;
    constructor(public payload: string) { }
}

export class FetchContractForAdminTeamSuccess implements Action {
    readonly type = FETCH_CONTRACT_FOR_ADMIN_TEAM_SUCCESS;
    constructor(public payload: Contract) { }
}

export class FetchContractForAdminTeamFail implements Action {
    readonly type = FETCH_CONTRACT_FOR_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}

export class RemoveContractFromAdminTeam implements Action {
    readonly type = REMOVE_CONTRACT_FROM_ADMIN_TEAM;
    constructor(public payload: {teamId: string, contactId: string, userId: string}) { }
}

export class RemoveContractFromAdminTeamSuccess implements Action {
    readonly type = REMOVE_CONTRACT_FROM_ADMIN_TEAM_SUCCESS;
    constructor(public payload: PoolContact) { }
}

export class RemoveContractFromAdminTeamFail implements Action {
    readonly type = REMOVE_CONTRACT_FROM_ADMIN_TEAM_FAIL;
    constructor(public payload: string) { }
}
export type AdminContractAction =
    AddContractToAdminTeam |
    AddContractToAdminTeamSuccess |
    AddContractToAdminTeamFail |
    UpdateContractForAdminTeam |
    UpdateContractForAdminTeamSuccess |
    UpdateContractForAdminTeamFail |
    RemoveContractFromAdminTeam |
    RemoveContractFromAdminTeamSuccess |
    RemoveContractFromAdminTeamFail |
    FetchContractForAdminTeam |
    FetchContractForAdminTeamSuccess |
    FetchContractForAdminTeamFail;
