import { BehaviorSubject } from 'rxjs';
import { PoolFeature } from '../../../../../ApiClient/Models/PoolFeature/poolFeature';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PoolWorld } from '../../../../../ApiClient/Models/PoolWorld/pool-world';
import * as fromRoot from '../../../../store'
import {shareReplay} from 'rxjs/operators';
import {Store} from "@ngrx/store";

@Injectable()
export class TeamEchoWorldsService {

  virbelaWorlds$ = this.store.select(fromRoot.getAdminCurrentVirbelaWorlds).pipe(shareReplay());
  currentPoolWorldsForPool$ = this.store.select(fromRoot.getAdminCurrentTeamPoolWorlds).pipe(shareReplay());

  constructor(
    private apiClient: EventFarmAPIClient,
    private messageService: NzMessageService,
    private store: Store
  ) {}

  public fetchPoolWorldsForPool(poolId) {
    return this.apiClient
      .getUseCaseFactory()
      .PoolWorld()
      .ListPoolWorldsForPool(poolId);
  }

  public listAllVirbelaWorlds() {
    return this.apiClient
      .getUseCaseFactory()
      .VirbelaWorld()
      .ListVirbelaWorlds();
  }

  public addPoolWorldToPool(poolId, virbelaWorldId) {
    return this.apiClient
      .getUseCaseFactory()
      .PoolWorld()
      .CreatePoolWorld(poolId, virbelaWorldId);
  }

  public removePoolWorldFromPool(poolWorldId) {
    return this.apiClient
      .getUseCaseFactory()
        .PoolWorld()
        .RemovePoolWorld(poolWorldId);
  }
}
