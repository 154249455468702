import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {Invitation} from '../../../ApiClient/Models/Invitation/invitation';
import {TicketBlock} from '../../../ApiClient/Models/Stack/ticket-block';
import {EventFarmService} from '../../eventFarm.service';
import * as fromRoot from '../../store';
import {ModalService} from '../Modal/modal.service';
import {GuestListViewService} from './guest-list-view.service';
import {GuestListBulkUpdateFormService} from './GuestListBulkUpdateForm/guest-list-bulk-update-form.service';
import {GuestListFiltersService} from './GuestListFilters/guest-list-filters.service';

@Component({
    selector: 'guest-list-view',
    providers: [
        ModalService,
    ],
    template: require('./guest-list-view.html'),
    styles: [require('./guest-list-view.scss')],
})

export class GuestListViewComponent implements OnInit, OnDestroy {

    @Input() type: 'Event' | 'TicketBlock' = 'Event';
    @Input() ticketBlock?: TicketBlock;

    public guests: Invitation[] = [];

    constructor(
        private modalService: ModalService,
        private store: Store<fromRoot.AppState>,
        private eventFarmService: EventFarmService,
        private guestListService: GuestListViewService,
        public route: ActivatedRoute,
        private guestListFiltersService: GuestListFiltersService,
        private guestListViewService: GuestListViewService,
        private guestListBulkUpdateFormService: GuestListBulkUpdateFormService
    ) {

    }

    private tabs: any = {
        activeTab: 'all',
        activeSubTab: 'all',
        activeTabPublicName: 'all',
        activeTabIndex: 3,
    };

    private loaded: boolean = false;
    private fadeOut: boolean = false;
    private hasTransfers: boolean = false;
    private suspend: boolean = false;

    ngOnInit() {
        this.checkContext();
    }

    ngOnChanges(changes: any): void {
        this.checkContext();
    }

    checkContext() {
        this.hasTransfers = false;
        this.guestListService.ticketBlock = this.ticketBlock;
        this.guestListService.counts$.next({})

        if (this.type === 'TicketBlock' && this.guestListService.ticketBlock && this.guestListService.ticketBlock.id) {
            this.guestListService.apiActionType = 'ListInvitationsForTicketBlock';
            this.guestListService.ticketBlock = this.ticketBlock;
            this.guestListService.currentEventOrTicketBlockId = this.guestListService.ticketBlock.id;
            if (!this.loaded) {
                this.loaded = true;
                this.guestListService.assembleGuestListData();
            }
        } else if (this.type === 'Event' && this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id) {

            this.guestListService.apiActionType = 'ListInvitationsForEvent';
            this.guestListService.currentEventOrTicketBlockId = this.eventFarmService.currentEvent.id;
            if (!this.loaded) {
                this.guestListService.assembleGuestListData();
            }
        }
    }


    private getUserAttributeValuesForQuery() {
        return ['info', 'custom'];
    }


    changePage(page) {
        this.fadeOut = true;
        this.guestListService.parameters.pagination.page = page
        this.guestListService.fetchGuestListForEventOrTicketBlock();
    }

    changePageSize(pageSize) {
        this.fadeOut = true;
        this.guestListService.parameters.pagination.itemsPerPage = pageSize
        this.guestListService.fetchGuestListForEventOrTicketBlock();
    }

    ngOnDestroy() {
        // Reset search, sort filter and bulk selections
        this.guestListViewService.resetQueryParams();
        this.guestListFiltersService.resetFilterSelection();
        this.guestListFiltersService.resetQuickFilter();
        this.guestListBulkUpdateFormService.clearBulkUpdate();
        // Reset invitations to clear guest list
        this.guestListService.resetGuestList();
    }

}
