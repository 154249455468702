import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Modal } from '../../CoreUI/Modal/modal.class';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { CopyEventDetailsComponent } from './CopyEventDetails/copy-event-details.component';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import {LocalStorageService} from '../../../_services/storage/local-storage.service';
import {
    CioGetStartedWelcomeService
} from '../../CoreUI/Modal/Modals/CioGetStarted/CioGetStartedWelcome/cio-get-started-welcome.service';
import * as _ from 'lodash';
import {Team} from "../../../ApiClient/Models/Pool/team";
@Component({
    selector: 'event-info',
    template: require('./event-info.html'),
    styles: [require('./event-info.scss')],
})

export class EventInfoComponent implements OnInit, OnDestroy, AfterViewInit {
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    private currentEvent: EfEvent;
    private currentTeam: Team;
    private cioModalInitialized: boolean = false;
    constructor(
        private eventFarmService: EventFarmService,
        private route: ActivatedRoute,
        private store: Store<fromRoot.AppState>,
        private router: Router,
        private legacyUIService: LegacyUIService,
        private routeGenerator: RouteGeneratorService,
        private modalService: ModalService,
        private segmentService: SegmentService,
        private localStorageService: LocalStorageService,
        private cioGetStartedWelcomeService: CioGetStartedWelcomeService,

    ) {
        this.store.select(fromRoot.getCurrentEvent).pipe()
            .subscribe(async event => {
                this.currentEvent = event;
                if (event.data && event.data.isTypeCio && _.get(this, 'currentTeam.features.loaded')) { await this.checkToInitializeCioModal(event); }
            });

        this.store.select(fromRoot.getCurrentTeam).pipe()
            .subscribe(async team => {
                this.currentTeam = team;
                if (_.get(this, 'currentTeam.features.loaded') && this.currentEvent) { await this.checkToInitializeCioModal(this.currentEvent); }
            });
    }

    async checkToInitializeCioModal(event) {
        const cioGetStartedDisplayCount = this.localStorageService.get('cioGetStartedDisplayCount');
        if (!this.cioModalInitialized && (cioGetStartedDisplayCount === null || cioGetStartedDisplayCount === undefined)  && _.get(event, 'data.isTypeCio')) {
            await this.initializeCioModal(event);
        }
    }
    async initializeCioModal(event) {
        this.cioModalInitialized = true;
        // If event is type of CIO and welcome modal has not been shown
            // Check guest list counts
        const invitationCounts = await this.eventFarmService.fetchGuestCountsForEventOrTicketBlock(event.data.id);
        if (invitationCounts.total === 0) {
            this.localStorageService.set('cioGetStartedDisplayCount', 1);
            if (this.eventFarmService.healthPassEnabled) {
                    // Show CIO + CLEAR modal
                    this.cioGetStartedWelcomeService.launchCioPlusClearModal();
                } else {
                    // SHOW CIO modal only
                this.cioGetStartedWelcomeService.launchCioModal();
                }
        }
    }
    ngOnInit() {
        if (this.eventFarmService.currentEvent) {
          this.store.dispatch(new fromRoot.FetchCurrentEvent());
        }
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    ngAfterViewInit() {

        this.route.queryParamMap.subscribe((res: Params) => {
            if (res.params.new) {
                const modal: Modal = new Modal();
                modal.component = CopyEventDetailsComponent;
                modal.cssClass = 'no-header';
                this.modalService.changeModal(modal);
            }
        });

        this.segmentService.segmentEventTracking().loadEvent();
        this.segmentService.segmentSideNavTracking().eventInfoEntered();
    }

    ngOnDestroy() {
    }
}
