import { Component, Input, OnInit } from '@angular/core';
import { ErrorInterface } from '../../../ApiClient/Models/error';

@Component({
    selector: 'ef-form-error',
    styles: [require('./formErrors.scss')],
    template: require('./formErrors.html'),
})

export class ErrorComponent implements OnInit {
    @Input() errors: ErrorInterface[];
    // constructor() {}
    ngOnInit() {
    }
}
