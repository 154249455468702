import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsComponent } from './events.component';
import { EventResolver } from './event-resolver.service';

const eventsRoutes: Routes = [
    {
        path: '',
        component: EventsComponent,
        children: [
            {
                path: ':event',
                resolve: [EventResolver],
                loadChildren: () => import(/* webpackChunkName: 'eventInfo' */ './EventInfo/eventInfo.module').then(
                    module => module.EventInfoModule
                )
            },
            {
                path: ':event/event-access',
                resolve: [EventResolver],
                loadChildren: () => import('./EventAccess/event-access.module').then(
                    module => module.EventAccessModule
                )
            },
            {
                path: ':event/child-events',
                resolve: [EventResolver],
                loadChildren: () => import('./ChildEvents/child-events.module').then(
                    module => module.ChildEventsModule
                )
            },
            {
                path: ':event/guest-list',
                resolve: [EventResolver],
                loadChildren: () => import('./GuestList/guest-list.module').then(
                    module => module.GuestListModule
                )
            },
            {
                path: ':event/wait-list',
                // resolve: [EventResolver],
                loadChildren: () => import('./WaitList/waitList.module').then(
                    module => module.WaitListModule
                )
            },
            {
                path: ':event/web-presence',
                resolve: [EventResolver],
                loadChildren: () => import('./WebPresence/web-presence.module').then(
                    module => module.WebPresenceModule
                )
            },
            {
                path: ':event/web-form',
                resolve: [EventResolver],
                loadChildren: () => import('./OldWebPresence/OldWebPresence.module').then(
                    module => module.OldWebPresenceModule
                )
            },
            {
                path: ':event/email-center',
                resolve: [EventResolver],
                loadChildren: () => import('./EmailDesignCenter/email-design-center.module').then(
                    module => module.EmailDesignCenterModule
                )
            },
            {
                path: ':event/email-center/invitations-confirmations',
                resolve: [EventResolver],
                loadChildren: () => import('./EmailCommunication/email-communication.module').then(
                    module => module.EmailCommunicationModule
                )
            },
            {
                path: ':event/questions',
                resolve: [EventResolver],
                loadChildren: () => import('./QuestionCenter/question-center.module').then(
                    module => module.QuestionCenterModule
                )
            },
            {
                path: ':event/payments-promos',
                resolve: [EventResolver],
                loadChildren: () => import('./PromotionsAndPayments/payments-promotions.module').then(
                    module => module.PaymentsPromoModule
                )
            },
            {
                path: ':event/event-promotions',
                resolve: [EventResolver],
                loadChildren: () => import('./EventPromotions/eventPromotions.module').then(
                    module => module.EventPromotionsModule
                )
            },
            {
                path: ':event/reports',
                resolve: [EventResolver],
                loadChildren: () => import('./Reports/reports.module').then(
                    module => module.ReportsModule
                )
            },
            {
                path: ':event/efx',
                resolve: [EventResolver],
                loadChildren: () => import('./EFX/efx.module').then(
                    module => module.EFXModule
                )
            },
            {
                path: ':event/exhibitors',
                resolve: [EventResolver],
                loadChildren: () => import('./Exhibitors/exhibitors.module').then(
                    module => module.ExhibitorsModule
                )
            },
            {
                path: ':event/event-info',
                resolve: [EventResolver],
                loadChildren: () => import('./EventInfo/eventInfo.module').then(
                    module => module.EventInfoModule
                )
            },
            {
                path: ':event/event-integrations',
                resolve: [EventResolver],
                loadChildren: () => import('./EventIntegrations/eventIntegrations.module').then(
                    module => module.EventIntegrationsModule
                )
            },
            {
                path: ':event/agenda',
                resolve: [EventResolver],
                loadChildren: () => import('./EventAgenda/event-agenda.module').then(
                    module => module.EventAgendaModule
                )
            },
            {
                path: ':event/sms',
                resolve: [EventResolver],
                loadChildren: () => import('./SMS/sms.module').then(
                    module => module.SMSModule
                )
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(eventsRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class EventsRoutingModule {
}
