import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
const moment = require('moment');
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { ScheduledService } from '../scheduled.service';
import { DesignsService } from './designs.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { dateTimeFormat, timePickerDefaults } from '../../../../../Shared/datetime-options';
import { differenceInCalendarDays } from 'date-fns';
@Component({
    providers: [ DesignsService ],
    styles: [
    ],
    template: require('./create.html'),
})
export class ScheduledCreateComponent {
    private submitted: boolean = false;
    private invalid: boolean = false;
    private errored: boolean = false;
    private dateTimeFormat = dateTimeFormat;
    private disabledDate;
    private timePickerDefaults = timePickerDefaults;

    private timeOptions: any = {
        dateFormat: dateTimeFormat,
        enableTime: true,
        defaultDate: moment().hour(10).minute(0).add(1, 'day').toDate(),
        minDate: moment().add(15, 'minutes').toDate(),
    };
    private createFormGroup: FormGroup = new FormGroup({
        time: new FormControl(moment().hour(10).minute(0).add(1, 'day').toDate(), Validators.required),
        timezone: new FormControl(this.designsService.eventTimeZone, Validators.required),
        designId: new FormControl(null, Validators.required),
    });
    private statuses = [
        {
            name: 'CONFIRMED or REGISTERED',
            slug: 'confirmed',
        },
        {
            name: 'PURCHASED',
            slug: 'purchased',
        },
        {
            name: 'ASSIGNED',
            slug: 'assigned',
        },
        {
            name: 'UNCONFIRMED',
            slug: 'unconfirmed',
        },
        {
            name: 'CHECKED IN',
            slug: 'attended',
        },
    ];
    private timezones = this.apiClient.getDateTimeFactory().TimeZoneList();
    constructor(
        private modalService: ModalService,
        private designsService: DesignsService,
        private apiClient: EventFarmAPIClient,
        private scheduledService: ScheduledService,
        private alertService: AlertService
    ) {
        for (const status of this.statuses) {
            this.createFormGroup.addControl(status.slug, new FormControl());
        }
    }
    ngOnInit() {
        this.designsService.listDesigns();
        this.disabledDate = (current: Date): boolean => {
            return differenceInCalendarDays(current, new Date()) < 0;
        };
        // this.createFormGroup.setValue({'time': moment().hour(10).minute(0).add(1, 'day').toDate()});
    }
    private get actualTime() {
        const value = this.createFormGroup.get('time').value;
        if (!value) {
            return moment().hour(10).minute(0).add(1, 'day').format('YYYY-MM-DD HH:mm');
        }
        return moment(value).format('YYYY-MM-DD HH:mm');
    }
    private get targets() {
        const targets = [];
        for (const status of this.statuses) {
            if (this.createFormGroup.get(status.slug).value) {
                targets.push(status.slug);
            }
        }
        return targets;
    }
    private get data() {
        const data = Object.assign({}, this.createFormGroup.value);
        data.time = this.actualTime;
        data.targets = this.targets;
        return data;
    }
    private submit($event) {
        $event.preventDefault();
        this.submitted = true;
        this.errored = false;
        this.invalid = false;
        if (
            this.createFormGroup.status !== 'VALID'
            || ! this.targets.length
        ) {
            this.invalid = true;
            return;
        }
        this.designsService.create(this.data)
        .subscribe(
            (res) => this.success(),
            (err) => this.errored = true,
        );
    }
    private success() {
        this.scheduledService.assembleList();
        this.alertService.emailDesignCenter().messageScheduledSuccess()
            .then((res) => this.modalService.closeModal());
    }
}
