import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { EfxStation } from '../../../../../../ApiClient/Models/EfxStation/efxStation';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import _ = require('lodash');
import { EfxStationService } from '../../efx-station.service';
import { EFx } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';
import { TicketType } from '../../../../../../ApiClient/Models/Stack/ticket-type';

@Component({
    selector: 'edit-station',
    template: require('./edit-station.html'),
    styles: [require('./edit-station.scss')],
})

export class EditStationComponent implements OnInit, OnDestroy {
    @Input() efxStationItem: EfxStation;
    editEfxStationItemForm: FormGroup;

    private isSaving: boolean = false;
    private confirmDelete: boolean = false;
    private isDeleting: boolean = false;
    public stationTypeArray = [{name: 'Check In', slug: 'check-in'}, {name: 'Check In Out', slug: 'check-in-out'}];
    public ticketTypeArray: TicketType[] = [];

    public base64Image: string = '';
    private removingImage: boolean = false;

    constructor(
        private drawerRef: NzDrawerRef,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
        private messageService: NzMessageService,
        private efxStationService: EfxStationService
    ) {}

    ngOnInit() {
        if (this.efxStationItem && this.efxStationItem['_smsCDText']) {
            this.efxStationService.smsGreeting$.next(this.efxStationItem['_smsCDText']);
        } else {
            this.efxStationService.smsGreeting$.next('');
        }

        if (this.efxStationItem && this.efxStationItem.emailCDEmailDesign) {
            this.efxStationService.cdEmailDesign$.next(this.efxStationItem.emailCDEmailDesign.id);
        } else {
            this.efxStationService.cdEmailDesign$.next('None');
        }
        if (!this.efxStationItem) {
            this.efxStationItem = EfxStation.forCreate();
        }
        this.initializeFormControls();
        this.efxStationService.stationScreens = {};
    }

    private initializeFormControls() {
        const checkIn = new EFx().EFxStationType().find(st => st.isCheckIn);
        this.ticketTypeArray = this.eventFarmService.currentEvent.ticketTypes;

        this.editEfxStationItemForm = new FormGroup({
            stationName: new FormControl(_.get(this, 'efxStationItem.stationName', null), Validators.required),
            moduleType: new FormControl(_.get(this, 'efxStationItem.moduleType', 'access-control')),
            stationType: new FormControl(_.get(this, 'efxStationItem.stationType.slug', checkIn.slug), Validators.required),
            stationTicketTypes: new FormControl(_.get(this, 'efxStationItem.ticketTypeIds', [])),
        });
    }

    public checkForTextAndImage() {
        const smsText = this.efxStationService.smsGreeting$.value;
        return !(!smsText && this.base64Image);
    }

    private async addEfxStation() {
        let imageUrl;
        if (this.checkForTextAndImage()) {
            if (this.isSaving === false) {
                this.isSaving = true;
                try {
                    if (this.base64Image) {
                        imageUrl = await this.efxStationService.uploadPoolImage(this.base64Image);
                    }
                    const efxStation = new EfxStation();
                    efxStation.stationName = this.editEfxStationItemForm.get('stationName').value;
                    efxStation.moduleType = this.editEfxStationItemForm.get('moduleType').value;
                    efxStation.stationType = this.editEfxStationItemForm.get('stationType').value;
                    const ticketTypeIds = this.editEfxStationItemForm.get('stationTicketTypes').value;
                    await this.efxStationService.createEfxStation(efxStation, ticketTypeIds, imageUrl);
                    this.messageService.success('Access control station created!');
                    await this.updateEfxStationsTable();
                } catch (err) {
                    this.messageService.error('Error adding access control station');
                }
                this.isSaving = false;
                this.drawerRef.close();
            }
        } else {
            this.messageService.error('Please configure text message if you wish to use an image.');
        }
    }

    private async saveEfxStationInfo() {
        let imageUrl;
        if (this.checkForTextAndImage()) {
            if (this.isSaving === false) {
                this.isSaving = true;
                if (this.base64Image) {
                    imageUrl = await this.efxStationService.uploadPoolImage(this.base64Image);
                } else {
                    if (!this.removingImage) {
                        imageUrl = this.efxStationItem.smsCDMediaUrl;
                    }
                }
                const updatedEfxStation = new EfxStation();
                updatedEfxStation.stationName = this.editEfxStationItemForm.get('stationName').value;
                updatedEfxStation.stationType = this.editEfxStationItemForm.get('stationType').value;
                const ticketTypeIds = this.editEfxStationItemForm.get('stationTicketTypes').value;
                try {
                    await this.efxStationService.updateEfxStation(this.efxStationItem.id, updatedEfxStation, ticketTypeIds, imageUrl);
                    this.messageService.success('Access control station updated!');
                    await this.updateEfxStationsTable();
                } catch (error) {
                    this.messageService.error('Error updating access control station');
                }
                this.isSaving = false;
                this.drawerRef.close();
            }
        } else {
            this.messageService.error('Please enter text description if uploading an image.');
        }
    }

    public async saveContentDelivery(id, imageUrl) {
        await this.efxStationService.setContentDelivery(id, imageUrl);
        await this.updateEfxStationsTable();
    }

    public async updateEfxStationsTable() {
        this.efxStationService.smsGreeting$.next('');
        this.drawerRef.close();
        this.efxStationService.efxStationsListOptions.pagination.currentEfxStationPage = 1;
        await this.efxStationService.getEfxStations();
    }

    private async handleDeleteEfxStation() {
        if (this.isDeleting) {
            this.isDeleting = false;
        }
        if (this.confirmDelete) {
            this.isDeleting = true;
            const deleteSuccess = await this.efxStationService.removeEfxStation(this.efxStationItem.id);

            this.isDeleting = false;
            this.confirmDelete = false;

            if (deleteSuccess === true) {
                this.drawerRef.close();
                await this.efxStationService.getEfxStations();
            }
        }
        this.confirmDelete = true;
    }

    private allowSave() {
        return this.editEfxStationItemForm.valid && this.efxStationService.areScreenValid;
    }

    close(): void {
        this.drawerRef.close(this.efxStationItem);
    }

    public imageUploaded(image) {
        if (!image && this.efxStationItem && this.efxStationItem.smsCDMediaUrl) {
            this.removingImage = true;
        }

        this.base64Image = image;
    }

    ngOnDestroy() {
    }
}
