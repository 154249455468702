import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EventRevenueComponent } from './event-revenue.component';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '../../../CoreUI/Error/error.module';
import { UtilitiesModule } from '../../../../Utilities/utilities.module';
import { EventRevenueService } from './event-revenue.service';
import { PaymentsComponent } from './Payments/payments.component';
import { AlertService } from '../../../eventFarmAlert.service';
import { NzUtilitiesModule } from '../../../../NZUtilities/nz.utilities.module';
import {NgxUtilitiesModule} from '../../../../NGXUtilities/ngx.utilities.module';

const forExport = [
    EventRevenueComponent,
    PaymentsComponent
];

@NgModule({
    declarations: forExport,
    exports: forExport,
    entryComponents: [
        PaymentsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        UtilitiesModule,
        NzUtilitiesModule,
        NgxUtilitiesModule
    ],
    providers: [
        EventRevenueService,
        AlertService
    ]
})

export class EventRevenueModule {
}
