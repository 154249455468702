import { User } from '../User/user';
import { WebConference, WebConferenceSourceTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/WebConference';
import { AbstractModel } from '../abstract-model';
import { Team } from '../Pool/team';

export class WebConferenceUserConnection extends AbstractModel {
    
    public _user: User;
    public _team: Team;
    public _sourceType: WebConferenceSourceTypeInterface;
    public _extraParams: string|null;

    constructor(id) {
        super(id);
    }
    
    public static fromApiResponse(data): WebConferenceUserConnection {
        const userConnection = new this(data.id);

        userConnection._sourceType = data.attributes ? data.attributes.sourceType : new WebConference().WebConferenceSourceType().find(v => v.isZoom);
        
        userConnection._extraParams = data.attributes ? data.attributes.extraParams : null;

        if (data.relationships && data.relationships.pool) {
            userConnection._team = Team.fromApiResponse(data.relationships.pool);
        }

        if (data.relationships && data.relationships.user) {
            userConnection._user = User.fromApiResponse(data.relationships.user);
        }

        return userConnection;
    }

    get user(): User {
        return this._user;
    }

    get team(): Team {
        return this._team;
    }

    get sourceType(): WebConferenceSourceTypeInterface {
        return this._sourceType;
    }

    get userEmailAndType(): string {
        const email = this._user.primaryEmail ? this._user.primaryEmail : ""; 
        return `${email} (${this._sourceType.name})`;
    }

    get userEmail(): string {

        if (this._extraParams) {
            const extra = JSON.parse(this._extraParams);
            return extra.firstName + ' ' + extra.lastName + ' (' + extra.email + ')';
        }

        return this._user.primaryEmail ? this._user.primaryEmail : ""; 
    }

}

