import { Component, OnInit } from '@angular/core';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { ChildEventsService } from '../child-events.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';
import { AlertService } from '../../../eventFarmAlert.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { EventFarmService } from '../../../eventFarm.service';

@Component({
    selector: 'children',
    template: require('./children.html'),
    styles: [require('./children.scss')]
})
export class ChildrenComponent implements OnInit {
    constructor(
        private iconService: IconService,
        private childEventsService: ChildEventsService,
        private store: Store<fromRoot.AppState>,
        private alertService: AlertService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private eventFarmService: EventFarmService
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit(): void {
        this.childEventsService.getChildEvents();
    }

    public changePage(page) {
        this.childEventsService.childEventsOptions.pagination.currentPage = page;
        this.childEventsService.getChildEvents();
      }

}
