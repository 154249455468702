import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';
import {Communication, InvitationStatusValue, SendByValue} from '../../../../ApiClient/Models/Communication';
import {Group} from '../../../../ApiClient/Models/Group/group';
import {Invitation} from '../../../../ApiClient/Models/Invitation/invitation';
import {TicketBlock} from '../../../../ApiClient/Models/Stack/ticket-block';
import {TicketType} from '../../../../ApiClient/Models/Stack/ticket-type';
import {EventFarmService} from '../../../eventFarm.service';

interface OptionInterface {
    value: string;
    label: string;
}

export interface SMSInvitationInterface extends OptionInterface {
    label: string;
    value: string;
    telephone: string;
}

interface SMSRecipientConfig {
    sendBy: SendByValue;
    invitations: SMSInvitationInterface[];
    invitationStatus: InvitationStatusValue[];
    ticketTypes: TicketType[];
    ticketBlock: OptionInterface;
    group: OptionInterface;
    whatsAppEnabled: boolean;
}

@Injectable()

export class SMSCreateService {
    private _recipientConfig: SMSRecipientConfig = {
        sendBy: 'invitations',
        invitations: [],
        invitationStatus: null,
        ticketTypes: [],
        ticketBlock: null,
        group: null,
        whatsAppEnabled: null,
    };
    private _previewRecipients: string[];
    isLoading: boolean;
    isSending: boolean;
    errorSending: boolean;
    sent$ = new BehaviorSubject<boolean>(false);

    private _content: string = '';

    public filteredGroups$ = new BehaviorSubject<any[]>([]);
    public filteredInvitations$ = new BehaviorSubject<SMSInvitationInterface[]>([]);
    public filteredTicketBlocks$ = new BehaviorSubject<any[]>([]);

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
    ) {
    }

    get recipientConfig(): SMSRecipientConfig {
        return this._recipientConfig;
    }

    set recipientConfig(value: SMSRecipientConfig) {
        this._recipientConfig = value;
    }

    set content(val: string) {
        this._content = val;
    }

    get content(): string {
        return this._content;
    }

    get previewRecipients(): string[] {
        return this._previewRecipients;
    }

    set previewRecipients(value: string[]) {
        this._previewRecipients = value;
    }

    get collatedRecipients(): string[] {
        return this.previewRecipients.map(recipient => recipient['e164Number']);
    }

    public reset() {
        this.content = '';
        this.previewRecipients = [];
        this.recipientConfig.sendBy = 'invitations';
        this.recipientConfig.invitations = [];
        this.recipientConfig.invitationStatus = null;
        this.recipientConfig.ticketTypes = [];
        this.recipientConfig.ticketBlock = null;
        this.recipientConfig.group = null;
        this.recipientConfig.whatsAppEnabled = null;
    }

    public async searchForGroup(term) {
        this.apiClient.getUseCaseFactory().Group().ListGroupsOwnedByUser(
            this.eventFarmService.currentUser.id,
            term,
        ).pipe(
            map(res => res['data']
                .map(group => Group.fromApiResponse((group)))
                .map(group => this.createGroupOption(group))
            ))
            .subscribe(val => {
                this.filteredGroups$.next(val);
            });
    }

    public async searchForInvitations(term) {
        this.isLoading = true;
        this.apiClient.getUseCaseFactory().Invitation().ListInvitationsForEvent(
            this.eventFarmService.currentEvent.id,
            [],
            ['info'],
            term,
            [
                'assigned',
                'purchased',
                'confirmed-by-rsvp',
                'declined-by-rsvp',
                'left-behind',
                'registered',
                'unconfirmed',
                'not-yet-purchased',
                'not-yet-registered'
            ]
        ).pipe(
            map(res => res['data']
                .map(invitation => Invitation.fromApiResponse((invitation)))
                .map(invitation => this.createInvitationOption(invitation))
            ))
            .subscribe(val => {
                this.isLoading = false;
                this.filteredInvitations$.next(val);
            });
    }

    public async searchForTicketBlock(term) {
        this.isLoading = true;
        this.apiClient.getUseCaseFactory().TicketBlock().ListTicketBlocksForEvent(
            this.eventFarmService.currentEvent.id,
            term,
        ).pipe(
            map(res => res['data']
                .map(invitation => TicketBlock.fromApiResponse((invitation)))
                .map(invitation => this.createTicketBlockOption(invitation))
            ))
            .subscribe(val => {
                this.isLoading = false;
                this.filteredTicketBlocks$.next(val);
            });
    }

    // Used to create autocomplete options for group search
    private createGroupOption(group): OptionInterface {
        return {label: group.name, value: group.id};
    }

    private createInvitationOption(invitation: Invitation): SMSInvitationInterface {
        const telephone = invitation.user.hasAttributeType('telephone') ? invitation.user.getAttributeType('telephone') : null;
        return {label: invitation.userName?.fullName, value: invitation.id, telephone};
    }

    private createTicketBlockOption(ticketBlock: TicketBlock): OptionInterface {

        return {label: ticketBlock.name, value: ticketBlock.id};
    }

    async sendPreview() {
        await this.send(true);
    }

    async sendSMS() {
        await this.send(false);
    }

    async send(isPreview?: boolean): Promise<void> {

        this.isSending = true;
        let r;
        if (isPreview) {
            r = await this.getPreviewEndpoint();
        } else {
            r = await this.getEndpoint();
        }

        r.subscribe(val => {
            this.isSending = false;
            // Only reset if this was not a preview
            if (!isPreview) {
                this.sent$.next(true);
                this.reset();
            } else {
                this.sent$.next(false);
            }
        }, err => {
            this.isSending = false;
            this.errorSending = true;
        });

    }

    private getPreviewEndpoint() {
        const ownerUserId = this.eventFarmService.currentUser.id;
        const currentEventId = this.eventFarmService.currentEvent.id;
        return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessagePreview(
            this.collatedRecipients,
            currentEventId,
            ownerUserId,
            this.content,
            null,
            null,
            this.recipientConfig.whatsAppEnabled
        );
    }

    private getEndpoint() {
        const ownerUserId = this.eventFarmService.currentUser.id;
        const currentEventId = this.eventFarmService.currentEvent.id;
        switch (this.recipientConfig.sendBy) {
            case 'ticket-block':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToTicketBlock(
                    this.recipientConfig.ticketBlock.value,
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled
                );
                break;

            case 'invitation-status':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToTicketTypes(
                    Communication.constructTicketTypesForPayload(this.recipientConfig.ticketTypes),
                    Communication.constructStatusesForPayload(this.recipientConfig.invitationStatus),
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled
                );
                break;

            case 'invitations':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToInvitations(
                    this.recipientConfig.invitations.map(invitation => invitation.value),
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled
                );

                break;

            case 'wait-list':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToWaitlist(
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled
                );

                break;
            case 'group':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToGroup(
                    this.recipientConfig.group.value,
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled,
                    'has_invitation'
                );
                break;

            case 'no-shows':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToNoShows(
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled
                );
                break;

            case 'checked-in':
                return this.apiClient.getUseCaseFactory().SMSMessage().SendASMSMessageToAllCheckedInGuests(
                    currentEventId,
                    ownerUserId,
                    this.content,
                    null,
                    null,
                    this.recipientConfig.whatsAppEnabled
                );
                break;
        }

    }
}
