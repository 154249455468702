
import { AbstractBaseAlert } from '../base-alert';
import swal from 'sweetalert2';
import * as _ from 'lodash';
export class VirbelaIntegrationAlert extends AbstractBaseAlert {

    copyUrlFromButton(url: string) {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.copySuccess()
    }

    copySuccess(): Promise<any> {
        return this.alert({
            title: 'Link Copied',
            text: 'Link successfully copied to the clipboard',
            icon: 'success',
        });
    }

    createSuccess(): Promise<any> {
        return this.alert({
            title: 'Settings Created',
            text: 'Virbela settings are now set for the pool',
            icon: 'success',
        });
    }

    updateSuccess(): Promise<any> {
        return this.alert({
            title: 'Settings Updated',
            text: 'Virbela settings are now updated for the pool',
            icon: 'success',
        });
    }

    removedSuccess(): Promise<any> {
        return this.alert({
            title: 'Setting Removed',
            text: 'Virbela setting removed for the pool',
            icon: 'success',
        });
    }

    viewPreviewAlert(): Promise<any> {
        return this.alert({
            title: 'View Public Page',
            html: `This will open the account creation page for preview purposes only.  Never copy or share this link directly with guests!`,
            confirmButtonText: 'Visit Link',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'warning',
            focusCancel: true,
            cancelButtonColor: '#a5a8b2',
            allowOutsideClick: true,
        });
    }

    public suspendGuest(guestIdentifier: string): Promise<any> {
        return this.alert({
            title: 'Suspend Guest?',
            text: 'Are you sure you want to suspend ' + guestIdentifier + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Suspend Guest',
        });
    }

    public suspendAll(): Promise<any> {
        return this.alert({
            title: 'Suspend Guests?',
            text: 'Are you sure you want to suspend all guests?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Suspend All',
        });
    }

    updateVirbelaSettings(poolId: string, data: any): Promise<any> {
        let teamId;
        const teamMapping = {
            0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H',
            8: 'I', 9: 'J', 10: 'K', 11: 'L', 12: 'M', 13: 'N', 14: 'O', 15: 'P',
            16: 'Q', 17: 'R', 18: 'S', 19: 'T', 20: 'U', 21: 'V', 22: 'W', 23: 'X',
            24: 'Y', 25: 'Z', 26: 'AA', 27: 'AB', 28: 'AC', 29: 'AD', 30: 'AE', 31: 'AF',
            32: 'AG', 33: 'AH', 34: 'AI', 35: 'AJ', 36: 'AK', 37: 'AL', 38: 'AM', 39: 'AN',
            40: 'AO', 41: 'AP', 42: 'AQ', 43: 'AR', 44: 'AS', 45: 'AT', 46: 'AU', 47: 'AV',
            48: 'AW', 49: 'AX', 50: 'AY', 51: 'AZ'
        };

        const titleMapping = [
            { "None": "" },
            { "Company": "[company]" },
            { "Position": "[position]" }
        ]


        return this.alert({

            onBeforeOpen: (m) => {
                const fields = ["worldName", "macDownloadURL", "windowsDownloadURL"]
                let currentIdx = 0;
                const content = swal.getContent()
                const footer = swal.getFooter()
                const title = swal.getTitle()
                const nextButton: HTMLElement = content.querySelector("#next");
                const macCopyButton: HTMLElement = content.querySelector("#macDownloadUrlCopy");
                const windowsCopyButton: HTMLElement = content.querySelector("#windowsDownloadUrlCopy");
                const prevButton: HTMLElement = content.querySelector("#prev");
                const dotButton = content.querySelector(".dot");

                const dot = (idx: number, selected?: boolean) => {
                    let background = selected ? "#3085d6" : "white";
                    return `<span  data-idx="${idx}" class="dot" style='cursor:pointer;display:inline-block; border-radius:100%; margin: 0 5px; width:15px; height:15px; border: 2px solid rgb(147, 149, 153); background:${background};'></span>`
                }

                const populate = () => {
                    const arrowContainer: HTMLElement = content.querySelector("#arrowContainer");
                    arrowContainer.style.display = data.length > 0 ? "flex" : "none";
                    //Determine whether to display prev arrow
                    const prevVisibility = data.length > 1 && currentIdx !== 0 ? "visible" : "hidden";
                    prevButton.style.visibility = prevVisibility;

                    //Determine whether to display next arrow
                    const nextVisibility = data.length > 1 && currentIdx !== data.length - 1 ? "visible" : "hidden";
                    nextButton.style.visibility = nextVisibility;

                    title.textContent = "Update " + _.get(data, `[${currentIdx}].attributes.virbelaWorld.worldName`, '') + " Settings"

                    const hiddenInput = <HTMLInputElement>document.getElementById('poolWorldId');
                    hiddenInput.value = data[currentIdx].id;


                    //Populate teams 
                    const select = content.querySelector("#teamId");
                        select.innerHTML = "";

                    for (const property in teamMapping) {
                        var option = document.createElement("option");
                        option.text = teamMapping[property]
                        option.value = property;
                        if (property === String(_.get(data, `[${currentIdx}].attributes.teamId`))) {
                            option.selected = true;
                        }
                        select.appendChild(option);
                    }


                    //Populate Title Mapping 
                    const select2 = content.querySelector("#titleMapping");
                    select2.innerHTML = "";
                    titleMapping.forEach(obj => {
                        var option = document.createElement("option");
                        option.text = Object.keys(obj)[0]
                        option.value = obj[Object.keys(obj)[0]]
                        option.selected = obj[Object.keys(obj)[0]] === _.get(data, `[${currentIdx}].attributes.titleMapping`);
                        select2.appendChild(option);
                    })


                    fields.forEach(field => {
                        var fieldEl = <HTMLInputElement>document.getElementById(`${field}`);
                        fieldEl.value = _.get(data, `[${currentIdx}].attributes.virbelaWorld.[${field}]`, '');
                    })

                    let footerContent = "<div style='margin: 0 auto; width: 50%; display:flex; justify-content:center;'>";
                   
                    data.map((d, idx) => {
                        return footerContent += dot(idx, currentIdx == idx);
                    })

                    footerContent += "</div>";
                    swal.getFooter().innerHTML = footerContent;
                    swal.getFooter().style.display = data.length > 1 ? "block" : "none";
                }

                nextButton.addEventListener('click', () => {
                    currentIdx = currentIdx + 1;
                    populate()

                })

                macCopyButton.addEventListener('click', () => {
                    this.copyUrlFromButton(data[currentIdx].attributes.virbelaWorld.macDownloadURL)
                })

                windowsCopyButton.addEventListener('click', () => {
                    this.copyUrlFromButton(data[currentIdx].attributes.virbelaWorld.windowsDownloadURL)
                })

                prevButton.addEventListener('click', () => {
                    currentIdx--;
                    populate()

                })

                footer.addEventListener("click", (e: any) => {
                    if (e.target.dataset && e.target.dataset.idx) {
                        currentIdx = parseInt(e.target.dataset.idx);
                        populate();
                    }
                });
                populate();

            },
            title: `Update Settings`,
            confirmButtonText: 'Yes, update settings',
            html:
                `<div id="arrowContainer" style="width:100%; display:flex;justify-content:space-between;">` +
                `<span  onMouseOut="this.style.color='rgb(147, 149, 153)'" onMouseOver="this.style.color='#3085d6'" style="color:rgb(147, 149, 153);cursor:pointer;font-size:32px;" id="prev">&#8672;</span>` +
                `<span onMouseOut="this.style.color='rgb(147, 149, 153)'" onMouseOver="this.style.color='#3085d6'" style="cursor:pointer; color:rgb(147, 149, 153);font-size:32px;" id="next">&#8674;</span>` +
                `</div>` +
                '<input id="poolWorldId" type="hidden"/>' +
                '<label style="display:flex;">World Name</label><input id="worldName" class="form-control sa-input-spacing" placeholder="World String ID" style="background: lightgrey;" disabled>' +
                '<label style="display:flex;">Mac Download Link</label>' +
                '<div style="display:flex;align-items:center;">' +
                '<input id="macDownloadURL" class="form-control sa-input-spacing" placeholder="Mac Download URL" style="background: lightgrey;"" disabled>' +
                '<button style="background: #5085f0; padding: 5px 10px; color: #fdfdfd; margin-left:5px; border:none; outline:none;" id="macDownloadUrlCopy">Copy</button>' +
                '</div>' +
                '<label style="display:flex;">Windows Download Link</label>' +
                '<div style="display:flex;align-items:center;">' +
                '<input id="windowsDownloadURL" class="form-control sa-input-spacing" placeholder="Window Download URL" style="background: lightgrey;" disabled>' +
                '<button style="background: #5085f0; padding: 5px 10px; color: #fdfdfd; margin-left:5px; border:none; outline:none;" id="windowsDownloadUrlCopy">Copy</button>' +
                '</div>' +
                '<label style="display:flex;">Title Mapping</label>' +
                `<select id="titleMapping" class="form-control sa-input-spacing"></select>` +
                `<label style="display:flex;">Team ID</label>` +
                `<select id="teamId" class="form-control sa-input-spacing"></select>`
            ,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCancelButton: true,
            preConfirm: async () => {

                const pWid = (document.getElementById('poolWorldId') as HTMLInputElement).value;
                const tM = (document.getElementById('titleMapping') as HTMLInputElement).value;
                const tId = (document.getElementById('teamId') as HTMLInputElement).value;


                let convertedTId = 0;

                if (tId.length) {
                    convertedTId = Number(tId);

                    if (isNaN(convertedTId)) {
                        return swal.showValidationMessage(
                            `Team ID must be a number`);
                    }

                    if (teamId < 0 || convertedTId > 51) {
                        return swal.showValidationMessage(
                            `Team ID must be between 0 and 51`);
                    }
                }

                try {
                    await this.apiClient.getUseCaseFactory().PoolWorld().UpdatePoolWorld(pWid, tM, convertedTId).toPromise();
                    await this.updateSuccess();
                }
                
                catch (e) {
                    swal.showValidationMessage(
                        `An error occured. Please try again or contact support.`);
                }
            }
        });
    }
}
