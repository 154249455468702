import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentEventTracking extends AbstractBaseSegment {

    loadEventsDashboard() {
        this.track({
            event: actions.LOAD_EVENTS_DASHBOARD,
        });
    }

    loadEvent() {
        this.identify();
        this.track({
            event: actions.NAVIGATE_TO_EVENT
        }, true);
    }

    createEventButtonClicked() {
        this.track({
            event: actions.CREATE_EVENT_STARTED
        });
    }

    /**
     * Event Type
     */

    fullEventButtonClicked() {
        this.track({
            event: actions.FULL_EVENT_SELECTED
        });
    }

    cioEventButtonClicked() {
        this.track({
            event: actions.CIO_EVENT_SELECTED
        });
    }

    /**
     * Event Created
     */

    createFullEvent(eventDetails: object) {
        this.track({
            event: actions.CREATE_FULL_EVENT_SUBMITTED,
            properties: {
                ...eventDetails
            }
        });
    }

    createCIOEvent(eventDetails: object) {
        this.track({
            event: actions.CREATE_CIO_EVENT_SUBMITTED,
            properties: {
                ...eventDetails
            }
        });
    }

    /**
     * Pql CIO
     */

    pqlGetCioEventButtonClicked() {
        this.track({
            event: actions.PQL_GET_CIO_EVENT_SELECTED
        });
    }

    pqlGetCioEventSubmitted(pqlFields: object) {
        this.track({
            event: actions.PQL_GET_CIO_EVENT_SUBMITTED,
            properties: {
                ...pqlFields
            }
        });
    }

    pqlGetCioEventButtonDismissed() {
        this.track({
            event: actions.PQL_GET_CIO_EVENT_DISMISSED
        });
    }

    /**
     * Copy Event or Start From Scratch
     */

    startFromScratchSelected() {
        this.track({
            event: actions.START_FROM_SCRATCH_EVENT
        }, true);
    }

    cancelCopyEvent() {
        this.track({
            event: actions.COPIED_EVENT_DISMISSED,
        }, true);
    }

    copyEvent(eventDetailsToCopy: object) {
        this.track({
            event: actions.COPIED_EVENT_SUBMITTED,
            properties: {
                ...eventDetailsToCopy
            }
        });
    }

    /**
     * Quick Create Access Types
     */

    quickCreateAccessTypesInit() {
        this.track({
            event: actions.QUICK_CREATE_ACCESS_TYPES_STARTED,
        }, true);
    }

    quickCreateAccessTypesSaveCreateAnother(accessTypeDetails: object) {
        this.track({
            event: actions.QUICK_CREATE_ACCESS_TYPES_SAVE_CREATE_ANOTHER,
            properties: {
                ...accessTypeDetails,
            }
        }, true);
    }

    quickCreateAccessTypesSaveDone(accessTypeDetails: object) {
        this.track({
            event: actions.QUICK_CREATE_ACCESS_TYPES_SAVE_DONE,
            properties: {
                ...accessTypeDetails
            }
        }, true);
    }

    quickCreateAccessTypesClose() {
        this.track({
            event: actions.QUICK_CREATE_ENTERED_CANVAS_MODAL_DISMISSED
        }, true);
    }

    /**
     * Quick Create Canvas
     */

    quickCreateEnteredCanvas() {
        this.track({
            event: actions.QUICK_CREATE_ENTERED_CANVAS
        }, true);
    }

    quickCreateCanvasClose() {
        this.track({
            event: actions.QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED,
        }, true);
    }

}
