import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { SalesforceEventIntegrationsService } from '../eventIntegrations.service';
import { MappingService } from '../mapping.service';

@Component({
    selector: 'status-mappings',
    template: require('./statusMappings.html'),
    styles: [require('./statusMappings.scss')],

})

export class StatusMappingsComponent implements OnInit, DoCheck {

    constructor(
        private _sfeis: SalesforceEventIntegrationsService,
        private _ms: MappingService,
    ) {
    }

    @Input() eventSettings;

    newContactRules: any[] = this._sfeis.eventSettings.fieldMappings.newContactRules;
    efStatusNames: Object[] = this._sfeis.eventSettings.fieldMappings.efStatusNames;
    intStatusNames: Object[] = this._sfeis.eventSettings.fieldMappings.intStatusNames;
    campaignMemberStatusMappings: Object[] = this._ms.campaignMemberStatusMappings;
    newCampaignMemberStatusMappings: Object[] = [];

    status = {int: ''};
    newContactRule = this._sfeis.newContactRule;
    loadingStatusMappings: boolean;

    ngOnInit() {
        this.loadingStatusMappings = true;

        this._sfeis.getSalesforceStatusNames()
            .subscribe((res) => {
                this.eventSettings.fieldMappings.intStatusNames = res['data']['attributes'];
            });

        this._sfeis.getStatusMappings(this.eventSettings.salesforceConfig.id)
            .then((res) => {
                this.efStatusNames = res['data'];
            })
            .then(() => {
                this.buildMappings();
                this.loadingStatusMappings = false;
            });
    }

    ngDoCheck() {
        this.efStatusNames = this._sfeis.eventSettings.fieldMappings.efStatusNames;
        this.campaignMemberStatusMappings = this._ms.campaignMemberStatusMappings;
        this._sfeis.newContactRule = this.newContactRule;
    }

    buildMappings() {
        this._ms.campaignMemberStatusMappings = [];
        for (let i = 0; i < this.efStatusNames.length; i++) {
            if (this.efStatusNames[i]['attributes'].statusId.slug !== 'recycled') {
                this._ms.campaignMemberStatusMappings.push(
                    {
                        integrationSettingId: this.efStatusNames[i]['attributes']['integrationSettingId'],
                        integrationMappingId: this.efStatusNames[i]['id'],
                        ef: {
                            attributes: {
                                name: this.efStatusNames[i]['attributes']['statusId']['name'],
                                slug: this.efStatusNames[i]['attributes']['statusId']['slug'],
                                integrationSettingType: this.efStatusNames[i]['attributes']['integrationSettingType']['id'],
                                integrationStatusValue: this.efStatusNames[i]['attributes']['integrationStatusValue'],
                                mappingType: this.efStatusNames[i]['attributes']['statusMappingType']['id'],
                            },
                        },
                        int: this.efStatusNames[i]['attributes']['integrationStatusValue'],
                    },
                );
            }
        }
    }

    setIntegrationStatusMappingValue(row) {
        this._ms.setIntegrationStatusMappingValue(row.integrationMappingId, row.int);
    }

    setSalesforceNewContactRuleForEvent(rule) {
        this._ms.setSalesforceNewContactRuleForEvent(rule.slug);
    }

}
