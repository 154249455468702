import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { WaitListService } from './waitList.service';
import { AlertService } from '../../eventFarmAlert.service';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';

@Component({
    selector: 'wait-list',
    template: require('./waitListEntry.html'),
    styles: [require('./waitListEntry.scss')],
})

export class WaitListEntryComponent implements OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private waitlistService: WaitListService,
        private alertService: AlertService,
        private store: Store<fromRoot.AppState>,
        private routeGenerator: RouteGeneratorService,
        private router: Router,

    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe(res => this.checkAccess());

    }

    private ticketTypeCount: Observable <number>;
    private eventStore$: Observable<any> = this.store.select('event');
    private eventUpdate$: Subscription;
    private shouldCheckAccess: boolean = true;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    checkAccess() {

        if (this.shouldCheckAccess) {

            this.shouldCheckAccess = false;

            const eventId = this.eventFarmService.currentEvent.id;
            const poolId = this.eventFarmService.currentTeam.id;
            this.eventFarmService.fetchEventDetails(eventId, ['TicketTypes'])
                .subscribe(res => {
                    this.ticketTypeCount = of(res.data.relationships.ticketTypes.length);
                    this.ticketTypeCount.subscribe(res => {
                        if (res < 1 && this.eventFarmService.currentEvent.isTypeFullPlatform) {
                            this.alertService.guestList().noTicketTypesSet('building your waitlist')
                            .then((res) => {
                                this.router.navigateByUrl(this.routeGenerator.url('events.event-access', { poolId, eventId }));
                            });
                        }
                    });
                });

        }

    }

    ngOnDestroy() {
        this.eventUpdate$.unsubscribe();
    }

    goToAddGuest() {
        this.waitlistService.addToWaitlist(this.eventFarmService.currentEvent.id, this.eventFarmService.currentEvent.stacks);
    }

}
