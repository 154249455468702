import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { CreateEventService } from '../create-event.service';
import { EventFarmService } from '../../../eventFarm.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';

@Injectable()
export class CanCreateEventGuard implements CanActivate {

    constructor(
        private createEventService: CreateEventService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private route: ActivatedRoute,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentState = route.data.state;
        const fullEventsRemaining = this.createEventService.fullEventsRemaining;
        const cioEventsRemaining = this.createEventService.cioEventsRemaining;

        if (currentState === 'create-cio-event') {
            if (cioEventsRemaining < 0 || cioEventsRemaining > 0) {
                return true;
            } else {
                this.router.navigateByUrl(this.routeGenerator.url('pools.create-event.free-cio', { poolId: this.createEventService.eventFarmService.currentTeam.id }));
                return false;
            }
        }

        if (currentState === 'create-full-event') {
            if (fullEventsRemaining < 0 || fullEventsRemaining > 0) {
                return true;
            } else {
                this.router.navigateByUrl(this.routeGenerator.url('pools.create-event.free-cio', { poolId: this.createEventService.eventFarmService.currentTeam.id }));
                return false;
            }
        }

    }
}
