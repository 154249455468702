import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { OldWebPresenceComponent } from './OldWebPresence.component';
import { OldWebPresenceRoutingModule } from './OldWebPresence-routing.module';
import { LegacyUIModule } from '../../LegacyUI/legacy-UI.module';
import 'froala-editor/js/froala_editor.pkgd.min.js';
@NgModule({
    bootstrap: [OldWebPresenceComponent],
    declarations: [
        OldWebPresenceComponent,
    ],
    entryComponents: [
        OldWebPresenceComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        LegacyUIModule,
        UtilitiesModule,
        BreadcrumbsModule,
        OldWebPresenceRoutingModule,
    ],
    providers: [
    ],
})

export class OldWebPresenceModule {
}