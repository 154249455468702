import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../../eventFarmAlert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EventRevenueService } from './event-revenue.service';
import { PaymentsComponent } from './Payments/payments.component';
import * as fromStore from '../../../store';
import { Store } from '@ngrx/store';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'event-revenue',
  template: require('./event-revenue.html'),
  styles: [require('./event-revenue.scss')]
})

export class EventRevenueComponent implements OnInit, OnDestroy {

  constructor(
    private alertService: AlertService,
    private message: NzMessageService,
    private drawerService: NzDrawerService,
    private revenueService: EventRevenueService,
    private store: Store<fromStore.AppState>,

  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
      this.init();
    });
  }

  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;

  ngOnInit() {
    this.init();
  }

  private init() {
    this.revenueService.getPaymentTotals();
    this.revenueService.getWithdrawalsForEvent();
  }

  ngOnDestroy() {
    this.revenueService.clearList();
    this.eventUpdate$.unsubscribe();
  }

  get shouldShowWithdraw(): boolean {
    const validAltPaymentFiles = [
      "eventfarm.constants.php", 
      "constants_development.php", 
      "development.constants.php",
      "eventfarm.stripe.production.constants.php",
      "eventfarm.stripe.prelive.constants.php",
      "eventfarm_development.php"
    ];

    // if shield
    if (this.revenueService.currentEvent.isShield
       && this.revenueService.currentEvent.poolPaymentGatewayId === null
       && this.revenueService.currentTotals$.value.revenue.total.revenue > 0) {
        return true;

        // old ppp
    } else if (!this.revenueService.currentEvent.isShield 
      && this.revenueService.currentTotals$.value.revenue.total.revenue > 0
      && validAltPaymentFiles.includes(this.revenueService.currentEvent.altPaymentFile)) {
        return true;
    }

    return false;
  }


  async goToWithdraw() {
    try {
      await this.alertService.revenue().withdrawFunds(this.revenueService.currentEventId, this.revenueService.availableRev - this.revenueService.sumOfWithdrawals, this.revenueService );
    } catch (e) {
    }
  }

  goToPayments(): void {

    const drawerRef = this.drawerService.create<PaymentsComponent, { }, string>({
      nzTitle: 'Payments',
      nzContent: PaymentsComponent,
      nzWidth: '50%'
    });

    drawerRef.afterOpen.subscribe(() => {

    });

    drawerRef.afterClose.subscribe(async data => {
      await this.revenueService.getPaymentTotals();
    });

  }
}
