import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Router } from '@angular/router';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { filter, take, tap, shareReplay } from 'rxjs/operators';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { PoolPaymentGateway } from '../../../../ApiClient/Models/PoolPaymentGateway/poolPaymentGateway';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'payments-not-configured',
  template: require('./payments-not-configured.html'),
  styles: [require('./payments-not-configured.scss')],
})

export class PaymentsNotConfiguredComponent implements OnInit, OnDestroy {

  constructor(
    private eventFarmService: EventFarmService, 
    private router: Router, 
    private routeGenerator: RouteGeneratorService, 
    private store: Store<fromRoot.AppState>,
    private apiClient: EventFarmAPIClient,
    private message: NzMessageService
   ) {
  }
  
  private userContactType$ = this.store.select(fromRoot.getPoolContactType).pipe(shareReplay());
  private isLoading: boolean = false;
  private gateways: PoolPaymentGateway[] = [];
  private userContactTypeSub: Subscription;
  private isTeamManager: boolean = false;
  private selectedGatewayId: string = null; 

  async ngOnInit() {
    this.isLoading = true;
    this.userContactTypeSub = this.userContactType$.subscribe(async (userContactType) => {
      if (userContactType && userContactType.isFull) {
        this.isTeamManager = true;
        const ppg = await this.apiClient.getUseCaseFactory().PoolPaymentGateway().ListPaymentGatewaysForPool(
              this.eventFarmService.currentEvent.team.id
          ).toPromise();
          this.gateways = ppg.data.map(pg => PoolPaymentGateway.fromApiResponse(pg));
          if (this.gateways.length) {
            this.selectedGatewayId = this.gateways[0].id;
          }
          this.isLoading = false;
      } else {
        this.isLoading = false;
      }
  });
  }

  goToPaymentSettings() {
    const route = this.routeGenerator.url('pools.my-account.payment-settings', { poolId: this.eventFarmService.currentTeam.id });
    this.router.navigateByUrl(route);
  }

  async goToEventPaymentSettings() {
    if (this.selectedGatewayId) {
      try {
        await this.apiClient.getUseCaseFactory().PoolPaymentGateway().SetPoolPaymentGatewayForEvent(this.selectedGatewayId, this.eventFarmService.currentEvent.id).toPromise();
        this.message.success('Success, you may now accept payments for your event.');
        this.store.dispatch(new fromRoot.FetchCurrentEvent());
      } catch (e) {
        this.message.error('Issue setting processor for event. Please contact support.');
      }
    }
  }

  ngOnDestroy(): void {
      if (this.userContactTypeSub) {
        this.userContactTypeSub.unsubscribe();
      }
  }
}
