import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-date',
  template: `
    <div
      class="dynamic-field form-group form-date"
      [formGroup]="group">
      <label>{{ config.label }}</label>
      <input type="text"
        class="form-control"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name" />
    </div>`
})
export class FormDateComponent implements Field {
  config;
  group: FormGroup;
}
