import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { get } from 'lodash';

@Injectable()

export class GuestResponsesService {
    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
    ) {}

    updateQuestionResponse(invitationId, question) {
        return this.apiClient.getUseCaseFactory().Invitation().SetQuestionResponse(
            invitationId,
            question.name,
            question.answers,
            question.text
        );
    }

    getAllQuestions() {
        return this.apiClient.getUseCaseFactory()
            .Event()
            .GetEvent(
                this.eventFarmService.currentEvent.id,
                ['QuestionsAndAnswers', 'QuestionContext', 'AllQuestions'],
            )
            .toPromise()
            .then((res) =>{
                // Let's filter out questions that have been deleted
                const questions = get(res, 'data.relationships.questions');
                return questions ? questions.filter(question => question.attributes.isDeleted !== true) : [];
                }).catch((err) => {
                throw new Error('bad response from the API');
            });
    }
}
