import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';


@Injectable()

export class StatisticsService {
    private totals: Object = {};
    private totalsList = [];
    private lastActionObj: Object = {};
    private lastActionCounts = [];
    private rawOpenActionData = [];
    private openActionsForLineChart = [];

    private haveTotals: boolean = false;
    private haveLastActionCounts: boolean = false;
    private haveOpenActionsForLastMonth: boolean = false;
    private error: boolean = false;

    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient
    ) { }

    public getStats() {
        return this.apiClient.getUseCaseFactory().EmailMessage()
        .GetEmailMessageStatsForEvent(this.eventFarmService.currentEvent.id).toPromise()
        .then((res) => {
            this.statsAsArray(res.data, 'totals');
        })
        .catch((err) => this.error = true);
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    public getLastActionCounts() {
        return this.apiClient
            .getUseCaseFactory()
            .Invitation()
            .GetInvitationLastActionCountsForEvent(this.eventFarmService.currentEvent.id).toPromise()
            .then((res) => {
                this.statsAsArray(res.data, 'lastAction');
            })
            .catch((err) => this.error = true);
    }

    public getOpenActionsForLastMonth() {
        this.apiClient
            .getUseCaseFactory()
            .EmailNotification()
            .GetOpenActionsForEventOverLastMonth(this.eventFarmService.currentEvent.id).toPromise()
            .then((res) => {
                this.formatOpenActionForLineChart(res.data.counts);
            })
            .catch((err) => this.error = true);
    }

    private formatOpenActionForLineChart(data) {
        this.getRawOpenActionData();
        this.mergeOpenActionDataWithRawArray(data);
        let series = this.getOpenActionLineChartSeriesArray();
        this.openActionsForLineChart = [{
            'name': 'Open Actions',
            'series': series
        }];

        if (series.length > 0) {
            this.haveOpenActionsForLastMonth = true;
        }
    }

    private getRawOpenActionData() {
        let rawOpenActionArray = [];
        for (let days = 31; days >= 0; days--) {
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - days);
            let arrayKey = this.getFormattedDate(currentDate);
            rawOpenActionArray[arrayKey] = 0;
        }
        this.rawOpenActionData = rawOpenActionArray;
    }

    private mergeOpenActionDataWithRawArray(data) {
        data.forEach((countForDay) => {
            this.rawOpenActionData[countForDay._id] = countForDay.count;
        });
    }

    private getOpenActionLineChartSeriesArray() {
        let series = [];
        for (let key in this.rawOpenActionData) {
            series.push({
                'name': key,
                'value': this.rawOpenActionData[key]
            });
        }
        return series;
    }

    private getFormattedDate(dateToFormat) {
        let dayOfMonth =  dateToFormat.getDate().toString();
        let month = (dateToFormat.getMonth() + 1).toString();

        month = this.addPaddedZeroToMonthOrDay(month);
        dayOfMonth = this.addPaddedZeroToMonthOrDay(dayOfMonth);

        return month
        + '/' + dayOfMonth + '/'
        + (dateToFormat.getFullYear().toString());
    }

    private addPaddedZeroToMonthOrDay(val) {
        if (val.length === 1) {
            val = '0' + val;
        }
        return val;
    }

    private statsAsArray(data, statType) {
        if (statType === 'totals') {
            this.error = false;
            this.totals = data.totals;
            this.createList();
        } else if (statType === 'lastAction') {
            this.error = false;
            this.lastActionObj = data;
            this.createLastActionArray();
        }
    }

    private createList() {
        this.totalsList = [];
        this.haveTotals = false;

        for (let total in this.totals) {
            const count = this.totals[total];
            if (count > 0) {
                this.haveTotals = true;
            }

            this.totalsList.push({
                name: this.niceName(total),
                value: count,
            });
        }
    }

    private createLastActionArray() {
        this.lastActionCounts = [];
        this.haveLastActionCounts = false;

        for (let stat in this.lastActionObj) {
            if (this.isNotExcludedLastAction(stat)) {
                const count = this.lastActionObj[stat];
                if (count > 0) {
                    this.haveLastActionCounts = true;
                }

                this.lastActionCounts.push({
                    name: this.niceName(stat),
                    value: count
                });
            }
        }
    }

    private isNotExcludedLastAction(action) {
        return (action !== 'total' && action !== 'none' && action !== 'rs');
    }

    private niceName(total) {
        const unCamel = total.replace(/([A-Z])/g, ' $1');
        const Capitalized = unCamel[0].toLocaleUpperCase() + unCamel.slice(1);

        return Capitalized;
    }
}
