import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter  } from '@angular/core';
import { Router } from '@angular/router';
import { EventFarmService } from '../../../eventFarm.service';
import { EventsListService } from '../eventsList.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { SessionStorageService } from '../../../../_services/storage/session-storage.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { getEventUrl } from '../../../environment';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { AlertService } from '../../../eventFarmAlert.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';

@Component({
    selector: 'list-view-actions',
    template: require('./listViewActions.html'),
    styles: [require('../events-list.scss'), require('./listViewActions.scss')],
    encapsulation: ViewEncapsulation.None
})

export class ListViewActionsComponent {
    @Input() event: EfEvent;
    @Input() isFullEvent: boolean;
    @Output() eventArchivedEvent: EventEmitter<any> = new EventEmitter();

    readonly viewEventSVG = require('../assets/view-event');
    readonly guestListSVG = require('../../../../../mars/components/icons/flat/list');

    constructor(
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private sessionStorageService: SessionStorageService,
        private eventFarmService: EventFarmService,
        private eventsDashboardService: EventsListService,
        private segmentService: SegmentService,
        private iconService: IconService,
        private store: Store<fromRoot.AppState>,
        private alertService: AlertService,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService

    ) {
        this.userContactType$ = this.store.select(fromRoot.getPoolContactType);
    }

    private loadingEventId: string = '';
    private userContactType$: Observable<any>;


    eventUrl(event: EfEvent) {
        return getEventUrl(event);
    }

    goToEventGuestlist(event: EfEvent): void {
        this.loadingEventId = event.id;
        this.eventsDashboardService.goToEventGuestlist(event);
        this.segmentService.segmentEventTracking().loadEvent();
    }

    async archiveEvent(event: EfEvent) {
        this.alertService.general().confirmArchiveEvent().then(async (res) => {
          if (res.value) {
            try {
                await this.apiClient.getUseCaseFactory().Event().ArchiveEvent(event.id).toPromise();
                this.message.success('Event archived');
                this.eventArchivedEvent.emit();
            } catch (e) {
                this.message.error('Error archiving event');
            }
          } 
        });
      }
}
