import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountInfoService } from './account-info.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'my-account',
    template: require('./my-account.html'),
})

export class MyAccountComponent implements OnDestroy {
    constructor(
        private accountInfoService: AccountInfoService,
        private routeGenerator: RouteGeneratorService,
        private router: Router,
        private store: Store<fromStore.AppState>,
        private route: ActivatedRoute
    ) {
        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.checkForContract();
        });
    }

    private teamStore$ = this.store.select(fromStore.getCurrentTeam).pipe(shareReplay());
    private teamUpdate$: Subscription;

    ngOnInit() {
    }

    async checkForContract() {
        const contractBulkResponse = this.route.snapshot.data['contract'];
        this.accountInfoService.broadcastDataSetsForTeam(contractBulkResponse);
    }

    ngOnDestroy() {
        this.teamUpdate$.unsubscribe();
    }
}
