import { AbstractModel } from '../abstract-model';
import { FormattedDateTime } from '../formatted-datetime';

export class Transfer extends AbstractModel {
    constructor(id?: string) {
        super(id);
    }

    private _toFirstName: string;
    private _toLastName: string;
    private _toEmail: string;
    private _fromFirstName: string;
    private _fromLastName: string;
    private _fromEmail: string;
    private _quantity: number;
    private _stackName: string;
    private _code: string;
    private _confirmedAtFormatted?: FormattedDateTime;

    public static fromApiResponse(data) {
        const transfer = new this(data.id);
        transfer._toFirstName = data.attributes.firstName;
        transfer._toLastName = data.attributes.lastName;
        transfer._toEmail = data.attributes.email;
        transfer._fromFirstName = data.attributes.sender.userName.firstName;
        transfer._fromLastName = data.attributes.sender.userName.lastName;
        transfer._fromEmail = data.attributes.sender.email;
        transfer._confirmedAtFormatted = data.attributes.confirmedFormatted;
        transfer._quantity = data.attributes.transferQty;
        transfer._stackName = data.attributes.stackName;
        transfer._code = data.attributes.code;

        return transfer;
    }

    public get isPending() {
        return this._confirmedAtFormatted === null;
    }

    public get toName(): string {
        return `${this._toFirstName} ${this._toLastName} (${this._toEmail})`;
    }

    public get fromName(): string {
        return `${this._fromFirstName} ${this._fromLastName} (${this._fromEmail})`;
    }

    public get details(): string {
        return `${this._quantity} ${this._stackName} `;
    }

    public get status(): string {
        if (!this.isPending) {
            return `Completed @ ${this._confirmedAtFormatted.date.short} ${this._confirmedAtFormatted.time.short}`;
        } else {
            return `Pending`;
        }
    }

    public get quantity(): number {
        return this._quantity;
    }

    public get code(): string {
        return this._code;
    }

    public get stackName(): string {
        return this._stackName;
    }

    public get confirmedAtFormatted(): FormattedDateTime {
        return this._confirmedAtFormatted;
    }


}
