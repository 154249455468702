export class EventRoles implements IEventRoles {
    isAssistant: boolean;
    isCheckInStaff: boolean;
    isOrganizer: boolean;
    isReadOnly: boolean;
    isSupport: boolean;
    
    public constructor({
        isAssistant = false,
        isCheckInStaff = false,
        isOrganizer = false,
        isReadOnly = false,
        isSupport = false
    }) {
        this.isAssistant = isAssistant;
        this.isCheckInStaff = isCheckInStaff;
        this.isOrganizer = isOrganizer;
        this.isReadOnly = isReadOnly;
        this.isSupport = isSupport;
    }
    
    public get maxRoleString(): string {
        if (this.isOrganizer) {
            return 'Organizer';
        } else if (this.isAssistant) {
            return 'Assistant';
        } else if (this.isSupport) {
            return 'Support';
        } else if (this.isReadOnly) {
            return 'Read Only';
        }
        
        return 'Check In Staff';
    }

    public get maxRoleSlug(): string {
       return this.maxRoleString.toLowerCase().replace(' ', '-');
    }
}

export interface IEventRoles {
    isAssistant: boolean;
    isCheckInStaff: boolean;
    isOrganizer: boolean;
    isReadOnly: boolean;
    isSupport: boolean;
}
