import { Component } from '@angular/core';
import { AddressBookService } from './addressBook.service';
import { AddressBookUserService } from './User/addressBookUser.service';
import { AddressBookGroupService } from './Group/addressBookGroup.service';
import { FormControl } from '@angular/forms';
import { EventFarmService } from '../../eventFarm.service';
import { Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'address-book-global-search',
    template: require('./address-book-global-search.html'),
    styles: [require('./address-book-global-search.scss')],
})

export class AddressBookGlobalSearchComponent {

    constructor(
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private addressBookGroupService: AddressBookGroupService,
        private eventFarmService: EventFarmService,
    ) {
        this.usersChange$ = addressBookUserService.users$.subscribe(
            (val) => {
                if (!this.addressBookService.selectedGroup && this.addressBookService.globalSearchTerm === '') {
                    this.searchTerm.setValue(this.addressBookService.globalSearchTerm);
                };
            });
    }

    readonly groupSearchFilter = require('./assets/group-search-filter');
    readonly userSearchFilter = require('./assets/user-search-filter');

    searchMode: 'user'|'group';
    private searchTerm: FormControl = new FormControl();
    usersChange$: Subscription;

    ngOnInit() {
        this.searchMode = 'user';

        this.searchTerm.valueChanges
            .pipe(debounceTime(400),
            distinctUntilChanged())
            .subscribe(term => this.addressBookSearch(String(term)));
    }

    termChange(event: any) {
        if (this.searchMode === 'user') {
            this.addressBookService.isLoadingContacts = true;
        } else {
            this.addressBookGroupService.isLoadingGroups = true;
        }
    }

    addressBookSearch(term: string): void {
        term = term.trim();
        this.addressBookService.globalSearchTerm = term;
        if (this.searchMode === 'user') {
            this.addressBookUserSearch(this.addressBookService.globalSearchTerm);
        } else {
            this.addressBookGroupSearch(this.addressBookService.globalSearchTerm);
        }
    }

    addressBookUserSearch(term) {
        this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
        this.addressBookUserService.userListOptions.query = term;
        this.addressBookService.usersSelectedInList = [];
        this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
        if (this.addressBookService.selectedGroup) {
            this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
        } else {
            this.addressBookUserService.broadcastAllUsers();
        }
    }

    addressBookGroupSearch(term) {
        this.addressBookGroupService.isLoadingGroups = true;
        this.addressBookGroupService.groupListOptions.query = term;
        this.addressBookGroupService.groupListOptions.pagination.currentGroupsPage = 1;
        this.addressBookGroupService.broadcastGroupsForUser(this.eventFarmService.currentUser.id);
    }

    switchMode(mode: 'user'|'group') {
        this.searchMode = mode;
        this.addressBookUserService.userListOptions.query = '';
        this.addressBookGroupService.groupListOptions.query = '';
        if (this.searchTerm.value) {
            this.searchTerm.setValue('');
            this.addressBookService.globalSearchTerm = this.searchTerm.value;
            this.addressBookUserSearch(this.searchTerm.value);
            this.addressBookGroupSearch(this.searchTerm.value);
        }
    }

    getPlaceholderString(): string {
        if (this.searchMode === 'user') {
            return 'Search Contacts in Address Book';
        } else {
            return 'Search Groups in Address Book';
        }
    }

    ngOnDestroy() {
        this.usersChange$.unsubscribe();
    }
}
