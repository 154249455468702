import { AbstractModel } from '../abstract-model';

export class QueueCommandError extends AbstractModel {
    public static fromApiResponse(data) {
        const queueCommandError = new this(data.id);

        if (data.meta.isInitialized) {
            queueCommandError._title = data.attributes.title;
            queueCommandError._message = data.attributes.message;
            queueCommandError._statusCode = data.attributes.statusCode;
            queueCommandError._createdAt = data.attributes.createdAt;
        }

        return queueCommandError;
    }

    private _title: string;
    private _message: string;
    private _statusCode?: string;
    private _createdAt: number;
}
