import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { FormattedDateTime } from '../formatted-datetime';
import { TicketType } from '../Stack/ticket-type';

export interface UpdatableTrackOptions {
    name: string|null;
    description: string|null;
}


export class Track extends AbstractModel {
    private _name?: string;
    private _description?: string;
    private _sessions: EfEvent[];
    private _ticketTypes: TicketType[];

    constructor(id?: string) {
        super(id);
    }

    public static forTrackCreation(data): Track {
        const event = new this();

        try {
            const { name, description } = data;
            event._name = name;
            event._description = description;
        } catch (err) {
        }
        return event;

    }

    public static fromApiResponse(data) {
        const track = new this(data.id);

        track._name = data.attributes.name;
        track._description = data.attributes.description;

        track._sessions = [];

        if (data.relationships && data.relationships.eventTracks) {
            track._sessions = data.relationships.eventTracks.map(eventTrack => {
                return EfEvent.fromApiResponse(eventTrack.relationships.event);
            })
        }

        if (data.relationships && data.relationships.ticketTypeTracks) {
            track._ticketTypes = data.relationships.ticketTypeTracks.map(ticketTypeTrack => {
                return TicketType.fromApiResponse(ticketTypeTrack.relationships.tickettype);
            })
        }
        
        return track;
    }

    get sessionCountLabel(): string {
        if (!this._sessions.length) {
            return '';
        }
        return `${this._sessions.length} Session(s)`
    }

    get ticketTypeCountLabel(): string {
        if (!this._ticketTypes.length) {
            return '';
        }
        return `${this._ticketTypes.length} Access Type(s)`
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get sessions(): EfEvent[] {
        return this._sessions;
    }

    set sessions(value: EfEvent[]) {
        this._sessions = value;
    }

    get ticketTypes(): TicketType[] {
        return this._ticketTypes;
    }

    set ticketTypes(value: TicketType[]) {
        this._ticketTypes = value;
    }
}
