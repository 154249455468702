import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
export const getCurrentAdmin = (state) => state.admin;

export const getAdminCurrentTeam = createSelector(
    getCurrentAdmin,
    (admin) => {
        return admin.currentTeam;
    }
);

export const getAdminCurrentTeamData = createSelector(
    getAdminCurrentTeam,
    (currentTeam) => {
        return currentTeam.data
    }
);

export const getAdminCurrentTeamLoading = createSelector(
    getAdminCurrentTeam,
    (currentTeam) => {
        return currentTeam.loading
    }
);

export const getAdminIsArchivingCurrentTeam = createSelector(
    getCurrentAdmin,
    state => state.isArchivingCurrentTeam
);

export const getAdminCurrentTeamEvents = createSelector(
    getCurrentAdmin,
    (admin) => {
        return admin.currentTeamEvents;
    }
);

export const getAdminCurrentTeamEventsData = createSelector(
    getAdminCurrentTeamEvents,
    (events) => {
        return events.data;
    }
);
export const getAdminCurrentTeamEventsMeta = createSelector(
    getAdminCurrentTeamEvents,
    (events) => {
        return events.meta;
    }
);

export const getAdminCurrentTeamContacts = createSelector(
    getCurrentAdmin,
    (admin) => {
        return admin.currentTeamContacts;
    }
);

export const getAdminCurrentTeamContactsData = createSelector(
    getAdminCurrentTeamContacts,
    (contacts) => {
        return contacts.data;
    }
);

export const getAdminCurrentTeamFeatures = createSelector(
    getCurrentAdmin,
    (admin) => {
        return admin.currentTeamFeatures;
    }
);

export const getAdminFeaturesData = createSelector(
    getCurrentAdmin,
    (admin) => {
        const data = _.get(admin, 'features.data', [])
        return _.orderBy(data, ['name'],['asc']);
    }
);

export const getAdminCurrentTeamFeaturesData = createSelector(
    getCurrentAdmin,
    (admin) => {
        const data = _.get(admin, 'currentTeamFeatures.data', [])
        return _.orderBy(data, ['name'],['asc']);
    }
);

export const getAdminCurrentTeamAllotments = createSelector(
    getCurrentAdmin,
    (admin) => {
        return admin.currentTeamAllotments;
    }
);
export const getAdminCurrentTeamAllotmentsData = createSelector(
    getAdminCurrentTeamAllotments,
    (currentTeamAllotments) => {
        return currentTeamAllotments.data;
    }
);
export const getAdminCurrentTeamAllotmentsLoading = createSelector(
    getAdminCurrentTeamAllotments,
    (currentTeamAllotments) => {
        return currentTeamAllotments.loading;
    }
);
export const getAdminCurrentTeamAllotmentsEF = createSelector(
    getAdminCurrentTeamAllotmentsData,
    (allotments) => {
        return allotments.filter((a) => a.type === 'eventfarm');
    }
);
export const getAdminCurrentTeamAllotmentsEFMax = createSelector(
    getAdminCurrentTeamAllotmentsEF,
    (filteredEf) => {
        return  filteredEf.length ? filteredEf[0]['maxEvents'] : null;
    }
);
export const getAdminCurrentTeamAllotmentsCIO = createSelector(
    getAdminCurrentTeamAllotmentsData,
    (allotments) => {
        return allotments.filter((a) => a.type === 'cio');
    }
);

export const getAdminCurrentTeamAllotmentsCIOMax = createSelector(
    getAdminCurrentTeamAllotmentsCIO,
    (filteredCIO) => {
        return  filteredCIO.length ? filteredCIO[0]['maxEvents'] : null;
    }
);
export const getAdminCurrentVirbelaWorlds = createSelector(
    getCurrentAdmin,
    (admin) => {
        return  admin.virbelaWorlds;
    }
);

export const getAdminCurrentVirbelaWorldsData = createSelector(
    getCurrentAdmin,
    (admin) => {
        return  admin.virbelaWorlds.data;
    }
);
export const getAdminCurrentVirbelaWorldsLoading = createSelector(
    getCurrentAdmin,
    (admin) => {
        return  admin.virbelaWorlds.loading;
    }
);
export const getAdminCurrentTeamPoolWorlds = createSelector(
    getCurrentAdmin,
    (admin) => {
        return  admin.currentTeamPoolWorlds;
    }
);

export const getAdminCurrentTeamContract = createSelector(
    getCurrentAdmin,
    (admin) => {
        return  admin.currentTeamContract;
    }
);

export const getAdminCurrentTeamDomainMasks = createSelector(
    getCurrentAdmin,
    (admin) => {
        return  admin.currentTeamDomainMasks;
    }
);

