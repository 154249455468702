import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'cio-get-started-stepper-completed',
    styles: [require('./cio-get-started-stepper-completed.scss')],
    template: require('./cio-get-started-stepper-completed.html')
})

export class CioGetStartedStepperCompletedComponent implements OnInit{
    @Input() whatsNextContent?: string = '';

    ngOnInit() {
    }


}
