import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { WebPresenceComponent } from './web-presence.component';
import { WebPresenceRoutingModule } from './web-presence-routing.module';
import { WebPresenceService } from './web-presence.service';
import { WebPresenceMessagesComponent } from './Messages/web-presence-messages.component';
import { WebPresenceMessageConfigureComponent } from './Messages/Configure/web-presence-message-configure.component';
import { WebPresenceSettingsComponent } from './Settings/web-presence-settings.component';
import { WebPresenceThemeComponent } from './Settings/Theme/web-presence-theme.component';
import { WebPresenceTrackingComponent } from './Settings/Tracking/web-presence-tracking.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { ColorPickerComponent } from './Settings/Theme/ColorPicker/color-picker.component';
import { InvitationRevealModule } from '../EventInfo/InvitationReveal/invitation-reveal.module';
import { AllowDuplicatesModule } from '../EventInfo/AllowDuplicates/allow-duplicates.module';
import { EditableNamesModule } from '../EventInfo/EditableNames/editable-name.module';
import { GuestChangeResponseModule } from '../EventInfo/GuestCanChangeResponse/guest-change-response.module';
import { MinMaxSelectionModule } from '../EventInfo/MinMaxSelection/min-max-selection-module';
import { DistributionModule } from '../EventInfo/Distribution/distribution.module';
import { QRCodeModule } from '../EventInfo/QRCode/qr-code.module';
import { WaitlistToggleModule } from '../EventInfo/WaitlistToggle/waitlist-toggle.module';
import { WebPresenceNavOptionsComponent } from './Settings/OldPPP/web-presence-nav-options.component';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';
import { WebPresenceFormStyleComponent } from './Settings/OldPPP/web-presence-form-styles.component';
import { KioskModeBreakoutSessionsModule } from '../EventInfo/KioskModeBreakOutSessions/kiosk-mode-breakout-sessions.module';
import { KioskModeGuestPassesModule } from '../EventInfo/KioskModeGuestPasses/kiosk-mode-guest-passes.module';

@NgModule({
    bootstrap: [WebPresenceComponent],
    declarations: [
        WebPresenceComponent,
        WebPresenceMessagesComponent,
        WebPresenceMessageConfigureComponent,
        WebPresenceSettingsComponent,
        WebPresenceThemeComponent,
        WebPresenceTrackingComponent,
        ColorPickerComponent,
        WebPresenceNavOptionsComponent,
        WebPresenceFormStyleComponent
    ],
    entryComponents: [
        WebPresenceComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        WebPresenceRoutingModule,
        ColorSketchModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        InvitationRevealModule,
        AllowDuplicatesModule,
        EditableNamesModule,
        GuestChangeResponseModule,
        MinMaxSelectionModule,
        DistributionModule,
        QRCodeModule,
        WaitlistToggleModule,
        NzUtilitiesModule,
        KioskModeBreakoutSessionsModule,
        KioskModeGuestPassesModule
    ],
    providers: [
        WebPresenceService
    ],
})

export class WebPresenceModule {
}
