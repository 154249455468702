import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilitiesModule } from '../../../Utilities/utilities.module';
import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { ErrorModule } from '../../CoreUI/Error/error.module';
import { LoadingOverlayModule } from '../../CoreUI/LoadingOverlay/loading-overlay.module';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { LegacyUIModule } from '../../LegacyUI/legacy-UI.module';
import { StatisticsComponent } from './Statistics/statistics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EmailDesignCenterService } from '../EmailDesignCenter/email-design-center.service';
import { GuestMessagingSendOptionsService } from '../EmailDesignCenter/GuestMessaging/guest-messaging-send-options.service';
import { ViewMessagingComponent } from './SentMessages/view-messaging.component';
import { MessageListComponent } from './SentMessages/message-list.component';
import { ReportComponent } from './Report/report.component';
import { ReportService } from './Report/report.service';
import { ReportTypeBoxComponent } from './Report/ReportTypeBox/report-type-box.component';
import { ReportListComponent } from './Report/ReportList/report-list-component';
import {PaginationModule} from 'ngx-bootstrap/pagination'

import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';

@NgModule({
    bootstrap: [ReportsComponent],
    declarations: [
        ReportsComponent,
        StatisticsComponent,
        ViewMessagingComponent,
        MessageListComponent,
        ReportComponent,
        ReportTypeBoxComponent,
        ReportListComponent,
    ],
    entryComponents: [
        ReportsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        LoadingOverlayModule,
        UtilitiesModule,
        BreadcrumbsModule,
        LegacyUIModule,
        NgxChartsModule,
        ReportsRoutingModule,
        PaginationModule,
        NzUtilitiesModule,
    ],
    providers: [
        EmailDesignCenterService,
        GuestMessagingSendOptionsService,
        ReportService,
    ],
})

export class ReportsModule {
}
