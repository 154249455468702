import { take, shareReplay } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { EmailDesignCenterService } from '../../email-design-center.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { Store } from '@ngrx/store';
import * as eventActions from './../../../../store/actions/event';
import * as fromRoot from './../../../../store';


@Component({
    selector: 'option-item',
    template: require('./option-item.html'),
    styles: [require('./option-item.scss')],
})

export class OptionItemComponent {
    constructor(
        private emailDesignCenterService: EmailDesignCenterService,
        private router: Router,
        private currentActivatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private store: Store<fromRoot.AppState>,
    ) { }

    @Input() option;
    @Input() selectedEvent;
    @Output() deleteDesign = new EventEmitter<any>();
    @Output() duplicateDesign = new EventEmitter<any>();
    readonly generatingPreviewSVG = require('../../assets/generating-preview');
    readonly noPreviewSVG = require('../../assets/no-preview');

    private currentEvent: any = this.emailDesignCenterService.currentEvent;
    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());


    deleteOption(option) {
        this.alertService.emailDesignCenter().confirmRemoveEmailDesign(option.name).then((res) => {
            if (res.value) {
                this.deleteDesign.emit();
                this.emailDesignCenterService.removeEmailDesign(option).pipe(take(1)).subscribe(_ => {
                    this.store.dispatch(new eventActions.FetchDesigns());
                });
            }
        });
    }

    editOption(option) {
        this.router.navigate(['edit', option.emailDesignId], { relativeTo: this.currentActivatedRoute });
    }

    duplicateOption(option) {
        this.alertService.emailDesignCenter().confirmCreateDuplicateEmailDesign(option.name).then((res) => {
            if (res.value) {
                this.emailDesignCenterService.isLoadingNewOption = true;
                this.emailDesignCenterService.duplicateEmailDesign(option).subscribe((res) => {
                    this.duplicateDesign.emit(res);
                    this.router.navigate(['edit', res['data'].command.duplicateEmailDesignId], { relativeTo: this.currentActivatedRoute });
                    this.emailDesignCenterService.isLoadingNewOption = false;
                });
            }
        });
    }

    useTemplate(template) {
        this.router.navigate(['create', template.slug], { relativeTo: this.currentActivatedRoute });
    }

}
