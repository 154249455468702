import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentEFxTracking extends AbstractBaseSegment {

    /**
     * EFx disabled
     */

    efxLearnMoreClicked() {
        this.track({
            event: actions.EFX_LEARN_MORE,
        }, true);
    }

    efxRequested() {
        this.track({
            event: actions.EFX_REQUEST,
        }, true);
    }

    /**
     * EFx enabled
     *
     */

    efxAppLinkText(phoneNumber: string) {
        this.track({
            event: actions.EFX_TEXT_LINK,
            properties: {
                phoneNumber
            }
        }, true);
    }

    efxUpdatedAdminPasscode(adminCode: string) {
        this.track({
            event: actions.EFX_UPDATE_ADMIN_PASSCODE,
            properties: {
                adminCode
            }
        }, true);
    }

    /**
     * EFx Contact
     */

    efxSmsArrivalEnabled() {
        this.track({
            event: actions.EFX_SMS_ARRIVAL_ENABLED,
        }, true);
    }

    efxSmsArrivalDisabled() {
        this.track({
            event: actions.EFX_SMS_ARRIVAL_DISABLED,
        }, true);
    }

    efxSmsArrivalMessageSet(message: string) {
        this.track({
            event: actions.EFX_SMS_ARRIVAL_MESSAGE_SET,
            properties: {
                message
            }
        }, true);
    }

    efxNFCEnabled() {
        this.track({
            event: actions.EFX_NFC_ENABLED,
        }, true);
    }

    efxNFCDisabled() {
        this.track({
            event: actions.EFX_NFC_DISABLED,
        }, true);
    }

    /**
     * EFx modules
     */

    efxModuleRequested(module: string) {
        this.track({
            event: actions.EFX_MODULE_REQUESTED,
            properties: {
                module
            }
        }, true);
    }
}
