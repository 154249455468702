import { Component, ViewEncapsulation } from '@angular/core';
import { EventFarmService } from '../eventFarm.service';
import { ModalService } from '../CoreUI/Modal/modal.service';

@Component({
    selector: 'legacy-ui-container',
    template: require('./legacy-UI-modal-container.html'),
    styles: ['.legacy-ui-spacing { margin: -1rem }'],
    encapsulation: ViewEncapsulation.None
})

export class LegacyUIModalContainerComponent {
    constructor(
        private eventFarmService: EventFarmService,
        private modalService: ModalService,
    ) {
    }
    private modalUrl: string;
    private isIframe: boolean;

    ngOnInit() {
        this.modalUrl = this.modalService.data.url;
        this.isIframe = this.modalService.data.isIframe;
    }
}
