import { AbstractModel } from '../abstract-model';

export class EventText extends AbstractModel {
  private _key: string;
  private _value: any;

  constructor(id: string) {
    super(id);
  }

  public static fromApiResponse(data): EventText {
    const text = new this(data.id);
    try {
      text._key = data.attributes.value.key;
      text._value = data.attributes.value.value;
    } catch (err) {
    }
    return text;
  }

  get key(): string {
    return this._key;
  }

  get value(): any {
    return this._value;
  }

}
