import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Userv2TypeaheadComponent } from './userv2-typeahead.component';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [Userv2TypeaheadComponent],
    exports: [Userv2TypeaheadComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUtilitiesModule
    ],
})

export class Userv2TypeaheadModule {
}
