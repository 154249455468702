import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Team } from '../../../../ApiClient/Models/Pool/team';
import { EventFarmService } from '../../../eventFarm.service';
import * as fromStore from '../../../store';
import {NzSelectComponent} from 'ng-zorro-antd/select';

@Component({
    selector: 'event-date-filter',
    template: require('./eventDateFilter.html'),
    styles: [require('./eventDateFilter.scss'), require('../events-list.scss')],
    encapsulation: ViewEncapsulation.None
})
export class EventDateFilterComponent implements OnDestroy {

    readonly currentAndFutureSVG = require('../assets/current-and-future-icon');
    readonly allPastSVG = require('../assets/all-past-icon');
    readonly archivedSVG = require('../assets/archived-icon');
    readonly past3SVG = require('../assets/past-3-months-icon');
    readonly past6SVG = require('../assets/past-6-months-icon');

    public dateFilterOptions: any[] = [
        {'id': 1, 'name': 'Current & Future Events', 'text': `<p class="filter-date-option"><span class="filter-date-icon current-future"><span>` + this.currentAndFutureSVG + `</span></span>Current & Future Events</p>`},
        {'id': 2, 'name': 'All Past Events', 'text': `<p class="filter-date-option" ><span class="filter-date-icon all-past"><span>` + this.allPastSVG + `</span></span>All Past Events</p>`},
        {'id': 3, 'name': 'Past 3 months', 'text': `<p class="filter-date-option" ><span class="filter-date-icon three-month"><span>` + this.past3SVG + `</span></span>Past 3 Months</p>`},
        {'id': 4, 'name': 'Past 6 months', 'text': `<p class="filter-date-option" ><span class="filter-date-icon six-month"><span>` + this.past6SVG + `</span></span>Past 6 Months</p>`},
        {'id': 5, 'name': 'Archived Events', 'text': `<p class="filter-date-option"><span class="filter-date-icon archived"><span>` + this.archivedSVG + `</span></span>Archived Events</p>`}
    ];

    private currentPoolId = null;
    public selectedIndex = 0;

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>
    ) {

        this.teamUpdate$ = this.teamStore$.subscribe((val) => {
            this.selectedIndex = 0;
            const targetItem = this.dateFilterOptions.find(i => i.id === this.eventFarmService.eventListOptions.filterOptionId);
            if (targetItem != null) {
                this.selectedIndex = this.dateFilterOptions.indexOf(targetItem);
            }
            if (val.data && val.data.id && val.loaded && !isEqual(this.teamStoreData, val.data)) {
                this.teamStoreData = val.data;
                if (this.currentPoolId && this.currentPoolId !== val.data.id) {
                    this.currentPoolId = val.data.id;
                } else {
                    this.currentPoolId = val.data;
                }
            }

        });

    }

    @ViewChild('choseDate', {static: false}) public ngSelect: NzSelectComponent;

    private teamUpdate$: Subscription;
    private teamStore$: Observable<any> = this.store.select('team').pipe(shareReplay());
    private teamStoreData: Team;

    public selected(id: any): void {
        this.filterEventsByDate(id);
    }

    public filterEventsByDate(id: number) {

        if (id === 1) {
            Object.assign(this.eventFarmService.eventListOptions, {
                'attributesFilter': null,
                'attributesExcludeFilter': ['archived'],
                'sortBy': 'event-start',
                'sortDirection': 'ascending',
                'eventDateFilterType': 'current-future'
            });
        }
        if (id === 2) {
            Object.assign(this.eventFarmService.eventListOptions, {
                'attributesFilter': null,
                'attributesExcludeFilter': ['archived'],
                'sortBy': 'event-start',
                'sortDirection': 'descending',
                'eventDateFilterType': 'past-all'
            });
        }
        if (id === 3) {
            Object.assign(this.eventFarmService.eventListOptions, {
                'attributesFilter': null,
                'attributesExcludeFilter': ['archived'],
                'sortBy': 'event-start',
                'sortDirection': 'descending',
                'eventDateFilterType': 'past-3-months'
            });
        }
        if (id === 4) {
            Object.assign(this.eventFarmService.eventListOptions, {
                'attributesFilter': null,
                'attributesExcludeFilter': ['archived'],
                'sortBy': 'event-start',
                'sortDirection': 'descending',
                'eventDateFilterType': 'past-6-months'
            });
        }
        if (id === 5) {
            Object.assign(this.eventFarmService.eventListOptions, {
                'attributesFilter': ['archived'],
                'attributesExcludeFilter': null,
                'sortBy': 'name',
                'sortDirection': 'ascending',
                'eventDateFilterType': null
            });
        }
        if (id >= 1 && id <= 5) {
            this.eventFarmService.eventListOptions.filterOptionId = id;
        }

        this.eventFarmService.eventListOptions.pagination.currentEventsPage = 1;
        this.store.dispatch(new fromStore.FetchEventsForTeam());
    }

    ngOnDestroy() {
        this.teamUpdate$.unsubscribe();
        this.currentPoolId = null;
    }
}
