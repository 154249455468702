import { DomainMask } from './domain-mask';
import { EFHelper } from '../../../../Shared/helper';
export class Design {

    constructor() {
        this.domainMask = new DomainMask('');
        this.type = 'design';
        this.backgroundColor = 'fff';
        this.emailDesignId = '';
    }

    name: string;
    type: string;
    private _layout: 'blank'|'alt-email-layout' = 'blank';
    emailDesignId?: string;
    fromName: string;
    subject: string;
    content: string;
    backgroundColor: string;
    private _emailDesignTypeId: string;
    eventId: string;
    shouldUseEventSpecificLayout: boolean = true;
    fromEmail?: string;
    replyEmail?: string;
    private _ccEmailString?: string;
    private _bccEmailString?: string;
    private _ccEmails?: string[];
    private _bccEmails?: string[];
    domainMask?: DomainMask;
    thumbnailUrl: string;

    public static fromApi(data) {
        const design = new this();

        design.name = data.attributes.name;
        design.layout = data.attributes.layout.slug;
        design.emailDesignId = data.id;
        design.fromName = data.attributes.fromName;
        design.subject = data.attributes.subject;
        design.content = data.attributes.content;
        design.backgroundColor = data.attributes.backgroundColor;
        design.emailDesignTypeId = data.relationships.emailDesignType.id;
        design.fromEmail = data.attributes.fromEmail;
        design.replyEmail = data.attributes.replyEmail;
        design.ccEmailString = data.attributes.ccEmail;
        design.bccEmailString = data.attributes.bccEmail;

        if (data.relationships.domainMask !== undefined) {
            design.domainMask = DomainMask.fromApi(data.relationships.domainMask);
            design.domainMask.senderAddress = data.attributes.domainMaskEmail ? data.attributes.domainMaskEmail.replace(/\@(.*)/, '') : null;
            design.domainMask.mask.domain = data.attributes.domainMaskEmail.replace(/^(.*?)\@/, '');
        }

        return design;
    }

    get isTypeSendAMessage(): boolean {
        let type = 'SendAMessage';
        return this._emailDesignTypeId === type || this._emailDesignTypeId === type.toLowerCase();
    }

    get isInvitation(): boolean {
        const type = 'Invitation';
        return this._emailDesignTypeId === type || this._emailDesignTypeId === type.toLowerCase();
    }

    get isTypeSendGroupMessage(): boolean {
        let type = 'SendGroupMessage';
        return this._emailDesignTypeId === type || this._emailDesignTypeId === type.toLowerCase();
    }

    set emailDesignTypeId(value: string) {
        this._emailDesignTypeId = value.toLowerCase();
    }

    get emailDesignTypeId(): string {
        return this._emailDesignTypeId;
    }

    get layout(): 'blank'|'alt-email-layout' {
        return this._layout;
    }

    set layout(value: 'blank'|'alt-email-layout') {
        this._layout = value;
        this.shouldUseEventSpecificLayout = (value === 'alt-email-layout');
    }

    /**
     * @param {string|null} value
     */
    set ccEmailString(value) {
        if (EFHelper.isEmpty(value)) {
            this._ccEmails = null;
            this._ccEmailString = null;
        } else {
            this._ccEmailString = this.removeWhitespaceFromString(value);
            this._ccEmails = this.convertStringIntoArray(this._ccEmailString);
        }
    }

    set ccEmails(value: string[]) {
        this._ccEmails = value;
        this._ccEmailString = this.removeWhitespaceFromString(value.join());
    }

    /**
     * @param {string|null} value
     */
    set bccEmailString(value) {
        if (EFHelper.isEmpty(value)) {
            this._bccEmails = null;
            this._bccEmailString = null;
        } else {
            this._bccEmailString = this.removeWhitespaceFromString(value);
            this._bccEmails = this.convertStringIntoArray(this._bccEmailString);
        }
    }

    set bccEmails(value: string[]) {
        this._bccEmails = value;
        this._bccEmailString = this.removeWhitespaceFromString(value.join());
    }

    get ccEmailString(): string {
        return this._ccEmailString;
    }

    get ccEmails(): string[] {
        return this._ccEmails;
    }

    get bccEmailString(): string {
        return this._bccEmailString;
    }

    get bccEmails(): string[] {
        return this._bccEmails;
    }

    private removeWhitespaceFromString(value: string): string {
        return value.replace(' ', '');
    }

    private convertStringIntoArray(value: string): string[] {
        return this.removeWhitespaceFromString(value).split(',') || [];
    }
}
