import { AbstractModel } from '../abstract-model';
import { UserIdentifier } from './user-identifier';

export class UserName extends AbstractModel {
    static fromApiResponse(data): UserName {
        const userName = new this(data.id);

        try {
            userName._firstName = data.attributes.firstName;
            userName._lastName = data.attributes.lastName;
            userName._fullName = data.attributes.fullName;
        } catch (err) {
        }

        return userName;
    }

    public _fullName?: string;
    private _firstName?: string;
    private _lastName?: string;

    constructor(id) {
        super(id);
    }

    get fullName() {
        return this._fullName;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    getFullNameWithIdentifier(userIdentifier: UserIdentifier = null): string {
        if (this.isIdentifierSet(userIdentifier) && userIdentifier.isEmail) {
            return this._fullName + ' | ' + userIdentifier.identifier;
        } else {
            return this._fullName;
        }
    }

    private isIdentifierSet(userIdentifier: UserIdentifier) {
        return userIdentifier != null && userIdentifier.identifier !== '';
    }
}
