import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';

@Component({
    selector: 'reports',
    template: require('./reports.html'),
    styles: [require('./reports.scss')],
})

export class ReportsComponent implements OnDestroy {

  constructor(
    private eventFarmService: EventFarmService,
    private legacyUIService: LegacyUIService,
    private store: Store<fromRoot.AppState>,
  ) {
  }

  ngOnDestroy() {
  }
}
