import { BehaviorSubject } from 'rxjs';
import { PoolFeature } from '../../../../../ApiClient/Models/PoolFeature/poolFeature';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../../ApiClient/event-farm-api-client';
import { Team } from '../../../../../ApiClient/Models/Pool/team';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DomainMask } from '../../../../../ApiClient/Models/DomainMask/domainMask';
import _ = require('lodash');
import * as fromRoot from '../../../../store';
import {shareReplay} from 'rxjs/operators';
import {Store} from '@ngrx/store';

@Injectable()
export class TeamEmailMasksService {
  private _features: PoolFeature[];
  public currentTeamDomainMasks$ = this.store.select(fromRoot.getAdminCurrentTeamDomainMasks).pipe(shareReplay());

  teams$ = new BehaviorSubject<Team[]>([]);

  constructor(
      private apiClient: EventFarmAPIClient,
      private messageService: NzMessageService,
      private store: Store
  ) {
  }

  /*** DOMAIN MASKING ***/
  public fetchDomainMasksByPool(poolId: string) {
    return this.apiClient
        .getUseCaseFactory()
        .DomainMask()
        .GetAllDomainMasksByPool(poolId);

  }

  public addDomainMask(poolId: string, domain: string) {
    return this.apiClient
        .getUseCaseFactory()
        .DomainMask()
        .CreateDomainMask(poolId, domain);
  }

  public removeDomainMask(maskId: string) {
    return this.apiClient
        .getUseCaseFactory()
        .DomainMask()
        .RemoveDomainMask(maskId);

  }

}
