import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { ApiError, ErrorInterface } from '../../../../../ApiClient/Models/error';
import { EventFarmService } from '../../../../eventFarm.service';
import { GuestListService } from '../../guest-list.service';
import { EFHelper } from '../../../../../Shared/helper';
import { InvitationOptionsService } from '../../../../CoreUI/InvitationOptions/invitationOptions.service';
import { AddInviteCountService } from '../../../../CoreUI/InvitationOptions/addInviteCount.service';
import { StackSelectionService } from '../../../../CoreUI/InvitationOptions/stackSelection.service';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../../eventFarmAlert.service';
import { InvitationCreationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { merge, cloneDeep } from 'lodash';
import { RouteGeneratorService } from '../../../../../_services/routes/route-generator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../../../../../ApiClient/Models/User/user';
import { isValidEmail } from '../../../../../Utilities/validateEmail';

import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from '@khazii/ngx-intl-tel-input';

@Component({
    selector: 'add-guest',
    styles: [require('../sharedInvite.scss'), require('./addGuest.scss')],
    template: require('./addGuest.html'),

})

export class AddGuestComponent implements OnInit {

    addGuestForm: any;


    readonly inviteIndividualSVG = require('./../assets/InviteIndividual-Icon');
    private showGuestInfoWarning: boolean = true;
    private showAdditionalFields: boolean = false;
    private isGuestDetailsActive: boolean = true;
    private guestSalutation: string;
    private errors: ErrorInterface[];
    private invitation: Invitation;

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private guestListService: GuestListService,
        private message: NzMessageService,
        private invitationOptionsService: InvitationOptionsService,
        private addInviteCountService: AddInviteCountService,
        private stackSelectionService: StackSelectionService,
        private alertService: AlertService,

    ) {
        this.guestSalutation = '';
    }

    ngOnInit() {

        this.resetInvite();
        this.errors = [];
    }

    showFirstOrLastNameWarning(): boolean {
        return !this.invitation.firstName && !this.invitation.lastName;
    }

    shouldEmailBeDisabled(type: InvitationCreationTypeInterface): boolean {
        return type.shouldSendEmail && !this.invitation['email'];
    }

    private selectUser(user: User): void {
        const formattedUser = this.transformUser(user);
        this.invitation = merge(this.invitation, formattedUser);
    }

    get hasRequiredFields(): boolean {

        const userDetails = {
            email: this.invitation['email'],
            firstName: this.invitation['firstName'],
            lastName: this.invitation['lastName'],
        } as ICreateUser;

        if (this.hasFirstName(userDetails) && this.hasLastName(userDetails) && this.hasEmail(userDetails)) {
            return false;
        }

        if (!isValidEmail(userDetails.email)) {
            return false;
        }

        return true;
    }

    get invalidProxyEmail(): boolean {
        if (this.invitation.hasProxyEmail) {
            if (!isValidEmail(this.invitation.proxyEmail)) {
                return true;
            }
        }

        return false;
    }

    private transformUser(user: User): IFormattedUser {
        const fullName = user.name ? user.name.fullName : null;
        const fullNameWithEmail = User.getFullNameWithEmail(user.name, user.identifier);
        const userId = user.id;
        const firstName = user.name ? user.name.firstName : null;
        const lastName = user.name ? user.name.lastName : null;
        const email = user.identifier ? user.identifier.identifier : null;
        let position = null;
        let company = null;
        let externalId = null;

        user.attributes.forEach((attribute) => {
            if (attribute.isPosition) {
                position = attribute.value;
            }
            if (attribute.isCompany) {
                company = attribute.value;
            }
            if (attribute.isExternalId) {
                externalId = attribute.value;
            }

        });

        return {
            userId,
            fullName,
            fullNameWithEmail,
            firstName,
            lastName,
            email,
            position,
            company,
            externalId
        };
    }

    private hasFirstName(userDetails: ICreateUser): boolean {
        return EFHelper.isEmpty(userDetails.firstName);
    }

    private hasLastName(userDetails: ICreateUser): boolean {
        return EFHelper.isEmpty(userDetails.lastName);
    }

    private hasEmail(userDetails: ICreateUser): boolean {
        return EFHelper.isEmpty(userDetails.email);
    }

    // We want to send null instead of empty string
    private setStringsToNull() {
        const arr = ['externalId', 'company', 'telephone', 'position', 'other', 'proxyEmail'];
        arr.forEach(val => {
            if (this.invitation[val] === '') {
                this.invitation[val] = null;
            }
        });
    }

    save() {
        if (EFHelper.isEmpty(this.invitation.email) && this.stackSelectionService.invitationCreationTypeSlug === 'send-email') {
            this.alertService.guestList().noEmailForInvitation();
            return false;
        }
        this.setStringsToNull();
        const formattedInvitation = cloneDeep(this.invitation);
        if(this.invitation.telephone && this.invitation.telephone['e164Number']){
            formattedInvitation.telephone = this.invitation.telephone['e164Number'];
        }

        for (const key in formattedInvitation) {
            if (formattedInvitation.hasOwnProperty(key) && formattedInvitation[key] === '') {
                formattedInvitation[key] === null;
            }
        }

        formattedInvitation.isCheckedIn = this.invitationOptionsService.invitationOptions.isCheckedIn;
        formattedInvitation.checkInNotes = this.invitationOptionsService.invitationOptions.checkInNotes;
        formattedInvitation.invitationNotes = this.invitationOptionsService.invitationOptions.invitationNotes;

        if (!formattedInvitation.hasProxyEmail) {
            formattedInvitation.proxyEmail = null;
        }

        this.addInviteCountService.validateInviteCount(false);
        formattedInvitation.status = this.stackSelectionService.invitationStatus;

        if (this.eventFarmService.currentEvent.isTypeFullPlatform) {
            formattedInvitation.shouldSendInvitation = this.stackSelectionService.invitationCreationMethod.shouldSendEmail;
        }

        if (this.eventFarmService.currentEvent.isTypeCio && !this.invitationOptionsService.selectedStackId && this.eventFarmService.currentEvent.stacks) {
            this.invitationOptionsService.selectedStackId = this.eventFarmService.currentEvent.stacks[0].id;
        }

        const arrivalAlerts = this.invitationOptionsService.getArrivalAlertForCreation();


        this.guestListService.createInvitation(
            formattedInvitation,
            this.invitationOptionsService.selectedStackId,
            this.addInviteCountService.inviteCount,
        )
            .subscribe(
                (res) => {
                    const invitationId = res.data.command.invitationId;
                    if (this.invitationOptionsService.arrivalAlertOptions.shouldSendArrivalAlert) {
                        this.guestListService.setArrivalAlertAndEmailsPhoneNumbers(
                            invitationId,
                            arrivalAlerts.arrivalAlertEmails,
                            arrivalAlerts.shouldSendArrivalAlert,
                            arrivalAlerts.arrivalAlertMobileNumbers                        );
                    }

                    const eventId = this.eventFarmService.currentEvent.id;
                    const poolId = this.eventFarmService.currentTeam.id;
                    this.message.success('Invitation added successfully to your event');
                    this.eventFarmService.updateEventDetails(eventId);
                    this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId, eventId }));
                },
                (err) => {
                    this.errors = ApiError.fromApiErrorArray(err.error.errors);
                    this.errors = this.errors.map((error) => {
                        if (error.detail === 'stackId (string) is required') {
                            error.detail = 'Please select an invitation type';
                        }
                        return error;
                    });
                },
            );
    }

    resetInvite() {
        this.invitation = {
            eventId: this.eventFarmService.currentEvent.id,
            email: '',
            status: '',
            firstName: '',
            lastName: '',
            company: '',
            position: '',
            telephone: '',
            other: '',
            isCheckedIn: false,
            shouldSendInvitation: false,
            inviteSource: 'direct-invite',
            checkInNotes: '',
            invitationNotes: '',
            externalId: '',
            hasProxyEmail: false,
            proxyEmail: ''
        };
    }

}

interface ICreateUser {
    email?: string;
    firstName?: string;
    lastName?: string;
}

interface IFormattedUser {
    userId: string;
    fullName?: string;
    fullNameWithEmail?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
    position?: string;
    externalId?: string;
}

interface Invitation {
    eventId: string;
    isCheckedIn: boolean;
    inviteSource: string;
    status: string;
    email: string;
    firstName: string;
    lastName: string;
    company: string | null;
    telephone: string | null;
    position: string | null;
    other: string | null;
    externalId: string | null;
    checkInNotes: string;
    invitationNotes: string;
    shouldSendInvitation: boolean;
    hasProxyEmail: boolean;
    proxyEmail: string | null;
}
