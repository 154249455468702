import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuestSearchComponent } from './guestSearch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [GuestSearchComponent],
    exports: [GuestSearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
})

export class GuestSearchModule {
}