import { Injectable } from '@angular/core';

const storagePrefix = 'eventFarm.';

@Injectable()
export class SessionStorageService {
    public get<T>(key: string): T {
        const item = sessionStorage.getItem(storagePrefix + key);

        if (!item || item === 'null') {
            return null;
        }

        try {
            return JSON.parse(item);
        } catch (e) {
            return null;
        }
    }

    public set(key: string, value: any): boolean {
        if (value === undefined) {
            value = null;
        } else {
            value = JSON.stringify(value);
        }

        try {
            sessionStorage.setItem(storagePrefix + key, value);
        } catch (e) {
            return false;
        }
    }

    public remove(...keys: string[]): boolean {
        let result = true;
        keys.forEach((key: string) => {
            try {
                sessionStorage.removeItem(storagePrefix + key);
            } catch (e) {
                result = false;
            }
        });
        return result;
    }
}
