import {AbstractControl} from '@angular/forms';

export const validateEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email: string): boolean {
    return validateEmailRegex.test(email);
}

// Used for Reactive Forms
export function ValidateEmail(control: AbstractControl) {
        if (control && control.value) {
            const isValid = isValidEmail(control.value.trim());
            return !isValid ? {validateEmail: true} : null;
        } else {
            return null;
        }
}
