import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Subject, BehaviorSubject, Observable, from ,  Subscription } from 'rxjs';
import { EventFarmService } from '../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { AlertService } from '../../eventFarmAlert.service';
import { EventTheme } from '../../../ApiClient/Models/Event/eventTheme';
import { EventMessageTypeInterface, Event, ThemeColorTypeInterface, TrackingScriptTypeInterface, EventMessageSlugTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
import { cloneDeep } from 'lodash';
import decodeHTML from '../../../Utilities/decode.html';
import * as _ from 'lodash';
@Injectable()
export class WebPresenceService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private store: Store<fromStore.AppState>,
        private segmentService: SegmentService,
        private alertService: AlertService,
    ) {
    }

    private savedThemeColors: ThemeColorTypeInterface[];
    public currentEventThemeColors: ThemeColorTypeInterface[];
    public oldPPPColors: ThemeColorTypeInterface[]|null;
    public filteredEventThemeColors: ThemeColorTypeInterface[];
    public currentEventTrackingScripts: TrackingScriptTypeInterface[];
    public currentEventWPMessages: EventMessageTypeInterface[];
    public currentMessageType: EventMessageTypeInterface;
    public messageSlugs: EventMessageSlugTypeInterface[];
    public eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
    public currentEditor;

    private frozenFilteredEventThemeColors: ThemeColorTypeInterface[];

    public isEditingMessage: boolean;

    public displayMessageOptions: object = this.froalaOptions();

    get isShield(): boolean {
        return this.eventFarmService.currentEvent.isShield;
    }

    get currentPageName(): string {
        return this.eventFarmService.currentEvent.isShield ? 'Registration Settings' : 'Registration Form';
    }

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    get currentEventTime(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.startTime.medium : 'Wednesday, May 13, 2020 12:15 PM';
    }

    private get eventId(): string {
        return this.eventFarmService.currentEvent.id;
    }
    public async openPreviewDisplayMessageModal(flow?: 'public' | 'invite') {
        const {modalPaths, id} = this.eventFarmService.currentEvent;
        const  pathRoot = modalPaths.root;
        const previewRoot = pathRoot + '/preview/' + id;
        await this.alertService.sitePagePreview().view(pathRoot, previewRoot, this.currentMessageType.slug);
    }

    get currentValueToShow(): string {
        if (this.currentMessageType.value) {
          return this.currentMessageType.value;
        } else if (this.currentMessageType.defaultValue) {
            return `${decodeHTML(this.currentMessageType.defaultValue)}`;
        } else {
          return `[BLANK - NOTHING TO DISPLAY]`;
        }
    }

    private guestNameSlug = {
        description: null,
        isEventDescription: false,
        isEventEmail: false,
        isEventEndDate: false,
        isEventEndDateLong: false,
        isEventEndDateShort: false,
        isEventEndDateTime: false,
        isEventEndDateTimeLong: false,
        isEventEndDateTimeShort: false,
        isEventEndTime: false,
        isEventEndTimeLong: false,
        isEventEndTimeShort: false,
        isEventFacebook: false,
        isEventInstagram: false,
        isEventLocationAddress: false,
        isEventLocationDetails: false,
        isEventLocationName: false,
        isEventName: false,
        isEventPublicURL: false,
        isEventStartDate: false,
        isEventStartDateLong: false,
        isEventStartDateShort: false,
        isEventStartDateTime: false,
        isEventStartDateTimeLong: false,
        isEventStartDateTimeShort: false,
        isEventStartTime: false,
        isEventStartTimeLong: false,
        isEventStartTimeShort: false,
        isEventTwitter: false,
        isGuestNameFirst: true,
        name: 'Guest Name',
        slug: '{{guestFirst}} {{guestLast}}'
    };

    public init() {
        this.currentEventThemeColors = this.eventFarmService.currentEvent.eventTheme ? this.eventFarmService.currentEvent.eventTheme.colors : new Event().ThemeColorType();
        this.savedThemeColors = cloneDeep(this.currentEventThemeColors);
        this.oldPPPColors = [];
        this.setupTrackingScripts();
        this.setupMessages();
        this.messageSlugs = new Event().EventMessageSlugType();
        this.messageSlugs.unshift(this.guestNameSlug);
        this.currentMessageType = this.currentEventWPMessages[0];
        this.isEditingMessage = false;
        this.enteredWebPresencePage();
    }

    private setupTrackingScripts() {
        if (this.isShield) {
            this.currentEventWPMessages = this.eventFarmService.currentEvent.eventMessageTypes ? this.eventFarmService.currentEvent.eventMessageTypes.filter(mt => mt.isWaitListSMS === false && mt.isResponseRestriction === false && mt.isDisclaimer === false) : new Event().EventMessageType().filter(mt => mt.isWaitListSMS === false && mt.isResponseRestriction === false && mt.isDisclaimer === false);
        } else {
            this.currentEventWPMessages = this.configureMessagesOldPPP();
        }
    }

    private configureMessagesOldPPP(): EventMessageTypeInterface[] {
        const messages = this.eventFarmService.currentEvent.eventMessageTypes
                ? this.eventFarmService.currentEvent.eventMessageTypes.filter(mt => mt.isWaitListSMS === false && mt.isIntroductionInvitation === false && mt.isConfirmationInvitation === false && mt.isWaitListConfirmationInvitation === false && mt.isWaitListConfirmationRegistration === false && mt.isWaitListConfirmationRegistration === false)
                : new Event().EventMessageType().filter(mt => mt.isWaitListSMS === false && mt.isIntroductionInvitation === false && mt.isConfirmationInvitation === false && mt.isWaitListConfirmationInvitation === false && mt.isWaitListConfirmationRegistration === false && mt.isWaitListConfirmationRegistration === false);

        const declination = messages.shift();
        messages.splice(2, 0, declination);

        return messages.map(m => {
            if (m.isOpening) {
                m.description = "The 'Prior to Opening Message' is displayed as a placeholder for the registration or purchase form before the event goes live and tickets become available.";
                m.defaultValue = this.currentEventName + ' is not yet available through this website. Please check back in the near future for further updates and availability.';
            }
            if (m.isClosing) {
                m.description = "The 'After Closing Message' is displayed as a placeholder for the registration or purchase form once registration has closed for an event.";
                m.defaultValue = this.currentEventName + ' is no longer accepting changes through this website. Please check with the event organizers to find out if more space is available through other options.';
            }
            if (m.isConfirmationRegistration) {
                m.name = 'Confirmation';
                m.description = 'This message will display on the confirmation screen after a successful purchase, registration or RSVP.';
                m.defaultValue = 'Thank you for your response. We look forward to seeing you soon!';
            }
            if (m.isDeclinationInvitation) {
                m.name = 'Declination';
                m.description = 'This message will display on the confirmation screen after a guest declines their RSVP.';
                m.defaultValue = "Thank you for your response. We're sorry to miss you!";
            }
            if (m.isSoldOut) {
                m.name = 'Sold-Out';
                m.description = "The 'Sold-Out Message' is displayed when the specific ticket stack or ticket type is no longer available (either sold out or at capacity).";
                m.defaultValue = 'There are no tickets currently available for this event. If you feel you have reached this page in error, please contact the organizers of the event for more information.';
            }
            if (m.isReveal) {
                m.description = "If Invitation Reveal is enabled, the 'Reveal Message' will display when a guest begins the registration process.";
            }
            if (m.isDisclaimer) {
                m.description = "If you have created a Waiver question, the 'Disclaimer Message' will display as part of the purchase, registration, and RSVP process. Guest must check the disclaimer box to complete registration.";
            }
            if (m.isResponseRestriction) {
                m.description = "If the Event Setting 'Guest can change response' is 'NO', this message will display for any registered guest who clicks the invitation link again.";
                m.defaultValue = 'You have already responded for this event or there is an issue with your invitation which cannot be forwarded or modified at this link. If you have questions about the event, your invitation, or you want to change your response please check your confirmation email or contact your event organizer.';
            }
            return m;
        });
    }

    private setupMessages() {
        if (this.isShield) {
            this.currentEventTrackingScripts = this.eventFarmService.currentEvent.eventTrackingScripts ? this.eventFarmService.currentEvent.eventTrackingScripts : new Event().TrackingScriptType();
        } else {
            this.currentEventTrackingScripts = this.eventFarmService.currentEvent.eventTrackingScripts ? this.eventFarmService.currentEvent.eventTrackingScripts.filter(val => val.isConfirmation) : new Event().TrackingScriptType().filter(val => val.isConfirmation);
        }
    }

    public freezeCurrentColors(val: boolean) {
        if (val) {
            this.filteredEventThemeColors = this.savedThemeColors.filter(color => color.slug === 'primary' || color.slug === 'primaryAlt');
        } else {
            for (const color of this.savedThemeColors) {
                this.setColorForSlug(color.slug, color.description);
            }
        }
    }

    public reset() {
        this.currentEventThemeColors = [];
        this.filteredEventThemeColors = [];
        this.savedThemeColors = [];
        this.currentEventTrackingScripts = [];
        this.currentEventWPMessages = [];
        this.messageSlugs = [];
        this.isEditingMessage = false;
        this.oldPPPColors = [];
    }

    public setColorForSlug(slug: string, color: string) {
        if (this.isShield) {
            const idx = this.currentEventThemeColors.findIndex(val => {
                return val.slug === slug;
            });
            this.currentEventThemeColors[idx].description = color;
        } else {
            const idx = this.oldPPPColors.findIndex(val => {
                return val.slug === slug;
            });
            this.oldPPPColors[idx].description = color;
        }

    }

    public isGuestSlugDisabledForType(slug: EventMessageSlugTypeInterface) {
        let disabled = false; // Generally, slugs should not be disabled

        // However, we want to disable "Guest Name" slug on message types
        // Other than confirmation and waitlist confirmation

        if (slug.name === 'Guest Name') {
            switch (this.currentMessageType.slug) {
                case 'confirmation':
                case 'wait_list_confirmation':
                case 'declination':
                case 'additional_invitation':
                case 'confirmation_invitation':
                case 'wait_list_confirmation_invitation':
                    disabled = false;
                    break;
                default:
                    disabled = true;
                    break;
            }
        }
        return disabled;
    }

    public setMessageType(slug: string) {
        this.currentMessageType = this.currentEventWPMessages.find(type => {
            return type.slug === slug;
        });
    }

    public async saveTheme(): Promise<boolean> {

        const primary = this.currentEventThemeColors.find(val => val.slug === 'primary');
        const primaryAlt = this.currentEventThemeColors.find(val => val.slug === 'primaryAlt');
        const background = this.currentEventThemeColors.find(val => val.slug === 'background');
        const font = this.currentEventThemeColors.find(val => val.slug === 'font');
        const alert = this.currentEventThemeColors.find(val => val.slug === 'alert');

        try {
            const res = await this.apiClient.getUseCaseFactory().EventTheme().SetEventTheme(
                this.eventId, primary.description, primaryAlt.description, alert.description, background.description, font.description, 'material', 'times'
            ).toPromise();

            this.eventFarmService.currentEvent.eventTheme = EventTheme.fromCommand(res.data.command);
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.message.success('Event theme updated');

            this.savedThemeColors = cloneDeep(this.currentEventThemeColors);

            this.segmentService.segmentWebPresenceTracking().webPresenceSetTheme(alert.description, background.description, font.description, primary.description, primaryAlt.description);
            return true;

        } catch (err) {
            this.message.success('Please try again');
            return false;
        }

    }

    public async saveTracking(slug: string, trackingScript: string): Promise<boolean> {
        try {
            const res = await this.apiClient.getUseCaseFactory().Event().SetTrackingScriptForEvent(this.eventId, slug, trackingScript).toPromise();
            const trIdx = this.currentEventTrackingScripts.findIndex(tr => tr.slug === slug);
            this.currentEventTrackingScripts[trIdx].value = trackingScript;
            this.eventFarmService.currentEvent.eventTrackingScripts = this.currentEventTrackingScripts;
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.message.success('Tracking script updated');

            this.segmentService.segmentWebPresenceTracking().webPresenceSetTracking(slug, trackingScript);

            return true;

        } catch (err) {
            this.message.success('Please try again');
            return false;
        }
    }

    public async saveMessage(message: string): Promise<boolean> {
        try {

            let res;
            if (message.length === 0) {
                res = await this.apiClient.getUseCaseFactory().Event().RemoveMessageForEvent(this.eventId, this.currentMessageType.slug).toPromise();
            } else {
                res = await this.apiClient.getUseCaseFactory().Event().SetMessageForEvent(this.eventId, this.currentMessageType.slug, message).toPromise();
            }

            const idx = this.currentEventWPMessages.findIndex(tr => tr.slug === this.currentMessageType.slug);
            this.currentMessageType.value = message;
            this.currentEventWPMessages[idx].value = message;
            this.eventFarmService.currentEvent.eventMessageTypes = this.currentEventWPMessages;
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.message.success('Display message updated');
            this.segmentService.segmentWebPresenceTracking().webPresenceSetDisplayMessage(this.currentMessageType.name, this.currentMessageType.value);
            return true;

        } catch (err) {
            this.message.success('Please try again');
            return false;
        }
    }

    public toggleMessageSelect() {
        this.isEditingMessage = !this.isEditingMessage;
    }

    public enteredWebPresencePage() {
        this.segmentService.segmentSideNavTracking().webPresenceEntered();
    }

    private froalaOptions(): object {
        return {
            key: 'dKA5cC3C2D2C1E2A4A4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4B2E4C2C2E3C1C2==',
            theme: 'custom',
            toolbarButtons: [
                'fontFamily', 'fontSize', '|',
                'bold', 'italic', 'underline', 'color', '|',
                'formatOL', 'formatUL', '|',
                'paragraphFormat', 'align', '|',
                'insertLink', '|',
                'undo', 'redo', 'clearFormatting', '|',
                'html'
            ],
            attribution: false,
            dragInline: false,
            pasteDeniedAttrs: ['class', 'id'],
            useClasses: false,
            keepFormatOnDelete: true,
            tooltips: false,
            linkEditButtons: ['linkEdit', 'linkRemove'],
            fontFamilySelection: true,
            fontFamily: {
                'Arial, Sans-Serif': 'Arial',
                'Arial Black, Gadget, Sans-Serif': 'Arial Black',
                'Helvetica, Sans-Serif': 'Helvetica',
                'Courier New, Courier, Monospace': 'Courier New',
                'Georgia, Times New Roman, Times, Serif': 'Georgia',
                'Impact, Charcoal, Sans-Serif': 'Impact',
                'Lucida Console, Monaco, Monospace': 'Lucida Console',
                'Lucida Sans Unicode, Lucida Grande, Sans-Serif': 'Lucida Sans',
                'Palatino Linotype, Book Antiqua, Palatino, Serif': 'Palatino',
                'Tahoma, Geneva, Sans-Serif': 'Tahoma',
                'Times New Roman, Times, Serif': 'Times New Roman',
                'Trebuchet MS, Helvetica, Sans-Serif': 'Trebuchet',
                'Verdana, Geneva, Sans-Serif': 'Verdana'
            },
            fontSizeSelection: true,
            charCounterCount: false,
            codeMirror: false,
            linkConvertEmailAddress: false,
            colorsStep: 9,

            colorsText: [
                '#FFFFFF', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
                '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#7C706B',
                '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#61BD6D',
                '#FAC51C', '#F37934', '#D14841', '#B8312F', '#000000', 'REMOVE'
            ],
            inlineStyles: {
                'Full Width': `border-radius: 100px; display: inline-block; padding: 16px 28px;`,
                'Small Blue': 'font-size: 14px; color: blue;',
            },
            pluginsEnabled: [
                'align', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'wordPaste', 'codeView'
            ],

            events: {
                'initialized': (e) => {
                    this.currentEditor = e._editor;
                },
            }
        };
    }
}
