import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../../../../../../../ApiClient/Models/User/user';
import { EventFarmService } from '../../../../../../eventFarm.service';
import { AlertService } from '../../../../../../eventFarmAlert.service';
import { ExhibitorTeamMembersService } from '../exhibitors-team-members.service';
import { EventFarmAPIClient } from '../../../../../../../ApiClient/event-farm-api-client';
import { Exhibitor } from '../../../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { isValidPhoneNumber } from '../../../../../../../Utilities/validatePhoneNumber';

import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from '@khazii/ngx-intl-tel-input';

@Component({
    selector: '[exhibitor-team-member]',
    template: require('./exhibitor-team-member.html'),
    styles: [require('./exhibitor-team-member.scss')],
})

export class ExhibitorTeamMemberComponent implements OnInit {

    @Input() exhibitor: Exhibitor;
    @Input() teamMember: User;
    @Input() index: number;
    @Output() deleteReceived: EventEmitter<User> = new EventEmitter();
    @Output() setIsSaving: EventEmitter<boolean> = new EventEmitter();
    @Output() setIsDeleting: EventEmitter<boolean> = new EventEmitter();
    @Output() editReceived: EventEmitter<User> = new EventEmitter();

    private isEditing: boolean = false;
    private firstName: FormControl = new FormControl();
    private lastName: FormControl = new FormControl();
    private phone: FormControl = new FormControl();

    constructor(
        private alertService: AlertService,
        private eventFarmService: EventFarmService,
        private teamMembersService: ExhibitorTeamMembersService,
        private message: NzMessageService,
        private apiClient: EventFarmAPIClient
    ) { }

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];

    ngOnInit() {
    }

    get phoneNumberString(): string {
        return this.teamMember.phoneNumber && this.teamMember.phoneNumber.length ? this.teamMember.phoneNumber : 'No Phone Number';
    }

    private async edit() {
        this.firstName.setValue(this.teamMember.name && this.teamMember.name.firstName ? this.teamMember.name.firstName : '');
        this.lastName.setValue(this.teamMember.name && this.teamMember.name.lastName ? this.teamMember.name.lastName : '');
        this.phone.setValue(this.teamMember.phoneNumber ? this.teamMember.phoneNumber : '');

        this.isEditing = true;
    }

    private async cancel() {
        this.isEditing = false;
    }

    private async delete() {
        this.setIsDeleting.emit(true);

        this.alertService.exhibitors().confirmUserDeletion(this.teamMember.identifier.identifier)
            .then(async (res) => {
                if (res.value) {
                    this.deleteReceived.emit(this.teamMember);
                    this.setIsDeleting.emit(false);

                }
            });
    }

    private async triggerResendWelcomeEmail() {
        const confirm = await this.alertService.welcomeEmail().resendWelcomeEmail();
        if (confirm.value) {
            this.message.info('Re-sending welcome email...');
            try {
                await this.apiClient.getUseCaseFactory().Exhibitor().ResendExhibitorTeamMemberEmail(
                    this.exhibitor.id,
                    this.teamMember.id,
                    this.eventFarmService.currentUser.id
                ).toPromise();
                this.message.success('Email resent');
            } catch (error) {
                this.message.error('Error sending email');
            }
        }
    }

    private async save() {
        this.setIsSaving.emit(true);
        const phoneNumber = this.phone.value ? this.phone.value['e164Number'] : null;
        const first = this.firstName.value;
        const last = this.lastName.value;

        if (!first.length || !last.length) {
            this.message.error('First name or last name cannot be empty');
            this.setIsSaving.emit(false);
            return;
        }

        if (!phoneNumber.length) {
            this.message.error('Phone number cannot by empty');
            this.setIsSaving.emit(false);
            return;
        }

        if (!isValidPhoneNumber(phoneNumber)) {
            this.message.error('Phone number is invalid');
            this.setIsSaving.emit(false);
            return;
        }

        const user = await this.teamMembersService.editUser(this.teamMember, first, last, phoneNumber);
        this.message.success('User Updated');

        this.editReceived.emit(user);
        this.isEditing = false;
        this.setIsSaving.emit(false);
    }
}
