import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../store';
import * as eventActions from './../../store/actions/event';
import { map } from 'rxjs/operators';

@Injectable()

export class EventAccessService {
    public ticketTypes$ = this.store.select(fromRoot.getTypesWithStacks);

    constructor(
      public store: Store<fromRoot.AppState>,
    ) {}

    public ticketTypesLoaded$ = this.store.select('event').pipe(
        map(event => event.ticketTypes.loaded)
    );

    public ticketTypesLoading$ = this.store.select('event').pipe(
        map(event => event.ticketTypes.loading)
    );

    public fetchAccessTypes() {
        this.store.dispatch(new eventActions.FetchAccessTypes());
    }
}
