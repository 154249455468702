import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { FormControl } from '@angular/forms';
import { formatMoney, formatInt } from '../../../../../../Utilities/formatMoney';
import { formatPromo } from '../../../../../../Utilities/formatPromo';
import { filter, map } from 'rxjs/operators';
import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { FormattedDateTime } from '../../../../../../ApiClient/Models/formatted-datetime';
import {dateTimeFormat, momentShortDateTime, timePickerDefaults} from '../../../../../../Shared/datetime-options';
import { Promo, PromoAccessType } from '../../../../../../ApiClient/Models/Promotion/promo';
import { PromotionTypeInterface, Promotion } from '@eventfarm/javascript-sdk/dist/Api/Type/Promotion';
import { TicketType } from '../../../../../../ApiClient/Models/Stack/ticket-type';
import { PaymentsPromotionsService } from '../../../payments-promotions.service';
import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';

interface AccessTypeSelect {
  label: string;
  value: string;
}

@Component({
  selector: 'edit-promo',
  template: require('./edit-promo.html'),
  styles: [require('./edit-promo.scss')]
})
export class EditPromoComponent implements OnInit {

  @Input() promo: Promo;

  private promoCodeName: FormControl = new FormControl();
  private promoCodeType: FormControl = new FormControl();
  private promoCodeTypes: PromotionTypeInterface[] = new Promotion().PromotionType().filter(type => type.slug !== 'shipping');
  private promoStartDate: Date;
  private promoEndDate: Date;
  private promoCodeMessage: FormControl = new FormControl();
  private promoCodeAmount: FormControl = new FormControl();
  private promoCodeMaximum: FormControl = new FormControl();
  private disabledEndDate;
  private eventTimezone$ = new BehaviorSubject<string>('America/New_York');
  private ticketTypes: TicketType[];
  private codeExists: boolean;
  private createPromo: boolean;
  private allowPaymentPromos: boolean = true;
  private isSaving: boolean = false;
  private confirmDelete: boolean = false;
  private isDeleting: boolean = false;
  private listOfAccessSelected: string[] = [];
  private dateTimeFormat = dateTimeFormat;
  private formatMoney = formatMoney;
  private formatInt = formatInt;
  private formatPromoCode = formatPromo;
  private eventStartTime: FormattedDateTime;
  private currentTime = new Date().getTime() / 1000;
  public filteredPromoAccessTypes: PromoAccessType[] = [];
  private invalidAccessTypeMessage = '';
  private timePickerDefaults = timePickerDefaults;

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private paymentPromoService: PaymentsPromotionsService,
    private segmentService: SegmentService,

  ) { }

  ngOnInit() {
    this.initStoreSubscriptions();
    this.setInitialFields();
    this.setValidEndDate();
    this.initFieldSubscriptions();
  }

  private get promoTypeMessage(): string {
    if (this.promoCodeType.value === 'discount') {
      return 'Applies specified discount to guests\' order total';
    } else if (this.promoCodeType.value === 'percentage') {
      return 'Applies specified price reduction to guests\' entire order';
    } else if (this.promoCodeType.value === 'quantity') {
      return 'Entitles each guest to a specified number of free tickets per order';
    } else if (this.promoCodeType.value === 'offer') {
      return 'Selected access types will only be visible to guests providing this promo code at the beginning of registration';
    }
  }

  private get shouldShowMoney(): boolean {
    return this.promoCodeType.value === 'discount';
  }

  private get shouldShowPercent(): boolean {
    return this.promoCodeType.value === 'percentage';
  }

  private get shouldShowFreeTickes(): boolean {
    return this.promoCodeType.value === 'quantity';
  }

  private async savePromo() {

    if (this.isSaving === false) {
      this.isSaving = true;

      /**
       * Grab type, amount, and maximum from form
       */
      const t = new Promotion().PromotionType().filter(type => type.slug === this.promoCodeType.value)[0];
      const amount = !t.isOffer ? Number(this.promoCodeAmount.value) : 0;
      const max = this.promoCodeMaximum.value ? Number(this.promoCodeMaximum.value) : null;
      const code = String(this.promoCodeName.value).toUpperCase();

      this.promo.startTimeFormatted.mutable = this.promoStartDate.toLocaleString('en-US', { hour12: false });
      this.promo.endTimeFormatted.mutable = this.promoEndDate.toLocaleString('en-US', { hour12: false });

      const promoToBeSaved = Promo.forNewCreation({
        promotionType: t,
        code: code,
        amount: amount,
        maximum: max,
        enabled: this.promo.isEnabled,
        startTimeFormatted: this.promo.startTimeFormatted,
        endTimeFormatted: this.promo.endTimeFormatted,
        message: this.promoCodeMessage.value,
        used: this.promo.used
      }, this.paymentPromoService.currency.slug);

      const stackIdsToBeSaved = this.convertSelectedAccessTypesNamesToTypeObjects().map(sel => sel.id);
      const promoSaveSuccess = await this.paymentPromoService.createOrUpdatePromotion(promoToBeSaved , stackIdsToBeSaved, this.promo.id);

      if (promoSaveSuccess) {
        this.isSaving = false;
        this.drawerRef.close();
        this.paymentPromoService.promosListOptions.pagination.currentPromosPage = 1;
        const getNewPromos = await this.paymentPromoService.getPromotions();
      } else {
        this.isSaving = false;
      }

    }
  }

  private accessTypesChanged(event) {
    this.verifyPromosForPayment(event);
  }

  private verifyPromosForPayment(promos: string[]) {
    this.allowPaymentPromos = true;
    this.invalidAccessTypeMessage = '';

    let type = this.promoCodeType.value;
    if (!type) {
      type = this.promo.type.slug;
    }

    if (type !== 'discount' && type !== 'percentage') {
      return;
    }

    let amount = this.promoCodeAmount.value;
    if (!amount) {
      amount = this.promo.amount ? this.promo.amount : 0;
    }

    const errors = this.paymentPromoService.promoAccessTypes.reduce((result, value) => {
  
      if (!promos.includes(value.name)) {
        return result;
      }

      if (!value.type.isPublicPurchase && !value.type.isInviteToPurchase) {
        return result;
      }

      const accessTypeCost = value.cost;

      if (type === 'discount') {
        const difference = accessTypeCost - amount;
        if (difference < 1.0 && difference > 0.0) {
          result.push(value.name);
        } 
        if (difference < 0.0) {
          result.push(value.name);
        }
      } else if (type === 'percentage') {
        const difference = (100 - amount) / 100 * accessTypeCost;
        if (difference < 1.0 &&  difference > 0.0) {
          result.push(value.name);
        } 
        if (difference < 0.0) {
          result.push(value.name);
        }
      }

      return result;
    }, []);

    if (errors.length) {
      const errorMessage = errors.join(', ');

      this.invalidAccessTypeMessage = 'Discounted price of access type(s) must be greater than $1! (' + errorMessage + ')';
      this.allowPaymentPromos = false;
    } else {
      this.allowPaymentPromos = true;
      this.invalidAccessTypeMessage = '';
    }    
  }

  private async handleDeletePromo() {
    if (this.isDeleting) {
      this.isDeleting = false;
    }
    if (this.confirmDelete) {
      this.isDeleting = true;

      const deleteSuccess = await this.paymentPromoService.deletePromo(this.promo.id);

      this.isDeleting = false;
      this.confirmDelete = false;

      if (deleteSuccess === true) {
        this.drawerRef.close();

        if (this.paymentPromoService.promoCodeCount - 1 === 0) {
          const currentPage = this.paymentPromoService.promosListOptions.pagination.currentPromosPage;
          if (currentPage > 1) {
            this.paymentPromoService.promosListOptions.pagination.currentPromosPage = currentPage - 1;
          }
        }

        const getNewPromos = await this.paymentPromoService.getPromotions();
      }
    }
    this.confirmDelete = true;
  }

  private allowSave() {
    return this.promoCodeName.value !== '' && Boolean(this.promoCodeName.value.match(/\s/)) === false && !this.codeExists && this.listOfAccessSelected.length && this.shouldHaveValue() && this.promoStartDate && this.promoEndDate && this.allowPaymentPromos;
  }

  private shouldHaveValue(): boolean {
    if (['discount', 'percentage', 'quantity'].includes(this.promoCodeType.value) && this.promoCodeAmount.value === null) {
      return false;
    }

    return true;
  }

  private handlePriceFormatForApi(price) {
    const formatPrice = price === '' || price === '0' || price === 0 ? 0 : Number(price);
    return formatPrice;
  }

  private updateValidEndDate() {
    if (this.promoStartDate > this.promoEndDate) {
      this.promoEndDate = new Date(this.promoStartDate.getTime() + 10800000);
    }
    this.setValidEndDate();
  }

  private setValidEndDate() {
    this.disabledEndDate = (current: Date): boolean => {
      return current < this.promoStartDate;
    };
  }

  private buildAccessTypeOptions(type: string, isInit: boolean = true) {

    let filteredSelected: PromoAccessType[] = [];

    if (['discount', 'percentage', 'quantity'].includes(type)) {
      this.filteredPromoAccessTypes = this.paymentPromoService.promoAccessTypes.filter(at => {
        return at.type.isAnyInviteToPurchase || at.type.isPublicPurchase;
      });

      if (isInit) {
        filteredSelected = this.promo.stacks ? this.promo.stacks.filter(stack => {
          return stack.type.isAnyInviteToPurchase || stack.type.isPublicPurchase;
        }) : [];
      } else {
        filteredSelected = this.convertSelectedAccessTypesNamesToTypeObjects().filter(stack => {
          return stack.type.isAnyInviteToPurchase || stack.type.isPublicPurchase;
        });
      }

      this.listOfAccessSelected = filteredSelected.map(f => f.name);
      this.verifyPromosForPayment(this.listOfAccessSelected);

    } else if (['offer'].includes(type)) {
      this.filteredPromoAccessTypes = this.paymentPromoService.promoAccessTypes.filter(at => {
        return at.type.isPublicPurchase || at.type.isPublicRegistration;
      });

      if (isInit) {
        filteredSelected = this.promo.stacks ? this.promo.stacks.filter(stack => {
          return stack.type.isPublicPurchase || stack.type.isPublicRegistration;
        }) : [];
      } else {
        filteredSelected = this.convertSelectedAccessTypesNamesToTypeObjects().filter(stack => {
          return stack.type.isPublicPurchase || stack.type.isPublicRegistration;
        });
      }
      this.listOfAccessSelected = filteredSelected.map(f => f.name);
    }
  }

  private convertSelectedAccessTypesNamesToTypeObjects(): PromoAccessType[] {
    return this.listOfAccessSelected.map(name => {
      return this.paymentPromoService.promoAccessTypes.filter(alltypes => {
        return alltypes.name === name;
      })[0];
    });
  }

  /**
   * Setup initial values and subscriptions
   */
  private setInitialFields() {

    this.paymentPromoService.fetchAccessTypesForPromotions();

    this.isSaving = false;

    if (!this.promo) {
      this.createPromo = true;
      this.promo = Promo.forNewCreation({
          promotionType: new Promotion().PromotionType().filter(type => type.slug === 'discount'),
          enabled: true,
          startTimeFormatted: {},
          endTimeFormatted: this.eventStartTime
        }, this.paymentPromoService.currency.slug
      );
    }

    this.buildAccessTypeOptions(this.promo.type.slug);

    if (!this.promo.startTimeFormatted.date) {
      this.promoStartDate = new Date();
    } else {
      const start = `${this.promo.startTimeFormatted.date.short} ${this.promo.startTimeFormatted.time.short}`;
      this.promoStartDate = moment(start, momentShortDateTime).toDate();
    }

    if (!this.promo.endTimeFormatted.date) {
      const close = `${this.eventStartTime.date.short} ${this.eventStartTime.time.short}`;
      this.promoEndDate = moment(close, momentShortDateTime).toDate();
    } else {
      const close = `${this.promo.endTimeFormatted.date.short} ${this.promo.endTimeFormatted.time.short}`;
      this.promoEndDate = moment(close, momentShortDateTime).toDate();
    }

    this.promoCodeMaximum.setValue(this.promo.maximum ? this.promo.maximum : '');
    
    this.promoCodeAmount.setValue(this.promo.amount ? this.promo.amount : '');
    this.promoCodeAmount.valueChanges.subscribe(_ => {
      this.verifyPromosForPayment(this.listOfAccessSelected);
    });

    this.promoCodeName.setValue(this.promo.code ? this.promo.code : '');
    this.promoCodeMessage.setValue(this.promo.message ? this.promo.message : '');
    
    this.promoCodeType.setValue(this.promo.type.slug);
    this.promoCodeType.valueChanges.subscribe(_ => {
      this.verifyPromosForPayment(this.listOfAccessSelected);
    });

  }

  private initStoreSubscriptions() {
    this.segmentService.segmentPromotionsTracking().openEdit();
    this.store.select('event').pipe(
      filter(res => !!res),
      map(res => res.data)
    ).subscribe((res: EfEvent) => {
      if (res) {
        this.eventTimezone$.next(res.timezone);
        this.eventStartTime = res.startTime;
        this.ticketTypes = res.ticketTypes;
      }
    });
  }

  private initFieldSubscriptions() {
    this.promoCodeName.valueChanges.subscribe(promoInput => {
      const typeExists = this.paymentPromoService.currentPromoCodes$.value
        .filter((i) => this.promo.code !== i.code)
        .filter(promoFromList => {
          return promoFromList.code.toLowerCase() === promoInput.toLowerCase();
        }).length > 0;
      this.codeExists = typeExists;
    });

    this.promoCodeType.valueChanges.subscribe(type => {
      this.buildAccessTypeOptions(type, false);
      this.promoCodeAmount.reset();
    });
  }

  close(): void {
    this.segmentService.segmentPromotionsTracking().closeEdit();
    this.drawerRef.close(this.promo);
  }
}
