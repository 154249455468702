import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';

@Component({
  selector: 'form-waiver',
  template: `
  <div class="dynamic-field form-group form-input">
    <label>{{ config.label }}</label>
    <p *ngIf="signature">
      <i>Guest signed waiver</i>
    </p>
    <p *ngIf="! signature">
      <i>Guest did not sign waiver</i>
    </p>
  </div>`
})
export class FormWaiverComponent implements Field {
  config;
  group: FormGroup;

  private get signature() {
    try {
      return JSON.parse(this.config.responses[0].text).signature;
    } catch (err) {
      return false;
    }
  }
}
