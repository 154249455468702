import * as Sentry from '@sentry/angular-ivy';
import {WaitListService} from './EventsModule/WaitList/waitList.service';
import {Injectable} from '@angular/core';
import {EventFarmAPIClient} from '../ApiClient/event-farm-api-client';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {User} from '../ApiClient/Models/User/user';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {map, take, filter, tap, shareReplay} from 'rxjs/operators';
import {Team} from '../ApiClient/Models/Pool/team';
import {EfEvent} from '../ApiClient/Models/Event/event';
import {PoolWorld} from '../ApiClient/Models/PoolWorld/pool-world';
import {State} from '../ApiClient/Models/state';
import {SessionStorageService} from '../_services/storage/session-storage.service';
import {Location} from '@angular/common';
import {RouteGeneratorService} from '../_services/routes/route-generator.service';
import * as queryString from 'query-string';
import * as _ from 'lodash';

declare const __USE_SUBDOMAINS__: boolean;
import * as fromRoot from './store';

import {Store} from '@ngrx/store';
import * as fromStore from './store';
import * as teamActions from './store/actions/team.action';
import * as userActions from './store/actions/user.action';
import {CookieTokenService} from '../ApiClient/cookie-token-service';
import {AngularRestClient} from '../_services/rest/AngularRestClient';
import {LocalStorageService} from '../_services/storage/local-storage.service';
export interface AlertBannerInterface {
    showAlertBanner: boolean;
    type: 'success' | 'info' | 'warning' | 'error';
    message: string;
  }

@Injectable()
export class EventFarmService {
    constructor(
        private sessionStorageService: SessionStorageService,
        private apiClient: EventFarmAPIClient,
        private http: AngularRestClient,
        private router: Router,
        private cookieAccessToken: CookieTokenService,
        private routeGenerator: RouteGeneratorService,
        private route: ActivatedRoute,
        private location: Location,
        private localStorageService: LocalStorageService,
        private store: Store<fromStore.AppState>,

    ) {
        this.attachLocalModelSubscribers();
    }

    public window: Window;

    public userTeams: Observable<Team[]> = this.store.select(fromRoot.getAllTeamsForCurrentUser)
        .pipe(
            map(teams => teams.length > 0 ? teams : null),
            filter(teams => !!teams),
            take(1),
        );

    public currentEvent: EfEvent;
    public currentTeam: Team;
    public currentUser: User;
    public currentState: State;
    public currentFeatures: any;
    //The following is useful so we can subscribe after values have been populated
    public currentFeaturesObs$ =  new BehaviorSubject([]);

    public currentEventRoles$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());
    public eventsMeta$ = new BehaviorSubject({});
    public eventStore$: Observable<any> = this.store.select('event');
    private teamStore$: Observable<any> = this.store.select('team');
    private userStore$ = this.store.select(fromRoot.getCurrentUser).pipe(shareReplay());
    public readonly updateTicketBlockCounts$ = new BehaviorSubject<Boolean>(false);

    public get currentTeamId(): string {
        return _.get(this, 'currentTeam.id')
    }

    public async updateEventDetails(eventId: string = null) {
        this.store.dispatch(new fromRoot.FetchCurrentEvent());
    }

    public get isAdmin(): boolean {
        if (this.currentUser && this.currentUser.admin) {
            return true;
        }
        return false;
    }

    public async currentPoolWorldForEvent(): Promise<PoolWorld | null> {
        try {
            const response = await this.apiClient.getUseCaseFactory().PoolWorld()
                .GetPoolWorldByEvent(this.currentEvent.id)
                .toPromise();
            return PoolWorld.fromApiResponse(response.data);
        } catch (e) {
            return null;
        }
    }

    public get isGhosting(): boolean {
        return document.cookie.match('(^|;)\\s*' + 'EF_GHOST_ACCESS_TOKEN' + '\\s*=\\s*([^;]+)') ? true : false;
    }

    public getAlertBannerUpdates(): AlertBannerInterface {
        const isSandboxGateway = _.get(this, 'currentEvent.poolPaymentGateway.isSandbox', false);

        if (isSandboxGateway) {
            return {
                showAlertBanner: true,
                type: "warning",
                message: "You cannot accept live payments with a payment gateway in test mode."
            }
        }

        return {
            showAlertBanner: false,
            type: "warning",
            message: ""
        }
    }

    public isUserAdmin() {

        const isCurrentEvent = _.get(this, "currentEvent", false);

        if (isCurrentEvent) {
            return _.get(this, "currentUser.admin", false);
        }

        return false;
    }

    public listAccessiblePoolsForUser(userId: string, page = 1, resultsPerPage = 500, sortBy = 'name', sortDirection = 'ascending') {
        return this.apiClient.getUseCaseFactory().Pool().ListAccessiblePoolsForUser(userId, page, resultsPerPage, sortBy, sortDirection);
    }

    public listAssignedPoolWorldsForPool() {
        return this.apiClient.getUseCaseFactory().PoolWorld().ListPoolWorldsForPool(this.currentTeam.id);
    }

    // TODO Revisit this
    public async loadRequiredAppUserContext(userId: string, email: string): Promise<void> {
        const ghostTech = this.localStorageService.get('X-EF-GhostTech');
        if (ghostTech) {
            this.store.dispatch(new userActions.GetUserByEmail(ghostTech));
        } else {
            if (userId) {
                this.store.dispatch(new userActions.GetCurrentUser());
            } else {
                Sentry.captureException('No user id found in loadRequiredAppUserContext')
            }
        }
    }

    public fetchEventAccess(eventId: string, userId = this.currentUser.id): Observable<any> {
        return this.apiClient
            .getUseCaseFactory().User().GetUserRolesForEvent(userId, eventId);
    }

    public setCoreAccess(eventId: string): Observable<any> {
        // @TODO JASON VERY SOON
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest'
            })
        };

        const req = this.http
            .get('/events/handleLocalPermissions/' + eventId, {headers: httpOptions.headers});
        return req;
    }

    public fetchSalesforceStatusForEvent(eventId: string): any {
        return this.apiClient
            .getUseCaseFactory().Salesforce().GetSalesforceStatusForEvent(eventId);
    }

    public fetchEventDetails(eventId: string, data: string[]): Observable<any> {
        return this.apiClient
            .getUseCaseFactory().Event().GetEvent(eventId, data);
    }

    public fetchGuestCountsForEventOrTicketBlock(eventOrTicketBlockId: string, isTicketBlock = false): Promise<any> {
        let type = this.apiClient.getUseCaseFactory().Invitation().GetInvitationCountsForEvent(eventOrTicketBlockId);
        if (isTicketBlock) {
            type = this.apiClient.getUseCaseFactory().Invitation().GetInvitationCountsForTicketBlock(eventOrTicketBlockId);
        }
        return new Promise((resolve, reject) => {
            type
                .toPromise()
                .then((res) => {
                    const counts: any = {};
                    counts.assigned = res.data['invitation-status-type-counts'].assigned || 0;
                    counts.confirmedByRsvp = res.data['invitation-status-type-counts']['confirmed-by-rsvp'] || 0;
                    counts.declinedByRsvp = res.data['invitation-status-type-counts']['declined-by-rsvp'] || 0;
                    counts.leftBehind = res.data['invitation-status-type-counts']['left-behind'] || 0;
                    counts.notYetPurchased = res.data['invitation-status-type-counts']['not-yet-purchased'] || 0;
                    counts.notYetRegistered = res.data['invitation-status-type-counts']['not-yet-registered'] || 0;
                    counts.purchased = res.data['invitation-status-type-counts'].purchased || 0;
                    counts.recycled = res.data['invitation-status-type-counts'].recycled || 0;
                    counts.registered = res.data['invitation-status-type-counts'].registered || 0;
                    counts.checkIn = res.data['check-in-counts']['checked-in'];
                    counts.total = res.data['invitation-status-type-counts'].total - res.data['invitation-status-type-counts'].recycled || 0;
                    counts.unconfirmed = res.data['invitation-status-type-counts'].unconfirmed || 0;
                    counts.allAttending = counts.confirmedByRsvp + counts.assigned + counts.registered + counts.purchased + counts.leftBehind;
                    counts.allUndecided = counts.unconfirmed + counts.notYetPurchased + counts.notYetRegistered;
                    counts.uniqueInvitationCount = res.data['total-unique-invitation-count'].uniqueInvitationCount || 0;
                    resolve(counts);
                }).catch((err) => {
                reject(err);
            });
        });
    }
    public fetchHealthPassScoreCountsForEvent(eventId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiClient.getUseCaseFactory().HealthPass().GetHealthPassScoreCountsForEvent(eventId)
                .toPromise()
                .then((res) => {
                    const counts: any = {};
                    counts.affirmative = res.data['affirmative'];
                    counts.assigned = res.data['assigned'];
                    counts.confirmedByRsvp = res.data['confirmed-by-rsvp'];
                    counts.declinedByRsvp = res.data['declined-by-rsvp'];
                    counts.leftBehind = res.data['left-behind'];
                    counts.notYetPurchased = res.data['not-yet-purchased'];
                    counts.notYetRegistered = res.data['not-yet-registered'];
                    counts.notAffirmative = res.data['notAffirmative'];
                    counts.purchased = res.data['purchased'];
                    counts.recycled = res.data['recycled'];
                    counts.registered = res.data['registered'];
                    counts.unconfirmed = res.data['unconfirmed'];
                    counts.waitlisted = res.data['waitlisted'];
                    counts.checkIn = res.data['checkIn'];
                    counts.allUndecided = res.data['allUndecided'];

                    counts.total = res.data['total'];
                    resolve(counts);
                }).catch((err) => {
                reject(err);
            });
        });
    }

    public goBack() {
        this.location.back();
    }

    get isDev(): boolean {
        if (__USE_SUBDOMAINS__ || window.location.host.includes('eventfarm')) {
            return false;
        }
        return true;
    }

    get isShield(): boolean {
        return this.currentEvent.isShield;
    }

    get eventAppEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Event App');

        if (!enabledForPool.length) {
            return false;
        }

        return !this.currentEvent.hasParentEvent && enabledForPool[0].attributes.isEnabled;
    }

    get virbelaEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Virbela');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get sessionsEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Session Management');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get webConferenceEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Web Conference');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get questionLogicEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Question Logic');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get guestPassEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Guest Pass');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get healthPassEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Health Pass');
        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get smsEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Messaging (EFx)');
        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get exhibitorsEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Exhibitors');
        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get disablePaymentsEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Disable Payments');
        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get salesforceEnabled(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Salesforce');
        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get isChildEvent(): boolean {
        if (!this.currentEvent) {
            return false;
        }

        return this.currentEvent.hasParentEvent;
    }

    get isSession(): boolean {
        if (!this.currentEvent) {
            return false;
        }

        return this.currentEvent.hasParentEvent;
    }

    get childEventsEnabledForEvent(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Child Events');

        if (!enabledForPool.length) {
            return false;
        }

        return !this.currentEvent.hasParentEvent && enabledForPool[0].attributes.isEnabled;
    }

    get waitlistEnabledForPool(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Waitlist');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    get betaEnabledForPool(): boolean {
        if (this.canCheckFeatureFlag()) {
            return false;
        }

        const enabledForPool = this.currentFeatures.data.filter(feature => feature.relationships.feature.attributes.name === 'Beta Features');

        if (!enabledForPool.length) {
            return false;
        }

        return enabledForPool[0].attributes.isEnabled;
    }

    private canCheckFeatureFlag(): boolean
    {
        const curFeaturesLen = _.get(this, 'currentFeatures.data.length', 0);
        return !curFeaturesLen || !this.currentTeam
    }


    private attachLocalModelSubscribers(): void {

        this.eventStore$.subscribe((val) => {
            this.currentEvent = null;
            if (val.data) {
                this.currentEvent = val.data;
            }
        });

        this.teamStore$.subscribe((val) => {
            this.currentTeam = null;
            this.currentFeatures = null;
            if (val.data) {
                this.sessionStorageService.set('pool', val.data);
                this.currentTeam = val.data;
            }
            if (val.features) {
                this.currentFeatures = val.features;
                this.currentFeaturesObs$.next(val.features)
            }
        });

        this.userStore$.subscribe((val) => {
            if (val.data) {
                this.currentUser = val.data;
                this.sessionStorageService.set('user', {
                    id: val.data.id,
                    attributes: {
                        name: val.data.name,
                    },
                });

            }
        });
    }

    public eventListOptions: EventListOptions = {
        attributesFilter: null,
        attributesExcludeFilter: ['archived'],
        sortBy: 'event-start',
        sortDirection: 'ascending',
        eventDateFilterType: 'current-future',
        pagination: {
            currentEventsPage: 1,
            currentTicketBlocksPage: 1,
            resultsPerPage: 15,
        },
        isListView: true
    };

    public async getCanvasAccessUrl(eventId: string, devMode: boolean = false): Promise<string> {
        const accessToken =  this.localStorageService.get('accessToken');
        const fullBaseUrl = window.location.protocol + '//' + window.location.host;
        let redirectUrl = `${fullBaseUrl}/canvas/tokenize?token=${accessToken}&eventId=${eventId}`;

        if (devMode) {
            redirectUrl = 'http://localhost:32954/tokenize';
        }

       return redirectUrl;
    }

    public listAllEventsForUserByPool(userId, teamId) {
        if (this.eventListOptions.query && this.eventListOptions.query.length) {
            this.eventListOptions.pagination.currentEventsPage = 1;
        }

        if (!teamId && this.currentTeamId) {
            teamId = this.currentTeamId
        }

        return this.apiClient.getUseCaseFactory().Event().ListEventsForUser(
            userId,
            this.eventListOptions.query || '',
            this.eventListOptions.attributesFilter,
            this.eventListOptions.attributesExcludeFilter,
            ['Pool', 'Tags', 'ThumbnailUrl'],
            null,
            this.eventListOptions.pagination.currentEventsPage,
            this.eventListOptions.pagination.resultsPerPage,
            this.eventListOptions.sortBy,
            this.eventListOptions.sortDirection,
            this.eventListOptions.eventDateFilterType,
            teamId,
            this.eventListOptions.tags,
            null,
            false,
            true
        ).pipe(map(res => {
            this.eventsMeta$.next(res['meta']);
            const events = res['data'].map(event => EfEvent.fromApiResponse(event));
            return events;
        }));
    }

    public async getEvent(id) {
        return this.apiClient.getUseCaseFactory().Event().GetEvent(
            id
        ).toPromise();
    }

    public async connectToZoom(withEvent: boolean = false) {
        const response = await this.apiClient.getUseCaseFactory().WebConference().GetWebConferenceRedirectURIByType(this.currentTeam.id, 'zoom').toPromise();

        if (withEvent) {
            this.sessionStorageService.set('globalZoomIntegrationsAuth&user=' + this.currentUser.id + '&team=' + this.currentTeam.id + '&event=' + this.currentEvent.id, true);
            location.assign('https://zoom.us/oauth/authorize?response_type=code&client_id='+ response.data.clientId + '&redirect_uri='+ encodeURI(response.data.uri) + '&state=' + encodeURIComponent(JSON.stringify({"poolId":this.currentTeam.id, "userId": this.currentUser.id, "eventId": this.currentEvent.id})));
        } else {
            this.sessionStorageService.set('globalZoomIntegrationsAuth&user=' + this.currentUser.id + '&team=' + this.currentTeam.id, true);
            location.assign('https://zoom.us/oauth/authorize?response_type=code&client_id='+ response.data.clientId + '&redirect_uri='+ encodeURI(response.data.uri) + '&state=' + encodeURIComponent(JSON.stringify({"poolId":this.currentTeam.id, "userId": this.currentUser.id})));
        }
    }

    public ticketTypeNameFromId(id: string): string{
        const filtered = this.currentEvent.ticketTypes.filter(tt => tt.id === id);
        if (filtered.length > 0){
            return filtered[0].name;
        } else {
            return '';
        }
    }

}

export interface Pagination {
    currentEventsPage: number;
    currentTicketBlocksPage: number;
    resultsPerPage: number;
}

export interface EventListOptions {
    userId?: string;
    query?: string;
    attributesFilter?: any[];
    attributesExcludeFilter?: any[];
    withData?: any;
    lastModifiedTimestamp?;
    page?;
    itemsPerPage?;
    sortBy?: string;
    sortDirection?: string;
    eventDateFilterType?;
    teamId?: string;
    pagination: Pagination;
    tags?;
    isListView: boolean;
    filterOptionId?: number;
}
