import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventTypeaheadComponent } from './event-typeahead.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [EventTypeaheadComponent],
    exports: [EventTypeaheadComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUtilitiesModule
    ],
})

export class EventTypeaheadModule {
}
