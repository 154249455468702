import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Router, ActivatedRoute } from '@angular/router';
import {trigger, animate, style, group, animateChild, query, stagger, transition} from '@angular/animations';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { CreateEventService } from '../create-event.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription ,  Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store';
import { filter, shareReplay } from 'rxjs/operators';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
    template: require('./select-event-type.html'),
    styles: [require('./select-event-type.layout.scss')]
})

export class SelectEventTypeComponent implements OnInit, OnDestroy {

    private eventCounts;
    private teamStore$: Observable<any> = this.store.select('team');
    private teamUpdate$: Subscription;

    constructor(
        private router: Router,
        private store: Store<fromRoot.AppState>,
        private route: ActivatedRoute,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private createEventService: CreateEventService,
        private message: NzMessageService,
        private segmentService: SegmentService
    ) {
        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.updateCounts();
        });
    }

    ngOnInit() {
        this.eventCounts = this.route.snapshot.data['eventCounts'];
        this.createEventService.getEventTypeCounts(this.eventCounts.data);
    }

    async updateCounts() {
        this.eventCounts = await this.createEventService.listPoolAllotmentsForPool(this.eventFarmService.currentTeam.id).toPromise();
        this.createEventService.getEventTypeCounts(this.eventCounts.data);
    }

    goToCreateEvent(type) {

        if (!this.checkAvailableEvents(type)) {
            this.message.info('You are out of events.');
            return false;
        }

        switch (type) {
            case 'full-event':
                this.segmentService.segmentEventTracking().fullEventButtonClicked();
                break;
            case 'cio-event':
                if (this.createEventService.cioEventsRemaining === 0) {
                    this.segmentService.segmentEventTracking().pqlGetCioEventButtonClicked();
                } else {
                    this.segmentService.segmentEventTracking().cioEventButtonClicked();
                }
                break;
            default:
                break;
        }

        this.router.navigate([this.router.url, type])
            .then(() => {})
            .catch(() => {});
    }

    private checkAvailableEvents(type) {
        if (type === 'full-event' && this.createEventService.fullEventsRemaining === 0) {
            return false;
        }
        return true;
    }

    ngOnDestroy() {
        this.teamUpdate$.unsubscribe();
    }

}
