import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateEventComponent } from './create-event.component';
import { CreateFullEventComponent } from './CreateEventDetails/create-event-details.component';
import { SelectEventTypeComponent } from './SelectEventType/select-event-type.component';
import { GetFreeEventComponent } from './GetFreeEvent/get-free-event.component';

import { CreateEventResolve } from './create-event.resolve';

import * as guards from './guards';

const routes: Routes = [
    {
        path: '',
        component: CreateEventComponent,
        data: { state: 'select-event-type' },
        canActivate: [guards.CreateEventPermissionsGuard],
        resolve: {
            eventCounts: CreateEventResolve
        },
        children: [
            {
                path: '',
                component: SelectEventTypeComponent,
                data: { state: 'select-event-type' },
            },
            {
                path: 'full-event',
                component: CreateFullEventComponent,
                canActivate: [guards.CanCreateEventGuard],
                data: { state: 'create-full-event' },
            },
            {
                path: 'cio-event',
                component: CreateFullEventComponent,
                canActivate: [guards.CanCreateEventGuard],
                data: { state: 'create-cio-event' },
            },
            {
                path: 'get-free-event',
                component: GetFreeEventComponent,
                data: { state: 'get-free-event' }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class CreateEventRoutingModule {
}
