import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from '@khazii/ngx-intl-tel-input';

import {IntlPhoneInputComponent} from './intl-phone-input.component';

const forExport = [
    IntlPhoneInputComponent,
];


@NgModule({
    declarations: forExport,
    exports: forExport,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
    ],
})

export class IntlPhoneInputModule {
}
