import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Subscription ,  Observable, from ,  BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { AddressBookService } from '../addressBook.service';
import { Group } from '../../../../ApiClient/Models/Group/group';
import { Router } from '@angular/router';
import { AddressBookUserService } from './addressBookUser.service';
import { User } from '../../../../ApiClient/Models/User/user';

import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Modal } from '../../../CoreUI/Modal/modal.class';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { AddressBookBulkAddUsersToGroup } from './Bulk/address-book-bulk-add-users-to-group.component';
import { AlertService } from '../../../eventFarmAlert.service';
import { ApiError } from '../../../../ApiClient/Models/error';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as fromRoot from '../../../store';
import { filter, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'address-book-user-list',
    template: require('./address-book-user-list.html'),
    styles: [require('./address-book-user-list.scss')],
})

export class AddressBookUserListComponent implements OnInit, OnDestroy {
    readonly ellipsisSVG = require('../assets/ellipsis');
    @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;

    constructor(
        private eventFarmService: EventFarmService,
        private addressBookService: AddressBookService,
        private addressBookUserService: AddressBookUserService,
        private router: Router,
        private message: NzMessageService,
        private routeGenerator: RouteGeneratorService,
        private segmentService: SegmentService,
        private store: Store<fromRoot.AppState>,
        private modalService: ModalService,
        private alertService: AlertService,
    ) {
        this.currentGroup$ = addressBookService.selectedGroup$.subscribe((res: Group) => {
            this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
            if (this.addressBookService.selectedGroup) {
                this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
            } else {
                this.addressBookUserService.broadcastAllUsers();
            }
        });
        this.teamUpdate$ = this.teamStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
            this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
            this.addressBookService.usersSelectedInList = [];
            if (this.eventFarmService.currentTeam && this.addressBookService.currentTeam && this.addressBookService.currentTeam.id !== this.eventFarmService.currentTeam.id) {
                this.addressBookService.currentTeam = this.eventFarmService.currentTeam;
                this.router.navigateByUrl(this.routeGenerator.url('pools.address-book.list', { poolId: this.addressBookService.currentTeam.id }));
                if (this.addressBookService.selectedGroup) {
                    this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                } else {
                    this.addressBookUserService.broadcastAllUsers();
                }
            }
        });
    }

    private teamStore$: Observable<any> = this.store.select('team');
    private teamUpdate$: Subscription;
    currentGroup$: Subscription;
    errors: ApiError[];
    requesting: boolean;
    placeholder = new BehaviorSubject({
        name: 'Select Bulk Action...'
    });
    dropdownOptionList = new BehaviorSubject([{
        id: 0,
        name: ''
    }]);

    private currentViewContacts: User[];
    private bulkOptionListFull = [
        {
            id: 0,
            name: 'Add to Other Group'
        },
        {
            id: 1,
            name: 'Remove from Group'
        },
        // {
        //     id: 2,
        //     name: 'Delete Contacts'
        // }
    ];

    private dropdownConfig = {
        options: this.dropdownOptionList,
        placeholder: this.placeholder,
        error: false
    };

    ngOnInit() {
        this.currentViewContacts = [];
        this.addressBookService.usersSelectedInList = [];
        this.addressBookService.isLoadingContacts = false;
        this.requesting = false;
        this.getDropdownOptionList();
        this.addressBookUserService.broadcastAllUsers();
    }

    ngAfterViewInit() {
        const flexContainerThatScrolls = document.querySelector('#event-tab-container') || window;
        this.addressBookUserService.users$.subscribe(res => {
            this.currentViewContacts = res;
        });
    }

    ngOnDestroy() {
        this.currentGroup$.unsubscribe();
        this.teamUpdate$.unsubscribe();
    }

    public getDropdownOptionList() {
        this.dropdownOptionList.next(this.bulkOptionListFull.filter(option => option.id !== 1));
        this.addressBookService.selectedGroup$.subscribe(
            (res) => {
                if (!this.addressBookService.selectedGroup || (this.addressBookService.selectedGroup && this.addressBookService.selectedGroup.name === 'Added By Me')) {
                    this.dropdownOptionList.next(this.bulkOptionListFull.filter(option => option.id !== 1));
                } else  {
                    this.dropdownOptionList.next(this.bulkOptionListFull);
                }
            }
        );
    }

    public isAddedByMeSelected() {
        if (this.addressBookService.selectedGroup && this.addressBookService.selectedGroup.name === 'Added By Me') {
            return true;
        } else {
            return false;
        }
    }

    public actionAddContactToGroup(user) {
        this.addressBookService.broadcastUser(user);
        this.addressBookService.openEditUserModal(user.id, 'group');
        this.closePopovers();
    }

    public actionDeleteContact(user: User) {
        this.requesting = true;
        this.segmentService.segmentAddressBookTracking().startDeleteContact(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.addressBookUserService.checkIfUserCanBeDeleted(
            user.id,
            this.eventFarmService.currentUser.id,
            this.addressBookService.currentTeam.id
        )
            .subscribe(
                (res) => {
                    this.requesting = false;
                    if (res.data && res.data.attributes) {
                        this.alertService.addressBook().confirmUserRemove(user.name && user.name.fullName ? user.name.fullName : 'this contact')
                            .then((res) => {
                                if (res.value) {
                                    this.segmentService.segmentAddressBookTracking().confirmDeleteContact(
                                        this.eventFarmService.currentEvent,
                                        this.eventFarmService.currentUser
                                    );
                                    this.handleUserDeletionResponse(user);
                                }
                            });
                    } else {
                        this.alertService.addressBook().cannotRemoveUser('Insufficient permissions.');
                    }
                },
                (err) => {
                    this.requesting = false;
                    let errorString = this.assembleErrorString(err.error.errors);
                    this.alertService.addressBook().cannotRemoveUser(errorString);
                });
    }

    public handleUserDeletionResponse(user) {
        this.addressBookUserService.removeUsersFromPool([user.id], this.addressBookService.currentTeam.id)
            .subscribe((val) => {
                    this.addressBookUserService.userListOptions.pagination.currentUsersPage = 1;
                    this.message.success('Contact deleted');
                    if (this.addressBookService.selectedGroup) {
                        this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                    } else {
                        this.addressBookUserService.broadcastAllUsers();
                    }
                },
                (err) => {
                    let errorString = this.assembleErrorString(err.error.errors);
                    this.alertService.addressBook().unableToRemoveUser(user.name && user.name.fullName ? user.name.fullName : 'this contact', errorString);
                });
    }

    public actionRemoveSingleUserFromGroup(user: User) {
        this.segmentService.segmentAddressBookTracking().startRemoveContactFromGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            this.addressBookService.selectedGroup
        );
        this.alertService.addressBook().removeSingleUserFromGroupWarning(user.name.fullName, this.addressBookService.selectedGroup.name)
            .then((res) => {
                if (res.value) {
                    this.segmentService.segmentAddressBookTracking().confirmRemoveContactFromGroup(
                        this.eventFarmService.currentEvent,
                        this.eventFarmService.currentUser,
                        this.addressBookService.selectedGroup
                    );
                    this.submitRemoveSingleUserFromGroup(user.id);
                }
            });
    }

    private submitRemoveSingleUserFromGroup(userId) {
        this.addressBookService.isLoadingContacts = true;
        let singleUserArray = [];
        singleUserArray.push(userId);
        return this.addressBookUserService.removeUsersFromGroup(this.addressBookService.selectedGroup.id, singleUserArray).toPromise()
            .then((res) => {
                this.message.success('Contact removed from group');
                this.addressBookService.usersSelectedInList = [];
                this.addressBookService.broadcastGroup(this.addressBookService.selectedGroup);
                this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
            }).catch((err) => {
                this.addressBookService.isLoadingContacts = false;
                this.alertService.addressBook().performActionFailure();
            });
    }

    public actionBulkRemoveUsersFromGroup() {
        this.segmentService.segmentAddressBookTracking().startBulkRemoveUsersFromGroup(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser,
            this.addressBookService.selectedGroup
        );
        this.alertService.addressBook().bulkRemoveUsersFromGroupWarning(this.addressBookService.selectedGroup.name)
            .then((res) => {
                if (res.value) {
                    this.segmentService.segmentAddressBookTracking().confirmBulkRemoveUsersFromGroup(
                        this.eventFarmService.currentEvent,
                        this.eventFarmService.currentUser,
                        this.addressBookService.selectedGroup
                    );
                    this.submitBulkRemoveUsersFromGroup();
                }
            });
    }

    private submitBulkRemoveUsersFromGroup() {
        this.addressBookService.isLoadingContacts = true;
        return this.addressBookUserService.removeUsersFromGroup(this.addressBookService.selectedGroup.id, this.addressBookService.usersSelectedInList).toPromise()
            .then((res) => {
                this.addressBookService.usersSelectedInList = [];
                this.addressBookService.broadcastGroup(this.addressBookService.selectedGroup);
                this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                this.message.success('Contacts removed from group');
            }).catch((err) => {
                this.addressBookService.isLoadingContacts = false;
                this.alertService.addressBook().performActionFailure();
            });
    }

    public actionBulkDeleteUsers() {
        const maxAllowed = 50;
        if (this.addressBookService.usersSelectedInList.length > maxAllowed) {
            this.alertService.addressBook().confirmMaxAllowedContactRemoval(maxAllowed)
                .then((res) => {
                    if (res.value) {
                        this.addressBookService.usersSelectedInList = this.addressBookService.usersSelectedInList.slice(0, maxAllowed);
                        this.removeUsers();
                    }
                });
            return;
        }
        this.alertService.addressBook().confirmMultipleUserRemove(this.addressBookService.usersSelectedInList.length)
            .then((alertRes) => {
                if (alertRes.value) {
                    this.removeUsers();
                }
            });
    }

    public removeUsers() {
        this.addressBookUserService.removeUsersFromPool(this.addressBookService.usersSelectedInList, this.eventFarmService.currentTeam.id).toPromise()
            .then((res) => {
                if (this.addressBookService.selectedGroup) {
                    this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                } else {
                    this.addressBookUserService.broadcastAllUsers();
                }
                this.message.success('Contacts deleted');
            }).catch((err) => {
                const errors = this.addressBookUserService.extractErrorsIntoMessages(err.error);
                let errorString = '';
                errors.forEach((error) => {
                    errorString += error.detail + ' ';
                });
                this.alertService.addressBook().unableToDeleteContacts(errorString);
                return;
            }).then((res) => {
                if (this.addressBookService.selectedGroup) {
                    this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
                } else {
                    this.addressBookUserService.broadcastAllUsers();
                }
            });
    }

    public closePopovers() {
        this.segmentService.segmentAddressBookTracking().toggleUserOptionsPopover(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.popovers.forEach((popover: PopoverDirective) => {
            popover.hide();
        });
    }

    public openAddUserModal() {
        this.addressBookService.openAddUserModal();
        this.closePopovers();
    }

    public openEditUserModal(user: User) {
        this.segmentService.segmentAddressBookTracking().openEditUserModal(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.addressBookService.broadcastUser(user);
        this.addressBookService.openEditUserModal(user.id);
        this.closePopovers();
    }

    public openBulkAddToGroupModal() {
        const modal: Modal = new Modal();
        if (this.addressBookService.usersSelectedInList.length === 1) {
            modal.title = 'ADD ' + this.addressBookService.usersSelectedInList.length + ' CONTACT TO GROUP';
        } else {
            modal.title = 'ADD ' + this.addressBookService.usersSelectedInList.length + ' CONTACTS TO GROUP';
        }
        modal.component = AddressBookBulkAddUsersToGroup;
        this.modalService.changeModal(modal);
    }

    public selectGroup(group: Group) {
        this.addressBookService.usersSelectedInList = [];
        this.addressBookService.broadcastGroup(group);
        this.closePopovers();
    }

    public addToSelectedContacts(id) {
        if (this.checkIfSelectedContact(id)) {
            for (let i = this.addressBookService.usersSelectedInList.length - 1; i >= 0; i--) {
                if (this.addressBookService.usersSelectedInList[i] === id) {
                    this.addressBookService.usersSelectedInList.splice(i, 1);
                    break;
                }
            }
        } else {
            if (this.checkForSelectedContactsLimit()) {
                this.alertService.addressBook().selectedContactsLimitError();
                return;
            }
            this.addressBookService.usersSelectedInList.push(id);
        }
    }

    public checkIfSelectedContact(id) {
        if (this.addressBookService.usersSelectedInList.indexOf(id) > -1) {
            return true;
        } else {
            return false;
        }
    }

    public selectAllContacts() {
        if (this.checkIfAllContactsSelected()) {
            for (let i = 0; i < this.currentViewContacts.length; ++i) {
                this.addToSelectedContacts(this.currentViewContacts[i].id);
            }
        } else {
            for (let i = 0; i < this.currentViewContacts.length; ++i) {
                if (this.addressBookService.usersSelectedInList.indexOf(this.currentViewContacts[i].id) < 0) {
                    this.addToSelectedContacts(this.currentViewContacts[i].id);
                }
            }
        }
    }

    public checkIfAllContactsSelected() {
        let contactsSelectedCount = 0;
        for (let i = 0; i < this.currentViewContacts.length; ++i) {
            if (this.checkIfSelectedContact(this.currentViewContacts[i].id)) {
                contactsSelectedCount++;
            }
        }
        if (contactsSelectedCount === this.currentViewContacts.length && this.currentViewContacts.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    checkForSelectedContactsLimit() {
        if (this.addressBookService.usersSelectedInList.length  >= 1000) {
            return true;
        }
    }

    public performBulkAction(event) {
        if (event.id === 0) {
            this.openBulkAddToGroupModal();
        } else if (event.id === 1) {
            this.actionBulkRemoveUsersFromGroup();
        } else if (event.id === 2) {
            this.actionBulkDeleteUsers();
        }
    }

    public assembleErrorString(err): string {
        let errorString = '';
        if (err.length) {
            err.forEach((error) => {
                errorString +=  error.detail;
            });
        }
        return errorString;
    }

    public changePage(page) {
        this.addressBookService.isLoadingContacts = true;
        this.addressBookUserService.userListOptions.pagination.currentUsersPage = page;
        if (this.addressBookService.selectedGroup) {
            this.addressBookUserService.broadcastUsersInGroup(this.addressBookService.selectedGroup.id);
        } else {
            this.addressBookUserService.broadcastAllUsers();
        }
    }
}
