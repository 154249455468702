import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UploaderService } from '../uploader.service';
import { UploaderTabsService } from '../uploaderTabs.service';
import { QueueCommand } from '../../../../ApiClient/Models/Queue/queue-command';
import { Subscription } from 'rxjs';
import { ProcessingService } from './processing.service';

@Component({
    selector: 'progress-display',
    providers: [
    ],
    template: require('./progress-display.html'),
    styles: [require('./progress-display.scss')],
    encapsulation: ViewEncapsulation.None,
})

export class ProgressDisplayComponent implements OnDestroy {

    currentProgress: QueueCommand;
    userFriendlyPercent: number = 0;
    error: boolean = false;
    progress$: Subscription;

    constructor(
        private uploaderService: UploaderService,
        private uploaderTabsService: UploaderTabsService,
        private processingService: ProcessingService
    ) {
        this.progress$ = this.processingService.progressData$.subscribe((val: QueueCommand|any) => {
            this.currentProgress = val;
            this.error = false;
            this.userFriendlyPercent = val['percentDone'];
        });
    }

    retry() {
        this.processingService.retryUpload();
    }

    ngOnDestroy() {
        if (this.processingService.pollingTask) {
            this.processingService.pollingTask.unsubscribe();
        }
        this.progress$.unsubscribe();
    }
}
