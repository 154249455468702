import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { User } from '../../../../ApiClient/Models/User/user';
import { AlertService } from '../../../eventFarmAlert.service';
import { Transfer } from '../../../../ApiClient/Models/Transfer/transfer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';


@Injectable()
export class GuestListTransfersService {

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private alertService: AlertService,
        private messageService: NzMessageService,
        private segmentService: SegmentService


    ) {
    }

    public transferSource = new Subject<Transfer[]>();
    public transferMetaSource = new BehaviorSubject<any>({});
    public transfers$ = this.transferSource.asObservable();
    public transfersMeta$ = this.transferMetaSource.asObservable();
    public isLoadingTransfers = true;

    public transfersListOptions: TransfersListOptions = {
        query: null,
        sortBy: 'confirmed',
        sortDirection: 'descending',
        pagination: {
            currentTransfersPage: 1,
            itemsPerPage: 20,
        }
    };

    public async getTransfersForEvent() {


        try {

            const t = await this.apiClient.getUseCaseFactory()
            .Transfer()
            .ListTransfersForEvent(
                this.eventFarmService.currentEvent.id, [],
                this.transfersListOptions.pagination.currentTransfersPage,
                this.transfersListOptions.pagination.itemsPerPage,
                this.transfersListOptions.sortBy,
                this.transfersListOptions.sortDirection
            ).toPromise();

            this.transferMetaSource.next(t.meta);
            const transfers: Transfer[] = t.data.map((transfer) => {
                return Transfer.fromApiResponse(transfer);
            });
            this.transferSource.next(transfers);
            this.isLoadingTransfers = false;

        } catch (error) {
            this.isLoadingTransfers = false;
        }

    }

    public async resendTransfer(id: string) {
        try {
            const resend = await this.apiClient.getUseCaseFactory()
            .Transfer()
            .ResendAuthorizationRequestEmail(id)
            .toPromise();

            this.segmentService.segmentTransfersTracking().transfersResendEmail({id});

            this.messageService.success('Transfer email resent');
        } catch (error) {
            this.messageService.error('Please try again');
        }
    }

    public async forceTransfer(id: string) {
        try {
            const force = await this.apiClient.getUseCaseFactory()
            .Transfer()
            .ForceTransfer(id)
            .toPromise();
            this.segmentService.segmentTransfersTracking().transfersForceConfirmed({id});

            this.messageService.success('Transfer confirmed');

            this.getTransfersForEvent();


        } catch (error) {
            this.messageService.error('Please try again');
        }
    }

    public reset() {
        this.transferMetaSource.next(null);
        this.transferSource.next([]);
        this.transfersListOptions.pagination.currentTransfersPage = 1;

    }
}

export interface Pagination {
    currentTransfersPage: number;
    itemsPerPage: number;
}

export interface TransfersListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}
