import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { shareReplay } from 'rxjs/operators';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditStationComponent } from '../EditStation/edit-station.component';
import { EfxStation } from '../../../../../../ApiClient/Models/EfxStation/efxStation';
import { EfxStationService } from '../../efx-station.service';

@Component({
    selector: '[station-item]',
    template: require('./station-item.html'),
    styles: [require('./station-item.scss')],
})

export class StationItemComponent implements OnInit {
    @Input() efxStationItem: EfxStation;
    public componentClicked: boolean = false;

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    constructor(
        private store: Store<fromRoot.AppState>,
        private drawerService: NzDrawerService,
        private stationService: EfxStationService
    ) { }

    ngOnInit() {
    }

    openComponent(): void {
        if (!this.componentClicked) {
            this.componentClicked = true;
            const drawerRef = this.drawerService.create<EditStationComponent, { efxStationItem: EfxStation }, string>({
                nzTitle: `Edit ${this.stationService.currentModule} Station`,
                nzContent: EditStationComponent,
                nzWidth: '50%',
                nzContentParams: {
                    efxStationItem: this.efxStationItem,
                }
            });

            drawerRef.afterOpen.subscribe(() => {
            });

            drawerRef.afterClose.subscribe(data => {
                this.componentClicked = false;
            });
        }
    }
}
