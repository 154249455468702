import * as _ from "lodash";
import {PoolFeature} from "../../../ApiClient/Models/PoolFeature/poolFeature";
import { EventFarmService } from '../../eventFarm.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '..';
import * as eventActions from '../actions/event';
import * as teamActions from '../actions/team.action';
import * as userActions from '../actions/user.action';
import * as miscActions from '../actions/misc.action';

import { mergeMap, switchMap, map, filter, catchError, tap, take, withLatestFrom, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { PoolWorld } from '../../../ApiClient/Models/PoolWorld/pool-world';
import { WebConferenceUserConnection } from '../../../ApiClient/Models/WebConferenceUserConnection/web-conference-user-connection';
import { StoreDevtools } from '@ngrx/store-devtools';
import {LocalStorageService} from '../../../_services/storage/local-storage.service';

@Injectable()
export class TeamEffects {
    constructor(
        private actions$: Actions,
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private store: Store<fromStore.AppState>,
        private localStorageService: LocalStorageService
    ) { }

    setCurrentTeam$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.SetCurrentTeam>(teamActions.SET_CURRENT_TEAM),
        map(action => action.payload),
        switchMap(team => [
            new teamActions.SetCurrentTeamSuccess(team),
        ]),
        catchError(error => {
            return of(new teamActions.SetCurrentTeamFail({ payload: error }));
        })
    ));

    setCurrentTeamSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.SetCurrentTeamSuccess>(teamActions.SET_CURRENT_TEAM_SUCCESS),
        map(action => action.payload),
        filter(team => !!team),
        switchMap(team => [
            new userActions.GetUserPoolPermissions(team['id']),
            new teamActions.FetchContractForTeam(team['id']),
            new teamActions.FetchFeaturesForTeam(team['id']),
            new teamActions.FetchEventsForTeam(team['id']),
            new teamActions.FetchPoolWorldsForTeam(team['id']),
        ]),
    ));

    setFetchFeaturesForTeamSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.SetCurrentTeamSuccess>(teamActions.FETCH_FEATURES_FOR_TEAM_SUCCESS),
        map(action => action.payload),
        withLatestFrom(this.store.select('event')),
        switchMap(([action, event]) => {
            const actionsToDispatch = [];
            if (event && event.data && _.get(event, 'access.event-crud') && this.eventFarmService.salesforceEnabled) {
                actionsToDispatch.push(new eventActions.FetchSalesForceIntegrationForEvent())
            }
            if (this.eventFarmService.webConferenceEnabled) {
                actionsToDispatch.push(new teamActions.FetchWebConferenceConnectionsForTeam(this.eventFarmService.currentTeam['id']))
            }
            if(actionsToDispatch.length === 0 ) {
                // Only push if no other actions are in there
                actionsToDispatch.push(new miscActions.Noop(true))
            }
            return actionsToDispatch;
        })));

    // @ts-ignore
    fetchContractForTeam$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.FetchContractForTeam>(teamActions.FETCH_CONTRACT_FOR_TEAM),
        map(action => action.payload),
        filter(team => !!team),
        switchMap((teamId: string) => this.apiClient.getUseCaseFactory().Pool().GetPoolContract(teamId).pipe(
            switchMap(contract => [
                new teamActions.FetchContractForTeamSuccess(contract['data'])
            ]),
            catchError(err => of(new teamActions.FetchContractForTeamFail(err)))
        )),
    ));


    // @ts-ignore
    fetchFeaturesForTeam$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.FetchFeaturesForTeam>(teamActions.FETCH_FEATURES_FOR_TEAM),
        map(action => action.payload),
        switchMap((teamId: string) => this.apiClient.getUseCaseFactory().PoolFeature().ListFeaturesForPool(teamId).pipe(
            switchMap(features => [
                new teamActions.FetchFeaturesForTeamSuccess(features['data'])
            ]),
        )),
        catchError(err => of(new teamActions.FetchFeaturesForTeamFail(err)))
    ));


    // @ts-ignore
    fetchPoolWorldsForTeam$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.FetchPoolWorldsForTeam>(teamActions.FETCH_POOL_WORLDS_FOR_TEAM),
        map(action => action.payload),
        switchMap((teamId: string) => this.apiClient.getUseCaseFactory().PoolWorld().ListPoolWorldsForPool(teamId)
            .pipe(
                map(poolWorlds => {
                    return poolWorlds['data']
                        .map(pw => PoolWorld.fromApiResponse(pw));
                }),
                switchMap(poolWorlds => [
                    new teamActions.FetchPoolWorldsForTeamSuccess(poolWorlds)
                ]),
            )),
        catchError(err => of(new teamActions.FetchPoolWorldsForTeamFail(err)))
    ));


    // @ts-ignore
    fetchWebConferenceConnectionsForTeam$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.FetchWebConferenceConnectionsForTeam>(teamActions.FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM),
        map(action => action.payload),
        switchMap((teamId: string) => this.apiClient.getUseCaseFactory().WebConference().ListWebConferenceConnectionsForPool(teamId)
            .pipe(
                map(connections => {
                    return connections['data']
                        .map(c => WebConferenceUserConnection.fromApiResponse(c));
                }),
                switchMap(connections => [
                    new teamActions.FetchWebConferenceConnectionsForTeamSuccess(connections)
                ]),
            )),
        catchError(err => of(new teamActions.FetchWebConferenceConnectionsForTeamFail(err)))
    ));


    // @ts-ignore
    fetchEventsForTeam$ = createEffect(() => this.actions$.pipe(
        ofType<teamActions.FetchEventsForTeam>(teamActions.FETCH_EVENTS_FOR_TEAM),
        map(action => action.payload),
        withLatestFrom(this.store),
        map(([team, store]) => team ? team : store.router.state.params.pool),
        withLatestFrom(this.store),
        mergeMap(([team, store]) => this.eventFarmService.listAllEventsForUserByPool(this.localStorageService.get('effectiveEfUserId'), team).pipe(
            switchMap((events: EfEvent[]) => [
                new teamActions.FetchEventsForTeamSuccess(events)
            ])
        )),
    ));
}
