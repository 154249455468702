import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/browser";
import * as http from "http";
const storagePrefix = 'eventFarm.';
import {getEnvironment} from './getEnvironment'
import * as _ from 'lodash'
import { httpClientIntegration  } from "@sentry/integrations";

const configureSentry = () => {
  const isProduction = getEnvironment() === "production";
    Sentry.init({
      dsn: "https://aec384a8f5984147bd87200bdd2bf4bf@o33949.ingest.sentry.io/4504900731731968",
      release: 'wakanda@' + process.env.npm_package_version,
      environment: getEnvironment(),
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: ["localhost:8080/api/v2", "https://prelive-eventfarm.com/api/v2", "https://eventfarm.com/api/v2"],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Integrations.Breadcrumbs({
          console: isProduction ? false : true
        }),
        httpClientIntegration({
          failedRequestStatusCodes: [400, 401, 403]
        }),
        // new Sentry.Replay({
        //   // Additional SDK configuration goes in here, for example:
        //   maskAllText: true,
        //   blockAllMedia: true,
        // }),
      ],
      beforeSend(event) {
          event.tags = event.tags || {};
          const tags = buildTags();
          Object.keys(tags).forEach((key) => {
            event.tags[key] = tags[key];
          });
         // processTags(event)

        return event;
      },
      beforeSendTransaction(event) {
        event.tags = event.tags || {};
          const tags = buildTags();
          Object.keys(tags).forEach((key) => {
            event.tags[key] = tags[key];
          });
        return event;

      },
      tracesSampleRate: isProduction ? 0.2 : 1.0,
      // replaysSessionSampleRate: isProduction ? 0.2 : 1.0,
      // replaysOnErrorSampleRate: isProduction ? 0.2 : 1.0,
  });
}

// const getLastBreadcrumb = (event) => {
//   let lastBreadcrumb;
//   const lastBreadcrumbIdx = _.get(event, 'breadcrumbs.length', 0)
//   if(event.breadcrumbs && event.breadcrumbs.length > 1){
//     lastBreadcrumb = event.breadcrumbs[lastBreadcrumbIdx-1]
//   }
//   return lastBreadcrumb
// }

// const processTags = (event) => {
//   event.tags = event.tags || {};
//   const tags = buildTags();
//   Object.keys(tags).forEach((key) => {
//     event.tags[key] = tags[key];
//   });
//   return event
// }

const buildTags = () => {
  const pool = getValueFromSessionStorage('pool');
  const event = getValueFromSessionStorage('event');

  const tags = {};

  if (pool) {
    const poolId = pool['id'] || pool['_id'];

    if (poolId && poolId.length) {
      tags['poolId'] = poolId;
    }
  }

  if (event) {
    const eventId = event['id'] || event['_id'];
    if (eventId && eventId.length) {
      tags['eventId'] = eventId;
    }
  }

  return tags;
}

const getValueFromSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(storagePrefix + key);

  if (!item || item === 'null') {
      return null;
  }

  try {
      return JSON.parse(item);
  } catch (e) {
      return null;
  }
}

export default configureSentry;





