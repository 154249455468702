import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
    CanLoad,
    Route,
    UrlSegment,
    CanActivateChild,
} from '@angular/router';
import {FusionAuthService} from "@fusionauth/angular-sdk";
import {Observable, of} from 'rxjs';
import { tap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(private fusionAuthService: FusionAuthService) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return of(this.fusionAuthService.isLoggedIn()).pipe(take(1));
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.redirectIfUnauthenticated(state);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.redirectIfUnauthenticated(state);
    }

    private redirectIfUnauthenticated(
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return of(this.fusionAuthService.isLoggedIn()).pipe(
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.fusionAuthService.startLogin();
                }
            })
        );
    }
}