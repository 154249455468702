import { Component, OnDestroy, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { WebPresenceService } from '../../web-presence.service';
import { NzMessageService } from 'ng-zorro-antd/message';

import decodeHTML from '../../../../../Utilities/decode.html';

@Component({
  selector: 'web-presence-message-configure',
  template: require('./web-presence-message-configure.html'),
  styles: [require('./web-presence-message-configure.scss'), require('../../web-presence.scss')],

})

export class WebPresenceMessageConfigureComponent implements OnDestroy, OnInit, AfterViewInit {

  constructor(
    private webPresenceService: WebPresenceService,
    private message: NzMessageService
  ) {
  }

  private editingModeEnabled: boolean;
  private eventMessage: string;

  private options: any;
  ngOnInit() {
    this.editingModeEnabled = false;
    this.options = this.webPresenceService.displayMessageOptions;
  }

  ngAfterViewInit() {
  }

  editMessage() {
    if (this.editingModeEnabled) {
      this.webPresenceService.currentEditor = null;
      this.webPresenceService.saveMessage(this.eventMessage).then(res => {
        this.editingModeEnabled = !this.editingModeEnabled;
        this.webPresenceService.toggleMessageSelect();
        this.eventMessage = '';
      }).catch(e => {
      });

    } else {
      this.setEventMessage();
      this.webPresenceService.toggleMessageSelect();
      this.editingModeEnabled = !this.editingModeEnabled;

    }
  }

  cancelEdit() {
    this.editingModeEnabled = !this.editingModeEnabled;
    this.webPresenceService.toggleMessageSelect();
    this.eventMessage = '';
  }

  private setEventMessage() {
    if (this.webPresenceService.currentMessageType.value) {
      this.eventMessage = this.webPresenceService.currentMessageType.value;
    } else if (this.webPresenceService.currentMessageType.defaultValue) {
      this.eventMessage = decodeHTML(this.webPresenceService.currentMessageType.defaultValue);
    } else {
      this.eventMessage = '';
    }
  }

  get isPreviewAvailableForCurrentMessage() {
    let available = { public: false, invite: false };
    switch (this.webPresenceService.currentMessageType.slug) {
      case 'additional':
        available = { public: true, invite: false };
        break;

      case 'confirmation':
        available = { public: true, invite: false };
        break;

      case 'additional_invitation':
        available = { public: false, invite: true };
        break;

      case 'confirmation_invitation':
        available = { public: false, invite: true };
        break;

      case 'wait_list_confirmation_invitation':
        available = { public: false, invite: true };
        break;

      case 'declination':
        available = { public: false, invite: true };
        break;

      case 'opening':
        available = { public: true, invite: true };
        break;

      case 'closing':
        available = { public: true, invite: true };
        break;

      case 'wait_list_confirmation':
        available = { public: true, invite: false };
        break;

      case 'soldout':
        available = { public: true, invite: true };
        break;

      case 'reveal':
        available = { public: false, invite: true };
        break;

      case 'disclaimer':
        available = { public: false, invite: false };
        break;

      case 'response_restriction':
        available = { public: true, invite: true };
        break;
    }
    return available;
  }

  openPreviewDisplayMessageModal(flow?: 'public' | 'invite') {
    this.webPresenceService.openPreviewDisplayMessageModal(flow);
  }

  addSlugToFroala(select: HTMLSelectElement, slug: string) {
    this.webPresenceService.currentEditor.html.insert(slug);
    this.webPresenceService.currentEditor.undo.saveStep();
    select.value = null;
  }

  ngOnDestroy() {
  }
}
