import { AbstractBaseSegment } from './segment-base';
import * as actions from './actions';

export class SegmentPromotionTracking extends AbstractBaseSegment {

    /**
     * Promo Gen
     */

    generatePromotion(promoDetails: object) {
        this.track({
            event: actions.PROMO_CREATE,
            properties: {
                ...promoDetails
            }
        }, true);
    }

    /**
     * Promo Update
     */

    updatePromotion(promoDetails: object) {
        this.track({
            event: actions.PROMO_UPDATE,
            properties: {
                ...promoDetails
            }
        }, true);
    }

    /**
     * Promo Delete
     */

    deletePromotion(promoDetails: object) {
        this.track({
            event: actions.PROMO_DELETE,
            properties: {
                ...promoDetails
            }
        }, true);
    }

     /**
     * Promo open slide out
     */

    openEdit() {
        this.track({
            event: actions.PROMO_OPEN_SLIDEOUT,
        }, true);
    }

     /**
     * Promo close slide out
     */

    closeEdit() {
        this.track({
            event: actions.PROMO_CLOSE_SLIDEOUT,
        }, true);
    }

}