export interface IEntityList<T> {
   [id: string]: T;
}

export interface IEfDataState<T> {
   paginationMeta?: IPaginationMetadata;
   meta?: object;
   data: IEntityList<T>;
   loading: boolean;
   loaded: boolean;
}

export interface IComplexActionResponse<T> {
  type: string;
  payload: IEfDataState<T>;
}

export interface IPaginationMetadata {
  totalPages: number;
  totalResults: number;
  totalResultsReturned: number;
  currentPage: number;
  itemsPerPage: number;
}

export const DefaultEfDataState = {
    data: null,
    loading: false,
    loaded: false,
}
