import { Component, EventEmitter, Output, HostBinding, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../eventFarm.service';
import { EfEvent } from '../../../ApiClient/Models/Event/event';
import { RouteGeneratorService } from '../../../_services/routes/route-generator.service';
import { debounceTime, map, tap, distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'event-typeahead',
    template: require('./event-typeahead.html'),
    animations: [
        trigger('leavingState', [
            transition(':enter', [
                animate(0, style({ opacity: 0, top: '-1rem' })),
                animate('100ms ease-out', style({ opacity: 1, top: '0' })),
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0 })),
            ])
        ])
    ],
    host: {
        '[@leavingState]': '',
        '(document:click)': 'offClick($event)',
    }
})

export class EventTypeaheadComponent {
    private searchTerm: FormControl = new FormControl();
    private searchResults$ = new Subject<EfEvent[]>();
    private isSearching: boolean = false;
    @Input('placeholder') placeholderText: string = 'Search Event Name...';
    @Input('filterOutCioEvents') shouldFilterOutCioEvents: boolean = false;
    @Input('currentEventId') currentEventId: string|null = null;

    @Output() selectionReceived: EventEmitter<any> = new EventEmitter();

    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private elementRef: ElementRef
    ) {
        this.searchEvents();
        this.isSearching = true;
    }

    @HostBinding('class.active') activeInput: boolean;

    private eventListOptions: EventListOptions = {
        query: null,
        attributesFilter: null,
        attributesExcludeFilter: ['archived'],
        withData: ['TicketTypes', 'TicketBlocks', 'Stacks'],
        lastModifiedTimestamp: null,
        page: null,
        itemsPerPage: null,
        sortBy: null,
        sortDirection: '',
        eventDateFilterType: null,
        teamId: this.eventFarmService.currentTeam.id,
        tags: null,
        earliestStartTimestamp: null,
    };

    private searchEvents() {
        this.searchTerm.valueChanges
            .pipe(tap(() => { this.isSearching = true; this.activeInput = true; }),
                debounceTime(200), distinctUntilChanged())
            .subscribe((term) => {
                this.eventListOptions.page = 1;
                this.eventListOptions.query = term;
                this.apiClient.getUseCaseFactory().Event().ListEventsForUser(
                    this.eventFarmService.currentUser.id,
                    this.eventListOptions.query,
                    this.eventListOptions.attributesFilter,
                    this.eventListOptions.attributesExcludeFilter,
                    this.eventListOptions.withData,
                    this.eventListOptions.lastModifiedTimestamp,
                    this.eventListOptions.page,
                    this.eventListOptions.itemsPerPage,
                    this.eventListOptions.sortBy,
                    this.eventListOptions.sortDirection,
                    this.eventListOptions.eventDateFilterType,
                    this.eventFarmService.currentTeam.id,
                    this.eventListOptions.tags,
                    this.eventListOptions.earliestStartTimestamp,
                )
                    .pipe(map(results => results['data'].map(event => EfEvent.fromApiResponse((event)))))
                    .subscribe((results: EfEvent[]) => {
                        this.isSearching = false;
                        if (term) {
                            if (this.currentEventId) {
                                results = results.filter(r => r.id != this.currentEventId)
                            }

                            if (this.shouldFilterOutCioEvents) {
                                this.searchResults$.next(results.filter(result => !result.isTypeCio ));
                            } else {
                                this.searchResults$.next(results);
                            }
                        } else {
                            this.searchResults$.next([]);
                        }
                    });
            });
    }

    private selectOption(option) {
        this.selectionReceived.emit(option);
        this.searchResults$.next([]);
        this.searchTerm.reset();
    }

    private offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.activeInput = false;
        }
    }

}

export interface EventListOptions {
    query?: string;
    attributesFilter?: any[];
    attributesExcludeFilter?: any[];
    withData?: any;
    lastModifiedTimestamp?;
    page?;
    itemsPerPage?;
    sortBy?: string;
    sortDirection?: string;
    eventDateFilterType?;
    teamId?: string;
    tags?;
    earliestStartTimestamp: number;
}