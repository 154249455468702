import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventFarmService } from '../../../../../eventFarm.service';
import { Exhibitor } from '../../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { User } from '../../../../../../ApiClient/Models/User/user';
import * as _ from 'lodash';

@Injectable()
export class ExhibitorTeamMembersService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private segmentService: SegmentService,
    ) {
    }

    public exhibitorTeamMembersListOptions: ExhibitorTeamMembersListOptions = {
        query: '',
        sortBy: 'createdAt',
        sortDirection: 'descending',
        pagination: {
            currentExhibitorTeamMembersPage: 1,
            itemsPerPage: 5,
        }
    };

    public readonly currentExhibitorTeamMembers$ = new BehaviorSubject<User[]>([]);
    public exhibitorTeamMembersMetaTotalResults$ = new BehaviorSubject<number>(0);
    public isLoadingTeamMembers: boolean = false;

    public reset() {
        this.currentExhibitorTeamMembers$.next([]);
    }

    get teamMemberCount(): number {
        return this.currentExhibitorTeamMembers$.value.length;
    }

    public async fetchTeamMembersForExhibitor(exhibitor: Exhibitor) {
        try {
            this.isLoadingTeamMembers = true;
            const userResponse = await this.apiClient.getUseCaseFactory()
                .User()
                .ListUsersForExhibitor(
                    exhibitor.id,
                    this.eventFarmService.currentTeam.id,
                    ['UserIdentifiers', 'UserNames', 'UserAttributes'],
                    this.exhibitorTeamMembersListOptions.query,
                    null,
                    null,
                    this.exhibitorTeamMembersListOptions.pagination.currentExhibitorTeamMembersPage,
                    this.exhibitorTeamMembersListOptions.pagination.itemsPerPage,

                ).toPromise();
            const users = userResponse.data.map(user => User.fromApiResponse(user));
            this.currentExhibitorTeamMembers$.next(users);
            this.exhibitorTeamMembersMetaTotalResults$.next(userResponse.meta.totalResults);

            this.isLoadingTeamMembers = false;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingTeamMembers = false;
        }
    }

    public async editUser(user: User, firstName: string, lastName: string, phoneNumber: string) {
        try {
            const poolId = this.eventFarmService.currentTeam.id;
            const userId = user.id;
            await this.apiClient.getUseCaseFactory().UserName().SetUserName(user.name.id, firstName, lastName).toPromise();
            await this.apiClient.getUseCaseFactory().UserAttribute().SetInfoUserAttribute(poolId, userId, 'telephone', phoneNumber).toPromise();

            const r3 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, userId, ['UserIdentifier', 'UserName'], ['info']).toPromise();
            return User.fromApiResponse(r3.data);
        } catch (e) {
            this.message.error('Error saving team member. Please try again.');
        }
    }

    public async createUser(email: string, firstName: string, lastName: string, phoneNumber: string) {
        const poolId = this.eventFarmService.currentTeam.id;

        const r1 = await this.apiClient.getUseCaseFactory().User().CreateUser(email, firstName, lastName, null, null, phoneNumber, poolId, null, null).toPromise().catch(
            async (err) => {
                if (err.status === 409) {
                    let oldUserId = null;
                    err.error.errors.forEach((error) => {
                        oldUserId = (error.meta && error.meta.userId) ? error.meta.userId : null;
                    });

                    const r2 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, oldUserId, ['UserIdentifier', 'UserName'], ['info']).toPromise();
                    const oldUser = User.fromApiResponse(r2.data);

                    if (oldUser.name) {
                        await this.apiClient.getUseCaseFactory().UserName().SetUserName(oldUser.name.id, firstName, lastName).toPromise();
                    } else {
                        await this.apiClient.getUseCaseFactory().UserName().AddUserName(poolId, oldUserId, firstName, lastName, null).toPromise();
                    }

                    await this.apiClient.getUseCaseFactory().UserAttribute().SetInfoUserAttribute (poolId, oldUserId, 'telephone', phoneNumber).toPromise();

                    const r3 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, oldUserId, ['UserIdentifier', 'UserName'], ['info']).toPromise();
                    return User.fromApiResponse(r3.data);
                }
            }
        );

        if (r1 instanceof User) {
            return r1;
        }

        const { userId } = r1.data.command;
        const r4 = await this.apiClient.getUseCaseFactory().User().GetUserInPool(poolId, userId, ['UserIdentifier', 'UserName'], ['info']).toPromise();
        return User.fromApiResponse(r4.data);

    }

    public async addUserToExhibitor(user: User, exhibitor: Exhibitor) {
        try {
            await this.apiClient.getUseCaseFactory().Exhibitor().AddUserToExhibitor(exhibitor.id, user.primaryEmail, user.name.firstName, user.name.lastName, this.eventFarmService.currentUser.id).toPromise();
            const newValue = _.cloneDeep(this.currentExhibitorTeamMembers$.value);
            newValue.push(user);
            this.currentExhibitorTeamMembers$.next(newValue);
            this.message.success('User added to exhibitor');

        } catch (e) {
            this.message.error('Error adding team member. Please try again.');
        }
    }

    public async removeUserFromExhibitor(user: User, exhibitor: Exhibitor) {
        try {
            await this.apiClient.getUseCaseFactory().Exhibitor().RemoveUserFromExhibitor(user.id, exhibitor.id).toPromise();
            const newValue = _.cloneDeep(this.currentExhibitorTeamMembers$.value);
            this.currentExhibitorTeamMembers$.next(newValue.filter(tm => tm.id !== user.id));
        } catch (e) {
            this.message.error('Error removing team member. Please try again.');
        }
    }

    public async updateUserForExhibitor(user: User) {
        const newValue = _.cloneDeep(this.currentExhibitorTeamMembers$.value);
        const idx = newValue.findIndex(tm => tm.id === user.id);
        newValue[idx] = user;
        this.currentExhibitorTeamMembers$.next(newValue);
    }

}

export interface Pagination {
    currentExhibitorTeamMembersPage: number;
    itemsPerPage: number;
}

export interface ExhibitorTeamMembersListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}
