import { StackMethodTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Stack';
import {Team} from '../../../../../../ApiClient/Models/Pool/team';
import {SitePage} from '../../../../../../ApiClient/Models/SitePage/site-page';
import {Design} from '../../../../EmailDesignCenter/Models/design';
import { Stack } from './../../../../../../ApiClient/Models/Stack/stack';
import { Component, Input, OnDestroy } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as fromRoot from './../../../../../store';
import * as eventActions from './../../../../../store/actions/event';
import { FormControl } from '@angular/forms';
import { formatMoney, formatInt } from './../../../../../../Utilities/formatMoney';
import {filter, map, shareReplay, tap} from 'rxjs/operators';
import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import * as moment from 'moment';
import { FormattedDateTime } from '../../../../../../ApiClient/Models/formatted-datetime';
import { dateTimeFormat, momentShortDateTime, timePickerDefaults } from '../../../../../../Shared/datetime-options';
import { EventFarmService } from '../../../../../eventFarm.service';
import { Virbela } from '@eventfarm/javascript-sdk/dist/Api/Type/Virbela';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as _ from 'lodash';
import { TicketType } from '../../../../../../ApiClient/Models/Stack/ticket-type';
import { EventFarmAPIClient } from '../../../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../../../eventFarmAlert.service';
import { isValidEmail } from '../../../../../../Utilities/validateEmail';
import { isValidPhoneNumber, isValidPhoneNumberLibJS, validateAndFormatPhoneNumber } from '../../../../../../Utilities/validatePhoneNumber';
import { ArrivalAlertUtility } from '../../../../../../Utilities/arrivalAlert.utility';

interface StackTimePeriodInterface {
  stackQuantity: number;
  stackExpirationStartDate;
  stackExpirationEndDate;
  disabledExpiresAtDate?: any | null;
  stackId?: string | null;
}

interface CreateOrUpdateStackTimePeriodInterface {
  quantity: number;
  expirationStartTime;
  expirationEndTime;
  stackId?: string | null;
}

@Component({
  selector: 'edit-stack',
  template: require('./edit-stack.html'),
  styles: [require('./edit-stack.scss')]
})
export class EditStackComponent implements OnDestroy {
  private ticketTypeQuantity: FormControl = new FormControl();
  private stackMaxQuantity: FormControl = new FormControl();
  private virbelaTeamId: FormControl = new FormControl();
  private virbelaRole: FormControl = new FormControl();
  private inviteOnly: FormControl = new FormControl();
  private stackPrice: FormControl = new FormControl();
  private stackServiceFee: FormControl = new FormControl();
  private ticketTypeName: FormControl = new FormControl();
  private ticketTypeDescription: FormControl = new FormControl();
  private ticketTypeCheckInMessage: FormControl = new FormControl();
  private ticketTypeDisplayOrder: FormControl = new FormControl();
  private arrivalEmailList: FormControl = new FormControl();
  private arrivalMobileNumberList: FormControl = new FormControl();
  private defaultSitePageId: FormControl = new FormControl();
  private defaulGuestPassCount: FormControl = new FormControl();
  private guestPassRange = Array(20).fill(1).map((x, i) => i + 1);


  private sitePages: SitePage[] = [];

  @Input() stacks: Stack[];
  private stackTimePeriods: StackTimePeriodInterface[] = [];
  private ticketTypes: TicketType[] = [];
  private stackOptions: Stack;
  public eventStore$ = this.store.select('event').pipe(shareReplay());
  // states
  private saveStackFailSub$: Subscription;
  private saveStackSuccessSub$: Subscription;
  private deleteTicketTypeSuccessSub$: Subscription;

  private isSaving: boolean = false;
  private confirmDelete: boolean = false;
  private isDeleting: boolean = false;
  private createStack: boolean;
  private typeNameExists: boolean;
  private ticketTypeQuantityValid: boolean;
  private creatingTimePeriod: boolean = false;

  // payments
  private isPaidAccessType: boolean = false;
  private formatMoney = formatMoney;
  private formatInt = formatInt;

  // open and closing and expiration time
  private eventTimezone$ = new BehaviorSubject<string>('America/New_York');
  private dateTimeFormat = dateTimeFormat;
  private timePickerDefaults = timePickerDefaults;
  private timeSettings = {
    nzSecondStep: 60,
    nzMinuteStep: 5,
  };

  private currentTime = + new Date() / 1000;
  private stackOpeningDate;
  private stackClosingDate;
  private disabledEndDate;
  private eventStartTime: FormattedDateTime;
  private eventEndTime: FormattedDateTime;

  // virbela

  private currentTeamName: string = '';
  private hideVirbelaSetting: boolean = true;

  private teamIdOptions = {
    0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H',
    8: 'I', 9: 'J', 10: 'K', 11: 'L', 12: 'M', 13: 'N', 14: 'O', 15: 'P',
    16: 'Q', 17: 'R', 18: 'S', 19: 'T', 20: 'U', 21: 'V', 22: 'W', 23: 'X',
    24: 'Y', 25: 'Z', 26: 'AA', 27: 'AB', 28: 'AC', 29: 'AD', 30: 'AE', 31: 'AF',
    32: 'AG', 33: 'AH', 34: 'AI', 35: 'AJ', 36: 'AK', 37: 'AL', 38: 'AM', 39: 'AN',
    40: 'AO', 41: 'AP', 42: 'AQ', 43: 'AR', 44: 'AS', 45: 'AT', 46: 'AU', 47: 'AV',
    48: 'AW', 49: 'AX', 50: 'AY', 51: 'AZ'
  };

  private roleOptions = {
    member: 'Member', moderator: 'Moderator', leader: 'Leader'
  };

  private optInTicketTypeSitePage: boolean = false;
  private optInDefaultGuestPassCount: boolean = false;

  public sitePagesArr$;
  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private actions$: Actions,
    private eventFarmService: EventFarmService,
    private message: NzMessageService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private nzMessageService: NzMessageService
  ) { }

  async ngOnInit() {
    this.isSaving = false;
    this.initSubscriptions();
    this.initModels();
  }

  private sitePages$ = this.store.select('event').pipe(
      filter(event => !!event.sitePages.data),
      map(event => event.sitePages.data),
      shareReplay()
  );
  private async saveStack() {

    if (this.isSaving === false) {
      this.isSaving = true;
      this.stackOptions.quantity = Number(this.ticketTypeQuantity.value);
      this.stackOptions.maxQuantity = Number(this.stackMaxQuantity.value);

      if (this.eventFarmService.virbelaEnabled) {
        this.stackOptions.virbelaTeamId = Number(this.virbelaTeamId.value);
        this.stackOptions.virbelaRole = this.virbelaRole.value;
      }

      if (this.isPaidAccessType === true && this.stackPrice.value >= 1) {
        this.stackOptions.price = this.handlePriceFormatForApi(this.stackPrice.value);
      } else {
        this.stackOptions.price = this.handlePriceFormatForApi(0);
      }

      if (this.isPaidAccessType === true && this.stackOptions.price >= 1) {
        this.stackOptions.serviceFee = this.handlePriceFormatForApi(this.stackServiceFee.value);
      } else {
        this.stackOptions.serviceFee = this.handlePriceFormatForApi(0);
      }

      this.stackOptions.openingAtFormatted.mutable = this.stackOpeningDate.toLocaleString('en-US', { hour12: false });
      this.stackOptions.closingAtFormatted.mutable = this.stackClosingDate.toLocaleString('en-US', { hour12: false });

      this.stackOptions.ticketType.name = this.ticketTypeName.value;
      this.stackOptions.stackMethodType.isInvitation = this.inviteOnly.value;
      this.stackOptions.ticketType.description = this.ticketTypeDescription.value;
      this.stackOptions.ticketType.checkInMessage = this.ticketTypeCheckInMessage.value;

      const arrivalUtility = new ArrivalAlertUtility();

      const arrivalAlerts = arrivalUtility.format(
        this.stackOptions.ticketType.shouldSendArrivalAlert,
        this.arrivalEmailList.value,
        this.arrivalMobileNumberList.value
      )

      this.stackOptions.ticketType.shouldSendArrivalAlert = arrivalAlerts.shouldSendArrivalAlert;
      this.stackOptions.ticketType.arrivalAlertEmails = arrivalAlerts.arrivalAlertEmails;
      this.stackOptions.ticketType.arrivalAlertPhoneNumbers = arrivalAlerts.arrivalAlertMobileNumbers;

      this.stackOptions.ticketType.displayOrder = this.ticketTypeDisplayOrder.value ? Number(this.ticketTypeDisplayOrder.value) : 0;

      // If custom canvas page is opted-out, we should use null
      if (this.optInTicketTypeSitePage) {
        this.stackOptions.ticketType.defaultSitePage = this.getDefaultSitePage(this.defaultSitePageId.value);
      } else {
        this.stackOptions.ticketType.defaultSitePage = null;
      }

      if (this.optInDefaultGuestPassCount) {
        this.stackOptions.ticketType.defaultGuestPassCount = this.defaulGuestPassCount.value;
      } else {
        this.stackOptions.ticketType.defaultGuestPassCount = null;
      }


      let timePeriods: CreateOrUpdateStackTimePeriodInterface[] = [];

      // guest pass
      if (this.eventFarmService.guestPassEnabled && this.stackTimePeriods.length == 1) {
        this.stackOptions.expirationStartTimeFormatted.mutable = this.stackTimePeriods[0].stackExpirationStartDate.toLocaleString('en-US', { hour12: false });
        this.stackOptions.expirationEndTimeFormatted.mutable = this.stackTimePeriods[0].stackExpirationEndDate.toLocaleString('en-US', { hour12: false });
      } else if (this.eventFarmService.guestPassEnabled && this.stackTimePeriods.length > 1) {
        timePeriods = this.stackTimePeriods.map(val => {
          return {
            stackId: val.stackId ?? null,
            quantity: val.stackQuantity,
            expirationStartTime: val.stackExpirationStartDate.toLocaleString('en-US', { hour12: false }),
            expirationEndTime: val.stackExpirationEndDate.toLocaleString('en-US', { hour12: false })

          };
        });
      } else {
        this.stackOptions.expirationStartTimeFormatted.mutable = null;
        this.stackOptions.expirationEndTimeFormatted.mutable = null;
      }

      if (this.createStack && this.stackTimePeriods.length <= 1) {
        this.store.dispatch(new eventActions.CreateStack(this.stackOptions));
      } else if (this.createStack && this.stackTimePeriods.length > 1) {
        this.store.dispatch(new eventActions.CreateStacksForTicketType({stack: this.stackOptions, timePeriods: timePeriods}));
      } else if (!this.createStack && this.stackTimePeriods.length <= 1) {
        this.store.dispatch(new eventActions.UpdateStack(this.stackOptions));
      } else if (!this.createStack && this.stackTimePeriods.length > 1) {
        await this.apiClient.getUseCaseFactory().TicketType().SetQuantityForTicketType(this.stackOptions.ticketType.id, this.stackOptions.quantity).toPromise();
        this.store.dispatch(new eventActions.UpdateStacksForTicketType({stack: this.stackOptions, timePeriods: timePeriods, eventId: this.eventFarmService.currentEvent.id}));
      }

      this.saveStackSuccessSub$ = this.actions$.pipe(ofType(eventActions.FETCH_STACKS_SUCCESS)).subscribe(() => {
        this.isSaving = false;
        this.close();
      });

    }
  }

  private handleDeleteTicketType() {
    if (this.isDeleting) {
      this.isDeleting = false;
    }
    if (this.confirmDelete) {
      this.isDeleting = true;
      this.store.dispatch(new eventActions.DeleteTicketType(this.stackOptions));
      this.deleteTicketTypeSuccessSub$ = this.actions$.pipe(ofType(eventActions.DELETE_TICKET_TYPE_SUCCESS)).subscribe(() => {
        this.isDeleting = false;
        this.confirmDelete = false;
        this.drawerRef.close();
      });
    }
    this.confirmDelete = true;
  }

  private allowSave() {
    if (this.stackOptions.ticketType.shouldSendArrivalAlert) {
      if (this.arrivalEmailList.value.length === 0 && this.arrivalMobileNumberList.value.length === 0) {
        return false
      }
    }


    if (this.isPaidAccessType && this.stackPrice.value < 1) {
      return false;
    }

    return this.ticketTypeName.value !== '' &&
      this.ticketTypeQuantity.value &&
      this.ticketTypeQuantityValid &&
      !this.typeNameExists;
  }

  // init subscriptions and models

  private initSubscriptions() {
    this.sitePagesArr$ = this.sitePages$.pipe(
        map((sitePages: SitePage): SitePage[] => {
          return Object.keys(sitePages).map((i) => sitePages[i]);
        })
    );
    this.sitePagesArr$.subscribe(val => {
      this.sitePages = val;
    });

    this.store.select('event').pipe(
      filter(res => !!res),
      map(res => res.data)
    ).subscribe((res: EfEvent) => {
      if (res) {
        this.eventTimezone$.next(res.timezone);
        this.eventStartTime = res.startTime;
        this.eventEndTime = res.endTime;
        this.ticketTypes = res.ticketTypes;
      }
    });

    this.saveStackFailSub$ = this.actions$.pipe(ofType(eventActions.UPDATE_STACK_FAIL))
      .subscribe((res: any) => {
        const err = _.get(res, 'payload.error.errors[0].detail', 'Error updating access type');
        this.isSaving = false;
        this.message.error(err);
      });

    this.ticketTypeName.valueChanges.subscribe(ticketTypeInput => {
      const typeExists = this.ticketTypes
        .filter((i) => this.stackOptions.ticketType.name !== i.name)
        .filter(ticketType => {
          return ticketType.name.toLowerCase() === ticketTypeInput.toLowerCase();
        }).length > 0;
      this.typeNameExists = typeExists;
    });

    this.ticketTypeQuantity.valueChanges.subscribe(stackQuantityInput => {
      this.ticketTypeQuantityValid = stackQuantityInput >= this.stackOptions.availabilityCounts.totalUsed;
    });

  }

  private async initModels() {
    if (!this.stacks) {
      this.createStack = true;
      this.stackOptions = Object.assign(new Stack(), {
        openingAtFormatted: {},
        closingAtFormatted: this.eventStartTime,
        stackMethodType: {
          isInvitation: false,
          isFirstComeFirstServe: true,
        } as StackMethodTypeInterface,
        ticketType: {
          name: '',
          displayOrder: 1,
          shouldSendArrivalAlert: false,
          arrivalAlertEmails: [],
          arrivalAlertPhoneNumbers: [],
          defaultGuestPassCount: null
        },
        isTransferable: true,
        maxQuantity: 10, // max number tickets per guest. set to guest +9
        quantity: 100,
        availabilityCounts: { totalUsed: 0 },
        expirationStartTimeFormatted: {},
        expirationEndTimeFormatted: {}
      });
    } else {
      this.stackOptions = this.stacks[0];
      this.createStack = false;
    }

    if (!this.stackOptions.openingAtFormatted.date) {
      this.stackOpeningDate = new Date();
    } else {
      const start = `${this.stackOptions.openingAtFormatted.date.short} ${this.stackOptions.openingAtFormatted.time.short}`;
      this.stackOpeningDate = moment(start, momentShortDateTime).toDate();
    }

    if (!this.stackOptions.closingAtFormatted.date) {
      const close = `${this.eventStartTime.date.short} ${this.eventStartTime.time.short}`;
      this.stackClosingDate = moment(close, momentShortDateTime).toDate();
    } else {
      const close = `${this.stackOptions.closingAtFormatted.date.short} ${this.stackOptions.closingAtFormatted.time.short}`;
      this.stackClosingDate = moment(close, momentShortDateTime).toDate();
    }

    if (this.stackOptions.price === 0 || this.stackOptions.price === undefined || this.stackOptions.price === null) {
      this.isPaidAccessType = false;
    } else {
      this.isPaidAccessType = true;
    }

    this.ticketTypeQuantity.setValue(_.get(this, 'stack.ticketType.quantity', this.stackOptions.quantity));
    this.stackMaxQuantity.setValue(this.stackOptions.maxQuantity);
    if (this.stackOptions.price !== 0) {
      this.stackPrice.setValue(this.stackOptions.price);
    }
    if (this.stackOptions.serviceFee !== 0) {
      this.stackServiceFee.setValue(this.stackOptions.serviceFee);
    }

    this.ticketTypeName.setValue(this.stackOptions.ticketType.name);
    this.inviteOnly.setValue(this.stackOptions.stackMethodType.isInvitation);
    this.ticketTypeDescription.setValue(this.stackOptions.ticketType.description);
    this.ticketTypeCheckInMessage.setValue(this.stackOptions.ticketType.checkInMessage);

    // arrivallist

    this.arrivalEmailList.setValue(this.makeEmailList());
    this.arrivalMobileNumberList.setValue(this.makeSMSList());

    this.ticketTypeDisplayOrder.setValue(this.stackOptions.ticketType.displayOrder);

    this.inviteOnly.valueChanges.subscribe(value => {
      if (value && this.createStack) {
        this.stackMaxQuantity.setValue(1);
      }
    });

    if (this.eventFarmService.virbelaEnabled) {
      this.initializeVirbela();
    }

    this.setValidEndDate();

    if (this.eventFarmService.guestPassEnabled && !this.createStack) {
      this.initializeStackTimePeriods();
    }

    // Set the default sitepage value to default to event's default or use ticketType override if already set

    if (this.stackOptions.ticketType.defaultSitePage) {
      //Toggle on opt-in switch
      this.optInTicketTypeSitePage = true;
      this.defaultSitePageId.setValue(this.stackOptions.ticketType.defaultSitePage.sitePageId);
    } else {
      //Toggle off opt-in switch
      this.optInTicketTypeSitePage = false;
      this.defaultSitePageId.setValue(this.eventFarmService.currentEvent.defaultSitePage);
    }

    // Set the default sitepage value to default to event's default or use ticketType override if already set

    if (this.stackOptions.ticketType.defaultGuestPassCount) {
      //Toggle on opt-in switch
      this.optInDefaultGuestPassCount = true;
      this.defaulGuestPassCount.setValue(this.stackOptions.ticketType.defaultGuestPassCount);
    } else {
      //Toggle off opt-in switch
      this.optInDefaultGuestPassCount = false;
      this.defaulGuestPassCount.setValue(this.stackOptions.ticketType.defaultGuestPassCount);
    }

  }

  // virbela

  private async initializeVirbela() {
    const role = new Virbela().VirbelaRoleType().filter(rt => rt.isMember)[0];
    this.virbelaRole.setValue(this.stackOptions.virbelaRole ? this.stackOptions.virbelaRole.slug : role.slug);
    const poolWorld = await this.eventFarmService.currentPoolWorldForEvent();

    if (poolWorld) {
      this.currentTeamName = poolWorld._worldName;
      if (!this.stackOptions.virbelaTeamId) {
        this.virbelaTeamId.setValue(String(poolWorld._teamId));
      } else {
        this.virbelaTeamId.setValue(String(this.stackOptions.virbelaTeamId));
      }
      this.hideVirbelaSetting = false;
    } else {
      this.hideVirbelaSetting = true;
    }
  }

  // Guest Pass

  private setValidExpiresAtDate(timePeriod: StackTimePeriodInterface, index: number) {
    this.stackTimePeriods[index].disabledExpiresAtDate = (current: Date): boolean => {
      return current < moment(timePeriod.stackExpirationStartDate).subtract(1, 'day').toDate();
    };
  }

  private async addTimePeriod(val: StackTimePeriodInterface | null) {
    if (this.creatingTimePeriod) {
      return;
    }

    const start = `${this.eventStartTime.date.short} ${this.eventStartTime.time.short}`;
    const end = `${this.eventEndTime.date.short} ${this.eventEndTime.time.short}`;

    let quantity = 0;
    if (this.stackTimePeriods.length === 0) {
      quantity = this.ticketTypeQuantity.value;
    }

    if (val) {
      this.stackTimePeriods.push(val);
    } else {
      if (this.createStack) {
        this.stackTimePeriods.push({
          stackQuantity: quantity,
          stackExpirationStartDate: moment(start, momentShortDateTime).toDate(),
          stackExpirationEndDate: moment(end, momentShortDateTime).toDate(),
          disabledExpiresAtDate: (current: Date): boolean => {
            return current < moment(start, momentShortDateTime).subtract(1, 'day').toDate();
          },
        });
      } else {
        if (this.stacks.length === 1 && this.stackTimePeriods.length === 0) {
          const stackId = this.stacks[0].id;

          this.stackTimePeriods.push({
            stackQuantity: quantity,
            stackExpirationStartDate: moment(start, momentShortDateTime).toDate(),
            stackExpirationEndDate: moment(end, momentShortDateTime).toDate(),
            disabledExpiresAtDate: (current: Date): boolean => {
              return current < moment(start, momentShortDateTime).subtract(1, 'day').toDate();
            },
            stackId: stackId
          });

        } else {
          await this.createStackWithTimePeriod();
        }
        this.nzMessageService.success('New Time Period Created');
      }
    }

    if (this.stackTimePeriods.length > 0) {
      this.ticketTypeQuantity.disable();
      this.updateTotalQuantity();
    }
  }

  private async createStackWithTimePeriod() {

    this.creatingTimePeriod = true;

    const start = `${this.eventStartTime.date.short} ${this.eventStartTime.time.short}`;
    const end = `${this.eventEndTime.date.short} ${this.eventEndTime.time.short}`;

    try {
      const res = await this.apiClient.getUseCaseFactory().Stack().CreateStackFromSettings(
        this.eventFarmService.currentEvent.id,
        this.stackOptions.ticketType.id,
        this.stackOptions.stackMethodType.isInvitation,
        this.stackOptions.stackMethodType.isFirstComeFirstServe,
        0,
        this.stackOptions.maxQuantity,
        this.stackOptions.price,
        this.stackOptions.serviceFee,
        this.stackOpeningDate.toLocaleString('en-US', { hour12: false }),
        this.stackClosingDate.toLocaleString('en-US', { hour12: false }),
        this.stackOptions.isTransferable,
        null,
        null,
        null,
        null,
        null,
        null,
        moment(start, momentShortDateTime).toDate().toLocaleString('en-US', { hour12: false }),
        moment(end, momentShortDateTime).toDate().toLocaleString('en-US', { hour12: false }),
      ).toPromise();

      const stackId = res.data.command.stackId;

      const stackRes = await this.apiClient.getUseCaseFactory().Stack().GetStack(stackId, ['TicketType', 'AvailabilityCounts']).toPromise();
      this.creatingTimePeriod = false;

      const s = Stack.fromApiResponse(stackRes.data);
      this.stacks.push(s);
      this.handleStackTimePeriod(s);
    } catch (e) {
      this.nzMessageService.error('Error adding time slot');

    }
  }

  private async deleteTimePeriod(timePeriod: StackTimePeriodInterface, index: number) {
    if (!this.createStack && this.stackTimePeriods.length > 1) {
      const res = await this.alertService.ticketType().confirmStackDeletion();

      if (res.value) {
        try {
          await this.apiClient.getUseCaseFactory().Stack().DeleteStack(timePeriod.stackId).toPromise();
          this.stacks.slice(index, 1);
          this.stackTimePeriods.splice(index, 1);
          this.handleTimePeriodPop(timePeriod);
          this.nzMessageService.success('Time Period Deleted!');
          await this.apiClient.getUseCaseFactory().TicketType().SetQuantityForTicketType(this.stackOptions.ticketType.id, this.stackTimePeriods.reduce((total, item) => item.stackQuantity + total, 0)).toPromise();
        } catch (e) {
          this.nzMessageService.success('Error Deleting Time Period Deleted!');
        }
      }

    } else {
      this.stackTimePeriods.splice(index, 1);
      this.handleTimePeriodPop(timePeriod);
    }
  }

  private handleTimePeriodPop(lastValue: StackTimePeriodInterface) {
    if (this.stackTimePeriods.length == 0) {
      this.ticketTypeQuantity.setValue(lastValue.stackQuantity);
      this.ticketTypeQuantity.enable();
    } else {
      this.updateTotalQuantity();
    }
  }

  private updateValidExpiresAtDate(timePeriod: StackTimePeriodInterface, index: number) {
    if (timePeriod.stackExpirationStartDate >= timePeriod.stackExpirationEndDate) {
      this.stackTimePeriods[index].stackExpirationEndDate = new Date(Date.parse(this.stackTimePeriods[index].stackExpirationStartDate) + 600000);
    }

    this.setValidExpiresAtDate(timePeriod, index);
  }

  private updateValidFromDate(timePeriod: StackTimePeriodInterface, index: number) {
    if (timePeriod.stackExpirationStartDate >= timePeriod.stackExpirationEndDate) {
      this.stackTimePeriods[index].stackExpirationStartDate = new Date(Date.parse(this.stackTimePeriods[index].stackExpirationEndDate) - 600000);
    }

    this.setValidExpiresAtDate(timePeriod, index);
  }

  private async updateTotalQuantity() {
    const total = this.stackTimePeriods.reduce((total, item) => item.stackQuantity + total, 0);
    this.ticketTypeQuantity.setValue(total);
  }

  private initializeStackTimePeriods() {
    this.stacks.sort((a, b) => a.expirationStartTime - b.expirationStartTime);

    this.stacks.forEach(s => {
      this.handleStackTimePeriod(s);
    });
  }

  // getters and setters
  private get shouldShowServiceFee(): boolean {
    const val: number = Number(this.stackPrice.value);
    return val > 0;
  }

  private get processingCurrency(): string {
    return this.eventFarmService.currentEvent.currency.description;
  }

  private handlePriceFormatForApi(price) {
    const formatPrice = price === '' || price === '0' || price === 0 ? 0 : Number(price);
    return formatPrice;
  }

  private updateValidEndDate() {
    if (this.stackOpeningDate > this.stackClosingDate) {
      this.stackClosingDate = new Date(Date.parse(this.stackOpeningDate) + 10800000);
    }
    this.setValidEndDate();
  }

  private setValidEndDate() {
    this.disabledEndDate = (current: Date): boolean => {
      return current < this.stackOpeningDate;
    };
  }

  private get totalQuantityName(): string {
    if (this.stackTimePeriods.length > 0) {
      return 'Total Quantity';
    }

    return 'Quantity';
  }

  private get isTicketTypeQuantityDisabled(): boolean {
    if (this.stackTimePeriods.length > 0) {
      return true;
    }

    return false;
  }

  private get totalQuantityMessage(): string {
    if (this.stackTimePeriods.length > 0) {
      return '';
    }

    return `(cannot be lower than ${this.stackOptions.availabilityCounts.totalUsed})`;
  }

  private getMinForStack(val: StackTimePeriodInterface) {

    if (this.createStack) {
      return 0;
    }

    const s = this.stacks.filter(st => st.id === val.stackId);

    if (s.length) {
      return _.get(s, '[0].availabilityCounts.totalUsed', 0);
    }

    return 0;
  }

  private handleStackTimePeriod(s: Stack) {
    if (Object.keys(s.expirationStartTimeFormatted).length || Object.keys(s.expirationEndTimeFormatted).length) {
      const start = `${s.expirationStartTimeFormatted.date.short} ${s.expirationStartTimeFormatted.time.short}`;
      const end = `${s.expirationEndTimeFormatted.date.short} ${s.expirationEndTimeFormatted.time.short}`;

      this.addTimePeriod({
          stackId: s.id,
          stackQuantity: s.quantity,
          stackExpirationStartDate: moment(start, momentShortDateTime).toDate(),
          stackExpirationEndDate: moment(end, momentShortDateTime).toDate(),
          disabledExpiresAtDate: (current: Date): boolean => {
            return current < moment(start, momentShortDateTime).subtract(1, 'day').toDate();
          }
        });
    }
  }

  private toggleArrival(val) {
    if (val === 'email') {
        this.arrivalEmailList.setValue(this.makeEmailList());
        this.arrivalMobileNumberList.setValue(this.makeSMSList());
    } else {
        this.arrivalEmailList.setValue('');
        this.arrivalMobileNumberList.setValue('');
    }
  }


    private getEventSitePageName(): string {
      const filtered = this.sitePages.filter(sitePage => sitePage.id === this.eventFarmService.currentEvent.defaultSitePage);
      if (filtered.length > 0) {
        return filtered[0].name;
      }
    }

  private getDefaultSitePage(sitePageId): SitePage {
    const filtered = this.sitePages.filter(sitePage => sitePage.sitePageId === sitePageId );
    if (filtered.length > 0) {
      return filtered[0];
    }
  }

    private makeEmailList(): string {
        const emails = this.stackOptions.ticketType.arrivalAlertEmails;
        return emails.join(', ');
    }

    private makeSMSList(): string {
        const sms = this.stackOptions.ticketType.arrivalAlertPhoneNumbers;
        return sms.join(', ');
    }

  close(): void {
    this.drawerRef.close(this.stacks);
  }

  ngOnDestroy() {
    if (this.saveStackFailSub$) {
      this.saveStackFailSub$.unsubscribe();
    }

    if (this.saveStackSuccessSub$) {
      this.saveStackSuccessSub$.unsubscribe();
    }

    if (this.deleteTicketTypeSuccessSub$) {
      this.deleteTicketTypeSuccessSub$.unsubscribe();
    }
  }
}
