import { Action } from '@ngrx/store';

export const FETCH_WAIT_LIST = '[Event] Fetch Wait List';
export const FETCH_WAIT_LIST_FAIL = '[Event] Fetch Wait List Fail';
export const SET_WAIT_LIST_SUCCESS = '[Event] Set Wait List Success';
export const SET_WAIT_LIST_FAIL = '[Event] Set Wait List Fail';
export const REMOVE_INVITATION_FROM_WAITLIST = '[Event] Remove Invitation From Waitlist';
export const REMOVE_INVITATION_FROM_WAITLIST_SUCCESS = '[Event] Remove Invitation From Waitlist Success';

export class FetchWaitList implements Action {
    readonly type = FETCH_WAIT_LIST;
    constructor(public payload?: any) { }
}

export class FetchWaitListFail implements Action {
    readonly type = FETCH_WAIT_LIST_FAIL;
    constructor(public payload?: any) { }
}

export class SetWaitListSuccess implements Action {
    readonly type = SET_WAIT_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class RemoveInvitationFromWaitlist implements Action {
    readonly type = REMOVE_INVITATION_FROM_WAITLIST;
    constructor(public payload: any) { }
}

export class RemoveInvitationFromWaitlistSuccess implements Action {
    readonly type = REMOVE_INVITATION_FROM_WAITLIST_SUCCESS;
    constructor(public payload?: any) { }
}

export type EventAction =
    FetchWaitList |
    FetchWaitListFail |
    RemoveInvitationFromWaitlist |
    RemoveInvitationFromWaitlistSuccess |
    SetWaitListSuccess;
