import { AbstractModel } from '../abstract-model';
import { Promotion, PromotionTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Promotion';
import { FormattedDateTime } from '../formatted-datetime';
import { StackMethodTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Stack';

export interface PromoAccessType {
    id: string;
    name: string;
    type: StackMethodTypeInterface;
    cost: number;
}

export class Promo extends AbstractModel {

    private _promotionType: PromotionTypeInterface;
    private _code: string;
    private _amount: number;
    private _used: number;
    private _maximum: number|null;
    private _message: string|null;
    private _enabled: boolean;
    private _startTime: number;
    private _endTime: number;
    private _startTimeFormatted: FormattedDateTime;
    private _endTimeFormatted: FormattedDateTime;
    private _stacks: PromoAccessType[]|null;
    private _currencyString: string;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data, currency: string): Promo {
        const promo = new this(data.id);
        try {
            const { promotionType, code, amount, used, maximum, message, isEnabled, startTimeFormatted, endTimeFormatted, startTime, endTime } = data.attributes;

            promo._promotionType = promotionType;
            promo._code = code;
            promo._amount = amount;
            promo._used = used;
            promo._maximum = maximum;
            promo._message = message;
            promo._enabled = isEnabled;
            promo._startTime = Number(startTime);
            promo._endTime = Number(endTime);
            promo._startTimeFormatted = startTimeFormatted ? startTimeFormatted : {};
            promo._endTimeFormatted = endTimeFormatted ? endTimeFormatted : {};
            promo._currencyString = currency;

            if (data.relationships && data.relationships.promotionStacks) {
                promo._stacks = Promo.buildStacks(data.relationships.promotionStacks);
            }


        } catch (err) {
        }
        return promo;
    }

    public static forNewCreation(data, currency: string): Promo {
        const promo = new this();
        try {
            const { promotionType, code, amount, used, maximum, message, enabled, startTimeFormatted, endTimeFormatted, startTime, endTime } = data;

            promo._promotionType = promotionType;
            promo._code = code;
            promo._amount = amount;
            promo._used = used;
            promo._maximum = maximum;
            promo._message = message;
            promo._enabled = enabled;
            promo._startTimeFormatted = startTimeFormatted;
            promo._endTimeFormatted = endTimeFormatted;
            promo._currencyString = currency;

        } catch (err) {
        }
        return promo;
    }

    setCurrencyFormat(value, showFree = false) {
        const newValue = value && value !== '0px' ? Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : showFree ? 'Free' : 'n/a';
        return newValue;
    }

    public static buildStacks(stacks): PromoAccessType[] {

        const removeDeleted = stacks.filter(stack => !stack.attributes.stack.isDeleted);

        return removeDeleted.map(stack => {
            const price = stack.relationships.stack.price ? stack.relationships.stack.price : 0;
            const serviceFee = stack.relationships.stack.serviceFee ? stack.relationships.stack.serviceFee : 0;
            const cost = price + serviceFee;
            return { id: stack.id, name: stack.relationships.stack.relationships.ticketType.attributes.name, type: stack.attributes.stack.stackMethodType, cost };
        } );
    }

    get code(): string {
        return this._code;
    }

    get accessTypes(): string {
        return this._stacks.map(type => type.name).join(', ');
    }

    get used(): number {
        return this._used;
    }

    get amount(): number {
        return this._amount;
    }

    get type(): PromotionTypeInterface {
        return this._promotionType;
    }

    get stacks(): PromoAccessType[] {
        return this._stacks;
    }

    get maximum(): number {
        return this._maximum;
    }

    get message(): string {
        return this._message;
    }

    get isEnabled(): boolean {
        return this._enabled;
    }

    set isEnabled(val: boolean) {
        this._enabled = val;
    }

    get startTimeFormatted(): FormattedDateTime {
        return this._startTimeFormatted;
    }

    set startTimeFormatted(val: FormattedDateTime) {
        this._startTimeFormatted = val;
    }

    get endTimeFormatted(): FormattedDateTime {
        return this._endTimeFormatted;
    }

    set endTimeFormatted(val: FormattedDateTime) {
        this._endTimeFormatted = val;
    }


    get startTime(): number {
        return this._startTime;
    }

    get endTime(): number {
        return this._endTime;
    }

    get displayType(): string {

        // for questions with slugs, look at PromotionTypeInterface

        switch (this._promotionType.slug) {
            case 'discount':
                return `${Number(this._amount).toLocaleString('en-US', { style: 'currency', currency: this._currencyString.toUpperCase() })} Off`;
                break;
            case 'percentage':
                return `${Number(this._amount)}% Off`;
                break;
            case 'offer':
                return `Reveal Access Types`;
                break;
            case 'quantity':
                return `Free Tickets`;
                break;
            default:
                return '';
                break;
        }
    }


    private static buildPromoType(data): PromotionTypeInterface {
        return new Promotion().PromotionType().find(type => {
            return type.slug === data;
        });
    }

}
