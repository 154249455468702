import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {NgxIntlTelInputModule} from "@khazii/ngx-intl-tel-input";
import {TruncatePipe} from "../../../Utilities/truncate.pipe";
import {
    TextAreaWithSlugsComponent
} from '../../CoreUI/Forms/components/TextAreaWithSlugs/text-area-with-slugs.component';
import {IntlPhoneInputModule} from '../../CoreUI/IntlPhoneInput/intl-phone-input.module';
import {MobilePreviewModule} from '../../CoreUI/MobilePreview/mobile-preview.module';

import {GroupTypeaheadModule} from '../../CoreUI/Typeahead/group-typeahead.module';
import {EmailDesignCenterService} from '../EmailDesignCenter/email-design-center.service';
import {FroalaService} from '../EmailDesignCenter/froala.service';
import {
    GuestMessagingSendOptionsService
} from '../EmailDesignCenter/GuestMessaging/guest-messaging-send-options.service';
import {SMSCreateComposeComponent} from './Create/Compose/sms-create-compose.component';
import {SmsCreateConfirmationComponent} from './Create/Confirmation/sms-create-confirmation.component';
import {SMSCreatePreviewComponent} from './Create/Preview/sms-create-preview.component';
import {SMSCreateRecipientsComponent} from './Create/Recipients/sms-create-recipients.component';
import {SMSCreateReviewComponent} from './Create/Review/sms-create-review.component';
import {SMSCreateComponent} from './Create/sms-create.component';
import {SMSCreateService} from './Create/sms-create.service';
import {SMSReportsSearchComponent} from "./Reports/search/sms-reports-search.component";
import {SMSReportsComponent} from './Reports/sms-reports.component';
import {SMSReportsService} from './Reports/sms-reports.service';
import {SMSRoutingModule} from './sms-routing.module';

import {SMSComponent} from './sms.component';
import {SMSService} from './sms.service';
import { UtilitiesModule } from './../../../Utilities/utilities.module';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { BreadcrumbsModule } from '../../CoreUI/Breadcrumbs/breadcrumbs.module';
import { GuestSearchModule } from './../../CoreUI/GuestSearch/guestSearch.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import { NzUtilitiesModule } from '../../../NZUtilities/nz.utilities.module';

@NgModule({
    bootstrap: [SMSComponent],
    declarations: [
        SMSComponent,
        SMSCreateComponent,
        SMSReportsComponent,
        SMSCreateComposeComponent,
        SMSCreateRecipientsComponent,
        SMSCreateReviewComponent,
        TextAreaWithSlugsComponent,
        SmsCreateConfirmationComponent,
        SMSReportsSearchComponent,
        TruncatePipe,
        SMSCreatePreviewComponent
    ],
    entryComponents: [],
    imports: [
        CommonModule,
        FormsModule,
        BreadcrumbsModule,
        GuestSearchModule,
        UtilitiesModule,
        PaginationModule,
        NzUtilitiesModule,
        SMSRoutingModule,
        GroupTypeaheadModule,
        ReactiveFormsModule,
        MobilePreviewModule,
        IntlPhoneInputModule
    ],
    providers: [
        SMSService,
        SMSCreateService,
        EmailDesignCenterService,
        GuestMessagingSendOptionsService,
        FroalaService,
        SMSReportsService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})

export class SMSModule {
}
