import { createSelector } from '@ngrx/store';
import { Invitation } from '../../../../ApiClient/Models/Invitation/invitation';

const getCurrentEvent = (state) => state.event;

export const getGuestList = (type = null) => createSelector(
    getCurrentEvent,
    (event) => {
        if (event.guestlist && event.guestlist.data) {
            const data = Object.keys(event.guestlist.data)
                .map(id => event.guestlist.data[id])
                .filter(invitation => invitation.attributes[type])
                .map(invitation => Invitation.fromApiResponse(invitation));
            return {...event.guestlist, data};
        }
    }
);

export const getInvitations = createSelector(
    getCurrentEvent,
    (event) => {
        if (event.invitations && event.invitations.waitlist) {
            return Object.keys(event.invitations.waitlist.data).map(id => event.invitations.waitlist.data[id]);
        }
    }
);
