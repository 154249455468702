import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl } from '@angular/forms';

import { Observable ,  Subscription } from 'rxjs';
import { filter, shareReplay, debounceTime, map, tap, distinctUntilChanged } from 'rxjs/operators';
import { getBaseUrl, getEventUrl, EF_DOMAIN } from '../../../environment';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';

@Component({
    selector: 'event-URLs',
    template: require('./event-URLs.html'),
    styles: [require('./event-URLs.scss')],
})

export class EventURLsComponent implements OnDestroy, OnInit {
    private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
    private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
    private eventUpdate$: Subscription;
    private isEditingURL: boolean;
    private altKeyword: string;
    private searchTerm: FormControl = new FormControl();
    private isSearching: boolean = false;
    private isValidKeyword: boolean = true;
    constructor(
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
        private store: Store<fromStore.AppState>,
        private message: NzMessageService,
        private alertService: AlertService
    ) {
        this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
              this.baseUrl();
              this.eventUrl();
        });
        this.checkKeyword();
    }

    ngOnInit() {
      this.isEditingURL = false;
    }

    cancelEdit() {
        this.isEditingURL = !this.isEditingURL;
    }

    editURL() {
        if (this.isSearching) { return true; }
        if (this.isEditingURL) {
        // This prevents save from happening if no change has been made
        if (this.searchTerm.value === this.eventFarmService.currentEvent.altKeyword) {
          this.isEditingURL = !this.isEditingURL;
          return;
        }
        if (this.searchTerm.value.length && this.isValidKeyword) {
          this.apiClient.getUseCaseFactory().Event().SetAltKeywordForEvent(this.eventFarmService.currentEvent.id, this.searchTerm.value).toPromise().then( () => {
            this.eventFarmService.currentEvent.altKeyword = this.searchTerm.value;
            this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
            this.isEditingURL = !this.isEditingURL;
            this.message.success('URL updated');
        }).catch((err) => {
            this.message.error('Please try again');
        });
        }
      } else {
          this.searchTerm.setValue(this.eventFarmService.currentEvent.altKeyword);
          // this.altKeyword = this.eventFarmService.currentEvent.altKeyword;
          this.isEditingURL = !this.isEditingURL;
      }
    }

    getDomain(): string {
      return EF_DOMAIN;
    }

    checkKeyword() {
      this.searchTerm.valueChanges
            .pipe(debounceTime(1000),
                tap(() => {}),
                distinctUntilChanged()
            ).subscribe((term) => {
          this.isSearching = true;
          this.searchTerm.setValue(this.format(term));
          // If the term is the same as the current, don't search
          this.searchTerm.disable();

          if ( this.format(term) === this.eventFarmService.currentEvent.altKeyword ) {
                  this.isSearching = false;
                  this.isValidKeyword = true;
                  this.searchTerm.enable();
              } else {
                  this.apiClient.getUseCaseFactory().Event().CheckAltKeywordAvailability(this.format(term)).subscribe(res => {
                    this.searchTerm.enable();
                    this.isValidKeyword = res.data.attributes;
                    this.searchTerm.setValue(this.format(term));
                    this.isSearching = false;
                  }, (err) => {
                      this.isSearching = false;
                      this.searchTerm.enable();
                  });
              }
            });
    }
    format(val) {
        return val.trim()
            .replaceAll(' ', '-')
            .replace(/[^a-z0-9-]/gi, '')
            .replace(/-{2,}/g, '-'); // replace multiple dashes with single
    }
    baseUrl() {
      return getBaseUrl();
    }

    eventUrl() {
      return getEventUrl(this.eventFarmService.currentEvent);
    }

    async toUrlFromButton(type: string, isCanvas: boolean, waitlist: boolean = false, isPreview: boolean = false) {
      if (type === 'virbela') {
        const result = await this.alertService.virbelaIntegration().viewPreviewAlert();
        if (result.value) {
          window.open(this.buildUrl(type, isCanvas, false, isPreview), '_blank');
        }
      } else {
        window.open(this.buildUrl(type, isCanvas, false, isPreview), '_blank');
      }
    }

    copyUrlFromButton(type: string, isCanvas: boolean, waitlist: boolean = false, isPreview: boolean = false) {

      const url = this.buildUrl(type, isCanvas, waitlist, isPreview);
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.message.success('Link copied');
    }

    private buildUrl(type: string, isCanvas: boolean = true, waitlist: boolean = false, isPreview: boolean = false): string {

      if (type === 'transfer') {
          return `${this.eventFarmService.currentEvent.publicPaths.transfer}`;
      } else if (type === 'waitList') {
          if (waitlist) {
            return `${this.eventFarmService.currentEvent.modalPaths.waitlist}`;
          }
          return `${this.eventFarmService.currentEvent.publicPaths.waitlist}`;
      } else if (type === 'inviteReveal') {
          return `${this.eventFarmService.currentEvent.publicPaths.reveal}`;
      } else if (type === 'virbela') {
        return `${this.eventFarmService.currentEvent.virbelaPublicPaths.main}`;
     }

      return '';
    }

    ngOnDestroy() {
      this.eventUpdate$.unsubscribe();
    }
}
