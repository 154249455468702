import { AbstractBaseAlert } from '../base-alert';

export class TicketStackAlert extends AbstractBaseAlert {

    confirmTicketStackDeletion(stackName: string): Promise<any> {
        return this.alert({
            title: 'Stacks',
            text: 'Are you sure you want to delete the Stack (' + stackName + ')?',
            showCancelButton: true,
            icon: 'question',
            confirmButtonColor: '#ea2b38',
            focusCancel: true,
            confirmButtonText: 'Delete Stack'
        });
    }

    stackSuccessfullyRemoved(): Promise<any> {
        return this.alert({
            title: 'Stack',
            text: 'Stack successfully removed. The list will now refresh.',
            icon: 'success',
        });
    }

    unableToRemove(): Promise<any> {
        return this.alert({
            title: 'Stack',
            text: 'Stack could not be removed. Please check your permissions or try removing the access type.',
            icon: 'error',
        });
    }
}
