import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { AdminAlertService } from './adminEventFarmAlert.service';
import {NgxUtilitiesModule} from '../../../NGXUtilities/ngx.utilities.module';

const mainModules: any[] = [CommonModule];

const ngxOtherModules: any[] = [NgxChartsModule];

const adminModules: any[] = [];

@NgModule({
  imports: mainModules.concat(
    NgxUtilitiesModule,
    ngxOtherModules,
    adminModules
  ),
  declarations: [],
  providers: [AdminAlertService],
})
export class AdminAlertModule {}
