import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebPresenceService } from '../../web-presence.service';
const Color = require('color');


@Component({
  selector: 'web-presence-theme',
  template: require('./web-presence-theme.html'),
  styles: [require('./web-presence-theme.scss'), require('../../web-presence.scss')],
})

export class WebPresenceThemeComponent implements OnInit, OnDestroy {

  constructor(
    private webPresenceService: WebPresenceService
  ) {
  }

  private editingTheme: boolean;
  private showPreview: boolean;
  private hovered: string;

  ngOnDestroy(): void {
    this.editingTheme = false;
    this.showPreview = false;
  }
  ngOnInit(): void {
    this.editingTheme = false;
    this.showPreview = false;
  }

  editTheme() {
    if (this.editingTheme) {
      this.webPresenceService.saveTheme().then(res => {
        this.editingTheme = false;
      }).catch(e => {
        this.editingTheme = true;
      });
    } else {
      this.webPresenceService.freezeCurrentColors(true);
      this.editingTheme = true;
    }
  }

  get eventName(): string {
    return this.webPresenceService.currentEventName;
  }

  get eventTime(): string {
    return this.webPresenceService.currentEventTime;
  }

  cancelEdit() {
    this.webPresenceService.freezeCurrentColors(false);
    this.editingTheme = false;
  }

  getThemeColor(slug: string) {

    const colorForSlug = this.webPresenceService.currentEventThemeColors.find(color => color.slug === slug);


    if (colorForSlug !== undefined) {

      let c = Color(colorForSlug.description).object();

      if (!isNaN(c.alpha)) {
        c = {
          r: c.r,
          g: c.g,
          b: c.b,
          a: c.alpha
        };
      }

      if (c.a === undefined || isNaN(c.a)) {
        c.a = '1.0';
      }

      return `rgba(${c.r},${c.g},${c.b},${c.a})`;

    }
  }

}
