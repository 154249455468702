import { Injectable } from '@angular/core';
import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { GuestListViewService, UpdateInvitationCommand } from './guest-list-view.service';
import { StackSelectionService } from '../InvitationOptions/stackSelection.service';
import { BasicUserDetails } from '../UserForm/userForm.service';
import { Operator } from 'rxjs';

@Injectable()

export class InviteEditGuestService {
    private _updateInvitationCommand: UpdateInvitationCommand;
    private _basicUserDetails: BasicUserDetails;
    private _inviteCount: number;
    private _inviteCountChanged = false;

    constructor(
        private guestListService: GuestListViewService,
        private stackSelectionService: StackSelectionService,
        private apiClient: EventFarmAPIClient,
    ) {
        this._updateInvitationCommand = {
            invitationId: '',
            invitationStatus: '',
        };
    }

    get updateInvitationCommand(): UpdateInvitationCommand {
        return this._updateInvitationCommand;
    }

    set updateInvitationCommand(value: UpdateInvitationCommand) {
        this._updateInvitationCommand = value;
    }

    get updateUserDetauls(): BasicUserDetails {
        return this._basicUserDetails;
    }

    set updateUserDetails(value: BasicUserDetails) {
        this._basicUserDetails = value;
    }

    decrementInvitationCount() {
        this._inviteCountChanged = true;
        if (this._inviteCount > 0) {
            this._inviteCount--;
        }
    }

    incrementInvitationCount() {
        this._inviteCountChanged = true;
        if (this._inviteCount < 99) {
            this._inviteCount++;
        }
    }
}