import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AlertService } from '../../eventFarmAlert.service';
import { CreateEditEmailComponent } from './CreateEditEmail/create-edit-email.component';

@Injectable()

export class ConfirmDeactivateGuard implements CanDeactivate<CreateEditEmailComponent> {

    constructor(
        private alertService: AlertService,
    ) {
    }

    canDeactivate(component: CreateEditEmailComponent) {
        if (component.hasChanges()) {
            return this.alertService.emailDesignCenter().unsavedChanges()
                .then((res) => {
                    if (res.value) {
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            return true;
        }
    }
}
