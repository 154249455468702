import { CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { tap, filter, map, catchError, take, shareReplay } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromRoot from '../store';

@Injectable()
export class PoolsGuard implements CanActivate {
    constructor(private store: Store<fromRoot.AppState>) { }

    canActivate(): Observable<boolean> {
        return of(true);
    }
}
