import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PoolTypeaheadComponent } from './pool-typeahead.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxUtilitiesModule} from '../../../../NGXUtilities/ngx.utilities.module';

@NgModule({
    declarations: [PoolTypeaheadComponent],
    exports: [PoolTypeaheadComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUtilitiesModule
    ],
})

export class PoolTypeaheadModule {
}
