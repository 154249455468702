import { Component, Input } from '@angular/core';

import { DragulaService } from 'ng2-dragula';
import { EventFarmService } from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { Question } from '../../../../../ApiClient/Models/Question/question';
import { EditQuestionService } from './EditQuestion/edit-question.service';

@Component({
    selector: 'question-list-item',
    styles: [
        require('./question-list-item.scss'),
    ],
    template: require('./question-list-item.html'),
})

export class QuestionsListItemComponent {

    @Input() question: Question;

    constructor(
        private eventFarmService: EventFarmService,
        private editQuestionService: EditQuestionService,
        private alertService: AlertService,
        private dragulaService: DragulaService,
    ) {
    }

    private async toggleEnableOrDisable(question: Question) {
        await this.editQuestionService.toggleEnableOrDisable(question);
        this.question.toggleEnabledOrDisabled();
    }

    private shouldDisableDrag(question: Question) {
        return this.editQuestionService.isIndividualToggleDisabled(question);
    }
}
