import * as teamActions from '../actions/team.action';
import * as models from '../models';
import { Contract } from '../../../ApiClient/Models/Contract/contract';
import { Team } from '../../../ApiClient/Models/Pool/team';
import { WebConferenceUserConnection } from '../../../ApiClient/Models/WebConferenceUserConnection/web-conference-user-connection';

export interface TeamState {
    data: Team | null;
    features: any;
    contract: Contract | null;
    events: models.EventsState;
    poolWorlds: any;
    webConferenceConnections: any;
    loaded: boolean;
    loading: boolean;
}

export const initialTeamState = {
    data: new Team(''),
    features: models.featuresInitialState,
    contract: null,
    events: models.eventsInitialState,
    poolWorlds: models.poolWorldsInitialState,
    webConferenceConnections: models.webConferenceInitialState,
    loaded: false,
    loading: false,
};

export function reducer(
    state = initialTeamState,
    action: teamActions.TeamAction
): TeamState {
    const data = action.payload;

    switch (action.type) {

        case teamActions.FETCH_CURRENT_TEAM:
            return {
                ...state,
                loading: true
            };

        case teamActions.FETCH_CURRENT_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true
            };

        case teamActions.SET_CURRENT_TEAM:
            return {
                ...initialTeamState,
                loading: true
            };

        case teamActions.SET_CURRENT_TEAM_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
                loaded: true,
            };

        case teamActions.FETCH_FEATURES_FOR_TEAM:
            return {
                ...state,
                features: {
                    data: [],
                    loaded: false,
                    loading: true
                },
                loaded: false,
                loading: true,
            };

        case teamActions.FETCH_FEATURES_FOR_TEAM_SUCCESS:
            return {
                ...state,
                features: {
                    data,
                    loading: false,
                    loaded: true
                },
                loaded: true,
                loading: false,
            };

        case teamActions.FETCH_FEATURES_FOR_TEAM_FAIL:
            return {
                ...state,
            };

        case teamActions.FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM:
            return {
                ...state,
                webConferenceConnections: {
                    data: [],
                    loaded: false,
                    loading: true
                },
                loaded: false,
                loading: true,
            };

        case teamActions.FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM_SUCCESS:
            return {
                ...state,
                webConferenceConnections: {
                    data,
                    loading: false,
                    loaded: true
                },
                loaded: true,
                loading: false,
            };

        case teamActions.FETCH_WEB_CONFERENCE_CONNECTIONS_FOR_TEAM_FAIL:
            return {
                ...state,
            };

        case teamActions.FETCH_POOL_WORLDS_FOR_TEAM:
            return {
                ...state,
                poolWorlds: {
                    data: [],
                    loaded: false,
                    loading: true
                },
                loaded: false,
                loading: true,
            };

        case teamActions.FETCH_POOL_WORLDS_FOR_TEAM_SUCCESS:
            return {
                ...state,
                poolWorlds: {
                    data,
                    loading: false,
                    loaded: true
                },
                loaded: true,
                loading: false,
            };

        case teamActions.FETCH_POOL_WORLDS_FOR_TEAM_FAIL:
            return {
                ...state,
            };
        case teamActions.FETCH_EVENTS_FOR_TEAM:
            return {
                ...state,
                events: {
                    data: {},
                    loading: true,
                    loaded: false
                }
            };

        case teamActions.FETCH_EVENTS_FOR_TEAM_SUCCESS: {
            const eventEntities = action.payload.reduce((entities, event) => {
                return {
                    ...entities,
                    [event.id]: event
                };
            }, {});
            return {
                ...state,
                events: {
                    data: eventEntities,
                    loading: false,
                    loaded: true
                }
            };
        }

        case teamActions.FETCH_EVENTS_FOR_TEAM_FAIL:
            return {
                ...state,
                data,
                events: data
            };

        case teamActions.FETCH_CONTRACT_FOR_TEAM:
            return {
                ...state,
            };

        case teamActions.FETCH_CONTRACT_FOR_TEAM_SUCCESS:
            const contract = Contract.fromApiResponse(data);
            return {
                ...state,
                contract,
            };

        case teamActions.FETCH_CONTRACT_FOR_TEAM_FAIL:
            return {
                ...state,
                contract: null
            };
    }
    return state;
}
