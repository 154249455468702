import { Injectable } from '@angular/core';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';
import { EventFarmService } from '../../../eventFarm.service';
import { EfxStation } from '../../../../ApiClient/Models/EfxStation/efxStation';
import { shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import * as _ from 'lodash';
import { EmailDesign } from '../../../../Shared/EmailDesign/email-design';
import { EFXAdminService } from '../Admin/efx-admin.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { EFx } from '@eventfarm/javascript-sdk/dist/Api/Type/EFx';

@Injectable()
export class EfxStationService {
    constructor(
        private apiClient: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private message: NzMessageService,
        private store: Store<fromStore.AppState>,
        private efxService: EFXAdminService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    public efxStationsListOptions: EfxStationListOptions = {
        query: '',
        sortBy: 'createdAt',
        sortDirection: 'descending',
        pagination: {
            currentEfxStationPage: 1,
            itemsPerPage: 10,
        }
    };
    public readonly cdEmailDesign$ = new BehaviorSubject<string|null>(null);
    public readonly cdEmailDesignsToList$ = new BehaviorSubject<EmailDesign[]>([]);

    public readonly smsGreeting$ = new BehaviorSubject<string>('');
    public eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());

    public efxStationMetaSource = new BehaviorSubject<any>({});
    public efxStationMetaTotalResults = new BehaviorSubject<number>(0);

    public efxStationsMeta$ = this.efxStationMetaSource.asObservable();
    public readonly currentEfxStations$ = new BehaviorSubject<EfxStation[]>([]);
    public efxStationMetaTotalResults$ = new BehaviorSubject<number>(0);

    public isLoadingEfxStations: boolean = false;

    get currentEventName(): string {
        return this.eventFarmService.currentEvent ? this.eventFarmService.currentEvent.name : 'No Event Selected';
    }

    public stationScreens = {}

    get areScreenValid(): boolean {
        const invalidScreens = Object.keys(this.stationScreens).filter(screenKey => {
            return this.stationScreens[screenKey].image === null;
        })

        return invalidScreens.length === 0;
    }

    
    get currentModule(): string {
        const urlSplit = this.router.url.split("/");
        const module = urlSplit.pop()
        const type = new EFx().EFxModuleType().find(m => m.slug === module);
        return type ? type.name : "Module Name";
    }

    public clearList() {
        this.currentEfxStations$.next([]);
        this.cdEmailDesignsToList$.next([]);
        this.cdEmailDesign$.next('None');
        this.efxStationMetaSource.next(null);
        this.efxStationsListOptions.pagination.currentEfxStationPage = 1;
    }

    get efxStationCount(): number {
        return this.currentEfxStations$.value.length;
    }

    public async getEmailDesigns() {
        const eventId = this.eventFarmService.currentEvent.id;
        const designs = await this.apiClient.getUseCaseFactory().EmailDesign().ListEmailDesignsByEvent(eventId, 1, 500).toPromise();
        this.cdEmailDesignsToList$.next(designs.data.map(design => EmailDesign.fromApi(design)))
    }

    public get getConnectedEmailId() {
        return this.cdEmailDesign$.value;
    }

    public async getEfxStations() {
        this.isLoadingEfxStations = true;
        const eventId = this.eventFarmService.currentEvent.id;
        try {
            const efxEfxStation = await this.apiClient.getUseCaseFactory().EFx().ListEFxStationsForEvent(
                eventId,
                ['TicketType', 'EFxScreens'],
                this.efxStationsListOptions.sortBy,
                this.efxStationsListOptions.sortDirection,
                this.efxStationsListOptions.pagination.currentEfxStationPage,
                this.efxStationsListOptions.pagination.itemsPerPage,
                null,
                this.efxStationsListOptions.query,
            ).toPromise();

            this.efxStationMetaSource.next(efxEfxStation.meta);
            this.efxStationMetaTotalResults.next(efxEfxStation.meta.totalResults);
            this.efxStationMetaTotalResults$.next(efxEfxStation.meta.totalResults);
            const e  = efxEfxStation.data.map(items => EfxStation.fromApiResponse(items));
            this.currentEfxStations$.next(e);
            await this.getEmailDesigns();
            this.isLoadingEfxStations = false;
            return true;
        } catch (err) {
            this.message.error('Please try again');
            this.isLoadingEfxStations = false;
            return false;
        }
    }

    public async createEfxStation(efxStation: EfxStation, ticketTypeIds: string[]|null, imageUrl:string|null) {

        const screenRequests = this.generateScreenRequests();

        try {
            const response = await this.apiClient
                .getUseCaseFactory()
                .EFx()
                .CreateEFxStation(
                    this.eventFarmService.currentEvent.id,
                    efxStation.stationName,
                    efxStation.moduleType,
                    efxStation.stationType,
                    this.smsGreeting$.value,
                    imageUrl,
                    null,
                    ticketTypeIds,
                    screenRequests
                ).toPromise();
            return _.get(response, 'data.command.stationId');
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async updateEfxStation(id: string, efxStation: EfxStation, ticketTypeIds: string[]|null, imageUrl:string|null) {
        try {
            await this.apiClient
                .getUseCaseFactory()
                .EFx()
                .UpdateEFxStation(
                    id,
                    efxStation.stationName,
                    efxStation.stationType,
                    this.smsGreeting$.value,
                    imageUrl,
                    null,
                    ticketTypeIds
                ).toPromise();

            const listOfScreens = Object.keys(this.stationScreens).map(screenKey => {
                return {
                    'screenType': screenKey,
                    'screenId': this.stationScreens[screenKey].id,
                    'text': this.stationScreens[screenKey].screenMessage,
                    'textColor': this.stationScreens[screenKey].color,
                    'backgroundUrl': this.stationScreens[screenKey].image
                }
            })    

            await this.apiClient.getUseCaseFactory().EFx().UpdateEFxScreens(
                this.eventFarmService.currentEvent.id,
                listOfScreens
            ).toPromise();

            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async removeEfxStation(id: string) {
        try {
            const deletedEfxStation = await this.apiClient.getUseCaseFactory().EFx().RemoveEFxStation(id).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public async setContentDelivery(stationId?, imageUrl?) {
        let emailId = this.cdEmailDesign$.value;
        if (emailId === 'None') {
            emailId = null;
        }

        try {
            await this.apiClient
                .getUseCaseFactory()
                .EFx()
                .SetContentDeliveryForEFxStation(
                    stationId,
                    this.smsGreeting$.value,
                    imageUrl,
                    emailId
                ).toPromise();
            return true;
        } catch (err) {
            this.message.error('Please try again');
            return false;
        }
    }

    public uploadPoolImage = async (base64Image) => {
        try {
            const res = await this.apiClient.getUseCaseFactory().PoolImage().CreatePoolImage(this.eventFarmService.currentTeam.id, base64Image, 'efx').toPromise();
            const poolImageId = _.get(res, 'data.command.poolImageId');
            const poolImageRes = await this.apiClient.getUseCaseFactory().PoolImage().GetPoolImage(poolImageId).toPromise();
            return _.get(poolImageRes, 'data.attributes.imageUri');
        } catch (error) {
            this.message.error('Error uploading image');
            return false;
        }
    }

    private generateScreenRequests() {
        return Object.keys(this.stationScreens).map(screenKey => {
            return {
                screenType: screenKey,
                text: this.stationScreens[screenKey].screenMessage,
                textColor: this.stationScreens[screenKey].color,
                backgroundUrl: this.stationScreens[screenKey].image
            }
        }) 
    }
}

export interface Pagination {
    currentEfxStationPage: number;
    itemsPerPage: number;
}

export interface EfxStationListOptions {
    query: string;
    sortBy: string;
    sortDirection: string;
    pagination: Pagination;
}

export interface EfxScreenOptions {
    screenType: string;
    screenId: string;
    sortDirection: string;
    textColor: string;
    backgroundUrl: string;
}
