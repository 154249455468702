import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as fromRoot from '../../../../../store';

import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { EventFarmService } from '../../../../../eventFarm.service';
import { ModalService } from '../../../../../CoreUI/Modal/modal.service';
import { LegacyUIService } from '../../../../../LegacyUI/legacy-UI.service';
import { AlertService } from '../../../../../eventFarmAlert.service';
import { IconService } from '../../../../../CoreUI/Icons/icons.service';
import { EditEventConfigComponent } from './EditEventConfig/edit-event-config.component';
import { EfEvent } from '../../../../../../ApiClient/Models/Event/event';
import moment = require('moment');
import { EditEventEfxComponent } from './EditEventConfig/EditEventEfX/edit-event-efx.component';

@Component({
  selector: 'event-list-item',
  template: require('./event-list-item.html'),
  styles: [require('./event-list-item.scss')],
  // encapsulation: ViewEncapsulation.None,
})
export class EventListItemComponent implements OnInit, OnDestroy {
  @Input() event;
  @ViewChild('glItem', { static: false }) glItem: ElementRef;
  private invitationStatusColor: object;
  private localEvent: EfEvent;
  // private removingGuest: boolean = false;
  private eventStore$: Observable<any> = this.store
    .select('event')
    .pipe(shareReplay());

  constructor(
    private eventFarmService: EventFarmService,
    private store: Store<fromRoot.AppState>,
    private legacyUIService: LegacyUIService,
    private message: NzMessageService,
    private modalService: ModalService,
    private alertService: AlertService,
    private iconService: IconService,
    private drawerService: NzDrawerService
  ) {
    // this.eventUpdate$ = this.eventStore$.subscribe(res => this.eventIntegration = res.salesforceIntegration);
  }

  ngOnInit() {
    // this.guest = Invitation.fromApiResponse(this.guestApi);
    // this.getStatusColor();
  }

  displayHumanReadableAction(action) {
    let humanReadableAction = '';
    action = String(action).toLowerCase() || '';

    switch (action) {
      case 'clicked':
        humanReadableAction = 'Clicked';
        break;
      case 'received':
        humanReadableAction = 'Received';
        break;
      case 'processed':
        humanReadableAction = 'Sent';
        break;
      case 'spamreport':
        humanReadableAction = 'Reported As Spam';
        break;
      case 'bounce':
        humanReadableAction = 'Bounced';
        break;
      case 'deferred':
        humanReadableAction = 'Deferred';
        break;
      case 'dropped':
        humanReadableAction = 'Dropped';
        break;
      case 'open':
        humanReadableAction = 'Opened';
        break;
      case 'delivered':
        humanReadableAction = 'Delivered';
        break;
      case 'none':
        humanReadableAction = 'None';
        break;
      default:
        humanReadableAction = 'Unknown';
        break;
    }
    return humanReadableAction;
  }

  ngOnDestroy() {
    // this.eventUpdate$.unsubscribe();
  }

  formattedTime(time) {
    return moment(time).format('MMMM Do YYYY, h:mm:ss a');
  }

  fromUnix(timestamp) {
    return moment.unix(timestamp).format('MMMM Do YYYY, h:mm:ss a');
  }

  openEventSlideout() {
    const drawerRef = this.drawerService.create<
      EditEventConfigComponent,
      { eventId: string },
      EfEvent
    >({
      nzTitle: '',
      nzContent: EditEventConfigComponent,
      nzWidth: '50%',
      nzContentParams: {
        eventId: this.event.id,
      },
      nzClosable: false,
    });

    drawerRef.afterClose.subscribe(async (data) => {
      if (data) {
        this.localEvent = data;
        // this.getStatusColor();
      } else {
      }
    });
  }

  openEfxSlideout() {
    const drawerRef = this.drawerService.create<
      EditEventEfxComponent,
      { eventId: string },
      EfEvent
    >({
      nzTitle: '',
      nzContent: EditEventEfxComponent,
      nzWidth: '50%',
      nzContentParams: {
        eventId: this.event.id,
      },
      nzClosable: false,
    });

    drawerRef.afterClose.subscribe(async (data) => {
      if (data) {
        this.localEvent = data;
        // this.getStatusColor();
      } else {
      }
    });
  }
}
