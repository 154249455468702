import { Event, VariantTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Event';
import { AbstractModel } from '../abstract-model';
import { EfEvent } from '../Event/event';
import { Venue } from '../Event/venue';
import { FormattedDateTime } from '../formatted-datetime';
import { Team } from '../Pool/team';
import { Stack } from '../Stack/stack';
import { TicketType } from '../Stack/ticket-type';
import { Track } from '../Track/track';

export interface UpdatableSessionOptions {
    name: string|null;
    description: string|null;
    startTime: string|null;
    endTime: string|null;
    variant: string|null;
    capacity: number|null
}

export interface CreateSessionOptions {
    name: string;
    description: string;
    startTime: string;
    endTime: string;
    variant: string;
}

export class EFSession extends AbstractModel {
    private _name?: string;
    private _description?: string;
    private _startTime?: FormattedDateTime;
    private _endTime?: FormattedDateTime;
    private _event: EfEvent;
    private _capacity: number;
    private _tracks: Track[];
    private _variant: VariantTypeInterface;
    private _team?: Team;
    private _venue?: Venue;


    private _stacks: Stack[];
    private _ticketTypes: TicketType[];

    constructor(id?: string) {
        super(id);
    }

    public static forSessionCreation(data): EFSession {
        const event = new this();

        try {
            const { name, endTime, startTime, description, capacity } = data;
            event._name = name;
            event._startTime = startTime;
            event._endTime = endTime;
            event._description = description;
            event._capacity = capacity;
        } catch (err) {
        }
        return event;

    }

    public static fromApiResponse(data) {
        const session = new this(data.id);

        session._name = data.attributes.name;
        session._description = data.attributes.eventDescription;
        session._startTime = data.attributes.startTime;
        session._endTime = data.attributes.endTime;
        session._variant = data.attributes.variant;
        session._venue = data.attributes.venue ? Venue.fromEventResponse(data.attributes.venue) : null;


        session._tracks = [];

        if (data.relationships && data.relationships.event) {
            session._event = EfEvent.fromApiResponse(data.relationships.event);
        }

        if (data.relationships && data.relationships.eventTracks) {
            session._tracks = data.relationships.eventTracks.map(eventTrack => {
                return Track.fromApiResponse(eventTrack.relationships.track);
            })
        }

        session._stacks = [];
        session._ticketTypes = [];

        if (data.relationships && data.relationships.stacks) {
            data.relationships.stacks.forEach(stack => {
              stack = Stack.fromApiResponse(stack);
              if (!stack.isDeleted) {
                session._stacks.push(stack);
              }
            });
        }
        
        if (data.relationships && data.relationships.ticketTypes) {
            data.relationships.ticketTypes.forEach(ticketType => {
              ticketType = TicketType.fromApiResponse(ticketType);
              if (!ticketType.isDeleted) {
                session._ticketTypes.push(ticketType);
              }
            });

            if (session._ticketTypes.length) {
                const val = session._ticketTypes.find(f => f.name === 'Session');

                if (val !== undefined) {
                    session.capacity = val.quantity;
                } else {
                    session.capacity = 50
                }

            }
        }

        if (data.relationships && data.relationships.pool) {
            session._team = Team.fromApiResponse(data.relationships.pool);
          }

        return session;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    set startTime(value: FormattedDateTime) {
        this._startTime = value;
    }

    set endTime(value: FormattedDateTime) {
        this._endTime = value;
    }

    get startTime(): FormattedDateTime {
        return this._startTime;
    }

    get endTime(): FormattedDateTime {
        return this._endTime;
    }

    get event(): EfEvent {
        return this._event;
    }

    set event(value: EfEvent) {
        this._event = value;
    }

    get tracks(): Track[] {
        return this._tracks;
    }

    set tracks(value: Track[]) {
        this._tracks = value;
    }
    
    get stacks(): Stack[] {
        return this._stacks;
    }

    set stacks(value: Stack[]) {
        this._stacks = value;
    }

    get ticketTypes(): TicketType[] {
        return this._ticketTypes;
    }

    set ticketTypes(value: TicketType[]) {
        this._ticketTypes = value;
    }

    get shouldCreateTicketTypeAndStack(): boolean {
        return !this.ticketTypes.length && !this.stacks.length
        
    }

    get shouldCreateStackAndNotTicketType(): boolean {
        return this.ticketTypes.length && !this.stacks.length
    }

    get capacity(): number {
        return this._capacity;
    }

    set capacity(value: number) {
        this._capacity = value;
    }

    get isTypeSession(): boolean {
        return this._variant.isSession;
    }

    get trackList(): string {
        if (this.tracks.length) {
            return this._tracks.map(track => track.name).join(', ');
        }

        return '';
    }

    get team() {
        return this._team;
    }

    get venue(): Venue {
        return this._venue;
    }
    
    set venue(value: Venue) {
        this._venue = value;
    }

    get variant(): VariantTypeInterface {
        return this._variant;
    }
    
    set variant(value: VariantTypeInterface) {
        this._variant = value;
    }
}
