import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../eventFarm.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { ChildEventsService } from './child-events.service';

@Component({
    selector: 'child-events',
    template: require('./child-events.html'),
    styles: [require('./child-events.scss')],
})

export class ChildEventsComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private childEventsService: ChildEventsService
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {

    }

    ngOnDestroy() {
    }
}
