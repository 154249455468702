import { TicketBlockResolver } from './ticket-block-resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TicketBlockDashboardCoreRoutesComponent } from './ticket-block-dashboard-core-routes.component';

const ticketBlocksRoutes: Routes = [
    {
        path: '',
        component: TicketBlockDashboardCoreRoutesComponent,
        resolve: {
            data: TicketBlockResolver
        },
        children: [
            {
                path: ':event',
                loadChildren: () => import('./TicketBlockDashboard/ticketBlockDashboard.module').then(
                    module => module.TicketBlockDashboardModule
                ),
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(ticketBlocksRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class TicketBlockRoutingModule {
}