import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';
import { SegmentService } from '../../../Analytics/Segment/segment.service';
import { ModalService } from '../../CoreUI/Modal/modal.service';
import { EventFarmService } from '../../eventFarm.service';
import { LegacyUIService } from '../../LegacyUI/legacy-UI.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import * as eventActions from './../../store/actions/event';
import { Modal } from '../../CoreUI/Modal/modal.class';
import { QuickEventAccess } from './QuickEventAccess/quick-event-access.component';
import { QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED } from '../../../Analytics/Segment/actions';

@Component({
    selector: 'tickets-invitations-registrations',
    providers: [
        ModalService,
    ],
    template: require('./event-access.html'),
    styles: [require('./event-access.scss')],
})

export class EventAccessComponent implements OnInit, OnDestroy {

    constructor(
        private eventFarmService: EventFarmService,
        private legacyUIService: LegacyUIService,
        private store: Store<fromRoot.AppState>,
        private modalService: ModalService,
        private segmentService: SegmentService
    ) {
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    ngOnInit() {
        this.segmentService.segmentTicketTracking().loadTab(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
        this.segmentService.segmentSideNavTracking().eventAccessEntered();
        this.store.dispatch(new eventActions.FetchAccessTypes());
    }

    ngOnDestroy() {
        this.segmentService.segmentTicketTracking().closeTab(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
    }

    openAdvancedCreateTicketType() {
        this.legacyUIService.openCoreUrlInModal(
            '/tickettypes/manageTickettype/' + this.eventFarmService.currentEvent.id,
            'Create Access Type'
        );

        this.segmentService.segmentTicketTracking().createTicketType(
            this.eventFarmService.currentEvent,
            this.eventFarmService.currentUser
        );
    }
    openQuickCreateTicketType() {
        const modal: Modal = new Modal();
        modal.component = QuickEventAccess;
        modal.cssClass = 'thin-header';
        modal.segment = QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED;
        modal.data = this.eventFarmService.currentEvent;
        this.modalService.changeModal(modal);
    }
}
