import { AbstractModel } from '../abstract-model';
import { User } from '../User/user';

export class PoolContact extends AbstractModel {
    private _description?: string;
    private _isCreate?: boolean;
    private _isFull?: boolean;
    private _name?: string;
    private _email?: string;
    private _user?: User;
    private _contactType?: any;

    private _slug?: string;

    constructor(id: string) {
        super(id);
    }

    public static fromApiResponse(data): PoolContact {
        const poolContact = new this(data.id);
        poolContact._email = data.relationships.user.attributes.primaryEmail;
        poolContact._contactType = data.attributes.poolContactType;
        poolContact._user = User.fromApiResponse(data.relationships.user);
        return poolContact;
    }
    get name(): string {
        return this._name;
    }
    get contactType(): any {
        return this._contactType;
    }
    get email(): string {
        return this._email;
    }
    get user(): User {
        return this._user;
    }
    get id(): string {
        return this._id;
    }
}
