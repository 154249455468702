import {Component, Input, OnInit} from '@angular/core';
import { UploaderService } from './uploader.service';
import {EventFarmService} from "../../eventFarm.service";

@Component({
    selector: 'uploader-information',
    template: require('./uploader-information.html'),
    styles: [require('./uploader-information.scss')],
})

export class UploaderInformationComponent implements OnInit {
    @Input() parentLayout?: 'row' | 'column' = 'column';
    @Input() hideUploadInfoSectionHeader?: boolean = false;
    @Input() hideUploadInfoTemplateLinks?: boolean = false;
    @Input() uploadInfoSectionContent?: string = 'We will give you a preview of how the system interprets your data before you complete your upload.';

    readonly xlsxIcon = require('./assets/xlsx-icon');
    readonly csvIcon = require('./assets/csv-icon');

    constructor(
        private uploaderService: UploaderService,
        private eventFarmService: EventFarmService
    ) {}

    ngOnInit() {

    }
}
