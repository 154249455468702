import { Component, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../../../eventFarm.service';
import { ModalComponent } from '../../../CoreUI/Modal/modal.component';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { ModalServiceComponent } from '../../../CoreUI/Modal/modalService.component';

@Component({
    template: require('./table-editor.html'),
    styles: [require('./table-editor.scss')],
})

export class TableEditorComponent extends ModalServiceComponent implements AfterViewInit {

    private td;
    private cell;
    private tableEditor;
    private tableHTML;
    private selectedCells = [];
    private table;

    private nativeElement: Node;

    constructor(
        private eventFarmService: EventFarmService,
        private client: EventFarmAPIClient,
        modalService: ModalService,
        private modal: ModalComponent,
        private elementRef: ElementRef,
        @Inject(FormBuilder) fb: FormBuilder,
    ) {
        super(modalService);
        this.cell = this.modalService.data.closest('td');
        this.td = this.modalService.data.offsetParent.children[0].children[0].children;
        this.table = this.modalService.data.offsetParent; // Table to update
        this.tableHTML = this.modalService.data.offsetParent.innerHTML; // Static String

        let paddingHeight = this.modalService.data.style['paddingTop'].split('px')[0];
        let paddingWidth = this.modalService.data.style['paddingLeft'].split('px')[0];

        let borderSpacing = this.modalService.data.offsetParent.style.borderSpacing.split('px')[0];
        // let cellPadding = this.modalService.data.offsetParent.style.cellPadding.split('px')[0];
        let borderCollapse = this.modalService.data.offsetParent.style.borderCollapse;
        let backgroundColor = this.modalService.data.offsetParent.style.backgroundColor;
        let maxWidth = this.modalService.data.offsetParent.style.maxWidth;
        let width = this.modalService.data.offsetParent.style.width;

        this.tableEditor = fb.group({
            borderSpacing: borderSpacing === '' ? '0' : borderSpacing,
            cellPadding: paddingHeight === '' ? '0' : paddingHeight,
            backgroundColor: backgroundColor === '' ? this.previewBackgroundColor(this.modalService.data) : backgroundColor,
            maxWidth: maxWidth === '' ? '100%' : maxWidth,
            width: width === '' ? '100%' : width,
        });
    }

    ngAfterViewInit() {
        this.clearSelectedCells();
        this.getSelectedCells();
        this.elementRef.nativeElement.querySelectorAll('td').forEach(i => i.addEventListener('click', (e) => {
            e.target.closest('td').classList.toggle('edit-this-cell');
            let smallestPadding = [].slice.call(this.elementRef.nativeElement.querySelectorAll('td.edit-this-cell')).sort((a,b) => {
                return a.style.padding.split('px')[0] - b.style.padding.split('px')[0];
            });
            this.elementRef.nativeElement.querySelectorAll('td.edit-this-cell').forEach(td => {
                this.tableEditor.patchValue({ cellPadding: smallestPadding[0].style.padding  === '' ? '0px' : smallestPadding[0].style.padding });
            });
        }));

        this.tableEditor.valueChanges.subscribe(() => {
            this.elementRef.nativeElement.querySelectorAll('td.edit-this-cell').forEach((i) => {
                i.style.padding = this.tableEditor.controls.cellPadding.value + 'px';
            });
        });

        this.clickSelectedCells();
    }

    replaceTable(tablePreview) {
        this.clearSelectedCells();
        this.table.outerHTML = `${tablePreview}`;
        this.close();
    }

    backgroundColorChange(color) {
        this.tableEditor.patchValue({ backgroundColor: color });
    }

    previewBackgroundColor(self) {
        let el = self;
        while (el.offsetParent) {
            el = el.offsetParent;
            if (el.style.backgroundColor && el.style.backgroundColor !== 'transparent')
                return el.style.backgroundColor;
            if (el.bgColor)
                return el.bgColor;
        }
        return null;
    }

    private getSelectedCells() {
        this.selectedCells = [];
        let tbody = this.table.querySelector('tbody');
        tbody.querySelectorAll('tr').forEach((row, index) => {
            this.getSelectedColumn(row, index);
        });
    }

    private getSelectedColumn(row, rowIndex) {
        row.querySelectorAll('td').forEach((column, index) => {
            if ((column.className).indexOf('fr-selected-cell') > -1) {
                let cell = {
                    'row': rowIndex,
                    'column': index
                };
                column.classList.add('edit-this-cell');
                this.selectedCells.push(cell);
            }
        });
    }

    private clickSelectedCells() {
        this.elementRef.nativeElement.querySelectorAll('tr').forEach((row, rowIndex) => {
            row.querySelectorAll('td').forEach((column, columnIndex) => {
                this.selectedCells.forEach((cell) => {
                    if (cell.row === rowIndex && cell.column === columnIndex) {
                        column.classList.add('edit-this-cell');
                    }
                });
            });
        });
    }

    private clearSelectedCells() {
        this.elementRef.nativeElement.querySelectorAll('td.edit-this-cell').forEach((i) => {
            i.classList.remove('edit-this-cell');
        });
    }
}