import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Exhibitor } from '../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { EditExhibitorComponent } from './EditExhibitor/edit-exhibitor.component';

@Component({
    selector: 'edit-exhibitor-item-button',
    template: `
        <span nzTooltipTitle="Edit exhibitor" nz-tooltip (click)="openComponent()">
            <i class="fa fa-pencil" style="cursor: pointer; color: #5085F0FF;"></i>
        </span>`
})

export class EditExhibitorButtonComponent implements OnInit {
    @Input() exhibitor: Exhibitor;
    width = '50%';
    public componentClicked: boolean = false;

    constructor(
        private drawerService: NzDrawerService,
    ) {}

    ngOnInit() {}

    openComponent(): void {
        if (!this.componentClicked) {
            this.componentClicked = true;
            const drawerRef = this.drawerService.create<EditExhibitorComponent, { exhibitor: Exhibitor }, string>({
                nzTitle: 'Edit Exhibitor',
                nzContent: EditExhibitorComponent,
                nzWidth: this.width,
                nzContentParams: {
                    exhibitor: this.exhibitor,
                }
            });

            drawerRef.afterOpen.subscribe(() => {
            });

            drawerRef.afterClose.subscribe(data => {
                this.componentClicked = false;
            });
        }

    }

}
