import { AbstractModel } from '../abstract-model';

import { LinkTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Link';
import { Team } from '../Pool/team';

export class EFLink extends AbstractModel {

    private _isDeleted: boolean;
    private _linkType: LinkTypeInterface;
    private _shownText: string;
    private _url: string|null;
    private _pool: Team;

    constructor(id?: string) {
        super(id);
    }

    public static fromApiResponse(data): EFLink {
        const link = new this(data.id);

        try {
            link._isDeleted = data.attributes.isDeleted;
            link._linkType = data.attributes.linkType;
            link._shownText = data.attributes.shownText;
            link._url = data.attributes.url;

            if (data.relationships && data.relationships.pool) {

                link._pool = Team.fromApiResponse(data.relationships.pool)
            }
            
        } catch (err) { }
        return link;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get linkType(): LinkTypeInterface {
        return this._linkType;
    }

    set linkType(value: LinkTypeInterface) {
        this._linkType = value;
    }

    get shownText(): string {
        return this._shownText;
    }

    set shownText(value: string) {
        this._shownText = value;
    }

    get url(): string | null {
        return this._url;
    }

    set url(value: string | null) {
        this._url = value;
    }

    get pool(): Team {
        return this._pool;
    }

    set pool(value: Team) {
        this._pool = value;
    }
}
