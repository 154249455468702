import * as moment from 'moment';

export class DateTimeUtility {
    public format(time: string | number | Date, format: string): string {
        return moment(time).format(format);
    }

    public laterThanNow(date: Date): boolean {
        return date > this.now();
    }

    public beforeNow(date: Date): boolean {
        return date < this.now();
    }

    public formatToDate(format: string): Date {
        return moment(format).toDate();
    }

    public now(): Date {
        return new Date();
    }
}
