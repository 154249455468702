import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../CoreUI/Modal/modal.service';
import { ModalServiceComponent } from '../../../../CoreUI/Modal/modalService.component';
import { GuestListService } from '../../guest-list.service';
import { AddGroupService } from './addGroup.service';

import { Group } from '../../../../../ApiClient/Models/Group/group';
import { SegmentService } from '../../../../../Analytics/Segment/segment.service';
import {EventFarmService} from '../../../../eventFarm.service';
import { AlertService } from '../../../../eventFarmAlert.service';
import { StackSelectionService } from '../../../../CoreUI/InvitationOptions/stackSelection.service';
import { RouteGeneratorService } from '../../../../../_services/routes/route-generator.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'add-group',
    styles: [require('../sharedInvite.scss'),
        require('./addGroup.scss')],
    template: require('./addGroup.html'),
})

export class AddGroupComponent extends ModalServiceComponent implements OnInit {
    readonly inviteGroupSVG = require('./../assets/InviteGroup-Icon');

    private selectedGroup = new Group('');
    private loading;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private routeGenerator: RouteGeneratorService,
        public modalService: ModalService,
        private alertService: AlertService,
        private message: NzMessageService,
        private guestListService: GuestListService,
        private stackSelectionService: StackSelectionService,
        private addGroupService: AddGroupService,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService
    ) {
        super(modalService);
    }

    private context: string = 'group';
    private isGuestDetailsActive = true;

    ngOnInit() {
        this.segmentService.segmentGuestListTracking().addGroup();
    }

    createInviteFromGroup() {
        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentTeam.id;

        if (this.stackSelectionService.invitationCreationTypeSlug === 'send-email') {
            this.alertService.guestList().confirmGroupAddedToEventWithEmails(this.selectedGroup.name)
                .then((res) => {
                    if (res.value) {
                        this.addGroupService.createInvitationFromGroup(this.selectedGroup.id)
                            .subscribe(() => {
                                this.alertService.guestList().groupAddedToEvent(this.selectedGroup.name, true);
                                // this.message.success('Group added successfully to your event, and invitation emails are queued');
                                this.eventFarmService.updateEventDetails(eventId);
                                this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId, eventId }));
                            });
                    }
                });

        } else {
            this.addGroupService.createInvitationFromGroup(this.selectedGroup.id)
                .subscribe(() => {
                    this.alertService.guestList().groupAddedToEvent(this.selectedGroup.name);
                    this.eventFarmService.updateEventDetails(eventId);
                    // this.message.success('Group added successfully to your event');
                    this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId, eventId }));
                });
        }
    }

    private selectGroup(group: Group): void {
        this.selectedGroup = group;
        this.addGroupService.getGroupDetails(group.id).toPromise()
            .then((res) => {
                this.selectedGroup = Group.fromApiResponse(res.data);
            });
    }

    createInvitationsFromGroupForCIOEvent() {
        const eventId = this.eventFarmService.currentEvent.id;
        const poolId = this.eventFarmService.currentTeam.id;
        this.addGroupService.createInvitationsFromGroupForCIOEvent(eventId, this.selectedGroup.id)
            .subscribe( () => {
                this.alertService.guestList().groupAddedToEvent(this.selectedGroup.name);
                this.eventFarmService.updateEventDetails(eventId);
                this.router.navigateByUrl(this.routeGenerator.url('events.guest-list', { poolId, eventId }));
            });
    }
}
