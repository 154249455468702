import { Injectable } from '@angular/core';
import { AlertService } from '../../eventFarmAlert.service';
import { Observable, Subject } from 'rxjs';

import { EventFarmAPIClient } from '../../../ApiClient/event-farm-api-client';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { Salutation } from '../../../ApiClient/Models/Salutation/salutation';
import { EventFarmService } from '../../eventFarm.service';

@Injectable()
export class UserFormService {

    public basicUserDetails: BasicUserDetails;
    public initialUserDetails: BasicUserDetails;
    public userAddEditContext: 'edit-invitation' | 'add-user' | 'edit-user';
    public requesting: boolean;
    private _salutations: Salutation[];
    public userChangeSource = new Subject<any>();
    public userChange$ = this.userChangeSource.asObservable();
    public userSubmitSource = new Subject<any>();
    public userSubmit$ = this.userSubmitSource.asObservable();

    constructor(
        private alertService: AlertService,
        private eventFarmService: EventFarmService,
        private apiClient: EventFarmAPIClient,
    ) {
        this._salutations = [];
        this.fetchSalutations();
    }

    get salutations(): Salutation[] {
        return this._salutations;
    }

    fetchSalutations(): void {
        this.apiClient.getUseCaseFactory()
            .Salutation()
            .GetAllSalutations()
            .subscribe((res) => {
                this._salutations = [];
                for (const salutation of res.data) {
                    this._salutations.push(Salutation.fromApiResponse(salutation));
                }
            });
    }

    fetchCountryList(): any {
        return this.apiClient.getCountryList().CountryList();
    }

    fetchUsaStateList(): any {
        return this.apiClient.getUsaStateList().StateList();
    }

    change() {
        this.userChangeSource.next(this.basicUserDetails);
    }

    save() {
        this.userSubmitSource.next(this.basicUserDetails);
    }
}

export interface BasicUserDetails {
    company?: string;
    position?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    telephone?: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    title?: string;
    other?: string;
    hasChanges?: boolean;
    isValid?: boolean;
}
