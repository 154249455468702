import {Injectable} from '@angular/core';
import {EventFarmAPIClient} from '../../../../ApiClient/event-farm-api-client';

import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {AngularRestClient} from '../../../../_services/rest/AngularRestClient';
import {PaymentGateway} from '../../../../ApiClient/Models/PaymentGateway/paymentGateway';
import {PoolPaymentGateway} from '../../../../ApiClient/Models/PoolPaymentGateway/poolPaymentGateway';
import {EventFarmService} from '../../../eventFarm.service';
import * as _ from 'lodash';

@Injectable()
export class PaymentSettingsService {
    teamStore$: Observable<any> = this.store.select('team');
    gatewaysSource$ = new BehaviorSubject<any>([]);
    poolGatewaysSource$ = new BehaviorSubject<any>([]);

    public gateways$ = this.gatewaysSource$.asObservable();

    private loading: boolean = false;
    private poolGatewayByName: { [key: string]: any } = {};
    private initialized: boolean = false;
    private currentTeamSubscription$: Subscription;

    constructor(
        private apiClient: EventFarmAPIClient,
        private http: AngularRestClient,
        private store: Store<fromRoot.AppState>,
    ) {
    }

    async init() {
        this.currentTeamSubscription$ = this.teamStore$.subscribe(val => {
            if (_.get(val, 'data.id')) {
                if (!this.initialized) {
                    this.initialize(val.data.id);
                }
            }
        });
    }

    async initialize(teamId) {
        this.loading = true;
        await this.fetchPaymentGatewaysForPool(teamId);
        await this.fetchPaymentGatewaysForSpreedly(teamId);
        this.loading = false;
        this.initialized = true;
    }

    getPoolPaymentGatewayByName(name: string): PoolPaymentGateway {
        return this.poolGatewayByName[name];
    }

    fetchPaymentGatewaysForSpreedly(teamId) {
        this.apiClient.getUseCaseFactory()
            .PoolPaymentGateway()
            .ListPaymentGatewaysForSpreedly(teamId)
            .toPromise()
            .then((res) => {
                const paymentGateways = res.data.gateways.map(data => PaymentGateway.fromApiResponse(data));
                this.gatewaysSource$.next(paymentGateways);
            }).catch((error) => {

        });
    }

    async fetchPaymentGatewaysForPool(teamId) {
        this.apiClient.getUseCaseFactory()
            .PoolPaymentGateway()
            .ListPaymentGatewaysForPool(teamId)
            .toPromise()
            .then((res) => {
                this.poolGatewayByName = {}
                const poolGateways = res.data.map(data => PoolPaymentGateway.fromApiResponse(data));
                poolGateways.forEach((pg: PoolPaymentGateway) => {
                    this.poolGatewayByName[pg.paymentGateway.stringId] = pg;
                });
                this.poolGatewaysSource$.next(this.poolGatewayByName);
            }).catch((error) => {

        });
    }

    async makeDefault(poolPaymentGatewayId) {
        return this.apiClient.getUseCaseFactory()
            .PoolPaymentGateway()
            .SetDefaultPaymentGatewayForPool(poolPaymentGatewayId)
            .toPromise();
    }

    async createPoolPaymentGateway(teamId, friendlyName, gateway, params) {
        return this.apiClient.getUseCaseFactory()
            .PoolPaymentGateway()
            .CreatePaymentGatewayForPool(teamId, friendlyName, gateway, params)
            .toPromise();
    }

    async updatePoolPaymentGateway(poolPaymentGatewayId, name, gateway, params) {
        return this.apiClient.getUseCaseFactory()
            .PoolPaymentGateway()
            .UpdatePaymentGatewayForPool(poolPaymentGatewayId, name, gateway, params)
            .toPromise();
    }

    async deletePoolPaymentGateway(poolPaymentGatewayId) {
        return this.apiClient.getUseCaseFactory()
            .PoolPaymentGateway()
            .RemovePaymentGatewayForPool(poolPaymentGatewayId)
            .toPromise();
    }

    ngOnDestroy() {
        this.currentTeamSubscription$.unsubscribe();
    }
}
