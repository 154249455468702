import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormControl,
} from '@angular/forms';
import {getGetContentLengthWithoutSlugs} from '../../../../../Shared/Validators/textAreaSlugContentValidator';
@Component({
    selector: 'text-area-with-slugs',
    template: require('./text-area-with-slugs.html'),
    styles: [require('./text-area-with-slugs.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextAreaWithSlugsComponent),
            multi: true,
        },
    ],
})
export class TextAreaWithSlugsComponent implements ControlValueAccessor {
    @Input() heading: string;
    @Input() content: string;
    @Input() maxChar: string;
    @Input() slugs: any[];
    @ViewChild('textAreaElement', { static: false }) textAreaRef: ElementRef;

    value: any;

    constructor() {
    }

    input = new FormControl('');

    addSlug(slugToAdd: string) {

        const start = this.textAreaRef.nativeElement.selectionStart;
        const end = this.textAreaRef.nativeElement.selectionEnd;
        const newGreeting =
            this.input.value.slice(0, start) + slugToAdd + this.input.value.slice(end);

        this.handleChange(newGreeting);
        setTimeout(() => {
            // const element = document.getElementById('efxtxtarea') as HTMLInputElement;
            const location = end + slugToAdd.length;
            this.textAreaRef.nativeElement.focus()
            this.textAreaRef.nativeElement.setSelectionRange(location, location);
        }, 10);
    }

    // Step 3: Instead of a simple string variable, use a FormControl

    get charactersUsed(): number {
        return getGetContentLengthWithoutSlugs(this.input.value);
    }

    handleChange(val) {
        this.input.setValue(val);
    }

    // Step 4: use the setValue method on the form control to update it when a new value comes in from the outside
    writeValue(input: string) {
        this.input.setValue(input);
    }

    // Step 5: Listen to value changes on the form control. If something happens, the function provided by Angular will be called and the outside world will be informed.
    subscriptions = [];
    registerOnChange(fn: any): void {
        this.subscriptions.push(this.input.valueChanges.subscribe(fn));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    // Step 6: onTouch stays the same
    onTouch: any = () => {};
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
}
