import { LaunchCanvasComponent } from './../../../CoreUI/Modal/Modals/LaunchCanvas/LaunchCanvas.component';
import { StackMethodTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Stack';
import {Component, OnInit, OnDestroy} from '@angular/core';
import { EventFarmAPIClient } from './../../../../ApiClient/event-farm-api-client';
import { ModalService } from './../../../CoreUI/Modal/modal.service';
import { PaymentInfoComponent } from './PaymentInfo/payment-info.component';
import { DistributionInfoComponent } from './DistributionInfo/distribution-info.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Modal } from '../../../CoreUI/Modal/modal.class';
import { Store } from '@ngrx/store';
import * as eventActions from './../../../store/actions/event';
import * as fromRoot from './../../../store';
import { EfEvent } from '../../../../ApiClient/Models/Event/event';
import { filter, tap } from 'rxjs/operators';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED } from '../../../../Analytics/Segment/actions';
import { EventFarmService } from '../../../eventFarm.service';
import { Virbela } from '@eventfarm/javascript-sdk/dist/Api/Type/Virbela';
import { Subscription } from 'rxjs';

@Component({
    selector: 'quick-event-access',
    template: require('./quick-event-access.html')
})

export class QuickEventAccess implements OnInit, OnDestroy {

    private isPaymentRequired = false;
    private isByInvitation = false;
    private isFcfs = true;
    private accessTypeCost: string;
    private accessTypeServiceFee: string;
    private isLoading = false;
    private event: EfEvent;

    constructor(
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService
    ) {
        this.checkFormStatus;
        this.store.select('event').pipe(
            filter(event => !!event.data)
        ).subscribe(event => this.event = event.data);
    }

    ngOnInit() {
        this.segmentService.segmentEventTracking().quickCreateAccessTypesInit();

        this.accessTypesForm = this.formBuilder.group({
            accessTypeName: [null, Validators.required],
            accessTypeQuantity: [null, Validators.required],
            distributionMethod: ['public-registration', Validators.required],
            accessTypePayment: ['free', Validators.required],
            accessTypeCost: [null],
            accessTypeServiceFee: [null],
            firstComeFirstServe: ['yes'],
        });

        this.checkFormStatus = this.accessTypesForm.valueChanges.subscribe(form => {
            this.isByInvitation = form.distributionMethod === 'invite';
            this.isPaymentRequired = form.accessTypePayment === 'payment-required';
            this.isFcfs = form.firstComeFirstServe === 'yes';
            this.accessTypeCost = form.accessTypeCost;
            this.accessTypeServiceFee = form.accessTypeServiceFee;
        });

    }

    private accessTypesForm: FormGroup;
    private checkFormStatus: Subscription;

    private get stackMethodType() {
        if (this.isByInvitation && this.isFcfs && !this.isPaymentRequired) { return 'invite-to-rsvp-fcfs'; }
        if (this.isByInvitation && this.isFcfs && this.isPaymentRequired) { return 'invite-to-purchase-fcfs'; }
        if (this.isByInvitation && !this.isFcfs && !this.isPaymentRequired) { return 'invite-to-rsvp'; }
        if (this.isByInvitation && !this.isFcfs && this.isPaymentRequired) { return 'invite-to-purchase'; }
        if (!this.isByInvitation && !this.isPaymentRequired) { return 'public-registration'; }
        if (!this.isByInvitation && this.isPaymentRequired) { return 'public-purchase'; }
    }

    private maxQuantity(res) {
        const maxQuantity = res.data.command.quantity <= 10 ? res.data.command.quantity : 10;
        if (this.isByInvitation) {
            return 1;
        }

        return maxQuantity;
    }

    private async createTicketType(close = null) {

        let teamId = null;
        // if virbela enabled, grab
        if (this.eventFarmService.virbelaEnabled) {
            const poolWorld = await this.eventFarmService.currentPoolWorldForEvent();
            teamId = poolWorld ? poolWorld._teamId : null;
        }

        if (!this.accessTypesForm.valid) {
            return this.message.info('Please fill out all fields');
        }
        this.isLoading = true;
        const loadingMsg = this.message.loading(`Creating ${this.accessTypesForm.get('accessTypeName').value}...`, {nzDuration: 0}).messageId;

        const accessTypeDetails = {
            accessTypeName: this.accessTypesForm.get('accessTypeName').value,
            accessTypeQuantity: parseInt(this.accessTypesForm.get('accessTypeQuantity').value, 10),
        };

        const role = new Virbela().VirbelaRoleType().filter(rt => rt.isMember)[0];

        try {
            const res = await this.apiClient.getUseCaseFactory().TicketType().CreateTicketType(
                this.event.id,
                this.accessTypesForm.get('accessTypeName').value,
                null,
                parseInt(this.accessTypesForm.get('accessTypeQuantity').value, 10),
                1,
                false
            ).toPromise();

            const date = new Date(this.event.startTime.timestamp * 1000).toISOString();
            const stackRes = await this.apiClient.getUseCaseFactory().Stack().CreateStack(
                this.event.id,
                res.data.command.ticketTypeId,
                this.stackMethodType,
                res.data.command.quantity,
                this.maxQuantity(res),
                this.accessTypeCost ? Number(this.accessTypeCost) : null,
                this.accessTypeServiceFee ? Number(this.accessTypeServiceFee) : null,
                new Date().getTime() / 1000,
                this.event.startTime.timestamp,
                true,
                null,
                null,
                null,
                null,
                teamId,
                teamId ? role.slug : null
            ).toPromise();

            this.isLoading = false;
            this.message.remove(loadingMsg);
            this.message.success(`Added ${this.accessTypesForm.get('accessTypeName').value}`);

            const launchCanvasModal: Modal = new Modal();
            launchCanvasModal.component = LaunchCanvasComponent;
            launchCanvasModal.cssClass = 'thin-header';
            launchCanvasModal.segment = QUICK_CREATE_ACCESS_TYPES_MODAL_DISMISSED;

            if (close && this.event.isCanvas) {
                // this.message.remove();
                this.segmentService.segmentEventTracking().quickCreateAccessTypesSaveDone(accessTypeDetails);
                // this.segmentService.segmentEventTracking().quickCreateEnteredCanvas();
                // this.modalService.changeModal(launchCanvasModal);
                this.modalService.closeModal();
            }

            if (!close) {
                this.segmentService.segmentEventTracking().quickCreateAccessTypesSaveCreateAnother(accessTypeDetails);
            }

            if (close && !this.event.isCanvas) {
                this.segmentService.segmentEventTracking().quickCreateAccessTypesSaveDone(accessTypeDetails);
                this.modalService.closeModal();
            }

            this.resetForm();

            this.store.dispatch(new eventActions.FetchAccessTypes());
        } catch (e) {
            this.apiError(e, '');
        }
    }

    private resetForm() {

        this.accessTypesForm = this.formBuilder.group({
            accessTypeName: [null, Validators.required],
            accessTypeQuantity: [null, Validators.required],
            distributionMethod: ['public-registration', Validators.required],
            accessTypePayment: ['free', Validators.required],
            accessTypeCost: [null],
            accessTypeServiceFee: [null],
            firstComeFirstServe: ['yes'],
        });

        this.checkFormStatus = this.accessTypesForm.valueChanges.subscribe(form => {
            this.isByInvitation = form.distributionMethod === 'invite';
            this.isPaymentRequired = form.accessTypePayment === 'payment-required';
            this.isFcfs = form.firstComeFirstServe === 'yes';
            this.accessTypeCost = form.accessTypeCost;
            this.accessTypeServiceFee = form.accessTypeServiceFee;
        });

        this.isByInvitation = false;
        this.isPaymentRequired = false;
        this.isFcfs = true;
    }

    private apiError(err, loadingMsg) {
        this.isLoading = false;
        this.message.remove(loadingMsg);
        this.message.error(`Error: ${err.error.errors[0].detail}`);
    }

    private preventNonInt(e) {
        return e.charCode >= 48 && e.charCode <= 57;
    }

    ngOnDestroy() {
        this.checkFormStatus.unsubscribe();
    }
}
