import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventFarmService } from '../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { AlertService } from '../../../eventFarmAlert.service';
import * as fromStore from '../../../store';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  selector: 'min-max-selection',
  template: require('./min-max-selection.html'),
  styles: [require('./min-max-selection.scss')],
})

export class MinMaxSelectionComponent implements OnDestroy, OnInit {

  constructor(
    private eventFarmService: EventFarmService,
    private apiClient: EventFarmAPIClient,
    private alertService: AlertService,
    private message: NzMessageService,
    private store: Store<fromStore.AppState>,
  ) {
    this.eventUpdate$ = this.eventStore$.pipe(filter(val => val.data && val.data.id)).subscribe((val) => {
    });
  }

  private eventRoleType$ = this.store.select(fromStore.eventRoleType).pipe(shareReplay());
  private min: number = 0;
  private max: number = 0;
  private minRange: number[];
  private maxRange: number[];

  private eventStore$: Observable<any> = this.store.select('event').pipe(shareReplay());
  private eventUpdate$: Subscription;

  ngOnInit() {
    this.min = this.eventFarmService.currentEvent.minInvitationCount;
    this.max = this.eventFarmService.currentEvent.maxInvitationCount;

    this.calcValidRanges();

  }

  ngOnDestroy() {
    this.eventUpdate$.unsubscribe();
  }

  calcValidRanges() {
    if ( this.min === 0 && this.max === 0 || this.min !== 0 && this.max === 0) {
      this.minRange = Array.from(new Array(25), (x, i) => i + 1);
      this.maxRange = Array.from(new Array(25), (x, i) => i + 1);
    } else if (this.min !== 0 && this.max !== 0) {
      this.minRange = Array.from(new Array(this.max), (x, i) => i + 1);
      this.maxRange = Array.from(new Array(25 - this.min), (x, i) => i + this.min);
    } else if (this.min === 0 && this.max !== 0 ) {
      this.minRange = Array.from(new Array(this.max), (x, i) => i + 1);
      this.maxRange = Array.from(new Array(25), (x, i) => i + 1);
    }
  }

  get helperText(): string {

    if ( this.min === 0 && this.max === 0) {
      return `Guests are not required to meet a minimum or maximum quantity at registration.`;
    } else if (this.min !== 0 && this.max === 0) {
      return `Guests must select a quantity of ${this.min} or more at registration, across all access types.`;
    } else if (this.min === 0 && this.max !== 0) {
      return `Guests must select a quantity of ${this.max} or fewer at registration, across all access types.`;
    } else if (this.min !== 0 && this.max !== 0) {
      return `Guests must select a quantity between ${this.min} and ${this.max} at registration, across all access types.`;
    }
    return '';
  }

  update(sliderName: string, val: number) {

    if ( sliderName === 'min') {
      this.apiClient.getUseCaseFactory().Event().SetMinInvitationCountForEvent(this.eventFarmService.currentEvent.id, val).toPromise().then( () => {
        this.eventFarmService.currentEvent.minInvitationCount = val;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Min order size updated');
        this.calcValidRanges();
      });
    } else {
      this.apiClient.getUseCaseFactory().Event().SetMaxInvitationCountForEvent(this.eventFarmService.currentEvent.id, val).toPromise().then( () => {
        this.eventFarmService.currentEvent.maxInvitationCount = val;
        this.store.dispatch(new fromStore.SetCurrentEvent(this.eventFarmService.currentEvent));
        this.message.success('Max order size updated');
        this.calcValidRanges();
      });
    }
  }
}
