import { AbstractBaseAlert } from '../base-alert';

export class UserPasswordAlert extends AbstractBaseAlert {

    confirmResetPassword(): Promise<any> {
        return this.alert({
            title: 'Reset Password?',
            text: 'By clicking "confirm", you will be sent an email with instructions to complete the process',
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            icon: 'question',
            focusCancel: true,
            confirmButtonColor: '#ea2b38',
            cancelButtonColor: '#a5a8b2'
        })
    }
}
