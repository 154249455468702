import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';

import { SegmentService } from '../../../../../../Analytics/Segment/segment.service';
import { Exhibitor } from '../../../../../../ApiClient/Models/Exhibitor/exhibitor';
import { ExhibitorsService } from '../../../exhibitors.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventFarmService } from '../../../../../eventFarm.service';
import _ = require('lodash');

@Component({
  selector: 'edit-exhibitor',
  template: require('./edit-exhibitor.html'),
  styles: [require('./edit-exhibitor.scss')]
})
export class EditExhibitorComponent implements OnInit {

   @Input() exhibitor: Exhibitor;
   editExhibitorsItemForm: FormGroup;

   private isSaving: boolean = false;
   private confirmDelete: boolean = false;
   private isDeleting: boolean = false;

  constructor(
    private drawerRef: NzDrawerRef,
    private store: Store<fromRoot.AppState>,
    private exhibitorService: ExhibitorsService,
    private segmentService: SegmentService,
    private eventFarmService: EventFarmService,
    private messageService: NzMessageService

  ) {}

  ngOnInit() {
    this.initializeFormControls();
  }

  private async addExhibitor() {
    if (this.isSaving === false) {
      this.isSaving = true;
      try {
        const exhibitor = new Exhibitor();
        exhibitor.name = this.editExhibitorsItemForm.get('name').value;
        exhibitor.description = this.editExhibitorsItemForm.get('description').value;
        exhibitor.logoUrl = this.editExhibitorsItemForm.get('logoUrl').value;
        await this.exhibitorService.createExhibitor(exhibitor);
        this.drawerRef.close();
        this.exhibitorService.exhibitorsListOptions.pagination.currentExhibitorsPage = 1;
        await this.exhibitorService.getExhibitors();
      } catch (err) {
        this.messageService.error('Error adding exhibitor');
      }
      this.isSaving = false;
      this.drawerRef.close();
    }
  }

  private async saveExhibitor() {

    if (this.isSaving === false) {
      this.isSaving = true;
      const promises = this.constructPromisesForUpdate();
      try {
        await Promise.all(promises);
        this.messageService.success('Exhibitor updated!');
        this.drawerRef.close();
        this.exhibitorService.getExhibitors();
      } catch (error) {
        this.messageService.error('Error updating exhibitor');
      }
      this.isSaving = false;
      this.drawerRef.close();
    }
  }

  private determineDirtyFields(controls) {
    const dirty = Object.keys(controls)
        .map((name) => {
          return { ...controls[name], name };
        })
        .filter((obj) => !obj.pristine);
    return dirty;
  }

  private constructPromisesForUpdate(): Array<Promise<any>> {
    const promises = [];
    const dirty = this.determineDirtyFields(
        this.editExhibitorsItemForm.controls
    );
    dirty.map((d) => {
      switch (d.name) {
        case 'name':
          promises.push(
              this.exhibitorService.updateExhibitorName(
                  this.exhibitor.id,
                  d.value
              )
          );
          break;
        case 'description':
          promises.push(
              this.exhibitorService.updateExhibitorDescription(
                  this.exhibitor.id,
                  d.value
              )
          );
          break;
        case 'logoUrl':
          promises.push(
              this.exhibitorService.updateExhibitorLogoUrl(
                  this.exhibitor.id,
                  d.value
              )
          );
          break;
      }
    });
    return promises;
  }

  private async handleDeleteExhibitor() {
    if (this.isDeleting) {
      this.isDeleting = false;
    }
    if (this.confirmDelete) {
      this.isDeleting = true;
      const deleteSuccess = await this.exhibitorService.removeExhibitor(this.exhibitor.id);

      this.isDeleting = false;
      this.confirmDelete = false;

      if (deleteSuccess === true) {
        this.drawerRef.close();
        await this.exhibitorService.getExhibitors();
      }
    }
    this.confirmDelete = true;
  }

  private allowSave() {
    return this.editExhibitorsItemForm.valid;
  }

  private initializeFormControls() {
    this.editExhibitorsItemForm = new FormGroup({
      name: new FormControl(_.get(this, 'exhibitor.name', null), Validators.required),
      description: new FormControl(_.get(this, 'exhibitor.description', null)),
      logoUrl: new FormControl(_.get(this, 'exhibitor.logoUrl', null))
    });
  }

  close(): void {
    this.drawerRef.close(this.exhibitor);
  }
}
