import {AbstractControl, ValidationErrors} from "@angular/forms";

export function ValidateEmailOrPhoneRequired(control: AbstractControl): ValidationErrors | null {
    const selection = control.get('selection').value?.value;
    const arrivalAlertEnabled = control.get('arrivalAlertEnabled').value;
    const email = control.get('arrivalAlertEmails').value;
    const phone = control.get('arrivalAlertPhoneNumbers').value;

    if (selection === 'arrivalAlert' && arrivalAlertEnabled && !email && !phone) { return { 'noMatch': true } }

    return null;
}