import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { TicketBlock } from '../../ApiClient/Models/Stack/ticket-block';
import { EventFarmAPIClient } from '../../ApiClient/event-farm-api-client';
import { EventFarmService } from '../eventFarm.service';
import { RouteGeneratorService } from '../../_services/routes/route-generator.service';

@Injectable()

export class TicketBlockResolver implements Resolve<TicketBlock> {
    constructor(
        private client: EventFarmAPIClient,
        private eventFarmService: EventFarmService,
        private router: Router,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const ticketBlockId = route.queryParams.ticketBlockId;

        if (ticketBlockId) {
            return this.client.getUseCaseFactory().TicketBlock().GetTicketBlock(ticketBlockId, ['Event', 'Allotments', 'AllotmentCounts', 'AllotmentsAndStack'], true);
        }

        this.router.navigateByUrl(this.routeGenerator.url('pools.events-list', { poolId: this.eventFarmService.currentTeam.id }));
        return;
    }
}
