import {EventEmitter, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { Operator } from 'rxjs';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { UploaderService } from '../uploader.service';
import { ProcessingService } from '../Processing/processing.service';
import { UploaderTabsService } from '../uploaderTabs.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';
import { EventFarmService } from '../../../eventFarm.service';

@Injectable()

export class ChooseFileService {

    constructor(
        private uploaderService: UploaderService,
        private client: EventFarmAPIClient,
        private processingService: ProcessingService,
        private router: Router,
        private segmentService: SegmentService,
        private eventFarmService: EventFarmService,
        private routeGenerator: RouteGeneratorService
    ) {
    }

    private queueCommandId: string;
    private userImportId: string;

    upload(selectedFile: File,
           onChooseFileSuccess?: () => void,
           onPreProcessSuccess?: (userImportId: string) => void,
           onPostProcessSuccess?: (userImportId: string) => void,

    ) {
        const poolId = this.eventFarmService.currentTeam.id || null;
        const eventId = this.eventFarmService.currentEvent && this.eventFarmService.currentEvent.id || null;
        const userId = this.eventFarmService.currentUser.id || null;

        this.segmentService.segmentUploaderTracking().uploadToExistingGroup(
            this.eventFarmService.currentTeam,
            this.eventFarmService.currentUser,
            this.uploaderService.group
        );

        this.client.getUseCaseFactory().Import().PreProcessSpreadsheetForUserImport(
            userId,
            poolId,
            selectedFile,
        )
            .subscribe(
                (val) => {
                    this.queueCommandId = val.data.id;
                    this.userImportId = val.data.command.userImportId;
                    this.processingService.currentState = 'pre';
                    this.processingService.startPolling(
                        this.queueCommandId,
                        this.userImportId,
                        onPreProcessSuccess,
                        onPostProcessSuccess
                    );
                    if (onPreProcessSuccess) { // Prioritize custom callback instead of changing routes
                        onPreProcessSuccess(this.userImportId);
                    } else {
                        if (eventId) {
                            this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.processing', { poolId, eventId }));
                        } else {
                            this.router.navigateByUrl(this.routeGenerator.url('pools.uploader.processing', { poolId }));
                        }
                    }
                },
                (error) => {
                }
            );
    }
}
