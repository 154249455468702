import {Component, OnInit} from '@angular/core';
import {EventFarmService} from '../../../eventFarm.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store';

import {SegmentService} from '../../../../Analytics/Segment/segment.service';
import {PaymentSettingsService} from './payment-settings.service';
import {Observable} from 'rxjs';


@Component({
    selector: 'payment-settings',
    template: require('./payment-settings.html'),
    styles: [require('./payment-settings.scss')],
})

export class PaymentSettingsComponent implements OnInit {
    constructor(
        private eventFarmService: EventFarmService,
        private store: Store<fromRoot.AppState>,
        private segmentService: SegmentService,
        private paymentSettingsService: PaymentSettingsService
    ) {
    }


    async ngOnInit() {
        this.paymentSettingsService.init();
        this.segmentService.segmentSideNavTracking().paymentSettingsEntered();
    }

}
