import { Component, Input, OnInit } from '@angular/core';
import { EditChildButtonComponent } from './EditChild/edit-child-button.component';
import { IconService } from '../../../../CoreUI/Icons/icons.service';
import { shareReplay, filter, switchMap, tap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store';
import { EfEvent } from '../../../../../ApiClient/Models/Event/event';
import { ChildEventsService } from '../../child-events.service';
import { RouteGeneratorService } from '../../../../../_services/routes/route-generator.service';

@Component({
    selector: '[child-item]',
    template: require('./child-item.html'),
    styles: [require('./child-item.scss')],
})

export class ChildItemComponent implements OnInit {
    @Input() child: EfEvent;
    private childStatusColor: { borderLeft: string };
    private currentTime = + new Date() / 1000;

    constructor(
        private iconService: IconService,
        private store: Store<fromRoot.AppState>,
        private childEventsService: ChildEventsService,
        private routeGenerator: RouteGeneratorService
    ) { }

    ngOnInit() {
        this.getChildColor();
    }

    private eventRoleType$ = this.store.select(fromRoot.eventRoleType).pipe(shareReplay());

    getChildColor() {

        let duringPromotion;

        return this.childStatusColor = { borderLeft: '4px green solid' };

        // if (this.promo.startTime && this.promo.endTime) {
        //     duringPromotion = this.promo.startTime < this.currentTime && this.promo.endTime > this.currentTime;

        //     if (!this.promo.isEnabled) {
        //         return this.promotionStatusColor = { borderLeft: '4px grey solid' };
        //     }
        //     if (this.promo.startTime > this.currentTime) {
        //         return this.promotionStatusColor = { borderLeft: '4px orange solid' };
        //     }
        //     if (this.promo.endTime < this.currentTime) {
        //         return this.promotionStatusColor = { borderLeft: '4px red solid' };
        //     }
        // } else {
        //     duringPromotion = true;
        //     if (!this.promo.isEnabled) {
        //         return this.promotionStatusColor = { borderLeft: '4px grey solid' };
        //     }
        // }

        // if ( this.promo.maximum === null) {
        //     return this.promotionStatusColor = { borderLeft: '4px green solid' };
        // } else {
        //     if (duringPromotion && ( this.promo.maximum - this.promo.used >= 1 )) {
        //         return this.promotionStatusColor = { borderLeft: '4px green solid' };
        //     }
        //     if (duringPromotion && ( this.promo.maximum - this.promo.used <= 0 )) {
        //         return this.promotionStatusColor = { borderLeft: '4px red solid' };
        //     }
        // }
    }

}
