import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Stack } from '../../../ApiClient/Models/Stack/stack';
import { StackSelectionService } from './stackSelection.service';
import { InvitationOptionsService, TicketTypeDTO } from './invitationOptions.service';
import { AddInviteCountService } from './addInviteCount.service';
import { EventFarmService } from '../../eventFarm.service';
import { BehaviorSubject ,  Subscription } from 'rxjs';
import { InvitationCreationTypeInterface } from '@eventfarm/javascript-sdk/dist/Api/Type/Invitation';
import { ApiError } from '../../../ApiClient/Models/error';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';

@Component({
    selector: 'stack-selection',
    styles: [require('./stackSelection.scss')],
    template: require('./stackSelection.html'),
})

export class StackSelectionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() stacks: Stack[];
    @Input() errors: ApiError[];
    @Input() invitationCreationTypes: InvitationCreationTypeInterface[];

    private validationError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private stackSelectionService: StackSelectionService,
        private eventFarmService: EventFarmService,
        private invitationOptionsService: InvitationOptionsService,
        private inviteCountService: AddInviteCountService,
        private store: Store<fromRoot.AppState>,
    ) {
    }

    placeholder = new BehaviorSubject({
        name: ''
    });

    dropdownOptionList = new BehaviorSubject([]);

    private dropdownConfig = {
        options: this.dropdownOptionList,
        placeholder: this.placeholder,
        error: this.validationError$
    };

    ngOnInit() {
        this.invitationOptionsService.stacks = this.eventFarmService.currentEvent.stacks;
        this.stackSelectionService.invitationCreationTypes = this.invitationCreationTypes;
        this.stackSelectionService.invitationCreationTypeSlug = this.stackSelectionService.defaultInvitationCreationTypeSlug;
        this.stackSelectionService.invitationStatusSlug = this.stackSelectionService.defaultStatus;

        this.invitationOptionsService.initTicketTypes();
    }

    ngOnChanges(changes: any): void {
    }

    
    isPublicPurchase(): boolean {
        const selectedStack = this.invitationOptionsService.selectedStack;
        if (selectedStack) {
            return (selectedStack.stackMethodType.isPublicPurchase || selectedStack.stackMethodType.isPublicRegistration);
        }
        return false;
    }

    updateCreationType(type: InvitationCreationTypeInterface) {
        this.stackSelectionService.invitationCreationTypeSlug = type.slug;
    }

    updatedStackSelection() {
        this.invitationOptionsService.getAllInvitationTypesForStackMethodType(this.invitationOptionsService.selectedStack.stackMethodType.slug);

        if (this.invitationOptionsService.selectedStack.stackMethodType.isPublicPurchase || this.invitationOptionsService.selectedStack.stackMethodType.isPublicRegistration) {
            this.updateCreationType(this.invitationCreationTypes.filter(type => type.isConfirmedNoEmail)[0]);
        }
    }

    changeSelectedInvitationType(event) {
        this.invitationOptionsService.selectedStackId = event.stack.id;
        this.invitationOptionsService.invitationOptions.selectedStackId = event.stack.id;
        this.updatedStackSelection();
        this.placeholder.next({
            name: event.name
        });
        if (this.invitationOptionsService.initialInvitation && this.invitationOptionsService.initialInvitation.ticketType.id === event.stack.ticketType.id && this.invitationOptionsService.invitationCount) {
            this.invitationOptionsService.invitationCount = this.invitationOptionsService.initialInvitation.inviteCount;
        }
    }

    private shouldDisableTicketTypeOption(ticketType: TicketTypeDTO) {
        if (!this.invitationOptionsService.showTimeSlots && ticketType.stacks.length === 1) {
            return this.stackSelectionService.shouldDisableStackOption(ticketType.stacks[0]);
        }

        let disabled = true;

        ticketType.stacks.every(s => {
            const shouldDisableStack = this.stackSelectionService.shouldDisableStackOption(s);

            if (!shouldDisableStack) {
                disabled = false;
                return false;
            }

            return true;
        })

        return disabled;
    }

    private onChangeStack(sId:string) {
        this.invitationOptionsService.selectedStackId = sId;
        this.invitationOptionsService.invitationOptions.selectedStackId = sId;
        this.updatedStackSelection();
    }

    private onChangeTicketType(t: string) {
        const selectedTicketTypeDTO = this.invitationOptionsService.selectedTicketType;
        const shouldSetNewStack = selectedTicketTypeDTO && selectedTicketTypeDTO.id !== t;

        this.invitationOptionsService.selectedTicketTypeId = t;

        if (this.invitationOptionsService.selectedTicketType.stacks.length === 1) {
            this.handleSingleStackForTicketType(this.invitationOptionsService.selectedTicketType);
        } else {
            if (shouldSetNewStack) {
                this.handleSingleStackForTicketType(this.invitationOptionsService.selectedTicketType);
            }
        }
    }

    private handleSingleStackForTicketType(selectedTicketType: TicketTypeDTO) {
        const stack = selectedTicketType.stacks[0];
        this.onChangeStack(stack.id);

        if (this.invitationOptionsService.initialInvitation && this.invitationOptionsService.initialInvitation.ticketType.id === stack.ticketType.id && this.invitationOptionsService.invitationCount) {
            this.invitationOptionsService.invitationCount = this.invitationOptionsService.initialInvitation.inviteCount;
        }
    }

    ngOnDestroy() {
        this.validationError$.unsubscribe();
    }

}
