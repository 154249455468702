import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'invitations-found-in-file',
    providers: [],
    template: require('./invitations-found-in-file.html'),
    styles: [require('./invitations-found-in-file.scss')],
})

export class InvitationsFoundInFileComponent {

    constructor(
    ) {
    }

    readonly hashtagSVG = require('./assets/hashtag');
    readonly personGroupSVG = require('./assets/person-group');
    readonly ticketSVG = require('./assets/ticket');

}
