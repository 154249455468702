import {Component, OnInit} from '@angular/core';
import {AdminAlertService} from '../../../Alert/adminEventFarmAlert.service';
import {ManageAdminService} from '../manage-admin.service';

@Component({
    selector: 'downgrade-from-admin',
    template: require('./downgrade-from-admin.html'),
    styles: [require('./downgrade-from-admin.scss')],

})
export class DowngradeFromAdmin implements OnInit {
    selectedValue = null;
    nzFilterOption = (): boolean => true;

    constructor(
        private adminAlertService: AdminAlertService,
        private manageAdminService: ManageAdminService
    ) {

    }

    ngOnInit() {
    }

    public async handleAdminDowngrade(userId) {
        const res = await this.adminAlertService.general().downgradeAdminConfirm(userId);
        if (res.value) {
            try {
                await this.manageAdminService.downgradeAdmin(userId).toPromise();
                await this.manageAdminService.fetchExistingAdmins()
                await this.adminAlertService.general().downgradeAdminSuccess()
                this.selectedValue = null;
            } catch (error) {
            }
          }
    }

}
