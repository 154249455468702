import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventFarmService } from '../../../eventFarm.service';
import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IconService } from '../../../CoreUI/Icons/icons.service';
import { RouteGeneratorService } from '../../../../_services/routes/route-generator.service';
import { CreateEventService } from '../create-event.service';
import { SegmentService } from '../../../../Analytics/Segment/segment.service';

@Component({
    template: require('./get-free-event.html')
})

export class GetFreeEventComponent implements OnInit {

    private industryTypes;
    private eventTypes;
    private eventCounts;
    private countries;

    private submittingStatus = {
        buttonText: 'Get Free Event',
        isRequesting: false
    };

    private getFreeEventForm = new FormGroup({
        firstName: new FormControl(this.eventFarmService.currentUser.name.firstName, Validators.required),
        lastName: new FormControl(this.eventFarmService.currentUser.name.lastName, Validators.required),
        email: new FormControl(this.eventFarmService.currentUser.primaryEmail, Validators.required),
        phoneNumber: new FormControl(null, Validators.required),
        company: new FormControl(null, Validators.required),
        jobTitle: new FormControl(null, Validators.required),
        country: new FormControl(null),
        industry: new FormControl(null, Validators.required),
        eventsPerYear: new FormControl(null, Validators.required),
        primaryUsage: new FormControl(null, Validators.required)
    });

    constructor(
        private router: Router,
        private routeGenerator: RouteGeneratorService,
        private eventFarmService: EventFarmService,
        private createEventService: CreateEventService,
        private apiClient: EventFarmAPIClient,
        private message: NzMessageService,
        private iconService: IconService,
        private segmentService: SegmentService
    ) {
        this.industryTypes = this.apiClient.getTypeFactory().Event().CIOMarketingIndustryType();
        this.eventTypes = this.apiClient.getTypeFactory().Event().CIOMarketingEventType();
        this.eventCounts = this.apiClient.getTypeFactory().Event().CIOMarketingEventCountType();
        this.countries = this.apiClient.getCountryList().CountryList();
    }

    ngOnInit() {
    }

    getFreeEvent() {
        const form = this.getFreeEventForm.value;

        if (!this.getFreeEventForm.valid) {
            return this.message.info('Make sure you fill out all the fields');
        }

        this.sendingRequest();

        const pqlFields = {
            pqlFirstName: form.firstName,
            pqlLastName: form.lastName,
            pqlEmail: form.email,
            pqlCompany: form.company,
            pqlPhone: form.phoneNumber,
            pqlEventsPerYear: form.eventsPerYear === null ? '' : form.eventsPerYear.slug,
            pqlIndustry: form.industry === null ? '' : form.industry.slug,
            pqlPrimaryUsage: form.primaryUsage === null ? [''] : [form.primaryUsage.slug]
        };

        this.apiClient.getUseCaseFactory().Event().IncrementCIOAndMessageCSM(
            this.eventFarmService.currentTeam.id,
            this.eventFarmService.currentUser.id,
            form.firstName,
            form.lastName,
            form.email,
            form.company,
            form.phoneNumber,
            form.eventsPerYear === null ? '' : form.eventsPerYear.slug,
            form.industry === null ? '' : form.industry.slug,
            form.primaryUsage === null ? [''] : [form.primaryUsage.slug],
        ).subscribe(
            (res) => {
                this.segmentService.segmentEventTracking().pqlGetCioEventSubmitted(pqlFields);
                this.successRequest();
                this.createEventService.cioEventsRemaining = '1';
                setTimeout(() => {
                    this.router.navigateByUrl(this.routeGenerator.url('pools.create-event.cio', {poolId: this.eventFarmService.currentTeam.id}))
                        .then()
                        .catch();
                }, 2000);
            },
            (err) => this.failedRequest()
        );
    }

    sendingRequest() {
        this.submittingStatus = {
            buttonText: 'Sending Request...',
            isRequesting: true
        };
    }

    successRequest() {
        this.submittingStatus = {
            buttonText: 'Creating Event...',
            isRequesting: false
        };
    }

    failedRequest() {
        this.submittingStatus = {
            buttonText: 'Get Free Event',
            isRequesting: false
        };
    }

    goBackCIO() {
        this.segmentService.segmentEventTracking().pqlGetCioEventButtonDismissed();
        this.eventFarmService.goBack();
    }
}
