import { froalaOptions } from './../../../../Shared/froala-options';
import { ModalService } from '../../../CoreUI/Modal/modal.service';
import { Component, OnInit } from '@angular/core';

import { EventFarmAPIClient } from '../../../../ApiClient/event-farm-api-client';
import { ModalComponent } from '../../../CoreUI/Modal/modal.component';
import { EventFarmService } from '../../../eventFarm.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    // providers: [ SendTestService ],
    styles: [require('./upload-image.scss')],
    template: require('./upload-image.html'),
})

export class UploadImageComponent implements OnInit {

    constructor(
        private eventFarmService: EventFarmService,
        private client: EventFarmAPIClient,
        private modal: ModalComponent,
        private message: NzMessageService,
        private modalService: ModalService,
    ) { }

    private options: object = froalaOptions;

    ngOnInit() {
        this.uploading = false;
        this.imageIsUploaded = false;
        this.imgToReplace = this.modalService.data && this.modalService.data.image && this.modalService.data.image.get() ? this.modalService.data.image.get()[0] : null;
    }

    public currentUploadImageURI: any;
    public uploading: boolean;
    public imageIsUploaded: boolean;
    public imgToReplace: any;

    submit(val, file) {
        const reader = new FileReader();
        this.uploading = true;
        const fileTypes = this.options['imageAllowedTypes'];

        reader.readAsDataURL(file.files[0]);
        if (!file.files[0].name || !file.files[0].name.split('.')) {
            this.message.error('Please upload a jpg, jpeg, png, or gif file type with a standard file name');
            this.uploading = false;
            return;
        }

        const extension = file.files[0].name.split('.').pop().toLowerCase();
        const isAllowedFileType = fileTypes.indexOf(extension) > -1;

        if ( !isAllowedFileType) {
            this.message.error('Please upload a jpg, jpeg, png, or gif file type');
            this.uploading = false;
            return;
        }

        reader.onload = (e) => {
            this.client.getUseCaseFactory().EmailDesign().CreateEmailDesignImage(
                this.eventFarmService.currentEvent.id,
                e.target['result'],
            ).subscribe((res) => {
                if (res.data.command.emailDesignImageId) {
                    this.client.getUseCaseFactory().EmailDesign().GetEmailDesignImage(
                        res.data.command.emailDesignImageId,
                    ).subscribe(
                        (internalRes) => {
                            this.currentUploadImageURI = internalRes.data.attributes.imageUri;
                            this.uploading = false;
                            this.imageIsUploaded = true;
                        }
                    );
                }
            });
        };
    }
    addToDesign() {
        if (this.imgToReplace !== null) {
            this.imgToReplace.src = this.currentUploadImageURI;
            this.modalService.data.undo.saveStep();
        } else {
            const imageWidth = document.getElementById('edc-ui-width')['value'];
            let randId = this.randIdGen();
            this.modalService.data.html.insert(
                '<img id="'+ randId +'" style="max-width: 100% !important; margin: 0 auto; display: inline-block; height: auto !important; '
                + `width: ${imageWidth};" src="${this.currentUploadImageURI}"/>`);
            this.modalService.data.undo.saveStep();
            let width = document.getElementById(randId).offsetWidth;
            document.getElementById(randId).setAttribute('width', width.toString());
        }
        this.modalService.closeModal();
    }
    randIdGen() {
        let randId = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++)
        randId += possible.charAt(Math.floor(Math.random() * possible.length));
        return randId;
    }
}
